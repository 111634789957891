import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface ITableGeoRegionCellProps extends IBaseProps {
  value: any
}

export const TableGeoRegionCell: React.FC<ITableGeoRegionCellProps> = (props) => {
  const { className, value } = props

  const renderDeadheadLine = () => {
    const { radius } = value
    if (radius) {
      return <span> + {radius.value} {radius.unit} </span>
    }
  }

  const renderStaticAddressFields = () => {
    return (
      <div className={className}>
        {value.address.locality}, {value.address.region}{renderDeadheadLine()}
      </div>
    )
  }

  const renderStaticState = (state, index) => {
    let comma = ', '
    if (index === 0) {
      comma = ''
    }
    return <span key={index}> {comma}{state} </span>

  }

  const renderStaticStates = () => {
    return value.states.map(renderStaticState)
  }

  const renderStaticStatesFields = () => {
    return <div className={className}> States: {renderStaticStates()} </div>

  }

  const renderStaticZipCodeFields = () => {
    return <div className={className}> ZIP Code Range: {value.zipCode} </div>
  }

  // Empty case
  if (_.isEmpty(value)) {
    return <div className={className}> - </div>
  }

  if (value.type === 'address') {
    return renderStaticAddressFields()
  } else if (value.type === 'states') {
    return renderStaticStatesFields()
  } else if (value.type === 'zipCode') {
    return renderStaticZipCodeFields()
  }
}
