import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { browserHistory } from 'browser/history'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import 'browser/components/atomic-elements/atoms/card/_card-header.scss'
import { ICardHeaderProps } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { PagingControls } from 'browser/components/atomic-elements/organisms/entity/entity-header/paging-controls'
import { Link } from 'react-router-dom'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { EntityJsonModal } from 'browser/components/atomic-elements/organisms/internal-tools/admin-json-editor-modal/admin-json-modal'
import apis from 'browser/app/models/apis'
import { Entity } from 'shared-libs/models/entity'
import { SchemaIds } from 'shared-libs/models/schema'
import { UserConversationsModal } from '../../internal-tools/admin-user-conversation-modal/admin-user-conversation-modal'

/**
 * @uiComponent
 */
interface IEntityHeaderProps extends ICardHeaderProps {
  centerFullScreenActionElement?: React.ReactElement<any>
  dataSet?: any
  detailActionElement?: React.ReactElement<any>
  leftFullScreenActionElement?: React.ReactElement<any>
  rightFullScreenActionElement?: React.ReactElement<any>
  isFullScreen?: boolean
  showOpenInNewTabButton?: boolean
  match: any
  pageIndex?: number
  workflowCancelAction?: () => void
}

export interface IEntityDetailCardStates {
  entityPageIndex: number
}

export class EntityHeader extends React.Component<IEntityHeaderProps, any> {
  public static defaultProps: Partial<IEntityHeaderProps> = {
    centerFullScreenActionElement: <CardHeaderItem className='c-cardHeader-item--grow' />,
    showOpenInNewTabButton: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      entityPageIndex: this.getEntityPageIndex(props),
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    // recompute entity page index if data set changes && it is done loading
    const { entityPageIndex } = this.state
    const hasPageIndex = !_.isNil(entityPageIndex)
    if (this.props.dataSet !== nextProps.dataSet || !hasPageIndex) {
      this.setState({
        entityPageIndex: this.getEntityPageIndex(nextProps),
      })
    }
  }

  public render() {
    const { isFullScreen } = this.props
    if (isFullScreen) {
      return this.renderFullScreenHeader()
    } else {
      return this.renderHeader()
    }
  }

  private renderHeader() {
    const { className, children, detailActionElement } = this.props
    // We don't use the CardHeader component because we structure HTML
    // differently
    return (
      <div className={classNames('grid-block shrink c-cardHeader', className)}>
        {children}
        <div className='flex u-flexShrink'>
          {this.renderConversationButton()}
          {detailActionElement}
          {this.renderJSONButton()}
          {this.renderDropdownPopover()}
          {this.renderOpenInNewTabButton()}
          {this.renderCloseButton()}
        </div>
        {this.renderJSONModal()}
      </div>
    )
  }

  private renderFullScreenHeader() {
    const { centerFullScreenActionElement,
      leftFullScreenActionElement,
      className,
      children,
      rightFullScreenActionElement,
    } = this.props
    return (
      <div className={classNames('grid-block shrink vertical', className)}>
        <div className='u-flex u-justifyContentSpaceBetween u-borderBottom c-cardHeader'>
          {this.renderBackButton()}
          {leftFullScreenActionElement}
          {centerFullScreenActionElement}
          {rightFullScreenActionElement}
          {this.renderPagingControls()}
          {this.renderJSONButton()}
          {this.renderConversationButton()}
          {this.renderDropdownPopover()}
          {this.renderOpenInNewTabButton()}
          {this.renderCloseButton()}
          {this.renderJSONModal()}
        </div>
        {children && (
          <div className='u-flex u-justifyContentSpaceBetween
          u-innerBumperTop u-innerBumperBottom--xs u-innerBumpRight--xl u-bumperLeft--xl'>
          {children}
        </div>
        )}
      </div>
    )
  }

  private renderDropdownPopover() {
    const { dropdownMenuElement, workflowCancelAction } = this.props
    if (!dropdownMenuElement) {
      return
    }

    const DropdownType = dropdownMenuElement.type as any
    const dropdownInstance = new DropdownType({
      ...dropdownMenuElement.props,
      workflowCancelAction
    })
    if (!dropdownInstance.hasContent()) {
      return
    }

    const tetherOptions = {
      attachment: 'top right',
      targetAttachment: 'bottom right',
    }
    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin u-noPrint'>
        <TetherTarget
          closeOnPortalClick={true}
          tethered={this.renderDropdown(dropdownMenuElement)}
          tetherOptions={tetherOptions}
        >
          <Button>
            <Icon icon={IconNames.MORE}/>
          </Button>
        </TetherTarget>
      </CardHeaderItem>
    )
  }

  private renderDropdown(dropdownMenuElement) {
    return (
      <Popover
        className='collapse'
        data-debug-id='card-header-options-popover'
      >
        {dropdownMenuElement}
      </Popover>
    )
  }

  private renderBackButton() {
    const { onClose } = this.props

    if (onClose) {
      return (
        <CardHeaderItem className='c-cardHeader-item--smallMargin'>
          <Button
            className={classNames('u-nowrap', Classes.iconClass(IconNames.ARROW_LEFT))}
            onClick={onClose}
          >
            Back
          </Button>
        </CardHeaderItem>
      )
    }
  }

  private renderPagingControls() {
    const { dataSet } = this.props
    const { entityPageIndex } = this.state
    if (!dataSet || _.isNil(entityPageIndex) || entityPageIndex < 0) {
      return null
    }
    return (
      <PagingControls
        dataSet={dataSet}
        onNavigateToNextPage={this.handleNavigateToNextPage}
        onNavigateToPreviousPage={this.handleNavigateToPrevPage}
        pageIndex={entityPageIndex}
      />
    )
  }

  private renderJSONButton() {
    if (!apis.getSettings()?.isAdmin) {
      return null
    }
    return (
      <CardHeaderItem className="c-cardHeader-item--smallMargin u-noPrint">
          <Button
            onClick={() => this.setState({ isShowingJsonModal: true })}
          >
            <Icon
              icon={IconNames.CODE}
              title="View JSON"
            />
          </Button>
      </CardHeaderItem>
    )
  }

  private renderConversationButton() {
    if (!apis.getSettings()?.isAdmin) {
      return null
    }
    const entity: Entity = this.props.entity
    if (!entity.hasMixin(SchemaIds.USER)) {
      return null
    }
    return (
      <CardHeaderItem className="c-cardHeader-item--smallMargin u-noPrint">
          <Button
            onClick={() => this.renderConversationModal() }
          >
            <Icon
              icon={IconNames.MOBILE_PHONE}
              title="View SMS Sessions"
            />
          </Button>
      </CardHeaderItem>
    )
  }

  private renderOpenInNewTabButton() {
    const { entity, onClose, showOpenInNewTabButton } = this.props
    // do not render this in single page mode
    if (!entity || !onClose || !showOpenInNewTabButton) {
      return
    }
    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin u-noPrint'>
        <Link
          className={Classes.BUTTON}
          to={`/entity/${entity.uniqueId}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Icon
            icon={IconNames.SHARE}
            title="Open In New Window"
          />
        </Link>
      </CardHeaderItem>
    )
  }

  private renderCloseButton() {
    const { onClose, isFullScreen } = this.props
    if (!onClose || isFullScreen) {
      return
    }
    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin u-noPrint'>
        <Button
          onClick={onClose}
          data-debug-id='document-card-close'
        >
          <Icon
            icon={IconNames.CROSS}
          />
        </Button>
      </CardHeaderItem>
    )
  }

  private renderJSONModal() {
    const { entity } = this.props
    const { isShowingJsonModal } = this.state
    if (!apis.getSettings()?.isAdmin) {
      return null
    }
    if (!isShowingJsonModal) {
      return null
    }
    return (
      <EntityJsonModal
        entity={entity}
        isOpen={true}
        onClose={() =>
          this.setState({ isShowingJsonModal: false })
        }
      />
    )
  }

  private renderConversationModal() {
    UserConversationsModal.open({ entity: this.props.entity })
  }

  /////////////////////////////////////////////////////////////////////////////
  // Helper Methods
  /////////////////////////////////////////////////////////////////////////////

  private getEntityPageIndex(props) {
    const { entity, dataSet } = props
    if (dataSet && !dataSet.isLoading) {
      return _.findIndex(dataSet.entities, { uniqueId: entity.uniqueId })
    }
    return undefined
  }

  private handleNavigateToNextPage = () => {
    const { dataSet } = this.props
    const { entityPageIndex } = this.state
    if (entityPageIndex === dataSet.content.length - 1) {
      dataSet.handleLoadNextPage().then(() => {
        const entity = dataSet.entities[entityPageIndex + 1]
        this.navigateToPage(entity)
      })
    } else {
      const entity = dataSet.entities[entityPageIndex + 1]
      this.navigateToPage(entity)
    }
  }

  private handleNavigateToPrevPage = () => {
    const { dataSet } = this.props
    const { entityPageIndex } = this.state
    const entity = dataSet.entities[entityPageIndex - 1]
    this.navigateToPage(entity)
  }

  private navigateToPage(entity) {
    const match: any = this.props.match
    const isFullScreen: boolean = this.props.isFullScreen
    const pathName = match.path.replace(':entityId', entity.uniqueId)
    const search = isFullScreen ? 'isFullScreen=1' : undefined
    browserHistory.push({
      pathname: pathName,
      search,
    })
  }
}
