import React from 'react'

import _ from 'lodash'
import { INVOICE_PATH, INVOICE_ENTITY_TYPE } from 'browser/app/constants/rendition-billing'
import { ORDER_ENTITY_TYPE, ORDER_PATH } from 'browser/app/constants/rendition-billing'
import apis from 'browser/app/models/apis'
import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { EntityTable } from 'browser/components/atomic-elements/organisms/entity/entity-table'
import { EntityDataSource } from '../entity-data-source'

const TIGERLINES_FIRM_ID = 'bf6a36f7-c417-47ea-8b2b-1697694e4bde'

/**
 * @prop entity - a salesInvoice entity
 */
interface IRbOrdersTableProps  {
  entity: any
  frames: any
}

interface IRbOrdersTableStates {
  orderEntities: any[]
}

const TABLE_COLUMNS = [
  {
    cellComponent: {
      labelPath: `${ORDER_PATH}.identifier`,
      linkPath: 'uniqueId',
      path: 'entity',
      type: 'ui:table:linkCell',
    },
    groupKey: 'data',
    isFixed: true,
    label: 'Orders',
    path: 'data',
    sortKey: `${ORDER_PATH}.identifier`,
    width: 100,
  },
  {
    flexGrow: 1,
    isSortable: false,
    label: 'Bill To',
    path: `${ORDER_PATH}.billTo.company.displayName`,
    width: 150,
  },
  {
    flexGrow: 1,
    cellComponent: {
      type: 'ui:table:requirementsCell',
      requirementCategory: 'document',
      requirementPath: 'readyToInvoice.requirements'
    },
    label: 'Documents',
    requirementsChecklistHeadingText: 'Required Documents',
    path: `${ORDER_PATH}`,
    width: 20
  },
  {
      flexGrow: 1,
      cellComponent: {
        type: 'ui:table:simpleArrayCell'
      },
      label: 'Missing Documents',
      path: `${ORDER_PATH}.readyToInvoice.status.missingDocuments`,
      width: 80
  },
  {
    flexGrow: 1,
    isSortable: false,
    label: 'Destination',
    path: `${ORDER_PATH}.stops.last.location`,
    width: 130,
  },
  {
    className: 'u-tabularFigures',
    flexGrow: 1,
    label: 'Delivery Window',
    path: `${ORDER_PATH}.stops.last.timeWindow`,
    width: 120,
  }
]

/**
 * A table displaying rows of order entities associated with an invoice
 * @comment This will likely need to be refactored for RB v2.5 -Dixon
 *
 * @props IRbOrdersTableProps
 */
export class RbOrdersTable extends React.Component<IRbOrdersTableProps, IRbOrdersTableStates> {

  private dataSet: EntityDataSource

  constructor(props) {
    super(props)

     const handleOnChange = (data) => {
      this.setState({ orderEntities: data })
      this.forceUpdate()
    }

    this.dataSet = new EntityDataSource({
      entityType: ORDER_ENTITY_TYPE,
    }).setOnChange(handleOnChange)

    this.state = {
      orderEntities: []
    }
  }

  public componentDidMount() {
    const { entity } = this.props

    if (entity.owner.firm.entityId === TIGERLINES_FIRM_ID) {
      const id = _.get(entity, 'uniqueId', '')
      if (!_.isEmpty(id)) {
        this.dataSet.setFilters(
          {
            path: 'core_fulfilment_salesOrder.consolidateSalesInvoice',
            type: 'matchEdge',
            value: {entityId: id}
          },
        )
        this.dataSet.find();
      }
    } else {
      const orderIds = this.relatedOrderIds(entity)
      if (!_.isEmpty(orderIds)) {
        this.dataSet.setFilters(
          {
            path: 'uniqueId',
            type: 'match',
            values: orderIds
          },
        )
        this.dataSet.find();
      }
    }
  }

  public componentWillUnmount() {
    this.dataSet.dispose()
  }

  public render() {
    return (
      <Section title='Related Orders'>
        <div className='u-positionRelative u-minHeight720'>
          <AutofillBlock className='u-border'>
            {this.renderContent()}
          </AutofillBlock>
        </div>
      </Section>
    )
  }

  private renderContent = () => {
    const { orderEntities } = this.state
    const entitySchema = apis.getStore().getRecord(ORDER_ENTITY_TYPE)
    return (
      <EntityTable
        columns={TABLE_COLUMNS}
        entitySchema={entitySchema}
        isConfigurable={false}
        onRowClick={null}
        onRowSelect={null}
        rows={orderEntities}
        frames={this.props.frames}
      />
    )
  }

  private relatedOrderIds = (entity) => {
    const orderId = _.get(entity, [INVOICE_PATH, 'order', 'entityId'])
    const childOrderIds = _.map(
      _.get(entity, 'core_accounting_accountsReceivable_consolidatedSalesInvoice.salesOrders'),
      'entityId')

    return _.filter([orderId, ...childOrderIds], (id) => !_.isEmpty(id))
  }
}
