import { WithDefaultProps } from 'browser/components/atomic-elements/higher-order-components/with-default-props'
import { Footer, IFooterProps } from 'browser/components/atomic-elements/atoms/footer/footer'

import '../../styles/_control.scss'
import './_mobile-footer.scss'
import React, { useContext } from 'react'
import { MobileHeaderContext } from 'browser/mobile/contexts/mobile-header-context'

const MobileFooterImpl = WithDefaultProps(Footer, {
  buttonClassName: 'mobile-button',
  size: 'lg',
  className: 'mobile-footer',
  footerHeight: 80
})

type IMobileFooterProps = IFooterProps & {
  /** Progress percentage in range [0.0, 1.0], or undefined. */
  progress?: number
}

export const MobileFooter: React.FC<IMobileFooterProps> = (props) => {
  const { enabled: isMobileHeaderEnabled } = useContext(MobileHeaderContext)
  const isVisible = props.isVisible && isMobileHeaderEnabled
  return <>
    {renderProgress(props.progress)}
    <MobileFooterImpl {...props} isVisible={isVisible} />
  </>
}

function renderProgress(progress: number | undefined) {
  if (progress == null) {
    return null
  }

  // coerce integer
  progress = progress | 0

  if (progress >= 1) {
    return (
      <div className='mobile-upload-finishing'>
        Processing...
      </div>
    )
  }
  return (
    <div className='mobile-upload-progress-container'>
      <div className='mobile-upload-progress' style={{width: `${progress * 100.0}%`}} />
    </div>
  )
}
