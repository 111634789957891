import classNames from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { Formatter } from 'shared-libs/helpers/formatter'

interface IStopCargoTableProps extends IBaseProps {
  order: any
  dispatchOrder: any
  isBlindBol?: any
}

export class StopCargoTable extends React.Component<IStopCargoTableProps, any> {

  public render() {
    const { order, dispatchOrder, className } = this.props
    const cargoRows = _.map(dispatchOrder.stops, (stop: any, index) => {
      if (!stop) { return }
      return this.renderMultiStopCargoSection(dispatchOrder, order, stop,
        index, dispatchOrder.stops.length - 1, index)
    })

    return (
      <table className={classNames('c-table c-table--responsive c-table--auto c-table--headerBodyBorder', className)}>
        <thead className='c-table-header'>
          <tr>
            <th
              className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft'
              colSpan={2}
            >
              Stops
            </th>
            <th
              className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft'
              style={{ minWidth: '50px' }}
            >
              Type
            </th>
            <th
              className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft'
              style={{ minWidth: '100px' }}
            >
              Commodity
            </th>
            <th className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
              Value
            </th>
            <th className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
              Quantity
            </th>
            <th
              className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'
              style={{ minWidth: '80px' }}
            >
              Weight
            </th>
          </tr>
        </thead>
        {cargoRows}
      </table>
    )
  }

  private renderStopCargoTable() {
    const { order } = this.props
    const { dispatchOrder } = order
    const { cargos } = dispatchOrder
    const cargoRows = _.map(cargos, (cargo: any, index) => {
      const { value, quantity, weight } = cargo
      return (
        <tr key={index}>
          <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft'>
            {cargo.description}
          </td>
          <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
            {_.get(value, 'value')} {_.get(value, 'unit')}
          </td>
          <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
            {_.get(quantity, 'value', '-')} {_.get(quantity, 'unit')}
          </td>
          <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
            {_.get(weight, 'value')} {_.get(weight, 'unit')}
          </td>
        </tr>
      )
    })
    return (
      <table className='c-table c-table--auto'>
        <thead className='c-table-header'>
          <tr>
            <th className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft'>
              Cargo Description
            </th>
            <th className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
              Value
            </th>
            <th className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
              Quantity
            </th>
            <th className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
              Weight
            </th>
          </tr>
        </thead>
        <tbody className='c-table-body'>
          {cargoRows}
        </tbody>
      </table>
    )
  }

  private renderMultiStopCargoSection(dispatchOrder, order, stop, stopNumber, lastIndex, cargoSectionIndex) {
    const { cargos } = dispatchOrder
    const location = stop.location
    const contact = stop.contact
    const timeWindow = stop.timeWindow
    const referenceNumbers = stop.referenceNumbers
    const rowSpan = _.get(stop, 'cargos.length', 0)
    const { customer } = order
    const cargoRows = _.map(stop.cargos, (task: any, index) => {
      const cargoId = task.cargo.itemId
      const cargo: any = _.find(cargos, { uniqueId: cargoId })
      const { value, quantity, weight } = task
      // tslint:disable-next-line:max-line-length
      const cellProps = { rowSpan, stopNumber, location, contact, timeWindow, referenceNumbers, index, lastIndex, customer }
      return (
        <tr key={index}>
          {this.renderLocationCell(cellProps)}
          {this.renderLocationInformationCell(cellProps)}
          <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft'>
            {task.type}
          </td>
          <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft'>
            {cargo.description}
          </td>
          <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
            {_.get(value, 'value')} {_.get(value, 'unit')}
          </td>
          <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
            {_.get(quantity, 'value')} {_.get(quantity, 'unit')}
          </td>
          <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
            {_.get(weight, 'value')} {_.get(weight, 'unit')}
          </td>
        </tr>
      )
    })
    return (
      <tbody
        className='c-table-body'
        key={cargoSectionIndex}
      >
        {cargoRows}
      </tbody>
    )
  }

  private renderLocationInformationCell(cellProps) {
    const { rowSpan, timeWindow, referenceNumbers, index } = cellProps
    if (index === 0) {
      return (
        <td
          className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft'
          rowSpan={rowSpan}
        >
          <div className=''>
            <div className='b'>Appointment Window</div>
            {formatAppointmentWinodw(timeWindow)}
          </div>

          <div className='mt3'>
            <div className='b'>Location Reference Numbers</div>
            <div className='pre-wrap'>
              {referenceNumbers}
            </div>
          </div>
        </td>
      )
    }
  }

  private renderLocationCell(cellProps) {
    // tslint:disable-next-line:max-line-length
    const { rowSpan, stopNumber, location, contact, index, lastIndex, customer } = cellProps
    const { isBlindBol } = this.props
    if (index === 0) {
      let stopLabel = `Stop ${stopNumber + 1}`
      if (stopNumber === 0) {
        stopLabel = `Stop ${stopNumber + 1} - Origin`
      } else if (stopNumber === lastIndex) {
        stopLabel = `Stop ${stopNumber + 1} - Destination`
      }
      if (isBlindBol && stopNumber === 0) {
        const { addresses } = customer.business
        if (!addresses || customer.business.addresses.length === 0) {
          return (
            <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft' rowSpan={rowSpan}>
              <span className='b'>{stopLabel}</span><br />
              <div>
                {customer.displayName}<br />
              </div>
            </td>
          )
        }
        const key = 'location.address'
        const address = addresses[0].value.denormalizedProperties[key]
        return (
          <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft' rowSpan={rowSpan}>
            <span className='b'>{stopLabel}</span><br />
            <div>
              {customer.displayName}<br />
              {address.streetAddress}<br />
              {address.locality}, {address.region} {address.postalCode}
            </div>
          </td>
        )
      }
      return (
        <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft' rowSpan={rowSpan}>
          <span className='b'>{stopLabel}</span><br />
          {this.renderLocation(location)}
          {this.renderLocationPhoneNumber(location)}
          {this.renderLocationContact(contact)}
        </td>
      )
    }
  }

  private renderLocation(location) {
    if (_.isEmpty(location)) {
      return
    }
    const key = 'location.address'
    const address = location.denormalizedProperties ?
      location.denormalizedProperties[key] : _.get(location, key)
    return (
      <div>
        {location.displayName}<br />
        {address.streetAddress}<br />
        {address.locality}, {address.region} {address.postalCode}
      </div>
    )
  }

  private renderLocationPhoneNumber(location) {
    if (_.isEmpty(location)) {
      return
    }
    const key = 'location.phoneNumbers'
    const phoneNumbers = location.denormalizedProperties ?
      location.denormalizedProperties[key] : _.get(location, key)
    return (
      <div>
        {_.map(phoneNumbers, (phone: any, index) => {
          return (
            <span key={index}>
              {phone.value.phone}<br />
            </span>
          )
        })}
      </div>
    )
  }

  private renderLocationContact(contact) {
    if (_.isEmpty(contact)) {
      return
    }

    return (
      <div className='mt3'>
        {contact.person.firstName} {contact.person.lastName}<br />
        {_.map(contact.person.phoneNumbers, (phone: any, index) => {
          if (phone.label === 'Primary Phone') {
            return (
              <span key={index}>
                {phone.value.phone}<br />
              </span>
            )
          }
        })}
      </div>
    )
  }
}

function formatAppointmentWinodw(appointmentWindow) {
  return appointmentWindow ? Formatter.formatDateRange(appointmentWindow, true, true) : '-'
}
