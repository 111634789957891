import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import { EntityFormPanel } from 'browser/components/atomic-elements/organisms/entity/entity-form-panel'
import 'browser/components/atomic-elements/organisms/share-bar/_share-bar.scss'

// tslint:disable-next-line:max-line-length
import { ShareConfigurationModal } from 'browser/components/atomic-elements/organisms/share-configuration-modal/share-configuration-modal'
// tslint:disable-next-line:max-line-length
import { getBusinessContacts, getBusinessEmails } from 'browser/components/atomic-elements/organisms/share-configuration-modal/utils'
import { getDeferredUser } from 'shared-libs/helpers/utils'

const SHARE_SCHEMA_URI = '/1.0/entities/metadata/share.json'
const CARRIER_PORTAL_INVITE_URI = '/1.0/entities/metadata/carrierPortalInvite.json'

/**
 * @uiComponent
 */
interface IShareBarProps extends IBaseProps {
  entity: any
  showButtonOnly?: boolean
  buttonText?: string
  isInvite?: boolean
  size?: string
  modalTitle?: string
  plusButtonClassName?: string
  showShareAccessSettingsInModal?: boolean
  showRecipientsList?: boolean
}

interface IShareBarState {
  isTogglingFollow: boolean
}

export class ShareBar extends React.Component<IShareBarProps, IShareBarState> {

  public static defaultProps: Partial<IShareBarProps> = {
    buttonText: 'Share',
    isInvite: false,
    showRecipientsList: true,
    showShareAccessSettingsInModal: true,
    size: 'xs',
  }

  private dataSet: EntityDataSource

  constructor(props) {
    super(props)
    this.createEntityDataSource(props.entity)
    this.state = {
      isTogglingFollow: false,
    }
  }

  public componentDidMount() {
    this.dataSet.find()
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.entity !== nextProps.entity) {
      this.createEntityDataSource(nextProps.entity)
      this.dataSet.find()
    }
  }

  public componentWillUnmount() {
    this.dataSet.dispose()
  }

  public render() {
    const {
      className,
      size,
      showButtonOnly,
    } = this.props
    if (showButtonOnly) {
      return this.renderButton()
    }
    return (
      <div className={classNames('c-shareBar u-noPrint', className)}>
        {this.renderAvatarRow()}
        {/* {this.renderFollowButton()} */}
      </div>
    )
  }

  // private renderFollowButton() {
  //   const { entity } = this.props
  //   const { isTogglingFollow } = this.state
  //   const { settings } = this.context
  //   const user = settings.getUser()
  //   const email = user.get('person.emails.0.value')
  //   const shareEntity = _.find(this.dataSet.entities, (share) => {
  //     return share.get('share.sharedTo.email') === email
  //   })
  //   const handleClick = () => {
  //     this.setState({ isTogglingFollow: true })
  //     const promise = shareEntity ? shareEntity.delete() : entity.addShare({ email })
  //     promise.finally(() => this.setState({ isTogglingFollow: false }))
  //   }
  //   return (
  //     <Button
  //       className='pt-minimal'
  //       data-debug-id='openSharePopoverButton'
  //       isLoading={isTogglingFollow}
  //       onClick={handleClick}
  //     >
  //       {shareEntity ? 'Unfollow' : 'Follow'} {entity.entityType}
  //     </Button>
  //   )
  // }

  private renderButton() {
    const { buttonText } = this.props
    // TODO(louis): implement size={size} when we refactor input
    return (
      <Button
        data-debug-id='openSharePopoverButton'
        className={Classes.INTENT_PRIMARY}
        onClick={this.handleOpenShareModal}
      >
        {buttonText}
      </Button>
    )
  }

  private renderAvatarRow() {
    const { plusButtonClassName, entity, size} = this.props
    const shares = this.dataSet.entities || []
    return (
      <div className='flex'>
        <div className='flex u-alignItemsCenter'>
          {/* <Button
            className='c-button--square u-bumperRight--xxs'
            size={size}
          >
            <Icon
              icon={IconNames.OFFICE}
            />
          </Button> */}
          {this.renderAvatars(shares, 5)}
          {this.renderOverflowAvatar(shares, 5)}
          <Button
            className={classNames('c-button--square c-shareBar-button', plusButtonClassName)}
            onClick={this.handleOpenShareModal}
            size='small'
          >
            <Icon icon={IconNames.PLUS} />
          </Button>
        </div>
      </div>
    )
  }

  private renderOverflowAvatar(shares, maxNumber) {
    if (_.isEmpty(shares) || shares.length <= maxNumber) {
      return
    }
    const { size } = this.props
    const overflowValue = shares.length - maxNumber
    return (
      <div
        className='c-share'
        key='overflowAvatar'
      >
        <Avatar
          size={size}
          overflowValue={overflowValue}
        />
      </div>
    )
  }

  private renderAvatars(shares, maxNumber) {
    if (_.isEmpty(shares)) {
      return
    }
    const { size } = this.props
    return _.map(shares.slice(0, maxNumber), (share: any) => {
      const sharedToEmail = share.get('share.sharedTo.email', '')
      const sharedToName = share.get('share.sharedTo.user.displayName', '')
      const sharedToDisplayName = _.isEmpty(sharedToName) ? sharedToEmail : sharedToName
      return (
        <div
          className='c-shareBar-item'
          key={share.uniqueId}
        >
          <Avatar
            name={sharedToDisplayName}
            email={sharedToEmail}
            size={size}
          />
          {/* Notifications Off */}
        </div>
      )
    })
  }

  private createEntityDataSource(entity) {
    if (this.dataSet) {
      this.dataSet.dispose()
    }
    this.dataSet = new EntityDataSource({
      entityType: SHARE_SCHEMA_URI,
      filters: [{
        path: 'share.entity',
        type: 'matchEdge',
        value: { entityId: entity.uniqueId },
      }],
    }).setOnChange(() => this.forceUpdate())
  }

  private handleOpenShareModal = () => {
    const { entity, isInvite, showShareAccessSettingsInModal, showRecipientsList, modalTitle } = this.props
    const edge = {
      displayName: entity.displayName,
      entityId: entity.uniqueId,
    }
    const promises = [
      getBusinessEmails(edge, 'Contacts'),
      getBusinessContacts(edge, 'Contacts'),
    ]
    const shares = this.dataSet.entities
    Promise.all(promises).then((results: any[]) => {
      const contacts = _.reduce(results, (a1, a2) => a1.concat(a2), [])
      if (!isInvite) {
        ShareConfigurationModal.open({
          entities: [entity],
          onSend: this.handleShare,
          shares: shares.slice(),
          showRecipientsList,
          showShareAccessSettings: showShareAccessSettingsInModal,
          suggestedRecipients: contacts,
          title: modalTitle,
        })
      } else {
        const store = apis.getStore()
        const entitySchema = store.getRecord(CARRIER_PORTAL_INVITE_URI)
        const contextProps = {
          density: 'collapse',
          isHorizontalLayout: true,
        }
        EntityFormPanel.open({
          defaultValue: {
            carrierPortalInvite: { carrier: edge },
          },
          schema: entitySchema,
          uiContext: contextProps,
          uiSchemaPath: 'uiSchema.web.entityCreationModal',
        })
      }
    })
  }

  private handleShare = (recipients, message) => {
    const { entity } = this.props
    const shares = _.map(recipients, (recipient) => {
      const share = getDeferredUser(recipient)
      return entity.addShare(share, message)
    })
    return Promise.all(shares)
  }
}
