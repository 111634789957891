import _ from 'lodash'
import React, { Component } from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { DragTypes } from 'browser/components/atomic-elements/organisms/entity/bulk-add/drag-types'
import Color from 'color'
import { DropTarget } from 'react-dnd'

const GREEN = '#2e7d32'
const GRAY = '#9e9e9e'

const fieldDropHandlers = {
  drop(props, monitor) {
    props.handleNewColumnDrop(monitor.getItem().field)
  },
}

interface INewColumnDropTargetProps extends IBaseProps {
  connectDropTarget: any
  isOver: boolean
  isSourceDragging: boolean
  field: { id; label; required }
  handleNewColumnDrop: () => void
}

interface INewColumnDropTargetState {
  isHovered: boolean
}

class InnerNewColumnDropTarget extends Component<INewColumnDropTargetProps, INewColumnDropTargetState> {
  public render() {
    const { field, connectDropTarget, isOver, isSourceDragging } = this.props
    const style = {
      alignItems: 'center',
      background: isSourceDragging ? Color(GREEN).alpha(isOver ? 0.25 : 0.1) : '#FFF',
      border: `1px dashed ${isSourceDragging ? GREEN : GRAY}`,
      borderRadius: '3px',
      cursor: field ? 'grab' : 'default',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0.5rem',
    }
    Object.assign(style, this.props.style)

    return connectDropTarget(
      <div style={style}>
        <div style={{ textAlign: 'center' }}>Drop here to add new column</div>
      </div>,
    )
  }
}

function mapDropStateToProps(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isSourceDragging: monitor.canDrop(),
  }
}

const NewColumnDropTarget = DropTarget(
  DragTypes.FIELD, fieldDropHandlers, mapDropStateToProps)(InnerNewColumnDropTarget)
export { NewColumnDropTarget }
