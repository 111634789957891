import _ from 'lodash'
import React from 'react'
import { browserHistory } from 'browser/history'

import './_mobile-workflow-list.scss'

import { StoryboardPlanEntity } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { filterEntityTypes } from 'shared-libs/models/entity-type-selection-filter'
import { normalizeUrlQuery } from 'shared-libs/helpers/deeplink'
import { Collection } from 'shared-libs/models/collection'
import { SchemaUris } from 'shared-libs/models/schema'
import { Entity } from 'shared-libs/models/entity'
import { Query } from 'shared-libs/models/query'
import apis from 'browser/app/models/apis'

import { IRenderListItemProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import { TruckLoader } from '../components/truck-loader/truck-loader'
import { List } from 'browser/components/atomic-elements/atoms/list'
import { getCurrentGeoPosition } from 'browser/app/utils/utils'
import { queryActiveExecution } from './util'
import { DEFAULT_ORDERS } from 'shared-libs/models/store'
import { queryWorkflowPlans } from 'shared-libs/models/types/storyboard/storyboard-utils'
import { ComponentsContext, IComponentsContext } from 'browser/contexts/components/components-context'
import { withContext } from 'shared-libs/components/context/with-context'


interface ContextProps {
  componentsContext?: IComponentsContext
}

type IMobileWorkflowSearchProps = ContextProps // & PublicProps - don't exist for this component yet


interface IMobileWorkflowSearchState {
  isLoading: boolean
  plans: Entity[]
  error?: any
}

@withContext(ComponentsContext, 'componentsContext')
export class MobileWorkflowList extends React.PureComponent<IMobileWorkflowSearchProps, IMobileWorkflowSearchState> {

  private store = apis.getStore()
  private settings = apis.getSettings()
  private collection: Collection

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      plans: [],
    }
  }

  public componentDidMount() {
    this.init()
      .then(() => {
        this.setState({ isLoading: false })
      })
      .catch((error) => {
        this.setState({ isLoading: false, error })
      })
  }

  public componentWillUnmount(): void {
    this.collection?.dispose()
  }

  public render() {
    const { isLoading, plans } = this.state
    if (isLoading) {
      return <TruckLoader />
    } else if (_.isEmpty(plans)) {
      return this.renderEmptyState()
    } else {
      return this.renderWorkflows()
    }
  }

  private renderEmptyState() {
    return (
      <div className="u-bumper--lg">
        <p>No available workflows.</p>
      </div>
    )
  }

  private renderWorkflows() {
    const { plans } = this.state
    return (
      <List
        className="mobile-workflow-list"
        showItemDeleteButton={false}
        renderListItem={this.renderWorkflowItem}
        value={plans}
      />
    )
  }

  private renderWorkflowItem = (props: IRenderListItemProps<Entity>) => {
    const { item } = props
    return (
      <div className="mobile-workflow-list-item" onClick={() => this.handleItemClick(item)}>
        {item.displayName}
      </div>
    )
  }

  private async handleItemClick(schema: Entity) {
    const execution = await this.createExecution(schema)
    this.navigateToExecution(execution)
  }

  private async init() {
    const resumeEnabled = this.settings.resumeStoryboardExecutionOnAppActive
    const activeExecution: Entity | undefined = resumeEnabled
      ? await queryActiveExecution()
      : undefined

    if (activeExecution) {
      this.navigateToExecution(activeExecution)
    } else {
      const plans = await this.queryWorkflowPlans()
      this.setState({ plans })

      this.maybeAutoCreate(plans)
    }
  }


  private async queryWorkflowPlans(): Promise<Entity[]> {
    const [userPlans, geofencedPlans] = await Promise.all([
      queryWorkflowPlans(apis),
      this.queryGeofencedPlans(),
    ])
    const plans = _.uniqBy([...userPlans, ...geofencedPlans], 'uniqueId')

    // filter out plans that don't have any stories for the current user
    const actionablePlans = _.filter(plans, (plan) => {
      const planModel: StoryboardPlanEntity = _.get(plan, 'core_storyboard_plan')
      const stories = planModel.getStoriesForCurrUser(this.props.componentsContext.platform, { isCreation: true })
      return !_.isEmpty(stories)
    })

    // pre-fetch execution schemas
    const executionIds = _.uniq(_.map(actionablePlans, 'core_storyboard_plan.execution.entityId'))
    await this.store.findRecords(executionIds)

    // filter plan types
    return filterEntityTypes(actionablePlans, this.settings, {
      _,
      settings: this.settings,
    })
  }

  private async queryGeofencedPlans(): Promise<Entity[]> {
    try {
      const position = await getCurrentGeoPosition()
      const { longitude, latitude } = position.coords
      const plans = await apis.searchStoryboardPlan({ longitude, latitude })
      return this.store.resolveRecords(plans, true)
    } catch (e) {
      return []
    }
  }

  private createExecution(schema: Entity): Entity {
    const execution = this.store.createRecord(schema, {})

    // apply defaults from jsonProps
    const props = normalizeUrlQuery(location.search)
    const { preFilledValues = {}, entityOptions = {} } = props
    const defaultValues = { preFilledValues, entityOptions }
    execution.withDefaultValues(defaultValues)
    return execution.save().catch((error) => {
      this.setState({ error })
    })
  }

  private async maybeAutoCreate(plans: Entity[]) {
    const plan = _.first(plans)
    if (plans.length === 1 && plan.shouldAutoCreate ) {
      const execution = await this.createExecution(plan)
      this.navigateToExecution(execution)
    }
  }

  private navigateToExecution(execution: Entity) {
    browserHistory.push({ pathname: `/workflow/${execution.uniqueId}` })
  }
}
