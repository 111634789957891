import _ from 'lodash'
import React from 'react'
import Favicon from 'react-favicon'

import { MobileRootNavigator } from './mobile-root-navigator'
import { MobileTitle } from './mobile-title'

import './_mobile-web-app.scss'

const VectorFavicon = require('images/faviconVector.png')

export const MobileWebApp = () => {
  return (
    <MobileTitle>
      <div className="mobile-web-app" data-reactroot={true}>
        <Favicon url={VectorFavicon} />
        <MobileRootNavigator />
      </div>
    </MobileTitle>
  )
}
