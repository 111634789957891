import _ from 'lodash'
import React, { Component } from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { LinkDriverDialog } from 'browser/components/domain/user/link-driver-dialog'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { hasMixinByIds } from 'shared-libs/helpers/utils'
import { Entity } from 'shared-libs/models/entity'
import { SchemaIds } from 'shared-libs/models/schema'
import Promise from 'bluebird'

interface ILinkDriverButtonState {
  loading: boolean
}

/**
 * @uiComponent
 */
interface ILinkDriverButtonProps extends IBaseProps {
  targetUser: Entity
}

export class LinkDriverButton extends Component<ILinkDriverButtonProps, ILinkDriverButtonState> {

  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }
  }

  public render() {
    if (this.state.loading) {
      return <LoadingSpinner/>
    }
    if (!hasMixinByIds(this.props.targetUser.activeMixins, new Set([SchemaIds.DRIVER_CONTACT]))) {
      const handleOnClick = this.handleLinkDriver.bind(this)
      return (
        <Button buttonText='Link To Driver' onClick={handleOnClick} />
      )
    } else {
      const handleOnClick = this.handleUnlinkDriver.bind(this)
      return (
        <Button buttonText='Unlink Driver' onClick={handleOnClick} />
      )
    }
  }

  private handleLinkDriver = () => {
    const { targetUser } = this.props
    LinkDriverDialog.open({ targetUser })
  }

  private handleUnlinkDriver = () => {
    const { targetUser } = this.props
    const contactMixin = {
      entityId: SchemaIds.DRIVER_CONTACT,
    }
    const dispatchMixin = {
      entityId: SchemaIds.DRIVER_DISPATCH_APP,
    }
    _.pullAllBy(targetUser.activeMixins, [contactMixin, dispatchMixin], 'entityId')
    targetUser.set('mixins.active', targetUser.activeMixins)
    _.unset(targetUser, 'driverContact')
    this.setState({
      loading: true
    });
    this.handleSave(targetUser)
  }

  private handleSave(targetUser) {
    targetUser.save().then(() => {
      this.setState({
        loading: false
      });
    })
  }
}
