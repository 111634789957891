import _ from 'lodash'

import { convertTimezone } from '../../helpers/utils'
import { Entity } from '../entity'
import { AbstractTriggers } from './abstract-triggers'
import { EntityType } from './entity-type'

export class SalesOrder extends EntityType implements AbstractTriggers {

  public beforeChangeTrigger(entity: Entity, path: any, oldValue: any, newValue: any, silentUpdate = false) {
    this.updateStopsTimeWindowTimezone(entity, path, newValue, silentUpdate)
  }

  public validateTrigger(entity: Entity, errors: any[]) {
    // do nothing
  }

  /////////////////////////////////////////////////////////////////////////////
  // Triggers
  /////////////////////////////////////////////////////////////////////////////

  private updateStopsTimeWindowTimezone(entity: Entity, path, location, silentUpdate): void {
    if (_.isArray(path)) { path = path.join('.') }
    const timezoneId = _.get(location, ['denormalizedProperties', 'location.address', 'timezoneId'])
    const pathRegex = /core_fulfilment_salesOrder.stops.([\d]+).location/
    if (!location || !timezoneId || !pathRegex.test(path)) {
      return
    }
    const matches = pathRegex.exec(path)
    const stopIndex = matches[1]
    this.updateTimeWindow(entity, timezoneId, stopIndex, 'start', silentUpdate)
    this.updateTimeWindow(entity, timezoneId, stopIndex, 'end', silentUpdate)
  }

  private updateTimeWindow(entity: Entity, timezoneId, stopIndex, suffix: 'start' | 'end', silentUpdate): void {
    const path = `core_fulfilment_salesOrder.stops.${stopIndex}.timeWindow.${suffix}`
    const endpoint = entity.get(path)
    if (!endpoint) {
      return
    }
    const dateTime = convertTimezone(endpoint.dateTime, endpoint.timezone, timezoneId)
    const value = { dateTime, timezone: timezoneId }
    if (silentUpdate) {
      entity.commit(path, value)
    } else {
      entity.set(path, value)
    }
  }
}
