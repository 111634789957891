import _ from 'lodash'
import React from 'react'

import { IRenderListItemProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import {
  HtmlTable,
  IHtmlTableProps,
} from 'browser/components/atomic-elements/atoms/table/html-table'
// tslint:disable-next-line:max-line-length
import { InlineEditableHtmlTableRow } from 'browser/components/atomic-elements/atoms/table/html-table/inline-editable-row'
import { createListItemElement } from 'browser/components/json-elements/atoms/list'

const UIHtmlTableRow = InlineEditableHtmlTableRow

/**
 * @uiComponent
 */
interface IUIHtmlTableProps extends IHtmlTableProps {
  frames: any
}

class UIHtmlTable extends React.Component<IUIHtmlTableProps, any> {
  public render() {
    return <HtmlTable {...this.props} renderListItem={this.renderListItem} />
  }

  private renderListItem = (props: IRenderListItemProps) => {
    const { frames } = this.props
    return createListItemElement({ frames, ...props })
  }
}

export { UIHtmlTable, UIHtmlTableRow }
