import _ from 'lodash'
import React from 'react'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { PickerWheel } from '../picker-wheel/picker-wheel'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { FormGroupContentWrapper } from 'browser/components/atomic-elements/atoms/form-group-content-wrapper/form-group-content-wrapper'
import { PickerWheelGroup } from '../picker-wheel/picker-wheel-group'
import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

interface IMobileTimezonePickerProps extends IBaseProps {
  onChange: (timezoneId: string) => void
  errorText?: string
  isDisabled?: boolean
  isHorizontalLayout?: boolean
  options: string[]
  /** A mapping of zone id to a friendly name. */
  zoneIdToName?: Record<string, string>
  value: string
}

interface IMobileTimezonePickerState {
  timezoneIds: string[]
  timezoneLabels: string[]
  timezoneAbbrs: string[]
  selectedTimezoneIdx: number
}

export class MobileTimezonePicker extends React.Component<
  IMobileTimezonePickerProps,
  IMobileTimezonePickerState
> {
  static defaultProps: Partial<IMobileTimezonePickerProps> = {
    options: [
      'America/Los_Angeles',
      'America/Phoenix',
      'America/Denver',
      'America/Chicago',
      'America/New_York',
    ],
    // 
    zoneIdToName: {
      'America/Los_Angeles': 'Pacific',
      'America/Phoenix': 'Arizona',
      'America/Denver': 'Mountain',
      'America/Chicago': 'Central',
      'America/New_York': 'Eastern',
    },
  }

  constructor(props: IMobileTimezonePickerProps) {
    super(props)
    const { value, options } = props

    const knownTimezoneIds = new Set(moment.tz.names())

    // filter out unknown timezoneIds
    const timezoneIds = options.filter((timezoneId, idx) => {
      const isKnown = knownTimezoneIds.has(timezoneId)
      if (!isKnown) {
        console.warn(`Ignoring unknown timezoneId at options[${idx}]: ${timezoneId}.`)
      }
      return isKnown
    })

    const timezoneLabels = timezoneIds.map(this.getTimezoneIdLabel)
    const timezoneAbbrs = timezoneIds.map((timezoneId) => moment.tz(timezoneId).format('z'))

    const timezoneIdIndex = timezoneIds.indexOf(value)
    const selectedTimezoneIdx = timezoneIdIndex !== -1 ? timezoneIdIndex : 0

    this.state = {
      timezoneIds,
      timezoneLabels,
      timezoneAbbrs,
      selectedTimezoneIdx,
    }
  }

  public componentDidUpdate(prevProps: Readonly<IMobileTimezonePickerProps>): void {
    const { value, options: timezoneIdOptions } = this.props

    if (prevProps.value !== value) {
      const idx = timezoneIdOptions.indexOf(value)

      this.setState({
        selectedTimezoneIdx: idx !== -1 ? idx : 0,
      })
    }
  }

  public render() {
    const { errorText, isDisabled, isHorizontalLayout, className } = this.props
    const { selectedTimezoneIdx, timezoneAbbrs } = this.state

    const selectedTimezoneLabel = timezoneAbbrs[selectedTimezoneIdx]

    return (
      <FormGroupContentWrapper
        hasError={!_.isEmpty(errorText)}
        isHorizontalLayout={isHorizontalLayout}
        isDisabled={isDisabled}
        className={className}
      >
        <TetherTarget
          automaticAdjustOffset={true}
          isEnabled={!isDisabled}
          openOnClick={false}
          openOnFocus={true}
          closeOnOutsideClick={false}
          tethered={this.renderTimezonePickerPopover()}
        >
          <div className={classNames('flex-grow', className)}>
            <Button className={classNames(Classes.MINIMAL)}>
              <div className="flex items-center">
                <span>{selectedTimezoneLabel}</span>
                <Icon icon={IconNames.CHEVRON_DOWN} />
              </div>
            </Button>
          </div>
        </TetherTarget>
      </FormGroupContentWrapper>
    )
  }

  private renderTimezonePickerPopover() {
    const { timezoneLabels } = this.state
    const { selectedTimezoneIdx } = this.state

    const selectedLabel = timezoneLabels[selectedTimezoneIdx]

    return (
      <Popover className={classNames('collapse', this.props.className)}>
        <PickerWheelGroup>
          <PickerWheel
            curvature={0}
            items={timezoneLabels}
            selectedItem={selectedLabel}
            onItemSelected={this.handleChange}
            initialIndex={selectedTimezoneIdx}
          />
        </PickerWheelGroup>
      </Popover>
    )
  }

  private handleChange = (selectedTimezoneId: string, idx: number) => {
    const { onChange } = this.props

    this.setState({ selectedTimezoneIdx: idx })

    const timezoneId = this.props.options[idx]
    onChange(timezoneId)
  }

  private getTimezoneIdLabel = (timezoneId: string): string => {
    const { zoneIdToName } = this.props
    const name = zoneIdToName[timezoneId] || timezoneId
    const abbr = moment.tz(timezoneId).format('z')
    return `${name} (${abbr})`
  }
}
