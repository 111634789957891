import _ from 'lodash'
import { Icon, Tab, Tabs } from '@blueprintjs/core'
import Promise from 'bluebird'
import Immutable from 'immutable'
import React from 'react'

import { Store } from 'shared-libs/models/store'
import { getDocumentSchemas } from 'shared-libs/models/utils'

import { IconNames } from '@blueprintjs/icons'
import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { EntityFooter } from 'browser/components/atomic-elements/atoms/footer/entity-footer'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import 'browser/components/atomic-elements/organisms/entity/_flow.scss'
import { Logger } from 'browser/apis/logging'
import { Entity } from 'shared-libs/models/entity'
import { queryWorkflowPlans } from 'shared-libs/models/types/storyboard/storyboard-utils'
import { StoryboardPlanEntity } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { ComponentsContext, IComponentsContext } from 'browser/contexts/components/components-context'
import { withContext } from 'shared-libs/components/context/with-context'
import { LoadingSpinner } from '../../atoms/loading-spinner/loading-spinner'

/**
 * @uiComponent
 */
interface PublicProps extends IBaseProps {
  defaultValue: object
  entity: any
  errors: any
  frames: any
  onChange: (value: any) => void
  onClose: () => void
  onSave: () => Promise<any>
  pages: any
  settings: Settings
}

interface ContextProps {
  componentsContext?: IComponentsContext
}

type IDocumentCreationFlowProps = PublicProps & ContextProps

interface IDocumentCreationFlowState {
  documentSchemas: any[]
  isSaving: boolean
  selectedTabId: string
  isLoadingPlans?: boolean
}

@withContext(ComponentsContext, 'componentsContext')
export class DocumentCreationFlow extends React.Component<IDocumentCreationFlowProps, IDocumentCreationFlowState> {

  private store: Store
  private planPromise: Promise<any>

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      documentSchemas: [],
      isSaving: false,
      selectedTabId: 'documentSelection',
    }
  }

  public componentDidMount() {
    const { settings } = this.props
    const schemas = settings.getBundleSchemas()
    const docSchemas = getDocumentSchemas(this.store, schemas, 'web')

    this.setState({
      documentSchemas: docSchemas,
      isLoadingPlans: true
    })
    this.loadPlansAsync()
  }

  public componentWillUnmount() {
    this.planPromise?.cancel()
  }

  private async loadPlansAsync() {
    this.planPromise = queryWorkflowPlans(apis)
    const plans = await this.planPromise
    const actionablePlans = _.filter(plans, (plan) => {
      const planModel: StoryboardPlanEntity = _.get(plan, 'core_storyboard_plan')
      const stories = planModel.getStoriesForCurrUser(this.props.componentsContext.platform, { isCreation: true })
      return !_.isEmpty(stories)
    })

    // Can't hoist to the top, relies on being run after the promise above
    const { documentSchemas } = this.state
    const allSchemas = _.concat(documentSchemas, actionablePlans)
    const uniqSchemas = _.uniqBy(allSchemas, s => s.uniqueId)

    this.setState({
      documentSchemas: uniqSchemas,
      isLoadingPlans: false
    })
  }

  public render() {
    const { selectedTabId } = this.state
    return (
      <div className='c-flow'>
        <Tabs
          className='c-flowBody'
          id='documentFlow'
          onChange={this.handleTabChange}
          renderActiveTabPanelOnly={true}
          selectedTabId={selectedTabId}
        >
          <Tab
            className='grid-block vertical'
            id='documentSelection'
            panel={this.renderDocumentSelectionPage()}
          />
          <Tab
            className='grid-block vertical'
            id='documentCreation'
            panel={this.renderDocumentCreationPage()}
          />
          <Tabs.Expander />
        </Tabs>
        {this.renderFlowFooterControls()}
      </div>
    )
  }

  private handleTabChange = (selectedTabId) => {
    this.setState({ selectedTabId })
  }

  private handleLeftButtonClick = () => {
    const { onClose, pages } = this.props
    const { selectedTabId } = this.state
    const navigation = pages[selectedTabId].navigation
    if (onClose && !navigation.previousPage) {
      this.props.onClose()
    } else {
      this.setState({ selectedTabId: navigation.previousPage })
    }
  }

  private handleRightButtonClick = () => {
    this.setState({ isSaving: true })
    this.props.onSave().finally(() => {
      this.setState({ isSaving: false })
      this.logDocumentCreatedEvent(this.props.entity)
    })
  }

  private logDocumentCreatedEvent = (entity: any) => {
    if (_.isNil(entity)) {
      return
    }
    try {
      // best effort
      const mixins = entity.activeMixins
      const isDocumentType = _.some(mixins, (mixin) => mixin.entityId === '11111111-0000-0000-0000-000000000011')
      if (isDocumentType) {
        const uniqueId = entity.uniqueId
        const documentType = _.last(mixins)
        const documentName = entity.get('document.name')
        const numberOfFiles = _.size(entity.get('document.attachments.files'))

        Logger.logEvent('Upload document successful', { uniqueId, documentName, documentType, numberOfFiles})
      }
    } catch  (ex) {
      console.log('Unable to log document created event for ' + JSON.stringify(_.get(entity, 'content')))
    }
  }

  private handleSelectMetadata = (schema) => {
    const { defaultValue, onChange, pages } = this.props
    const { selectedTabId } = this.state
    const record = this.store.createRecord(schema, defaultValue)
    onChange(record)

    const navigation = pages[selectedTabId].navigation
    this.setState({ selectedTabId: navigation.nextPage })
  }

  private renderDocumentSelectionPage() {
    const { documentSchemas, isLoadingPlans } = this.state

    return (
      <div>
        <div className='ph3 pt3'>
          <Label className='c-label--heading'>
            Select document type
          </Label>
        </div>
        <div className='c-dropdownList'>
          {documentSchemas.map((schema, index) => (
            <div
              className='c-dropdownList-item'
              // tslint:disable-next-line:jsx-no-lambda
              onClick={() => this.handleSelectMetadata(schema)}
              data-debug-id={schema.title}
              key={index}
            >
              <div className='flex w-100'>
                <div className='w-100'>
                  {schema.title || _.get(schema, 'core_storyboard_plan.name') /* todo - add redirect property to schema class */}
                </div>
                <div>
                  <Icon
                    icon={IconNames.CHEVRON_RIGHT}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        {isLoadingPlans &&
          <div style={{ position: 'relative', height: '60px' }}>
            <LoadingSpinner
              label={"Loading available workflows..."}
            />
          </div>
        }
      </div>
    )
  }

  private renderDocumentCreationPage() {
    const { frames, entity } = this.props
    const uiSchemaPath = 'uiSchema.web.entityCreationSection'
    const renderer = frames.getContext('renderer')
    const resolved = entity.resolveSubschemaByPath(uiSchemaPath)
    const newFrames = frames.push(Immutable.Map({
      context: {
        uiContext: resolved.context.content,
        uiSchema: resolved.schema,
        uiSchemaPath: uiSchemaPath.split('.'),
      },
      key: uiSchemaPath,
      parent: frames,
    }))
    return (
      <div>
        {renderer.createElementFromFrame(newFrames)}
      </div>
    )
  }

  private renderFlowFooterControls() {
    const { pages, errors, entity } = this.props
    const { isSaving, selectedTabId } = this.state
    const navigation = pages[selectedTabId].navigation
    const { leftButtonText, rightButtonText } = navigation

    return (
      <EntityFooter
        cancelButtonText={leftButtonText}
        isCancelButtonDisabled={isSaving}
        isPrimaryButtonLoading={isSaving}
        isVisible={true}
        entity={entity}
        errors={selectedTabId == 'documentSelection' ? [] : errors}
        onCancelButtonClick={leftButtonText ? this.handleLeftButtonClick : null}
        onPrimaryButtonClick={rightButtonText ? this.handleRightButtonClick : null}
        primaryButtonText={rightButtonText}
      />
    )
  }
}
