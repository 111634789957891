import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Dropzone from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
// tslint:disable-next-line:max-line-length
import { FormGroupContentWrapper } from 'browser/components/atomic-elements/atoms/form-group-content-wrapper/form-group-content-wrapper'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { Thumbnail } from 'browser/components/atomic-elements/molecules/thumbnail/thumbnail'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import { ImageEditorModal } from 'browser/components/atomic-elements/organisms/image-editor-modal'
import 'browser/components/json-elements/atoms/file-input/_file-input.scss'

const dropdownTetherOptions = {
  attachment: 'top right',
  targetAttachment: 'bottom right',
}

/**
 * @uiComponent
 */
interface IImageInputProps extends IBaseProps {
  entity: any
  errors?: object[]
  emptyCueButtonText?: string
  emptyCueHelpText?: string
  frames?: any
  showItemDeleteButton?: boolean
  showOptionsButton?: boolean
  showAddButton?: boolean
  isHorizontalLayout?: boolean
  inputClassName?: string
  label?: string
  onChange: (value: object | object[]) => void
  showFileName?: boolean
  size?: string
  value: any[] | any
  valuePath?: string
}

// TODO (Grant) this class is largely duplicated from FileInput,
// need to break down these classes into composable, reusable parts

export class ImageInput extends React.Component<IImageInputProps, any> {

  public static defaultProps: Partial<IImageInputProps> = {
    emptyCueButtonText: 'Upload File',
    emptyCueHelpText: 'Drop an image, or click to select your file',
    showAddButton: true,
    showFileName: true,
    showOptionsButton: true,
    size: 'lg',
  }

  private dropzone: Dropzone

  public render() {
    const { className, errors, isHorizontalLayout, inputClassName } = this.props
    const errorText = errors && errors.join('\n')
    return (
      <FormGroupContentWrapper
        className={classNames(className, inputClassName, {
          'c-formGroupContentWrapper--noBottomBorder': !isHorizontalLayout,
        })}
        isHorizontalLayout={isHorizontalLayout}
        hasError={!_.isEmpty(errorText)}
      >
        <Dropzone
          data-debug-id='fileInput:Attachments'
          activeClassName='is-active'
          activeStyle={{}}
          className={classNames('c-fileInput', {
            'c-fileInput--error': !_.isEmpty(errorText),
            'c-fileInput--isHorizontalLayout': isHorizontalLayout,
            'c-fileInput--singleFile': true,
          })}
          disableClick={true}
          onDrop={this.handleDrop}
          ref={(ref) => { this.dropzone = ref }}
        >
          {({ isDragActive, isDragReject }) => {
            return this.renderContent(isDragActive, isDragReject)
          }}
        </Dropzone>
      </FormGroupContentWrapper>
    )
  }

  private renderContent(isDragActive, isDragReject) {
    const { showAddButton } = this.props
    let { value } = this.props
    value = value ? [value] : []
    let overlay = null
    if (isDragActive) {
      overlay = (
        <div className='c-fileInputOverlay'>
          <HelpBlock>
            Drop to attach files
          </HelpBlock>
        </div>
      )
    }
    if (isDragReject) {
      overlay = (
        <div className='c-fileInputOverlay'>
          <HelpBlock>
            Something doesn{"'"}t look right...
          </HelpBlock>
        </div>
      )
    }

    let content = null
    if (_.isEmpty(value)) {
      content = this.renderEmptyState()
    } else {
      content = this.handleRenderFilePreviewItem(_.first(value))
    }
    return (
      <div className='c-fileInputItems w-100'>
        {content}
        {overlay}
      </div>
    )
  }

  private renderDropdown(file) {
    const url = _.get(file, 'source[0].uri', '')
    const onClick = () => this.handleShowDeleteFilePrompt(file)
    const downloadPageLink = (
      <a
        className='c-dropdownList-item u-displayBlock'
        href={url}
        target='_blank'
        rel='noopener noreferrer'
        download={true}
      >
        Download page
      </a>
    )
    return (
      <Popover
        className='collapse'
      >
        <ul className='c-dropdownList'>
          {downloadPageLink}
          <a
            className='c-dropdownList-item u-displayBlock'
            href={file.uri}
            target='_blank'
            rel='noopener noreferrer'
            download={true}
          >
            Download original
          </a>
          <li className='c-list-divider' />
          <li
            className='c-dropdownList-item'
            onClick={onClick}
          >
            Delete page
          </li>
        </ul>
      </Popover>
    )
  }

  private renderEmptyState() {
    const { emptyCueHelpText, emptyCueButtonText, size } = this.props
    const handleClick = () => this.dropzone.open()
    return (
      <div className='pv3 tc'>
        <div className='mb3'>
          <HelpBlock>
            {emptyCueHelpText}
          </HelpBlock>
        </div>
        <Button
          className={Classes.iconClass(IconNames.UPLOAD)}
          onClick={handleClick}
        >
          {emptyCueButtonText}
        </Button>
      </div>
    )
  }

  private handleRenderFilePreviewItem = (file) => {
    const { size } = this.props
    const handleRemove = () => this.handleShowDeleteFilePrompt(file)
    const handlePreview = () => this.handleThumbnailClick(file)
    const preview = (
      <Thumbnail
        imageClassName='c-fileInputItemPreview c-fileInputItemPreview--image'
        onClick={handlePreview}
        file={file}
        size={size}
      />
    )
    return (
      <div
        className='c-fileInputItem-container pv2'
      >
        <div className='c-fileInputItemPreview-wrapper'>
          <div className='c-fileInputItemPreview-relative'>
            {preview}
          </div>
        </div>
        {this.renderFileName(file)}
        {this.renderItemDeleteButton(handleRemove)}
        {this.renderOptions(file)}
      </div>
    )
  }

  private renderFileName(file) {
    const { showFileName } = this.props

    if (showFileName) {
      return (
        <Label className='c-label--heading collapse u-ellipsis w-100 ml3 c-fileInputItem-name'>
          {file.name}
        </Label>
      )
    }
  }

  private renderOptions(file) {
    const { showOptionsButton } = this.props
    if (showOptionsButton) {
      return (
        <TetherTarget
          closeOnPortalClick={true}
          tethered={this.renderDropdown(file)}
          tetherOptions={dropdownTetherOptions}
        >
          <div className={classNames(Classes.BUTTON, Classes.MINIMAL)}>
            <Icon
              icon={IconNames.MORE}
            />
          </div>
        </TetherTarget>
      )
    }
  }

  private renderItemDeleteButton(onClick) {
    const { showItemDeleteButton } = this.props
    if (showItemDeleteButton) {
      return (
        <Button
          className={Classes.MINIMAL}
          onClick={onClick}
        >
          <Icon icon={IconNames.CROSS} />
        </Button>
      )
    }
  }

  private handleDrop = (files) => {
    if (_.isEmpty(files)) {
      return
    }
    const { entity, onChange, valuePath } = this.props
    const multipartFiles = []
    const filesList = []
    files.forEach((file) => {
      const uniqueId = uuidv4()
      multipartFiles.push({
        file,
        uniqueId,
      })
      filesList.push({
        name: file.name,
        type: 'image',
        uniqueId,
        uri: file.preview,
      })
    })
    onChange(_.first(filesList))
    entity.addMultipartFiles(valuePath, multipartFiles)
  }

  private handleRemoveFile = (file) => {
    const { entity, value, onChange } = this.props
    entity.removeMultipartFileByIds(file.uniqueId)
    onChange(undefined)
  }

  private handleShowDeleteFilePrompt = (file) => {
    ConfirmationModal.open({
      confirmationText: 'Do you want to delete this image?',
      confirmationTitle: 'Review Changes',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: () => this.handleRemoveFile(file),
      primaryButtonText: 'Confirm',
    })
  }

  private handleThumbnailClick = (file) => {
    const { frames, entity } = this.props
    const fileType = _.get(file, 'type', null)

    // Frame is passed in by entity renderer, if it is not available,
    // don{"'"}t allow opening ImageEditorModal
    if (!frames) { return }

    const imagesPath = frames.getContext('valuePath').join('.')
    ImageEditorModal.open({
      entity,
      imagesPath,
      onClose: () => this.forceUpdate(),
    })
  }
}
