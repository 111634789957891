import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'

import 'browser/app/pages/app/views/_print.scss'
import { createEntityTableColumns } from 'shared-libs/components/table/utils'
import { evaluateExpression } from 'shared-libs/helpers/evaluation'
import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { browserHistory } from 'browser/history'
import { PrintConfigurationModal } from './print-configuration-modal'

interface IPrintableTableProps {
  dataSets: any
  viewState: any

  match: any
}

interface IPrintableTableState {
  isLoading: boolean
  printDataSets: any
  columns?: any
  config: any
}

export class PrintableTable extends React.Component<IPrintableTableProps, IPrintableTableState> {
  public constructor(props: IPrintableTableProps) {
    super(props)

    this.state = {
      isLoading: true,
      printDataSets: this.getPrintDataSets(props.dataSets),
      config: this.getPrintConfig(),
    }

    createEntityTableColumns(apis, this.printDataset.entitySchema, props.viewState.columns).then(
      (columns) => {
        this.setState({
          columns,
        })
      }
    )
  }

  public getPrintConfig() {
    return {
      zoom: 100,
      title: undefined,
    }
  }

  public getPrintDataSets(dataSets) {
    const ret = {}

    Object.entries(dataSets).forEach(([dataKey, entityDataSource]: any) => {
      const overrideMetadata = _.assign(_.cloneDeep(entityDataSource.query.metadata), {
        size: 999,
        maxSizePerGroup: 99,
      })

      const overrides = {
        refreshInterval: undefined,
        metaData: overrideMetadata,
      }

      const updatedDataSet = entityDataSource.clone(overrides).setOnChange(this.datasetDidLoad)
      updatedDataSet.find()

      ret[dataKey] = updatedDataSet
    })

    return ret
  }

  public datasetDidLoad = () => {
    this.setState({
      isLoading: this.hasAnyLoadingDataSets(),
    })
  }

  public hasAnyLoadingDataSets(): boolean {
    return _.some(this.state.printDataSets, (dataSet) => dataSet.isLoading)
  }

  public handleReturnToTable = () => {
    browserHistory.push({
      pathname: this.props.match.url,
    })
  }

  public handleOpenConfigurationModal = () => {
    const { config } = this.state

    PrintConfigurationModal.open({
      onConfirm: this.handleConfigurationChange,
      config,
    })
  }

  public handleConfigurationChange = (config) => {
    this.setState({
      config,
    })
  }

  public render() {
    const { isLoading, columns, config } = this.state

    if (isLoading || _.isEmpty(columns)) {
      return <div>Loading...</div>
    }

    const groups = this.printDataset.groups || []
    const content = this.printDataset.content
    const { title } = config

    return (
      <div style={{ width: '100%' }}>
        <div className="u-bumper u-noPrint">
          <Button onClick={this.handleReturnToTable}>Back</Button>

          <Button
            onClick={this.handleOpenConfigurationModal}
            className={classNames('u-bumperLeft', Classes.INTENT_PRIMARY)}
          >
            Configure
          </Button>
        </div>

        {title ? (
          <div className="u-bumperBottom--sm" style={{ fontWeight: 'bold' }}>
            {title}
          </div>
        ) : null}

        <div className="c-printTable-table" style={{ zoom: `${config.zoom}%` }}>
          <div className="c-printTable-row">{this.renderHeader()}</div>
          {this.renderRows(content, groups, 0)}
        </div>
      </div>
    )
  }

  public renderHeader() {
    const { columns } = this.state
    const totalColumnWidth = _.sum(_.map(columns, (c) => c.width || 100))

    return _.map(columns, (column) => {
      return (
        <div
          className="c-printTable-cell c-printTable-header "
          style={{ flex: column.width / totalColumnWidth }}
        >
          {column.label}
        </div>
      )
    })
  }

  public get printDataset(): any {
    const { printDataSets } = this.state

    // assume ONLY 1 data set for a table view
    return _.first(_.values(printDataSets))
  }

  public getConditionalStyles(column, value) {
    const accStyles = {}
    const { conditions, conditionStyles } = column.cellComponent || {}

    if (!conditions || !conditionStyles) {
      return accStyles
    }

    _.zip(conditions, conditionStyles).forEach(([cond, styles]: any[]) => {
      const expression = cond.value

      if (evaluateExpression(value, expression)) {
        _.assign(accStyles, styles)
      }
    })

    return accStyles
  }

  public renderRows(rows, groups, groupIdx) {
    const { columns } = this.state

    const totalColumnWidth = _.sum(_.map(columns, (c) => c.width || 100))

    return _.map(rows, (row, idx) => {
      const group = groups[groupIdx]

      if (group) {
        return (
          <span className="c-printTable-groupedSection">
            <div className="c-printTable-row" style={{ backgroundColor: '#f8f8f8' }}>
              <div className="c-printTable-cell c-printTable-group">
                <Icon icon={IconNames.CHEVRON_DOWN} className="u-innerBumperTop--xxs" />

                {row.data.displayName}
              </div>
            </div>
            {this.renderRows(row.children, groups, groupIdx + 1)}
          </span>
        )
      }

      const cells = columns.map((column, colIdx) => {
        const data = { data: row.data }
        const rawValue = column.path === '.' ? data : _.get(data, column.path)
        const value = column.formatter ? column.formatter(rawValue) : rawValue
        const paddingLeft = groupIdx > 0 && colIdx === 0 ? 8 + 16 * groupIdx : 0

        return (
          <div className="c-printTable-cell" key={colIdx} style={{ flex: column.width / totalColumnWidth }}>
            <div style={{ paddingLeft }}>
              <div style={{ ...this.getConditionalStyles(column, data), width: 'fit-content' }}>
                {value}
              </div>
            </div>
          </div>
        )
      })

      return <div className="c-printTable-row" key={idx}>{cells}</div>
    })
  }
}
