import classNames from 'classnames'
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { Motion, presets, spring } from 'react-motion'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import 'browser/components/atomic-elements/organisms/chat-bar/_chat-bar.scss'
import { ChatPanel } from 'browser/components/atomic-elements/organisms/chat-panel/chat-panel'

// Sync with $c-chatBar-width
const CHAT_BAR_WIDTH = 205

interface IChatBarProps extends IBaseProps {
  isVisible?: boolean
}

export class ChatBar extends React.Component<IChatBarProps, any> {

  public static defaultProps: Partial<IChatBarProps> = {
    isVisible: false,
  }

  constructor(props) {
    super(props)
    const { isVisible } = this.props
    const initialWidth = isVisible ? CHAT_BAR_WIDTH : 0
    this.state = {
      animation: {
        from: {
          width: initialWidth,
        },
        to: {
          width: initialWidth,
        },
      },
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isVisible !== nextProps.isVisible) {
      const { animation } = this.state
      const nextAnimation = {
        from: animation.from,
        to: {
          width: spring(nextProps.isVisible ? CHAT_BAR_WIDTH : 0),
        },
      }
      this.setState({ animation: nextAnimation })
    }
  }

  public render() {
    const { animation } = this.state
    return (
      <Motion
        defaultStyle={animation.from}
        style={animation.to}
      >
        {(style) => this.renderContent(style)}
      </Motion>
    )
  }

  private renderContent(style) {
    const { className, children } = this.props
    return (
      <div
        className={classNames('grid-block shrink vertical c-chatBar', className)}
        style={style}
      >
        <div className='c-chatBar-inner'>
          {/*{this.renderTicker()} */}
          {this.renderChat()}
        </div>
      </div>
    )
  }

  private renderTicker() {
    return (
      <Scrollbars
        autoHide={true}
        className='grid-block u-borderBottom'
      >
        <div className='grid-content collapse'>
          <div className='u-borderBottom u-innerBumperTop--xs u-innerBumperBottom--xs u-bumperLeft u-bumperRight'>
            <HelpBlock>
              Order Update
            </HelpBlock>
            <p>Order: 8748129</p>
          </div>
          <div className='u-borderBottom u-innerBumperTop--xs u-innerBumperBottom--xs u-bumperLeft u-bumperRight'>
            <HelpBlock>
              Order Update
            </HelpBlock>
            <p>Order: 1923879</p>
          </div>
          <div className='u-borderBottom u-innerBumperTop--xs u-innerBumperBottom--xs u-bumperLeft u-bumperRight'>
            <HelpBlock>
              Order Update
            </HelpBlock>
            <p>Order: 129837</p>
          </div>
          <div className='u-borderBottom u-innerBumperTop--xs u-innerBumperBottom--xs u-bumperLeft u-bumperRight'>
            <HelpBlock>
              Order Task
            </HelpBlock>
            <p>Carrier Rep	to cover load</p>
          </div>
          <div className='u-borderBottom u-innerBumperTop--xs u-innerBumperBottom--xs u-bumperLeft u-bumperRight'>
            <HelpBlock>
              Order Task
            </HelpBlock>
            <p>Customer Rep	for accessorial approval</p>
          </div>
          <div className='u-borderBottom u-innerBumperTop--xs u-innerBumperBottom--xs u-bumperLeft u-bumperRight'>
            <HelpBlock>
              Order Task
            </HelpBlock>
            <p>Carrier Rep / Carrier Dispatcher	to upload POD</p>
          </div>
          <div className='u-borderBottom u-innerBumperTop--xs u-innerBumperBottom--xs u-bumperLeft u-bumperRight'>
            <HelpBlock>
              Order Task
            </HelpBlock>
            <p>Accounting	to issue Invoice</p>
          </div>
          <div className='u-borderBottom u-innerBumperTop--xs u-innerBumperBottom--xs u-bumperLeft u-bumperRight'>
            <HelpBlock>
              Order Task
            </HelpBlock>
            <p>Customer Rep	to reach out to customers with previous moves on lane</p>
          </div>
          <div className='u-borderBottom u-innerBumperTop--xs u-innerBumperBottom--xs u-bumperLeft u-bumperRight'>
            <HelpBlock>
              Order Task
            </HelpBlock>
            <p>Carrier Rep	to track load</p>
          </div>
        </div>
      </Scrollbars>
    )
  }

  private renderChat() {
    return (
      <div className='grid-block'>
        <Scrollbars
          autoHide={true}
          className='c-sideNavigationBar-block c-sideNavigationBar-block--shrink'
        >
          <div className='c-label c-label--secondary c-sideNavigationBar-sectionTitle'>
            CHANNELS (4)
          </div>
          <div className='c-chatRow'>
            # fresnoyard
          </div>
          <div className='c-chatRow'>
            # drivers
          </div>
          <div className='c-chatRow'>
            # all
          </div>

          <div className='c-label c-label--secondary c-sideNavigationBar-sectionTitle'>
            DIRECT MESSAGES (11)
          </div>
          <div className='c-chatRow' onClick={this.handleShowChatPanel}>
            <div className='c-chatRow-circle is-online' />
            Mike Brunner
          </div>
          <div className='c-chatRow' onClick={this.handleShowChatPanel}>
            <div className='c-chatRow-circle is-online' />
            Jenny Klovic
          </div>
          <div className='c-chatRow'>
            <div className='c-chatRow-circle' />
            Jim Smith
          </div>
          <div className='c-chatRow'>
            <div className='c-chatRow-circle' />
            Sharon Wu
          </div>

          <div className='c-label c-label--secondary c-sideNavigationBar-sectionTitle'>
            RECENT THREADS (3)
          </div>
          <div className='c-chatRow'>
            Order 123123
          </div>
          <div className='c-chatRow'>
            Carrier: Starling Trucking
          </div>
          <div className='c-chatRow'>
            Order 12908371
          </div>
        </Scrollbars>
      </div>
    )
  }

  private handleShowChatPanel = () => {
    ChatPanel.open(this.props)
  }
}
