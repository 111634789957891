import _ from 'lodash'

import { EntityType } from './entity-type'

export class Invite extends EntityType {

  public accept = () => {
    const entity = this.entity
    const url = `${this.api.getHost()}/1.0/entities/actions/system/user/invite/accept`
    return this.api.postJSON(url, { inviteId: entity.get('uniqueId') })
  }

  public cancel = () => {
    const entity = this.entity
    return entity.delete()
  }

  public resend = () => {
    const entity = this.entity
    const entityId = entity.get('uniqueId')
    const url = `${this.api.getHost()}/1.0/entities/actions/system/invite/resend`
    return this.api.postJSON(url, { entityId })
  }
}
