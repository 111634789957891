import _ from 'lodash'

import { Formatter } from '../../helpers/formatter'
import { CustomFormulas } from '../../helpers/formulas'
import { EsprimaParser, ExpressionEvaluator, evaluateExpressionWithValueGetter } from '../../helpers/evaluation'
import { JSONSchemaResolver } from '../../resolvers/json-schema-resolver'

export const createEntityTableColumns = async (api, entitySchema, columns, sessionContext?) => {
  await precacheMissingColumns(api, columns)
  const schemaResolver = new JSONSchemaResolver(api)
  return columns.map((column) => {
    const schemaContext = column.entityType || entitySchema
    const result = schemaResolver.resolveSubschemaByValuePath(schemaContext, column.path)
    if (!result && column.path !== 'data') {
      // NOTE: For context when debugging, in the past this has come up as a bug
      // during development of a view's columns, where a path refers to some
      // custom entity type (e.g. heartland execution schema), but the table's
      // schema context is only the base entity type (e.g. core storyboard
      // execution schema). In that case, the fix was to modify the view's query
      // to use the more concrete/custom entityType. The table will provide that
      // via `entitySchema`.
      // It's also possible that this warning does not indicate a potential
      // issue, for example a path pointing to a denormalized property, which
      // our resolver doesn't support, but may be valid and may work fine.
      // Ideally in the future we could add support for resolving those paths,
      // so that this warning reflects true issues and could maybe be upgraded
      // to an error log.
      console.warn(`Warning: could not resolve column path ${column.path} in schema context ${schemaContext.id || schemaContext}`)
    }

    const subschema = _.get(result, 'schema', column.schema)
    return createTableColumn(column, subschema, sessionContext)
  })
}

export const filterTableColumns = (columns, settings) => {
  const ctx = {
    settings
  }
  const valueGetter = (key) => _.get(ctx, key, CustomFormulas[key])

  const evaluator = ExpressionEvaluator.create()
    .setValueGetter(valueGetter)
    .setASTParser(EsprimaParser)

  return columns.filter(c =>
    c.isVisibleExpression ? evaluator.evaluate(c.isVisibleExpression) : true)
}

const precacheMissingColumns = async (api, columns) => {
  const store = api.getStore();
  const unresolvedColumns = _.filter(columns, (column) => {
    return _.isString(column.entityType) && !store.getRecord(column)
  })
  const uris = _.map(unresolvedColumns, (column) => column.entityType)
  if (uris.length > 0) {
    // wait for it finish, ensuring they've been cached
    await store.resolveEntitiesByUri(uris)
  }
}

const createTableColumn = (column, schemaFragment, context?) => {
  column = _.cloneDeep(column)
  // TODO(peter): we should have a better way than this
  if (column.path &&
    !column.path.startsWith('data') &&
    !column.path.startsWith('computations.')) {
      if (column.path === '.') {
        column.path = '.'
      } else {
        column.path = `data.${column.path}`
      }
  }
  if (schemaFragment) {
    column.label = column.label || schemaFragment.label
    column.type = schemaFragment.type
    column.schema = schemaFragment
  }
  column.formatter = createColumnFormatter(column, context)
  return column
}

const createColumnFormatter = (column: any, context?: any) => {
  let getValue: any = _.identity
  if (column.formula) {
    getValue = (value) => {
      const extractVariables = (key) => _.get(value, key, CustomFormulas[key])
      return evaluateExpressionWithValueGetter(column.formula, extractVariables)
    }
  }
  if (column.format) {
    const { type, ...options } = column.format
    const ctx = {
      ...context,
      ...options,
    }
    return (value) => Formatter.formatValue(getValue(value), type, null, ctx)
  } else if (column.schema && !column.skipFormatter) {
    return (value) => Formatter.formatEntityValue(getValue(value), column.schema, context)
  }
  return getValue
}
