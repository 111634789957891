/**
 * Enrichment counterpart of the NavigationRoutes json.  In json, it stored as an array so build
 * a couple of helper dictionaries for faster lookup
 */

import _ from 'lodash'
import { IStoryboardEnrichProps, StoryboardElement } from './storyboard-element'
import { StoryboardNavigationRoute } from './storyboard-navigation-route'
import { Identifier, NavigationMap, Predicate } from './storyboard-plan-model'
import { SharedContext } from './storyboard-shared-context'
import { INITIAL_ROUTE_KEY } from './storyboard-utils'

/**
 *  Example: Coyote initial routing table
 *  From welcome screen, scene_1_welcome:
 *   a. Goto 'scene_2_trailer_bobtail' document type select screen to select either trailer or bobtail
 *      if last document is unknown or end of their route, delivery trailer or bobtail drop off
 *   b. Goto 'scene_3_trailer_deliver, add trailer delivery view if last document trailer pickup
 *   c. Goto 'scene_4_bobtail_end, add a bobtail end if last document is bobtail
 *
 *   {
 *     "sourceId": "scene_1_welcome",
 *     "destinations": [
 *       {
 *         "name": "Trailer/Bobtail",
 *         "pathId": "scene_2_trailer_bobtail",
 *         "predicates": [
 *           {
 *             "name": "Initial",
 *             "expression": "_.isEmpty(coyoteTestExecution) || _.isEmpty(coyoteTestExecution.lastDocumentTypeId)"
 *           },
 *           {
 *             "name": "Bobtail end",
 *             "expression": "coyoteTestExecution.lastDocumentTypeId === 'ff259cb2-6633-44b2-94bd-9e2838efbbc4'"
 *           },
 *           {
 *             "name": "Trailer delivery",
 *             "expression": "coyoteTestExecution.lastDocumentTypeId === '97b5d41e-fe7e-434d-865a-bcd1449d42da'"
 *           }
 *         ]
 *       },
 *       {
 *         "name": "Bobtail Delivery",
 *         "pathId": "scene_4_bobtail_end",
 *         "predicates": [
 *           {
 *             "name": "Bobtail start",
 *             "expression": "coyoteTestExecution.lastDocumentTypeId === '599eb60f-79a0-4d2d-88c5-8f436a320580'"
 *           }
 *         ]
 *       },
 *       {
 *         "name": "Trailer Delivery",
 *         "pathId": "scene_3_trailer_delivery",
 *         "predicates": [
 *           {
 *             "name": "Trailer pickup",
 *             "expression": "coyoteTestExecution.lastDocumentTypeId === 'ecd4d7f8-d604-4271-96bb-374576f95286'"
 *
 *           }
 *         ]
 *       }
 *     ]
 *   },
 *
 * The initial route must be marked as "__ROOT__"
 * Any final exit route must be marked as "__EXIT__"
 */

export class StoryboardNavigationMap<ItemType> extends StoryboardElement {
  private routeTable: {} = {} // source/destinations
  private itemTable: {} = {} // all the routing elements of this navigation map
  private initialRoute: StoryboardNavigationRoute = null

  constructor(data: NavigationMap, schema: any) {
    super(data || [], schema)
  }

  public enrich(props: IStoryboardEnrichProps): any {
    super.enrich(props)
    const { getElement } = props

    // the routing tables store in the json is array format, convert it to a dictionary
    this.initItemTable(getElement)
    this.initRouteTable(getElement)
    this.initInitialRoute()

    return this
  }

  public getInitialRoute(): StoryboardNavigationRoute {
    return this.initialRoute
  }

  public getItem(itemId: Identifier): ItemType {
    return _.get(this.itemTable, itemId)
  }

  public getItems(): ItemType[] {
    return _.values(this.itemTable)
  }

  /**
   * Get all possible routes for this item
   */
  public getRoutes(itemId: Identifier): StoryboardNavigationRoute[] {
    return _.get(this.routeTable, itemId, [])
  }

  public getNextRoute(route: ItemType, sharedContext: SharedContext): StoryboardNavigationRoute {
    return this.getNextRouteById(_.get(route, 'data.id'), sharedContext)
  }

  public getNextRouteById(
    currId: Identifier = INITIAL_ROUTE_KEY,
    sharedContext: SharedContext
  ): StoryboardNavigationRoute {

    const potentialRoutes = this.getRoutes(currId)

    if (_.isEmpty(potentialRoutes)) {
      return null
    }

    return _.find(potentialRoutes, (navEntry) => {
      const entryPredicates = navEntry.data.entryPredicates || []

      // treat empty predicate as true
      return (
        _.isEmpty(entryPredicates) ||
        _.find(entryPredicates, (predicate: Predicate) =>
          sharedContext.evaluate(predicate.expression)
        ) !== undefined
      )
    })
  }

  /**
   * Build a key/value pair for all the enriched route elements to help with lookup
   */
  private initItemTable(getElement: (id) => ItemType): void {
    const routes = this.data

    // collect all the children
    const children = _.reduce(
      routes,
      (acc, value) => {
        let nextRoutes = []
        if (value.destinations != null) {
          nextRoutes = value.destinations.map((dest) => dest.pathId) || []
        }
        return acc.concat([value.sourceId, ...nextRoutes])
      },
      []
    )

    const uniqChildren = _.uniq(children)

    // build a key/value pair dictionary to lookup element in the routes
    this.itemTable = uniqChildren.reduce((acc, value) => {
      const element = getElement(value)
      return _.isEmpty(element) ? acc : { ...acc, [value]: element }
    }, {})
  }

  private initRouteTable(getElement: (id) => ItemType): void {
    const routes = this.data

    this.routeTable = routes.reduce((acc, value) => {
      const key = value.sourceId
      let enrichedDestinations = []
      if (value.destinations != null) {
        enrichedDestinations = value.destinations.map((dest) => {
          return new StoryboardNavigationRoute(dest).enrich({ getElement })
        })
      }
      acc[key] = enrichedDestinations
      return acc
    }, {})
  }

  private initInitialRoute(): void {
    const initialDestinations = _.get(this.routeTable, INITIAL_ROUTE_KEY, [])
    this.initialRoute = _.first(initialDestinations)
  }
}
