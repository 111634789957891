import { browserHistory } from 'browser/history'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { AbstractEntityView, IAbstractEntityViewState } from 'browser/components/containers/abstract-entity-view'

interface IDATSettingsViewState extends IAbstractEntityViewState {
  isDisconnecting: boolean
}

export class DATSettingsView extends AbstractEntityView<IDATSettingsViewState> {

  constructor(props) {
    super(props)
    this.state = {
      entity: null,
      errors: null,
      isDisconnecting: false,
      isLoading: true,
      isSaving: false,
    }
  }

  private handleDisconnectDatIntegration() {
    const { entity } = this.state
    this.setState({ isDisconnecting: true })
    apis.datCredentialDisconnect().then(() => {
      this.setState({ isDisconnecting: false })
      browserHistory.push({ pathname: '/integrations' })
    })
  }

}
