import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
// use BluebirdPromise here instead of Promise, otherwise, compile error due to async:
// error TS2529: Duplicate identifier 'Promise'. Compiler reserves name 'Promise' in the top level scope of a module containing async functions.
import BluebirdPromise from 'bluebird'
import { evaluateExpressionWithValueGetter, preprocess, ValueGetter } from '../../helpers/evaluation'

export interface IFormulaTaskProvider {
  getFormulaTasks(): FormulaTask[]
}

interface IFormulaTaskProps {
  formula: string
  valueGetter: ValueGetter
  valuePath?: string | string[]
  id?: string
  originalValue?: any
  schema?: any
}

export class FormulaTask {
  public formula: string
  public valueGetter: ValueGetter
  public valuePath: string | string[]
  public id: string
  public originalValue: any
  public schema: Record<string, any>

  constructor(options: IFormulaTaskProps) {
    const {
      formula,
      valuePath,
      valueGetter,
      id = uuidv4(),
      originalValue = undefined,
      schema = {},
    } = options
    this.formula = preprocess(formula)
    this.valueGetter = valueGetter
    this.valuePath = valuePath
    this.id = id
    this.originalValue = originalValue
    this.schema = schema
  }
}

export interface IFormulaTaskResult {
  task: FormulaTask
  value: any
  /**
   * Indicates in which batch iteration this formula task completed. Useful for
   * tests. Affected by sorting method (in the future, if we improved sorting,
   * we'd likely see this value decrease)
   */
  batchIteration: number
}

type ValueSetter = (result: IFormulaTaskResult) => boolean

interface BatchRunOptions {
  sort?: boolean
}
export class FormulaBatchProcessor {
  private options: BatchRunOptions

  // Cancelable promise. In most scenarios this won't matter, but if schema
  // designers use formulas that produce longer-running async Promises, then
  // additional user input is more likely to interrupt this promise and start it
  // over.
  private promise?: BluebirdPromise<IFormulaTaskResult[]>

  constructor(options: BatchRunOptions = {}) {
    this.options = options
  }

  /**
   * Runs tasks until no more changes are observed.
   * @param tasks List of formula tasks to run.
   * @param valueSetter Callback for intermediate values.
   */
  public run(
    tasks: FormulaTask[],
    valueSetter: ValueSetter,
    options: BatchRunOptions = this.options
  ): BluebirdPromise<IFormulaTaskResult[]> {
    const { sort = true } = options
    const pendingTasks = sort ? this.sort(tasks) : tasks
    return this.coalesce(pendingTasks, valueSetter)
  }

  public cancel() {
    if (this.promise) {
      this.promise.cancel()
      this.promise = undefined
    }
  }

  private sort(tasks: FormulaTask[]): FormulaTask[] {
    // TODO: implement toposort using ASTs and value paths
    return tasks
  }

  private coalesce(
    tasks: FormulaTask[],
    valueSetter: ValueSetter
  ): BluebirdPromise<IFormulaTaskResult[]> {
    const taskResults: IFormulaTaskResult[] = _.map(tasks, (task) => ({
      task,
      value: task.originalValue,
      batchIteration: 0,
    }))
    this.cancel()

    const maxBatches = _.size(tasks)
    let batchIteration = 0

    const createRecursiveBatchPromise = BluebirdPromise.method(async () => {
      let hasDiff = false
      for (const taskResult of taskResults) {
        const newValue = await this.createTaskPromise(taskResult.task)
        const oldValue = taskResult.value
        const changed = !_.isEqual(newValue, oldValue)
        taskResult.value = newValue
        taskResult.batchIteration = changed ? batchIteration : taskResult.batchIteration
        valueSetter(taskResult)
        hasDiff = hasDiff || changed
      }
      if (!hasDiff || ++batchIteration >= maxBatches) {
        return taskResults
      } else {
        return createRecursiveBatchPromise()
      }
    })

    return (this.promise = createRecursiveBatchPromise())
  }

  private async createTaskPromise(task: FormulaTask): Promise<any> {
    const { isComputedProperty, defaultValue } = task.schema
    const evaluatedResult = evaluateExpressionWithValueGetter(task.formula, task.valueGetter)
    let value = await Promise.resolve(evaluatedResult)
    // TODO: does isComputedProperty code path need the same treatment?
    // previously it did not do this, but was never sure why not
    if (!isComputedProperty) {
      if (_.isNaN(value)) {
        value = undefined
      }
      if (_.isNil(value)) {
        value = defaultValue
      }
    }
    return value
  }
}
