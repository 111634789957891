"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.compare = compare;
exports.getCurrentGeoPosition = getCurrentGeoPosition;
exports.getInitialsColor = getInitialsColor;
exports.getPostedByName = getPostedByName;
exports.getRenameDownloadUri = getRenameDownloadUri;
var _bluebird = _interopRequireDefault(require("bluebird"));
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
var _lodash = _interopRequireDefault(require("lodash"));
var _moment = _interopRequireDefault(require("moment"));
function compare(value1, value2) {
  // the spaceship operator
  var spaceship = function spaceship(a, b) {
    var diff = a - b;
    return (diff > 0) - (diff < 0);
  };
  var type1 = (0, _typeof2["default"])(value1);
  var type2 = (0, _typeof2["default"])(value2);
  if (type1 !== type2) {
    return false;
  }
  if (type1 === 'object') {
    if (_moment["default"].isMoment(value1)) {
      type1 = 'moment';
    }
  }
  // types are equal - so we have to check values now
  switch (type1) {
    case 'boolean':
    case 'number':
      return spaceship(value1, value2);
    case 'string':
      return spaceship(value1.localeCompare(value2), 0);
    case 'array':
      var len1 = value1.length;
      var len2 = value2.length;
      var len = Math.min(len1, len2);
      for (var i = 0; i < len; i++) {
        var r = compare(value1[i], value2[i]);
        if (r !== 0) {
          return r;
        }
      }
      // all elements are equal now
      // shorter array should be ordered first
      return spaceship(len1, len2);
    case 'moment':
      return value1 - value2;
    default:
      return spaceship(value1, value2);
  }
}
function getPostedByName(postedBy) {
  if (!_lodash["default"].isEmpty(postedBy.first_name) && !_lodash["default"].isEmpty(postedBy.last_name)) {
    return "".concat(postedBy.first_name, " ").concat(postedBy.last_name);
  } else {
    return postedBy.email;
  }
}
function getCurrentGeoPosition() {
  return new _bluebird["default"](function (resolve, reject) {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
}
function getInitialsColor(email) {
  var colors = ['red', 'pink', 'purple', 'deepPurple', 'indigo', 'blue', 'lightBlue', 'cyan', 'teal', 'green', 'lightGreen', 'amber', 'orange', 'deepOrange', 'brown', 'blueGrey'];
  var hash = function hash(str) {
    var hash = 0;
    for (var i = 0; i < str.length; ++i) {
      hash += str.charCodeAt(i);
    }
    return hash;
  };
  return colors[hash(email) % colors.length];
}
function getRenameDownloadUri(link, name) {
  return "/1.0/entities/actions/system/renameDownload?link=".concat(link, "&name=").concat(name);
}