import React, { useState } from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { Event } from 'shared-libs/models/types/storyboard/storyboard-execution-model'
import { ElementType } from 'shared-libs/helpers/types'
import { Entity } from 'shared-libs/models/entity'

export const EventChanges: React.FC = (props) => {
  const [expanded, setExpanded] = useState(false)

  const handleToggleExpanded = () => {
    setExpanded(!expanded)
  }

  const count = React.Children.count(props.children)

  if (!count) {
    return null
  } else if (count === 1) {
    return <>{props.children}</>
  } else {
    return (
      <>
        <button className="c-timelineEvent-body-accordion" onClick={handleToggleExpanded}>
          <Icon
            icon={IconNames.CHEVRON_DOWN}
            className={classNames({
              'c-timelineEvent-body-accordion__icon': true,
              'c-timelineEvent-body-accordion__icon--open': expanded,
            })}
          />
        </button>
        {!expanded && <strong>Show {count} changes</strong>}
        {expanded && props.children}
      </>
    )
  }
}

interface IEventChangeProps {
  mapping: ElementType<Event['outputMappings']>
  workflowEntity: Entity
  index: any
}

export const renderEventChange: React.FC<IEventChangeProps> = (props) => {
  const { mapping, workflowEntity, index } = props
  const { destination: key, value } = mapping

  const propSchema = workflowEntity.resolveSubschemaByValuePath(key)
  const propLabel = propSchema?.schema.label

  if (_.isNil(value) || (propSchema?.schema.shouldHideFromFeed)) {
    return null
  } else if (_.isObject(value)) {
    // avoid rendering "Set Foo to [object Object]"
    return (
      <div key={key}>
        Set <strong>{propLabel || key}</strong>
      </div>
    )
  } else {
    return (
      <div key={key}>
        Set <strong>{propLabel || key}</strong> to <strong>{value.toString()}</strong>
      </div>
    )
  }
}
