/**
 * Allow the user user to inject share/log/notification event into the event log
 *   share: will cause the backend to create a share notification from the share event
 *   log: this is business log event, currently will not process by the server
 *   notification: the server will send out notfication upon processing this event
 */
import _ from 'lodash'
import { Entity } from '../../entity'
import { eventSourceFromNode, IStoryboardNode, StoryboardExecutionEntity } from './storyboard-execution'
import {
  Event as ExecutionEvent,
  NotificationEvent as ExecutionNotificationEvent,
} from './storyboard-execution-model'
import { Mappings, Predicate, UserEvent } from './storyboard-plan-model'
import { SharedContext } from './storyboard-shared-context'
import { StoryboardStory } from './storyboard-story'
import { getStoryboardExecution } from './storyboard-utils'

export interface IStoryboardUserEventProps {
  executionEntity: Entity
  story: StoryboardStory
}

export enum StoryboardEventType {
  CREATE = 'create',
  EDIT = 'edit',
  SHARE = 'share',
  EXTERNAL = 'external',
  LOG = 'log',
  BREADCRUMB = 'breadcrumb',
  ACTION = 'action',
  NOTIFICATION = 'notification',
}

export class StoryboardUserEventHandler {
  private props: IStoryboardUserEventProps
  private executionEntity: Entity
  private storyboardExecution: StoryboardExecutionEntity
  private sharedContext: SharedContext
  private story: StoryboardStory

  constructor(props: IStoryboardUserEventProps) {
    this.props = props
    this.executionEntity = this.props.executionEntity
    this.storyboardExecution = getStoryboardExecution(this.executionEntity)
    this.story = props.story

    this.sharedContext = this.storyboardExecution.context
  }

  public process(events: UserEvent[], node: IStoryboardNode): ExecutionEvent[] {
    const processedEvents = events
      .filter(
        (event) =>
          _.isEmpty(event.predicates) ||
          _.find(event.predicates, (predicate: Predicate) =>
            this.sharedContext.evaluate(predicate.expression)
          ) !== undefined
      )
      .map((event) => {
        return this.processEvent(event, node)
      })

    return _.compact(processedEvents)
  }

  public processEvent(event: UserEvent, node: IStoryboardNode): ExecutionEvent {
    const context = _.omitBy(this.getContext(event.context || []), _.isNil)
    const executionEvent = this.storyboardExecution.createEvent(node, event.eventType)
    const details = this.evaluate(event.details || {})
    _.set(executionEvent, 'details', details)
    // store the context into the output mappings
    if (!_.isEmpty(context)) {
      _.set(executionEvent, 'outputMappings', [
        {
          name: 'User data event',
          value: context,
        },
      ])
    }
    return executionEvent
  }

  // --------------------------------------------------------------------------------
  /**
   * Get the context from shared context to event context
   * @returns
   */
  private getContext(mappings: Mappings) {
    return this.sharedContext.getValueWithMappings(mappings)
  }

  /*
   * Assume all the string fields are expression and evaluate them
   */
  private evaluate(obj: any) {
    let newValue = obj
    if (_.isArray(obj)) {
      newValue = obj.map((val: any) => this.evaluate(val))
    } else if (_.isPlainObject(obj)) {
      if (_.get(obj, 'type', '') === 'expression') {
        const formula = _.get(obj, 'value', '')
        newValue = this.sharedContext.evaluate(formula)
      } else {
        newValue = _.transform(
          obj,
          (result: any, value, key) => {
            result[key] = this.evaluate(value)
            return result
          },
          {}
        )
      }
    }
    return newValue
  }
}
