import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'
import 'browser/components/atomic-elements/atoms/select/carrier-select/_carrier-select.scss'
import { default as EntitySelect, IEntitySelectProps } from 'browser/components/atomic-elements/atoms/select/entity-select'
import { components } from 'react-select'

// tslint:disable-next-line:no-empty-interface
type ICarrierSelectProps = IEntitySelectProps

interface ICarrierSelectStates {
  isLoading: boolean
  carrierEntity: Entity
}

export class CarrierSelect extends React.Component<ICarrierSelectProps, ICarrierSelectStates> {
  private input: any
  private store: any

  constructor(props: ICarrierSelectProps) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      carrierEntity: null,
      isLoading: true,
    }
  }

  public componentDidMount() {
    const { value } = this.props
    const valueId: string = _.get(value, 'entityId')
    this.fetchValueEntity(valueId)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const valueId = _.get(this.props.value, 'entityId')
    const nextValueId: string = _.get(nextProps.value, 'entityId')
    if (valueId !== nextValueId) {
      this.fetchValueEntity(nextValueId)
    }
  }

  public focus() {
    this.input.focus()
  }

  public render() {
    const {
      className,
      isStatic,
    } = this.props
    if (!isStatic) {
      return (
        <EntitySelect
          {...this.props}
          className={className}
          optionRenderer={this.handleRenderOption}
          valueRenderer={this.handleRenderValue}
          ref={(ref) => { this.input = ref }}
        />
      )
    }
  }

  private renderStyledDisplay = (props) => {
    const option = props.data
    const eligibility: any = _.get(option, 'carrier.eligibility', 'Disqualified')

    const isIneligible = eligibility === 'Disqualified' ? true : false
    return (
      <div
        className={classNames({
          'c-carrierSelect--ineligible': isIneligible,
        })}
      >
        {option.displayName}
      </div>
    )
  }

  private fetchValueEntity(uniqueId: string) {
    if (!uniqueId) {
      return this.setState({ carrierEntity: null })
    }
    this.setState({ isLoading: true })
    this.store.findRecord(uniqueId).then((entity) => {
      this.setState({
        carrierEntity: entity,
        isLoading: false,
      })
    })
  }

  private handleRenderValue = (props) => {
    return (
      <components.SingleValue {...props}>
        {this.renderStyledDisplay(props)}
      </components.SingleValue>
    )
  }

  private handleRenderOption = (props) => {
    return (
      <components.Option {...props}>
        {this.renderStyledDisplay(props)}
      </components.Option>
    )
  }
}
