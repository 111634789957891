import { isMobilePlatform } from 'browser/app/utils/utils'
import { IDeviceInfoProvider } from 'shared-libs/models/device'
import { Event } from 'shared-libs/models/types/storyboard/storyboard-execution-model'

// webapp doesn't have an incrementing version string, so we'll use the git hash
declare let __GITHASH__: string

/* helper that provides device info for workflow events */
export class WebDeviceInfoProvider implements IDeviceInfoProvider {
  getDeviceInfo(workflowVersion: string): Event['deviceInfo'] {
    return {
      platform: isMobilePlatform() ? 'mobile web' : 'desktop web',
      name: getDeviceName(),
      locale: navigator.language,
      appVersion: __GITHASH__,
      workflowVersion,
      timezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      connectionType: navigator['connection']?.['effectiveType'],
    }
  }
}

function getDeviceName(): string {
  const match = navigator.userAgent.match(/\((\w+;?[^)]*)\)/)
  if (match?.[1]) {
    // e.g.
    // 'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1'
    // => 'iPhone; CPU iPhone OS 13_2_3 like Mac OS X'
    return match[1]
  } else {
    // default to full user agent
    return navigator.userAgent
  }
}
