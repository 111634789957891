import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Header } from 'browser/components/atomic-elements/atoms/header/header'
import { OrBlock } from 'browser/components/atomic-elements/atoms/or-block/or-block'
import { Sheet } from 'browser/components/atomic-elements/atoms/sheet'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'

interface IAccountingMappingSheetProps extends IBaseProps {
  businessEntity: any
}

interface IAccountingMappingSheetStates {
  businessType: string
  errorText: string
  isSaving: boolean
  options: any
  value: any
}

const DEFAULT_ORDERS = [
  {
    path: 'precomputation.displayName',
    type: 'ascending',
  },
]

const CARRIER_TYPE = '/1.0/entities/metadata/carrier.json'
const CUSTOMER_TYPE = '/1.0/entities/metadata/customer.json'
const INTEGRATION_ENTITY_TYPE = '/1.0/entities/metadata/integrationEntities.json'
const QB_SYNC_TYPE = '/1.0/entities/metadata/quickBooksSync.json'

export class AccountingMappingSheet
  extends React.Component<IAccountingMappingSheetProps, IAccountingMappingSheetStates> {

  private sheet: Sheet
  private businessDataSet: EntityDataSource
  private integrationDataSet: EntityDataSource
  private qbSyncSchema: any

  constructor(props) {
    super(props)
    const store = apis.getStore()
    let businessType = ''
    this.qbSyncSchema = store.getRecord(QB_SYNC_TYPE)

    if (_.some(props.businessEntity.activeMixins, { entityId: '11111111-0000-0000-0000-000000000004'})) {
      this.businessDataSet = new EntityDataSource({
        entityType: CARRIER_TYPE,
        orders: DEFAULT_ORDERS,
      }).setOnChange(() => this.forceUpdate())
      businessType = 'Carrier'
    } else {
      this.businessDataSet = new EntityDataSource({
        entityType: CUSTOMER_TYPE,
        orders: DEFAULT_ORDERS,
      }).setOnChange(() => this.forceUpdate())
      businessType = 'Customer'
    }

    this.integrationDataSet = new EntityDataSource({
      entityType: INTEGRATION_ENTITY_TYPE,
      orders: DEFAULT_ORDERS,
    }).setOnChange(this.handleIntegrationDataChange)

    this.state = {
      businessType,
      errorText: '',
      isSaving: false,
      options: [],
      value: null,
    }
  }

  public componentDidMount() {
    this.businessDataSet.find()
    this.integrationDataSet.find()
  }

  public componentWillUnmount() {
    this.businessDataSet.dispose()
    this.integrationDataSet.dispose()
  }

  public render() {
    const { businessType, errorText, isSaving, options, value } = this.state
    const { businessEntity } = this.props
    const handleRef = (ref) => { this.sheet = ref }
    const helpText = `Select the corresponding ${businessType} below or create a new QBO ${businessType}.`
    return (
      <Sheet
        ref={handleRef}
        size='sm'
        bodyClassName='c-sheet-body--padded'
        header={
          <Header
            title={`Unmapped ${businessType} Detected`}
          />
        }
        footer={
          <Footer
            isPrimaryButtonLoading={isSaving}
            isVisible={true}
            onCancelButtonClick={this.handleClose}
            onPrimaryButtonClick={this.handleSave}
          />
        }
      >
        <div className='pv3'>
          <span className='b'>{businessEntity.displayName}</span> is not mapped to a corresponding QBO {businessType}.<br /><br />
          {helpText}
        </div>
        <FormTable>
          <SelectField
            errorText={errorText}
            isErrorInline={true}
            isHorizontalLayout={true}
            label={'QBO ' + businessType}
            noResultsText=''
            onChange={this.handleQBOEntitySelected}
            options={options}
            optionLabelPath={'name'}
            optionValuePath={'identifier'}
            placeholder={'QBO ' + businessType}
            value={value}
          />
        </FormTable>

        <OrBlock />

        <div className='tc'>
          <Button
            onClick={this.handleCreateNewQBOEntity}
          >
            Create {businessEntity.displayName} in QBO and map to it
          </Button>
        </div>
      </Sheet>
    )
  }

  private handleIntegrationDataChange = () => {
    const { businessType } = this.state
    const { entities } = this.integrationDataSet
    if (_.isEmpty(entities)) {
      return
    }
    _.head(entities).reload().then((entity) => {
      const options = _.filter(entity.content.integrationEntities.entities, (a) => {
        return a.entityType === businessType
      })
      this.setState({
        options,
      })
    })
  }

  private handleSave = () => {
    this.setState({ isSaving: true })
    const { businessEntity } = this.props
    const { value } = this.state
    const { activeMixins } = businessEntity
    if (!_.find(activeMixins, (mixin) => mixin.entityId === '164fd38f-5ea0-41f5-bf35-5aae09ddcb7d')) {
      businessEntity.addMixin(this.qbSyncSchema)
    }
    _.set(businessEntity.content, 'quickBooksSync.identifier', value.identifier)
    _.set(businessEntity.content, 'quickBooksSync.version', value.version)
    businessEntity.save().then(() => {
      this.handleClose()
    })
  }

  private handleQBOEntitySelected = (value, option) => {
    this.setState({
      errorText: '',
      value: option,
    })
  }

  private addQuickBooksSync(entity, qbs) {
    const activeMixins = entity.mixins.active
    if (!_.find(activeMixins, (mixin) => mixin.entityId === '164fd38f-5ea0-41f5-bf35-5aae09ddcb7d')) {
      entity.addMixin(this.qbSyncSchema)
    }
    _.set(entity.content, 'quickBooksSync.identifier', qbs.identifier)
    _.set(entity.content, 'quickBooksSync.version', qbs.version)
    entity.save().then(() => {
      this.handleClose()
    })
  }

  private handleClose = () => {
    this.sheet.close()
  }

  private handleCreateNewQBOEntity = () => {
    const { businessEntity } = this.props
    const { businessType } = this.state
    this.setState({ isSaving: true })
    // tslint:disable-next-line:max-line-length
    const createQBOEntity = businessType === 'Customer' ? (id) => apis.createQBOCustomer(id) : (id) => apis.createQBOVendor(id)

    createQBOEntity(businessEntity.uniqueId).then((response) => {
      if (!response) {
        throw Error('Unable to create QBO ' + businessType)
      }
      this.addQuickBooksSync(businessEntity, response)
      Toast.show({
        message: `New ${businessType} created in QBO`,
        position: Position.BOTTOM_RIGHT,
        timeout: 5000,
      })
    }).catch((error) => {
      // Do Something
      this.setState({
        errorText: error.responseJSON.errors[0].message,
        isSaving: false,
        value: null,
      })
    })
  }
}
