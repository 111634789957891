import { IStoredValues } from './interface'

const KEY_PREFIX = 'vector.'

export function isVectorStorageKey(key: string) {
  return key && key.indexOf(KEY_PREFIX) === 0
}

export function putLocalStorageItem<T>(key: string, value: T): void {
  const fullKey = getFullKey(key)
  const data = JSON.stringify(value)
  try {
    if (data === undefined) {
      // remove, otherwise next retrieval will be string literal `"undefined"`.
      localStorage.removeItem(fullKey)
    } else {
      localStorage.setItem(fullKey, data)
    }
  } catch (err) {
    // no local storage support
  }
}

export function getSubKey(fullKey: string): string {
  const firstDot = fullKey.indexOf('.')
  return fullKey.substring(firstDot + 1)
}

export function getValueFromEvent<T>(ev: StorageEvent): T {
  return JSON.parse(ev.newValue) as T
}

export function getLocalStorageItems() {
  const values: IStoredValues = {}
  try {
    const keys = Object.keys(localStorage)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (isVectorStorageKey(key)) {
        values[getSubKey(key)] = JSON.parse(localStorage.getItem(key))
      }
    }
  } catch (err) {
    // no local storage support
  }
  return values
}

function getFullKey(key: string) {
  return `${KEY_PREFIX}${key}`
}
