import { Hotkey, Hotkeys, HotkeysTarget, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import apis from 'browser/app/models/apis'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { EntityFormPanel } from 'browser/components/atomic-elements/organisms/entity/entity-form-panel'

const INVITE_SCHEMA_URI = '/1.0/entities/metadata/userInvite.json'

/**
 * @uiComponent
 */
interface IOmniButtonProps extends IBaseProps {
  firm: any
  isFirmAdmin: boolean
  showInviteEmployee: boolean
}

@HotkeysTarget
export class OmniButton extends React.Component<IOmniButtonProps, any, never> {

  public static defaultProps: Partial<IOmniButtonProps> = {
    showInviteEmployee: true,
  }

  private tetherTarget: TetherTarget

  public render() {
    const tetherOptions = {
      attachment: 'top right',
      targetAttachment: 'bottom right',
    }
    const refHandler = (ref) => { this.tetherTarget = ref }
    return (
      <TetherTarget
        closeOnPortalClick={true}
        ref={refHandler}
        tethered={this.renderPopover()}
        tetherOptions={tetherOptions}
      >
        <div className={classNames('c-topNavigationBar-link c-topNavigationBar-link--icon', this.props.className)}>
          <Icon icon={IconNames.PLUS} />
        </div>
      </TetherTarget>
    )
  }

  public renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          global={true}
          combo='shift + n'
          label='Open Omni Button Popover'
          onKeyDown={this.handleToggleOmniButtonPopover}
        />
      </Hotkeys>
    )
  }

  private renderPopover() {
    // user is not firm admin, clicking on add would just open add document modal
    const { children, isFirmAdmin, firm, showInviteEmployee } = this.props
    return (
      <Popover className='collapse'>
        <ul className='c-dropdownList c-omniButtonPopoverDropdown'>
          {children}
          <li
            className={classNames('c-list-divider', {
              'u-hide': !showInviteEmployee || !isFirmAdmin || _.isEmpty(children),
            })}
          />
          {this.renderInviteCoworker()}
        </ul>
      </Popover>
    )
  }

  private renderInviteCoworker() {
    const { showInviteEmployee, isFirmAdmin } = this.props
    if (showInviteEmployee && isFirmAdmin) {
      return (
        <li
          className='c-dropdownList-item'
          data-debug-id='add-invite'
          onClick={this.handleShowInviteMemberModal}
        >
          Invite Employee
        </li>
      )
    }
  }

  private handleShowInviteMemberModal = () => {
    const store = apis.getStore()
    const entitySchema = store.getRecord(INVITE_SCHEMA_URI)
    const contextProps = {
      density: 'collapse',
      isHorizontalLayout: true,
    }
    EntityFormPanel.open({
      schema: entitySchema,
      uiContext: contextProps,
      uiSchemaPath: 'uiSchema.web.entityCreationModal',
    })
  }

  private handleToggleOmniButtonPopover = (event: KeyboardEvent) => {
    this.tetherTarget.toggle()
  }
}
