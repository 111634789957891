import * as React from 'react'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'

type ComponentMap = {
  [componentKey: string]: any
}

export interface IComponentsContext {
  components: ComponentMap
  platform: PlatformType
}

export const ComponentsContext = React.createContext<IComponentsContext>({
  components: {},
  platform: PlatformType.WEB
})
