import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useState, useContext } from 'react'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import 'browser/components/atomic-elements/atoms/card/_card-header.scss'
import { ICardHeaderProps } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { PagingControls } from 'browser/components/atomic-elements/organisms/entity/entity-header/paging-controls'
import { ImagingSummaryModal } from '../../imaging-summary-modal/imaging-summary-modal'
import { Entity } from 'shared-libs/models/entity'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'

/**
 * @uiComponent
 */
interface IImagingHeaderProps extends ICardHeaderProps {
  centerFullScreenActionElement?: React.ReactElement<any>
  dataSet?: any
  detailActionElement?: React.ReactElement<any>
  leftFullScreenActionElement?: React.ReactElement<any>
  rightFullScreenActionElement?: React.ReactElement<any>
  isFullScreen?: boolean
  match: any
  pageIndex?: number
  handleNavigateToNextPage: any
  handleNavigateToPreviousPage: any
  handlePublishTask: any
  relatedSalesOrders: Entity[]
}

export const ImagingHeader: React.FC<IImagingHeaderProps> = (props) => {
  const {
    centerFullScreenActionElement,
    className,
    children,
    dataSet,
    dropdownMenuElement,
    entity,
    handleNavigateToPreviousPage,
    handleNavigateToNextPage,
    leftFullScreenActionElement,
    relatedSalesOrders,
    onClose,
    rightFullScreenActionElement,
  } = props

  const { settings } = useContext(AppNavigatorContext)

  const getEntityPageIndex = () => {
    if (dataSet && !dataSet.isLoading) {
      return _.findIndex(dataSet.entities, { uniqueId: entity.uniqueId })
    }
    return undefined
  }

  const [entityPageIndex, setEntityPageIndex] = useState(getEntityPageIndex())
  useEffect(() => {
    if (_.isNil(entityPageIndex)) {
      setEntityPageIndex(getEntityPageIndex())
    }
  }, [dataSet])

  const renderBackButton = () => {
    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin'>
        <Button
          className={classNames('u-nowrap', Classes.iconClass(IconNames.ARROW_LEFT))}
          onClick={onClose}
        >
          Back
        </Button>
      </CardHeaderItem>
    )
  }

  const renderPagingControls = () => {
    if (!dataSet || _.isNil(entityPageIndex) || entityPageIndex < 0) {
      return null
    }
    return (
      <PagingControls
        dataSet={dataSet}
        onNavigateToNextPage={handleNavigateToNextPage}
        onNavigateToPreviousPage={handleNavigateToPreviousPage}
        pageIndex={entityPageIndex}
      />
    )
  }

  const renderPublishButton = () => {
    const tasks = entity.get('documentClassificationTask.classificationTasks')
    const isMissingRequiredFields = _.some(tasks, (task) => {
      const documentName = _.get(task, 'documentProperties.document.name', null)
      return !task.documentType || !documentName || _.isEqual(documentName, "Document Needs Load Number")
    })
    const handlePublishTask = () => {
      ImagingSummaryModal.open({ onSave: onClose, entities: [entity], relatedSalesOrders, settings })
    }
    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin u-noPrint'>
        <Button
          isDisabled={isMissingRequiredFields}
          onClick={handlePublishTask}
        >
          Publish
        </Button>
      </CardHeaderItem>
    )
  }

  const renderDropdownPopover = () => {
    if (dropdownMenuElement) {
      const tetherOptions = {
        attachment: 'top right',
        targetAttachment: 'bottom right',
      }

      const renderDropdown = () => {
        return (
          <Popover className='collapse'>
            {dropdownMenuElement}
          </Popover>
        )
      }

      return (
        <CardHeaderItem className='c-cardHeader-item--smallMargin u-noPrint'>
          <TetherTarget
            closeOnPortalClick={true}
            tethered={renderDropdown()}
            tetherOptions={tetherOptions}
          >
            <Button>
              <Icon icon={IconNames.MORE} />
            </Button>
          </TetherTarget>
        </CardHeaderItem>
      )
    }
  }

  return (
    <div className={classNames('grid-block shrink vertical', className)}>
      <div className='u-flex u-justifyContentSpaceBetween u-borderBottom c-cardHeader'>
        {renderBackButton()}
        {leftFullScreenActionElement}
        {centerFullScreenActionElement}
        {rightFullScreenActionElement}
        {renderPagingControls()}
        {renderPublishButton()}
        {renderDropdownPopover()}
      </div>
      <div
        className='u-flex u-justifyContentSpaceBetween
          u-innerBumperTop u-innerBumperBottom--xs u-innerBumpRight--xl
          u-bumperLeft--xl'
      >
        {children}
      </div>
    </div>
  )
}

ImagingHeader.defaultProps = {
  centerFullScreenActionElement: <CardHeaderItem className='c-cardHeader-item--grow' />,
}
