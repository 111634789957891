import $ from 'jquery'
import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'

import 'browser/components/atomic-elements/organisms/overlay-manager/_overlay-manager.scss'

class OverlayManager {
  private overlays: any[]
  private reactHosts: any[]

  constructor() {
    this.overlays = []
    this.reactHosts = []
  }

  public closeAllOverlays() {
    this.overlays.forEach((envelope) => {
      const portalContainer = envelope.portalContainer
      ReactDOM.unmountComponentAtNode(portalContainer)
      // HTMLElement.remove is not supported in IE11
      $(portalContainer).remove()
    })
    this.overlays = []
  }

  public closeOverlay(overlay) {
    const envelope = this.getPortalEnvelope(overlay)
    if (!envelope) { return }
    const { portalContainer } = envelope
    // HTMLElement.remove is not supported in IE11
    $(portalContainer).remove()
    ReactDOM.unmountComponentAtNode(portalContainer)
    _.pull(this.overlays, envelope)
  }

  public getNextStackingOrder() {
    const stackingOrders = _.map(this.overlays, 'stackingOrder') as number[]
    return _.isEmpty(stackingOrders) ? 0 : _.max(stackingOrders) + 1
  }

  public getPortalContainer(overlay) {
    const envelope = this.getPortalEnvelope(overlay)
    return _.get(envelope, 'portalContainer')
  }

  public getPortalEnvelope(overlay) {
    return _.find(this.overlays, (item) => item.overlay === overlay)
  }

  public isTopOfStack(overlay) {
    const envelope = this.getPortalEnvelope(overlay)
    if (!envelope) { return false }
    const currentStackingOrders = _.map(this.overlays, 'stackingOrder')
    const maxStackingOrder = Math.max.apply(null, currentStackingOrders)
    return envelope.stackingOrder === maxStackingOrder
  }

  public openOverlay(Component, props) {
    const parent = this.getReactHost()
    this.openOverlayWithParent(parent, Component, props)
  }

  public openOverlayElement(element, callback?): Element {
    const parent = this.getReactHost()
    return this.openOverlayElementWithParent(parent, element, callback)
  }

  public updateOverlayElement(overlay, element, callback?) {
    const parent = this.getReactHost()
    return this.updateOverlayElementWithParent(parent, overlay, element, callback)
  }

  public openOverlayWithParent(parent, Component, props) {
    const modalElement = <Component {...props} />
    return this.openOverlayElementWithParent(parent, modalElement)
  }

  public openOverlayElementWithParent(parent, element, callback?): Element {
    const portalContainer = document.createElement('div')
    const stackingOrder = this.getNextStackingOrder()
    const stackingOrderClass = `u-zIndex-8-${stackingOrder}`
    portalContainer.setAttribute('class', `c-portalContainer ${stackingOrderClass}`)
    this.rootPortalContainer.appendChild(portalContainer)
    const overlay = this.renderOverlay(portalContainer, stackingOrder, parent, element, callback)
    const envelope = { overlay, portalContainer, stackingOrder }
    this.overlays.push(envelope)
    return overlay
  }

  public popReactHost() {
    this.reactHosts.pop()
  }

  public pushReactHost(reactHost) {
    this.reactHosts.push(reactHost)
  }

  public updateOverlayElementWithParent(parent, overlay, element, callback?) {
    const envelope = this.getPortalEnvelope(overlay)
    if (envelope) {
      const { portalContainer, stackingOrder } = envelope
      envelope.overlay = this.renderOverlay(
        portalContainer, stackingOrder, parent, element, callback)
      return envelope.overlay
    }
  }

  private getReactHost() {
    return _.last(this.reactHosts)
  }

  private get rootPortalContainer() {
    return document.getElementById('root-portal-container')
  }

  private renderOverlay(domNode, stackingOrder, parent, element, callback?): Element {
    const elementOnClose = element.props.onClose
    const onClose = () => {
      this.closeOverlay(domNode)
      if (elementOnClose) {
        elementOnClose()
      }
    }
    element = React.cloneElement(element, { onClose, stackingOrder })
    ReactDOM.unstable_renderSubtreeIntoContainer(parent, element, domNode, callback)
    return domNode
  }
}

const OverlayManagerInst = new OverlayManager()
export default OverlayManagerInst
