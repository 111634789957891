import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { Owner } from 'shared-libs/generated/server-types/entity'
import { renderAuthorHeader, renderEventTimestamp, renderDistance, renderDeviceInfoTooltipContent } from '../util'
import { EventChanges, renderEventChange } from './event-changes'
import { SchemaIds } from 'shared-libs/models/schema'
import { Position, Tooltip } from '@blueprintjs/core'

interface ICreateItemProps extends IBaseProps {
  event: any
  owner: Owner
  index: number
  isFirst: boolean
  isLast: boolean
  workflowEntity: any
  appointmentDestinationPath?: string
}

export const CreateItem: React.FC<ICreateItemProps> = (props) => {
  const { className, appointmentDestinationPath, event, isFirst, owner, workflowEntity } = props
  const { createdBy, createdAt, creationDate, processedDate } = event
  const [ entity, setEntity ] = useState(null)
  const [ missingAssociation, setMissingAssociation ] = useState(false)
  const { settings } = useContext(AppNavigatorContext)

  useEffect(() => {
    const { event } = props
    const entityId = _.get(event, ['associations', '0', 'entityId'])

    if (!entityId) {
      return
    }

    if (entityId) {
      apis.getStore().findRecord(entityId).then((result) => {
        setEntity(result)
      })
    } else {
      setMissingAssociation(true)
    }
  }, [])

  if (missingAssociation || _.isEmpty(event.outputMappings)) {
    // dont have enough information to display an item
    return null
  }

  const changeElements = event.outputMappings
    .map((mapping, index) => renderEventChange({ mapping, workflowEntity: workflowEntity, index }))
    .filter(Boolean)

  if (!_.isEmpty(changeElements) || settings.isAdmin) {
    return null
  }

  return (
    <div className={classNames('c-timelineEvent', className)}>
      <div
        className={classNames('c-timelineEvent-line', className, {
          'c-timelineEvent-line--topCutOff': isFirst,
        })}
      />
      <div className='c-timelineEvent-dot' />
      <div className='c-timelineEvent-contentContainer'>
        <div className='c-timelineEvent-header'>
          <div className='c-timelineEvent-author'>
            <Avatar
              className='c-timelineEvent-avatar'
              name={createdBy.displayName}
              size='xs'
            />
            <Tooltip
              disabled={!event.deviceInfo}
              content={renderDeviceInfoTooltipContent(event.deviceInfo)}
              position={Position.TOP}
            >
              <Label className='c-timelineEvent-authorName'>
                {createdBy.displayName}
                {renderAuthorHeader(createdBy, owner?.firm)}
              </Label>
            </Tooltip>
          </div>
          <HelpBlock className='c-timelineEvent-timestamp'>
            {renderEventTimestamp(creationDate, processedDate, settings)}
          </HelpBlock>
        </div>
        <div className='c-timelineEvent-body'>
          {renderEntityBlock(entity)}
          <EventChanges>{changeElements}</EventChanges>
        </div>
        <div className='c-timelineEvent-footer c-helpBlock'>
          {createdAt && renderDistance(event, workflowEntity, 'miles', settings, appointmentDestinationPath)}
        </div>
      </div>
    </div>
  )
}

function renderEntityBlock(entity: any) {
  if (entity?.hasMixin(SchemaIds.SHARE)) {
    const sharedBy = entity.share.sharedBy.displayName
    const documentName = entity.share.entity.displayName
    const sharedTo = getSharedToLabel(entity.share)
    return <p>{sharedBy} shared {getEntityLink(entity.share.entity.uniqueId, documentName)} with <strong>{sharedTo}</strong></p>
  } else if (entity) {
    return <p>Created {entity.entityType} {getEntityLink(entity.uniqueId, entity.displayName)}</p>
  } else {
    return <p>Loading...</p>
  }
}

function getEntityLink(id, name) {
  return <a href={`/entity/${id}`} target='_blank' rel='noopener noreferrer'>{name}</a>
}

function getSharedToLabel(shareItem) {
  const { sharedTo } = shareItem
  if (sharedTo.firstName) {
    return sharedTo.firstName + ' ' + sharedTo.lastName
  }
  if (sharedTo.email) {
    return sharedTo.email
  }
  if (sharedTo.phoneNumber) {
    return sharedTo.phoneNumber.phone
  }
  return ''
}
