import _ from 'lodash'
import React from 'react'

import { IQuantityInputProps } from 'browser/components/atomic-elements/atoms/input/quantity-input'
import 'browser/components/atomic-elements/molecules/dimensions-field/_dimensions-field.scss'
import { QuantityField } from 'browser/components/atomic-elements/molecules/fields/quantity-field'

const MeasurementOptions = [
  { label: 'Ft', value: 'Feet' },
  { label: 'In', value: 'Inches' },
]

interface IDimensionsField extends IQuantityInputProps {
  density?: string
  errorsMap?: any
  label: string
  labelProps?: any
}

export class DimensionsField extends React.Component<IDimensionsField, any> {

  public static defaultProps: Partial<IDimensionsField> = {
    defaultUnit: 'Feet',
    errorText: '',
    isDisabled: false,
    isHorizontalLayout: false,
    label: '',
    type: 'text',
  }

  public render() {
    const {
      errorsMap,
      label,
      labelProps,
      ...quantityInputProps
    } = this.props

    const { width, height, length } = this.getValue()

    return (
      <div>
        <QuantityField
          {...quantityInputProps}
          label='Length'
          placeholder='0'
          errorsMap={_.get(errorsMap, 'length')}
          onChange={(value) => this.handleChange('length', value)}
          unitOptions={MeasurementOptions}
          value={length}
        />
        <QuantityField
          {...quantityInputProps}
          label='Width'
          placeholder='0'
          errorsMap={_.get(errorsMap, 'width')}
          onChange={(value) => this.handleChange('width', value)}
          unitOptions={MeasurementOptions}
          value={width}
        />
        <QuantityField
          {...quantityInputProps}
          label='Height'
          placeholder='0'
          errorsMap={_.get(errorsMap, 'height')}
          onChange={(value) => this.handleChange('height', value)}
          unitOptions={MeasurementOptions}
          value={height}
        />
      </div>
    )
  }

  private getValue() {
    return this.props.value || {}
  }

  private handleChange = (key, value) => {
    const { onChange } = this.props
    const dimensions = this.getValue()
    _.set(dimensions, key, value)
    if (dimensions.height || dimensions.width || dimensions.length) {
      onChange(dimensions)
    } else {
      onChange(undefined)
    }
  }
}
