import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { List } from 'browser/components/atomic-elements/atoms/list'
import { IRenderListItemProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { SelectList } from 'browser/components/atomic-elements/atoms/select/select-list'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'

interface ISchemasSectionProps extends IBaseProps {
  bundle: any
}

interface ISchemasSectionState {
  isLoading: boolean
  isSaving: boolean
}

export class SchemasSection extends React.Component<ISchemasSectionProps, ISchemasSectionState> {

  public store: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
  }

  public render() {
    const { bundle } = this.props
    const { applicationBundle } = bundle
    let baseSchema = null
    let otherSchemas = []
    if (applicationBundle.extends) {
      [baseSchema, ...otherSchemas] = applicationBundle.extends
    }

    return (
      <Section
        title='Schemas'
      >
        <div className='row'>
          <div className='col-xs-4'>
            <p>
              Lists all available schemas and hide/unhide certain schemas.
            </p>
          </div>
          <div className='col-xs-8'>
            {baseSchema && this.renderSchemasForBundle(baseSchema, 'These schemas cannot be removed or hidden.')}
            {_.map(otherSchemas, (schema, key) => (
              this.renderSchemasForBundle(schema, 'Schemas imported by bundle. They can be hidden or unhidden.', key)
            ))}
            <div className='row'>
              <div className='col-xs-8'>
                <TetherTarget
                  tethered={this.renderAddSchemaPopover()}
                >
                  <Button>
                    Add additional schema
                  </Button>
                </TetherTarget>
              </div>
            </div>
          </div>
        </div>
      </Section>
    )
  }

  public renderSchemasForBundle(edge, helpText, key?) {
    if (!edge) {
      return
    }
    const bundle = this.store.getRecord(edge.entityId)
    const schemas = bundle.applicationBundle.schemas
    return (
      <div
        className='u-bumperBottom'
        key={key}
      >
        <Label>{edge.displayName}</Label>
        <div className='row'>
          <div className='col-xs-8'>
            <List
              renderListItem={this.renderSchemaItem}
              value={schemas}
            />
          </div>
          <div className='col-xs-4'>
            <HelpBlock>
              {helpText}
            </HelpBlock>
          </div>
        </div>
      </div>
    )
  }

  public renderAddSchemaPopover() {
    return (
      <Popover
        className='collapse'
      >
        <SelectList
          autoFocus={true}
          options={[]}
        />
      </Popover>
    )
  }

  private renderSchemaItem = (props: IRenderListItemProps) => {
    const { item, index } = props
    const schema = this.store.getRecord(item.entityId)
    return (
      <div
        className='u-flex u-justifyContentSpaceBetween'
        key={index}
      >
        <div>
          {schema.displayName}
        </div>
        <Button
          className={Classes.MINIMAL}
          size='xs'
        >
          Hide
        </Button>
      </div>
    )
  }
}
