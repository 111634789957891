import _ from 'lodash'

export class ChangeTracker {
  private lastKnownState

  constructor() {
    this.lastKnownState = {}
  }

  public trackChange(key, value) {
    this.lastKnownState[key] = value
  }

  public isChangedSinceTracked(key, value) {
    return !_.isEqual(_.get(this.lastKnownState, key), value)
  }
}
