import React, { useEffect, useState, useRef, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import _ from 'lodash'
import { Tab as BlueprintTab, Tabs as BlueprintTabs } from '@blueprintjs/core'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { VisibilityTimer } from 'browser/app/utils/visibility-timer'
import { getDefaultTab, getTabIdFromTitle } from './utils'

import 'browser/components/atomic-elements/atoms/tabs/_tabs.scss'

/**
 * @uiComponent
 */
interface ITabsProps extends IBaseProps {
  vertical?: boolean
  tabClassName?: string
  renderActiveTabPanelOnly?: boolean
  entity: any
  forceUpdate(): () => void
  isGridContent?: boolean
  defaultTab: number | string
  selectedTab: number | string
  location: Location
}

const BaseTabs: React.FC<ITabsProps> = ({
  children,
  className,
  renderActiveTabPanelOnly,
  tabClassName,
  entity,
  vertical,
  forceUpdate,
  defaultTab,
  selectedTab,
  location,
}) => {
  const [showUpdateBadge, setShowUpdateBadge] = useState(false)
  const [hasNewActivity, setHasNewActivity] = useState(false)
  const [activityTabId, setActivityTabId] = useState(null)
  const [selectedTabId, setSelectedTabId] = useState(undefined)
  const [timer, setTimer] = useState(null)
  const lastUpdated = useRef(moment().startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS-00'))

  const resolvedDefault = useMemo(() => getDefaultTab(defaultTab, children, location),[])

  useEffect(() => {
    let timeoutId = null
    const timeout = 15000

    React.Children.map(children, (tab, index) => {
      const tabElement = tab as React.ReactElement<any>
      if (tabElement.props.showActivityUpdates) {
        setActivityTabId(index)
        setTimer(new VisibilityTimer(refreshFn, 30000))
        refreshFn()
      }
      if (tabElement.props.refresh) {
        const refreshFn = () => {
          apis
            .getStore()
            .findRecord(tabElement.props.entity.uniqueId)
            .then((record) => {
              forceUpdate()
            })
            .finally(() => {
              timeoutId = setTimeout(refreshFn, timeout)
            })
        }
        timeoutId = setTimeout(refreshFn, timeout)
      }
    })

    return () => {
      if (timer) {
        timer.dispose()
      }
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [])

  useEffect(() => {
    if (hasNewActivity && (!selectedTabId || selectedTabId !== activityTabId)) {
      setShowUpdateBadge(true)
    } else {
      setShowUpdateBadge(false)
      setHasNewActivity(false)
    }
  }, [hasNewActivity])

  const refreshFn = () => {
    const limit = 1

    const promise = apis.getComments(entity.uniqueId, lastUpdated.current, limit)
    promise.then(handleActivityData)

    return promise
  }

  useEffect(() => {
    if (_.isString(selectedTab)) {
      const tabId = getTabIdFromTitle(selectedTab, children)
      if (tabId >= 0) {
        setSelectedTabId(tabId)
      } else {
        console.warn(`Failed to set selected tab to "${selectedTab}"`)
      }
    } else {
      setSelectedTabId(selectedTab)
    }
  }, [selectedTab])

  const handleActivityData = (entities) => {
    if (entities.length > 0) {
      setHasNewActivity(true)
    }
  }

  const renderTitle = ({ title, isActivityTab }) => {
    return (
      <div className="c-tab--title">
        {title} {isActivityTab && showUpdateBadge && <div className="c-tab--update-badge" />}
      </div>
    )
  }

  const renderTabs = () => {
    return React.Children.map(children, (tab, index) => {
      const tabElement = tab as React.ReactElement<any>
      const title = tabElement.props.tabLabel
      const isActivityTab = tabElement.props.showActivityUpdates

      const gridMode = tabElement.props.isGridContent ? 'grid-content' : 'grid-block'

      return (
        <BlueprintTab
          className={classNames(`c-tab ${gridMode}`, tabClassName)}
          id={index}
          title={renderTitle({ title, isActivityTab })}
          panel={tabElement}
          disabled={tabElement.props.disabled}
        />
      )
    })
  }

  return (
    <BlueprintTabs
      className={classNames('grid-block vertical c-tabs', className)}
      id={uuidv4()}
      renderActiveTabPanelOnly={renderActiveTabPanelOnly}
      defaultSelectedTabId={resolvedDefault}
      selectedTabId={selectedTabId}
      onChange={(newTabId, {}, {}) => {
        setSelectedTabId(newTabId)
        if (newTabId === activityTabId) {
          setHasNewActivity(false)
        }
        lastUpdated.current = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS-00')
      }}
      vertical={vertical}
    >
      {renderTabs()}
      <BlueprintTabs.Expander />
    </BlueprintTabs>
  )
}

BaseTabs.defaultProps = {
  renderActiveTabPanelOnly: true,
}

export const Tabs = withRouter(BaseTabs) as React.ComponentType<ITabsProps>
