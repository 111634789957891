import { RadioGroup as BlueprintRadioGroup } from '@blueprintjs/core'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/radio/_radio-group.scss'

/**
 * @uiComponent
 */
interface IRadioGroupProps extends IBaseProps {
  isBoolean?: boolean
  isDisabled?: boolean
  isHorizontalLayout?: boolean
  isOptionsHorizontal?: boolean
  isOptional?: boolean
  isRequired?: boolean
  name?: string
  onChange: (value: any) => void
  options?: any[]
  size?: string
  value: string
}

export class RadioGroup extends React.Component<IRadioGroupProps, any> {

  public render() {
    const {
      children,
      className,
      isDisabled,
      isOptional,
      isRequired,
      isHorizontalLayout,
      isOptionsHorizontal,
      name,
      size,
    } = this.props
    const options = this.getOptions()
    const value = this.getValue()
    const sizeClass = _.isEmpty(this.props.size) ? '' : `c-radioGroup--${this.props.size}`

    return (
      <BlueprintRadioGroup
        className={classNames('c-radioGroupOptions', className, sizeClass, {
          'c-radioGroupOptions--horizontal': isHorizontalLayout,
          'c-radioGroupOptions--isOptionsHorizontal': isOptionsHorizontal,
          'c-radioGroupOptions--isRequired': isRequired,
        })}
        disabled={isDisabled}
        name={name}
        onChange={this.handleChange}
        options={options}
        selectedValue={value}
      >
        {children}
      </BlueprintRadioGroup>
    )
  }

  private handleChange = (event) => {
    const { isBoolean, onChange } = this.props
    const value = event.target.value
    if (isBoolean) {
      return onChange(value === 'true')
    }
    onChange(value)
  }

  private getOptions(): any[] {
    const { isBoolean, options } = this.props
    if (isBoolean) {
      return [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ]
    }
    return options
  }

  private getValue(): string {
    const { isBoolean, value } = this.props
    if (isBoolean) {
      return value ? 'true' : 'false'
    }
    return value
  }
}
