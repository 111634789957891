import _ from 'lodash'
import moment from 'moment-timezone'
import { DateTimeWithTimezone } from '../../../generated/server-types/entity/definitions/dateTimeWithTimezone'

// TODO: integrate TrueTime.now() promise for mobile?

export function now() {
  return moment().add(this.getTruetimeOffset(), 'ms').format()
}

export function nowDateTimeWithTimeZone() {
  const boundNow = now.bind(this);
  return {
    dateTime: boundNow(),
    timezone: moment.tz.guess(true)
  }
}

/** @deprecated Use `now` and/or `utc(dateTime)`. */
export function nowUtc() {
  console.log(`TruetimeOffset ${this.getTruetimeOffset()} moment ${moment().toISOString()}`)
  return moment().add(this.getTruetimeOffset(), 'ms').toISOString()
}

export function today() {
  return moment().startOf('d').format()
}

export function utc(dateTime?: moment.MomentInput | DateTimeWithTimezone): string {
  return getMoment(dateTime).toISOString()
}

export function unixSeconds(dateTime?: moment.MomentInput | DateTimeWithTimezone) {
  return getMoment(dateTime).unix()
}

export function unixMilliseconds(dateTime?: moment.MomentInput | DateTimeWithTimezone) {
  return getMoment(dateTime).valueOf()
}

export function timezoneOffset(dateTime?: moment.MomentInput | DateTimeWithTimezone): number {
  const dt = isDateTimeWithTimezone(dateTime) ? momentLocal(dateTime) : moment.parseZone(dateTime)
  return dt.utcOffset()
}

export function addDate(
  dateTime: moment.MomentInput | DateTimeWithTimezone,
  ...rest: any[]
): string {
  return getMoment(dateTime)
    .add(...rest)
    .format()
}

export function substractDate(
  dateTime: moment.MomentInput | DateTimeWithTimezone,
  ...rest: any[]
): string {
  return getMoment(dateTime)
    .subtract(...rest)
    .format()
}

export function startOf(
  dateTime: moment.MomentInput | DateTimeWithTimezone,
  unit: moment.unitOfTime.StartOf
): string {
  return getMoment(dateTime).startOf(unit).format()
}

export function endOf(
  dateTime: moment.MomentInput | DateTimeWithTimezone,
  unit: moment.unitOfTime.StartOf
): string {
  return getMoment(dateTime).endOf(unit).format()
}

export function isBefore(
  dateTime: moment.MomentInput | DateTimeWithTimezone,
  other: moment.MomentInput | DateTimeWithTimezone
): boolean {
  const dt = getMoment(dateTime)
  const otherDt = getMoment(other)
  return dt.isBefore(otherDt)
}

export function isAfter(
  dateTime: moment.MomentInput | DateTimeWithTimezone,
  other: moment.MomentInput | DateTimeWithTimezone
): boolean {
  const dt = getMoment(dateTime)
  const otherDt = getMoment(other)
  return dt.isAfter(otherDt)
}

export function isBetween(
  dateTime: moment.MomentInput | DateTimeWithTimezone,
  start: moment.MomentInput | DateTimeWithTimezone,
  end: moment.MomentInput | DateTimeWithTimezone
): boolean {
  const dt = getMoment(dateTime)
  const startDt = getMoment(start)
  const endDt = getMoment(end)
  return dt.isBetween(startDt, endDt)
}

/* Helper function to get a local date time object from moment-timezone */
function getMoment(dateTime: moment.MomentInput | DateTimeWithTimezone): moment.Moment {
  return isDateTimeWithTimezone(dateTime) ? momentLocal(dateTime) : moment(dateTime)
}

function momentLocal(dateTime: DateTimeWithTimezone): moment.Moment {
  return moment.tz(
    dateTime?.dateTime,
    moment.ISO_8601,
    dateTime.timezone ?? moment['defaultZone'].name
  )
}

function isDateTimeWithTimezone(dateTime: any): dateTime is DateTimeWithTimezone {
  return _.isString(dateTime?.dateTime)
}

export function formatDate(...params: any[]): string {
  const date = params[0]
  let format = params[1]
  if (!date) {
    return null
  }
  if (!format) {
    format = 'MM/DD/YYYY'
  }
  return moment(date).format(format)
}

export function duration(...params: any[]): number {
  const start = _.get(params, '[0].dateTime', params[0])
  const end = _.get(params, '[1].dateTime', params[1])
  const format = params[2] || 'm'
  if (!start || !end) {
    return
  }
  const diff = moment(end).diff(moment(start))
  return moment.duration(diff).as(format)
}

/**
 *
 * @param minutes duration in minutes
 * @param format  ex "dd hh mm" -> "1 day 3 hours 1 minute"
 */
export function formatDuration(durationMinutes, durationFormat = "hh mm") {
  const hasDays = durationFormat.includes('dd')
  const hasHours = durationFormat.includes('hh')
  const hasMinutes = durationFormat.includes('mm')
  const oneDay = 60 * 24

  const days = hasDays && Math.floor(durationMinutes / (oneDay))
  const hours = hasHours && (Math.floor((durationMinutes - days * oneDay) / 60))
  const minutes = hasMinutes && (durationMinutes - days * oneDay -  hours * 60)


  const formattedValues = {
    dd: hasDays && days <= 1 ? `${days} day` : `${days} days`,
    hh: hasHours && hours <= 1 ? `${hours} hour` : `${hours} hours`,
    mm: hasMinutes && minutes <= 1 ? `${minutes} minute` : `${minutes} minutes`,
  }

  return durationFormat
   .replace('dd', formattedValues['dd'])
   .replace('hh', formattedValues['hh'])
   .replace('mm', formattedValues['mm'])
}
