import React from 'react'
import { translateString } from 'shared-libs/helpers/utils'
import { globalTranslationTable } from 'browser/mobile/util/global-translation-table'

import './_empty-state.scss'

const VectorLogo = require('images/onboarding/vector-logo-horizontal.svg')

const translatedTagline = translateString('Workflow_Empty_State_Tagline', globalTranslationTable)

export function MobileWorkflowEmptyState() {
  return (
    <div className="c-workflow-emptyState-container">
      <img className="c-workflow-emptyState-logo" src={VectorLogo} />
      <div>{translatedTagline}</div>
    </div>
  )
}
