import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { IInputProps } from 'browser/components/atomic-elements/atoms/input/input'
import { TimeInput } from 'browser/components/atomic-elements/atoms/input/time-input'
import { List } from 'browser/components/atomic-elements/atoms/list'
import { IRenderListItemProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import { CheckboxField } from 'browser/components/atomic-elements/molecules/fields/checkbox-field'
import 'browser/components/atomic-elements/molecules/fields/hours-of-operation-field/_hours-of-operation-row.scss'

export interface IHoursOfOperationRowProps extends IInputProps {
  errorsMap?: any
}

interface IHoursOfOperationRowState {
  value: any
}

export class HoursOfOperationRow extends React.Component<IHoursOfOperationRowProps, IHoursOfOperationRowState> {

  constructor(props) {
    super(props)
    this.state = {
      value: props.value || {},
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: nextProps.value || {},
      })
    }
  }

  public render() {
    const { className } = this.props
    const { value } = this.state
    const { isOpen24Hours } = value
    return (
      <div className={classNames('c-hoursOfOperationRow', className)}>
        {this.handleRenderDaysOfWeek(value)}
        <div className='flex u-darkBorderBottom u-innerBumperLeft--xs u-innerBumperRight--xs u-innerBumperTop--xs u-innerBumperBottom--xs'>
          {this.renderHoursList(value)}
          <div className='u-flexShrink u-innerBumperTop--xs u-borderLeft u-innerBumperLeft--xs u-bumperLeft--xs'>
            <CheckboxField
              onChange={(checked) => this.handleIsOpen24HoursChange(checked)}
              label='24h'
              value={isOpen24Hours}
            />
          </div>
        </div>
      </div>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Renders
  //////////////////////////////////////////////////////////////////////////////

  private handleRenderDaysOfWeek(value) {
    const daysOfWeekMap = {}
    _.forEach(value.daysOfWeek, (dayOfWeek) => {
      daysOfWeekMap[dayOfWeek] = true
    })
    return (
      <div
        className={classNames(
          'c-buttonGroup--square u-bumperTop--xs u-bumperLeft--xs u-bumperRight--xs',
          Classes.BUTTON_GROUP,
          Classes.FILL
        )}
      >
        {this.renderDayOfWeekButton(daysOfWeekMap, 'Sunday', 'Sun')}
        {this.renderDayOfWeekButton(daysOfWeekMap, 'Monday', 'Mon')}
        {this.renderDayOfWeekButton(daysOfWeekMap, 'Tuesday', 'Tue')}
        {this.renderDayOfWeekButton(daysOfWeekMap, 'Wednesday', 'Wed')}
        {this.renderDayOfWeekButton(daysOfWeekMap, 'Thursday', 'Thu')}
        {this.renderDayOfWeekButton(daysOfWeekMap, 'Friday', 'Fri')}
        {this.renderDayOfWeekButton(daysOfWeekMap, 'Saturday', 'Sat')}
      </div>
    )
  }

  private renderDayOfWeekButton(activeMap, dayOfWeek, displayName) {
    const isActive = activeMap[dayOfWeek]
    return (
      <Button
        isActive={isActive}
        onClick={() => this.handleDayOfWeekChange(dayOfWeek, !isActive)}
      >
        {displayName}
      </Button>
    )
  }

  private handleRenderHoursItem = (props: IRenderListItemProps) => {
    const { item, index, onRemove } = props
    const { value } = this.state
    const { isOpen24Hours } = value
    const { errorsMap } = this.props
    const startTimeError = _.get(errorsMap, ['hours', index, 'start', '_errors', 0], null)
    const endTimeError = _.get(errorsMap, ['hours', index, 'end', '_errors', 0], null)
    const isDeleteButtonDisabled = _.isNil(onRemove) ? true : false
    return (
      <div className='flex u-flexShrink'>
        <TimeInput
          className='u-flexShrink'
          isDisabled={isOpen24Hours}
          errorText={startTimeError}
          onChange={(time) => this.handleHourItemChange(item, index, 'start.dateTime', time)}
          value={_.get(item, 'start.dateTime')}
        />
        <div className='c-fakeInputContainer--sm c-hoursOfOperationRow-separator'>
          -
        </div>
        <TimeInput
          className='u-flexShrink'
          isDisabled={isOpen24Hours}
          errorText={endTimeError}
          onChange={(time) => this.handleHourItemChange(item, index, 'end.dateTime', time)}
          value={_.get(item, 'end.dateTime')}
        />
        <Button
          className={classNames(Classes.MINIMAL)}
          isDisabled={isDeleteButtonDisabled || isOpen24Hours}
          onClick={onRemove}
        >
          <Icon
            icon={IconNames.CROSS}
          />
        </Button>
      </div>
    )
  }

  private renderHoursList(value) {
    const { hours } = value
    return (
      <List
        onChange={this.handleHoursChange}
        itemClassName='u-flexShrink u-darkBorderBottom'
        renderListItem={this.handleRenderHoursItem}
        showPlaceholderItem={true}
        showItemDeleteButton={false}
        value={hours}
      />
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleDayOfWeekChange = (dayOfWeek, isActive) => {
    const { onChange } = this.props
    const { value } = this.state
    if (isActive) {
      const daysOfWeek = _.get(value, 'daysOfWeek', [])
      daysOfWeek.push(dayOfWeek)
      _.set(value, 'daysOfWeek', daysOfWeek)
    } else {
      _.pull(value.daysOfWeek, dayOfWeek)
    }
    onChange(value)
  }

  private handleIsOpen24HoursChange = (isOpen24Hours) => {
    const { onChange } = this.props
    const { value } = this.state
    value.isOpen24Hours = isOpen24Hours
    // if is not open 24 hours, we want to delete isOpen24Hours so that
    // the validation will force us to enter the hours
    if (!isOpen24Hours) {
      delete value.isOpen24Hours
    }
    delete value.hours
    onChange(value)
  }

  private handleHoursChange = (hours) => {
    const { onChange } = this.props
    const { value } = this.state
    value.hours = hours
    onChange(value)
  }

  private handleHourItemChange = (hour, index, key, time) => {
    const { value } = this.state
    const hours = _.get(value, 'hours', [])
    hours[index] = hour
    _.set(hour, key, time)
    this.handleHoursChange(hours)
  }
}
