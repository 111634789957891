import _ from 'lodash'

import { Entity } from '../entity'
import { AbstractTriggers } from './abstract-triggers'
import { EntityType } from './entity-type'

export class OrbCommDvir extends EntityType implements AbstractTriggers {

  public beforeChangeTrigger(entity: Entity, path: any, oldValue: any, newValue: any, silentUpdate = false) {}

  public validateTrigger(entity: Entity, errors: any[]) {
    const questions = [
      'vehicleServiceBrakeConnection',
      'vehicleParkingBrake',
      'vehicleSteeringMechanism',
      'vehicleLightingDeviceReflectors',
      'vehicleTires',
      'vehicleHorn',
      'vehicleWindshieldWiper',
      'vehicleRearViewMirrors',
      'vehicleCouplingDevice',
      'vehicleWheelsAndRims',
      'vehicleEmergencyEquipment',
      'trailerBrakes',
      'trailerLightingLampsMarkers',
      'trailerWheelsAndRims',
      'trailerAirlineConnectionHosesCoupler',
      'trailerKingPin',
      'trailerRailsAndSupportFrames',
      'trailerTieDownBolsters',
      'trailerLockingPinsClevisesClampsHooks',
      'trailerSliderSliderFrameHooks',
      'eMFluidLevels',
      'eMRadio',
      'eMDefrosterHeater',
      'eMBeltsAndHoses',
      'eMOilPressure',
    ]

    _.forEach(questions, (question: string) => {
      this.validateQuestion(entity, errors, question)
    })
  }

  private validateQuestion(entity: Entity, errors: any[], field: string) {
    const question = _.get(entity, `orbCommDvir.${field}`)
    const exceptionPath = field + 'Exception'
    const attachmentsPath = field + 'ExceptionAttachments'
    if (question === 'No') {
      const exception = _.get(entity, `orbCommDvir.${exceptionPath}`, null)
      const attachments = _.get(entity, `orbCommDvir.${attachmentsPath}`, null)
      if (exception === null) {
        errors.push({
          errors: [`exception is required`],
          path: ['orbCommDvir', exceptionPath],
        })
      }
      if (attachments === null || attachments.length < 1) {
        errors.push({
          errors: [`attachments are required`],
          path: ['orbCommDvir', attachmentsPath],
        })
      }
    }
  }
}
