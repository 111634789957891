import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { AppStatusContext } from 'browser/contexts/app-status/AppStatusContext'
import _ from 'lodash'
import './_notification-bar.scss'
import classNames from 'classnames'

interface INotificationBarProps extends IBaseProps {
  isNavigationFixed?: boolean
  isSideNavigationBarVisible?: boolean
}

export class NotificationBar extends React.Component<INotificationBarProps, any> {
  public render() {
    const { isNavigationFixed, isSideNavigationBarVisible } = this.props
    return (
      <AppStatusContext.Consumer>
        {({ computed }) => {
          if (!computed || !computed.title) {
            return <></>
          }
          return (
            <div className={classNames("notification-bar", {
              'notification-bar__bottom': isNavigationFixed,
              'notification-bar__side-nav-open': isSideNavigationBarVisible
            })}>
              <p className="notification-bar-title">{computed.title}</p>
              <p className="notification-bar-message">{computed.message}</p>
            </div>
          )
        }}
      </AppStatusContext.Consumer>
    )
  }
}
