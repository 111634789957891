import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import { Cell } from 'fixed-data-table-2'
import _ from 'lodash'
import React from 'react'

import { Column } from '../..'

import 'browser/components/atomic-elements/atoms/table/cells/internal/_table-primary-column-cell.scss'

export const LOAD_MORE_PREFIX = 'load-more-row-'

interface ITablePrimaryColumnCellProps {
  column: Column
  isGroupExpansionRow: boolean
  level: number
  onPaginateGroup: (event: any, rowIndex: number) => void
  onToggleExpandCollapseRow: (event: any, rowIndex: number) => void
  row: any
  rowIndex: number
  showToggle: boolean
}

export const TablePrimaryColumnCell: React.FC<ITablePrimaryColumnCellProps> = (props) => {
  const {
    children, column, isGroupExpansionRow, level, row, rowIndex, showToggle,
    onPaginateGroup, onToggleExpandCollapseRow, ...cellProps
  } = props
  const levelClassName = `c-tableCellLevel--${level}`
  const dataDebugId = column.debugKey ? _.get(row, column.debugKey) : ''
  const handlePaginateGroup = (event) => {
    onPaginateGroup(event, rowIndex)
  }
  const handleToggleExpandCollapse = (event) => {
    onToggleExpandCollapseRow(event, rowIndex)
  }
  const renderPrimaryColumnPaginateGroupLink = () => {
    return (
      <a onClick={handlePaginateGroup} id={`${LOAD_MORE_PREFIX}${rowIndex}`} className="c-tableCellLoadMore" href="javascript:void(0)">
        Click to load more...
      </a>
    )
  }
  const renderPrimaryColumnToggle = () => {
    return (
      <div
        className='c-tableCellCollapseExpand'
        onClick={handleToggleExpandCollapse}
      >
        <Icon
          className='c-tableCellCollapseExpand-icon'
          icon={IconNames.CHEVRON_RIGHT}
        />
      </div>
    )
  }

  return (
    <Cell {...cellProps}>
      <div
        className={classNames('c-tableCellContent', column.className, levelClassName, {
          'has-children': showToggle,
        })}
        data-debug-id={`primaryTableColumn-${dataDebugId}`}
      >
        {showToggle ? renderPrimaryColumnToggle() : null}
        {isGroupExpansionRow ? renderPrimaryColumnPaginateGroupLink() : children}
      </div>
    </Cell>
  )
}
