export interface ICache<K, V> {
  get(key: K): V
  has(key: K): boolean
  set(key: K, value: V): void
  readonly size: number
}

export class LRUCache<K, V> implements ICache<K, V> {
  private capacity: number
  private cache: Map<K, V>

  constructor(capacity: number) {
    this.capacity = capacity
    this.cache = new Map()
  }

  public has(key: K): boolean {
    return this.cache.has(key)
  }

  public get(key: K): V {
    if (!this.cache.has(key)) {
      return undefined
    }
    const value = this.cache.get(key)
    this.cache.delete(key)
    this.cache.set(key, value)
    return value
  }

  public set(key: K, value: V): number {
    if (this.has(key)) {
      this.cache.delete(key)
    } else if (this.cache.size >= this.capacity) {
      const oldestKey = this.cache.keys().next().value
      this.cache.delete(oldestKey)
    }
    this.cache.set(key, value)
    return this.cache.size
  }

  public get size(): number {
    return this.cache.size
  }
}
