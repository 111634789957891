import _ from 'lodash'
import { Image as ImageModel } from 'shared-libs/models/image'

import apis from 'browser/app/models/apis'
import { JSONSchemaResolver } from 'shared-libs/resolvers/json-schema-resolver';

const DoctypeOtherImage = require('images/doctype-other.svg')
const FILE_SCHEMA = '/1.0/entities/metadata/entity.json#/definitions/file'
const PDF_SCHEMA = '/1.0/entities/metadata/entity.json#/definitions/pdf'
const IMAGE_SCHEMA = '/1.0/entities/metadata/entity.json#/definitions/image'

export function getGenericFileImage(file) {
  return (
    {
      height: 600,
      name: file.name,
      source: [
        {
          height: 600,
          uri: DoctypeOtherImage,
          width: 452,
        },
      ],
      transformations: [],
      type: 'image',
      uri: DoctypeOtherImage,
      width: 452,
    }
  )
}

export function flattenAttachment(attachment: any, schema: any = {}, entity): [ ImageModel[], any ] {
  let files
  const schemaType = schema[JSONSchemaResolver.REF_KEY]
  if (schema.type === 'array') {
    files = attachment
  } else if (schemaType === FILE_SCHEMA || schemaType === PDF_SCHEMA || schemaType === IMAGE_SCHEMA) {
    files = [attachment]
  } else {
    files = _.get(attachment, 'files', [])
  }
  const images = []
  const attachmentsByIndex = {}

  files.forEach((file, index) => {
    if (file.type === 'image') {
      const image: any = new ImageModel(apis, file, entity)
      image.isEditable = true
      attachmentsByIndex[images.length] = file
      images.push(image)
    } else if (file.type === 'pdf') {
      if (!file.preview) { return }
      const pdfImages = file.preview.map((preview) => {
        const image: any = new ImageModel(apis, preview, entity)
        image.parentFile = file
        image.isEditable = false
        return image
      })
      const indices = _.range(images.length, images.length + pdfImages.length)
      indices.forEach((i) => { attachmentsByIndex[i] = file })
      images.push(...pdfImages)
    } else {
      const imageFile = getGenericFileImage(file.name)
      const image: any = new ImageModel(apis, imageFile, entity)
      image.parentFile = file
      image.isEditable = false
      attachmentsByIndex[images.length] = file
      images.push(image)
    }
  })
  return [images, attachmentsByIndex]
}
