import Promise from 'bluebird'
import { browserHistory } from 'browser/history'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface IBatchCreationProgressPopoverProps extends IBaseProps {
  entity: any
  onClose: () => void
  onSave: (onProgress: (progress) => void) => Promise<any>
  redirectPath?: string
  shouldRedirectOnSuccess?: boolean
}

interface IBatchCreationProgressPopoverState {
  message: string
  showLink: boolean
}

export class BatchCreationProgressPopover
  extends React.Component<IBatchCreationProgressPopoverProps, IBatchCreationProgressPopoverState> {

  constructor(props) {
    super(props)
    this.state = {
      message: '',
      showLink: false,
    }
  }

  public componentDidMount() {
    const { onClose, onSave, redirectPath, shouldRedirectOnSuccess } = this.props
    onSave(this.handleProgress).then((entity) => {
      this.setState({ message: 'Processing...' })
      return entity.waitUntilIdle()
    }).then((entity) => {
      if (shouldRedirectOnSuccess) {
        browserHistory.push({ pathname: redirectPath })
      }
    }).finally(() => {
      this.setState({ message: '', showLink: true })
      setTimeout(() => { onClose() }, 7000)
    })
  }

  public render() {
    const { entity } = this.props
    const { message, showLink } = this.state
    return (
      <div className='u-flex u-justifyContentSpaceBetween' data-debug-id='toast'>
        <div className='u-ellipsis'>
          {entity.entityType}:
        </div>
        <div className='u-bumperLeft'>
          {message} {showLink && <a onClick={this.handleOnRedirect}> Import Results</a>}
        </div>
      </div>
    )
  }

  private handleProgress = (progress) => {
    this.setState({ message: `${Math.round(progress)}%` })
  }

  private handleOnRedirect = () => {
    const { redirectPath } = this.props
    browserHistory.push({ pathname: redirectPath })
  }
}
