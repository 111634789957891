import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { NavLink } from 'react-router-dom'
import apis from 'browser/app/models/apis'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CustomFormulas } from 'shared-libs/helpers/formulas'
import { evaluateFilters } from 'shared-libs/helpers/evaluation'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import 'browser/components/atomic-elements/organisms/side-navigation-bar-item/_side-navigation-bar-item.scss'

/**
 * @uiComponent
 */
interface ISideNavigationBarItemProps extends IBaseProps {
  count?: number
  to: string
  label: string
  showBadge?: boolean
  recordBadgeAtKey?: (key: string, remove: boolean) => void
  isClosed: boolean
  _key: string
}

export class SideNavigationBarItem extends React.Component<ISideNavigationBarItemProps, any> {
  private dataSet?: EntityDataSource

  public static defaultProps: Partial<ISideNavigationBarItemProps> = {
    count: 0,
    showBadge: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      count: 0,
      view: null
    }
  }

  public componentDidMount() {
    const to = this.props.to
    if (this.props.showBadge && !_.isEmpty(to) && to.includes("view")) {
      // cut out first 6 chars from /view/ in path to get id
      const viewId = to.substring(6)
      apis.getStore().findRecord(viewId)
        .then((view) => {
          this.setState({view})
          this.initDataSet(view)
        })
    }
  }

  public componentWillUnmount() {
    this.dataSet?.dispose()
    this.props.recordBadgeAtKey?.(this.props._key, true)
  }

  public render() {
    const { to, label, isClosed } = this.props
    return (
      <NavLink
        activeClassName='is-active'
        className={`c-sideNavigationBarItem ${isClosed && 'u-hide'}`}
        data-debug-id={`sideNavItem:${label}`}
        to={{
          pathname: to,
        }}
      >
        <div className='u-flex u-flexGrow u-alignItemsCenter'>
          <div className='c-sideNavigationBarItem-label u-flexGrow'>
            {label}
          </div>
          {this.renderCount()}
        </div>
      </NavLink>
    )
  }

  private initDataSet = (viewEntity) => {
    const settings = apis.getSettings()
    const viewState = _.get(viewEntity, 'view.state', {})
    const query = _.values(viewState.queries)[0]
    const rawFilters = _.isEmpty(query.filters) ? viewState.filters : query.filters
    const filters = evaluateFilters(rawFilters, { settings, ...CustomFormulas })
    const debug = { context: `sidenavBlueDot--${viewEntity.displayName}` }
    this.dataSet = new EntityDataSource({...query, filters, debug })
    this.dataSet.query.setSize(1)
    this.dataSet.setOnChange(() => {
      this.handleDataSetChange(this.dataSet)
    })
    this.dataSet.find()
    this.setState(
      {
        count: this.getBadgeCount(this.dataSet)
      }
    )
  }

  private getBadgeCount(dataSet: EntityDataSource) {
    return _.get(dataSet, 'result.metadata.totalEntityMatchCount')
  }

  private renderCount() {
    const { count } = this.state
    if (count > 0) {
      const isCountMinimal = false
      return (
        <div className={classNames('c-sideNavigationBarItem-count u-positionRelative', {
          'c-sideNavigationBarItem-count--minimal': isCountMinimal,
        })}>
          {count}
        </div>
      )
    }
  }

  private handleDataSetChange = (dataSet) => {
    const { view } = this.state
    const viewState = view.view.state
    const query = _.values(viewState.queries)[0]
    query.groups = dataSet.query.groups
    query.orders = dataSet.query.orders
    const count = this.getBadgeCount(dataSet)
    this.setState(
      {
        view: view,
        count
      }
    )

    const badgeKey = `${this.props._key} | ${this.props.to} | ${this.props.label}`
    this.props.recordBadgeAtKey?.(badgeKey, count == 0)
  }
}
