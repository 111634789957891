import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Motion, presets, spring } from 'react-motion'
import { Classes } from '@blueprintjs/core'

import apis from 'browser/app/models/apis'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { View } from 'browser/components/atomic-elements/atoms/view'
import 'browser/components/atomic-elements/organisms/view-header/_view-controls.scss'

// Sync with .c-viewControls
const VIEW_CONTROLS_WIDTH = 221

export interface IViewControlsProps extends IBaseProps {
  isVisible: boolean
  isSaving?: boolean
  handleResetButton: any
  handleSaveButton: any
}

interface IViewControlsState {
  animation: any
}

export class ViewControls extends React.Component<IViewControlsProps, IViewControlsState> {

  public static defaultProps: Partial<IViewControlsProps> = {
    isVisible: false,
  }

  constructor(props) {
    super(props)
    const { isVisible } = this.props
    const initialWidth = isVisible ? VIEW_CONTROLS_WIDTH : 0
    this.state = {
      animation: {
        from: {
          width: initialWidth,
        },
        to: {
          width: initialWidth,
        },
      },
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isVisible !== nextProps.isVisible) {
      const { animation } = this.state
      const nextAnimation = {
        from: animation.from,
        to: {
          width: spring(nextProps.isVisible ? VIEW_CONTROLS_WIDTH : 0),
        },
      }
      this.setState({ animation: nextAnimation })
    }
  }

  public render() {
    const { animation } = this.state
    return (
      <Motion
        defaultStyle={animation.from}
        style={animation.to}
      >
        {(style) => this.renderContent(style)}
      </Motion>
    )
  }

  private renderContent(style) {
    const { handleResetButton, handleSaveButton, isSaving } = this.props

    return (
      <div className='relative c-viewControls-container' style={style}>
        {this.renderItems()}
      </div>
    )
  }

  private renderItems() {
    const { handleResetButton, handleSaveButton, isSaving } = this.props

    return (
      <div className='flex absolute c-viewControls'>
        <CardHeaderItem
          className='c-cardHeader-item--separator u-bumperLeft--sm u-bumperRight--sm'
          key='separator'
        />
        <CardHeaderItem
          className='c-cardHeader-item--smallMargin'
          key='reset'
        >
          <Button
            className='u-nowrap'
            isDisabled={isSaving}
            onClick={handleResetButton}
          >
            Reset View
          </Button>
        </CardHeaderItem>
        <CardHeaderItem
          key='save'
        >
          <Button
            isLoading={isSaving}
            className={classNames('u-nowrap', Classes.INTENT_PRIMARY)}
            onClick={handleSaveButton}
          >
            Save View
          </Button>
        </CardHeaderItem>
      </div>
    )
  }
}
