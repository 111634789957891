import _ from 'lodash'
import React from 'react'
import { WebMercatorViewport } from 'viewport-mercator-project'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

function getPresenceMap(selection) {
  const mapping = {}
  _.forEach(selection, (item) => { mapping[item.uniqueId] = item })
  return mapping
}

interface IMarkerLayerProps extends IBaseProps {
  map?: object
  entities: any[]
  entityIdPath?: string
  geolocationPath: string | string[]
  hoveredEntities?: any[]
  MarkerComponent: any
  markersHeight: number
  markersWidth: number
  modifiers: (entity: any) => any
  onMouseOver?: (values: any[]) => void
  onMouseOut?: (values: any[]) => void
  onSelect?: (values: any[]) => void
  onViewportChange: (viewport: any) => void
  selectedEntities?: any[]
  width: number
  height: number
  viewport: any
}

export class MarkerLayer extends React.Component<IMarkerLayerProps, any> {
  public static defaultProps: Partial<IMarkerLayerProps> = {
    entityIdPath: 'uniqueId',
    onMouseOut: _.noop,
    onMouseOver: _.noop,
    onSelect: _.noop,
  }

  constructor(props) {
    super(props)
    this.state = this.getNextStateFromProps(props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selectedEntities !== nextProps.selectedEntities ||
        this.props.hoveredEntities !== nextProps.hoveredEntities) {
      this.setState(this.getNextStateFromProps(nextProps))
    }
  }

  public getNextStateFromProps(props) {
    const { selectedEntities, hoveredEntities } = props
    const hoveredMap = getPresenceMap(hoveredEntities)
    const selectedMap = getPresenceMap(selectedEntities)
    const hasHovered = !_.isEmpty(hoveredMap)
    const hasSelected = !_.isEmpty(selectedMap)
    return { hoveredMap, selectedMap, hasHovered, hasSelected }
  }

  public renderMarker(entity, project) {
    const {
      entityIdPath,
      geolocationPath,
      map,
      MarkerComponent,
      markersHeight,
      markersWidth,
      modifiers,
      onMouseOver,
      onMouseOut,
      onSelect,
      onViewportChange,
      viewport,
    } = this.props
    const geolocation = _.get(entity, geolocationPath) as any
    if (!geolocation) {
      return
    }
    const point = project([geolocation.longitude, geolocation.latitude])
    return (
      <MarkerComponent
        entity={entity}
        key={_.get(entity, entityIdPath)}
        map={map}
        modifiers={modifiers}
        onClick={() => onSelect([entity])}
        onMouseOver={() => onMouseOver([entity])}
        onMouseOut={() => onMouseOut([entity])}
        style={{
          left: point[0] - markersWidth / 2,
          position: 'absolute',
          top: point[1] - markersHeight,
        }}
        onViewportChange={onViewportChange}
        viewport={viewport}
      />
    )
  }

  public redraw({width, height, project, unproject}) {
    const { entities } = this.props
    const markers = []
    _.forEach(entities, (entity) => {
      const marker = this.renderMarker(entity, project)
      if (marker) {
        markers.push(marker)
      }
    })
    return markers
  }

  public render() {
    const { width, height, style, viewport } = this.props
    const divStyle: React.CSSProperties = {
      left: 0,
      position: 'absolute',
      top: 0,
      ...style,
    }
    const { project, unproject } = new WebMercatorViewport({
      height,
      latitude: viewport.latitude,
      longitude: viewport.longitude,
      width,
      zoom: viewport.zoom,
    })
    return (
      <div
        ref='overlay'
        style={divStyle}
      >
        {this.redraw({width, height, project, unproject})}
      </div>
    )
  }
}
