import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Header } from 'browser/components/atomic-elements/atoms/header/header'
import { DateInput } from 'browser/components/atomic-elements/atoms/input/date-input/date-input'
import { QuantityInput } from 'browser/components/atomic-elements/atoms/input/quantity-input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { Sheet } from 'browser/components/atomic-elements/atoms/sheet'
import { TextareaField } from 'browser/components/atomic-elements/molecules/fields/textarea-field'

interface IAccountingPaymentSheetProps extends IBaseProps {
  accountingEntity: any
  businessEntity: any
  updateExportPayments: any
}

interface IAccountingPaymentSheetStates {
  amount: any
  date: any
  isSaving: boolean
  notes: any
}

export class AccountingPaymentSheet
  extends React.Component<IAccountingPaymentSheetProps, IAccountingPaymentSheetStates> {

  private sheet: Sheet

  constructor(props) {
    super(props)
    this.state = {
      amount: null,
      date: moment(),
      isSaving: false,
      notes: null,
    }
  }

  public render() {
    const { amount, date, isSaving, notes } = this.state
    const address = null
    const handleRef = (ref) => { this.sheet = ref }
    return (
      <Sheet
        ref={handleRef}
        size='sm'
        bodyClassName='c-sheet-body--padded'
        header={
          <Header
            title='Make a Payment'
          />
        }
        footer={
          <Footer
            isPrimaryButtonLoading={isSaving}
            isVisible={true}
            onCancelButtonClick={this.handleClose}
            onPrimaryButtonClick={this.handleSave}
          />
        }
      >
        <FormTable>
          <FormGroup isHorizontalLayout={true}>
            <Label isHorizontalLayout={true} size='sm'>
              Payment Date
            </Label>
            <DateInput
              onChange={this.handleDateChange}
              showTimeInput={false}
              value={date}
            />
          </FormGroup>
          <FormGroup isHorizontalLayout={true}>
            <Label isHorizontalLayout={true} size='sm'>
              Payment Amount
            </Label>
            <QuantityInput
              isUnitDisabled={true}
              placeholder='Amount'
              onChange={this.handleAmountChange}
              showUnitSelect={false}
              type='number'
              value={amount}
            />
          </FormGroup>
          <TextareaField
            size='sm'
            isHorizontalLayout={true}
            label='Payment Notes'
            onChange={this.handleNotesChange}
            value={notes}
          />
        </FormTable>
      </Sheet>
    )
  }

  private handleDateChange = (value) => {
    this.setState({
      date: value,
    })
  }

  private handleAmountChange = (value) => {
    this.setState({
      amount: value,
    })
  }

  private handleNotesChange = (value) => {
    this.setState({
      notes: value,
    })
  }

  private handleSave = () => {
    const { accountingEntity, businessEntity } = this.props
    const { amount, date, notes } = this.state
    const accountingEntityId = accountingEntity.uniqueId
    const amountValue = amount.value
    this.setState({ isSaving: true })
    if (_.some(accountingEntity.activeMixins, { entityId: '680a5bbf-799d-44de-9b0c-227e06ec728b' })) {
      apis.makeInvoicePayment(accountingEntityId, businessEntity, amountValue, date, notes).then(() => {
        this.setState({ isSaving: false })
        this.handleClose()
      })
    } else {
      apis.makeBillPayment(accountingEntityId, businessEntity, amountValue, date, notes).then(() => {
        this.setState({ isSaving: false })
        this.handleClose()
      })
    }
  }

  private handleClose = () => {
    const { updateExportPayments } = this.props
    updateExportPayments()
    this.sheet.close()
  }
}
