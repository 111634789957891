declare let window: any

import classNames from 'classnames'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { useFetchDocuments } from 'browser/components/hooks/useFetchDocuments'

import { RB_V1_CONFIG } from 'browser/app/constants/rendition-billing'
import apis from 'browser/app/models/apis'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { DocumentItem } from 'browser/components/atomic-elements/organisms/entity/documents-list/document-item'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import { ImageEditorModal } from 'browser/components/atomic-elements/organisms/image-editor-modal'
import { Entity } from 'shared-libs/models/entity'
import {
  ShareConfigurationModal,
} from 'browser/components/atomic-elements/organisms/share-configuration-modal/share-configuration-modal'
import { isPrintable } from './utils'

const TIGERLINES_FIRM_ID = 'bf6a36f7-c417-47ea-8b2b-1697694e4bde'
const ORDER_TYPE = '/1.0/entities/metadata/dispatchOrder.json'

/**
 * @uiComponent
 */
interface IRbDocumentsListProps extends IBaseProps {
  entity: any
}

export const RbDocumentsList: React.FC<IRbDocumentsListProps> = (props) => {
  const { className, entity } = props
  const ordersPath = RB_V1_CONFIG.INVOICE_ORDERS_PATH
  const { settings } = useContext(AppNavigatorContext)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const [invoiceDocument, setInvoiceDocument] = useState(null)
  const [orders, setOrders] = useState(_.get(entity, ordersPath))
  const [documents, isLoading] = useFetchDocuments(orders)

  useEffect(() => {
    const { owner, rbInvoice } = entity
    if (rbInvoice && rbInvoice.invoice) {
      apis.getStore().findRecord(entity.rbInvoice.invoice.entityId)
        .then((doc) => setInvoiceDocument(doc))
    }
    if (owner.firm.entityId === TIGERLINES_FIRM_ID) {
      apis.getRelatedOrders(entity.uniqueId).then((ids: any) => {
        const ordersDataSource = new EntityDataSource({
          entityType: ORDER_TYPE,
          filters: [
            {
              path: 'uniqueId',
              type: 'match',
              values: ids,
            },
          ],
        }).setOnChange((res) => {
          const edges = _.map(res, (e: any) => {
            const { displayName, uniqueId } = e.data
            return { displayName, entityId: uniqueId }
          })
          setOrders(edges)
          ordersDataSource.dispose()
        })
        ordersDataSource.find()
      })
    }
  }, [])

  const renderTableActionBar = () => {
    const hasNoSelection = _.isEmpty(selectedDocuments)
    return (
      <div className='flex mv1'>
        <Button
          className={classNames(
            'ml2',
            Classes.SMALL,
            Classes.iconClass(IconNames.SHARE),
            Classes.MINIMAL
          )}
          isDisabled={hasNoSelection}
          onClick={handleOpenShareConfigurationModal}
        >
          Share
        </Button>
        <Button
          className={classNames(
            'ml2',
            Classes.SMALL,
            Classes.iconClass(IconNames.DOWNLOAD),
            Classes.MINIMAL
          )}
          isDisabled={hasNoSelection}
          isLoading={isDownloading}
          onClick={handleDownloadDocuments}
        >
          Download
        </Button>
      </div>
    )
  }

  const renderDocumentList = () => {
    if (_.isEmpty(documents) && !invoiceDocument) {
      return renderEmptyState()
    }
    // Remove all invoiceDocuments that are not directly associated with salesInvoice
    const documentsList = documents
    if (invoiceDocument) {
      documentsList.unshift(invoiceDocument)
    }

    const uniqueEntities: any = _.uniqBy(documentsList, (e: any) => e.uniqueId)
    const listItems = _.map(uniqueEntities, (document) => {

      const handleSelectDocument = () => {
        if (_.indexOf(selectedDocuments, document) >= 0) {
          _.pull(selectedDocuments, document)
        } else {
          selectedDocuments.push(document)
        }
        setSelectedDocuments(selectedDocuments.slice())
      }

      const handleOpenPreview = (entity: Entity, setLoading: (loading: boolean) => void) => {

        if (!document.isIdle) { return }
        if (isPrintable(document)) {
          setLoading(true)
          apis.getPrintablePreview(document.uniqueId).then((result: any) => {
            ImageEditorModal.open({
              isEditable: false,
              entity: result,
              imagesPath: 'document.attachments',
            })
            setLoading(false)
          })
        } else {
          ImageEditorModal.open({
            entity: document,
            imagesPath: 'document.attachments',
          })
        }
      }

      return (
        <DocumentItem
          entity={document}
          isSelected={_.indexOf(selectedDocuments, document) >= 0}
          key={document.uniqueId}
          onOpenPreview={handleOpenPreview}
          onSelect={handleSelectDocument}
          showDisplayName={true}
        />)
    })
    return (
      <table className='c-table c-table--flushHorizontal c-table--auto c-table--noBorders'>
        <tbody className='c-table-body'>
          {listItems}
        </tbody>
      </table>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className='c-boxListItem c-boxListItem--noHover'>
        <div className='c-boxListItem-content u-textCenter u-bumperTop u-bumperBottom'>
          <HelpBlock className='mb2'>
            No documents have been attached yet.
          </HelpBlock>
        </div>
      </div>
    )
  }

  const handleOpenShareConfigurationModal = () => {
    ShareConfigurationModal.open({
      onSend: handleSendDocument,
      showRecipientsList: false,
      showShareAccessSettings: false,
    })
  }

  const handleSendDocument = (recipients, message) => {
    const firm = settings.getFirm()
    // TODO (David): Need to change this, assuming that entity is order here
    const loadNumber = entity.displayName
    const attachments = getSelectedAttachments()
    const subject = `Documents from ${firm.displayName} for Load ${loadNumber}`
    return apis.sendNotification({
      attachments,
      message,
      recipients,
      subject,
    }).then(() => {
      Toast.show({
        message: 'Emailing documents.',
        position: Position.BOTTOM_RIGHT,
      })
    })
  }

  const handleDownloadDocuments = () => {
    const attachments = getSelectedAttachments()
    setIsDownloading(true)
    apis.batchDownloadDocuments({ attachments }).then((url) => {
      window.location = url
    }).then(() => {
      setIsDownloading(false)
    })
  }

  const getSelectedAttachments = () => {
    const loadNumber = entity.displayName
    return _.map(selectedDocuments, (document) => ({
      entityId: document.get('uniqueId'),
      fileName: `${loadNumber} - ${document.entityType}.pdf`,
      type: 'document',
    }))
  }

  return (
    <div className={classNames('c-documentsList', className)}>
      <Section
        title='Related Documents'
        headerControls={renderTableActionBar()}
      >
        {renderDocumentList()}
      </Section>
    </div>
  )
}
