declare let window: any

import _ from 'lodash'
import queryString from 'query-string'
import React from 'react'

import { EntityRenderer } from 'shared-libs/components/entity/renderer'
import { EdgeDetails, Entity } from 'shared-libs/models/entity'
import { Store } from 'shared-libs/models/store'

import apis from 'browser/app/models/apis'
import ComponentsMap from 'browser/components'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { IContainerProps } from 'browser/components/containers/container-props'
import { MessagePayload, getMessaging, onMessage } from 'firebase/messaging'
import { EntitySubscriptionManager } from 'browser/app/utils/entity-subscription-manager'

export interface IAbstractEntityViewState {
  entity: Entity
  errors: any
  isLoading: boolean
  isSaving: boolean
  settings?: any
}

export abstract class AbstractEntityView<S extends IAbstractEntityViewState>
  extends React.Component<IContainerProps, S> {

  protected store: Store
  private edges: string[] = []

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    onMessage(getMessaging(), this.handleNotification.bind(this))
    window.addEventListener('beforeunload', this.componentWillUnmount.bind(this))
  }

  public componentDidMount() {
    const entityId = this.getEntityIdFromProps(this.props)
    this.fetchModel(entityId)
      .then(() => {
        this.subscribeToEntityUpdates()
      })
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const entityId = this.getEntityIdFromProps(this.props)
    const nextEntityId = this.getEntityIdFromProps(nextProps)
    if (entityId !== nextEntityId) {
      this.fetchModel(nextEntityId)
    }
  }

  public componentWillUnmount(): void {
    const updatesEnabled = apis.getSettings().getRemoteConfigValue('entityUpdates').asBoolean()
    if (updatesEnabled) {
      EntitySubscriptionManager.removeEntityUpdateSubscriptions(this.edges)
    }
  }

  public render() {
    const { viewSchema } = this.props
    const { entity, errors, isLoading } = this.state
    if (isLoading) {
      return this.renderLoading()
    }
    const entityRendererContext = {
      density: 'collapse',
      isHorizontalLayout: true,
      onSave: this.handleSave,
    }
    return (
      <div
        className='grid-block c-appBody'
      >
        <EntityRenderer
          actions={this}
          componentsMap={ComponentsMap}
          context={entityRendererContext}
          errors={errors}
          onChangeComplete={this.handleEntityChange}
          state={this.state}
          uiContext={viewSchema}
          uiSchemaPath={[]}
          value={entity}
        >
          {this.props.children}
        </EntityRenderer>
      </div>
    )
  }

  protected renderLoading() {
    return (
      <div className='grid-block c-appBody'>
        <LoadingSpinner />
      </div>
    )
  }

  protected fetchModel(entityId) {
    this.setState({ isLoading: true })
    return this.store.findRecord(entityId).then((entity) => {
      this.setState({ entity, isLoading: false })
    }).catch((error) => { throw error })
  }

  protected getEntityIdFromProps(props) {
    const { location } = props
    const { entityId } = queryString.parse(location.search)
    return entityId
  }

  protected handleEntityChange = (entity) => {
    this.forceUpdate()
  }

  protected handleOpenModal = ({ overlayName, overlayProps }) => {
    const ComponentClass = ComponentsMap[overlayName]
    ComponentClass.open(overlayProps)
  }

  protected handleRollback = () => {
    const { entity } = this.state
    entity.rollback()
    this.forceUpdate()
  }

  protected handleSave = async () => {
    const { entity } = this.state
    const errors = await entity.validate()
    if (_.isEmpty(errors)) {
      this.setState({ isSaving: true })
      entity.save().then(() => this.setState({ isSaving: false }))
    } else {
      this.setState({ errors })
    }
  }

  private async subscribeToEntityUpdates() {
    const updatesEnabled = await apis.getSettings().getRemoteConfigValue('entityUpdates').asBoolean()
    if (!updatesEnabled) return
    const { entity } = this.state
    if (!entity) return
    const edges = _.map(entity.getEdges(), (edge: EdgeDetails) => edge.value?.entityId)
    try {
      await EntitySubscriptionManager.diffEntitySubscriptions(this.edges, edges)
    } catch(err) {
      console.log(`failed to update subscriptions: ${err.stack}`)
    }
    this.edges = edges
  }

  private async handleNotification(e: MessagePayload) {
    const uri = _.get(e, 'data.uri')
    if (!uri) {
      return
    }
    try {
      const parsedUri = new URL(uri)
      if (parsedUri.pathname !== '/actions/entity/update') return
      // Mobile and web seem to present the notification differently
      const json = parsedUri.searchParams.get('jsonProps').replace(/\\/g,'')
      const payload = JSON.parse(json)
      const { entity } = this.state
      await Promise.all([entity.reload(), ..._.map(payload.updatedEntities, async (updated) => {
        if (entity.uniqueId === updated) {
          return Promise.resolve()
        }
        const updatedEntity = apis.getStore().getRecord(updated)
        if (_.isNil(updatedEntity)) {
          return apis.getStore().getOrFetchRecord(updated)
        } else {
          return updatedEntity.reload()
        }
      })])
      this.forceUpdate()
    } catch (err) {
      console.warn(`failed to parse notification: ${err.message}\n${err.stack}`)
    }
  }
}
