import { Classes, Icon, Intent, Tag } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'

/**
 * @uiComponent
 */
interface IOrderStopProps extends IBaseProps {
  frames: any
  onRemove: () => void
  onRemoveClassName?: string
  value: any
}

export class OrderStop extends React.Component<IOrderStopProps, any> {

  public render() {
    const { children } = this.props
    return (
      <Section
        className='c-section--collapse'
        headerControls={this.renderStopButton()}
        isCollapsable={true}
        titleElement={this.renderStopTitle()}
      >
        {children}
      </Section>
    )
  }

  private renderStopTitle() {
    const { frames, value } = this.props
    const index = frames.getValue('index')
    const items = frames.getValue('items')
    const isLastStop = index === items.length - 1

    const pickups = _.filter(value.cargos, { type: 'Pick Up' })
    let pickupEvent
    if (pickups.length > 0 ) {
      pickupEvent = (
        <Tag
          className={classNames('ml2', Classes.MINIMAL)}
          intent={Intent.NONE}
        >
          <Icon icon={IconNames.ARROW_UP} /> Pick Up
        </Tag>
      )
    }

    const dropOffs = _.filter(value.cargos, { type: 'Drop Off' })
    let dropoffEvent
    if (dropOffs.length > 0) {
      dropoffEvent = (
        <Tag
          className={classNames('ml2', Classes.MINIMAL)}
          intent={Intent.NONE}
        >
          <Icon icon={IconNames.ARROW_DOWN} /> Drop Off
        </Tag>
      )
    }

    if (index === 0) {
      return (
        <div className='c-sectionHeaderGroup'>
          <div className='c-sectionHeader-title'>
            Stop {index + 1}
          </div>
          <span className='c-label c-label--secondary c-label--collapse c-label--flex items-center'>
            {pickupEvent}{dropoffEvent}
            <span className='ml2'>
              &middot; Origin
            </span>
          </span>
        </div>
      )
    } else if (isLastStop) {
      return (
        <div className='c-sectionHeaderGroup'>
          <div className='c-sectionHeader-title'>
            Stop {index + 1}
          </div>
          <span className='c-label c-label--secondary c-label--collapse c-label--flex items-center'>
            {pickupEvent}{dropoffEvent}
            <span className='ml2'>
              &middot; Destination
            </span>
          </span>
        </div>
      )
    } else {
      return (
        <div className='c-sectionHeaderGroup'>
          <div className='c-sectionHeader-title'>
            Stop {index + 1}
          </div>
          <span className='c-label c-label--secondary c-label--collapse c-label--flex items-center'>
            {pickupEvent}{dropoffEvent}
          </span>
        </div>
      )
    }
  }

  private renderStopButton() {
    const { onRemove, onRemoveClassName } = this.props
    if (!onRemove) {
      return
    }
    return (
      <Button
        className={classNames(Classes.MINIMAL, onRemoveClassName)}
        size='small'
        onClick={onRemove}
      >
        Remove
      </Button>
    )
  }
}
