import _ from 'lodash'
import Ajv from 'ajv'
import ajvErrorsPlugin from 'ajv-errors'
import installAjvKeywordUniqueItemProperties from './ajv-keyword-uniqueItemProperties'
import { Entity } from '../../models/entity'
import { JSONValidator, ValidationErrorsAtPath, ValidationErrorsMap } from '../../components/entity/validator'
import { JSONSchemaResolver, RefResolver } from '../../resolvers/json-schema-resolver'
import { isEmailValid, isPhoneValid } from '../../helpers/utils'

type ValidationFunctionCacheEntry = {
  /**
   * An identifier that invalidates the cache entry when the associated schema
   * changes, e.g. `modifiedDate`.
   */
  cacheKey: string
  /**
   * The compiled ajv validation function.
   */
  validationFunction: Ajv.ValidateFunction
}

interface CompileOptions {
  /**
   * Function cache key generator. Defaults to using the schema's `modifiedDate`.
   */
  cacheKeyStrategy?: (schema: any) => string | undefined
  /**
   * Whether to compile the schema synchronously. Defaults to false.
   */
  synchronous?: boolean
}

interface ValidationOptions {
  /**
   * A ref resolver to use when resolving references into an entity. Provide
   * null if you don't need to resolve references.
   */
  refResolver?: RefResolver
  /**
   * The target entity to resolve references against.
   */
  refTarget?: Entity
  /**
   * Whether to clear the validation cache before validating a schema. Triggers a re-compile.
   */
  clearCache?: boolean
  /**
   * Function cache invalidation strategy. Defaults to checking the schema's `modifiedDate`.
   */
  cacheInvalidationStrategy?: (cachedSchema: any | undefined, newSchema: any) => boolean
}

const DEFAULT_CACHE_INVALIDATION_STRATEGY = (
  cachedEntry: ValidationFunctionCacheEntry | undefined,
  newSchema: any
): boolean => {
  return cachedEntry && cachedEntry.cacheKey !== newSchema.modifiedDate
}

const DEFAULT_CACHE_KEY_STRATEGY = (schema: any): string | undefined => {
  // fall back to id, since ajv only needs an id to write to its internal cache.
  return schema.modifiedDate ?? schema.id
}

// TODO: Refactor to isolate the AJV instance to the AJVSchemaAJVValidator
// instance? AJV caches globally by default, which originally influenced
// creating these singletons. However, its constructor can take a `cache` Map.
// This would require refactoring `Entity` (and/or the individual
// platforms/applications) to re-use the same validator instance, to preserve
// the cache across the app lifecycle as a singleton anyway, but it would still
// be useful for improving isolation/clarity in the unit tests.
const AJVValidateFunctionCache = new Map<string, ValidationFunctionCacheEntry>()
let ajv: Ajv.Ajv

export class AJVSchemaValidator implements JSONValidator {
  public static mapErrors = (ajvErrors: Ajv.ErrorObject[]): ValidationErrorsAtPath[] => {
    const filteredErrors = _.filter(ajvErrors, (error) => {
      // suppress uri format errors for now
      if (error.message.includes('should match format "uri"')) {
        console.warn(`suppressing "uri" format validation error at ${error.dataPath}`)
        return false
      } else if (error.message.includes('should match format "date"')) {
        console.warn(`suppressing "date" format validation error at ${error.dataPath}`)
        return false
      }
      // ignore if the schema path ends with non-property keyword if, anyOf, allOf, or oneOf.
      // negative lookbehind doesn't work outside Chrome for some reason,
      //  can't use /(?<!properties)\/(if|anyOf|allOf|oneOf)$/
      else if (
        error.schemaPath.match(/\/(if|anyOf|allOf|oneOf)$/) &&
        !error.schemaPath.match(/properties\/(if|anyOf|allOf|oneOf)$/)
      ) {
        return false
      }
      return true
    })

    const mappedErrors = _.map(filteredErrors, (error) => {
      const dataPath = error.dataPath
      const message = error.message

      // map ajv error strings to desired output
      const mappedRequiredPropertyResult = AJVSchemaValidator.mapRequiredPropertyError(
        dataPath,
        message
      )
      if (mappedRequiredPropertyResult) return mappedRequiredPropertyResult

      return { dataPath, message }
    })

    const errorsByPath = _.groupBy(mappedErrors, 'dataPath')
    return _.map(errorsByPath, (errorsArr, pathKey) => {
      const path = _.toPath(pathKey)
      if (pathKey[0] === '.') path.shift()
      const errors = _.uniq(_.map(errorsArr, 'message'))
      return { path, errors }
    })
  }

  private static mapRequiredPropertyError(dataPath, message) {
    const matchRequiredProperty = message.match(/^should have required property '(.*)'$/)
    if (matchRequiredProperty) {
      const property = matchRequiredProperty[1]
      dataPath = dataPath ? dataPath : ''
      dataPath += `.${property}`
      return { dataPath, message: 'is required' }
    }
    return undefined
  }

  public static async validate(
    validationFunction: Ajv.ValidateFunction,
    content: {}
  ): Promise<ValidationErrorsMap> {
    const results = {}

    await validationFunction(content)

    const errors = AJVSchemaValidator.mapErrors(validationFunction.errors || [])
    errors.forEach((entry) => {
      results[entry.path.join('.')] = entry.errors
    })
    return results
  }

  private refResolver: RefResolver
  private refTarget: Entity

  constructor(refResolver: RefResolver = null, entity: Entity = null) {
    this.refResolver = refResolver
    this.refTarget = entity
    this.initializeAjv()
  }

  private initializeAjv() {
    if (ajv) return

    ajv = new Ajv({
      allErrors: true,
      $data: true,
      schemaId: 'auto',
      unknownFormats: 'ignore',
      extendRefs: true,
      loadSchema: this.schemaUriLoader,
    })
    const metaSchema = require('ajv/lib/refs/json-schema-draft-04.json')
    ajv.addMetaSchema(metaSchema)
    ajv.addFormat('phone', isPhoneValid) // non-standard, but re-instates our existing phone validation
    ajv.addFormat('number', () => true) // no-op
    ajv.addFormat('email', isEmailValid)
    ajvErrorsPlugin(ajv)
    installAjvKeywordUniqueItemProperties(ajv)
    _.set(ajv, '_opts.jsonPointers', false) // ajv-errors set this to true for some reason
    return ajv
  }

  private schemaUriLoader = (uri: string) => {
    // TODO: Check if it's already loaded.
    // see https://withvector.atlassian.net/browse/VD-1681
    const resolved = this.refResolver.resolveReference(this.refTarget, uri)
    const schema = resolved.schema
    // schema might be Entity, or plain object in unit tests
    const content = schema.content || schema
    const existingFunction = ajv.getSchema(content.id || content[JSONSchemaResolver.ID_KEY])
    if (!existingFunction) {
      return AJVSchemaValidator.compile(content).then((result) => content)
    }
    return Promise.resolve(content)
  }

  public static async compile(
    schema: any,
    opts?: CompileOptions
  ): Promise<Ajv.ValidateFunction | undefined> {
    if (!schema) {
      return undefined
    }
    if (ajv.getSchema(schema.id)) {
      ajv.removeSchema(schema.id)
    }

    try {
      const validationFunction = opts?.synchronous
        ? ajv.compile(schema)
        : await ajv.compileAsync(schema)

      const cacheKeyStrategy = opts?.cacheKeyStrategy ?? DEFAULT_CACHE_KEY_STRATEGY
      const cacheKey = cacheKeyStrategy(schema)
      if (cacheKey && schema.id) {
        AJVValidateFunctionCache.set(schema.id, { cacheKey, validationFunction })
      }
      return validationFunction
    } catch (error) {
      console.log(`[AJVSchemaValidator.compile] failed for schema id ${schema.id}:`, error)
      throw error
    }
  }

  public validate = async (
    schema: any,
    instance: {},
    opts?: ValidationOptions & CompileOptions
  ): Promise<ValidationErrorsAtPath[]> => {
    const invalidationStrategy =
      opts?.cacheInvalidationStrategy ?? DEFAULT_CACHE_INVALIDATION_STRATEGY
    const shouldClear =
      opts?.clearCache ?? invalidationStrategy(AJVValidateFunctionCache.get(schema.id), schema)

    if (shouldClear) {
      AJVValidateFunctionCache.delete(schema.id)
      ajv.removeSchema(schema.id)
    }

    const cachedEntry = AJVValidateFunctionCache.get(schema.id)
    const validationFunction =
      cachedEntry?.validationFunction ?? (await AJVSchemaValidator.compile(schema, opts))
    await validationFunction(instance)
    return AJVSchemaValidator.mapErrors(validationFunction.errors || [])
  }

  /**
   * @returns A raw Ajv.ValidateFunction, useful for tests to compile simple
   * schemas. NOTE: This does not compile dependencies, e.g. from "$ref", for
   * that see {@link AJVSchemaValidator.compile}
   */
  public unsafe_makeValidationFunction(schema: any): Ajv.ValidateFunction {
    return ajv.compile(schema)
  }
}
