import { Icon, Colors } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/table/cells/_table-copy-cell.scss'

interface ITableCopyCellProps extends IBaseProps {
  value: string
}

export const TableCopyCell: React.FC<ITableCopyCellProps> = (props) => {
  const { value, className } = props
  const [copied, setCopied] = useState<boolean>(false)
  const timeoutId = useRef<ReturnType<typeof setTimeout>>()
    
  const timeout = 3000

  const handleOnCopy = (e) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    setCopied(true)
    timeoutId.current = setTimeout(() => {
      setCopied(false)
    }, timeout)
    e.stopPropagation()
  }

  // Empty case
  if (_.isEmpty(value)) {
    return <div className={className}> - </div>
  }

  // keep visual weight of icon low to avoid distractions when scanning data
  const icon = copied ? (
    <Icon icon={IconNames.TICK} color={Colors.GREEN5} size={12} />
  ) : (
    <Icon icon={IconNames.DUPLICATE} color={Colors.GRAY5} size={12} />
  )

  return (
    <div
      className={classNames('c-tableCopyCell', {
        'c-tableCopyCell--copied': copied,
      })}
    >
      {value}
      <CopyToClipboard text={value} onCopy={handleOnCopy}>
        <a className="pa1 ml2">
          {icon}
        </a>
      </CopyToClipboard>
    </div>
  )
}
