import _ from 'lodash'
import React from 'react'

import { ISelectProps } from 'browser/components/atomic-elements/atoms/select'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'

interface IEmailsFieldProps extends ISelectProps {
  entity: any
  value: any
}

interface IEmailsFieldState {
  options: any[]
}

export class EmailsField extends React.Component<IEmailsFieldProps, IEmailsFieldState> {
  private select: any

  constructor(props) {
    super(props)
    this.state = {
      options: [],
    }
  }

  public render() {
    const { options } = this.state
    const { value } = this.props
    const handleRef = (ref) => this.select = ref
    return (
      <SelectField
        {...this.props}
        value={value}
        isCreatable={true}
        multi={true}
        noResultsText='No Contacts Found'
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onSelectRef={handleRef}
        options={options}
      />
    )
  }

  private handleChange = (value, options) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(options)
    }
  }

  private handleInputChange = (inputValue) => {
    const { onInputChange } = this.props
    if (onInputChange) {
      onInputChange(inputValue)
    }
  }
}
