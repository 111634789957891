import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import apis from 'browser/app/models/apis'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { ErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/error-block'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { OrBlock } from 'browser/components/atomic-elements/atoms/or-block/or-block'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { PhoneNumberField } from 'browser/components/atomic-elements/molecules/fields/phone-number-field'
import 'browser/components/atomic-elements/organisms/onboarding-flow/_join-organization-tab.scss'

interface IJoinOrganizationTabProps extends IBaseProps {
  onClose?: () => void
  navigation: any
  productName: string
  size?: string
}

interface IJoinOrganizationTabStates {
  firmId: string
  identity: object | string
  identityError: string
  isAddingIdentity: boolean
  isRequestingAccess: boolean
  requestAccessError: string
}

export class JoinOrganizationTab extends React.Component<IJoinOrganizationTabProps, IJoinOrganizationTabStates> {

  constructor(props) {
    super(props)
    this.state = {
      firmId: '',
      identity: '',
      identityError: null,
      isAddingIdentity: false,
      isRequestingAccess: false,
      requestAccessError: null,
    }
  }

  public render() {
    const { navigation, size, onClose } = this.props
    const footerAction = onClose ? onClose : navigation.goBack
    return (
      <AppNavigatorContext.Consumer>
        {({settings}) => (
          <div>
            <div className='ph4 pb4'>
              <h3 className='f3 lh-title mt2 mb4'>
                Join an Organization
              </h3>
              {this.renderAddIdentityToFindInvitations(settings)}
              <OrBlock />
              {this.renderRequestAccess(settings)}
            </div>
            <Footer
              className='c-footer--transparent c-footer--horizontalPadding'
              cancelButtonText='Back'
              onCancelButtonClick={footerAction}
            />
          </div>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private renderAddIdentityToFindInvitations(settings) {
    const hasVerifiedPhone = this.hasVerifiedPhone(settings)
    const inviteByType = hasVerifiedPhone ? 'email' : 'mobile number'
    return (
      <div>
        <h4 className='f4 lh-copy'>
          Find Invitations
        </h4>
        <div className='flex'>
          <div className='mr3 c-joinOrganizationTab-helpText'>
            Please enter your {inviteByType} to see if any organization invited you with other credentials.
          </div>
          {hasVerifiedPhone ? this.renderEmailField(settings) : this.renderPhoneField(settings)}
        </div>
      </div>
    )
  }

  private renderEmailField(settings) {
    const { identity, identityError, isAddingIdentity } = this.state
    return (
      <div className='flex items-center justify-center'>
        <FormTable>
          <InputField
            errorText={identityError}
            placeholder='Enter your email'
            onChange={this.handleIdentityChange}
            size='lg'
            value={identity}
          />
        </FormTable>
        <Button
          className={classNames('c-button--squareLeft', Classes.INTENT_PRIMARY)}
          isLoading={isAddingIdentity}
          onClick={() => this.handleAddEmailToUser(settings)}
          size='lg'
        >
          Check
        </Button>
      </div>
    )
  }

  private renderPhoneField(settings) {
    const { identity, identityError, isAddingIdentity } = this.state
    return (
      <div>
        <div className='flex items-start justify-center'>
          <FormTable>
            <PhoneNumberField
              hideExtension={true}
              onChange={this.handleIdentityChange}
              placeholder='(000) 000-0000'
              size='lg'
              value={identity}
            />
          </FormTable>
          <Button
            className={classNames('c-button--squareLeft', Classes.INTENT_PRIMARY)}
            isLoading={isAddingIdentity}
            onClick={() => this.handleAddPhoneNumberToUser(settings)}
            size='large'
          >
            Check
          </Button>
        </div>
        <ErrorBlock
          errorText={identityError}
        />
      </div>
    )
  }

  private renderRequestAccess(settings) {
    const { firmId, isRequestingAccess, requestAccessError } = this.state
    const { productName } = this.props
    return (
      <div>
        <h4 className='f4 lh-copy'>
          Request Access
        </h4>
        <div className='flex'>
          <div className='mr3 c-joinOrganizationTab-helpText'>
            Enter an organization&apos;s {productName} ID to request to join.
          </div>
          <div>
            <div className='flex items-start justify-center'>
              <FormTable>
                <InputField
                  size='lg'
                  onChange={this.handleFirmIdChange}
                  placeholder='Enter Firm ID'
                  value={firmId}
                />
              </FormTable>
              <Button
                className={classNames("c-button--squareLeft", Classes.INTENT_PRIMARY)}
                isLoading={isRequestingAccess}
                onClick={() => this.handleRequestAccess(settings)}
                size='large'
              >
                Request
              </Button>
            </div>

            <ErrorBlock
              errorText={requestAccessError}
            />
          </div>
        </div>
      </div>
    )
  }

  private handleSaveIdentity(user, identity) {
    const { navigation } = this.props
    this.setState({ isAddingIdentity: true, identityError: '' })
    user.save().then(() => {
      return user.user.getPendingInvites()
    }).then(({ unverifiedInvites }) => {
      if (!_.isEmpty(unverifiedInvites)) {
        apis.sendVerificationCode(identity)
        navigation.navigate('verifyIdentity', { unverifiedInvites })
      } else {
        this.setState({ identityError: `There are no invites associated with ${identity}` })
      }
    }).catch((error) => {
      if (_.get(error.errors, 'person.phoneNumbers.0.value.phone')) {
        this.setState({ identityError: 'Invalid phone number' })
      } else if (_.get(error.errors, 'person.emails.0.value')) {
        this.setState({ identityError: 'Invalid emails' })
      }
    }).finally(() => this.setState({ isAddingIdentity: false }))
  }

  private handleAddEmailToUser = (settings) => {
    const { identity } = this.state
    const user = settings.getUser()
    user.user.addEmailIdentity('Primary Email', identity)
    this.setState({ identity: null })
    this.handleSaveIdentity(user, identity)
  }

  private handleAddPhoneNumberToUser = (settings) => {
    const phoneObject: any = this.state.identity
    const user = settings.getUser()
    user.user.addPhoneIdentity('Primary Phone', phoneObject)
    this.setState({ identity: null })
    this.handleSaveIdentity(user, phoneObject.phone)
  }

  private handleFirmIdChange = (firmId) => {
    this.setState({ firmId })
  }

  private handleIdentityChange = (identity) => {
    this.setState({ identity })
  }

  private handleRequestAccess = (settings) => {
    const { firmId } = this.state
    const user = settings.getUser()
    this.setState({ isRequestingAccess: true, requestAccessError: null })
    user.user.requestJoinFirm(firmId).then(() => {
      user.set('user.preferences.hasSeenJoinOrganizationModal', true)
      return user.save()
    }).then(() => {
      this.showSuccessfullyAddedJoinRequest()
    }).catch((error) => {
      let requestAccessError = _.get(error, 'message', 'Invalid company ID')
      if (error.code === 409) {
        requestAccessError = 'You have already requested to join this firm'
      }
      this.setState({ requestAccessError })
    }).finally(() => {
      this.setState({ firmId: '', isRequestingAccess: false })
    })
  }

  private hasVerifiedPhone(settings) {
    const user = settings.getUser()
    const phones = user.get('person.phoneNumbers')
    return !_.isEmpty(phones) && _.get(phones[0], 'isVerified')
  }

  private showSuccessfullyAddedJoinRequest() {
    Toast.show({
      message: 'Successfully requested access!',
      position: Position.TOP,
    })
  }
}
