import styled from 'styled-components'

export const ErrorMessageContainer = styled.div`
background-color: #ffebee;
border-radius: 5px;
justify-content: center;
margin-top: 5px;
`

export const ErrorTitle = styled.div`
font-weight: bold;
color: #D45C43;
padding-right: 5px;
padding-left: 5px;
`

export const ErrorText = styled.div`
color: #D45C43;
padding-right: 5px;
padding-left: 10px;
`
