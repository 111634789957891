import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { ISelectListProps, SelectList } from 'browser/components/atomic-elements/atoms/select/select-list'
import { CheckboxField } from 'browser/components/atomic-elements/molecules/fields/checkbox-field'

interface IMultiSelectListProps extends ISelectListProps  {
  getOptionLabel?: (value: any) => string
  optionKey: string
  selection: any[]
}

export class MultiSelectList extends React.Component<IMultiSelectListProps, any> {

  public static defaultProps: Partial<IMultiSelectListProps> = {
    showSearchInput: true,
  }

  public list: any

  public render() {
    const {
      hasNext,
      isLoading,
      isSearching,
      onLoadMore,
      onSearch,
      options,
      scrollToIndex,
      searchQuery,
      showSearchInput,
    } = this.props
    return (
      <SelectList
        hasNext={hasNext}
        isLoading={isLoading}
        isSearching={isSearching}
        onLoadMore={onLoadMore}
        onSearch={onSearch}
        options={options}
        optionRenderer={this.handleRenderOption}
        ref={(ref) => { this.list = ref }}
        scrollToIndex={scrollToIndex}
        searchQuery={searchQuery}
        showSearchInput={showSearchInput}
      />
    )
  }

  public forceUpdateList() {
    this.list.forceUpdateList()
  }

  private isOptionSelected(option) {
    const { optionKey, selection } = this.props
    if (_.isObject(option.data)) {
      return !!_.find(selection, { [optionKey]: option.data[optionKey] })
    }
    return _.includes(selection, option.data)
  }

  private toggleOption(values, option, selected) {
    const { optionKey } = this.props
    if (selected) {
      values.push(option.data)
    } else {
      if (_.isObject(option.data)) {
        _.remove(values, (value) => value[optionKey] === option.data[optionKey])
      } else {
        _.pull(values, option.data)
      }
    }
  }

  private handleSelection = (option, selected) => {
    const { onChange, selection } = this.props
    this.toggleOption(selection, option, selected)
    onChange(selection)
    // need to call forceUpdateGrid to update list item
    this.list.forceUpdateList()
  }

  private handleRenderOption = ({ index, key, style }) => {
    const { getOptionLabel, options } = this.props
    const option = options[index]
    const label = getOptionLabel ? getOptionLabel(option) : option.label
    const hasNoLabel = _.isNil(label)
    const isSelected = this.isOptionSelected(option)
    const className = classNames({
      'u-multiSelectFilter-emptyItemLabel': hasNoLabel,
    })
    return (
      <div
        className='c-multiSelectFilter-item'
        key={key}
        style={style}
      >
        <CheckboxField
          labelProps={{
            className: 'u-ellipsis',
          }}
          label={hasNoLabel ? 'Has no value' : label}
          onChange={(selected) => this.handleSelection(option, selected)}
          value={isSelected}
        />
        <span className='u-bumperLeft'>
          ({option.metadata.totalEntityMatchCount})
        </span>
      </div>
    )
  }
}
