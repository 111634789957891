import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { ImageEditorModal } from 'browser/components/atomic-elements/organisms/image-editor-modal'
import { renderEventTimestamp } from '../util'

interface IShareItemProps extends IBaseProps {
  entity: any
  shareItem: any
  isFirst: boolean
  isLast: boolean
}

export class ShareItem extends React.Component<IShareItemProps, any> {

  private store: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      entity: null,
    }
  }

  public componentDidMount() {
    const { shareItem } = this.props
    const { entityId } = shareItem.feedEvent.entity
    this.store.findRecord(entityId).then((result) => {
      // tslint:disable-next-line:max-line-length
      const isSystemGenerated = _.some(result.activeMixins, (mixin) => mixin.entityId === 'a85e9f04-3baa-4149-8f2f-fbbd8f8d1833')
      if (isSystemGenerated) {
        this.setState({
          entity: result,
        })
      }
    })
  }

  public render() {
    const { className, shareItem, isFirst } = this.props
    const { share } = shareItem
    return (
      <div
        className={classNames('c-timelineEvent', className)}
        key={share.uniqueId}
      >
        <div
          className={classNames('c-timelineEvent-line', className, {
            'c-timelineEvent-line--topCutOff': isFirst,
          })}
        />
        <div className='c-timelineEvent-dot' />
        <div className='c-timelineEvent-contentContainer'>
          <div className='c-timelineEvent-body'>
            {this.renderComment()}
          </div>
        </div>
      </div>
    )
  }

  private renderComment() {
    const { shareItem } = this.props
    const { creationDate } = shareItem
    const { entity } = this.state
    const { share } = shareItem
    const { sharedBy } = share

    let documentName = share.entity.displayName
    if (entity) {
      documentName = (
        <a onClick={() => this.handleImagePreview(entity)}>{share.entity.displayName}</a>
      )
    }

    return (
      <HelpBlock>
        <span className='b'>{sharedBy.displayName}</span> shared {documentName} with {this.getSharedToLabel()}, {renderEventTimestamp(creationDate)}
      </HelpBlock>
    )
  }

  private getSharedToLabel() {
    const { sharedTo } = this.props.shareItem.share
    if (sharedTo.firstName) {
      return sharedTo.firstName + ' ' + sharedTo.lastName
    }
    if (sharedTo.email) {
      return sharedTo.email
    }
    if (sharedTo.phoneNumber) {
      return sharedTo.phoneNumber.phone
    }
    return ''
  }

  private handleImagePreview(entity) {
    ImageEditorModal.open({
      entity,
      imagesPath: 'document.attachments',
    })
  }
}
