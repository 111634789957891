import _ from 'lodash'

import { EntityType } from './entity-type'

export class AccessRequest extends EntityType {

  public accept = () => {
    if (!this.isPending) {
      throw new Error('Cannot approve request that is not in pending state')
    }
    this.entity.set('accessRequest.state', 'accepted')
    return this.entity.save()
  }

  public reject = () => {
    if (!this.isPending) {
      throw new Error('Cannot reject requests that are not in pending state')
    }
    this.entity.set('accessRequest.state', 'rejected')
    return this.entity.save()
  }

  public get isPending() {
    return this.entity.get('accessRequest.state') === 'pending'
  }

}
