import React, { Fragment } from "react";
import { IconNames } from '@blueprintjs/icons'
import _ from 'lodash'
import styled from 'styled-components'

import { edgeFromEntity } from 'shared-libs/helpers/formulas/modules'

import apis from 'browser/app/models/apis'

import { Button } from "browser/components/atomic-elements/atoms/button/button";
import { Section } from "browser/components/atomic-elements/atoms/section/section";
import { Entity } from "shared-libs/models/entity";
import { CreateSpotSheet } from "./create-spot-sheet";
import { Query } from "shared-libs/models/query";
import { Checkbox, Icon } from "@blueprintjs/core";
import { HelpBlock } from "browser/components/atomic-elements/atoms/help-block/help-block";
import { FormTable } from "browser/components/atomic-elements/atoms/form-table/form-table";
import { LabelFormGroup } from "browser/components/atomic-elements/molecules/label-form-group/label-form-group";
import { CheckboxField } from "browser/components/atomic-elements/molecules/fields/checkbox-field";
import { SchemaUris } from "shared-libs/models/schema";

/**
 * @uiComponent
 */
interface ISpotsListProps {
  facility: Entity
}

interface ISpotsListState {
  isCreateSpotSheetOpen: boolean
  spots: Entity[]
  spotBeingEdited?: Entity
}

const BORDER_COLOR = '#e0e0e0'

const CheckboxWrapper = styled.div`
  height: 32px;
  padding-left: 0.75rem;
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid ${ BORDER_COLOR };
  justify-content: space-between;
`

// TODO - hacky, find a generic class
const VerticalAlign = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`

const InlineHelp = styled.span`
  color: #9e9e9e;
`



export class SpotsList extends React.Component<ISpotsListProps, ISpotsListState> {
  private store: any

  constructor(props) {
    super(props)

    this.store = apis.getStore()
    this.state = {
      isCreateSpotSheetOpen: false,
      spots: [],
    }

    this.loadSpots()
  }

  public loadSpots = async () => {
    const { facility } = this.props

    const filters = [{
      path: 'core_yms_spot.facility',
      type: 'matchEdge',
      value: {
        entityId: facility.uniqueId,
      },
    }]

    const orders = [{
      "label": "Name",
      "path": "core_yms_spot.name",
      "type": "ascending"
    }]

    const collection = new Query(apis)
      .setEntityType(SchemaUris.SPOT_TYPE)
      .setFilters(filters)
      .setOrders(orders)
      .setSize(500)
      .getCollection()

    const spots = await collection.find()

    this.setState({ spots })
  }

  public render() {
    const { facility } = this.props
    const { spots, spotBeingEdited } = this.state

    const addButton = (
      <Button onClick={this.handleOpenCreateSpotSheet}>
        <VerticalAlign>
          <Icon className='u-bumperRight--xs' icon={IconNames['ADD']} />
          Add Spot or Area
        </VerticalAlign>
      </Button>
    )

    const [activeSpots, inactiveSpots] = _.partition(spots, (spot: any) => spot.core_yms_spot.isActive)

    return <>
      {this.renderSection("Active Spots and Areas", "There are no active spots or areas for this facility", activeSpots, addButton)}
      {this.renderSection("Deactivated Spots and Areas", "There are no deactivated spots or areas for this facility", inactiveSpots)}

      <CreateSpotSheet
        isOpen={this.state.isCreateSpotSheetOpen}
        onClose={this.handleCloseCreateSpotSheet}
        uiSchemaPath='uiSchema.web.entityCreationSheet'
        spot={spotBeingEdited}
      />
    </>
  }

  public renderSection(title, helpText, spots, headerControls?) {
    return (
      <Section title={title} headerControls={headerControls} headerClassName="u-bumperTop--sm u-bumperBottom--sm">
        {_.isEmpty(spots) ?
          this.renderZeroState(helpText) :
          this.renderSpots(spots)
        }
      </Section>
    )
  }

  public renderSpots = (spots) => {
    return (
      <FormTable>
        {spots.map(this.renderSpot)}
      </FormTable>
    )
  }

  public renderSpot = (spot) => {
    const spotName = spot.displayName
    const isActive = spot.core_yms_spot.isActive

    const spotLabel = <>
      {spotName}
      <InlineHelp>
        &nbsp;(
          {`${spot.core_yms_spot.capacity} spot${spot.core_yms_spot.capacity > 1 ? 's' : ''}`}
        )
      </InlineHelp>
    </>

    return<LabelFormGroup
      label={spotLabel}
      labelProps={{
        "className": "c-label--isHorizontalVeryWide",
      }}
      key={spot.uniqueId}
    >
      <CheckboxWrapper>
        <CheckboxField
          label=''
          onChange={() => { this.flipSpotActive(spot) }}
          value={isActive}
        />
        <a
          onClick={() => { this.editSpot(spot) }}
          className="u-bumperRight--sm"
        >
          Edit
        </a>
      </CheckboxWrapper>
    </LabelFormGroup>
  }

  public editSpot = (spot) => {
    this.setState({
      spotBeingEdited: spot,
      isCreateSpotSheetOpen: true,
    })
  }

  public flipSpotActive = async (spot) => {
    spot.core_yms_spot.isActive = !spot.core_yms_spot.isActive
    await spot.save()
    this.loadSpots()
  }

  public renderZeroState = (helpText) => {
    return (
      <div className='c-boxListItem c-boxListItem--noHover'>
        <div className='c-boxListItem-content u-textCenter u-bumperTop--xl u-bumperBottom--xl'>
          <Icon
            className='u-bumperTop'
            icon={IconNames["HOME"]}
          />
          <HelpBlock>
            {helpText}
          </HelpBlock>
        </div>
      </div>
    )
  }

  private handleOpenCreateSpotSheet = async () => {
    const entitySchema = SchemaUris.SPOT_TYPE
    const { facility } = this.props

    const entityDefaultValue = {
      core_yms_spot: {
        facility: edgeFromEntity(facility)
      }
    }
    const schemas = await this.store.getEntitySchemasWithDependencies([entitySchema])
    const spotBeingEdited = this.store.createRecord(schemas[0], entityDefaultValue)
    this.setState({
      spotBeingEdited,
      isCreateSpotSheetOpen: true,
    })
  }

  private handleCloseCreateSpotSheet = (newSpotSchema: Entity) => {
    this.setState({ isCreateSpotSheetOpen: false })
    this.loadSpots()
  }
}
