import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'
// tslint:disable-next-line:max-line-length
import { AddressInput, IAddressInputProps } from 'browser/components/atomic-elements/atoms/input/address-input/address-input'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
// tslint:disable-next-line:max-line-length
import 'browser/components/atomic-elements/molecules/fields/embedded-location-field/_embedded-location-inline-input.scss'

const LOCATION_SCHEMA_URI = '/1.0/entities/metadata/location.json'

export interface IEmbeddedLocationInlineInputProps extends IAddressInputProps {
  entity: Entity
  index: number
}

interface IEmbeddedLocationInlineInputState {
  isLoading: boolean
  location: Entity
}

// We have 6 cases for embedded address: This deal with the inline mode
//
// Inline Mode (this is only available in the creation panel of the parent model)
// 1. creation - We stage the save of this child model to the parent entity
// 2. edit - No special logic needed to take care of this
// 3. deletion - We stage the delete of this from parent entity
//
// Sheet mode (this is possible after initial creation of parent model)
// 4. creation - should as entity association panel
// 5. edit - no special logic needed can use entity form sheet
// 6. delete - We need a hook into the abstract-list on delete
export class EmbeddedLocationInlineInput
  extends React.Component<IEmbeddedLocationInlineInputProps, IEmbeddedLocationInlineInputState> {

  private store: any

  constructor(props: IEmbeddedLocationInlineInputProps) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      isLoading: true,
      location: null,
    }
  }

  public componentDidMount() {
    this.fetchOrCreateRecord(this.props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { entity, value } = this.props
    const { location } = this.state
    const nextLocationId = _.get(nextProps.value, 'entityId')
    if (!nextLocationId) { return }
    if (nextLocationId === _.get(location, 'uniqueId')) { return }
    this.fetchOrCreateRecord(nextProps).then((newLocation) => {
      // if location already exists, remove it from parent record
      const oldLocation = this.state.location
      if (oldLocation) {
        entity.removeParentRecord(oldLocation)
      }
      entity.addParentRecord(newLocation)
    })
  }

  public componentWillUnmount() {
    const { entity } = this.props
    const { location } = this.state
    if (location) {
      entity.removeParentRecord(location)
    }
  }

  public render() {
    const { size } = this.props
    const { isLoading, location } = this.state
    const sizeClassName = size ? `c-fakeInputContainer--${size}` : ''
    if (isLoading) {
      return (
        <div className={classNames('relative c-fakeInputContainer c-embeddedLocationInlineInput-loadingContainer u-darkBorderBottom', sizeClassName)}>
          <LoadingSpinner size='xs' />
        </div>
      )
    }
    return (
      <AddressInput
        {...this.props}
        onChange={this.handleChange}
        value={_.get(location, 'location.address')}
      />
    )
  }

  private createLocationRecord(props) {
    // if we don't have a value and there is no existing location then create one
    const locationSchema = this.store.getRecord(LOCATION_SCHEMA_URI)
    const location = this.store.createRecord(locationSchema)
    // TODO(Peter): talk to Ben to add a hook to change the name
    location.location.name = 'Embedded Address'
    return Promise.resolve(location)
  }

  private fetchLocationRecord(props) {
    const { entity, value } = props
    // try to get location from entity if possible
    // e.g. if we are in the sheet, the entity would have already been added
    // by the embedded location field when it was mounted
    const location = entity.getParentRecord(value.entityId)
    return location ? Promise.resolve(location) :
      this.store.findRecord(value.entityId)
  }

  private fetchOrCreateRecord(props) {
    const { entity, value } = props
    const promise = props.value ?
      this.fetchLocationRecord(props) : this.createLocationRecord(props)
    return promise.then((location) => {
      this.setState({ isLoading: false, location })
      return location
    })
  }

  private handleChange = (address) => {
    const { entity, value } = this.props
    const { location } = this.state
    location.set('location.address', address)
    const uniqueId = location.get('uniqueId')
    this.setState({ location })

    // onChange materializes any placeholder item, we want to add record
    // to entity's pendingParentRecord for proper bookkeeping
    entity.addParentRecord(location)
    this.props.onChange({ entityId: uniqueId })
  }
}
