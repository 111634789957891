import _ from 'lodash'
import React from 'react'

import { QueryOptionsGenerator } from 'shared-libs/models/query-options-generator'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Header } from 'browser/components/atomic-elements/atoms/header/header'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Select } from 'browser/components/atomic-elements/atoms/select'
import { Sheet } from 'browser/components/atomic-elements/atoms/sheet'
import { Column } from 'browser/components/atomic-elements/atoms/table'
import { TableConfigurationSection } from 'browser/components/atomic-elements/atoms/table/table-configuration-section'

const SORT_ORDERS = [
  { label: 'Ascending', order: 'ascending' },
  { label: 'Descending', order: 'descending' },
]

interface ITableConfigurationSheetProps extends IBaseProps {
  availableColumns: any[]
  columns: Column[]
  entitySchema?: any
  groups?: any[]
  onChange: (value: any) => void
  orders?: any[]
}

interface ITableConfigurationSheetStates {
  columns: Column[]
  validSchemas: any[]
  groups: any[]
  isLoading: boolean
  isSaving: boolean
  orders: any[]
  orderBy: any
  orderType: any
}

export class TableConfigurationSheet
  extends React.Component<ITableConfigurationSheetProps, ITableConfigurationSheetStates> {

  public static defaultProps: Partial<ITableConfigurationSheetProps> = {
    availableColumns: [],
  }

  private query: any
  private sheet: Sheet

  constructor(props) {
    super(props)
    // TODO: (David) Currently using label because path can be either path or sortKey
    this.state = {
      columns: _.cloneDeep(props.columns) || [],
      groups: _.cloneDeep(props.groups) || [],
      isLoading: true,
      isSaving: false,
      orderBy: _.isEmpty(props.orders) ? null : props.orders[0].label,
      orderType: _.isEmpty(props.orders) ? null : props.orders[0].type,
      orders: _.cloneDeep(props.orders) || [],
      validSchemas: [],
    }
  }

  public componentDidMount() {
    const { entitySchema } = this.props
    this.query = QueryOptionsGenerator.getValidSchemas(apis, entitySchema)
    this.query.then((validSchemas) => {
      this.setState({ validSchemas, isLoading: false })
    })
  }

  public componentWillUnmount() {
    this.query?.cancel()
  }

  public render() {
    const {
      isLoading,
    } = this.state

    if (isLoading) {
      return this.renderLoading()
    }
    const handleSheetRef = (ref) => { this.sheet = ref }
    return (
      <Sheet
        footer={this.renderSheetFooter()}
        header={
          <Header
            title={`Table Configuration`}
          />
        }
        className='c-sheet--noBorder'
        bodyClassName='c-sheet-body--padded'
        disableFadeIn={true}
        ref={handleSheetRef}
        size='sm'
      >
        {this.renderSectionDetails()}
      </Sheet>
    )
  }

  private renderLoading() {
    return (
      <div>
        <div className='c-sheetBackdrop c-sheetBackdrop--fadeIn' />
        <LoadingSpinner size='sm' />
      </div>
    )
  }

  private renderSectionDetails() {
    const { availableColumns } = this.props
    const {
      columns,
      validSchemas,
      groups,
      isLoading,
    } = this.state

    if (isLoading) {
      return (
        <div className='u-positionRelative u-innerBumper--xl'>
          <LoadingSpinner size='sm' />
        </div>
      )
    }
    // Only the base entities properties can be groupon (eg. document.json) - because
    // those are the only properties all entities share. (Assuming that when we show
    // document and all entities that have document as a mixin.)
    const columnOptions = QueryOptionsGenerator.getColumnOptions(columns, validSchemas)
    columnOptions.push(...availableColumns)
    return (
      <div>
        <TableConfigurationSection
          addButtonText='Add Grouping'
          className='u-bumperBottom'
          emptyHelperText='No groupings added'
          maxSelection={5}
          onOrderChange={(groups) => this.setState({ groups })}
          onSelectionChange={this.handleNewGroup}
          options={QueryOptionsGenerator.getGroupOptions(groups, validSchemas)}
          selection={groups ? groups : []}
          title='Groupings'
        />
        <TableConfigurationSection
          addButtonText='Add Column'
          className='u-bumperBottom'
          emptyHelperText='No columns added'
          onOrderChange={(columns) => this.setState({ columns })}
          onSelectionChange={this.handleNewColumn}
          options={columnOptions}
          selection={columns}
          title='Columns'
        />
        {this.renderOrdersConfig()}
      </div>
    )
  }

  private renderOrdersConfig() {
    const { orders, orderBy, orderType } = this.state
    const order = _.first(orders) || {}
    return (
      <FormTable>
        <FormGroup isHorizontalLayout={true}>
          <Label isHorizontalLayout={true}>
            Default Sort
          </Label>
          <div className='c-formGroup-horizontalContent flex-column'>
            <Select
              isNative={true}
              onChange={this.handleSortKeyChange}
              options={this.getAvailableSoratbles()}
              optionLabelPath='label'
              optionValuePath='label'
              value={orderBy}
            />
            <Select
              className='u-pullUp'
              isDisabled={_.isEmpty(order)}
              isNative={true}
              onChange={this.handleSortOrderChange}
              options={SORT_ORDERS}
              placeholder={'Sort'}
              value={orderType}
              optionLabelPath='label'
              optionValuePath='order'
            />
          </div>
        </FormGroup>
      </FormTable>
    )
  }

  private handleSortKeyChange = (key, column: Column) => {
    const { orders } = this.state
    const order = _.first(orders) || {}
    order.path = column.path
    order.label = column.label
    order.type = order.type || 'descending'
    order.sortKey = column.sortKey
    this.setState({
      orderBy: order.label,
      orderType: order.type,
      orders: [order],
    })
  }

  private handleSortOrderChange = (sortOrder) => {
    const { orders } = this.state
    const order = _.first(orders) || {}
    order.type = sortOrder
    this.setState({
      orderBy: order.label,
      orderType: sortOrder,
      orders: [order],
    })
  }

  private renderSheetFooter() {
    const { isSaving } = this.state
    const handleClose = () => this.sheet.close()
    return (
      <Footer
        isVisible={true}
        isPrimaryButtonLoading={isSaving}
        onCancelButtonClick={handleClose}
        onPrimaryButtonClick={this.handleSave}
        primaryButtonText='Apply'
      />
    )
  }

  private getAvailableSoratbles() {
    const { columns } = this.state
    return _.filter(columns, { isSortable: true })
  }

  private handleSave = () => {
    const { columns, groups, orders } = this.state
    const { onChange } = this.props
    this.setState({ isSaving: true })
    if (!_.isEmpty(orders) && orders[0].sortKey) {
      orders[0].path = orders[0].sortKey
    }
    onChange({ columns, groups, orders })
    this.sheet.close()
  }

  private handleNewColumn = (option) => {
    const { columns } = this.state
    columns.push({ ...option })
    this.setState({ columns })
  }

  private handleNewGroup = (option) => {
    const { groups } = this.state
    groups.push({ ...option })
    this.setState({ groups })
  }
}
