import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { EntityRenderer } from 'shared-libs/components/entity/renderer'
import { getColumnConfigurationSchema } from 'shared-libs/models/client/column-configuration'
import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'
import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Header } from 'browser/components/atomic-elements/atoms/header/header'
import { Sheet } from 'browser/components/atomic-elements/atoms/sheet'

interface IColumnConfigurationSheetProps extends IBaseProps {
  column: any
}

interface IColumnConfigurationSheetStates {
  errors: any
  columnConfigurationEntity: Entity
}

export class ColumnConfigurationSheet
  extends React.Component<IColumnConfigurationSheetProps, IColumnConfigurationSheetStates> {

  private sheet: Sheet

  constructor(props) {
    super(props)
    const store = apis.getStore()
    const ColumnConfigurationSchema = getColumnConfigurationSchema(apis)
    ColumnConfigurationSchema.registerAllProperties()
    store.cacheRecord(ColumnConfigurationSchema)
    const columnConfigurationEntity = store.createRecord(ColumnConfigurationSchema, {
      columnConfiguration: {...props.column},
    })

    this.state = {
      columnConfigurationEntity,
      errors: null,
    }
  }

  public render() {
    const { errors, columnConfigurationEntity } = this.state
    const handleRef = (ref) => { this.sheet = ref }
    const context = {
      density: 'collapse',
      isHorizontalLayout: true,
    }
    return (
      <Sheet
        ref={handleRef}
        size='xs'
        bodyClassName='c-sheet-body--padded'
        header={
          <Header
            title={`Configure Column`}
          />
        }
        footer={
          <Footer
            isVisible={true}
            onCancelButtonClick={this.handleClose}
            onPrimaryButtonClick={this.handleSave}
          />
        }
      >
        <EntityRenderer
          actions={this}
          componentsMap={ComponentsMap}
          errors={errors}
          onChangeComplete={() => this.forceUpdate()}
          state={this.state}
          context={context}
          uiSchemaPath='uiSchema.web.columnConfigurationSheet'
          value={columnConfigurationEntity}
        />
      </Sheet>
    )
  }

  private handleClose = () => {
    this.sheet.close()
  }

  private handleSave = async () => {
    const { column } = this.props
    const { columnConfigurationEntity } = this.state
    const errors = await columnConfigurationEntity.validate()
    if (!_.isEmpty(errors)) {
      this.setState({ errors })
      return
    }
    _.assign(column, columnConfigurationEntity.get('columnConfiguration'))
    this.handleClose()
  }
}
