import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Classes } from '@blueprintjs/core'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { QuantityInput } from 'browser/components/atomic-elements/atoms/input/quantity-input'
import { HtmlTableRow, IHtmlTableRowProps } from 'browser/components/atomic-elements/atoms/table/html-table/row'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'

const PICK_UP = 'Pick Up'
const DROP_OFF = 'Drop Off'
const DISPATCH_ORDER_CARGOS_PATH = 'dispatchOrder.cargos'
const DISPATCH_ORDER_STOPS_PATH = 'dispatchOrder.stops'
const CARGO_QUANTITY_UNIT_SCHEMA_PATH =
  'properties.dispatchOrder.properties.cargos.items.properties.quantity.properties.unit'

// tslint:disable-next-line:max-line-length
import 'browser/components/atomic-elements/domains/trucking/order-cargo-delivery-task-row/_order-cargo-delivery-task-row.scss'
import { components } from 'react-select'

const WeightOptions = [
  { label: 'lbs', value: 'Pounds' },
  { label: 'kgs', value: 'Kilograms' },
]

interface IQuantity {
  unit: string
  value: string
}

interface ICargo {
  description?: string
  uniqueId?: string
  quantity?: IQuantity
  value?: IQuantity
  weight?: IQuantity
}

interface ICargoDeliveryTask {
  cargo?: any
  type?: string
  quantity?: IQuantity
  value?: IQuantity
  weight?: IQuantity
}

/**
 * @uiComponent
 */
interface IOrderCargoDeliveryTaskRowProps extends IHtmlTableRowProps {
  entity: any
  frames: any
  errorsMap: any
  value: ICargoDeliveryTask
}

interface IOrderCargoDeliveryTaskRowState {
  cargo: ICargo
  defaultQuantityUnit: string
  pickupCargo: ICargo
  pickupOptions: any[]
  stopIndex: number
  quantityOptions: any[]
}

export class OrderCargoDeliveryTaskRow
  extends React.Component<IOrderCargoDeliveryTaskRowProps, IOrderCargoDeliveryTaskRowState> {

  private htmlTableRow: HtmlTableRow

  constructor(props) {
    super(props)

    // get cargoSchema to find default values and enums
    const { entity, frames } = props
    const value = this.getValue()
    const quantityTypeSchema = entity.resolveSubschemaByPath(
      CARGO_QUANTITY_UNIT_SCHEMA_PATH).schema
    const quantityTypeEnums = _.get(quantityTypeSchema, 'suggestions', [])
    const defaultQuantityUnit = quantityTypeSchema.default
    const quantityOptions = _.map(quantityTypeEnums, (option) => {
      return { label: option, value: option }
    })

    // get cargo
    const cargos = entity.get(DISPATCH_ORDER_CARGOS_PATH, [])
    const cargoItemId = _.get(value, 'cargo.itemId')
    const cargo = _.find(cargos, { uniqueId: cargoItemId }) || {}

    // get stopIndex - dispatchOrder, stops, <stopIndex>, cargos, <cargoIndex>
    const valuePath = frames.getContext('valuePath')
    const stopIndex = valuePath[2]

    // if value is a pickup, its cargo is already pickup cargo. otherwise create a new one
    const pickupCargo: any = value.type === PICK_UP ? cargo : {
      uniqueId: uuidv4(),
    }
    const pickupOptions = [
      {
        cargo: pickupCargo,
        label: PICK_UP,
        type: PICK_UP,
        value: pickupCargo.uniqueId,
      },
    ]

    this.state = {
      cargo,
      defaultQuantityUnit,
      pickupCargo,
      pickupOptions,
      quantityOptions,
      stopIndex,
    }
  }

  public componentDidMount() {
    // Setting default value. We want to do it in componentDidMount becuase
    // handleItemTypeChange will trigger setState
    // In the first stop every line item should default to pick up
    // const value = this.getValue()
    // const { pickupOptions, stopIndex } = this.state
    // if (stopIndex === 0 && !value.type) {
    //   this.handleItemTypeChange(pickupOptions[0])
    // }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { entity, value } = nextProps
    const cargoId = _.get(this.state.cargo, 'uniqueId')
    const cargoItemId = _.get(value, 'cargo.itemId')
    if (cargoId !== cargoItemId) {
      const cargos = entity.get(DISPATCH_ORDER_CARGOS_PATH, [])
      const cargo = _.find(cargos, { uniqueId: cargoItemId }) || {}
      this.setState({ cargo })
    }
  }

  public focus() {
    this.htmlTableRow.focus()
  }

  public render() {
    const { cargo } = this.state
    const value = this.getValue()
    const hasItemType = !!value.type
    const isPickup = value.type === PICK_UP
    return (
      <HtmlTableRow
        {...this.props as IHtmlTableRowProps}
        ref={this.handleRef}
        renderControls={this.renderRemoveButton}
      >
        {this.renderTypeSelect(value, isPickup)}
        {this.renderCargoDescription(cargo, hasItemType, isPickup)}
        {this.renderValue(value, hasItemType, isPickup)}
        {this.renderQuantity(cargo, value, hasItemType, isPickup)}
        {this.renderWeight(cargo, value, hasItemType, isPickup)}
      </HtmlTableRow>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Helper functions
  //////////////////////////////////////////////////////////////////////////////

  private getCargos() {
    const { entity } = this.props
    return entity.get(DISPATCH_ORDER_CARGOS_PATH, [])
  }

  private getValue(): ICargoDeliveryTask {
    return this.props.value || {}
  }

  private showCargoDeleteConfirmation() {
    return new Promise((resolve, reject) => {
      ConfirmationModal.open({
        confirmationText: 'Do you want to delete this cargo?',
        confirmationTitle: 'Review Changes',
        modalDialogClassName: 'c-modal-dialog--sm',
        onPrimaryClicked: resolve,
        primaryButtonText: 'Confirm',
      })
    })
  }

  private getItemTypeOptions() {
    const { index } = this.props
    const { pickupCargo, pickupOptions, stopIndex } = this.state
    const cargos = this.getCargos()
    // Cargos in first stop can only have Pick Up
    if (stopIndex === 0) {
      return pickupOptions
    }
    // Cargos in other stop can have drop offs. However, the drop off cannot
    // include the pickup cargo in this row
    const dropOffCargos = _.filter(cargos, (cargo) => {
      return cargo.uniqueId !== pickupCargo.uniqueId
    })
    const dropOffOptions = []
    _.forEach(dropOffCargos, (cargo, cargoIndex) => {
      if (_.isEmpty(cargo.description)) {
        return
      }
      const value = `${DROP_OFF} - Cargo ${cargo.description}`
      dropOffOptions.push({
        cargo,
        label: value,
        type: DROP_OFF,
        value: cargo.uniqueId,
      })
    })
    return pickupOptions.concat(dropOffOptions)
  }

  //////////////////////////////////////////////////////////////////////////////
  // Cargo and Stop Cargo Sync
  //////////////////////////////////////////////////////////////////////////////

  private associateCargoWithStopCargo(itemType, cargo, value) {
    const { entity, onChange } = this.props
    const cargos = this.getCargos()
    value.cargo = { itemId: cargo.uniqueId }
    value.type = itemType
    if (itemType === PICK_UP) {
      // add cargo to dispatchOrder.cargos if it does not exist
      if (!_.find(cargos, { uniqueId: cargo.uniqueId })) {
        cargos.push(cargo)
        entity.set(DISPATCH_ORDER_CARGOS_PATH, cargos)
      }
    } else if (itemType === DROP_OFF) {
      // copy quantity, weight and value from cargo to item
      value.quantity = _.cloneDeep(cargo.quantity)
      value.weight = _.cloneDeep(cargo.weight)
      value.value = _.cloneDeep(cargo.value)
    }
    this.setState({ cargo })
    onChange(value)
  }

  private deleteCargoAndAssociatedDropOffs() {
    const cargos = this.getCargos()
    const { cargo } = this.state
    const { entity } = this.props
    // delete cargo from cargos array
    // console.log(`Deleting cargo`, cargo)
    // console.log(`Before:`, _.cloneDeep(cargos))
    _.remove(cargos, { uniqueId: cargo.uniqueId })
    // console.log(`After:`, _.cloneDeep(cargos))

    // delete any drop off for this cargo
    const stops = entity.get(DISPATCH_ORDER_STOPS_PATH, [])
    _.forEach(stops, (stop, stopIndex) => {
      // console.log(`Deleting cargo drop off from stop ${stopIndex}`)
      // console.log('Before:', _.cloneDeep(stop))
      _.remove(stop.cargos, (stopCargo: any) => {
        const cargoItemId = _.get(stopCargo, 'cargo.itemId')
        return stopCargo.type === DROP_OFF && cargoItemId === cargo.uniqueId
      })
      // console.log('After:', _.cloneDeep(stop))
    })
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleRef = (ref) => {
    this.htmlTableRow = ref
  }

  private handleItemTypeChange = (ununsed, option) => {
    const { cargo } = option
    const value = this.getValue()
    const previousItemType = value.type
    const newItemType = option.type
    if (previousItemType === PICK_UP && newItemType === DROP_OFF) {
      this.showCargoDeleteConfirmation().then(() => {
        this.deleteCargoAndAssociatedDropOffs()
        this.associateCargoWithStopCargo(newItemType, cargo, value)
      })
    } else {
      this.associateCargoWithStopCargo(newItemType, cargo, value)
    }
  }

  private handlePropertyChange = (key, newValue, { shouldUpdateCargo, shouldUpdateItem }) => {
    const { onChange } = this.props
    const { cargo } = this.state
    const value = this.getValue()
    if (shouldUpdateCargo) {
      _.set(cargo, key, newValue)
    }
    if (shouldUpdateItem) {
      _.set(value, key, newValue)
    }
    onChange(value)
  }

  private handleDeleteCargo = () => {
    const { onRemove, value } = this.props
    if (value.type !== PICK_UP) {
      onRemove()
    }
    this.showCargoDeleteConfirmation().then(() => {
      this.deleteCargoAndAssociatedDropOffs()
      onRemove()
    })
  }

  //////////////////////////////////////////////////////////////////////////////
  // Renderers
  //////////////////////////////////////////////////////////////////////////////

  private getErrorText(paths: string[]): string {
    const { errorsMap } = this.props
    for (let i = 0; i < paths.length; ++i) {
      const errorText = _.get(errorsMap, [paths[i], '_errors', 0], null)
      if (errorText) {
        return errorText
      }
    }
  }

  private renderTypeSelect(item, isPickup) {
    const errorText = this.getErrorText(['type'])
    const selectOptions = this.getItemTypeOptions()
    const isDisabled = _.isEmpty(item.type) && _.isEmpty(selectOptions)
    return (
      <SelectField
        className={classNames({
          'c-orderCargoDeliveryTaskRow-typeSelect': selectOptions.length > 1,
        })}
        errorText={isDisabled ? '' : errorText}
        size={this.props.size}
        isDisabled={isDisabled}
        onChange={this.handleItemTypeChange}
        options={selectOptions}
        value={item}
        valueRenderer={this.renderTypeValue}
      />
    )
  }

  private renderCargoDescription(cargo, hasItemType, isPickup) {
    const options = { shouldUpdateCargo: isPickup, shouldUpdateItem: false }
    const errorText = this.getErrorText(['description'])
    const isDisabled = !hasItemType || !isPickup
    return (
      <InputField
        errorText={isDisabled ? '' : errorText}
        isDisabled={isDisabled}
        onChange={(value) => this.handlePropertyChange('description', value, options)}
        size={this.props.size}
        value={cargo.description}
      />
    )
  }

  private renderQuantity(cargo, item, hasItemType, isPickup) {
    const { errorsMap } = this.props
    const { defaultQuantityUnit, quantityOptions } = this.state
    const options = { shouldUpdateCargo: isPickup, shouldUpdateItem: true }
    const defaultUnit: string = defaultQuantityUnit
    const isDisabled = !hasItemType
    const isUnitDisabled = !isPickup
    const value: IQuantity = _.get(item, 'quantity', null)
    if (isUnitDisabled && value) {
      value.unit = defaultUnit
    }
    return (
      <QuantityInput
        defaultUnit={defaultUnit}
        errorsMap={isDisabled ? null : _.get(errorsMap, 'quantity')}
        isDisabled={isDisabled}
        isUnitDisabled={isUnitDisabled}
        onChange={(value) => this.handlePropertyChange('quantity', value, options)}
        size={this.props.size}
        unitOptions={quantityOptions}
        value={value}
      />
    )
  }

  private renderWeight(cargo, item, hasItemType, isPickup) {
    const { errorsMap } = this.props
    const options = { shouldUpdateCargo: isPickup, shouldUpdateItem: true }
    const defaultUnit: string = _.get(cargo, 'weight.unit', 'Pounds')
    const isDisabled = !hasItemType
    const isUnitDisabled = !isPickup
    const value: IQuantity = _.get(item, 'weight', null)
    if (isUnitDisabled && value) {
      value.unit = defaultUnit
    }
    return (
      <QuantityInput
        defaultUnit={defaultUnit}
        errorsMap={isDisabled ? null : _.get(errorsMap, 'weight')}
        isDisabled={isDisabled}
        isUnitDisabled={!isPickup}
        onChange={(value) => this.handlePropertyChange('weight', value, options)}
        size={this.props.size}
        unitOptions={WeightOptions}
        value={value}
      />
    )
  }

  private renderValue(item, hasItemType, isPickup) {
    const { errorsMap } = this.props
    const options = { shouldUpdateCargo: isPickup, shouldUpdateItem: true }
    const isDisabled = !hasItemType
    return (
      <QuantityInput
        errorsMap={isDisabled ? null : _.get(errorsMap, 'value')}
        inputClassName='tr'
        isDisabled={isDisabled}
        onChange={(value) => this.handlePropertyChange('value', value, options)}
        size={this.props.size}
        showUnitSelect={false}
        value={_.get(item, 'value')}
      />
    )
  }

  private renderRemoveButton = () => {
    const { density, onRemove, size } = this.props
    const densityClassName = _.isEmpty(density) ? '' : `c-table-cell--${density}`
    const sizeClassName = _.isEmpty(size) ? '' : `c-table-cell--${size}`
    return (
      <td className={classNames('c-table-cell c-table-cell--delete', sizeClassName, densityClassName)}>
        <Button
          className={classNames('c-button--square c-table-cellDeleteButton', Classes.MINIMAL)}
          isDisabled={!onRemove}
          onClick={this.handleDeleteCargo}
          size={size}
        >
          <Icon
            icon={IconNames.CROSS}
          />
        </Button>
      </td>
    )
  }

  private renderTypeValue = (props) => {
    return <components.SingleValue {...props}>{props.data.type}</components.SingleValue>
  }
}
