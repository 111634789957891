import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import { Logger } from 'browser/apis/logging'
import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/molecules/files-viewer/_files-viewer.scss'
// tslint:disable-next-line:max-line-length
import { FileInput } from 'browser/components/json-elements/atoms/file-input/file-input'
import { IImageEditorCarouselProps } from '../../organisms/image-editor-carousel/image-editor-carousel'

const ATTACHMENTS_PATH = 'document.attachments'

/**
 * @uiComponent
 */
interface IFilesViewerProps extends IBaseProps {
  entity: any
  frames: any
  onChange: (value: any) => void
  showDownloadAllButton?: boolean
  showOptionsButton?: boolean
  showPrintPdfButton?: boolean
  showLightboxOnClick?: boolean
  hasBorderBottom?: boolean
  carouselProps?: Partial<IImageEditorCarouselProps>
  value: any
}

export class FilesViewer extends React.Component<IFilesViewerProps, any> {

  public static defaultProps: Partial<IFilesViewerProps> = {
    showDownloadAllButton: true,
    showPrintPdfButton: true,
    showLightboxOnClick: true,
  }

  public render() {
    const { className, entity, value } = this.props
    // TODO(Peter): it is unclear what part of the attachment is unavailable
    // if entity is in busy state. Honestly, I think we should just show
    // a 'busy' state. Instead of all these null checks everywhere in this
    // component
    const attachments = value
    const aggregate = _.get(attachments, 'aggregate')
    const files = _.get(attachments, 'files')
    const disableDownloadButtons = entity.isDirty
      && !_.isEqual(_.get(entity.content, ATTACHMENTS_PATH, {}), _.get(entity.prevContent, ATTACHMENTS_PATH, {}))

    // OCR Text available when we need it.
    // <a
    //   href={aggregate.text}
    //   target='_BLANK'
    //   className='c-button pt-minimal c-button--sm'
    //   download
    // >
    //   OCR Text
    // </a>

    return (
      <div className={classNames('c-attachmentRows', className)}>
        {this.renderDownloadPdfButton(aggregate, disableDownloadButtons)}
        {this.renderPrintButton(aggregate, disableDownloadButtons)}
        {this.renderFilesList()}
      </div>
    )
  }

  private renderDownloadPdfButton(aggregate, disableDownloadButtons) {
    if (_.isEmpty(aggregate) || !this.props.showDownloadAllButton) {
      return
    }
    const { entity } = this.props
    const fileName = `${entity.displayName}.pdf`
    const buttonText = 'Download PDF'
    const link = `${apis.getRenameDownloadURI(aggregate.uri, fileName)}&waitUntilIdle=true`
    return disableDownloadButtons ? (
      <a
        className={classNames(
          'c-attachmentDownloadButton',
          Classes.BUTTON,
          Classes.SMALL,
          Classes.MINIMAL,
          Classes.DISABLED
        )}
        href={'#'}
      >
        {buttonText}
      </a>
    ) : (
      <a
        className={classNames(
          'c-attachmentDownloadButton',
          Classes.BUTTON,
          Classes.SMALL,
          Classes.MINIMAL
        )}
        download={true}
        href={link}
        onClick={() => Logger.logEvent(buttonText)}
      >
        {buttonText}
      </a>
    )
  }

  private handlePrintClick(uri) {
    window.open(`${uri}?waitUntilIdle=true&shouldLoadInline=true`)
    return false
  }

  private renderPrintButton(aggregate, disableDownloadButtons) {
    const { showPrintPdfButton } = this.props
    if (aggregate && showPrintPdfButton) {
      return (
        <a
          className={classNames(
            'c-attachmentPrintButton',
            Classes.BUTTON,
            Classes.SMALL,
            Classes.MINIMAL,
            {
              [Classes.DISABLED]: disableDownloadButtons,
            }
          )}
          href="#"
          onClick={disableDownloadButtons ? () => false : () => { this.handlePrintClick(aggregate.uri) } }
        >
          Print PDF
        </a>
      )
    }
  }

  private renderFilesList() {
    const { frames, value } = this.props
    // TOOD(Peter): need to handle value when undefined. Breaks on perdue sheet
    const handleChange = (files) => {
      const contentValue = value || {}
      contentValue.files = files
      this.props.onChange(contentValue)
    }
    const inputValue = value ? value.files : []
    return (
      <FileInput
        {...this.props}
        frames={frames}
        isMultiFile={true}
        onChange={handleChange}
        value={inputValue}
      />
    )
  }
}
