import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core'
import apis from 'browser/app/models/apis'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Settings } from 'browser/app/models/settings'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { EntityFormPanel } from 'browser/components/atomic-elements/organisms/entity/entity-form-panel'

/**
 * @uiComponent
 */
export interface IOmniButtonItemProps extends IBaseProps {
  id: string
  label: string
  hotkey?: string
  settings: Settings
}

class BaseOmniButtonItem extends React.Component<IOmniButtonItemProps, any, never> {

  public render() {
    const { label } = this.props
    return (
      <li
        className='c-dropdownList-item'
        onClick={this.handleClick}
      >
        {label}
      </li>
    )
  }

  public renderHotkeys() {
    const { hotkey, label } = this.props
    return (
      <Hotkeys>
        <Hotkey
          global={true}
          combo={hotkey}
          label={label}
          onKeyDown={this.handleClick}
        />
      </Hotkeys>
    )
  }

  private handleClick = () => {
    const { id, settings } = this.props
    const schema = apis.getStore().getRecord(id)
    EntityFormPanel.open({ schema, state: { settings } })
  }
}

// tslint:disable-next-line:max-classes-per-file
@HotkeysTarget
class OmniButtonItemWithHotkeys extends BaseOmniButtonItem {}

// tslint:disable-next-line:max-classes-per-file
export class OmniButtonItem extends React.Component<IOmniButtonItemProps, any> {
  public render() {
    const { hotkey } = this.props
    return hotkey
      ? <OmniButtonItemWithHotkeys {...this.props} />
      : <BaseOmniButtonItem {...this.props} />
  }
}
