import React from 'react'
import { IContainerProps } from 'browser/components/containers/container-props'
import apis from 'browser/app/models/apis'
import { LoadingSpinner } from '../../atoms/loading-spinner/loading-spinner'
import 'browser/components/atomic-elements/organisms/embedded-frame/embedded-frame.scss'
import { buildFullMetabaseUrl } from './utils'

enum FrameType {
  METABASE = 'METABASE',
}

interface IEmbeddedFrameProps extends IContainerProps {
  frameType?: FrameType
  url?: string
  dashboardId?: string
  metabaseParams?: string
  metabaseLockedParams?: any
}

interface IEmbeddedFrameState {
  url?: string
  error?: any
}

export class EmbeddedFrame extends React.Component<IEmbeddedFrameProps, IEmbeddedFrameState> {
  constructor(props) {
    super(props)
    this.state = {
      url: this.props.url,
      error: undefined,
    }
  }

  public componentDidMount() {
    const { frameType } = this.props
    if (frameType === FrameType.METABASE) {
      this.getMetabaseLink()
    }
  }

  public render() {
    const { error, url } = this.state
    if (error) {
      return <p>The page could not be embedded.</p>
    } else if (!url) {
      return <LoadingSpinner />
    } else {
      return <iframe className="embedded-frame" src={url} />
    }
  }

  private getMetabaseLink = async () => {
      const { dashboardId, metabaseParams, metabaseLockedParams} = this.props
    try {
        const baseUrl = (await apis.getMetabaseUrl({ dashboardId: dashboardId, params: metabaseLockedParams })).signedUrl
      this.setState({
        url: buildFullMetabaseUrl(baseUrl, metabaseParams)
      })
    } catch (error) {
      this.setState({
        error,
      })
    }
  }
}
