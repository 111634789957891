import { browserHistory } from 'browser/history'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { AbstractEntityView, IAbstractEntityViewState } from 'browser/components/containers/abstract-entity-view'

interface IDocumentImagingSettingsViewState extends IAbstractEntityViewState {
  isDisconnecting: boolean
}

export class DocumentImagingSettingsView extends AbstractEntityView<IDocumentImagingSettingsViewState> {

  constructor(props) {
    super(props)
    this.state = {
      entity: null,
      errors: null,
      isDisconnecting: false,
      isLoading: true,
      isSaving: false,
    }
  }
}
