import _ from 'lodash'

import { EntityType } from './entity-type'
import { Entity } from '../entity'
import { CheckinListResponse } from '../../generated/server-types/entity/action/system/sms/flow/checkinListResponse'

const JOIN_REQUEST_ENTITY_TYPE = '/1.0/entities/metadata/joinRequest.json'
const FIRM_ADMIN_ENTITY_TYPE = '11111111-0000-0000-0000-000000000015'
const MANAGER_ENTITY_TYPE = '17c8c85e-8ba3-40f9-92ff-4153ff0875c4'

export class User extends EntityType {

  public get identity() {
    const emails = this.entity.get('person.emails')
    const phones = this.entity.get('person.phoneNumbers')
    const primaryEmail: any = _.find(emails, { isVerified: true })
    const primaryPhone: any = _.find(phones, { isVerified: true })
    if (primaryEmail) {
      return primaryEmail.value
    }
    if (primaryPhone) {
      return primaryPhone.value.phone
    }
  }

  public get role() {
    const isFirmAdmin = _.find(this.entity.activeMixins, (mixin: any) => {
      return mixin.entityId === FIRM_ADMIN_ENTITY_TYPE
    })
    const isManager = _.find(this.entity.activeMixins, (mixin: any) => {
      return mixin.entityId === MANAGER_ENTITY_TYPE
    })

    if (isFirmAdmin) {
      return 'Admin'
    } else if (isManager) {
      return 'Manager'
    } else {
      return 'Member'
    }
  }

  public get additionalIdentity(): string {
    const identityString = this.identity
    if (identityString) {
      return identityString
    }

    const employeeId = this.entity.get('user.employeeId')
    if (employeeId) {
      return employeeId
    }

    const additionalIdentities = this.entity.get('user.additionalIdentities')
    if (additionalIdentities?.length > 0) {
      const additionalIdentity = additionalIdentities[0]
      return `${additionalIdentity.type}: ${additionalIdentity.driverUniqueId}`
    }
    return ''
  }

  public activate = () => {
    this.entity.set('user.isActive', true)
    return this.entity.save()
  }

  public deactivate = () => {
    this.entity.set('user.isActive', false)
    return this.entity.save()
  }

  public purgeCache = () => {
    const url = `${this.api.getHost()}/1.0/entities/actions/system/user/manage/clearCache/${this.entity.uniqueId}`
    return this.api.postJSON(url, {})
  }

  // replace handleAddEmailToUser
  // replace handleAddPhoneNumberToUser
  public addEmailIdentity = (label, identity) => {
    const emails = this.entity.get('person.emails', [])
    const labeledIdentity = _.remove(emails, { label })
    emails.push({ label: 'Primary Email', value: identity })
    this.entity.set('person.emails', emails)
  }

  public addPhoneIdentity = (label, identity) => {
    const phoneNumbers = this.entity.get('person.phoneNumbers')
    const labeledIdentity = _.remove(phoneNumbers, { label })
    phoneNumbers.push({ label: 'Primary Phone', value: identity })
    this.entity.set('person.phoneNumbers', phoneNumbers)
  }

  public getPendingInvites = () => {
    const store = this.api.getStore()
    let invites, unverifiedInvites
    return this.fetchPendingInvites().then((json: any) => {
      invites = json.invites
      unverifiedInvites = json.unverifiedInvites
      const createdByIds = _.map(invites, 'createdBy.entityId')
      return store.findRecords(createdByIds)
    }).then((invitedByUsers) => {
      _.forEach(invites, (invite) => {
        this.modifyInviteByDisplayName(invite, invitedByUsers)
      })
      return { invites, unverifiedInvites }
    })
  }

  public requestJoinFirm = (firmId) => {
    const store = this.api.getStore()
    const metadata = store.getRecord(JOIN_REQUEST_ENTITY_TYPE)
    const joinRequest = store.createRecord(metadata, {
      accessRequest: {
        receivingFirm: { entityId: firmId },
        state: 'pending',
      },
    })
    return joinRequest.save()
  }

  public fetchConversations(): Promise<CheckinListResponse> {
    const url = `${this.api.getHost()}/1.0/entities/actions/sms/checkins`
    return this.api.postJSON(url, {
      userId: this.entity.uniqueId
    })
  }

  private fetchPendingInvites() {
    const url = `${this.api.getHost()}/1.0/entities/actions/system/user/pendingInvites`
    return this.api.getJSON(url).then(({ invites, unverifiedInvites }) => {
      return {
        invites: _.map(invites, (json) => new Entity(json, this.api)),
        unverifiedInvites: _.map(unverifiedInvites, (json) => new Entity(json, this.api)),
      }
    })
  }

  private modifyInviteByDisplayName(invite, invitedByUsers) {
    const user: any = _.find(invitedByUsers, {
      uniqueId: invite.get('createdBy.entityId'),
    })
    const labeledEmail: any = _.first(user.person.emails)
    const userDisplayName = labeledEmail ?
        `${user.displayName} (${labeledEmail.value})` : user.displayName
    invite.set('createdBy.displayName', userDisplayName)
  }
}
