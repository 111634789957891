import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import padStart from 'lodash/padStart'
import moment from 'moment'

import './timer.scss'

/**
 * @uiComponent
 */
interface ITimerProps {
  expiration: any
  expirationText: string
  expiringThreshold?: number
  expiringTextStyle?: any
  expiredTextStyle?: any
  textStyle?: any
}

/**
 * Countdown timer component allowing custom styles.
 *
 * @param expiration sets datetime for when time expires
 * @param expirationText sets text to display when time expires
 * @param expirationThreshold sets seconds for when to change countdown to warning color
 * @param expiringTextStyle sets text style for warning mode (default: orange)
 * @param expiredTextStyle set text style for expired mode (default: red)
 * @param textStyle set text style (default: black)
 */
export const Timer: React.FC<ITimerProps> = ({
  expiration,
  expirationText,
  expiringThreshold,
  expiringTextStyle,
  expiredTextStyle,
  textStyle,
}) => {
  const [remainingSeconds, setRemainingSeconds] = useState(0)
  const [update, setUpdate] = useState(0)

  useEffect(() => {
    const timerId = setInterval(() => {
      setRemainingSeconds(getRemainingSeconds())
      setUpdate(update + 1)
    }, 1000)

    return () => clearInterval(timerId)
  }, [update])

  const getExpirationDateTime = () => {
    const expirationDate = expiration['dateTime']
    return expirationDate ? expirationDate : expiration
  }

  const getRemainingSeconds = () => {
    const dateTime = getExpirationDateTime()
    return moment(dateTime).diff(moment(), 's')
  }

  const formatCountdown = () => {
    const min = Math.floor(remainingSeconds / 60)
    const sec = remainingSeconds % 60
    const formattedParts = [min, sec].map((part) => padStart(Math.max(0, part).toString(), 2, '0'))
    return formattedParts.join(':')
  }

  const expired = remainingSeconds < 0
  const content = expired ? expirationText : formatCountdown()
  let timerTextStyle = textStyle
  if (expiringThreshold > 0 && remainingSeconds < expiringThreshold && remainingSeconds > 0) {
    timerTextStyle = expiringTextStyle
  }
  if (expired) {
    timerTextStyle = expiredTextStyle
  }

  return (
    <div className="timer__value-container">
      <div style={timerTextStyle}>{content}</div>
    </div>
  )
}

Timer.defaultProps = {
  expirationText: 'Expired',
  expiringThreshold: 0,
  expiringTextStyle: { color: '#f89939' }, // orange
  expiredTextStyle: { color: '#e53935' }, // red
  textStyle: { color: '#262626' }, // black
}
