import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'
import { default as EntitySelect, IEntitySelectProps } from 'browser/components/atomic-elements/atoms/select/entity-select'
import { UserProps } from 'shared-libs/models/prop-constants'
import { components } from 'react-select'

import {
  NameOptionProp,
  Option,
  OptionPropLabel,
  ShortOptionProp,
} from 'browser/components/domain/user/dialog-option-styles'

// tslint:disable-next-line:no-empty-interface
type IDriverSelectProps = IEntitySelectProps

interface IDriverSelectStates {
  isLoading: boolean
  driverEntity: Entity
}

export class DriverSelect extends React.Component<IDriverSelectProps, IDriverSelectStates> {
  private input: any
  private store: any

  constructor(props: IDriverSelectProps) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      driverEntity: null,
      isLoading: true,
    }
  }

  public componentDidMount() {
    const { value } = this.props
    const valueId: string = _.get(value, 'entityId')
    this.fetchValueEntity(valueId)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const valueId = _.get(this.props.value, 'entityId')
    const nextValueId: string = _.get(nextProps.value, 'entityId')
    if (valueId !== nextValueId) {
      this.fetchValueEntity(nextValueId)
    }
  }

  public focus() {
    this.input.focus()
  }

  public render() {
    const {
      className,
      isStatic,
    } = this.props
    const selectNotFilter = {
      path: 'driver.linkedUser',
      type: 'notExists'
    }
    if (!isStatic) {
      return (
        <EntitySelect
          {...this.props}
          className={className}
          filter={selectNotFilter}
          optionRenderer={this.handleRenderOption}
          valueRenderer={this.handleRenderValue}
          ref={(ref) => { this.input = ref }}
        />
      )
    }
  }

  private handleRenderValue = (props) => {
    const user = props.data
    return (
      <components.SingleValue {...props}>
        <NameOptionProp>
          {_.get(user, UserProps.NAME)}
        </NameOptionProp>
      </components.SingleValue>
    )
  }

  private handleRenderOption = (props) => {
    const user = props.data
    return (
      <components.Option {...props}>
        <ShortOptionProp>
          <OptionPropLabel>
            Source ID:
          </OptionPropLabel>
          {_.get(user, 'driver.sourceId')}
        </ShortOptionProp>
        <NameOptionProp>
          {_.get(user, UserProps.NAME)}
        </NameOptionProp>
      </components.Option>
    )
  }

  private fetchValueEntity(uniqueId: string) {
    if (!uniqueId) {
      return this.setState({ driverEntity: null })
    }
    this.setState({ isLoading: true })
    this.store.findRecord(uniqueId).then((entity) => {
      this.setState({
        driverEntity: entity,
        isLoading: false,
      })
    })
  }
}
