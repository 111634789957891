import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { Textarea } from 'browser/components/atomic-elements/atoms/textarea/textarea'
import { RecipientsField } from 'browser/components/atomic-elements/molecules/fields/recipients-field'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import 'browser/components/atomic-elements/organisms/share-configuration-modal/_share-configuration-modal.scss'
import { RecipientItem } from 'browser/components/atomic-elements/organisms/share-configuration-modal/recipient-item'
// tslint:disable-next-line:max-line-length
import { SuggestedRecipientsList } from 'browser/components/atomic-elements/organisms/share-configuration-modal/suggested-recipients-list'
import { Entity } from 'shared-libs/models/entity'
import { ErrorBlock } from '../../atoms/error-block/error-block'
import { isEmailValid, isPhoneValid, isUUIDValid } from 'shared-libs/helpers/utils'

interface IShareConfigurationModalProps extends IBaseProps {
  entities: Entity[]
  onClose?: () => void
  onSend?: (emails: string[], message: string) => Promise<any>
  shares?: Entity[]
  showRecipientsList?: boolean
  showShareAccessSettings?: boolean
  suggestedRecipients?: any[]
  title?: string
}

interface IShareConfigurationModalState {
  shareMessage?: string
  isSending: boolean
  recipients: any[]
  recipientFieldInputValue?: string
  shareWithErrorText: string
  shares?: Entity[]
  errorText?: string
  hasReachedMaxShares?: boolean
}

/**
 * The maximum amount of shares allowed for a single entity. 
 * Should be kept in sync with the constant of the same name in ShareThresholdTrigger.
 */
const MAX_SHARE_THRESHOLD = 100;

// tslint:disable-next-line:max-line-length
export class ShareConfigurationModal
  extends React.PureComponent<IShareConfigurationModalProps, IShareConfigurationModalState> {

  public static defaultProps: Partial<IShareConfigurationModalProps> = {
    title: 'Sharing & Notifications Settings',
  }

  public static open(props) {
    return OverlayManager.openOverlayElement(
      <Modal>
        <ShareConfigurationModal {...props} />
      </Modal>,
    )
  }

  constructor(props) {
    super(props)
    const { shares } = props
    const shareState = this.generateShareThresholdState(shares);
    this.state = {
      isSending: false,
      recipients: [],
      shareWithErrorText: shareState.shareWithErrorText,
      shares: shares ? shares.slice() : [],
      hasReachedMaxShares: shareState.hasReachedMaxShares,
    }
  }

  public render() {
    const { title } = this.props
    return (
      <div
        className='c-shareConfigurationModal'
        data-debug-id='shareConfigurationModal'
      >
        <div
          className='c-modalHeader'
          data-debug-id='member-configuration'
        >
          <h4 className='c-modal-title'>{title}</h4>
        </div>
        <div className='c-modalBody collapse'>
          {this.renderRecipientField()}
          {this.renderModalContent()}
        </div>
      </div>
    )
  }

  private renderRecipientField() {
    const { shareWithErrorText, hasReachedMaxShares } = this.state
    return (
      <div className='u-innerBumperLeft--lg u-innerBumperRight--lg mt3'>
        {/* No point in rendering suggested recipients if the user has no shares left */}
        {!hasReachedMaxShares && this.renderRecipients()}
        {!hasReachedMaxShares && this.renderSuggestedRecipients()}
        {shareWithErrorText}
      </div>
    )
  }

  private renderRecipients() {
    const { isSending, recipients } = this.state
    return ( 
      <FormTable>
        <div className='flex'>
          <RecipientsField
            autoFocus={true}
            className='u-flexGrow'
            isDisabled={isSending}
            label=''
            onChange={this.handleRecipientsChange}
            onInputChange={this.handleInputChange}
            value={recipients}
          />
        </div>
      </FormTable>
    );
  }

  private renderSuggestedRecipients() {
    const { suggestedRecipients } = this.props
    const { recipients } = this.state
    return (
      <SuggestedRecipientsList
        onChange={this.handleRecipientsChange}
        suggestions={suggestedRecipients}
        recipients={recipients}
      />
    )
  }

  private renderModalContent() {
    const { isSending, recipients, recipientFieldInputValue, shareMessage } = this.state
    if (_.isEmpty(recipientFieldInputValue) && _.isEmpty(recipients) && !isSending) {
      return (
        <div>
          {this.renderShareInfo()}
          <Footer
            className='u-bumperTop'
            isVisible={true}
          >
            {this.renderDefaultFooter()}
          </Footer>
        </div>
      )
    }
    return (
      <div>
        <FormTable className='u-bumperTop u-bumperLeft--lg u-bumperRight--lg'>
          <Textarea
            placeholder='Add a note (optional)'
            minRows={3}
            onChange={this.handleShareMessageChange}
            value={shareMessage}
          />
        </FormTable>
        <Footer
          className='u-bumperTop'
          isVisible={true}
        >
          {this.renderRecipientFieldFooter()}
        </Footer>
      </div>
    )
  }

  private renderShareInfo() {
    const { showRecipientsList, showShareAccessSettings } = this.props
    if (!showRecipientsList && !showShareAccessSettings) { return }
    return (
      <div className='u-innerBumperLeft--lg u-innerBumperRight--lg'>
        {this.renderRecipientsList()}
        {this.renderShareAccessSettings()}
      </div>
    )
  }

  private renderRecipientsList() {
    const { showRecipientsList } = this.props
    const { shares } = this.state
    if (!showRecipientsList) { return }
    if (_.isEmpty(shares)) {
      return (
        <HelpBlock className='u-border u-innerBumper--xl u-innerBumperBottom--xl u-textCenter u-bumperTop'>
          Recipients of this document can view and post comments.
        </HelpBlock>
      )
    }
    return (
      <div className='u-bumperBottom u-bumperTop'>
        {_.map(shares, (share, index) => (
          <RecipientItem
            key={index}
            onDelete={this.handleDeleteShare}
            share={share}
          />
        ))}
      </div>
    )
  }

  private renderShareAccessSettings() {
    const { entities, showShareAccessSettings } = this.props
    if (!showShareAccessSettings) { return }
    const entity = _.first(entities)
    const firmName = entity.get('owner.firm.displayName')
    const message = entities.length > 1 ?
      `${firmName} firm admins and document owners can view and edit these documents` :
      `${firmName} firm admins and document owners can view and edit this ${entity.entityType}`
    return (
      <div className='c-boxListItem c-boxListItem--noHover u-bumperTop'>
        <div className='c-boxListItem-content'>
          <div className='flex'>
            <div className='u-bumperLeft--xs u-bumperRight--lg u-bumperTop--xxs'>
              <Icon
                icon={IconNames.OFFICE}
              />
            </div>
            <div className='u-flexGrow'>
              <div className='b'>
                Access and edit settings
              </div>
              <div>
                {message}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private renderDefaultFooter() {
    const {
      onClose,
    } = this.props
    return (
      <div className='flex u-flexGrow u-justifyContentSpaceBetween'>
        <div className='c-footer-group'>
          <div className='c-footer-item'>
            <Button
              data-debug-id='closePopoverButton'
              onClick={onClose}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    )
  }

  private renderRecipientFieldFooter() {
    return (
      <div className='u-flex u-flexGrow u-justifyContentSpaceBetween'>
        <div className='c-footer-group'>
          <div className='c-footer-item'>
            <Button
              onClick={this.handleClearRecipients}
            >
              Cancel
            </Button>
          </div>
        </div>
        {this.renderErrorFooterItem()}
        {this.renderSendFooterItem()}
      </div>
    )
  }

  private renderErrorFooterItem() {
    const { errorText } = this.state
    return (
      <div className='c-footer-group'>
        <ErrorBlock
          errorText={errorText}
          />
      </div>
    )
  }
  
  private renderSendFooterItem() {
    const { isSending, recipientFieldInputValue, errorText } = this.state
    return (
      <div className='c-footer-group'>
        <div className='c-footer-item'>
          <Button
            className={Classes.INTENT_PRIMARY}
            data-debug-id='sendInviteButton'
            isDisabled={!_.isEmpty(recipientFieldInputValue) || !_.isNil(errorText)}
            onClick={this.handleSend}
            isLoading={isSending}
          >
            Send
          </Button>
        </div>
      </div>
    )
  }

  /**
   * Checks the number of shares against the share threshold and generates states accordingly.
   * NOTE: Does NOT set state, see {@link updateShareThresholdState}.
   * @param shares users this entity is being shared with, passed in from constructor or from state
   * @returns partial state object with share threshold properties
   */
  private generateShareThresholdState(shares?: Entity[]) {
    if (!shares) {
      ({ shares } = this.state ?? {});
    }
    const hasReachedMaxShares = _.size(shares) >= MAX_SHARE_THRESHOLD;
    let errorText = '';
    if (hasReachedMaxShares) {
      errorText = 'You have reached the maximum number of users you can share this document with.';
    }
    return {shareWithErrorText: errorText, hasReachedMaxShares: hasReachedMaxShares};
  }

  /**
   * {@link setState} shouldn't be called in the constructor, so here the state setting logic
   * is separated from the state creation logic in {@link generateShareThresholdState}.
   */
  private updateShareThresholdState() {
    this.setState(this.generateShareThresholdState());
  }

  /**
   * Checks if the user is attempting to share this document with too many recipients and sets the
   * state accordingly. This method is to be called when the user attempts to add a new recipient,
   * in {@link handleRecipientsChange}.
   * @param recipients list that the user is attempting to share to, passed in from recipients input
   * change handler
   * @returns has the user added too many recipients
   */
  private checkHasTooManyRecipients(recipients) {
    const { shares } = this.state;
    const hasExceededMaxRecipients = shares.length + recipients.length > MAX_SHARE_THRESHOLD;
    if (hasExceededMaxRecipients) {
      this.setState({ shareWithErrorText: `You have reached the maximum number of users you can share this document with.` });
    }
    return hasExceededMaxRecipients;
  }
  
  // TODO: (David): Add to check there is no duplicate recipients
  private checkHasDuplicateRecipient(recipient) {
    if (_.isEmpty(recipient)) { return false }
    return this.hasDuplicateEmails(recipient) || this.hasDuplicatePhoneNumbers(recipient) || this.hasDuplicateUUIDs(recipient)
  }

  private hasDuplicateEmails(email) {
    const { shares } = this.state
    const sharedToEmails = _.map(shares, 'share.sharedTo.email')
    if (_.includes(sharedToEmails, email)) {
      this.setState({ shareWithErrorText: `You have already shared to ${email}` })
      return true
    }
    return false
  }

  // TODO: (David): rewrite this....
  private hasDuplicatePhoneNumbers(phoneNumber) {
    const { shares } = this.state
    const sharedToPhoneNumbers = _.map(shares, (share) => {
      let rawNumber = ''
      const shareNumber = _.get(share, 'share.sharedTo.phoneNumber.phone')
      if (shareNumber) {
        for (let x = 0; x < shareNumber.length; x++) {
          if (!isNaN(shareNumber.charAt(x))) {
            if (shareNumber.charAt(x) === ' ') {
              continue
            }
            rawNumber = rawNumber + shareNumber.charAt(x)
          }
        }
        return rawNumber
      }
      return undefined
    })
    let compareNumber = phoneNumber
    // 11 digits w/ extension 1, 10 w/o
    if (phoneNumber.length < 11 ) {
      compareNumber = '1' + phoneNumber
    }
    if (_.includes(sharedToPhoneNumbers, compareNumber)) {
      this.setState({ shareWithErrorText: `You have already shared to ${phoneNumber}` })
      return true
    }
    return false
  }

  private hasDuplicateUUIDs(uuid) {
    const { shares } = this.state
    const sharedToUUIDs = _.map(shares, 'share.sharedTo.user.entityId')
    if (_.includes(sharedToUUIDs, uuid)) {
      this.setState({ shareWithErrorText: `You have already shared to one of the selected users` })
      return true
    }
    return false
  }

  private handleClearRecipients = () => {
    this.setState({ recipients: [], shareWithErrorText: '' })
  }

  private handleDeleteShare = (share: Entity) => {
    const { shares } = this.state
    return share.delete().then(() => {
      this.setState({ shares: _.without(shares, share) })
      this.updateShareThresholdState();
    })
  }

  private handleRecipientsChange = (recipients) => {
    const errors = []
    this.setState({ shareWithErrorText: '' })
    if (this.checkHasTooManyRecipients(recipients)) {
      errors.push('You have exceeded the maximum number of shares for this document.')
    }
    for (const recipient of recipients) {
      if (this.checkHasDuplicateRecipient(recipient)) {
        errors.push(`${recipient} is duplicated.`)
      } else if (!isEmailValid(recipient) && !isPhoneValid(recipient) && !isUUIDValid(recipient)) {
        errors.push(`${recipient} is invalid.`)
      }
    }
    const errorText = errors.length ? _.join(errors, "; ") : undefined
    this.setState({ recipients, errorText })
  }

  private handleInputChange = (recipientFieldInputValue) => {
    this.setState({ recipientFieldInputValue })
  }

  private handleSend = () => {
    const { onClose, onSend } = this.props
    const { shareMessage, recipients, errorText } = this.state
    this.setState({ isSending: true })
    onSend(recipients, shareMessage)
      .then(onClose)
      .catch((error) => {
        this.setState({
          isSending: false,
          errorText: _.join(_.flatten(_.values(error.errors)), '; ')
        })
      })
  }

  private handleShareMessageChange = (shareMessage) => {
    this.setState({ shareMessage })
  }
}
