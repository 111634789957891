import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/table/cells/_table-link-cell.scss'

/**
 * @uiComponent
 */
interface ITableLinkCellProps extends IBaseProps {
  isRelativePath?: boolean
  labelPath?: string
  linkPath?: string
  match?: any
  path?: any
  search?: string
  shouldStopPropagation?: boolean
  target?: string
  value: any
}

@withRouter
export class TableLinkCell extends React.Component<ITableLinkCellProps, any> {
  public static defaultProps: Partial<ITableLinkCellProps> = {
    labelPath: 'displayName',
    linkPath: 'entityId',
    path: 'entity',
  }

  public render() {
    const {
      className,
      labelPath,
      search,
      shouldStopPropagation,
      target,
      value,
    } = this.props

    if (!value) {
      return <div> – </div>
    } else if (_.isString(value)) {
      return <div>{value}</div>
    }

    const label = _.get(value, labelPath, '')
    const pathname = this.getPathname()
    const handleClick = (e) => {
      if (shouldStopPropagation) { e.stopPropagation() }
    }
    return (
      <NavLink
        activeClassName='active'
        className={classNames('c-tableLink c-link', className)}
        onClick={handleClick}
        target={target}
        to={{ pathname, search }}
      >
        {label}
      </NavLink>
    )
  }

  private getPathname() {
    const { isRelativePath, linkPath, match, path, value } = this.props
    const link = _.get(value, linkPath, '')
    if (isRelativePath) {
      return `${match.url}/${path}/${link}`
    }
    return `/${path}/${link}`
  }
}
