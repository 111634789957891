import React from 'react'

import { InlineEditable } from 'browser/components/atomic-elements/molecules/inline-editable'
import { IToggleFieldImplProps, ToggleFieldImpl } from './toggle-field'

/**
 * @uiComponent
 */
interface IToggleFieldProps extends IToggleFieldImplProps {
  onRemove?: () => void
  onSave?: () => void
  validate?: (value: any) => boolean
  helpText?: string
}

export class ToggleField extends React.Component<IToggleFieldProps, any> {

  public render() {
    const { isEditableInline } = this.props
    const toggleProps: IToggleFieldImplProps = this.props as any
    if (isEditableInline) {
      return (
        <InlineEditable
          isStatic={this.props.isStatic}
          onChange={this.props.onChange}
          onDelete={this.props.onRemove}
          onSave={this.props.onSave}
          size={this.props.size}
          validate={this.props.validate}
          value={this.props.value}
        >
          <ToggleFieldImpl {...toggleProps} />
        </InlineEditable>
      )
    } else {
      return <ToggleFieldImpl {...toggleProps} />
    }
  }
}
