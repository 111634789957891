import { Classes, Icon, Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import React from 'react'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'

interface IPagingControls {
  dataSet: any
  pageIndex: number
  onNavigateToNextPage: () => void
  onNavigateToPreviousPage: () => void
}

@HotkeysTarget
export class PagingControls extends React.Component<IPagingControls, any, never> {
  public render() {
    const { dataSet, pageIndex, onNavigateToNextPage, onNavigateToPreviousPage } = this.props
    const totalPages = dataSet.count
    return (
      <CardHeaderItem className="c-cardHeader-item--alignCenter c-cardHeader-item--smallMargin u-flexDirectionRow u-alignItemsCenter">
        <div className="u-bumperRight--xs u-nowrap">
          <span className="u-fontWeightSemibold">{pageIndex + 1}</span> of{' '}
          <span className="u-fontWeightSemibold">{totalPages}</span>
        </div>
        <div className={Classes.BUTTON_GROUP}>
          <Button isDisabled={!this.hasPrevPage()} onClick={onNavigateToPreviousPage}>
            <Icon icon={IconNames.CHEVRON_LEFT} />
          </Button>
          <Button isDisabled={!this.hasNextPage()} onClick={onNavigateToNextPage}>
            <Icon icon={IconNames.CHEVRON_RIGHT} />
          </Button>
        </div>
      </CardHeaderItem>
    )
  }

  public renderHotkeys() {
    const { onNavigateToNextPage, onNavigateToPreviousPage } = this.props
    return (
      <Hotkeys>
        <Hotkey
          allowInInput={true}
          combo="ctrl+,"
          label="Previous"
          onKeyDown={() => this.hasPrevPage() && onNavigateToPreviousPage()}
          stopPropagation={true}
          preventDefault={true}
          global={true}
        />
        <Hotkey
          allowInInput={true}
          combo="ctrl+."
          label="Next"
          onKeyDown={() => this.hasNextPage() && onNavigateToNextPage()}
          stopPropagation={true}
          preventDefault={true}
          global={true}
        />
      </Hotkeys>
    )
  }

  private hasNextPage = () => {
    const { pageIndex, dataSet } = this.props
    const totalPages = dataSet.count
    return pageIndex < totalPages - 1
  }

  private hasPrevPage = () => {
    const { pageIndex } = this.props
    return pageIndex > 0
  }
}
