import React from 'react'

import { IModalProps, Modal } from 'browser/components/atomic-elements/atoms/modal'

export interface IModalPortalProps extends IModalProps {
  isOpen?: boolean
  onClose?: () => void
}

export class ModalPortal extends React.Component<IModalPortalProps, any> {

  public render() {
    const { isOpen } = this.props
    if (!isOpen) {
      return null
    } else {
      return this.renderModal()
    }
  }

  private renderModal() {
    const { children } = this.props
    return (
      <Modal {...this.props}>
        {children}
      </Modal>
    )
  }
}
