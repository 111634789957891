import _ from 'lodash'
import React from 'react'

import { IInputProps } from 'browser/components/atomic-elements/atoms/input/input'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'

import {
  IInputSheetProps,
  InputSheetFactory,
} from 'browser/components/atomic-elements/higher-order-components/input-sheet-factory'

export interface IInputFieldSheetProps extends IInputProps, IInputSheetProps { }
export const InputFieldSheet = InputSheetFactory<IInputFieldSheetProps>(InputField)
