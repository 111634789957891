import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import {
  filterCarrier,
  filterLoad,
  filterLoadStatus,
} from 'browser/components/atomic-elements/organisms/analytics/data-set'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import { AnalyticsBlock } from './analytics-block'

/**
 * @uiComponent
 */
interface ICarrierAnalyticsSectionProps extends IBaseProps {
  entity: any
}

interface ICarrierAnalyticsSectionState {
  dataSets: any
  filters: any[]
  isLoading: boolean
  tabs: any
}

export class CarrierAnalyticsSection extends
  React.Component<ICarrierAnalyticsSectionProps, ICarrierAnalyticsSectionState> {

  private store: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      dataSets: [],
      filters: this.getFilters(props.entity),
      isLoading: true,
      tabs: [],
    }
  }

  public componentDidMount() {
    const { filters } = this.state
    this.store.findRecord('469a1ce0-3f64-4c85-b5e1-98dc814bbaae').then((result) => {
      const dataSets = this.createDataSets(result.content.view.state.queries, filters)
      _.forEach(dataSets, (dataSet: any) => dataSet.find())
      this.setState({
        dataSets,
        isLoading: true,
        tabs: result.content.view.state.tabs,
      })
    })
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { dataSets } = this.state
    if (this.props.entity !== nextProps.entity) {
      this.setState({ isLoading: true })
      _.forEach(dataSets, (dataSet: any) => {
        dataSet.handleFiltersChange(this.getFilters(nextProps.entity))
      })
    }
  }

  public componentWillUnmount() {
    const { dataSets } = this.state
    _.forEach(dataSets, (dataSet) => dataSet.dispose())
  }

  public render() {
    const { dataSets, filters, isLoading, tabs } = this.state
    return (
      <Section
        title='Carrier Analytics'
      >
        <AnalyticsBlock
          dataSets={dataSets}
          filters={filters}
          isLoading={isLoading}
          tabs={tabs}
        />
      </Section>
    )
  }

  private getFilters(entity) {
    return [
      filterLoad(),
      filterLoadStatus('Settling'),
      filterCarrier(entity.uniqueId),
    ]
  }

  private createDataSets(queries, filters) {
    const results = {}
    _.forEach(queries, (query, queryName) => {
      results[queryName] = new EntityDataSource(query)
        .setFilters(filters)
        .setOnChange(this.handleDataSetChange)
    })
    return results
  }

  private handleDataSetChange = (dataSet) => {
    const { dataSets } = this.state
    const isLoading = _.some(dataSets, { isLoading: true })
    this.setState({ isLoading })
  }

}
