import Promise from 'bluebird'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { SheetManager } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
import { DocumentTypeList } from 'browser/components/domains/firm/document-type-list'
import { Entity } from 'shared-libs/models/entity'
import { AppBundleProps } from 'shared-libs/models/prop-constants'
import { Store } from 'shared-libs/models/store'

interface ICustomDocumentTypesPage extends IBaseProps {
  entity: Entity
}

interface ICustomDocumentTypesState {
  appBundle: Entity
  docSchemasToSave: object
  replaceDocSchemaMappings: object
  isSaving: any
  errors: any
}

export class CustomDocumentTypesPage extends React.Component<ICustomDocumentTypesPage, ICustomDocumentTypesState> {
  private store: Store

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      appBundle: props.entity,
      docSchemasToSave: {},
      errors: null,
      isSaving: false,
      replaceDocSchemaMappings: {},
    }
  }

  public render() {
    const { appBundle, docSchemasToSave, isSaving } = this.state
    return (
      <div className='grid-block vertical'>
        <SheetManager className='grid-block vertical'>
          {appBundle &&
            <DocumentTypeList
              appBundle={appBundle}
              docSchemasToSave={docSchemasToSave}
              onChange={this.handleDocTypeListChange}
            />
          }
          {!appBundle &&
            <HelpBlock>
              Firm does not have an application bundle
            </HelpBlock>
          }
        </SheetManager>
        <Footer
          isPrimaryButtonLoading={isSaving}
          isVisible={this.isDirty()}
          onCancelButtonClick={this.handleRollback}
          onPrimaryButtonClick={this.handleSaveEntity}
        />
      </div>
    )

  }

  private handleRollback = () => {
    const { appBundle, docSchemasToSave } = this.state
    if (appBundle.isDirty || !_.isEmpty(docSchemasToSave)) {
      const clonedAppBundle = appBundle.cloneForReactState()
      clonedAppBundle.rollback()
      this.setState({
        appBundle: clonedAppBundle,
        docSchemasToSave: {},
        replaceDocSchemaMappings: {},
      })
    }
  }

  private handleSaveEntity = async () => {
    const { appBundle, docSchemasToSave, replaceDocSchemaMappings } = this.state

    const errs = {}
    const docSchemas: Entity[] = _.values(docSchemasToSave)
    for (const docSchema of docSchemas) {
      Object.assign(errs, await docSchema.validate())
    }
    Object.assign(errs, await appBundle.validate())

    if (!_.isEmpty(errs)) {
      this.setState({ errors : errs })
    } else {
      this.setState({ isSaving: true })
      const docSchemaSavePromises = _.values(docSchemasToSave).map((docSchema: Entity) => {
        return docSchema.saveWithoutValidation().then(() => this.store.cacheRecord(docSchema))
      })

      return Promise.all(docSchemaSavePromises)
        .then(() => appBundle.saveWithoutValidation())
        .then(() => {
          const firmId = appBundle.get(AppBundleProps.FIRM_ID)
          const docSchemaMigratePromises = _.map(replaceDocSchemaMappings, (toDocSchemaId, fromDocSchemaId) =>
            apis.migrateFirmDocuments(firmId, fromDocSchemaId, toDocSchemaId))
          return Promise.all(docSchemaMigratePromises)
        })
        .then(() => this.setState({ errors: null, docSchemasToSave: {}, replaceDocSchemaMappings: {} }))
        .catch(({ errors }) => this.setState({ errors }))
        .finally(() => this.setState({ isSaving: false }))
    }
  }

  // Helpers
  // -------

  private isDirty() {
    const { appBundle, docSchemasToSave } = this.state
    return (appBundle && appBundle.isDirty) || !_.isEmpty(docSchemasToSave)
  }

  private handleDocTypeListChange = (appBundle, docSchemasToSave = _.cloneDeep(this.state.docSchemasToSave), newReplaceDocSchemaMappings = []) => {
    const replaceDocSchemaMappings = {
      ...newReplaceDocSchemaMappings,
      ...this.state.replaceDocSchemaMappings,
    }
    // Make sure to delete any mappings that no longer have a target schema
    const newDocSchemaIds = Object.keys(docSchemasToSave)
    Object.keys(replaceDocSchemaMappings).forEach((fromDocSchemaId) => {
      const toDocSchemaId = replaceDocSchemaMappings[fromDocSchemaId]
      if (!_.includes(newDocSchemaIds, toDocSchemaId)) {
        delete replaceDocSchemaMappings[fromDocSchemaId]
      }
    })

    this.setState({
      appBundle,
      docSchemasToSave,
      replaceDocSchemaMappings,
    })
  }
}
