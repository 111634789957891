import React from 'react'

export enum MaintenanceStatus {
  COMPLETED = 'COMPLETED',
  INPROGRESS = 'INPROGRESS',
  NOTSTARTEDYET = 'NOTSTARTEDYET'
}

interface IBaseMaintenance {
  id: string
  duration?: number
  start: string // YYYY-MM-DDThh:mm:ss.sssZ
  status: MaintenanceStatus
}

interface IUpdate {
  message: string
  messageHtml: string
  ended?: string // YYYY-MM-DDThh:mm:ss.sssZ
  createdAt: string // YYYY-MM-DDThh:mm:ss.sssZ
  notify: boolean
}

interface IMaintenance extends IBaseMaintenance {
  name: string
  end?: string // YYYY-MM-DDThh:mm:ss.sssZ
  updates: IUpdate[]
}

interface IComputedStatus {
  title: string
  message: string
}

export interface IApplicationStatusResponse {
  maintenances: IMaintenance[]
}

export interface IAppStatusContext {
  raw?: IApplicationStatusResponse
  computed?: IComputedStatus
}

export const DEFAULT_APP_STATUS: IAppStatusContext = {
}

export const AppStatusContext = React.createContext<IAppStatusContext>(DEFAULT_APP_STATUS)
