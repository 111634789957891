import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import numeral from 'numeral'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import 'browser/components/atomic-elements/molecules/dashboard-tile/_dashboard-tile.scss'

interface IDashboardTileProps extends IBaseProps {
  currentValue: number
  previousValue: number
  title: string
  subTitle: string
  format: string
}

export class DashboardTile extends React.Component<IDashboardTileProps, any> {

  public render() {
    const { className, children, subTitle, title } = this.props
    let content = children
    if (_.isEmpty(children)) {
      content = this.renderValueTile()
    }
    return (
      <div className={classNames('c-dashboardTile pa3', className)}>
        <h5 className='c-dashboardTile-title'>
          {title}
        </h5>
        <HelpBlock>
          {subTitle}
        </HelpBlock>
        <div className='u-positionRelative'>
          {content}
        </div>
      </div>
    )
  }

  private renderValueTile() {
    const { currentValue, previousValue, format } = this.props
    if (_.isNumber(currentValue) && _.isNumber(previousValue)) {
      const formattedCurrentValue = numeral(currentValue).format(format)
      return (
        <div>
          <h1 className='c-dashboardTile-value f1'>
            {formattedCurrentValue}
          </h1>
          {this.renderChange()}
        </div>
      )
    }
  }

  private renderChange() {
    const { currentValue, previousValue } = this.props
    const valueChange = previousValue === 0 ? 0 :
      (currentValue - previousValue) / previousValue
    const formattedValueChange = numeral(valueChange).format('0.00%')
    if (valueChange >= 0) {
      return (
        <div className='c-dashboardTile-change c-dashboardTile-change--positive'>
          <Icon icon={IconNames.ARROW_UP} /> {formattedValueChange}
        </div>
      )
    } else {
      return (
        <div className='c-dashboardTile-change c-dashboardTile-change--negative'>
          <Icon icon={IconNames.ARROW_DOWN} /> {formattedValueChange}
        </div>
      )
    }
  }
}
