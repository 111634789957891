import _ from 'lodash'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'

import { Settings } from 'browser/app/models/settings'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { FileInput } from 'browser/components/json-elements/atoms/file-input/file-input'

interface ISetUpOrganizationTabProps extends IBaseProps {
  navigation: any
  productName: string
  onClose: any
  settings: Settings
  size?: string
}

interface ISetUpOrganizationTabStates {
  firm: Entity
  errorText: string
  isLoading: boolean
  user: Entity
}

const JOIN_REQUEST_ENTITY_TYPE = '/1.0/entities/metadata/joinRequest.json'

export class SetUpOrganizationTab extends React.Component<ISetUpOrganizationTabProps, ISetUpOrganizationTabStates> {

  constructor(props) {
    super(props)
    this.state = {
      errorText: null,
      firm: null,
      isLoading: false,
      user: null,
    }
  }

  public UNSAFE_componentWillMount() {
    const { settings } = this.props
    const firm = settings.getFirm()
    const user = settings.getUser()
    this.setState({ firm, user })
  }

  public render() {
    const { navigation, size } = this.props
    const { errorText, firm, isLoading } = this.state
    const navigateToSelectProducts = () => navigation.navigate('selectProducts')

    return (
      <div>
        <div className='ph4'>
          <h3 className='f3 lh-title mt2 mb4 tc'>
            Set Up Your Organization
          </h3>

          <div className='cf'>
            <div className='fl w-50 pr2'>
              <InputField
                isHorizontalLayout={false}
                errorText={errorText}
                className='c-formGroup--inputHasBorder'
                label='Organization Name'
                labelProps={{
                  className: 'c-label--heading',
                }}
                onChange={this.handleNameChange}
                value={firm.get('business.legalName')}
                size='lg'
              />
            </div>
            <div className='fl w-50 pl2 mb4'>
              <Label className='c-label--heading'>
                Organization Logo
              </Label>
              <FileInput
                showFileName={false}
                size='xl'
                className='ba b--light-gray'
                emptyCueButtonText='Upload Logo'
                emptyCueHelpText='Drop in an image (PNG or JPG), 300px by 300px recommended'
                entity={firm}
                onChange={this.handleLogoChange}
                value={firm.get('business.logo')}
                showOptionsButton={false}
                valuePath='business.logo'
              />
            </div>
          </div>
        </div>

        <Footer
          className='c-footer--transparent c-footer--horizontalPadding'
          cancelButtonText='Back'
          isPrimaryButtonLoading={isLoading}
          // onPrimaryButtonClick={navigateToSelectProducts}
          onPrimaryButtonClick={this.handleSetupOrganization}
          onCancelButtonClick={navigation.goBack}
          primaryButtonText='Next'
        />
      </div>
    )
  }

  private handleNameChange = (value) => {
    const { firm } = this.state
    firm.set('business.legalName', value)
    this.setState({ firm })
  }

  private handleLogoChange = (value) => {
    const { firm } = this.state
    firm.set('business.logo', value)
    this.setState({ firm })
  }

  private handleSetupOrganization = () => {
    const { onClose } = this.props
    const { firm, user } = this.state
    this.setState({ errorText: null, isLoading: true })
    const waitUntil = [firm.waitUntilIdle(), user.waitUntilIdle()]
    return Promise.all(waitUntil).then(() => {
      firm.save().then(() => {
        // set hasSeenJoinOrganizationModal to true so we don't
        user.set('user.preferences.hasSeenJoinOrganizationModal', true)
        return user.save()
      }).then(onClose).catch((error) => {
        this.setState({ errorText: 'Organization name cannot be empty.' })
      }).finally(() => this.setState({ isLoading: false }))
    })
  }
}
