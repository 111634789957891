import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment-timezone'
import React from 'react'

import { DatePickerPopover } from 'browser/components/atomic-elements/atoms/date-picker/date-picker-popover'
import 'browser/components/atomic-elements/atoms/input/date-input/_date-input.scss'
import { IInputProps } from 'browser/components/atomic-elements/atoms/input/input'
import { TextMaskInput } from 'browser/components/atomic-elements/atoms/input/text-mask-input'
import { createAutoCorrectedDatePipe } from 'browser/components/atomic-elements/atoms/input/text-mask-pipes'
import { TimeInput } from 'browser/components/atomic-elements/atoms/input/time-input'
import { TimezoneSelect } from 'browser/components/atomic-elements/atoms/select/timezone-select/timezone-select'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
// tslint:disable-next-line:max-line-length
import { InputGroupField } from 'browser/components/atomic-elements/molecules/fields/input-group-field/input-group-field'

const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

/**
 * Provides a contract for implementing an override of the time input component.
 */
export interface ITimeComponentProps {
  value: string
  onChange: (value: string) => void
  isDisabled?: boolean
}

/**
 * Provides a contract for implementing an override of the timezone input
 * component.
 */
export interface ITimezoneComponentProps {
  onChange: (timezoneId: string) => void
  isDisabled?: boolean
  date: Date
  value: string
}

// value can be a string, moment obj or { datetime, timezone }
/**
 * @uiComponent
 */
export interface IDateInputProps extends IInputProps {
  daySize?: number
  defaultHours?: number
  defaultMinutes?: number
  defaultTimezone?: string
  onChange: (value: any) => void
  openOnFocus?: boolean
  showDatePicker?: boolean
  showDateInput?: boolean
  showTimeInput?: boolean
  TimeInputComponent?: React.ComponentType<ITimeComponentProps>
  showTimezoneInput?: boolean
  TimezoneInputComponent?: React.ComponentType<ITimezoneComponentProps>
  isTimezoneDisabled?: boolean
  tetherOptions?: object
  value: any
}

interface IDateFields {
  dateString: string
  hours: number
  minutes: number
  timezone: string
}

export class DateInput extends React.Component<IDateInputProps> {
  public static defaultProps: Partial<IDateInputProps> = {
    daySize: 30,
    defaultHours: 0,
    defaultMinutes: 0,
    defaultTimezone: moment.tz.guess(),
    isTimezoneDisabled: false,
    openOnFocus: true,
    placeholder: 'mm/dd/yyyy',
    showDateInput: true,
    showDatePicker: true,
    showTimeInput: false,
    tetherOptions: {
      attachment: 'top left',
      targetAttachment: 'bottom left',
    },
  }

  private autoCorrectDatePipe: (value: string) => any
  private input: TextMaskInput
  private tether: TetherTarget

  constructor(props: IDateInputProps) {
    super(props)
    this.autoCorrectDatePipe = createAutoCorrectedDatePipe()
  }

  public focus() {
    this.input.focus()
  }

  /////////////////////////////////////////////////////////////////////////////
  // Rendering
  /////////////////////////////////////////////////////////////////////////////

  public render() {
    const { className, showTimeInput, showDateInput } = this.props
    const dateFields = this.getDateFieldsFromProps()
    if (showTimeInput) {
      let dateInput
      if (showDateInput) {
        dateInput = this.renderDateInput(dateFields)
      }
      return (
        <InputGroupField className={classNames('c-dateInput-container', className)}>
          {dateInput}
          {this.renderTimeInput(dateFields)}
          {this.renderTimezoneSelect(dateFields)}
        </InputGroupField>
      )
    }
    return this.renderDateInput(dateFields)
  }

  private getDateFieldsFromProps(): IDateFields {
    const { defaultTimezone, value, defaultHours, defaultMinutes } = this.props
    const defaultState = {
      dateString: undefined,
      hours: defaultHours,
      minutes: defaultMinutes,
      timezone: defaultTimezone,
    }
    if (_.isEmpty(value)) {
      return defaultState
    }
    const dateTime = this.getDateTime(this.props)
    const timezone = this.getTimezone(this.props)
    const momentDateTime = moment.tz(dateTime, moment.ISO_8601, true, timezone)
    const dateString = momentDateTime.format('MM/DD/YYYY')
    const hours = momentDateTime.hours()
    const minutes = momentDateTime.minutes()
    return { dateString, hours, minutes, timezone }
  }

  private getDateTime(props) {
    const { value } = props
    return _.get(value, 'dateTime', value)
  }

  private getTimezone(props) {
    const { defaultTimezone, value } = props
    return _.get(value, 'timezone', defaultTimezone)
  }

  private handleChange = (dateString, hours, minutes, timezone) => {
    const { onChange, showTimezoneInput } = this.props
    const date = moment.tz(dateString, 'MM/DD/YYYY', true, timezone)
    if (!date.isValid()) { return }
    date.hours(hours)
    date.minutes(minutes)
    const dateTime = date.toISOString()
    if (showTimezoneInput) {
      onChange({ dateTime, timezone })
    } else {
      onChange(dateTime)
    }
  }

  private handleDateChange = (dateString) => {
    if (!dateString) {
      return this.props.onChange(undefined)
    }
    const { hours, minutes, timezone } = this.getDateFieldsFromProps()
    this.handleChange(dateString, hours, minutes, timezone)
  }

  private handleDatePickerChange = (value) => {
    const { hours, minutes, timezone } = this.getDateFieldsFromProps()
    const dateString = moment.tz(value, moment.ISO_8601, true, timezone).format('MM/DD/YYYY')
    this.handleChange(dateString, hours, minutes, timezone)
    this.tether.close()
  }

  private handleTimeChange = (value) => {
    if (!value) { return }
    const { dateString, timezone } = this.getDateFieldsFromProps()
    // time-input doesn't care about timezone, so treat as UTC to leave hours
    // uninterpreted.
    const momentTime = moment.utc(value)
    const hours = momentTime.hours()
    const minutes = momentTime.minutes()
    this.handleChange(dateString, hours, minutes, timezone)
  }

  private handleTimezoneChange = (timezone) => {
    const { dateString, hours, minutes } = this.getDateFieldsFromProps()
    this.handleChange(dateString, hours, minutes, timezone)
  }

  private renderDatePickerPopover() {
    const { defaultHours, defaultMinutes, defaultTimezone, daySize } = this.props
    const time = this.getDateTime(this.props)
    return (
      <DatePickerPopover
        daySize={daySize}
        defaultHours={defaultHours}
        defaultMinutes={defaultMinutes}
        defaultTimezone={defaultTimezone}
        onChange={this.handleDatePickerChange}
        value={time}
      />
    )
  }

  private renderDateInput(dateFields: IDateFields) {
    const { isDisabled, openOnFocus, showDatePicker, tetherOptions, inputClassName } = this.props
    const { dateString } = dateFields
    const canOpenPopover = showDatePicker && !isDisabled
    // NOTE: we cannot openOnClick or openOnFocus to be true at the same time.
    // onFocus is fired when clicking in the input also and would immediately toggle
    // and close the popover
    return (
      <TetherTarget
        automaticAdjustOffset={true}
        isEnabled={canOpenPopover}
        openOnClick={!openOnFocus}
        openOnFocus={openOnFocus}
        tetherOptions={tetherOptions}
        tethered={this.renderDatePickerPopover()}
        ref={(ref) => { this.tether = ref }}
      >
        <TextMaskInput
          {...this.props as IInputProps}
          guide={false}
          inputClassName={classNames('c-dateInput', inputClassName)}
          keepCharPositions={true}
          mask={dateMask}
          onChange={this.handleDateChange}
          pipe={this.autoCorrectDatePipe}
          ref={(ref) => { this.input = ref }}
          value={dateString}
        />
      </TetherTarget>
    )
  }

  private renderTimeInput(dateFields: IDateFields) {
    const { isDisabled, value, TimeInputComponent } = this.props
    const { hours, minutes } = dateFields

    // provide value as UTC since time-input doesn't care about timezone, to
    // leave the hours value uninterpreted.
    const time = value ? moment.utc().hours(hours).minutes(minutes).toISOString() : undefined

    const TimeComponentType = TimeInputComponent || this.defaultRenderTimeInput

    return (
      <TimeComponentType
        isDisabled={isDisabled}
        value={time}
        onChange={this.handleTimeChange}
      />
    )
  }

  private defaultRenderTimeInput = (props: ITimeComponentProps) => {
    const { value, isDisabled, onChange } = props
    const { inputClassName, size } = this.props

    return (
      <TimeInput
        className={classNames("flex justify-end", inputClassName)}
        isDisabled={isDisabled}
        size={size}
        onChange={onChange}
        value={value}
        timezoneId={'UTC'}
      />
    )
  }

  private renderTimezoneSelect(dateFields) {
    const { showTimezoneInput, TimezoneInputComponent, isDisabled, isTimezoneDisabled } = this.props
    const { dateString, timezone } = dateFields
    if (!showTimezoneInput) {
      return
    }
    const date = dateString ? new Date(dateString) : new Date()
    // do not let user change timezone before date is specified
    const timezoneDisabled = !dateString || isDisabled || isTimezoneDisabled

    const TimezoneComponentType = TimezoneInputComponent || this.defaultRenderTimezoneSelect

    return (
      <TimezoneComponentType
        date={date}
        isDisabled={timezoneDisabled}
        onChange={this.handleTimezoneChange}
        value={timezone}
      />
    )
  }

  private defaultRenderTimezoneSelect = (props: ITimezoneComponentProps) => {
    const { value, date, onChange, isDisabled } = props
    const { inputClassName } = this.props
    return (
      <TimezoneSelect
        className={inputClassName}
        date={date}
        isDisabled={isDisabled}
        onChange={onChange}
        value={value}
      />
    )
  }
}
