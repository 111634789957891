import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { IconNames } from '@blueprintjs/icons'
import { Icon } from '@blueprintjs/core'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

import 'browser/components/atomic-elements/organisms/side-navigation-bar-folder/_side-navigation-bar-folder.scss'

const DEFAULT_IS_OPEN = true

/**
 * @uiComponent
 */
interface ISideNavigationBarFolderProps extends IBaseProps {
  label: string
  persistenceKey: string
}


export class SideNavigationBarFolder extends React.Component<ISideNavigationBarFolderProps> {
  badgesByKey: any

  constructor(props) {
    super(props)

    this.badgesByKey = new Set()
  }

  private recursiveCloneChildren = (props, isClosed) => {
    if (props.children) {
      return props.children.map(c => {
        const nestedChildren = this.recursiveCloneChildren(c.props, isClosed)
        return React.cloneElement(c, {
          children: nestedChildren,
          isClosed,
          recordBadgeAtKey: this.recordBadgeAtKey,
        })
      })
    }

    return []
  }

  private recordBadgeAtKey = (key: string, remove=false) => {
    remove ? this.badgesByKey.delete(key) : this.badgesByKey.add(key)
    this.setState({})
  }

  public render() {
    const { children, className, label, persistenceKey } = this.props
    const isOpenVal = localStorage.getItem(persistenceKey)
    const isOpen = _.isNil(isOpenVal) ? DEFAULT_IS_OPEN : isOpenVal === 'true'

    const onToggle = () => {
      localStorage.setItem(persistenceKey, String(!isOpen))
      this.setState({})
    }

    const updatedChildren = this.recursiveCloneChildren(this.props, !isOpen)

    return (
      <div
        className={classNames('u-flex u-flexDirectionColumn c-sideNavigationBarFolder', className)}
      >
        <button
          onClick={onToggle}
          className="c-sideNavigationBarFolder__label c-sideNavigationBarItem c-sideNavigationBarItem-label"
        >
          <span>
            {label}

            {this.badgesByKey.size !== 0 &&
              <div className={classNames('c-sideNavigationBarItem-dot')}>
              </div>
            }
          </span>

          <span>
            <Icon
              icon={IconNames.CHEVRON_DOWN}
              className={classNames({
                'c-sideNavigationBarFolder__icon': true,
                'c-sideNavigationBarFolder__icon--open': isOpen,
              })}
            />
          </span>

        </button>
        <div className="c-sideNavigationBarFolder__children">{updatedChildren}</div>
      </div>
    )
  }
}
