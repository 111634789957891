import _ from 'lodash'
import React from 'react'

import { EntityTableWithGroupSimplification } from 'browser/components/atomic-elements/organisms/entity/entity-table-with-group-simplification'
// tslint:disable-next-line:max-line-length
import { InvitesTableActionBar } from 'browser/components/atomic-elements/organisms/table-action-bar/invites-table-action-bar'

/**
 * Entity table to render rows of invite entities
 *
 * @props IEntityTableProps
 */
export class InviteTable extends EntityTableWithGroupSimplification {
  render() {
    const { selection } = this.props
    return (
      <EntityTableWithGroupSimplification {...this.props}>
        <InvitesTableActionBar
          selection={selection}
        />
      </EntityTableWithGroupSimplification>
    )
  }
}
