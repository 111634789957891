import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/spacer/_spacer.scss'

/**
 * @uiComponent
 */
interface SpacerProps extends IBaseProps {

}

export class Spacer extends React.PureComponent<SpacerProps, any> {

  public render() {
    return (
      <div className={classNames('c-spacer', this.props.className)} />
    )
  }
}
