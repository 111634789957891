import _ from 'lodash'

// Reference: https://github.com/text-mask/text-mask/blob/master/addons/src/createAutoCorrectedDatePipe.js
export function createAutoCorrectedDatePipe(dateFormat = 'mm dd yyyy', minValues?: any, maxValues?: any) {
  // const today = moment()
  // const year = today.year()
  if (!minValues) {
    minValues = {dd: 1, mm: 1, yy: 0, yyyy: 1900}
  }

  if (!maxValues) {
    maxValues = {dd: 31, mm: 12, yy: 99, yyyy: 2099}
  }

  const conformDayAndMonth = (conformedValueArr, format, position) => {
    const maxFirstDigit = parseInt(maxValues[format].toString().substr(0, 1), 10)
    if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
      conformedValueArr[position + 1] = conformedValueArr[position]
      conformedValueArr[position] = 0
    }
  }

  const conformYear = (conformedValueArr, format, position) => {
    const minValue = minValues[format]
    const maxValue = maxValues[format]
    for (let i = 0; i < format.length && position + i < conformedValueArr.length; ++i) {
      let curYear = conformedValueArr.slice(position, position + format.length).join('')
      while (i < format.length && !isInYearRange(curYear, minValue, maxValue)) {
        const maxDigit = parseInt(maxValue.toString().substr(i, 1), 10)
        const curDigit = parseInt(conformedValueArr[position + i], 10)
        conformedValueArr.splice(position + i, 0, String(maxDigit))
        curYear = conformedValueArr.slice(position, position + format.length).join('')
        i = i + 1
      }
    }
  }

  const isInYearRange = (chrs, minyear, maxyear) => {
    const enteredyear = parseInt(chrs.concat(minyear.toString().slice(chrs.length)), 0)
    const enteredyear2 = parseInt(chrs.concat(maxyear.toString().slice(chrs.length)), 0)
    return (minyear <= enteredyear && enteredyear <= maxyear) ||
          (minyear <= enteredyear2 && enteredyear2 <= maxyear)
  }

  return (conformedValue, state?) => {
    const previousConformedValue = _.get(state, 'previousConformedValue', conformedValue)
    const indexesOfPipedChars = []
    const dateFormatArray = dateFormat.split(/[^dmy]+/)
    const conformedValueArr = conformedValue.split('')

    // if user is deleting characters from input don't do anything
    if (previousConformedValue.length <= conformedValue.length) {
      // Check first digit
      _.forEach(dateFormatArray, (format, index) => {
        const position = dateFormat.indexOf(format)
        indexesOfPipedChars.push(position)
        if (index < 2) {
          conformDayAndMonth(conformedValueArr, format, position)
        } else {
          conformYear(conformedValueArr, format, position)
        }
      })
    }

    // Check for invalid date
    const isInvalid = dateFormatArray.some((format) => {
      const position = dateFormat.indexOf(format)
      const length = format.length
      const textValue = conformedValue.substr(position, length).replace(/\D/g, '')
      const value = parseInt(textValue, 10)

      return value > maxValues[format] || (textValue.length === length && value < minValues[format])
    })

    if (isInvalid) {
      return false
    }

    return {
      indexesOfPipedChars,
      value: conformedValueArr.join(''),
    }
  }
}
