import { Logger } from 'browser/apis/logging'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { browserHistory } from 'browser/history'
import { getEnvironmentAlias } from 'shared-libs/helpers/utils'
import apis from '../models/apis'

declare let __SEMATEXT_TOKEN__: string
declare let __SENTRY_DSN__: string
declare let __GITHASH__: string
declare let __API_HOST__: string

export function initializeAnalytics() {
  // only log to SemaText and raven in production
  if (process.env.NODE_ENV === 'production') {
    // Setup aplitude key
    Logger.initialize(__SEMATEXT_TOKEN__)
    // Setup sentry
    Sentry.init({
      dsn: __SENTRY_DSN__,
      release: __GITHASH__,
      environment: getEnvironmentAlias(__API_HOST__),
      integrations: [
        new BrowserTracing({
          tracePropagationTargets: [__API_HOST__, /^\//],
          routingInstrumentation: Sentry.reactRouterV4Instrumentation(browserHistory),
        }),
      ],
    })
  }
  apis.setLogger(Logger.logEvent.bind(Logger))
}
