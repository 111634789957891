import _ from 'lodash'
import { Tab, Tabs } from '@blueprintjs/core'
import React, { useContext } from 'react'
import SplitPane from 'react-split-pane'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { ImageEditorCarousel } from 'browser/components/atomic-elements/organisms/image-editor-carousel/image-editor-carousel'
import { ImagingFileTree } from 'browser/components/atomic-elements/organisms/entity/imaging-detail-panel/imaging-file-tree/imaging-file-tree'
import { default as ImagingPropertiesPanel } from 'browser/components/atomic-elements/organisms/entity/imaging-detail-panel/imaging-properties-panel/imaging-properties-panel'
import ComponentsMap from 'browser/components'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'
import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { Entity } from 'shared-libs/models/entity'

/**
 * @uiComponent
 */
interface IImagingDetailPanelProps extends IBaseProps {
  entity: any
  frames: FramesManager
  handleNavigateToNextPage: any
  handlePageIndexChanged: any
  onChange: any
  pageIndex: number
  previousName?: string
  previousDocumentType?: any
  setLoadInput: any
  relatedSalesOrders: Entity[]
}

// Used as both the template editor and the classifier editor
export const ImagingDetailPanel: React.FC<IImagingDetailPanelProps> = (props) => {
  const {
    frames,
    entity,
    relatedSalesOrders,
    handlePageIndexChanged,
    onChange,
    pageIndex,
    previousName,
    previousDocumentType,
    setLoadInput,
  } = props
  const { settings } = useContext(AppNavigatorContext)

  const renderProperties = () => {
    const classificationTasks = _.get(entity, 'documentClassificationTask.classificationTasks', [])
    const classificationTask = classificationTasks[pageIndex]
    return (
      <ImagingPropertiesPanel
        entity={entity}
        relatedSalesOrders={relatedSalesOrders}
        classificationTask={classificationTask}
        handleNavigateToNextPage={handlePageIndexChanged}
        numberOfPages={classificationTasks.length}
        onChange={onChange}
        pageIndex={pageIndex}
        previousName={previousName}
        previousDocumentType={previousDocumentType}
        settings={settings}
        setLoadInput={setLoadInput}
      />
    )
  }

  const renderImage = () => {
    return (
      <ImageEditorCarousel
        entity={entity}
        imagesPath='documentClassificationTask.classificationTasks'
        index={pageIndex}
        isImagingCarousel={true}
        onCarouselIndexChange={handlePageIndexChanged}
        onClose={_.noop}
        showOriginal={true}
        schema={{ type: 'array' }}
      />
    )
  }

  const renderEmailSource = () => {
    return (
      <div style={{marginLeft: '26px'}}>
      <EntityRenderer
        actions={this}
        state={{}}
        componentsMap={ComponentsMap}
        uiSchemaPath={'uiSchema.web.entityDetailSection'}
        value={entity}
      />
      </div>
    )
  }

  const renderAttachmentAndEmailSource = () => {
    const showAttachmentOnly = _.isEmpty(_.get(entity, 'inboundEmail'))
    if (showAttachmentOnly) {
      return renderImage();
    }

    // show attachment and inbound email source
    return (
      <Tabs
        className='grid-block vertical c-tabs'
        id='inboxTabs'
        renderActiveTabPanelOnly={true}
      >
        <Tab
          className='grid-block vertical'
          id='attachment'
          title='Attachment'
          panel={renderImage()}
        />
        <Tab
          className='grid-block vertical'
          id='email'
          title='Email'
          panel={renderEmailSource()}
        />
        <Tabs.Expander />
      </Tabs>
    )
  }

  const renderFileTree = () => {
    return (
      <ImagingFileTree
        entity={entity}
        onClick={handlePageIndexChanged}
        pageIndex={pageIndex}
      />
    )
  }

  return (
    <div className='grid-block'>
      <div className='grid-block u-positionRelative' style={{ flex: 5 }}>
        <SplitPane split='vertical' defaultSize='35vw' primary='first'>
          {renderAttachmentAndEmailSource()}
          {renderProperties()}
        </SplitPane>
      </div>
      <div className='grid-content' style={{ backgroundColor: '#f2f2f2', borderRadius: '5px', maxWidth: '20%' }}>
        {renderFileTree()}
      </div>
    </div>
  )
}
