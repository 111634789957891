import { Menu, MenuDivider, MenuItem, Popover, Position } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import React from 'react'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { IRenditionBillingViewHeaderButtonsProps } from './rendition-billing-view-header-buttons'

/**
 * a dropdown menu component rendered into the View Header of Invoicing Views
 *
 * @props IRenditionBillingViewHeaderButtonsProps
 */
export const RbViewHeaderMoreOptionsDropdown: React.FC<Partial<IRenditionBillingViewHeaderButtonsProps>> = ({
  handleExport,
  handleShowEntityFormPanel,
  onViewModeChange
}) => {
  const MenuContent = (
    <Menu>
      <MenuItem text={'Create Invoice'} icon={IconNames.BLANK} onClick={handleShowEntityFormPanel} />
      <MenuDivider />
      <div style={{ color: 'gray', paddingLeft: '10px', paddingTop: '5px' }}>View Options</div>
      <MenuItem text={'Full Entity View'} icon={IconNames.BLANK} onClick={() => onViewModeChange('list')} />
      <MenuItem text={'Detail Pane View'} icon={IconNames.BLANK} onClick={() => onViewModeChange('listDetail')} />
      <MenuDivider />
      <MenuItem text={'Export View'} icon={IconNames.BLANK} onClick={handleExport} />
    </Menu>
  )
  return (
    <Popover content={MenuContent} minimal={true} position={Position.BOTTOM_RIGHT}>
      <Button className='u-nowrap' >
        <div style={{ transform: 'rotate(90deg)' }}>{'|||'}</div>
      </Button>
    </Popover>
  )
}
