import { Classes, Icon } from '@blueprintjs/core'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IconNames } from '@blueprintjs/icons'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import 'browser/components/atomic-elements/atoms/table/cells/_table-email-status-cell.scss'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { RbStatusSelect } from './rb-status-select'

/**
 * @prop emailStatus - status of whether the invoice has been billed by email
 * @prop invoiceNumber - identification number of the invoice
 * @prop invoiceOptions - selectable values representing different invoice stages
 * @prop invoiceStatus - the stage of the invoice in the system
 * @prop onInvoiceStatusUpdate - callback to be executed when an invoice's stage is changed
 */
interface IRbSecondaryCardHeaderProps {
  emailStatus: string
  invoiceNumber: string
  invoiceOptions: {
    label: string
    value: string
  }[]
  invoiceStatus: string
  onInvoiceStatusUpdate: any
}

/**
 * An Entity Detail Card Secondary Header to display elements unique to Invoices.
 * These elements include:
 * - Invoice Status Select
 * - Email Status display
 *
 * @props IRbSecondaryCardHeaderProps
 */
export const RbSecondaryCardHeader: React.FC<IRbSecondaryCardHeaderProps> = (props) => {
  const { emailStatus, invoiceNumber, invoiceStatus, invoiceOptions, onInvoiceStatusUpdate } = props
  const renderTitle = () => {
    return (
      <CardHeaderItem
        className='c-cardHeader-item--largeMargin'
        description='Invoice Number'
        title={(
          <span>{invoiceNumber}</span>
        )}
      />
    )
  }

  const renderInvoiceStatus = () => {
    return (
      <CardHeaderItem
        className='c-cardHeader-item--largeMargin'
        description='Invoice Status'
      >
        <TetherTarget
          closeOnPortalClick={true}
          tetherOptions={{
            attachment: 'top left',
            targetAttachment: 'bottom left',
          }}
          tethered={renderInvoiceStatusDropdownMenu()}
        >
          <div className={classNames('mb1 c-cardHeader-label c-cardHeaderStatus', Classes.TAG)}>
            {invoiceStatus} <Icon icon={IconNames.CHEVRON_DOWN} />
          </div>
        </TetherTarget>
      </CardHeaderItem>
    )
  }

  const renderEmailStatus = () => {
    if (emailStatus) {
      const tagClassName = emailStatus ? `c-tag--${emailStatus.toLowerCase()}` : ''
      return (
        <CardHeaderItem
          className='c-cardHeader-item--largeMargin'
          description='Email Status'
        >
          <div className={classNames('mb1 c-cardHeader-label', Classes.TAG, tagClassName)}>
            {emailStatus}
          </div>
        </CardHeaderItem>
      )
    }
  }

  const renderInvoiceStatusDropdownMenu = () => {
    return (
      <Popover
        className='collapse'
        data-debug-id='invoice-options-dropdown'
      >
        <RbStatusSelect
          options={invoiceOptions}
          optionLabelPath='label'
          optionValuePath='value'
          onChange={onInvoiceStatusUpdate}
          value={invoiceStatus}
        />
      </Popover>
    )
  }

  return (
    <div className='u-flex u-noPrint'>
      {renderTitle()}
      {renderInvoiceStatus()}
      {renderEmailStatus()}
      <CardHeaderItem className='c-cardHeader-item--grow' />
    </div>
  )
}
