import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { ILabelProps, Label } from 'browser/components/atomic-elements/atoms/label/label'
import { IFocusableInput, InlineEditableInputProps } from 'browser/components/atomic-elements/molecules/inline-editable'
import { EditableLabel } from './editable-label'
import { translateString } from 'shared-libs/helpers/utils'
import { FramesManager } from 'shared-libs/components/view/frames-manager'

export interface ILabelFormGroupProps extends IBaseProps, InlineEditableInputProps {
  frames?: FramesManager
  density?: string
  errors?: any
  errorText?: string
  helpText?: string
  isDisabled?: boolean
  isEditableInline?: boolean
  isHorizontalLayout?: boolean
  isLabelCreatable?: boolean
  isLabelEditable?: boolean
  isOptional?: boolean
  isRequired?: boolean
  label: string | JSX.Element
  labelProps?: ILabelProps
  link?: string
  onChange?: (value: any, option?: any) => void
  onRemove?: () => void
  onSave?: () => void
  addInflationSessionId?: (string) => void
  removeInflationSessionId?: (string) => void
  size?: string
  validate?: (value: any) => boolean
  value?: any
}

interface ILabelFormGroupState {
  isFocused: boolean
}

export class LabelFormGroup extends React.Component<ILabelFormGroupProps, ILabelFormGroupState>
  implements IFocusableInput {

  public static defaultProps: Partial<ILabelFormGroupProps> = {
    isDisabled: false,
    isHorizontalLayout: true,
    isLabelCreatable: false,
    isLabelEditable: false,
    isOptional: false,
    onChange: _.noop,
  }

  private input: any

  constructor(props) {
    super(props)
    // need to bind explicitly here because we need to spread arguments
    this.handleValueChange = this.handleValueChange.bind(this)
    this.state = {
      isFocused: false,
    }
  }

  public focus() {
    this.input.focus()
  }

  public render() {
    const {
      className,
      density,
      errorText,
      errors,
      isDisabled,
      isEditableInline,
      isHorizontalLayout,
      isLabelEditable,
      value,
      size,
    } = this.props
    const { isFocused } = this.state
    const errorTextSafe = errorText || (errors && errors[0])

    const formGroupProps = {
      className,
      density,
      hasError: !_.isEmpty(errorTextSafe),
      hasValue: !_.isNil(value),
      isDisabled,
      isEditableInline,
      isFocused,
      isHorizontalLayout,
      isLabelEditable,
      size,
    }
    return (
      <FormGroup {...formGroupProps} >
        {this.renderLabelBlock()}
        {this.renderEditableBlock()}
        {this.renderHelpBlock()}
      </FormGroup>
    )
  }

  private getValue(): any {
    const { isLabelEditable, labelProps, value } = this.props
    if (!_.isNil(value)) {
      return value
    } else if (isLabelEditable) {
      // https://app.asana.com/0/167876960125712/346093892201233
      const defaultLabel = _.get(labelProps, 'default')
      return { label: defaultLabel }
    }
    return null
  }

  private renderLabelBlock() {
    const {
      frames,
      isDisabled,
      isLabelEditable,
      label,
      labelProps,
      errorText,
      errors,
      isHorizontalLayout,
      isOptional,
      isRequired,
      isStatic,
      size,
    } = this.props
    const value = this.getValue()
    const errorTextSafe = errorText || (errors && errors[0])
    const translationTable = frames?.getContext('translationTable')
    const translatedLabel: string | JSX.Element = !_.isString(label)
      ? label
      : translateString(label, translationTable)

    if (isLabelEditable) {
      return (
        <EditableLabel
          {...labelProps}
          isDisabled={isDisabled}
          isStatic={isStatic}
          isHorizontalLayout={isHorizontalLayout}
          onChange={this.handleLabelChanged}
          size={size}
          value={value.label}
        />
      )
    } else {
      return (
        <Label
          {...labelProps}
          isHorizontalLayout={isHorizontalLayout}
          hasError={!_.isEmpty(errorTextSafe)}
          isOptional={isOptional}
          isRequired={isRequired}
          size={size}
        >
          {translatedLabel}
        </Label>
      )
    }
  }

  private renderHelpBlock() {
    const { helpText } = this.props
    if (helpText) {
      return (
        <HelpBlock
          className='c-tempHelpBlock'
          key='helpBlock'
        >
          {helpText}
        </HelpBlock>
      )
    }
  }

  private renderEditableBlock() {
    const {
      children,
      inlineEditControls,
      inlineEditSheet,
      isEditableInline,
      isLabelEditable,
      isDisabled,
      addInflationSessionId,
      removeInflationSessionId,
    } = this.props
    const value = this.getValue()
    const inputValue = isLabelEditable ? value.value : value
    // TODO(peter/louis): inputValue is weird here for the embedded address field
    const inputElement = React.cloneElement(React.Children.only(children), {
      addInflationSessionId,
      isDisabled,
      onBlur: this.handleOnBlur,
      onChange: this.handleValueChange,
      onFocus: this.handleOnFocus,
      ref: (ref) => { this.input = ref },
      removeInflationSessionId,
      value: inputValue,
    })
    if (isEditableInline) {
      return (
        <div className='c-labelFormGroup-container'>
          {inputElement}
          {inlineEditControls}
          {inlineEditSheet}
        </div>
      )
    }
    return inputElement
  }

  private handleLabelChanged = (label) => {
    const { onChange } = this.props
    const value = this.getValue()
    value.label = label
    onChange(value)
  }

  private handleValueChange(newValue, ...args) {
    const { isLabelEditable, onChange } = this.props
    const value = this.getValue()
    if (isLabelEditable) {
      value.value = newValue
      onChange(value)
    } else {
      onChange(newValue, ...args)
    }
  }

  private handleOnFocus = () => {
    this.setState({isFocused: true})
  }

  private handleOnBlur = () => {
    this.setState({ isFocused: false })
  }
}
