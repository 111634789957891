import _ from 'lodash'

import { Entity } from '../entity'
import { EntityProxy } from '../proxy'

const entityProxyHandler = {
  get: function (prop) {
    return this[prop]
  },
  set: function (prop, value) {
    throw new Error(`Cannot update ${prop} directly. Use entity.set(...)`)
  }
}

interface IEntityTypeParams {
  api: any
  data: any
  entity: Entity
  entitySchema: Entity
}

export abstract class EntityType extends EntityProxy {

  protected api: any
  protected entity: Entity
  protected entitySchema: Entity

  constructor({ api, data, entity, entitySchema }: IEntityTypeParams) {
    super(data, entityProxyHandler)
    this.api = api
    this.entity = entity
    this.entitySchema = entitySchema
    this.registerOwnProperties()
  }

  public reInitializeWithValues({data, entitySchema}) {
    this.entitySchema = entitySchema
    this.setAllContents(data)
    this.registerOwnProperties()
  }

  protected registerOwnProperties() {
    const namespace = _.get(this.entitySchema, 'metadata.namespace')
    const propertiesPath = `properties.${namespace}.properties`
    const properties = _.get(this.entitySchema, propertiesPath, {})
    _.forEach(properties, (property, key) => this.registerProperty(key))
  }
}
