import _ from 'lodash'
import React from 'react'
import { findDOMNode } from 'react-dom'

import { getMinZoomLevel } from 'browser/app/utils/image'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { PolygonMask } from 'browser/components/atomic-elements/organisms/image-editor/polygon-mask'
import { ComponentsContext, IComponentsContext } from 'browser/contexts/components/components-context'
import { withContext } from 'shared-libs/components/context/with-context'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'

const d3 = require('d3')

interface IImageEditorProps extends IBaseProps {
  backgroundColor?: string
  enableOverlay?: boolean
  handleFocusChange?: any
  height: number
  focusIndex?: any
  image: any
  isInEditMode: boolean
  maxZoomLevel?: number
  mappings?: any[]
  onChange?: (value: any) => void
  onClose?: () => void
  onZoom?: (zoomLevel: number) => void
  onShapeCreated?: any
  padding: number
  pageIndex?: number
  showOriginal?: boolean
  showOverlay?: boolean
  width: number
  zoomLevel: number
}

interface ContextProps {
  componentsContext?: IComponentsContext
}

interface IImageEditorState {
  isLoading: boolean
  newShape?: any
  src?: string
  transformations?: number
  zoomLevel: number
}

@withContext(ComponentsContext, 'componentsContext')
export class ImageEditor extends React.Component<IImageEditorProps & ContextProps, IImageEditorState> {

  public static defaultProps: Partial<IImageEditorProps> = {
    backgroundColor: 'rgba(0,0,0,0)',
    enableOverlay: false,
    maxZoomLevel: 3,
    onClose: _.noop,
    onZoom: _.noop,
    padding: 24,
    zoomLevel: 0,
  }

  private d3zoom: any
  private imageElement: any
  private imageWrapperElement: any
  private newShape: any
  private tether: any

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      zoomLevel: 1,
    }
  }

  public componentDidMount() {
    const { enableOverlay } = this.props
    this.d3zoom = d3.zoom()
    d3.select(findDOMNode(this)).call(this.d3zoom)

    if (enableOverlay) {
      this.installSVGDragHandler()
    }
    this.getNextStateForProps(this.props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.image !== nextProps.image ||
        this.props.isInEditMode !== nextProps.isInEditMode ||
        this.props.height !== nextProps.height ||
        this.props.width !== nextProps.width ||
        this.props.zoomLevel !== nextProps.zoomLevel ||
        this.isTransformationChanged(this.state.transformations, nextProps.image.transformations)) {
      this.getNextStateForProps(nextProps)
    }
  }

  public render() {
    const {
      backgroundColor,
      children,
      className,
      height,
      image,
      isInEditMode,
      onClose,
      mappings,
      width,
      componentsContext,
    } = this.props
    const rotation = (isInEditMode || componentsContext.platform === PlatformType.MOBILE_WEB) ? image.getRotation() : 0
    const rotateTransform = `rotate(${rotation}, ${width / 2}, ${height / 2})`
    // TODO(louis):  bg-light-gray for document imaging
    return (
      <div className='relative'>
        <svg
          className={className}
          height={height}
          width={width}
        >
          <rect
            width={width}
            height={height}
            onClick={onClose}
            fill={backgroundColor}
          />
          <g
            className='js-rotationWrapper'
            transform={rotateTransform}
            width={width}
            height={height}
          >
            <g
              className='js-imageWrapper'
              ref={this.handleImageWrapperRef}
            >
              {this.renderImage()}
              {this.renderCropMask()}
              {children}
              {_.map(mappings, (mapping, index) => this.renderPropertyMappingBoxes(mapping, index))}
              {this.renderNewShape()}
            </g>
          </g>
        </svg>
        {this.renderLoading()}
      </div>
    )
  }

  private renderImage() {
    const { isInEditMode, image, pageIndex, showOriginal } = this.props
    const previewImage = isInEditMode || showOriginal ? image : _.first(image.source)
    // if not inEidtMode and showOriginal, we show the original. In edit mode always
    // show the image src in the state
    let src = (!isInEditMode && showOriginal) ? image.uri : this.state.src
    if (previewImage.pages > 1 && pageIndex) {
      src = previewImage.preview[pageIndex].uri
    }
    return (
      <image
        className='js-image'
        onLoadStart={this.handleLoadStart}
        onLoad={this.handleLoad}
        width={previewImage.width}
        height={previewImage.height}
        xlinkHref={src}
        ref={this.handleImageRef}
      />
    )
  }

  private renderLoading() {
    const { isLoading } = this.state
    if (isLoading) {
      return <LoadingSpinner/>
    }
  }

  private renderCropMask() {
    const { image, onChange, showOverlay } = this.props
    const { isLoading, zoomLevel } = this.state
    if (isLoading || !showOverlay) { return }
    const croppingRect = image.getPerspectiveTransform(true)
    const handleRadius = 7 / zoomLevel
    return (
      <PolygonMask
        corners={croppingRect}
        handleRadius={handleRadius}
        maxHeight={image.height - 1}
        maxWidth={image.width - 1}
        // tslint:disable-next-line:jsx-no-lambda
        onChange={() => onChange(image)}
      />
    )
  }

  private renderPropertyMappingBoxes = (mapping, index) => {
    const { focusIndex } = this.props
    const tetherOptions = {
      attachment: 'top center',
      targetAttachment: 'bottom center',
    }
    let fill = 'rgba(248, 153, 57, 0.5)'
    if (focusIndex === index) {
      fill = '#ECF3FF'
    }
    const { boundingBox } = mapping

    return (
      <TetherTarget
        automaticAdjustOffset={true}
        isEnabled={true}
        tetherOptions={tetherOptions}
        tethered={this.renderPropertyMappingPopover(boundingBox)}
        ref={(ref) => { this.tether = ref }}
      >
        <rect
          onMouseOver={(event) => this.handleMouseOverRect(event, index)}
          onMouseOut={this.handleMouseOutRect}
          fill={fill}
          x={boundingBox.left}
          y={boundingBox.top}
          width={boundingBox.width}
          height={boundingBox.height}
        />
      </TetherTarget>
    )
  }

  private handleMouseOverRect = (event, index) => {
    const { handleFocusChange } = this.props
    handleFocusChange(index)
  }

  private handleMouseOutRect = (event) => {
    const { handleFocusChange } = this.props
    handleFocusChange(null)
  }

  private renderNewShape() {
    const { newShape } = this.state
    if (!newShape) { return }
    const tetherOptions = {
      attachment: 'top center',
      targetAttachment: 'bottom center',
    }
    return (
      <TetherTarget
        automaticAdjustOffset={true}
        isEnabled={true}
        tetherOptions={tetherOptions}
        tethered={this.renderCreateNewShapePopover()}
        ref={(ref) => { this.tether = ref }}
      >
        <rect
          fill={'rgba(248, 153, 57, 0.5)'}
          x={newShape.x}
          y={newShape.y}
          width={newShape.width}
          height={newShape.height}
        />
      </TetherTarget>
    )
  }

  private renderCreateNewShapePopover() {
    return (
      <Popover className='collapse'>
        Hello World
      </Popover>
    )
  }

  private renderPropertyMappingPopover(property) {
    return (
      <Popover className='collapse'>
        Label should go here
      </Popover>
    )
  }

  private handleImageRef = (ref) => {
    this.imageElement = ref
  }

  private handleImageWrapperRef = (ref) => {
    this.imageWrapperElement = ref
  }

  private handleLoadStart = () => {
    this.setState({ isLoading: true })
  }

  private handleLoad = () => {
    this.setState({ isLoading: false })
  }

  private handleZoom = () => {
    const domNode = findDOMNode(this.imageWrapperElement)
    d3.select(domNode).attr('transform', d3.event.transform)
    this.props.onZoom(d3.event.transform.k)
  }

  private installSVGDragHandler() {
    const imageEditor = this
    function handleDragStart() {
      // console.log(`start x=${d3.event.x} y=${d3.event.y}`)
      imageEditor.setState({ newShape: {
        height: 0,
        type: 'rectangle',
        width: 0,
        x: d3.event.x,
        y: d3.event.y,
      }})
    }
    function handleDrag() {
      const newShape: any = imageEditor.state.newShape
      newShape.height = d3.event.y - newShape.y
      newShape.width = d3.event.x - newShape.x
      imageEditor.setState({ newShape })
    }
    function handleDragEnd() {
      // console.log(`end x=${d3.event.x} y=${d3.event.y}`)
      const newShape = imageEditor.state.newShape
      const onShapeCreated = imageEditor.props.onShapeCreated
      onShapeCreated(newShape)
      imageEditor.setState({ newShape: null })
    }
    const domNode = findDOMNode(this.imageElement)
    d3.select(domNode).call(d3.drag()
      .on('start', handleDragStart)
      .on('drag', handleDrag)
      .on('end', handleDragEnd))
  }

  private getNextStateForProps(props) {
    const { isInEditMode, image, maxZoomLevel, padding, showOriginal, width, height } = props
    const isZoomable = !isInEditMode
    const previewImage = isInEditMode || showOriginal ? image : _.first(image.source)
    const rotation = isInEditMode ? image.getRotation() : 0
    const src = isInEditMode ? null : previewImage.uri
    const k = this.getMinZoomLevel(width, height, previewImage, padding, rotation)
    const zoomLevel = isInEditMode ? k : props.zoomLevel
    // we want to temporarily set zoom callback before calling d3zoom.transform
    this.d3zoom.scaleExtent([k, maxZoomLevel])
      .translateExtent([[0, 0], [previewImage.width, previewImage.height]])
      .on('zoom', this.handleZoom)
    d3.select(findDOMNode(this)).call(this.d3zoom.scaleTo, zoomLevel)
    // set zoom callback appropriately depending on state
    this.d3zoom.on('zoom', isZoomable ? this.handleZoom : null)
    // setting next state
    this.setState({
      isLoading: !src,
      src,
      transformations: _.cloneDeep(image.transformations),
      zoomLevel: k,
    })
    if (isInEditMode) {
      previewImage.previewUri.then((uri) => {
        this.setState({ isLoading: false, src: uri })
      }).catch((err) => {
        console.error(`failed to get preview URI: ${err.stack}`)
        this.setState({ isLoading: false, src: image.uri })
      })
    }
  }

  private getMinZoomLevel(containerWidth, containerHeight, image, padding, rotation) {
    if (rotation === 90 || rotation === 270) {
      return getMinZoomLevel(image, padding, containerHeight, containerWidth)
    } else {
      return getMinZoomLevel(image, padding, containerWidth, containerHeight)
    }
  }

  private isTransformationChanged(oldTransformation, newTransformation) {
    const oldRotateTransform = _.find(oldTransformation, { type: 'rotation' })
    const newRotateTransform = _.find(newTransformation, { type: 'rotation' })
    const oldThresholdTransform = _.find(oldTransformation, { type: 'threshold' })
    const newThresholdTransform = _.find(newTransformation, { type: 'threshold' })
    return !_.isEqual(oldRotateTransform, newRotateTransform) ||
        !_.isEqual(oldThresholdTransform, newThresholdTransform)
  }
}
