/**
 * Storyboard scene enrichment of the json story equivalent
 */

import _ from 'lodash'
import { IStoryboardEnrichProps, StoryboardElement } from './storyboard-element'
import { StoryboardNavigationMap } from './storyboard-navigation-map'
import { StoryboardNavigationRoute } from './storyboard-navigation-route'
import { ChangeSetSettings, Identifier, Story } from './storyboard-plan-model'
import { StoryboardScene } from './storyboard-scene'
import { SharedContext } from './storyboard-shared-context'

export class StoryboardStory extends StoryboardElement {
  private navigationTable: StoryboardNavigationMap<StoryboardScene> = null

  constructor(data: Story, schema: any) {
    super(data, schema)
    this.navigationTable = new StoryboardNavigationMap(data.sceneRoutes, {})
  }

  public enrich(props: IStoryboardEnrichProps): StoryboardStory {
    const { getElement } = props

    this.navigationTable.enrich({ getElement })

    return this
  }

  public get initialRoute(): StoryboardNavigationRoute {
    return this.navigationTable.getInitialRoute()
  }

  public get platforms(): string[] {
    return _.get(this.data, 'platforms')
  }

  public getSceneWithId(id: Identifier): StoryboardScene {
    return this.navigationTable.getItem(id)
  }

  public getScenes(): StoryboardScene[] {
    return this.navigationTable.getItems()
  }

  public resumeEnabled(): boolean {
    return this.get('settings.resumeEnabled', true)
  }

  public changeSetSettings(): ChangeSetSettings[] {
    return this.get('settings.changeSet', [])
  }

  /**
   * Get the next scene based on the current state.
   */
  public getNextSceneRoute(
    scene: StoryboardScene | null,
    sharedContext: SharedContext
  ): StoryboardNavigationRoute {
    return this.navigationTable.getNextRoute(scene, sharedContext)
  }
}
