import classNames from 'classnames'
import React from 'react'

import {
  RelativeDateRangePicker,
  IRelativeDateRangePickerProps,
} from 'browser/components/atomic-elements/atoms/date-picker/relative-date-range-picker'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'

interface IRelativeDateRangePickerPopoverProps extends IRelativeDateRangePickerProps {
  onClose?: () => void
  preContent?: any
}

export class RelativeDateRangePickerPopover extends React.PureComponent<IRelativeDateRangePickerPopoverProps, any> {
  public render() {
    const { onClose } = this.props

    return (
      <Popover className={classNames('collapse', this.props.className)}>
        { this.props.preContent }
        <RelativeDateRangePicker
          {...this.props}
          onChange={this.handleChange}
          onClose={onClose}
        />
      </Popover>
    )
  }

  private handleChange = (filter, isShortcutClicked) => {
    const { onChange, onClose } = this.props

    onChange(filter)

    if (isShortcutClicked) {
      onClose()
    }
  }
}
