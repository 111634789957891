import _ from 'lodash'
import React, { Component } from 'react'
import styled from 'styled-components'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import apis from 'browser/app/models/apis'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { RadioGroup } from 'browser/components/atomic-elements/atoms/radio/radio-group'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { default as EntitySelect } from 'browser/components/atomic-elements/atoms/select/entity-select'
import { HtmlTable } from 'browser/components/atomic-elements/atoms/table/html-table'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { MergeUsersProgressToast } from 'browser/components/domain/user/merge-users-progress-toast'
import { EntityProps, MergeUserProps, UserProps } from 'shared-libs/models/prop-constants'
import { SchemaIds, SchemaUris } from 'shared-libs/models/schema'
import { createEdge } from 'shared-libs/models/utils'

import {
  ContentWrapper,
  DeleteTableCell,
  EmailOptionProp, KEEP_DISCARD_OPTIONS,
  NameOptionProp,
  Option,
  OptionProp,
  OptionPropLabel,
  RoleOptionProp,
  TABLE_COLUMNS,
  TableCell,
} from 'browser/components/domain/user/dialog-option-styles'
import { components } from 'react-select'

interface IMergeUsersDialogProps {
  isOpen: boolean
  onClose: () => void
  initialTargetUser: object
}

interface IMergeUsersDialogState {
  errorText: string
  usersToMerge: object[]
  targetUserId: string
  selectedUser: object
}

export class MergeUsersDialog extends Component<IMergeUsersDialogProps, IMergeUsersDialogState> {
  public static open(props) {
    return OverlayManager.openOverlay(this, { ...props })
  }

  constructor(props) {
    super(props)

    this.state = {
      errorText: '',
      selectedUser: null,
      targetUserId: null,
      usersToMerge: [],
    }
  }

  public componentDidMount() {
    const { initialTargetUser } = this.props
    this.setState({
      targetUserId: _.get(initialTargetUser, EntityProps.ID),
      usersToMerge: [ initialTargetUser ],
    })
  }

  // Rendering
  // ---------

  public render() {
    const { onClose } = this.props
    const { errorText, selectedUser, targetUserId, usersToMerge } = this.state
    const selectNotFilter = {
      path: 'uniqueId',
      type: 'notMatch',
      values: [
        targetUserId,
      ],
    }
    return (
      <Modal modalStyle={{ width: 'auto' }}>
        <div>
          <div className='c-modalHeader'><h4 className='c-modal-title u-textCenter'>Merge Users</h4></div>
          <ContentWrapper>
            <Section title='Users to Merge'>
              <HtmlTable
                columns={TABLE_COLUMNS}
                value={usersToMerge}
                renderListItem={this.renderTableRow}
                showItemDeleteButton={true}
              />
            </Section>
            <EntitySelect
              autoFocus={true}
              entityType={SchemaUris.USER}
              errorText={errorText}
              filter={selectNotFilter}
              isCreatable={false}
              onChange={this.handleAddUser}
              optionRenderer={this.renderSelectOption}
              placeholder='Choose Additional Users'
              returnValueAsEdge={false}
              style={{ border: '1px solid #E0E0E0' }}
              value={selectedUser}
            />
          </ContentWrapper>
          <Footer
            isPrimaryButtonDisabled={usersToMerge.length <= 1}
            onCancelButtonClick={onClose}
            onPrimaryButtonClick={this.handleMergeUsers}
            primaryButtonText='Merge'
          />
        </div>
      </Modal>
    )
  }

  private renderSelectOption = (props) => {
    const { children, ...remainingProps } = props
    const user = props.data
    return (
      <components.Option {...remainingProps}>
        <Option>
          <NameOptionProp>
            {_.get(user, UserProps.NAME)}
          </NameOptionProp>
          <OptionProp>
            <OptionPropLabel>
              Firm:
            </OptionPropLabel>
            {_.get(user, UserProps.FIRM_NAME)}
          </OptionProp>
          <RoleOptionProp>
            <OptionPropLabel>
              Role:
            </OptionPropLabel>
            {_.get(user, UserProps.ROLE)}
          </RoleOptionProp>
          <EmailOptionProp>
            <OptionPropLabel>
              Email:
            </OptionPropLabel>
            {_.get(user, UserProps.FIRST_EMAIL_VALUE)}
          </EmailOptionProp>
          <OptionProp>
            <OptionPropLabel>
              Phone:
            </OptionPropLabel>
            {_.get(user, UserProps.FIRST_PHONE_VALUE)}
          </OptionProp>
        </Option>
      </components.Option>
    )
  }

  private renderTableRow = (row) => {
    return (
      <tr className='c-table-row'>
        {TABLE_COLUMNS.map((col) => this.renderCell(col, row.item))}
      </tr>
    )
  }

  private renderCell = (col, item) => {
    const Cell = col.entityKey === 'delete' ? DeleteTableCell : TableCell
    return (
      <Cell
        className='c-table-cell'
        key={col.entityKey}
      >
        {this.renderCellValue(col, item)}
      </Cell>
    )
  }

  private renderCellValue = (col, item) => {
    const { usersToMerge, targetUserId } = this.state
    const userId = _.get(item, EntityProps.ID)
    const isTarget = (userId === targetUserId)

    const keepDiscardChange = (value) => {
      this.handleKeepDiscardChange(value, userId)
    }

    const removeUserId = () => {
      this.handleRemoveUser(userId)
    }

    if (col.entityKey === 'actions') {
      return (
        <RadioGroup
          options={KEEP_DISCARD_OPTIONS}
          value={isTarget ? 'keep' : 'discard'}
          isOptionsHorizontal={true}
          isHorizontalLayout={true}
          className='merge-table'
          isDisabled={usersToMerge.length <= 1}
          onChange={keepDiscardChange}
        />
      )
    } else if (col.entityKey === 'delete') {
      return (
        <div style={{ marginLeft: '-7px' }}>
          { !isTarget &&
            <Button
              className={classNames('c-table-cellDeleteButton--merge', Classes.MINIMAL)}
              onClick={removeUserId}
            >
              <Icon
                icon={IconNames.CROSS}
                color='#db3737'
              />
            </Button>
          }
        </div>
      )
    }
    return _.get(item, col.entityKey)
  }

  // Handlers
  // --------

  private handleKeepDiscardChange = (value, userId) => {
    if (value === 'keep') {
      this.setState({ targetUserId: userId })
    } else {
      const { usersToMerge } = this.state
      const currentKeepIndex = _.findIndex(usersToMerge, (u) => _.get(u, EntityProps.ID) === userId )
      const toKeepIndex = (currentKeepIndex + 1) % usersToMerge.length
      this.setState({ targetUserId: _.get(usersToMerge[toKeepIndex], EntityProps.ID) })
    }
  }

  private handleMergeUsers = () => {
    const { targetUserId, usersToMerge } = this.state
    const store = apis.getStore()
    store.getOrFetchRecord(SchemaIds.MERGE_USER).then((mergeUserSchema) => {
      const mergeUserRequest = store.createRecord(mergeUserSchema)
      const destinationUserId = targetUserId
      const sourceUsers = usersToMerge.filter((u) => (_.get(u, EntityProps.ID) !== destinationUserId))
      const sourceEdges = sourceUsers.map((u) => createEdge(_.get(u, EntityProps.ID)))
      mergeUserRequest.set(MergeUserProps.DESTINATION, createEdge(destinationUserId))
      mergeUserRequest.set(MergeUserProps.SOURCES, sourceEdges)

      new MergeUsersProgressToast().showToastAndExecuteRequest(mergeUserRequest, store.getRecord(destinationUserId))
      this.props.onClose()
    })
  }

  private handleRemoveUser = (userId) => {
    const usersToMerge = [ ...this.state.usersToMerge ]
    _.remove(usersToMerge, (u) => (_.get(u, EntityProps.ID) === userId))
    this.setState({ usersToMerge })
  }

  private handleAddUser = (user) => {
    const userId = _.get(user, EntityProps.ID)
    const userAlreadyExists = _.some(this.state.usersToMerge, (u) => (_.get(u, EntityProps.ID) === userId))
    if (!userAlreadyExists) {
      const usersToMerge = [ ...this.state.usersToMerge, user ]
      this.setState({ usersToMerge })
    }
  }
}
