import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { SectionHeader } from 'browser/components/atomic-elements/atoms/section/section-header/section-header'

const FileRowContainer = styled.div`
  background-color: #dbdbdb;
  border-radius: 5px;
  box-shadow: 5px;
  display: flex;
  height: 40px;
  margin-bottom: 5px;
  align-items: center;
  background-color: ${(props) => props.pageIndex === props.index ? 'rgba(248, 153, 57, 0.5)' : undefined}
`

const FileRowName = styled.div`
  margin-left: 10px;
`

const FileRowImage = styled.img`
  height: 40px;
  width: 45px;
  padding: 2px;
`

const ClassifiedRowContainer = styled.div`
  background-color: #dbdbdb;
  border-radius: 5px;
  box-shadow: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom 5px;
`

const ClassifiedTitleContainer = styled.div`
  padding-top: 5px;
  padding-left: 5px;
`

const ClassifiedFileContainer = styled.div`
  padding-left: 15px;
  background-color: ${(props) => props.pageIndex === props.index ? 'rgba(248, 153, 57, 0.5)' : undefined}
`

interface IImagingFileTreeProps extends IBaseProps {
  entity: any
  onClick: (index: string) => void
  pageIndex: number
}

export const ImagingFileTree: React.FC<IImagingFileTreeProps> = (props) => {
  const { entity, onClick, pageIndex } = props
  const { classificationTasks } = entity.documentClassificationTask

  // Should Create a dictionary of doctyped elements
  const createClassifiedMap = (tasks) => {
    const map = new Map()
    _.forEach(tasks, (task, index) => {
      const loadNumber = _.get(task, 'documentProperties.document.name', null)
      const documentType = _.get(task, 'documentType', null)
      if (loadNumber && documentType) {
        const key = `${documentType.displayName}, ${loadNumber}`
        const value = map.get(key)
        if (!value) {
          map.set(key, [index])
        } else {
          value.push(index)
        }
      }
    })
    return map
  }

  const classifiedMap = createClassifiedMap(classificationTasks)
  return (
    <div className='grid-block vertical'>
      <div className='collapse'>
        <div className='pt2 u-bumperLeft u-bumperRight'>
          <div className='grid-block vertical u-overflowHidden'>
            <SectionHeader title='Documents' />
            {_.map([...classifiedMap], (entrySet, index) => {
              return (
                <ClassifiedRowContainer key={index}>
                  <ClassifiedTitleContainer><b>{entrySet[0]}</b></ClassifiedTitleContainer>
                  {_.map(entrySet[1], (pageNumber) => {
                    const handleOnClick = () => {
                      onClick(pageNumber)
                    }
                    return (
                      <ClassifiedFileContainer
                        key={pageNumber}
                        onClick={handleOnClick}
                        pageIndex={pageIndex}
                      >
                        Page: {pageNumber + 1}
                      </ClassifiedFileContainer>
                    )
                  })}
                </ClassifiedRowContainer>
              )
            })}
            <SectionHeader title='Unclassified' />
            {_.map(classificationTasks, (task, index) => {
              const { uri } = task.attachment
              const loadNumber = _.get(task, 'documentProperties.document.name', null)
              const documentType = _.get(task, 'documentType', null)
              if (loadNumber && documentType) {
                return
              }
              const handleOnClick = () => {
                onClick(index)
              }
              return (
                <FileRowContainer
                  key={index}
                  onClick={handleOnClick}
                  pageIndex={pageIndex}
                >
                  <FileRowImage src={uri} />
                  <FileRowName>Page: {index + 1}</FileRowName>
                </FileRowContainer>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
