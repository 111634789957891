declare let window: any

import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { SheetContext } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { DocumentItem } from 'browser/components/atomic-elements/organisms/entity/documents-list/document-item'
import {
  GeneratedOrderDocumentsList,
} from 'browser/components/atomic-elements/organisms/entity/documents-list/generated-order-documents-list'
import {
  EntityAssociationsSheet,
} from 'browser/components/atomic-elements/organisms/entity/entity-associations/entity-associations-sheet'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import { ImageEditorModal } from 'browser/components/atomic-elements/organisms/image-editor-modal'
import {
  ShareConfigurationModal,
} from 'browser/components/atomic-elements/organisms/share-configuration-modal/share-configuration-modal'
import {
  getBusinessContacts,
  getBusinessEmails,
} from 'browser/components/atomic-elements/organisms/share-configuration-modal/utils'
import { Entity } from 'shared-libs/models/entity'
import { isPrintable } from './utils'

/**
 * @uiComponent
 */
interface IDocumentsListProps extends IBaseProps {
  dataSet: EntityDataSource
  entity: any
  openOverlay: any
  showGeneratedDocumentsList?: boolean
  settings: Settings
}

interface IDocumentsListState {
  isDownloading: boolean
  selectedDocuments: Entity[]
  selectedGeneratedDocuments: any[]
}

const BUSINESS_ENTITY_TYPE = '11111111-0000-0000-0000-000000000003'
const DISPATCH_ORDER_ENTITY_TYPE = '11111111-0000-0000-0000-000000000107'
const SALES_ORDER_ENTITY_TYPE = 'edc3908c-3f25-4a97-9c75-779cdcf5614a'
const DOCUMENT_TYPE = '/1.0/entities/metadata/document.json'

class DocumentsList extends React.PureComponent<IDocumentsListProps, IDocumentsListState> {

  public static defaultProps: Partial<IDocumentsListProps> = {
    showGeneratedDocumentsList: true,
  }

  private relatedDocumentsDataSet: any

  constructor(props) {
    super(props)
    this.state = {
      isDownloading: false,
      selectedDocuments: [],
      selectedGeneratedDocuments: [],
    }
    this.relatedDocumentsDataSet = {}
  }

  public UNSAFE_componentWillMount() {
    const { dataSet, entity } = this.props
    const identifier = _.get(entity, 'displayName', null)
    dataSet.find()

    // TODO: we should not append any default query in here and just let it
    // derived from a schema, but don't want to untangle the mess
    if (_.find(entity.activeMixins, { entityId: 'edc3908c-3f25-4a97-9c75-779cdcf5614a'})) {
      // don't append any fuzzy match query for rb2
      return
    }
    this.relatedDocumentsDataSet = new EntityDataSource({
      entityType: DOCUMENT_TYPE,
      filters: [
        {
          entityType: DOCUMENT_TYPE,
          path: 'document.name',
          type: 'match',
          values: [ identifier ],
        },
      ],
    }).setOnChange(() => this.forceUpdate())
    this.relatedDocumentsDataSet.find()
  }

  public render() {
    const { className, dataSet } = this.props
    if (dataSet.isLoading || this.relatedDocumentsDataSet.isLoading) {
      return (
        <div className='relative u-innerBumper--xl'>
          <LoadingSpinner size='xs'/>
        </div>
      )
    }
    return (
      <div className={classNames('c-documentsList', className)}>
        <Section
          title='Documents'
          headerControls={this.renderTableActionBar()}
        >
          {this.renderDocumentList()}
          {this.renderGeneratedDocumentsList()}
        </Section>
      </div>
    )
  }

  private renderTableActionBar() {
    const { isDownloading, selectedDocuments, selectedGeneratedDocuments } = this.state
    const hasNoSelection = _.isEmpty(selectedDocuments) && _.isEmpty(selectedGeneratedDocuments)
    return (
      <div className='flex mv1'>
        <Button
          className={classNames(
            'ml2',
            Classes.SMALL,
            Classes.iconClass(IconNames.SHARE),
            Classes.MINIMAL
          )}
          isDisabled={hasNoSelection}
          onClick={this.handleOpenShareConfigurationModal}
        >
          Share
        </Button>
        <Button
          className={classNames(
            'ml2',
            Classes.SMALL,
            Classes.iconClass(IconNames.DOWNLOAD),
            Classes.MINIMAL
          )}
          isDisabled={hasNoSelection}
          isLoading={isDownloading}
          onClick={this.handleDownloadDocuments}
        >
          Download
        </Button>
        <Button
          className={classNames(
            'ml2',
            Classes.SMALL,
            Classes.iconClass(IconNames.UPLOAD),
            Classes.MINIMAL
          )}
          onClick={this.handleShowEntityCreationPanel}
        >
          Upload
        </Button>
      </div>
    )
  }

  private renderDocumentList() {
    const { dataSet } = this.props
    const { selectedDocuments } = this.state
    if (_.isEmpty(dataSet.entities) && _.isEmpty(this.relatedDocumentsDataSet.entities)) {
      return this.renderEmptyState()
    }
    let entities = []
    if (dataSet.entities) {
      entities = _.concat(entities, dataSet.entities)
    }
    if (this.relatedDocumentsDataSet.entities) {
      entities = _.concat(entities, this.relatedDocumentsDataSet.entities)
    }
    const uniqueEntities: any = _.uniqBy(entities, (data: any) => data.uniqueId)
    const listItems = _.map(uniqueEntities, (document, index) => (
      <DocumentItem
        entity={document}
        isSelected={_.indexOf(selectedDocuments, document) >= 0}
        key={document.get('uniqueId')}
        onOpenPreview={this.handleOpenPreview}
        onSelect={this.handleSelectDocument}
      />
    ))
    return (
      <table className='c-table c-table--flushHorizontal c-table--auto c-table--noBorders'>
        <tbody className='c-table-body'>
          {listItems}
        </tbody>
      </table>
    )
  }

  private renderGeneratedDocumentsList() {
    const { entity, showGeneratedDocumentsList } = this.props
    const { selectedGeneratedDocuments } = this.state

    if (showGeneratedDocumentsList) {
      return (
        <GeneratedOrderDocumentsList
          entity={entity}
          onSelect={this.handleSelectGeneratedDocument}
          selection={selectedGeneratedDocuments}
        />
      )
    }
  }

  private renderEmptyState() {
    return (
      <div className='c-boxListItem c-boxListItem--noHover'>
        <div className='c-boxListItem-content u-textCenter u-bumperTop u-bumperBottom'>
          <HelpBlock className='mb2'>
            No documents have been attached yet.
          </HelpBlock>
          <Button
            className={classNames(
              Classes.SMALL,
              Classes.iconClass(IconNames.UPLOAD),
              Classes.MINIMAL
            )}
            onClick={this.handleShowEntityCreationPanel}
          >
            Attach Documents
          </Button>
        </div>
      </div>
    )
  }

  private handleOpenPreview = (entity: Entity, setLoading: (loading: boolean) => void) => {
    // don't open preview if we are not in a good state
    if (!entity.isIdle) { return }
    if (isPrintable(entity)) {
      setLoading(true)
      apis.getPrintablePreview(_.get(entity, 'uniqueId')).then((result: any) => {
        ImageEditorModal.open({
          isEditable: false,
          entity: result,
          imagesPath: 'document.attachments',
        })
        setLoading(false)
      })
    } else {
      ImageEditorModal.open({
        entity,
        imagesPath: 'document.attachments',
      })
    }
  }

  private handleShowEntityCreationPanel = () => {
    const { dataSet, entity, openOverlay } = this.props
    const associatedEntitySchema = dataSet.entitySchema
    const entityPath = 'document.entity'
    // TODO(Peter): I am assuming entity is dispatchOrder right now
    const isBusiness = _.find(entity.activeMixins, (mixin: any) => {
      return mixin.entityId === BUSINESS_ENTITY_TYPE
    })
    const isDispatchorder = _.find(entity.activeMixins, (mixin: any) => {
      return mixin.entityId === DISPATCH_ORDER_ENTITY_TYPE
    })
    const isSalesOrder = _.find(entity.activeMixins, (mixin: any) => {
      return mixin.entityId === SALES_ORDER_ENTITY_TYPE
    })

    // for sales order, there is hard edge from document to sales order
    const edgePath = isSalesOrder ? 'document.salesOrder' : 'document.entity'

    let documentName = ''
    if (isBusiness) {
      documentName = entity.business.legalName
    } else if (isDispatchorder) {
      documentName = entity.dispatchOrder.identifier
    } else if (isSalesOrder) {
      documentName = entity.core_fulfilment_salesOrder.identifier
    }

    const associatedEntityDefaultValue = {
      document: {
        name: documentName,
      },
    }
    // TODO(Peter): we want to disable the document.name field, but don't
    // know how :( How about this?
    // const uiSchemaTheme = {
    //   '[value="document.name"]': {
    //     isDisabled: true
    //   }
    // }
    openOverlay(
      <AppNavigatorContext.Consumer>
        {({settings}) => (
          <EntityAssociationsSheet
            associatedEntityDefaultValue={associatedEntityDefaultValue}
            associatedEntitySchema={associatedEntitySchema}
            entity={entity}
            edgePath={edgePath}
            isEdgeOnEntity={false}
            showFooter={false}
            size='sm'
            state={{settings}}
            uiSchemaPath='uiSchema.web.entityCreationFlow'
          />
        )}
      </AppNavigatorContext.Consumer>,

    )
  }

  private handleSelectDocument = (entity: Entity) => {
    const { selectedDocuments } = this.state
    if (_.indexOf(selectedDocuments, entity) >= 0) {
      _.pull(selectedDocuments, entity)
    } else {
      selectedDocuments.push(entity)
    }
    this.setState({ selectedDocuments: selectedDocuments.slice() })
  }

  private handleSelectGeneratedDocument = (selection: any[]) => {
    this.setState({ selectedGeneratedDocuments: selection })
  }

  private handleOpenShareConfigurationModal = () => {
    const { entity } = this.props
    const promises = [
      getBusinessEmails(entity.get('brokerOrder.carrier'), 'Carrier'),
      getBusinessContacts(entity.get('brokerOrder.carrier'), 'Carrier'),
      getBusinessEmails(entity.get('brokerOrder.customer'), 'Customer'),
      getBusinessContacts(entity.get('brokerOrder.customer'), 'Customer'),
    ]
    Promise.all(promises).then((results: any[]) => {
      const contacts = _.reduce(results, (a1, a2) => a1.concat(a2), [])
      ShareConfigurationModal.open({
        onSend: this.handleSendDocument,
        showRecipientsList: false,
        showShareAccessSettings: false,
        suggestedRecipients: contacts,
      })
    })
  }

  private getSelectedAttachments() {
    const { entity, settings } = this.props
    const { selectedDocuments, selectedGeneratedDocuments } = this.state
    const loadNumber = entity.displayName
    const documentAttachments = _.map(selectedDocuments, (document) => ({
      entityId: document.get('uniqueId'),
      fileName: `${loadNumber} - ${document.entityType}.pdf`,
      type: 'document',
    }))
    const brokerOrderId = entity.get('uniqueId')
    const printAttachments = _.map(selectedGeneratedDocuments, (item) => ({
      entityId: brokerOrderId,
      fileName: `${loadNumber} - ${item.label}.pdf`,
      timezone: settings.getTimezone(),
      type: 'print',
      url: `/view/${item.viewId}`,
    }))
    return documentAttachments.concat(printAttachments)
  }

  private handleSendDocument = (recipients, message) => {
    const { entity, settings } = this.props
    const firm = settings.getFirm()
    const loadNumber = entity.displayName
    const attachments = this.getSelectedAttachments()
    const subject = `Documents from ${firm.displayName} for Load ${loadNumber}`
    return apis.sendNotification({
      attachments,
      message,
      recipients,
      subject,
    }).then(() => {
      Toast.show({
        message: 'Sending documents.',
        position: Position.BOTTOM_RIGHT,
      })
    })
  }

  private handleDownloadDocuments = () => {
    const attachments = this.getSelectedAttachments()
    this.setState({ isDownloading: true })
    apis.batchDownloadDocuments({ attachments }).then((url) => {
      window.location = url
    }).then(() => {
      this.setState({ isDownloading: false })
    })
  }
}

export default React.forwardRef((props: IDocumentsListProps, ref: React.Ref<DocumentsList>) => (
  <AppNavigatorContext.Consumer>
    {({ settings }) => (
      <SheetContext.Consumer>
        {({ openOverlay }) => (
          <DocumentsList {...props} openOverlay={openOverlay} settings={settings} ref={ref} />
        )}
      </SheetContext.Consumer>
    )}
  </AppNavigatorContext.Consumer>
))
