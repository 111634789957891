import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { IQuantityInputProps, QuantityInput } from 'browser/components/atomic-elements/atoms/input/quantity-input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'

const TemperatureOptions = [
  { label: '°C', value: 'Celsius' },
  { label: '°F', value: 'Fahrenheit' },
]

/**
 * @uiComponent
 */
interface ITemperatureRangeField extends IQuantityInputProps {
  density?: string
  errorsMap?: any
  label: string
  labelProps?: any
  temperatureRangeWidthClassName?: string
}

export class TemperatureRangeField extends React.Component<ITemperatureRangeField, any> {

  public static defaultProps: Partial<ITemperatureRangeField> = {
    label: '',
    temperatureRangeWidthClassName: 'c-formGroup-horizontalContent',
    type: 'text',
  }

  public render() {
    const {
      density,
      errorsMap,
      label,
      labelProps,
      temperatureRangeWidthClassName,
      ...quantityInputProps
    } = this.props

    const {
      className,
      isEditableInline,
      isHorizontalLayout,
      isRequired,
      size,
      value,
    } = this.props

    let contentTemperatureRangeWidthClassName = ''
    if (isHorizontalLayout) {
      contentTemperatureRangeWidthClassName = temperatureRangeWidthClassName
    }

    const { min, max } = this.getValue()
    return (
      <FormGroup
        className={className}
        hasValue={!_.isEmpty(value)}
        isEditableInline={isEditableInline}
        isHorizontalLayout={isHorizontalLayout}
        size={size}
      >
        <Label
          {...labelProps}
          hasError={!_.isEmpty(errorsMap)}
          isHorizontalLayout={isHorizontalLayout}
          isRequired={isRequired}
          size={size}
        >
          {label}
        </Label>
        <div className={classNames(contentTemperatureRangeWidthClassName)}>
          <div className='row row--collapse'>
            <div className='col-xs-6'>
              <QuantityInput
                {...quantityInputProps}
                placeholder='Min'
                defaultUnit='Fahrenheit'
                errorsMap={_.get(errorsMap, 'min')}
                onChange={(value) => this.handleChange('min', value)}
                unitOptions={TemperatureOptions}
                value={min}
              />
            </div>
            <div className='col-xs-6'>
              <QuantityInput
                {...quantityInputProps}
                placeholder='Max'
                defaultUnit='Fahrenheit'
                errorsMap={_.get(errorsMap, 'max')}
                onChange={(value) => this.handleChange('max', value)}
                unitOptions={TemperatureOptions}
                value={max}
              />
            </div>
          </div>
        </div>
      </FormGroup>
    )
  }

  private getValue() {
    return this.props.value || {}
  }

  private handleChange = (key, value) => {
    const { onChange } = this.props
    const range = this.getValue()
    _.set(range, key, value)
    if (range.min || range.max) {
      onChange(range)
    } else {
      onChange(undefined)
    }
  }
}
