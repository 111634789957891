import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { createEntityTableColumns, filterTableColumns } from 'shared-libs/components/table/utils'
import { Formatter } from 'shared-libs/helpers/formatter'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import apis from 'browser/app/models/apis'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { SheetManager } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
import { Column, ITableProps, Table } from 'browser/components/atomic-elements/atoms/table'
import { TableLocationEdgeCell } from 'browser/components/atomic-elements/atoms/table/cells/table-location-edge-cell'
import { TableSettingsButton } from 'browser/components/atomic-elements/atoms/table/table-settings-button'
import { TableActionsButton } from 'browser/components/atomic-elements/atoms/table/table-actions-button'
import { EntityFormPanel } from 'browser/components/atomic-elements/organisms/entity/entity-form-panel'
import { AbstractSettings } from 'shared-libs/models/abstract-settings'
import { SchemaIds } from 'shared-libs/models/schema'
import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { DocumentsTableActionBar } from 'browser/components/atomic-elements/organisms/table-action-bar/documents-table-action-bar'
import { DefaultTableActionBar } from 'browser/components/atomic-elements/organisms/table-action-bar/default-table-action-bar'

Formatter.registerEdgeFormatter('/1.0/entities/metadata/location.json', (edge, context?) => {
  // TODO - this should be an entity table specific override, not a hack in the generic formatting functions
  return context?.expectString ? edge.displayName : <TableLocationEdgeCell value={edge} />
})

/**
 * @uiComponent
 *
 * @prop addAutofillContainer - (optional) Set to false for tables rendered within search result
 *                              contexts to enable dynamic sizing instead of filling the page
 *
 */
export interface IEntityTableProps extends ITableProps {
  availableColumns?: any[]
  columns: Column[]
  dataSet?: any
  emptyStateEntitySchema?: any
  emptyIconClassName?: string
  emptyState?: JSX.Element
  enableGroupSimplification?: boolean
  entitySchema: any
  entityNameOverride?: string
  footerContent?: any
  groups?: any[]
  isConfigurable?: boolean
  isLoading?: boolean
  isLoadingTable?: boolean
  onColumnsChange?: any
  onGroupsChange?: any
  onOrdersChange?: any
  onVerticalScroll?: any
  uiSchemaPath?: string
  position?: string
  canAddEntity?: boolean
  settings: AbstractSettings
  showActionHeader: boolean
  actions: any
  addAutofillContainer: boolean
}

interface IEntityTableState {
  columns: any[]
  emptyStateEntitySchemaRecord: any
  isLoadingTable: boolean
}

export class EntityTable extends React.Component<IEntityTableProps, IEntityTableState> {
  private tableRef = React.createRef<any>()

  public static defaultProps: Partial<IEntityTableProps> = {
    onColumnsChange: _.noop,
    onGroupsChange: _.noop,
    onOrdersChange: _.noop,
    position: 'absolute',
    canAddEntity: true,
    addAutofillContainer: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      columns: null,
      emptyStateEntitySchemaRecord: null,
      isLoadingTable: props.isLoadingTable,
    }
  }

  public UNSAFE_componentWillMount() {
    const { emptyStateEntitySchema } = this.props
    if (emptyStateEntitySchema) {
      const record = apis.getStore().getRecord('/1.0/entities/metadata/documentClassificationBatchUpload.json')
      this.setState({ emptyStateEntitySchemaRecord: record })
    }
    this.createTableColumns(this.props);
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { columns, isLoadingTable, orders } = this.props
    if (columns !== nextProps.columns || orders !== nextProps.orders) {
      this.createTableColumns(nextProps)
    }
    if (isLoadingTable !== nextProps.isLoadingTable) {
      this.setState({ isLoadingTable: nextProps.isLoadingTable })
    }
  }

  public render() {
    const { columns } = this.state
    const {
      className,
      footerContent,
      height,
      position,
      onColumnsChange,
      showActionHeader,
      actions
    } = this.props

    if (this.isLoading()) {
      return this.renderLoadingState()
    }

    // 37 needs to be synced with .c-cardHeader's height
    // const tableHeight = height - 37
    const tableHeight = height

    const wrapperFn = showActionHeader || actions ?
      this.renderWrapper :
      (c) => c

    return wrapperFn(
      <SheetManager
        className={classNames({
          'c-entityTableContainer': position === 'absolute',
        })}
      >
        {this.renderHeader()}
        <Table
          {...this.props}
          className={classNames('c-entityTable', className)}
          columns={columns}
          height={tableHeight}
          onColumnHeaderClick={this.handleOrdersChanged}
          emptyStateView={this.renderEmptyState()}
          footerContent={footerContent}
          onColumnsChange={onColumnsChange}
          ref={this.tableRef}
        />
      </SheetManager>
    )
  }

  public renderWrapper = (content) => {
    const additionalHeaders = this.getActionHeader()
    const { addAutofillContainer } = this.props

    if (!addAutofillContainer) {
      return (
        <>
          {additionalHeaders}
          {content}
        </>
      )
    }

    return (
      <div className='grid-block vertical'>
        {additionalHeaders}
        <div className='grid-block'>
          <AutofillBlock>
            {content}
          </AutofillBlock>
        </div>
      </div>
    )
  }

  public getActionHeader = () => {
    const { entitySchema, selection, actions } = this.props

    if (_.includes(entitySchema.getTypes(), SchemaIds.DOCUMENT)) {
      return <DocumentsTableActionBar
        entityPath='data'
        selection={selection}
      />
    }

    return <DefaultTableActionBar
      entityPath='data'
      selection={selection}
      actions={actions}
    />
  }

  private isLoading = (): boolean => {
    const { isLoading } = this.props
    const { columns } = this.state
    return isLoading || columns === null
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleOrdersChanged = (column) => {
    const { columns, groups, orders } = this.props

    if (column.path.startsWith('data.')) {
      column.path = column.path.replace('data.', '')
    }
    const path = column.sortKey || column.path
    const order = _.find(orders, { path }) || {}
    const newOrders = [{
      label: column.label,
      path,
      type: order.type === 'descending' ? 'ascending' : 'descending',
    }]
    this.handleTableConfigChange({ columns, groups, orders: newOrders })
  }

  private handleShowEntityFormPanel = (settings) => {
    const { entitySchema, uiSchemaPath } = this.props
    const { emptyStateEntitySchemaRecord } = this.state
    EntityFormPanel.open({
      schema: emptyStateEntitySchemaRecord ? emptyStateEntitySchemaRecord : this.props.entitySchema,
      settings,
      uiSchemaPath,
    })
  }

  private renderLoadingState() {
    return (
      <div>
        <LoadingSpinner />
      </div>
    )
  }

  private renderEmptyState() {
    const { emptyState, entitySchema, emptyIconClassName, entityNameOverride, canAddEntity } = this.props
    if (emptyState) {
      return emptyState
    }
    // TODO(peter/louis): Need to be able to distinguish between that there
    // are no entities of that type in the DB or if the filters are too strong.
    const entityName = entityNameOverride || (entitySchema.title).toLowerCase()
    return (
      <AppNavigatorContext.Consumer>
        {({ settings }) => (
          <div className='c-emptyTableMessage'>
            <i className={classNames(emptyIconClassName)} />
            <div className='f3 lh-title mb2'>
              No {entityName} matches your selected filters.
            </div>
            { canAddEntity && (
              <>
                <div className='lh-copy mb4'>
                  Please modify filters to display matches or click below to add {entityName}.
                </div>
                <Button
                  size='lg'
                  onClick={() => this.handleShowEntityFormPanel(settings)}
                >
                  Add {entityName}
                </Button>
              </>
            ) }
          </div>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private renderHeader() {
    const {
      availableColumns,
      columns,
      entitySchema,
      groups,
      isConfigurable,
      orders,
    } = this.props
    const { isLoadingTable } = this.state

    if (!isConfigurable) {
      return null
    }

    return (
      <>
        {!_.isEmpty(groups) &&
          <TableActionsButton
            tableRef={this.tableRef}
          />
        }
        <TableSettingsButton
          availableColumns={availableColumns}
          columns={columns}
          entitySchema={entitySchema}
          groups={groups}
          isLoadingTable={isLoadingTable}
          onChange={this.handleTableConfigChange}
          orders={orders}
        />
      </>
    )
  }

  private async createTableColumns(props: IEntityTableProps) {
    const { columns, entitySchema } = props
    const entityTableColumns = await createEntityTableColumns(apis, entitySchema, columns)
    const filteredEntityTableColumns = filterTableColumns(entityTableColumns, apis.getSettings())
    this.setState({
      columns: filteredEntityTableColumns
    })
  }

  private handleTableConfigChange = ({groups, columns, orders}) => {
    const { onColumnsChange, onGroupsChange, onOrdersChange } = this.props
    onColumnsChange(columns)
    onGroupsChange(groups)
    onOrdersChange(orders)
  }
}
