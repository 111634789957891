import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { PrintContainer } from 'browser/components/atomic-elements/atoms/print-container/print-container'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import {
  fetchEdges,
  getDisplayName,
  renderIdentifier,
  renderLocation,
  renderPhonesAndEmails,
  renderSignatureLines,
} from 'browser/components/atomic-elements/domains/trucking/generated-document-helper'
import { StopCargoTable } from 'browser/components/atomic-elements/domains/trucking/stop-cargo-table/stop-cargo-table'

interface IBillOfLadingProps extends IBaseProps {
  entity: any
  settings: Settings
}

interface IBillOfLadingState {
  firm: any
  isLoading: boolean
  orderContent: any
  tmsSettings: any
}

class BillOfLading extends React.Component<IBillOfLadingProps, IBillOfLadingState> {

  constructor(props) {
    super(props)
    this.state = {
      firm: null,
      isLoading: true,
      orderContent: null,
      tmsSettings: null,
    }
  }

  public componentDidMount() {
    const { entity, settings } = this.props
    const promises = []
    const store = apis.getStore()
    const orderContent = _.cloneDeep(entity.content)
    fetchEdges(store, ['brokerOrder'], orderContent, promises)
    fetchEdges(store, ['carrierOrder'], orderContent, promises)
    fetchEdges(store, ['dispatchOrder'], orderContent, promises)
    Promise.all(promises).then(() => {
      this.setState({
        firm: settings.getFirm(),
        isLoading: false,
        orderContent,
        tmsSettings: settings.getTMSSettings(),
      })
    })
  }

  public render() {
    const { isLoading } = this.state
    if (isLoading) {
      return this.renderLoading()
    }
    // TODO(peter/louis): need to pull out print frame to generalized view
    // and then there also handle the print modal
    return (
      <div className='c-printFrame'>
        {this.renderBillOfLading()}
      </div>
    )
  }

  private renderLoading() {
    return (
      <div className='c-printFrame'>
        <Head
          title='Loading'
        />
        <div className='grid-block'>
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  private renderBillOfLading() {
    const { firm, orderContent } = this.state
    const { settings } = this.props
    const { brokerOrder, carrierOrder, dispatchOrder } = orderContent
    const order = settings.isBrokerTMSApplication() ? brokerOrder : carrierOrder
    const { carrier, carrierContact } = order
    const carrierAddress = _.first(_.get(carrier, 'business.addresses', []))

    const brokerLogo = _.get(firm, 'business.logo.uri', '')
    const brokerAddress = _.first(_.get(firm, 'business.addresses', []))
    const brokerPhones = _.get(firm, 'business.phoneNumbers', [])
    const brokerEmails = _.get(firm, 'business.emails', [])

    const user = settings.getUser()
    const userPhones = _.get(user, 'person.phoneNumbers', [])
    const userEmails = _.get(user, 'person.emails', [])

    const todaysDate = moment().format('ddd, MMM DD, YYYY')

    const { identifier } = dispatchOrder
    const title = `${identifier} - Bill of Lading`
    const primaryIdentifierName = 'Load Number'

    return (
      <PrintContainer>
        <Head
          title={title}
        />
        <div className='c-printPage c-printPage--portrait'>
          <div className='c-printPageInner'>

            <div className='row'>
              <div className='col-xs-6'>
                <div className='f2 lh-title'>
                  Bill of Lading
                </div>
                <div className='u-bumperBottom'>
                  {primaryIdentifierName}: {identifier} &middot; Issued: {todaysDate}
                </div>
              </div>
              <div className='col-xs-6 tr'>
                <img
                  className='c-printLogo u-bumperBottom'
                  src={brokerLogo}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-xs-6'>
                <FormTable>
                  <FormGroup isHorizontalLayout={true}>
                    <Label
                      size='sm'
                      isHorizontalLayout={true}
                    >
                      Broker
                    </Label>
                    <div className='c-fakeInputContainer--sm'>
                      <div className='b'>
                        {getDisplayName(firm)}
                      </div>
                      {renderLocation(_.get(brokerAddress, 'value'))} <br />
                      {renderPhonesAndEmails(brokerPhones, brokerEmails)}
                    </div>
                  </FormGroup>
                  <FormGroup isHorizontalLayout={true}>
                    <Label
                      size='sm'
                      isHorizontalLayout={true}
                    >
                      Broker Contact
                    </Label>
                    <div className='c-fakeInputContainer--sm'>
                      <div className='b'>
                        {user.person.firstName} {user.person.lastName}
                      </div>
                      {_.map(userPhones, (phone: any, index) => (
                        <span key={index}>
                          {phone.value.phone}<br />
                        </span>
                      ))}
                      {_.map(userEmails, (email: any, index) => (
                        <span key={index}>
                          {email.value}<br />
                        </span>
                      ))}
                    </div>
                  </FormGroup>
                </FormTable>
              </div>
            </div>

            <Section
              title='Details'
            >
              <div className='row'>
                <div className='col-xs-6'>
                  <FormTable>
                    <FormGroup isHorizontalLayout={true}>
                      <Label
                        size='sm'
                        isHorizontalLayout={true}
                      >
                        {primaryIdentifierName}
                      </Label>
                      <div className='c-fakeInputContainer--sm b'>
                        {identifier}
                      </div>
                    </FormGroup>
                    {_.map(dispatchOrder.additionalIdentifiers, (additionalIdentifier: any, index) => {
                      return renderIdentifier(additionalIdentifier.label, additionalIdentifier.value, index)
                    })}
                  </FormTable>
                </div>
              </div>
              <StopCargoTable
                className='u-bumperTop'
                order={order}
                dispatchOrder={dispatchOrder}
              />
            </Section>

            <div className='pre-wrap'>
              {renderSignatureLines('Shipper / Consignor Name')}
              {renderSignatureLines('Carrier Driver Name')}
              {renderSignatureLines('Receiver / Consignor Name')}
            </div>
          </div>
        </div>
      </PrintContainer>
    )
  }
}

export default React.forwardRef((props: IBillOfLadingProps, ref: React.Ref<BillOfLading>) => (
  <AppNavigatorContext.Consumer>
    {({ settings }) => <BillOfLading {...props} settings={settings} ref={ref} />}
  </AppNavigatorContext.Consumer>
))
