import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Checkbox, ICheckboxProps } from 'browser/components/atomic-elements/atoms/checkbox/checkbox'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import 'browser/components/atomic-elements/molecules/fields/checkbox-field/_checkbox-field.scss'
import { IFocusableInput, InlineEditableInputProps } from 'browser/components/atomic-elements/molecules/inline-editable'

export interface ICheckboxFieldImplProps extends ICheckboxProps, InlineEditableInputProps {
  density?: string
  onChange: (value: boolean) => void
  value?: boolean
}

export class CheckboxFieldImpl extends React.Component<ICheckboxFieldImplProps, any> implements IFocusableInput {

  public static defaultProps: Partial<ICheckboxFieldImplProps> = {
    value: false,
  }

  private input: Checkbox

  public focus() {
    this.input.focus()
  }

  public render() {
    const {
      density,
      inlineEditControls,
      isHorizontalLayout,
      value,
    } = this.props
    const {
      className,
      ...checkboxProps
    } = this.props
    return (
      <FormGroup
        className={classNames('c-checkboxField', className)}
        hasValue={!_.isNil(value)}
        isHorizontalLayout={isHorizontalLayout}
      >
        <Checkbox
          {...checkboxProps as ICheckboxProps}
          ref={(ref) => this.input = ref}
        />
      </FormGroup>
    )
  }
}
