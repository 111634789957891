import React from 'react'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'

import apis from 'browser/app/models/apis'

import 'browser/components/atomic-elements/organisms/onboarding-block/_onboarding-block.scss'
import { Entity } from 'shared-libs/models/entity'

interface ITestFtpBlockProps {
  isEditable?: boolean
}

interface ITestFtpBlockState {
  statusText: any
  failureText: any
}

export class TestFtpBlock extends React.Component<ITestFtpBlockProps, ITestFtpBlockState> {

  static defaultProps: Partial<ITestFtpBlockProps> = {
    isEditable: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      failureText: null,
      statusText: 'No Result',
    }
    this.sendConnectionRequest = this.sendConnectionRequest.bind(this)
  }

  public render() {
    const { isEditable } = this.props
    return (
      <div>
        <Button
          isDisabled={!isEditable}
          onClick={this.sendConnectionRequest}
        >
          Test Connection
        </Button>
        <div>
          {this.getTestResult()}
        </div>
      </div>
    )
  }

  private sendConnectionRequest() {
    this.setState({
      failureText: null,
      statusText: 'Sending Request...',
    })
    apis.getTestFtpStatus().then((result) => {
      result.waitUntilIdle().then(() => {
        const resultState = result.core_integrations_ftp_activities_testConnection.testResult
        let failureOutputText = null
        if (resultState === 'failure') {
          failureOutputText = result.core_integrations_ftp_activities_testConnection.failureOutput.message
        }
        this.setState({
          failureText: failureOutputText,
          statusText: resultState,
        })
      })
    })
  }

  private getTestResult() {
    return (
      <div>
        <div>
          Result: {this.state.statusText}
        </div>
        {this.getFailureText()}
      </div>
    )
  }

  private getFailureText() {
    if (this.state.failureText) {
      return (
        <div>
          {this.state.failureText}
        </div>
      )
    }
  }
}
