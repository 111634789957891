import React from 'react'

// tslint:disable-next-line:max-line-length
import { FormGroupContentWrapper } from 'browser/components/atomic-elements/atoms/form-group-content-wrapper/form-group-content-wrapper'
import { defaultProps, ISelectProps } from './select/interface'
import { NativeSelect } from './select/native-select'
import { ReactSelect } from './select/react-select'
import { evaluateData, evaluateExpression } from 'shared-libs/helpers/evaluation'
import _ from 'lodash'
import { translateString } from 'shared-libs/helpers/utils'
import apis from 'browser/app/models/apis'
import { CustomFormulas } from 'shared-libs/helpers/formulas'

export { ISelectProps }

const COMPUTED_LABEL_PATH = '__computedLabel__'

export class Select extends React.Component<ISelectProps> {
  public static defaultProps = defaultProps

  private select: any

  constructor(props: ISelectProps) {
    super(props)
  }

  public focus() {
    this.select.focus()
  }

  public render() {
    const { children, isDisabled, errorText, className, isHorizontalLayout } = this.props
    const debugId = this.props['data-debug-id']
    return (
      <FormGroupContentWrapper
        hasError={errorText && errorText.length > 0}
        isHorizontalLayout={isHorizontalLayout}
        isDisabled={isDisabled}
        className={className}
        data-debug-id={debugId}
      >
        {this.renderSelect()}
        {children}
      </FormGroupContentWrapper>
    )
  }

  private renderSelect() {
    const { frames, noOptionsMessage, placeholder, optionLabelPath, labelFormula } = this.props
    const translationTable = frames?.getContext('translationTable')
    const translatedPlaceholder = translateString(placeholder, translationTable)
    const translatedNoOptionsMessage = translateString(noOptionsMessage, translationTable)
    const childProps = {
      ...this.props,
      optionMapper: this.props.optionMapper || this.getFormulaOptionMapper,
      ref: (ref) => {
        this.select = ref
      },
      placeholder: translatedPlaceholder,
      noOptionsMessage: translatedNoOptionsMessage,
      optionLabelPath: this.props?.options?.length > 0 && labelFormula ? COMPUTED_LABEL_PATH : optionLabelPath,
    }

    const SelectInput = this.props.isNative ? NativeSelect : ReactSelect
    return <SelectInput {...childProps} />
  }

  private getFormulaOptionMapper = (options) => {
    const { formula, frames, optionValuePath, optionLabelPath, labelFormula, isDisabledFormula } = this.props
    const settings = apis.getSettings()

    const formattedOptions = options.map((option) => {
      const value = option[optionValuePath]
      if (typeof value === 'object' && value !== null) {
        const label = value[optionLabelPath]
        return {
          [optionLabelPath]: label,
          value,
        }
      }
      return option
    })

    const clonedOptions = labelFormula || isDisabledFormula ?
      formattedOptions.map(option => {
        return option.cloneDeep ? option.cloneDeep() : _.cloneDeep(option)
      }) :
      formattedOptions

    labelFormula && clonedOptions.forEach(option =>
      option[COMPUTED_LABEL_PATH] = evaluateExpression({ settings, ...CustomFormulas, option }, labelFormula))

    isDisabledFormula && clonedOptions.forEach(option =>
      option.isDisabled = evaluateExpression({ settings, ...CustomFormulas, option }, isDisabledFormula))

    if (!formula || !frames) return clonedOptions

    const dataSchema = frames.getContext('dataSchema')
    if (!dataSchema || dataSchema.type !== 'object') return clonedOptions

    const optionsMap = {}

    // Filter on the option'/s "value" property
    const optionValues = clonedOptions.map((option) => {
      const hash = JSON.stringify(option)
      const value = _.cloneDeep(option[optionValuePath])
      value['_hash'] = hash
      optionsMap[hash] = option
      return value
    })

    // bit of a hack here, for lack of a refactor. Ideally,
    // we can carefully move this to, say, json-select-factory.
    // There's very similiar logic there, so if possible we shouldn't
    // really need this.
    // The idea is that we only need to run this formula when
    // enum(/enumOptions/suggestions) and a formula are present.
    // We run them through the evaluator as a variable called `data`.
    let filtered = optionValues
    if (dataSchema.enum
      || dataSchema.enumOptions
      || dataSchema.suggestions) {
      filtered = evaluateData(optionValues, formula, frames)
    }

    // Re-inflate the option
    return filtered.map((value) => {
      return optionsMap[value['_hash']]
    })
  }
}
