import Promise from 'bluebird'
import { v4 as uuidv4 } from 'uuid'

function now(): number {
  return (new Date().getTime())
}

export class Timer {
  // Interface Arguments
  private timedFunction: () => void
  private interval: number

  // Internal Variables
  private timeoutId: any
  private maxTimeoutId: any
  private promise: Promise

  private lastRequestTime: number
  public isPaused: boolean = false
  public pauseReason: string = undefined
  public pauseKeys: Set<string> = undefined

  constructor(timedFunction: () => Promise, interval, continueOnFailure = false, maxTimeOut = 0) {
    const continueTimer = () => {
      if (this.isPaused) {
        return
      }

      this.timeoutId = setTimeout(this.timedFunction, this.interval)
    }

    this.timedFunction = () => {
      this.promise = timedFunction().then(() => {
        if(!continueOnFailure) {
          continueTimer()
        }
      }).finally(() => {
        this.lastRequestTime = now()

        if (continueOnFailure) {
          continueTimer()
        }

        this.promise = null
      })
    }

    this.dispose = this.dispose.bind(this)
    this.interval = interval
    this.timeoutId = setTimeout(this.timedFunction, this.interval)
    this.pauseKeys = new Set()

    if (maxTimeOut) {
      this.maxTimeoutId = setTimeout(this.dispose, maxTimeOut)
    }
  }

  private clearTimeout() {
    clearTimeout(this.timeoutId)
    this.timeoutId = null
  }

  public pause = (reason=null) => {
    this.isPaused = true
    this.pauseReason = reason
    this.clearTimeout()

    const pauseKey = uuidv4()
    this.pauseKeys.add(pauseKey)
    return pauseKey
  }

  public _resume = (key, timeUntilNextTick) => {
    this.isPaused = false
    this.pauseReason = undefined
    this.pauseKeys.delete(key)

    if (this.timeoutId || this.pauseKeys.size !== 0) { return }

    this.timeoutId = setTimeout(this.timedFunction, Math.max(timeUntilNextTick, 0))
  }

  public resume = (key) => {
    this._resume(key, this.lastRequestTime + this.interval - now())
  }

  public resumeDelayed = (key) => {
    this._resume(key, this.interval)
  }

  public dispose() {
    this.timeoutId && this.clearTimeout()
    if (this.maxTimeoutId) {
      clearTimeout(this.maxTimeoutId)
      this.maxTimeoutId = null
    }

    this.promise && this.promise.cancel && this.promise.cancel()
  }

}
