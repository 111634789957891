import _ from 'lodash'

const formatValue = (value) => {
  return value.employeeId ? `${value.displayName} - ${value.employeeId}` : value.displayName
}

export const formatFilterValue = (unused, filterValues) => {
  if (_.isNil(filterValues)) { return }
  if (!_.isArray(filterValues)) { filterValues = [filterValues] }
  const values = _.map(filterValues, formatValue)
  return values.join(', ')
}
