import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { ILabelProps, Label } from 'browser/components/atomic-elements/atoms/label/label'
import 'browser/components/atomic-elements/atoms/toggle/_toggle.scss'
import { View } from 'browser/components/atomic-elements/atoms/view'

export interface IToggleProps extends IBaseProps {
  hasError?: boolean
  helpText?: string
  horizontalLabel?: string
  horizontalLabelProps?: ILabelProps
  inlineEditControls?: React.ReactElement<any>
  inputWidthClassName?: string
  isStatic?: boolean
  isDisabled?: boolean
  isHorizontalLayout?: boolean
  label?: string
  labelProps?: ILabelProps
  onChange?: (event: any) => void
  size?: string
  isEditableInline?: boolean
  value?: boolean
}

export class Toggle extends React.Component<IToggleProps, any> {
  public static defaultProps: Partial<IToggleProps> = {
    hasError: false,
    horizontalLabelProps: Label.defaultProps,
    inputWidthClassName: 'u-flexGrow',
    isDisabled: false,
    isHorizontalLayout: false,
    isStatic: false,
    labelProps: Label.defaultProps,
    value: false,
  }

  public input: any

  constructor(props) {
    super(props)
    this.state = {
      isFocused: false,
    }
  }

  public focus() {
    // TODO(peter): fill me out
  }

  public render() {
    if (this.props.isHorizontalLayout) {
      return this.renderHorizontalToggle()
    }

    return this.renderVerticalToggle(true, false)
  }

  //////////////////////////////////////////////////////////////////////////////
  // Renderers
  //////////////////////////////////////////////////////////////////////////////

  private renderHorizontalToggle() {
    const {
      className,
      horizontalLabel,
      horizontalLabelProps,
      label,
      labelProps,
      isDisabled,
      isEditableInline,
      isStatic,
      inputWidthClassName,
      hasError,
      size,
      value,
    } = this.props
    let contentHorizontalLabel = horizontalLabel
    let hideLabel = false
    if (_.isEmpty(horizontalLabel)) {
      contentHorizontalLabel = label
      hideLabel = true
    }
    const toggleSizeClass = _.isEmpty(size) ? '' : `c-toggle--${size}`
    const labelSizeClass = _.isEmpty(size) ? '' : `c-label--${size}`
    // Cannot wrap the toggle + inline edit controls within the label,
    // otherwise the label catches the click for the input and interferes
    // with the inline edit controls.
    const toggleTag = isStatic && isEditableInline ? 'div' : 'label'
    return (
      <View
        tagName={toggleTag}
        className={classNames('c-toggle c-toggle--horizontal', toggleSizeClass, className, {
          'c-toggle--static': isStatic,
          'is-disabled': isDisabled,
        })}
      >
        <div
          className={classNames('c-label c-label--isHorizontal',
            labelSizeClass, horizontalLabelProps.widthClassName, horizontalLabelProps.className, {
              'c-label--error': hasError,
              'c-label--secondary' : horizontalLabelProps.isSecondary,
            })}
        >
          {contentHorizontalLabel}
        </div>
        <div className={classNames('c-formGroup-horizontalContent c-toggle-boxContainer', inputWidthClassName)}>
          {this.renderVerticalToggle(hideLabel, true)}
        </div>
      </View>
    )
  }

  private renderVerticalToggle(hideLabel, isLabelSecondary) {
    const {
      label,
      labelProps,
      inlineEditControls,
      isDisabled,
      isEditableInline,
      isHorizontalLayout,
      isStatic,
      hasError,
      size,
    } = this.props
    // Cannot wrap the toggle + inline edit controls within the label,
    // otherwise the label catches the click for the input and interferes
    // with the inline edit controls.
    const toggleTag = isStatic && isEditableInline ? 'div' : 'label'
    const toggleSizeClass = _.isEmpty(size) ? '' : `c-toggle--${size}`
    const labelSizeClass = _.isEmpty(size) ? '' : `c-label--${size}`

    return (
      <View
        tagName={toggleTag}
        className={classNames('c-toggle', toggleSizeClass, {
          'c-toggle--static': isStatic,
          'is-disabled': isDisabled,
        })}
      >
        {this.renderToggle()}
        <div
          className={classNames('c-toggle-labelSpacer', labelSizeClass, {
            'u-hide': !isHorizontalLayout,
          })}
        />
        <span
          className={classNames('c-label c-toggle-label--inline',
            labelSizeClass, labelProps.className, {
              'c-label--error': hasError,
              'c-label--secondary' : labelProps.isSecondary,
              'c-toggle-label--secondary': isLabelSecondary,
              'u-hide': hideLabel,
            })}
        >
          {label}
        </span>
        {this.renderHelpText()}
        {inlineEditControls}
      </View>
    )
  }

  private renderToggle() {
    const {
      className,
      value,
      onChange,
      size,
    } = this.props
    const sizeClass = _.isEmpty(size) ? '' : `c-toggle-slider--${size}`

    return (
      <div
        className={classNames('c-toggle-slider', sizeClass, className, {
          'c-toggle-slider--error': this.props.hasError,
        })}
      >
        <input
          data-debug-id={_.get(this.props, 'data-debug-id')}
          checked={value}
          className='c-toggle-input'
          onChange={onChange}
          type='checkbox'
          ref={(ref) => { this.input = ref }}
        />
        <i className='c-toggle-background' />
        <i className='c-toggle-switch' />
      </div>
    )
  }

  private renderHelpText() {
    const { helpText, isHorizontalLayout, size } = this.props
    if (!helpText) {
      return null
    }
    const helpBlockSizeClass = _.isEmpty(size) ? '' : `c-toggle-helpBlock--${size}`
    return (
      <HelpBlock
        className={classNames(helpBlockSizeClass, {
          'c-toggle-helpBlock--horizontal': isHorizontalLayout,
        })}
        key='helpBlock'
      >
        {this.props.helpText}
      </HelpBlock>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleOnBlur(event) {
    this.setState({ isFocused: false })
  }

  private handleOnFocus(event) {
    if (!this.props.isDisabled) {
      this.setState({ isFocused: true })
    }
  }
}
