import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { MultiSelectList } from 'browser/components/atomic-elements/atoms/select/multi-select-list'
import { CheckboxField } from 'browser/components/atomic-elements/molecules/fields/checkbox-field'
import { NegatableFilter } from '../negation-filter'

export interface IMultiSelectFilterPopoverProps extends IBaseProps {
  activeFilters: any[]
  entitySchema: object
  filter: object
  onChange: (value: any, ...args) => void
  onClear: () => void
  onResize?: () => void
  optionKey?: string
  optionLabeler: (filter: any, option: any, entitySchema: any) => string
  FilterOptionsCollection: any
}

export class MultiSelectFilterPopover extends React.Component<IMultiSelectFilterPopoverProps, any> {

  public static defaultProps: Partial<IMultiSelectFilterPopoverProps> = {
    optionKey: 'entityId',
  }

  public collection: any
  public list: any

  constructor(props) {
    super(props)
    const { activeFilters, entitySchema, FilterOptionsCollection } = props
    let { filter } = props
    filter = _.cloneDeep(props.filter)
    filter.values = _.get(filter, 'values', [])
    this.state = {
      filter,
      isLoading: true,
      isSearching: false,
      options: [],
    }
    this.collection = new FilterOptionsCollection(
      apis, activeFilters, entitySchema, filter)
    this.performSearch = _.debounce(this.performSearch.bind(this), 200)
  }

  public componentDidMount() {
    const { onResize } = this.props
    this.performSearch(onResize)
  }

  public componentWillUnmount() {
    this.collection?.cancel()
  }

  private handleNegation = (filter, ...args) => {
    this.setState({ filter })
    this.props.onChange(filter, ...args)
  }

  public render() {
    const { filter } = this.state

    return (
      <Popover className='collapse'>
        <div className='u-innerBumperTop u-innerBumperLeft u-innerBumperBottom'>
          <NegatableFilter
            filter={filter}
            onChange={this.handleNegation}
          />
        </div>
        <MultiSelectList
          getOptionLabel={this.handleGetOptionLabel}
          hasNext={this.collection.hasNext()}
          isLoading={this.state.isLoading}
          isSearching={this.state.isSearching}
          onChange={this.handleSelectionChange}
          onLoadMore={this.handleLoadMoreData}
          onSearch={this.handleQuery}
          optionKey={this.props.optionKey}
          options={this.state.options}
          ref={(ref) => { this.list = ref }}
          searchQuery={this.collection.query}
          selection={filter.values}
        />
      </Popover>
    )
  }

  private performSearch(onResize?) {
    this.collection.find(true).then((content) => {
      this.setState({
        isLoading: false,
        isSearching: false,
        options: content,
      }, onResize)
      this.list.forceUpdateList()
    })
  }

  private handleGetOptionLabel = (option) => {
    const { filter, optionLabeler, entitySchema } = this.props
    return optionLabeler(filter, option.data, entitySchema)
  }

  private handleLoadMoreData = (offset) => {
    this.collection.next().then((content) => {
      this.setState({ options: content })
    })
  }

  private handleQuery = (query) => {
    this.collection.query = query
    this.setState({ isSearching: true })
    this.performSearch()
  }

  private handleSelectionChange = (selection) => {
    const { filter } = this.state
    filter.values = selection
    if (_.isEmpty(selection)) {
      this.props.onClear()
    } else {
      this.props.onChange(filter)
    }
  }
}
