import React from 'react'

import { Formatter } from 'shared-libs/helpers/formatter'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'

interface IMarkerPopoverProps extends IBaseProps {
  entities: any[]
  onClick: (entity: any) => void
}

export class MarkerPopover extends React.Component<IMarkerPopoverProps, any> {

  public render() {
    const entities = this.props.entities
    if (entities.length > 1) {
      return (
        <Popover className='collapse'>
          {this.renderPopoverList(entities)}
        </Popover>
      )
    } else {
      return (
        <Popover className='collapse'>
          {this.renderPopover(entities[0])}
        </Popover>
      )
    }
  }

  private renderPopover(entity) {
    const { onClick } = this.props
    const name = entity.createdBy.displayName
    const displayName = entity.displayName
    const postedTime = Formatter.formatDateTime(entity.creationDate)
    const docType = entity.entityType
    return (
      <a
        className='c-mapPopoverListItem'
        onClick={() => onClick(entity)}
        key={entity.uniqueId}
      >
        <div className='c-mapPopoverListItem-right'>
          <div className='c-mapPopoverListItem-title u-ellipsis'>{displayName}</div>
          {docType} by <span className='c-mapPopoverListItem-author'>{name}</span>
          <div className='c-mapPopoverListItem-date'>{postedTime}</div>
        </div>
      </a>
    )
  }

  private renderPopoverList(entities) {
    return (
      <div className='c-mapPopoverList'>
        {entities.map((entity) => this.renderPopover(entity))}
      </div>
    )
  }
}
