declare let window: any

import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/organisms/authentication-page/_authentication-page.scss'
import { NotificationBar } from '../notification-bar'

const LoadDocsLogo = require('images/navigation/loaddocs_logo_small.svg')
const VectorLogo = require('images/navigation/vector-white-on-black.svg')

interface IAuthenticationPageProps extends IBaseProps {
  bodyClassName?: string
}

export class AuthenticationPage extends React.Component<IAuthenticationPageProps, any> {

  public render() {
    const { bodyClassName, children, className } = this.props
    return (
      <div className={classNames('flex flex-column items-center center mw6-m mw6-l h-100-ns c-authenticationPage-container', className)}>
        <div className='flex-grow-0 paper c-authenticationPage'>
          <NotificationBar />
          {this.renderLogo()}
          <div className={classNames('ph4 pb4', bodyClassName)}>
            {children}
          </div>
        </div>
      </div>
    )
  }

  private renderLogo() {
    const url = window.location.origin
    const logoSrc = url.indexOf('loaddocs') !== -1 ? LoadDocsLogo : VectorLogo
    return (
      <div className="pt4 ph4 tc">
        <img
          className="c-authenticationPage-logo mb3"
          src={logoSrc}
        />
      </div>
    )
  }
}
