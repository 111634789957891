import Promise from 'bluebird'

export function PromiseHash(object) {
  const keys = Object.keys(object)
  const values = []
  for (const key in object) {
    const value = object[key]
    values.push(value)
  }
  return Promise.all(values).then((results) => {
    return keys.reduce((fulfilledObject, key, index) => {
      fulfilledObject[key] = results[index]
      return fulfilledObject
    }, {})
  })
}
