import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

/**
 * @uiComponent
 */
interface ISearchSuggestionProps extends IBaseProps {
  isSelected: boolean
  entity: any
}

export class SearchSuggestion extends React.Component<ISearchSuggestionProps, any> {
  public render() {
    // https://medium.com/@justahl/simplifying-facebook-search-8a555dbef4ad#.7wlnnu7eg
    const { entity, isSelected } = this.props
    return (
      <div
        className={classNames('c-searchSuggestion', {
          'is-selected': isSelected,
        })}
      >
        {entity.displayName} | {entity.entityType}
      </div>
    )
  }
}
