import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { ContainerQuery } from 'react-container-query'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

/**
 * @uiComponent
 */
export interface IContainerQueryViewProps extends IBaseProps {
  query?: any
  initialSize?: any
}

export class ContainerQueryView extends React.Component<IContainerQueryViewProps, any> {
  public static defaultProps: Partial<IContainerQueryViewProps> = {
    query: {
      'c-containerQuery--xs': {
        maxWidth: 543,
      },
      // tslint:disable-next-line:object-literal-sort-keys
      'c-containerQuery--sm': {
        maxWidth: 767,
        minWidth: 544,
      },
      'c-containerQuery--md': {
        maxWidth: 991,
        minWidth: 768,
      },
      'c-containerQuery--lg': {
        maxWidth: 1199,
        minWidth: 992,
      },
      'c-containerQuery--xl': {
        minWidth: 1200,
      },
    },
  }

  public render() {
    const {
      children,
      className,
      initialSize,
      query,
    } = this.props
    return (
      <ContainerQuery
        query={query}
        initialSize={initialSize}
      >
        {(params) => (
          <div className={classNames('c-containerQuery', params, className)}>
            {children}
          </div>
        )}
      </ContainerQuery>
    )
  }
}
