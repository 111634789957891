import _ from 'lodash'
import React from 'react'

export function WithDefaultProps<T>(
  WrappedComponent: React.ComponentType<T>,
  defaultProps: Partial<T>
): React.ComponentType<T> {
  return class extends React.Component<T> {
    public render() {
      return (
        <WrappedComponent
          {...defaultProps}
          {...this.props}
        />
      )
    }
  }
}
