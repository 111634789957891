import React from 'react'
import { CSVLink } from 'react-csv'

/**
 * @uiComponent
 */
interface ICsvLinKProps {
  filename: string
  headers: string[]
  text: string
}

export const CsvLink: React.FC<ICsvLinKProps> = ({ filename, headers, text }) => {
  return (
    <CSVLink headers={headers} filename={filename} data={[]}>
      {text}
    </CSVLink>
  )
}
