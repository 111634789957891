import { NotificationActivity, connect } from 'getstream'
import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import _ from 'lodash'
import { IInboxConfiguration } from 'shared-libs/models/inbox'
import { InboxContext } from './inbox-context'
import { IInboxEntry, IUpdate } from './interface'
import { getInboxEntriesFromAggregatedActivities } from '../../app/utils/inbox'

interface IInboxProviderProps {
  inboxConfig: IInboxConfiguration
  userId: string
  children: React.ReactNode
}

const BATCH_SIZE = 10
const FETCH_DELAY_MS = 1000

export function InboxProvider({ children, inboxConfig, userId }: IInboxProviderProps) {
  const client = useMemo(() => {
    return connect(inboxConfig.apiKey, undefined, inboxConfig.appId)
  }, [])
  const feed = useMemo(() => {
    return client.feed('user', userId, inboxConfig.token)
  }, [client])

  const [activities, setActivites] = React.useState<Array<IInboxEntry>>([])
  const [unread, setUnread] = useState(0)
  const [hasMore, setHasMore] = useState(true)

  const onFeedChange = async (updates: IUpdate) => {
    if (updates.new.length === 0) {
      return
    }
    await new Promise((resolve) => setTimeout(resolve, FETCH_DELAY_MS))
    await fetchData(undefined, updates.new.length)
  }

  const fetchData = async (from?: string, limit: number = BATCH_SIZE) => {
    // id_lt -> activities added before that id
    const data = await feed.get({ limit, id_lt: from })
    let newActivities = await getInboxEntriesFromAggregatedActivities(
      data.results as NotificationActivity[]
    )
    if (from) {
      // we are fetching with an offset, append the items
      newActivities = [...activities, ...newActivities]
    } else {
      // we are fetching from the start, prepend the items
      newActivities = [...newActivities, ...activities]
    }
    // new activities may have been aggregated into existing groups
    newActivities = _.uniqBy(newActivities, (group) => group.group)
    setActivites(newActivities)
    setUnread(data.unread)
    if (data.results.length < limit) {
      setHasMore(false)
    }
  }

  useEffect(() => {
    const f = feed
    // they are not expecting an async function, but do not expect a return value
    f.subscribe(onFeedChange as any)
    return () => {
      f.unsubscribe()
    }
  }, [feed])

  useEffect(() => {
    fetchData() // first batch of activities
  }, [])

  const markAsRead = async (...ids: (string | string[])[]) => {
    const flattenedIds = _.concat([], ...ids)
    await feed.get({ mark_read: flattenedIds })
    setUnread(unread - flattenedIds.length)
    setActivites(
      _.map(activities, (activity) => ({
        ...activity,
        read: _.includes(flattenedIds, activity.groupId) ? true : activity.read,
      }))
    )
  }

  const markAllAsRead = async () => {
    await feed.get({ mark_read: true })
    setUnread(0)
    setActivites(
      _.map(activities, (activity) => ({
        ...activity,
        read: true,
      }))
    )
  }

  const fetchMore = async () => {
    if (hasMore && activities.length > 0) {
      await fetchData(activities[activities.length - 1].groupId)
    }
  }

  return (
    <InboxContext.Provider
      value={{
        client,
        feed,
        userId,
        unread,
        activities,
        markAsRead,
        markAllAsRead,
        hasMore,
        fetchMore,
      }}
    >
      {children}
    </InboxContext.Provider>
  )
}
