import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import { IconNames } from '@blueprintjs/icons'
import { Try } from 'shared-libs/helpers/utils'
import { Button, Icon } from '@blueprintjs/core'
import CodeMirror, { ReactCodeMirrorProps, ReactCodeMirrorRef } from '@uiw/react-codemirror'
import { solarizedLight } from '@uiw/codemirror-theme-solarized'
import { forEachDiagnostic } from '@codemirror/lint'
import { ViewUpdate, showPanel, EditorView } from '@codemirror/view'
import { Transaction, Text, Extension } from '@codemirror/state'

import 'browser/components/atomic-elements/atoms/input/_code-mirror-input.scss'

class StatusLine extends React.Component {
  private statusLine: string = undefined

  public setStatusLine(statusLine: string) {
    this.statusLine = statusLine
    this.forceUpdate()
  }

  public render() {
    return <div className="code tr f6 pa1 bg-black-05">{this.statusLine}</div>
  }
}

interface ICodeMirrorInputProps extends ReactCodeMirrorProps {
  showStatusLine?: boolean
}

export class CodeMirrorInput extends React.Component<ICodeMirrorInputProps> {
  static defaultProps: Partial<ICodeMirrorInputProps> = {
    showStatusLine: true,
  }

  private editorRef = React.createRef<ReactCodeMirrorRef>()
  private statusLineRef = React.createRef<StatusLine>()

  constructor(props: ICodeMirrorInputProps) {
    super(props)
    this.state = {}
  }

  shouldComponentUpdate(nextProps: Readonly<ICodeMirrorInputProps>): boolean {
    return this.props.value !== nextProps.value
  }

  public render() {
    return (
      <div className="c-section h-100 flex flex-column flex-grow">
        {this.renderEditor()}
        {this.renderStatusLine()}
      </div>
    )
  }

  private renderEditor() {
    const { value } = this.props
    const { showStatusLine, ...props } = this.props
    return (
      <CodeMirror
        className="overflow-y-auto"
        theme={solarizedLight}
        style={{
          "flexGrow": 1,
        }}
        basicSetup={{
          foldGutter: true,
          foldKeymap: true,
          lineNumbers: true,
          highlightActiveLineGutter: true,
          highlightActiveLine: false,
        }}
        {...props}
        ref={this.editorRef}
        onChange={this.debouncedOnChange}
        onUpdate={(viewUpdate) => {
          if (showStatusLine) {
            // Set the computed status line text directly on the ref instead of
            // setting state here, due to a flaw in the React CodeMirror
            // wrapper. It seems to not behave nicely during a re-render.
            //
            // For example:
            //
            //  * CMD+F
            //  * type search text
            //  * press Enter or hit Next
            //
            // This triggers `onUpdate` with the new text selection state, and
            // if we set state here then CM seems to unexpectedly destroy its
            // search dialog during the re-render.
            this.statusLineRef.current.setStatusLine(this.getStatusLineText(viewUpdate))
          }
        }}
        value={value}
      />
    )
  }

  /* don't trigger re-render delays due to fast typing or key repeat */
  private debouncedOnChange = _.debounce((value: string, update: ViewUpdate) => {
    const { onChange } = this.props
    onChange?.(value, update)
  }, 200)

  private renderStatusLine() {
    const { showStatusLine } = this.props
    if (!showStatusLine) {
      return null
    }
    return <StatusLine ref={this.statusLineRef} />
  }

  private getStatusLineText(viewUpdate: ViewUpdate) {
    const anchor = this.getStatusLine(viewUpdate.state.doc, viewUpdate.state.selection.main.anchor)
    const head = this.getStatusLine(viewUpdate.state.doc, viewUpdate.state.selection.main.head)

    const line = this.formatRange(anchor.line, head.line)
    const col = this.formatRange(anchor.column, head.column)
    const offset = this.formatRange(anchor.offset, head.offset)
    return `Ln ${line}, Col ${col}, Pos ${offset}`
  }

  /* display two numbers as a range if they are unique */
  private formatRange: (from: number, to: number) => string = _.flow([
    _.concat,
    _.uniq,
    _.sortBy,
    _.partial(_.join, _, ' - '),
  ])

  private getStatusLine(doc: Text, pos: number) {
    const line = doc.lineAt(pos)
    return { line: line.number, column: pos - line.from, offset: pos }
  }

  public getEditor() {
    return this.editorRef.current
  }

  public setText(text, opts: { isRemote?: boolean } = {}) {
    const { isRemote = false } = opts
    this.editorRef.current.view.dispatch({
      changes: {
        from: 0,
        to: this.editorRef.current.view.state.doc.length,
        insert: text,
      },
      annotations: [Transaction.remote.of(isRemote)],
    })
  }

  public hasError() {
    let hasError = false
    forEachDiagnostic(this.editorRef.current.state, (diag, from, to) => {
      if (diag.severity === 'error') {
        hasError = true
      }
    })
    return hasError
  }
}

export function formatButtonPanelExts(formatter: (string) => string): Extension[] {
  return [
    showPanel.of((view: EditorView) => {
      const dom = document.createElement('div')
      dom.className = 'relative'
      ReactDOM.render(
        <div className="absolute top-0 right-0 pa1">
          <Button onClick={() => handleFormatClick(view, formatter)}>
            <Icon icon={IconNames.CLEAN} />
          </Button>
        </div>,
        dom
      )
      return { top: true, dom }
    }),

    EditorView.baseTheme({
      // override panel z-index so CM lines don't prevent button clicks
      '.cm-panels': {
        zIndex: 1,
      },
    }),
  ]
}

function handleFormatClick(view: EditorView, formatter: (string) => string) {
  const formatted = Try(() => formatter(view.state.doc.toString()))
  if (formatted) {
    view.dispatch({
      changes: {
        from: 0,
        to: view.state.doc.length,
        insert: formatted,
      },
    })
  }
}
