import _ from 'lodash'
import * as Sentry from '@sentry/react'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Mobile404 } from './mobile-404'
import { MobileAppNavigator } from './mobile-app-navigator'

import { SignInCredential } from 'browser/app/pages/onboarding/sign-in-credential/sign-in-credential'
import { SignInPassword } from 'browser/app/pages/onboarding/sign-in-password'
import { PasswordRecover } from 'browser/app/pages/onboarding/password-recover'
import { PasswordReset } from 'browser/app/pages/onboarding/password-reset'
import { CompanyCode } from 'browser/app/pages/onboarding/company-code/company-code'
import { RegisterCredential } from 'browser/app/pages/onboarding/register-credential/register-credential'
import { Register } from 'browser/app/pages/onboarding/register/register'
import { Verify } from 'browser/app/pages/onboarding/verify'
import { initializeAnalytics } from 'browser/app/config/analytics'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { Landing } from './mobile-landing'

const SentryRoute = Sentry.withSentryRouting(Route);

export class MobileRootNavigator extends React.Component {

  constructor(props) {
    super(props)

    OverlayManager.pushReactHost(this)
  }

  componentDidMount() {
    initializeAnalytics()
  }

  componentWillUnmount(): void {
    OverlayManager.popReactHost()
  }

  render() {
    return (
      <Switch>
        <SentryRoute path="/sign-in" exact component={SignInCredential} />
        <SentryRoute path="/sign-in/password" exact component={SignInPassword} />
        <SentryRoute path="/sign-in/password-recover" exact component={PasswordRecover} />
        <SentryRoute path="/sign-in/password-reset" exact component={PasswordReset} />
        <SentryRoute path='/company-code' component={CompanyCode} />
        <SentryRoute path="/register-credential" exact component={RegisterCredential} />
        <SentryRoute path="/register" exact component={Register} />
        <SentryRoute path="/verify" exact component={Verify} />
        <SentryRoute path="/landing/:entityId" exact component={Landing} />
        <SentryRoute path="/" component={MobileAppNavigator} />
        <SentryRoute component={Mobile404} />
      </Switch>
    )
  }
}
