import _ from 'lodash'
import Promise from 'bluebird'

import { Entity } from './entity'
import { EventEmitter } from './event-emitter'
import { Store } from './store'

export abstract class AbstractCollection extends EventEmitter {

  public static COLLECTION_CHANGED = 'COLLECTION_CHANGED'
  public static COLLECTION_ERROR = 'COLLECTION_ERROR'

  protected store: Store
  protected inboundSubs: any[]
  protected outboundSubs: any[]
  protected promise: Promise<any>
  public content: any[]
  public isLoading: boolean
  public query: any
  public result: any

  constructor (store, query) {
    super()
    this.query = query
    this.store = store
    this.isLoading = false
    this.inboundSubs = []
    this.outboundSubs = []
    this.clearContent()
  }

  /// ///////////////////////////////////////////////////////////////////////////
  // Public Methods
  /// ///////////////////////////////////////////////////////////////////////////

  public get hasOutboundListeners(): boolean {
    return !_.isEmpty(this.outboundSubs)
  }

  public clearContent() {
    this.content = []
    this.result = null
  }

  public dispose() {
    _.forEach(this.inboundSubs, sub => sub.remove())
    _.forEach(this.outboundSubs, sub => sub.remove())
  }

  public addUpdateListener(handler) {
    this.outboundSubs.push(this.addListener(AbstractCollection.COLLECTION_CHANGED, handler))
    return this
  }

  public addErrorListener(handler) {
    this.outboundSubs.push(this.addListener(AbstractCollection.COLLECTION_ERROR, handler))
    return this
  }

  public reload() {
    return this.find().then(this.emitUpdate)
  }

  /// ///////////////////////////////////////////////////////////////////////////
  // Properties
  /// ///////////////////////////////////////////////////////////////////////////

  public get count() {
    return _.get(this.result, 'metadata.totalEntityMatchCount')
  }

  public get totalGroups() {
    return _.get(this.result, 'metadata.totalChildrenCount')
  }

  /// ///////////////////////////////////////////////////////////////////////////
  // Promise Proxy
  /// ///////////////////////////////////////////////////////////////////////////

  public cancel() {
    if (this.promise) {
      this.isLoading = false
      this.promise.cancel()
      this.promise = null
    }
    return this
  }

  public abstract find(forceFetch?: boolean): Promise<any>
  public abstract hasNext(): boolean
  public abstract next(): Promise<any>
  protected abstract handleOnPending: (entity: Entity) => void
  protected abstract handleOnInsert: (entity: Entity) => void
  protected abstract handleOnUpdate: (entity: Entity) => void
  protected abstract handleOnDelete: (entity: Entity) => void
  protected handleReloadCollection = _.noop

  protected emitUpdate = (content) => {
    this.isLoading = false
    this.emit(AbstractCollection.COLLECTION_CHANGED, content)
  }

  protected emitError = (error) => {
    this.isLoading = false
    this.emit(AbstractCollection.COLLECTION_ERROR, error)
  }

  protected subscribeToStore() {
    this.inboundSubs = [
      this.store.addListener(Store.RECORD_PENDING, this.handleOnPending),
      this.store.addListener(Store.RECORD_CREATED, this.handleOnInsert),
      this.store.addListener(Store.RECORD_CHANGED, this.handleOnUpdate),
      this.store.addListener(Store.RECORD_DELETED, this.handleOnDelete),
      this.store.addListener(Store.RELOAD_COLLECTION,
            this.handleReloadCollection)
    ]
  }

  /// ///////////////////////////////////////////////////////////////////////////
  // Private Methods
  /// ///////////////////////////////////////////////////////////////////////////

  private collectLeafNodes(node, path, results) {
    if (!node) { return }
    // if node doesn't have children it is a leaf node
    if (_.isEmpty(node.children)) {
      const result = _.isEmpty(path) ? node : _.get(node, path)
      if (result) { results.push(result) }
      return
    }
    _.forEach(node.children, node => {
      this.collectLeafNodes(node, path, results)
    })
  }

  protected flatten(result, path?) {
    const results = []
    this.collectLeafNodes(result, path, results)
    return results
  }
}
