import classNames from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { Formatter } from 'shared-libs/helpers/formatter'

interface IFinancialItemTableProps extends IBaseProps {
  financialItems: any
}

export class FinancialItemTable extends React.Component<IFinancialItemTableProps, any> {

  public render() {
    const { financialItems, className } = this.props
    let total = 0
    let unit = 'USD'
    const rows = _.map(financialItems, (item: any, index) => {
      if (!item) {
        return null
      }
      total = total + item.amount.value
      unit = _.get(item, 'amount.unit')
      return this.renderFinancialItemRow(item, index)
    })

    return (
      <div className={className}>
        <table className='c-table c-table--auto u-pb'>
          <thead className='c-table-header'>
            <tr>
              <th
                className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft'
              >
                Item
              </th>
              <th
                className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft'
                style={{ minWidth: '50px' }}
              >
                Description
              </th>
              <th
                className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'
                style={{ minWidth: '100px' }}
              >
                Rating Method
              </th>
              <th className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
                Quantity
              </th>
              <th className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
                Rate
              </th>
              <th className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
                Amount
              </th>
            </tr>
          </thead>
          <tbody className='c-table-body'>
            {rows}
          </tbody>
        </table>
        {this.renderTotalValue(total, unit)}
      </div>
    )
  }

  private renderFinancialItemRow = (item, index) => {
    const amountValue = _.get(item.amount, 'value', 0)
    const formattedAmount = Formatter.formatMoney(amountValue)
    return (
      <tr key={index}>
        <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft'>
          {_.get(item, 'itemType.financialAccountItemType.name')}
        </td>
        <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft'>
          {_.get(item, 'description', '-')}
        </td>
        <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
          {_.get(item, 'ratingMethod')}
        </td>
        <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
          {_.get(item, 'quantity.value')} {_.get(item, 'quantity.unit')}
        </td>
        <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
          {_.get(item, 'ratePerUnit')}
        </td>
        <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft tr'>
          {formattedAmount} {_.get(item, 'amount.unit')}
        </td>
      </tr>
    )
  }

  private renderTotalValue(amount, unit) {
    amount = Formatter.formatMoney(amount)
    const value = amount + ' ' + unit
    return (
      <div className='row'>
        <div className='col-xs-5 col-xs-offset-7'>
          <FormTable className='u-bumperTop'>
            <InputField
              inputClassName='tr'
              isHorizontalLayout={true}
              label='Total'
              value={value}
            />
          </FormTable>
        </div>
      </div>
    )
  }

  private renderEmptyChargesTable() {
    return (
      <div>
        No charges added yet.
      </div>
    )
  }

}
