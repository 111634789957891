import React from 'react'
import { IMultiSelectFilterItemProps, MultiSelectFilterItem } from '../multi-select-filter-item'
import { EmployeeFilterCollection } from './employee-filter-collection'
import { formatFilterValue } from './formatter'

export class EmployeeFilterItem extends React.Component<IMultiSelectFilterItemProps, any> {

  public render() {
    return (
      <MultiSelectFilterItem
        {...this.props}
        FilterOptionsCollection={EmployeeFilterCollection}
        optionLabeler={formatFilterValue}
      />
    )
  }
}
