import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { DayPicker } from 'react-dates'
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { DateRangeInput } from 'browser/components/atomic-elements/atoms/input/date-range-input/date-range-input'
import { isInclusivelyAfterDay, isNextDay, isSameDay } from './date-utils'

export interface IDateRangePickerProps extends IBaseProps {
  defaultEndDateToStartDate?: boolean
  minimumDays?: number
  onChange: (value: object, isShortcutClicked?: boolean) => void
  showTimeInput?: boolean
  showInputFields?: boolean
  value: object
}

interface IDatePickerState {
  hoverDate: object
  startDate: object
  endDate: object
}

export class DateRangePicker extends React.Component<IDateRangePickerProps, IDatePickerState> {
  public static defaultProps: Partial<IDateRangePickerProps> = {
    defaultEndDateToStartDate: false,
    minimumDays: 0,
    onChange: () => {},
    value: {},
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.getNextStateFromProps(props),
      hoverDate: null,
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.value, nextProps.value)) {
      this.setState(this.getNextStateFromProps(nextProps))
    }
  }

  public render() {
    const { startDate } = this.state
    const modifiers = {
      'after-hovered-start': (day) => this.isDayAfterHoveredStartDate(day),
      // before anything has been set or after both are set
      'hovered': (day) => this.isHovered(day),
      // while start date has been set, but end date has not been
      'hovered-span': (day) => this.isInHoveredSpan(day),
      'last-in-range': (day) => this.isLastInRange(day),
      'selected-end': (day) => this.isEndDate(day),
      'selected-span': (day) => this.isInSelectedSpan(day),
      // once a start date and end date have been set
      'selected-start': (day) => this.isStartDate(day),
    }

    return (
      <div
        className='flex'
        data-debug-id='dateRangePicker'
      >
        <div>
          <DayPicker
            enableOutsideDays={false}
            navPrev={<Icon icon={IconNames.CHEVRON_LEFT} />}
            navNext={<Icon icon={IconNames.CHEVRON_RIGHT} />}
            modifiers={modifiers}
            numberOfMonths={1}
            daySize={30}
            onDayMouseEnter={this.handleDayMouseEnter}
            onDayMouseLeave={this.handleDayMouseLeave}
            onDayClick={this.handleDayClick}
            initialVisibleMonth={() => moment(startDate)}
          />
          {this.renderInputFields()}
        </div>
        {this.renderShortcuts()}
      </div>
    )
  }

  private renderInputFields() {
    if (this.props.showInputFields) {
      const { defaultEndDateToStartDate, showTimeInput, value } = this.props
      return (
        <div className='c-dateRangePicker-topInputs'>
          <DateRangeInput
            autoFocus={true}
            isHorizontalLayout={true}
            defaultEndDateToStartDate={defaultEndDateToStartDate}
            onChange={this.handleChange}
            showDatePicker={false}
            showSecondaryLabels={true}
            showTimeInput={showTimeInput}
            value={value}
          />
        </div>
      )
    }
  }

  private renderShortcuts() {
    return (
      <div className='u-bumperTop u-bumperRight u-bumperBottom u-borderLeft u-innerBumperLeft'>
        <Button
          className='u-displayBlock u-bumperBottom--xs u-width100'
          buttonText='Today'
          onClick={this.handleSetDateRangeToToday}
        />
        <Button
          className='u-displayBlock u-bumperBottom--xs u-width100'
          buttonText='Yesterday'
          onClick={this.handleSetDateRangeToYesterday}
        />
        <Button
          className='u-displayBlock u-bumperBottom--xs u-width100'
          buttonText='This week'
          onClick={this.handleSetDateRangeToThisWeek}
        />
        <Button
          className='u-displayBlock u-bumperBottom--xs u-width100'
          buttonText='Last week'
          onClick={this.handleSetDateRangeToLastWeek}
        />
        <Button
          className='u-displayBlock u-bumperBottom--xs u-width100'
          buttonText='This month'
          onClick={this.handleSetDateRangeToThisMonth}
        />
        <Button
          className='u-displayBlock u-bumperBottom--xs u-width100'
          buttonText='Last month'
          onClick={this.handleSetDateRangeToLastMonth}
        />
        <Button
          className='u-displayBlock u-bumperBottom--xs u-width100'
          buttonText='This year'
          onClick={this.handleSetDateRangeToThisYear}
        />
        <Button
          className='u-displayBlock u-width100'
          buttonText='Last year'
          onClick={this.handleSetDateRangeToLastYear}
        />
      </div>
    )
  }

  private handleChange = ({ start, end }, isShortcutClicked = false) => {
    if (start) {
      start = moment(start, moment.ISO_8601, true).startOf('day')
    }
    if (end) {
      end = moment(end, moment.ISO_8601, true).endOf('day')
    }
    this.props.onChange({ start, end }, isShortcutClicked)
  }

  private handleSetDateRangeToToday = () => {
    const start = moment()
    const end = start.clone()
    this.handleChange({ start, end }, true)
  }

  private handleSetDateRangeToYesterday = () => {
    const start = moment().startOf('day').subtract(1, 'day')
    const end = start.clone()
    this.handleChange({ start, end }, true)
  }

  private handleSetDateRangeToThisWeek = () => {
    const start = moment().startOf('week')
    const end = start.clone().add(6, 'day')
    this.handleChange({ start, end }, true)
  }

  private handleSetDateRangeToLastWeek = () => {
    const start = moment().startOf('week').subtract(1, 'week')
    const end = start.clone().add(6, 'day')
    this.handleChange({ start, end }, true)
  }

  private handleSetDateRangeToThisMonth = () => {
    const start = moment().startOf('month')
    const end = start.clone().add(1, 'month').subtract(1, 'day')
    this.handleChange({ start, end }, true)
  }

  private handleSetDateRangeToLastMonth = () => {
    const start = moment().startOf('month').subtract(1, 'month')
    const end = start.clone().add(1, 'month').subtract(1, 'day')
    this.handleChange({ start, end }, true)
  }

  private handleSetDateRangeToThisYear = () => {
    const start = moment().startOf('year')
    const end = start.clone().add(1, 'year').subtract(1, 'day')
    this.handleChange({ start, end }, true)
  }

  private handleSetDateRangeToLastYear = () => {
    const start = moment().startOf('year').subtract(1, 'year')
    const end = start.clone().add(1, 'year').subtract(1, 'day')
    this.handleChange({ start, end }, true)
  }

  private getNextStateFromProps(props): any {
    const { value } = props
    let startDate = _.get(value, 'start')
    let endDate = _.get(value, 'end')
    startDate = startDate ? moment(startDate, moment.ISO_8601, true) : undefined
    endDate = endDate ? moment(endDate, moment.ISO_8601, true) : undefined
    return { startDate, endDate }
  }

  private handleStartDateChange = (startDate) => {
    const { defaultEndDateToStartDate, value } = this.props
    const defaultEndDate = defaultEndDateToStartDate ? startDate : undefined
    const endDate = _.get(value, 'end', defaultEndDate)
    const result = { start: startDate, end: endDate }
    this.handleChange(result)
  }

  private handleEndDateChange = (endDate) => {
    const { defaultEndDateToStartDate, value } = this.props
    const defaultStartDate = defaultEndDateToStartDate ? endDate : undefined
    const startDate = _.get(value, 'end', defaultStartDate)
    const result = { start: startDate, end: endDate }
    this.handleChange(result)
  }

  private handleDayClick = (day, modifiers, e) => {
    if (e) {
      e.preventDefault()
    }
    const { defaultEndDateToStartDate, minimumDays } = this.props
    let { startDate, endDate } = this.state
    // case 1: start date and end date exist
    if (startDate && endDate) {
      startDate = day
      endDate = null
    } else if (startDate) {
    // case 2: only start date exist
      if (minimumDays === 0 && isSameDay(day, startDate)) {
        endDate = startDate
      } else if (minimumDays > 0 && isSameDay(day, startDate)) {
        // if minimumDays > 0 and user click on same day, cancel out
        startDate = null
      } else if (isInclusivelyAfterDay(day, startDate)) {
        endDate = day
      } else {
        endDate = startDate
        startDate = day
      }
    } else {
    // case 3: start and end date don't exist
      startDate = day
      if (defaultEndDateToStartDate) {
        endDate = day
      }
    }
    this.handleChange({ start: startDate, end: endDate })
  }

  private handleDayMouseEnter = (day) => {
    this.setState({
      hoverDate: day,
    })
  }

  private handleDayMouseLeave = () => {
    this.setState({
      hoverDate: null,
    })
  }

  private isDayAfterHoveredStartDate(day) {
    const { startDate, endDate } = this.state
    const { minimumDays } = this.props
    const { hoverDate } = this.state
    return !!startDate && !endDate && isNextDay(hoverDate, day) && minimumDays > 0 &&
      isSameDay(hoverDate, startDate)
  }

  private isEndDate(day) {
    return isSameDay(day, this.state.endDate)
  }

  private isHovered(day) {
    return isSameDay(day, this.state.hoverDate)
  }

  private isInHoveredSpan(day) {
    const { startDate, endDate } = this.state
    const { hoverDate } = this.state
    const isForwardRange = !!startDate && !endDate &&
      (day.isBetween(startDate, hoverDate) ||
        isSameDay(hoverDate, day))
    const isBackwardRange = !!endDate && !startDate &&
      (day.isBetween(hoverDate, endDate) ||
        isSameDay(hoverDate, day))

    return isForwardRange || isBackwardRange
  }

  private isInSelectedSpan(day) {
    const { startDate, endDate } = this.state
    if (!startDate || !endDate) {
      return false
    }
    return day.isBetween(startDate, endDate)
  }

  private isLastInRange(day) {
    return this.isInSelectedSpan(day) && isNextDay(day, this.state.endDate)
  }

  private isStartDate(day) {
    return isSameDay(day, this.state.startDate)
  }
}
