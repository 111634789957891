import React from 'react'

export type PropMappingFunction = () => any

export type PropMappingStrategy = (
  schema: any,
  key: string,
  value: any
) => PropMappingFunction | undefined

export interface IUIPropMappingContext {
  /**
   * A platform-specific function that takes property info and may return a
   * function that returns a new property value.
   */
  getPropMappingStrategy?: PropMappingStrategy
}

export const UIPropMappingContext = React.createContext<IUIPropMappingContext>({
  getPropMappingStrategy: undefined,
})
