import { Button as BlueprintButton, Intent } from '@blueprintjs/core'
import classNames from 'classnames'
import _ from 'lodash'
import Promise from 'bluebird'
import React, { useEffect, useRef, useState } from 'react'
import { Classes } from '@blueprintjs/core'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/button/_button-group.scss'
import 'browser/components/atomic-elements/atoms/button/_button.scss'
import { translateString } from 'shared-libs/helpers/utils'
import { FramesManager } from 'shared-libs/components/view/frames-manager'

/**
 * @uiComponent
 */
export interface IButtonProps extends IBaseProps {
  buttonText?: string
  intent?: Intent
  isDisabled?: boolean
  isActive?: boolean
  isLoading?: boolean
  onClick?: (event: any) => void
  onClickProps?: any
  onMouseDown?: (event: any) => void
  size?: string
  tabIndex?: number
  frames?: FramesManager
}

export const Button: React.FC<IButtonProps> = (props) => {
  const [ isLoading, setIsLoading ] = useState(props.isLoading)
  const promise = useRef<Promise<any>>()
  const { buttonText, children, className, intent, isActive, isDisabled,
    onMouseDown, size, tabIndex, onClick, onClickProps, style, frames
  } = props
  const translationTable = frames?.getContext('translationTable')
  const sizeClass = _.isEmpty(size) ? '' : `${Classes.getClassNamespace()}-${size}`
  const translatedText = translateString(buttonText, translationTable)

  useEffect(() => {
    setIsLoading(props.isLoading)
  }, [props.isLoading])

  const handleClick = (event) => {
    const result: any = onClick?.(onClickProps ? onClickProps : event)
    if (!isLoading && result?.then) {
      setIsLoading(true)
      promise.current = result
        .then(() => setIsLoading(false))
        .catch((e) => setIsLoading(false))
    }
  }

  return (
    <BlueprintButton
      className={classNames(sizeClass, className, {
        [Classes.ACTIVE]: isActive,
      })}
      data-debug-id={props['data-debug-id']}
      disabled={isDisabled}
      intent={intent}
      loading={isLoading}
      onClick={handleClick}
      onMouseDown={onMouseDown}
      type='button'
      tabIndex={tabIndex}
      style={style}
    >
      {translatedText || children}
    </BlueprintButton>
  )
}

Button.defaultProps = {
  className: '',
  isDisabled: false,
}
