import _ from 'lodash'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'
import { IInputProps, Input } from 'browser/components/atomic-elements/atoms/input/input'

// TODO(Peter): it is bad to require that we have entity and frames available
// in a component that is in atomic element.
/**
 * @uiComponent
 */
interface ISequenceInputProps extends IInputProps {
  onChange: (value: string, silenUpdate?: boolean) => void
  entity: Entity
  frames: any
  value: string
}

export class SequenceInput extends React.Component<ISequenceInputProps, any> {
  private input: Input

  public UNSAFE_componentWillMount() {
    const { entity, frames, isDisabled, onChange, value } = this.props
    // only fetch sequence if value is empty and is not disabled
    if (isDisabled || !_.isEmpty(value)) { return }
    const path = frames.getContext('valuePath').join('.')
    const schemaId = _.get(_.last(entity.schemas), 'uniqueId')
    apis.getNextSequence(schemaId, path).then((json: any) => {
      const { range } = json
      if (_.isNumber(range[0])) {
        const sequenceNumber = String(range[0])
        onChange(sequenceNumber, true)
      }
    })
  }

  public focus() {
    this.input.focus()
  }

  public render() {
    return (
      <Input
        {...this.props as IInputProps}
        ref={(ref) => { this.input = ref }}
      />
    )
  }
}
