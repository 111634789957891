import _ from "lodash"
import apis from "../models/apis"
import Bluebird, { Promise } from 'bluebird'

class EntitySubscriptionManagerImpl {
  // entity UniqueId: Number
  private subscriptionCounts = {}

  public addEntityUpdateSubscriptions(entityIds: string[]): Bluebird<void> {
    const newSubscriptions = []
    _.forEach(entityIds, (entityId) => {
      const count = _.get(this.subscriptionCounts, entityId, 0) + 1
      this.subscriptionCounts[entityId] = count
      if (count === 1) {
        newSubscriptions.push(entityId)
      }
    })
    if (newSubscriptions.length > 0) {
      return apis.subscribeToEntityUpdates(newSubscriptions)
    } else {
      return Promise.resolve()
    }
  }

  public removeEntityUpdateSubscriptions(entityIds: string[]): Bluebird<void> {
    const removeSubscriptions = []
    _.forEach(entityIds, (entityId) => {
      if (_.has(this.subscriptionCounts, entityId)) {
        this.subscriptionCounts[entityId]--
        if (this.subscriptionCounts[entityId] <= 0) {
          removeSubscriptions.push(entityId)
          delete this.subscriptionCounts[entityId]
        }
      }
    })
    if (removeSubscriptions.length > 0) {
      return apis.unsubscribeFromEntityUpdates(removeSubscriptions)
    } else {
      return Promise.resolve()
    }
  }

  /**
   * Subscribes to new entity ids not found in oldEntityIds, and unsubscribes from
   * oldEntityIds not found in newEntityIds.
   * @param oldEntityIds The subscriptions we have
   * @param newEntityIds The subscriptions we want
   */
  public diffEntitySubscriptions(oldEntityIds: string[] = [], newEntityIds: string[] = []): Bluebird<[void,void]> {
    const uniqOldEntityIds = _.uniq(oldEntityIds)
    const uniqNewEntityIds = _.uniq(newEntityIds)
    return Promise.all([
      this.removeEntityUpdateSubscriptions(_.difference(uniqOldEntityIds, uniqNewEntityIds)),
      this.addEntityUpdateSubscriptions(_.difference(uniqNewEntityIds, uniqOldEntityIds))
    ])
  }
}

// Singleton instance
// eslint-disable-next-line @typescript-eslint/naming-convention
export const EntitySubscriptionManager = new EntitySubscriptionManagerImpl()