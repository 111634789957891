import _ from 'lodash'
import React from 'react'

import { InlineEditable, InlineEditableProps } from 'browser/components/atomic-elements/molecules/inline-editable'
import { ILabelFormGroupProps, LabelFormGroup } from 'browser/components/atomic-elements/molecules/label-form-group/label-form-group'

type FieldProps = Partial<ILabelFormGroupProps> & {
  isEditableInline?: boolean
  isStatic?: boolean
  onChange?: (value: any, option?: any) => void
  onRemove?: () => void
  onSave?: () => void
  size?: string
  validate?: (value: any) => void
  value?: any
  ref?: any // hack: refactor LabelFieldFactory to use forwardRef?
}

export default function LabelFieldFactory<T>({
  defaultInlineEditableProps,
  defaultLabelFormGroupProps,
  InputComponent,
  InputSheetComponent,
}: {
  defaultInlineEditableProps?: Partial<InlineEditableProps>
  defaultLabelFormGroupProps?: Partial<ILabelFormGroupProps>
  InputComponent: React.ComponentType<T & FieldProps>
  InputSheetComponent?: any
}): React.ComponentType<T & FieldProps> {
  return class extends React.Component<T & FieldProps> {
    private input

    public render() {
      // onChange and value are injected into the AtomComponent by
      // LabelFormGroup. We omit it from the props
      const { isEditableInline } = this.props
      if (!isEditableInline) {
        return this.renderLabeledFormGroup()
      }
      return (
        <InlineEditable
          {...defaultInlineEditableProps}
          inlineEditSheet={this.renderInputSheet()}
          isStatic={this.props.isStatic}
          onChange={this.props.onChange}
          onDelete={this.props.onRemove}
          onSave={this.props.onSave}
          size={this.props.size}
          validate={this.props.validate}
          value={this.props.value}
        >
          {this.renderLabeledFormGroup()}
        </InlineEditable>
      )
    }

    public getElement = () => this.input

    private renderInputSheet() {
      if (InputSheetComponent) {
        return (<InputSheetComponent {...this.props} />)
      }
    }

    private renderLabeledFormGroup() {
      const atomProps = _.omit(this.props, ['className', 'onChange', 'value'])
      return (
        <LabelFormGroup
          {...defaultLabelFormGroupProps}
          {...this.props}
        >
          <InputComponent {...atomProps} extraRef={(ref) => { this.input = ref }} />
        </LabelFormGroup>
      )
    }
  }
}
