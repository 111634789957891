import Immutable from 'immutable'
import _ from 'lodash'
import React from 'react'

import { evaluateExpressionWithScopes } from 'shared-libs/helpers/evaluation'
import { CustomFormulas } from 'shared-libs/helpers/formulas'

/**
 * @uiComponent
 */
interface IUIConditionalProps {
  frames: any
  conditionals: any[]
  actionHooks?: { [id: string]: () => void }
  timeoutMs?: number
}

interface IUIConditionalState {
  isTimeout: boolean
}

export class UIConditional extends React.Component<IUIConditionalProps, IUIConditionalState> {
  private timeoutId: any
  private lastActionRun: any

  constructor(props: IUIConditionalProps) {
    super(props)

    this.state = {
      isTimeout: false
    }
  }

  public componentDidMount() {
    const { timeoutMs } = this.props

    if (timeoutMs) {
      this.timeoutId = setTimeout(() => {
        this.setState({ isTimeout: true })
      }, timeoutMs)
    }
  }

  public componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  public render() {
    const { frames, conditionals, actionHooks = {} } = this.props
    const { isTimeout } = this.state

    const framesActionHooks = this.props?.frames?.getValue('actionHooks') || {}
    const framesActions = this.props?.frames?.getActions() || {}

    const formulaContext = {
      ...framesActionHooks,
      ...framesActions,
      ...actionHooks,
      ...CustomFormulas,
      isTimeout
    }

    for (let i = 0; i < conditionals.length; ++i) {
      const conditional = conditionals[i]
      if (!conditional.test) {
        const uiSchema = conditional
        return this.renderConditionalBlock(uiSchema, i)
      } else if (evaluateExpressionWithScopes(frames, conditional.test, formulaContext)) {
        const action = conditional && conditional.action

        if (!_.isEmpty(action) && action !== this.lastActionRun) {
          this.lastActionRun = action

          evaluateExpressionWithScopes(frames, action, formulaContext)
        }

        const uiSchema = _.assign({}, conditional)
        delete uiSchema.test
        delete uiSchema.action

        return this.renderConditionalBlock(uiSchema, i)
      }
    }
    return false
  }

  private renderConditionalBlock(uiSchema, index) {
    const { frames } = this.props
    const renderer = frames.getContext('renderer')
    const uiSchemaFragment = ['conditionals', index]
    const uiSchemaPath = frames.getContext('uiSchemaPath').concat(uiSchemaFragment)
    const newFrames = renderer.createChildFrame(frames, { uiSchema, uiSchemaPath })
    // NOTE: we should forward any props that is intended for the component
    // wrapped by the conditional block. E.g. we should forward the onRemove
    // that is injected to the list row
    const defaultProps = _.omit(this.props, ['conditionals'])
    return renderer.createElementFromFrame(newFrames, defaultProps)
  }
}
