import _ from 'lodash'
import React from 'react'

import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import 'browser/components/atomic-elements/organisms/filters/geolocation-filter-item/_geolocation-filter-item.scss'
import { Item, ItemProps } from '../item'
import { GeolocationFilterPopover, IGeolocationFilterPopoverProps } from './popover'

interface IGeolocationFilterItemProps extends IGeolocationFilterPopoverProps {
  filter: any
  isButton: boolean
  isClearable: boolean
  showFilterValue: boolean
  tetherOptions: object
}

export class GeolocationFilterItem extends React.PureComponent<IGeolocationFilterItemProps, any> {
  public render() {
    const {
      filter,
      className,
      isButton,
      isClearable,
      onClear,
      showFilterValue,
      tetherOptions,
    } = this.props
    return (
      <TetherTarget
        automaticAdjustOffset={true}
        openOnClick={isClearable}
        tetherOptions={tetherOptions}
        tethered={<GeolocationFilterPopover {...this.props} />}
      >
        <Item
          className={className}
          isButton={isButton}
          isClearable={isClearable}
          label={filter.label}
          onClear={onClear}
          showFilterValue={showFilterValue}
        >
          {this.getFilterValue()}
        </Item>
      </TetherTarget>
    )
  }

  private getFilterValue() {
    const { filter } = this.props
    const negationText = filter.isNegation ? "Excluding " : ""

    if (filter.type === 'prefix' || filter.type === 'geoDistance'
        || filter.filterType === 'geoDistance') {
      return `${negationText}${filter.displayName}`
    }
    const values = _.map(_.get(filter, 'values', []), (value: any) => {
      return _.isObject(value) ? value.displayName : value
    })
    return `${negationText}${values.join(', ')}`
  }
}
