import { Entity } from "../entity"

const schema = {
  "$schema": "http://json-schema.org/draft-04/schema#",
  "metadata": {
    "namespace": "user"
  },
  "properties": {
    "user": {
      "properties": {
        "identity": {
          "label": "Mobile Phone or Email",
          "type": "string"
        },
        "companyCode": {
          "label": "Vector Code",
          "type": "string"
        },
        "phoneNumber": {
          "label": "Phone Number",
          "type": "string"
        },
        "email": {
          "label": "Email Address",
          "type": "string"
        },
        "firstName": {
          "isFilterable": true,
          "label": "First Name",
          "type": "string"
        },
        "lastName": {
          "isFilterable": true,
          "label": "Last Name",
          "type": "string"
        },
        "password": {
          "label": "Password",
          "type": "string"
        },
        "mcNumber": {
          "label": "MC Number",
          "type": "string"
        },
        "orgName": {
          "label": "Organization Name",
          "type": "string"
        }
      },
      "required": [],
      "type": "object"
    },
    "type": "object"
  },
  "status": {
    "state": "idle"
  },
  "type": "object",
  "uiSchema": {
    "web": {
      "carrierPortalRegistrationForm": {
        "children": [
          {
            "data-debug-id": "OrgName",
            "type": "ui:inputField",
            "isHorizontalLayout": false,
            "className": "u-bumperBottom c-formGroup--inputHasBorder",
            "labelProps": {
              "className": "c-label--heading"
            },
            "size": "lg",
            "value": "user.orgName"
          },
          {
            "data-debug-id": "mcNumber",
            "type": "ui:inputField",
            "isHorizontalLayout": false,
            "className": "u-bumperBottom c-formGroup--inputHasBorder",
            "labelProps": {
              "className": "c-label--heading"
            },
            "size": "lg",
            "value": "user.mcNumber"
          },
          {
            "data-debug-id": "identity",
            "type": "ui:inputField",
            "isHorizontalLayout": false,
            "className": "u-bumperBottom c-formGroup--inputHasBorder",
            "labelProps": {
              "className": "c-label--heading"
            },
            "mapStateToProps": {
              "isDisabled": "isIdentityDisabled"
            },
            "size": "lg",
            "value": "user.email"
          },
          {
            "children": [
              {
                "children": [
                  {
                    "data-debug-id": "firstName",
                    "type": "ui:inputField",
                    "size": "lg",
                    "isHorizontalLayout": false,
                    "className": "u-bumperBottom c-formGroup--inputHasBorder",
                    "labelProps": {
                      "className": "c-label--heading"
                    },
                    "value": "user.firstName"
                  }
                ],
                "className": "col-xs-6",
                "type": "ui:view"
              },
              {
                "children": [
                  {
                    "data-debug-id": "lastName",
                    "type": "ui:inputField",
                    "size": "lg",
                    "isHorizontalLayout": false,
                    "className": "u-bumperBottom c-formGroup--inputHasBorder",
                    "labelProps": {
                      "className": "c-label--heading"
                    },
                    "value": "user.lastName"
                  }
                ],
                "className": "col-xs-6",
                "type": "ui:view"
              }
            ],
            "className": "row",
            "type": "ui:view"
          },
          {
            "className": "c-passwordField u-bumperBottom c-formGroup--inputHasBorder",
            "data-debug-id": "password",
            "type": "ui:inputField",
            "isHorizontalLayout": false,
            "labelProps": {
              "className": "c-label--heading"
            },
            "helpText": "Must be at least 6 characters.",
            "inputType": "password",
            "mapActionsToProps": {
              "onEnterPressed": "handleRegister"
            },
            "size": "lg",
            "value": "user.password"
          },
          {
            "className": "pt-intent-primary pt-fill",
            "data-debug-id": "registerButton",
            "type": "ui:button",
            "size": "large",
            "buttonText": "Continue",
            "mapActionsToProps": {
              "onClick": "handleRegister"
            },
            "mapStateToProps": {
              "isLoading": "isLoading"
            }
          }
        ],
        "type": "ui:view"
      },
      "registrationForm": {
        "children": [
          {
            "type": "ui:inputField",
            "isHorizontalLayout": false,
            "className": "u-bumperBottom c-formGroup--inputHasBorder",
            "labelProps": {
              "className": "c-label--heading"
            },
            "size": "lg",
            "value": "user.companyCode"
          },
          {
            "type": "ui:inputField",
            "isHorizontalLayout": false,
            "className": "u-bumperBottom c-formGroup--inputHasBorder",
            "labelProps": {
              "className": "c-label--heading"
            },
            "size": "lg",
            "value": "user.phoneNumber"
          },
          {
            "type": "ui:inputField",
            "isHorizontalLayout": false,
            "className": "u-bumperBottom c-formGroup--inputHasBorder",
            "labelProps": {
              "className": "c-label--heading"
            },
            "size": "lg",
            "value": "user.email"
          },
          {
            "children": [
              {
                "children": [
                  {
                    "data-debug-id": "firstName",
                    "type": "ui:inputField",
                    "size": "lg",
                    "isHorizontalLayout": false,
                    "className": "u-bumperBottom c-formGroup--inputHasBorder",
                    "labelProps": {
                      "className": "c-label--heading"
                    },
                    "value": "user.firstName"
                  }
                ],
                "className": "col-xs-6",
                "type": "ui:view"
              },
              {
                "children": [
                  {
                    "data-debug-id": "lastName",
                    "type": "ui:inputField",
                    "size": "lg",
                    "isHorizontalLayout": false,
                    "className": "u-bumperBottom c-formGroup--inputHasBorder",
                    "labelProps": {
                      "className": "c-label--heading"
                    },
                    "value": "user.lastName"
                  }
                ],
                "className": "col-xs-6",
                "type": "ui:view"
              }
            ],
            "className": "row",
            "type": "ui:view"
          },
          {
            "className": "c-passwordField u-bumperBottom c-formGroup--inputHasBorder",
            "data-debug-id": "password",
            "type": "ui:inputField",
            "isHorizontalLayout": false,
            "labelProps": {
              "className": "c-label--heading"
            },
            "helpText": "Must be at least 6 characters.",
            "inputType": "password",
            "mapActionsToProps": {
              "onEnterPressed": "handleRegister"
            },
            "size": "lg",
            "value": "user.password"
          },
          {
            "className": "pt-intent-primary pt-fill",
            "data-debug-id": "registerButton",
            "type": "ui:button",
            "size": "large",
            "buttonText": "Continue",
            "mapActionsToProps": {
              "onClick": "handleRegister"
            },
            "mapStateToProps": {
              "isLoading": "isLoading"
            }
          }
        ],
        "type": "ui:view"
      }
    }
  },
  "uniqueId": "255eff87-94b6-43bb-a028-ef57c799bec6"
}

export function getUserSchema(apis) {
  return new Entity(schema, apis)
}
