import { IAggregatedInboxActivity, IInboxActivity, IInboxEntry, Verb } from 'browser/contexts/inbox/interface'
import { FlatActivity, NotificationActivity } from 'getstream'
import _, { Dictionary, isString } from 'lodash'
import { Entity } from 'shared-libs/models/entity'
import apis from '../models/apis'

export function getObjectText({ object }: IInboxEntry) {
  if (!object) {
    return ''
  } else if (isString(object)) {
    return object
  } else {
    return `${object.displayName} (${object.entityType})`
  }
}

export function getActorText(entry: IInboxEntry) {
  const actorIds = _.map(entry.actors, (actor) => {
    if (!actor) {
      return ''
    } else if (isString(actor)) {
      return actor
    } else {
      return actor.get('uniqueId')
    }
  })
  const uniqueActors = _.uniq(actorIds)
  if (uniqueActors.length === 1) {
    const firstActor = entry.actors[0]
    if (!firstActor) {
      return 'A user'
    } else if (isString(firstActor)) {
      return firstActor
    } else {
      return firstActor.displayName
    }
  } else {
    return `${uniqueActors.length} users`
  }
}

export function getActionText(entry: IInboxEntry) {
  switch(entry.verb) {
    case Verb.COMMENT:
      return 'commented on'
    case Verb.SHARE:
      return 'shared'
    case Verb.UPDATE:
    default:
      return 'updated'
  }
}

export async function getInboxEntriesFromAggregatedActivities(activities: NotificationActivity[]): Promise<IInboxEntry[]> {
  const hydratedData = await hydrateGroupedActivities(activities)
  return _.map(hydratedData, (group) => {
    const inboxEntry: IInboxEntry = {
      actors: [],
      object: group.activities[0].object,
      targets: [],
      read: group.is_read,
      date: group.updated_at,
      verb: group.verb as Verb,
      groupId: group.id,
      group: group.group,
    }
    _.forEach(group.activities, (activity) => {
      inboxEntry.actors.push(activity.actor)
      inboxEntry.targets.push(activity.object)
    })
    return inboxEntry
  })  
}

async function hydrateGroupedActivities(results: NotificationActivity[]): Promise<IAggregatedInboxActivity[]> {
  const ids = gatherGroupedIds(results)
  const store = apis.getStore()
  const entities = await store.getOrFetchRecords(ids)
  const entityMap = _.keyBy(entities, 'uniqueId')
  return replaceGroupedIds(results, entityMap)
}

function gatherIds(activities: FlatActivity[]) {
  const allIds = _.reduce(activities, (prev, current) => {
    prev.push(getIdFromDescriptor(current.actor))
    prev.push(getIdFromDescriptor(current.object as string))
    prev.push(getIdFromDescriptor(current.target))
    return prev
  }, [])
  const noEmptyValues = _.filter(allIds, (id) => !!id)
  return _.uniq(noEmptyValues)
}

function gatherGroupedIds(activities: NotificationActivity[]) {
  const allIds = _.reduce(activities, (prev, current) => {
    return [...prev, ...gatherIds(current.activities)]
  }, [])
  return _.uniq(allIds)
}

function getIdFromDescriptor(descriptor: string) {
  return _.split(descriptor, ':')[1]
}

function replaceGroupedIds(activities: NotificationActivity[], entities: Dictionary<Entity>): IAggregatedInboxActivity[] {
  return _.map(activities, (activity) => {
    return {
      ...activity,
      activities: replaceIds(activity.activities, entities)
    }
  })
}

function replaceIds(activities: FlatActivity[], entities: Dictionary<Entity>): IInboxActivity[] {
  return _.map(activities, (activity) => {
    return {
      ...activity,
      actor: denormalizeDescriptor(activity.actor, entities),
      target: denormalizeDescriptor(activity.target, entities),
      object: denormalizeDescriptor(activity.object as string, entities),
    }
  })
}

function denormalizeDescriptor(descriptor: string, entities: Dictionary<Entity>) {
  if (!descriptor) {
    return undefined
  }
  const id = getIdFromDescriptor(descriptor)
  return entities[id] || id
}

export function getLinkFromEntry(entry: IInboxEntry): string {
  if (!entry.object) {
    return undefined
  }
  const id = isString(entry.object) ? entry.object : entry.object.get('uniqueId')
  const baseUrl = `/entity/${id}`
  switch (entry.verb) {
    case Verb.COMMENT: 
      return `${baseUrl}?tab=activity`
    default: 
      return baseUrl
  }
}
