import React, { Component } from 'react'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import _ from 'lodash'
import BPromise from 'bluebird'
import apis from 'browser/app/models/apis'
import { Logger } from 'browser/apis/logging'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import printJS from 'print-js'
import { getAuthorization } from 'browser/app/utils/auth'
import { ErrorModal } from 'browser/components/atomic-elements/organisms/error-modal'

/**
 * @uiComponent
 */
interface IPrintEntityButtonProps extends IBaseProps {
  text?: string
  /**
   * Edge to printable entity.
   */
  value: { entityId: string }

  style?: React.CSSProperties
}

interface IPrintEntityButtonState {
  isLoading?: boolean
  isWaitingForAsset?: boolean
  record?: any
  pdfUri?: string
}

const objectUrlCache: Record<string, string> = {}

export class PrintEntityButton extends Component<IPrintEntityButtonProps, IPrintEntityButtonState> {
  public static defaultProps: Partial<IPrintEntityButtonProps> = {
    text: 'Print',
    className: classNames(Classes.BUTTON, Classes.INTENT_PRIMARY, Classes.LARGE, 'center'),
  }

  private fetchUriPromise: BPromise<void>

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isWaitingForAsset: true,
    }
  }

  componentDidMount() {
    const { value } = this.props
    const { entityId } = value

    this.setState({ isLoading: true })

    this.fetchUriPromise = apis.getStore()
      .findRecord(entityId)
      .then((record) => {
        if (record) {
          return apis.getPrintableDownloadUri(entityId).then((data) => {
            this.setState({
              isLoading: false,
              isWaitingForAsset: false,
              pdfUri: data.uri,
            })
          })
        }
      })
      .catch((error) => {
        this.showError(error)
      })
  }

  componentWillUnmount(): void {
    this.fetchUriPromise?.cancel?.()
  }

  public render() {
    const { text, className, style } = this.props
    const { isLoading, isWaitingForAsset } = this.state

    if (isLoading) {
      return (
        <div className="grid-block">
          <LoadingSpinner />
          {isWaitingForAsset && (
            <div className="c-workflowPrintButton">
              Your print is being prepared, please<br/>
              wait as this may take a minute...
            </div>
          )}
        </div>
      )
    }
    return (
      <>
        <a
          style={style}
          className={className}
          onClick={this.handleClick}
        >
          {text}
        </a>
      </>
    )
  }

  private handleClick = () => {
    const { value } = this.props
    const { entityId } = value
    const { pdfUri } = this.state

    Logger.logEvent('Search and Print', { entityId })

    // clean up any previous object urls
    _.forEach(objectUrlCache, (objectUrl, pdfUriKey) => {
      URL.revokeObjectURL(objectUrl)
      delete objectUrlCache[pdfUriKey]
    })

    this.downloadPdf(pdfUri)
      .then(this.printPdf)
      .catch((error) => {
        this.showError(error)
      })
  }

  private showError(error) {
    let errorText = error?.message
    if (!errorText) {
      if (typeof error === 'string') {
        errorText = error
      } else {
        errorText = 'There was an error attempting to print this document.'
      }
    }
    ErrorModal.open({
      errorTitle: 'Error',
      errorText,
    })
  }

  private downloadPdf(uri): Promise<string> {
    const signUri = uri.replace('auth', 'sign')
    return fetch(signUri, {
      headers: {
        Authorization: getAuthorization(),
      },
    }).then((res) => res.json())
      .then((json) => {
        return fetch(json.url)
      })
      .then((res) => res.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob)
        objectUrlCache[uri] = blobUrl
        return blobUrl
      })
  }

  private printPdf = (blobUri) => {
    printJS({
      printable: blobUri,
      type: 'pdf',
      onLoadingStart: () => {
        this.setState({ isLoading: true })
      },
      onLoadingEnd: () => {
        this.setState({ isLoading: false })
      },
      onError: (error) => {
        this.setState({ isLoading: false })
        this.showError(error)
      },
    })
  }
}
