import _ from 'lodash'

export function checkDigit(...params: any[]): boolean {
  const data = params[0]
  const type = params[1]
  if (type === 'SEFL_MOD11') {
    return seflMod11(data)
  }
  return true
}

function seflMod11(proNumberStr: string | number): boolean {
  let data = proNumberStr
  if (typeof data !== 'string') {
    data = `${data}`
  }
  const digits = _.map(data, (c) => parseInt(c))
  const n = data.length
  if (n < 8 || n > 9) {
    return true
  }

  let sum = 0
  for (let i = 0; i < 8; i++) {
    sum += digits[i] * (10 - i - 1)
  }
  const sumDiv11 = Math.floor(sum / 11)
  const remainder = sum - sumDiv11 * 11
  let checkdigit = 11 - remainder
  if (checkdigit > 9) {
    checkdigit -= 10
  }
  return checkdigit === digits[n - 1]
}
