/**
 * Just a central logging location, a stub for any future fancy logger
 */
import * as log from 'loglevel'

export function consoleLog(...args) {
  // inject timestamp when no debugger attach
  // const timestamp: string = new Date().toISOString();
  // console.log(`[${timestamp}]` , ...args)
  console.log(...args)
}

export function debug(...args) {
  consoleLog(...args)
  log.debug(...args)
}

export function info(...args) {
  consoleLog(...args)
  log.info(...args)
}

export function warn(...args) {
  consoleLog(...args)
  log.warn( ...args)
}

export function error(...args) {
  consoleLog(...args)
  log.error( ...args)
}

export function getLogger(name) {
  const ret = log.getLogger(name)
  ret.setLevel(log.levels.INFO)
  return ret
}

export function setLogger(logger, newLogger: (message: string, args: any) => void) {
  const origFactory = logger.methodFactory
  logger.methodFactory = function(methodName, logLevel, loggerName) {
    const origMethod = origFactory(methodName, logLevel, loggerName)
    return function(message, ...args) {
      try {
        origMethod?.(message, args)
        newLogger?.(message, args?.[0])
      } catch (e) {
        console.error(`sematext log failed: ${e.stack}`)
      }
    }
  }
  // necessary to refresh the log method
  logger.setLevel(logger.getLevel())
}
