import _ from 'lodash'
import React from 'react'

import { ISelectProps } from 'browser/components/atomic-elements/atoms/select'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'

const DEFAULT_ORDERS = [
  {
    path: 'precomputation.displayName',
    type: 'ascending',
  },
]

/**
 * @uiComponent
 */
interface IDocumentsFieldProps extends ISelectProps {
  entity: any
  entityType: any
  value: any
}

interface IDocumentsFieldState {
  options: any[]
}

export class DocumentsField extends React.Component<IDocumentsFieldProps, IDocumentsFieldState> {
  private select: any
  private entityDataSet: any

  constructor(props) {
    super(props)
    this.state = {
      options: [],
    }
    this.entityDataSet = new EntityDataSource({
      entityType: props.entityType,
      orders: DEFAULT_ORDERS,
    }).setOnChange(this.handleOnDataSetChange)
  }

  public componentDidMount() {
    this.entityDataSet.find()

  }

  public componentWillUnmount() {
    this.entityDataSet.dispose()
  }

  public render() {
    const { options } = this.state
    const { value } = this.props

    const handleRef = (ref) => this.select = ref
    // TODO(DAVID): This does not paginate through the different options.
    return (
      <SelectField
        {...this.props}
        value={value}
        isCreatable={false}
        multi={true}
        noResultsText='Document type not found'
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onSelectRef={handleRef}
        options={options}
      />
    )
  }

  private handleOnDataSetChange = (content) => {
    const entities = _.map(content, (entity) => entity.data)
    const edges = _.map(entities, (entity) => {
      return { displayName: entity.displayName, entityId: entity.uniqueId }
    })
    this.setState({ options: edges })
  }

  private handleChange = (value, options) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(options)
    }
  }

  private handleInputChange = (inputValue) => {
    const { onInputChange } = this.props
    if (onInputChange) {
      onInputChange(inputValue)
    }
  }
}
