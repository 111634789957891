import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import apis from 'browser/app/models/apis'
import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { ViewRenderer } from 'shared-libs/components/view/renderer'

interface IAllResultsMasterPanelProps extends IBaseProps {
  onSelectEntity: (...any) => void
  onSelectTab: (tabIndex: number) => void
  searchQuery: string
  searchResults: any[]
  selection: any[]
  dataSet: any
  sectionPriorities: any
}

export class AllResultsMasterPanel extends React.Component<IAllResultsMasterPanelProps, any> {
  public getSearchResultSchema(schemaId) {
    const schema = apis.getStore().getRecord(schemaId)
    if (!schema) {
      return this.getSearchResultSchema('/1.0/entities/metadata/entity.json')
    }
    return schema
  }

  public render() {
    const { searchResults, sectionPriorities } = this.props

    if (_.isEmpty(searchResults)) {
      return this.renderEmptyState()
    }

    const sortedSearchResults = _.sortBy(searchResults, (group, _) => sectionPriorities[group.data.entityId])

    // Note we don't use grid-block shrink here because it then collapses
    return (
      <div className='c-searchResultsMasterPanel-container u-noPrint'>
        {_.map(sortedSearchResults, (group, index) => {
          return this.renderSearchResultTable(group, index + 1)
        })}
      </div>
    )
  }

  private renderEmptyState() {
    const { searchQuery } = this.props
    return (
      <div className='grid-block vertical align-center align-verticalCenter'>
        <div className='c-emptyTableMessage u-textLeft c-emptyTableMessage--transparent'>
          <p className='c-lead mb3'>
            Your search - {searchQuery} - did not match any entities.
          </p>
          <p className='b'>Suggestions:</p>
          <p>
            Make sure all words are spelled correctly.<br />
            Try different keywords.<br />
            Try more general keywords.<br />
            Try fewer keywords.
          </p>
        </div>
      </div>
    )
  }

  private renderSearchResultTable(group, tabIndex) {
    const { onSelectEntity, onSelectTab, selection, dataSet } = this.props
    const entitySchema = this.getSearchResultSchema(group.data.entityId)
    if (_.isEmpty(group.children)) {
      return null
    }
    const entitySample: any = _.first(group.children)
    const uiSchema = entitySample.resolveSubschemaByPath('uiSchema.web.searchResults').schema
    const rendererAction = {
      handleOnSearchResultClicked: onSelectEntity,
    }
    const rendererState = {
      entitySchema,
      searchResults: group.children.map(x => ({data: x })),
      selection,
      dataSet,
    }

    const handleShowMore = () => {
      onSelectTab(tabIndex)
    }

    return (
      <div
        key={group.data.entityId}
        className='c-searchResultsMasterPanel'
      >
        <ViewRenderer
          actions={rendererAction}
          componentsMap={ComponentsMap}
          state={rendererState}
          schema={uiSchema}
          api={apis}
        />
        <div>
          <Button
            className={classNames('u-bumperLeft--xs', Classes.MINIMAL)}
            onClick={handleShowMore}
          >
            Show More
          </Button>
        </div>
      </div>
    )
  }
}
