import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/error-block/_error-block.scss'

/**
 * @uiComponent
 */
interface IErrorBlockProps extends IBaseProps {
  errorText: any
  isWarning?: boolean
}

export class ErrorBlock extends React.Component<IErrorBlockProps, any> {
  public render() {
    const { errorText, className, isWarning } = this.props
    if (!errorText) {
      return null
    }

    const errorClass = !isWarning ? 'c-errorBlock' : 'c-warningBlock'

    return (
      <div className={classNames(errorClass, className)}>
        {errorText}
      </div>
    )
  }
}
