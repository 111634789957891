import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { arrayMove } from 'react-sortable-hoc'
import { v4 as uuidv4 } from 'uuid'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import {
  SortableListContainer,
  SortableListItem,
} from 'browser/components/atomic-elements/atoms/list/sortable-list'

import 'browser/components/atomic-elements/atoms/list/_abstract-list.scss'
import { Entity } from 'shared-libs/models/entity'
import { evaluateExpressionWithScopes } from 'shared-libs/helpers/evaluation'
import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { CustomFormulas } from 'shared-libs/helpers/formulas'

/**
 * Provides a simple default behavior of writing the selected item into the desired `value` path.
 */
export interface IListItemSelectionOptions {
  /**
   * A destination path to write to.
   */
  value: string

  /**
   * An optional formula to transform a list item `item` element before writing to the path at `value`.
   */
  formula?: string
}

export interface IRenderListItemProps<T = any> {
  className?: string
  items: T[]
  item: T
  index: number
  onRemove?: () => void
  onClick?: () => void
  selectionOptions?: IListItemSelectionOptions
  isSelectable?: boolean
}

/**
 * @prop addButtonText - (optional) text to be displayed on the addItem button [default: 'Add Item']
 * @prop addButtonClassName - (optional) className(s) to be included on the addItem button
 * @prop createNewItem - (optional) function that returns a newly created item
 * @prop density - (optional)
 * @prop filterRow - (optional) [default: (row) => true]
 * @prop footerItems - (optional) components to be rendered as table items in the footer
 * @prop hasUniqueId - (optional) whether or not an item has a unique id [default: false]
 * @prop headerItems - (optional) components to be rendered as table items in the header
 * @prop helperClassName - (optional)
 * @prop isEditableInline - (optional)
 * @prop isHorizontalLayout - (optional)
 * @prop isSortable - (optional)
 * @prop itemClassName - (optional) className(s) to be included in each rendered item
 * @prop itemKeyPath - (optional) specifieable string for key prop of rendered items
 * @prop labelText - (optional)
 * @prop maxItems - (optional) maximum allowable number of items in the list
 * @prop minItems - (optional) minimum allowable number of items in the list [default: 0]
 * @prop onChange - (optional) method to be called (with value) when the value of the list changes
 * @prop placeholderItem - (optional) component to be displayed as placeholder when no items exist
 * @prop renderAddButton - (optional) method that returns a customized addItem button component
 * @prop renderListItem - (optional) method that returns a customized listItem component
 * @prop showAddButton - (optional) whether or not to show the addItem button
 * @prop showRemoveButton - (optional) whether or not to show the removeItem button [default: true]
 * @prop showItemDeleteButton - (optional) whether or not to show the itemDelete button for each item [default: false]
 * @prop showPlaceholderItem - (optional) whether or not to show a placeholder item [default: true]
 * @prop size - (optional)
 * @prop value - (optional) array of items representing the data in the list
 * @prop itemSpacing - (optional) string specifying the bumper size to use for spacing the list children elements (xs, sm, [empty string ""], lg, xl, xxl)
 */
export interface IAbstractListProps extends IBaseProps {
  addButtonText?: string
  addButtonClassName?: string
  createNewItem?: () => Record<string, any>
  density?: string
  entity?: any
  frames?: FramesManager
  forceUpdate: () => void
  filterRow?: (row) => boolean
  footerItems?: Array<React.ReactElement<any>>
  hasUniqueId?: boolean
  headerItems?: Array<React.ReactElement<any>>
  helperClassName?: string
  isSelectable?: boolean
  isEditableInline?: boolean
  isHorizontalLayout?: boolean
  isSortable?: boolean
  itemClassName?: string
  itemKeyPath?: string
  labelText?: string
  maxItems?: number
  minItems?: number
  onChange?: (value: any, silentUpdate?: boolean) => void
  placeholderItem?: React.ReactElement<any>
  renderAddButton?: (onAdd: any) => void
  renderListItem?: (props: IRenderListItemProps) => React.ReactElement<any>
  showAddButton?: boolean
  showRemoveButton?: boolean
  showItemDeleteButton?: boolean
  showPlaceholderItem?: boolean
  selectionOptions?: IListItemSelectionOptions
  size?: string
  value?: any[]
  itemSpacing?: string
}

/**
 * Generic component to display information in a list
 *
 * @props IAbstractListProps
 */
export class AbstractList<P extends IAbstractListProps> extends React.Component<P, any> {
  public static defaultProps: Partial<IAbstractListProps> = {
    addButtonText: 'Add Item',
    filterRow: (row: any) => true,
    hasUniqueId: false,
    minItems: 0,
    showItemDeleteButton: true,
    showPlaceholderItem: false,
    showRemoveButton: true,
  }

  public UNSAFE_componentWillMount() {
    this.ensureItems(this.props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    this.ensureItems(nextProps)
  }

  public render() {
    const { className, helperClassName, value } = this.props
    // TODO(louis): Figure out what to do with isEditableInline
    return (
      <SortableListContainer
        className={classNames('c-abstractList', className)}
        helperClass={classNames('c-sortableList-helper', helperClassName)}
        items={value}
        lockAxis="y"
        onSortEnd={this.handleSortEnd}
        useDragHandle={true}
      >
        {this.renderHeaderItems()}
        {this.renderListItems()}
        {this.renderFooterItems()}
        {this.renderAddButton()}
      </SortableListContainer>
    )
  }

  protected getItemKey(item, index) {
    const { itemKeyPath } = this.props
    return itemKeyPath ? _.get(item, itemKeyPath, index) : index
  }

  protected getValue() {
    return this.props.value || []
  }

  // Note: This has nothing to do with table header cells. These are just
  // elements at the top of the list
  protected renderHeaderItems() {
    return React.Children.map(this.props.headerItems, (item, index) => {
      return this.renderHeaderItem(item, index)
    })
  }

  // Note: These cells get rendered as footer cells in tables.
  protected renderFooterItems() {
    return React.Children.map(this.props.footerItems, (item, index) => {
      return this.renderFooterItem(item, index)
    })
  }

  protected renderListItems() {
    const { filterRow, minItems, maxItems, showAddButton, showPlaceholderItem } = this.props
    const items = this.getValue()
    const listItems = []
    _.forEach(items, (item, index) => {
      if (!filterRow(item)) {
        return
      }
      // We need to invoke onRemoveItem in the next run loop to avoid race
      // conditions. E.g. when we delete an inline editable item and the
      // handleOutsideMouseClick is called after the item is remove and
      // thus restoring the old item value
      const onRemove =
        items.length <= minItems
          ? null
          : () => {
              window.setTimeout(() => this.handleRemoveItem(index), 0)
            }
      const itemProps = this.getListItemProps({ items, item, index, onRemove })
      listItems.push(this.renderListItem(itemProps))
    })
    if (showPlaceholderItem) {
      // Don't show subsequent placeholder items after the first one if there
      // is a add button.
      const shouldShowButtonInstead = showAddButton && items.length > 0
      const exceededMaxItems = !_.isNil(maxItems) && items.length >= maxItems
      if (shouldShowButtonInstead || exceededMaxItems) {
        return listItems
      }
      const placeholderItem = this.renderPlaceholderItem(items.length)
      return listItems.concat([placeholderItem])
    }
    return listItems
  }

  protected renderListItemRemoveButton(handleDelete) {
    const { density, showItemDeleteButton, size } = this.props
    const densityClassName = _.isEmpty(density) ? '' : `c-density--${density}`
    const isDisabled = !handleDelete || !showItemDeleteButton

    // TODO(louis): Add this back size={size} once we have the input done
    return (
      <Button
        className={classNames(
          'c-button--square c-abstractListItem-removeButton',
          Classes.MINIMAL,
          densityClassName,
          {
            'u-hide': !showItemDeleteButton || isDisabled,
          }
        )}
        isDisabled={isDisabled}
        onClick={handleDelete}
      >
        <Icon icon={IconNames.CROSS} />
      </Button>
    )
  }

  protected renderHeaderItem(headerItem, index) {
    return headerItem
  }

  protected renderFooterItem(footerItem, index) {
    return footerItem
  }

  protected renderListItem(itemProps: IRenderListItemProps) {
    const { item, index, onRemove } = itemProps
    const { itemClassName, renderListItem, entity, selectionOptions, isSelectable, itemSpacing } = this.props
    const key = this.getItemKey(item, index)
    const isSelected =
      isSelectable && selectionOptions && item === entity.get(selectionOptions.value)

    const spacingClassName = !_.isNil(itemSpacing) ? `u-bumperBottom--${itemSpacing}` : undefined
    const borderClassName = !_.isNil(itemSpacing) ? 'c-abstractListItem--alwaysBordered' : undefined

    return (
      <SortableListItem
        className={classNames('c-abstractListItem', itemClassName, spacingClassName, borderClassName, {
          'c-abstractListItem--isSelectable': isSelectable,
          'c-abstractListItem--selected': isSelected,
        })}
        index={index}
        key={key}
        value={item}
      >
        {renderListItem(itemProps)}
        {this.props.showRemoveButton && this.renderListItemRemoveButton(onRemove)}
      </SortableListItem>
    )
  }

  private getListItemProps(props: IRenderListItemProps): IRenderListItemProps {
    const { selectionOptions, isSelectable } = this.props
    // provide default onClick behavior of writing the selected item to a path
    const onClick = !selectionOptions
      ? undefined
      : () => {
          const { entity, frames } = this.props
          const { value: path, formula } = selectionOptions
          if (!entity || !path) {
            return
          }
          const value = formula
            ? evaluateExpressionWithScopes(frames, formula, { ...CustomFormulas, ...props })
            : props.item

          // TODO(Dan): components normally trigger a rendering update through `onChange`,
          // but we're writing to a value elsewhere here.
          // Could modify onChange to take more args, but for now just forceUpdate.
          entity.set(path, value)
          this.props.forceUpdate()
        }
    return {
      ...props,
      onClick,
      selectionOptions,
      isSelectable,
    }
  }

  protected renderAddButton() {
    const {
      addButtonClassName,
      addButtonText,
      isHorizontalLayout,
      maxItems,
      renderAddButton,
      showAddButton,
      showPlaceholderItem,
      size,
    } = this.props
    const value = this.getValue()
    // if we are showing a placeholder row, we want to adjust maxItem by -1
    const adjustedMaxItems = showPlaceholderItem ? maxItems - 1 : maxItems
    const exceedsMaxItems = !_.isNil(maxItems) && value.length >= adjustedMaxItems
    if (!showAddButton || exceedsMaxItems) {
      return
    }
    if (renderAddButton) {
      return renderAddButton(this.handleAddItem)
    }
    // size={size}
    return (
      <div className="tr">
        <Button
          className={classNames('c-abstractList-addButton', Classes.MINIMAL, addButtonClassName)}
          onClick={this.handleAddItem}
          size="small"
        >
          {addButtonText}
        </Button>
      </div>
    )
  }

  protected renderPlaceholderItem(index) {
    const placeholderItem = this.createNewItem()
    const items = [placeholderItem]
    return this.renderListItem({ items, item: placeholderItem, index })
  }

  protected handleAddItem = () => {
    const value = this.getValue()
    value.push(this.createNewItem())
    this.props.onChange(value)
  }

  protected handleRemoveItem = (index) => {
    const value = this.getValue()
    _.pullAt(value, [index])
    this.props.onChange(value)
  }

  protected handleSortEnd = ({ oldIndex, newIndex }) => {
    const { onChange } = this.props
    const value = this.getValue()
    const newValue = arrayMove(value, oldIndex, newIndex)
    onChange(newValue)
  }

  private createNewItem() {
    const { hasUniqueId, createNewItem } = this.props
    const item: any = typeof createNewItem === 'function' ? createNewItem() : {}
    if (hasUniqueId) {
      _.set(item, 'uniqueId', uuidv4())
    }
    return item
  }

  private ensureItems(props) {
    const { hasUniqueId, minItems, onChange } = props
    const value = this.getValue()
    // ensures that value is filled up to minItems
    if (value.length < minItems) {
      while (value.length < minItems) {
        value.push(this.createNewItem())
      }
      onChange(value, true)
    }
    // TODO(Peter): this is crappy, but sometimes rows are not added with the
    // add button which means that may or may not have the uniqueId
    // ensure that items have uniqueId
    _.forEach(value, (item) => {
      if (hasUniqueId && !item.uniqueId) {
        _.set(item, 'uniqueId', uuidv4())
      }
    })
  }
}
