declare let __MAPBOX_TOKEN__: string

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import React from 'react'
import MapGL from 'react-map-gl'

import 'browser/components/atomic-elements/atoms/mapbox/_map-cluster-icon.scss'
import 'browser/components/atomic-elements/atoms/mapbox/_map.scss'
import { Logger } from 'browser/apis/logging'

export interface IBaseMapProps {
  className?: string
  defaultLocation?: object
  doubleClickZoomEnabled?: boolean
  handleToggleMapFullscreen?: () => void
  isFullScreenControlsEnabled?: boolean
  isInteractive?: boolean
  isMapExpanded?: boolean
  isZoomControlsEnabled?: boolean
  scrollZoomEnabled?: boolean
  mapStyle?: string
  onViewportChange?: (viewport: any) => void
  viewport?: any
  zoomLevel?: number
}

interface IBasemapState {
  isLoading: boolean
}

export class BaseMap extends React.Component<IBaseMapProps, IBasemapState> {
  public static defaultProps: Partial<IBaseMapProps> = {
    doubleClickZoomEnabled: true,
    isInteractive: true,
    scrollZoomEnabled: false,
  }

  public mapgl: any

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  public componentDidMount() {
    this.setState({ isLoading: false })
    this.logMapEvents();
  }

  /****************************************************************************/
  // Rendering
  /****************************************************************************/

  public render() {
    const viewport = { ...this.props.viewport, ...this.props }
    const settings = {
      doubleClickZoom: this.props.doubleClickZoomEnabled,
      scrollZoom: this.props.scrollZoomEnabled,
    }
    return (
      <MapGL
        {...viewport}
        {...settings}
        mapStyle={this.props.mapStyle || 'mapbox://styles/mapbox/light-v9'}
        mapboxApiAccessToken={__MAPBOX_TOKEN__}
        onViewportChange={this.handleViewportChanged}
        ref={this.handleMapGLRef}
      >
        {this.renderChildren()}
        {this.renderZoomControls()}
        {this.renderFullScreenControls()}
      </MapGL>
    )
  }

  private renderZoomControls() {
    if (!this.props.isZoomControlsEnabled) {
      return
    }
    return (
      <div className='grid-block vertical paper paper--zDepth-1 c-map-zoomControls'>
        <a
          className='grid-block align-center align-verticalCenter c-map-zoomControlPlus'
          onClick={this.handleZoomIn}
        >
          +
        </a>
        <a
          className='grid-block align-center align-verticalCenter c-map-zoomControlMinus'
          onClick={this.handleZoomOut}
        >
          -
        </a>
      </div>
    )
  }

  private renderFullScreenControls() {
    const {
      isFullScreenControlsEnabled,
      handleToggleMapFullscreen,
      isMapExpanded,
    } = this.props
    if (!isFullScreenControlsEnabled) {
      return
    }
    let icon = (
      <Icon icon={IconNames.MAXIMIZE} />
    )
    if (isMapExpanded) {
      icon = (
        <Icon icon={IconNames.MINIMIZE} />
      )
    }
    return (
      <div
        className='paper paper--zDepth-1 toggleExpandedMapButton'
        onClick={handleToggleMapFullscreen}
      >
        {icon}
      </div>
    )
  }

  private renderChildren() {
    const { isLoading } = this.state
    if (isLoading) {
      return
    }
    return React.Children.map(this.props.children, (child) => {
      const element = child as React.ReactElement<any>
      return React.cloneElement(element, {
        map: this.mapgl.getMap(),
      })
    })
  }

  private handleZoomIn = () => {
    const { viewport } = this.props
    viewport.zoom += 1
    this.handleViewportChanged(viewport)
  }

  private handleZoomOut = () => {
    const { viewport } = this.props
    viewport.zoom = Math.max(1, viewport.zoom - 1)
    this.handleViewportChanged(viewport)
  }

  private handleViewportChanged = (opt) => {
    const { isInteractive, onViewportChange } = this.props
    if (isInteractive) {
      onViewportChange(opt)
    }
  }

  private handleMapGLRef = (mapgl) => {
    this.mapgl = mapgl
  }

  private logMapEvents = () => {
    if (!this.mapgl.getMap()) {
      Logger.logEvent('Map - Failed to load')
    }
  }
}
