import React from 'react'
import _ from 'lodash'

import 'browser/components/atomic-elements/atoms/footer/_footer.scss'
import { Entity } from 'shared-libs/models/entity'
import { EntityErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/entity-error-block'
import { Footer, IFooterProps } from 'browser/components/atomic-elements/atoms/footer/footer'
import { EventSubscription } from 'fbemitter'
import { ENTITY_COMPUTATION_STATUS } from 'shared-libs/components/entity/renderer'

/**
 * @uiComponent
 */
export interface IEntityFooterProps extends IFooterProps {
  entity: Entity
  errors?: any
}

export class EntityFooter extends React.Component<IEntityFooterProps, any> {
  private entityStatusListener?: EventSubscription

  public constructor(props) {
    super(props)
    this.state = {}
  }

  public componentDidMount() {
    const { entity } = this.props
    const { isPrimaryButtonLoading: isLoading } = this.state
    this.entityStatusListener = entity.addListener(ENTITY_COMPUTATION_STATUS, (status) => {
      const isPrimaryButtonLoading = status === 'busy'
      if (isPrimaryButtonLoading !== isLoading) {
        this.setState({ isPrimaryButtonLoading })
      }
    })
  }

  public componentWillUnmount() {
    if (this.entityStatusListener) {
      this.entityStatusListener.remove()
    }
  }

  public render() {
    const isPrimaryButtonLoading = _.isNil(this.state.isPrimaryButtonLoading)
      ? this.props.isPrimaryButtonLoading
      : this.state.isPrimaryButtonLoading
    return (
      <Footer
        errorBlockChildren={this.renderErrorBlock()}
        {...this.props}
        isPrimaryButtonLoading={isPrimaryButtonLoading}
      />
    )
  }

  private renderErrorBlock() {
    const { entity, errors } = this.props
    return <EntityErrorBlock entity={entity} errors={errors} />
  }
}
