import _ from 'lodash'
import { ApplicationBundle } from './app-bundle'

import { Entity } from './entity'
import { IInboxConfiguration } from './inbox'
import { SchemaIds, SchemaUris } from './schema'
import { PlatformType } from './types/storyboard/storyboard-plan'

const SYSTEM_FIRM_ID = '00000000-0000-0000-0000-000000000000'
const TMS_BROKER_BUNDLE_ID = '11111111-0000-0000-0000-200000000002'
const TMS_CARRIER_BUNDLE_ID = 'b92c8240-0dab-489e-b219-45c689f82bdf'

export abstract class AbstractSettings {

  protected apis: any

  protected applicationBundle: ApplicationBundle
  protected firm: any
  protected settings: any
  protected mappings: any
  protected portals: any
  protected store: any
  protected user: any
  protected inboxConfiguration: IInboxConfiguration
  protected platform: PlatformType

  constructor(apis) {
    this.apis = apis
    this.store = apis.getStore()
    this.applicationBundle = new ApplicationBundle(apis)
  }

  public abstract initialize()

  // Super Admin
  public get isAdmin(): boolean {
    return this.firm && this.firm.uniqueId === SYSTEM_FIRM_ID
  }

  public get isFirmAdmin(): boolean {
    const activeEdges = this.user?.activeMixins
    return _.some(activeEdges, (mixin) => mixin.entityId === SchemaIds.FIRM_ADMIN)
  }

  public get isManager(): boolean {
    const activeMixins = this.user?.activeMixins
    return _.some(activeMixins, (mixin) => mixin.entityId === SchemaIds.MANAGER)
  }

  public get isMember(): boolean {
    const activeMixins = this.user?.activeMixins
    return _.some(activeMixins, (mixin) => mixin.entityId === SchemaIds.MEMBER)
  }

  public get isRenditionBillingEnabled(): boolean {
    const generalSettings = this.getSettingsByEntityId(SchemaIds.GENERAL_SETTINGS)
    if (!generalSettings) {
      return false
    }
    return _.get(generalSettings, 'generalSettings.isRenditionBillingEnabled', false)
  }

  public get isRenditionBillingV2Enabled(): boolean {
    const generalSettings = this.getGeneralSettings()
    return _.get(generalSettings, 'generalSettings.isRenditionBillingV2Enabled', false)
  }

  public get isDriverAppEnabled(): boolean {
    const generalSettings = this.getGeneralSettings()
    return _.get(generalSettings, 'generalSettings.isDriverAppEnabled', false)
  }

  public get isYmsEnabled(): boolean {
    const generalSettings = this.getGeneralSettings()
    return _.get(generalSettings, 'generalSettings.isYmsEnabled', false)
  }

  public get hasCustomFTPTrigger(): boolean {
    const generalSettings = this.getGeneralSettings()
    return _.get(generalSettings, 'generalSettings.hasCustomFTPTrigger', false)
  }

  public get hasDriverAppAccess(): boolean {
    const activeMixins = this.user?.activeMixins
    return _.some(activeMixins, (mixin) => mixin.entityId === SchemaIds.DRIVER_DISPATCH_APP)
  }

  public get hasReceivedPortals(): boolean {
    return _.some(this.portals, (portal) => portal.core_portal.to.entityId === this.firm.uniqueId)
  }

  public getApplicationBundle() {
    return this.applicationBundle
  }

  public getBundleSchemas() {
    return this.applicationBundle.getResolvedSchemas()
  }

  public getFirm() {
    return this.firm
  }

  public getUser() {
    return this.user
  }

  public getUserInitial() {
    const firstName = this.user.person?.firstName || ''
    const lastName = this.user.person?.lastName || ''

    const initial =  `${firstName.charAt(0)}${lastName.charAt(0)}`
    return initial.toUpperCase()
  }

  public getMappings() {
    return this.mappings
  }

  public getInitialRoute() {
    const { initialRoute } = this.applicationBundle.getResolvedBundle().applicationBundle
    return initialRoute
  }

  public isDocumentImagingEnabled(): boolean {
    return !_.isNil(this.getDocumentImagingSettings())
  }

  public isBrokerTMSApplication(): boolean {
    const extensionEdges = _.get(this.applicationBundle.getResolvedBundle(), 'applicationBundle.extends', [])
    return _.some(extensionEdges, (edge) => edge.entityId === TMS_BROKER_BUNDLE_ID)
  }

  // TODO(peter/louis): We need to clean this up.
  public isCarrierTMSAppliction(): boolean {
    const extensionEdges = _.get(this.applicationBundle.getResolvedBundle(), 'applicationBundle.extends', [])
    return _.some(extensionEdges, (edge) => edge.entityId === TMS_CARRIER_BUNDLE_ID)
  }

  public get isDATEnabled(): boolean {
    const datSettings = this.getDATSettings()
    return (datSettings !== null)
  }

  public getDATSettings(): Entity {
    return this.getSettingsByEntityId(SchemaIds.DAT_SETTINGS)
  }

  public getUserDATSettings(): Entity {
    return _.get(this.user, 'dat')
  }

  public getMobileSettings(): Entity {
    return this.getSettingsByEntityId(SchemaIds.MOBILE_SETTINGS)
  }

  public getKioskSettings(): Entity {
    return this.getSettingsByEntityId(SchemaIds.KIOSK_SETTINGS)
  }

  public getEntityTypeFilterSettings(): Entity[] {
    return _.filter(this.settings, (settings: Entity) => {
      return settings.hasMixin(SchemaIds.ENTITY_TYPE_SELECTION_FILTER)
    })
  }

  public getInvoiceSettings(): Entity {
    return this.getSettingsByEntityId(SchemaIds.ACCOUNTS_RECEIVABLE_INVOICE_SETTINGS)
  }

  public getDocumentImagingSettings(): Entity {
    return this.getSettingsByEntityId(SchemaIds.DOCUMENT_IMAGING_SETTINGS)
  }

  public getGeneralSettings(): Entity {
    return this.getSettingsByEntityId(SchemaIds.GENERAL_SETTINGS)
  }

  public getQuickBooksOnlineSettings(): Entity {
    return this.getSettingsByEntityId(SchemaIds.QUICK_BOOKS_ONLINE_SETTINGS)
  }

  public getTMSSettings(): Entity {
    return this.getSettingsByEntityId(SchemaIds.TMS_SETTINGS)
  }

  public getFtpSettings(): Entity {
    return this.getSettingsByEntityId(SchemaIds.FTP_SETTINGS)
  }

  public removeFtpSettings() {
    this.removeSettingsByEntityId(SchemaIds.FTP_SETTINGS)
  }

  public getSettingsByEntityId(entityId): Entity {
    return _.find(this.settings, (settings: any) => {
      const mixins = settings.activeMixins
      return _.some(mixins, (mixin) => mixin.entityId === entityId)
    })
  }

  public removeSettingsByEntityId(entityId): void {
    _.remove(this.settings, (settings: any) => {
      const mixins = settings.activeMixins
      return _.some(mixins, (mixin) => mixin.entityId === entityId)
    })
  }

  public getSettingsByNamespace(namespace): Entity {
    return _.find(this.settings, (settings) => settings[namespace])
  }

  public getSettingsProperty(namespace, property, defaultValue?) {
    const settings = this.getSettingsByNamespace(namespace)
    return _.get(settings, `${namespace}.${property}`, defaultValue)
  }

  public get resumeStoryboardExecutionOnAppActive() {
    return _.get(this.getMobileSettings(), 'mobileSettings.bootstrapConfig.resumeStoryboardExecutionOnAppActive', false)
  }

  public getOrderEntityType(): string {
    if (this.isRenditionBillingV2Enabled) {
      return SchemaUris.RBV2_ORDER
    } else if (!_.isNil(this.getTMSSettings())) {
      return SchemaUris.RBV1_ORDER
    }
  }

  public getOrderIdentifierPath(): string {
    if (this.isRenditionBillingV2Enabled) {
      return 'core_fulfilment_salesOrder.identifier'
    } else if (!_.isNil(this.getTMSSettings())) {
      return 'dispatchOrder.identifier'
    }
  }

  public getInboxConfiguration(): IInboxConfiguration {
    return this.inboxConfiguration
  }

  public getPlatform(): PlatformType {
    return this.platform
  }

  public setPlatform(platform: PlatformType) {
    this.platform = platform
  }
}
