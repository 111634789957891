import _ from 'lodash'
import { IFormulaLocalContext } from '../../formulas'

export function negate(value: any) {
  return _.negate(value)
}

export function memoize(func: any) {
  const localContext = this as IFormulaLocalContext
  const { componentInfo, applicationContext } = localContext

  const frames = componentInfo?.frames
  const renderer = frames?.getContext('renderer')

  const memoizedFormula = renderer.getMemoizedFormula(func)

  if (memoizedFormula) {
    return memoizedFormula
  }

  // if not exist yet, create a new memoize formula and store in local scope
  const memoizableFormula = _.memoize(func)
  renderer?.setMemoizedFormula(func, memoizableFormula)

  return memoizableFormula
}
