import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { conformToMask } from 'text-mask-core/dist/textMaskCore'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/input/_phone-number-input.scss'
import { FormGroupContentWrapper } from 'browser/components/atomic-elements/atoms/form-group-content-wrapper/form-group-content-wrapper'
import { IInputProps } from 'browser/components/atomic-elements/atoms/input/input'
import { TextMaskInput } from 'browser/components/atomic-elements/atoms/input/text-mask-input'
import { isPhoneNumberOnlyPrefix, normalizePhoneNumberIfEmpty } from './utils'

const EXTENSION_MASK = createNumberMask({
  prefix: 'x',
  thousandsSeparatorSymbol: '',
})

/**
 * @prop onChange - change handler for field input
 * @prop value - phone number represented as phone and extension
 * @prop errorText - error message about field value validation
 * @prop isStatic - 
 * @prop size -
 * @prop hideExtension - whether or not to hide the extension field
 *
 * @uiComponent
 */
interface IPhoneNumberInputProps extends IBaseProps {
  onChange: IInputProps['onChange']
  value: {
    phone?: string
    extension?: string
  }
  errorText?: IInputProps['errorText']
  isDisabled?: IInputProps['isDisabled']
  isStatic?: boolean
  size?: string
  hideExtension?: boolean
  placeholder?: string
}

/**
 * @class PhoneNumberInput - an input element for phone numbers
 * 
 * @props IPhoneNumberInputProps
 */
export class PhoneNumberInput extends React.Component<IPhoneNumberInputProps, any> {
  private input: any

  public focus() {
    this.input.focus()
  }

  public render() {
    const {
      errorText,
      hideExtension,
      isDisabled,
      isStatic,
      size,
    } = this.props
    const value = this.getValue()
    const { phone, extension } = value
    // Remove the className prop
    const inputProps = _.omit(this.props, 'className')
    const contentHideExtension = hideExtension || (isStatic && _.isEmpty(extension))
    const formattedExtension = _.isEmpty(extension) ? '' :
      conformToMask(extension, EXTENSION_MASK(extension), {}).conformedValue
    // TODO(louis): Might need size in the near future
    return (
      <div className='c-inputGroup'>
        <FormGroupContentWrapper
          hasError={!_.isEmpty(errorText)}
          isHorizontalLayout={false}
          isDisabled={isDisabled}
        >
          <PhoneInput
            {...inputProps}
            country={'us'}
            disabled={isDisabled}
            onChange={this.handlePhoneNumberChange}
            preferredCountries={['us', 'ca', 'mx']}
            value={phone}
            onBlur={this.onBlur}
          />
        </FormGroupContentWrapper>
        <TextMaskInput
          {...inputProps}
          className={classNames('c-phoneNumberExtensionInput-container', {
            'u-hide': contentHideExtension,
          })}
          guide={false}
          isHorizontalLayout={false}
          keepCharPositions={false}
          mask={EXTENSION_MASK}
          onChange={this.handleExtensionChange}
          placeholder='Ext.'
          ref={(ref) => { this.input = ref }}
          value={formattedExtension}
        />
      </div>
    )
  }

  private getValue() {
    return this.props.value || {}
  }

  private handlePhoneNumberChange = (phone, country, event, formattedPhone) => {
    const { onChange, value } = this.props
    const newValue = value || {}
    const normalizedPhoneNumber = normalizePhoneNumberIfEmpty(formattedPhone)
    if (_.isEmpty(normalizedPhoneNumber) && _.isEmpty(newValue.extension)) {
      onChange(undefined)
    } else {
      newValue.phone = normalizedPhoneNumber
      onChange(newValue)
    }
    this.attemptToClearPhonePrefix(event.target);
  }

  private onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    this.attemptToClearPhonePrefix(event.target);
  }

  private handleExtensionChange = (extension) => {
    const { onChange, value } = this.props
    const newValue = value || {}
    if (_.isEmpty(extension) && _.isEmpty(newValue.phone)) {
      onChange(undefined)
    } else {
      // extension can be undefined when backspacing to an empty value
      if (_.isEmpty(extension)) {
        newValue.extension = ''
      } else {
        newValue.extension = extension.slice(1)
      }
      onChange(newValue)
    }
  }

  // react-phone-input-2 leaves an extra '+' symbol in the input in some scenarios
  // it can't be removed with backspace/delete which results in the user being stuck
  // this is the case even when removing it from the stored value
  // we manually update the input to remove the '+'
  private attemptToClearPhonePrefix = (input: HTMLInputElement): void => {
    setTimeout(() => {
      if (input && isPhoneNumberOnlyPrefix(input.value)) {
        input.value = ''
      }
    }, 0)
  }
}
