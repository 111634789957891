import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core'
import classNames from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import React from 'react'
import { Motion, presets, spring } from 'react-motion'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/sheet/_sheet.scss'
import { SheetContext } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'

const topPreset = _.assign({ precision: 20 }, presets.noWobble)

export interface ISheetProps extends IBaseProps {
  bodyClassName?: string
  disableFadeIn?: boolean
  footer?: React.ReactElement<any>
  header?: React.ReactElement<any>
  maxHeight?: number
  paperLevel?: string
  size?: string
}

@HotkeysTarget
export class Sheet extends React.Component<ISheetProps, any, never> {

  public static defaultProps: Partial<ISheetProps> = {
    maxHeight: 0,
    paperLevel: '4',
  }

  private sheetMotionContainerRef: HTMLElement
  private sheetContainerRef: HTMLElement
  private sheetRef: HTMLElement

  constructor(props) {
    super(props)
    const { disableFadeIn } = this.props
    const initialBackdropOpacity = disableFadeIn ? 1 : 0
    this.state = {
      animation: {
        from: { opacity: initialBackdropOpacity, top: -10000 },
        to: { opacity: 0, top: -10000 },
      },
      isClosing: false,
      maxSheetHeight: this.props.maxHeight,
    }
  }

  public componentDidMount() {
    this.setMaxHeight()
    this.open()
    this.focusSheetContainer()
  }

  public open() {
    const $sheetRef = $(this.sheetRef)
    const height = $sheetRef.outerHeight()
    const { disableFadeIn } = this.props
    const initialBackdropOpacity = disableFadeIn ? 1 : 0
    this.setState({
      animation: {
        from: { opacity: initialBackdropOpacity, top: -height },
        to: { opacity: spring(1), top: spring(0) },
      },
    })
  }

  public close() {
    const $sheetRef = $(this.sheetRef)
    const height = $sheetRef.outerHeight() + 30
    this.setState({
      animation: {
        from: { opacity: 1, top: 0 },
        to: { opacity: spring(0), top: spring(-height, topPreset) },
      },
      isClosing: true,
    })
  }

  public render() {
    const { animation } = this.state
    // Note that we add the div because HotkeysTarget needs to be able to add
    // a keyUp and keyDown to it.
    return (
      <SheetContext.Consumer>
        {({closeOverlay}) => (
          <div
            tabIndex={-1}
            ref={this.handleSetSheetMotionContainerRef}
          >
            <Motion
              defaultStyle={animation.from}
              style={animation.to}
              onRest={() => this.handleAnimationEnd(closeOverlay)}
            >
              {(style) => this.renderContent(style)}
            </Motion>
          </div>
        )}
      </SheetContext.Consumer>
    )
  }

  public renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          allowInInput={true}
          group='Sheets'
          combo='esc'
          label='Close Sheet'
          onKeyDown={(event) => {
            event.stopImmediatePropagation()
            this.close()
          }}
          stopPropagation={true}
          preventDefault={true}
        />
      </Hotkeys>
    )
  }

  private renderContent(style) {
    const {
      bodyClassName,
      children,
      className,
      footer,
      header,
      paperLevel,
      size ,
    } = this.props
    const { maxHeight } = this.state
    const paperLevelClassName = `paper--zDepth-${paperLevel}`
    const sizeClass = _.isEmpty(size) ? '' : `c-sheet--${size}`
    // We need to add a backdrop if the panel is within a panel to cover the
    // parent panel's content.
    return (
      <div
        className='c-sheetContainer js-sheetContainer'
        ref={this.handleSetSheetContainerRef}
      >
        <div
          className='c-sheetBackdrop'
          style={{ opacity: style.opacity }}
        />
        <div
          className={classNames('c-sheet paper', paperLevelClassName, sizeClass, className)}
          ref={this.handleSetSheetRef}
          style={{ top: style.top}}
        >
          <div className='c-sheet-ieWrapper' style={{maxHeight}}>
            {header}
            <div className={classNames('c-sheet-body', bodyClassName)}>
              {children}
            </div>
            {footer}
          </div>
        </div>
      </div>
    )
  }

  private focusSheetContainer = () => {
    if (this.sheetMotionContainerRef != null) {
      this.sheetMotionContainerRef.focus()
    }
  }

  private handleSetSheetMotionContainerRef = (ref: Element) => {
    this.sheetMotionContainerRef = ref as HTMLElement
  }

  private handleSetSheetContainerRef = (ref: Element) => {
    this.sheetContainerRef = ref as HTMLElement
  }

  private handleSetSheetRef = (ref: Element) => {
    this.sheetRef = ref as HTMLElement
  }

  private setMaxHeight() {
    const offsetToBottom = 50
    // TODO(peter): Why does this not work?
    // const $sheetContainer = $(ReactDOM.findDOMNode(this.refs.sheetContainerRef))
    const $sheetContainer = $('.js-sheetContainer')
    const containerHeight = $sheetContainer.height() - offsetToBottom
    this.setState({ maxHeight: containerHeight })
  }

  private handleAnimationEnd = (closeOverlay) => {
    const { isClosing } = this.state
    if (isClosing) {
      closeOverlay()
    }
  }
}
