import _ from 'lodash'
import React, { Component } from 'react'

import apis from 'browser/app/models/apis'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { default as EntitySelect } from 'browser/components/atomic-elements/atoms/select/entity-select'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { Entity } from 'shared-libs/models/entity'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { UserProps } from 'shared-libs/models/prop-constants'
import { SchemaIds, SchemaUris } from 'shared-libs/models/schema'

import {
  ContentWrapper,
  EmailOptionProp,
  NameOptionProp,
  Option,
  OptionProp,
  OptionPropLabel,
  ShortOptionProp,
} from 'browser/components/domain/user/dialog-option-styles'
import { components } from 'react-select'

interface ILinkDriverDialogProps {
  isOpen: boolean
  onClose: () => void
  targetUser: Entity
}

interface ILinkDriverDialogState {
  errorText: string
  driverToLink: object
  targetUser: Entity
  loading: boolean
}

export class LinkDriverDialog extends Component<ILinkDriverDialogProps, ILinkDriverDialogState> {

  public static open(props) {
    return OverlayManager.openOverlay(this, { ...props })
  }

  constructor(props) {
    super(props)

    this.state = {
      driverToLink: null,
      errorText: '',
      targetUser: null,
      loading: false
    }
  }

  public componentDidMount() {
    this.setState({
      targetUser: this.props.targetUser,
    })
  }

  public render() {
    const { onClose } = this.props
    const { errorText, driverToLink } = this.state
    const selectNotFilter = {
      path: 'driver.linkedUser',
      type: 'notExists'
    }
    return (
      <Modal modalStyle={{ width: 'auto' }}>
        <div>
          <div className='c-modalHeader'><h4 className='c-modal-title u-textCenter'>Link Driver</h4></div>
            <ContentWrapper>
              {!this.state.loading && <EntitySelect
                autoFocus={true}
                entityType={SchemaUris.DRIVER}
                filter={selectNotFilter}
                errorText={errorText}
                isCreatable={false}
                onChange={this.handleSelectDriver}
                optionRenderer={this.renderSelectOption}
                placeholder='Choose Driver'
                returnValueAsEdge={true}
                style={{ border: '1px solid #E0E0E0' }}
                value={driverToLink}
                filterByFieldPaths={['driver.sourceId']}
              />}
              {this.state.loading && <LoadingSpinner/>}
            </ContentWrapper>
            <Footer
              errorText={this.state.errorText}
              isPrimaryButtonDisabled={!driverToLink}
              onCancelButtonClick={onClose}
              onPrimaryButtonClick={this.handleLinkDriver}
              primaryButtonText='Link'
            />
        </div>
      </Modal>
    )
  }

  private handleSelectDriver = (driver) => {
    this.setState({driverToLink: driver})
  }

  private handleLinkDriver = () => {
    const { targetUser, driverToLink } = this.state
    const store = apis.getStore()
    const driverContactMetadata = store.getRecord(SchemaIds.DRIVER_CONTACT)
    const driverDispatchMetadata = store.getRecord(SchemaIds.DRIVER_DISPATCH_APP)
    targetUser.addMixin(driverContactMetadata)
    targetUser.addMixin(driverDispatchMetadata)
    targetUser.set('driverContact.driverRef', driverToLink)
    this.setState({
      loading: true
    });
    targetUser.save().then(() => {
      this.setState({
        loading: false
      });
      this.props.onClose()
    }).catch((err) => {
      let errorMessage = ''
      for (const e in err.errors){
        for (let i=0;i<err.errors[e].length;i++){
          if (errorMessage !== '') {
            errorMessage += ','
          }
          errorMessage += err.errors[e][i]
        }
      }
      if (errorMessage === '') {
        errorMessage = 'error'
      }
      this.setState({
        errorText: errorMessage,
        loading: false
      });
    })
  }

  private renderSelectOption = (props) => {
    const user = props.data
    return (
      <components.Option {...props}>
        <Option>
          <ShortOptionProp>
            <OptionPropLabel>
              Source ID:
            </OptionPropLabel>
            {_.get(user, 'driver.sourceId')}
          </ShortOptionProp>
          <NameOptionProp>
            {_.get(user, UserProps.NAME)}
          </NameOptionProp>
          <OptionProp>
            <OptionPropLabel>
              Firm:
            </OptionPropLabel>
            {_.get(user, 'driver.carriers[0].displayName')}
          </OptionProp>
          <EmailOptionProp>
            <OptionPropLabel>
              Email:
            </OptionPropLabel>
            {_.get(user, UserProps.FIRST_EMAIL_VALUE)}
          </EmailOptionProp>
          <OptionProp>
            <OptionPropLabel>
              Phone:
            </OptionPropLabel>
            {_.get(user, UserProps.FIRST_PHONE_VALUE)}
          </OptionProp>
        </Option>
      </components.Option>
    )
  }
}
