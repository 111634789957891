import { Tab, Tabs } from '@blueprintjs/core'
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import Promise from 'bluebird'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { MultiMixinRenderer } from 'shared-libs/components/entity/multi-mixin-renderer'
import { Entity } from 'shared-libs/models/entity'
import { Store } from 'shared-libs/models/store'

import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Card } from 'browser/components/atomic-elements/atoms/card/card'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { BlockTransition } from 'browser/components/atomic-elements/atoms/navigation/block-transition'
import { SheetContext } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import 'browser/components/atomic-elements/organisms/entity/entity-detail-card/_entity-detail-card.scss'
// tslint:disable-next-line:max-line-length
import { EntityDetailCardDropdown } from 'browser/components/atomic-elements/organisms/entity/entity-detail-card/entity-detail-card-dropdown'
import { ShareBar } from 'browser/components/atomic-elements/organisms/share-bar/share-bar'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'

import { debouncePromise } from 'shared-libs/helpers/debounce-promise'

/**
 * @uiComponent
 */
export interface IEntityDetailCardProps extends IBaseProps {
  enableSchemaOverride: boolean
  dropdownElement: React.ReactElement<any>
  entity: Entity
  disableBlockTransactionAndFooter?: boolean
  header: React.ReactElement<any>
  isFullScreen?: boolean
  onClose: () => void
  queries: any
  printView?: string
  removeDropdown?: boolean
  openOverlay: any
  settings: Settings
  additionalTabs?: any
  additionalTabSchemas?: any // todo - provide typing for this
  removeDeleteButton?: boolean
  showWorkflowCancelButton?: boolean
  showPasswordResetButton?: boolean
  showNeedsActionOverrideButton?: boolean
  afterWorkflowCancel: () => void
}

export interface IEntityDetailCardStates {
  activeTab: string
  dataSets: any
  errors: any
  isSaving: boolean
}

const DOCUMENT_SCHEMA_ID = '11111111-0000-0000-0000-000000000011'
const UNKNOWN_ENTITY = 'Unknown Entity'

export class EntityDetailCard extends React.Component<IEntityDetailCardProps, IEntityDetailCardStates> {
  refreshSubscription: any

  constructor(props) {
    super(props)
    this.state = Object.assign({
      activeTab: 'Details',
      dataSets: this.createDataSets(props.queries),
      errors: null,
      isSaving: false,
    })
    this.refreshSubscription = this.props.entity.addListener(Store.RECORD_CHANGED, this.onEntityRefresh)
  }

  public componentDidMount() {
    const { dataSets } = this.state
    _.forEach(dataSets, (dataSet: EntityDataSource) => dataSet.find())
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    // TODO(Peter): there is a bug, hence requires casting
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/14250 Problem 2
    const entity: any = this.props.entity
    const { queries } = this.props
    const { dataSets } = this.state
    if (entity !== nextProps.entity || !_.isEqual(queries, nextProps.queries)) {
      _.forEach(dataSets, (dataSource: EntityDataSource) => dataSource.dispose())
      const newDataSets = this.createDataSets(nextProps.queries)
      _.forEach(newDataSets, (dataSet: EntityDataSource) => dataSet.find())
      this.setState({ dataSets: newDataSets })

      this.refreshSubscription && this.refreshSubscription.remove()
      this.refreshSubscription = nextProps.entity.addListener(Store.RECORD_CHANGED, this.onEntityRefresh)
    }
  }

  public onEntityRefresh = () => {
    this.forceUpdate()
  }

  public componentWillUnmount() {
    const { dataSets } = this.state
    _.forEach(dataSets, (dataSource: EntityDataSource) => dataSource.dispose())
    this.refreshSubscription && this.refreshSubscription.remove()
  }

  public render() {
    const {
      className,
      children,
      isFullScreen,
    } = this.props

    let body = children

    if (_.isEmpty(children)) {
      body = isFullScreen ?
        this.renderFullScreenCard() :
        this.renderDetailCard()
    }

    const entityDetailCardBodyFullScreen = this.renderSchemasForMixins('entityDetailCardBodyFullScreen')
    const entityDetailCardBodyPreview = this.renderSchemasForMixins('entityDetailCardBodyPreview')
    if (isFullScreen && !_.isEmpty(entityDetailCardBodyFullScreen)) {
      body = entityDetailCardBodyFullScreen
    } else if (!_.isEmpty(entityDetailCardBodyPreview)) {
      body = entityDetailCardBodyPreview
    }

    // Note: Do not add shrink, causes issues with table inside of it.
    return (
      <Card
        className={classNames('grid-block vertical w-100 relative c-entityDetailCard', className)}
        data-debug-id='entityDetailCard'
      >
        {this.renderHelmet()}
        {this.renderHeader()}
        <BlockTransition
            condition={this.shouldShowBlockTransition()}
            onBlockTransition={this.handleBlockTransition}
            debug='entity-detail-card'
        >
          {body}
        </BlockTransition>
      </Card>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  protected renderHelmet() {
    const entity: any = this.props.entity
    let title = `${entity.displayName} | ${entity.entityType}`
    if (entity.displayName && entity.displayName === UNKNOWN_ENTITY) {
      title = `${entity.entityType}`
    }
    return (
      <Head
        title={title}
      />
    )
  }

  protected renderHeader() {
    const {
      header,
      isFullScreen,
      onClose,
    } = this.props
    return React.cloneElement(header, {
      dropdownMenuElement: this.renderDropdownMenu(),
      isFullScreen,
      onClose,
    })
  }

  protected renderDropdownMenu() {
    const {
      entity,
      onClose,
      printView,
      dropdownElement,
      removeDropdown,
      settings,
      removeDeleteButton,
      showWorkflowCancelButton,
      afterWorkflowCancel,
      showPasswordResetButton,
      showNeedsActionOverrideButton,
    } = this.props

    // This is for when we are looking at the user detail panel
    // We are removing the more button
    // Also to do this we added a prop called removeDropdown used in user.json
    if (removeDropdown && !settings.isAdmin) {
      return null
    }

    return (
      <EntityDetailCardDropdown
        printView={printView}
        entity={entity}
        onClose={onClose}
        settings={settings}
        removeDeleteButton={removeDeleteButton}
        showWorkflowCancelButton={showWorkflowCancelButton}
        showPasswordResetButton={showPasswordResetButton}
        afterWorkflowCancel={afterWorkflowCancel}
        showNeedsActionOverrideButton={showNeedsActionOverrideButton}
      >
        {dropdownElement}
      </EntityDetailCardDropdown>
    )
  }

  protected renderDetailCard() {
    return (
      <div className='grid-block vertical'>
        <Tabs
          className='grid-block vertical c-tabs'
          id='entityDetailCardTabs'
          onChange={this.handleTabChange}
          renderActiveTabPanelOnly={true}
        >
          {this.getDetailCardTabRenderers().map((renderer) => renderer())}
          <Tabs.Expander />
        </Tabs>
        {this.renderShareBar()}
      </div>
    )
  }

  protected getDetailCardTabRenderers() {
    const renderTabs = [
      this.renderDetailsTab,
      this.renderActivityTab,
      this.renderAnalyticsTab,
      this.renderRelatedTab,
      this.renderDocumentsTab,
    ]

    if (this.props.additionalTabs) {
      renderTabs.push(...this.props.additionalTabs)
    }

    renderTabs.push(...this.getAdditionalTabRenderers())

    return renderTabs
  }

  protected renderFullScreenCard() {
    const relatedTab = this.renderRelatedTab()
    const documentsTab = this.renderDocumentsTab()
    const rightBlock = !relatedTab ? null : (
      <div className='grid-block c-entityDetailCard-rightBlock'>
        <Tabs
          className='grid-block vertical c-tabs'
          id='entityRightBlockTabs'
          onChange={this.handleTabChange}
          renderActiveTabPanelOnly={true}
        >
          {relatedTab}
          {documentsTab}
          <Tabs.Expander />
        </Tabs>
      </div>
    )

    return (
      <div className='grid-block'>
        <div className='grid-block c-entityDetailCard-leftBlock'>
          <Tabs
            className='grid-block vertical c-tabs'
            id='entityLeftBlockTabs'
            onChange={this.handleTabChange}
            renderActiveTabPanelOnly={true}
          >
            {this.renderDetailsTab()}
            <Tabs.Expander />
          </Tabs>
        </div>
        <div className='grid-block vertical c-entityDetailCard-centerBlock'>
          <Tabs
            className='grid-block vertical c-tabs'
            id='entityCenterBlockTabs'
            onChange={this.handleTabChange}
            renderActiveTabPanelOnly={true}
          >
            {/* {this.renderJSONTab()} */}
            {this.renderActivityTab()}
            {this.renderAnalyticsTab()}
            <Tabs.Expander />
          </Tabs>
          {this.renderShareBar()}
        </div>

        {rightBlock}
      </div>
    )
  }

  protected renderShareBar() {
    const { entity } = this.props
    // TODO(peter): add conditional that only documents should show documents
    // for now until we have the follow concept.
    if (entity.hasMixin(DOCUMENT_SCHEMA_ID)) {
      return (
        <ShareBar
          className='grid-block shrink'
          entity={entity}
        />
      )
    }
  }

  protected renderSaveFooter() {
    const { isSaving } = this.state
    return (
      <Footer
        isPrimaryButtonLoading={isSaving}
        isVisible={this.isDirty()}
        onCancelButtonClick={this.handleRollback}
        onPrimaryButtonClick={this.handleSaveEntity}
        primaryButtonHotkey={{
          label: 'Save',
          combo: 'mod+shift+s'
        }}
        cancelButtonHotkey={{
          label: 'Cancel',
          combo: 'ctrl+q'
        }}
      />
    )
  }

  protected renderDetailsTab = () => {
    const { disableBlockTransactionAndFooter } = this.props
    const content = this.renderSchemasForMixins('entityDetailSection')
    if (_.isEmpty(content)) {
      return
    }
    const detailTabPanel = (
      <div className="grid-block vertical">
        <div className="grid-block">
          <div className="grid-content u-innerBumperTop">{content}</div>
        </div>
        {!disableBlockTransactionAndFooter && this.renderSaveFooter()}
      </div>
    )
    return (
      <Tab
        id='entityCardDetails'
        key='detail-tab'
        title='Details'
        className='grid-block vertical'
        panel={detailTabPanel}
      />
    )
  }

  protected renderActivityTab = () => {
    const content = this.renderSchemasForMixins('entityActivitySection')
    if (!_.isEmpty(content)) {
      return (
        <Tab
          id='entityCardActivity'
          key='activity-tab'
          title='Activity'
          className='grid-block'
          panel={
            <div className='grid-block'>
              {content}
            </div>
          }
        />
      )
    }
  }

  protected renderAnalyticsTab = () => {
    const content = this.renderSchemasForMixins('entityAnalyticsSection')
    if (!_.isEmpty(content)) {
      return (
        <Tab
          id='entityCardAnalytics'
          key='analytics-tab'
          title='Analytics'
          className='grid-block'
          panel={
            <div className='grid-content u-innerBumperTop'>
              {content}
            </div>
          }
        />
      )
    }
  }

  protected renderRelatedTab = () => {
    const content = this.renderSchemasForMixins('entityAssociationsList')
    if (!_.isEmpty(content)) {
      return (
        <Tab
          id='entityCardRelated'
          key='related-tab'
          title='Related'
          className='grid-block'
          panel={
            <div className='grid-content u-innerBumperTop c-entityAssociationList'>
              {content}
            </div>
          }
        />
      )
    }
  }

  protected getAdditionalTabRenderers = () => {
    const { additionalTabSchemas } = this.props

    if (_.isEmpty(additionalTabSchemas)) {
      return []
    }

    return additionalTabSchemas.map((tabSchema) => {
      return () => {
        const tabContent = this.renderSchemasForMixins(tabSchema.uiSchema)

        if (_.isEmpty(tabContent)) {
          return undefined
        }

        return (
          <Tab
            id={`entityCard--additionalTab--${tabSchema.id}`}
            key={`${tabSchema.id}-tab`}
            title={tabSchema.name}
            className='grid-block'
            panel={
              <div className='grid-content u-innerBumperTop'>
                {tabContent}
              </div>
            }
          />
        )
      }
    })
  }

  protected renderSchemasForMixins(uiSchemaName) {
    const { entity, enableSchemaOverride, settings } = this.props
    const { errors } = this.state
    const uiSchemaPath = `uiSchema.web.${uiSchemaName}`
    if (!entity.resolveSubschemaByPath(uiSchemaPath)) {
      return
    }
    // We want members to be able to change anything on document, keeping it here incase we want to change back.
    // isStatic: !isEditable
    // const isEditable = this.isAdminOrFirmAdminOrManager()
    const rendererContext = {
      density: 'collapse',
      isHorizontalLayout: true,
      isStatic: false,
      onSave: this.handleSaveEntity,
    }
    const renderState = Object.assign({ settings }, this.state)
    return (
      <MultiMixinRenderer
        actions={this}
        componentsMap={ComponentsMap}
        context={rendererContext}
        errors={errors}
        shouldOverrideUiSchema={enableSchemaOverride}
        state={renderState}
        onChangeComplete={() => this.forceUpdate()}
        value={entity}
        uiSchemaPath={uiSchemaPath}
      />
    )
  }

  protected renderDocumentsTab = () => {
    const context = {
      density: 'collapse',
      isEditableInline: false,
      isHorizontalLayout: true,
      isStatic: true,
    }
    const { dataSets } = this.state
    const numDocuments = _.get(dataSets, 'documents.collection.count')
    if (!_.get(dataSets, 'documents')) {
      return null
    }
    return (
      <Tab
        className='grid-block'
        id='documents'
        key='document-tab'
        title={numDocuments ? `Documents (${numDocuments})` : 'Documents'}
        panel={(
          <div className='grid-content pt3'>
            {this.renderUiSchema('components.documentsTab', context)}
          </div>
        )}
      />
    )
  }

  protected renderUiSchema(uiSchemaName, context) {
    const { settings } = this.props
    const { entity } = this.props
    const { errors } = this.state
    const schemas = entity.schemas
    const renderState = Object.assign({ settings }, this.state)
    const uiSchemaPath = `uiSchema.web.${uiSchemaName}`
    for (let i = schemas.length - 1; i >= 0; --i) {
      if (!_.get(schemas[i], uiSchemaPath)) {
        continue
      }
      return (
        <EntityRenderer
          actions={this}
          componentsMap={ComponentsMap}
          context={context}
          errors={errors}
          state={renderState}
          onChangeComplete={() => this.forceUpdate()}
          value={entity}
          uiContext={schemas[i]}
          uiSchemaPath={uiSchemaPath}
        />
      )
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  protected handleBlockTransition = (onTransition) => {
    const saveChanges = () => {
      this.handleSaveEntity().then(() => onTransition())
    }
    const discardChanges = () => {
      this.handleRollback()
      onTransition()
    }
    ConfirmationModal.open({
      confirmationText: 'Are you sure you want to leave this screen and discard your changes?',
      confirmationTitle: 'You have unsaved changes',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: saveChanges,
      onSecondaryClicked: discardChanges,
      primaryButtonText: 'Save Changes',
      secondaryButtonText: 'Discard Changes',
    })
  }

  protected handleEntityChange = (entity) => {
    // TODO(Peter): this is not great to forceUpdate when entity change
    this.forceUpdate()
  }

  protected handleRollback = () => {
    const entity: any = this.props.entity
    entity.rollback()
    this.forceUpdate()
  }

  protected handleSaveEntity = (): Promise<any> => {
    const { entity } = this.props
    this.setState({ isSaving: true })

    return this.debouncedSave(entity)
      .then(() => this.setState({ errors: null }))
      .catch(({ errors }) => this.setState({ errors }))
      .finally(() => this.setState({ isSaving: false }))
  }

  protected debouncedSave = debouncePromise((entity: Entity) => {
    return entity.save()
  }, 250)

  protected handleTabChange = (activeTab: string) => {
    this.setState({ activeTab })
  }

  //////////////////////////////////////////////////////////////////////////////
  // Custom Handler we have to move out
  //////////////////////////////////////////////////////////////////////////////

  protected handleSendInvitation = () => {
    const { entity, onClose } = this.props
    entity.set('invite.status', 'Sent')
    entity.save().then(() => {
      Toast.show({
        message: 'Sending Invitation',
        position: Position.BOTTOM_RIGHT,
      })
      onClose()
    })
  }

  protected handleResendInvitation = () => {
    const { onClose } = this.props
    const entity: any = this.props.entity
    return entity.invite.resend().then(() => {
      Toast.show({
        message: 'Successfully resent invitation.',
        position: Position.BOTTOM_RIGHT,
      })
      onClose()
    })
  }

  protected handleDeleteInvitation = () => {
    const { onClose } = this.props
    const entity: any = this.props.entity
    return entity.invite.cancel().then(() => {
      this.setState({ errors: null })
      Toast.show({
        message: 'Successfully deleted invitation.',
        position: Position.BOTTOM_RIGHT,
      })
      onClose()
    }).catch((errors) => {
      entity.rollback()
      this.setState({ errors: errors.errors })
    })
  }

  protected handleCancelInvitation = () => {
    const { entity, onClose } = this.props
    entity.set('invite.status', 'Canceled')
    entity.save().then(() => {
      Toast.show({
        message: 'Cancelling Invitation',
        position: Position.BOTTOM_RIGHT,
      })
      onClose()
    })
  }

  protected handleApproveJoinRequest = () => {
    const { onClose } = this.props
    const entity: any = this.props.entity
    return entity.accessRequest.accept().then(() => {
      this.setState({ errors: null })
      Toast.show({
        message: 'Successfully approved request.',
        position: Position.BOTTOM_RIGHT,
      })
      onClose()
      // TODO: We need to somehow tell the table to reload instead of reloading
      // the entire application.
      location.reload()
    }).catch((errors) => {
      entity.rollback()
      this.setState({ errors: errors.errors })
    })
  }

  protected handleRejectJoinRequest = () => {
    const { onClose } = this.props
    const entity: any = this.props.entity
    return entity.accessRequest.reject().then(() => {
      this.setState({ errors: null })
      Toast.show({
        message: 'Successfully rejected request.',
        position: Position.BOTTOM_RIGHT,
      })
      onClose()
      // TODO: We need to somehow tell the table to reload instead of reloading
      // the entire application.
      location.reload()
    }).catch((errors) => {
      entity.rollback()
      this.setState({ errors: errors.errors })
    })
  }

  protected handleDownloadPdf = () => {
    const { entity } = this.props
    const entityId = _.get(entity, 'uniqueId')
    const attachments = [{
      entityId,
      fileName: `${entity.displayName}.pdf`,
      type: 'document',
    }]
    apis.batchDownloadDocuments({ attachments }).then((url) => {
      window.location = url
    })
  }

  //////////////////////////////////////////////////////////////////////////////
  // Helpers
  //////////////////////////////////////////////////////////////////////////////

  protected createDataSets(dataSets) {
    const results = {}
    _.forEach(dataSets, (dataSource, key) => {
      results[key] = new EntityDataSource(dataSource)
        .setOnChange(() => this.forceUpdate())
    })
    return results
  }

  protected isDirty() {
    return this.props.entity.isDirty
  }

  protected shouldShowBlockTransition() {
    const { entity, disableBlockTransactionAndFooter } = this.props

    // TODO(David): This is def a hack take a look at this
    const isDeleted = _.get(entity.content, 'isDeleted', false)
    return !disableBlockTransactionAndFooter && entity.isDirty && !isDeleted
  }

  /*
    Actions (for Workflows primarily, will move to a new class later on)
  */
  onClose = () => {
    // todo(joco) - this may not be super reliable, especially in the context of inline details panels
    const { onClose } = this.props
    return onClose && onClose()
  }

  showToast = (message, delay=3000) => {
    const toast = Toast.show({
      message: (
        <div className="c-toastContents">
          <Icon
            className="c-toastContents-icon mr3"
            icon={IconNames.TICK_CIRCLE} // todo(joco) - this should be more generalized, maybe an info icon
            size={30}

          />
          <div className="c-toastContents-message">
            {message}
          </div>
        </div>
      ),
      position: Position.BOTTOM_RIGHT,
      isLightMode: true,
    })

    return new Promise((resolve) => {
      setTimeout(() => {
        Toast.dismiss(toast)
        resolve()
      }, delay)
    })
  }

  actions = {
    onClose: this.onClose,
    showToast: this.showToast,
  }
}

export default React.forwardRef((props: IEntityDetailCardProps, ref: React.RefObject<EntityDetailCard>) => (
  <AppNavigatorContext.Consumer>
    {({ settings }) => (
      <SheetContext.Consumer>
        {({ openOverlay }) => (
          <EntityDetailCard {...props} openOverlay={openOverlay} settings={settings} ref={ref} />
        )}
      </SheetContext.Consumer>
    )}
  </AppNavigatorContext.Consumer>
))
