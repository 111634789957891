import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface IViewProps extends IBaseProps {
  tagName?: string
}

export const View: React.FC<IViewProps> = (props) => {
  const { children, tagName, ...viewProps } = props
  return React.createElement(tagName, viewProps, children)
}

View.defaultProps = {
  tagName: 'div',
}
