import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { INVOICE_PATH, INVOICE_ENTITY_TYPE } from 'browser/app/constants/rendition-billing'
import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { Column } from 'browser/components/atomic-elements/atoms/table'
import { EntityTable } from 'browser/components/atomic-elements/organisms/entity/entity-table'
import { EntityDataSource } from '../entity-data-source'


const TIGERLINES_FIRM_ID = 'bf6a36f7-c417-47ea-8b2b-1697694e4bde'
const SALES_INVOICE_TYPE = '/1.0/entities/metadata/core_accounting_accountsReceivable_salesInvoice.json'

interface IRbV2SalesInvoiceChildTable  {
  entity: any
  frames: any
}

export class RbV2SalesInvoiceChildTable extends React.Component<IRbV2SalesInvoiceChildTable, any> {

  private dataSet: EntityDataSource

  constructor(props) {
    super(props)
    const { entity } = this.props

    this.dataSet = new EntityDataSource({
      entityType: SALES_INVOICE_TYPE,
      orders: [
        {
          label: 'Documents Ready',
          path: 'core_accounting_accountsReceivable_salesInvoice.readyToInvoice.status.hasRequiredDocuments',
          type: 'ascending',
        },
      ],
    }).setOnChange(() => this.forceUpdate())
  }

  public componentDidMount() {
    const { entity } = this.props
    
    if (entity.owner.firm.entityId === TIGERLINES_FIRM_ID) {
      const id = _.get(entity, 'uniqueId', '')
      if (!_.isEmpty(id)) {
        this.dataSet.setFilters(
            {
              path: 'core_accounting_accountsReceivable_salesInvoice.consolidateSalesInvoice',
              type: 'matchEdge',
              value: {entityId: id}
            },
        )
        this.dataSet.find();
      }
    } else {
      const childIds = this.childInvoiceIds(entity)
      if (!_.isEmpty(childIds)) {
        this.dataSet.setFilters(
          {
            path: 'uniqueId',
            type: 'match',
            values: childIds
          }
        )
        this.dataSet.find();
      }
    }
  }

  public componentWillUnmount() {
    this.dataSet.dispose()
  }

  public render() {
    const entitySchema = apis.getStore().getRecord(SALES_INVOICE_TYPE)
    const columns: any[] = [
      {
        cellComponent: {
          labelPath: 'core_accounting_accountsReceivable_salesInvoice.invoiceNumber',
          linkPath: 'uniqueId',
          path: 'entity',
          type: 'ui:table:linkCell',
        },
        flexGrow: 1,
        groupKey: 'data',
        isFixed: true,
        isSortable: true,
        label: 'Invoice Number',
        path: 'data',
        sortKey: 'core_accounting_accountsReceivable_salesInvoice.invoiceNumber',
        width: 100,
      },
      {
        className: 'u-tabularFigures',
        flexGrow: 1,
        format: { type: 'currency' },
        horizontalAlignment: 'right',
        isSortable: true,
        label: 'Revenue ($)',
        path: 'core_accounting_accountsReceivable_salesInvoice.totalRevenue.value',
        width: 50,
      },
      {
        flexGrow: 1,
        cellComponent: {
          type: 'ui:table:requirementsCell',
          requirementCategory: 'document',
          requirementPath: 'readyToInvoice.requirements'
        },
        label: 'Documents',
        requirementsChecklistHeadingText: 'Required Documents',
        path: 'core_accounting_accountsReceivable_salesInvoice',
        width: 20
      },
      {
          flexGrow: 1,
          cellComponent: {
            type: 'ui:table:simpleArrayCell'
          },
          label: 'Missing Documents',
          path: 'core_accounting_accountsReceivable_salesInvoice.readyToInvoice.status.missingDocuments',
          width: 80
      },
      {
        flexGrow: 1,
        cellComponent: {
          type: 'ui:table:rbPreferenceRequirementsCell',
          requirementCategory:  'preference',
          requirementPath: 'readyToInvoice.requirements'
        },
        label: 'Preferences',
        requirementsChecklistHeadingText: 'Invoice Preferences',
        path: 'core_accounting_accountsReceivable_salesInvoice',
        width: 20
      }
    ]
    return (
      <Section title='Related Invoices'>
        <div className='u-positionRelative u-minHeight400'>
            <AutofillBlock className='u-border'>
              <EntityTable
                columns={columns}
                entitySchema={entitySchema}
                isConfigurable={false}
                isLoading={this.dataSet.isLoading}
                onEndReached={this.dataSet.handleLoadNextPage}
                onRowClick={null}
                onRowSelect={null}
                rows={this.dataSet.content}
                frames={this.props.frames}
              />
            </AutofillBlock>
        </div>
      </Section>
    )
  }

  private childInvoiceIds = (entity) => {
    return _.map(
      _.get(entity, 'core_accounting_accountsReceivable_consolidatedSalesInvoice.invoices', []),
     'entityId')
  }
}
