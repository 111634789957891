import * as firebase from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyBVqd-Z1aMIBpVUz2CRJ1v5xcufgQr8ehc',
  authDomain: 'loaddocs-api.firebaseapp.com',
  databaseURL: 'https://loaddocs-api.firebaseio.com',
  projectId: 'loaddocs-api',
  storageBucket: 'loaddocs-api.appspot.com',
  messagingSenderId: '617518490024',
  appId: '1:617518490024:web:fb5167d50073366e6a3d9d',
}

export const FirebaseApp = firebase.initializeApp(firebaseConfig)
