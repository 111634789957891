import classNames from 'classnames'
import React from 'react'

// tslint:disable-next-line:max-line-length
import { DateRangePicker, IDateRangePickerProps } from 'browser/components/atomic-elements/atoms/date-picker/date-range-picker'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'

interface IDateRangePickerPopoverProps extends IDateRangePickerProps {
  onClose?: () => void
  preContent?: any
}

export class DateRangePickerPopover extends React.PureComponent<IDateRangePickerPopoverProps, any> {
  public render() {
    return (
      <Popover
        className={classNames('collapse', this.props.className)}
      >
        { this.props.preContent }
        <DateRangePicker
          {...this.props}
          onChange={this.handleChange}
          showInputFields={true}
        />
      </Popover>
    )
  }

  private handleChange = (props, isShortcutClicked) => {
    const { onChange, onClose } = this.props

    onChange(props)

    if (isShortcutClicked) {
      onClose()
    }
  }
}
