import { Collapse } from '@blueprintjs/core'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { CityQuery } from 'browser/components/atomic-elements/atoms/geo-region-input/city-select/city-query'
import { CitySelect } from 'browser/components/atomic-elements/atoms/geo-region-input/city-select/city-select'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { QuantityInput } from 'browser/components/atomic-elements/atoms/input/quantity-input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { SelectList } from 'browser/components/atomic-elements/atoms/select/select-list'
import { CheckboxField } from 'browser/components/atomic-elements/molecules/fields/checkbox-field'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { Query } from 'shared-libs/models/query'
import { CityFilterItem } from './city-filter-item'

export interface IGeolocationFilterCityTabPanelProps extends IBaseProps {
  activeFilters: any[]
  entitySchema: object
  onClear?: () => void
  onClose?: () => void
  onChange: (value: any) => void
  onResize?: () => void
  value: any
}

export class GeolocationFilterCityTabPanel
  extends React.Component<IGeolocationFilterCityTabPanelProps, any> {

  private filterKey: string
  private cityQuery: CityQuery

  constructor(props) {
    super(props)
    const { activeFilters, entitySchema, value } = props
    this.filterKey = value.key || value.path
    this.state = {
      isSearching: false,
    }
    this.cityQuery = new CityQuery(
      apis, entitySchema.id, activeFilters, this.filterKey)
  }

  public render() {
    const { isSearching } = this.state
    return (
      <div>
        <FormGroup>
          <CitySelect
            clearable={true}
            isHorizontalLayout={false}
            className={classNames('c-geoRegionInput-cityPicker', {
              'c-geoRegionInput-cityPicker--bottomSquare': false,
              'c-geoRegionInput-cityPicker--topSquare': true,
            })}
            onChange={this.handleSelectCity}
            onLoadOptions={this.handleLoadOptions}
            optionLabelPath='countDisplay'
            optionValuePath='placeId'
            placeholder='Search for a city'
          />
        </FormGroup>
        <div>
          {this.renderCityFilterItems()}
        </div>
      </div>
    )
  }

  private renderCityFilterItems() {
    const { onChange, value } = this.props
    const selection = this.getSelection()
    const unitOptions = [{ label: 'Miles', value: 'miles' }]
    return _.map(selection, (item: any, index) => {
      const handleRemoveItem = () => {
        _.remove(value.values, item)
        onChange(value)
      }
      const displayName = `${item.locality} ${item.region}`
      const distance = { value: item.distance, unit: item.distanceUnit }
      return (
        <div
          className='flex items-end pa3 u-darkBorderBottom'
          key={index}
        >
          <div className='flex-grow w-100'>
            <Label className='c-label--heading pb2'>
              {displayName}
            </Label>
            <FormTable>
              <FormGroup isHorizontalLayout={true}>
                <Label
                  className='c-label--isHorizontalNarrow'
                  isHorizontalLayout={true}
                >
                  Radius
                </Label>
                <QuantityInput
                  className='c-geoRegionInput-cityRadius'
                  defaultUnit='miles'
                  onChange={this.handleRadiusChange}
                  unitOptions={unitOptions}
                  value={distance}
                />
              </FormGroup>
            </FormTable>
          </div>
        </div>
      )
    })
  }

  private isOptionSelected(option) {
    const { value } = this.props
    const selection = this.getSelection()
    return _.find(selection, (item) => {
      return option.locality === item.locality && option.region === item.region
    })
  }

  private getSelection() {
    const { value } = this.props
    if (value.type === 'matchLanePoint') {
      return value.values || []
    } else if ((value.type === 'geoDistance' && value.center) || (value.filterType === 'geoDistance')) {
      return [value]
    }
    return []
  }

  private handleLoadOptions = (query) => {
    return this.cityQuery.queryLocationOptions(query).then((options) => {
      return _.filter(options, (option) => !this.isOptionSelected(option))
    })
  }

  private handleSelectCity = (option) => {
    const { onChange, value } = this.props
    if (this.isOptionSelected(option)) { return }

    const displayName = `${option.locality} ${option.region}`
    const localitySearchValue = `${option.locality}--${option.region}`
    onChange({
      center: option.geolocation,
      displayName: `${displayName} | Radius: ${0}`,
      distance: 0,
      distanceUnit: 'miles',
      filterType: 'geoDistance',
      key: this.filterKey,
      label: value.label,
      locality: option.locality,
      path: `${this.filterKey}.localityRegion`,
      region: option.region,
      type: `match`,
      value: localitySearchValue,
    })

    // const selection = this.getSelection()
    // selection.push({
    //   center: option.geolocation,
    //   displayName,
    //   lanePointBucketType: 'localityRegion',
    //   locality: option.locality,
    //   region: option.region,
    // })
    // onChange({
    //   label: value.label,
    //   path: this.filterKey,
    //   type: 'matchLanePoint',
    //   values: selection,
    // })

    // const firstSelection: any = selection[0]
    // if (selection.length === 0) {
    //   onChange(null)
    // } else if (selection.length === 1 && !_.isEmpty(firstSelection.radius)) {
    //   const radius = firstSelection.radius
    //   onChange({
    //     center: firstSelection.geolocation,
    //     displayName: `${option.displayName} | Radius: ${radius}`,
    //     distance: parseFloat(radius),
    //     distanceUnit: 'miles',
    //     key: this.filterKey,
    //     label: value.label,
    //     path: `${this.filterKey}.geolocation`,
    //     type: 'geoDistance',
    //   })
    // } else {
    //   const values = _.map(selection, (item: any) => ({
    //     displayName: item.displayName,
    //     lanePointBucketType: 'localityRegion',
    //     locality: item.locality,
    //     region: item.region,
    //   }))
    //   onChange({
    //     label: value.label,
    //     path: this.filterKey,
    //     type: 'matchLanePoint',
    //     values,
    //   })
    // }
  }

  private handleRadiusChange = (quantity) => {
    const { onChange, value } = this.props
    const displayName = `${value.locality} ${value.region}`
    if (!quantity || parseFloat(quantity.value) === 0) {
      onChange({
        ...value,
        displayName: `${displayName} | Radius: 0`,
        distance: quantity ? 0 : undefined,
        path: this.filterKey + '.localityRegion',
        type: 'match',
      })
    } else {
      const valueAsFloat = parseFloat(quantity.value)
      onChange({
        ...value,
        // Need to use the parsed valueAsFloat here since quantity.value is '07' when transitioning from '0' to '7'
        displayName: `${displayName} | Radius: ${valueAsFloat}`,
        distance: valueAsFloat,
        path: this.filterKey + '.geolocation',
        type: 'geoDistance',
      })
    }
  }

  private renderOption = (option) => {
    return (
      <span>{option.displayName} ({option.numLoads})</span>
    )
  }
}
