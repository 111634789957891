import _ from 'lodash'
import React, { useState } from 'react'

import { getKeyComboString, KeyComboTag } from '@blueprintjs/core'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { ErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/error-block'
import classNames from 'classnames'

interface ITableImagingHotkeyCellProps extends IBaseProps {
  errors: any[]
  onChange: (value: string) => void
  value?: any
}

export const TableImagingHotkeyCell: React.FC<ITableImagingHotkeyCellProps> = (props) => {
  const { errors, onChange, value } = props
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const handleStopPropgagation = (e) => {
    e.stopPropagation()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    let combo = getKeyComboString(e.nativeEvent as KeyboardEvent)
    if (e.nativeEvent.key === 'Tab' ||
      combo === 'shift + s' ||
      combo === 'shift + n' ||
      combo === '/') {
      return
    } else if (e.nativeEvent.key === 'Escape') {
      combo = null
    }
    onChange(combo)
  }

  const handleOnBlur = () => {
    setIsFocused(false)
  }

  const handleOnFocus = () => {
    setIsFocused(true)
  }

  const renderErrors = () => {
    if (!_.isEmpty(errors)) {
      if (_.includes(errors[0], 'is required')) {
        return (
          <div className='pl3 pt1'>
            <ErrorBlock errorText='Is required' />
          </div>
        )
      }
      if (_.includes(errors[0], 'is not unique')) {
        return (
          <div className='pl3 pt1'>
            <ErrorBlock errorText='Is not unique' />
          </div>
        )
      }
    }
  }

  const renderKeyCombo = (): React.ReactNode => {
    if (_.isEmpty(value)) {
      if (isFocused) {
        return (
          <div className='c-placeholderColor u-innerBumperLeft--sm u-innerBumperTop--xs u-innerBumperBottom--xs'>
            Enter key for hotkey
          </div>
        )
      } else {
        return (
          <div className='c-placeholderColor u-innerBumperLeft--sm u-innerBumperTop--xs u-innerBumperBottom--xs'>
            Click here to add hotkey
          </div>
        )
      }
    }
    return (
      <div className='c-placeholderColor u-innerBumperLeft--sm u-innerBumperTop--xxs u-innerBumperBottom--xxs'>
        <KeyComboTag combo={value} />
      </div>
    )
  }

  return (
    <div
      onClick={handleStopPropgagation}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      className={classNames('', {
        'c-field--focus': isFocused,
      })}
    >
      {renderKeyCombo()}
      {renderErrors()}
    </div>
  )
}
