import React from 'react'

const TruckImage = require('images/truck.png')

import './_truck-loader.scss'
import { globalTranslationTable } from 'browser/mobile/util/global-translation-table'
import { translateString } from 'shared-libs/helpers/utils'

interface ITruckLoaderProps {
  label?: string
}

const translatedMessage = translateString('Loading_Up', globalTranslationTable)

export const TruckLoader = (props: ITruckLoaderProps) => {
  const { label } = props
  return (
    <div className="truck-loader">
      <div className="truck-loader__visual">
        <div className="truck-loader__streaks">
          <div className="truck-loader__streak truck-loader__streak-1" />
          <div className="truck-loader__streak truck-loader__streak-2" />
          <div className="truck-loader__streak truck-loader__streak-3" />
          <div className="truck-loader__streak truck-loader__streak-4" />
        </div>
        <img className="truck-loader__image" src={TruckImage} />
      </div>
      {label && <p className="truck-loader__label">{props.label}</p>}
    </div>
  )
}

TruckLoader.defaultProps = {
  label: translatedMessage,
}
