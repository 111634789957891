import _ from 'lodash'

const INTERNATIONAL_PREFIX = '+'
const EMPTY_STRING = ''

export function isPhoneNumberOnlyPrefix(phoneNumber: string): boolean {
  return phoneNumber === INTERNATIONAL_PREFIX
}

// react-phone-input-2 leaves a '+' sign when clearing, 
//   we should treat it as empty state anyway
export function normalizePhoneNumberIfEmpty(phoneNumber: string): string {
  if (_.isEmpty(phoneNumber) || isPhoneNumberOnlyPrefix(phoneNumber)) {
    return EMPTY_STRING
  } else {
    return phoneNumber
  }
}
