import React from 'react'
import 'browser/mobile/styles/_control.scss'
import 'browser/mobile/components/footer/_mobile-footer.scss'
import { MobileTimePicker } from '../time-picker/mobile-time-picker'
import { MobileTimezonePicker } from '../time-picker/mobile-timezone-picker'
import { DateField } from 'browser/components/atomic-elements/molecules/fields/date-field'
import {
  IDateInputProps,
  ITimeComponentProps,
  ITimezoneComponentProps,
} from 'browser/components/atomic-elements/atoms/input/date-input/date-input'

/**
 * @uiComponent
 */
interface IMobileDateFieldProps extends IDateInputProps {
  minutePrecision?: number
}

export class MobileDateField extends React.Component<IMobileDateFieldProps> {
  public render() {
    return (
      <DateField
        {...this.props}
        daySize={44}
        inputClassName="mobile-control content-center"
        TimezoneInputComponent={this.renderTimezonePicker}
        TimeInputComponent={this.renderTimePicker}
      />
    )
  }

  private renderTimePicker = (props: ITimeComponentProps) => {
    const { minutePrecision } = this.props

    return (
      <MobileTimePicker
        {...props}
        className="mobile-control content-center"
        minutePrecision={minutePrecision}
      />
    )
  }

  private renderTimezonePicker = (props: ITimezoneComponentProps) => {
    return (
      <MobileTimezonePicker
        {...props}
        className="content-center"
      />
    )
  }
}
