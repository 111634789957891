import { Classes, Icon } from '@blueprintjs/core'
import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import 'browser/components/atomic-elements/atoms/card/_card-header-item.scss'

/**
 * @uiComponent
 */
interface ICardHeaderItemProps extends IBaseProps {
  superTitle?: string
  title?: any // but should be string || HTMLElement
  subtitle?: string
  label?: string
  description?: string | JSX.Element
  description2?: string | JSX.Element
  email?: string
  icon?: any
  onClick?: () => void
  onLabelClick?: () => void
}

export class CardHeaderItem extends React.Component<ICardHeaderItemProps, any> {
  public render() {
    const { children, className } = this.props
    return (
      <div className={classNames('c-cardHeader-item', className)}>
        {this.renderSuperTitle()}
        {this.renderTitle()}
        {this.renderSubtitle()}
        {this.renderIcon()}
        {children}
        {this.renderLabel()}
        {this.renderDescription()}
      </div>
    )
  }

  private renderSuperTitle() {
    const { superTitle } = this.props
    if (superTitle) {
      return (
        <h2
          className='c-cardHeader-superTitle u-ellipsis'
          data-debug-id={`cardHeaderItemTitle:${superTitle}`}
        >
          {superTitle}
        </h2>
      )
    }
  }

  private renderTitle() {
    const { title } = this.props
    if (title) {
      return (
        <h3
          className='c-cardHeader-title u-ellipsis'
          data-debug-id={`cardHeaderItemTitle:${title}`}
        >
          {title}
        </h3>
      )
    }
  }

  private renderSubtitle() {
    const { subtitle } = this.props
    if (subtitle) {
      return (
        <h4
          className='c-cardHeader-subtitle u-ellipsis'
          data-debug-id={`cardHeaderItemSubtitle:${subtitle}`}
        >
          {this.props.subtitle}
        </h4>
      )
    }
  }

  private renderLabel() {
    const { label, onLabelClick } = this.props
    if (label) {
      return (
        <div className='c-cardHeader-label u-ellipsis'>
          {onLabelClick ?
            (<a onClick={onLabelClick}>{label}</a>) :
            (label)}
        </div>
      )
    }

  }

  private renderDescription() {
    // TODO - sometimes description gets ignored. i don't know why, this is a hack.
    const { description, description2 } = this.props
    const descriptionToShow = description || description2
    if (descriptionToShow) {
      return (
        <div className='c-label c-label--secondary c-cardHeader-description u-ellipsis'>
          {descriptionToShow}
        </div>
      )
    }
  }

  private renderIcon() {
    const { icon, onClick } = this.props
    if (icon) {
      return (
        <Button
          className={classNames('c-button--icon', Classes.MINIMAL)}
          onClick={onClick}
        >
          <Icon icon={icon} />
        </Button>
      )
    }
  }
}
