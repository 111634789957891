import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'

const FIRM_ADMIN_SCHEMA_ID = '11111111-0000-0000-0000-000000000015'
const MANAGER_SCHEMA_ID = '17c8c85e-8ba3-40f9-92ff-4153ff0875c4'

const ROLE_OPTIONS = [
  { label: 'Member', value: 'Member' },
  { label: 'Manager', value: MANAGER_SCHEMA_ID },
  { label: 'Admin', value: FIRM_ADMIN_SCHEMA_ID },
]
export class RoleSelectField extends React.Component<any, any> {

  public render() {
    const { entity, label } = this.props
    const value = this.getValue(entity)
    return (
      <SelectField
        isNative={true}
        isHorizontalLayout={true}
        label={label}
        onChange={this.handleChange}
        options={ROLE_OPTIONS}
        value={value}
      />
    )
  }

  private getValue(entity) {
    if (_.find(entity.activeMixins, { entityId: FIRM_ADMIN_SCHEMA_ID })) {
      return FIRM_ADMIN_SCHEMA_ID
    } else if (_.find(entity.activeMixins, { entityId: MANAGER_SCHEMA_ID })) {
      return MANAGER_SCHEMA_ID
    }
    return 'Member'
  }

  private handleChange = (schemaId) => {
    const { entity, onChange } = this.props
    _.remove(entity.activeMixins, (mixin: any) => {
      return mixin.entityId === FIRM_ADMIN_SCHEMA_ID ||
          mixin.entityId === MANAGER_SCHEMA_ID
    })
    if (schemaId !== 'Member') {
      const store = apis.getStore()
      const firmSchema = store.getRecord(schemaId)
      entity.addMixin(firmSchema)
    }
    if (onChange) { onChange() }
    this.forceUpdate()
  }
}
