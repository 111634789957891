import React from 'react'
import _ from 'lodash'

/**
 * @uiComponent
 */
interface IPermissionStatusProviderProps {
  permission: PermissionName
  onChange: (value: any) => void
  forceUpdate: () => void
}

export class PermissionStatusProvider extends React.PureComponent<IPermissionStatusProviderProps> {
  private permissionStatus: PermissionStatus

  componentDidMount() {
    this.listenForPermissionChanges()
  }

  componentWillUnmount(): void {
    if (this.permissionStatus) {
      this.permissionStatus.onchange = null
    }
  }

  render() {
    // NOTE: this component is only used to listen for permission changes, so
    // there's no need to render anything.
    return null
  }

  private listenForPermissionChanges() {
    const { permission, onChange, forceUpdate } = this.props

    void navigator.permissions?.query({ name: permission }).then((permissionStatus: PermissionStatus) => {
      this.permissionStatus = permissionStatus
      onChange(permissionStatus.state)
      forceUpdate()

      permissionStatus.onchange = () => {
        onChange(permissionStatus.state)
        forceUpdate()
      }
    })
  }
}
