import React from 'react'
import _ from 'lodash'
import Immutable from 'immutable'

import { FramesManager } from './view/frames-manager'
import { Formatter } from '../helpers/formatter'
import { evaluateExpressionWithScopes, EsprimaParser } from '../helpers/evaluation'
import { JSONSchemaResolver } from '../resolvers/json-schema-resolver'
import { CustomFormulas } from '../helpers/formulas'
import { edgeFromEntity } from '../helpers/formulas/modules/entity'
import { FormulaComponentRegistry } from './entity/formula-component-registry'
import {
  FormulaBatchProcessor,
  IFormulaTaskProvider,
  IFormulaTaskResult,
} from './entity/formula-batch-processor'
import { FormulaModel } from './entity/formula-model'
import { UIPropMappingContext } from './context/ui-prop-mapping-context'

// Remember to fix this:
// TODO(Peter): this.props.path -> this.props.valuePath

export interface IAbstractRendererProps {
  actions?: any
  componentsMap: any
  context?: object
  schema?: any
  state?: any
  translationTable?: any
  /**
   * Allow the caller to control the UI element creation.  This is used by
   * storyboard UI to simplify a complex view
   */
  uiSchemaFilter?: (uiSchema: any) => boolean
  onLayout?: (event: any) => void
  /**
   * Optional error handler for the renderer. If not provided, the error will be
   * thrown (preserving original behavior). This was added to avoid the RedBox
   * in react-native, which unfortunately is not suppressed by
   * componentDidCatch.
   */
  onError?: (error: any) => void
}

export interface IAbstractRendererState {
  extras: any
}

const MIN_FORMULA_REFRESH_TIMEOUT_MS = 1000

export class AbstractRenderer<
  P extends IAbstractRendererProps,
  S extends IAbstractRendererState
> extends React.Component<P, S> {
  // using a static context here, since composing via rendering is a tougher
  // refactor; also see note in with-context about subclassing limitations.
  static contextType = UIPropMappingContext
  declare context: React.ContextType<typeof UIPropMappingContext>

  private formulaRefreshTimeoutDuration: number = MIN_FORMULA_REFRESH_TIMEOUT_MS
  private formulaBatchProcessor: FormulaBatchProcessor
  private formulaRefreshTimeoutHandle
  private formulaModel: FormulaModel
  protected formulaComponentRegistry: FormulaComponentRegistry
  protected transientVariables = {}
  protected memoizedFormulas = new Map<Function, Function>()
  protected trackedRefsBySchemaPath: any
  protected trackedRefsByValuePath: any

  constructor(props) {
    super(props)

    this.formulaBatchProcessor = new FormulaBatchProcessor()
    this.formulaComponentRegistry = new FormulaComponentRegistry(() =>
      this.handleFormulaComponentRegistrationChange()
    )
    this.formulaModel = new FormulaModel()
    this.formulaRefreshTimeoutHandle = null
    this.trackedRefsBySchemaPath = {}
    this.trackedRefsByValuePath = {}
  }

  public componentWillUnmount() {
    this.cancelPendingFormulaWork()

    // mark the handle as undefined to use as flag to terminate formula refresh timer
    this.formulaRefreshTimeoutHandle = undefined

    // clear memoized functions
    this.memoizedFormulas?.forEach((memoizedFormula, formula) => (memoizedFormula as any).cache?.clear())
    this.memoizedFormulas?.clear()
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(this.props.context, nextProps.context) ||
      !_.isEqual(this.props.schema, nextProps.schema) ||
      !_.isEqual(this.props.state, nextProps.state)
    ) {
      this.handleFormulaComponentRegistrationChange()
    }
  }

  public setExtras = (path, value) => {
    this.setStateAtPath(`extras.${path}`, value)
  }

  public setStateAtPath = (path, value) => {
    _.set(this.state, path, value)
    this.setState({})
  }

  protected setReference(instanceRef, uiSchemaPath, valuePath) {
    _.set(this.trackedRefsBySchemaPath, [...uiSchemaPath, 'ref'], instanceRef)
    if (_.isArray(valuePath)) {
      _.set(this.trackedRefsByValuePath, [...valuePath, 'ref'], instanceRef)
    }
  }

  public createElementFromFrame(frames, defaultProps = {}) {
    const uiContext = frames.getContext('uiContext')
    const uiSchemaPath = frames.getContext('uiSchemaPath')
    const uiSchema = frames.getContext('uiSchema') ||  _.get(uiContext, uiSchemaPath, uiContext)
    if (uiSchema.type === 'ui:outlet') {
      return this.props.children
    }

    const { uiSchemaFilter } = this.props
    if (uiSchemaFilter && !uiSchemaFilter(uiSchema)) {
      return this.createEmptyElement(frames)
    }

    try {
      const { component, componentProps } = this.makeComponentProps(frames, defaultProps)
      const children = this.makeChildren(componentProps, uiSchema, uiSchemaPath)
      return React.createElement(component, componentProps, children)
    } catch (e) {
      console.error(`Failed to create component: ${e}`)
      return this.createEmptyElement(frames)
    }
  }

  public render() {
    const { onError } = this.props
    const frameInfo = this.makeRootScope()
    try {
      return this.createElementFromFrame(frameInfo, { onLayout: this.props?.onLayout })
    } catch (error) {
      const serializable = this.getFrameInfo(frameInfo)
      const errorExtraInfo = {
        frameInfo: serializable,
      }
      error.extraInfo = errorExtraInfo

      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }
  }

  protected getFrameInfo = (frame: any) => {
    const frames = frame.frames.toJS()
    return _.map(frames, (frame) => {
      return frame.context?.uiContext?.content || {}
    })
  }

  // pathFragment is the partial path string that forms the uiSchemaPath
  // object is the object that we want to materialize
  protected materializeUiSchemaProp(frames, pathFragment, object) {
    if (this.isUIElement(object)) {
      // if object is an ui schema element, create element from props
      return this.makeUIElementFromProps(frames, pathFragment, object)
    } else if (this.isImageElement(object)) {
      return this.makeImageElementFromProps(frames, pathFragment, object)
    } else if (this.isExpression(object)) {
      return this.evaluateExpression(frames, object)
    } else {
      return this.materializePropMappings(frames, object)
    }
  }

  // materialize props for the uiSchema object
  // this includes creating uiElement or uiImage on the props
  protected materializeUiSchemaProps(frames, object) {
    const { getPropMappingStrategy } = this.context
    // these are keywords to omit from props
    const omitProps = ['children', 'type', 'mapActionsToProps', 'mapContextToProps', 'mapStateToProps']
    const props = _.omit(object, omitProps)
    _.forEach(props, (value, key) => {
      // we do not want to process these keywords
      // e.g. ui:conditionals, ui:list, itemTemplate,
      if (key === 'conditionals'
         || key === 'items'
         || key.endsWith('Template')
         || key.endsWith('Passthrough')
         || key.startsWith('lazy')
         ) {
        return
      }

      // map the value if needed
      const getMappedValue = getPropMappingStrategy?.(object, key, value)
      if (getMappedValue) {
        value = getMappedValue()
        props[key] = value
      }

      // in the case of an array, we need to process each item in it
      if (_.isArray(value)) {
        props[key] = _.map(value, (item, index) => {
          const isPlainObject = _.isPlainObject(item)
          return isPlainObject ? this.materializeUiSchemaProp(frames, index, item) : item
        })
      } else if (_.isPlainObject(value)) {
        props[key] = this.materializeUiSchemaProp(frames, key, value)
      }
    })
    return this.mapActionContextStateToProps(frames, props, object)
  }

  protected makeImageElementFromProps(frames, key, schema) {
    const { componentsMap } = this.props
    const imageElement = componentsMap[schema.type]
    if (!imageElement) {
      throw new Error(`image=${schema.type} does not exist`)
    }
    return imageElement
  }

  protected makeUIElementFromProps(frames, key, childSchema) {
    const uiSchemaPath = frames.getContext('uiSchemaPath')
    const childPath = uiSchemaPath.concat([key])
    const childScope = this.createChildFrame(frames, {
      uiSchema: childSchema,
      uiSchemaPath: childPath,
    })
    // The children schema is not provided, returning
    return this.createElementFromFrame(childScope)
  }

  protected makeRootScope() {
    const { actions, schema, state, translationTable={} } = this.props
    const { navigation, extras } = state
    const rootState = [{ ...state, extras }, { Formatter }, this.transientVariables, this.memoizedFormulas]
    const mergedTranslationTable = _.merge({}, translationTable, schema.translationTable)
    const rootScope = Immutable.Map({
      actions,
      context: {
        navigation,
        renderer: this,
        uiContext: schema,
        uiSchema: schema,
        uiSchemaPath: [],
        translationTable: mergedTranslationTable,
      },
      key: '',
      state: rootState,
      ref: {},
    })
    return new FramesManager(Immutable.List.of(rootScope))
  }

  protected makeChildren(props, uiSchema, uiSchemaPath) {
    const { frames, translationTable } = props
    // it is important to return undefined, when there are no children
    // otherwise we get errors relating to `TextInput prop `children` is only
    // supported with multiline`
    if (_.isEmpty(uiSchema.children)) return undefined
    if (!_.isArray(uiSchema.children)) {
      console.log("'children' property in a UI schema must be provided as an array, please check that you haven't passed an object, string, or otherwise.")
      return undefined
    }

    return _.map(uiSchema.children, (childSchema, index) => {
      const childPath = uiSchemaPath.concat(['children', index])
      const childScope = this.createChildFrame(frames, {
        uiSchema: childSchema,
        uiSchemaPath: childPath,
        translationTable: _.merge({}, translationTable, childSchema?.translationTable)
      })
      return this.createElementFromFrame(childScope)
    })
  }

  protected createEmptyElement(frames) {
    const { componentsMap } = this.props
    return React.createElement(componentsMap['ui:view'], { key: frames.getKey()})
  }

  protected makeComponentProps(frames, defaultProps) {
    const uiContext = frames.getContext('uiContext')
    const uiSchemaPath = frames.getContext('uiSchemaPath')
    const uiSchema = frames.getContext('uiSchema') || _.get(uiContext, uiSchemaPath, uiContext)
    const translationTable = _.merge({}, frames.getContext('translationTable'), uiSchema.translationTable) || {}
    const component = this.props.componentsMap[uiSchema.type]
    if (!component) {
      throw new Error(`[Renderer]: Cannot find component with type=${uiSchema.type}, path=${uiSchemaPath}`)
    }
    const uiSchemaProps = this.materializeUiSchemaProps(frames, uiSchema)
    // TODO(Peter): typescript keep complaining context is not of object when it is
    const context: any = this.props.context

    // get current transient value, if any
    const transientValue = uiSchema.transientValue
    const value = transientValue ? frames.getValue(transientValue) : undefined
    const transientProps = { value }

    // any onChange provided by `uiSchemaProps` should take precedence over the
    // default, e.g. mobile's specialized LoadInformationSection that handles
    // its own change events.
    defaultProps = {
      ...defaultProps,
      onChange: (newValue, silentUpdate = false) => {
        this.handleChange(uiSchema.transientValue, newValue, value, silentUpdate, /* isTransient */ true)
      },
    }

    const componentProps = {
      ...transientProps,
      ...defaultProps,
      ...context,
      ...uiSchemaProps,
      translationTable,
      frames,
      key: frames.getKey(),
      formulaRegistry: this.formulaComponentRegistry,
      ref: this.createRefForFrame(frames),
    }
    return { component, componentProps }
  }

  protected createChildFrame(frames, context, state?) {
    const { uiSchemaPath } = context
    return frames.push(Immutable.Map({
      key: uiSchemaPath.join('.'),
      parent: frames,
      context,
      state,
      ref: {},
    }))
  }

  public isExpression(schema) {
    return schema.type === 'expression'
  }

  public isImageElement(schema) {
    return _.get(schema, 'type', '').startsWith('image:')
  }

  public isUIElement(schema) {
    const hasRef = schema[JSONSchemaResolver.REF_KEY]
    const hasTypeUI = _.get(schema, 'type', '').startsWith('ui:')
    return hasRef || hasTypeUI
  }

  public materializePropObj(frames, object) {
    const blackListProps = ['mapActionsToProps', 'mapContextToProps', 'mapStateToProps']
    const props = _.omit(object, blackListProps)

    return _.mapValues(props, value => {
      if (_.isArray(value)) {
        return this.materializePropArray(frames, value)
      } else if (this.isExpression(value)) {
        return this.evaluateExpression(frames, value)
      } else if (_.isPlainObject(value)) {
        return this.materializePropMappings(frames, value)
      } else {
        return value
      }
    })
  }

  public materializePropArray(frames, array) {
    return _.map(array, value => {
      if (_.isPlainObject(value)) {
        return this.materializePropMappings(frames, value)
      } else if (_.isArray(value)) {
        return this.materializePropArray(frames, value)
      } else {
        return value
      }
    })
  }

  public materializePropMappings(frames, object) {
    const props = this.materializePropObj(frames, object)
    return this.mapActionContextStateToProps(frames, props, object)
  }

  public mapActionContextStateToProps(frames, props, object) {
    if (object.mapActionsToProps) {
      this.mapActionsToProps(frames, props, object.mapActionsToProps)
    }
    if (object.mapContextToProps) {
      this.mapContextToProps(frames, props, object.mapContextToProps)
    }
    if (object.mapStateToProps) {
      this.mapStateToProps(frames, props, object.mapStateToProps)
    }

    return props
  }

  public createRefForFrame(frames) {
    const uiSchemaPath = frames.getContext('uiSchemaPath')
    const valuePath = frames.getContext('valuePath')
    const componentType = this.props.componentsMap[frames.getContext('uiSchema').type]

    if (_.isFunction(componentType) && componentType.prototype && !componentType.prototype.isReactComponent) {
      return
    }

    return (ref) => {
      const frameRef = frames.getRef()
      if (frameRef && ref) {
        frameRef.current = ref
      }
      this.setReference(ref, uiSchemaPath, valuePath)
    }
  }

  public mapActionsToProps(frames, props, mapping) {
    _.forEach(mapping, (value, key) => {
      let action = frames.getAction(value)
      if (!action) {
        action = this.createGlobalActionForProps(frames, value, key)
      }
      if (!_.isFunction(action)) {
        console.warn(`Function with name=${value} is not found`)
      }
      _.set(props, key, action)
    })
  }

  public createGlobalActionForProps(frames, sourceKey, targetKey) {
    if (!_.isObject(sourceKey) || sourceKey['type'] !== "expression") {
      // warning
      return undefined
    }

    return (contextArgs) => {
      // init w/ global actions
      const actionsFromFrames = {
        setExtras: this.setExtras,
        setStateAtPath: this.setStateAtPath,
        edgeFromEntity: edgeFromEntity,
      }
      _.assign(actionsFromFrames, frames.getActions())

      return evaluateExpressionWithScopes(frames, sourceKey['value'], {
        ...CustomFormulas,
        ...actionsFromFrames,
        contextArgs,
      }, EsprimaParser)
    }
  }

  public mapContextToProps(frames, props, mapping) {
    _.forEach(mapping, (value, key) => {
      props[key] = frames.getContext(value)
    })
  }

  public mapStateToProps(frames, props, mapping) {
    _.forEach(mapping, (value, key) => {
      if (_.isString(value)) {
        props[key] = frames.getValue(value)
      } else if (this.isExpression(value)) {
        props[key] = this.evaluateExpression(frames, value)
      }
    })
  }

  protected evaluateExpression(frames, value) {
    const expression = _.get(value, 'expression') || _.get(value, 'value')
    return evaluateExpressionWithScopes(frames, expression, {...CustomFormulas})
  }

  /* formula processing and transient value handling */

  protected handleChangesComplete(results: IFormulaTaskResult[], userEdited = false) {
    this.forceUpdate()
  }

  protected setValue = (path, newValue, oldValue, silentUpdate = false): boolean => {
    return false
  }

  // immediate response to user edits
  protected handleChange = (
    path,
    newValue,
    oldValue,
    silentUpdate = false,
    isTransient = false
  ) => {
    this.handleFormulaComponentRegistrationChangeDebounced.cancel()
    this.cancelPendingFormulaWork()

    // only transient values are supported in the abstract-renderer
    if (isTransient) {
      const userEdited = this.setTransientValue(path, newValue, oldValue)
      this.handleChangeDebounced(userEdited)
    } else {
      console.error(`A component in a view renderer triggered its onChange, but no 'transientValue' was specified in the UI schema, so the value won't be captured. 'value' won't work in this renderer type, so please use the 'transientValue' to specify a variable name (or nested path) if you need to capture this component's value in a temporary variable.`)
    }
  }

  // debounce execution upon user input
  protected handleChangeDebounced = _.debounce((userEdited) => {
    this.batchProcessFormulas()
      .then((results) => {
        this.handleChangesComplete(results, userEdited)
      })
      .catch(_.noop)
  }, 200)

  // immediate response to formula component mounting/props changes
  protected handleFormulaComponentRegistrationChange = () => {
    this.handleChangeDebounced.cancel()
    this.cancelPendingFormulaWork()
    this.handleFormulaComponentRegistrationChangeDebounced()
  }

  // debounce execution upon formula component mount/unmount
  protected handleFormulaComponentRegistrationChangeDebounced = _.debounce(() => {
    this.batchProcessFormulas()
      .then((results) => {
        this.handleChangesComplete(results)
      })
      .catch(_.noop)
  }, 25)

  protected cancelPendingFormulaWork() {
    this.formulaBatchProcessor.cancel()
    clearTimeout(this.formulaRefreshTimeoutHandle)
    this.formulaRefreshTimeoutHandle = null
  }

  private batchProcessFormulas = (): Promise<IFormulaTaskResult[]> => {
    const registeredFields = this.formulaComponentRegistry.getRegisteredFields()
    const formulaTaskProviders: IFormulaTaskProvider[] = [
      this.formulaComponentRegistry,
      this.formulaModel,
    ]
    const tasks = _.flatMap(formulaTaskProviders, (provider) => provider.getFormulaTasks())
    const valueSetter = (result: IFormulaTaskResult) => {
      return this.setValueFromFormulaResult(result)
    }
    return this.formulaBatchProcessor.run(tasks, valueSetter).then((results) => {
      _.forEach(results, (result: IFormulaTaskResult) => {
        // Distribute results back up to UI value refs, if relevant
        const componentInfo = registeredFields[result.task.id]
        if (componentInfo) {
          componentInfo.valueRef.current = result.value
        }
      })
      return results
    })
  }

  protected startFormulaRefreshTimer(hasChanges: boolean): void {
    if (this.formulaRefreshTimeoutHandle === undefined) {
      // component is unmounting, don't start another timer
      return
    }
    // reset or backoff the refresh timer
    this.formulaRefreshTimeoutDuration = hasChanges
      ? MIN_FORMULA_REFRESH_TIMEOUT_MS
      : Math.min(30000, this.formulaRefreshTimeoutDuration * 2)

    // Re-compute formulas after a delay, in case of any formulas that
    // repeatedly update the document, in order to continue supporting scenarios
    // such as conditional views updating in response to some time-varying value
    // (datetimes and durations based on "now" are good examples, such as
    // formulas that contain "NOW_UTC()").
    this.formulaRefreshTimeoutHandle = setTimeout(() => {
      this.batchProcessFormulas()
        .then((results) => {
          this.handleChangesComplete(results, false)
        })
        .catch(_.noop)
    }, this.formulaRefreshTimeoutDuration)
  }

  protected setTransientValue = (path, newValue, oldValue) => {
    if (newValue === oldValue && !_.isObject(newValue)) {
      return false
    }
    _.set(this.transientVariables, path, newValue)
    this.forceUpdate()
    return true
  }

  public setMemoizedFormula = (formula, memoizedFormula) => {
    this.memoizedFormulas.set(formula, memoizedFormula)
  }

  public getMemoizedFormula = (formula) => {
    return this.memoizedFormulas.get(formula)
  }

  // Wraps setValue for formulas, to preserve previous logic in all code paths
  // of legacy formula UI components.
  private setValueFromFormulaResult = (result: IFormulaTaskResult): boolean => {
    const { isComputedProperty, computedName, defaultValue } = result.task.schema
    const { valuePath, originalValue, schema } = result.task
    const { transientValue } = schema
    const value = _.isNil(result.value) && valuePath ? defaultValue : result.value
    const isSilent = isComputedProperty
    let formulaEdited = false

    if (transientValue) {
      formulaEdited = formulaEdited || this.setTransientValue(transientValue, value, originalValue)
    } else if (valuePath) {
      formulaEdited = formulaEdited || this.setValue(valuePath, value, originalValue, isSilent)
    }

    if (isComputedProperty && computedName) {
      console.log('computedName is legacy, use transientValue instead')
      formulaEdited = formulaEdited || this.setValue(computedName, value, originalValue, isSilent)
    }
    return formulaEdited
  }

}

