import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import apis from 'browser/app/models/apis'
import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Carousel } from 'browser/components/atomic-elements/atoms/carousel/carousel'
import { ImageEditor } from 'browser/components/atomic-elements/organisms/image-editor'
import 'browser/components/atomic-elements/organisms/image-editor-carousel/_image-editor-carousel.scss'
import { EditControls } from 'browser/components/atomic-elements/organisms/image-editor-carousel/edit-controls'
import { flattenAttachment } from './utils'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { Entity } from 'shared-libs/models/entity'
import { applyPerspective, grayscaleFile } from 'browser/mobile/util/page-utils'
import { withContext } from 'shared-libs/components/context/with-context'
import { ComponentsContext, IComponentsContext } from 'browser/contexts/components/components-context'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'

/**
 * @uiComponent
 */
export interface IImageEditorCarouselProps extends IBaseProps {
  entity: any
  imagesPath: string
  index: number
  isEditable?: boolean
  isExportable?: boolean
  isInModal?: boolean
  showHeader?: boolean
  editControls?: React.ReactElement
  isImagingCarousel?: boolean
  onClose: () => void
  onCarouselIndexChange?: any
  padding?: number
  schema: any
  showOriginal: boolean
  useWebGLPreview?: boolean
}

interface ContextProps {
  componentsContext?: IComponentsContext
}

@withContext(ComponentsContext, 'componentsContext')
export class ImageEditorCarousel extends React.Component<IImageEditorCarouselProps & ContextProps, any> {
  public static defaultProps: Partial<IImageEditorCarouselProps> = {
    index: 0,
    isImagingCarousel: false,
    padding: 24,
    showHeader: true,
  }

  constructor(props) {
    super(props)

    const [ images, attachmentsByIndex ] = flattenAttachment(this.attachment, props.schema, props.entity)

    this.state = {
      carouselIndex: props.index,
      height: 0,
      images: images,
      attachmentsByIndex: attachmentsByIndex,
      isInEditMode: false,
      width: 0,
      zoomLevel: 0,
    }
  }

  public componentDidMount() {
    // TODO(peter/louis): Find a better solution to this but we need to hide
    // the detail panel when the image editor modal is open so that it doesn't
    // get included when we print the modal image. PRINTHACK
    $('.js-documentDetailCard').addClass('u-noPrint')
  }

  public componentWillUnmount() {
    // TODO(peter/louis): Find a better solution to this but we undo the
    // 'hiding on print'. PRINTHACK
    $('.js-documentDetailCard').removeClass('u-noPrint')
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.index !== this.props.index) {
      this.setState({carouselIndex: nextProps.index})
    }
  }

  public render() {
    const files = this.state.images
    const positionString = files.length > 0 ?
      `${this.state.carouselIndex + 1} of ${files.length}` :
      'No Files'

    return (
      <div className='grid-block vertical c-modal-card'>
        {this.renderHeader()}
        {this.renderBody()}
        <div className='c-numberOverlay c-imageView-count u-noPrint'>
          {positionString}
        </div>
      </div>
    )
  }

  private renderHeader() {
    const { showHeader } = this.props
    if (!showHeader) {
      return
    }
    return (
      <div className='grid-block shrink c-cardHeader bb b--light-gray paper u-noPrint'>
        {this.renderModalHeaderItems()}
        {this.renderEditControls()}
      </div>
    )
  }

  private renderModalHeaderItems() {
    const { entity, isInModal } = this.props
    if (!isInModal) {
      return
    }
    const creationDate = moment(entity.creationDate).format('MMM D, YYYY, h:mm A')
    const displayName = _.get(entity, 'createdBy.displayName')
    const description = _.isNil(displayName) ?
      (<div className='c-cardHeader-item-break'>{entity.entityType} · <wbr /><span style={{whiteSpace: 'nowrap'}}>{creationDate}</span></div>) :
      (<div className='c-cardHeader-item-break'>{entity.entityType} · <wbr />Uploaded by {displayName} <wbr /><span style={{whiteSpace: 'nowrap'}}>{creationDate}</span></div>)
    return (
      <div className='flex'>
        <div className='c-cardHeader-item c-cardHeader-item--alignCenter '>
          <Button
            className={Classes.MINIMAL}
            onClick={this.handleOnclose}
            data-debug-id='image-viewer-close'
          >
            <Icon
              icon={IconNames.ARROW_LEFT}
            />
          </Button>
        </div>
        <div className='c-cardHeader-item c-cardHeader-item--alignCenter u-borderRight' />
        <CardHeaderItem
          className='c-cardHeader-item--grow c-cardHeader-item-break u-textLeft'
          description={description}
          subtitle={entity.displayName}
        />
      </div>
    )
  }

  private renderEditControls() {
    const { editControls, onClose } = this.props
    if (editControls) {
      return React.cloneElement(editControls, { onClose })
    }
    return editControls ?? this.renderDefaultEditControls()
  }

  private renderDefaultEditControls() {
    const { entity, isExportable, showOriginal, padding, useWebGLPreview } = this.props
    const attachment = this.attachment
    const imageIndex = this.state.carouselIndex
    const image = this.state.images[imageIndex]

    let downloadHref = _.get(this.state, `attachmentsByIndex.${imageIndex}.uri`)
    let isEditable = this.props.isEditable
    if (!showOriginal && image) {
      isEditable = isEditable && image.isEditable
      const fileUri = _.get(attachment, 'aggregate.uri', image.getDownloadUri())
      const fileName = `${entity.displayName}.pdf`
      downloadHref = apis.getRenameDownloadURI(fileUri, fileName)
    }

    return (
        <EditControls
          downloadButtonHref={downloadHref}
          image={image}
          isEditable={isEditable}
          isExportable={isExportable}
          isInEditMode={this.state.isInEditMode}
          onChange={this.handleOnImageChange}
          onDelete={this.handleOnImageDelete}
          onSave={this.handleOnSave}
          onEdit={this.handleEdit}
          onZoom={this.handleZoom}
          padding={padding}
          useWebGLPreview={useWebGLPreview}
          width={this.state.width}
          height={this.state.height}
          zoomLevel={this.state.zoomLevel}
        />
    )
  }

  private renderBody() {
    const { images } = this.state
    return (
      <div className='grid-block'>
        <AutofillBlock
          onChange={this.handleOnBodyDimensionsChange}
        >
          <div
            className='grid-content collapse c-imageViewer-body'
            tabIndex={-1}
          >
            <div className='c-imageViewer-carousel'>
              <Carousel
                index={this.state.carouselIndex}
                numItems={images.length}
                onChange={this.handleCarouselPositionChanged}
                renderCarouselItem={this.renderCarouselContent}
                showChevrons={!this.state.isInEditMode}
              />
            </div>
          </div>
        </AutofillBlock>
      </div>
    )
  }

  private renderCarouselContent = (index: number) => {
    const { images } = this.state
    if (_.isEmpty(images)) {
      return (
        <div className='carousel-item active'>
          <div className='c-documentPhotoCarousel-emptyMessage'>
            No images available
          </div>
        </div>
      )
    }
    const image = images[index]
    const { padding, showOriginal } = this.props
    const { isInEditMode, width, height, zoomLevel } = this.state
    return (
      <div className='carousel-item active'>
        {this.renderPrint(image)}
        <ImageEditor
          className='u-noPrint'
          image={image}
          isInEditMode={isInEditMode}
          onChange={this.handleOnImageChange}
          onClose={this.handleOnclose}
          onZoom={this.handleZoom}
          padding={padding}
          showOriginal={showOriginal}
          showOverlay={isInEditMode}
          width={width}
          height={height}
          zoomLevel={zoomLevel}
        />
      </div>
    )
  }

  private renderPrint(image) {
    const { showOriginal } = this.props
    const imageSource: any = showOriginal ? image : _.first(image.source)
    return (
      <div
        className={classNames('c-printReport c-printReport--portrait c-onlyVisibleInPrint')}
      >
        <div
          className={classNames('c-printPage c-printPage--single', {
            'c-printPage--landscape': imageSource.width > imageSource.height,
            'c-printPage--portrait': imageSource.width <= imageSource.height,
          })}
        >
          <div className='c-printPageInner'>
            <img src={imageSource.uri} />
          </div>
        </div>
      </div>
    )
  }

  private get attachment(): any {
    const { entity, imagesPath, isImagingCarousel } = this.props
    if (isImagingCarousel) {
      const { classificationTasks } = entity.documentClassificationTask
      return _.map(classificationTasks, (task: any) => {
        return task.attachment
      })
    }
    return entity.get(imagesPath)
  }

  private handleOnclose = () => {
    // rollback all images
    _.forEach(this.state.images, (image) => image.rollback())
    this.props.onClose()
  }

  private handleEdit = (isInEditMode) => {
    this.setState({
      isInEditMode,
      zoomLevel: 0,
    })
  }

  private handleOnBodyDimensionsChange = (width, height) => {
    this.setState({ width, height })
  }

  private handleCarouselPositionChanged = (index) => {
    // reset zoom on position change
    const { onCarouselIndexChange } = this.props
    if (onCarouselIndexChange) {
      onCarouselIndexChange(index)
    }
    this.setState({
      carouselIndex: index,
      zoomLevel: 0,
    })
  }

  private handleOnImageChange = (image) => {
    this.forceUpdate()
  }

  private handleOnImageDelete = (image) => {
    const files = this.attachment.files
    _.pull(files, image.content)
    this.handleOnSave().then(() => {
      if (_.isEmpty(files)) {
        this.props.onClose()
      }

      const curToast = Toast.show({
        message: (
          <div className="c-toastContents">
            <Icon
              className="c-toastContents-icon mr3"
              icon={IconNames.TRASH}
              size={30}
            />
            <div className="c-toastContents-message">
              This page has been deleted, please
              <a onClick={() => { this.props.onClose(); Toast.dismiss(curToast) }}>
                &nbsp;click here&nbsp;
              </a>
              to exit the lightbox view.
            </div>
          </div>
        ),
        position: Position.BOTTOM_RIGHT,
        timeout: 10000,
        isLightMode: true,
      })
    })
  }

  private applyTransformations = async (): Promise<void> => {
    const files = this.attachment.files || this.attachment
    const index = _.isEmpty(files) ? 0: Math.min(this.state.carouselIndex, files.length - 1)
    const image = files[index]
    const jpgUri = image.uri
    const perspectiveTransform = _.find(image.transformations, t => t.type === 'perspective')
    const croppedUri = _.isNil(perspectiveTransform)
      ? jpgUri
      : URL.createObjectURL(await applyPerspective(jpgUri, perspectiveTransform, image.name))
    const thresholdTransform = _.find(image.transformations, t => t.type === 'threshold')
    const previewUri = _.isNil(thresholdTransform)
      ? croppedUri
      : URL.createObjectURL(await grayscaleFile(croppedUri, image.name,
          thresholdTransform.offset, thresholdTransform.size,
          this.props.componentsContext.platform === PlatformType.MOBILE_WEB))
    image.transformUri = previewUri
    if (image.source && image.source[0]) {
      image.source[0].uri = previewUri
    }
  }

  private finishSave = (entity: Entity) => {
    const { schema } = this.props
    const [ images, attachmentsByIndex ] = flattenAttachment(this.attachment, schema, entity)
      const files = this.attachment.files
      const index = _.isEmpty(files) ? 0: Math.min(this.state.carouselIndex, files.length - 1)

      this.setState({
        images: images,
        attachmentsByIndex: attachmentsByIndex,
        isInEditMode: false,
        carouselIndex: index,
      })
      return entity
  }

  private handleOnSave = () => {
    const { entity, useWebGLPreview } = this.props
    if (useWebGLPreview) {
      return this.applyTransformations().then(() => {
        this.finishSave(entity)
      })
    }
    return entity.save().then((entity) => {
      return entity.waitUntilIdle(100, 10000)
    }).then((entity) => {
      return this.finishSave(entity)
    })
  }

  private handleZoom = (zoomLevel) => {
    this.setState({ zoomLevel })
  }

}
