import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import React from 'react'
import Dropzone from 'react-dropzone'
import { Icon } from '@blueprintjs/core'
import LabelFieldFactory from 'browser/components/atomic-elements/higher-order-components/label-field-factory'
import { ImageLoader } from 'browser/components/atomic-elements/atoms/image-loader/image-loader'
import { MobileInputField } from '../input/mobile-input-field'
import classNames from 'classnames'

/**
 * @uiComponent
 */
interface IOcrCameraFieldProps {
  emptyButtonText?: string
  errors?: string[]
  label?: string
  placeholder?: string
  entity: any
  frames: any
  isEditable: boolean
  ocrValuePath: string
  onChange: (...value: any) => void
  value: any[]
}

interface IOcrCameraFieldState {
  trailerNumber?: string
  ocrValuePath: string | string[]
}

class OcrCameraFieldImpl extends React.PureComponent<IOcrCameraFieldProps, IOcrCameraFieldState> {
  private inputRef: React.RefObject<Dropzone> = React.createRef()

  constructor(props: IOcrCameraFieldProps) {
    super(props)

    const ocrValuePath = this.normalizeOcrValuePath() || ''
    this.state = {
      trailerNumber: _.get(props.entity, ocrValuePath, ''),
      ocrValuePath: ocrValuePath,
    }
  }

  render() {
    return (
      <div className="mobile-media-input-container">
        {this.renderTextInput()}
        {this.renderDropzone()}
        {this.renderImageButton()}
      </div>
    )
  }

  private renderTextInput() {
    const { frames, placeholder, ocrValuePath } = this.props
    const { trailerNumber } = this.state
    return (
      <div className="mobile-media-input-field">
        <MobileInputField
          placeholder={placeholder}
          frames={frames}
          value={trailerNumber}
          onChange={this.handleInputChange}
        />
      </div>
    )
  }

  private renderDropzone() {
    return <Dropzone ref={this.inputRef} className="u-hide" onDrop={this.handleDrop} />
  }

  // TODO: when OCR is implemented, look back into how mobile deals with the
  // text & photo being "in sync" or not. I recall there were situations, maybe
  // just minor UX issues, related to being able to clear the text and/or image?
  private renderImageButton() {
    const { value } = this.props
    const hasImage = !!value

    return (
      <span
        className={classNames('mobile-media-input-thumbnail', {
          'c-thumbnail-container': hasImage,
          'c-thumbnail-container--lg': hasImage,
        })}
        onClick={this.handleTakePhoto}
      >
        {hasImage ? this.renderImage() : this.renderEmptyImageState()}
      </span>
    )
  }

  private renderEmptyImageState() {
    return <Icon className="mobile-icon" color="#f89939" icon="camera" />
  }

  private renderImage() {
    const { value } = this.props
    const file = value?.[0]
    const source = file?.source || file?.uri
    return (
      <ImageLoader
        className="c-thumbnail c-thumbnail--image"
        imageClassName="c-image"
        src={source}
      />
    )
  }

  // NOTE: for now, we're not actually performing OCR. May revisit later, would
  // need to determine what to use for the OCR extraction on web (similar to
  // MLKit on mobile).
  private handleTakePhoto = () => {
    // laanch a modal? or what?
    this.inputRef?.current.open()
  }

  private handleInputChange = (value, event?: any, silentUpdate?: boolean) => {
    const { onChange } = this.props
    const { ocrValuePath } = this.state

    if (ocrValuePath) {
      onChange(value, false, ocrValuePath)
    }
    this.setState({ trailerNumber: value })
  }

  private handleDrop = (files: any[]) => {
    if (_.isEmpty(files)) {
      return
    }
    const { frames, entity, onChange } = this.props
    const valuePath = frames.getContext

    const file = _.first(files)
    const uniqueId = uuidv4()
    const multipartFile = {
      uniqueId,
      file,
    }
    const fileValue = {
      uri: file.preview,
      name: file.name,
      type: 'image',
      uniqueId,
    }

    onChange([fileValue])
    entity.addMultipartFiles(valuePath, [multipartFile])
  }

  // NOTE: borrowed from
  // `apps/mobile/app/Components/JsonElements/Atoms/OcrCameraField.tsx`, just in
  // case this normalization is expected by SEs coming from mobile ui schema
  // scenarios where they've been relying on it.
  //
  // Probably not worth pulling up to shared-libs, for now, as I think a better
  // solution would be to get rid of `ocrValuePath` and just have the component
  // write to a composite data type that includes both the photo/files and the
  // ocr text?
  private normalizeOcrValuePath = (): string[] => {
    const { frames, ocrValuePath } = this.props

    if (!ocrValuePath) {
      return
    }

    const normalizedOcrPath = _.split(ocrValuePath, '.')
    const valuePath: string[] = frames.getContext('valuePath')

    // need to find the parent path
    const parentIndex = _.findLastIndex(valuePath, (value) => typeof value === 'number')

    return parentIndex == -1
      ? normalizedOcrPath
      : [..._.slice(valuePath, 0, parentIndex + 1), ...normalizedOcrPath]
  }
}

export const OcrCameraField = LabelFieldFactory({ InputComponent: OcrCameraFieldImpl })
