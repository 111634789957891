import { Classes, Icon, Position, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import 'browser/components/atomic-elements/organisms/entity/entity-preview/_entity-preview.scss'
import { MultiMixinRenderer } from 'shared-libs/components/entity/multi-mixin-renderer'
import ComponentsMap from 'browser/components'

interface IEntityPreviewProps extends IBaseProps {
  entity?: any
  value?: any
  previewSchema?: string // preview schema path
  previewComponent?: any

  // override schema to be used instead of the "previewSchema" path.  If the uiSchema is set
  // the entity renderer will use this instead of the one in the entity schema.
  uiSchema?: any
  renderAsPopover?: boolean
  components: any
}

interface IEntityPreviewState {
  entity: Entity
  isLoading: boolean
}

export class EntityPreview extends React.PureComponent<IEntityPreviewProps, IEntityPreviewState> {
  private store: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      entity: null,
      isLoading: true,
    }
  }

  public componentDidMount() {
    const { value } = this.props
    const entityId: string = _.get(value, 'entityId')
    if (entityId) {
      this.fetchValueEntity(entityId)
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IEntityPreviewProps) {
    const nextEntityId = nextProps?.value?.entityId
    const curEntityId = this.props?.value?.entityId

    if (nextEntityId !== curEntityId) {
      if (_.isNil(nextEntityId)) {
        this.setState({
          entity: undefined
        })
      } else {
        this.fetchValueEntity(nextEntityId)
      }
    }
  }

  public render() {
    const { value, renderAsPopover } = this.props
    const title = value.displayName

    if (!renderAsPopover) {
      return this.renderContent()
    }

    return (
      <Popover
        className={classNames('collapse', this.props.className)}
      >
        <div className='c-entityPreviewPopover'>
          <Section
            bodyClassName='relative'
            className='c-section--collapse'
            headerControls={
              <Tooltip
                content='Open in new tab'
                position={Position.BOTTOM}
              >
                <Button
                  className={Classes.MINIMAL}
                  size='sm'
                  onClick={this.handleInfoButtonClick}
                >
                  <Icon
                    icon={IconNames.SHARE}
                  />
                </Button>
              </Tooltip>
            }
            title={title}
          >
            {this.renderContent()}
          </Section>
        </div>
      </Popover>
    )
  }

  private renderContent() {
    const { entity, isLoading } = this.state
    const { previewSchema, uiSchema, components, previewComponent } = this.props

    if (isLoading) {
      return (
        <div className='pa3 pt4 bt b--light-gray'>
          <LoadingSpinner size='xs' />
        </div>
      )
    }

    if (previewComponent) {
      return this.renderPreviewComponent()
    }

    const rendererContext = {
      density: 'collapse',
      isHorizontalLayout: true,
      isStatic: true,
    }
    return (
      <MultiMixinRenderer
        actions={this}
        componentsMap={components}
        context={rendererContext}
        shouldOverrideUiSchema={!_.isEmpty(uiSchema)}
        value={entity}
        uiSchema={uiSchema}
        uiSchemaPath={(previewSchema || 'uiSchema.web.entityPreview')}
      />
    )
  }

  private renderPreviewComponent() {
    const { previewComponent, entity } = this.props
    const { entity: previewEntity } = this.state
    const Component = ComponentsMap[previewComponent]

    return <Component
      entity={entity}
      previewEntity={previewEntity}
    />
  }

  private handleInfoButtonClick = () => {
    const { entity } = this.state
    const id = entity.get('uniqueId')
    if (id) {
      window.open(`/entity/${id}`, '_blank')
    }
  }

  private fetchValueEntity(uniqueId: string) {
    this.setState({ isLoading: true })
    this.store.findRecord(uniqueId).then((entity) => {
      this.setState({
        entity,
        isLoading: false,
      })
    })
  }
}
