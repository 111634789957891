import _ from 'lodash'
import apis from 'browser/app/models/apis'
import React, { useRef, useState } from 'react'
import Promise from 'bluebird'
import { ISelectProps, Select } from '..'
import { useForceUpdate } from 'browser/components/hooks/useForceUpdate'
import { translateString } from 'shared-libs/helpers/utils'
import { globalTranslationTable } from 'browser/mobile/util/global-translation-table'
import { InputActionMeta } from 'react-select'

const MIN_PARTIAL_CARRIER_LENGTH = 3

export const CarrierSearch: React.FC<ISelectProps> = (props) => {
  const [dotOptions, setDotOptions] = useState([])
  const searchPromise = useRef<Promise<void>>(null)
  const forceUpdate = useForceUpdate()

  const handleTextChange = _.debounce((companyCodeValue: string, actionMeta?: InputActionMeta) => {
    const { onInputChange } = props
    onInputChange?.(companyCodeValue, actionMeta)

    searchPromise.current?.cancel()
    if (companyCodeValue.length < MIN_PARTIAL_CARRIER_LENGTH) {
      setDotOptions([])
      return
    }

    searchPromise.current = apis
      .getCarrierSearchResults(companyCodeValue)
      .then((results) => {
        const options = _.map(results, (carrier) => {
          const { dotNumber, legalName, city } = carrier
          const citySuffix = city ? ` (${city})` : ''
          const label = `${dotNumber}: ${legalName}${citySuffix}`
          return { label, value: dotNumber }
        })
        setDotOptions(options)
      })
      .catch((e) => {
        console.error('Error searching for carriers:', e)
        setDotOptions([])
      })
      .finally(() => {
        searchPromise.current = null
        forceUpdate()
      })
  }, 250)

  const handleValueChange = (dotNumber) => {
    searchPromise.current?.cancel()
    searchPromise.current = null
    setDotOptions([])
    props.onChange?.(dotNumber)
  }

  return (
    <Select
      {...props}
      isLoading={searchPromise.current !== null}
      onInputChange={handleTextChange}
      onChange={handleValueChange}
      options={dotOptions}
      clearable={true}
      value={props.value}
    />
  )
}

const translatedPlaceholder = translateString('Carrier_Search_Field_Placeholder', globalTranslationTable)

CarrierSearch.defaultProps = {
  placeholder: translatedPlaceholder,
}
