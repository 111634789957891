export const SchemaIds = {
  ENTITY: '11111111-0000-0000-0000-000000000000',
  FIRM: '11111111-0000-0000-0000-000000000014',
  APP_BUNDLE: '11111111-0000-0000-0000-000000000211',
  FIRM_ADMIN: '11111111-0000-0000-0000-000000000015',
  USER: '11111111-0000-0000-0000-000000000023',
  MANAGER: '17c8c85e-8ba3-40f9-92ff-4153ff0875c4',
  MEMBER: 'dea05ff2-0ab2-4fa0-b2ad-81431e4db28b',
  ENTITY_SCHEMA: '11111111-0000-0000-0000-000000000001',
  MERGE_USER: 'a29a30bd-96b9-4e85-b785-ca9f0c504e4f',
  USER_INVITE: 'bcf88815-c50a-4645-8cd5-613858277071',
  DRIVER: '11111111-0000-0000-0000-000000000012',
  DRIVER_CONTACT: '7e15123a-5f55-4d5f-8425-5c88822913b3',
  DRIVER_DISPATCH_APP: '7651456a-3993-4457-8803-d8ff3759b4a9',
  INVOICE_SUBMISSION_TASK: 'a92cfb02-aaf3-455c-be4e-57c2bebdfd21',
  STORYBOARD_PLAN: '0b770bb1-501c-4408-a477-fe409e9f5f6b',
  STORYBOARD_EXECUTION: '54809ba0-e1c6-46ec-af52-a392803a36b0',
  STORYBOARD_YMS_EXECUTION: 'cf96dc53-9659-4f4b-a2a7-2b2bee249466',
  STORYBOARD_PLAN_CONTAINER: 'dcbaf6c6-472c-4e9a-9590-88d7d47c1ca7',
  DOCUMENT: '11111111-0000-0000-0000-000000000011',
  BATCH_CLASSIFICATION:'6b5ae4aa-4888-499b-a5e1-632885818350',
  LOCATION: '11111111-0000-0000-0000-000000000018',
  TRIP: '86af510a-f030-41d6-bda4-2228b8e181b1',
  SHARE: '11111111-0000-0000-0000-000000000022',
  PACKET: 'f0249077-2bb9-4301-8294-de4e9343716a',
  SETTINGS: '2085e728-543e-4ed0-9295-3df8981af575',
  MOBILE_SETTINGS: 'c2b4c805-5f95-40c0-a463-af55a51687bf',
  KIOSK_SETTINGS: 'f72d2771-5cb7-4542-a6f5-c647e93c8fde',
  GENERAL_SETTINGS: '7c736e86-d1f9-490c-b498-29dd93ee978c',
  DAT_SETTINGS: '767ff530-c021-11e7-abc4-cec278b6b50a',
  DOCUMENT_IMAGING_SETTINGS: '181e8f53-06de-4bb9-88aa-d6818ab3d329',
  QUICK_BOOKS_ONLINE_SETTINGS: '3e4f7fea-0798-415b-b91c-bc9a887a0995',
  ACCOUNTS_RECEIVABLE_INVOICE_SETTINGS: '09736153-04e9-4c06-bb2d-b10a93609533',
  TMS_SETTINGS: '08b338ef-65d9-4ccc-aa56-6983ead01185',
  FTP_SETTINGS: 'ccce1c47-a123-4d14-b788-e5d9fcc635b4',
  ENTITY_MAPPING: '3d3d61bc-c01e-45b0-b251-417ea3f11121',
  ENTITY_TYPE_SELECTION_FILTER: 'fe82a7f5-5baf-4ceb-a5c3-56f641ec83d3',
  KIOSK_PRINT_EXECUTION: '2da9430a-5c3b-41c2-a051-3f433c6e4333',
  WORKFLOW_PLAN: '0b770bb1-501c-4408-a477-fe409e9f5f6b',
  COMMENT: '11111111-0000-0000-0000-000000000006',
  CHANGE_SET: '11111111-0000-0000-0000-000000000005',
  CHECK_CALL: '2b90f891-e9de-4f8a-9fcf-169099937a73',
  EMAIL: '6c969f8b-0779-4da3-87ee-799ff26e3cd2',
  INVOICE_SUBMISSION: '95224f39-4f1f-4b15-9dcf-559d5cb39187',
  INVOICE_MAIL: '434aba5b-4b1d-471a-a639-16ba5e7ea9a9',
  YMS_SPOT: '5ec7171f-dd67-4580-af43-cbb7b7458723',
  YMS_SHIPMENT: 'e0926f83-a87d-4e19-965e-6ea0e9b012a8',
  YMS_CARRIER_APPOINTMENT: '129b43e7-a48e-4b53-8818-b3199ad58782',
  YMS_FACILITY: '34313619-ed9c-4be8-b47e-8fd67b861b75',
  YMS_TRAILER: '1ad2e51a-74a8-41a8-b321-9f15332b6609',
  SYSTEM_EVENT: '0167e057-c62d-4ed4-9860-104b40705523'
}

export const SchemaUris = {
  APP_BUNDLE: '/1.0/entities/metadata/applicationBundle.json',
  USER: '/1.0/entities/metadata/user.json',
  MERGE_USER: '/1.0/entities/metadata/mergeUser.json',
  USER_INVITE: '/1.0/entities/metadata/userInvite.json',
  FIRM: '/1.0/entities/metadata/firm.json',
  GENERAL_SETTINGS: '/1.0/entities/metadata/generalSettings.json',
  MOBILE_SETINGS: '/1.0/entities/metadata/mobileSettings.json',
  IMAGING_SETTINGS: '/1.0/entities/metadata/documentImagingSettings.json',
  DRIVER: '/1.0/entities/metadata/driver.json',
  DRIVER_CONTACT: '/1.0/entities/metadata/contacts/driverContact.json',
  DRIVER_DISPATCH_APP: '/1.0/entities/metadata/driverDispatchApp.json',
  INVOICE_SUBMISSION_TASK: '/1.0/entities/metadata/core_accounting_accountsReceivable_invoiceSubmissionTask.json',
  STORYBOARD_EXECUTION: '/1.0/entities/metadata/core_storyboard_execution.json',
  STORYBOARD_PLAN: '/1.0/entities/metadata/core_storyboard_plan.json',
  STORYBOARD_YMS_EXECUTION: '/1.0/entities/metadata/core_yms_execution.json',
  RBV1_ORDER: '/1.0/entities/metadata/brokerOrder.json',
  RBV2_ORDER: '/1.0/entities/metadata/core_fulfilment_salesOrder.json',
  SPOT_TYPE: '/1.0/entities/metadata/core_yms_spot.json',
  COMMENT: '/1.0/entities/metadata/comment.json',
  SHARE: '/1.0/entities/metadata/share.json',
  EDGE: '/1.0/entities/metadata/entity.json#/definitions/edge',
  FEDEX_FACILITY: '/1.0/entities/metadata/fedex_facility.json',
  CARRIER: '/1.0/entities/metadata/carrier.json',
  GAF_YMS_EXECUTION: '/1.0/entities/metadata/gafYMSExecution.json',
}
