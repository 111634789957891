import { Tab, Tabs } from '@blueprintjs/core'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { EntityTable } from 'browser/components/atomic-elements/organisms/entity/entity-table'
import { Histogram } from './histogram'

import 'browser/components/containers/analysis-view/_analytics.scss'

import {
  formatMoney,
} from './data-set'

interface IAnalyticsBlockProps extends IBaseProps {
  benchmarkRates?: any
  dataSets?: any
  filters: any
  isLoading?: any
  maxRows?: number
  onTabChanged?: () => void
  showSummary?: boolean
  showHistogram?: boolean
  tabs?: any
}

interface IAnalyticsBlockState {
  tableData: any
}

export class AnalyticsBlock extends React.Component<IAnalyticsBlockProps, IAnalyticsBlockState> {
  public static defaultProps: Partial<IAnalyticsBlockProps> = {
    showHistogram: true,
    showSummary: true,
  }

  constructor(props) {
    super(props)

    this.state = {
      tableData: _.map(props.tabs, (tab: any) => props.dataSets[tab.dataSet]),
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      tableData: _.map(nextProps.tabs, (tab: any) => nextProps.dataSets[tab.dataSet]),
    })
  }

  public render() {
    const { dataSets, isLoading } = this.props
    const { tableData } = this.state
    const className = classNames('u-positionRelative u-minHeight100', this.props.className)

    if (isLoading ||
      _.some(dataSets, (dataSet) => dataSet.isLoading) ||
      _.some(tableData, (dataSet) => dataSet.isLoading)) {
      return this.renderLoading(className)
    }

    return (
      <div className={className}>
        {this.renderSummary()}
        {this.renderHistogram()}
        {this.renderTabs()}
      </div>
    )
  }

  private renderLoading(className) {
    return (
      <div className={className}>
        <LoadingSpinner />
      </div>
    )
  }

  private renderSummary() {
    const { dataSets, showSummary } = this.props
    if (!showSummary) { return }
    const computations = _.get(dataSets, 'overview.collection.result.computations')
    if (!computations) { return }
    const avgCustomerRatePerMile = computations.averageRevenuePerMile
    const avgCarrierRatePerMile = computations.averageExpensePerMile
    const avgMarginPerMile = computations.averageMarginPerMile
    const averageCustomrRate = computations.averageRevenue
    const averageCarrierRate = computations.averageExpense
    const averageMarginRate = computations.averageMargin
    return (
      <div className='row'>
        <div className='col-xs-4 pa1'>
          <h4>
            {formatMoney(averageCarrierRate)}
          </h4>
          <div className='c-label c-label--secondary'>
            Avg. Carrier Rate
          </div>
        </div>
        <div className='col-xs-4 pa1'>
          <h4>
            {formatMoney(averageCustomrRate)}
          </h4>
          <div className='c-label c-label--secondary'>
            Avg. Customer Rate
          </div>
        </div>
        <div className='col-xs-4 pa1'>
          <h4>
            {formatMoney(averageMarginRate)}
          </h4>
          <div className='c-label c-label--secondary'>
            Avg. Margin
          </div>
        </div>
        <div className='col-xs-4 pa1'>
          <h4>
            {formatMoney(avgCarrierRatePerMile)}/mile
          </h4>
          <div className='c-label c-label--secondary'>
            Avg. Carrier Rate / Mile
          </div>
        </div>
        <div className='col-xs-4 pa1'>
          <h4>
            {formatMoney(avgCustomerRatePerMile)}/mile
          </h4>
          <div className='c-label c-label--secondary'>
            Avg. Customer Rate / Mile
          </div>
        </div>
        <div className='col-xs-4 pa1'>
          <h4>
            {formatMoney(avgMarginPerMile)}/mile
          </h4>
          <div className='c-label c-label--secondary'>
            Avg. Margin / Mile
          </div>
        </div>
      </div>
    )
  }

  private renderHistogram() {
    const { benchmarkRates, dataSets, showHistogram } = this.props
    if (!showHistogram || !dataSets.histogram) { return }

    const data = dataSets.histogram.collection.content
    const benchmark = _.get(benchmarkRates, 'children', [])
    return (
      <Section
        className='u-bumperTop'
        hideHeaderBorder={false}
        title='Historical Load Pricing'
      >
        <div className='c-financeHistogram u-bumperBottom--xxl'>
          <AutofillBlock>
            <Histogram
              benchmark={benchmarkRates}
              data={data}
            />
          </AutofillBlock>
        </div>
      </Section>
    )
  }

  private renderTabs() {
    const { tabs } = this.props
    return (
      <Tabs
        className='c-tabs c-tabs--flush c-carrierAnalytics'
        id='analyticsTabs'
      >
        {_.map(tabs, (tab, index) => this.renderTabPanel(tab, index))}
        <Tabs.Expander />
      </Tabs>
    )
  }

  private renderTabPanel(tab, index) {
    const { dataSets, maxRows } = this.props
    const { tableData } = this.state
    const title = tab.label
    const tabPanel = (
      <div
        className='grid-block vertical u'
      >
        <AutofillBlock>
          <EntityTable
            columns={tab.columns}
            entitySchema={dataSets[tab.dataSet].entitySchema}
            groups={dataSets[tab.dataSet].groups}
            isConfigurable={true}
            onEndReached={() => tableData[index].handleLoadNextPage()}
            onColumnsChange={(columns) => this.handleColumnsChange(columns, index)}
            onGroupsChange={(groups) => tableData[index].handleGroupsChange(groups)}
            onOrdersChange={(orders) => tableData[index].handleOrdersChange(orders)}
            onRowClick={null}
            onRowSelect={null}
            orders={dataSets[tab.dataSet].orders}
            rows={tableData[index].content}
          />
        </AutofillBlock>
      </div>
    )
    return (
      <Tab
        className='c-carrierAnalytics-tabPanel'
        id={index}
        key={index}
        title={title}
        panel={tabPanel}
      />
    )
  }

  private handleColumnsChange(columns, index) {
    const { tabs } = this.props
    tabs[index].columns = columns
    this.forceUpdate()
  }

}
