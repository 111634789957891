import classNames from 'classnames'
import _ from 'lodash'
import QRCode from 'qrcode.react'
import React from 'react'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { PrintContainer } from 'browser/components/atomic-elements/atoms/print-container/print-container'
import {  qrOnboardingCode } from 'browser/app/utils/utils'
import 'browser/components/atomic-elements/organisms/onboarding-block/_onboarding-block.scss'

const VectorLogo = require('images/onboarding/vector-logo-horizontal.svg')

interface IOnboardingPrintOutProps extends IBaseProps {
  entity: any
}

export class OnboardingPrintOut extends React.Component<IOnboardingPrintOutProps, any> {

  public render() {
    const { className } = this.props
    return (
      <AppNavigatorContext.Consumer>
        {({settings}) => (
          settings.getFirm() &&
          <div className='c-printFrame'>
            <PrintContainer>
              <Head
                title={`${settings.getFirm().get('business.legalName', '')} - Onboarding Print Out`}
              />
              <div className={classNames('c-printPage c-printPage--portrait', className)}>
                <div className='c-printPageInner'>
                  <div className='row row--wide'>
                    <div className='col-xs-8'>
                      <img
                        className='c-printLogo mb3'
                        src={VectorLogo}
                      />
                    </div>
                    <div className='col-xs-4 tr'>
                      <img
                        className='c-printLogo mb3'
                        src={settings.getFirm().get('business.logo.uri', '')}
                      />
                    </div>
                  </div>

                  <div className='f1 lh-title tc mv4'>
                    {settings.getFirm().displayName} now accepts trip paperwork through Vector. Register for free with your mobile phone.
                  </div>

                  <hr />
                  <div className='mv4'>
                    <div className='f1 b lh-title mv3 tc'>
                      Join {settings.getFirm().get('business.legalName', '')} on Vector
                    </div>
                    <div className='row row--wide mt4'>
                      <div className='col-xs-5 col-xs-offset-1 tc'>
                        <div className='f2 b lh-title mb2'>
                          Accept Invitation
                        </div>
                        <div className='f2 lh-title mb3'>
                          Or ask your admin for a Vector invite
                        </div>
                        {/* <img
                          className='ba b--gray'
                          style={{maxWidth: 200}}
                          src={Sample}
                        /> */}
                      </div>
                      <div className='col-xs-5 tc'>
                        <div className='f2 b lh-title'>
                          Or Request to Join
                        </div>
                        <div className='f2 lh-title mt2 mb3'>
                          Scan QR Code below in Vector App
                        </div>
                        <QRCode
                          value={qrOnboardingCode(settings.getFirm())}
                        />
                        <div className='f3 mt2'>
                          Vector Firm ID:<br />
                          {settings.getFirm().uniqueId}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </PrintContainer>
          </div>
        )}
      </AppNavigatorContext.Consumer>
    )
  }
}
