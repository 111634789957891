import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { Column } from 'browser/components/atomic-elements/atoms/table'
import { EntityTable } from 'browser/components/atomic-elements/organisms/entity/entity-table'
import { EntityDataSource } from '../entity-data-source'


const SALES_INVOICE_TYPE = '/1.0/entities/metadata/core_accounting_accountsReceivable_salesInvoice.json'

interface IRbV2SalesInvoiceTable  {
  entity: any
  frames: any
}

export class RbV2SalesInvoiceTable extends React.Component<IRbV2SalesInvoiceTable, any> {

  private dataSet: EntityDataSource

  constructor(props) {
    super(props)
    const { entity } = this.props

    this.dataSet = new EntityDataSource({
      entityType: SALES_INVOICE_TYPE,
      filters: [
        {
          entityType: SALES_INVOICE_TYPE,
          path: 'core_accounting_accountsReceivable_salesInvoice.order',
          type: 'matchEdge',
          value: { entityId: entity.uniqueId },
        },
      ],
    }).setOnChange(() => this.forceUpdate())
  }

  public componentDidMount() {
    this.dataSet.find();
  }

  public componentWillUnmount() {
    this.dataSet.dispose()
  }

  public render() {
    const entitySchema = apis.getStore().getRecord(SALES_INVOICE_TYPE)
    const columns: Column[] = [
      {
        cellComponent: {
          labelPath: 'core_accounting_accountsReceivable_salesInvoice.invoiceNumber',
          linkPath: 'uniqueId',
          path: 'entity',
          type: 'ui:table:linkCell',
        },
        flexGrow: 1,
        groupKey: 'data',
        isFixed: true,
        isSortable: true,
        label: 'Invoice Number',
        path: 'data',
        sortKey: 'core_accounting_accountsReceivable_salesInvoice.invoiceNumber',
        width: 150,
      },
      {
        className: 'u-tabularFigures',
        flexGrow: 1,
        format: { type: 'currency' },
        horizontalAlignment: 'right',
        isSortable: true,
        label: 'Revenue ($)',
        path: 'core_accounting_accountsReceivable_salesInvoice.totalRevenue.value',
        width: 100,
      },
      {
        className: 'u-tabularFigures',
        flexGrow: 1,
        isSortable: true,
        label: 'Status',
        path: 'core_accounting_accountsReceivable_salesInvoice.invoiceStatus',
        width: 100,
      },
      {
        className: 'u-tabularFigures',
        flexGrow: 1,
        isSortable: true,
        label: 'Stage',
        path: 'core_accounting_accountsReceivable_salesInvoice.stage',
        width: 100,
      },
      {
        flexGrow: 1,
        isSortable: false,
        label: 'Submitted',
        path: 'core_accounting_accountsReceivable_salesInvoice.submittedDate',
        width: 100,
      },
    ]
    return (
      <Section title='Related Invoices'>
        <div className='u-positionRelative u-minHeight400'>
          <div className='grid-content vertical'>
            <AutofillBlock className='u-border'>
              <EntityTable
                columns={columns}
                entitySchema={entitySchema}
                isConfigurable={false}
                isLoading={this.dataSet.isLoading}
                onEndReached={this.dataSet.handleLoadNextPage}
                onRowClick={null}
                onRowSelect={null}
                rows={this.dataSet.content}
                frames={this.props.frames}
              />
            </AutofillBlock>
          </div>
        </div>
      </Section>
    )
  }
}
