import _ from 'lodash'

export const getLabelProps = (schema, props) => {
  const { isLabelEditable, labelProps } = props
  if (!isLabelEditable) {
    return labelProps
  }
  const labelSchema = schema.properties.label
  // https://app.asana.com/0/167876960125712/346093892201233
  const defaultLabel = labelSchema.default
  // allow custom labels if schema doesn't contain enum or there are suggestions
  const isCreatable = _.isEmpty(labelSchema.enum) || !_.isEmpty(labelSchema.suggestions)
  const labelOptions = labelSchema.enum || labelSchema.suggestions
  const options = _.map(labelOptions, (option) => {
    return { value: option, label: option }
  })
  return {
    ...labelProps,
    default: defaultLabel,
    isCreatable,
    options,
  }
}

export function findAndClickFirstInput(element: HTMLInputElement) {
  if (!element) {
    return
  }
  const inputElement: any = _.first(element.getElementsByTagName('input'))
  if (inputElement) {
    inputElement.focus()
  }
}
