import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

/**
 * @uiComponent
 */
interface ITableConditionalStyleCellProps extends IBaseProps {
  value: any
  conditions?: boolean[]
  conditionStyles?: object[]
}

export const TableConditionalStyleCell: React.FC<ITableConditionalStyleCellProps> = (props) => {
  const { value, conditions, conditionStyles } = props
  let styles = {}
  conditions.forEach((condition, index) => {
    if (condition) {
      styles = { ...styles, ...conditionStyles[index] }
    }
  })

  return <div style={styles}>{value}</div>
}
