import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { TextMaskInput } from 'browser/components/atomic-elements/atoms/input/text-mask-input'

const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/]

export interface IGeoRegionZipTabPanelProps extends IBaseProps {
  onChange: (value: any) => void
  size?: string
  value?: any
}

export class GeoRegionZipTabPanel extends React.Component<IGeoRegionZipTabPanelProps, any> {

  public focus() {
    // TODO(peter/louis): Depends on the value and the tabs
    // this.input.focus()
    return false
  }

  public render() {
    const { value } = this.props
    const zipCode = _.get(value, 'zipCode', '')
    return (
      <FormGroup>
        <TextMaskInput
          inputClassName='c-geoRegionInput-zipcode'
          guide={false}
          keepCharPositions={false}
          mask={zipCodeMask}
          onChange={this.handleZipCodeChange}
          placeholder='Enter zipcode range'
          value={zipCode}
        />
      </FormGroup>
    )
  }

  private handleZipCodeChange = (zipCode) => {
    this.props.onChange({
      type: 'zipCode',
      zipCode,
    })
  }
}
