import Promise from 'bluebird'
import _ from 'lodash'
import React from 'react'
import Platform from 'platform'

import { GeolocationError, getCurrentGeoPosition, getDebugId } from 'browser/app/utils/utils'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { Formatter } from 'shared-libs/helpers/formatter'
import classNames from 'classnames'
import { parseGeolocation, translateString } from 'shared-libs/helpers/utils'
import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { FormGroupContentWrapper } from 'browser/components/atomic-elements/atoms/form-group-content-wrapper/form-group-content-wrapper'
import LabelFieldFactory from '../../higher-order-components/label-field-factory'
import { Geolocation } from 'shared-libs/generated/server-types/entity/address'

let CACHED_LOCATION: Pick<GeolocationCoordinates, 'latitude' | 'longitude'> | undefined

/**
 * @uiComponent
 */
interface IGeolocationFieldProps extends IBaseProps {
  frames?: FramesManager
  errors: string[]
  inputClassName?: string
  isHorizontalLayout: boolean
  /**
   * Whether to automatically query for the user's own location. Incompatible
   * with isDisabled=false. Defaults to true.
   */
  shouldAutoLocate?: boolean
  isStatic: boolean
  isDisabled: boolean
  isOptional: boolean
  label: string
  name: string
  onChange: (value: object, silentUpdate?: boolean) => void
  showGPSAsDegree: boolean
  size?: string
  isEditableInline: boolean
  value?: any
}

interface IGeolocationFieldState {
  gpsError?: GeolocationError
}

export class GeolocationField extends React.Component<IGeolocationFieldProps, IGeolocationFieldState> {
  public static defaultProps: Partial<IGeolocationFieldProps> = {
    isDisabled: true,
    shouldAutoLocate: true,
  }

  private request: Promise<any>
  private permissionStatus: PermissionStatus

  constructor(props: IGeolocationFieldProps) {
    super(props)

    this.state = {}
  }

  public componentDidMount() {
    const { shouldAutoLocate } = this.props

    if (shouldAutoLocate) {
      this.handleChange(CACHED_LOCATION)
      this.getLocation()
      this.requestLocationPermission()
    }
  }

  public componentWillUnmount() {
    this.request?.cancel()

    if (this.permissionStatus) {
      this.permissionStatus.onchange = null
    }
  }

  public render() {
    const { isDisabled } = this.props
    const { gpsError } = this.state

    if (isDisabled) {
      return <StaticGeolocationField {...this.props} gpsError={gpsError} />
    } else {
      return <EditableGeolocationField {...this.props} gpsError={gpsError} />
    }
  }

  private handleChange = (value) => {
    const { onChange } = this.props
    onChange(value, /*silentUpdate*/ true)
  }

  private getLocation = () => {
    this.setState({ gpsError: undefined }, this.requestLocation)
  }

  private requestLocationPermission() {
    void navigator.permissions
      ?.query({ name: 'geolocation' })
      .then((permissionStatus: PermissionStatus) => {
        this.permissionStatus = permissionStatus

        permissionStatus.onchange = () => {
          this.getLocation()
        }
      })
  }

  private requestLocation = () => {
    this.request = getCurrentGeoPosition().then((position: GeolocationPosition) => {
      const { longitude, latitude } = position.coords
      CACHED_LOCATION = { longitude, latitude }
      this.handleChange(CACHED_LOCATION)
    }, (gpsError: GeolocationError) => {
      this.setState({ gpsError })
    })
  }
}

type IGeolocationFieldExtendedProps = IGeolocationFieldProps & {
  gpsError?: GeolocationError
}

class StaticGeolocation extends React.Component<IGeolocationFieldExtendedProps> {
  public render() {
    const { isHorizontalLayout } = this.props
    return (
      <FormGroupContentWrapper
        className="u-innerBumperLeft--sm u-innerBumperRight--sm"
        isHorizontalLayout={isHorizontalLayout}
        isDisabled={true}
      >
        {this.renderContent()}
      </FormGroupContentWrapper>
    )
  }

  private renderContent() {
    const coordinate = this.getCoordinate()
    const displayValue = this.getDisplayValue(coordinate)

    if (!coordinate) {
      return <div className="u-flex u-alignItemsCenter c-input--minHeight">{displayValue}</div>
    }

    const mapUrl =
      Platform.os.family === 'iOS'
        ? Formatter.formatGeolocationAsAppleMapUrl(coordinate)
        : Formatter.formatGeolocationAsGoogleMapUrl(coordinate)

    return (
      <a
        className="u-flex u-alignItemsCenter c-input--minHeight "
        href={mapUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {displayValue}
      </a>
    )
  }


  private getDisplayValue(coordinate: Required<Geolocation> | undefined) {
    const { showGPSAsDegree, gpsError, shouldAutoLocate } = this.props

    const errorText = getErrorText(gpsError)

    if (coordinate) {
      return Formatter.formatGeolocation(coordinate, showGPSAsDegree)
    } else if (errorText) {
      return errorText
    } else if (shouldAutoLocate) {
      return 'Locating...'
    }
  }

  private getCoordinate() {
    const { value, shouldAutoLocate } = this.props

    if (shouldAutoLocate) {
      return value ?? CACHED_LOCATION
    } else {
      return value
    }
  }
}

const StaticGeolocationField = LabelFieldFactory({ InputComponent: StaticGeolocation })

type IEditableGeolocationFieldState = {
  userInput?: string
  userInputError?: Error
}

class EditableGeolocationField extends React.Component<
  IGeolocationFieldExtendedProps,
  IEditableGeolocationFieldState
> {
  constructor(props: IGeolocationFieldExtendedProps) {
    super(props)
    const { value, showGPSAsDegree, shouldAutoLocate, isDisabled } = props

    // precondition
    if (shouldAutoLocate && !isDisabled) {
      throw new Error(`shouldAutoLocate=true is not compatible with the editable geolocation field at this time. If you need an editable field, provide shouldAutoLocate=false.`)
    }

    this.state = {
      userInput: value ? Formatter.formatGeolocation(value, showGPSAsDegree) : undefined,
    }
  }

  public render() {
    const { userInput, userInputError } = this.state
    const {
      frames,
      errors,
      label,
      name,
      inputClassName,
      isHorizontalLayout,
      isEditableInline,
      size,
    } = this.props

    const errorText = errors?.join('\n') ?? getErrorText(userInputError)
    const translationTable = frames?.getContext('translationTable')
    const translatedLabel = translateString(label, translationTable)

    return (
      <InputField
        inputClassName={classNames(inputClassName, '')}
        label={translatedLabel}
        errorText={errorText}
        isStatic={true}
        isDisabled={false}
        name={name}
        size={size}
        onChange={this.handleInputChange}
        value={userInput}
        isHorizontalLayout={isHorizontalLayout}
        isEditableInline={isEditableInline}
        data-debug-id={getDebugId(frames)}
      />
    )
  }

  private handleInputChange = (value) => {
    const { onChange } = this.props

    let error: Error | undefined = undefined
    let parsedCoordinate: Geolocation | undefined = undefined

    try {
      parsedCoordinate = parseGeolocation(value)
    } catch (e) {
      error = e
    }

    this.setState({ userInput: value, userInputError: error }, () => {
      if (!error) {
        // don't clear immediately on error, let user correct it
        onChange(parsedCoordinate)
      }
    })
  }
} 

function getErrorText(gpsError: GeolocationError) {
  if (!gpsError) {
    return
  } else if (gpsError.nativeError?.code === GeolocationPositionError.PERMISSION_DENIED) {
    return 'Please enable Location'
  } else if (gpsError.nativeError?.code === GeolocationPositionError.POSITION_UNAVAILABLE) {
    return 'Location unavailable'
  } else if (gpsError.nativeError?.code === GeolocationPositionError.TIMEOUT) {
    return 'Timed out'
  } else {
    return gpsError.message
  }
}
