import { browserHistory } from 'browser/history'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Header } from 'browser/components/atomic-elements/atoms/header/header'
import { Sheet } from 'browser/components/atomic-elements/atoms/sheet'

const FREIGHT_CHARGE = 'Freight Charge'

interface ICapacityAssignmentSheetProps extends IBaseProps {
  brokerOrder: any
  capacity: any
  redirectPath: string
  shouldRedirectOnSuccess: boolean
}

interface ICapacityAssignmentSheetState {
  isSaving: boolean
}

// Assign capacity to a brokerOrder
export class CapacityAssignmentSheet
  extends React.Component<ICapacityAssignmentSheetProps, ICapacityAssignmentSheetState> {

  public static defaultProps: Partial<ICapacityAssignmentSheetProps> = {
    redirectPath: '/entity',
  }

  private sheet: Sheet

  constructor(props) {
    super(props)
    this.state = {
      isSaving: false,
    }
  }

  public render() {
    const { isSaving } = this.state
    const { brokerOrder, capacity } = this.props
    const { carrier } = capacity.capacity
    const handleRef = (ref) => { this.sheet = ref }
    return (
      <Sheet
        ref={handleRef}
        size='sm'
        bodyClassName='c-sheet-body--padded u-textCenter'
        header={
          <Header
            className='u-textCenter'
            title='Assign capacity'
          />
        }
        footer={
          <Footer
            isPrimaryButtonLoading={isSaving}
            isVisible={true}
            onCancelButtonClick={this.handleClose}
            onPrimaryButtonClick={this.handleAssignCapacity}
            primaryButtonText='Confirm'
          />
        }
      >
        <h6 className='c-h6'>Confirm carrier assignment to order {brokerOrder.displayName}</h6>
        <p className='c-p'>Assign carrier {carrier.displayName} to order {brokerOrder.displayName}.</p>
      </Sheet>
    )
  }

  private handleAssignCapacity = () => {
    // TODO(Peter): EntityTypeFunctions
    // TODO(Peter): WE NEED TO DETERMINE HOW TO HANDLE REASSIGNMENT OF CAPACITY
    const { brokerOrder, capacity, redirectPath, shouldRedirectOnSuccess } = this.props
    capacity.capacity.assignedOrder = {
      entityId: brokerOrder.uniqueId,
    }
    capacity.capacity.status = 'Assigned'
    brokerOrder.brokerOrder.carrier = capacity.capacity.carrier
    brokerOrder.brokerOrder.carrierContact = capacity.capacity.carrierContact
    brokerOrder.brokerOrder.carrierQuoteMethod = 'Matching Capacity'
    // TODO(Peter): refactor this make getOrCreateFreightCharge
    const expenseItems = _.get(brokerOrder, 'dispatchOrder.expenseItems', [])
    const freightCharge = _.find(expenseItems, (item) => {
      return _.get(item, 'itemType.displayName') === FREIGHT_CHARGE
    })
    if (freightCharge) {
      _.set(freightCharge, 'amount', capacity.capacity.quote)
    }

    this.setState({ isSaving: true })
    Promise.all([brokerOrder.save(), capacity.save()]).then(() => {
      if (shouldRedirectOnSuccess) {
        browserHistory.push({ pathname: `${redirectPath}/${brokerOrder.uniqueId}` })
      } else {
        this.handleClose()
      }
    }).catch((exception) => {
      console.error(JSON.stringify(exception, null, 2))
    })
  }

  private handleClose = () => {
    this.sheet.close()
  }
}
