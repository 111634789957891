import React from 'react'
import _ from 'lodash'
import bwipjs from 'bwip-js'

import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { pointsToMillimeters } from 'shared-libs/helpers/mobile/util'
import { getDebugId } from 'browser/app/utils/utils'

const DEFAULT_BWIP_RENDER_OPTIONS: Partial<bwipjs.RenderOptions> = {
  bcid: 'code128',
  height: 10,
  includetext: true,
  textxalign: 'center',
}

/**
 * @uiComponent
 */
export interface IBarcodeProps {
  frames?: FramesManager
  value?: string
  /**
   * Optional raw barcode string value.
   * 
   * Only used when `mode` is set to "display".
   */
  barcodeValue?: string
  /**
   * See https://github.com/bwipp/postscriptbarcode/wiki/Options-Reference for
   * more detailed documentation.
   * 
   * Only used when `mode` is set to "display".
   */
  renderOptions?: bwipjs.RenderOptions
}

/** Renders a 1D barcode image. */
export class Barcode extends React.Component<IBarcodeProps> {
  private barcodeContainerRef = React.createRef<HTMLDivElement>()
  private canvasRef = React.createRef<HTMLCanvasElement>()

  constructor(props: IBarcodeProps) {
    super(props)
    this.state = {}
  }

  public componentDidMount() {
    this.drawBarcode()
    document.addEventListener('visibilitychange', this.drawBarcode)
  }

  public componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.drawBarcode)
  }

  public componentDidUpdate(prevProps: IBarcodeProps): void {
    if (
      prevProps.value !== this.props.value ||
      prevProps.barcodeValue !== this.props.barcodeValue ||
      !_.isEqual(prevProps.renderOptions, this.props.renderOptions)
    ) {
      this.prepareBarcodeDebounced()
    }
  }

  public render() {
    const { frames } = this.props
    return (
      <div
        ref={this.barcodeContainerRef}
        className="grid-block align-verticalCenter u-justifyContentCenter"
        data-debug-id={getDebugId(frames)}
      >
        <canvas ref={this.canvasRef} />
      </div>
    )
  }

  private getValue() {
    const { value, barcodeValue, renderOptions } = this.props
    if (barcodeValue) {
      return barcodeValue
    } else if (renderOptions?.text) {
      return renderOptions?.text
    } else {
      return value
    }
  }

  private drawBarcode = () => {
    const value = this.getValue()
    if (_.isEmpty(value)) {
      return
    }
    const { renderOptions } = this.props
    const defaultScalingOptions = this.getDefaultScalingOptions()
    const options = _.merge({}, DEFAULT_BWIP_RENDER_OPTIONS, defaultScalingOptions, renderOptions, {
      text: value,
    })
    try {
      bwipjs.toCanvas(this.canvasRef.current, options)
    } catch (e) {
      // nop
      console.error('Failed to generate barcode', e)
    }
  }

  private prepareBarcodeDebounced = _.debounce(this.drawBarcode, 250)

  private getDefaultScalingOptions() {
    const { renderOptions } = this.props
    if (!_.isNil(renderOptions?.scale) || !_.isNil(renderOptions?.width)) {
      // if either is provided by schema, then don't provide any default scaling
      return {}
    }

    // get width of the container view
    const widthMillimeters = pointsToMillimeters(this.barcodeContainerRef.current.clientWidth, window.devicePixelRatio)

    // clamp to a reasonable range
    const width = _.clamp(widthMillimeters, 35, 80)
    return {
      width,
    }
  }
}
