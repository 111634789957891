import { Classes, Button, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IInputProps, Input } from 'browser/components/atomic-elements/atoms/input/input'

import './_secret-string-input.scss'

/**
 * @prop label - label associated with the input
 * @prop value - corresponds to "/1.0/entities/metadata/entity.json#/definitions/secretString"
 *
 * @uiComponent
 */
interface ISecretStringInputProps extends IInputProps {
  label?: string
  value: {
    secret?: string
    versionId?: string
  }
  isEditable?: boolean
}

/**
 * A text input for entering secret strings (passwords, keys, etc)
 *
 * @props ISecretStringInputProps
 */
export class SecretStringInput extends React.Component<ISecretStringInputProps, any> {

  static defaultProps: Partial<ISecretStringInputProps> = {
    isEditable: true,
  }

  private input: Input

  public focus() {
    this.input.focus()
  }

  private renderclearInputButton() {
    const { onChange, isEditable } = this.props
    const handleDelete = () => onChange({})
    return (
      <Button
        disabled={!isEditable}
        className={classNames(
          'c-button--square c-secretStringInput-clearValueButton',
          Classes.MINIMAL,
          {
            'u-hide': false,
          }
        )}
        onClick={handleDelete}
      >
        <Icon
          icon={IconNames.CROSS}
        />
      </Button>
    )
  }

  public render() {
    const { isDisabled, label, onChange, placeholder, type, value = {} } = this.props
    const valueSetPlaceholder = `${label || 'value'} has been set`
    const isSecretStringSet = !!(value && value.versionId)

    return (
      <>
        <Input
          {...this.props as IInputProps}
          inputType={type || 'password'}
          isDisabled={isSecretStringSet || isDisabled}
          onChange={value => onChange({ secret: value })}
          placeholder={isSecretStringSet ? valueSetPlaceholder : placeholder}
          ref={(ref) => { this.input = ref }}
          value={value && value.secret}
        />
        {this.renderclearInputButton()}
      </>
    )
  }
}
