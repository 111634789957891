import _ from 'lodash'

export function camelCase(value: string): string {
  return _.camelCase(value)
}

export function capitalize(value: string): string {
  return _.capitalize(value)
}

export function lower(value: string): string {
  return _.toLower(value)
}

export function upper(value: string): string {
  return _.toUpper(value)
}

export function pad(...params: any[]): string {
  return _.pad.apply(null, params)
}

export function padStart(...params: any[]): string {
  return _.padStart.apply(null, params)
}

export function padEnd(...params: any[]): string {
  return _.padEnd.apply(null, params)
}

export function split(...params: any[]): string[] {
  return _.split.apply(null, params)
}

export function replace(...params: any[]): string {
  return _.replace.apply(null, params)
}

export function startsWith(...params: any[]): string {
  return _.startsWith.apply(null, params)
}

export function endsWith(...params: any[]): string {
  return _.endsWith.apply(null, params)
}

export function parseInt(...params: any[]): number {
  return _.parseInt.apply(null, params)
}

export function _parseFloat(value: string): number {
  return parseFloat(value)
}

export function trim(value: string, pattern: RegExp | string): string {
  if (_.isRegExp(pattern)) {
    const regexPattern = pattern.source
    const regexTrim = new RegExp(`^(${regexPattern})+|(${regexPattern})+$`, 'g')
    return value.replace(regexTrim, '')
  } else {
    return _.trim(value, pattern)
  }
}

export function trimStart(value: string, pattern: RegExp | string): string {
  if (_.isRegExp(pattern)) {
    const regexPattern = pattern.source
    const regexTrim = new RegExp(`^(${regexPattern})+`, 'g')
    return value.replace(regexTrim, '')
  } else {
    return _.trimStart(value, pattern)
  }
}

export function trimEnd(value: string, pattern: RegExp | string): string {
  if (_.isRegExp(pattern)) {
    const regexPattern = pattern.source
    const regexTrim = new RegExp(`(${regexPattern})+$`, 'g')
    return value.replace(regexTrim, '')
  } else {
    return _.trimEnd(value, pattern)
  }
}

export function truncate(...params: any[]): string {
  return _.truncate.apply(null, params)
}

export function uriEncode(value: string): string {
  return encodeURI(value)
}

export function uriEncodeComponent(value: string): string {
  return encodeURIComponent(value)
}

export function uriDecode(value: string): string {
  return decodeURI(value)
}

export function uriDecodeComponent(value: string): string {
  return decodeURIComponent(value)
}

export function snakeCase(value: string): string {
  return _.snakeCase(value)
}
