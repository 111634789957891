import classNames from 'classnames'
import QRCode from 'qrcode.react'
import React from 'react'
import { Link } from 'react-router-dom'
import { Classes } from '@blueprintjs/core'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import {  qrOnboardingCode } from 'browser/app/utils/utils'
import 'browser/components/atomic-elements/organisms/onboarding-block/_onboarding-block.scss'

/**
 * @uiComponent
 */
interface IOnboardingBlockProps extends IBaseProps {
  entity: any
}

export class OnboardingBlock extends React.Component<IOnboardingBlockProps, any> {

  public render() {
    const { className } = this.props
    return (
      <AppNavigatorContext.Consumer>
        {({settings}) => (
          <div className={classNames('flex', className)}>
            <div className='mt3'>
              <QRCode
                size={64}
                value={qrOnboardingCode(settings.getFirm())}
              />
            </div>
            <div className='ml3 mt2 flex-grow'>
              <div className='mb3'>
                Employees can request access to your organization by scanning the QR code on the {settings.getFirm().firm.preferences.domain === 'loaddocs.co' ? `Loaddocs` : `Vector`} mobile app.
              </div>
              <Link
                className={Classes.BUTTON}
                target='_blank'
                rel='noopener noreferrer'
                to={this.getOnboardingPrintOut(settings)}
              >
                Print Onboarding Document
              </Link>

              <HelpBlock className='mt2'>
                Your Firm ID: <br />
                {settings.getFirm().uniqueId}
              </HelpBlock>
            </div>
          </div>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private getOnboardingPrintOut(settings) {
    return {
      pathname: '/view/540c2025-c3d5-4c4f-bb1d-6b4092b91d56',
      search: `&entityId=${settings.getFirm().uniqueId}&isPrint=1`,
    }
  }
}
