import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

/**
 * @uiComponent
 */
interface IUIRowProps extends IBaseProps {
  columns: any[]
}

export class UIRow extends React.Component<IUIRowProps, any> {

  public renderChildren() {
    const columns = this.props.columns
    const children = this.props.children
    if (_.isEmpty(columns)) {
      return children
    }
    if (React.Children.count(children) !== columns.length) {
      throw new Error('count(columns) must equals count(children)')
    }
    return React.Children.map(children, (child, index) => {
      return (
        <div className={columns[index]}>
          {child}
        </div>
      )
    })
  }

  public render() {
    return (
      <div className={classNames('row', this.props.className)}>
        {this.renderChildren()}
      </div>
    )
  }
}
