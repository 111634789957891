import { IWorkflowProgressContextProps, WorkflowProgressContext } from 'browser/components/containers/workflow-task-context'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import './_workflow-button.scss'

import { evaluateExpression, evaluateExpressionWithScopes } from 'shared-libs/helpers/evaluation'
import { Button, IButtonProps } from '../../../components/atomic-elements/atoms/button/button'
import { translateString } from 'shared-libs/helpers/utils'
import { globalTranslationTable } from 'browser/mobile/util/global-translation-table'

/**
 * @uiComponent
 */
export interface IWorkflowButtonProps extends IButtonProps {
  outputMappings?: any
  frames?: any
  onPressAction?: string
  shouldShowProgressBar?: boolean
  runActionOnly?: boolean
}

export interface IWorkflowButtonState {
  pendingAction?: boolean
}

export class WorkflowButton extends React.Component<IWorkflowButtonProps, IWorkflowButtonState> {
  private unmounted: boolean = false
  private taskUploadInProgress: boolean = false

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <WorkflowProgressContext.Consumer>
        {(context) => this.renderButton(context)}
      </WorkflowProgressContext.Consumer>
    )
  }

  componentWillUnmount() {
    this.unmounted = true
  }

  private renderButton(context: IWorkflowProgressContextProps) {
    const { pendingAction } = this.state
    const { progress, hasAttachments } = context
    const { runActionOnly } = this.props
    this.taskUploadInProgress = this.taskUploadInProgress || (!_.isNil(progress) && hasAttachments)

    if (pendingAction && this.taskUploadInProgress) {
      return this.renderProgressBar(progress)
    }

    return (
      <Button
        {...this.props}
        {...this.state}
        className={classNames(this.props.className, 'mobile-button')}
        onClick={runActionOnly ? this.handleOnClickActionOnly : this.handleOnClick}
        data-debug-id={this.getDebugId()}
        frames={this.props?.frames}
      />
    )
  }

  private renderProgressBar(progress: number) {
    progress = _.isNumber(progress) ? progress * 100.0 : 100 /* undefined = done */
    progress |= 0 /* truncate to int */
    const uploading = progress < 100
    const text = (uploading || !this.props.shouldShowProgressBar) ? 'Uploading' : 'Processing'
    const translatedText = translateString(text, globalTranslationTable)
    const suffix = (uploading && this.props.shouldShowProgressBar) ? ` ${progress}%` : ''
    const formatted = `${translatedText}...${suffix}`

    const displayedProgress = !this.props.shouldShowProgressBar ? 100 : progress
    const barStyle = { width: `${displayedProgress}%`}
    const clippedTextStyle = { clipPath: `inset(0 0 0 ${displayedProgress}%)` }

    return (
      <div className="upload-progress-container">
        <div className="upload-progress-bar" style={barStyle} />
        <div className="upload-progress-text overlapping">{formatted}</div>
        <div className="upload-progress-text" style={clippedTextStyle}>
          {formatted}
        </div>
      </div>
    )
  }

  /**
   * Preserving the original implementation of button handler which is always advance to next
   * and then evaluate the onPressAction
   */
  private handleOnClick = async () => {
    const { outputMappings, onPressAction } = this.props
    const actionHooks = this.props?.frames?.getValue('actionHooks')
    const goNext = actionHooks?.goNext

    this.taskUploadInProgress = false
    await this.setStateAsync({ pendingAction: true })

    await goNext?.(outputMappings, {})
    onPressAction && evaluateExpression(actionHooks, onPressAction)
  }

  /**
   * In order avoid blast radius, don't tinker with the original handleOnClick and implement
   * a new one for kiosk which is more aligned with mobile.
   */
  private handleOnClickActionOnly = async () => {
    const { outputMappings, onPressAction = "goNext()" } = this.props
    const actionHooks = this.props?.frames?.getValue('actionHooks')
    const actions= this.props?.frames?.getActions() || {}
    const updateExecutionState = actionHooks?.updateExecutionState

    this.taskUploadInProgress = false
    await this.setStateAsync({ pendingAction: true })

    outputMappings && updateExecutionState?.(outputMappings, {})

    onPressAction && await evaluateExpressionWithScopes(this.props?.frames, onPressAction, {...actionHooks, ...actions })

    !this.unmounted && this.setState({ pendingAction: false })
  }

  private async setStateAsync(props) {
    return new Promise<void>((res) => {
      this.setState(props, res)
    })
  }

  private getDebugId() {
    const { buttonText } = this.props
    return buttonText
  }
}
