import _ from 'lodash'

export function buildErrorTree(errors) {
  const results = {}
  _.forEach(errors, (error, key) => {
    const keyArray = key.split('.')
    const errorsKey = keyArray.concat(['_errors'])
    const errorsArray = _.get(results, errorsKey, [])
    errorsArray.push(error)
    _.set(results, errorsKey, errorsArray)
  })
  return results
}
