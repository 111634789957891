import classNames from 'classnames'
import _ from 'lodash'
import React, { useContext } from 'react'

import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import 'browser/components/atomic-elements/atoms/table/cells/_table-button-cell.scss'
import { SheetContext } from '../../sheet/sheet-manager'

/**
 * @uiComponent
 */
interface ITableButtonCellProps extends IBaseProps {
  buttonText: string
  buttonClassName?: string
  className?: string
  isDisabled?: boolean
  onClick?: (value: any) => void
  overlayComponent?: string
  size?: string
  params?: object
  value: any
  valueParamName?: string
}

export const TableButtonCell: React.FC<ITableButtonCellProps> = (props) => {
  const { buttonText, buttonClassName, className, isDisabled, overlayComponent,
    onClick, params, size, value, valueParamName } = props
  const { openOverlay, closeOverlay } = useContext(SheetContext)
  const getOverlayComponentClass = () => {
    if (_.isString(overlayComponent)) {
      return ComponentsMap[overlayComponent]
    }
    return overlayComponent
  }
  const handleClick = () => {
    const overlayProps = {
      ...params,
      [valueParamName]: value,
    }
    const OverlayComponentClass = getOverlayComponentClass()
    if (OverlayComponentClass) {
      openOverlay(
        <OverlayComponentClass
          {...overlayProps}
          onClose={closeOverlay}
        />,
      )
    } else if (onClick) {
      onClick(props)
    }
  }

  return (
    <div className={classNames('c-tableButtonCell-button', className)}>
      <Button
        className={classNames(buttonClassName)}
        isDisabled={isDisabled}
        onClick={handleClick}
        size={size}
      >
        {buttonText}
      </Button>
    </div>
  )
}
