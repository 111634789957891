import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

/**
 * @uiComponent
 */
interface ITableFlagCellProps extends IBaseProps {
  value: any
}

export const TableFlagCell: React.FC<ITableFlagCellProps> = (props) => {
  const { value } = props
  return (
    <Icon
      className={classNames({
        'c-errorColor': value,
      })}
      icon={IconNames.FLAG}
    />
  )
}
