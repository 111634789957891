import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import apis from 'browser/app/models/apis'
import MobileComponents from '../components'
import { Entity } from 'shared-libs/models/entity'
import { TruckLoader } from '../components/truck-loader/truck-loader'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'

const MOBILE_WEB_UI_SCHEMA_PATH = 'uiSchema.mobileWeb.entityPage'

export interface IMobileEntityPageProps {
  entityId?: string
  history: any
  location: any
  match: any
}

export const MobileEntityPage = (props: IMobileEntityPageProps) => {
  const { match, history, location } = props
  const { params } = match

  const store = apis.getStore()

  const { settings } = useContext(AppNavigatorContext)
  const [loading, setLoading] = useState(true)
  const [entity, setEntity] = useState<Entity>(null)

  const fetchEntity = async (entityId: string) => {
    setLoading(true)
    const e = await store.findRecord(entityId)
    setEntity(e)
    setLoading(false)
  }

  useEffect(() => {
    fetchEntity(params.entityId)
  }, [params.entityId])

  if (loading || !entity) {
    return <TruckLoader />
  }

  return (
    <EntityRenderer
      actions={{}}
      componentsMap={MobileComponents}
      context={{
        density: 'collapse',
        isFullScreen: true,
        isHorizontalLayout: false,
        location,
      }}
      state={{ history, location, match, settings }}
      uiSchemaPath={MOBILE_WEB_UI_SCHEMA_PATH}
      value={entity}
    />
  )
}
