import React from 'react'
import ComponentsMap from 'browser/components'
import { ViewRenderer } from 'shared-libs/components/view/renderer'
import { IContainerProps } from './container-props'
import apis from 'browser/app/models/apis'

export class ContainerView extends React.Component<IContainerProps> {

  public render() {
    const { children, viewSchema, view } = this.props
    return (
      <ViewRenderer
        actions={this}
        componentsMap={ComponentsMap}
        schema={viewSchema}
        state={{
          ...view.view.state,
          settings: apis.getSettings()
        }}
        api={apis}
      >
        {children}
      </ViewRenderer>
    )
  }
}
