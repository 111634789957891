import classNames from 'classnames'
import React from 'react'

export const DEFAULT_ITEM_HEIGHT = 40

interface IPickerWheelItemProps {
  isSelected: boolean
  style: React.CSSProperties
  onClick: () => void
}

export const PickerWheelItem: React.FC<IPickerWheelItemProps> = (props) => {
  const { isSelected, onClick, style } = props
  return (
    <div
      className={classNames('c-mobile-pickerWheel-item', {
        selected: isSelected,
      })}
      style={style}
      onClick={onClick}
    >
      {props.children}
    </div>
  )
}

const RADIUS_MULTIPLIER = 7

export function makeItemStyle(
  distanceFromCenterY: number,
  wheelHeight: number,
  curvatureFactor: number
): React.CSSProperties {
  // derive a radius from the `wheelHeight` (imagine a larger circle whose edge
  // runs vertically through the picker wheel viewport).
  const radius = (wheelHeight / 2) * RADIUS_MULTIPLIER
  // `distanceFromCenterY` can be thought of a distance along the circle's edge.
  const angle = Math.asin(distanceFromCenterY / radius)
  // get the x coordinate of that (flipped to the left side of the circle).
  const xPos = (1 - Math.cos(angle)) * radius
  // multiply by the curve factor to flip or remove the curve.
  const translateXPixels = xPos * curvatureFactor

  // decrease scale & opacity a bit as the distance from the center increases.
  const scale = Math.max(1 - (distanceFromCenterY * 0.3) / DEFAULT_ITEM_HEIGHT, 0.2)
  const opacity = Math.max(1 - (distanceFromCenterY * 0.3) / DEFAULT_ITEM_HEIGHT, 0.2)

  return {
    transform: `scaleY(${scale}) translateX(${translateXPixels}px)`,
    opacity: opacity,
    height: `${DEFAULT_ITEM_HEIGHT}px`,
    lineHeight: `${DEFAULT_ITEM_HEIGHT}px`,
  }
}
