import React, { PureComponent } from 'react'
import _ from 'lodash'

import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { default as SheetPortal } from 'browser/components/atomic-elements/atoms/sheet/sheet-portal'
import { LabelFormGroup } from 'browser/components/atomic-elements/molecules/label-form-group/label-form-group'
import { createNewDocumentType } from 'browser/components/domains/firm/firm-utils'

interface ICreateDocTypeSheetProps {
  isOpen: boolean
  onClose: (Entity?) => void
}

interface ICreateDocTypeSheetState {
  docTypeName: string
  errorText: string
}

export class CreateDocTypeSheet extends PureComponent<ICreateDocTypeSheetProps, ICreateDocTypeSheetState> {
  private docTypeNameInput

  constructor(props) {
    super(props)
    this.state = {
      docTypeName: '',
      errorText: null,
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      setTimeout(() => this.docTypeNameInput.focus(), 0) // need to delay until after render
    }
  }

  public render() {
    return (
      <SheetPortal
        isOpen={this.props.isOpen}
        footer={this.renderCreateDocTypeFooter()}
        size='sm'
      >
        <div className='c-sheet-body--padded'>
          <FormTable>
            <LabelFormGroup
              label='Document Type Name'
              onChange={this.handleDocTypeNameChange}
              ref={(input) => { this.docTypeNameInput = input }}
              errorText={this.state.errorText}
              labelProps={{ className: 'c-label--isHorizontalMediumWide' }}
              value={this.state.docTypeName}
            >
              <Input onKeyDown={this.handleDocTypeNameKeyDown} />
            </LabelFormGroup>
          </FormTable>
        </div>
      </SheetPortal>
    )
  }

  private renderCreateDocTypeFooter() {
    const onCancelButtonClick = () => {
      this.props.onClose(null)
    }

    return (
      <Footer
        primaryButtonText='Create'
        onPrimaryButtonClick={this.handleCreateDocType}
        onCancelButtonClick={onCancelButtonClick}
        errorText={this.state.errorText}
      />
    )
  }

  private handleCreateDocType = () => {
    const { docTypeName } = this.state
    if (_.isEmpty(docTypeName)) {
      this.setState({ errorText: 'Name is required' })
      this.docTypeNameInput.focus()
      return
    }

    const docSchema = createNewDocumentType(docTypeName)
    this.setState({
      docTypeName: '',
      errorText: null,
    })
    this.props.onClose(docSchema)
  }

  private handleDocTypeNameKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleCreateDocType()
    }
  }

  private handleDocTypeNameChange = (docTypeName) => this.setState({ docTypeName, errorText: null })
}
