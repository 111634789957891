import _ from 'lodash'
import React from 'react'
import { IBaseProps } from '../../../components/atomic-elements/atoms/base-props'
import { translateString } from 'shared-libs/helpers/utils'
import { globalTranslationTable } from 'browser/mobile/util/global-translation-table'
import './_progress-bar.scss'

/**
 * @uiComponent
 */
interface IProgressBarProps extends IBaseProps {
    progress: number  // 0.0 to 1.0
    text?: string
}

export const ProgressBar : React.FC<IProgressBarProps> = (props) => {
    let progress = 100.0 * (_.isNumber(props.progress) ? props.progress : 1) /* undefined = done */
    progress |= 0 /* truncate to int */
    const uploading = progress < 100
    const text = props.text || uploading ? 'Uploading' : 'Done'
    const translatedText = translateString(text, globalTranslationTable)
    const suffix = uploading ? ` ${progress}%` : ''
    const formatted = `${translatedText}...${suffix}`

    const barStyle = { width: `${progress}%`}
    const clippedTextStyle = { clipPath: `inset(0 0 0 ${progress}%)` }

    return (
      <div className="upload-progress-container">
        <div className="upload-progress-bar" style={barStyle} />
        <div className="upload-progress-text overlapping">{formatted}</div>
        <div className="upload-progress-text" style={clippedTextStyle}>
          {formatted}
        </div>
      </div>
    )
  }