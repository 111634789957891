import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/form-table/_form-table.scss'

/**
 * @uiComponent
 */
interface IFormTableProps extends IBaseProps {
  density?: string
}

export class FormTable extends React.Component<IFormTableProps, any> {
  public render() {
    const { children, className, density } = this.props
    const densityClassName = _.isEmpty(density) ? '' : `c-density--${density}`
    return (
      <div className={classNames('c-formTable', densityClassName, className)} >
        {children}
      </div>
    )
  }
}
