import { FlatActivity, NotificationActivity } from 'getstream'
import { Entity } from 'shared-libs/models/entity'

export interface IUpdate {
  feed?: string
  new: FlatActivity[]
  deleted: FlatActivity[]
  deleted_foreign_ids: string[]
}

export interface IInboxActivity extends Omit<FlatActivity, 'object' | 'entity'> {
  object: Entity | string
  actor: Entity | string
  target: Entity | string
}

export interface IAggregatedInboxActivity extends Omit<NotificationActivity, 'activities'> {
  activities: IInboxActivity[]
}

export interface IInboxEntry {
  actors: Array<Entity | string>
  targets: Array<Entity | string>
  object: Entity | string
  read?: boolean
  verb: Verb
  date: string
  // a groupId is changed when a new entry is added to an aggregation,
  // whereas the group aggregation key stays the same
  groupId: string
  group: string
}

export enum Verb {
  UPDATE = 'UDPATE',
  COMMENT = 'COMMENT',
  SHARE = 'SHARE'
}
