import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { renderEventTimestamp } from '../util'

interface IInvoiceMailEventItemInterface extends IBaseProps {
  entity: any
  item: any
  isFirst: boolean
  isLast: boolean
}

export const InvoiceMailEventItem: React.FC<IInvoiceMailEventItemInterface> = (props) => {
  const { className, item, isFirst } = props
  const { creationDate, modifiedDate, invoiceMailEvent, owner } = item
  const { invoiceNumber, author, combinedPdf } = invoiceMailEvent

  const downloadPdf = (
    <a
      href={combinedPdf}
      target='_blank'
      rel='noopener noreferrer'
      download={true}
    >
      {combinedPdf}
    </a>
  )

  const renderContent = () => {
    return (<div>
      Invoice {invoiceNumber} for mail deliver is included in the following print job: <br/>
     {downloadPdf}
    </div>)
  }

  return (
    <div
      className={classNames('c-timelineEvent', className)}
      key={item.uniqueId}
    >
      <div
        className={classNames('c-timelineEvent-line', className, {
          'c-timelineEvent-line--topCutOff': isFirst,
        })}
      />
      <div className='c-timelineEvent-dot' />
      <div className='c-timelineEvent-contentContainer'>
        <div className='c-timelineEvent-body'>
          <HelpBlock>
            <span className='b'>{_.get(author, 'displayName', '')}</span> on {renderEventTimestamp(creationDate)}:<br />
            {renderContent()}
          </HelpBlock>
        </div>
      </div>
    </div>
  )
}
