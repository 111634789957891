import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { convertServerError } from 'shared-libs/models/utils'
import { PasswordResetModal } from 'browser/components/atomic-elements/organisms/password-reset-modal/password-reset-modal'

import { StoryboardExecutionStatus } from 'shared-libs/models/types/storyboard/storyboard-execution'
import { Action } from 'browser/app/models/action'
import { ComponentsContext } from 'browser/contexts/components/components-context'

export interface IEntityDetailCardDropdownProps {
  printView?: string
  entity: any
  onClose?: () => void
  settings: Settings
  removeDeleteButton: boolean
  showWorkflowCancelButton?: boolean
  showNeedsActionOverrideButton?: boolean
  showPasswordResetButton?: boolean
  afterWorkflowCancel?: () => void
}

interface IEntityDetailCardDropdownState {
  isLoading: boolean
}

const DOCUMENT_SCHEMA_ID = '11111111-0000-0000-0000-000000000011'
const PRINTABLE_SCHEMA_ID = '5209a80d-d216-4a27-acd3-56da546141a5'

export class EntityDetailCardDropdown extends React.Component<IEntityDetailCardDropdownProps, any> {
  public static defaultProps: Partial<IEntityDetailCardDropdownProps> = {
    onClose: _.noop,
  }

  private popUpRef: any

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
    this.popUpRef = null
  }

  public render() {
    const { children } = this.props
    const { isLoading } = this.state
    if (isLoading) {
      return (
        <LoadingSpinner />
      )
    }
    return (
      <ul className='c-dropdownList'>
        {this.getChildren()}
      </ul>
    )
  }

  public hasContent(): boolean {
    return this.getChildren().length > 0
  }

  private getChildren(): any[] {
    let ret = []

    ret.push(this.props.children)
    if (this.isAdminOrFirmAdminOrManager()) {
      ret = ret.concat([
        this.renderPrintLink(),
        this.renderDeleteUnDeleteMenuItem(),
        this.renderRetryMenuItem(),
        this.renderInvalidateMenuItem(),
        this.renderRecomputeMenuItem(),
        this.renderInsightsMenuItem(),
      ])
    }
    ret.push(this.renderTriggerPush())
    ret.push(this.renderDownloadPDF())
    ret.push(this.renderDigitalDoc())
    ret.push(this.renderPasswordReset())
    ret.push(this.renderCancelWorkflow())
    ret.push(this.renderNeedsActionOverride())

    ret.push(...this.renderDynamicActions())

    return ret.filter(x => !!x)
  }

  private renderDynamicActions() {
    const { entity } = this.props
    const actions = entity.actions

    if (!actions) {
      return []
    }

    const wrappedActions = actions.map(a => new Action(a))
    const permissionedActions = wrappedActions.filter(
      a => a.permissions ? a.eval({ entity }, a.permissions) : true)
    const entityActions = permissionedActions.filter(a => a.isSingle)

    return entityActions.map(a => (
      <li
        className='c-dropdownList-item'
        onClick={() => a.process([entity])}
        key={a.__reactKey}
      >
        {a.title}
      </li>
    ))
  }

  protected isAdminOrFirmAdminOrManager() {
    const { settings } = this.props
    return settings.isAdmin || settings.isFirmAdmin || settings.isManager
  }

  private renderPrintLink() {
    const { printView } = this.props
    if (!_.isEmpty(printView)) {
      return (
        <li
          className='c-dropdownList-item'
          onClick={this.handlePrintLink}
        >
          Print
        </li>
      )
    }
  }

  private handlePrintLink = () => {
    const { entity, printView } = this.props
    const url = `/view/${printView}?entityId=${entity.uniqueId}&print=1`
    window.open(url)
  }

  private renderDeleteUnDeleteMenuItem() {
    const { entity, removeDeleteButton } = this.props

    if (removeDeleteButton) {
      return
    }

    const isDeleted = entity.status.state === 'deleted'
    return (
      <li
        className='c-dropdownList-item'
        onClick={isDeleted ? this.handleUnArchive : this.handleArchive}
      >
        {isDeleted ? 'Un-Delete' : 'Delete'}
      </li>
    )
  }

  private renderRetryMenuItem() {
    const { settings } = this.props
    if (!settings.isAdmin) {
      return
    }
    return (
      <li
        className='c-dropdownList-item'
        onClick={this.handleRetry}
      >
        Retry
      </li>
    )
  }

  private renderInvalidateMenuItem() {
    const { settings } = this.props
    if (!settings.isAdmin) {
      return
    }
    return (
      <li
        className='c-dropdownList-item'
        onClick={this.handleInvalidate}
      >
        Invalidate
      </li>
    )
  }

  private renderRecomputeMenuItem() {
    const { settings } = this.props
    if (!settings.isAdmin) {
      return
    }
    return (
      <li
        className='c-dropdownList-item'
        onClick={this.handleRecompute}
      >
        Recompute
      </li>
    )
  }

  private renderInsightsMenuItem() {
    const { entity, settings } = this.props
    if (!settings.isAdmin) {
      return
    }
    return (
      <Link className='c-dropdownList-item' to={`/insights/${entity.get('uniqueId')}`}>
        Insights
      </Link>
    )
  }

  private renderDownloadPDF() {
    const { entity } = this.props
    if (entity.hasMixin(DOCUMENT_SCHEMA_ID)) {
      return (
        <li
          className='c-dropdownList-item'
          onClick={this.handleDownloadPdf}
        >
          Download PDF
        </li>
      )
    }
    return null
  }

  private renderTriggerPush() {
    const { entity } = this.props
    if (entity.hasMixin(DOCUMENT_SCHEMA_ID)) {
      return (
        <li
          className='c-dropdownList-item'
          onClick={this.handleTriggerPush}
        >
          Trigger Push
        </li>
      )
    }
    return null
  }

  private renderDigitalDoc() {
    const { entity } = this.props
    if (entity.hasMixin(PRINTABLE_SCHEMA_ID)) {
      return (
        <li
          className='c-dropdownList-item'
          onClick={this.handleRenderPdf}
        >
          View PDF
        </li>
      )
    }
    return null
  }

  private renderPasswordReset() {
    const { showPasswordResetButton, entity } = this.props

    if (showPasswordResetButton) {
      return (
        <li
          className='c-dropdownList-item'
          onClick={() => { PasswordResetModal.open({ userEntity: entity }) }}
        >
          Reset Password
        </li>
      )
    }

    return null
  }

  private renderCancelWorkflow() {
    const { showWorkflowCancelButton } = this.props
    if (showWorkflowCancelButton) {
      return (
        <li
          className='c-dropdownList-item'
          onClick={this.cancelWorkflow}
        >
          Cancel Workflow
        </li>
      )
    }
    return null
  }

  private cancelWorkflow = () => {
    const { entity, afterWorkflowCancel } = this.props

    new Promise((resolve, _) => {
      ConfirmationModal.open({
        confirmationTitle: 'Cancel Workflow',
        confirmationText: `Are you sure you wish to cancel the workflow: ${entity.core_storyboard_execution.name}?`,
        modalDialogClassName: 'c-modal-dialog--sm',
        onPrimaryClicked: resolve,
      })
    }).then(() => {
      entity.set('core_storyboard_execution.status', StoryboardExecutionStatus.CANCELED)
      entity.isCancelledLocally = true

      entity.save().then(() => {
        afterWorkflowCancel()
      })
    })
  }

  private renderNeedsActionOverride() {
    const { showNeedsActionOverrideButton, entity } = this.props
    if (showNeedsActionOverrideButton) {
      const needsAction = entity.custom_integrations_lastComment.needsAction
      const needsActionText = needsAction ? 'Mark As Read' : 'Mark As Unread'
      return (
        <li
          className='c-dropdownList-item'
          onClick={this.overrideNeedsAction}
        >
          {needsActionText}
        </li>
      )
    }
    return null
  }

  private overrideNeedsAction = () => {
    const { entity } = this.props
    entity.set('custom_integrations_lastComment.needsActionOverride', true)
    entity.save()
  }

  private handleArchive = () => {
    const { entity, onClose } = this.props
    const deleteEntity = () => {
      return entity.delete()
        .then(() => onClose ? onClose() : null)
        .catch((error) => {
          // bubble up error to delete popup modal
          const deleteErrors = !error.responseJSON ? error : convertServerError(error)
          this.popUpRef.refreshErrors(deleteErrors.errors)
          throw deleteErrors
        })
    }
    ConfirmationModal.open({
      confirmationText: 'Do you want to delete this item?',
      confirmationTitle: 'Confirm Delete',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: deleteEntity,
      primaryButtonText: 'Confirm',
    }, (ref) => { this.popUpRef = ref })
  }

  private handleUnArchive = () => {
    const { entity } = this.props
    entity.undelete()
  }

  private handleRetry = () => {
    const { entity } = this.props
    entity.retry()
  }

  private handleInvalidate = () => {
    const { entity } = this.props
    entity.invalidate()
  }

  private handleRecompute = () => {
    const { entity } = this.props
    entity.recompute()
  }

  private handleDownloadPdf = () => {
    const { entity } = this.props
    const attachments = [{
      entityId: entity.uniqueId,
      fileName: `${entity.displayName}.pdf`,
      type: 'document',
    }]
    apis.batchDownloadDocuments({ attachments }).then((url) => {
      window.location = url
    })
  }

  private handleTriggerPush = () => {
    const { entity } = this.props
    apis.batchTriggerPush([entity.uniqueId])
  }

  private handleRenderPdf = () => {
    const { entity } = this.props
    this.setState({
      isLoading: true,
    })
    apis.getPrintableDownloadUri(entity.uniqueId).then((data: any) => {
      this.setState({
        isLoading: false,
      })
      window.open(data.uri)
    })
  }
}
