import { WithDefaultProps } from 'browser/components/atomic-elements/higher-order-components/with-default-props'
import { RecipientsField } from 'browser/components/atomic-elements/molecules/fields/recipients-field'

import '../../styles/_control.scss'
import { JSONSelectFactory } from 'browser/components/json-elements/higher-order-components/json-select-factory'
import { ISelectProps } from 'browser/components/atomic-elements/atoms/select/select/interface'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import React from 'react'

const MobileSelectImpl: React.FC<ISelectProps> = (props: ISelectProps) => {
  return (
    <RecipientsField
      {...props}
      styles={{
        valueContainer: (base) => ({
          ...base,
          height: '44pt',
        }),
        control: (base) => ({
          ...base,
          height: '44pt',
        }),
      }}
    />
  )
}

const UIRecipientsField = JSONSelectFactory(MobileSelectImpl)

export const MobileRecipientsField = WithDefaultProps(UIRecipientsField, {
  inputClassName: 'mobile-control',
})
