import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core'
import _ from 'lodash'
import React from 'react'
import { findDOMNode } from 'react-dom'
import { findAndClickFirstInput } from './utils'

type TempProps = Partial<{
  label: any
  frames: any
  errors: any
  value: any
  focusHotkey: any
}>

// This extracts the quantity unit options from the data schema
// This logic cannot live inside the Atomic elements because
// we agreed that Atomic elements should be agnostic to the json schema
export function JSONQuantityFactory<T extends TempProps>(
  QuantityComponent: React.ComponentType<T>
): React.ComponentType<T> {
  @HotkeysTarget
  class QuantityWrapper extends React.Component<T, any> {
    public static displayName = 'JSONQuantityFactory'

    private defaultUnit: string
    private unitOptions: any[]

    constructor(props: T) {
      super(props)
      const { defaultUnit, unitOptions } = this.getQuantityProps(props)
      this.defaultUnit = defaultUnit
      this.unitOptions = unitOptions
    }

    public render() {
      return (
        <QuantityComponent
          defaultUnit={this.defaultUnit}
          unitOptions={this.unitOptions}
          {...this.props}
        />
      )
    }

    public renderHotkeys() {
      const { focusHotkey, label } = this.props
      if (focusHotkey) {
        return (
          <Hotkeys>
            <Hotkey
              allowInInput={true}
              combo={focusHotkey}
              label={`Focus '${label}'`}
              onKeyDown={this.focus}
              stopPropagation={true}
              preventDefault={true}
              global={true}
            />
          </Hotkeys>
        )
      } else {
        return <Hotkeys />
      }
    }

    private focus = () => {
      // Will only work on wrapped components that use an input
      const element: any = findDOMNode(this)
      findAndClickFirstInput(element)
    }

    private getQuantityProps(props) {
      const { frames } = props
      const schema = frames.getContext('dataSchema')
      const unitSchema: any = _.get(schema, 'properties.unit', {})
      const defaultUnit = unitSchema.default
      let unitOptions = unitSchema.enumOptions
      if (_.isEmpty(unitOptions)) {
        const enumValues = unitSchema.suggestions || unitSchema.enum
        unitOptions = _.map(enumValues, (enumValue) => {
          return { label: enumValue, value: enumValue }
        })
      }
      return { defaultUnit, unitOptions }
    }
  }

  return QuantityWrapper
}
