import React from 'react'

export class PickerWheelGroup extends React.Component {
  public render() {
    return (
      <div className="c-mobile-pickerWheel-group">
        <div className="reticle" />
        {this.props.children}
      </div>
    )
  }
}
