import _ from 'lodash'
import React from 'react'

import {
  IInputSheetProps,
  InputSheetFactory,
} from 'browser/components/atomic-elements/higher-order-components/input-sheet-factory'
import {
  HoursOfOperationInput,
  IHoursOfOperationInputProps,
} from './hours-of-operation-input'

export const HoursOfOperationSheet = InputSheetFactory<IHoursOfOperationInputProps>(
  HoursOfOperationInput,
  // No default props needed
  {},
  // Need to override these props in the sheet
  {
    showEditableFields: true,
  },
)
