import React from 'react'
import _, { get } from 'lodash'
import { ComponentsContext, IComponentsContext } from 'browser/contexts/components/components-context'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { IModalProps } from 'browser/components/atomic-elements/atoms/modal'
import { Feed } from 'browser/components/atomic-elements/organisms/feed'
import { MobileModal } from 'browser/mobile/elements/modal/modal'
import { Settings } from 'browser/app/models/settings'
import { Entity } from 'shared-libs/models/entity'
import { Icon, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import 'browser/mobile/elements/modal/_modal.scss'
import 'browser/mobile/components/feed/_feed.scss'
import { translateString } from 'shared-libs/helpers/utils'
import { globalTranslationTable } from 'browser/mobile/util/global-translation-table'
import { IInboxContext, InboxContext } from 'browser/contexts/inbox/inbox-context'
import { Verb } from 'browser/contexts/inbox/interface'

const translatedInputPlaceholder = translateString('Feed_Type_A_Message', globalTranslationTable)
const translatedEmptyState = translateString('Feed_No_Comments_Yet', globalTranslationTable)

export interface IChatModalProps extends IBaseProps {
  entity: Entity
  onClose?: () => void
}

export class ChatModal extends React.PureComponent<IChatModalProps> {
  public render() {
    const { entity } = this.props
    return (
      <>
        <header className="mobile-header">
          {this.renderTitle()}
          {this.renderExpander()}
          {this.renderCloseButton()}
        </header>
        <Feed
          className="modal-body"
          timestampDisplayMode='grouped'
          entityType="/1.0/entities/metadata/feedEvent.json"
          emptyStateMessage={translatedEmptyState}
          commentInputPlaceholder={translatedInputPlaceholder}
          entity={entity}
        />
      </>
    )
  }

  private renderTitle() {
    const translatedTitle = translateString('Feed_Conversation', globalTranslationTable)
    return (
      <span className="mobile-header__title-container">
        <h1 className="mobile-header__title">{translatedTitle}</h1>
      </span>
    )
  }

  private renderExpander() {
    return <span className="flex-grow" />
  }

  private renderCloseButton() {
    const { onClose } = this.props
    return (
      <Button
        intent={Intent.PRIMARY}
        data-debug-id="headerChatButton"
        className="mobile-header__button-active bg-blue"
        onClick={onClose}
      >
        <Icon icon={IconNames.CROSS} />
      </Button>
    )
  }

  public static open(
    props: IChatModalProps,
    settings: Settings,
    componentsContext: IComponentsContext,
    inboxContext: IInboxContext,
    modalProps?: IModalProps
  ) {
    const { markAsRead } = inboxContext
    const unreadComments = getUnreadComments(props.entity, inboxContext)
    const unreadIds = _.map(unreadComments, a => a.groupId)
    markAsRead(unreadIds)

    // TODO: the modal is outside the scope of the context providers since it
    // renders in the portal sitting in the root <body> element as a sibling to
    // the app root, so providing it here. May want to generalize this to
    // "mobile modals" so any components within can use contexts.
    const ModalWithContext = (injectedProps) => (
      <AppNavigatorContext.Provider value={{ settings: settings }}>
        <ComponentsContext.Provider value={componentsContext}>
          <InboxContext.Provider value={inboxContext}>
            <ChatModal {...props} {...injectedProps} />
          </InboxContext.Provider>
        </ComponentsContext.Provider>
      </AppNavigatorContext.Provider>
    )
    return MobileModal.open(<ModalWithContext />, modalProps)
  }
}

// TODO: give me a better home
export function getUnreadComments(entity: Entity, inboxContext: IInboxContext) {
  return _.filter(inboxContext.activities, (activity) => {
    const object: Entity = activity.object as Entity
    return !activity.read && activity.verb === Verb.COMMENT && object?.uniqueId == entity.uniqueId
  })
}
