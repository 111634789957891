import React, { Reducer, useEffect, useReducer, useState } from 'react'
import _ from 'lodash'
import SplitPane from 'react-split-pane'
import { createPatch } from 'rfc6902'
import { Pointer } from 'rfc6902/pointer'
import { Icon, Position, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { EventSubscription } from 'fbemitter'

import { Entity } from 'shared-libs/models/entity'
import { Store } from 'shared-libs/models/store'

import { IModalPortalProps, ModalPortal } from 'browser/components/atomic-elements/atoms/modal/portal'
import { JSONEditor } from 'browser/components/atomic-elements/organisms/internal-tools/json-editor'
import { CodeMirrorInput } from 'browser/components/atomic-elements/atoms/input/code-mirror-input'
import { EntityFooter } from 'browser/components/atomic-elements/atoms/footer/entity-footer'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { useLocalStorage } from 'browser/components/hooks/useLocalStorage'
import { useForceUpdate } from 'browser/components/hooks/useForceUpdate'
import classNames from 'classnames'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { linter } from '@codemirror/lint'

interface IEntityJsonModalProps extends IModalPortalProps {
  entity: Entity
}

const PERSISTENCE_KEY = "admin_json_editor_diff_enabled"

export const EntityJsonModal = (props: IEntityJsonModalProps) => {
  const { entity, onClose } = props
  const [isDiffEnabled, setIsDiffEnabled] = useLocalStorage(PERSISTENCE_KEY, true)
  const forceUpdate = useForceUpdate()

  return (
    <ModalPortal
      className="u-flex flex-column"
      modalDialogClassName="c-modal-dialog--xl h-100"
      modalPaperClassName="u-flex flex-column h-100"
      {...props}
    >
      <div className="c-modalHeader collapse u-textCenter">
        <ModalHeader
          entity={entity}
          diffEnabled={isDiffEnabled}
          handleDiffClick={() => {
            setIsDiffEnabled(!isDiffEnabled)
            forceUpdate()
          }}
          handleClearClick={() => {
            entity.rollback()
            entity.emit(Store.RECORD_CHANGED, entity)
            forceUpdate()
          }}
          onClose={onClose}
        />
      </div>

      <div className="c-modalBody collapse flex-grow u-textCenter">
        <ModalBody
          entity={entity}
          diffEnabled={isDiffEnabled}
        />
      </div>

      <div className="c-modalFooter collapse u-textCenter">
        <ModalFooter
          entity={entity}
          onClose={onClose}
        />
      </div>
    </ModalPortal>
  )
}

interface IModalHeaderProps extends IEntityJsonModalProps {
  diffEnabled: boolean
  handleDiffClick: (enabled: boolean) => void
  handleClearClick: () => void
}

const ModalHeader = (props: IModalHeaderProps) => {
  const { entity, diffEnabled, handleDiffClick, handleClearClick, onClose } = props

  return (
    <div className="grid-block shrink c-cardHeader">
      <div className="flex-column tl">
        <h4 className="c-modal-title mb1">
          {entity.displayName}
        </h4>
        <h3 className="c-modal-title c-label--secondary">
          {entity.entityType}
        </h3>
      </div>
      <div className="grid-block shrink">
        <Button
          isDisabled={!entity.isDirty}
          onClick={handleClearClick}
          className="c-cardHeader-item--smallMargin"
        >
          <Icon
            icon={IconNames.UNDO}
            title="Reset entity"
          />
        </Button>
        <Button
          isActive={diffEnabled}
          onClick={handleDiffClick}
          className="c-cardHeader-item--smallMargin"
        >
          <Icon
            icon={IconNames.PANEL_STATS}
            title={diffEnabled ? 'Hide Diff' : 'Show Diff'}
          />
        </Button>
        <Button
          onClick={() => onClose?.()}
          className="c-cardHeader-item--smallMargin"
        >
          <Icon
            icon={IconNames.CROSS}
            title="Close"
          />
        </Button>
      </div>
    </div>
  )
}

interface IModalBodyProps extends IEntityJsonModalProps {
  diffEnabled: boolean
}

interface IModalBodyState {
  diff?: any
  diffString?: string
}

const ModalBody = (props: IModalBodyProps) => {
  const { entity, diffEnabled } = props
  const [state, setState] = useReducer<Reducer<IModalBodyState, Partial<IModalBodyState>>>(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    {
      diff: [],
      diffString: "[]",
    }
  )

  useEffect(() => {
    recomputeDiff()
    const subscriptions: EventSubscription[] = [
      entity.addListener(Store.RECORD_CHANGED, recomputeDiff),
      entity.addListener(Store.RECORD_RESET, recomputeDiff),
    ]
    return () => {
      subscriptions.forEach((subscription) => subscription.remove())
    }
  }, [diffEnabled])

  const handleJsonChanged = () => {
    entity.emit(Store.RECORD_CHANGED, entity)
  }

  const recomputeDiff = () => {
    if (!diffEnabled) {
      return
    }
    const diff = createPatch(entity.prevContent, entity.content, (left: any, right: any, ptr: Pointer) => {
      if (_.isArray(left) && _.isArray(right) && _.size(left) * _.size(right) > 1e5) {
        const same = _.isEqual(left, right)
        const tooLarge = _.size(left) * _.size(right) > 1e5
        if (!same && tooLarge) {
          return [{ op: 'replace', path: ptr.toString(), value: right }]
        }
      }
    })
    setState({
      diff,
      diffString: JSON.stringify(diff, null, 2),
    })
  }

  if (!diffEnabled) {
    return (
      <JSONEditor
        entity={entity}
        onChange={handleJsonChanged}
        className="tl h-100"
      />
    )
  } else {
    return (
      <SplitPane
        primary="first"
        split="vertical"
        defaultSize="60%"
        className="tl"
        paneStyle={{
          display: 'flex',
          overflow: 'hidden'
        }}
      >
        <div className="w-100 flex-grow">
          <JSONEditor
            entity={entity}
            onChange={handleJsonChanged}
            className="tl h-100"
          />
        </div>
        <div className="w-100 tl flex-grow">
          <CodeMirrorInput
            value={state.diffString}
            extensions={[json(), linter(jsonParseLinter())]}
            onChange={undefined}
            readOnly={true}
          />
        </div>
      </SplitPane>
    )
  }
}

const ModalFooter = (props: IEntityJsonModalProps) => {
  const { entity, onClose } = props
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])

  const handleSaveClick = () => {
    setIsSaving(true)
    entity.save()
      .then(() => setErrors(null))
      .catch(({ errors }) => setErrors(errors))
      .finally(() => setIsSaving(false))
  }

  return (
    <EntityFooter
      entity={entity}
      errors={errors}
      isPrimaryButtonLoading={isSaving}
      cancelButtonText={""}
      primaryButtonText="Save"
      isVisible={true}
      isPrimaryButtonDisabled={!entity.isDirty}
      onCancelButtonClick={() => onClose?.()}
      onPrimaryButtonClick={handleSaveClick}
      primaryButtonHotkey={{
        label: 'Save',
        combo: 'mod+shift+s',
      }}
      cancelButtonHotkey={{
        label: 'Close',
        combo: 'esc',
      }}
    />
  )
}
