import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { List } from 'browser/components/atomic-elements/atoms/list'
import { IRenderListItemProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { SelectList } from 'browser/components/atomic-elements/atoms/select/select-list'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'

interface IDependenciesSectionProps extends IBaseProps {
  bundle: any
}

interface IDependenciesSectionState {
}

export class DependenciesSection extends React.Component<IDependenciesSectionProps, IDependenciesSectionState> {

  public store: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
  }

  public renderDependencyItem = (props: IRenderListItemProps) => {
    const { item, index } = props
    return (
      <div
        className='u-flex u-justifyContentSpaceBetween'
        key={index}
      >
        <div>
          {item.displayName}
        </div>
        <Button
          className={Classes.MINIMAL}
          size='xs'
        >
          Delete
        </Button>
      </div>
    )
  }

  public renderAddDependencyButton = () => {
    const addDependencyPopover = (
      <Popover
        className='collapse'
      >
        <SelectList
          autoFocus={true}
          options={[]}
        />
      </Popover>
    )
    return (
      <TetherTarget
        tethered={addDependencyPopover}
      >
        <Button>
          Add additional bundle
        </Button>
      </TetherTarget>
    )
  }

  public render() {
    const { bundle } = this.props
    const dependencies = bundle.applicationBundle.extends
    return (
      <Section
        title='Dependencies'
      >
        <div className='row'>
          <div className='col-xs-4'>
            <p>
              Lists defined bundles.
            </p>
          </div>
          <div className='col-xs-8'>
            <Label>Bundles</Label>
            <List
              renderAddButton={this.renderAddDependencyButton}
              renderListItem={this.renderDependencyItem}
              value={dependencies}
            />
          </div>
        </div>
      </Section>
    )
  }
}
