import React from 'react'
import _ from 'lodash'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { GenerateOneUseTokenDialog } from 'browser/components/domain/user/generate-one-use-token-dialog'
import { IEmail, IPhone } from './types'
import { isVerified } from './utils'

import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'

/**
 * @uiComponent
 */
interface IGenerateOneUseTokenButtonProps {
  targetUserId: string
  phoneNumbers?: IPhone[]
  additionalIdentities: any[]
  emails?: IEmail[]
}

export const GenerateOneUseTokenButton: React.FC<IGenerateOneUseTokenButtonProps> = (props) => {
  const { emails, phoneNumbers, additionalIdentities, targetUserId } = props

  const openDialog = (identity: string) => {
    GenerateOneUseTokenDialog.open({ targetUserId, identity })
  }

  const verifiedEmails: string[] = _.map(_.filter(emails, isVerified), 'value')
  const verifiedPhones: string[] = _.map(_.filter(phoneNumbers, isVerified), 'value.phone')
  const driverUniqueIds: string[] = _.map(additionalIdentities, 'driverUniqueId')
  const allLogins = _.concat(verifiedEmails, verifiedPhones, driverUniqueIds)

  if (allLogins.length <= 1) {
    return <Button isDisabled={allLogins.length === 0} buttonText="Generate One Use Token" onClick={() => openDialog(allLogins[0])} />
  } else {
    return (
      <TetherTarget
        closeOnPortalClick={true}
        tethered={(
          <Popover className="collapse" data-debug-id="document-options-dropdown">
            <div>
              <ul className="c-dropdownList">
                {allLogins.map((identity) => (
                  <li className="c-dropdownList-item" onClick={() => openDialog(identity)} key={identity}>
                    {identity}
                  </li>
                ))}
              </ul>
            </div>
          </Popover>
        )}
      >
        <Button buttonText="Generate One Use Token" />
      </TetherTarget>
    )
  }
}
