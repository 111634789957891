import _ from 'lodash'
import React from 'react'

import { AbstractEntityView, IAbstractEntityViewState } from 'browser/components/containers/abstract-entity-view'
import PropTypes from 'prop-types'

export class EntityDetailView extends AbstractEntityView<IAbstractEntityViewState> {
  public static contextTypes = {
    settings: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      entity: null,
      errors: null,
      isLoading: true,
      isSaving: false,
      settings: _.get(this, 'store.api.settings'),
    }
  }
}
