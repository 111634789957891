import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import 'browser/components/atomic-elements/atoms/select/select/_native-select.scss'
import { InputFieldSheet } from 'browser/components/atomic-elements/molecules/fields/input-field//input-field-sheet'
import { defaultProps, ISelectProps } from './interface'
import { translateString } from 'shared-libs/helpers/utils'

const CUSTOM_OPTION_VALUE = '_custom'

interface INativeSelectState {
  customValue: string
  isSheetOpened: boolean
  options: any[]
}

const NULL = '__null'

// It is important that native select must be a PureComponent otherwise
// we run into some crazy performance bug
export class NativeSelect extends React.PureComponent<ISelectProps, INativeSelectState> {
  public static defaultProps = defaultProps

  private select: any

  constructor(props) {
    super(props)
    this.state = {
      customValue: null,
      isSheetOpened: false,
      options: this.getNextOptions(props),
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.options !== nextProps.options ||
        this.props.isCreatable !== nextProps.isCreatable ||
        this.props.value !== nextProps.value) {
      this.setState({ options: this.getNextOptions(nextProps) })
    }
  }

  public focus() {
    this.select.focus()
  }

  public render() {
    const {
      autoFocus,
      dir,
      isDisabled,
      onFocus,
      onBlur,
      value,
      size,
      errorText,
      isEditableInline,
      isHorizontalLayout,
      isStatic,
      isRequired,
      optionGroupPath,
      placeholder,
      inputClassName,
      isEditableLabel,
    } = this.props
    const { options } = this.state
    const sizeClassName = _.isEmpty(size) ? '' : `c-nativeSelect--${size}`
    // TODO(louis): xoxo need to figure this out
    // const contentPlaceholder = isRequired ? placeholder + ' (required)' : placeholder
    // TODO(peter/louis): need your help, don't know how to reliably check if it has a value
    const hasPlaceholderStyling = _.isNil(value)
    const selectValue = value || ''
    // Note this is dependent on $c-label-textAlign--horizontal
    // const contentDir = isEditableLabel ? 'rtl' : dir
    return (
      <div className='c-nativeSelect-container'>
        <select
          autoFocus={autoFocus}
          className={classNames('c-nativeSelect', sizeClassName, inputClassName, {
            'c-nativeSelect--error': !_.isEmpty(errorText),
            'c-nativeSelect--isDisabled': isDisabled,
            'c-nativeSelect--isEditableInline': isEditableInline,
            'c-nativeSelect--isEditableLabel c-label--isHorizontal': isEditableLabel,
            'c-nativeSelect--isHorizontalLayout': isHorizontalLayout,
            'c-nativeSelect--isStatic': isStatic,
          })}
          disabled={isDisabled || isStatic}
          onChange={this.handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={(ref) => { this.select = ref }}
          value={selectValue}
          required={true}
        >
          <option disabled={true} key='placeholder' value=''>
            {placeholder}
          </option>
          {optionGroupPath ? this.renderGroupOptions(options) : this.renderOptions(options)}
        </select>
        <Icon
          icon={IconNames.CHEVRON_DOWN}
          className={classNames('c-nativeSelect-caret', {
            'u-hide': isStatic,
          })}
        />
        {this.renderCustomOptionSheet()}
      </div>
    )
  }

  private getNextOptions(props) {
    const {
      isCreatable,
      options,
      optionValuePath,
      optionLabelPath,
      value,
    } = props
    let nextOptions = options.slice()

    if (!_.isEmpty(value) && !_.find(options, { [optionValuePath]: value })) {
      nextOptions.push({ label: value, value })
    }

    if (isCreatable) {
      nextOptions.push({ label: 'Custom', value: CUSTOM_OPTION_VALUE })
    }

    const { showRemoveOption, removeOptionText } = this.props
    if (showRemoveOption) {
      const removeOption = {
        [optionLabelPath]: removeOptionText || 'Remove Value',
        [optionValuePath]: NULL,
      }
      nextOptions = [removeOption].concat(nextOptions)
    }

    return nextOptions
  }

  private renderGroupOptions(options) {
    const { optionGroupPath, optionLabelPath, optionValuePath } = this.props
    const groupings = _.groupBy(options, optionGroupPath)
    return _.map(groupings, (group, groupName) => {
      return (
        <optgroup label={groupName} key={groupName}>
          {this.renderOptions(group)}
        </optgroup>
      )
    })
  }

  private renderOptions(options) {
    const { optionMapper, optionLabelPath, optionValuePath, frames } = this.props

    let opts = options
    if (optionMapper) {
      opts = optionMapper(opts)
    }
    const translationTable = frames?.getContext('translationTable')
    return _.map(opts, (option) => {
      const value = option[optionValuePath]
      const translatedLabel = translateString(_.get(option, optionLabelPath), translationTable)
      return (
        <option key={value} value={value}>
          {translatedLabel}
        </option>
      )
    })
  }

  private renderCustomOptionSheet() {
    const { isCreatable, onChange } = this.props
    if (!isCreatable) {
      return
    }
    const { isSheetOpened, customValue } = this.state
    const handleChange = (val) => this.setState({ customValue: val })
    const handleCancel = () => this.setState({ isSheetOpened: false })
    const handleSave = () => {
      const value = this.state.customValue
      const option = { label: value, value }
      onChange(value, option)
      this.setState({ isSheetOpened: false })
    }
    return (
      <InputFieldSheet
        autoFocus={true}
        isHorizontalLayout={true}
        isOpen={isSheetOpened}
        value={customValue}
        onChange={handleChange}
        onCancel={handleCancel}
        onSave={handleSave}
      />
    )
  }

  private handleChange = (event) => {
    const { isCreatable } = this.props
    const value = event.target.value
    if (isCreatable && value === CUSTOM_OPTION_VALUE) {
      this.setState({ isSheetOpened: true })
      return
    }

    if (value === NULL) {
      this.props.onChange(undefined)
      return
    }

    const option = _.find(this.props.options, {
      [this.props.optionValuePath]: value,
    })
    this.props.onChange(value, option)
  }
}
