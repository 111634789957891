import { Tab, Tabs } from '@blueprintjs/core'
import _ from 'lodash'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { EntityIndex as EntityPage } from 'browser/app/pages/app/entity'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { MasterDetail } from 'browser/components/atomic-elements/atoms/master-detail/master-detail'
import 'browser/components/atomic-elements/organisms/inbox/_inbox.scss'
import { InboxTab } from 'browser/components/atomic-elements/organisms/inbox/inbox-tab'

const SentryRoute = Sentry.withSentryRouting(Route);

const NOTIFICATION_SCHEMA_URI = '/1.0/entities/metadata/notification.json'

interface IInboxProps extends IBaseProps {
  match: any
}

export class Inbox extends React.Component<IInboxProps, any> {

  constructor(props) {
    super(props)
    this.state = {
      activeTabId: 'activity',
    }
  }

  public render() {
    const { match } = this.props
    return (
      <AppNavigatorContext.Consumer>
        {({settings}) => (
          <div className='grid-block'>
            <MasterDetail
              showDetailPanel={!match.isExact}
              masterPanel={this.renderMasterPanel(settings)}
              detailPanel={this.renderDetailRoutes()}
            />
          </div>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private renderDetailRoutes() {
    const { match } = this.props
    const route = match.url
    return (
      <Switch>
        <SentryRoute
          path={`${route}/entity/:entityId`}
          render={this.handleRenderEntityDetail}
        />
      </Switch>
    )
  }

  private handleRenderEntityDetail = (props) => {
    const { history, location, match, staticContext } = props
    return (
      <EntityPage
        history={history}
        location={location}
        match={match}
      />
    )
  }

  private renderMasterPanel(settings) {
    const { match } = this.props
    return (
      <Tabs
        className='grid-block vertical c-tabs'
        id='inboxTabs'
        onChange={this.handleTabChange}
        renderActiveTabPanelOnly={true}
      >
        <Tab
          className='grid-block vertical'
          id='activity'
          title='Activity'
          panel={<InboxTab isArchived={false} match={match} settings={settings} />}
        />
        <Tab
          className='grid-block vertical'
          id='archived'
          title='Archived'
          panel={<InboxTab isArchived={true} match={match} settings={settings} />}
        />
        <Tabs.Expander />
      </Tabs>
    )
  }

  private handleTabChange = (activeTabId: string) => {
    this.setState({ activeTabId })
  }
}
