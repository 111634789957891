import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core'
import { browserHistory } from 'browser/history'
import classNames from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import React from 'react'
import { Motion, presets, spring } from 'react-motion'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/modal/_modal.scss'

/**
 * @uiComponent
 */
export interface IModalProps extends IBaseProps {
  enableHotkeys?: boolean
  backdropClosesModal?: boolean
  bodyClassName?: string
  modalDialogClassName?: string
  modalBackdropClassName?: string
  onClose?: () => void
  stackingOrder?: number
  modalPaperClassName?: string
  modalStyle?: object
}

@HotkeysTarget
export class Modal extends React.Component<IModalProps, any, never> {

  public static defaultProps: Partial<IModalProps> = {
    backdropClosesModal: false,
    bodyClassName: 'c-modal-open',
    enableHotkeys: true,
    modalBackdropClassName: '',
    modalDialogClassName: '',
    modalPaperClassName: 'paper paper--zDepth-4',
    modalStyle: {},
    onClose: _.noop,
  }

  private modalBody: Array<React.ReactElement<any>>
  private modalRef: HTMLElement

  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
    }
  }

  public componentDidMount() {
    const { bodyClassName } = this.props
    $('body').addClass(bodyClassName)
    this.setState({ isVisible: true })
    this.focusModal()
  }

  public UNSAFE_componentWillUpdate(nextProps, nextState) {
    // update modal body so we don't clone children during animation
    this.modalBody = React.Children.map(nextProps.children, (child) => {
      const element = child as React.ReactElement<any>
      return React.cloneElement(element, {
        onClose: this.closeModal.bind(this),
      })
    })
  }

  public componentWillUnmount() {
    const { bodyClassName } = this.props
    $('body').removeClass(bodyClassName)
  }

  public closeModal() {
    this.setState({ isVisible: false })
  }

  public render() {
    const { className } = this.props
    return (
      <div
        className={className}
        tabIndex={-1}
        ref={this.handleSetModalRef}
      >
        <Motion
          defaultStyle={{ opacity: 0 }}
          onRest={this.handleOnAnimationEnd}
          style={{
            opacity: spring(this.state.isVisible ? 1 : 0, presets.stiff),
          }}
        >
          {(interpolatingStyle) => this.renderModal(interpolatingStyle)}
        </Motion>
      </div>
    )
  }

  public renderHotkeys() {
    const { enableHotkeys } = this.props
    // HotkeysTarget must return <Hotkeys/>
    if (!enableHotkeys) {
      return <Hotkeys />
    }
    return (
      <Hotkeys>
        <Hotkey
          allowInInput={true}
          group='Panels'
          combo='esc'
          label='Close Modal'
          onKeyDown={this.handleEscHotkey}
          stopPropagation={true}
          preventDefault={true}
        />
      </Hotkeys>
    )
  }

  // Note that the backdrop has to be a sibling of the modal, or otherwise
  // it blocks the scroll.
  private renderModal(interpolatingStyle) {
    const { backdropClosesModal, modalBackdropClassName, modalDialogClassName, modalPaperClassName } = this.props
    return (
      <div>
        <div
          className={classNames('c-modal-backdrop u-noPrint', modalBackdropClassName)}
          onClick={backdropClosesModal ? browserHistory.goBack : null}
          style={interpolatingStyle}
        />
        <div
          className='c-modal'
          style={interpolatingStyle}
        >
          <div className={classNames('c-modal-dialog', modalDialogClassName)} style={this.props.modalStyle}>
            <div className={classNames('c-modal-content', modalPaperClassName)}>
              {this.modalBody}
            </div>
          </div>
        </div>
      </div>
    )
  }

  private focusModal = () => {
    if (this.modalRef != null) {
      this.modalRef.focus()
    }
  }

  private handleOnAnimationEnd = () => {
    if (!this.state.isVisible) {
      this.props.onClose()
    }
  }

  private handleEscHotkey = (event) => {
    event.stopImmediatePropagation()
    this.props.onClose()
  }

  private handleSetModalRef = (ref: Element) => {
    this.modalRef = ref as HTMLElement
  }
}
