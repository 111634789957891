import React from 'react'
import _ from 'lodash'
import { Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'

import {
  ISignatureFieldProps,
} from 'browser/components/atomic-elements/molecules/fields/esignature-field/esignature-field'
import LabelFieldFactory from 'browser/components/atomic-elements/higher-order-components/label-field-factory'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { getDebugId } from 'browser/app/utils/utils'
import { ISignatureDrawingProps, ISignaturePadProps, SignaturePad } from './signature-pad-modal'
import { ImageLoader } from 'browser/components/atomic-elements/atoms/image-loader/image-loader'

import '../../styles/_control.scss'
import './_mobile-signature-field.scss'
import { MobileModal } from 'browser/mobile/elements/modal/modal'

/**
 * @uiComponent
 */
interface IMobileSignatureProps extends ISignatureFieldProps, ISignatureDrawingProps {
  modalStyle?: object
}

interface IMobileSignatureState {
  localFileUri?: string
}

export class MobileSignature extends React.Component<IMobileSignatureProps, IMobileSignatureState> {
  constructor(props: IMobileSignatureProps) {
    super(props)

    this.state = {}
  }

  render() {
    const { localFileUri } = this.state
    return <SignatureField {...this.props} onOpen={this.onOpen} localFileUri={localFileUri} />
  }

  private onChange = (value: any, localFileUri: string) => {
    this.props.onChange(value)
    this.setState({
      localFileUri,
    })
  }

  private onOpen = () => {
    MobileSignature.open(this.props, this.onChange)
  }

  private static open(props: IMobileSignatureProps, onSigned: ISignaturePadProps['onSigned']) {
    const { modalStyle } = props
    return MobileModal.open(<SignaturePad {...props} onSigned={onSigned} />, {
      modalDialogClassName: 'overflow-hidden',
      ...(!_.isEmpty(modalStyle) && { modalStyle }), // only set if not empty to avoid override the value in the subsequent call
    })
  }
}

const SignatureField = (
  props: IMobileSignatureProps & { onOpen: () => void; localFileUri?: string }
) => {
  const { frames, value, immutableSignature, localFileUri, onOpen } = props
  const isSigned = value?.signed

  if (isSigned) {
    const sourceUrl = _.get(value, 'signature.source.0.uri')
    const origUrl = _.get(value, 'uri', '')
    const signatureUrl =
      (!_.startsWith(sourceUrl, 'file://') && sourceUrl) || origUrl || localFileUri

    return (
      <div className="c-fakeInputContainer" onClick={immutableSignature ? _.noop : onOpen}>
        <ImageLoader src={signatureUrl} />
      </div>
    )
  }

  return (
    <Button
      className={classNames(
        'mobile-button w-100',
        Classes.iconClass(IconNames.EDIT),
        Classes.INTENT_PRIMARY
      )}
      onClick={onOpen}
      data-debug-id={getDebugId(frames)}
    >
      Add Signature
    </Button>
  )
}

export const MobileESignatureField = LabelFieldFactory({ InputComponent: MobileSignature })
