import _ from 'lodash'
import numeral from 'numeral'
import React from 'react'
import ReactDOM from 'react-dom'
const d3 = require('d3')

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'

function parseData(value) {
  if (value === 'NaN') {
    return 0
  }
  return value
}

interface IHistogramProps extends IBaseProps {
  benchmark: any[]
  data: any[]
  width?: number
  height?: number
}

export class Histogram extends React.Component<IHistogramProps, any> {
  public svg: any

  public componentDidMount() {
    this.redraw(this.props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props, nextProps)) {
      this.redraw(nextProps)
    }
  }

  public redraw(props) {
    const { data } = props
    const svgWidth = props.width
    const svgHeight = props.height
    const margin = {top: 10, right: 0, bottom: 30, left: 50}
    const width = svgWidth - margin.left - margin.right
    const height = svgHeight - margin.top - margin.bottom

    const parseDate = d3.timeParse('%Y-%m-%dT%H:%M:%S.%LZ')
    const formatDate = d3.timeFormat('%m/%y')
    const formatMoney = (value) => numeral(value).format('($0a)')

    const keys = ['revenue', 'expense']
    if (!_.isArray(data)) {
      return
    }
    const loads = _.map(data, (row: any) => ({
      expense: parseData(row.computations.averageExpense),
      revenue: parseData(row.computations.averageRevenue),
      x: parseDate(row.data.start),
    }))

    // Scales
    const x0 = d3.scaleBand()
      .domain(loads.map((d) => d.x))
      .rangeRound([margin.left, width - margin.right])
      .paddingInner(0.1)
    const x1 = d3.scaleBand()
      .domain(keys)
      .rangeRound([0, x0.bandwidth()])
      .padding(0.05)
    const y = d3.scaleLinear()
      .domain([0, d3.max(loads, (d) => Math.max(d.revenue, d.expense))])
      .rangeRound([height, margin.top])
    const z = d3.scaleOrdinal()
      .range(['#ffcd9b', '#f89939'])

    // Axis variables for the bar chart

    const xAxis = d3.axisBottom().scale(x0).tickFormat(formatDate)
    if (loads.length > 10) {
      const modValue = Math.round(loads.length / 10)
      const xTicks = x0.domain().filter((d, i) => !(i % modValue))
      xAxis.tickValues(xTicks)
    }
    const yAxis = d3.axisLeft().scale(y).ticks(5).tickFormat(formatMoney)

    // Prepare the barchart canvas
    const svgNode = ReactDOM.findDOMNode(this.svg)
    const svg = d3.select(svgNode)
    svg.selectAll('*').remove()

    const barchart = svg
      .attr('width', svgWidth)
      .attr('height', svgHeight)
      .append('g')

    barchart
      .selectAll('g.freq')
      .data(loads)
      .enter().append('g')
      .attr('class', 'freq')
      .attr('transform', (d) => `translate(${x0(d.x)},0)`)
      .selectAll('rect')
      .data((d) => _.map(keys, (key) => ({ key, value: d[key] })))
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => x1(d.key))
      .attr('y', (d) => y(d.value))
      .attr('width', x1.bandwidth())
      .attr('height', (d) => height - y(d.value))
      .attr('fill', (d) => z(d.key))

    // x axis
    barchart.append('g')
      .attr('class', 'x c-axis')
      .attr('transform', 'translate(0,' + height + ')')
      .style('fill', '#9e9e9e')
      .call(xAxis)

    // y axis
    barchart.append('g')
      .attr('class', 'y c-axis')
      .attr('transform', `translate(${margin.left}, 0)`)
      .style('fill', '#9e9e9e')
      .call(yAxis)
  }

  public render() {
    return (
      <div>
        <svg
          ref={(svg) => { this.svg = svg }}
        />
        {this.renderLegend()}
      </div>
    )
  }

  private renderLegend() {
    // TODO(Peter): do not hardcode this
    const colors = ['#ffcd9b', '#f89939']
    const keys = ['Revenue', 'Expense']
    return (
      <div className='u-flex u-justifyContentCenter'>
        {_.map(keys, (key, index) => {
          return (
            <div
              className='c-legend'
              key={key}
            >
              <div
                className='c-legend-indicator'
                style={{
                  backgroundColor: colors[index],
                }}
              />
              <HelpBlock className='u-topFlush'>
                {key}
              </HelpBlock>
            </div>
          )
        })}
      </div>
    )
  }
}
