import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/table/_table-settings-button.scss'
import { TableConfigurationSheet } from 'browser/components/atomic-elements/atoms/table/table-configuration-sheet'
import { SheetContext } from '../sheet/sheet-manager'

interface ITableSettingsButtonProps extends IBaseProps {
  availableColumns: any[]
  columns: any[]
  entitySchema?: any
  groups?: any[]
  isLoadingTable?: boolean
  onChange: (value: any) => void
  orders?: any[]
}

export class TableSettingsButton extends React.Component<ITableSettingsButtonProps, any> {

  constructor(props) {
    super(props)
    this.handleOpenTableConfigSheet = _.debounce(this.handleOpenTableConfigSheet, 1000, {
      leading: true,
    })
  }

  public render() {
    const { className } = this.props
    // TODO(peter/david/louis): remove u-hide to show table settings
    return (
      <SheetContext.Consumer>
        {({openOverlay}) => (
          <div
            className={classNames('c-tableSettingsButton u-noPrint', className)}
            onClick={() => this.handleOpenTableConfigSheet(openOverlay)}
          >
            <Icon
              icon={IconNames.COG}
            />
          </div>
        )}
      </SheetContext.Consumer>
    )
  }

  private handleOpenTableConfigSheet = (openOverlay) => {
    openOverlay(
      <TableConfigurationSheet
        {...this.props}
      />,
    )
  }
}
