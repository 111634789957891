import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { ImageLoader } from 'browser/components/atomic-elements/atoms/image-loader/image-loader'
import { ILabelProps, Label } from 'browser/components/atomic-elements/atoms/label/label'
import 'browser/components/atomic-elements/molecules/fields/signature-field/_signature-field.scss'
import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { translateString } from 'shared-libs/helpers/utils'

/**
 * @uiComponent
 */
interface ISignatureFieldProps extends IBaseProps {
  frames?: FramesManager
  density?: string
  errorText?: string
  helpText?: string
  value: object
  isHorizontalLayout?: boolean
  size?: string
  label: string
  isOptional?: boolean
  isRequired?: boolean
  labelProps?: ILabelProps
  inputWidthClassName?: string
  emptySignaturePlaceholder?: string
}

export class SignatureField extends React.PureComponent<ISignatureFieldProps, any> {
  public static defaultProps: Partial<ISignatureFieldProps> = {
    inputWidthClassName: 'c-formGroup-horizontalContent',
    emptySignaturePlaceholder: 'No Signature Attached'
  }

  public render() {
    const {
      value,
      isHorizontalLayout,
      size,
      className,
      density,
      inputWidthClassName,
    } = this.props
    const formGroupProps = {
      className,
      density,
      hasValue: !_.isNil(value),
      isHorizontalLayout,
      size,
    }
    if (isHorizontalLayout) {
      return (
        <FormGroup {...formGroupProps}>
          {this.renderLabel()}
          <div className={inputWidthClassName}>
            {this.renderSignature()}
            {this.renderHelpBlock()}
          </div>
        </FormGroup>
      )
    } else {
      return (
        <FormGroup {...formGroupProps}>
          {this.renderLabel()}
          {this.renderSignature()}
          {this.renderHelpBlock()}
        </FormGroup>
      )
    }
  }

  private renderHelpBlock() {
    const {
      helpText,
    } = this.props
    if (!helpText) {
      return
    }
    return (
      <HelpBlock
        key='helpBlock'
      >
        {helpText}
      </HelpBlock>
    )
  }

  private renderLabel() {
    const {
      frames,
      errorText,
      isHorizontalLayout,
      size,
      isOptional,
      isRequired,
      label,
      labelProps,
    } = this.props
    const translationTable = frames?.getContext('translationTable')
    const translatedLabel = translateString(label, translationTable)
    return (
      <Label
        {...labelProps}
        isHorizontalLayout={isHorizontalLayout}
        hasError={!_.isEmpty(errorText)}
        isOptional={isOptional}
        isRequired={isRequired}
        size={size}
      >
        {translatedLabel}
      </Label>
    )
  }

  private renderEmptySignature() {
    const { emptySignaturePlaceholder } = this.props
    return (
      <HelpBlock className='u-textCenter u-innerBumper--xl'>
        {emptySignaturePlaceholder}
      </HelpBlock>
    )
  }

  private renderSignature() {
    const {
      value,
      size,
    } = this.props
    // sometime source.0.uri is not ready yet, server still busy converting, then
    // just use the whatever it is uploaded in the mean time

    const sourceUrl = _.get(value, 'source.0.uri')
    const origUrl = _.get(value, 'uri', '')
    const signatureUrl = !_.startsWith(sourceUrl, 'file://') && sourceUrl || origUrl

    let content
    if (_.isEmpty(signatureUrl)) {
      content = this.renderEmptySignature()
    } else {
      content = (
        <ImageLoader
          className='c-signature'
          size={size}
          src={signatureUrl}
        />
      )
    }
    const sizeClass = _.isEmpty(size) ? 'c-fakeInputContainer' : `c-fakeInputContainer--${size}`
    return (
      <div className={sizeClass}>
        {content}
      </div>
    )
  }
}
