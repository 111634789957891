import _ from 'lodash'
import queryString from 'query-string'
import React from 'react'

import apis from 'browser/app/models/apis'
import { Card } from 'browser/components/atomic-elements/atoms/card/card'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { AnalyticsBlock } from 'browser/components/atomic-elements/organisms/analytics/analytics-block'
import {
  filterCarrier,
  filterCustomer,
  filterLane,
} from 'browser/components/atomic-elements/organisms/analytics/data-set'
import { IContainerProps } from 'browser/components/containers/container-props'

interface IAnalysisDetailViewProps extends IContainerProps {
  dataSets: any
  filters: any[]
  onClose: any
}

interface IAnalysisDetailViewState {
  datRates: any
  description: string
  entity: any
  filters: any[]
  subtitle: string
  tabs: any
}

export class AnalysisDetailView extends React.Component<IAnalysisDetailViewProps, IAnalysisDetailViewState> {

  constructor(props) {
    super(props)
    const viewState = props.view.view.state
    this.state = {
      datRates: null,
      description: '',
      entity: null,
      filters: [],
      subtitle: '',
      tabs: viewState.tabs,
    }
  }

  public componentDidMount() {
    this.updateStateFromProps(this.props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      this.updateStateFromProps(nextProps)
    }
  }

  public render() {
    const { dataSets, onClose } = this.props
    const { datRates, description, entity, filters, subtitle, tabs } = this.state
    return (
      <Card className='grid-block vertical c-analyticsDetailPanel'>
        <div className='grid-block vertical'>
          <CardHeader
            onClose={onClose}
          >
            <CardHeaderItem
              className='c-cardHeader-item--grow'
              description={description}
              title={subtitle}
            />
          </CardHeader>
          <div className='grid-block'>
            <AnalyticsBlock
              className='grid-content'
              benchmarkRates={datRates}
              dataSets={dataSets}
              filters={filters}
              tabs={tabs}
            />
          </div>
        </div>
      </Card>
    )
  }

  private updateStateFromProps(props) {
    const store = apis.getStore()
    const { dataSets, filters, location } = props
    const { carrierId, customerId, laneId } = queryString.parse(location.search)
    const additionalFilters = _.get(location, 'state.filters', [])
    let newFilters = []
    if (carrierId) {
      newFilters = [...filters, filterCarrier(carrierId)]
      store.findRecord(carrierId).then((entity) => {
        this.setState({
          description: 'Carrier',
          entity,
          filters: newFilters,
          subtitle: entity.displayName,
        })
      })
    } else if (customerId) {
      newFilters = [...filters, filterCustomer(customerId)]
      store.findRecord(customerId).then((entity) => {
        this.setState({
          description: 'Customer',
          entity,
          filters: newFilters,
          subtitle: entity.displayName,
        })
      })
    } else if (laneId) {
      newFilters = [...filters, filterLane(laneId)]
      // TODO should only ping if user has DAT Settings
      // apis.datRateViewHistoric(laneId)
      //    .then((datRates) => this.setState({ datRates }))
      apis.getLaneById(laneId).then((lane: any) => {
        this.setState({
          description: 'Lane',
          entity: lane,
          filters: newFilters,
          subtitle: lane.displayName,
        })
      })
    } else if (!_.isEmpty(additionalFilters)) {
      newFilters = [...filters, ...additionalFilters]
      const labels = _.map(additionalFilters, 'label')
      const values = _.map(additionalFilters, (filter: any) => {
        return _.isObject(filter.value) ? filter.value.displayName : filter.value
      })
      // labels.join('  >  ')
      const description = 'Filtered by ' + values.join(', ')
      this.setState({
        description,
        filters: newFilters,
        subtitle: _.last(values),
      })
    }
    _.forEach(dataSets, (dataSet: any) => {
      dataSet.handleFiltersChange(newFilters)
    })
  }

}
