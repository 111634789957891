import _ from 'lodash'

export function get(...params: any[]): any {
  return _.get.apply(null, params)
}

export function equal(...params: any[]): any {
  return _.isEqual.apply(null, params)
}

export function keys(...params: any[]): any {
  return _.keys.apply(null, params)
}

export function values(...params: any[]): any {
  return _.values.apply(null, params)
}

export function defaultTo(value: any, defaultValue: any): any {
  return _.defaultTo(value, defaultValue)
}

export function isNumber(value: any): boolean {
  return _.isNumber(value)
}

export function isString(value: any): boolean {
  return _.isString(value)
}

export function isNil(value: any): boolean {
  return _.isNil(value)
}

export function isNull(value: any): boolean {
  return _.isNull(value)
}

export function isUndefined(value: any): boolean {
  return _.isUndefined(value)
}

export function isEmpty(value: any): boolean {
  return _.isEmpty(value)
}

export function _if(cond: any, value_if_true: any, value_if_false: any): any {
  return cond ? value_if_true : value_if_false
}

export function stringify(...params: any[]): string {
  return JSON.stringify.apply(null, params)
}

export function matchesProperty(...params: any[]): any  {
  return _.matchesProperty.apply(null, params)
}
