import React, { Component } from 'react'

import { Input } from 'browser/components/atomic-elements/atoms/input/input'

interface IBulkAddTableCellProps {
  data: any
  error: string
  onChange: (arg0: any) => void
  onFocus: () => void
  onBlur: () => void
}

export class BulkAddTableCell extends Component<IBulkAddTableCellProps> {
  private input

  public focus() {
    if (this.input) {
      this.input.focus()
    }
  }

  public shouldComponentUpdate(nextProps) {
    return this.props.data !== nextProps.data || this.props.error !== nextProps.error
  }

  public render() {
    const { data, onChange, onBlur, onFocus, error } = this.props
    const assignRef = (ref) => {
      this.input = ref
    }
    return (
      <td className='c-table-cell c-table-cell--bulkAdd' style={{ padding: 0 }}>
        <Input
          value={data}
          onChange={onChange}
          errorText={error}
          extraRef={assignRef}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </td>
    )
  }
}
