import React, { PureComponent } from 'react'
import _ from 'lodash'

import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { default as SheetPortal } from 'browser/components/atomic-elements/atoms/sheet/sheet-portal'
import { LabelFormGroup } from 'browser/components/atomic-elements/molecules/label-form-group/label-form-group'

interface IRenameDocTypeSheetProps {
  isOpen: boolean
  onClose: (Entity?) => void
  nameBeingRenamed?: string
}
interface IRenameDocTypeSheetState {
  newName: string
  errorText: string
}
class RenameDocTypeSheet extends PureComponent<IRenameDocTypeSheetProps, IRenameDocTypeSheetState> {
  private newNameInput

  constructor(props) {
    super(props)
    this.state = {
      errorText: null,
      newName: '',
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.setState({
        newName: nextProps.nameBeingRenamed,
        errorText: null,
      })
      setTimeout(() => this.newNameInput.focus(), 0) // need to delay until after render
    }
  }

  public render() {
    return (
      <SheetPortal
        isOpen={this.props.isOpen}
        footer={this.renderAddCustomDocTypeFooter()}
        size='sm'
      >
        <div className='c-sheet-body--padded'>
          <FormTable>
            <LabelFormGroup
              label='Document Type Name'
              onChange={this.handleNewNameChange}
              ref={(input) => { this.newNameInput = input }}
              errorText={this.state.errorText}
              labelProps={{ className: 'c-label--isHorizontalMediumWide' }}
              value={this.state.newName}
            >
              <Input onKeyDown={this.handleNewNameKeyDown} />
            </LabelFormGroup>
          </FormTable>
        </div>
      </SheetPortal>
    )
  }

  private renderAddCustomDocTypeFooter() {
    const onCancelButtonClick = () => {
      this.props.onClose(null)
    }

    return (
      <Footer
        primaryButtonText='Rename'
        onPrimaryButtonClick={this.handleRenameDocType}
        onCancelButtonClick={onCancelButtonClick}
        errorText={this.state.errorText}
      />
    )
  }

  private handleRenameDocType = () => {
    const { newName } = this.state
    if (_.isEmpty(newName)) {
      this.setState({ errorText: 'Name is required' })
      this.newNameInput.focus()
      return
    }

    this.props.onClose(newName)
  }

  private handleNewNameKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleRenameDocType()
    }
  }

  private handleNewNameChange = (newName) => this.setState({ newName, errorText: null })
}

export { RenameDocTypeSheet }
