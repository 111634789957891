declare let window: any

import { Classes, Icon, Position, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Logger } from 'browser/apis/logging'
import { getMinZoomLevel } from 'browser/app/utils/image'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { RangeInput } from 'browser/components/atomic-elements/atoms/range-input/range-input'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import { grayscaleFile } from 'browser/mobile/util/page-utils'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { withContext } from 'shared-libs/components/context/with-context'
import { ComponentsContext, IComponentsContext } from 'browser/contexts/components/components-context'

interface IEditControlsProps extends IBaseProps {
  downloadButtonHref?: string
  image: any
  isEditable?: boolean
  isExportable?: boolean
  isInEditMode: boolean
  onChange: (value: any) => void
  onDelete?: (value: any) => void
  onEdit?: (value: any) => void
  onSave?: () => Promise<any>
  onZoom?: (value: any) => void
  padding: number
  useWebGLPreview?: boolean
  width: number
  height: number
  zoomLevel: number
}

interface ContextProps {
  componentsContext?: IComponentsContext
}

@withContext(ComponentsContext, 'componentsContext')
export class EditControls extends React.Component<IEditControlsProps & ContextProps, any> {

  constructor(props) {
    super(props)
    this.state = {
      isSaving: false,
      minZoomLevel: 0,
      isPreparingImage: true,
    }
    this.updateImagePreview().then(() => {
      this.setState({ isPreparingImage: false })
    })
  }

  public componentDidMount() {
    this.getNextStateForProps(this.props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props, nextProps)) {
      this.getNextStateForProps(nextProps)
    }
  }

  public render() {
    const { image, isInEditMode } = this.props
    if (!image || this.state.isPreparingImage) {
      return null
    }
    if (isInEditMode) {
      return this.renderEditModeControls()
    }
    return this.renderPreviewModeControls()
  }

  private renderPreviewModeControls() {
    const { children } = this.props
    return (
      <div className='flex u-bumperRight--xs'>
        {this.renderZoomSlider()}
        {children}
        {this.renderExportControls()}
        {this.renderDropdownTether()}
      </div>
    )
  }

  private renderExportControls() {
    const { isExportable } = this.props
    if (!isExportable) {
      return
    }
    return (
      <div className='flex mr2'>
        <div className='c-cardHeader-item c-cardHeader-item--smallMargin c-cardHeader-item--alignCenter'>
          {this.renderDownloadLink()}
        </div>
        <div className='c-cardHeader-item c-cardHeader-item--smallMargin c-cardHeader-item--alignCenter'>
          {this.renderPrintButton()}
        </div>
      </div>
    )
  }

  private renderEditModeControls() {
    const { image } = this.props
    const { isSaving } = this.state
    return (
      <div className='u-flex u-bumperRight--xs'>
        <div className='c-cardHeader-item c-cardHeader-item--smallMargin'>
          <div className={Classes.BUTTON_GROUP}>
            <Tooltip
              content='Rotate -90 deg'
              position={Position.BOTTOM}
            >
              <Button
                className={Classes.iconClass(IconNames.IMAGE_ROTATE_LEFT)}
                isDisabled={isSaving}
                onClick={this.handleRotateLeftImage}
              />
            </Tooltip>
            <Tooltip
              content='Rotate 90 deg'
              position={Position.BOTTOM}
            >
              <Button
                className={Classes.iconClass(IconNames.IMAGE_ROTATE_RIGHT)}
                isDisabled={isSaving}
                onClick={this.handleRotateRightImage}
              />
            </Tooltip>
          </div>
        </div>

        <div className='c-cardHeader-item c-cardHeader-item--smallMargin'>
          <Button
            className='u-bumperRight--xs'
            isDisabled={isSaving}
            onClick={this.handleEnhanceImage}
          >
            Grayscale:
            <span
              className={classNames('c-imageView-grayscaleLabel', {
                'c-imageView-grayscaleLabel--on': image.getThresholdTransform(),
              })}
            >
              {image.getThresholdTransform() ? 'On' : 'Off'}
            </span>
          </Button>
        </div>

        <div className='c-cardHeader-item c-cardHeader-item--smallMargin u-borderLeft'>
          <Button
            className='u-bumperLeft--xs'
            isDisabled={!image.isDirty || isSaving}
            onClick={this.handleRevertImage}
          >
            Undo Edits
          </Button>
        </div>

        <div className='c-cardHeader-item c-cardHeader-item--smallMargin'>
          <Button
            className={Classes.INTENT_PRIMARY}
            isLoading={isSaving}
            onClick={this.handleSaveImage}
          >
            Done
          </Button>
        </div>
      </div>
    )
  }

  private renderDropdownTether() {
    const { isEditable } = this.props
    if (!isEditable) {
      return
    }
    const dropdownMenu = (
      <Popover
        className='collapse'
        data-debug-id='document-options-dropdown'
      >
        <ul className='c-dropdownList'>
          <li
            className='c-dropdownList-item'
            onClick={this.handleEdit}
          >
            Edit image
          </li>
          <li className='c-list-divider' />
          <li
            className='c-dropdownList-item'
            onClick={this.handleDeleteImage}
          >
            Delete page
          </li>
        </ul>
      </Popover>
    )

    const tetherOptions = {
      attachment: 'top right',
      offset: { top: 0, left: 7 },
      targetAttachment: 'bottom right',
    }

    return (
      <TetherTarget
        tethered={dropdownMenu}
        tetherOptions={tetherOptions}
      >
        <div className='c-cardHeader-item c-cardHeader-item--smallMargin c-cardHeader-item--alignCenter'>
          <Button
            data-debug-id='document-card-more'
          >
            <Icon
              icon={IconNames.MORE}
            />
          </Button>
        </div>
      </TetherTarget>
    )
  }

  private renderDownloadLink() {
    const { downloadButtonHref } = this.props
    if (downloadButtonHref) {
      return (
        <Tooltip
          content='Download'
          position={Position.BOTTOM}
        >
          <a
            className={Classes.BUTTON}
            data-debug-id='image-viewer-download'
            href={downloadButtonHref}
            target='_blank'
            rel='noopener noreferrer'
            download={true}
          >
            <Icon
              icon={IconNames.DOWNLOAD}
            />
          </a>
        </Tooltip>
      )
    }
  }

  private renderPrintButton() {
    return (
      <Button
        onClick={this.handlePrintClick}
      >
        <Icon icon={IconNames.PRINT} />
      </Button>
    )
  }

  private renderZoomSlider() {
    if (this.props.componentsContext.platform === PlatformType.MOBILE_WEB) {
      return null
    }
    return (
      <div className='c-cardHeader-item c-cardHeader-item--alignCenter'>
        <div className='c-imageView-zoomControl'>
          <Icon
            className='c-imageView-zoomControl-smallIcon'
            icon={IconNames.MEDIA}
          />
          <RangeInput
            min={this.state.minZoomLevel}
            max={3}
            name='zoom-slider'
            onChange={this.handleOnZoomChange}
            onMouseUp={this.handleOnZoomChange}
            value={this.props.zoomLevel}
          />
          <Icon
            className='c-imageView-zoomControl-bigIcon'
            size={28}
            icon={IconNames.MEDIA}
          />
        </div>
      </div>
    )
  }

  private handleEdit = () => {
    Logger.logEvent('Document Editing')
    this.props.onEdit(true)
  }

  private handleOnZoomChange = (event) => {
    this.props.onZoom(parseFloat(event.target.value))
  }

  private handleSaveImage = () => {
    if (!this.props.image.isDirty) {
      Logger.logEvent('Edit Image - No Change')
      return this.props.onEdit(false)
    }
    this.setState({ isSaving: true })
    this.props.onSave().then(() => {
      Logger.logEvent('Edit Image - Changed')
      this.props.onEdit(false)
      this.setState({ isSaving: false })
    })
  }

  private handleRotateLeftImage = () => {
    this.props.image.rotateCounterClockwise90()
    this.props.onChange(this.props.image)
  }

  private handleRotateRightImage = () => {
    this.props.image.rotateClockwise90()
    this.props.onChange(this.props.image)
  }

  private handleEnhanceImage = async () => {
    const { image } = this.props
    image.toggleThresholdTransform()
    this.updateImagePreview().then(() => {
      this.props.onChange(image)
    })
  }

  private updateImagePreview = async () => {
    const { image, useWebGLPreview, componentsContext } = this.props
    if (useWebGLPreview) {
      const previewUri = image.jpgUri
      const thresholdTransform = image.getThresholdTransform()
      if (thresholdTransform) {
        this.setState({ isPreparingImage: true })
        const previewFile = await grayscaleFile(previewUri, image.name,
          thresholdTransform.offset, thresholdTransform.size,
          componentsContext.platform === PlatformType.MOBILE_WEB)
        image.previewUri = URL.createObjectURL(previewFile)
        this.setState({ isPreparingImage: false })
      } else {
        image.previewUri = previewUri
      }
    }
  }

  private handleRevertImage = () => {
    this.props.image.rollback()
    this.props.onChange(this.props.image)
  }

  private handleDeleteImage = () => {
    ConfirmationModal.open({
      confirmationText: 'Do you want to delete this image?',
      confirmationTitle: 'Review Changes',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: () => {
        this.props.onDelete(this.props.image)
      },
      primaryButtonText: 'Confirm',
    })
  }

  private handlePrintClick = () => {
    window.print()
  }

  private getNextStateForProps(props) {
    const { width, height, image, padding } = props
    // Image might not be available as we are closing modal after we deleted
    // the last image
    if (!image) { return }
    const imageSource = _.first(image.source)
    this.setState({
      minZoomLevel: getMinZoomLevel(imageSource, padding, width, height),
    })
  }

}
