import classNames from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/card/_card.scss'
import { SheetManager } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'

/**
 * @uiComponent
 */
interface ICardProps extends IBaseProps {
  cardClassName?: string
  fillVerticalSpace?: boolean
  maxHeight?: number
  paperLevel?: string
  passDimensionsToChildren?: boolean
  subtractHeight?: number
}

// TODO(Peter): Needs to be refactored
// Instead of passDimensionsToChildren, we should figure out if it is possible
// to use AutofillBlock and maxHeight on the children
// Should also look at https://material.google.com/components/cards.html for
// api design
export class Card extends React.Component<ICardProps, any> {

  public static defaultProps: Partial<ICardProps> = {
    fillVerticalSpace: false,
    paperLevel: '',
    passDimensionsToChildren: false,
    subtractHeight: 0,
  }

  private debouncedResize: any
  private cardContainer: SheetManager
  private card: HTMLDivElement

  constructor(props) {
    super(props)
    this.state = {
      height: 0,
      maxHeight: this.props.maxHeight ? this.props.maxHeight : 0,
      width: 0,
    }
    this.debouncedResize = _.debounce(this.handleResize.bind(this), 300)
  }

  public componentDidMount() {
    const $cardContainer = $(ReactDOM.findDOMNode(this.cardContainer))
    $cardContainer.resize(this.debouncedResize)

    this.setCardDimensions()
  }

  public componentWillUnmount() {
    this.debouncedResize?.cancel()
    const $cardContainer = $(ReactDOM.findDOMNode(this.cardContainer))
    $cardContainer.unbind('resize')
  }

  public handleResize() {
    this.setCardDimensions()
  }

  public render() {
    const {
      className,
      cardClassName,
      children,
      fillVerticalSpace,
      paperLevel,
      passDimensionsToChildren,
    } = this.props
    const {
      height,
      width,
      maxHeight,
    } = this.state
    const paperLevelClassName = _.isEmpty(paperLevel) ? '' : `paper--zDepth-${paperLevel}`

    if (passDimensionsToChildren) {
      const mappedChildren = React.Children.map(children, (child) => {
        const element = child as React.ReactElement<any>
        return React.cloneElement(element, {
          // TODO(louis): this breaks things...
          // height,
          maxHeight,
          width,
        })
      })
      return (
        <SheetManager
          className={classNames('c-card-container', className, {
            'c-card-container--fill': fillVerticalSpace,
          })}
          ref={(ref) => { this.cardContainer = ref }}
          data-debug-id={this.props['data-debug-id']}
        >
          <div
            className={classNames('c-card c-card--minimalHeight paper', paperLevelClassName, cardClassName)}
            ref={(ref) => { this.card = ref }}
          >
            {mappedChildren}
          </div>
        </SheetManager>
      )
    }

    return (
      <SheetManager
        className={classNames('c-card paper', paperLevelClassName, className)}
        data-debug-id={this.props['data-debug-id']}
      >
        {children}
      </SheetManager>
    )
  }

  private setCardDimensions() {
    const $cardContainer = $(ReactDOM.findDOMNode(this.cardContainer))
    const $card = $(ReactDOM.findDOMNode(this.card))
    this.setState({
      height: $card.outerHeight(),
      maxHeight: $cardContainer.innerHeight() - this.props.subtractHeight,
      width: $card.innerWidth(),
    })
  }
}
