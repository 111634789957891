import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { UserSelect } from 'browser/components/atomic-elements/atoms/select/user-select/user-select'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import 'browser/components/atomic-elements/organisms/entity/order-detail-card/_order-detail-card.scss'
// tslint:disable-next-line:max-line-length
import { getDispatchStatus, StatusSelect } from 'browser/components/atomic-elements/organisms/entity/order-detail-card/status-select'

const tetherOptions = {
  attachment: 'top left',
  targetAttachment: 'bottom left',
}

interface ICarrierOrderSecondaryCardHeader extends IBaseProps {
  entity: any
  isExpanded?: boolean
  onUpdateAndSaveModelStatus: (path, value) => void
  onDispatchStatusChange: (value) => void
  onTrackingStatusChange: (value) => void
}

export class CarrierOrderSecondaryCardHeader extends React.Component<ICarrierOrderSecondaryCardHeader, any> {

  public render() {
    const { children, entity } = this.props
    const dispatcherPath = 'carrierOrder.dispatcher'
    const driverPath = 'carrierOrder.driver'
    return (
      <div className='u-flex u-noPrint'>
        {this.renderTitle(entity)}
        {this.renderOrderStatus(entity)}
        {this.renderExpandedElements()}
        {this.renderTrackingStatus(entity)}
        {this.renderAssignee(entity, dispatcherPath, 'Dispatcher')}
        {this.renderAssignee(entity, driverPath, 'Driver')}
        <CardHeaderItem className='c-cardHeader-item--grow' />
        {children}
      </div>
    )
  }

  private renderTitle(entity) {
    const { isOnHold } = entity.dispatchOrder
    const onHoldFlag = !isOnHold ? null : (
      <span className='c-cardHeader-titleIcon c-cardHeader-titleIcon--hold'>
        <Icon icon={IconNames.FLAG} />
      </span>
    )
    return (
      <CardHeaderItem
        className='c-cardHeader-item--largeMargin'
        data-debug-id={`cardHeaderItemTitle:${entity.displayName}`}
        description='Order Number'
        title={(
          <span>
            {entity.displayName}
            {onHoldFlag}
          </span>
        )}
      />
    )
  }

  private renderExpandedElements() {
    const { entity, isExpanded } = this.props
    if (isExpanded) {
      return [
        this.renderRoute(entity),
        this.renderCustomer(entity),
      ]
    }
  }

  private renderRoute(entity) {
    const firstStop = _.first(entity.dispatchOrder.stops)
    const lastStop = _.last(entity.dispatchOrder.stops)
    const addressPath = ['location', 'denormalizedProperties', 'location.address']
    const firstAddress: any = _.get(firstStop, addressPath)
    const lastAddress: any = _.get(lastStop, addressPath)
    const primaryStatus = entity.dispatchOrder.status.primary
    let orderStops = '-'
    if (firstAddress && lastAddress) {
      orderStops = `${firstAddress.locality}, ${firstAddress.region} - ${lastAddress.locality}, ${lastAddress.region}`
    }

    return (
      <CardHeaderItem
        className='c-cardHeader-item--largeMargin'
        key='lane'
        label={orderStops}
        description='Lane'
      />
    )
  }

  private renderCustomer(entity) {
    const customer = entity.get('carrierOrder.customer')
    let customerName = '-'
    if (customer) {
      customerName = customer.displayName
    }
    return (
      <CardHeaderItem
        className='c-cardHeader-item--largeMargin'
        description='Customer'
        key='customer'
        label={customerName}
      />
    )
  }

  private renderAssignee(entity, path, title) {
    const assignee = entity.get(path)
    return (
      <CardHeaderItem
        className='c-cardHeader-item--largeMargin u-overflowVisible'
      >
        <UserSelect
          entityType='/1.0/entities/metadata/user.json'
          onChange={(user) => this.props.onUpdateAndSaveModelStatus(path, user)}
          value={assignee}
        />
        <div className='c-label c-label--secondary c-cardHeader-description u-ellipsis u-bumperLeft--lg u-innerBumperLeft--xs'>
          {title}
        </div>
      </CardHeaderItem>
    )
  }

  private renderOrderStatus(entity) {
    const { status } = entity.dispatchOrder
    const tagClassName = status.secondary ? `c-tag--${status.secondary.toLowerCase()}` : ''
    return (
      <CardHeaderItem
        className='c-cardHeader-item--largeMargin'
        description='Status'
      >
        <TetherTarget
          closeOnPortalClick={true}
          tetherOptions={tetherOptions}
          tethered={this.renderStatusDropdownMenu()}
        >
          <div
            className={classNames(
              'mb1 c-cardHeader-label c-cardHeaderStatus',
              Classes.TAG,
              tagClassName
            )}
          >
            {status.secondary} <Icon icon={IconNames.CHEVRON_DOWN} />
          </div>
        </TetherTarget>
      </CardHeaderItem>
    )
  }

  private renderTrackingStatus(entity) {
    const { status, trackingStatus } = entity.dispatchOrder
    if (status.primary !== 'Tracking') {
      return
    }
    return (
      <CardHeaderItem
        className='c-cardHeader-item--largeMargin'
        description='Tracking Status'
      >
        <TetherTarget
          closeOnPortalClick={true}
          tetherOptions={tetherOptions}
          tethered={this.renderTrackingStatusDropdown()}
        >
          <div className='c-cardHeader-label c-cardHeaderStatus'>
            {trackingStatus} <Icon icon={IconNames.CHEVRON_DOWN} />
          </div>
        </TetherTarget>
      </CardHeaderItem>
    )
  }

  private renderStatusDropdownMenu() {
    const { entity } = this.props
    const { status } = entity.dispatchOrder
    return (
      <Popover
        className='collapse'
        data-debug-id='document-options-dropdown'
      >
        <StatusSelect
          onChange={(value, option) => this.props.onDispatchStatusChange(option)}
          options={getDispatchStatus(entity)}
          optionGroupPath='primary'
          optionLabelPath='secondary'
          optionValuePath='secondary'
          value={status.secondary}
        />
      </Popover>
    )
  }

  private renderTrackingStatusDropdown() {
    const trackingStatusOptions = [
      { label: 'On Time', value: 'On Time' },
      { label: 'Late', value: 'Late' },
      // { label: 'Potentially delayed', value: 'Potentially delayed' },
      // { label: 'Delayed and can make it', value: 'Delayed and can make it' },
      // { label: 'Delayed and will miss', value: 'Delayed and will miss' },
    ]
    const { entity } = this.props
    const { trackingStatus } = entity.dispatchOrder
    return (
      <Popover
        className='collapse'
        data-debug-id='document-options-dropdown'
      >
        <StatusSelect
          onChange={this.props.onTrackingStatusChange}
          options={trackingStatusOptions}
          optionLabelPath='label'
          optionValuePath='value'
          value={trackingStatus}
        />
      </Popover>
    )
  }
}
