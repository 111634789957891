import React, { useEffect } from 'react'
import { useState } from 'react'
import _ from 'lodash'
import { LocalStorageContext } from './local-storage-context'
import { IStoredValues } from './interface'
import { getSubKey, getValueFromEvent, isVectorStorageKey, putLocalStorageItem, getLocalStorageItems } from './utils'

interface ILocalStorageProviderProps {
  children: React.ReactNode
}

export function LocalStorageProvider(props: ILocalStorageProviderProps) {
  const { children } = props

  const [storedValues, setStoredValues] = useState<IStoredValues>(() => getLocalStorageItems())

  const updateStoredValues = <T,>(key: string, value: T) => {
    setStoredValues({
      ...storedValues,
      [key]: value,
    })
  }

  const onChange = <T,>(key: string, value: T) => {
    updateStoredValues(key, value)
    putLocalStorageItem(key, value)
  }

  useEffect(() => {
    // stay in sync with other tabs
    const listener = (event: StorageEvent) => {
      if (!event || !isVectorStorageKey(event.key)) {
        return
      }
      const key = getSubKey(event.key)
      const val = getValueFromEvent(event)
      updateStoredValues(key, val)
    }
    window.addEventListener('storage', listener)
    return () => window.removeEventListener('storage', listener)
  })

  return (
    <LocalStorageContext.Provider
      value={{
        storedValues,
        onChange,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  )
}
