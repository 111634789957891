import { BlockTransitionContext, IBlockTransitionContext } from 'browser/components/atomic-elements/atoms/navigation/block-transition-context'
import PropTypes from 'prop-types'
import React from 'react'
import { withContext } from 'shared-libs/components/context/with-context'

interface IBlockTransitionProps {
  condition: boolean
  onBlockTransition: (callback: any) => void
  debug: string
}


interface ContextProps {
  blockTransitionContext?: IBlockTransitionContext
}

const LOCAL_DEBUG = false // leaving this in just in-case, it's a huge pain to debug this style of component without having contextual debug logs

/*
  We use BlockTransitionContext to ensure that only one callback is active at a time via BlockTransition
  When a BlockTransition is nested within a BlockTransition, the outer component will disable itself to allow the inner one to function without contention
  Behaviour is still undefined when there are two side-by-side BlockTransition's in the component tree, and there's no reasonable way to prioritize one over another
*/
@withContext(BlockTransitionContext, 'blockTransitionContext')
export class BlockTransition extends React.PureComponent<IBlockTransitionProps & ContextProps, any> {

  public static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        block: PropTypes.func,
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props)

    this.disableCount = 0
  }

  private unblock: any
  private disableCount: number

  public UNSAFE_componentWillMount() {
    this.props.blockTransitionContext?.disable?.()

    if (this.props.condition) {
      this.enable()
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    // we only want two possible values - true or false. null and undefined could cause further unwanted transitions.
    const nextCondition = Boolean(nextProps.condition)
    const curCondition = Boolean(this.props.condition)

    if (nextCondition != curCondition) {
      if (nextCondition) {
        this.enable()
      } else {
        this.disable()
      }
    }
  }

  public componentWillUnmount() {
    this.props.blockTransitionContext?.enable?.()
    this.disable()
  }

  public render() {
    if (!this.props.children) {
      return null
    }

    const contextProps = {
      enable: this.enable,
      disable: this.disable,
    }

    return <BlockTransitionContext.Provider value={contextProps}>
      {this.props.children}
    </BlockTransitionContext.Provider>
  }

  private enable = () => {
    if (this.disableCount > 0) {
      this.disableCount -= 1
    }

    if (this.disableCount > 0 || !this.props.condition) {
      return
    }

    LOCAL_DEBUG && console.log(`BT - enable: ${this.props.debug}`)

    this.unblock?.()
    this.unblock = this.context.router.history.block((location, action) => {
      if (action === 'PUSH') {
        this.props.onBlockTransition(() => {
          this.disable()
          this.context.router.history.push(location)
        })
      }
      return false
    })
  }

  private disable = () => {
    LOCAL_DEBUG && console.log(`BT - disable: ${this.props.debug}`)

    this.disableCount += 1

    this.unblock?.()
    this.unblock = null
  }
}
