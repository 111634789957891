import React, { ReactElement } from 'react'
import qs from 'query-string'
import _ from 'lodash'

export function getTabIdFromTitle(title: string, children: ReactElement[]) {
  return React.Children.toArray(children).findIndex((ele) => {
    const label = ele.props.tabLabel
    return label && label.toLowerCase() === title
  })
}

export function getDefaultTab(defaultTab: number | string, children: ReactElement[], location: Location) {
  const tabNameFromLocation = qs.parse(location.search).tab
  if (_.isString(defaultTab) || tabNameFromLocation) {
    // URL default takes precedence over schema default
    const title = tabNameFromLocation || defaultTab
    const tabId = getTabIdFromTitle(title, children)
    if (tabId >= 0) {
      return tabId
    }
  } else if (_.isNumber(defaultTab)) {
    return defaultTab
  }
  return 0
}
