import moment from 'moment'

const isSameDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) { return false }
  return a.isSame(b, 'day')
}

const isNextDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) { return false }
  const nextDay = moment(a).add(1, 'day')
  return isSameDay(nextDay, b)
}

const isInclusivelyAfterDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) { return false }
  return a.isAfter(b) || isSameDay(a, b)
}

const isInclusivelyBeforeDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) { return false }
  return a.isBefore(b) || isSameDay(a, b)
}

export {
  isSameDay,
  isNextDay,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
}
