import LabelFieldFactory from 'browser/components/atomic-elements/higher-order-components/label-field-factory'
import 'browser/components/atomic-elements/molecules/fields/embedded-location-field/_embedded-location-field.scss'
import {
  EmbeddedLocationFieldSheet,
} from 'browser/components/atomic-elements/molecules/fields/embedded-location-field/embedded-location-field-sheet'
import {
  EmbeddedLocationInlineInput,
} from 'browser/components/atomic-elements/molecules/fields/embedded-location-field/embedded-location-inline-input'

const defaultLabelFormGroupProps = {
  className: 'c-embeddedLocationField overflow-hidden',
}
const EmbeddedLocationField = LabelFieldFactory({
  InputComponent: EmbeddedLocationInlineInput,
  InputSheetComponent: EmbeddedLocationFieldSheet,
  defaultLabelFormGroupProps,
})
export { EmbeddedLocationField }
