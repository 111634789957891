
import { AccessRequest } from './access-request'
import { DispatchOrder } from './dispatch-order'
import { DocumentImagingSettings } from './document-imaging-settings'
import { Invite } from './invite'
import { OrbCommDvir } from './orbCommDvir'
import { Person } from './person'
import { SalesOrder } from './sales-order'
import { User } from './user'
import { SEFLePUP } from './sefl-epup'
import { StoryboardPlanEntity } from './storyboard/storyboard-plan'
import { StoryboardExecutionEntity } from './storyboard/storyboard-execution'

const TypesById = {}

export const getTypeById = (uuid) => {
  return TypesById[uuid]
}

const registerType = (uuid, entityClass) => {
  TypesById[uuid] = entityClass
}

registerType('11111111-0000-0000-0000-000000000107', DispatchOrder)
registerType('11111111-0000-0000-0000-000000000016', Invite)
registerType('342c9b52-2356-448c-b21d-56dc8133b188', AccessRequest)
registerType('11111111-0000-0000-0000-000000000020', Person)
registerType('11111111-0000-0000-0000-000000000023', User)
registerType('181e8f53-06de-4bb9-88aa-d6818ab3d329', DocumentImagingSettings)
registerType('ef8c239e-4514-4285-a794-db376ec1b6a2', SEFLePUP)
registerType('edc3908c-3f25-4a97-9c75-779cdcf5614a', SalesOrder)
registerType('edce7a80-0c8d-44e9-9237-9e8139c5f76b', OrbCommDvir)
registerType('0b770bb1-501c-4408-a477-fe409e9f5f6b', StoryboardPlanEntity)
registerType('54809ba0-e1c6-46ec-af52-a392803a36b0', StoryboardExecutionEntity)
