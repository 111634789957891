import classNames from 'classnames'
import { Cell } from 'fixed-data-table-2'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Checkbox } from 'browser/components/atomic-elements/atoms/checkbox/checkbox'
import 'browser/components/atomic-elements/atoms/table/cells/internal/_table-checkbox-cell.scss'

interface ITableCheckboxCellProps extends IBaseProps {
  isSelected: boolean
  onChange: (event: any, rowIndex: number) => void
  rowIndex: number
}

export const TableCheckboxCell: React.FC<ITableCheckboxCellProps> = (props) => {
  const { isSelected, onChange, rowIndex } = props
  const handleChange = (event) => {
    onChange(event, rowIndex)
  }

  return (
    <Cell className='mr3'>
      <div className='c-tableCheckboxCell'>
        <Checkbox
          className=''
          label=''
          onClick={handleChange}
          value={isSelected}
        />
      </div>
    </Cell>
  )
}
