import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import { Settings } from 'browser/app/models/settings'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import 'browser/components/atomic-elements/organisms/inbox/_inbox.scss'
import { InboxCardItem } from 'browser/components/atomic-elements/organisms/inbox/inbox-card-item'

const NOTIFICATION_SCHEMA_URI = '/1.0/entities/metadata/notification.json'

interface IInboxTabProps extends IBaseProps {
  isArchived: boolean
  match: any
  settings: Settings
}

interface IInboxTabState {
  isLoading: boolean
}

export class InboxTab extends React.Component<IInboxTabProps, IInboxTabState> {

  private dataSet: EntityDataSource

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  public componentDidMount() {
    this.createEntityDataSource()
    this.dataSet.find()
  }

  public componentWillUnmount() {
    this.dataSet.dispose()
  }

  public render() {
    const { isLoading } = this.state
    if (isLoading) {
      return (
        <div className='grid-block'>
          <LoadingSpinner/>
        </div>
      )
    }
    return (
      <div className='grid-block vertical'>
        <div className='grid-block'>
          {this.renderCards()}
        </div>
        <div className='grid-block shrink u-borderTop'>
          {this.renderFooter()}
        </div>
      </div>
    )
  }

  private createEntityDataSource() {
    const { isArchived, settings } = this.props

    const user = settings.getUser()
    const filters = [
      {
        path: 'owner.user',
        type: 'matchEdge',
        value: { entityId: user.uniqueId },
      },
      {
        path: 'notification.isArchived',
        type: 'notMatch',
        value: !isArchived,
      },
    ]
    this.dataSet = new EntityDataSource({
      entityType: NOTIFICATION_SCHEMA_URI,
      filters,
    }).setOnChange(() => this.setState({ isLoading: false }))
  }

  private renderCards() {
    const { match } = this.props
    const notifications = this.dataSet.entities || []
    const notificationCards = _.map(notifications, (notification) => (
      <InboxCardItem
        match={match}
        notification={notification}
        key={notification.get('uniqueId')}
      />
    ))
    return (
      <div className='grid-content collapse'>
        {notificationCards}
      </div>
    )
  }

  private renderFooter() {
    const { isArchived } = this.props
    if (isArchived) { return }
    return (
      <div className='grid-content collapse'>
        <div className='u-borderBottom u-innerBumperLeft--lg u-innerBumperRight--lg u-flex u-borderRight'>
          <div className='u-flexGrow'>
            <Button
              className={Classes.MINIMAL}
              size='sm'
            >
              Archive All
            </Button>
          </div>
          <div>
            <Button
              className={Classes.MINIMAL}
              size='sm'
            >
              Email Notifications: On
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
