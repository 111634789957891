import _ from 'lodash'
import React from 'react'

import { IEntityRendererProps, EntityRenderer } from './renderer'

interface IMultiMixinRendererProps extends IEntityRendererProps {
  shouldOverrideUiSchema?: boolean
  uiSchemaPath: string
  useUiSchema?: boolean
}

export class MultiMixinRenderer extends React.Component<IMultiMixinRendererProps, any> {

  public render() {
    const { shouldOverrideUiSchema, uiSchemaPath, value, uiSchema, useUiSchema } = this.props
    // if uiSchema is provided,  render it right away
    if (useUiSchema && !_.isEmpty(uiSchema)) {
      return (
        <EntityRenderer
          {...this.props}
          key={value?.uniqueId}
          uiSchemaPath={uiSchemaPath}
        />
      )
    }
    const mixins = value.activeMixins
    let schemasToRender = []
    _.forEach(mixins, (edge: any) => {
      const schemaId = edge.entityId
      const schema = _.find(value.schemas, { uniqueId: schemaId })
      if (_.get(schema, uiSchemaPath)) {
        schemasToRender.push(schema)
      }
    })
    if (shouldOverrideUiSchema && !_.isEmpty(schemasToRender)) {
      schemasToRender = [_.last(schemasToRender)]
    }
    return _.map(schemasToRender, (schema) => {
      return (
        <EntityRenderer
          {...this.props}
          key={schema.uniqueId}
          uiContext={schema}
          uiSchemaPath={uiSchemaPath}
        />
      )
    })
  }
}
