import './_mobile-switch.scss'

import _ from 'lodash'
import React from 'react'
import LabelFieldFactory from 'browser/components/atomic-elements/higher-order-components/label-field-factory'
import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { getDebugId } from 'browser/app/utils/utils'

/**
 * @uiComponent
 */
interface IMobileSwitchProps {
  frames?: FramesManager
  onChange?: (value: boolean) => void
  value?: boolean
}

class MobileSwitchImpl extends React.PureComponent<IMobileSwitchProps> {
  render() {
    const { value, frames } = this.props
    return (
      <div className="mobile-switch mt1" onClick={this.handleChange}>
        <input
          type="checkbox"
          checked={value}
          onChange={_.noop}
          data-debug-id={getDebugId(frames)}
        />
        <span className="mobile-switch-indicator" />
      </div>
    )
  }

  private handleChange = () => {
    const { onChange, value } = this.props
    onChange?.(!value)
  }
}

export const MobileSwitch = LabelFieldFactory({ InputComponent: MobileSwitchImpl })
