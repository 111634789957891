import * as gm from 'gammacv';
import _ from 'lodash'

const COALESCE_RHO_TOLERANCE = 10
const COALESCE_THETA_TOLERANCE = 10 // degrees
const RIGHT_ANGLE_THRESHOLD = 12
const HOUGH_LINE_ANGLE_TOLERANCE = 15
const OVERLAP_DISTANCE_TOLERANCE = 5
const CONTOUR_SCORE_PENALTY = 1.2
const EPSILON = 1e-8
export const ADAPTIVE_THRESHOLD_BOX_SIZE = 5
export const ADAPTIVE_THRESHOLD_VALUE = 15
const DEFAULT_THRESHOLD_VALUE = 0.55
/// Maximum number of Hough Lines (sorted by quality) to evaluate for contours
const MAX_LINES_TO_EVALUATE = 12
const MIN_AREA = 1000

export interface Point {
  x: number
  y: number
}

interface Rect {
  area: number
}
interface Intersection {
  coord: Point
  lines: Set<gm.Line>
}

function scalePoints(points: Point[], scale: number): Point[] {
  const result = []
  // the points can be in either cw or ccw order, and the top left corner can be any of them
  // This slightly wonky logic adjusts so we're always clockwise, and
  // result[0] is the top left corner.
  const xd1 = points[0].x - points[1].x
  const xd2 = points[0].x - points[3].x
  const yd1 = points[0].y - points[1].y
  const yd2 = points[0].y - points[3].y
  const xd = Math.abs(xd1) > Math.abs(xd2) ? xd1: xd2
  // while 3 * false == 0 and 3 * true == 3, we get a warning.
  const isRight = xd > 0 ? 1 : 0
  const yd = Math.abs(yd1) > Math.abs(yd2) ? yd1 : yd2
  const isTop = yd < 0 ? 1 : 0
  const isClockwise = isRight === 1
    ? (isTop === 1 ? yd === yd1 : yd === yd2)
    : (isTop === 1 ? yd === yd2 : yd === yd1)
  const tlIndexBase = isRight + (1 - isTop) + (isClockwise ? (isRight && isTop ? 2 : 0) : (!isRight && !isTop ? 2 : 0))
  const span = isClockwise ? 1 : 3

  for (let idx = 0; idx < 4; idx++) {
    const point = points[((tlIndexBase + idx) * span) & 3]
    result.push({
      x: point.x * scale, y: point.y * scale
    })
  }

  // console.log(`points ${JSON.stringify(points)}`)
  // console.log(`xd1 ${xd1} xd2 ${xd2} yd1 ${yd1} yd2 ${yd2} isRight ${isRight} isTop ${isTop} iscw ${isClockwise} tlIndex ${tlIndexBase} span ${span}`)
  // console.log(`result ${JSON.stringify(result)}`)

  return result;
}

const imageToBlob = (output: gm.Tensor): Promise<Blob> => {
  const canvas = gm.canvasCreate(output.shape[1], output.shape[0])
  gm.canvasFromTensor(canvas, output)
  return new Promise<Blob>((resolve, reject) => {
    try {
      canvas.toBlob((blob) => {
        resolve(blob)
      })
    } catch(err) {
      reject(err)
    }
  })
}

export const grayscaleFile = async (image:string, name = 'file.png',
    thresholdValue = ADAPTIVE_THRESHOLD_VALUE, thresholdBoxSize = ADAPTIVE_THRESHOLD_BOX_SIZE, requiresMobileWebWorkaround = true): Promise<File> => {
  const im_mat = await gm.imageTensorFromURL(image, 'uint8', null, true)
  let pipeline = gm.grayscale(im_mat);
  if (requiresMobileWebWorkaround) {
    // NB The native and backend pipelines use OpenCV's adaptive threshold, which
    // gets better results. GammaCV gets similar results with AT on desktop web,
    // but looks awful on Android - assuming a bug?
    pipeline = gm.threshold(
      pipeline,
      DEFAULT_THRESHOLD_VALUE,
      0 // grayscale channel only
    )
  } else {
    pipeline = gm.adaptiveThreshold(
      pipeline,
      thresholdBoxSize,
      thresholdValue,
      0 // grayscale channel only
    )
  }
  const grayScale = gm.tensorFrom(pipeline)
  const session = new gm.Session()
  session.init(pipeline)
  session.runOp(pipeline, 0, grayScale)
  session.destroy()
  pipeline.destroy()

  const blob = await imageToBlob(grayScale)
  return new File([blob], name, { type: 'image/png' })
}

/**
 * Scales an image down so the smallest dimension is minDimension.
 *
 * @param height
 * @param width
 * @param minDimension
 * @returns [ scaledHeight, scaledWidth ]
 */
export const scaleImageCalculation = (
  height: number, width: number, minDimension = 1700
): number[] => {
const ratio = height / width
const newDimensions =  height > width
  ? [ Math.floor(minDimension * ratio), Math.floor(minDimension) ]
  : [ Math.floor(minDimension), Math.floor(minDimension / ratio) ]

// Don't scale up
return (newDimensions[0] > height || newDimensions[1] > width)
  ? [ height, width ]
  : newDimensions
}

// Adapted from https://github.com/calebbergman/gammacv-perspective-transform-example/blob/master/src/components/HelloWorld.vue
export const applyPerspective = async (image:string, bounds?: any, name='file.png'): Promise<File> => {
  const im_mat = await gm.imageTensorFromURL(image, 'uint8', null, true)
  let output
  let session
  if (!_.isEmpty(bounds)) {
    const rect = new gm.Rect(
      bounds.topLeft.x, bounds.topLeft.y, bounds.topRight.x, bounds.topRight.y,
      bounds.bottomRight.x, bounds.bottomRight.y, bounds.bottomLeft.x, bounds.bottomLeft.y)
    //console.log("rect", rect);
    const tTransform = new gm.Tensor("float32", [3, 1, 4]);

    const heightRight = Math.hypot(bounds.topRight.x - bounds.bottomRight.x, bounds.topRight.y - bounds.bottomRight.y);
    const heightLeft = Math.hypot(bounds.topLeft.x - bounds.bottomLeft.x, bounds.topLeft.y - bounds.bottomLeft.y);
    const maxHeight = Math.floor(Math.max(heightRight, heightLeft));
    const widthTop = Math.hypot(bounds.topRight.x - bounds.topLeft.x, bounds.topRight.y - bounds.topLeft.y);
    const widthBottom = Math.hypot(bounds.bottomRight.x - bounds.bottomLeft.x, bounds.bottomRight.y - bounds.bottomLeft.y);
    const maxWidth = Math.floor(Math.max(widthTop, widthBottom));

    //  console.log("maxHeight", maxHeight);
    //  console.log("maxWidth", maxWidth);
    const outputRatio = maxHeight / maxWidth
    const [ transformedWidth, transformedHeight ] = outputRatio > 1
      ? [ Math.floor(im_mat.shape[0] / outputRatio), im_mat.shape[0] ]
      : [ im_mat.shape[1], Math.floor(im_mat.shape[0] * outputRatio) ]

    const [ finalHeight, finalWidth ] = scaleImageCalculation(transformedHeight, transformedWidth)
    gm.generateTransformMatrix(rect, [finalHeight, finalWidth], tTransform);

    const operation = gm.perspectiveProjection(im_mat, tTransform, [
      finalHeight,
      finalWidth,
      4
    ]);

    session = new gm.Session();
    output = gm.tensorFrom(operation);

    session.init(operation);
    session.runOp(operation, 0, output);
    operation.destroy()
  } else {
    output = im_mat
  }

  const blob = await imageToBlob(output)
  session.destroy()
  return new File([blob], name, { type: 'image/png' })
}

export const applyRotation = async(imageUri: string, degrees: number, name = 'file.png') => {
  const im_mat = await gm.imageTensorFromURL(imageUri, 'uint8', null, true)
  const sin = Math.sin(degrees * Math.PI / 180)
  const cos = Math.cos(degrees * Math.PI / 180)
  const transform = new gm.Tensor('float32', [3,3],
  [
    cos, -sin, 0,
    sin, cos, 0,
    0, 0, 1
  ])
  const rect = new gm.Rect([
    0, 0,
    im_mat.shape[1], 0,
    im_mat.shape[1], im_mat.shape[0],
    0, im_mat.shape[0]
  ]).perspective(transform)
  const tTransform = new gm.Tensor("float32", [3, 1, 4]);
  const finalHeight = Math.abs(rect.cy)
  const finalWidth = Math.abs(rect.cx)
  gm.generateTransformMatrix(rect, [finalHeight, finalWidth], tTransform)
  const operation = gm.perspectiveProjection(im_mat, tTransform, [
    finalHeight,
    finalWidth,
    4
  ]);
  const session = new gm.Session();
  const output = gm.tensorFrom(operation);

  session.init(operation);
  session.runOp(operation, 0, output);

  const blob = await imageToBlob(output)
  session.destroy()
  operation.destroy()
  return new File([blob], name, { type: 'image/png' })
}

export const scaleImage = async (imageUri: string, name = 'file.png'): Promise<File> => {
  const im_mat = await gm.imageTensorFromURL(imageUri, 'uint8', null, true)
  const size = im_mat.shape
  const [ newHeight, newWidth ] = scaleImageCalculation(size[0], size[1])
  //console.log(`input shape ${size} new width ${newWidth} new height ${newHeight}`)
  const operation = gm.resize(im_mat, newWidth, newHeight)
  const resized = gm.tensorFrom(operation)
  const session = new gm.Session()
  session.init(operation)
  session.runOp(operation, 0, resized)
  const blob = await imageToBlob(resized)
  session.destroy()
  operation.destroy()
  return new File([blob], name, { type: 'image/png' })
}

export const imageSourceFromCanvas = (canvas: HTMLCanvasElement): gm.InputType => {
  return new gm.MediaInput(canvas, [canvas.height, canvas.width, 4])
}

export const imageSourceFromVideo = (video: HTMLVideoElement): gm.InputType /* gm.InputType */ => {
  return new gm.MediaInput(video, [video.videoHeight, video.videoWidth, 4])
}

export const imageSourceFromURL = async (url: string): Promise<gm.InputType> => {
  return gm.imageTensorFromURL(url, 'uint8', null, true)
}

export const captureImage = async (im_mat: gm.InputType, name = 'file.png', videoToPause?: HTMLVideoElement): Promise<File> => {
  const operation = gm.upsample(im_mat, 1)
  const tensor = gm.tensorFrom(im_mat)
  const session = new gm.Session()
  session.init(operation)
  session.runOp(operation, 0, tensor)
  session.destroy()
  operation.destroy()
  // Since imageToBlob transfers a fairly large block from GPU to CPU memory,
  // pause the video now to avoid letting it play after the capture and
  // potentially confusing the user.
  if (videoToPause) {
    videoToPause.pause()
  }
  const blob = await imageToBlob(tensor)
  return new File([blob], name, { type: 'image/png' })
}

// @param image Image data
// @return array of points (cv::Point) of the best rectangle found in the image, or undefined
// Algorithm source: https://github.com/alessandrozamberletti/docdetect/blob/master/docdetect/
// from https://dropbox.tech/machine-learning/fast-and-accurate-document-detection-for-scanning
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const detectPage = (im_mat: gm.InputType, delayedErrorCallback?: (err: Error) => void): any => {
  //const startTime = Date.now()
  const size = im_mat.shape
  //const imageReadTime = Date.now()
  const width = 512.0
  const ratio = width / size[1]
  let pipeline = gm.resize(im_mat, width, Math.ceil(size[0] * ratio))
  pipeline = gm.grayscale(pipeline);
  pipeline = gm.gaussianBlur(pipeline, 3, 3);
  //pipeline = gm.downsample(pipeline, 2);
  //pipeline = gm.upsample(pipeline, 2);
  pipeline = gm.sobelOperator(pipeline);
  pipeline = gm.cannyEdges(pipeline, 0.35, 0.75)
  const canny = gm.tensorFrom(pipeline)
  const session = new gm.Session()
  session.init(pipeline)
  session['gl']?.flush()
  let err
  const onLoseWebGL = (ev) => {
    console.warn('lost webgl context')
  }
  session['canvas']?.addEventListener('webglcontextlost', onLoseWebGL)
  try {
    session.runOp(pipeline, 0, canny)
  } catch(e) {
    err = e
  } finally {
    pipeline.destroy()
    session.destroy()
  }
  if (err) {
    return { bounds:[], lines: [], intersections: [], ratio, error: err }
  }
  //const pipelineTime = Date.now()

  const { contours, lines, intersections } = houghFeatures(canny);
  //const houghTime = Date.now()

  // try to match canny with contours found from hough
  let bestCandidate;
  let bestContourArea = 0;
  for (let i = 0; i < contours.length; ++i) {
    const curveArea = calculateContourScore(canny, contours[i], bestContourArea);
    if (curveArea > bestContourArea) {
      bestCandidate = contours[i];
      bestContourArea = curveArea;
    }
  }
  //const contourTime = Date.now()

  if (bestCandidate?.length == 4) {
    //console.log(`image read ${imageReadTime - startTime} pipeline ${pipelineTime-startTime} hough Features ${houghTime-startTime} contours ${contourTime-startTime}`)
    const tempBounds = scalePoints(bestCandidate, 1.0 / ratio)
    const bounds = []
    for (const point of tempBounds) {
      if (point.x >= im_mat.shape[1]) {
        point.x = im_mat.shape[1] - 1
      }
      if (point.y >= im_mat.shape[0]) {
        point.y = im_mat.shape[0] - 1
      }
      bounds.push(point)
    }
    return { bounds, lines, intersections, ratio }
  } else {
    return { bounds:[], lines, intersections, ratio }
  }
}

function doesPointOverlapHorizontal(canny, x, y): boolean {
  const ytest = Math.floor(y)
  const xstart = Math.floor(Math.max(x - OVERLAP_DISTANCE_TOLERANCE, 0));
  const xend = Math.min(x + OVERLAP_DISTANCE_TOLERANCE, canny.shape[1] - 1);
  for (let xtest = xstart; xtest <= xend; ++xtest) {
    const value = canny.get(ytest, xtest, 0);
    if (value) { return true; }
  }
  return false;
}

function doesPointOverlapVertical(canny, x, y): boolean {
  const xtest = Math.floor(x)
  const ystart = Math.floor(Math.max(y - OVERLAP_DISTANCE_TOLERANCE, 0));
  const yend = Math.min(y + OVERLAP_DISTANCE_TOLERANCE, canny.shape[0] - 1);
  for (let ytest = ystart; ytest <= yend; ++ytest) {
    const value = canny.get(ytest, xtest, 0);
    if (value) { return true; }
  }
  return false;
}

// Adapted from opencv source
export function contourArea(contour: Point[]): number {
  const size = contour.length
  if (size === 0) {
    return 0
  }
  let prev = contour[size - 1]
  let area = 0.
  for (let i=0; i < size; i++) {
    const pt = contour[i]
    area += prev.x * pt.y - prev.y * pt.x
    prev = pt
  }
  return area/2
}

function calculateContourScore(canny, contour, minScore): any {
  const size = { height: canny.shape[0], width: canny.shape[1] };
  const approxCurveMat = contour;
  const curveArea = Math.abs(contourArea(approxCurveMat));
  // ignore contours that are too small
  if (curveArea < minScore) {
    return 0;
  }

  // console.log("=================================================================\n");
  let minX = size.width;
  let maxX = 0;
  let minY = size.height;
  let maxY = 0;
  for (let i = 0; i < contour.length; ++i) {
    const p1 = contour[i];
    // console.log("POINTS (${p1.x}, ${p1.y})");
    minX = Math.min(minX, p1.x);
    maxX = Math.max(maxX, p1.x);
    minY = Math.min(minY, p1.y);
    maxY = Math.max(maxY, p1.y);
  }

  const approxHeight = maxY - minY;
  const approxWidth = maxX - minX;
  const missingHeight: number[] = []
  const missingWidth: number[] = []
  missingHeight.fill(0, 0, maxY - minY + 1)
  missingWidth.fill(0, 0, maxX - minX + 1)
  for (let i = 0; i < 4; ++i) {
    const p1 = contour[i];
    const p2 = contour[(i + 1) % 4];
    const isHorizontal = Math.abs(p1.x - p2.x) > Math.abs(p1.y - p2.y);
    // console.log("LINE (${p1.x}, ${p1.y}) => (${p2.x}, ${p2.y})");

    if (isHorizontal) {
      const xstart = Math.round(Math.min(p1.x, p2.x));
      const xend = Math.round(Math.max(p1.x, p2.x));
      let m = 0;
      if (p2.x != p1.x) {
        const diffX = (p2.x - p1.x);
        const diffY = (p2.y - p1.y);
        m = diffY / diffX;
      }
      // b = y - mx
      const b = p1.y - m * p1.x;
      //console.log(`HORIZONTAL m=${m} b=${b}`);

      for (let x = minX; x <= maxX; ++x) {
        const index = Math.round(x - minX)
        if (x >= xstart && x <= xend) {
          const y = m * x + b;
          const hasOverlap = doesPointOverlapVertical(canny, x, y);
          //console.log(`(${x}, ${y}) index=${index} hasOverlap=${hasOverlap}`);
          if (hasOverlap) {
            missingWidth[index] += 1;
          }
        } else {
          missingWidth[index] += 1;
        }
      }
    } else {
      const ystart = Math.round(Math.min(p1.y, p2.y));
      const yend = Math.round(Math.max(p1.y, p2.y));
      let m = 0;
      if (p2.x != p1.x) {
        const diffX = (p2.x - p1.x);
        const diffY = (p2.y - p1.y);
        m = diffY / diffX;
      }
      // b = y - mx
      const b = p1.y - m * p1.x;
      //console.log(`VERTICAL m=${m} b=${b}`);

      for (let y = minY; y <= maxY; ++y) {
        const index = Math.round(y-minY)
        if (y >= ystart && y <= yend) {
          // y = mx + b  ===>  x = (y - b) / m
          const x = (m == 0) ? p1.x : (y - b) / m;
          const hasOverlap = doesPointOverlapHorizontal(canny, x, y);
          //console.log(`(${x}, ${y}) index=${index} hasOverlap=${hasOverlap}`);
          if (hasOverlap) {
            missingHeight[index] += 1;
          }
        } else {
          missingHeight[index] += 1;
        }
      }
    }
  }
  let totalMissingHeight = 0;
  let totalMissingWidth = 0;
  // penalize missing value from beg and end more
  const heightMidIndex = missingHeight.length / 2;
  const widthMidIndex = missingWidth.length / 2;
  const missingHeightPenaltyDenom = Math.pow(heightMidIndex, 2);
  const missingWidthPenaltyDenom = Math.pow(widthMidIndex, 2);
  for (let i = 0; i < missingHeight.length; i++) {
    if (isNaN(missingHeight[i])) continue
    const multiplier = Math.pow(i - heightMidIndex, 2) / missingHeightPenaltyDenom * 2 + 1;
    totalMissingHeight += (2 - missingHeight[i]) * multiplier;
  }
  for (let i = 0; i < missingWidth.length; i++) {
    if (isNaN(missingWidth[i])) continue
    const multiplier = Math.pow(i - widthMidIndex, 2) / missingWidthPenaltyDenom * 2 + 1;
    totalMissingWidth += (2 - missingWidth[i]) * multiplier;
  }
  totalMissingHeight /= 2;
  totalMissingWidth /= 2;
  const missingHeightArea = totalMissingHeight * approxWidth;
  const missingWidthArea = totalMissingWidth * approxHeight;
  // console.log(`area=${curveArea} height=${approxHeight} width=${approxWidth}`);
  // console.log(`totalMissingHeight=${totalMissingHeight} missingHeightArea=${missingHeightArea}`);
  // console.log(`totalMissingHeight=${totalMissingWidth} missingHeightArea=${missingWidthArea}`);
  return curveArea - missingHeightArea * CONTOUR_SCORE_PENALTY -
      missingWidthArea * CONTOUR_SCORE_PENALTY;
  //console.log(`result=${result}`);
  //return result
}

function findIntersections(canny, foundLines): Intersection[] {
  const startTime = Date.now()

  // const linesTime = Date.now()
  // console.log(`created ${foundLines.length} lines in ${linesTime - startTime}ms`)
  const intersections: Intersection[] = []
  for (let i2=0; i2 < foundLines.length - 1; i2++) {
    for (let j=i2 + 1; j < foundLines.length; j++) {
      const angle = Math.abs(foundLines[i2].angle - foundLines[j].angle) % 180
      if (angle >= 90 - RIGHT_ANGLE_THRESHOLD && angle <= 90 + RIGHT_ANGLE_THRESHOLD) {
        const intersection = gm.Line.Intersection(foundLines[i2], foundLines[j])
        if (intersection &&
          intersection[0] >= 0 && intersection[0] < canny.shape[1] &&
          intersection[1] >= 0 && intersection[1] < canny.shape[0]) {
          intersections.push({
            coord: { x: intersection[0], y: intersection[1] },
            lines: new Set([foundLines[i2], foundLines[j]])
          })
        }
      }
    }
  }

  return intersections
}

function addIfQuadrilateral(current: string, neighbors: any, seen: string[], cycles: string[][]) {
  if (neighbors[current].includes(seen[0])) {
    cycles.push([...seen])
  }
}

function boundedDfs(neighbors: any, current: string, loops: string[][], seen: string[] = []) {
  if (seen.includes(current)) return
  seen.push(current)
  if (seen.length == 4) {
    addIfQuadrilateral(current, neighbors, seen, loops)
  } else {
    for (const neighbor of neighbors[current]) {
      boundedDfs(neighbors, neighbor, loops, seen)
    }
  }
  seen.pop()
}

function cyclesToCoords(loops: string[][], intersections: Intersection[]): Rect[] {
  const coords = []
  for (const quad of loops) {
    const rect = []
    for (const node of quad) {
      rect.push(intersections[node].coord)
    }
    const area = contourArea(rect)
    if (area < MIN_AREA) continue
    coords.push(rect)
  }
  return coords
}

// eslint-disable-next-line sonarjs/cognitive-complexity, @typescript-eslint/no-explicit-any
function houghFeatures(canny: gm.InputType): any {
  const operation = gm.pcLines(canny, 4, 2, 2)
  const lines = gm.tensorFrom(operation)
  const session = new gm.Session()
  session.init(operation)
  session.runOp(operation, 0, lines)

  const foundLines: gm.Line[] = []
  let houghLines = []
  const maxP = Math.max(canny.shape[0], canny.shape[1]);

  for (let i = 0; i < lines.size / 4; i += 1) {
    const y = Math.floor(i / lines.shape[1]);
    const x = i - (y * lines.shape[1]);
    const value = lines.get(y, x, 0);
    const x0 = lines.get(y, x, 1);
    const y0 = lines.get(y, x, 2);

    if (value > 0.0) {
      houghLines.push([value, x0, y0]);
    }
  }

  // const houghTime = Date.now()
  // console.log(`found ${houghLines.length} lines in ${houghTime - startTime}ms`)

  houghLines = houghLines.sort((b, a) => a[0] - b[0]);
  houghLines = houghLines.slice(0, MAX_LINES_TO_EVALUATE)

  for (let i1 = 0; i1 < houghLines.length; i1 += 1) {
    const line = new gm.Line()
    line.fromParallelCoords(
      houghLines[i1][1], houghLines[i1][2],
      canny.shape[1], canny.shape[0], maxP, maxP / 2,
    );
    //console.log(`line rho ${line.length} theta ${line.angle}`)
    // A slightly less accurate aggregator. The original algorithm
    // had the rho and theta normal to the line, so it was pretty easy
    // to determine if they were close together. GammaCV's algorithm
    // gives a length and angle which are of the line itself, which makes
    // it trickier.
    // I've settled on lines of similar angles that intersect within the
    // visible portion of the image (since almost all lines will intersect somewhere)
    // are considered able to be aggregated.
    let canAggregate = false
    for (const evalLine of foundLines) {
      const intersection = gm.Line.Intersection(line, evalLine)
      if (intersection && intersection[0] >= 0 && intersection[0] < canny.shape[1]
        && intersection[1] >= 0 && intersection[1] < canny.shape[0]
        && Math.abs(line.angle - evalLine.angle) < COALESCE_THETA_TOLERANCE) {
          canAggregate = true
          //console.log(`agg with ${evalLine.length} ${evalLine.angle}`)
          break
        }
    }
    if (!canAggregate) foundLines.push(line)
  }

  const intersections = findIntersections(canny, foundLines)
  const graph = {}
  for (let id=0; id < intersections.length; id++) {
    graph[id] = []
  }
  for (let i=0; i < intersections.length - 1; i++) {
    for (let j=i + 1; j < intersections.length; j++) {
      for (const line of intersections[j].lines) {
        if (intersections[i].lines.has(line)) {
          graph[i].push(j.toString())
          graph[j].push(i.toString())
        }
      }
    }
  }

  const loops: string[][] = []
  for (const node of Object.keys(graph)) {
    boundedDfs(graph, node, loops)
  }
  //console.log(`toplines ${topLines.length} rightLines ${rightLines.length} bottomLines ${bottomLines.length} leftLines ${leftLines.length}`)

  return { lines: foundLines, intersections, contours: cyclesToCoords(loops, intersections).sort((a, b) => b.area - a.area) }
}