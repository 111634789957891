import React from 'react'
import { IInboxEntry } from 'browser/contexts/inbox/interface'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import moment from 'moment'
import classNames from 'classnames'
import { Classes } from '@blueprintjs/core'

import { Button } from '../../atoms/button/button'
import { getActionText, getActorText, getLinkFromEntry, getObjectText } from 'browser/app/utils/inbox'

import './inbox-dropdown-item.scss'

interface IInboxDropdownItemProps {
  entry: IInboxEntry
  markAsRead: (...ids: (string | string[])[]) => void
  close: () => void
}

function getTimeString(dateString: string) {
  const date = moment(new Date(`${dateString}Z`))
  return date.format('MM/DD/YYYY HH:mm')
}

export const InboxDropdownItem = ({ close, entry, markAsRead }: IInboxDropdownItemProps) => {
  const className = classNames('inbox-dropdown-list-item', {
    'inbox-dropdown-list-item--unread': !entry.read,
  })

  const markAsReadIfNeeded = () => {
    if (!entry.read) {
      markAsRead(entry.groupId)
    }
  }

  const onMarkAsReadClicked = (ev: React.MouseEvent<HTMLButtonElement>) => {
    markAsReadIfNeeded()
    // dont want to trigger the outer link
    ev.preventDefault()
    ev.stopPropagation()
  }

  const onEntryClicked = () => {
    markAsReadIfNeeded()
    close()
  }

  return (
    <li>
      <Link
        className={className}
        to={getLinkFromEntry(entry)}
        onClick={onEntryClicked}
      >
        <p className="inbox-dropdown-list-item__title">
          {getActorText(entry)} {getActionText(entry)}{' '}
          <span className="inbox-dropdown-list-item__object">{getObjectText(entry)}</span>
        </p>
        <div className="inbox-dropdown-list-item__footer">
          <span className="inbox-dropdown-list-item__time">{getTimeString(entry.date)}</span>
          {!entry.read && entry.groupId && (
            <Button
              className={Classes.MINIMAL}
              size="small"
              onClick={onMarkAsReadClicked}
            >
              Mark as read
            </Button>
          )}
        </div>
      </Link>
    </li>
  )
}
