import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/molecules/fields/input-group-field/_input-group-field.scss'

type IInputGroupFieldProps = IBaseProps

export class InputGroupField extends React.Component<IInputGroupFieldProps, any> {
  public render() {
    return (
      <div className={classNames('c-inputGroup', this.props.className)}>
        {this.props.children}
      </div>
    )
  }
}
