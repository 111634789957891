import _ from 'lodash'
import { Query } from 'shared-libs/models/query'

export class ZipcodeQuery {
  private apis: any
  private entityType: string
  private filterKey: string
  private lastQuery: any

  constructor(apis, entityType, filters, filterKey) {
    this.apis = apis
    this.entityType = entityType
    this.filterKey = filterKey
  }

  public cancel() {
    this.lastQuery?.cancel()
    this.lastQuery = null
  }

  public queryLoadCountForZipcode(query) {
    this.cancel()
    const request = {
      filters: [
        {
          path: `${this.filterKey}.postalCode`,
          type: 'prefix',
          value: query,
        },
      ],
      groups: [
        {
          lanePointBucketType: 'localityRegion',
          path: this.filterKey,
          type: 'lanePoint',
        },
      ],
    }
    const collection = new Query(this.apis)
      .setEntityType(this.entityType)
      .setFilters(request.filters)
      .setGroups(request.groups)
      .setSize(1)
      .setShouldIncludeLeafEntities(false)
      .getCollection()
    this.lastQuery = collection.find()
    return this.lastQuery
      .then(() => collection.result)
      .finally(() => this.lastQuery = null)
  }
}
