import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import TextareaAutosize from 'react-textarea-autosize'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
// tslint:disable-next-line:max-line-length
import { FormGroupContentWrapper } from 'browser/components/atomic-elements/atoms/form-group-content-wrapper/form-group-content-wrapper'
import 'browser/components/atomic-elements/atoms/textarea/_textarea.scss'

/**
 * @uiComponent
 */
interface ITextareaProps extends IBaseProps {
  autoFocus?: boolean
  errorText?: string
  isDisabled?: boolean
  isRequired?: boolean
  isHorizontalLayout?: boolean
  isEditableInline?: boolean
  textareaClassName?: string
  minRows?: number
  name?: string
  onBlur?: (event) => void
  onClick?: () => void
  onChange?: (event, silentUpdate?: boolean) => void
  onEnterPressed?: (event) => void
  onFocus?: (event) => void
  onKeyPress?: (event) => void
  onSave?: () => void
  openErrorsOnHover?: boolean
  placeholder?: string
  initialValue?: string
  size?: string
  tabIndex?: string
  type?: string
  value?: string
  expandLink?: boolean // value is link, fetch and show it
}

export class Textarea extends React.Component<ITextareaProps, any> {
  public static defaultProps: ITextareaProps = {
    minRows: 2,
    openErrorsOnHover: true,
    type: 'text',
    expandLink: false,
  }

  private textArea

  constructor(props: ITextareaProps) {
    super(props)
    const { value, initialValue } = props
    this.state = {
      isFocused: false,
      internalValue: (_.isNil(initialValue) ? value : initialValue)
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      const { value, initialValue } = nextProps
      this.setState({ internalValue: (_.isNil(initialValue) ? value : initialValue) })
    }
  }

  public componentDidMount() {
    const { isDisabled, value, initialValue, expandLink, onChange } = this.props

    if (!isDisabled && !_.isNil(initialValue) && value !== initialValue) {
      // one time initalValue write back
      onChange(initialValue, true)
    }

    if (expandLink && !_.isEmpty(value)) {
      apis.getJSON(value).then((expandedValue) => {
        this.setState( { internalValue: expandedValue} )
      })
    }
  }

  public getElement(): HTMLTextAreaElement {
    return ReactDOM.findDOMNode(this.refs.textarea) as HTMLTextAreaElement
  }

  public focus() {
    this.getElement().focus()
  }

  public blur() {
    this.getElement().blur()
  }

  public render() {
    const {
      className,
      isDisabled,
      errorText,
      isHorizontalLayout,
    } = this.props
    const { isFocused } = this.state

    return (
      <FormGroupContentWrapper
        hasError={!_.isEmpty(errorText)}
        isHorizontalLayout={isHorizontalLayout}
        isFocused={isFocused}
        isDisabled={isDisabled}
        className={className}
      >
        {this.renderTextarea()}
      </FormGroupContentWrapper>
    )
  }

  private renderTextarea() {
    const {
      autoFocus,
      textareaClassName,
      isHorizontalLayout,
      errorText,
      isDisabled,
      name,
      minRows,
      onClick,
      placeholder,
      tabIndex,
      type,
      size,
      value,
    } = this.props

    const { internalValue } = this.state

    const sizeClassName = _.isEmpty(size) ? '' : `c-input--${size}`
    // TODO(louis): Why do we do this?
    const inputValue = internalValue || ''
    // TODO(louis): Need to handle empty case where the placeholder is showing
    // but we should actually show '-'
    if (_.isArray(value)) {
      const messages = _.map(value, (error) => error.message)
      const flattenedValue = _.join(messages, '\n')
      return (
        <TextareaAutosize
          autoFocus={autoFocus}
          className={classNames('c-input c-input--textarea', sizeClassName, textareaClassName, {
            'c-input--error': !_.isEmpty(errorText),
            'c-input--isDisabled': isDisabled,
            'c-input--isHorizontalLayout': isHorizontalLayout,
          })}
          data-debug-id={_.get(this.props, 'data-debug-id')}
          disabled={isDisabled}
          ref={(ref) => { this.textArea = ref }}
          name={name}
          minRows={minRows}
          onBlur={this.handleOnBlur}
          onClick={onClick}
          onChange={this.handleOnChange}
          onFocus={this.handleOnFocus}
          onKeyPress={this.handleOnKeyPress}
          placeholder={placeholder}
          tabIndex={tabIndex}
          type={type}
          value={flattenedValue}
        />
      )
    }
    return (
      <TextareaAutosize
        autoFocus={autoFocus}
        className={classNames('c-input c-input--textarea', sizeClassName, textareaClassName, {
          'c-input--error': !_.isEmpty(errorText),
          'c-input--isDisabled': isDisabled,
          'c-input--isHorizontalLayout': isHorizontalLayout,
        })}
        data-debug-id={_.get(this.props, 'data-debug-id')}
        disabled={isDisabled}
        ref={(ref) => { this.textArea = ref }}
        name={name}
        minRows={minRows}
        onBlur={this.handleOnBlur}
        onClick={onClick}
        onChange={this.handleOnChange}
        onFocus={this.handleOnFocus}
        onKeyPress={this.handleOnKeyPress}
        placeholder={placeholder}
        tabIndex={tabIndex}
        type={type}
        value={inputValue}
      />
    )
  }

  private handleOnChange = (event) => {
    const { onChange } = this.props

    this.setState({ internalValue: event.target.value })

    if (onChange) {
      onChange(event.target.value)
    }
  }

  private handleOnBlur = (event) => {
    const { onBlur } = this.props
    this.setState({ isFocused: false })
    if (onBlur) {
      onBlur(event)
    }
  }

  private handleOnFocus = (event) => {
    const { onFocus } = this.props
    this.setState({ isFocused: true })
    if (onFocus) {
      onFocus(event)
    }
  }

  private handleOnKeyPress = (event) => {
    const { onEnterPressed, onKeyPress } = this.props
    if (event.key === 'Enter' && !event.shiftKey) {
      if (onEnterPressed) {
        onEnterPressed(event)
      }
    }
    if (onKeyPress) {
      onKeyPress(event)
    }
  }
}
