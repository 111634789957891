import _ from 'lodash'
import React from 'react'

import { EntityTableWithGroupSimplification } from 'browser/components/atomic-elements/organisms/entity/entity-table-with-group-simplification'

const RbTableEmptyState = () => {
  return (
    <div className='c-emptyTableMessage'>
      <div className='f3 lh-title mb2'>
        No invoice matches your selected filters.
      </div>
      <div className='lh-copy mb4'>
        Please modify filters to display matches
      </div>
    </div>
  )
}

/**
 * Entity table to render rows of salesInvoice entities
 *
 * @props IEntityTableProps
 */
export class RbTable extends EntityTableWithGroupSimplification {
  render() {
    const { selection } = this.props
    return (
      <EntityTableWithGroupSimplification 
        {...this.props}
        emptyState={<RbTableEmptyState />}
      />
    )
  }
}
