import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import { AnalyticsBlock } from './analytics-block'

import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import {
  filterLane,
  filterLoadStatus,
} from 'browser/components/atomic-elements/organisms/analytics/data-set'

/**
 * @uiComponent
 */
interface IOrderAnalyticsSectionProps extends IBaseProps {
  entity: any
  showHistogram: boolean
  showSummary: boolean
  viewName: string
  settings: Settings
}

interface IOrderAnalyticsSectionState {
  dataSets: any
  filters: any
  isLoading: boolean
  laneId: any
  name: string
  tabs: any[]
}

class OrderAnalyticsSection extends React.Component<IOrderAnalyticsSectionProps, IOrderAnalyticsSectionState> {

  private store: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      dataSets: [],
      filters: [],
      isLoading: true,
      laneId: null,
      name: '',
      tabs: null,
    }
  }

  public componentDidMount() {
    const { entity } = this.props
    this.prepareData(entity)
  }

  public componentWillUnmount() {
    const { dataSets } = this.state
    _.forEach(dataSets, (dataSet) => dataSet.dispose())
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { entity } = this.props
    if (entity.uniqueId !== nextProps.entity.uniqueId) {
      this.setState({ isLoading: true })
      this.prepareData(nextProps.entity)
    }
  }

  public prepareData(entity) {
    const { settings, viewName } = this.props
    const applicationBundle = settings.getApplicationBundle()
    const promises = [
      this.fetchLaneId(entity),
      applicationBundle.getView(viewName),
    ]
    return Promise.all(promises).then((results) => {
      const [laneId, resolved] = results
      const name = resolved.view.view.name
      const viewState = resolved.view.view.state
      const filters = this.getFilters(laneId)
      const queries = viewState.queries
      const dataSets = this.createDataSets(queries, filters)
      const dataPromises = _.map(dataSets, (dataSet: any) => dataSet.find())
      this.setState({
        dataSets,
        filters,
        isLoading: true,
        laneId,
        name,
        tabs: viewState.tabs,
      })
    }).catch(() => this.setState({ isLoading: false }))
  }

  public render() {
    const { dataSets, filters, isLoading, name, tabs } = this.state
    const { showHistogram, showSummary } = this.props
    return (
      <Section
        hideHeaderBorder={false}
        title={name}
      >
        <AnalyticsBlock
          dataSets={dataSets}
          filters={filters}
          isLoading={isLoading}
          showHistogram={showHistogram}
          showSummary={showSummary}
          tabs={tabs}
        />
      </Section>
    )
  }

  private fetchLaneId(entity) {
    return apis.getLaneByEntityId(entity.uniqueId)
      .then((laneBucketValue: any) => laneBucketValue.identifier)
  }

  private getFilters(laneId) {
    return [
      filterLoadStatus('Settling'),
      filterLane(laneId),
    ]
  }

  private createDataSets(queries, filters) {
    const results = {}
    _.forEach(queries, (query, queryName) => {
      results[queryName] = new EntityDataSource(query)
        .setFilters(filters)
        .setOnChange(this.handleDataChange)
    })
    return results
  }

  private handleDataChange = (dataSet) => {
    const { dataSets } = this.state
    const isLoading = _.some(dataSets, { isLoading: true })
    this.setState({ isLoading })
  }
}

export default React.forwardRef((props: IOrderAnalyticsSectionProps, ref: React.Ref<OrderAnalyticsSection>) => (
  <AppNavigatorContext.Consumer>
    {({ settings }) => (
      <OrderAnalyticsSection {...props} settings={settings} ref={ref} />
    )}
  </AppNavigatorContext.Consumer>
))
