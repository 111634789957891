import { Icon, Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import 'browser/components/atomic-elements/atoms/card/_card-header.scss'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { Link } from 'react-router-dom'
import { Entity } from 'shared-libs/models/entity'

/**
 * @uiComponent
 */
export interface ICardHeaderProps extends IBaseProps {
  entity?: Entity
  dropdownMenuElement?: React.ReactElement<any>
  onClose?: () => void
}

export class CardHeader extends React.Component<ICardHeaderProps, any> {

  public render() {
    const { className, children } = this.props
    return (
      <div className={classNames('grid-block shrink c-cardHeader', className)}>
        {children}
        {this.renderDropdownPopover()}
        {this.renderOpenInNewTabButton()}
        {this.renderCloseButton()}
      </div>
    )
  }

  private renderDropdownPopover() {
    const { dropdownMenuElement } = this.props
    if (!dropdownMenuElement) {
      return false
    }
    const tetherOptions = {
      attachment: 'top right',
      targetAttachment: 'bottom right',
    }
    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin u-noPrint'>
        <TetherTarget
          closeOnPortalClick={true}
          tethered={this.renderDropdown()}
          tetherOptions={tetherOptions}
        >
          <Button>
            <Icon
              icon={IconNames.MORE}
            />
          </Button>
        </TetherTarget>
      </CardHeaderItem>
    )
  }

  private renderDropdown() {
    const { dropdownMenuElement } = this.props
    return (
      <Popover
        className='collapse'
        data-debug-id='card-header-options-popover'
      >
        {dropdownMenuElement}
      </Popover>
    )
  }

  private renderOpenInNewTabButton() {
    const { entity, onClose } = this.props
    // do not render this in single page mode
    if (!entity || !onClose) {
      return
    }
    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin u-noPrint'>
        <Link
          className={Classes.BUTTON}
          to={`/entity/${entity.uniqueId}`}
          target='_blank' rel='noopener noreferrer'
        >
          <Icon
            icon={IconNames.SHARE}
          />
        </Link>
      </CardHeaderItem>
    )
  }

  private renderCloseButton() {
    const { onClose } = this.props

    if (!onClose) {
      return
    }
    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin u-noPrint'>
        <Button
          onClick={onClose}
          data-debug-id='document-card-close'
        >
          <Icon
            icon={IconNames.CROSS}
          />
        </Button>
      </CardHeaderItem>
    )
  }
}
