import _ from 'lodash'

export class FramesManager {
  frames: any

  constructor(frames) {
    this.frames = frames
  }

  public push(newFrame) {
    const newFrames = this.frames.push(newFrame)
    return new FramesManager(newFrames)
  }

  public getKey() {
    return this.frames.last().get('key')
  }

  public getRef() {
    return this.frames.last().get('ref')
  }

  public getCurrentContext() {
    return this.frames.last().get('context')
  }

  public getFromContainer(container, key) {
    if (!container) return
    if (_.isArray(container)) {
      for (let i = container.length - 1; i >= 0; --i) {
        const value = _.get(container[i], key)
        if (!_.isUndefined(value)) return value
      }
    }
    return _.get(container, key)
  }

  public getFromNamespace(namespace, key, startFrame) {
    for (let i = startFrame; i >= 0; --i) {
      const frame = this.frames.get(i)
      const container = frame.get(namespace)
      const value = this.getFromContainer(container, key)
      if (!_.isUndefined(value)) return value
    }
  }

  public getActions() {
    const mergedActions = {}

    this.frames.forEach((frame) => {
      _.assign(mergedActions, frame.get('actions') || {})

      const ref = frame.get('ref')
      const component = ref && ref.current
      if (component && component.actions) {
        _.assign(mergedActions, component.actions)
      }

    })

    return mergedActions
  }

  public getStates() {
    const mergedStates = {}

    this.frames.forEach((frame) => {
      const frameState = _.reduce(frame.get('state'), (result, value, key) => {
        _.assign(result, value)
        return result
      }, {} )
      _.assign(mergedStates, frameState)
    })

    return mergedStates
  }

  public getAction(key) {
    const startFrame = this.frames.size - 1
    for (let i = startFrame; i >= 0; --i) {
      const frame = this.frames.get(i)
      const container = frame.get('actions')
      const value: any = this.getFromContainer(container, key)
      if (_.isUndefined(value)) continue
      if (typeof value !== 'function') {
        throw new Error('Expected a function for ' + key + ', found: ' + value)
      }
      return value.bind(container)
    }
  }

  public getValue(key) {
    const startFrame = this.frames.size - 1
    return this.getFromNamespace('state', key, startFrame)
  }

  public getContext(key) {
    const startFrame = this.frames.size - 1
    return this.getFromNamespace('context', key, startFrame)
  }

  public getParentContext(key) {
    // start from the 2nd to last frame because last frame is the current context
    const startFrame = this.frames.size - 2
    return this.getFromNamespace('context', key, startFrame)
  }

  public getTransientVariables() {
    return this.frames.first().get('transientVariables')
  }
}
