import classNames from 'classnames'
import React from 'react'
import Scroll from 'react-scroll'
import { v4 as uuidv4 } from 'uuid'
const Link = Scroll.Link
const Element = Scroll.Element
const Events = Scroll.Events
const scrollSpy = Scroll.scrollSpy
import { Classes } from '@blueprintjs/core'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface IScrollspyTabsProps extends IBaseProps {
  contentClassName: string
}

export class ScrollspyTabs extends React.Component<IScrollspyTabsProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      uuid: uuidv4(),
    }
  }

  // TODO(louis): move this into the regular atoms directory
  // TODO(louis): remove hard coded containerId
  public componentDidMount() {
    scrollSpy.update()
  }

  public componentWillUnmount() {
    Events.scrollEvent.remove('begin')
    Events.scrollEvent.remove('end')
  }

  public renderTabs(containerId) {
    const { children } = this.props
    return React.Children.map(children, (tab, index) => {
      const tabElement = tab as React.ReactElement<any>
      return (
        <div>
          <Link
            activeClass={Classes.ACTIVE}
            className={classNames('c-scrollSpyButton', Classes.BUTTON, Classes.MINIMAL)}
            containerId={containerId}
            duration={250}
            key={index}
            smooth={true}
            spy={true}
            to={tabElement.props.tabLabel + '-' + this.state.uuid}
          >
            {tabElement.props.tabLabel}
          </Link>
        </div>
      )
    })
  }

  public renderElements() {
    const { children } = this.props
    return React.Children.map(children, (tab, index) => {
      const tabElement = tab as React.ReactElement<any>
      return (
        <Element
          className='c-scrollSpyContent'
          key={index}
          name={tabElement.props.tabLabel + '-' + this.state.uuid}
        >
          {tabElement}
        </Element>
      )
    })
  }

  public render() {
    // Note the grid-content collapse is needed on the renderElements block
    // because otherwise the scrollspy doesn't work properly.
    const containerId = 'containerElement-' + this.state.uuid
    return (
      <div className={classNames('grid-block', this.props.className)}>
        <div className='grid-block'>
          <Element
            className='grid-content collapse'
            id={containerId}
            style={{
              position: 'relative',
            }}
          >
            {this.renderElements()}
          </Element>
        </div>
        <div className='c-scrollSpyTabs'>
          {this.renderTabs(containerId)}
        </div>
      </div>
    )
  }
}
