import _ from "lodash";
import React from "react";

import SheetPortal from "browser/components/atomic-elements/atoms/sheet/sheet-portal";
import { EntityFormBlock } from 'browser/components/atomic-elements/organisms/entity/entity-form-block'
import { Entity } from "shared-libs/models/entity";
import { Footer } from "browser/components/atomic-elements/atoms/footer/footer";
import { EntityErrorBlock } from "browser/components/atomic-elements/atoms/error-block/entity-error-block";

interface ICreateSpotSheetProps {
  isOpen: boolean
  onClose: (Entity) => void
  uiSchemaPath: string
  spot: Entity
}

interface ICreateSpotSheetState {
  errors: any[]
}

export class CreateSpotSheet extends React.Component<ICreateSpotSheetProps, ICreateSpotSheetState> {
  constructor(props) {
    super(props)

    this.state = {
      errors: []
    }
  }

  public componentDidUpdate(prevProps, prevState) {
    if (prevProps.spot !== this.props.spot) {
      this.setState({ errors: [] })
    }
  }

  public render() {
    return (
      <SheetPortal
        isOpen={this.props.isOpen}
        footer={this.renderCreateSpotFooter()}
        size='sm'
      >
        {this.renderSpotForm()}
      </SheetPortal>
    )

  }

  private renderSpotForm = () => {
    const { uiSchemaPath, spot } = this.props
    const { errors } = this.state

    if (_.isEmpty(spot)) {
      return
    }

    return <>
      <EntityFormBlock
        entity={spot}
        state={{}}
        uiSchemaPath={uiSchemaPath}
      />
      <EntityErrorBlock
        entity={spot}
        errors={errors}
      />
      {/* TODO - entity error block doesn't re-render properly VD-8104 */}
    </>
  }

  private renderCreateSpotFooter = () => {
    return (
      <Footer
        primaryButtonText='Submit'
        onPrimaryButtonClick={this.handleCreateSpot}
        onCancelButtonClick={this.onCancelButtonClick}
      />
    )
  }

  private handleCreateSpot = async () => {
    const { spot } = this.props

    try {
      await spot.save()
    } catch (e) {
      this.setState({ errors: e.errors })
      this.forceUpdate()

      return
    }

    this.props.onClose(null)
  }

  private onCancelButtonClick = () => {
    this.props.onClose(null)
  }
}
