import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Icon, IconNames } from 'browser/components/atomic-elements/atoms/custom-icon'
import './_table-requirements-cell.scss'

/**
 * @comment corresponds to "/1.0/entities/metadata/core_accounting_accountsReceivable_salesInvoice.json#/definitions/requirement"
 *
 * @prop category - the type of a requirement
 * @prop message - string to be displayed when the requirment satisfied icon is hovered over
 * @prop satisfied - whether or not the requirement is satisfied
 */
export interface Requirement {
  category: string
  message: string
  satisfied: boolean
  source: any
}

interface IRequirementChecklistItem {
  itemSatisfied: Requirement['satisfied']
  itemText: Requirement['message']
}

const RequirementChecklistItem: React.FC<IRequirementChecklistItem> = ({ itemText, itemSatisfied }) => {
  return (
    <div className='requirement-checklist-item'>
      <Icon icon={itemSatisfied ? IconNames.GREEN_CHECK : IconNames.X_BOX} />
      <div className='icon-adjacent-text'>{itemText}</div>
    </div>
  )
}

// render this on hover if the entity has no matching requirements for the column
const noMatchingRequirementsHtml = (label: ITableRequirementStatusCellProps['label']) => (
  <RequirementChecklistItem itemSatisfied={true} itemText={`No ${label} Requirement Specified` } />
)

/**
 * @prop AdditionalToolTipContent - component that renders additional content into tooltips
 * @prop label - the column label
 * @prop requirementCategory - the type of a requirement displayed by the table cell
 * @prop requirementsChecklistHeadingText - text to be displayed in the header of the requirements
 *                                          checklist popover
 * @prop requirementPath - path to the list of requirements
 * @prop value - the parent object of requirementPath.  This object should have everything for
 *               any contexual calculation
 */
export interface ITableRequirementStatusCellProps extends IBaseProps {
  AdditionalToolTipContent?: () => JSX.Element
  label: string
  requirementCategory: Requirement['category']
  requirementsChecklistHeadingText?: string
  requirementPath?: string
  value: any
}

/**
 * @component TableRequirementStatusCell - A table cell that takes a list of requirements
 * and renders a checkmark if they are satisfied, and an x if they are not.
 *
 * @props ITableRequirementStatusCellProps
 */
export const TableRequirementStatusCell: React.FC<ITableRequirementStatusCellProps> = ({
  AdditionalToolTipContent,
  label,
  requirementCategory,
  requirementPath = 'readyToInvoice.requirements',
  requirementsChecklistHeadingText,
  value
}) => {
  let cellSatisfied = true
  let requirementChecklistItemKeyIter = 0
  const requirements: Requirement[] = _.get(value, requirementPath, [])

  const requirementsChecklistHtml = _.map(requirements, ({ category, message, satisfied, source }: Requirement) => {
    if (category === requirementCategory) {
      requirementChecklistItemKeyIter++
      cellSatisfied = cellSatisfied && satisfied
      const preText = _.get(source, 'displayName', '')
      const itemText = _.isEmpty(preText) ? message : preText + ' - ' + message
      return (
        <RequirementChecklistItem
          key={`${category}_${requirementChecklistItemKeyIter}`}
          itemSatisfied={satisfied}
          itemText={itemText}
        />
      )
    }
  })

  if (AdditionalToolTipContent) {
    requirementsChecklistHtml.push(
      <AdditionalToolTipContent key={`${requirementCategory}_${requirementChecklistItemKeyIter}_addl`} />
    )
  }

  const popoverContent = (
    <div style={{ padding: '5px' }}>
      {requirementsChecklistHeadingText && (
        <div className='requirement-checklist-header'>{requirementsChecklistHeadingText}</div>
      )}
      {requirementsChecklistHtml.length > 0 ? requirementsChecklistHtml : noMatchingRequirementsHtml(label)}
    </div>
  )

  return (
    <Popover
      content={popoverContent}
      hoverOpenDelay={500}
      interactionKind={PopoverInteractionKind.HOVER}
      minimal={true}
      position={Position.LEFT_TOP}
    >
      <Icon icon={cellSatisfied ? IconNames.GREEN_CHECK : IconNames.X_BOX} />
    </Popover>
  )
}
