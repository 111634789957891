import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { PrintContainer } from 'browser/components/atomic-elements/atoms/print-container/print-container'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import 'browser/components/atomic-elements/domains/dms/havas-expense-report/_havas-expense-report.scss'
// tslint:disable-next-line:max-line-length
import { FinancialItemTable } from 'browser/components/atomic-elements/domains/trucking/financial-item-table/financial-item-table'
import {
  fetchEdges,
  getDisplayName,
  renderLocation,
  renderPhonesAndEmails,
} from 'browser/components/atomic-elements/domains/trucking/generated-document-helper'
// tslint:disable-next-line:max-line-length
import { OrderLabeledInputField } from 'browser/components/atomic-elements/domains/trucking/order-labeled-input-field/order-labeled-input-field'
import { StopCargoTable } from 'browser/components/atomic-elements/domains/trucking/stop-cargo-table/stop-cargo-table'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'

interface ICustomerInvoiceProps extends IBaseProps {
  entity: any
  settings: Settings
}

interface ICustomerInvoiceState {
  firm: any
  isLoading: boolean
  orderContent: any
  tmsSettings: any
}

class CustomerInvoice extends React.Component<ICustomerInvoiceProps, ICustomerInvoiceState> {

  constructor(props) {
    super(props)
    this.state = {
      firm: null,
      isLoading: true,
      orderContent: null,
      tmsSettings: null,
    }
  }

  public componentDidMount() {
    const { entity, settings } = this.props
    const promises = []
    const store = apis.getStore()
    const orderContent = _.cloneDeep(entity.content)
    fetchEdges(store, ['brokerOrder'], orderContent, promises)
    fetchEdges(store, ['carrierOrder'], orderContent, promises)
    fetchEdges(store, ['dispatchOrder'], orderContent, promises)
    Promise.all(promises).then(() => {
      this.setState({
        firm: settings.getFirm(),
        isLoading: false,
        orderContent,
        tmsSettings: settings.getTMSSettings(),
      })
    })
  }

  public render() {
    const { orderContent, isLoading } = this.state
    if (isLoading) {
      return this.renderLoading()
    }

    // TODO(peter/louis): need to pull out print frame to generalized view
    // and then there also handle the print modal
    return (
      <div className='c-printFrame'>
        <Head
          title='Customer Invoice'
        />
        {this.renderCustomerInvoice()}
      </div>
    )
  }

  private renderLoading() {
    return (
      <div className='c-printFrame'>
        <Head
          title='Loading'
        />
        <div className='grid-block'>
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  private renderCustomerInvoice() {
    const { settings } = this.props
    const { orderContent, tmsSettings } = this.state
    const { dispatchOrder, carrierOrder, brokerOrder } = orderContent
    const order = settings.isBrokerTMSApplication() ? brokerOrder : carrierOrder
    const { identifier } = dispatchOrder
    const primaryIdentifierName = 'Load Number'
    const todaysDate = moment().format('ddd, MMM DD, YYYY')
    const title = `${identifier} - Invoice`
    const origin = dispatchOrder.stops[0]
    const destination = dispatchOrder.stops[dispatchOrder.stops.length - 1]
    const invoiceTerms = _.get(tmsSettings, 'tmsSettings.invoiceTerms')
    const revenueItems = _.get(dispatchOrder, 'revenueItems')

    return (
      <PrintContainer>
        <Head
          title={title}
        />
        <div className='c-printPage c-printPage--portrait'>
          <div className='c-printPageInner'>

            <div className='row'>
              <div className='col-xs-6'>
                <div className='f2 lh-title'>
                  Invoice
                </div>
                <div className='u-bumperBottom'>
                  {primaryIdentifierName}: {identifier} &middot; Issued: {todaysDate}
                </div>
              </div>
              {this.renderFirmBranding()}
            </div>

            {this.renderInvoiceContext()}

            <Section title='Details'>
              {this.renderIdentifiersAndShipDate(order)}
              <StopCargoTable
                order={order}
                dispatchOrder={dispatchOrder}
              />
            </Section>

            <Section title='Charges'>
              <FinancialItemTable financialItems={revenueItems}/>
            </Section>

            <Section title='Terms'>
              <div className='pre-wrap'>
                {invoiceTerms}
              </div>
            </Section>
          </div>
        </div>
      </PrintContainer>
    )
  }

  private renderFirmBranding() {
    const { firm } = this.state
    const brokerLogo = _.get(firm, 'business.logo.uri', '')
    // {firm.displayName}
    return (
      <div className='col-xs-6 tr'>
        <img
          className='c-printLogo u-bumperBottom'
          src={brokerLogo}
        />
      </div>
    )
  }

  private renderInvoiceContext() {
    const { firm, orderContent } = this.state
    const { settings } = this.props
    const { brokerOrder, carrierOrder, dispatchOrder } = orderContent
    const { identifier } = dispatchOrder
    const order = settings.isBrokerTMSApplication() ? brokerOrder : carrierOrder
    const { customer } = order

    const brokerAddress = _.first(_.get(firm, 'business.addresses', []))
    const brokerPhones = _.get(firm, 'business.phoneNumbers', [])
    const brokerEmails = _.get(firm, 'business.emails', [])

    const user = settings.getUser()
    const userPhones = _.get(user, 'person.phoneNumbers', [])
    const userEmails = _.get(user, 'person.emails', [])

    const customerAddress = _.first(_.get(customer, 'business.addresses', []))
    const todaysDate = moment().format('ddd, MMM D, YYYY')
    const paymentTermsDays = _.get(customer, 'receivable.paymentTerms', 30)
    const dueDate = moment().add(paymentTermsDays, 'days').format('ddd, MMM D, YYYY')
    return (
      <div className='row u-bumperBottom--lg'>
        <div className='col-xs-6'>
          <FormTable>
            <FormGroup isHorizontalLayout={true}>
              <Label
                size='sm'
                isHorizontalLayout={true}
              >
                To
              </Label>
              <div className='c-fakeInputContainer--sm'>
                <div className='b'>
                  {getDisplayName(customer)}
                </div>
                {renderLocation(_.get(customerAddress, 'value'))}
              </div>
            </FormGroup>
            <FormGroup isHorizontalLayout={true}>
              <Label
                size='sm'
                isHorizontalLayout={true}
              >
                Invoice Number
              </Label>
              <div className='c-fakeInputContainer--sm'>
                {identifier}
              </div>
            </FormGroup>
            <OrderLabeledInputField
              isHorizontalLayout={true}
              className='u-pullUp'
              size='sm'
              referenceLabel='Customer Load Number'
              value={dispatchOrder.additionalIdentifiers}
              label='Customer Load Number'
            />
            <FormGroup isHorizontalLayout={true}>
              <Label
                size='sm'
                isHorizontalLayout={true}
              >
                Issued
              </Label>
              <div className='c-fakeInputContainer--sm'>
                {todaysDate}
              </div>
            </FormGroup>
            <FormGroup isHorizontalLayout={true}>
              <Label
                size='sm'
                isHorizontalLayout={true}
              >
                Due
              </Label>
              <div className='c-fakeInputContainer--sm'>
                {dueDate}
              </div>
            </FormGroup>
          </FormTable>
        </div>
        <div className='col-xs-6'>
          <FormTable>
            <FormGroup isHorizontalLayout={true}>
              <Label
                size='sm'
                isHorizontalLayout={true}
              >
                From
              </Label>
              <div className='c-fakeInputContainer--sm'>
                <div className='b'>
                  {getDisplayName(firm)}
                </div>
                {renderLocation(_.get(brokerAddress, 'value'))} <br />
                {renderPhonesAndEmails(brokerPhones, brokerEmails)}
              </div>
            </FormGroup>
            <FormGroup isHorizontalLayout={true}>
              <Label
                size='sm'
                isHorizontalLayout={true}
              >
                From Contact
              </Label>
              <div className='c-fakeInputContainer--sm'>
                <div className='b'>
                  {user.person.firstName} {user.person.lastName}
                </div>
                {_.map(userPhones, (phone: any, index) => (
                  <span key={index}>
                    {phone.value.phone}<br />
                  </span>
                ))}
                {_.map(userEmails, (email: any, index) => (
                  <span key={index}>
                    {email.value}<br />
                  </span>
                ))}
              </div>
            </FormGroup>
          </FormTable>
        </div>
      </div>
    )
  }

  private renderIdentifiersAndShipDate(order) {
    const { dispatchOrder } = order
    if (!dispatchOrder) {
      return null
    }
    const { identifier } = dispatchOrder
    const primaryIdentifierName = 'Load Number'
    const trailerType = _.get(dispatchOrder, 'trailerType')
    return (
      <div className='row u-bumperBottom'>
        <div className='col-xs-6'>
          <FormTable>
            <InputField
              isHorizontalLayout={true}
              size='sm'
              label={primaryIdentifierName}
              value={identifier}
            />
            {_.map(dispatchOrder.additionalIdentifiers, (additionalIdentifier: any, index) => {
              return this.renderIdentifier(additionalIdentifier.label, additionalIdentifier.value, index)
            })}
          </FormTable>
        </div>
        <div className='col-xs-6'>
          <FormTable>
            <FormGroup isHorizontalLayout={true}>
              <Label
                size='sm'
                isHorizontalLayout={true}
              >
                Trailer Type
              </Label>
              <div className='c-fakeInputContainer--sm'>
                {trailerType}
              </div>
            </FormGroup>
          </FormTable>
        </div>
      </div>
    )
  }

  private renderIdentifier(label, identifier, index) {
    return (
      <InputField
        key={index}
        isHorizontalLayout={true}
        size='sm'
        label={label}
        value={identifier}
      />
    )
  }
}

export default React.forwardRef((props: ICustomerInvoiceProps, ref: React.Ref<CustomerInvoice>) => (
  <AppNavigatorContext.Consumer>
    {({ settings }) => <CustomerInvoice {...props} settings={settings} ref={ref} />}
  </AppNavigatorContext.Consumer>
))
