import _ from 'lodash'

import { RelativeDateFilterRange, RELATIVE } from './relative-date'

export const dateShortcuts = [
  {
    label: 'Past Day',
    key: 'past_day',
    getOffsets: (): RelativeDateFilterRange => {
      return {
        startDate: { offsetUnit: 'days', offsetValue: '1', mode: RELATIVE },
        endDate: { offsetUnit: 'now', mode: RELATIVE },
      }
    },
  },
  {
    label: 'Past Week',
    key: 'past_week',
    getOffsets: (): RelativeDateFilterRange => {
      return {
        startDate: { offsetUnit: 'weeks', offsetValue: '1', mode: RELATIVE },
        endDate: { offsetUnit: 'now', mode: RELATIVE },
      }
    },
  },
  {
    label: 'Past Month',
    key: 'past_month',
    getOffsets: (): RelativeDateFilterRange => {
      return {
        startDate: { offsetUnit: 'months', offsetValue: '1', mode: RELATIVE },
        endDate: { offsetUnit: 'now', mode: RELATIVE },
      }
    },
  },
  {
    label: 'Past Year',
    key: 'past_year',
    getOffsets: (): RelativeDateFilterRange => {
      return {
        startDate: { offsetUnit: 'years', offsetValue: '1', mode: RELATIVE },
        endDate: { offsetUnit: 'now', mode: RELATIVE },
      }
    },
  },
  {
    label: 'Today',
    key: 'today',
    getOffsets: (): RelativeDateFilterRange => {
      return {
        startDate: { offsetUnit: 'days', offsetValue: '0', mode: RELATIVE },
        endDate: { offsetUnit: 'days', offsetValue: '0', mode: RELATIVE },
      }
    },
  },
  {
    label: 'Yesterday',
    key: 'yesterday',
    getOffsets: (): RelativeDateFilterRange => {
      return {
        startDate: { offsetUnit: 'days', offsetValue: '1', mode: RELATIVE },
        endDate: { offsetUnit: 'days', offsetValue: '1', mode: RELATIVE },
      }
    },
  },
  {
    label: 'Tomorrow',
    key: 'tomorrow',
    getOffsets: (): RelativeDateFilterRange => {
      return {
        startDate: { offsetUnit: 'days', offsetValue: '-1', mode: RELATIVE },
        endDate: { offsetUnit: 'days', offsetValue: '-1', mode: RELATIVE },
      }
    },
  },
  {
    label: 'This Week',
    key: 'this_week',
    getOffsets: (): RelativeDateFilterRange => {
      return {
        startDate: { offsetUnit: 'weeks_start', offsetValue: '0', mode: RELATIVE },
        endDate: { offsetUnit: 'weeks_end', offsetValue: '0', mode: RELATIVE },
      }
    },
  },
  {
    label: 'Last Week',
    key: 'last_week',
    getOffsets: (): RelativeDateFilterRange => {
      return {
        startDate: { offsetUnit: 'weeks_start', offsetValue: '1', mode: RELATIVE },
        endDate: { offsetUnit: 'weeks_end', offsetValue: '1', mode: RELATIVE },
      }
    },
  },
  {
    label: 'This Month',
    key: 'this_month',
    getOffsets: (): RelativeDateFilterRange => {
      return {
        startDate: { offsetUnit: 'months_start', offsetValue: '0', mode: RELATIVE },
        endDate: { offsetUnit: 'months_end', offsetValue: '0', mode: RELATIVE },
      }
    },
  },
  {
    label: 'Last Month',
    key: 'last_month',
    getOffsets: (): RelativeDateFilterRange => {
      return {
        startDate: { offsetUnit: 'months_start', offsetValue: '1', mode: RELATIVE },
        endDate: { offsetUnit: 'months_end', offsetValue: '1', mode: RELATIVE },
      }
    },
  },
  {
    label: 'This Year',
    key: 'this_year',
    getOffsets: (): RelativeDateFilterRange => {
      return {
        startDate: { offsetUnit: 'years_start', offsetValue: '0', mode: RELATIVE },
        endDate: { offsetUnit: 'years_end', offsetValue: '0', mode: RELATIVE },
      }
    },
  },
  {
    label: 'Last Year',
    key: 'last_year',
    getOffsets: (): RelativeDateFilterRange => {
      return {
        startDate: { offsetUnit: 'years_start', offsetValue: '1', mode: RELATIVE },
        endDate: { offsetUnit: 'years_end', offsetValue: '1', mode: RELATIVE },
      }
    },
  },
]

export const dateShortcutsByKey = _.keyBy(dateShortcuts, 'key')
