import _ from 'lodash'
import React from 'react'

import 'browser/components/atomic-elements/atoms/input/_quantity-input.scss'
import { IInputProps, Input } from 'browser/components/atomic-elements/atoms/input/input'
import { ISelectProps, Select } from 'browser/components/atomic-elements/atoms/select'
import {
  InputGroupField,
} from 'browser/components/atomic-elements/molecules/fields/input-group-field/input-group-field'

/**
 * @uiComponent
 */
export interface IQuantityInputProps extends IInputProps {
  defaultUnit?: string
  errorsMap?: any
  isUnitDisabled?: boolean
  showUnitSelect?: boolean
  unitOptions?: any[]
  value: any
}

export class QuantityInput extends React.Component<IQuantityInputProps, any> {

  public static defaultProps: Partial<IQuantityInputProps> = {
    showUnitSelect: true,
  }

  private input: any

  public focus() {
    return this.input.focus()
  }

  public render() {
    const { errorsMap, showUnitSelect } = this.props
    const quantity = this.getValue()
    const { unit, value } = quantity
    const errorText: string = showUnitSelect ?
      _.get(errorsMap, ['value', '_errors', 0], null) :
      _.get(errorsMap, ['_errors', 0], null)

    return (
      <InputGroupField>
        <Input
          inputType='number'
          placeholder='0'
          {...this.props}
          inputClassName='c-quantityInput-valueInput'
          errorText={errorText}
          onChange={this.handleValueChange}
          ref={(ref) => { this.input = ref }}
          value={value}
          charsToStrip={new RegExp('[^0-9.+-]')}
          regexToMatch={new RegExp('^[-+]?[0-9]*\\.?[0-9]*$')}
        />
        {this.renderUnitSelect(this.props, unit)}
      </InputGroupField>
    )
  }

  private renderUnitSelect(props, unit) {
    const { errorsMap, isDisabled, isUnitDisabled, showUnitSelect, size, unitOptions } = this.props
    if (!showUnitSelect) {
      return
    }
    // NOTE: We always default unit to a value. Never show error text for unit
    return (
      <Select
        placeholder='Unit'
        {...props as ISelectProps}
        inputClassName='c-quantityInput-unitTypeSelector'
        errorText={_.get(errorsMap, ['unit', '_errors', 0], null)}
        isDisabled={isDisabled || isUnitDisabled}
        isNative={true}
        onChange={this.handleUnitChange}
        options={unitOptions}
        size={size}
        value={unit}
      />
    )
  }

  private getValue() {
    const { defaultUnit, value } = this.props
    if (value) {
      return value
    }
    return {
      unit: defaultUnit,
    }
  }

  private handleValueChange = (value) => {
    const { onChange } = this.props
    const quantity = this.getValue()
    quantity.value = parseFloat(value)
    if (_.isNil(value) || value === '') {
      onChange(undefined)
    } else {
      onChange(quantity)
    }
  }

  private handleUnitChange = (unit) => {
    const { onChange } = this.props
    const quantity = this.getValue()
    quantity.unit = unit
    onChange(quantity)
  }

}
