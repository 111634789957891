import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { InboxContext } from 'browser/contexts/inbox/inbox-context'
import classNames from 'classnames'
import React, { useContext, useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { Button } from '../../atoms/button/button'
import { Popover } from '../../atoms/popover/popover'
import { TetherTarget } from '../../atoms/tether-target'
import { InboxDropdownItem } from './inbox-dropdown-item'

import './inbox-dropdown.scss'

const PULSE_DURATION = 2100
const TOO_MANY_UNREAD_THRESHOLD = 10
const TOO_MANY_UNREAD_DISPLAY = '9+'

const TETHER_OPTIONS = {
  attachment: 'top right',
  targetAttachment: 'bottom right',
}

export const InboxDropdown = () => {
  const { activities, unread, markAsRead, markAllAsRead, hasMore, fetchMore } =
    useContext(InboxContext)
  const [animationName, setAnimationName] = useState('')
  const tetherRef = useRef<TetherTarget>()
  const hasTooMany = unread >= TOO_MANY_UNREAD_THRESHOLD

  useEffect(() => {
    setAnimationName('pulse')
    const timeoutId = setTimeout(() => {
      setAnimationName('')
    }, PULSE_DURATION)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [activities])

  const close = () => {
    if (tetherRef.current !== null) {
      tetherRef.current.close()
    }
  }

  return (
    <TetherTarget
      tetherOptions={TETHER_OPTIONS}
      ref={tetherRef}
      tethered={
        <Popover className="collapse inbox-dropdown-list-wrapper">
          <div className="inbox-dropdown-list">
            <div className="inbox-dropdown-list__header">
              <h3>Inbox</h3>
              {unread > 0 && (
                <Button className={Classes.MINIMAL} onClick={markAllAsRead}>
                  Mark all as read
                </Button>
              )}
            </div>
            {activities.length === 0 && (
              <p className="inbox-dropdown-list__no-results">You do not have any notifications!</p>
            )}
            {activities.length > 0 && (
              <ul>
                {activities.map((activity, index) => (
                  <InboxDropdownItem
                    entry={activity}
                    markAsRead={markAsRead}
                    close={close}
                    key={index}
                  />
                ))}
                {hasMore && (
                  <li className="inbox-dropdown-list__more">
                    <Button className={Classes.MINIMAL} onClick={fetchMore}>
                      Load more
                    </Button>
                  </li>
                )}
              </ul>
            )}
            <div className="inbox-dropdown-list__footer"></div>
          </div>
        </Popover>
      }
    >
      <div className="c-topNavigationBar-link c-topNavigationBar-link--icon c-topNavigationBarItem inbox-dropdown">
        {unread > 0 && (
          <div
            className={classNames('count-badge', {
              'count-badge--small': hasTooMany,
            })}
            style={{ animationName }}
          >
            {hasTooMany ? TOO_MANY_UNREAD_DISPLAY : unread}
          </div>
        )}
        <Icon icon={IconNames.NOTIFICATIONS} />
      </div>
    </TetherTarget>
  )
}
