import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { browserHistory } from 'browser/history'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { MarkerPopover } from './marker-popover'

const clusterTetherOptions = {
  attachment: 'bottom center',
  offset: { top: -12, left: 0 },
  targetAttachment: 'top center',
}

const defaultTetherOptions = {
  attachment: 'bottom center',
  offset: { top: -3, left: 0 },
  targetAttachment: 'top center',
}

interface IMarkerProps extends IBaseProps {
  cluster: object
  entities: any[]
  map: any
  onExpandCluster: () => void
  onViewportChange: (viewport: any) => void
  selectedEntity: any
  style: object
  viewport: any
}

export class DocumentMarker extends React.Component<IMarkerProps, any> {
  public static height = 65
  public static width = 44

  constructor(props) {
    super(props)
    this.handlePopoverOffsetChanged = this.handlePopoverOffsetChanged.bind(this)
    this.handleDocumentClicked = this.handleDocumentClicked.bind(this)
  }

  public render() {
    const { entities, map } = this.props
    let tethered
    let tetherOptions = defaultTetherOptions
    if (entities.length > 1) {
      tethered = this.renderClusterMarker(entities)
      tetherOptions = clusterTetherOptions
    } else {
      tethered = this.renderSingleMarker(_.first(entities))
    }
    return (
      <TetherTarget
        automaticAdjustOffset={true}
        container={map.getCanvas()}
        onAdjustOffset={this.handlePopoverOffsetChanged}
        tetherOptions={tetherOptions}
        tethered={(
          <MarkerPopover
            entities={entities}
            onClick={this.handleDocumentClicked}
          />
        )}
        theme='map'
      >
        {tethered}
      </TetherTarget>
    )
  }

  private getCommonEmail(entities): string {
    const emails = _.uniq(_.map(entities, 'createdBy.displayName')) as string[]
    return emails.length === 1 ? emails[0] : ''
  }

  private getMarkerClusterIcon(entities) {
    const commonEmail = this.getCommonEmail(entities)
    if (_.isEmpty(commonEmail)) {
      return (
        <div className='c-avatar c-avatar--sm c-avatar--red'>
          <div className='c-avatar-initials'>
            <Icon
              icon={IconNames.PEOPLE}
            />
          </div>
        </div>
      )
    } else {
      return (
        <Avatar
          email={commonEmail}
          size='sm'
        />
      )
    }
  }

  private handleDocumentClicked(entity) {
    browserHistory.push({ pathname: `/entity/${entity.uniqueId}` })
  }

  private handlePopoverOffsetChanged({ offsetX, offsetY }) {
    if (!offsetX && !offsetY) {
      return
    }
    const { map, onViewportChange, viewport } = this.props
    const point = map.project(map.getCenter())
    point.x -= offsetX
    point.y -= offsetY
    const center = map.unproject(point)
    viewport.latitude = center.lat
    viewport.longitude = center.lng
    onViewportChange(viewport)
    return true
  }

  private isClusterSelected(entities) {
    const { selectedEntity } = this.props
    if (!selectedEntity) { return false }
    return !!_.find(entities, { uniqueId: selectedEntity.uniqueId })
  }

  private renderSingleMarker(entity) {
    const postedBy = entity.get('createdBy.displayName', '')
    let isMarkerSelected = false
    const selectedEntity = this.props.selectedEntity
    if (selectedEntity) {
      isMarkerSelected = selectedEntity.uniqueId === entity.uniqueId
    }
    const pinClassName = classNames('markerIcon pin', {
      highlightedMarkerIcon: isMarkerSelected,
    })
    return (
      <div
        className='markerIcon-container'
        style={this.props.style}
      >
        <div className={pinClassName}>
          <Avatar
            name={postedBy}
            size='xs'
          />
        </div>
      </div>
    )
  }

  private renderClusterMarker(entities) {
    const isMarkerSelected = this.isClusterSelected(entities)
    const className = classNames('markerCluster', {
      highlightedMarkerIcon: isMarkerSelected,
    })
    return (
      <div
        className={className}
        style={this.props.style}
      >
        <div className='imageContainerClustered'>
          {this.getMarkerClusterIcon(entities)}
        </div>
        <div className='clusterChildCount'>
          {entities.length}
        </div>
      </div>
    )
  }
}
