import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React, { Fragment } from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { EntityFooter } from 'browser/components/atomic-elements/atoms/footer/entity-footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { CheckboxField } from 'browser/components/atomic-elements/molecules/fields/checkbox-field'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { RecipientsField } from 'browser/components/atomic-elements/molecules/fields/recipients-field'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { EntitySelectField } from 'browser/components/atomic-elements/molecules/fields/select-field/entity-select-field'
import { TextareaField } from 'browser/components/atomic-elements/molecules/fields/textarea-field'

import {
  JSONSchemaResolver,
  RefResolver,
  SchemaResolver,
} from 'shared-libs/resolvers/json-schema-resolver'
import {
  getOptionsFromSchema,
} from 'shared-libs/models/utils'

interface IConditional {
  propertyType?: string
  conditionType?: string
  edgeName?: string
  values?: string
}


/**
 * @uiComponent
 */
interface ICreateEmailTriggerModalProps extends IBaseProps {
  isUpdatingEntity?: boolean
  entity: any
  onClose: () => void
  onSaveEntity: () => void
}

interface ICreateEmailTriggerModalStates {
  condition: any
  conditionType: string
  edgeName: string
  isLoading: boolean
  value: any
  options: any[]
}

const ENTITY_SCHEMA_URI = '/1.0/entities/metadata/entity.json'
const CONDITION_TYPE_OPTIONS = [
  { label: 'Equals', value: 'match' },
  { label: 'Greater Than', value: 'greaterThan' },
  { label: 'Less Than', value: 'lessThan' },
]

export class CreateEmailTriggerModal
  extends React.Component<ICreateEmailTriggerModalProps, ICreateEmailTriggerModalStates> {

  private resolver: RefResolver & SchemaResolver

  public static defaultProps: Partial<ICreateEmailTriggerModalProps> = {
    isUpdatingEntity: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      condition: null,
      conditionType: 'match',
      edgeName: '',
      isLoading: false,
      options: [],
      value: null,
    }
    this.resolver = new JSONSchemaResolver(apis)
  }

  public render() {
    const { entity, isUpdatingEntity, onClose, onSaveEntity } = this.props
    const { condition, options, value, isLoading } = this.state
    let newConditionalFields = (
      // <HelpBlock>
      //   No conditionals in this trigger. <br /> Will be sending email to recipients for all
      // </HelpBlock>
      <div />
    )
    if (!isUpdatingEntity) {
      newConditionalFields = (
        <div>
          <FormTable className='mb.5'>
            <SelectField
              isDisabled={isUpdatingEntity}
              label='Field'
              onChange={this.handleFieldChange}
              options={options}
              optionLabelPath='label'
              optionValuePath='path'
              value={condition}
            />
            {this.renderFieldPath(condition)}
            {this.renderFieldValues()}
          </FormTable>
          <div className='u-flex u-justifyContentSpaceBetween'>
            <div/>
            <Button
              className={classNames('u-bumperTop--xs', Classes.BUTTON)}
              isDisabled={(!condition || _.isNull(value))}
              onClick={this.handleCreateCondition}
            >
              Add Condition
            </Button>
          </div>
        </div>
      )
    }

    const criteriaOptions = this.getTriggerCriteriaOptions()

    return (
      <Fragment>
        <div className='c-modalBody'>
          <FormTable>
            <InputField
              label='Name'
              onChange={this.handleEntityFieldChange('emailTrigger.name')}
              placeholder='Enter a name for this trigger'
              value={entity.emailTrigger.name}
            />
            <TextareaField
              label='Description'
              onChange={this.handleEntityFieldChange('emailTrigger.description')}
              placeholder='Enter a description for this trigger'
              value={entity.emailTrigger.description}
            />
          </FormTable>
          <HelpBlock>
            Text from the Description will be included in the body of the email sent to recipients.
          </HelpBlock>
          <br />
          <FormTable>
            <SelectField
              label='Trigger Mode'
              onChange={this.handleEntityFieldChange('emailTrigger.criteria')}
              options={criteriaOptions}
              optionLabelPath='label'
              optionValuePath='value'
              value={entity.emailTrigger.criteria || criteriaOptions[0]}
            />
            <EntitySelectField
              entityType='/1.0/entities/metadata/entitySchema.json'
              isCreatable={false}
              isDisabled={isUpdatingEntity}
              label={'Document Type'}
              onChange={this.handleDocumentTypeChange}
              showLinkIcon={false}
              value={entity.emailTrigger.documentType}
            />
            <RecipientsField
              label='Recipients'
              placeholder='Enter email addresses'
              onChange={this.handleEntityFieldChange('emailTrigger.recipients')}
              value={entity.emailTrigger.recipients}
            />
          </FormTable>
          <br/>
          <h4> Conditionals </h4>
          <HelpBlock className='mt0'>
            All conditional rules must validate true for email trigger to generate
          </HelpBlock>
          {this.renderSavedConditionals()}
          {newConditionalFields}
        </div>
        <EntityFooter
          isPrimaryButtonLoading={isLoading}
          isPrimaryButtonDisabled={this.handleIsSaveEntityDisabled()}
          onCancelButtonClick={onClose}
          onPrimaryButtonClick={onSaveEntity}
          primaryButtonText='Save'
          entity={entity}
        />
      </Fragment>
    )
  }

  private getTriggerCriteriaOptions = () => {
    return [
      {
        label: "On creation",
        value: "Created",
      },
      {
        label: "On creation, or any edits creating a match to the conditions",
        value: "Transition",
      },
      {
        label: "On creation, and all subsequent edits while conditions match",
        value: "Any Edit",
      },
    ]
  }

  private renderSavedConditionals = () => {
    const { entity } = this.props
    const conditionals: IConditional[] = _.get(entity, 'emailTrigger.conditionals', [])
    if (_.isEmpty(conditionals)) {
      return (
        <div />
      )
    }
    return _.map(conditionals, (conditional) => this.renderConditional(conditional))
  }

  private renderConditional = (conditional: IConditional) => {
    const { isUpdatingEntity } = this.props

    let additionalFields = (
      <div/>
    )
    if (conditional.propertyType === 'number' || conditional.propertyType === 'integer') {
      additionalFields = (
        <SelectField
          isDisabled={true}
          isHorizontalLayout={true}
          label='Condition Type'
          value={{ label: conditional.conditionType, value: '' }}
        />
      )
    }
    return (
      <FormTable className='mb2'>
        <SelectField
          isDisabled={true}
          label='Field'
          onChange={_.noop}
          options={[]}
          optionLabelPath='label'
          optionValuePath='.'
          value={conditional}
        />
        {this.renderFieldPath(conditional)}
        {additionalFields}
        <InputField
          isDisabled={true}
          label='Field Value(s)'
          onChange={_.noop}
          value={conditional.edgeName ? conditional.edgeName : conditional.values}
        />
        <Button
          className={classNames(
            'c-button--square c-abstractListItem-removeButton pr.5',
            Classes.MINIMAL,
            { 'u-hide': isUpdatingEntity }
          )}
          onClick={() => this.handleRemoveConditional(conditional)}
        >
          <Icon
            icon={IconNames.CROSS}
          />
        </Button>
      </FormTable>
    )
  }

  private renderFieldPath = (conditional) => {
    if (!conditional || !conditional.path) {
      return
    }

    return <InputField
      isDisabled={true}
      label='Field Path'
      onChange={_.noop}
      value={conditional.path}
    />
  }

  private renderFieldValues = () => {
    const { condition, conditionType, value } = this.state
    if (!condition) {
      return (
        <InputField
          label='Field Values'
          onChange={(value, handler) => this.setState({ value })}
          value={value}
        />
      )
    }
    let { propertyType } = condition
    if (_.startsWith(propertyType, 'array-')) {
      propertyType = propertyType.split('-')[1]
    }
    if (propertyType === 'boolean') {
      return (
        <CheckboxField
          isHorizontalLayout={true}
          label='Field Value'
          onChange={(value) => this.setState({ value })}
          value={value}
        />
      )
    } else if (propertyType === 'number' || propertyType === 'integer') {
      return (
        <div>
          <SelectField
            isHorizontalLayout={true}
            label='Condition Value'
            options={CONDITION_TYPE_OPTIONS}
            onChange={(value) => this.setState({ conditionType: value })}
            value={conditionType}
          />
          <InputField
            isHorizontalLayout={true}
            label='Field Value'
            onChange={(value) => this.setState({ value })}
            value={value}
          />
        </div>
      )
    } else if (propertyType === 'edge') {
      return (
        <EntitySelectField
          entityType={condition.entityType}
          isCreatable={false}
          label={'Field Value'}
          onChange={(value) => this.setState({ edgeName: value.displayName, value })}
          showLinkIcon={false}
          value={value}
        />
      )
    } else if (propertyType === 'string') {
      // Check to see if there are enums for the string
      if (!_.isEmpty(condition.enum)) {
        return (
          <SelectField
            isHorizontalLayout={true}
            label='Field Values'
            options={condition.enum}
            onChange={(value) => this.setState({ value })}
            value={value}
          />
        )
      }
    }
    return (
      <InputField
        label='Field Values'
        onChange={(value, handler) => this.setState({ value })}
        value={value}
      />
    )
  }

  private handleIsSaveEntityDisabled = () => {
    const { entity } = this.props
    const { condition } = this.state
    const documentType = _.get(entity, 'emailTrigger.documentType', null)
    return !documentType || !_.isNull(condition)
  }

  private handleFieldChange = (placeholder, value) => {
    this.setState({
      condition: value,
      conditionType: 'match',
      edgeName: '',
      value: !_.isNull(value) && value.propertyType === 'boolean' ? false : null,
    })
  }

  private handleEntityFieldChange = (fieldPath) => {
    return (value) => {
      const { entity } = this.props
      _.set(entity, fieldPath, value)
      this.forceUpdate()
    }
  }

  private handleDocumentTypeChange = async (documentType, entitySchema) => {
    const { entity } = this.props
    entity.set('emailTrigger.documentType', documentType)
    let options = []
    try {
      const schemas = await this.resolver.resolveSchema(entitySchema)
      _.forEach(schemas, (schema, key) => {
        if (key !== ENTITY_SCHEMA_URI) {
          options = _.concat(options, getOptionsFromSchema(schema, apis, this.resolver))
        }
      })
    } catch (err) {
      // stick to core properties
    }
    options = _.concat(options, getOptionsFromSchema(entitySchema, apis, this.resolver))
    options.push({
      entityType: '/1.0/entities/metadata/user.json',
      label: 'User',
      path: 'createdBy',
      propertyType: 'edge',
    })
    this.setState({
      condition: null,
      conditionType: 'match',
      edgeName: '',
      options,
      value: null,
    })
  }

  private handleCreateCondition = () => {
    const { condition, conditionType, edgeName, value } = this.state
    const { entity } = this.props
    const conditionals = _.get(entity, 'emailTrigger.conditionals', [])
    conditionals.push({
      conditionType,
      edgeName,
      label: condition.label,
      path: condition.path,
      propertyType: condition.propertyType,
      values: this.processValueFromType(),
    })
    _.set(entity, 'emailTrigger.conditionals', conditionals)
    this.setState({
      condition: null,
      conditionType: 'match',
      value: null,
    })
  }

  private handleRemoveConditional = (conditional) => {
    const { entity } = this.props
    const conditionals  = _.get(entity, 'emailTrigger.conditionals')
    _.remove(conditionals, conditional)
    this.forceUpdate()
  }

  private processValueFromType() {
    const { condition, value } = this.state
    // Dont need to check for boolean and empty value since we
    // set it to false in handleFieldChange when we pick a boolean field
    if (condition.propertyType === 'edge') {
      return String(value.entityId)
    }
    return String(value)
  }
}
