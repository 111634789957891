import _ from 'lodash'
import { insertInOrder } from '../helpers/utils'

const UI_KEY = '_key'
const UI_OPERATION = '_operation'
const UI_OPERATION_DELETE = 'DELETE'
const UI_FORCE_INCLUDE = 'forceInclude'


// NOTE: we currently do not support Array<React.ReactElement<any>> in props
export class UISchemaResolver {

  private addKeysToSchema(uiSchema, layer, id) {
    const type = _.get(uiSchema, 'type', '')
    if (!type.startsWith('ui:')) {
      return
    }
    if (!uiSchema[UI_KEY] && !_.isNil(id)) {
      uiSchema[UI_KEY] = `${layer}.${id}`
    }
    _.forEach(uiSchema, value => {
      if (_.isPlainObject(value)) {
        this.addKeysToSchema(value, layer, null)
      } else if (_.isArray(value)) {
        _.forEach(value, (child, index) => {
          this.addKeysToSchema(child, layer, index)
        })
      }
    })
  }

  private applyPatchForChildren(baseChildren, newChildren) {
    const resultChildren = _.cloneDeep(newChildren)

    // Insert base children (sorting lexicographically by UI_KEY) that are
    // marked with forceInclude, unless already present.
    baseChildren?.forEach((baseChild) => {
      if (baseChild[UI_FORCE_INCLUDE]) {
        const existingChild = _.find(resultChildren, { [UI_KEY]: baseChild[UI_KEY] })
        if (!existingChild) {
          insertInOrder(resultChildren, baseChild, UI_KEY)
        }
      }
    })

    return resultChildren.filter(child => {
      return child[UI_OPERATION] !== UI_OPERATION_DELETE
    }).map(child => {
      const baseChild = _.find(baseChildren, { [UI_KEY]: child[UI_KEY] })
      return baseChild ? this.applyPatch(baseChild, child) : child
    })
  }

  private createPatchForChildren(baseChildren, newChildren) {
    const deletedChildren = this.getDeletedChildren(baseChildren, newChildren)
    const patchChildren = newChildren.map(child => {
      const baseChild: any = _.find(baseChildren, { [UI_KEY]: child[UI_KEY] })
      if (!baseChild) return child
      if (_.isEqual(baseChild, child)) {
        return {
          [UI_KEY]: baseChild[UI_KEY],
          type: baseChild.type
        }
      } else {
        return this.createPatch(baseChild, child)
      }
    })
    return patchChildren.concat(deletedChildren)
  }

  private getDeletedChildren (baseChildren, newChildren) {
    return baseChildren.filter(child => {
      return !_.find(newChildren, { id: child.id })
    }).map(child => {
      return {
        id: child.id,
        type: child.type,
        [UI_OPERATION]: UI_OPERATION_DELETE
      }
    })
  }

  public applyPatch(baseSchema, patch) {
    const result = _.cloneDeep(baseSchema)
    if (_.isEmpty(patch)) {
      return result
    }
    if (baseSchema.type !== patch.type) {
      throw new Error('Mismatch types')
    }
    const keys = _.pull(Object.keys(patch), 'type', 'children')
    _.forEach(keys, key => {
      const baseSchemaValue = baseSchema[key]
      const patchValue = patch[key]
      if (_.isObject(baseSchemaValue) && _.isObject(patchValue)) {
        result[key] = this.applyPatch(baseSchemaValue, patchValue)
      } else {
        result[key] = patchValue
      }
    })
    if (patch.children) {
      result.children = this.applyPatchForChildren(baseSchema.children, patch.children)
    }
    return result
  }

  public createPatch(baseSchema, newSchema) {
    if (baseSchema.type !== newSchema.type) {
      throw new Error('Mismatch types')
    }
    const patch: any = {}
    const baseSchemaKeys = Object.keys(baseSchema)
    const newSchemaKeys = Object.keys(newSchema)
    let keys = _.uniq(baseSchemaKeys.concat(newSchemaKeys))
    keys = _.pull(keys, 'type', 'children')
    _.forEach(keys, key => {
      const baseSchemaValue = baseSchema[key]
      const newSchemaValue = newSchema[key]
      if (_.isEqual(baseSchemaValue, newSchemaValue)) {
        return
      }
      if (_.isObject(baseSchemaValue) && _.isObject(newSchemaValue)) {
        patch[key] = this.createPatch(baseSchemaValue, newSchemaValue)
      } else {
        patch[key] = newSchemaValue
      }
    })
    if (!_.isEqual(baseSchema.children, newSchema.children)) {
      patch.children = this.createPatchForChildren(baseSchema.children, newSchema.children)
    }
    if (!_.isEmpty(patch)) {
      patch.type = baseSchema.type
    }
    return patch
  }
}
