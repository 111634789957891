import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { WithDefaultProps } from 'browser/components/atomic-elements/higher-order-components/with-default-props'
import { JSONInputFactory } from 'browser/components/json-elements/higher-order-components/json-input-factory'

import '../../styles/_control.scss'
import { IInputProps } from 'browser/components/atomic-elements/atoms/input/input'

export const UIInputField = JSONInputFactory(InputField)

export const MobileInputField = WithDefaultProps<IInputProps>(UIInputField, {
  inputClassName: 'mobile-control'
})
