import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Select } from 'browser/components/atomic-elements/atoms/select'
import 'browser/components/atomic-elements/molecules/label-form-group/_editable-label-form-group.scss'

interface IEditableLabelProps extends IBaseProps {
  errorText?: string
  hasError?: boolean
  isCreatable?: boolean
  isDisabled?: boolean
  isStatic?: boolean
  isHorizontalLayout?: boolean
  options?: any[]
  placeholder?: string
  onChange?: (value: string) => void
  size: string
  value: string
  widthClassName?: string
}

export class EditableLabel extends React.Component<IEditableLabelProps, any> {
  public static defaultProps: Partial<IEditableLabelProps> = {
    isCreatable: false,
    isDisabled: false,
    isHorizontalLayout: false,
    isStatic: false,
    widthClassName: '',
  }

  public render() {
    const { className, hasError, widthClassName } = this.props
    // When in horizontal layout mode the label has to be wrapped in a column
    return (
      <div
        className={classNames('c-editableLabel', widthClassName, className, {
          'c-editableLabel--error': hasError,
        })}
      >
        {this.renderSelect()}
      </div>
    )
  }

  private renderSelect() {
    const {
      errorText,
      isDisabled,
      isStatic,
      isHorizontalLayout,
      isCreatable,
      placeholder,
      options,
      onChange,
      size,
      value,
    } = this.props
    const sizeClass = _.isEmpty(size) ? '' : `c-editableLabel-select--${size}`
    const className = classNames('c-editableLabel-select', sizeClass, {
      'c-editableLabel-select--isStatic': isStatic,
      'c-editableLabel-select--isVerticalLayout': !isHorizontalLayout,
      // tslint:disable-next-line:object-literal-sort-keys
      'c-editableLabel-select--isHorizontalLayout': isHorizontalLayout,
    })
    return (
      <Select
        className={className}
        clearable={false}
        errorText={errorText}
        isCreatable={isCreatable}
        isDisabled={isDisabled}
        isEditableLabel={true}
        isNative={true}
        isStatic={isStatic}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        searchable={true}
        size={size}
        value={value}
      />
    )
  }

}
