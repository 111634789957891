import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/organisms/table-action-bar/_table-action-bar.scss'

interface ITableActionBarProps extends IBaseProps {
  actionButtons?: any
  selection: any[]
}

export const TableActionBar: React.FC<ITableActionBarProps> = (props) => {
  const { actionButtons, className } = props
  return (
    <div className={classNames('grid-block shrink', className)}>
      <div className='grid-content pv3 bb b--light-gray'>
        <div className='flex'>
          {actionButtons}
        </div>
      </div>
    </div>
  )
}
