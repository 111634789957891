import React from 'react'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { OnboardingFlow } from 'browser/components/atomic-elements/organisms/onboarding-flow/onboarding-flow'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'

interface IOnboardingModalProps extends IBaseProps {
  onClose: () => void
}

export class OnboardingModal
  extends React.Component<IOnboardingModalProps, any> {

  // c-modal-dialog--lg
  public static open(props?: any) {
    OverlayManager.openOverlayElement(
      <Modal
        modalPaperClassName=''
        modalDialogClassName='c-onboardingModal'
      >
        <OnboardingModal
          {...props}
        />
      </Modal>,
    )
  }

  public render() {
    const { onClose } = this.props
    return (
      <AppNavigatorContext.Consumer>
        {({settings}) => (
          <div className='pt4'>
            <OnboardingFlow
              activeTabId='joinOrganization'
              onClose={onClose}
              size='normal'
              settings={settings}
            />
          </div>
        )}
      </AppNavigatorContext.Consumer>
    )
  }
}
