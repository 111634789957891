import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import classNames from 'classnames'
import { Edge } from 'shared-libs/generated/server-types/entity'
import { HelpBlock } from '../../atoms/help-block/help-block'
import { FirmIds } from 'shared-libs/models/firm'
import { Entity } from 'shared-libs/models/entity'
import { SchemaIds } from 'shared-libs/models/schema'
import { geoDistance, metersToMiles } from 'shared-libs/helpers/formulas/modules'
import { Formatter } from 'shared-libs/helpers/formatter'
import { IconNames } from '@blueprintjs/icons'
import { Icon, Position, Tooltip } from '@blueprintjs/core'
import { Settings } from 'browser/app/models/settings'
import { Event } from 'shared-libs/models/types/storyboard/storyboard-execution-model'
import { Address } from 'shared-libs/generated/server-types/entity/locationEntity'

export function renderAuthorHeader(author: Edge, firm: Edge | undefined): string {
  if (!firm || firm.entityId === FirmIds.VECTOR) {
    // exclude Vector Firm (e.g. for System User)
    return author.displayName
  } else {
    return `${author.displayName} - ${firm.displayName}`
  }
}

const PROCESSED_DATE_WARNING_THRESHOLD = 10 // seconds

export function renderEventTimestamp(
  creationDate: string,
  processedDate?: string,
  settings?: Settings
) {
  const startDate = moment(creationDate)
  const endDate = moment(processedDate)
  const formattedStartDate = startDate.format('LLL')
  const detailedStartDate = startDate.format('LL h:mm:ss.SSS A')
  const detailedEndDate = endDate.format('LL h:mm:ss.SSS A')

  const exceedsThreshold = endDate.diff(startDate, 'seconds', true) > PROCESSED_DATE_WARNING_THRESHOLD
  const shouldWarn = exceedsThreshold && processedDate && settings?.isAdmin
  const warningClassname = classNames({
    'c-processedDate--exceedsThreshold': shouldWarn,
  })

  const displayString = shouldWarn ? (
    <strong className="c-processedDate--exceedsThreshold">{formattedStartDate}</strong>
  ) : (
    formattedStartDate
  )

  const tooltip = !processedDate ? null : (
    <HelpBlock className="c-timelineEvent-timestamp tr">
      <p>Creation Date: {detailedStartDate}</p>
      <p className={warningClassname}>Processed Date: {detailedEndDate}</p>
      <p className={warningClassname}>Δ {formatTimeDelta(endDate, startDate)}</p>
    </HelpBlock>
  )

  return (
    <Tooltip
      disabled={!settings || !settings.isAdmin}
      content={tooltip}
      position={Position.TOP}
    >
      {displayString}
    </Tooltip>
  )
}

// TODO: could use a conversion table w/ numeric values or functions
const units = ['d', 'h', 'm', 's', 'ms']
const multipliers = [24, 60, 60, 1000, 1]

function formatTimeDelta(end: moment.Moment, start: moment.Moment): string {
  let diff = end.diff(start, 'days', true)

  const parts = _.transform(
    units,
    (acc, unit, idx) => {
      const value = diff | 0
      if (value) {
        acc.push(`${value}${unit}`)
      }
      diff -= value
      diff *= multipliers[idx]
    },
    []
  )

  return _.join(parts, ' ') || '0ms'
}

interface FacilityDetails {
  displayName: string
  address: Address
}

export function renderDistance(
  event: any,
  workflowEntity: Entity,
  unit: 'miles' | 'kilometers' = 'miles',
  settings: Settings,
  facilityPathOverride?: string
): JSX.Element {
  const { createdAt: driverLocation } = event
  if (!driverLocation) {
    return null
  }

  const facilityDetails = getFacilityDetails(workflowEntity, facilityPathOverride)
  if (!facilityDetails) {
    return null
  }

  const fromFacility = facilityDetails.displayName
    ? ` from ${facilityDetails.displayName}`
    : ''

  const distanceMeters = geoDistance(driverLocation, facilityDetails.address)
  const displayString =
    unit === 'kilometers'
      ? `${formatDistance(distanceMeters / 1000, 'km')} away`
      : `${formatDistance(metersToMiles(distanceMeters), 'mi')} away`

  const showGpsAsDegrees = settings.getSettingsProperty('generalSettings', 'showGpsAsDegrees')
  const tooltip = (
    <>
      <Icon icon={IconNames.MAP_MARKER} /> {Formatter.formatAddress(driverLocation, showGpsAsDegrees)}
    </>
  )
  return (
    <Tooltip content={tooltip} position={Position.TOP}>
      <a
        href={Formatter.formatGeolocationAsGoogleMapUrl(driverLocation.geolocation)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {displayString}{fromFacility}
      </a>
    </Tooltip>
  )
}

const DEFAULT_APPOINTMENT_FACILITY_EDGE_PATH = "core_yms_execution.facility"

function getFacilityDetails(
  workflowEntity: Entity,
  overridePathToFacilityEdge: string
): FacilityDetails | undefined {
  // Try to get the actual facility entity via the facility edge, if available
  const facilityId = getFacilityId(workflowEntity)
  const facility = workflowEntity.getStore().getRecord(facilityId)

  // Check override or default path
  const edgePaths = _.compact([overridePathToFacilityEdge, DEFAULT_APPOINTMENT_FACILITY_EDGE_PATH])
  const facilityEdge = edgePaths.map((path) => workflowEntity.get(path)).find(Boolean)

  // Get address from facility edge or entity
  const address =
    facilityEdge?.denormalizedProperties?.['location.address'] || _.get(facility, 'location.address')
  const displayName = facilityEdge?.displayName || facility?.displayName

  if (!address) {
    return undefined
  }

  return {
    displayName,
    address,
  }
}

export function renderMapLink(createdAt: any, settings: Settings) {
  const showGpsAsDegrees = settings.getSettingsProperty('generalSettings', 'showGpsAsDegrees')
  const address = Formatter.formatAddress(createdAt, showGpsAsDegrees)
  const mapUrl = Formatter.formatGeolocationAsGoogleMapUrl(createdAt.geolocation)
  return (
    <Tooltip content={address} position={Position.TOP}>
      <a href={mapUrl} target="_blank" rel="noopener noreferrer">
        <Icon icon={IconNames.MAP_MARKER} />
      </a>
    </Tooltip>
  )
}

function formatDistance(value: number, unit = '', precision = 2) {
  const fixedPoint = value.toFixed(precision)
  const trimmedZeroes = fixedPoint.replace(/\.?0+$/, '')
  return `${trimmedZeroes} ${unit}`
}

/**
 * Get a facility id from a workflow execution entity.
 */
export function getFacilityId(execution: Entity): string | undefined {
  if (execution?.hasMixin(SchemaIds.STORYBOARD_YMS_EXECUTION)) {
    return execution.get(`${DEFAULT_APPOINTMENT_FACILITY_EDGE_PATH}.entityId`)
  }

  // NOTE: this is supported temporarily while THD is pre-YMS (roughly as of
  // 4/2023). When no workflow plans have their own custom facility edge, this
  // can be removed.
  const HOMEDEPOT_EXECUTION_ID = '966897c6-4206-429a-8424-4606a0a83eb1'
  if (execution?.hasMixin(HOMEDEPOT_EXECUTION_ID)) {
    return execution.get('homeDepotExecution.facilityEdge.entityId')
  }
}

export function renderDeviceInfoTooltipContent(deviceInfo: Event['deviceInfo']) {
  if (!deviceInfo) {
    return null
  }

  const { platform, name, locale, timezoneId, connectionType, workflowVersion } = deviceInfo
  const platformName = _.startCase(_.toLower(platform))
  const isMobileDevice = platform === 'mobile' || platform === 'mobile web'
  const isMobileApp = platform === 'mobile'
  const iconName = isMobileDevice ? IconNames.MOBILE_PHONE : IconNames.DESKTOP
  const appVersion = isMobileApp ? `Vector Mobile ${deviceInfo.appVersion}` : deviceInfo.appVersion

  return (
    <div>
      <div>
        <Icon icon={iconName} /> {platformName}
      </div>
      {renderLabeledDeviceProperty('App Version', appVersion)}
      {renderLabeledDeviceProperty('Workflow Version', workflowVersion)}
      {renderLabeledDeviceProperty('Device Name', name)}
      {renderLabeledDeviceProperty('Locale', locale)}
      {renderLabeledDeviceProperty('Timezone', timezoneId)}
      {renderLabeledDeviceProperty('Connection Type', connectionType)}
      {deviceInfo.mobile && renderMobileDeviceInfoDetails(deviceInfo)}
    </div>
  )
}

function renderMobileDeviceInfoDetails(deviceInfo: Event['deviceInfo']) {
  const { osVersion, isTablet, screenOrientation, screenDensity, screenWidth, screenHeight } =
    deviceInfo.mobile
  const width = (screenWidth * screenDensity) | 0
  const height = (screenHeight * screenDensity) | 0

  return (
    <>
      {renderLabeledDeviceProperty('OS', osVersion)}
      {renderLabeledDeviceProperty('Tablet?', isTablet ? 'Yes' : 'No')}
      {renderLabeledDeviceProperty('Orientation', screenOrientation)}
      {renderLabeledDeviceProperty('Pixel Density', screenDensity)}
      {renderLabeledDeviceProperty('Dimensions', `${width} x ${height}`)}
    </>
  )
}

function renderLabeledDeviceProperty(label: string, value: any) {
  return (
    <div>
      <strong>{label}:</strong> {value}
    </div>
  )
}
