import _ from 'lodash'
import React from 'react'
import Supercluster from 'supercluster'
import { WebMercatorViewport } from 'viewport-mercator-project'

import { Entity } from 'shared-libs/models/entity'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

export interface IMarkerClusterLayerProps extends IBaseProps {
  map?: object
  entities: Entity[]
  selectedEntity: object
  geolocationPath: string
  MarkerComponent: any
  markersHeight: number
  markersWidth: number
  onMouseOver?: () => void
  onMouseOut?: () => void
  onSelect?: (values: any[]) => void
  onViewportChange: (viewport: any) => void
  width?: number
  height?: number
  viewport: any
}

interface IMarkerClusterLayerState {
  clusters: any
  clusterIndex: any
}

export class MarkerClusterLayer extends React.Component<IMarkerClusterLayerProps, IMarkerClusterLayerState> {
  public static defaultProps: Partial<IMarkerClusterLayerProps> = {
    onSelect: _.noop,
  }

  constructor(props) {
    super(props)
    this.state = {
      clusterIndex: null,
      clusters: null,
    }
  }

  public componentDidMount() {
    // some props that are injected. E.g. map might not be ready until mounted
    const clusterIndex = this.makeClusterIndex(this.props)
    const clusters = this.makeClusters(this.props, clusterIndex)
    this.setState({ clusters, clusterIndex })
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    let { clusters, clusterIndex } = this.state
    if (this.props.entities !== nextProps.entities) {
      clusterIndex = this.makeClusterIndex(nextProps)
    }
    clusters = this.makeClusters(nextProps, clusterIndex)
    this.setState({ clusters, clusterIndex })
  }

  public makeClusters(props, clusterIndex) {
    const { map, markersHeight, viewport } = props
    if (!map) {
      // cant process markers if we arent passed the map
      return null
    }
    const bounds = map.getBounds()
    // IMPORTANT: extend south by markersHeight so that marker doesn't disappare
    // when bottom of the marker hits the edge of the map
    const southEastPoint = map.project(bounds.getSouthEast())
    southEastPoint.y += markersHeight
    const southEastCoord = map.unproject(southEastPoint)
    return clusterIndex.getClusters([
      bounds.getWest(), southEastCoord.lat,
      southEastCoord.lng, bounds.getNorth()],
    Math.round(viewport.zoom))
  }

  public makeClusterIndex(props) {
    const { entities, geolocationPath } = props
    const features = this.makeGeojsonFeatures(entities, geolocationPath)
    return new Supercluster({
      extent: 256,
      maxZoom: 17,
      radius: 60,
    }).load(features)
  }

  public makeGeojsonFeatures(entities, geolocationPath) {
    return _.map(entities, (entity: Entity) => {
      const geolocation = entity.get(geolocationPath)
      const latitude = _.get(geolocation, 'latitude')
      const longitude = _.get(geolocation, 'longitude')
      return {
        geometry: {
          coordinates: [longitude, latitude],
          type: 'Point',
        },
        properties: {
          entity,
        },
        type: 'Feature',
      }
    })
  }

  public handleExpandCluster = (cluster) => {
    const { clusterIndex } = this.state
    const { onViewportChange, viewport } = this.props
    const zoom = Math.round(viewport.zoom)
    viewport.longitude = cluster.geometry.coordinates[0]
    viewport.latitude = cluster.geometry.coordinates[1]
    viewport.zoom = clusterIndex.getClusterExpansionZoom(
      cluster.properties.cluster_id, zoom)
    onViewportChange(viewport)
  }

  public renderMarker(cluster, point) {
    const { clusterIndex } = this.state
    const { map, markersHeight, MarkerComponent, markersWidth, onViewportChange,
      onMouseOver, onMouseOut, onSelect, selectedEntity, viewport } = this.props
    const zoom = Math.round(viewport.zoom)
    let entities
    if (cluster.properties.cluster) {
      const clusterId = cluster.properties.cluster_id
      entities = clusterIndex.getLeaves(clusterId, Infinity, 0)
      entities = _.map(entities, 'properties.entity')
    } else {
      entities = [cluster.properties.entity]
    }
    const firstEntity = _.first(entities) as any
    if (!firstEntity) {
      return
    }
    const key = firstEntity.uniqueId
    return (
      <MarkerComponent
        cluster={cluster}
        entities={entities}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={(entity) => onSelect([entity])}
        key={key}
        map={map}
        onExpandCluster={() => this.handleExpandCluster(cluster)}
        selectedEntity={selectedEntity}
        style={{
          left: point[0] - markersWidth / 2,
          position: 'absolute',
          top: point[1] - markersHeight,
        }}
        onViewportChange={onViewportChange}
        viewport={viewport}
      />
    )
  }

  public redraw({ width, height, project, unproject }) {
    const { clusters } = this.state
    if (!clusters) {
      return
    }
    return _.map(clusters, (cluster) => {
      const coordinates = (cluster as any).geometry.coordinates
      const point = project(coordinates)
      return this.renderMarker(cluster, point)
    })
  }

  public render() {
    const { width, height, style, viewport } = this.props
    const divStyle: React.CSSProperties = {
      left: 0,
      position: 'absolute',
      top: 0,
      ...style,
    }
    const { project, unproject } = new WebMercatorViewport({
      height,
      latitude: viewport.latitude,
      longitude: viewport.longitude,
      width,
      zoom: viewport.zoom,
    })
    return (
      <div
        ref='overlay'
        style={divStyle}
      >
        {this.redraw({ width, height, project, unproject })}
      </div>
    )
  }
}
