import { EventEmitter } from 'fbemitter'
import _ from "lodash"
import { v4 as uuidv4 } from 'uuid'
import Promise from 'bluebird'
import { JobStatus } from 'browser/mobile/components/upload-progress'
import * as Sentry from '@sentry/react'

// Injected by Webpack
declare let __GITHASH__: string

export const uploadSuccess = 'HTTP_UPLOAD_SUCCESS'
export const uploadFailed = 'HTTP_UPLOAD_FAILED'
export const uploadProgress = 'HTTP_UPLOAD_PROGRESS'
export const APPLICATION_JSON = 'application/json'

export default class UploadWorker extends EventEmitter {
    private promises: any = {}
    private static WORKER_PATH = `/file-upload-worker.js?version=${__GITHASH__}`
    private worker: Worker
    private apiToken?: string

    constructor() {
        super()
        this.initWorker()
    }

    private initWorker() {
        this.worker = new Worker(UploadWorker.WORKER_PATH)
        this.worker.onmessage = ((ev) => {
          const message = ev.data
          const taskId = message.id
          const entityId = this.promises[taskId].entityId
          if (message.complete) {
            let result
            try {
              if (message.contentType === APPLICATION_JSON) {
                result = JSON.parse(message.result)
              } else {
                result = message.result
              }
            } catch(e) {
              // HTTP status 204 indicates no response was expected
              if (message.status !== 204) {
                console.error(`Failed to parse server response: ${e.message}`)
                message.jsonError = true
              }
              result = message.result
            }
            if (!_.isNil(message.error) || message.jsonError || (result.statusCode > 299 && result.statusCode != 410)) {
              // preserve jquery error API `error.responseJSON`
              if (_.isObject(result)) {
                result.responseJSON = result
              }

              const reject = this.promises[taskId].reject
              if (reject === undefined) {
                const logMessage = `No reject method for task ${taskId}, web app may become stuck`
                console.error(logMessage)
                Sentry.captureMessage(logMessage, {
                  level: "warning",
                  extra: {
                    ...message.metadata,
                    contentType: message.contentType,
                    progress: message.progress,
                    status: message.status,
                    error: message.error,
                    id: taskId,
                  }
                })
              }
              this.promises[taskId].status = JobStatus.Failed
              delete this.promises[taskId]?.resolve
              delete this.promises[taskId]?.reject
              delete this.promises[taskId]?.progressCallback
              reject?.(result)
              this.emit(uploadFailed, {
                entityId,
                taskId,
                taskTag: this.promises[taskId].jobTag,
                progress: message.progress,
                error: message.error || message.jsonError,
                status: JobStatus.Failed
              })
            } else {
              const resolve = this.promises[taskId].resolve
              if (resolve === undefined) {
                const logMessage = `No resolve method for task ${taskId}, web app may become stuck`
                console.error(logMessage)
                Sentry.captureMessage(logMessage, {
                  level: "warning",
                  extra: {
                    ...message.metadata,
                    contentType: message.contentType,
                    progress: message.progress,
                    status: message.status,
                    error: message.error,
                    id: taskId,
                  }
                })
              }
              this.promises[taskId].status = JobStatus.Succeeded
              delete this.promises[taskId]?.resolve
              delete this.promises[taskId]?.reject
              delete this.promises[taskId]?.progressCallback
              resolve?.(result)
              this.emit(uploadSuccess, {
                entityId,
                taskId,
                taskTag: this.promises[taskId].jobTag,
                progress: message.progress,
                status: JobStatus.Succeeded
              })
            }
          } else if (message.progress) {
            this.promises[taskId]?.onProgress?.(message.progress)
            this.promises[taskId].progress = message.progress
            this.emit(uploadProgress, {
              entityId,
              taskId,
              taskTag: this.promises[taskId].jobTag,
              progress: message.progress,
            })
          } else if (message.started) {
            this.promises[taskId].status = JobStatus.Running
          }
        })
      }

      public setupAuthorization(apiToken: string) {
        this.apiToken = apiToken
      }

      public doApiSend(payload: any, progressCallback: (progress: number) => void) {
        const id = payload.saveTaskId || uuidv4()

        this.promises[id] ||= {}
        this.promises[id].promise = new Promise((resolve, reject, onCancel) => {
          this.promises[id].resolve = resolve
          this.promises[id].reject = reject
          this.promises[id].onProgress = progressCallback
          this.promises[id].progress = 0
          this.promises[id].status = JobStatus.Enqueued
          this.promises[id].startTime = Date.now()

          onCancel(() => {
            if (this.promises[id]) {
                this.promises[id].status = JobStatus.Cancelled
            }
          })

          let data = payload.data
          if (payload.data.constructor?.name === 'FormData') {
            data = { '__isFormData__': true }
            if (payload.method === 'PATCH') {
              const urlMatch = payload.url.match(/\/entities\/records\/([0-9a-f-]*)/)
              const entityId = urlMatch?.[1]
              this.promises[id].entityId = entityId
              const jobTag = payload.jobTag || entityId
              this.promises[id].jobTag = jobTag
              if (_.isEmpty(payload.jobTag)) delete payload.jobTag
              payload = { entityId, jobTag, ...payload }
            } else {
              const entityData = payload.data.get('entity')
              if (entityData) {
                try {
                  const entity = JSON.parse(entityData)
                  const entityId = entity['uniqueId']
                  const jobTag = payload['jobTag'] || entity['jobTag'] || entityId
                  this.promises[id].entityId = entityId
                  this.promises[id].jobTag = jobTag
                  if (_.isEmpty(payload.jobTag)) delete payload.jobTag
                  payload = { entityId, jobTag, ...payload }
                } catch(err) {
                  console.error(`error ${err.message} parsing ${entityData}`)
                }
              }
            }
            try {
              data.data = Array.from(payload.data.entries())
            } catch(e) {
              console.log(`encoding formdata: ${e}`)
            }
          }
          const options = { ...payload, authToken: this.apiToken, data, id }
          this.worker.postMessage(options)
        })
        .finally(() => {
          if (_.isNil(this.promises[id].status)) {
            const entityId = this.promises[id].entityId
            this.promises[id] = {entityId, status: JobStatus.Cancelled}
          }
        })
        return this.promises[id]?.promise
      }

      // Adds the job to this.promises so we can get all the jobs
      // from a single source. Returns the assigned taskId.
      public addPendingJob(entity: any, promise: any) : string {
        const entityId = entity.uniqueId
        const jobTag = entity.jobTag || entityId
        const taskId = uuidv4()
        this.promises[taskId] = { status: JobStatus.Pending, entityId, jobTag, promise }
        return taskId
      }

      public cancelPendingJob(jobId: string) {
        if (this.promises[jobId]?.status === JobStatus.Pending) {
            this.promises[jobId].status = JobStatus.Cancelled
        }
      }

      public pendingJobsForEntityId(entityId?: string): any[] {
        const activeStatuses = [JobStatus.Pending, JobStatus.Enqueued, JobStatus.Running]
        return _.filter(this.promises, (promise) => {
            return !_.isNil(promise.jobTag) &&
              (_.isNil(entityId) || promise.jobTag === entityId) &&
              _.indexOf(activeStatuses, promise.status) != -1
        })
      }

      public totalJobsForEntityId(entityId?: string): any[] {
        return _.filter(this.promises, (promise) => {
            return !_.isNil(promise.jobTag) &&
              (_.isNil(entityId) || promise.jobTag === entityId)
        })
      }
}
