import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import {
  IFocusableInput,
  InlineEditableInputProps,
} from 'browser/components/atomic-elements/molecules/inline-editable'
import { IListItemSelectionOptions } from 'browser/components/atomic-elements/atoms/list/abstract-list'

import { Column } from '..'

export interface IHtmlTableRowProps extends IBaseProps, InlineEditableInputProps {
  columns?: Column[]
  density?: string
  entity?: any
  index: number
  onClick?: (item: any, index: number) => void
  onRemove?: () => void
  renderControls?: () => void
  showItemDeleteButton?: boolean
  size?: string
  isSelectable?: boolean
  selectionOptions?: IListItemSelectionOptions
}

export class HtmlTableRow
  extends React.Component<IHtmlTableRowProps, any>
  implements IFocusableInput {
  public static defaultProps: Partial<IHtmlTableRowProps> = {
    showItemDeleteButton: true,
  }

  public focus() {
    // TODO(Peter)
  }

  public render() {
    const {
      className,
      children,
      density,
      isSelectable,
      selectionOptions,
      entity,
      value,
    } = this.props
    const densityClassName = _.isEmpty(density) ? '' : `c-table-row--${density}`
    const isSelected =
      isSelectable && selectionOptions && value === entity.get(selectionOptions.value)
    return (
      <tr
        className={classNames('c-table-row', densityClassName, className, {
          'c-table-row--isSelectable': isSelectable,
          'c-table-row--selected': isSelected,
        })}
        onClick={this.handleOnClick}
      >
        {React.Children.map(children, this.renderCell)}
        {this.renderControls()}
      </tr>
    )
  }

  private handleOnClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.value, this.props.index)
    }
  }

  private renderControls() {
    const {
      inlineEditControls,
      isDisabled,
      density,
      onRemove,
      renderControls,
      showItemDeleteButton,
      size,
    } = this.props
    const densityClassName = _.isEmpty(density) ? '' : `c-table-cell--${density}`
    const sizeClassName = _.isEmpty(size) ? '' : `c-table-cell--${size}`
    if (inlineEditControls) {
      return (
        <td className={classNames('c-table-cell', sizeClassName, densityClassName)}>
          {inlineEditControls}
        </td>
      )
    }
    if (!showItemDeleteButton || !onRemove) {
      return
    }
    if (renderControls) {
      return renderControls()
    }

    // TODO(louis) This is odd
    // if (showItemDeleteButton && onRemove) {
    //   isDisabled = false
    // }
    return (
      <td
        className={classNames('c-table-cell c-table-cell--delete', sizeClassName, densityClassName)}
      >
        <Button
          className={classNames('c-button--square c-table-cellDeleteButton', Classes.MINIMAL)}
          isDisabled={isDisabled}
          size={size}
          onClick={onRemove}
        >
          <Icon icon={IconNames.CROSS} />
        </Button>
      </td>
    )
  }

  private renderCell = (cell, index) => {
    const { columns, density, size, isStatic, isSelectable } = this.props
    const column = columns[index]
    const densityClassName = _.isEmpty(density) ? '' : `c-table-cell--${density}`
    const sizeClassName = _.isEmpty(size) ? '' : `c-table-cell--${size}`
    const { colSpan, rowSpan, cellClassName } = cell.props
    const cellStyle = {
      flexGrow: column.flexGrow || 0,
      textAlign: column.textAlign,
      width: column.width,
    }
    return (
      <td
        className={classNames('c-table-cell', densityClassName, sizeClassName, cellClassName)}
        colSpan={colSpan}
        rowSpan={rowSpan}
        data-label={column.label}
        key={index}
        style={cellStyle}
      >
        {React.cloneElement(cell, { isStatic, isSelectable })}
      </td>
    )
  }
}
