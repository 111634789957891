import _ from 'lodash'
import React from 'react'
import { conformToMask } from 'vanilla-text-mask';

import { IInputProps } from 'browser/components/atomic-elements/atoms/input/input'
import { TextMaskInput } from 'browser/components/atomic-elements/atoms/input/text-mask-input'
import { createNumberMask } from './text-mask-masks'

export class CurrencyInput extends React.Component<IInputProps, any> {

  private input: TextMaskInput
  private currencyMask: any

  constructor(props: IInputProps) {
    super(props)
    this.currencyMask = createNumberMask({
      allowDecimal: true,
      allowNegative: true,
    })
  }

  public focus() {
    this.input.focus()
  }

  public render() {
    const modelValue = this.props.value;
    const value = _.isEmpty(modelValue)
      ? modelValue
      : conformToMask(modelValue, this.currencyMask).conformedValue;

    return (
      <TextMaskInput
        {...this.props}
        value={value}
        guide={false}
        keepCharPositions={false}
        mask={this.currencyMask}
        onChange={this.handleChange}
        ref={(ref) => { this.input = ref }}
      />
    )
  }

  private handleChange = (value) => {
    const { onChange } = this.props
    if (!_.isNil(value)) {
      value = value.replace(/[$,]/g, '')
    }
    onChange(value)
  }
}
