import { Icon, Intent, Tab, Tabs } from '@blueprintjs/core'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useContext, useState } from 'react'

import {
  INVOICE_PATH, TMW_SALES_INVOICE_ID, CONSOLIDATED_SALES_INVOICE_ID,
  CONSOLIDATED_SALES_INVOICE_PATH,
  RB_V1_CONFIG, RB_V2_CONFIG
} from 'browser/app/constants/rendition-billing'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import ComponentsMap from 'browser/components'
import { Card } from 'browser/components/atomic-elements/atoms/card/card'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { useFetchCustomer } from 'browser/components/hooks/useFetchCustomer'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'
import { IEntityDetailCardProps } from '../entity-detail-card/entity-detail-card'
import { RbSecondaryCardHeader } from './rb-secondary-card-header'
import { ConfirmationModal } from '../../confirmation-modal'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { IconNames } from '@blueprintjs/icons'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'

const INVOICE_DETAIL_TAB_ID = 'invoiceEntity'
const ACTIVITY_TAB_ID = 'activity'
const ORDER_DETAIL_TAB_ID = 'orderDetail'
const CHILD_INVOICES_TAB_ID = 'childInvoicesDetail'
const SOUCE_INFO_TAB_ID = 'sourceInfo'

/**
 * Entity Detail card to display the details of an Invoice Entity (Read Only)
 *
 * @props IEntityDetailCardProps
 */
export const RbDetailCard: React.FC<IEntityDetailCardProps> = ({ className, entity, header, isFullScreen, onClose }) => {
  // If/when Rendition Billing V1 is deprecated, simply remove RB_V1_CONFIG and refactor RB_V2_CONFIG constants
  const entitySchemaConfig = _.get(entity, INVOICE_PATH, null) ? RB_V2_CONFIG : RB_V1_CONFIG
  const { invoiceOptions, INVOICE_CUSTOMER_PATH, INVOICE_NUMBER_PATH, INVOICE_STATUS_PATH } = entitySchemaConfig
  const { settings } = useContext(AppNavigatorContext)
  const [activeTab, setActiveTab] = useState<string>('invoiceEntity')
  const [forceUpdate, setForceUpdate] = useState<number>(0)
  const customerId = _.get(entity, INVOICE_CUSTOMER_PATH, null)
  const [customer, isLoading] = useFetchCustomer(customerId)
  const invoiceNumber = _.get(entity, INVOICE_NUMBER_PATH)
  const emailable = _.get(entity, 'emailable', {})
  const emailStatus = _.get(emailable, 'status', null)
  const isTmwSalesInvoice = _.find(_.get(entity, 'mixins.active'), { entityId: TMW_SALES_INVOICE_ID } )
  const isConsolidatedSalesInvoice = _.find(_.get(entity, 'mixins.active'), { entityId: CONSOLIDATED_SALES_INVOICE_ID } )

  const handleDelete = () => {
    const deleteInvoice = () => {
      entity.delete()
        .then(() => onClose && onClose())
        .then (() => {
          Toast.show({
            message: 'Invoice deleted',
            position: Position.BOTTOM_RIGHT,
            timeout: 5000,
          })
        })
        .catch(() => {
          Toast.show({
            message: 'Failed to delete invoice',
            position: Position.BOTTOM_RIGHT,
            timeout: 5000,
          })
        })
    }
    ConfirmationModal.open({
      confirmationText: 'Do you want to delete this invoice?',
      confirmationTitle: 'Confirm Delete',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: deleteInvoice,
      primaryButtonText: 'Confirm',
    })
  }

  const handlePrimaryTabChange = (tabId) => { setActiveTab(tabId) }

  // TODO(David): Ghetto forceupdate, find a way to do it properly
  const handleForceUpdate = () => { setForceUpdate(forceUpdate + 1) }

  const handleInvoiceStatusChange = (value) => {
    _.set(entity.prevContent, INVOICE_STATUS_PATH, value)
    entity.set(INVOICE_STATUS_PATH, value)
    return entity.savePrevContent()
  }

  const renderHeader = () => {
    const invoiceStatus = _.get(entity, INVOICE_STATUS_PATH)
    return React.cloneElement(header, {
      children: (
        <RbSecondaryCardHeader
          emailStatus={emailStatus}
          invoiceNumber={invoiceNumber}
          invoiceStatus={invoiceStatus}
          invoiceOptions={invoiceOptions}
          onInvoiceStatusUpdate={handleInvoiceStatusChange}
        />
      ),
      isFullScreen: false,
      onClose,
      detailActionElement: <>
        <CardHeaderItem>
          <Button
            onClick={handleDelete}
            intent={Intent.DANGER}
            data-debug-id="delete-invoice"
          >
            <Icon icon={IconNames.TRASH} title="Delete" />
          </Button>
        </CardHeaderItem>
      </>
        
    })
  }

  const renderUiSchema = (uiSchemaName) => {
    const schemas = entity.schemas
    const uiSchemaPath = `uiSchema.web.${uiSchemaName}`
    for (let i = schemas.length - 1; i >= 0; --i) {
      if (!_.get(schemas[i], uiSchemaPath)) {
        continue
      }
      return (
        <EntityRenderer
          actions={this}
          componentsMap={ComponentsMap}
          context={{
            isEditableInline: false,
            isStatic: false,
          }}
          errors={null}
          state={{ settings, customer }}
          onChangeComplete={handleForceUpdate}
          value={entity}
          uiContext={schemas[i]}
          uiSchemaPath={uiSchemaPath}
        />
      )
    }
  }

  const invoiceTabTitle = () => {
    const invoices = _.get(entity, [CONSOLIDATED_SALES_INVOICE_PATH, 'invoices'], [])

    return _.isEmpty(invoices) ? 'Invoices' : `Invoices (${_.size(invoices)})`
  }

  const orderTabTitle = () => {
    const orders = _.get(entity, [CONSOLIDATED_SALES_INVOICE_PATH, 'salesOrders'], [])

    return _.isEmpty(orders) ? 'Orders' : `Orders (${_.size(orders)})`
  }

  if (isLoading) {
    return (
      <LoadingSpinner />
    )
  }

  return (
    <Card
      data-debug-id='invoiceDetailCard'
      className={classNames('grid-block c-entityDetailCard c-orderCard u-noPrint', className)}
    >
      <Head title={`Rendition Billing - ${entity.displayName}`} />
      <div className='grid-block vertical'>
        {renderHeader()}
        <div className='grid-block'>
          <div className='grid-block vertical'>
            <div className='grid-block vertical u-overflowHidden'>
              <Tabs
                className='grid-block vertical c-tabs'
                id='secondaryOrderCard'
                onChange={handlePrimaryTabChange}
                renderActiveTabPanelOnly={true}
                selectedTabId={activeTab}
              >
                <Tab
                  className='grid-block'
                  id={INVOICE_DETAIL_TAB_ID}
                  title='Details'
                  panel={renderUiSchema('components.detailsTab')}
                />
                <Tab
                  className='grid-block'
                  id={ACTIVITY_TAB_ID}
                  title='Activity'
                  panel={renderUiSchema('components.activityTab')}
                />
               {!_.isNil(isConsolidatedSalesInvoice) && (
                  <Tab
                    className='grid-block'
                    id={CHILD_INVOICES_TAB_ID}
                    title={invoiceTabTitle()}
                    panel={renderUiSchema('components.salesInvoiceChildTab')}
                  />
                )}

                <Tab
                  className='grid-block'
                  id={ORDER_DETAIL_TAB_ID}
                  title={orderTabTitle()}
                  panel={renderUiSchema('components.orderTab')}
                />
                <Tabs.Expander />
                {!_.isNil(isTmwSalesInvoice) && (
                  <Tab
                    className='grid-block'
                    id={SOUCE_INFO_TAB_ID}
                    title='Source Details'
                    panel={(
                      <div className='grid-content pt3'>
                        {renderUiSchema('components.sourceDetailsTab')}
                      </div>
                    )}
                  />
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
