import _ from 'lodash'
import React, { useState } from 'react'
import classNames from 'classnames'
import SplitPane from 'react-split-pane'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import {
  DocumentImagingEditor
} from 'browser/components/atomic-elements/organisms/document-imaging-editor/document-imaging-editor'
import {
  DocumentImagingTemplateDetailPanel
} from 'browser/components/atomic-elements/organisms/document-imaging-template-detail-panel/document-imaging-template-detail-panel'
import apis from 'browser/app/models/apis'

/**
 * @uiComponent
 */
interface IDocumentImagingTemplateEditorProps extends IBaseProps {
  entity: any
}

const TEMPLATE_NAMESPACE = 'textExtractTemplate'
const TEMPLATE_IMAGE_PATH = `${TEMPLATE_NAMESPACE}.templateFile.files`
const TEMPLATE_DATA_MAPPING_PATH = `${TEMPLATE_NAMESPACE}.dataMappings`

export const DocumentImagingTemplateEditor: React.FC<IDocumentImagingTemplateEditorProps> = (
  props: IDocumentImagingTemplateEditorProps
) => {
  const { className, entity } = props
  const [focusIndex, setFocusIndex] = useState(null)
  const [propertyIndex, setPropertyIndex] = useState(0)
  const [forceUpdate, setForceUpdate] = useState(0)
  const docType = _.get(entity, 'textExtractTemplate.documentType')
  // TODO: we should not include template editor in the entity schema.  Right now it is being called
  // from the entity schema editor.
  if (docType == null) {
    return <div />
  }
  const docTypeEntity = apis.getStore().getRecord(docType.entityId)
  const defaultNextValues = {
    '': { position: 'left', edge: 'right' },
    left: { position: 'top', edge: 'bottom' },
    top: { position: 'right', edge: 'left' },
    right: { position: 'bottom', edge: 'top' },
    bottom: { position: 'left', edge: 'right' },
  }

  const getValueOptions = () => {
    const options = [{ label: 'Name', value: 'document.name' }]

    return _.concat(options, getAllValueOptions(docTypeEntity))
  }

  const getAllValueOptions = (currType) => {
    let options = []
    if (_.isNil(currType)) {
      return options
    }

    // get the current level options
    const namespace = currType.getNamespace(currType)
    if (!_.isNil(currType.properties) && currType.properties[namespace]) {
      const properties = currType.properties[namespace].properties
      _.forEach(properties, (property, key) => {
        options.push({
          label: property.label,
          value: `${namespace}.${key}`,
        })
      })
    }

    // get parent options
    const allOf = _.get(currType, 'allOf', [])
    // filter out document type and entity type
    const parentTypes = _.filter(
      _.map(allOf, '$ref'),
      (parent) =>
        parent !== '/1.0/entities/metadata/entity.json' &&
        parent !== '/1.0/entities/metadata/document.json'
    )
    _.forEach(parentTypes, (parent) => {
      const parentEntity = apis.getStore().getRecord(parent)
      const parentOptions = getAllValueOptions(parentEntity)
      options = _.concat(options, parentOptions)
    })

    return options
  }

  const handleForceUpdate = () => {
    setForceUpdate(forceUpdate + 1)
  }

  const handleCancelClicked = () => {
    handleForceUpdate()
  }

  const handleFocusChange = (focusIndex) => {
    setFocusIndex(focusIndex)
  }

  const handleLabelMarkerCreation = (newShape) => {
    const boundingBox = {
      height: newShape.height,
      left: newShape.x,
      top: newShape.y,
      width: newShape.width,
    }
    const ocrResult = getOcrTextForBox(newShape)
    const dataMappings = _.get(entity, TEMPLATE_DATA_MAPPING_PATH)
    const currentProperty = dataMappings[propertyIndex]
    let labelMarkers = _.get(currentProperty, 'labelMarkers')
    if (!labelMarkers) {
      _.set(currentProperty, 'labelMarkers', [])
      labelMarkers = _.get(currentProperty, 'labelMarkers')
    }
    const ocrPoly = ocrResult.poly
    const shrinkedBoundingBox = {
      height: ocrPoly[3].y - ocrPoly[0].y,
      width: ocrPoly[1].x - ocrPoly[0].x,
      left: ocrPoly[0].x,
      top: ocrPoly[0].y,
    }
    const lastMarker = _.last(labelMarkers)
    const defaultValue = _.get(defaultNextValues, _.get(lastMarker, 'position', ''))
    labelMarkers.push({
      boundingBox: shrinkedBoundingBox,
      text: ocrResult.text,
      ...defaultValue,
    })
    handleForceUpdate()
  }
  const mergePoly = (poly1, poly2) => {
    return [
      {
        x: Math.min(poly1[0].x, poly2[0].x),
        y: Math.min(poly1[0].y, poly2[0].y),
      },
      {
        x: Math.max(poly1[1].x, poly2[1].x),
        y: Math.min(poly1[1].y, poly2[1].y),
      },
      {
        x: Math.max(poly1[2].x, poly2[2].x),
        y: Math.max(poly1[2].y, poly2[2].y),
      },
      {
        x: Math.min(poly1[3].x, poly2[3].x),
        y: Math.max(poly1[3].y, poly2[3].y),
      },
    ]
  }

  const getOverlapArea = (poly1, poly2) => {
    const poly2Left = poly2[0].x
    const poly2Top = poly2[0].y
    const poly2Right = poly2[1].x
    const poly2Bottom = poly2[3].y

    const poly1Left = poly1[0].x
    const poly1Top = poly1[0].y
    const poly1Right = poly1[1].x
    const poly1Bottom = poly1[3].y

    const overlapWidth = Math.max(
      0,
      Math.min(poly1Right, poly2Right) - Math.max(poly1Left, poly2Left)
    )
    const overlapHeight = Math.max(
      0,
      Math.min(poly1Bottom, poly2Bottom) - Math.max(poly1Top, poly2Top)
    )
    return 1.0 * overlapWidth * overlapHeight
  }

  const getOverlapAreaPercentage = (poly1, poly2) => {
    const poly2Area = (poly2[1].x - poly2[0].x) * (poly2[2].y - poly2[1].y)
    const overlapArea = getOverlapArea(poly1, poly2)

    return (overlapArea * 1.0) / poly2Area
  }

  const getOcrTextForBox = (textShape) => {
    const textRegionPoly = [
      { x: textShape.x, y: textShape.y },
      { x: textShape.x + textShape.width, y: textShape.y },
      { x: textShape.x + textShape.width, y: textShape.y + textShape.height },
      { x: textShape.x, y: textShape.y + textShape.height },
    ]

    const annotations = _.get(entity, 'textExtractTemplate.visionAnalysis.annotations', [])

    const ocrWords = []
    let wordsPoly = []
    for (const wordAnnotation of annotations) {
      const textPoly = wordAnnotation.boundingPoly

      if (getOverlapAreaPercentage(textRegionPoly, textPoly) > 0.7) {
        ocrWords.push(wordAnnotation.text)
        if (_.isEmpty(wordsPoly)) {
          wordsPoly = textPoly
        } else {
          wordsPoly = mergePoly(wordsPoly, textPoly)
        }
      }
    }

    return { text: _.join(ocrWords, ' '), poly: _.isEmpty(wordsPoly) ? textRegionPoly : wordsPoly }
  }

  const handleMapNewValue = () => {
    const dataMappings = _.get(entity, TEMPLATE_DATA_MAPPING_PATH)
    dataMappings.push({
      fieldType: 'primitive',
      labelMarkers: [],
    })
    setPropertyIndex(dataMappings.length - 1)
  }

  const handlePropertyValueChange = (property, value) => {
    const properties = _.get(entity, TEMPLATE_NAMESPACE)
    _.set(properties, property, value)
    handleForceUpdate()
  }

  const handleTemplateNameChange = (value) => {
    handlePropertyValueChange('templateName', value)
  }

  const handleImagingAIDataPromptChange = (value) => {
    const dataMappings = _.get(entity, TEMPLATE_DATA_MAPPING_PATH)
    const property = dataMappings[propertyIndex]
    _.set(property, 'imagingAIQuery.prompt', value)
    handleForceUpdate()
  }


  const handleMatchThresholdChange = (value) => {
    handlePropertyValueChange('matchThreshold', value)
  }

  const handleImagingAIClassificationPrompt = (value) => {
    handlePropertyValueChange('imagingAIConfig.classificationPrompt', value)
  }


  const handleLabelPathChange = (value, option) => {
    const dataMappings = _.get(entity, TEMPLATE_DATA_MAPPING_PATH)
    const property = dataMappings[propertyIndex]
    _.set(property, 'label', option.label)
    _.set(property, 'path', option.value)
    handleForceUpdate()
  }

  const handlePathChange = (value) => {
    const dataMappings = _.get(entity, TEMPLATE_DATA_MAPPING_PATH)
    const property = dataMappings[propertyIndex]
    _.set(property, 'path', value)
    handleForceUpdate()
  }

  const handleValueChange = (value) => {
    const dataMappings = _.get(entity, TEMPLATE_DATA_MAPPING_PATH)
    const property = dataMappings[propertyIndex]
    _.set(property, 'value', value)
    handleForceUpdate()
  }

  const handleValueFormatChange = (value) => {
    const dataMappings = _.get(entity, TEMPLATE_DATA_MAPPING_PATH)
    const property = dataMappings[propertyIndex]
    _.set(property, 'valueFormat', value)
    handleForceUpdate()
  }

  const handleRemoveLabel = (markerLabelIndex) => {
    const dataMappings = _.get(entity, TEMPLATE_DATA_MAPPING_PATH)
    const property = dataMappings[propertyIndex]
    const labelMarkers = _.get(property, 'labelMarkers')

    const leftHalfMarkers = _.slice(labelMarkers, 0, markerLabelIndex)
    const rightHalftMarkers = _.slice(labelMarkers, markerLabelIndex + 1)
    const newLabelMarkers = _.concat(leftHalfMarkers, rightHalftMarkers)

    _.set(property, 'labelMarkers', newLabelMarkers)
    handleForceUpdate()
  }

  const handleRemoveProperty = (removeIndex) => {
    const dataMappings = _.get(entity, TEMPLATE_DATA_MAPPING_PATH)
    _.remove(dataMappings, (dataMapping, index) => {
      return index === removeIndex
    })
    setPropertyIndex(0)
    handleForceUpdate()
  }

  return (
    <SplitPane primary="first" split="vertical" defaultSize={1000} minSize={700}>
      <div className={classNames('grid-block vertical', className)}>
        <DocumentImagingEditor
          entity={entity}
          focusIndex={focusIndex}
          handleEntityChange={handleForceUpdate}
          handleFocusChange={handleFocusChange}
          imagePath={TEMPLATE_IMAGE_PATH}
          onLabelMarkerCreate={handleLabelMarkerCreation}
          propertyIndex={propertyIndex}
        />
      </div>
      <div className={classNames('grid-block vertical', className)}>
        <DocumentImagingTemplateDetailPanel
          entity={entity}
          focusIndex={focusIndex}
          handleCancelClicked={handleCancelClicked}
          handleChangePropertyFocus={setPropertyIndex}
          handleEntityChange={handleForceUpdate}
          handleFocusChange={handleFocusChange}
          handleMapNewValue={handleMapNewValue}
          handleRemoveLabel={handleRemoveLabel}
          handleRemoveProperty={handleRemoveProperty}
          handleLabelPathChange={handleLabelPathChange}
          handlePathChange={handlePathChange}
          handleValueChange={handleValueChange}
          handleValueFormatChange={handleValueFormatChange}
          handleTemplateNameChange={handleTemplateNameChange}
          handleMatchThresholdChange={handleMatchThresholdChange}
          handleImagingAIClassificationPrompt={handleImagingAIClassificationPrompt}
          handleImagingAIDataPrompt={handleImagingAIDataPromptChange}
          options={getValueOptions()}
          propertyIndex={propertyIndex}
        />
      </div>
    </SplitPane>
  )
}
