import _ from 'lodash'
import React from 'react'

import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { default as SheetPortal } from 'browser/components/atomic-elements/atoms/sheet/sheet-portal'

export interface IInputSheetProps {
  isOpen: boolean
  isLoading?: boolean
  onCancel: () => void
  onSave?: any
}

export function InputSheetFactory<IAtomComponentProps>(
  AtomComponent: React.ComponentType, defaultProps?: object, overrideProps?: object) {

  return class extends React.Component<IInputSheetProps & IAtomComponentProps, any> {

    public render() {
      const { isOpen } = this.props
      // overrideProps are used in the location.json example where a field
      // has certain props, but we need to override them when in the sheet mode
      return (
        <SheetPortal
          footer={this.renderSheetFooter()}
          isOpen={isOpen}
          size='sm'
        >
          <div className='c-sheet-body--padded'>
            <FormTable>
              <AtomComponent
                {...defaultProps}
                {...this.props}
                {...overrideProps}
              />
            </FormTable>
          </div>
        </SheetPortal>
      )
    }

    private renderSheetFooter() {
      const { isLoading, onCancel, onSave } = this.props
      return (
        <Footer
          isPrimaryButtonLoading={isLoading}
          isVisible={true}
          onCancelButtonClick={onCancel}
          onPrimaryButtonClick={onSave}
        />
      )
    }
  }
}
