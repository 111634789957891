import React, { Fragment } from "react"
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import apis from 'browser/app/models/apis'
import { evaluateExpression } from 'shared-libs/helpers/evaluation'
import { IBaseProps } from "browser/components/atomic-elements/atoms/base-props"
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import _ from "lodash"
import { Popover } from "browser/components/atomic-elements/atoms/popover/popover"
import { TetherTarget } from "browser/components/atomic-elements/atoms/tether-target"
import { CustomFormulas } from "shared-libs/helpers/formulas"
import { Action } from "browser/app/models/action"
import { ComponentsContext } from "browser/contexts/components/components-context"

interface IDefaultTableActionButtonsProps extends IBaseProps {
  entityPath: string
  selection: any[]
  actions?: any
}

export class DefaultTableActionButtons extends React.Component<IDefaultTableActionButtonsProps> {
  static contextType = ComponentsContext
  context!: React.ContextType<typeof ComponentsContext>

  public render() {
    const { selection } = this.props

    // todo - implement keys, de-dupe across sources
    // todo - should we inherit from parents, or require full override? (currently following latter, as uischema does)
    const entity = selection[0].data
    const actions = this.props.actions || entity.actions
    const settings = apis.getSettings()

    if (_.isEmpty(actions)) {
      return null
    }

    const wrappedActions = actions.map(a => new Action(a))
    const permissionedActions = wrappedActions.filter(
      a => a.permissions ? evaluateExpression({ settings, ...CustomFormulas }, a.permissions) : true)
    const bulkActions = permissionedActions.filter(a => a.isBulk)
    const [bulkCoreActions, bulkHamburgerActions] = _.partition(bulkActions, a => !a.isHamburger)

    return (
      <>
        {bulkCoreActions.map(action => this.renderActionButton(action))}
        {this.renderHamburgerActions(bulkHamburgerActions)}
      </>
    )
  }

  public renderHamburgerActions(actions) {
    if (_.isEmpty(actions)) {
      return null
    }

    return (
      <TetherTarget
        closeOnPortalClick={true}
        tethered={this.renderPopover(actions)}
      >
        <Button>
          <Icon icon={IconNames.MORE}/>
        </Button>
      </TetherTarget>
    )
  }

  public renderPopover(actions) {
    const { selection } = this.props

    return (
      <Popover
        className='collapse'
        data-debug-id='table-actions-popover'
      >
        <ul className='c-dropdownList'>
          {actions.map(a => (
            <li
              className='c-dropdownList-item'
              onClick={() => a.process(selection.map(s => s.data))}
              key={a.__reactKey}
            >
              {a.title}
            </li>
          ))}
        </ul>
      </Popover>
    )
  }

  public renderActionButton(action) {
    const { selection } = this.props

    return (
      <Fragment key={action.__reactKey}>
        <Button
          className={`mr2 ${action.iconClass}`}
          onClick={() => action.process(selection.map(s => s.data))}
        >
          {action.title}
        </Button>
      </Fragment>
    )
  }
}
