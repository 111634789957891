import _ from 'lodash'
import { createFilter } from 'react-select'

const BASE_FILTER_PATHS = ['value', 'label']

export function createFilterFromAdditionalFields(fieldPaths) {
  return createFilter({ stringify: stringifyForFields(fieldPaths) })
}

function stringifyForFields(fieldPaths: string[]) {
  const allPaths = [
    ...BASE_FILTER_PATHS,
    ..._.map(fieldPaths, (entityPath) => `data.${entityPath}`),
  ]
  return (option: any) => {
    const vals = _.map(allPaths,
      (path: string) => _.get(option, path))
    return _.join(_.filter(vals, (val) => !!val), ' ')
  }
}
