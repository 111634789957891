import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/checkbox/_checkbox.scss'
// tslint:disable-next-line:max-line-length
import { FormGroupContentWrapper } from 'browser/components/atomic-elements/atoms/form-group-content-wrapper/form-group-content-wrapper'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { ILabelProps, Label } from 'browser/components/atomic-elements/atoms/label/label'
import { View } from 'browser/components/atomic-elements/atoms/view'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'

/**
 * @uiComponent
 */
export interface ICheckboxProps extends IBaseProps {
  errorText?: string
  helpText?: string
  horizontalLabel?: string
  horizontalLabelProps?: ILabelProps
  inlineEditControls?: React.ReactElement<any>
  inputWidthClassName?: string
  isStatic?: boolean
  isDisabled?: boolean
  isHorizontalLayout?: boolean
  label: string
  labelProps?: ILabelProps
  onChange?: (event: any, silentUpdate?: boolean) => void
  onClick?: (event: any) => void
  size?: string
  isEditableInline?: boolean
  value?: boolean
  initialValue?: boolean
  uncheckedConfirmationText?: string
  checkedConfirmationText?: string
}

export class Checkbox extends React.Component<ICheckboxProps, any> {
  public static defaultProps: Partial<ICheckboxProps> = {
    horizontalLabelProps: Label.defaultProps,
    inputWidthClassName: '',
    isDisabled: false,
    isHorizontalLayout: false,
    isStatic: false,
    labelProps: Label.defaultProps
  }

  public input: any

  constructor(props: ICheckboxProps) {
    super(props)

    const { initialValue, value } = props
    this.state = {
      isFocused: false,
      internalValue: (_.isNil(initialValue) ? value : initialValue)
    }
  }

  public focus() {
    // TODO(peter): fill me out
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      const { format, type, value, initialValue } = nextProps
      this.setState({ internalValue: (_.isNil(initialValue) ? value : initialValue) })
    }
  }


  public componentDidMount() {
    const { isDisabled, value, initialValue, onChange } = this.props

    if (!isDisabled && !_.isNil(initialValue) && value !== initialValue) {
      // one time initalValue write back
      onChange(initialValue, true)
    }
  }

  public render() {
    const { isHorizontalLayout } = this.props

    if (isHorizontalLayout) { return this.renderHorizontalCheckbox() }
    return this.renderVerticalCheckbox(false, false)
  }

  //////////////////////////////////////////////////////////////////////////////
  // Renderers
  //////////////////////////////////////////////////////////////////////////////

  private renderHorizontalCheckbox() {
    const {
      className,
      isHorizontalLayout,
      horizontalLabel,
      horizontalLabelProps,
      isDisabled,
      isEditableInline,
      isStatic,
      inputWidthClassName,
      errorText,
      label,
      size,
    } = this.props
    let contentHorizontalLabel = horizontalLabel
    let hideLabel = false
    if (_.isEmpty(horizontalLabel)) {
      contentHorizontalLabel = label
      hideLabel = true
    }
    const labelSizeClass = _.isEmpty(size) ? '' : `c-label--${size}`
    // Cannot wrap the checkbox + inline edit controls within the label,
    // otherwise the label catches the click for the input and interferes
    // with the inline edit controls.
    const CheckboxTag = isStatic && isEditableInline ? 'div' : 'label'
    return (
      <View
        tagName={CheckboxTag}
        className={classNames('flex w-100', className)}
        style={this.props.style}
      >
        <div
          className={classNames('c-label c-label--isHorizontal',
            labelSizeClass,
            horizontalLabelProps.widthClassName,
            horizontalLabelProps.className, {
              'c-label--error': !_.isEmpty(errorText),
              'c-label--secondary' : horizontalLabelProps.isSecondary,
            })}
        >
          {contentHorizontalLabel}
        </div>
        <FormGroupContentWrapper
          className='c-formGroupContentWrapper--horizontalCheckbox'
          isHorizontalLayout={true}
          isDisabled={isDisabled}
        >
          {this.renderVerticalCheckbox(hideLabel, true)}
        </FormGroupContentWrapper >
      </View>
    )
  }

  private renderVerticalCheckbox(hideLabel, isLabelSecondary) {
    const {
      className,
      label,
      labelProps,
      inlineEditControls,
      isDisabled,
      isEditableInline,
      isHorizontalLayout,
      isStatic,
      errorText,
      size,
    } = this.props
    // Cannot wrap the checkbox + inline edit controls within the label,
    // otherwise the label catches the click for the input and interferes
    // with the inline edit controls.
    const checkboxTag = isStatic && isEditableInline ? 'div' : 'label'
    const checkboxSizeClass = _.isEmpty(size) ? '' : `c-checkbox--${size}`
    const labelSizeClass = _.isEmpty(size) ? '' : `c-label--${size}`

    return (
      <View
        tagName={checkboxTag}
        className={classNames('c-checkbox', checkboxSizeClass, className, {
          'c-checkbox--static': isStatic,
          'is-disabled': isDisabled,
        })}
        style={this.props.style}
      >
        {this.renderCheckbox()}
        <span
          className={classNames('c-label c-checkbox-label--inline', labelSizeClass, labelProps.className, {
            'c-checkbox-label--secondary': isLabelSecondary,
            'c-label--error': !_.isEmpty(errorText),
            'c-label--secondary': labelProps.isSecondary,
            'u-hide': hideLabel,
          })}
        >
          {label}
        </span>
        {this.renderHelpText()}
        {inlineEditControls}
      </View>
    )
  }

  private renderCheckbox() {
    const {
      isDisabled,
      isStatic,
      onClick,
      errorText,
      size,
      value,
    } = this.props
    const { internalValue } = this.state
    const sizeClass = _.isEmpty(size) ? '' : `c-checkbox-box--${size}`

    return (
      <div className={classNames('c-checkbox-box', sizeClass)}>
        <input
          checked={internalValue}
          className='c-checkbox-input'
          data-debug-id={_.get(this.props, 'data-debug-id')}
          disabled={isDisabled || isStatic}
          onChange={this.handleChange}
          onClick={onClick}
          type='checkbox'
          ref={(ref) => { this.input = ref }}
        />
        <i
          className={classNames('c-checkbox-mark', {
            'c-checkbox-mark--error': !_.isEmpty(errorText),
          })}
        />
      </div>
    )
  }

  private renderHelpText() {
    const { helpText, isHorizontalLayout, size } = this.props
    if (!helpText) { return null }
    const helpBlockSizeClass = _.isEmpty(size) ? '' : `c-checkbox-helpBlock--${size}`
    return (
      <HelpBlock
        className={classNames(helpBlockSizeClass)}
        key='helpBlock'
      >
        {helpText}
      </HelpBlock>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleChange = (event) => {
    const { checkedConfirmationText, uncheckedConfirmationText } = this.props
    const value = event.target.checked

    if (this.props.onChange) {
      let confirmationMsg = ''
      if (value && checkedConfirmationText) {
        confirmationMsg = checkedConfirmationText
      } else if (!value && uncheckedConfirmationText) {
        confirmationMsg = uncheckedConfirmationText
      }
      if (!_.isEmpty(confirmationMsg)) {
        ConfirmationModal.openYesNo({
          confirmationText: confirmationMsg,
          ...this.props,
          onPrimaryClicked: () => {
            this.setState ( { internalValue : value })
            this.props.onChange(value)
          },
        })
      } else {
        this.setState ( { internalValue : value })
        this.props.onChange(value)
      }

    }
  }

  private handleOnBlur(event) {
    this.setState({ isFocused: false })
  }

  private handleOnFocus(event) {
    if (!this.props.isDisabled) {
      this.setState({ isFocused: true })
    }
  }
}
