import classNames from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'

import 'browser/components/atomic-elements/atoms/autofill-block/_autofill-block.scss'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

/**
 * @uiComponent
 */
interface IAutofillBlockProps extends IBaseProps {
  maxHeight?: number
  onChange?: (width: number, height: number) => void
  shouldFillHeight?: boolean
  shouldFillWidth?: boolean
}

interface IAutofillBlockState {
  width: number
  height: number
}

export class AutofillBlock extends React.Component<IAutofillBlockProps, IAutofillBlockState> {

  public static defaultProps: IAutofillBlockProps = {
    maxHeight: Infinity,
    onChange: () => {},
    shouldFillHeight: true,
    shouldFillWidth: true,
  }

  private debouncedResize: any

  constructor(props) {
    super(props)
    this.state = {
      height: 0,
      width: 0,
    }
    // TODO(peter/louis): this causes the table to resize slowly
    this.debouncedResize = _.debounce(this.handleResize.bind(this), 100, {
      trailing: true,
    })
  }

  public componentDidMount() {
    const $container = $(ReactDOM.findDOMNode(this))
    $container.resize(this.debouncedResize)
    this.handleResize()
  }

  public componentWillUnmount() {
    this.debouncedResize?.cancel()
    const $container = $(ReactDOM.findDOMNode(this))
    $container.unbind('resize')
  }

  public render() {
    const { className, shouldFillHeight } = this.props
    const autofillClassName = classNames('c-autofillBlock', className, {
      'c-autofillBlock--fillHeight': shouldFillHeight,
    })
    return (
      <div className={autofillClassName}>
        {this.renderChildren()}
      </div>
    )
  }

  private handleResize() {
    const $container = $(ReactDOM.findDOMNode(this))
    const width = $container.innerWidth()
    const height = $container.innerHeight()
    this.setState({ width, height })
    this.props.onChange(width, height)
  }

  private renderChildren() {
    const { children, maxHeight, shouldFillHeight, shouldFillWidth } = this.props
    const { width } = this.state
    let { height } = this.state
    if (!width || !height) { return }
    height = Math.min(height, maxHeight)
    return React.Children.map(children, (child) => {
      const element = child as React.ReactElement<any>
      return React.cloneElement(element, {
        height: shouldFillHeight ? height : undefined,
        width: shouldFillWidth ? width : undefined,
      })
    })
  }
}
