import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'

interface ISortableListProps extends IBaseProps {
  items: any[]
  emptyHelperText?: string
  onEditItem?: (item, index) => void
  onChange: (items: any[]) => void
}

interface ISortableListStates {
  items: any[]
}

export const SortableListDragHandle = SortableHandle((props) => {
  const { className, children } = props
  return (
    <div className={classNames('c-sortableList-itemHandle', className)}>
      {children}
    </div>
  )
})

export const SortableListItem = SortableElement((props) => {
  const { className, children } = props
  return (
    <div className={className}>
      {children}
    </div>
  )
})

export const SortableListContainer = SortableContainer(({ className, children }) => {
  return (
    <div className={className}>
      {children}
    </div>
  )
})

export class SortableList extends React.Component<ISortableListProps, ISortableListStates> {

  constructor(props) {
    super(props)
    this.state = {
      items: props.items.slice(),
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ items: nextProps.items.slice() })
  }

  public render() {
    const { items } = this.props
    if (_.isEmpty(items)) {
      return this.renderEmptyCase()
    }
    return (
      <SortableListContainer
        className='c-sortableList'
        helperClass='c-sortableList-helper'
        items={items}
        lockAxis='y'
        onSortEnd={this.handleSortEnd}
        useDragHandle={true}
      >
        {_.map(items, this.renderSortableListItem)}
      </SortableListContainer>
    )
  }

  private renderEmptyCase() {
    const { emptyHelperText } = this.props
    const contentEmptyHelperText = _.isEmpty(emptyHelperText) ? 'No items' : emptyHelperText
    return (
      <HelpBlock className='u-innerBumper--xl u-textCenter u-topFlush w-100'>
        {contentEmptyHelperText}
      </HelpBlock>
    )
  }

  private renderSortableListItem = (item, index) => {
    return (
      <SortableListItem
        className='c-sortableList-item'
        key={`item-${index}`}
        index={index}
        value={item}
      >
        <SortableListDragHandle>
          <Icon
            icon={IconNames.DOUBLE_CARET_VERTICAL}
          />
        </SortableListDragHandle>
        <div className='u-flexGrow'>
          {item.label}
        </div>
        {this.renderEditButton(item, index)}
        {this.renderDeleteButton(item, index)}
      </SortableListItem>
    )
  }

  private renderEditButton = (value, index) => {
    const { onEditItem } = this.props
    if (!onEditItem) { return }
    const handleClick = () => onEditItem(value, index)
    return (
      <Button
        className={classNames('mr1', Classes.MINIMAL)}
        onClick={handleClick}
        size='sm'
      >
        Configure
      </Button>
    )
  }

  private renderDeleteButton = (value, index) => {
    const handleClick = () => this.handleRemoveItem(value, index)
    return (
      <Button
        className={classNames('mr1', Classes.MINIMAL)}
        onClick={handleClick}
        isDisabled={value.isDeletable != null && !value.isDeletable}
        size='sm'
      >
        <Icon
          className='c-sortableList-itemRemove'
          icon={IconNames.CROSS}
        />
      </Button>
    )
  }

  private handleRemoveItem = (item, index) => {
    const { items, onChange } = this.props
    const newItems = _.filter(items, (value) => value !== item)
    this.props.onChange(newItems)
  }

  private handleSortEnd = ({ oldIndex, newIndex}) => {
    const { items } = this.props
    const newItems = arrayMove(items, oldIndex, newIndex)
    this.props.onChange(newItems)
  }
}
