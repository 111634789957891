import _ from 'lodash'
import React, { Fragment } from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface ITableNumberCellProps extends IBaseProps {
  value: any
  showZeroAsDash?: boolean
}

export const TableNumberCell: React.FC<ITableNumberCellProps> = (props) => {
  const { showZeroAsDash, value } = props
  if (value === 0 && showZeroAsDash === true) {
    return (
      <Fragment>–</Fragment>
    )
  } else {
    return (
      <Fragment>{value}</Fragment>
    )
  }
}
