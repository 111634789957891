import _ from 'lodash'
import React, { HTMLProps } from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

const whiteListProps: (keyof HTMLProps<HTMLElement>)[] = ['className', 'href', 'onClick', 'target', 'style']

/**
 * @uiComponent
 */
interface IUIViewProps extends IBaseProps {
  tagName?: string
  text?: string
}

export class UIView extends React.Component<IUIViewProps, any> {
  public static defaultProps: IUIViewProps = {
    tagName: 'div',
  }

  public render() {
    const {
      children,
      tagName,
      text,
    } = this.props
    // NOTE: it is important to set children to null if empty, otherwise we run
    // into warnings for certain tags such as hr
    // hr is a void element tag and must neither have `children` or ...
    const viewChildren = text || children
    // if (_.isNil(viewChildren)) {
    //   viewChildren = null
    // }
    const props = _.pick(this.props, whiteListProps)
    return React.createElement(tagName, props, viewChildren)
  }
}
