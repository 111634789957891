import classNames from 'classnames'
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { Motion, presets, spring } from 'react-motion'

import { Entity } from 'shared-libs/models/entity'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import 'browser/components/atomic-elements/organisms/side-navigation-bar/_side-navigation-bar.scss'

const LoadDocsLogo = require('images/navigation/loaddocs_logo_small.svg')
const VectorLogo = require('images/navigation/vector-logo-white.svg')

// Sync with $c-sideNavigationBar-width
const SIDE_NAVIGATION_BAR_WIDTH = 248

/**
 * @uiComponent
 */
interface ISideNavigationBarProps extends IBaseProps {
  entityMetadata: any[]
  firm: Entity
  isVisible?: boolean
}

interface ISideNavigationBarState {
  animation: any
}

export class SideNavigationBar extends React.Component<ISideNavigationBarProps, ISideNavigationBarState> {
  public static defaultProps: Partial<ISideNavigationBarProps> = {
    isVisible: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      animation: this.getInitialAnimation(props),
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isVisible !== nextProps.isVisible) {
      this.setState({ animation: this.getNextAnimation(nextProps) })
    }
  }

  public render() {
    const { animation } = this.state
    return (
      <Motion
        defaultStyle={animation.from}
        style={animation.to}
      >
        {(style) => this.renderSideNavigationBarWrapper(style)}
      </Motion>
    )
  }

  private renderSideNavigationBarWrapper(style) {
    const { children, className, firm, isVisible } = this.props
    return (
      <div
        className={classNames('grid-block shrink vertical c-sideNavigationBar', className, {
          'c-sideNavigationBar--minimized': !isVisible,
        })}
        style={style}
      >
        <div className='c-sideNavigationBar-inner'>
          {this.renderLogo()}
          <div className='grid-block'>
            <Scrollbars
              autoHide={true}
              className='c-sideNavigationBar-block c-sideNavigationBar-block--shrink'
            >
              <div className='c-label c-sidebarFirmName u-ellipsis'>
                {firm.displayName}
              </div>
              {children}
            </Scrollbars>
          </div>
        </div>
      </div>
    )
  }

  private renderLogo() {
    const url = window.location.origin
    if (url.indexOf('loaddocs') !== -1) {
      return (
        <img
          className='c-logo'
          src={LoadDocsLogo}
        />
      )
    }

    return (
      <img
        className='c-logo'
        src={VectorLogo}
      />
    )
  }

  private getInitialAnimation(props) {
    const { isVisible } = props
    const initialWidth = isVisible ? SIDE_NAVIGATION_BAR_WIDTH : 0
    return {
      from: {
        width: initialWidth,
      },
      to: {
        width: initialWidth,
      },
    }
  }

  private getNextAnimation(props) {
    const { isVisible } = props
    const { animation } = this.state
    return {
      from: animation.from,
      to: {
        width: spring(isVisible ? SIDE_NAVIGATION_BAR_WIDTH : 0),
      },
    }
  }
}
