import Promise from 'bluebird'
import _ from 'lodash'
import { EntityProxy } from './proxy'
import { deepPathFind } from '../helpers/utils'

const MIN_THRESHOLD_VALUE = 5
const VARIABLE_THRESHOLD_VALUE = 25
const MIN_OFFSET_VALUE = 2
const VARIABLE_OFFSET_VALUE = 10

export class Image extends EntityProxy {

  private apis: any
  private prevTransformations: any
  private transformations: any
  private parentFile: any
  private width: any
  private height: any
  private uri: any
  private _previewUri: any

  constructor(apis, data, parentEntity) {
    super(data)
    this.apis = apis
    const updateEntries = []
    // initialize proxy
    this.setPrevContent(this.content)
    if (!this.get('transformations')) {
      updateEntries.push(['transformations', []])
    }

    // if image is pdf, set the width/length of preview size
    if (this.get('type') === 'pdf') {
      const pdfPreviewWidth = this.get('preview[0].width')
      const pdfPreviewHeight = this.get('preview[0].height')
      updateEntries.push(['width', pdfPreviewWidth])
      updateEntries.push(['height', pdfPreviewHeight])
    }

    // NOTE: This is a fix for a nagging issue VD-2112, which is that Entity.isDirty
    // gets triggered needlessly due to non-user-driven changes we make to the entity
    // model, such as initialization of default values.
    // Ideally, we would have a specific base path passed into this constructor
    // indicating where in the Entity this `data` object resides. But since that is a
    // more difficult and riskier refactor, the workaround here is to search the entity
    // for those paths, and fully commit just these few small changes to the entity
    // model. This way, we only clean bits of dirt very precisely, instead of using
    // Entity.clearDirt which risks wiping out the dirty flag for any edits the user
    // may have made before this constructor ran, e.g. when clicking into a thumbnail
    // in web's entity-details-card.
    const foundPaths = parentEntity ? deepPathFind(parentEntity.content, this.content) : []
    _.forEach(updateEntries, kvPair => {
      const key = kvPair[0]
      const value = kvPair[1]
      this.set(key, value)
      _.forEach(foundPaths, pathPrefix => {
        parentEntity.commit(`${pathPrefix}.${key}`, value)
      })
    })

    this.registerOwnProperties()
    // one time code to fix the documents with out of bound perspective transformation
    this.fixOutOfBoundPerspectiveTransformation()
  }

  public getDownloadUri() {
    if (this.parentFile) {
      return this.parentFile.uri
    }
    return _.get(this, 'source.0.uri')
  }

  private getDefaultPerspectiveTransform() {
    const imageWidth = this.width
    const imageHeight = this.height
    return {
      type: 'perspective',
      topLeft: { x: 0, y: 0 },
      topRight: { x: imageWidth, y: 0 },
      bottomRight: { x: imageWidth, y: imageHeight },
      bottomLeft: { x: 0, y: imageHeight }
    }
  }

  static getDefaultThresholdTransform(value = 0.5) {
    const result = {
      type: 'threshold',
      size: Math.floor(MIN_THRESHOLD_VALUE + value * VARIABLE_THRESHOLD_VALUE),
      offset: Math.floor(MIN_OFFSET_VALUE + value * VARIABLE_OFFSET_VALUE),
      value: value
    }
    if (result.size % 2 === 0) {
      result.size += 1
    }
    return result
  }

  static getDefaultRotationTransform(degrees = 0) {
    return {
      type: 'rotation',
      degrees: degrees
    }
  }

  private getPerspectiveTransform(getOrCreate = false) {
    const transformations = this.transformations
    let transformation: any = _.find(transformations, { type: 'perspective' })
    if (!transformation && getOrCreate) {
      transformation = this.getDefaultPerspectiveTransform()
      transformations.push(transformation)
      this.reorderTransformations()
    }
    return transformation
  }

  /**
   * Reset perspective transformation if any of vertices are out of bound
   */
  private fixOutOfBoundPerspectiveTransformation() {
    const transformations = this.transformations
    const perspective: any = _.find(transformations, { type: 'perspective' })

    if (!perspective) {
      return
    }

    const vertices = _.pick(perspective, ['topLeft', 'topRight', 'bottomRight', 'bottomLeft'])
    const isOutOfBound = _.find(vertices, (vertex) => vertex.x > this.width || vertex.y > this.height)

    if (isOutOfBound) {
      _.pull(transformations, perspective)
      this.transformations.unshift(this.getDefaultPerspectiveTransform())
    }
  }

  public getThresholdTransform(getOrCreate = false) {
    const transformations = this.transformations
    let transformation: any = _.find(transformations, { type: 'threshold' })
    if (!transformation && getOrCreate) {
      transformation = Image.getDefaultThresholdTransform()
      transformations.push(transformation)
      this.reorderTransformations()
    }
    return transformation
  }

  private getRotationTransform(getOrCreate = false) {
    const transformations = this.transformations
    let transformation: any = _.find(transformations, { type: 'rotation' })
    if (!transformation && getOrCreate) {
      transformation = Image.getDefaultRotationTransform()
      transformations.push(transformation)
      this.reorderTransformations()
    }
    return transformation
  }

  public getRotation() {
    const transformation = this.getRotationTransform()
    return transformation ? transformation.degrees : 0
  }

  public toggleThresholdTransform() {
    const transformation = this.getThresholdTransform()
    if (transformation) {
      _.pull(this.transformations, transformation)
    } else {
      return this.getThresholdTransform(true)
    }
  }

  private reorderTransformations() {
    // make sure we do perspective transformation first (before rotation)
    const perspectiveTransformation = this.getPerspectiveTransform()
    if (perspectiveTransformation) {
      _.pull(this.transformations, perspectiveTransformation)
      this.transformations.unshift(perspectiveTransformation)
    }
  }

  public rotateClockwise90() {
    const transformation = this.getRotationTransform(true)
    transformation.degrees = (transformation.degrees + 90) % 360
    return transformation
  }

  public rotateCounterClockwise90() {
    const transformation = this.getRotationTransform(true)
    // [0 -> 270], [90 -> 0], [180 -> 90], [270 -> 180]
    transformation.degrees = (transformation.degrees + 270) % 360
    return transformation
  }

  public updateThresholdTransformation(value) {
    const transformation = this.getThresholdTransform(true)
    const newTransformation = Image.getDefaultThresholdTransform(value)
    transformation.size = newTransformation.size
    transformation.offset = newTransformation.offset
    transformation.value = value
    return transformation
  }

  get previewUri() {
    const transform = this.getThresholdTransform()
    if (transform) {
      if (this._previewUri) {
        return Promise.resolve(this._previewUri)
      }
      return this.apis.getImagePreview(this.uri, [transform]).then(json => {
        this._previewUri = `data:image/jpeg;base64,${json.image}`
        return this._previewUri
      })
    }
    return Promise.resolve(this.uri)
  }

  set previewUri(previewUri: string) {
    this._previewUri = previewUri
  }

  get jpgUri() {
    if (_.get(this, 'type').toLowerCase() === 'pdf') {
      return _.get(this, 'preview[0].uri')
    }
    return _.get(this, 'uri')
  }
}
