import React from 'react'

import _ from 'lodash'
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { browserHistory } from 'browser/history'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import apis from 'browser/app/models/apis'
import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import 'browser/components/atomic-elements/organisms/top-navigation-bar/_user-account.scss'
import { signout } from 'browser/app/utils/auth'

interface IUserAccountNavbarItemProps extends IBaseProps {
  user: any
}

export class UserAccountNavbarItem extends React.Component<IUserAccountNavbarItemProps, any> {

  public render() {
    const { className, user } = this.props
    const tetherOptions = {
      attachment: 'top right',
      classes: {
        element: 'c-tether-element c-tether-theme-basic u-zIndex-8',
      },
      targetAttachment: 'bottom right',
    }
    return (
      <TetherTarget
        closeOnPortalClick={true}
        tethered={this.renderUserAccountPopover()}
        tetherOptions={tetherOptions}
      >
        <div
          className={classNames('c-userAccount', className)}
          data-debug-id='userAccount'
        >
          <Avatar
            size='xs'
            user={user}
          />
          <div className='u-bumperLeft--sm c-topNavigationBar-link'>
            {user.displayName}
            <Icon
              className='ml1'
              icon={IconNames.CHEVRON_DOWN}
            />
          </div>
        </div>
      </TetherTarget>
    )
  }

  private renderUserAccountPopover() {
    const { user } = this.props
    const firmName = user.get('owner.firm.displayName')
    return (
      <Popover className='collapse'>
        <ul
          className='c-dropdownList'
          data-debug-id='userAccountPopover'
        >
          <NavLink
            className='c-dropdownList-item'
            to='/settings'
          >
            Account &amp; Settings
          </NavLink>
          <li className='c-list-divider' />
          <a
            className='c-dropdownList-item'
            href='mailto:support@withvector.com'
            target='_blank'
            rel='noreferrer'
          >
            Contact Vector Support
          </a>
          <li className='c-list-divider' />
          <li
            className='c-dropdownList-item'
            data-debug-id='signOut'
            onClick={signout}
          >
            Sign out of {firmName}
          </li>
        </ul>
      </Popover>
    )
  }
}
