import { Classes, Tab, Tabs } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import SplitPane from 'react-split-pane'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import ComponentsMap from 'browser/components'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Card } from 'browser/components/atomic-elements/atoms/card/card'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { BlockTransition } from 'browser/components/atomic-elements/atoms/navigation/block-transition'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { SheetContext } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import {
  PostToDATSheet,
} from 'browser/components/atomic-elements/domains/trucking/post-to-dat-sheet/post-to-dat-sheet'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
// tslint:disable-next-line:max-line-length
import {
  default as EntityDetailCard,
  IEntityDetailCardProps,
} from 'browser/components/atomic-elements/organisms/entity/entity-detail-card/entity-detail-card'
import { EntityFormPanel } from 'browser/components/atomic-elements/organisms/entity/entity-form-panel'
import 'browser/components/atomic-elements/organisms/entity/order-detail-card/_order-detail-card.scss'
// tslint:disable-next-line:max-line-length
import { BrokerOrderSecondaryCardHeader } from 'browser/components/atomic-elements/organisms/entity/order-detail-card/broker-order-secondary-card-header'
// tslint:disable-next-line:max-line-length
import { CarrierOrderSecondaryCardHeader } from 'browser/components/atomic-elements/organisms/entity/order-detail-card/carrier-order-secondary-card-header'
import { OrderFooter } from 'browser/components/atomic-elements/organisms/entity/order-detail-card/order-footer'
import { ShareBar } from 'browser/components/atomic-elements/organisms/share-bar/share-bar'
import { browserHistory } from 'browser/history'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'
import { Entity } from 'shared-libs/models/entity'
import { Query } from 'shared-libs/models/query'
import { JSONEditor } from '../../internal-tools/json-editor'
import { EntityDataSource } from '../entity-data-source'

const ORDER_ENTRY_TAB_ID = 'orderEntry'
const COVERING_TAB_ID = 'covering'
const TRACKING_TAB_ID = 'tracking'
const SETTLING_TAB_ID = 'settling'
const FREIGHT_CHARGE = 'Freight Charge'

interface IOrderDetailCardState {
  activeSecondaryTab: string
  activeTab: string
  dataSets: any
  errors: any
  isRightPanelVisible: boolean
  isSaving: boolean
  shouldRefresh: boolean
}

const StatusToStateLookup = [
  { primary: 'Tracking', secondary: '',
    tabs: { activeTab: TRACKING_TAB_ID, activeSecondaryTab: 'activity' } },
  { primary: '', secondary: '',
    tabs: { activeTab: ORDER_ENTRY_TAB_ID, activeSecondaryTab: 'activity' } }, // default
]

const FullScreenStatusToStateLookup = [
  { primary: 'Planning', secondary: 'Draft',
    tabs: { activeTab: ORDER_ENTRY_TAB_ID, activeSecondaryTab: 'analytics' } },
  { primary: 'Planning', secondary: 'Available',
    tabs: { activeTab: COVERING_TAB_ID, activeSecondaryTab: 'keyDetails' } },
  { primary: 'Planning', secondary: 'Canceled',
    tabs: { activeTab: ORDER_ENTRY_TAB_ID, activeSecondaryTab: 'activity' } },
  { primary: 'Planning', secondary: '',
    tabs: { activeTab: TRACKING_TAB_ID, activeSecondaryTab: 'analytics' } },
  { primary: 'Tracking', secondary: 'Booked',
    tabs: { activeTab: COVERING_TAB_ID, activeSecondaryTab: 'activity' } },
  { primary: 'Tracking', secondary: '',
    tabs: { activeTab: TRACKING_TAB_ID, activeSecondaryTab: 'activity' } },
  { primary: 'Settling', secondary: '',
    tabs: { activeTab: SETTLING_TAB_ID, activeSecondaryTab: 'documents' } },
  { primary: '', secondary: '',
    tabs: { activeTab: ORDER_ENTRY_TAB_ID, activeSecondaryTab: 'activity' } }, // default
]

const POLL_TIMEOUT = 10000

/**
 * @uiComponent
 */
interface IOrderDetailCardProps extends IEntityDetailCardProps {
  location?: any
  openOverlay: any
  settings: Settings
}

class OrderDetailCard extends React.Component<IOrderDetailCardProps, IOrderDetailCardState> {

  private store: any
  private datTimer: any
  private datCarrierWatchEnabled: boolean
  private datLastUpdated: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      activeTab: 'Details',
      dataSets: this.createDataSets(props.queries),
      errors: null,
      isRightPanelVisible: true,
      isSaving: false,
      shouldRefresh: false,
      ...this.getNextStateFromProps(props)
    }
    this.datNotificationPoll = this.datNotificationPoll.bind(this)
    this.viewEntity = this.viewEntity.bind(this)
    this.datLastUpdated = new Date().toISOString()
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.entity !== nextProps.entity) {
      this.setState(this.getNextStateFromProps(nextProps))
    }
  }

  public componentDidMount() {
    const { dataSets } = this.state
    _.forEach(dataSets, (dataSet: EntityDataSource) => dataSet.find())

    this.datCarrierWatchEnabled =  _.get(this.props.settings.getUserDATSettings(),
      'settings.isCarrierWatchEnabled', false)

    if (this.datCarrierWatchEnabled) {
      this.datTimer = setInterval(this.datNotificationPoll, POLL_TIMEOUT)
    }
  }

  public componentWillUnmount() {
    const { dataSets } = this.state
    _.forEach(dataSets, (dataSource: EntityDataSource) => dataSource.dispose())

    if (this.datCarrierWatchEnabled) {
      this.stopPolling()
    }
  }

  public render() {
    const { className, entity } = this.props
    const { shouldRefresh } = this.state
    const isDirty = entity.isDirty || shouldRefresh

    if (shouldRefresh) {
      this.setState ( { shouldRefresh: false })
    }

    // Note: Do not add shrink class, causes issues with table inside of it.
    return (
      <Card
        data-debug-id='orderDetailCard'
        className={classNames('grid-block c-entityDetailCard c-orderCard u-noPrint', className)}
      >
        {this.renderOrderHelmet()}
        {this.renderContent(isDirty)}
        <BlockTransition
          condition={isDirty}
          onBlockTransition={this.handleBlockTransition}
          debug='order-detail-card'
        />
      </Card>
    )
  }

  private renderOrderHelmet() {
    const { entity } = this.props
    const stops = entity.get('dispatchOrder.stops')
    const status = entity.get('dispatchOrder.status')
    const firstStop = _.first(stops)
    const lastStop = _.last(stops)
    const addressPath = ['location', 'denormalizedProperties', 'location.address']
    const firstAddress: any = _.get(firstStop, addressPath)
    const lastAddress: any = _.get(lastStop, addressPath)
    const primaryStatus = status.primary
    let stopStatus = ''
    let title = ''
    if (firstAddress && lastAddress) {
      stopStatus = `${firstAddress.locality}, ${firstAddress.region} - ${lastAddress.locality}, ${lastAddress.region}`
      title = `${entity.displayName} | ${stopStatus} | ${primaryStatus} - LoadDocs`
    } else {
      title = `${entity.displayName} | ${primaryStatus} - LoadDocs`
    }
    return (
      <Head
        title={title}
      />
    )
  }

  private renderContent(isDirty: boolean) {
    const { isRightPanelVisible } = this.state
    const { isFullScreen } = this.props
    let content = this.renderOrderDetailCard(isDirty)
    if (isFullScreen) {
      if (isRightPanelVisible) {
        content = (
          <SplitPane
            primary='second'
            split='vertical'
            defaultSize={544}
            minSize={544}
          >
            {this.renderLeftPanel(isDirty)}
            {this.renderRightPanel()}
          </SplitPane>
        )
      } else {
        content = this.renderLeftPanel(isDirty)
      }
    }

    return (
      <div className='grid-block vertical'>
        {this.renderOrderHeader(isFullScreen)}
        <div
          className={classNames('absolute c-toggleRightPanelVisibilityPanel', {
            'u-hide': !isFullScreen,
          })}
        >
          <Button
            className={Classes.iconClass(IconNames.INFO_SIGN)}
            onClick={this.handleToggleRightPanelVisibility}
          />
        </div>
        <div className='grid-block'>
          {content}
        </div>
      </div>
    )
  }

  private handleToggleRightPanelVisibility = () => {
    this.setState({ isRightPanelVisible: !this.state.isRightPanelVisible })
  }

  //////////////////////////////////////////////////////////////////////////////
  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  private renderOrderHeader(isExpanded) {
    const { entity, header, onClose } = this.props
    // TODO(louis): clean up
    const { settings } = this.props
    const isCarrierTMSAppliction = settings.isCarrierTMSAppliction()

    if (isCarrierTMSAppliction) {
      if (isExpanded) {
        return React.cloneElement(header, {
          children: (
            <CarrierOrderSecondaryCardHeader
              isExpanded={true}
              entity={entity}
              onDispatchStatusChange={this.handleDispatchStatusChange}
              onTrackingStatusChange={this.handleTrackingStatusChange}
              onUpdateAndSaveModelStatus={this.handleUpdateAndSaveModelStatus}
            />
          ),
          // dropdownMenuElement: this.renderDropdownMenu(),
          leftFullScreenActionElement: (
            <div className='flex'>
              {this.renderHoldButton()}
              {this.renderDuplicateButton()}
              {this.renderMoreOptionsDropdown()}
              {this.renderDATDropdown()}
            </div>
          ),
          onClose,
        })
      } else {
        return React.cloneElement(header, {
          children: (
            <CarrierOrderSecondaryCardHeader
              entity={entity}
              onDispatchStatusChange={this.handleDispatchStatusChange}
              onTrackingStatusChange={this.handleTrackingStatusChange}
              onUpdateAndSaveModelStatus={this.handleUpdateAndSaveModelStatus}
            />
          ),
          // dropdownMenuElement: this.renderDropdownMenu(),
          onClose,
        })
      }
    }

    if (isExpanded) {
      return React.cloneElement(header, {
        children: (
          <BrokerOrderSecondaryCardHeader
            isExpanded={true}
            entity={entity}
            onDispatchStatusChange={this.handleDispatchStatusChange}
            onTrackingStatusChange={this.handleTrackingStatusChange}
            onUpdateAndSaveModelStatus={this.handleUpdateAndSaveModelStatus}
          />
        ),
        // dropdownMenuElement: this.renderDropdownMenu(),
        leftFullScreenActionElement: (
          <div className='flex'>
            {/* {this.renderShareButton()} */}
            {this.renderHoldButton()}
            {this.renderDuplicateButton()}
            {this.renderMoreOptionsDropdown()}
            {this.renderDATDropdown()}
          </div>
        ),
        onClose,
      })
    } else {
      return React.cloneElement(header, {
        children: (
          <BrokerOrderSecondaryCardHeader
            entity={entity}
            onDispatchStatusChange={this.handleDispatchStatusChange}
            onTrackingStatusChange={this.handleTrackingStatusChange}
            onUpdateAndSaveModelStatus={this.handleUpdateAndSaveModelStatus}
          />
        ),
        // dropdownMenuElement: this.renderDropdownMenu(),
        onClose,
      })
    }
  }

  private renderHoldButton() {
    const { entity } = this.props
    const isOnHold = entity.get('dispatchOrder.isOnHold')
    const text = isOnHold ? 'Remove Flag' : 'Flag Hold'
    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin'>
        <Button
          className={Classes.iconClass(IconNames.FLAG)}
          onClick={this.handleToggleHold}
        >
          {text}
        </Button>
      </CardHeaderItem>
    )
  }

  private renderShareButton() {
    const { entity } = this.props
    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin'>
        <ShareBar
          showButtonOnly={true}
          entity={entity}
        />
      </CardHeaderItem>
    )
  }

  private renderDuplicateButton() {
    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin'>
        <Button
          onClick={this.handleDuplicateOrder}
        >
          Duplicate
        </Button>
      </CardHeaderItem>
    )
  }

  private renderMoreOptionsDropdown() {
    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin'>
        <TetherTarget
          closeOnPortalClick={true}
          tethered={this.renderMoreOptionsDropdownMenu()}
        >
          <Button>
            More
          </Button>
        </TetherTarget>
      </CardHeaderItem>
    )
  }

  private renderMoreOptionsDropdownMenu() {
    return (
      <Popover
        className='collapse'
        data-debug-id='document-options-dropdown'
      >
        <div>
          <ul className='c-dropdownList'>
            <li
              className='c-dropdownList-item'
              onClick={this.handleCancelAndArchive}
            >
              Cancel &amp; Archive
            </li>
          </ul>
        </div>
      </Popover>
    )
  }

  private renderDATDropdown() {
    const { settings } = this.props
    const { entity } = this.props
    const orderStatus = _.get(entity, 'dispatchOrder.status.secondary')
    const loadBoardEnabled = _.get(settings.getUserDATSettings(), 'settings.isLoadBoardEnabled', false)

    if (!loadBoardEnabled || orderStatus !== 'Available') {
      return
    }

    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin'>
        <TetherTarget
          closeOnPortalClick={true}
          tethered={this.renderDATDropdownMenu()}
        >
          <div className={Classes.BUTTON}>
            Load Board
          </div>
        </TetherTarget>
      </CardHeaderItem>
    )
  }

  private renderDATDropdownMenu() {
    const  datActionStates = [
      { current: 'Posted',         next: ['RePost', 'UnPost'] },
      { current: 'UnPosted',       next: ['Post'] },
      { current: 'Never Reposted', next: ['Post'] },
      { current: '',               next: ['Post'] },
    ]

    const datActionHandlers = [
      { name: 'Post', value: { handler: this.handlePostToDAT, displayName: 'Post To DAT'}},
      { name: 'RePost', value: { handler: this.handleRepostToDAT, displayName: 'Repost To DAT'}},
      { name: 'UnPost', value: { handler: this.handleRemoveFromDAT, displayName: 'Remove From DAT'}},
    ]
    const { entity } = this.props

    const currState = entity.get('dat.loadBoard.status', '')
    const nextStates = _.find(datActionStates, (state) => {
      return (!state.current || state.current === currState)
    })

    const actionHandlers = _.filter(datActionHandlers, (handler) => _.includes(nextStates.next, handler.name))

    return (
      <Popover
        className='collapse'
        data-debug-id='document-options-dropdown'
      >
        <div>
          <ul className='c-dropdownList'>
            { actionHandlers.map((i, index) => (
              <li className='c-dropdownList-item' onClick={i.value.handler} key={index}>
                {i.value.displayName}
              </li>
            ))}
          </ul>
        </div>
      </Popover>
    )
  }

  private renderOrderDetailCard(isDirty: boolean) {
    const { entity, location } = this.props
    const { activeTab, dataSets } = this.state
    const numDocuments = _.get(dataSets, 'documents.collection.count', 0)
    const context = {}
    return (
      <div className='grid-block vertical'>
        <div className='grid-block vertical u-overflowHidden'>
          <Tabs
            className='grid-block vertical c-tabs'
            id='secondaryOrderCard'
            onChange={this.handlePrimaryTabChange}
            renderActiveTabPanelOnly={true}
            selectedTabId={activeTab}
          >
            <Tab
              className='grid-block'
              id={ORDER_ENTRY_TAB_ID}
              title='Details'
              panel={this.renderTabPanel('components.allDetailsTab', context, isDirty)}
            />
            <Tab
              className='grid-block'
              id={TRACKING_TAB_ID}
              title='Tracking'
              panel={this.renderUiSchema('components.trackingTab', context)}
            />
            <Tab
              className='grid-block'
              id='activity'
              title='Activity'
              panel={this.renderUiSchema('components.activityTab', context)}
            />
            <Tab
              className='grid-block'
              id='analytics'
              title='Analytics'
              panel={this.renderUiSchema('components.analyticsTab', context)}
            />
            <Tab
              className='grid-block'
              id='documents'
              title={numDocuments ? `Documents (${numDocuments})` : 'Documents'}
              panel={this.renderUiSchema('components.documentsTab', context)}
            />
            <Tabs.Expander />
            {this.renderJSONTab()}
          </Tabs>
        </div>
        <ShareBar
          className='grid-block shrink'
          entity={entity}
        />
      </div>
    )
  }

  private renderLeftPanel(isDirty: boolean) {
    const { activeTab } = this.state
    const context = {
      isEditableInline: false,
      isStatic: false,
    }
    return (
      <Tabs
        className='grid-block vertical c-tabs c-statusTabBar'
        id='primaryOrderCard'
        onChange={this.handlePrimaryTabChange}
        renderActiveTabPanelOnly={true}
        selectedTabId={activeTab}
      >
        <Tab
          className='grid-block c-statusTabBar-tab c-statusTabBar-firstTab'
          id={ORDER_ENTRY_TAB_ID}
          title={this.renderTabTitle('Order Entry')}
          panel={this.renderTabPanel('components.orderEntryTab', context, isDirty)}
        />
        <Tab
          className='grid-block c-statusTabBar-tab'
          id={COVERING_TAB_ID}
          title={this.renderTabTitle('Covering')}
          panel={this.renderTabPanel('components.coveringTab', context, isDirty)}
        />
        <Tab
          className='grid-block c-statusTabBar-tab'
          id={TRACKING_TAB_ID}
          title={this.renderTabTitle('Tracking')}
          panel={this.renderUiSchema('components.trackingTab', context)}
        />
        <Tab
          className='grid-block c-statusTabBar-tab c-statusTabBar-lastStatusTab'
          id={SETTLING_TAB_ID}
          title={this.renderTabTitle('Settling')}
          panel={this.renderTabPanel('components.settlingTab', context, isDirty)}
        />
        <Tabs.Expander />
        {/* {this.renderJSONTab()} */}
      </Tabs>
    )
  }

  private renderRightPanel() {
    const { entity } = this.props
    const { dataSets, activeSecondaryTab } = this.state
    const numDocuments = _.get(dataSets, 'documents.collection.count', 0)
    const context = {
      density: 'collapse',
      isEditableInline: false,
      isHorizontalLayout: true,
      isStatic: true,
    }
    return (
      <div className='grid-block vertical'>
        <Tabs
          className='grid-block vertical c-tabs'
          id='secondaryOrderCard'
          onChange={this.handleSecondaryTabChange}
          selectedTabId={activeSecondaryTab}
          renderActiveTabPanelOnly={true}
        >
          <Tab
            className='grid-block'
            id='analytics'
            title='Analytics'
            panel={this.renderUiSchema('components.analyticsTab', context)}
          />
          <Tab
            className='grid-block'
            id='keyDetails'
            title='Key Details'
            panel={this.renderUiSchema('components.keyDetailsTab', context)}
          />
          <Tab
            className='grid-block'
            id='activity'
            title='Activity'
            panel={this.renderUiSchema('components.activityTab', context)}
          />
          <Tab
            className='grid-block'
            id='documents'
            title={numDocuments ? `Documents (${numDocuments})` : 'Documents'}
            panel={this.renderUiSchema('components.documentsTab', context)}
          />
          <Tab
            className='grid-block'
            id='invoice'
            title='Invoice'
            panel={this.renderUiSchema('components.invoicingTab', context)}
          />
          <Tabs.Expander />
        </Tabs>
        <ShareBar
          className='grid-block shrink'
          entity={entity}
        />
      </div>
    )
  }

  private renderTabTitle(title) {
    return (
      <div className='c-statusTabBar-title'>
        {title}
      </div>
    )
  }

  private renderTabPanel(uiSchemaName: string, options: object, isDirty: boolean) {
    const { entity } = this.props
    const { errors, isSaving } = this.state
    return (
      <div className='grid-block vertical u-overflowHidden'>
        {this.renderUiSchema(uiSchemaName, options)}
        <OrderFooter
          errors={errors}
          entity={entity}
          isPrimaryButtonLoading={isSaving}
          isVisible={isDirty}
          onCancelButtonClick={this.handleRollback}
          onPrimaryButtonClick={this.handleSaveEntity}
        />
      </div>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleCancelAndArchive = () => {
    const { entity, onClose } = this.props
    const cancelAndDeleteOrder = () => {
      entity.set('dipsatchOrder.status', {
        primary: 'Planning',
        secondary: 'Canceled',
      })
      entity.save()
        .then(() => entity.delete())
        .then(() => onClose ? onClose() : null)
    }
    ConfirmationModal.open({
      confirmationText: 'Do you want to cancel and delete this order?',
      confirmationTitle: 'Confirm Cancel and Delete',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: cancelAndDeleteOrder,
      primaryButtonText: 'Confirm',
    })
  }

  private handleDispatchStatusChange = (option) => {
    this.handleUpdateAndSaveModelStatus('dispatchOrder.status', {
      primary: option.primary,
      secondary: option.secondary,
    })
  }

  private handleDuplicateOrder = () => {
    const { entity } = this.props
    /*
     * Order Entry: Everything + Customer Rep (firm reference number won't be copied over for obvious reasons)
     * Covering: Dispatch Notes
     * Nothing else: No check calls / Tracking tab, no Settling tab, no activity feed,
     * no Tracking Status, no Flag Hold, no Documents
     *
     * The values below are extracted from the orderEntryTab and coveringTab section
     */
    const cloneFields = [
      'brokerOrder.customer',
      'brokerOrder.customerContact',
      'brokerOrder.customerRepresentative',
      'brokerOrder.planningNotes',
      'brokerOrder.targetSellPrice',
      'dispatchOrder.additionalIdentifiers',
      'dispatchOrder.cargos',
      'dispatchOrder.hasHazardousMaterials',
      'dispatchOrder.loadType',
      'dispatchOrder.notes',
      'dispatchOrder.revenueItems',
      'dispatchOrder.stops',
      'dispatchOrder.temperatureRange',
      'dispatchOrder.trailerType',
    ]

    const defaultValue = {}
    _.forEach(cloneFields, (field) => {
      _.set(defaultValue, field, _.cloneDeep(entity.get(field)))
    })

    _.forEach(_.get(defaultValue, 'dispatchOrder.revenueItems'), (item) => delete item.accountingEntity)

    // TODO(Peter): this is really sketchy
    const schema = _.last(entity.schemas)
    EntityFormPanel.open({ schema, defaultValue })
  }

  private handlePrimaryTabChange = (tabId) => {
    const { entity } = this.props
    if (!entity.isDirty) {
      this.setState({ activeTab: tabId })
      return
    }
    this.handleBlockTransition(() => {
      this.setState({ activeTab: tabId })
    })
  }

  private handleSecondaryTabChange = (tabId) => {
    this.setState({ activeSecondaryTab: tabId })
  }

  private handlePostToDAT = () => {
    const { entity, openOverlay } = this.props
    const rate = entity.get('brokerOrder.targetSellPrice')
    const comment = entity.get('dispatchOrder.notes')

    openOverlay(
      <PostToDATSheet
        rate={rate}
        comment={comment}
        onSave={this.postToDATSaveCallback}
        onCancel={this.postToDATCancelCallback}
      />,
    )
  }

  private postToDATSaveCallback = (value: any) => {
    const { entity } = this.props
    const  uniqueId = entity.get('uniqueId')
    const toastKey = this.showSnackbar('Posting to DAT LoadBoard')

    apis.datLoadBoardSync(uniqueId, value.offerRate, value.comment).then(() => {
      return this.store.findRecord(uniqueId)
    }).then((updatedEntity: Entity) =>  {
      return updatedEntity.waitUntilIdle()
    }).then(() => {
      this.updateSnackbar(toastKey, 'Posted to DAT LoadBoard')
      this.forceUpdate()
    }).catch ((error) => {
      const response = JSON.parse(error.responseText)
      this.updateSnackbar(toastKey, 'Unable to post to LoadBoard.  Error: ' + response.code + ' - ' + response.message)
    })
  }

  private postToDATCancelCallback = () =>  {

  }

  private handleRepostToDAT = () => {
    const { entity } = this.props
    const uniqueId = entity.get('uniqueId')
    const toastKey = this.showSnackbar('Reposting to DAT LoadBoard')
    apis.datLoadBoardRefresh(uniqueId).then(() => {
      return this.store.findRecord(uniqueId)
    }).then((updatedEntity: Entity) =>  {
      return updatedEntity.waitUntilIdle()
    }).then(() => {
      this.updateSnackbar(toastKey, 'Reposted to DAT LoadBoard')
      this.forceUpdate()
    }).catch ((error) => {
      const response = JSON.parse(error.responseText)
      this.updateSnackbar(toastKey, 'Unable to repost to LoadBoard.  Error: ' + response.code + ' - ' + response.message)
    })

  }

  private handleRemoveFromDAT = () => {
    const { entity } = this.props
    const uniqueId = entity.get('uniqueId')
    const toastKey = this.showSnackbar('Removing from DAT LoadBoard')
    apis.datLoadBoardUnsync(uniqueId).then(() =>  {
      return this.store.findRecord(uniqueId)
    }).then((updatedEntity: Entity) =>  {
      return updatedEntity.waitUntilIdle()
    }).then(() => {
      this.updateSnackbar(toastKey, 'Removed from DAT LoadBoard')
      this.forceUpdate()
    }).catch ((error) => {
      const response = JSON.parse(error.responseText)
      this.updateSnackbar(toastKey, 'Unable to remove from LoadBoard.  Error: ' + response.code + ' - ' + response.message)
    })
  }

  private handleToggleHold = () => {
    const { entity } = this.props
    const isOnHold = entity.get('dispatchOrder.isOnHold')
    this.handleUpdateAndSaveModelStatus('dispatchOrder.isOnHold', !isOnHold)
  }

  private handleTrackingStatusChange = (status) => {
    this.handleUpdateAndSaveModelStatus('dispatchOrder.trackingStatus', status)
  }

  private handleUpdateAndSaveModelStatus = (key, value) => {
    const { entity } = this.props
    _.set(entity.prevContent, key, value)
    entity.set(key, value)
    return entity.savePrevContent().then(() => {
      // remove from DAT on 'Available'
      if (key === 'dispatchOrder.status' && value !== 'Available'
          && (entity.get('dat.loadBoard.status') === 'Posted')) {
        this.handleRemoveFromDAT()
      }
    })
  }

  private getNextStateFromProps(props) {
    const { entity, isFullScreen } = props
    const status = entity.get('dispatchOrder.status')
    const stateLookup = isFullScreen ?
      FullScreenStatusToStateLookup : StatusToStateLookup
    const foundEntry = _.find(stateLookup, (entry) => {
      return (!entry.primary || entry.primary === status.primary) &&
             (!entry.secondary || entry.secondary === status.secondary)
    })
    return foundEntry.tabs
  }

  private showSnackbar(message) {
    return Toast.show({
      message,
      position: Position.BOTTOM_RIGHT,
      timeout: 30000,
    })
  }

  private updateSnackbar(toastKey, message) {
    Toast.update(toastKey, {
      message,
      position: Position.BOTTOM_RIGHT,
      timeout: 5000,
    })
  }

  private async datNotificationPoll() {
    const collection = new Query(apis)
      .setEntityType('11111111-0000-0000-0000-000000000004')
      .setOrders([{path: 'business.legalName', type: 'ascending', label: 'Company name'}])
      .setFilters([
        {
          gte: this.datLastUpdated,
          path: 'dat.carrierWatch.lastUpdateDate',
          type: 'range',
        },
      ])
      .setSize(100)
      .getCollection()

    const syncedDate = new Date().toISOString()

    // wait for result
    await collection.find()
    while (collection.hasNext()) {
      await collection.next()
    }

    const updatedCarriers = collection.content

    updatedCarriers.forEach((carrier) => {
      Toast.show({
        message: (
          <div className='u-bumperLeft' onClick={() => this.viewEntity(carrier.get('uniqueId'))}>
            {carrier.get('business.legalName') + ' updated'}
          </div>
        ),
        position: Position.BOTTOM_RIGHT,
        timeout: 30000,
      })
    })

    if (updatedCarriers.length) {
      this.datLastUpdated = syncedDate
    }
  }

  private viewEntity(entityId) {
    browserHistory.push({ pathname: `/entity/${entityId}` })
  }

  private stopPolling() {
    clearInterval(this.datTimer)
  }

  private createDataSets(dataSets) {
    const results = {}
    _.forEach(dataSets, (dataSource, key) => {
      results[key] = new EntityDataSource(dataSource)
        .setOnChange(() => this.forceUpdate())
    })
    return results
  }

  private renderUiSchema(uiSchemaName, context) {
    const { settings } = this.props
    const { entity } = this.props
    const { errors } = this.state
    const schemas = entity.schemas
    const renderState = Object.assign({ settings }, this.state)
    const uiSchemaPath = `uiSchema.web.${uiSchemaName}`
    for (let i = schemas.length - 1; i >= 0; --i) {
      if (!_.get(schemas[i], uiSchemaPath)) {
        continue
      }
      return (
        <EntityRenderer
          actions={this}
          componentsMap={ComponentsMap}
          context={context}
          errors={errors}
          state={renderState}
          onChangeComplete={() => this.forceUpdate()}
          value={entity}
          uiContext={schemas[i]}
          uiSchemaPath={uiSchemaPath}
        />
      )
    }
  }

  private renderJSONTab = () => {
    const { entity, settings } = this.props

    if (!settings.isAdmin) {
      return
    }
    const detailTabPanel = (
      <div className='grid-block vertical'>
        <div className='grid-block'>
          <JSONEditor
            entity={entity}
            onChange={() => this.forceUpdate()}
          />
        </div>
        {this.renderSaveFooter()}
      </div>
    )
    return (
      <Tab
        id='entityCardJSON'
        key='json-tab'
        title='JSON'
        className='grid-block vertical'
        panel={detailTabPanel}
      />
    )
  }

  private renderSaveFooter() {
    const { isSaving } = this.state
    return (
      <Footer
        isPrimaryButtonLoading={isSaving}
        isVisible={this.isDirty()}
        onCancelButtonClick={this.handleRollback}
        onPrimaryButtonClick={this.handleSaveEntity}
      />
    )
  }

  private isDirty() {
    return this.props.entity.isDirty
  }

  private handleRollback = () => {
    const entity: any = this.props.entity
    entity.rollback()
    this.forceUpdate()
  }

  private handleSaveEntity = (): Promise<any> => {
    const { entity } = this.props
    this.setState({ isSaving: true })
    return entity.save()
      .then(() => this.setState({ errors: null }))
      .catch(({ errors }) => this.setState({ errors }))
      .finally(() => this.setState({ isSaving: false }))
  }

  private handleBlockTransition = (onTransition) => {
    const saveChanges = () => {
      this.handleSaveEntity().then(() => onTransition())
    }
    const discardChanges = () => {
      this.handleRollback()
      onTransition()
    }
    ConfirmationModal.open({
      confirmationText: 'Are you sure you want to leave this screen and discard your changes?',
      confirmationTitle: 'You have unsaved changes',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: saveChanges,
      onSecondaryClicked: discardChanges,
      primaryButtonText: 'Save Changes',
      secondaryButtonText: 'Discard Changes',
    })
  }

  private handleAssignCarrierQuote = (carrierQuote) => {
    const { entity } = this.props
    _.set(entity, 'brokerOrder.carrier', carrierQuote.carrier)
    const carrierQuoteMethod = _.get(carrierQuote, 'origin', null)
    _.set(entity, 'brokerOrder.carrierQuoteMethod', carrierQuoteMethod)
    this.forceUpdate()
  }
}

export default React.forwardRef((props: IOrderDetailCardProps, ref: React.Ref<OrderDetailCard>) => (
  <AppNavigatorContext.Consumer>
    {({ settings }) => (
      <SheetContext.Consumer>
        {({ openOverlay }) => (
          <OrderDetailCard {...props} openOverlay={openOverlay} settings={settings} ref={ref} />
        )}
      </SheetContext.Consumer>
    )}
  </AppNavigatorContext.Consumer>
))
