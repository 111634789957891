import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { CitySelect } from 'browser/components/atomic-elements/atoms/geo-region-input/city-select/city-select'
import { Header } from 'browser/components/atomic-elements/atoms/header/header'
import { DateInput } from 'browser/components/atomic-elements/atoms/input/date-input/date-input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { Sheet } from 'browser/components/atomic-elements/atoms/sheet'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { TextareaField } from 'browser/components/atomic-elements/molecules/fields/textarea-field'
import { Entity } from 'shared-libs/models/entity'

interface IPostToDATSheetProps extends IBaseProps {
  rate?: number
  comment?: string
  onSave: (value: any) => void
  onCancel: () => void
}

interface IPostToDATSheetStates {
  comment: string
  rate: number
  isSaving: boolean
}

export class PostToDATSheet extends React.Component<IPostToDATSheetProps, IPostToDATSheetStates> {

  private sheet: Sheet

  constructor(props) {
    super(props)
    this.state = {
      comment: props.comment,
      isSaving: false,
      rate: props.rate,
    }
  }

  public render() {
    const { rate, comment, isSaving } = this.state
    const handleRef = (ref) => { this.sheet = ref }
    return (
      <Sheet
        ref={handleRef}
        size='sm'
        bodyClassName='c-sheet-body--padded'
        header={
          <Header
            title='Post to DAT'
          />
        }
        footer={
          <Footer
            isPrimaryButtonLoading={isSaving}
            isVisible={true}
            onCancelButtonClick={this.handleCancel}
            primaryButtonText='Post'
            onPrimaryButtonClick={this.handleSave}
          />
        }
      >
        <InputField
          label='Offer Rate'
          isHorizontalLayout={true}
          onChange={this.handleOfferRate}
          value={rate}
        />

        <TextareaField
          isHorizontalLayout={true}
          label='Comment'
          onChange={this.handleComment}
          value={comment}
        />
      </Sheet>
    )
  }

  private handleOfferRate = (input) => {
    this.setState( { rate: input} )
  }
  private handleComment = (input) => {
    this.setState( { comment: input} )

  }
  private handlePropertyChange = (key, value) => {
    _.set(this.state, key, value)
  }

  private handleSave = () => {
    const { rate, comment } = this.state
    const { onSave } = this.props

    this.handleClose()
    onSave( { rate, comment })
    /*
    const  uniqueId = order.get('uniqueId')

    apis.datLoadBoardSync(uniqueId)
    order.waitUntilIdle()
    */
  }

  private handleCancel = () => {
    const { onCancel } = this.props

    this.sheet.close()
    onCancel()
  }

  private handleClose = () => {
    this.sheet.close()
  }
}
