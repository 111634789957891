import _ from 'lodash'

import { crumbIdFromNode, IStoryboardNode, StoryboardExecutionEntity } from './storyboard-execution'
import { StoryboardScene } from './storyboard-scene'
import { StoryboardTask } from './storyboard-task'
import { EXIT_ROUTE_KEY, WorkflowRoute, WorkflowRouteImpl } from './storyboard-utils'


const ROUTE = 'route'
const STORY = 'story'
const SCENE = 'scene'
const TASK = 'task'

export class StoryboardNavigator {
  public execution: StoryboardExecutionEntity

  public currentState: WorkflowRoute
  private history: WorkflowRoute[]

  public shouldTruncateStack: boolean = false

  constructor(execution) {
    this.execution = execution
  }

  public getRoute(state) { return state.task || state.scene }
  public get currentRoute() { return this.currentState?.route }
  public get currentNode() { return this.nodeFromState(this.currentState) }

  public navigateToStory(story): void {
    const shouldResume = story.resumeEnabled()
    this.history = shouldResume
      ? this.rebuildNavigation(story, this.execution.context)
      : this.initialNavigation(story, this.execution.context)
    this.currentState = this.history.pop()
  }

  public rebuildNavigation(story, context): WorkflowRoute[] {
    const history = []
    const seenNodes = new Set()

    let curNode = new WorkflowRouteImpl(story, null, null)

    // eslint-disable-next-line no-constant-condition
    while (true) {
      curNode = this.getNextActionableNode(curNode, context)

      if (!curNode) {
        break
      }

      const crumbId = this.crumbIdFromState(curNode)

      if (this.isExitNode(curNode) || seenNodes.has(crumbId)) {
        break
      }

      history.push(curNode)
      seenNodes.add(crumbId)

      const legacyCrumbId = curNode.scene.path.data.id
      if (!this.execution.isCompleted(crumbId) && !this.execution.isCompleted(legacyCrumbId)) {
        break
      }
    }

    return history
  }

  public initialNavigation(story, context): WorkflowRoute[] {
    const curNode = new WorkflowRouteImpl(story, null, null)
    return [this.getNextActionableNode(curNode, context)]
  }

  public getNextActionableNode(node, context) {
    if (this.isExitNode(node)) {
      return node
    }

    let nextNode = node

    do {
      nextNode = this.getNextNode(nextNode, context)
    } while (nextNode && !this.isExitNode(nextNode) && !this.isActionableNode(nextNode))

    return nextNode
  }

  public getNextNode(node, context) {
    const { story, scene, task } = node

    if (!scene) {
      const nextScene = story.getNextSceneRoute(null, context)
      return nextScene ? new WorkflowRouteImpl(story, nextScene, null) : undefined
    }

    const nextTask = scene.getPath().getNextTaskRoute(task && task.getPath() as StoryboardTask, context)
    if (nextTask) {
      return new WorkflowRouteImpl(story, scene, nextTask)
    }

    const nextScene = story.getNextSceneRoute(scene.getPath() as StoryboardScene, context)
    return nextScene ? new WorkflowRouteImpl(story, nextScene, null) : undefined
  }

  public isExitNode(node) {
    const { scene, task } = node

    return ((task && task.data.pathId === '__EXIT__')
      || (scene && scene.data.pathId === '__EXIT__'))
  }

  public isActionableNode(node) {
    if (node.task) {
      return true
    }

    if (node.scene) {
      return !_.isEmpty(node.scene.getPath().uiSchemaMobile)
        || !_.isEmpty(node.scene.getPath().uiSchemaWeb)
        || !_.isEmpty(node.scene.getPath().uiSchemaMobileWeb)
    }

    return false
  }

  public peekNext(context): WorkflowRoute {
    return this.getNextActionableNode(this.currentState, context)
  }

  public goNext(context): void {
    if (this.currentRoute) {
      this.execution.dropCompletedCrumb(this.currentNode)
    }

    if (!this.currentRoute.isImplicitTask()) {
      this.history.push(this.currentState)
    }
    this.currentState = this.peekNext(context)
    const currentContext = this.currentState.task?.data?.inputMappings
      || this.currentState.scene?.data?.inputMappings
      || []
    this.execution.dropViewedCrumb(this.currentNode, currentContext)
  }

  public peekBack(): WorkflowRoute {
    const hist = this.history
    const lastState = hist[hist.length - 1]
    const { currentState } = this

    if (!lastState) {
      return
    }

    const currentRoute = this.currentRoute
    const lastRoute = lastState.route

    const isExitCheckpoint = (x) => x && x.isExitCheckpoint
    if (_.some([lastRoute, _.get(lastState, 'task.path'), _.get(lastState, 'scene.path')], isExitCheckpoint)) {
      return null
    }

    const isEntryCheckpoint = (x) => x && x.isEntryCheckpoint
    if (_.some([currentRoute, _.get(currentState, 'task.path'), _.get(currentState, 'scene.path')], isEntryCheckpoint)) {
      return null
    }

    return lastState
  }

  public goBack(): void {
    this.currentState = this.history.pop()
  }

  public nodeFromState(state: WorkflowRoute): IStoryboardNode {
    return {
      story: state.story,
      scene: _.get(state, 'scene.path'),
      task: _.get(state, 'task.path'),
    }
  }

  private crumbIdFromState = (state: WorkflowRoute): string => {
    return crumbIdFromNode(this.nodeFromState(state))
  }

}
