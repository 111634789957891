import _ from 'lodash'
import React from 'react'

import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'

interface ISalesOrderSecondaryCardHeaderProps {
  entity: any
}

const SOLD_TO_PATH = 'soldTo'
const SOLD_TO_DESCRIPTION = 'Sold To'
const BILL_TO_PATH = 'billTo'
const BILL_TO_DESCRIPTION = 'Bill To'

export const SalesOrderSecondaryCardHeader: React.FC<ISalesOrderSecondaryCardHeaderProps> = (props) => {
  const { children, entity } = props

  const renderTitle = () => {
    return (
      <CardHeaderItem
        className='c-cardHeader-item--largeMargin'
        description='Order Number'
        title={(
          <span>{entity.displayName}</span>
        )}
      />
    )
  }

  const renderRoute = () => {
    const firstStop = _.first(entity.core_fulfilment_salesOrder.stops)
    const lastStop = _.last(entity.core_fulfilment_salesOrder.stops)
    const addressPath = ['location', 'denormalizedProperties', 'location.address']
    const firstAddress = _.get(firstStop, addressPath)
    const lastAddress = _.get(lastStop, addressPath)
    let orderStops = '-'

    if (firstAddress && lastAddress) {
      orderStops = `${firstAddress.locality}, ${firstAddress.region} - ${lastAddress.locality}, ${lastAddress.region}`
    }

    return (
      <CardHeaderItem
        className='c-cardHeader-item--largeMargin'
        key='lane'
        label={orderStops}
        description='Lane'
      />
    )
  }

  const renderCompany = (path: string, description: string) => {
    const company = entity.get(`core_fulfilment_salesOrder.${path}.company`)
    let companyName = '-'
    let onLabelClick = null
    if (company) {
      companyName = company.displayName
      onLabelClick = () => { window.open(`/entity/${company.entityId}`, '_blank') }
    }
    return (
      <CardHeaderItem
        className='c-cardHeader-item--largeMargin'
        description={description}
        key={path}
        label={companyName}
        onLabelClick={onLabelClick}
      />
    )
  }

  const renderMovementNumber = () => {
    const movementKey = 'core_integrations_tmwSuite_fulfilment_salesOrder.movementNumber'
    const movementNumber = entity.get(movementKey)

    if (!movementNumber) {
      return
    }

    return (
      <CardHeaderItem
        className='c-cardHeader-item--largeMargin'
        description='Movement Number'
        label={movementNumber}
      />
    )
  }



  return (
    <div className='u-flex u-noPrint'>
      {renderTitle()}
      {renderRoute()}
      {renderCompany(SOLD_TO_PATH, SOLD_TO_DESCRIPTION)}
      {renderCompany(BILL_TO_PATH, BILL_TO_DESCRIPTION)}
      {renderMovementNumber()}
      <CardHeaderItem className='c-cardHeader-item--grow' />
      {children}
    </div>
  )
}
