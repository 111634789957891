import React from 'react'
import { WebMercatorViewport } from 'viewport-mercator-project'

export interface ICanvasLayerProps {
  width: number
  height: number
  latitude: number
  longitude: number
  zoom: number
  redraw: (props: any) => void
  isDragging: boolean
}

export class CanvasLayer extends React.PureComponent<ICanvasLayerProps, any> {
  public overlay: any

  public componentDidMount() {
    this.redraw()
  }

  public componentDidUpdate() {
    this.redraw()
  }

  public render() {
    const pixelRatio = window.devicePixelRatio || 1
    const canvasProps = {
      height: this.props.height * pixelRatio,
      ref: (ref) => { this.overlay = ref },
      style: {
        height: `${this.props.height}px`,
        left: 0,
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        width: `${this.props.width}px`,
      },
      width: this.props.width * pixelRatio,
    }
    return React.createElement('canvas', canvasProps)
  }

  private redraw() {
    const pixelRatio = window.devicePixelRatio || 1
    const canvas = this.overlay
    const ctx = canvas.getContext('2d')
    ctx.save()
    ctx.scale(pixelRatio, pixelRatio)
    const { project, unproject } = new WebMercatorViewport(this.props)
    this.props.redraw({
      ctx,
      height: this.props.height,
      isDragging: this.props.isDragging,
      project,
      unproject,
      width: this.props.width,
    })
    ctx.restore()
  }
}
