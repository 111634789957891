import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/popover/_popover.scss'

export const Popover: React.FC<IBaseProps> = (props) => {
  const { className, children } = props
  return (
    <div className={classNames('c-tether-content', className)}>
      {children}
    </div>
  )
}
