import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { LoadMap } from 'browser/components/atomic-elements/atoms/mapbox/load-map'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import 'browser/components/atomic-elements/domains/trucking/order-tracking-map/_order-tracking-map.scss'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { ToggleField } from 'browser/components/atomic-elements/molecules/fields/toggle-field'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'

/**
 * @uiComponent
 */
interface IOrderTrackingMapProps extends IBaseProps {
  entity?: any
  loads?: any[]
  selection: any
  shouldAlwaysShowRoute?: boolean
  onSelect?: (value: any) => void
}

export class OrderTrackingMap extends React.Component<IOrderTrackingMapProps, any> {

  private dataSource: EntityDataSource

  constructor(props) {
    super(props)
    this.state = {
      checkCallsLocations: [],
      ...this.getNextStateForProps(props),
    }
  }

  public componentDidMount() {
    this.dataSource = this.createDataSource(this.props.entity)
    if (this.dataSource) { this.dataSource.find() }
  }

  public componentWillUnmount() {
    if (this.dataSource) { this.dataSource.dispose() }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { entity, loads } = this.props
    if (entity !== nextProps.entity || loads !== nextProps.loads) {
      this.dataSource = this.createDataSource(nextProps.entity)
      if (this.dataSource) { this.dataSource.find() }
      this.setState(this.getNextStateForProps(nextProps))
    }
  }

  public render() {
    const { checkCallsLocations, locations, orders } = this.state
    const { className, onSelect, selection, shouldAlwaysShowRoute } = this.props
    return (
      <div className={classNames('grid-block vertical', className)} >
        <div className='grid-block'>
          <AutofillBlock className='u-overflowHidden'>
            <LoadMap
              loads={orders}
              locationGeolocationPath='geolocation'
              locations={locations.concat(checkCallsLocations)}
              height={300}
              mapStyle='mapbox://styles/mapbox/light-v9'
              selection={selection}
              shouldAlwaysShowRoute={shouldAlwaysShowRoute}
              scrollZoomEnabled={true}
              onSelect={_.noop}
            />
          </AutofillBlock>
        </div>
      </div>
    )
  }

  private createDataSource(entity) {
    // TODO(Peter): this is crappy. We should revisit this when we rework the maps
    if (!entity) { return }
    const query = {
      entityType: '/1.0/entities/metadata/checkCall.json',
      filters: [
        {
          path: 'feedEvent.entity',
          type: 'matchEdge',
          value: {
            entityId: entity.uniqueId,
          },
        },
      ],
    }
    return new EntityDataSource(query).setOnChange(this.handleData)
  }

  private handleData = () => {
    const { checkCalls } = this.state
    const locations = _.map(this.dataSource.entities, (entity) => {
      const location = entity.get('checkCall.address')
      location.uniqueId = entity.get('uniqueId')
      return location
    })
    this.setState({ checkCallsLocations: locations })
  }

  private getNextStateForProps(props) {
    const { entity, loads } = props
    if (entity) {
      return {
        locations: getLocationsForOrders([entity]),
        orders: [augmentOrderWithRouteInfo(entity)],
      }
    }
    return {
      locations: getLocationsForOrders(loads),
      orders: _.map(loads, augmentOrderWithRouteInfo),
    }
  }
}

function augmentOrderWithRouteInfo(order) {
  const mapFunc = (point) => [point.longitude, point.latitude]
  const points = []
  const stops = _.get(order, 'dispatchOrder.stops', [])
  _.forEach(stops, (stop) => {
    const projectedRoute = _.get(stop, 'projectedRoute', [])
    points.push.apply(points, projectedRoute)
  })
  const pointsProjected = points.map(mapFunc)
  order.routeProgress = {
    metersTraveled: 0,
    points: pointsProjected,
    pointsPast: [],
    pointsProjected,
  }
  return order
}

function getLocationsForOrders(orders: any[]) {
  const locationsById = {}
  const path = ['denormalizedProperties', 'location.address']
  _.forEach(orders, (order) => {
    const stops = _.get(order, 'dispatchOrder.stops', [])
    _.forEach(stops, (stop: any) => {
      if (stop.location) {
        const address = _.cloneDeep(_.get(stop.location, path))
        address.uniqueId = stop.location.entityId
        locationsById[stop.location.entityId] = address
      }
    })
  })
  return _.values(locationsById)
}
