import React from 'react'

import LabelFieldFactory from 'browser/components/atomic-elements/higher-order-components/label-field-factory'
import {
  HoursOfOperationInput,
} from 'browser/components/atomic-elements/molecules/fields/hours-of-operation-field/hours-of-operation-input'
import {
  HoursOfOperationSheet,
} from 'browser/components/atomic-elements/molecules/fields/hours-of-operation-field/hours-of-operation-sheet'

export const HoursOfOperationField = LabelFieldFactory({
  InputComponent: HoursOfOperationInput,
  InputSheetComponent: HoursOfOperationSheet,
})
