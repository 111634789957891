import _ from 'lodash'

import { EntityType } from './entity-type'

export class Person extends EntityType {

  public get primaryEmail() {
    const emails = this.get('emails')
    const primaryEmail = _.find(emails, { label: 'Primary Email' })
    const result = primaryEmail ? primaryEmail : _.first(emails)
    return _.get(result, 'value')
  }

  public get name() {
    return `${this.get('firstName')} ${this.get('lastName')}`
  }
}
