import _ from 'lodash'
import React from 'react'
import moment from 'moment'

import { relativeDateModes, relativeDateModesByKey } from 'shared-libs/models/datetime/relative-date'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { RelativeDateRangePickerPopover } from 'browser/components/atomic-elements/atoms/date-picker/relative-date-range-picker-popover'
import { RELATIVE } from 'shared-libs/models/datetime/relative-date'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { Item } from '../item'
import { NegatableFilter } from '../negation-filter'

interface IRelativeDateRangeFilterItemProps extends IBaseProps {
  filter: object
  isButton: boolean
  isClearable: boolean
  onClear: () => void
  onChange: (value: any, ...args) => void
  showFilterValue: boolean
  tetherOptions: object
}

export class RelativeDateRangeFilterItem extends React.Component<IRelativeDateRangeFilterItemProps, any> {

  constructor(props) {
    super(props)
    this.state = {
      filter: _.cloneDeep(props.filter),
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.isEqual(this.props.filter, nextProps.filter)) {
      this.setState({ filter: _.cloneDeep(nextProps.filter) })
    }
  }

  public render() {
    const { isClearable } = this.props
    const { filter } = this.state

    return (
      <TetherTarget
        automaticAdjustOffset={true}
        openOnClick={isClearable}
        tetherOptions={this.props.tetherOptions}
        tethered={this.renderDateRangePickerPopover()}
      >
        <Item
          {...this.props}
          label={filter.label}
        >
          {this.getFilterDisplayValue()}
        </Item>
      </TetherTarget>
    )
  }

  private getFilterDisplayValue() {
    const { filter } = this.state
    const negationText = filter.isNegation ? "Excluding " : ""

    const startDate = this.getDisplayValue(filter.startDate ?? filter.gte)
    const endDate = this.getDisplayValue(filter.endDate ?? filter.lte)

    if (startDate && endDate) {
      return `${negationText}${startDate} - ${endDate}`
    } else if (startDate) {
      return `${negationText}after ${startDate}`
    } else {
      return `${negationText}before ${endDate}`
    }
  }

  public getDisplayValue(relativeDate) {
    if (!relativeDate) {
      return ''
    }

    if (relativeDate.mode === RELATIVE) {
      const offsetUnit = relativeDate.offsetUnit
      const offsetValue = parseInt(relativeDate.offsetValue)

      if (!offsetUnit) {
        return ''
      }

      const offsetLabel = relativeDateModesByKey[offsetUnit].format(offsetValue)

      if (offsetUnit === 'now') {
        return offsetLabel
      }

      return `${offsetLabel}`
    }

    return moment.utc(relativeDate.date).format('MMM D, YYYY')
  }

  private handleOnChange = (updatedFilter) => {
    this.props.onChange(updatedFilter)
  }

  private renderDateRangePickerPopover() {
    const { filter } = this.state
    return (
      <RelativeDateRangePickerPopover
        onChange={this.handleOnChange}
        filter={filter}
        preContent={this.renderNegationFilter()}
      />
    )
  }

  private handleNegation = (filter, ...args) => {
    this.setState({ filter })
    this.props.onChange(filter, ...args)
  }

  private renderNegationFilter() {
    return (
      <div className='u-innerBumperTop u-innerBumperLeft u-innerBumperBottom'>
        <NegatableFilter
          filter={this.props.filter}
          onChange={this.handleNegation}
        />
      </div>
    )
  }
}

