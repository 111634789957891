import React, { useState, Fragment } from 'react'
import _ from 'lodash'
import { Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'

import apis from 'browser/app/models/apis'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { ISalesOrdersViewHeaderEntitySelectionProps } from './sales-orders-view-header-buttons'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'

/**
 * Print the selected sales orders
 */
export const SalesOrdersViewHeaderPrintSelected: React.FC<ISalesOrdersViewHeaderEntitySelectionProps> = (props) => {
  const { selection } = props
  const [ isCreatingPDF, setIsCreatingPDF ] = useState(false)

  if (selection.length < 1) {
    return null
  }

  const salesOrderRelatedDocuments = (salesOrders)  => {
    const DOCUMENT_TYPE = '/1.0/entities/metadata/document.json'

    const orderIds = _.map(salesOrders, (order) => {
      return { entityId: _.get(order, 'uniqueId') }
    })

    if (_.isEmpty(orderIds)) {
      return Promise.resolve([])
    }

    const documentsDataSource = new EntityDataSource({
      entityType: DOCUMENT_TYPE,
      filters: [
        {
          entityType: DOCUMENT_TYPE,
          path: 'document.salesOrder',
          type: 'matchEdge',
          values: orderIds
        }
      ],
    })

    return documentsDataSource.collection.find()
  }

  const handleBatchPrint = () => {
    const salesOrders = _.map(selection, (entity) => entity.data)
    setIsCreatingPDF(true)
    return salesOrderRelatedDocuments(salesOrders)
      .then((docs) => {
        if (_.isEmpty(docs)) {
          return Promise.reject({ responseText: 'No associated documents'})
        }
        // sort the docs by the user selection
        const orderIds = _.map(salesOrders, 'uniqueId')
        const docIdsBySelection = _.reduce(orderIds, (acc, curr) => {
          const relatedDocs = _.filter(docs, (doc) => _.get(doc, 'data.document.salesOrder.entityId') === curr)
          const docIds = _.map(relatedDocs, 'data.uniqueId')
          return acc.concat(docIds)
        }, [])

        return apis.batchPrintDocuments(docIdsBySelection)
      }).then((response: string) => {
        window.open(response)
      }).catch((e) => {
        const errorMsg = _.get(e, 'responseText', 'Unknown')
        Toast.show({
          message: `Unable to print.  Error: ${errorMsg}`,
          position: Position.BOTTOM_RIGHT,
          timeout: 5000,
        })
      }).finally(()=> {
        setIsCreatingPDF(false)
      })
  }

  return (
    <Fragment>
      <Button
        className={classNames('mr2', Classes.iconClass(IconNames.PRINT))}
        isLoading={isCreatingPDF}
        onClick={handleBatchPrint}
      >
        Print Selected
      </Button>
    </Fragment>
  )
}
