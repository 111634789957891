import _ from 'lodash'
import React from 'react'

import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { LaneMap } from 'browser/components/atomic-elements/atoms/mapbox/lane-map'

interface IAnalyticsLaneMapProps extends IBaseProps {
  data: any[]
  summaryDataSet: any
  selection: any[]
}

export class AnalyticsLaneMap extends React.Component<IAnalyticsLaneMapProps, any> {
  public render() {
    return (
      <div className='grid-block'>
        <div className='grid-content collapse u-overflowHidden'>
          {this.renderCalloutCard()}
          <AutofillBlock>
            <LaneMap
              lanes={this.props.data}
              selection={this.props.selection}
              isZoomControlsEnabled={false}
            />
          </AutofillBlock>
        </div>
      </div>
    )
  }

  private renderCalloutCard() {
    const { summaryDataSet } = this.props
    return (
      <div className='c-card paper paper--zDepth-1 c-analyticsCalloutCard'>
        <div className='u-textCenter c-analyticsCalloutCard-section'>
          <h4>
            {summaryDataSet.collection.result.computations.countEntities}
            {/* {1234} */}
          </h4>
          <div className='c-label c-label--secondary'>
            # of Loads
          </div>
        </div>
        <div className='u-textCenter c-analyticsCalloutCard-section'>
          <h4>
            {summaryDataSet.collection.result.computations.uniqueCountLanes}
            {/* {43435} */}
          </h4>
          <div className='c-label c-label--secondary'>
            # of Lanes
          </div>
        </div>
      </div>
    )
  }
}
