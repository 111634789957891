import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { SelectList } from 'browser/components/atomic-elements/atoms/select/select-list'

export interface IEntitySelectPopoverProps extends IBaseProps {
  collection: any
  entityType: string
  inputValue: string
  optionLabelPath?: string
  optionValuePath?: string
  optionRenderer?: (props: any) => React.ReactElement<any>
  scrollToIndex: number
  value: any
}

interface IEntitySelectPopoverState {
  isOptionsLoaded: boolean
  isSearching: boolean
  options: any[]
}

export class EntitySelectPopover extends React.Component<IEntitySelectPopoverProps, IEntitySelectPopoverState> {
  public static defaultProps: Partial<IEntitySelectPopoverProps> = {
    optionLabelPath: 'displayName',
    optionValuePath: 'uniqueId',
  }

  public store: any

  constructor(props) {
    super(props)
    const { collection } = this.props
    this.state = {
      isOptionsLoaded: collection.result,
      isSearching: false,
      options: collection.content,
    }
  }

  public componentDidMount() {
    // if collection has not loaded initially, try to load
    const { isOptionsLoaded } = this.state
    if (!isOptionsLoaded) {
      this.handleInputChanged(null)
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.inputValue !== nextProps.inputValue) {
      this.handleInputChanged(nextProps.inputValue)
    }
  }

  public getOptions() {
    const { inputValue, optionLabelPath, optionValuePath, value } = this.props
    const { options } = this.state
    if (value) {
      const isValueInOptions = _.find(options, {
        [optionValuePath]: value.entityId,
      })
      // add value to options if the following criterias are met
      // 1. if searchQuery does not exists
      // 2. value is not in option
      if (_.isEmpty(inputValue) && !isValueInOptions) {
        return [value].concat(options)
      }
    }
    return options
  }

  public handleInputChanged = (input) => {
    input = input ? input.trim() : null
    const { collection } = this.props
    const { isOptionsLoaded } = this.state
    // we also need to load the selected entity to get the name
    collection.cancel()
    collection.query.setQuery(input)
    this.setState({ isSearching: true })
    return collection.find().then((entities) => {
      this.setState({
        isOptionsLoaded: true,
        isSearching: false,
        options: entities,
      })
    })
  }

  public handleLoadMoreData = (offset) => {
    const { collection } = this.props
    collection.next().then((content) => {
      this.setState({ options: content })
    })
  }

  public render() {
    const {
      collection,
      optionLabelPath,
      optionRenderer,
      scrollToIndex,
      value,
    } = this.props
    const { isOptionsLoaded, isSearching } = this.state
    return (
      <Popover className='collapse'>
        <SelectList
          hasNext={collection.hasNext()}
          isLoading={!isOptionsLoaded}
          isSearching={isSearching}
          onLoadMore={this.handleLoadMoreData}
          onSearch={this.handleInputChanged}
          optionRenderer={optionRenderer}
          options={this.getOptions()}
          scrollToIndex={scrollToIndex}
          searchQuery={collection.query}
          selection={value}
          width={240}
        />
      </Popover>
    )
  }
}
