import _ from 'lodash'
import { TryAsync } from './utils'

/**
 * Convert the url query into view props. The field could be a json path like "a.b.c"
 * or a blob of raw json value.   The blob json must be in under "jsonProps" field
 *
 * Ex:  Deeplink with fields, example only without any uri encoding
 *   http:\\app.withvectorcom\sign-in?user.companyCode=1234&jsonProps={"userName": "minhtngo@yahoo.com", "firstName": "minh"}
 *
 *  The query will be converted into
 *
 *  {
 *    user: {
 *      companyCode: 1234
 *    },
 *    userName: 'minhtngo@yahoo.com',
 *    firstName; 'minh'
 *  }
 */
export function normalizeUrlQuery(query: any) {
  const jsonPropsField = _.get(query, 'jsonProps')
  const otherFields = _.omit(query, 'jsonProps')

  const jsonProps = normalizeJsonPropsField(jsonPropsField)
  const otherProps = normalizeUrlQueryFields(otherFields)

  return _.merge(jsonProps, otherProps)
}

function normalizeUrlQueryFields(fields: any) {
  if (_.isEmpty(fields)) {
    return {}
  }

  // eslint-disable-next-line no-useless-escape
  const numberRegex = new RegExp(`^[+-]?[0-9]+(\.[0-9]*)?$`)
  const boolRegex = new RegExp(`true|false`)
  const props = {}

  for (const pathSpec of Object.keys(fields)) {
    const value = fields[pathSpec]
    let parsedValue: any = value
    if (typeof value === 'string') {
      if (boolRegex.test(value)) {
        parsedValue = value === 'true'
      } else if (numberRegex.test(value)) {
        parsedValue = parseFloat(value)
      } else if (value.length > 1 && value[0] === '"' && value[value.length - 1] === '"') {
        // Strip the quotes off the beginning and end
        parsedValue = value.substr(1, value.length - 2)
      }
    }
    _.set(props, pathSpec, parsedValue)
  }
  return props
}

function normalizeJsonPropsField(jsonBlob: any) {
  try {
    return jsonBlob ? JSON.parse(decodeURIComponent(jsonBlob)) : {}
  } catch(err) {
    console.warn('Unable to parse json props', jsonBlob, err)
  }
  return {}
}

/**
 * Gather the entity ids from all known prop names
 */
export function getEntityIds(props: any) {
  const { docTypeId = null, docTypeIds = [], entityId = null, entityIds = [] } = props
  return _.uniq(_.compact([docTypeId, ...docTypeIds, entityId, ...entityIds]))
}

/**
 * Detect a container ID and resolve it to the plan ID, to be handled
 * downstream.
 */
export async function maybeResolveContainerId(entityIds: string[], api: any): Promise<string[]> {
  if (_.size(entityIds) === 1) {
    const entityId = entityIds[0]
    const planJson = await TryAsync(() => api.getContainerActivePlan(entityId))
    const planId = planJson?.uniqueId
    if (planId) {
      return [planId]
    }
  }
  return entityIds
}
