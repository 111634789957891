import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'

/**
 * @uiComponent
 */
interface IViewEditorProps extends IBaseProps {
  view: any
}

interface IViewEditorState {
  isLoading: boolean
  isSaving: boolean
}

export class ViewEditor extends React.Component<IViewEditorProps, IViewEditorState> {

  public store: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      isLoading: false,
      isSaving: false,
    }
  }

  public render() {
    if (this.state.isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <div className={this.props.className}>
        {this.renderGeneralSection()}
        {this.renderSettingsSection()}
      </div>
    )
  }

  private renderGeneralSection() {
    return (
      <Section
        title='General'
      >
        <div className='row'>
          <div className='col-xs-4'>
            <p>
              Internal views, external views. Applications and pages.
            </p>
          </div>
          <div className='col-xs-8'>
            <InputField
              helpText='Eg. TODO'
              label='View Name'
            />
          </div>
        </div>
      </Section>
    )
  }

  private renderSettingsSection() {
    return (
      <Section
        title='View Settings'
      >
        <div className='row'>
          <div className='col-xs-4'>
            <p>
              TBD - What kind of settings go in here?
            </p>
          </div>
          <div className='col-xs-8'>
            Settings relevant to the view. Page views could have filters. Application views could have what?
          </div>
        </div>
      </Section>
    )
  }
}
