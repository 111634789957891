import React, { PureComponent } from 'react'

import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { default as SheetPortal } from 'browser/components/atomic-elements/atoms/sheet/sheet-portal'
import { LabelFormGroup } from 'browser/components/atomic-elements/molecules/label-form-group/label-form-group'

const GRAY = '#9e9e9e'

interface IDefaultValueSheetProps {
  isOpen: boolean
  onClose: (Entity?) => void
  columnName: string
}

interface IDefaultValueSheetState {
  defaultValue: string
}

export class DefaultValueSheet extends PureComponent<IDefaultValueSheetProps, IDefaultValueSheetState> {
  private defaultValueInput

  constructor(props) {
    super(props)
    this.state = {
      defaultValue: '',
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.setState({ defaultValue: nextProps.defaultValue })
      setTimeout(() => this.defaultValueInput.focus(), 0) // need to delay until after render
    }
  }

  public render() {
    const { columnName } = this.props
    return (
      <SheetPortal
        isOpen={this.props.isOpen}
        footer={this.renderAddCustomDocTypeFooter()}
        size='sm'
      >
        <div className='c-sheet-body--padded'>
          <Section
            title={`Adding ${columnName} Column...`}
            style={{ marginBottom: '0' }}
          >
            <div style={{ marginBottom: '0.5rem', color: GRAY }}>
              Add default value for new column below if desired
            </div>
            <FormTable>
              <LabelFormGroup
                label='Default value'
                onChange={this.handleNewNameChange}
                ref={(input) => { this.defaultValueInput = input }}
                labelProps={{ className: 'c-label--isHorizontal' }}
                value={this.state.defaultValue}
              >
                <Input onKeyDown={this.handleNewNameKeyDown} />
              </LabelFormGroup>
            </FormTable>
          </Section>
        </div>
      </SheetPortal>
    )
  }

  private renderAddCustomDocTypeFooter() {
    return (
      <Footer
        primaryButtonText="Add Column"
        onPrimaryButtonClick={this.handleSaveDefaultValue}
        onCancelButtonClick={() => this.props.onClose(null)}
      />
    )
  }

  private handleNewNameKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleSaveDefaultValue()
    }
  }

  private handleSaveDefaultValue = () => this.props.onClose(this.state.defaultValue)

  private handleNewNameChange = (defaultValue) => this.setState({ defaultValue })
}
