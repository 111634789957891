import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Hotkey, Hotkeys, HotkeysTarget, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/carousel/_carousel.scss'

interface ICarouselProps extends IBaseProps {
  index: number
  numItems: number
  onChange?: (index: number) => void
  renderCarouselItem: (index: number) => any
  showChevrons?: boolean
}

@HotkeysTarget
export class Carousel extends React.Component<ICarouselProps, any, never> {

  public static defaultProps: Partial<ICarouselProps> = {
    onChange: _.noop,
    showChevrons: false,
  }

  public render() {
    const { children, index, renderCarouselItem } = this.props
    return (
      <div
        className={classNames('carousel', this.props.className)}
        data-debug-id={this.props['data-debug-id']}
      >
        <div className='carousel-inner'>
          {renderCarouselItem(index)}
        </div>
        {this.renderChevrons()}
      </div>
    )
  }

  public renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          global={true}
          group='Carousel'
          combo={'up'}
          label={'Previous Image'}
          onKeyDown={this.handleOnPrev}
        />
        <Hotkey
          global={true}
          group='Carousel'
          combo={'down'}
          label={'Next Image'}
          onKeyDown={this.handleOnNext}
        />
        <Hotkey
          global={true}
          group='Carousel'
          combo={'enter'}
          label={'Go To Next Image'}
          onKeyDown={this.handleOnNext}
        />
      </Hotkeys>
    )
  }

  private handleOnNext = () => {
    const { index, numItems } = this.props
    const nextIndex = (index + 1) % numItems
    this.props.onChange(nextIndex)
  }

  private handleOnPrev = () => {
    const { index, numItems } = this.props
    let prevIndex = index - 1
    if (prevIndex < 0) {
      prevIndex = numItems - 1
    }
    this.props.onChange(prevIndex)
  }

  private renderChevrons() {
    const { numItems, showChevrons } = this.props
    const shouldRenderChevrons = showChevrons && numItems > 1
    if (!shouldRenderChevrons) { return }
    return (
      <div>
        <a
          className='left carousel-control'
          onClick={this.handleOnPrev}
        >
          <div className='chevron-wrapper'>
            <Icon
              className='chevron-icon-left'
              icon={IconNames.CHEVRON_LEFT}
            />
          </div>
        </a>
        <a
          className='right carousel-control'
          onClick={this.handleOnNext}
        >
          <div className='chevron-wrapper'>
            <Icon
              className='chevron-icon-right'
              icon={IconNames.CHEVRON_RIGHT}
            />
          </div>
        </a>
      </div>
    )
  }
}
