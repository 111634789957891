import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/label/_label.scss'
import { translateString } from 'shared-libs/helpers/utils'
import { FramesManager } from 'shared-libs/components/view/frames-manager'

/**
 * @uiComponent
 */
export interface ILabelProps extends IBaseProps {
  frames?: FramesManager
  hasError?: boolean
  isHorizontalLayout?: boolean
  isOptional?: boolean
  isRequired?: boolean
  isSecondary?: boolean
  // TODO(peter/louis): need to look at this. Only made this that I could use the property in json.
  text?: string
  size?: string
  multiLine?: boolean
  widthClassName?: string
}

export class Label extends React.Component<ILabelProps, any> {
  public static defaultProps: ILabelProps = {
    children: [],
    isOptional: false,
    multiLine: true,
    widthClassName: '',
  }

  public render() {
    const {
      frames,
      widthClassName,
      children,
      text,
      className,
      isHorizontalLayout,
      isRequired,
      isSecondary,
      isOptional,
      hasError,
      multiLine,
      size,
    } = this.props
    let sizeClassName
    const contentWidthClassName = isHorizontalLayout ? widthClassName : ''

    if (isHorizontalLayout && !_.isEmpty(size)) {
      sizeClassName = `c-label--${size}`
    }

    const translationTable = frames?.getContext('translationTable')
    const translatedText = translateString(text, translationTable)

    // Note that if children == null, then the CSS will automatically
    // display:none the label element.
    const content = _.isEmpty(children) ? translatedText : children
    return (
      <label
        className={classNames('c-label', contentWidthClassName, sizeClassName, className, {
          'c-label--error': hasError,
          'c-label--isHorizontal': isHorizontalLayout,
          'c-label--isOptional': isOptional,
          'c-label--isRequired': isRequired,
          'c-label--secondary': isSecondary,
          'u-ellipsis': !multiLine,
        })}
      >
        {content}
      </label>
    )
  }
}
