import _ from 'lodash'
import moment from 'moment-timezone'
import Promise from 'bluebird'
import React from 'react'

import { dateShortcuts, dateShortcutsByKey } from 'shared-libs/models/datetime/shortcuts'
import { relativeDateModes, relativeDateModesByKey, FIXED, RELATIVE, RelativeDateFilterRange } from 'shared-libs/models/datetime/relative-date'

import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { DateField } from 'browser/components/atomic-elements/molecules/fields/date-field'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { ErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/error-block'
import { SectionHeader } from 'browser/components/atomic-elements/atoms/section/section-header/section-header'

import 'browser/components/atomic-elements/atoms/date-picker/_relative-date-picker.scss'
import { Checkbox } from '../checkbox/checkbox'

export interface IRelativeDateRangePickerProps extends IBaseProps {
  onChange: (value: object, isShortcutClicked?: boolean) => void
  onClose?: () => void
  filter: RelativeDateFilterRange
  onResize?: () => void
}

interface IRelativeDateRangePickerState {
  filter: RelativeDateFilterRange
}


export class RelativeDateRangePicker extends React.Component<
  IRelativeDateRangePickerProps,
  IRelativeDateRangePickerState
> {

  // save latest user values for convenience when toggling a date back on
  private savedUserValues: Record<string, any> = {}

  constructor(props) {
    super(props)
    const newFilter = _.cloneDeep(props.filter)
    const tz = moment.tz.guess()

    if (!newFilter.startDate && !newFilter.endDate) {
      // only initialize if both are missing, as one being missing could be intential
      newFilter.startDate = { mode: FIXED, date: newFilter['gte'] || moment.utc().startOf('day') }
      newFilter.endDate = { mode: FIXED, date: newFilter['lte'] || moment.utc().startOf('day') }
    }

    delete newFilter.gte
    delete newFilter.lte

    if (newFilter.startDate) {
      newFilter.startDate.timezone = tz
    }

    if (newFilter.endDate) {
      newFilter.endDate.timezone = tz
    }

    this.state = {
      filter: newFilter,
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state && !_.isEqual(this.props.filter, nextProps.filter)) {
      const { startDate, endDate } = this.state.filter

      this.setState({
        filter: _.merge(nextProps.filter, { startDate, endDate }),
      })
    }
  }

  public render() {
    const { filter } = this.state
    const errors = this.getErrorsFromFilter(filter)
    const warnings = this.getWarningsFromFilter(filter)

    return (
      <div className="c-relativeDateRangePicker">
        <div className="c-relativeDateRangePicker-dates">
          {this.renderDateInputs('startDate', 'Start Date')}
          {this.renderDateInputs('endDate', 'End Date')}
        </div>

        <SelectField
          className="mv3 mh3"
          onChange={this.applyShortcut}
          options={dateShortcuts}
          optionLabelPath="label"
          optionValuePath="key"
          placeholder="Select Pre-Configured Time Period"
        />
        {this.renderErrors(warnings, true)}
        {this.renderErrors(errors)}

        <Footer
          onCancelButtonClick={this.props.onClose}
          onPrimaryButtonClick={this.handleFilterApply}
          isPrimaryButtonDisabled={errors.length !== 0}
          primaryButtonText="Apply"
        />
      </div>
    )
  }

  public getWarningsFromFilter(filter) {
    const errors = []

    if (filter.startDate?.mode === RELATIVE && filter.endDate?.mode === FIXED) {
      errors.push(
        'Mixing a relative start date with a fixed end date may eventually cause the two dates to cross over'
      )
    }

    return errors
  }

  public handleFilterApply = () => {
    const { filter } = this.state
    const { onChange, onClose } = this.props

    onChange(filter)
    onClose()
  }

  public renderDateInputs(datePath, label) {
    const { filter } = this.state

    const enabled = !!filter[datePath]

    return (
      <div className="c-relativeDatePicker">
        <div className="mh3">
          <SectionHeader
            title={label}
            className="mv2"
            headerControls={
              <Checkbox value={enabled} onChange={this.handleChecked(datePath)} />
            }
          />
          {enabled && this.renderDateInputContents(datePath)}
        </div>
      </div>
    )
  }

  public renderDateInputContents(datePath) {
    const { filter } = this.state
    return (
      <>
        <SelectField
          onChange={this.handleDateModeChange(datePath)}
          options={this.getRelativeDateModeOptions()}
          optionLabelPath="label"
          optionValuePath="value"
          value={filter[datePath].mode}
        />
        <DateField
          value={this.getFixedDate(filter[datePath])}
          isDisabled={filter[datePath].mode === RELATIVE}
          onChange={this.setFieldUnderDatePath(datePath, 'date')}
          defaultHours={0}
          defaultMinutes={0}
          defaultTimezone={'UTC'}
        />
        {this.renderRelativeDateInputs(datePath)}
      </>
    )
  }

  public getErrorsFromDate(relativeDate, label) {
    const errors = []

    if (!relativeDate) {
      return errors
    }

    if (relativeDate.mode === RELATIVE) {
      if (relativeDate.offsetUnit !== 'now') {
        if (isNaN(relativeDate.offsetValue)) {
          errors.push(`Please provide an relative offset for ${label}`)
        }
      }
    } else {
      if (!moment.utc(relativeDate.date).isValid()) {
        errors.push(`Please specify a date for ${label}`)
      }
    }

    return errors
  }

  public getErrorsFromFilter(filter) {
    const errors = []

    if (!filter.startDate && !filter.endDate) {
      errors.push('Please provide at least the start or end date')
    }

    errors.push(...this.getErrorsFromDate(filter.startDate, 'start date'))
    errors.push(...this.getErrorsFromDate(filter.endDate, 'end date'))

    return errors
  }

  public renderErrors(errors, isWarning = false) {
    if (errors.length === 0) {
      return
    }

    return (
      <ErrorBlock
        errorText={
          <div className="ph4 pv2">
            <ul>
              {errors.map((e, index) => (
                <li key={index}>{e}</li>
              ))}
            </ul>
          </div>
        }
        isWarning={isWarning}
      />
    )
  }

  public renderRelativeDateInputs(datePath) {
    const { filter } = this.state
    const relativeDate = filter[datePath]

    if (relativeDate.mode !== RELATIVE) {
      return
    }

    let maybeValueInput = null
    if (relativeDate.offsetUnit !== 'now') {
      maybeValueInput = (
        <Input
          inputType="number"
          placeholder="0"
          onChange={this.setFieldUnderDatePath(datePath, 'offsetValue')}
          value={relativeDate.offsetValue}
        />
      )
    }

    const relativeUnitOptions = relativeDateModes.map((option) => {
      return {
        value: option.getKey(),
        label: option.label,
      }
    })

    return (
      <div>
        <SelectField
          onChange={this.setFieldUnderDatePath(datePath, 'offsetUnit')}
          options={relativeUnitOptions}
          optionLabelPath="label"
          optionValuePath="value"
          value={relativeDate.offsetUnit}
        />
        {maybeValueInput}
      </div>
    )
  }

  public setFieldUnderDatePath = (datePath, fieldPath) => {
    const { onResize } = this.props

    return (value) => {
      const { filter } = this.state
      filter[datePath][fieldPath] = value
      this.setState({ filter }, onResize)
    }
  }

  public getFixedDate(relativeDate) {
    if (relativeDate.mode === RELATIVE) {
      const offsetUnit = relativeDate.offsetUnit
      const offsetValue = relativeDate.offsetValue

      if (offsetUnit && offsetValue) {
        return relativeDateModesByKey[offsetUnit].calculateDate(moment.utc(), offsetValue)
      } else {
        return null
      }
    }

    return moment.utc(relativeDate.date)
  }

  public handleChecked(datePath) {
    return (checked) => {
      const { onResize } = this.props
      const { filter } = this.state

      if (checked) {
        // restore
        filter[datePath] = this.savedUserValues[datePath] ?? { mode: FIXED, date: moment.utc().startOf('day') }
      } else {
        // save
        this.savedUserValues[datePath] = filter[datePath]
        delete filter[datePath]
      }

      this.setState({ filter }, onResize)
    }
  }

  public handleDateModeChange(datePath) {
    return (mode) => {
      const { filter } = this.state
      const oldMode = filter[datePath].mode

      if (oldMode == mode) {
        return
      }

      filter[datePath].mode = mode

      if (mode == RELATIVE) {
        const oldDate = filter[datePath].date
        let newOffset = moment.utc().diff(moment.utc(oldDate), 'days')

        if (newOffset < 0) {
          newOffset = 0
        }

        filter[datePath].offsetUnit = 'days'
        filter[datePath].offsetValue = newOffset

        delete filter[datePath].date
      } else {
        const oldOffsetUnit = filter[datePath].offsetUnit
        const oldOffsetValue = filter[datePath].offsetValue

        const relativeDateMode = relativeDateModesByKey[oldOffsetUnit]
        filter[datePath].date = relativeDateMode.calculateDate(moment.utc(), oldOffsetValue)

        delete filter[datePath].offsetUnit
        delete filter[datePath].offsetValue
      }

      const { onResize } = this.props
      this.setState({ filter }, onResize)
    }
  }

  public getRelativeDateModeOptions() {
    return [
      {
        value: FIXED,
        label: 'Fixed Date',
      },
      {
        value: RELATIVE,
        label: 'Relative Date Offset',
      },
    ]
  }

  public applyShortcut = (shortcutKey) => {
    const shortcut = dateShortcutsByKey[shortcutKey]

    const { startDate, endDate } = shortcut.getOffsets()
    const { filter } = this.state

    startDate.timezone = filter.startDate.timezone
    endDate.timezone = filter.endDate.timezone

    filter.startDate = startDate
    filter.endDate = endDate

    const { onChange, onClose } = this.props
    onChange(filter)
    onClose()
  }
}
