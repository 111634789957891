import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import {
  HtmlTableRow,
  IHtmlTableRowProps,
} from 'browser/components/atomic-elements/atoms/table/html-table/row'
import { InlineEditable } from 'browser/components/atomic-elements/molecules/inline-editable'
import { IListItemSelectionOptions } from 'browser/components/atomic-elements/atoms/list/abstract-list'

/**
 * @uiComponent
 */
interface InlineEditableHtmlTableRowProps extends IHtmlTableRowProps {
  isDisabled?: boolean
  isSelectable?: boolean
  isEditableInline: boolean
  onSave: () => void
  validate: (value: any) => void
  selectionOptions?: IListItemSelectionOptions
}

export class InlineEditableHtmlTableRow extends React.Component<
  InlineEditableHtmlTableRowProps,
  any
> {
  public render() {
    const {
      density,
      className,
      isDisabled,
      isEditableInline,
      isStatic,
      onChange,
      onRemove,
      onSave,
      size,
      validate,
      value,
    } = this.props
    if (isEditableInline) {
      const densityClass = _.isEmpty(density) ? '' : `c-inlineEditableTableRow--${density}`
      return (
        <InlineEditable
          className={classNames('c-inlineEditableTableRow', densityClass, className)}
          isDisabled={isDisabled}
          isStatic={isStatic}
          onChange={onChange}
          onDelete={onRemove}
          onSave={onSave}
          size={size}
          validate={validate}
          value={value}
        >
          <HtmlTableRow {...this.props} />
        </InlineEditable>
      )
    } else {
      return <HtmlTableRow {...this.props} />
    }
  }
}
