import { Classes, Radio } from '@blueprintjs/core'
import { browserHistory } from 'browser/history'
import _ from 'lodash'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'
import { ViewScope } from 'shared-libs/models/types/view'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { RadioGroupField } from 'browser/components/atomic-elements/atoms/radio/radio-group-field'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { makeViewLink } from './utils'

interface IViewConfigurationModalProps extends IBaseProps {
  defaultView: Entity
  isDeletable: boolean
  onClose?: () => void
  view: Entity
  canCreateFirmView?: boolean

  basePath: string
}

interface IViewConfigurationModalState {
  isDeleting: boolean
  isSaving: boolean
  name: string
  scope: ViewScope
}

export class ViewConfigurationModal
  extends React.Component<IViewConfigurationModalProps, IViewConfigurationModalState> {

  public static open(props?: IViewConfigurationModalProps) {
    return OverlayManager.openOverlayElement(
      <Modal
        onClose={props.onClose}
      >
        <ViewConfigurationModal {...props} />
      </Modal>,
    )
  }

  constructor(props: IViewConfigurationModalProps) {
    super(props)
    this.state = {
      isDeleting: false,
      isSaving: false,
      name: props.view.displayName,
      scope: _.get(props.view, 'view.scope') === ViewScope.personal || !props.canCreateFirmView
        ? ViewScope.personal
        : ViewScope.firm,
    }
  }

  public render() {
    const { onClose } = this.props
    const { isDeleting, isSaving, name } = this.state
    const handleNameChange = (newName) => this.setState({ name: newName })
    return (
      <div>
        <div className='c-modalHeader'>
          <h4 className='c-modal-title'>
            View Settings
          </h4>
        </div>
        <div className='c-modalBody u-textCenter'>
          <FormTable>
            <InputField
              autoFocus={true}
              isHorizontalLayout={true}
              label='View Name'
              onChange={handleNameChange}
              placeholder='View Name'
              value={name}
            />
            {this.renderScopeFields()}
          </FormTable>
        </div>
        <Footer
          cancelButtonGroupElement={this.renderDeleteButton()}
          isCancelButtonDisabled={isDeleting || isSaving}
          isVisible={true}
          isPrimaryButtonDisabled={isDeleting}
          isPrimaryButtonLoading={isSaving}
          onCancelButtonClick={onClose}
          onPrimaryButtonClick={() => this.handleSaveView()}
        />
      </div>
    )
  }

  private renderScopeFields() {
    const { canCreateFirmView } = this.props
    const { scope } = this.state
    const isScopeDisabled = !canCreateFirmView
    if (isScopeDisabled) {
      return (
        <InputField
          isHorizontalLayout={true}
          label='View Scope'
          isDisabled={true}
          value={_.capitalize(scope)}
        />
      )
    } else {
      return (
        <RadioGroupField
          isHorizontalLayout={true}
          isOptionsHorizontal={true}
          label='View Scope'
          onChange={this.handleInputChange}
          value={scope}
        >
          <Radio
            label='Personal'
            value={ViewScope.personal}
            />
          <Radio
            label='Global'
            value={ViewScope.firm}
            />
        </RadioGroupField>
      )
    }
  }

  private renderDeleteButton() {
    const { isDeletable } = this.props
    const { isDeleting, isSaving } = this.state
    if (isDeletable) {
      return (
        <Button
          className={Classes.INTENT_DANGER}
          isDisabled={isSaving}
          isLoading={isDeleting}
          onClick={this.handlePromptDeleteView}
        >
          Delete
        </Button>
      )
    }
  }

  private handleInputChange = (value) => {
    this.setState({ scope: value })
  }

  private handlePromptDeleteView = (viewToDelete) => {
    ConfirmationModal.open({
      confirmationText: 'Are you sure you want to delete this view?',
      confirmationTitle: 'Delete View',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: this.handleDeleteView,
      primaryButtonText: 'Delete',
      secondaryButtonText: 'Cancel',
    })
  }

  private handleDeleteView = () => {
    const { defaultView, onClose, view, basePath } = this.props

    const savedViewLink = makeViewLink(basePath, defaultView.uniqueId)
    this.setState({ isDeleting: true })
    view.delete().then(() => {
      browserHistory.push({ pathname: savedViewLink })
      onClose()
    })
  }

  private handleSaveView = () => {
    const { onClose, view, defaultView, basePath } = this.props

    const savedViewLink = makeViewLink(basePath, defaultView.uniqueId, view.uniqueId, false)

    const { scope, name } = this.state
    const uniqueId = view.get('uniqueId')
    view.set('view.name', name)
    view.set('view.scope', scope)
    this.setState({ isSaving: true })
    view.save().then(() => {
      browserHistory.push({ pathname: savedViewLink })
      onClose()
    })
  }
}
