import $ from 'jquery'
import _ from 'lodash'
import React from 'react'
import * as TextMask from 'vanilla-text-mask'

import { IInputProps, Input } from 'browser/components/atomic-elements/atoms/input/input'

export interface ITextMaskInputProps extends IInputProps {
  guide?: boolean
  keepCharPositions?: boolean
  mask?: any
  pipe?: (vlaue: number | string) => any
  placeholderChar?: string
  showMask?: boolean
}

export class TextMaskInput extends React.Component<ITextMaskInputProps, any> {

  private input: Input
  private maskedInputController: any

  public componentDidMount() {
    const { guide, mask, pipe, placeholderChar, keepCharPositions, showMask, value } = this.props
    const inputElement = this.input.getElement()
    if (!_.isNil(value)) {
      $(inputElement).val(value)
    }
    this.maskedInputController = TextMask.maskInput({
      guide,
      inputElement,
      keepCharPositions,
      mask,
      pipe,
      placeholderChar,
      showMask,
    })
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = this.props
    if (value !== nextProps.value) {
      const inputElement = this.input.getElement()
      $(inputElement).val(nextProps.value)
    }
  }

  public componentWillUnmount() {
    this.maskedInputController.destroy()
  }

  public focus() {
    this.input.focus()
  }

  public render() {
    // we want to ommit all the props from TextMaskInput.propTypes
    const {
      guide, mask, pipe, placeholderChar, keepCharPositions, showMask,
      ...inputProps
    } = this.props
    const handleInputRef = (ref) => { this.input = ref }
    return (
      <Input
        {...inputProps}
        format='none'
        isControlled={false}
        ref={handleInputRef}
        type='text'
      />
    )
  }
}
