import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

import 'browser/components/atomic-elements/organisms/dashboard/_dashboard.scss'

export const Dashboard: React.FC<IBaseProps> = (props: IBaseProps) => {
  const { children, className } = props
  return (
    <div className={classNames('c-dashboard', className)}>
      {children}
    </div>
  )
}
