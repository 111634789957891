import $ from 'jquery'
import _, { filter } from 'lodash'
interface LogEntry {
  '@timestamp'?: string
  eventType: string
  userProperties?: any
  eventProperties?: any
  deviceProperties?: any
  logsene_orig_type: string
  level: string
  meta: any
}

declare let __GITHASH__: string
const SEMATEXT_ENDPOINT = 'https://logsene-receiver.sematext.com/_bulk'

export default class SemaText {
  private type: string
  private userProperties = {}
  private deviceProperties = {}
  private apiKey: string

  constructor(apiKey: string, type: string) {
    this.type = type
    this.apiKey = apiKey
  }

  public setUserProperties = (userProperties: any): void => {
    this.userProperties = _.clone(userProperties)
  }

  public setDeviceProperties = (deviceProperties: any): void => {
    this.deviceProperties = _.clone(deviceProperties)
  }

  public log(level: string, eventType: string, eventProperties?: any): void {
    const enrichedPayload: LogEntry = {
      eventType,
      logsene_orig_type: this.type,
      level,
      meta: {
        osType: _.get(this, 'userProperties.platform'),
        osRelease: window.navigator.userAgent,
        uuid: _.get(this, 'userProperties.userId'),
        versionName: __GITHASH__,
        geoLocation: window.navigator.geolocation,  // Will prompt user for permission
      }
    }
    enrichedPayload['@timestamp'] = new Date().toISOString()
    if (!_.isEmpty(this.userProperties)) {
      enrichedPayload.userProperties = this.userProperties
    }
    if (!_.isEmpty(this.deviceProperties)) {
      enrichedPayload.deviceProperties = this.deviceProperties
    }
    if (!_.isEmpty(eventProperties)) {
      // Source is a magic field to sematext, if not a string the record fails to parse.
      const filteredEventProperties = _.clone(eventProperties)
      if (filteredEventProperties.source) {
        filteredEventProperties.source = JSON.stringify(filteredEventProperties.source)
      }
      enrichedPayload.eventProperties = filteredEventProperties
    }
    const authHeader = {index: { _index: this.apiKey }}
    const data = JSON.stringify(authHeader) + '\n' + JSON.stringify(enrichedPayload)

    return $.ajax({
      method: 'POST',
      url: SEMATEXT_ENDPOINT,
      data,
      cache: false,
    }).then((data, status, xhr) => {
      console.log(`send log succeeded: ${status}\n${JSON.stringify(data)}`)
    }, (xhr, status, error) => {
      console.warn(`Send log failed: ${status}: ${error?.message}`)
    })
  }
}
