import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'

interface IEmailEventItemInterface extends IBaseProps {
  entity: any
  item: any
  isFirst: boolean
  isLast: boolean
}

export const EmailEventItem: React.FC<IEmailEventItemInterface> = (props) => {
  const { className, item, isFirst } = props
  const { creationDate, emailEvent, owner } = item
  const { subject, to, event, emailTimeStamp, documentsSent, failureMessage, attachmentsAsLinks } = emailEvent

  const renderContent = () => {
    const recipients = _.join(to, ', ')
    const docs = _.join(documentsSent, ', ')
    switch (event) {
      case 'Sent':
        return `${owner.user.displayName} sent ${docs} to ${recipients} with files ${attachmentsAsLinks ? "linked" : "attached"}.`
      case 'Delivered':
        return `Email '${subject}' delivered to ${recipients}.`
      case 'Opened':
        return `Email '${subject}' opened by ${recipients}.`
      case 'Temporary Failure':
        return `Email '${subject}' TEMPORARY failure. Reason: ${failureMessage}`
      case 'Failed':
        return `Email '${subject} HARD failure. Reason: ${failureMessage}`
      default:
        return 'Unknown Email Event.'
    }
  }

  const renderDate = () => {
    const parts = ['Email', event]
    if (event === 'Sent') {
      parts.push('-', moment(creationDate).calendar())
    } else if (emailTimeStamp) {
      parts.push('-', moment.unix(parseFloat(emailTimeStamp)).calendar())
    }
    return _.join(parts, ' ')
  }

  return (
    <div
      className={classNames('c-timelineEvent', className)}
      key={item.uniqueId}
    >
      <div
        className={classNames('c-timelineEvent-line', className, {
          'c-timelineEvent-line--topCutOff': isFirst,
        })}
      />
      <div className='c-timelineEvent-dot' />
      <div className='c-timelineEvent-contentContainer'>
        <div className='c-timelineEvent-body'>
          <HelpBlock>
            {renderDate()} <br />
            {renderContent()}
          </HelpBlock>
        </div>
      </div>
    </div>
  )
}
