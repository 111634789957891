import React from 'react'

/**
 * Injects a class component with an additional context property.
 *
 * NOTE: An annoyance here is that React.forwardRef returns an ExoticComponent,
 * which is not callable, which means any class component definition that uses
 * this HOC will no longer be inheritable (e.g. ViewRender -> AbstractRender).
 * If you try to subclass such a component, you'll get an exception in babel
 * helper _inherits: the `superClass` is an ExoticComponent, not a constructor
 * function. The problem being solved with React.forwardRef is that this ensures
 * that the ref is passed to the wrapped component and not just to this outer
 * HOC, but this blocks class inheritance, so it's a tradeoff. You'll likely need
 * to find a way to use composition in that case.
 */
export function withContext<T>(context: React.Context<T>, propName: string) {
  return function <P extends Record<string, any>>(WrappedComponent: React.ComponentType<P>): void {
    type ResultProps = P & { [propName: string]: React.Context<T> }

    const WithContext: React.RefForwardingComponent<P, ResultProps> = (props, ref) => {
      const contextProps = {
        [propName]: React.useContext(context),
      } as Pick<P, typeof propName>

      return <WrappedComponent {...contextProps} {...props} ref={ref} />
    }

    WithContext.displayName = `WithContext(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`

    return React.forwardRef(WithContext) as unknown as void
  }
}
