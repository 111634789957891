import React, { useState } from 'react'
import _ from 'lodash'
import { Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { INVOICE_PATH, RB_V1_CONFIG, RB_V2_CONFIG } from 'browser/app/constants/rendition-billing'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { RbStatusSelect } from '../../entity/rb-detail-card/rb-status-select'

/**
 * @prop selection - array of invoice entities selected by the user
 */
interface IRbViewHeaderInvoiceStatusSelectorProps extends IBaseProps {
  selection: any[]
}

/**
 * A status selector dropdown and button grouping that modifies the stage of selected invoices in invoicing views
 * 
 * @props IRbViewHeaderInvoiceStatusSelectorProps
 */
export const RbViewHeaderInvoiceStatusSelector: React.FC<IRbViewHeaderInvoiceStatusSelectorProps> = (props) => {
  const { selection } = props
  const [isLoading, setIsLoading] = useState(false)

  if (selection.length <= 1) {
    return null
  }

  // If/when Rendition Billing V1 is deprecated, simply remove RB_V1_CONFIG and refactor RB_V2_CONFIG constants
  const firstEntity = selection[0]['data']
  const isV2 = firstEntity && _.get(firstEntity, INVOICE_PATH, null)
  const archivedStatus = isV2 ? 'Archived' : 'Invoice archived'
  const entitySchemaConfig = isV2 ? RB_V2_CONFIG : RB_V1_CONFIG
  const { invoiceOptions, INVOICE_STATUS_PATH } = entitySchemaConfig

  const handleInvoiceStatusChange = (value) => {
    const entities = _.map(selection, (entity) => entity.data)
    _.forEach(entities, (entity) => {
      entity.set(INVOICE_STATUS_PATH, value)
    })
    const promises = _.map(entities, (entity) => entity.save())
    setIsLoading(true)
    Promise.all(promises).then((result) => {
      const waitUntilIdle = _.map(result, (entity) => entity.waitUntilIdle())
      return Promise.all(waitUntilIdle)
    }).then(() => {
      setIsLoading(false)
    })
  }

  const tetherOptions = {
    attachment: 'bottom right',
    targetAttachment: 'top right',
  }
  const tetheredView = (
    <Popover className='collapse' >
      <RbStatusSelect
        options={invoiceOptions}
        optionLabelPath='label'
        optionValuePath='value'
        onChange={handleInvoiceStatusChange}
        value={null}
      />
    </Popover>
  )

  return (
    <div className={Classes.BUTTON_GROUP}>
      <Button
        data-debug-id='saveAndMoveToNextStatus'
        isDisabled={isLoading}
        isLoading={isLoading}
        onClick={() => handleInvoiceStatusChange(archivedStatus)}
      >
        Set to Invoice Archived
      </Button>
      <TetherTarget
        closeOnPortalClick={true}
        isEnabled={true}
        tetherOptions={tetherOptions}
        tethered={tetheredView}
      >
        <Button
          className={Classes.iconClass(IconNames.CHEVRON_DOWN)}
          isDisabled={isLoading}
        />
      </TetherTarget>
    </div>
  )
}
