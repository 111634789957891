import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
// tslint:disable-next-line:max-line-length
import { FormGroupContentWrapper } from 'browser/components/atomic-elements/atoms/form-group-content-wrapper/form-group-content-wrapper'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import 'browser/components/atomic-elements/atoms/input/address-input/_address-input.scss'
import { GooglePlaceInput } from 'browser/components/atomic-elements/atoms/input/google-place-input'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { Select } from 'browser/components/atomic-elements/atoms/select'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { getAddressFromGooglePlace } from 'shared-libs/helpers/google-places'
import COUNTRIES from './countries'

/**
 * @uiComponent
 */
export interface IAddressInputProps extends IBaseProps {
  countries?: any[]
  errorText?: string
  hasLabels?: boolean
  helpText?: string
  innerDensity?: string
  isDisabled?: boolean
  isHorizontalLayout?: boolean
  isRequired?: boolean
  showEditableFields?: boolean
  onChange?: (value: any) => void
  placeholder?: string
  size?: string
  value?: any
}

// All countries and their states:
// https://github.com/astockwell/countries-and-provinces-states-regions

// Has three different view modes:
// 1. Text only mode where we show two lines for the address
// 2. Editable input fields WITHOUT individual labels for their fields, but
// an editable label. See carrier creation address.
// 3. Editable fields WITH individual labels for their fields, see location
// sheet.
export class AddressInput extends React.Component<IAddressInputProps, any> {

  public static defaultProps: Partial<IAddressInputProps> = {
    countries: COUNTRIES,
    innerDensity: 'collapse',
    showEditableFields: true,
  }

  public render() {
    const { showEditableFields } = this.props
    if (showEditableFields) {
      return this.renderEditableInput()
    } else {
      return this.renderNonEditableBlock()
    }
  }

  private getValue(): any {
    const { countries, value } = this.props
    if (value) {
      return value
    }
    const defaultCountry = _.find(countries, (country) => {
      return country.name === 'United States'
    })
    return {
      countryName: defaultCountry.name,
    }
  }

  private handleOnCountryChange = (value, option) => {
    this.setState({
      regions: option.regions,
    })
    this.handleOnChange('countryName', value)
  }

  private handleOnChange = (path, newValue) => {
    const address = this.getValue()
    _.set(address, path, newValue)
    if (this.props.onChange) {
      this.props.onChange(address)
    }
  }

  private handleOnPlaceChange = (place) => {
    const address = getAddressFromGooglePlace(place)
    this.props.onChange(address)
  }

  private renderEditableInput() {
    const {
      className,
      countries,
      errorText,
      hasLabels,
      innerDensity,
      isHorizontalLayout,
      size,
      isDisabled,
      isRequired,
    } = this.props
    const value = this.getValue()
    let countryPath = 'name'
    const { countryName } = value
    let selectedCountry = _.find(countries, { name: countryName })
    if  (_.isNil(selectedCountry)) {
      countryPath = 'code'
      selectedCountry = _.find(countries, { code: countryName} )
      // selectedCountry can still be undefined if 'UNKNOWN' country
    }
    const regions = _.get(selectedCountry, 'regions', [])
    const regionLabel = _.get(selectedCountry, 'regionLabel');
    const postalCodeLabel = _.get(selectedCountry, 'postalCodeLabel');
    const baseDataDebugId = this.props['data-debug-id']

    const streetAddressLabel = !hasLabels ? null : (
      <Label
        isHorizontalLayout={isHorizontalLayout}
        size={size}
      >
        Street Address
      </Label>
    )
    const InputComponent = hasLabels ? InputField : Input
    const SelectComponent = hasLabels ? SelectField : Select

    return (
      <FormGroupContentWrapper
        className={className}
        isDisabled={isDisabled}
        hasError={!_.isEmpty(errorText)}
        isHorizontalLayout={isHorizontalLayout && !hasLabels}
      >
        <FormGroup isHorizontalLayout={isHorizontalLayout}>
          {streetAddressLabel}
          <GooglePlaceInput
            data-debug-id={`${baseDataDebugId}.address1`}
            errorText={_.isEmpty(value.streetAddress) && errorText}
            isDisabled={isDisabled}
            isRequired={isRequired}
            onPlaceChange={this.handleOnPlaceChange}
            onChange={(value) => this.handleOnChange('streetAddress', value)}
            placeholder='Street Address'
            size={size}
            value={value.streetAddress}
          />
        </FormGroup>
        <InputComponent
          className=''
          data-debug-id={`${baseDataDebugId}.address2`}
          density={innerDensity}
          isDisabled={isDisabled}
          isHorizontalLayout={isHorizontalLayout && hasLabels}
          label='Apt./Suite'
          placeholder='Apt./Suite'
          onChange={(value) => this.handleOnChange('extendedAddress', value)}
          size={size}
          value={value.extendedAddress}
        />
        <div className='row row--collapse'>
          <div className='col-xs-12'>
            <InputComponent
              className='c-formGroup--noBorderTop'
              data-debug-id={`${baseDataDebugId}.city`}
              density={innerDensity}
              errorText={_.isEmpty(value.locality) && errorText}
              isRequired={isRequired}
              isHorizontalLayout={isHorizontalLayout && hasLabels}
              label='City'
              placeholder='City'
              isDisabled={isDisabled}
              onChange={(value) => this.handleOnChange('locality', value)}
              size={size}
              value={value.locality}
            />
          </div>
          <div className='col-xs-7'>
            <SelectComponent
              className='c-formGroup--noBorderTop'
              data-debug-id={`${baseDataDebugId}.state`}
              density={innerDensity}
              errorText={_.isEmpty(value.region) && errorText}
              isNative={true}
              isRequired={isRequired}
              isHorizontalLayout={isHorizontalLayout && hasLabels}
              label={regionLabel}
              placeholder={regionLabel}
              onChange={(value) => this.handleOnChange('region', value)}
              options={regions}
              optionLabelPath='name'
              optionValuePath='code'
              size={size}
              value={value.region}
            />
          </div>
          <div className='col-xs-5'>
            <InputComponent
              className='c-formGroup--noBorderTop'
              data-debug-id={`${baseDataDebugId}.zip`}
              density={innerDensity}
              errorText={_.isEmpty(value.postalCode) && errorText}
              isDisabled={isDisabled}
              labelProps={{
                widthClassName: 'u-flexShrink c-addressInput-zipCodeLabel',
              }}
              isRequired={isRequired}
              label={postalCodeLabel}
              isHorizontalLayout={isHorizontalLayout && hasLabels}
              placeholder={postalCodeLabel}
              onChange={(value) => this.handleOnChange('postalCode', value)}
              size={size}
              value={value.postalCode}
            />
          </div>
        </div>
        <SelectComponent
          isNative={true}
          data-debug-id={`${baseDataDebugId}.country`}
          density={innerDensity}
          errorText={_.isEmpty(value.countryName) && errorText}
          isRequired={isRequired}
          isHorizontalLayout={isHorizontalLayout && hasLabels}
          label='Country'
          placeholder='Country'
          onChange={this.handleOnCountryChange}
          options={countries}
          optionLabelPath='name'
          optionValuePath={countryPath}
          size={size}
          value={value.countryName}
        />
        {this.renderHelpBlock()}
      </FormGroupContentWrapper>
    )
  }

  private renderHelpBlock() {
    const { helpText } = this.props
    if (!helpText) {
      return
    }
    return (
      <HelpBlock>
        {helpText}
      </HelpBlock>
    )
  }

  private renderNonEditableBlock() {
    const { className, size, value, placeholder, isHorizontalLayout, isDisabled } = this.props
    const staticContainerClassName = _.isEmpty(size) ? 'c-fakeInputContainer' : `c-fakeInputContainer--${size}`
    if (_.isEmpty(value)) {
      return (
        <FormGroupContentWrapper
          className={classNames('u-flexGrow', className)}
          isDisabled={isDisabled}
          isHorizontalLayout={isHorizontalLayout}
        >
          <div className={classNames(staticContainerClassName, className)}>
            {placeholder}
          </div>
        </FormGroupContentWrapper>
      )
    }
    const comma = _.isEmpty(value.locality) ? '' : ','
    let lineBreak = <br />

    if (_.isEmpty(value.streetAddress) && _.isEmpty(value.extendedAddress)) {
      lineBreak = null
    }

    // TODO(louis): Make a standard address formatter
    return (
      <FormGroupContentWrapper
        isDisabled={isDisabled}
        className={classNames('u-flexGrow', className)}
        isHorizontalLayout={isHorizontalLayout}
      >
        <div className={classNames(staticContainerClassName, className)}>
          {value.streetAddress} {value.extendedAddress}{lineBreak}
          {value.locality}{comma} {value.region} {value.postalCode}
          {/* <br />{value.countryName} */}
        </div>
      </FormGroupContentWrapper>
    )
  }
}
