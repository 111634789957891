import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import 'browser/components/atomic-elements/organisms/top-navigation-bar/_notifications-navbar-item.scss'
import 'browser/components/atomic-elements/organisms/top-navigation-bar/_top-navigation-bar.scss'

interface INotificationsNavbarItemProps extends IBaseProps {
  count?: number
}

export class NotificationsNavbarItem extends React.Component<INotificationsNavbarItemProps, any> {
  public static defaultProps: Partial<INotificationsNavbarItemProps> = {
    count: 2,
  }

  public render() {
    const tetherOptions = {
      attachment: 'top right',
      targetAttachment: 'bottom right',
    }

    return (
      <TetherTarget
        tethered={this.renderNotificationPopover()}
        tetherOptions={tetherOptions}
      >
        <div
          className={classNames('c-topNavigationBar-link c-topNavigationBar-link--icon u-positionRelative', this.props.className)}
        >
          {/* <i className='notifications-none' /> */}
          <Icon icon={IconNames.NOTIFICATIONS} />
          {this.renderCount()}
        </div>
      </TetherTarget>
    )
  }

  private renderNotificationPopover() {
    return (
      <Popover className='collapse'>
        <div className='flex'>
          <div>
            Inbox
          </div>
          <div>
            Mark all as read | Settings
          </div>
        </div>
        <ul className='c-dropdownList'>
          <li
            className='c-dropdownList-item'
          >
            Heidi added a quote to order 8748129.
            <HelpBlock>
              30 seconds ago
            </HelpBlock>
          </li>
          <li
            className='c-dropdownList-item'
          >
            Found matching capacity for order 1923879.
            <HelpBlock>
              1 minute ago
            </HelpBlock>
          </li>
          <li
            className='c-dropdownList-item'
          >
            Jim Smith put order 129837 on hold.
            <HelpBlock>
              5 minutes ago
            </HelpBlock>
          </li>
        </ul>
        See All
      </Popover>
    )
  }

  private renderCount() {
    if (this.props.count > 0) {
      return (
        <div className='c-notificationsNavbarItem-count'>
          {this.props.count}
        </div>
      )
    }
  }
}
