import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React, { Fragment } from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { IRenderListItemProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { SheetContext } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
import { Column } from 'browser/components/atomic-elements/atoms/table'
import { HtmlTable } from 'browser/components/atomic-elements/atoms/table/html-table'
import { HtmlTableRow } from 'browser/components/atomic-elements/atoms/table/html-table/row'
import {
  AccountingPaymentSheet,
} from 'browser/components/atomic-elements/domains/trucking/accounting-payment-sheet/accounting-payment-sheet'
import 'browser/components/atomic-elements/domains/trucking/order-accounting-export/_order-accounting-export.scss'
import { Formatter } from 'shared-libs/helpers/formatter'
import { AccountingMappingSheet } from '../accounting-mapping-sheet/accounting-mapping-sheet'

/**
 * @uiComponent
 */
interface IOrderAccountingExportProps extends IBaseProps {
  businessEntity?: any
  label: string
  lineItemsPath: string
  paymentPath: string
  value: any
  openOverlay: any
}

interface IOrderAccountingExportState {
  grandTotal: number
  isLoading: boolean
  paidAmount: number
  payments: any
}

const columns: Column[] = [
  {
    label: 'Item',
    width: 180,
  },
  {
    flexGrow: 1,
    label: 'Description',
  },
  {
    label: 'Rating Method',
    width: 120,
  },
  {
    label: 'Quantity',
    textAlign: 'right',
    width: 142,
  },
  {
    label: 'Rate',
    textAlign: 'right',
    width: 80,
  },
  {
    label: 'Amount ($)',
    textAlign: 'right',
    width: 90,
  },
]

const QBO_SYNC_ID = '164fd38f-5ea0-41f5-bf35-5aae09ddcb7d'

class OrderAccountingExport extends React.PureComponent<IOrderAccountingExportProps, IOrderAccountingExportState> {

  public store: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    const { lineItemsPath, value } = props

    this.state = {
      grandTotal: this.calculateExportTotal(value, lineItemsPath),
      isLoading: false,
      paidAmount: 0,
      payments: null,
    }
  }

  public componentDidMount() {
    const { value } = this.props
    this.fetchPayment(value)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { lineItemsPath, value } = nextProps
    if (this.props.value.uniqueId !== nextProps.value.uniqueId) {
      this.fetchPayment(nextProps.value)
    }
    this.setState({ grandTotal: this.calculateExportTotal(value, lineItemsPath) })
  }

  public render() {
    const { isLoading } = this.state

    if (isLoading) {
      return this.renderLoading()
    }

    const { label, lineItemsPath, value } = this.props
    const { createdBy, creationDate } = value
    const items = _.get(value, lineItemsPath, [])
    const formattedCreationDate = moment(creationDate).format('M/D/YYYY')
    const footerItems = []
    footerItems.push(this.renderFooterTotal())

    return (
      <div className='u-bumperBottom--xs'>
        <div className='u-innerBumper--lg u-border u-positionRelative u-overflowHidden'>
          {this.renderPaymentButton()}
          <h6>
            {label}
          </h6>
          <div className='u-bumperBottom--xl'>
            Exported to QBO on {formattedCreationDate} by {createdBy.displayName}.<br /><br />
            {this.renderPayment()}
          </div>
          <HtmlTable
            columns={columns}
            footerItems={footerItems}
            renderListItem={this.renderLineItem}
            showItemDeleteButton={false}
            value={items}
          />
        </div>
      </div>
    )
  }

  private handlePaymentMade = () => {
    const { businessEntity, openOverlay, value } = this.props
    // if (!_.some(businessEntity.activeMixins, { entityId: QBO_SYNC_ID })) {
    //   openOverlay(
    //     <AccountingMappingSheet
    //       businessEntity={businessEntity}
    //     />,
    //   )
    //   return
    // }
    openOverlay(
      <AccountingPaymentSheet
        accountingEntity={value}
        businessEntity={businessEntity.entityId}
        updateExportPayments={this.handleRefreshPayments}
      />,
    )
  }

  private fetchPayment(entity) {
    const { paymentPath } = this.props
    const paymentIds = entity.get(paymentPath, null)
    const promises = _.map(paymentIds, (payment: any) => {
      return this.store.findRecord(payment.entityId)
    })

    this.setState({ isLoading: true })
    Promise.all(promises).then((result) => {
      this.setState({
        isLoading: false,
        paidAmount: _.sum(_.map(result, (payment: any) => payment.accountingPayment.amount.value)),
        payments: result,
      })
    })
  }

  private handleRefreshPayments = () => {
    const { value } = this.props
    value.reload().then((entity) => {
      this.fetchPayment(entity)
    })
  }

  private calculateExportTotal(value, lineItemsPath) {
    const items = _.get(value, lineItemsPath, [])
    return _.sum(_.map(items, (item: any) => item.amount.value ))
  }

  //////////////////////////////////////////////////////////////////////////////
  // Renderer
  //////////////////////////////////////////////////////////////////////////////

  private renderLoading() {
    return (
      <div className='grid-block c-appBody'>
        <LoadingSpinner />
      </div>
    )
  }

  private renderPayment() {
    const { payments } = this.state

    if (_.isEmpty(payments)) { return }

    return (
      <Fragment>
        {_.map(payments, (payment: any) => this.renderPaymentInformation(payment))} <br />
        <div>
          {this.renderCurrentPaymentStatus()}
        </div>
      </Fragment>
    )
  }

  private renderPaymentInformation(payment) {
    const paymentAmount = payment.accountingPayment.amount.value
    const { notes } = payment.accountingPayment
    const { creationDate } = payment
    const paymentDateTime = moment(creationDate)
    const paymentDate = paymentDateTime.format('M/D/YYYY')
    const paymentTime = paymentDateTime.format('LT')
    return (
      <div>
        Payment of {paymentAmount} was made on {paymentDate} at {paymentTime} <br />
        Notes: {notes}
      </div>
    )
  }

  private renderCurrentPaymentStatus() {
    const { grandTotal, paidAmount, payments } = this.state
    const latestPayment = payments.length - 1
    const { creationDate } = payments[latestPayment]
    const paymentDateTime = moment(creationDate)
    const paymentDate = paymentDateTime.format('M/D/YYYY')
    const paymentTime = paymentDateTime.format('LT')
    const latestPaymentAmount = payments[latestPayment].accountingPayment.amount.value

    if (paidAmount < grandTotal) {
      return (
        <div>
          Latest payment of {latestPaymentAmount} was made on {paymentDate} at {paymentTime} <br />
          <b> Remaining Balance: ${grandTotal - paidAmount} </b>
        </div>
      )
    }
    return (
      <div>
        <b> Fully Paid on {paymentDate} at {paymentTime} </b> <br />
      </div>
    )
  }

  private renderPaymentButton() {
    const { grandTotal, paidAmount } = this.state
    return (
      <Fragment>
        <div
          className={classNames('c-ribbon c-ribbon--topRight', {
            'u-hide': paidAmount < grandTotal,
          })}
        >
          Paid
        </div>
        <Button
          className={classNames('c-button--secondary', {
            'u-hide': paidAmount >= grandTotal,
          })}
          onClick={this.handlePaymentMade}
        >
          Record Payment
        </Button>
      </Fragment>
    )
  }

  private renderLineItem = (props: IRenderListItemProps): React.ReactElement<any> => {
    const { item, index } = props
    return (
      <HtmlTableRow
        index={index}
        isEditableInline={false}
        isStatic={true}
      >
        {this.renderCell(_.get(item, 'itemType.displayName', ''), false)}
        {this.renderCell(_.get(item, 'description', ''), false)}
        {this.renderCell(_.get(item, 'ratingMethod', ''), false)}
        {this.renderCell(`${_.get(item, 'quantity.value', '')} ${_.get(item, 'quantity.unit', '')}`, true)}
        {this.renderCell(_.get(item, 'ratePerUnit', ''), true)}
        {this.renderCell(_.get(item, 'amount.value', ''), true)}
      </HtmlTableRow>

    )
  }

  private renderFooterTotal = () => {
    const { grandTotal } = this.state
    const emptyCellProps = {
      cellClassName: 'bn',
      colSpan: 3,
    }
    const totalLabelProps = {
      className: 'c-tableCellText tr b',
      colSpan: 2,
    }
    const totalValueProps = {
      className: 'c-tableCellText tr b',
      colSpan: 1,
    }
    return (
      <HtmlTableRow
        index={0}
        isEditableInline={false}
        isStatic={true}
      >
        <div
          {...emptyCellProps}
        >
          {``}
        </div>
        <div
          {...totalLabelProps}
        >
          Total
        </div>
        <div
          {...totalValueProps}
        >
          {Formatter.formatMoney(grandTotal)}
        </div>
      </HtmlTableRow>
    )
  }

  private renderCell = (text, isRightAlign) => {
    return (
      <Input
        inputClassName={isRightAlign ? 'tr' : ''}
        value={text}
      />
    )
  }
}

export default React.forwardRef((props: IOrderAccountingExportProps, ref: React.Ref<OrderAccountingExport>) => (
  <SheetContext.Consumer>
    {({ openOverlay }) => (
      <OrderAccountingExport {...props} openOverlay={openOverlay} ref={ref} />
    )}
  </SheetContext.Consumer>
))
