import $ from 'jquery'
import _ from 'lodash'

export default function getTime(): Promise<number> {
  let startTime: number
  // The actual URL doesn't matter, we just want to get a date header from the server.
  const request = $.ajax({
    url: `${window.location.origin}/1.0/entities/actions/system/user/me`,
    beforeSend() { startTime = Date.now() }
  })

  return new Promise((resolve, reject) => {
    request.always((data) => {
      const responseDate = request.getResponseHeader('Date')
      if (_.isEmpty(responseDate)) {
        resolve(Date.now())
      }
      const serverDate = new Date(responseDate).getTime()
      console.log(`Server Date ${serverDate}`)
      // Add date parsing time to latency to help with accuracy.
      const latency = (Date.now() - startTime)/2
      resolve(serverDate + latency)
    })
    request.fail(reject)
  })
}