export const EdgeProps = {
  ENTITY_ID:    'entityId',
  DISPLAY_NAME: 'displayName'
}

export const EntityProps = {
  ID:             'uniqueId',
  OWNER:          'owner',
  OWNING_FIRM:    'owner.firm',
  OWNING_FIRM_ID: 'owner.firm.entityId',
  OWNING_USER_ID: 'owner.user.entityId',
  ACTIVE_MIXINS:  'mixins.active'
}

export const EntitySchemaProps = {
  TITLE: 'title' ,
  ALL_OF: 'allOf',
  ANY_OF: 'anyOf',
  URI: 'id',
  DESCRIPTION: 'description',
  TYPE: 'type',
  METADATA: 'metadata',
  PROPERTIES: 'properties'
}

export const FirmProps = {
  LEGAL_NAME: 'business.legalName',
  DOMAIN:     'firm.preferences.domain',
  SUBDOMAIN:  'firm.preferences.subdomain',
  COMPANY_CODES: 'firm.companyCodes',
  COMPANY_TYPE: 'firm.companyType',
  SSO: 'firm.externalIdentifiers'
}

export const GeneralSettingsProps = {
  FIRM_ID:      'owner.firm.entityId'
}

export const AppBundleProps = {
  FIRM:         'applicationBundle.firm',
  FIRM_ID:      'applicationBundle.firm.entityId',
  NAME:         'applicationBundle.name',
  EXTEND_REFS:  'applicationBundle.extends',
  SCHEMA_REFS:  'applicationBundle.schemas'
}

export const SchemaRefProps = {
  OPERATION: '_operation',
  ENTITY_ID: 'entityId',
  ORDER_PRIORITY: 'orderPriority'
}

export const PersonProps = {
  NAME:       'person.name',
  FIRST_NAME: 'person.firstName',
  LAST_NAME:  'person.lastName',
}

export const UserProps = {
  EMAILS:                           'person.emails',
  FIRST_EMAIL_VALUE:                'person.emails.0.value',
  FIRST_PHONE_VALUE:                'person.phoneNumbers.0.value.phone',
  FIRST_NAME:                       PersonProps.FIRST_NAME,
  LAST_NAME:                        PersonProps.LAST_NAME,
  NAME:                             PersonProps.NAME,
  PASSWORD:                         'user.password',
  HAS_SEEN_JOIN_ORGANIZATION_MODAL: 'user.preferences.hasSeenJoinOrganizationModal',
  IS_INVITED:                       'user.preferences.isInvited',
  ROLE:                             'user.role',
  FIRM_NAME:                        'owner.firm.displayName'
}

export const InviteProps = {
  FIRST_NAME: 'invite.firstName',
  LAST_NAME: 'invite.lastName',
  ROLE: 'userInvite.role',
  EMPLOYEE_ID: 'userInvite.employeeId',
  DIVISION: 'userInvite.division',
  EMAIL: 'invite.email',
  PHONE_NUMBER: 'invite.phoneNumber',
}

export const OwnerProps = {
  FIRM: 'firm'
}

export const MetadataProps = {
  NAMESPACE: 'namespace'
}

export const MergeUserProps = {
  DESTINATION: 'mergeUser.destination',
  SOURCES: 'mergeUser.sources'
}

export const DocumentClassificationTaskProps = {
  CLASSIFICATION_TASKS: 'documentClassificationTask.classificationTasks',
  DOCUMENT_TABLE: 'documentClassificationTask.documentTable',
}

export const ClassificationTaskProps = {
  DOCUMENT_PROPERTIES: 'documentProperties',
  DOCUMENT_TABLE: 'documentTable',
  TEMPLATE: 'template',
  TEMPLATE_ID: 'template.entityId',
  IS_MACHINE_CLASSIFIED: 'isMachineClassified',
  DOCUMENT_TYPE: 'documentType'
}

export const WorkflowPlanProps = {
  EXECUTION_ID: 'core_storyboard_plan.execution.entityId',
}
