import _ from 'lodash'

import { Entity } from './entity'
import { CustomFormulas } from '../helpers/formulas'
import { ExpressionEvaluator, EsprimaParser } from '../helpers/evaluation'
import { AbstractSettings } from './abstract-settings'
import { Store } from './store'
import { TripModel } from './trip-model'

/*
 * Default mappings that should apply to all firms as default behavior.
 *
 * The individual mappings can be overriden (or removed) for an individual firm by establishing a mapping Entity
 * for that firm with mapping entries for the desired path.
 *
 * To "remove" a mapping this way, simply write "destinationValue" (without quotes) in the formula, and
 * leave the source path blank.
 */
export const DEFAULT_MAPPINGS = [
  {
    uniqueId: '03ed71cc-78a0-4b51-816b-d207707b223f',
    mixins: {
      active: [
        {
          entityId: '11111111-0000-0000-0000-000000000000',
          displayName: 'Entity',
        },
        {
          entityId: '3d3d61bc-c01e-45b0-b251-417ea3f11121',
          displayName: 'Entity Mapping',
        },
      ],
      inactive: [],
    },
    core_entity_mapping: {
      source: {
        entityId: '86af510a-f030-41d6-bda4-2228b8e181b1',
        displayName: 'Trip',
      },
      destination: {
        entityId: '11111111-0000-0000-0000-000000000011',
        displayName: 'Document',
      },
      mappings: [
        {
          formula: `order ? order.displayName : _.get(trip.getSalesOrders(stop), '0.displayName')`,
          destination: 'document.name',
        },
        {
          formula: `order ? order.entityId : _.get(trip.getSalesOrders(stop), '0.entityId')`,
          destination: 'document.salesOrder.entityId',
        },
        {
          formula: 'trip.getUniqueId()',
          destination: 'document.trip.entityId',
        },
      ],
    },
    creationDate: '1970-01-01T00:00:00Z',
    description: 'Default Trip-Document mapping',
    precomputation: {
      displayName: 'Trip to Document',
    },
    entity: {},
    status: {
      state: 'idle',
    },
  },
]

export const CONDITIONAL_FAILED = new Object()

/**
 * A mapping from a source to a destination object.
 */
export interface IMapping {
  source: any
  destination: any
  sourcePath?: string
  destinationPath: string
  expression?: string
  conditional?: string
}

/**
 * A generic description of a mapping.
 */
export interface ISimpleMapping {
  destination: string
  source?: string
  formula?: string
  conditional?: string
}

/**
 * Convert json prop into IMappings
 */
export function toIMapping(entity: Entity, mappings: ISimpleMapping[], destination: any): IMapping[] {
  return mappings.map((entry) => ({
    source: entity,
    destination,
    sourcePath: entry.source,
    destinationPath: entry.destination,
    expression: entry.formula,
    conditional: entry.conditional,
  }))
}

export type MappingResultCallback = (result: any, mapping: IMapping) => any

export function createEntityMappingFormulaContext(store: Store): any {
  const formulaMap = {
    TRIP_MODEL: (tripEntity) => {
      return new TripModel(tripEntity)
    },
    FIND_RECORD: (entityId) => {
      return store.fetchOrGetRecord(entityId)
    },
    FIND_RECORDS: (...params) => {
      const entityIds = _.isArray(params[0]) ? params[0] : params
      return store.fetchOrGetRecords(entityIds)
    }
  }
  return {
    ...formulaMap,
  }
}

/**
 * Execute a mapping. Supports mappings whose expression evaluate to promises.
 */
export async function executeMapping(
  mapping: IMapping,
  context: any = {},
  opts: any = {}
): Promise<any> {
  const sourceValue = _.get(mapping.source, mapping.sourcePath)
  const destinationValue = _.get(mapping.destination, mapping.destinationPath)
  const ctx = {
    ...context,
    ...mapping,
    sourceValue,
    destinationValue,
    self: mapping.source,
  }
  const valueGetter = (key) => _.get(ctx, key, CustomFormulas[key])

  const maybePromiseConditional = mapping.conditional
    ? ExpressionEvaluator.create()
        .setErrorLogger((error) => console.log(error))
        .setValueGetter(valueGetter)
        .setThrowError(opts.throwError)
        .setASTParser(EsprimaParser)
        .evaluate(mapping.conditional)
    : true

  const conditionalResult = await maybePromiseConditional
  if (!conditionalResult) {
    return CONDITIONAL_FAILED
  }

  const maybePromiseResult = mapping.expression
    ? ExpressionEvaluator.create()
        .setErrorLogger((error) => console.log(error))
        .setValueGetter(valueGetter)
        .setThrowError(opts.throwError)
        .setASTParser(EsprimaParser)
        .evaluate(mapping.expression)
    : sourceValue

  // the evaluated result might be a Promise, so resolve any kind of value
  const value = await maybePromiseResult

  _.set(mapping.destination, mapping.destinationPath, value)
  return value
}

/**
 * Get a flattened list of mappings whose entity types are included the specified filter lists.
 */
export function filterMappings(
  mappingEntities: Entity[],
  source: any,
  sourceTypeIds: string[],
  destination: any,
  destinationTypeIds: string[]
): IMapping[] {
  return (
    _.chain(mappingEntities)
      .filter((mapping) => {
        const sourceSchemaId = _.get(mapping, 'core_entity_mapping.source.entityId')
        const destinationSchemaId = _.get(mapping, 'core_entity_mapping.destination.entityId')
        return _.includes(sourceTypeIds, sourceSchemaId) && _.includes(destinationTypeIds, destinationSchemaId)
      })
      // TODO: Simplify to .flatMap('core_entity_mapping.mappings') when replacing the sort
      .flatMap((entity) => {
        const mappings = _.get(entity, 'core_entity_mapping.mappings')
        return _.map(mappings, (mapping) => {
          // TODO: Remove when replacing the sort
          _.set(mapping, 'creationDate', entity.get('creationDate'))
          return mapping
        })
      })
      .filter((pathMapping) => {
        return !_.isNil(pathMapping.source) || !_.isNil(pathMapping.formula)
      })
      .map((pathMapping) => ({
        source,
        destination,
        sourcePath: pathMapping.source,
        destinationPath: pathMapping.destination,
        expression: pathMapping.formula,
        creationDate: pathMapping.creationDate, // TODO: refactor the flatMap and creationDate later when replacing the sort
      }))
      .groupBy('destinationPath')
      // TODO: for each destination path, sort the value list by schema topology, then take last
      .mapValues((values) => {
        const sorted = _.sortBy(values, 'creationDate')
        return _.last(sorted)
      })
      .values()
      .value()
  )
}

/**
 * A wrapper for entity-mappings.
 */
 export class EntityMapper {
  private source: any
  private sourceTypeIds: string[]
  private destination: any
  private destinationTypeIds: string[]
  private api: any
  private settings: AbstractSettings
  private mappings: IMapping[] = []

  private constructor(api: any, settings: AbstractSettings) {
    this.api = api
    this.settings = settings
  }

  public static create(api: any, settings: AbstractSettings): EntityMapper {
    return new EntityMapper(api, settings)
  }

  public fromEntity(source: Entity): EntityMapper {
    const mixins = source && source.activeMixins
    return this.from(source, _.map(mixins, 'entityId'))
  }

  public from(source: any, sourceTypeIds: string | string[]): EntityMapper {
    this.source = source
    this.sourceTypeIds = _.isString(sourceTypeIds) ? [sourceTypeIds] : _.compact(sourceTypeIds)
    return this
  }

  public useMappings(mappings: IMapping[]) {
    this.mappings = mappings
    return this
  }

  public toEntity(destination: Entity): EntityMapper {
    const mixins = destination && destination.activeMixins
    return this.to(destination, _.map(mixins, 'entityId'))
  }

  public to(destination: any, destinationTypeIds: string | string[]): EntityMapper {
    this.destination = destination
    this.destinationTypeIds = _.isString(destinationTypeIds) ? [destinationTypeIds] : _.compact(destinationTypeIds)
    return this
  }

  /**
   * @returns A list of mappings for the current session.
   */
  public getMappings() {
    const defaultMappings = _.map(DEFAULT_MAPPINGS, (json) => new Entity(json, this.api))
    const firmMappings = this.settings.getMappings()
    const allMappings = _.concat(defaultMappings, firmMappings)
    const entityMappings =  filterMappings(
      allMappings,
      this.source,
      this.sourceTypeIds,
      this.destination,
      this.destinationTypeIds
    )

    return [ ...entityMappings, ...this.mappings ]
  }

  /**
   * Resolve and execute mappings for the current session.
   */
  public async execute(context: any = {}, handleResult?: MappingResultCallback): Promise<void> {
    if (!this.destination || !this.source || _.isEmpty(this.sourceTypeIds) || _.isEmpty(this.destinationTypeIds)) {
      // bail gracefully with no mapping performed
      console.warn('could not perform Entity Mapping due to empty criteria')
      return this.destination
    }
    const ctx = {
      ...CustomFormulas,
      ...createEntityMappingFormulaContext(this.api.getStore()),
      _,
      ...context,
    }
    const mappings = this.getMappings()
    for (const mapping of mappings) {
      const result = await executeMapping(mapping, ctx).catch((error) => {
        console.warn(error)
      })
      // publish the result to an optional result handler and wait for it to finish
      const resultCallback = handleResult ? handleResult.call(this, result, mapping) : undefined
      await Promise.resolve(resultCallback)
    }
    return this.destination
  }
}
