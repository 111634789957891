import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { BaseMap, IBaseMapProps } from './base-map'
import { IMarkerClusterLayerProps, MarkerClusterLayer } from './layers/marker-cluster-layer'
import { DocumentMarker } from './markers/document-marker'
import { LocationMarker } from './markers/location-marker'
import { fitBounds } from './utils/fit-bounds'

// New York
const location = {
  latitude: 40,
  longitude: -100,
}

/**
 * @uiComponent
 */
interface IEntityMapProps extends IBaseProps {
  className?: string
  defaultLocation?: object
  entity?: object
  entities?: any[]
  geolocationPath?: string
  isInteractive?: boolean
  isMapExpanded?: boolean
  isZoomControlsEnabled?: boolean
  markersPaddingLeft?: number
  markerType?: string
  padding?: object
  selectedEntity?: object
  handleToggleMapFullscreen?: () => void
  zoomLevel?: number
}

class InnerEntityMap extends React.Component<IEntityMapProps, any> {
  public static defaultProps: Partial<IEntityMapProps> = {
    defaultLocation: undefined,
    geolocationPath: 'document.address.geolocation',
    markerType: 'document',
    padding: { left: 5, right: 5, top: 5, bottom: 5 },
    zoomLevel: 2,
  }

  constructor(props) {
    super(props)
    this.state = this.getNextStateFromProps(props)
    this.handleViewportChanged = this.handleViewportChanged.bind(this)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.entity || this.props.entities !== nextProps.entities) {
      this.setState(this.getNextStateFromProps(nextProps, this.state))
    }
  }

  public getNextStateFromProps(props, prevState?) {
    const entities = this.getEntitiesWithLocation(props)
    const result = this.getBoundsFromEntities(entities, props)
    const viewport = _.get(prevState, 'viewport')
    const zoom = result ? result.zoom : props.zoomLevel
    const maxFitPropsZoom = 15
    return {
      entities,
      viewport: {
        isDragging: _.get(viewport, 'isDragging'),
        latitude: result ? result.latitude : location.latitude,
        longitude: result ? result.longitude : location.longitude,
        startDragLngLat: _.get(viewport, 'startDragLngLat'),
        zoom: Math.min(zoom, maxFitPropsZoom),
      },
    }
  }

  public getEntitiesWithLocation(props) {
    const { entity, entities, geolocationPath } = props
    let values = (_.isEmpty(entities) && entity) ? [entity] : entities
    values = _.filter(values, (entity) => {
      const geolocation = entity.get(geolocationPath) as any
      return geolocation && geolocation.latitude !== 0 &&
          geolocation.longitude !== 0
    })
    return _.uniqBy(values, 'uniqueId')
  }

  public getBoundsFromEntities(entities, props) {
    const { geolocationPath, width, height, padding } = props
    const MarkerComponent = this.getMarkerComponent()
    const latitudePath = `${geolocationPath}.latitude`
    const longitudePath = `${geolocationPath}.longitude`
    const minLat = _.minBy(entities, latitudePath)
    const minLng = _.minBy(entities, longitudePath)
    const maxLat = _.maxBy(entities, latitudePath)
    const maxLng = _.maxBy(entities, longitudePath)
    if (minLat && minLng && maxLat && maxLng) {
      const bounds = [
        [_.get(minLat, latitudePath),
          _.get(minLng, longitudePath)],
        [_.get(maxLat, latitudePath),
          _.get(maxLng, longitudePath)],
      ]
      // if all entities are at the same point, just return lat, lng, default
      // zoom, fitBounds doesn't handle that case
      // if (_.isEqual(bounds[0], bounds[1])) {
      // }
      return {
        latitude: _.get(minLat, latitudePath),
        longitude: _.get(minLng, longitudePath),
        zoom: props.zoomLevel,
      }
      // return fitBounds(width, height, bounds, {
      //   paddingBottom: padding.bottom,
      //   paddingLeft: padding.left,
      //   paddingRight: padding.right,
      //   paddingTop: MarkerComponent.height + padding.top,
      // })
    }
  }

  public getMarkerComponent(): any {
    const { markerType } = this.props
    if (markerType === 'location') {
      return LocationMarker
    }
    return DocumentMarker
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  public handleViewportChanged(opt) {
    this.setState({
      viewport: {
        isDragging: opt.isDragging,
        latitude: opt.latitude,
        longitude: opt.longitude,
        startDragLngLat: opt.startDragLngLat,
        zoom: opt.zoom,
      },
    })
  }

  public render() {
    const { entities, viewport } = this.state
    const MarkerComponent = this.getMarkerComponent()
    return (
      <BaseMap
        {...this.props as IBaseMapProps}
        onViewportChange={this.handleViewportChanged}
        viewport={viewport}
      >
        <MarkerClusterLayer
          {...this.props as IMarkerClusterLayerProps}
          entities={entities}
          MarkerComponent={MarkerComponent}
          markersHeight={MarkerComponent.height}
          markersWidth={MarkerComponent.width}
          onViewportChange={this.handleViewportChanged}
          viewport={viewport}
        />
      </BaseMap>
    )
  }
}

// TODO(peter): Why is it this way?
export class EntityMap extends React.PureComponent<IEntityMapProps, any> {
  public render() {
    return (
      <AutofillBlock>
        <InnerEntityMap {...this.props} />
      </AutofillBlock>
    )
  }
}
