import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import { Column } from '..'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
// tslint:disable-next-line:max-line-length
import { AbstractList, IAbstractListProps, IRenderListItemProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import 'browser/components/atomic-elements/atoms/table/html-table/_html-table.scss'


/**
 * @prop columns - array of Column configuration objects
 * @prop density - (optional) string representing responsive ui size
 * @prop emptyStateView - (optional) component to be displayed when the table is empty
 * @prop hasError - (optional) whether or not a field using this component is in an error state (for CSS)
 * @prop tableHeaderClassName - (optional) class name to be added to table header html
 * @prop tableBodyClassName - (optional) class name to be added to table body html
 * @prop tableFooterClassName - (optional) class name to be added to table footer html
 */
export interface IHtmlTableProps extends IAbstractListProps {
  columns: Column[]
  density?: string
  emptyStateView?: React.ReactElement<any>
  hasError?: boolean
  tableHeaderClassName?: string
  tableBodyClassName?: string
  tableFooterClassName?: string
}

// TODO(peter): How to add IHtmlTableProps to this? and list props?
export class HtmlTable extends AbstractList<IHtmlTableProps> {
  public static defaultProps: Partial<IHtmlTableProps> = AbstractList.defaultProps

  public render() {
    const {
      className,
      density,
      tableHeaderClassName,
      tableFooterClassName,
    } = this.props
    const densityClassName = _.isEmpty(density) ? '' : `c-table--${density}`
    return (
      <div>
        <table className={classNames('c-table', densityClassName, className)}>
          <thead className={classNames('c-table-header', tableHeaderClassName)}>
            <tr className='c-table-row'>
              {this.renderColumnHeaders()}
              {this.renderDeleteButtonSpacer()}
            </tr>
          </thead>
          {this.renderTableBody()}
          <tfoot className={classNames('c-table-footer', tableFooterClassName)}>
            {this.renderFooterItems()}
          </tfoot>
        </table>
        {this.renderTableAddRowButton()}
      </div>
    )
  }

  protected renderTableBody() {
    const { showPlaceholderItem, tableBodyClassName } = this.props
    const rows = this.getValue()
    const showEmptyState = _.isEmpty(rows) && !showPlaceholderItem
    return (
      <tbody className={classNames('c-table-body', tableBodyClassName)}>
        {/* Note: This has nothing to do with table header cells, these are
        items at the top of the list. Used in the order identifier list. */}
        {this.renderHeaderItems()}
        {showEmptyState ? this.renderEmptyState() : this.renderListItems()}
      </tbody>
    )
  }

  protected renderEmptyState() {
    const { columns, emptyStateView, showItemDeleteButton } = this.props
    let numColumns = columns.length
    numColumns = showItemDeleteButton ? numColumns + 1 : numColumns
    const content = emptyStateView ? emptyStateView : (
      <HelpBlock
        className='u-textCenter collapse'
        style={{
          paddingBottom: 8,
          // $v-spacing-unit--sm, but need to round to the nearest integer,
          // otherwise the table has odd artifacts.
          paddingTop: 8,
        }}
      >
        No data available
      </HelpBlock>
    )
    return (
      <tr>
        <td
          colSpan={numColumns}
        >
          {content}
        </td>
      </tr>
    )
  }

  protected renderHeaderItem(headerItem, index) {
    const {
      columns,
      density,
    } = this.props
    return React.cloneElement(headerItem, {
      columns,
      density,
      key: `header-${index}`,
    })
  }

  protected renderFooterItem(footerItem, index) {
    const {
      columns,
      density,
    } = this.props
    return React.cloneElement(footerItem, {
      columns,
      density,
      key: `footer-${index}`,
      showItemDeleteButton: false,
    })
  }

  protected renderListItem(props: IRenderListItemProps) {
    const {
      isEditableInline,
      renderListItem,
      density,
      columns,
      showItemDeleteButton,
    } = this.props
    const { index, onRemove, onClick } = props
    const listItem = renderListItem(props)
    return React.cloneElement(listItem, {
      columns,
      density,
      key: index,
      onRemove,
      showItemDeleteButton,
      onClick,
    })
  }

  private renderColumnHeaders() {
    const {
      columns,
      hasError,
      density,
      size,
    } = this.props

    return columns.map((columnSpec, index) => {
      const densityClassName = _.isEmpty(density) ? '' : `c-table-cell--${density}`
      const sizeClassName = _.isEmpty(size) ? '' : `c-table-cell--${size}`
      const errorClassName = hasError ? 'c-table--has-error' : ''
      return (
        <th
          className={classNames('c-table-cell', densityClassName, sizeClassName, columnSpec.className, errorClassName)}
          key={index}
          style={columnSpec}
        >
          {columnSpec.label}
        </th>
      )
    })
  }

  private renderDeleteButtonSpacer() {
    const {
      density,
      showItemDeleteButton,
      size,
    } = this.props
    // We need the spacer because we need to manually sync the table header
    // cells widths with the body table widths.
    if (!showItemDeleteButton) {
      return
    }
    const densityClassName = _.isEmpty(density) ? '' : `c-table-cell--${density}`
    const sizeClassName = _.isEmpty(size) ? '' : `c-table-cell--${size}`

    return (
      <th
        className={classNames('c-table-cell c-table-cell--delete', densityClassName, sizeClassName)}
      />
    )
  }

  private renderTableAddRowButton() {
    const {
      addButtonText,
      showAddButton,
    } = this.props
    if (!showAddButton) {
      return
    }
    return (
      <div className='tr u-noPrint'>
        <Button
          className={Classes.MINIMAL}
          onClick={this.handleAddItem}
        >
          {addButtonText}
        </Button>
      </div>
    )
  }

}
