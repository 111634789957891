import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Select } from 'browser/components/atomic-elements/atoms/select'

interface ITableSelectCellProps extends IBaseProps {
  row: any
  onSelectMenuChange: any
  onChange?: any
  options?: any
  placeholder?: any
  optionLabelPath?: any
  optionValuePath?: any
}

export const TableSelectCell: React.FC<ITableSelectCellProps> = (props) => {
  const {
    onSelectMenuChange,
    onChange,
    options,
    optionLabelPath,
    optionValuePath,
    placeholder,
    row,
  } = props
  const entity = _.get(row, 'data')
  let value = null
  if (entity.quickBooksSync) {
    value = _.find(options, { identifier: entity.quickBooksSync.identifier })
  }

  return (
    <Select
      className='w-100'
      onSelectMenuChange={onSelectMenuChange}
      placeholder={placeholder}
      onChange={(value, option) => onChange(value, option, entity)}
      options={options}
      optionLabelPath={optionLabelPath}
      optionValuePath={optionValuePath}
      value={value}
    />
  )
}
