import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface ITableLocationEdgeCellProps extends IBaseProps {
  value: any
}

export const TableLocationEdgeCell: React.FC<ITableLocationEdgeCellProps> = (props) => {
  const { value } = props
  if (!value) {
    return <div>-</div>
  }

  const address: any = _.get(value, ['denormalizedProperties', 'location.address'])
  if (!address || !address.region || !address.locality) {
    return <div>{value.displayName}</div>
  }

  const state = address.region.toUpperCase()
  return (
    <div>
      {address.locality}
      , {state}
    </div>
  )
}
