import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { CitySelect } from 'browser/components/atomic-elements/atoms/geo-region-input/city-select/city-select'
import { Header } from 'browser/components/atomic-elements/atoms/header/header'
import { DateInput } from 'browser/components/atomic-elements/atoms/input/date-input/date-input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { Sheet } from 'browser/components/atomic-elements/atoms/sheet'
import { TextareaField } from 'browser/components/atomic-elements/molecules/fields/textarea-field'

interface IAddCheckCallSheetProps extends IBaseProps {
  order: any
}

interface IAddCheckCallSheetStates {
  checkCall: Entity
  isSaving: boolean
}

const CHECK_CALL_SCHEMA_URI = '/1.0/entities/metadata/checkCall.json'

export class AddCheckCallSheet extends React.Component<IAddCheckCallSheetProps, IAddCheckCallSheetStates> {

  private sheet: Sheet
  private store: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    const entitySchema = this.store.getRecord(CHECK_CALL_SCHEMA_URI)
    const checkCall = this.store.createRecord(entitySchema, {
      checkCall: {
        entity: {
          entityId: props.order.uniqueId,
        },
        timestamp: moment().toISOString(),
      },
    })
    this.state = {
      checkCall,
      isSaving: false,
    }
  }

  public render() {
    const { checkCall, isSaving } = this.state
    const { address, timestamp, notes } = (checkCall as any).checkCall
    const handleRef = (ref) => { this.sheet = ref }
    return (
      <Sheet
        ref={handleRef}
        size='sm'
        bodyClassName='c-sheet-body--padded'
        header={
          <Header
            title='Add Check Call'
          />
        }
        footer={
          <Footer
            isPrimaryButtonLoading={isSaving}
            isVisible={true}
            onCancelButtonClick={this.handleClose}
            onPrimaryButtonClick={this.handleSave}
          />
        }
      >
        <FormTable className='u-bumperBottom'>
          <FormGroup isHorizontalLayout={true}>
            <Label isHorizontalLayout={true}>
              City
            </Label>
            <CitySelect
              clearable={true}
              className={classNames('c-formGroup-horizontalContent c-geoRegionInput-cityPicker', {
                'c-geoRegionInput-cityPicker--filledIn': !_.isEmpty(address),
              })}
              onChange={(value) => this.handlePropertyChange('address', value)}
              placeholder='Search for a city'
              value={address}
            />
          </FormGroup>

          <FormGroup isHorizontalLayout={true}>
            <Label isHorizontalLayout={true}>
              Time Stamp
            </Label>
            <DateInput
              onChange={(value) => this.handlePropertyChange('timestamp', value)}
              showTimeInput={true}
              value={timestamp}
            />
          </FormGroup>
        </FormTable>

        <FormTable>
          <TextareaField
            isHorizontalLayout={true}
            label='Notes'
            onChange={(value) => this.handlePropertyChange('notes', value)}
            value={notes}
          />
        </FormTable>
      </Sheet>
    )
  }

  private handlePropertyChange = (key, value) => {
    const { checkCall } = this.state
    checkCall.set(`checkCall.${key}`, value)
    this.setState({ checkCall })
  }

  private handleSave = () => {
    const { checkCall } = this.state
    this.setState({ isSaving: true })
    checkCall.save().then(() => {
      this.handleClose()
    })
  }

  private handleClose = () => {
    this.sheet.close()
  }
}
