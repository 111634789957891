import React, { useState, Fragment } from 'react'
import _ from 'lodash'
import { Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'

import apis from 'browser/app/models/apis'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { IRbViewHeaderEntitySelectionProps } from './rendition-billing-view-header-buttons'

/**
 * Print the selected rb invoice
 */
export const RbViewHeaderInvoicePrintSelected: React.FC<IRbViewHeaderEntitySelectionProps> = (props) => {
  const { selection } = props
  const [ isCreatingPDF, setIsCreatingPDF ] = useState(false)

  if (selection.length <= 1) {
    return null
  }

  const handleBatchPrint = () => {
    const salesInvoices = _.map(selection, (entity) => entity.data)
    setIsCreatingPDF(true)
    return apis
      .salesInvoiceRelatedDocuments(salesInvoices)
      .then((relatedSalesInvoiceDoc) => {
        return _.flatten(relatedSalesInvoiceDoc)
      })
      .then((docs) => {
        const ids = _.map(docs, 'uniqueId')
        return apis.batchPrintDocuments(ids)
      })
      .then((response: string) => {
        window.open(response)
      }).finally(()=> {
        setIsCreatingPDF(false)
      })
  }

  return (
    <Fragment>
      <Button
        className={classNames('mr2', Classes.iconClass(IconNames.PRINT))}
        isLoading={isCreatingPDF}
        onClick={handleBatchPrint}
      >
        Print Selected
      </Button>
    </Fragment>
  )
}
