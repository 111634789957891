import { Intent, Popover } from '@blueprintjs/core'
import _ from 'lodash'
import React from 'react'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { IEntityPageContainerProps } from 'browser/components/atomic-elements/organisms/entity/entity-page-container'
import { RenditionBillingConfirmationModal } from 'browser/components/atomic-elements/organisms/rendition-billing-v2-confirmation-modal/rendition-billing-confirmation-modal'
import { RbViewHeaderMoreOptionsDropdown } from './rb-view-header-more-options-dropdown'
import { RbViewHeaderInvoiceStatusSelector } from './rb-view-header-invoice-status-selector'
import { RbViewHeaderInvoicePrintSelected } from './rb-view-header-invoice-print-selected'
import { RbViewHeaderInvoiceDownloadSelected } from './rb-view-header-invoice-download-selected'
import { IRenditionBillingViewHeaderOptions } from './'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

/**
 * @prop dataSet - the entire EntityDataSource of the displayed invoice entities
 * @prop handleExport - handler to export the table of invoice entities as a csv file
 * @prop handleShowEntityFormPanel - handler to open the sales invoice creation panel
 * @prop onViewModeChange - handler to toggle the view state between full and detail entity views
 * @prop selection - array of invoice entities selected by the user
 */
export interface IRenditionBillingViewHeaderButtonsProps extends IRenditionBillingViewHeaderOptions {
  dataSet: IEntityPageContainerProps['dataSet']
  handleExport: () => void
  handleShowEntityFormPanel: () => void
  onViewModeChange: IEntityPageContainerProps['onViewModeChange']
  selection: IEntityPageContainerProps['selection']
}
export interface IRbViewHeaderEntitySelectionProps extends IBaseProps {
  selection: any[]
}

/**
 * buttons to be rendered into the View Header for invoicing views
 *
 * @props IRenditionBillingViewHeaderButtonsProps
 */
export const RenditionBillingViewHeaderButtons: React.FC<IRenditionBillingViewHeaderButtonsProps> = ({
  dataSet,
  handleExport,
  handleShowEntityFormPanel,
  onViewModeChange,
  selection,
  billSelectedText = 'Bill Selected',
  showBillAllReady = false
}) => {
  const handleRenditionBillingPressed = () => RenditionBillingConfirmationModal.open({ dataSet, selection })
  return (
    <>
      <CardHeaderItem>
        <RbViewHeaderInvoicePrintSelected selection={selection} />
      </CardHeaderItem>
      <CardHeaderItem>
        <RbViewHeaderInvoiceDownloadSelected selection={selection} />
      </CardHeaderItem>
      <CardHeaderItem>
        <RbViewHeaderInvoiceStatusSelector selection={selection} />
      </CardHeaderItem>
      <CardHeaderItem
        className='c-cardHeader-item--smallMargin'
      >
        <Button
          className='u-nowrap'
          isDisabled={_.isEmpty(selection)}
          onClick={handleRenditionBillingPressed}
        >
          {billSelectedText}
        </Button>
      </CardHeaderItem>
      {showBillAllReady &&
        <CardHeaderItem
          className='c-cardHeader-item--smallMargin'
        >
          <Button
            className='u-nowrap'
            intent={'primary' as Intent}
            isDisabled={!_.isEmpty(selection)}
            onClick={handleRenditionBillingPressed}
          >
            Bill All Ready
          </Button>
        </CardHeaderItem>
      }
      <CardHeaderItem>
        <RbViewHeaderMoreOptionsDropdown
          handleExport={handleExport}
          handleShowEntityFormPanel={handleShowEntityFormPanel}
          onViewModeChange={onViewModeChange}
        />
      </CardHeaderItem>
    </>
  )
}
