import classNames from 'classnames'
import React from 'react'

// TODO(louis): Not quite ready yet for react native
// import { StyleSheet, Text, View } from 'react-native'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/form-group-content-wrapper/_form-group-content-wrapper.scss'

interface IFormGroupContentWrapperProps extends IBaseProps {
  isHorizontalLayout?: boolean
  isDisabled?: boolean
  isFocused?: boolean
  hasError?: boolean
}

export class FormGroupContentWrapper extends React.Component<IFormGroupContentWrapperProps, any> {
  public render() {
    const { children, className, isHorizontalLayout, hasError, isDisabled, isFocused, 'data-debug-id': debugId } = this.props
    return (
      <div
        className={classNames('c-formGroupContentWrapper', className, {
          'c-formGroupContentWrapper--hasError': hasError,
          'c-formGroupContentWrapper--horizontal': isHorizontalLayout,
          'c-formGroupContentWrapper--isDisabled': isDisabled,
          'c-formGroupContentWrapper--isFocused': isFocused,
        })}
        data-debug-id={debugId}
      >
        {children}
      </div>
    )
  }
}
