import _ from 'lodash'

import { Formatter } from 'shared-libs/helpers/formatter'
import { Entity } from 'shared-libs/models/entity'
import { JSONSchemaResolver } from 'shared-libs/resolvers/json-schema-resolver'

import apis from 'browser/app/models/apis'

const getPropertySchema = (filter, entitySchema) => {
  const schema = filter.entityType || entitySchema

  if (!schema) {
    console.warn(`schema is missing for filter with path=${filter.path}`)
    return
  }

  const schemaResolver = new JSONSchemaResolver(apis)
  const result = schemaResolver.resolveSubschemaByValuePath(schema, filter.path)
  return result ? result.schema : null
}

const formatValue = (value, propertySchema, context?) => {
  if (propertySchema) {
    return Formatter.formatEntityValue(value, propertySchema, context)
  }
  // TODO(Peter): these cases should not be necessary they are just here for
  // back compat reasons
  if (_.isObject(value)) {
    return value.displayName
  } else if (_.isBoolean(value)) {
    return Formatter.formatBoolean(value)
  }
  return value
}

export const formatFilterValue = (filter, filterValues, entitySchema) => {
  if (_.isNil(filterValues)) { return }
  if (!_.isArray(filterValues)) { filterValues = [filterValues] }
  const propertySchema = getPropertySchema(filter, entitySchema)
  const context = { expectString: true }
  const values = _.map(filterValues, (value: any) => formatValue(value, propertySchema, context))
  return values.join(', ')
}
