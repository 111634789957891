import React from 'react'
import _ from 'lodash'
import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'

import { Select } from 'browser/components/atomic-elements/atoms/select'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'

import './label-marker.scss'

const EDGE_OPTIONS = [
  { label: 'Top edge', value: 'top' },
  { label: 'Bottom edge', value: 'bottom' },
  { label: 'Left edge', value: 'left' },
  { label: 'Right edge', value: 'right' }
]

const BORDER_POSITION_OPTIONS = [
   { label: 'Top border', value: 'top' },
   { label: 'Bottom border', value: 'bottom' },
   { label: 'Left border', value: 'left' },
   { label: 'Right border', value: 'right' }
]

interface ILabelMarkersProps {
  handleEntityChange: any
  handleRemoveLabel: any
  index: any
  isDisabled: boolean
  labelMarker: any
  style: any
}

export const LabelMarker: React.FC<ILabelMarkersProps> = ({
  handleEntityChange,
  handleRemoveLabel,
  index,
  isDisabled,
  labelMarker,
  style
}) => {
  return (
    <LabelBorder
      labelMarker={labelMarker}
      handleEntityChange={handleEntityChange}
      handleRemoveLabel={handleRemoveLabel}
      isDisabled={isDisabled}
      markerIndex={index}
      style={style}
    />
  )
}

interface ILabelBorderProps {
  handleEntityChange: any
  handleRemoveLabel: any
  isDisabled: boolean
  markerIndex: any
  style: any
  labelMarker: any
}

const LabelBorder: React.FC<ILabelBorderProps> = ({
  handleEntityChange,
  handleRemoveLabel,
  isDisabled,
  markerIndex,
  style,
  labelMarker
}) => {
  const { text, position, edge } = labelMarker

  const handleBorderPositionChange = (value) => {
    labelMarker.position = value
    handleEntityChange()
  }

  const handleEdgePositionChange = (value) => {
    labelMarker.edge = value
    handleEntityChange()
  }

  const handleLabelTextChange = (value) => {
    labelMarker.text = value
    handleEntityChange()
  }

  return (
    <div className='c-formGroup c-formGroup--isHorizontal' style={style}>
      <div className='c-formGroupContentWrapper'>
        <Select
          styles={{
            control: (base) => ({
              ...base,
              backgroundColor: '#f0f1f2'
            })
          }}
          className='c-label label-border-corner'
          isDisabled={isDisabled}
          onChange={handleBorderPositionChange}
          options={BORDER_POSITION_OPTIONS}
          value={position}
        />
      </div>
     <div className='c-formGroupContentWrapper'>
        <InputField
          placeholder='Label'
          value={text}
          onChange={handleLabelTextChange}
        />
      </div>
      <div className='c-formGroupContentWrapper'>
        <Select
          styles={{
            control: (base) => ({
              ...base,
              backgroundColor: '#f0f1f2'
            })
          }}
          className='label-border-corner'
          isDisabled={isDisabled}
          onChange={handleEdgePositionChange}
          options={EDGE_OPTIONS}
          value={edge}
        />
      </div>
      {!isDisabled && (
        <Button
          className={classNames(
            'c-button--square c-abstractListItem-removeButton',
            Classes.MINIMAL
          )}
          onClick={() => handleRemoveLabel(markerIndex)}
        >
          <Icon icon={IconNames.CROSS} />
        </Button>
      )}
    </div>
  )
}
