import React from 'react'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import { RequestMethod } from 'shared-libs/models/store'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { EntityErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/entity-error-block'
import { CheckboxField } from 'browser/components/atomic-elements/molecules/fields/checkbox-field'


interface IPasswordResetModalProps extends IBaseProps {
  userEntity: any
  onClose: () => void
}

interface IPasswordResetModalState {
  newPassword?: string
  errors?: any

  showPassword: boolean
}

export class PasswordResetModal extends React.Component<IPasswordResetModalProps, IPasswordResetModalState> {
  constructor(props) {
    super(props)
    this.state = {
      showPassword: false
    }
  }

  public static open(props?: any) {
    OverlayManager.openOverlayElement(
      <Modal
        modalPaperClassName=''
        modalDialogClassName='c-passwordResetModal'
      >
        <PasswordResetModal
          {...props}
        />
      </Modal>,
    )
  }

  private onSave = async () => {
    const { onClose, userEntity } = this.props
    const { newPassword } = this.state

    const jsonPatchContent = [
      { "op": "add", "path": "/user/password", "value": newPassword }
    ]

    try {
      await userEntity.save({}, RequestMethod.PATCH, false, jsonPatchContent)
    } catch(e) {
      this.setState({ errors: e.errors })
      return
    }

    onClose()
  }

  private onCancel = () => {
    const { onClose } = this.props
    onClose()
  }

  private setNewPassword = (newPassword) => {
    this.setState({ newPassword })
  }

  private setShowPassword = (value) => {
    this.setState({
      showPassword: value
    })
  }

  public render() {
    const { userEntity  } = this.props
    const { newPassword, errors, showPassword } = this.state

    return (
      <div data-debug-id='passwordResetModal'>
        <div className='c-modalHeader'>
          <h4 className='c-modal-title'>Reset Password</h4>
        </div>
        <div className='c-modalBody collapse'>
          <FormTable className="u-bumper--sm">
            <InputField
              isHorizontalLayout={true}
              size="sm"
              label="Password"
              autoComplete="off"
              autoFocus={true}
              type={showPassword ? "text" : "password"}
              onChange={this.setNewPassword}
              value={newPassword}
            />
          </FormTable>
          <CheckboxField
              className="u-bumper--sm"
              label="Show Password"
              onChange={this.setShowPassword}
              value={showPassword}
            />
          <Button
            className={classNames('u-bumper--sm', Classes.INTENT_PRIMARY)}
            onClick={this.onSave}
          >
            Save
          </Button>
          <Button
            className='u-bumper--sm'
            onClick={this.onCancel}
          >
            Cancel
          </Button>
          <div className="u-bumperLeft--sm u-innerBumperBottom--sm">
            <EntityErrorBlock entity={userEntity} errors={errors} />
          </div>
        </div>
      </div>
    )
  }
}
