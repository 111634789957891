import _ from 'lodash'
import { ApplicationContext } from '../applicationContext'

export function range(...params: any[]): number[] {
  return _.range.apply(null, params)
}

/**
 * Generate a deeplink to Entity details.
 */
export function deeplinkEntityDetails(id: string): string {
  const context = this as ApplicationContext
  const host = context?.getDeeplinkHost() ?? ''
  return `${host}/entity/${id}`
}

/**
 * Generate a deeplink to a Workflow Execution.
 */
export function deeplinkWorkflow(executionId: string, storyId: string): string {
  const context = this as ApplicationContext
  const host = context?.getDeeplinkHost() ?? ''
  const deeplink = `${host}/workflow/${executionId}`
  const storyParam = _.isEmpty(storyId) ? '' : `?storyId=${storyId}`
  return deeplink + storyParam
}

// TODO(Peter): this should probably be a custom function
export function joinWeight(...params: any[]): string {
  const weights = _.chain(params[0])
    .compact()
    .filter((weight: any) => {
      return weight.value && weight.unit
    })
    .value()

  const units = _.map(weights, 'unit')
  const values = _.chain(weights)
    .map('value')
    .map((x) => parseInt(x))
    .value()

  // if all unit are the same, return total
  if (_.sortedUniq(units).length == 1) {
    return _.sum(values) + ' ' + (units[0] === 'Kilograms' ? 'kg' : 'lbs')
  }

  // if units are different, return join items
  const normalizeWeights = []
  _.forEach(weights, (weight) => {
    const entry = weight.value + (weight.unit === 'Kilograms' ? ' kg' : ' lbs')
    normalizeWeights.push(entry)
  })
  return normalizeWeights.join(', ')
}

export function joinValue(...params: any[]) {
  const currency = params[0]
  const currencyType = _.map(currency, 'unit')
  const currencyValue = _.map(currency, 'value').map((x: any) => {
    // Need this because when we add cargo initial value is undefined
    if (isNaN(x)) return
    return parseFloat(x)
  })
  const filteredValues = _.filter(currencyValue, (value) => value !== undefined)
  // We assume all of the values are in one currency, also removing the
  // undefined which appear when we add a cargo intially and dont save it.
  if (_.filter(_.uniq(currencyType), (type) => type !== undefined).length == 1) {
    return '$' + _.sum(filteredValues).toFixed(2) + ' ' + currencyType[0]
  }
  //TODO:(DAVID) If they are mixed currency... we should figure out what to do...
  // This should not happen since we only have USD available for now.
  return 0
}

export function getPlatform(): string {
  const context = this as ApplicationContext
  return context?.getPlatform() ?? 'unknown'
}

export function getWindowDimensions(): object {
  const context = this as ApplicationContext
  return context?.getWindowDimensions() ?? {}
}
