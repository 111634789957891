
export function getPositionX(position, min, max) {
  if (position === 'left') {
    return min
  } else if (position === 'right') {
    return max
  } else if (position === 'center') {
    return (min + max) / 2
  }
}

export function getPositionY(position, min, max, invert = false) {
  if (position === 'top') {
    return invert ? max : min
  } else if (position === 'bottom') {
    return invert ? min : max
  } else if (position === 'middle') {
    return (min + max) / 2
  }
}

export function isElementCoveringTarget($target, $element, elementOffset) {
  const elementHeight = $element.outerHeight()
  const elementWidth = $element.outerWidth()
  const elementBounds = {
    maxX: elementOffset.left + elementWidth,
    maxY: elementOffset.top + elementHeight,
    minX: elementOffset.left,
    minY: elementOffset.top,
  }

  const targetOffset = $target.offset() || { left: 0, top: 0 }
  // This is necessary if target is zoomed or has scale transform
  const boundingRect = $target.get(0).getBoundingClientRect()
  const targetHeight = boundingRect.height
  const targetWidth = boundingRect.width
  const targetBounds = {
    maxX: targetOffset.left + targetWidth,
    maxY: targetOffset.top + targetHeight,
    minX: targetOffset.left,
    minY: targetOffset.top,
  }

  return elementBounds.minX < targetBounds.maxX &&
    elementBounds.maxX > targetBounds.minX &&
    elementBounds.maxY > targetBounds.minY &&
    elementBounds.minY < targetBounds.maxY
}

export function ensureNotOutOfBounds($container, $element, elementOffset, padding) {
  const containerOffset = $container.offset() || { left: 0, top: 0 }
  const containerHeight = $container.outerHeight()
  const containerWidth = $container.outerWidth()
  const containerBounds = {
    maxX: containerOffset.left + containerWidth - padding,
    maxY: containerOffset.top + containerHeight - padding,
    minX: containerOffset.left + padding,
    minY: containerOffset.top + padding,
  }

  const elementHeight = $element.outerHeight()
  const elementWidth = $element.outerWidth()
  const elementBounds = {
    maxX: elementOffset.left + elementWidth,
    maxY: elementOffset.top + elementHeight,
    minX: elementOffset.left,
    minY: elementOffset.top,
  }

  let offsetX = 0
  let offsetY = 0
  if (elementBounds.minX < containerBounds.minX) {
    offsetX = containerBounds.minX - elementBounds.minX
  } else if (elementBounds.maxX > containerBounds.maxX) {
    offsetX = containerBounds.maxX - elementBounds.maxX
  }
  if (elementBounds.minY < containerBounds.minY) {
    offsetY = containerBounds.minY - elementBounds.minY
  } else if (elementBounds.maxY > containerBounds.maxY) {
    offsetY = containerBounds.maxY - elementBounds.maxY
  }
  return { offsetX, offsetY }
}

// https://github.com/HubSpot/tether/blob/master/src/js/utils.js#L34
export function getScrollParents(el) {
  // In firefox if the el is inside an iframe with display: none; window.getComputedStyle() will return null;
  // https://bugzilla.mozilla.org/show_bug.cgi?id=548397
  const computedStyle: any = getComputedStyle(el) || {}
  const position = computedStyle.position
  const parents = []

  if (position === 'fixed') {
    return [el]
  }

  let parent = el
  while ((parent = parent.parentNode) && parent && parent.nodeType === 1) {
    let style
    try {
      style = getComputedStyle(parent)
    } catch (err) { 
      // ignore err
    }

    if (typeof style === 'undefined' || style === null) {
      parents.push(parent)
      return parents
    }

    const { overflow, overflowX, overflowY } = style
    if (/(auto|scroll|overlay)/.test(overflow + overflowY + overflowX)) {
      if (position !== 'absolute' || ['relative', 'absolute', 'fixed'].indexOf(style.position) >= 0) {
        parents.push(parent)
      }
    }
  }
  parents.push(el.ownerDocument.body)
  // If the node is within a frame, account for the parent window scroll
  if (el.ownerDocument !== document) {
    parents.push(el.ownerDocument.defaultView)
  }
  return parents
}
