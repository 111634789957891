import { RbViewHeaderInvoiceStatusSelector } from './rb-view-header-invoice-status-selector'
import { RenditionBillingViewHeaderButtons } from './rendition-billing-view-header-buttons'

/**
 * @prop billSelectedText - text to be displayed on the view header button that launches RB modal [default: 'Bill Selected']
 * @prop showBillAllReady - whether or not the 'Bill All Ready' button should displayed in the View Header
 */
export interface IRenditionBillingViewHeaderOptions {
  billSelectedText: string
  showBillAllReady: boolean
}

export { RbViewHeaderInvoiceStatusSelector, RenditionBillingViewHeaderButtons }
