import _ from 'lodash'
import { Edge, Email, PhoneNumber } from '../../../generated/server-types/entity'
import { Entity } from '../../../models/entity'

/**
 * Get the entity type for an entity by its last mixin value.
 */
export function entityType(entity: any): string {
  const json = _.get(entity, '_content', entity)
  const activeMixins = _.get(json, 'mixins.active', [])
  const mixinIds = _.map(activeMixins, 'entityId')
  return _.last(mixinIds)
}

/**
 * Misc helper functions to create entity edge or deferred user
 */
export function entityEdge(id: string): Edge {
  return _.isEmpty(id)
    ? null
    : {
        entityId: id,
      }
}

export function edgeFromEntity(entity: Entity): Edge {
  return {
    entityId: entity.uniqueId,
    displayName: entity.displayName,
  }
}

export function deferredUserPhone(
  phoneNumber: string,
  phoneExtension: string
): { phoneNumber: PhoneNumber } {
  return {
    phoneNumber: {
      phone: phoneNumber,
      ...(phoneExtension && { phoneExtension }),
    },
  }
}

export function deferredUserEmail(email: Email): { email: Email } {
  return {
    email,
  }
}

export function deferredUserId(id: string, displayName = ''): { user: Edge } {
  return {
    user: {
      displayName,
      entityId: id,
    },
  }
}

/**
 * Form a denormalize edge from an entity
 */
export function denormalizeEdge(entity: Entity, displayName, denormalizeFields): any {
  if (_.isEmpty(entity)) {
    return undefined
  }

  const denormalizedProps = _.reduce(denormalizeFields, (result, path) => {
    const value = entity.get(path)
    if (!_.isEmpty(value)) {
      _.set(result, [path], value)
    }
    return result

  }, {})

  return {
    entityId: entity.uniqueId,
    displayName,
    denormalizedProperties: denormalizedProps
  }
}

