import React from 'react'
import { BulkAddEntityPanel } from '../entity/bulk-add/bulk-add-entity-panel'

export class BatchInviteOmniButtonItem extends React.Component {

  public render() {
    return (
      <li className='c-dropdownList-item' onClick={() => BulkAddEntityPanel.open({})} >
        Batch Invite
      </li>
    )
  }
}
