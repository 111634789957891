import _ from 'lodash'
import React, { Component } from 'react'

import { DragTypes } from 'browser/components/atomic-elements/organisms/entity/bulk-add/drag-types'
import Color from 'color'
import { DragSource, DropTarget } from 'react-dnd'
import styled from 'styled-components'

const GREEN = '#2e7d32'
const ORANGE = '#f89939'
const GRAY = '#9e9e9e'

const TextDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const fieldDragHandlers = {
  beginDrag(props) {
    return { field: props.field, panelX: props.panelX, panelY: props.panelY }
  },

  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      props.handleFieldDelete()
    }
  },
}

const fieldDropHandlers = {
  drop(props, monitor) {
    props.handleFieldDrop(monitor.getItem().field)
  },
}

interface IFieldDropTargetProps {
  connectDropTarget: any
  connectDragSource: any
  connectDragPreview: any
  isOver: boolean
  isDragging: boolean
  isSourceDragging: boolean
  field: { id; label; required }
  handleFieldDrop: () => void
  handleFieldDelete: () => void
  handleColumnDelete: () => void
  panelX: number
  panelY: number
}

interface IFieldDropTargetState {
  isHovered: boolean
  isOverDelayed: boolean
  isSourceDraggingDelayed: boolean
}

class InnerFieldDropTarget extends Component<IFieldDropTargetProps, IFieldDropTargetState> {
  constructor(props) {
    super(props)
    this.state = {
      isHovered: false,
      isOverDelayed: false,
      isSourceDraggingDelayed: false,
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isOver !== nextProps.isOver || this.props.isSourceDragging !== nextProps.isSourceDragging) {
      setTimeout(() => {
        this.setState({ isOverDelayed: nextProps.isOver, isSourceDraggingDelayed: nextProps.isSourceDragging })
      }, 1)
    }
  }

  public render() {
    const { field, connectDropTarget, connectDragSource, isDragging } = this.props
    const { isOverDelayed, isSourceDraggingDelayed } = this.state
    const { isHovered } = this.state
    let border = `1px dashed ${GRAY}`
    let background = '#FFF' // Color(RED).alpha(0.05)
    let label = 'Skip Column'
    let clickHandler = null
    let boxShadow = 'none'
    const height = '28.5px'

    if (field && !isDragging) {
      border = `1px dashed ${isSourceDraggingDelayed ? GREEN : ORANGE}`
      label = field.label
      background = isOverDelayed ? Color(GREEN).alpha(0.25) : 'none'
      // fontStyle = 'normal'
    } else if (isOverDelayed || isSourceDraggingDelayed) {
      border = `1px dashed ${GREEN}`
      background = Color(GREEN).alpha(isOverDelayed ? 0.25 : 0.1)
      label = 'Drop Here'
      // fontStyle = 'normal'
    } else if (isHovered) {
      background = '#e3e3e3'
      border = 'none'
      label = 'Delete Column'
      boxShadow = 'inset 0 0 0 1px rgba(16,22,26,.1), inset 0 -1px 0 rgba(16,22,26,.05)'
      clickHandler = () => {
        this.props.handleColumnDelete()
      }
    }

    const outerDivStyle = {
      alignItems: 'center',
      background,
      border,
      borderRadius: '3px',
      boxShadow,
      cursor: field ? 'grab' : 'pointer',
      display: 'flex',
      height,
      justifyContent: (!field && isHovered) ? 'center' : 'space-between',
      padding: '0.25rem 0.5rem',
      width: '100%',
    }

    const textStyle = {
      fontSize: (!field && isHovered) ? '11px' : 'inherit',
      fontWeight: ((field && !isDragging) || isHovered) ? 'bold' : 'normal',
      textTransform: (!field && isHovered) ? 'uppercase' : 'none',
    }

    const additionalDivProps: any = {}
    if (clickHandler) {
      additionalDivProps.onClick = clickHandler
    }

    const dropTarget = connectDropTarget(
      <div
        style={outerDivStyle}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        {...additionalDivProps}
      >
        <TextDiv style={textStyle}>{label}</TextDiv>
      </div>,
    )

    return field ? connectDragSource(dropTarget) : dropTarget
  }

  private handleMouseEnter = () => { this.setState({ isHovered: true }) }
  private handleMouseLeave = () => { this.setState({ isHovered: false }) }
}

function mapDragStateToProps(connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }
}

function mapDropStateToProps(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isSourceDragging: monitor.canDrop(),
  }
}

const FieldDropTarget = DragSource(
  DragTypes.FIELD, fieldDragHandlers, mapDragStateToProps)(DropTarget(
  DragTypes.FIELD, fieldDropHandlers, mapDropStateToProps)(InnerFieldDropTarget))
export { FieldDropTarget }
