import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { CitySelect } from 'browser/components/atomic-elements/atoms/geo-region-input/city-select/city-select'
import { QuantityInput } from 'browser/components/atomic-elements/atoms/input/quantity-input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'

export interface IGeoRegionCityTabPanelProps extends IBaseProps {
  errors?: any[]
  showDeadHeadInput?: boolean
  showGeoRegionTabs?: boolean
  isHorizontalLayout?: boolean
  isStatic?: boolean
  onChange: (value: any) => void
  placeholder?: string
  size?: string
  value?: any
}

export class GeoRegionCityTabPanel extends React.Component<IGeoRegionCityTabPanelProps, any> {

  public static defaultProps: Partial<IGeoRegionCityTabPanelProps> = {
    showDeadHeadInput: true,
    showGeoRegionTabs: true,
  }

  public focus() {
    // TODO(peter/louis): Depends on the value and the tabs
    // this.input.focus()
    return false
  }

  public render() {
    const { value, showDeadHeadInput, showGeoRegionTabs, isHorizontalLayout } = this.props
    const address = _.get(value, 'address')
    const radius = _.get(value, 'radius')
    const unitOptions = [{ label: 'Miles', value: 'Miles' }]
    return (
      <div>
        <FormGroup>
          <CitySelect
            clearable={true}
            className={classNames('c-geoRegionInput-cityPicker', {
              'c-geoRegionInput-cityPicker--bottomSquare': (!_.isEmpty(address)) && showDeadHeadInput,
              'c-geoRegionInput-cityPicker--topSquare': showGeoRegionTabs,
            })}
            onChange={this.handleAddressChange}
            placeholder='Search for a city'
            value={address}
          />
        </FormGroup>
        {/* <Collapse isOpen={!_.isEmpty(address) && showDeadHeadInput}> */}
        <FormGroup isHorizontalLayout={isHorizontalLayout}>
          <Label isHorizontalLayout={isHorizontalLayout}>
            Deadhead
          </Label>
          <QuantityInput
            className='c-geoRegionInput-cityRadius'
            defaultUnit='Miles'
            onChange={this.handleRadiusChange}
            unitOptions={unitOptions}
            value={radius}
          />
        </FormGroup>
        {/* </Collapse> */}
      </div>
    )
  }

  private handleAddressChange = (address) => {
    if (!address) {
      this.props.onChange(undefined)
      return
    }
    const { value } = this.props
    this.props.onChange({
      address,
      radius: _.get(value, 'radius'),
      type: 'address',
    })
  }

  private handleRadiusChange = (radius) => {
    const { value } = this.props
    this.props.onChange({
      address: _.get(value, 'address'),
      radius,
      type: 'address',
    })
  }
}
