import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { EntitySelectField } from 'browser/components/atomic-elements/molecules/fields/select-field/entity-select-field'

import { DependenciesSection } from './dependencies-section'
import { SchemasSection } from './schemas-section'

/**
 * @uiComponent
 */
interface IApplicationBundleEditorProps extends IBaseProps {
  bundle: any
}

interface IApplicationBundleEditorState {
  isLoading: boolean
  isSaving: boolean
}

export class ApplicationBundleEditor
  extends React.Component<IApplicationBundleEditorProps, IApplicationBundleEditorState> {

  public store: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      isLoading: true,
      isSaving: false,
    }
    this.handleEntityChange = this.handleEntityChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  public componentDidMount() {
    this.fetchDataForProps(this.props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.bundle !== nextProps.bundle) {
      this.fetchDataForProps(nextProps)
    }
  }

  public render() {
    if (this.state.isLoading) {
      return <LoadingSpinner/>
    }
    const { className, bundle } = this.props
    return (
      <div className={this.props.className}>
        {this.renderGeneralSection()}
        {this.renderOwnerSection()}
        <DependenciesSection
          bundle={bundle}
        />
        <SchemasSection
          bundle={bundle}
        />
        {this.renderViewsSection()}
        {this.renderSettingsSection()}
      </div>
    )
  }

  private cacheEdges(edges) {
    const ids = _.map(edges, 'entityId')
    const idsToFetch = _.filter(ids, (id) => !this.store.getRecord(id))
    if (_.isEmpty(idsToFetch)) {
      return Promise.resolve(_.map(ids, (id) => this.store.getRecord(id)))
    }
    return this.store.findRecords(ids, {
      shouldCacheRecord: true,
      shouldResolveMixins: true,
    })
  }

  private fetchDataForProps(props) {
    const { applicationBundle } = props.bundle
    this.setState({ isLoading: true })
    this.cacheEdges(applicationBundle.extends).then((bundles) => {
      let edges = []
      _.forEach(bundles, (bundle) => {
        edges = edges.concat(_.values(bundle.applicationBundle.schemas))
      })
      return this.cacheEdges(edges)
    }).then(() => {
      this.setState({ isLoading: false })
    })
  }

  private handleEntityChange(path, value) {
    const { bundle } = this.props
    _.set(bundle, path, value)
    this.forceUpdate()
  }

  private handleSave() {
    const { bundle } = this.props
    this.setState({ isSaving: true })
    bundle.save().finally(() => this.setState({ isSaving: false }))
  }

  private renderGeneralSection() {
    const { applicationBundle } = this.props.bundle
    return (
      <Section
        title='General'
      >
        <div className='row'>
          <div className='col-xs-4'>
            <p>
              We have two variations of bundles. One is firm specific and the other product specific. [Firm] Name OR [Application suite] Name.
            </p>
          </div>
          <div className='col-xs-8'>
            <InputField
              helpText='Eg. Alpine Trucking Bunlde or DMS Bundle.'
              label='Application Bundle Name'
              onChange={(value) => this.handleEntityChange('applicationBundle.name', value)}
              value={applicationBundle.name}
            />
          </div>
        </div>
      </Section>
    )
  }

  private renderOwnerSection() {
    const { applicationBundle } = this.props.bundle
    return (
      <Section
        className='u-bumperTop'
        title='Owner'
      >
        <div className='row'>
          <div className='col-xs-4'>
            <p>
              Who owns this app bundle.
            </p>
          </div>
          <div className='col-xs-8'>
            <EntitySelectField
              entityType='/1.0/entities/metadata/firm.json'
              helpText='Select which firm this app bundle belongs to.'
              label='Firm'
              onChange={(value) => this.handleEntityChange('applicationBundle.firm', value)}
              value={applicationBundle.firm}
            />

            <div className='c-horizontalLine u-bumperBottom'>
              or
            </div>

            <InputField
              isDisabled={true}
              placeholder='Coming soon'
              label='User'
            />
          </div>
        </div>
      </Section>
    )
  }

  private renderViewsSection() {
    const { bundle } = this.props
    const { applicationBundle } = bundle
    return (
      <Section
        title='Views'
      >
        <div className='row'>
          <div className='col-xs-4'>
            <p>
              List all available views in this app bundle. Most views are hidden. Allows overriding of specific views.
            </p>
          </div>
          <div className='col-xs-8'>
            <EntitySelectField
              entityType='/1.0/entities/metadata/view.json'
              helpText='This is the shell that holds the pages. The view that defines which sidebar navigation elements are visible.'
              label='Application view'
              onChange={(value) => this.handleEntityChange('applicationBundle.views.application', value)}
              value={_.get(applicationBundle, 'views.application')}
            />

            DEMO DATA - App bundles view imports.<br /><br />
            Document &gt; entityDetailCard<br />
            View 31421321<br /><br />
            Document &gt; entityDetailCard<br />
            View 98172937<br /><br />

          </div>
        </div>
      </Section>
    )
  }

  private renderSettingsSection() {
    return (
      <Section
        title='Application Bundle Settings'
      >
        <div className='row'>
          <div className='col-xs-4'>
            <p>
              TBD - What kind of settings go in here?
            </p>
          </div>
          <div className='col-xs-8'>
            DEMO DATA<br />
            Formatting Options<br />
            GPS formatting<br />
            Time formatting<br />
          </div>
        </div>
      </Section>
    )
  }
}
