import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import { delay } from 'bluebird'
import { Position } from '@blueprintjs/core'

import { Entity } from 'shared-libs/models/entity'
import { User } from 'shared-libs/models/types/user'
import { Formatter } from 'shared-libs/helpers/formatter'
import { convertServerError } from 'shared-libs/models/utils'
import {
  Checkin,
  CheckinListResponse,
} from 'shared-libs/generated/server-types/entity/action/system/sms/flow/checkinListResponse'

import apis from 'browser/app/models/apis'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { TableCell } from 'browser/components/domain/user/dialog-option-styles'
import { HtmlTable } from 'browser/components/atomic-elements/atoms/table/html-table'
import { IModalPortalProps } from 'browser/components/atomic-elements/atoms/modal/portal'
import { IRenderListItemProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'

const TABLE_COLUMNS = [
  { label: 'Entity Id' },
  { label: 'Session Id' },
  { label: 'Created On' },
  { label: 'Expires On' },
  { label: 'Facility Number' },
  { label: 'User Number' },
]

interface IUserConversationsModalProps extends IModalPortalProps {
  entity: Entity & { user: User }
}

interface IUserConversationsModalState {
  isLoading: boolean
  response?: CheckinListResponse
}

export class UserConversationsModal extends React.Component<
  IUserConversationsModalProps,
  IUserConversationsModalState
> {
  public static open(props) {
    OverlayManager.openOverlay(this, {
      ...props,
    })
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
    }
  }

  componentDidMount(): void {
    this.getConversations()
  }

  render() {
    const { onClose } = this.props
    const { isLoading } = this.state
    if (isLoading) {
      return <LoadingSpinner />
    }
    return (
      <Modal
        modalDialogClassName='c-modal-dialog--xl'
        onClose={onClose}
      >
        <div className='c-modalHeader'>
          <h4 className="c-modal-title mb1">
            SMS Sessions
          </h4>
        </div>
        <div className='c-modalBody u-textCenter'>
          <div className='ph4'>
            {this.renderContent()}
          </div>
        </div>
        <Footer
          isPrimaryButtonDisabled={true}
          onCancelButtonClick={onClose}
          primaryButtonText=''
          cancelButtonText='Close'
        />
      </Modal>
    )
  }

  private renderContent = () => {
    const { response } = this.state
    if (_.isEmpty(response?.checkins)) {
      return null
    }
    return (
      <HtmlTable
        columns={TABLE_COLUMNS}
        value={response.checkins}
        renderListItem={this.renderItem}
        showItemDeleteButton={false}
      />
    )
  }

  private renderItem = (props: IRenderListItemProps<Checkin>) => {
    const { item: checkin } = props
    const link = `${apis.WEBAPP_URL}/entity/${checkin.entityId}`
    const proxyAddress = checkin.proxyAddress
      ? Formatter.formatPhoneNumber(checkin.proxyAddress)
      : undefined
    const phoneNumber = checkin.phoneNumber
      ? Formatter.formatPhoneNumber(checkin.phoneNumber)
      : undefined
    return (
      <tr className="c-table-row">
        {this.renderCell(
          <a className="underline" target="_blank" rel="noopener noreferrer" href={link}>
            {checkin.entityId}
          </a>
        )}
        {this.renderCell(checkin.sessionId)}
        {this.renderCell(moment(checkin.createdOn).format('L LT'))}
        {this.renderCell(moment(checkin.expiresOn).format('L LT'))}
        {this.renderCell(proxyAddress)}
        {this.renderCell(phoneNumber)}
      </tr>
    )
  }

  private renderCell(content: any) {
    return (
      <TableCell
        className='c-table-cell tl wrap'
      >
        {content}
      </TableCell>
    )
  }

  private getConversations = () => {
    const { entity } = this.props
    entity.user.fetchConversations()
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleResponse(undefined, error))
  }

  private handleResponse = async (response?: CheckinListResponse, error?: Error): Promise<void> => {
    const { entity } = this.props

    // artificial load time
    await delay(200)

    if (!_.isNil(error)) {
      const errorMessage = convertServerError(error)?.message ?? error?.message ?? error
      this.closeWithMessage(errorMessage)
    } else if (_.isEmpty(response?.checkins)) {
      this.closeWithMessage(`${entity.displayName} does not have any SMS sessions.`)
    } else {
      response.checkins = _.sortBy(response.checkins, (checkin) => checkin.createdOn).reverse()

      this.setState({
        isLoading: false,
        response,
      })
    }
  }

  private closeWithMessage(message: string) {
    const { onClose } = this.props
    Toast.show({
      message,
      position: Position.BOTTOM_RIGHT,
    })
    onClose?.()
  }
}
