import React from 'react'
import { Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { EntityFooter, IEntityFooterProps } from 'browser/components/atomic-elements/atoms/footer/entity-footer'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { getDispatchStatus, StatusSelect } from './status-select'

/**
 * @uiComponent
 */
interface IOrderFooterProps extends IEntityFooterProps {
  entity: any
}

interface IOrderFooterStates {
  isSaving: boolean
  isSavingStatus: boolean
}

export class OrderFooter extends React.Component<IOrderFooterProps, IOrderFooterStates> {

  constructor(props) {
    super(props)
    this.state = {
      isSaving: false,
      isSavingStatus: false,
    }
  }

  public render() {
    const {
      className,
      entity,
      errors,
      isVisible,
      onCancelButtonClick,
    } = this.props
    return (
      <EntityFooter
        className={className}
        entity={entity}
        errors={errors}
        isVisible={isVisible}
        onCancelButtonClick={onCancelButtonClick}
        primaryButtonGroupElement={this.renderPrimaryButtonGroup()}
      />
    )
  }

  private handleSave = () => {
    this.setState({ isSaving: true })
    this.props.onPrimaryButtonClick()
      .finally(() => this.setState({ isSaving: false }))
  }

  private handleSetStatusAndSave = (status) => {
    this.setState({ isSavingStatus: true })
    const { entity } = this.props
    entity.set('dispatchOrder.status', {
      primary: status.primary,
      secondary: status.secondary,
    })
    return this.props.onPrimaryButtonClick()
      .finally(() => this.setState({ isSavingStatus: false }))
  }

  private renderPrimaryButtonGroup() {
    const { isSaving, isSavingStatus } = this.state
    return (
      <div className='flex'>
        <div className='c-footer-item'>
          <Button
            data-debug-id='primaryButton'
            className={classNames(Classes.INTENT_PRIMARY)}
            isDisabled={isSavingStatus}
            isLoading={isSaving || this.props.entity.areInflationsPending()}
            onClick={this.handleSave}
          >
            Save
          </Button>
        </div>
        {this.renderSetToNextStatusButton()}
      </div>
    )
  }

  private renderSetToNextStatusButton() {
    const { entity } = this.props
    const { isSaving, isSavingStatus } = this.state
    const canModifyModel = !isSaving && !isSavingStatus
    const statusOptions = getDispatchStatus(entity)
    const nextStatus = getNextStatus(entity, statusOptions)
    const areInflationsPending = entity.areInflationsPending()
    if (!nextStatus) {
      const { status } = entity.dispatchOrder
      console.warn(`[OrderFooter]: Bad order status=${JSON.stringify(status)}`)
      return
    }
    const tetherOptions = {
      attachment: 'bottom right',
      targetAttachment: 'top right',
    }
    return (
      <div className="c-footer-item">
        <div className={Classes.BUTTON_GROUP}>
          <Button
            className={Classes.INTENT_PRIMARY}
            data-debug-id="saveAndMoveToNextStatus"
            isDisabled={isSaving}
            isLoading={isSavingStatus || areInflationsPending}
            onClick={() => this.handleSetStatusAndSave(nextStatus)}
          >
            Save and set to {nextStatus.secondary}
          </Button>
          <TetherTarget
            closeOnPortalClick={true}
            isEnabled={canModifyModel}
            tetherOptions={tetherOptions}
            tethered={this.renderStatusDropdownMenu(statusOptions)}
          >
            <Button
              className={classNames(
                Classes.INTENT_PRIMARY,
                Classes.iconClass(IconNames.CHEVRON_DOWN)
              )}
              isDisabled={!canModifyModel || areInflationsPending}
            />
          </TetherTarget>
        </div>
      </div>
    )
  }

  private renderStatusDropdownMenu(statusOptions) {
    const { entity } = this.props
    const { status } = entity.dispatchOrder
    return (
      <Popover
        className='collapse'
        data-debug-id='document-options-dropdown'
      >
        <StatusSelect
          onChange={(value, option) => this.handleSetStatusAndSave(option)}
          options={statusOptions}
          optionGroupPath='primary'
          optionLabelPath='secondary'
          optionValuePath='secondary'
          value={status.secondary}
        />
      </Popover>
    )
  }
}

function getNextStatus(entity, options) {
  const { status } = entity.dispatchOrder
  for (let i = 0; i < options.length - 1; ++i) {
    if (options[i].primary === status.primary &&
        options[i].secondary === status.secondary) {
      return options[i + 1]
    }
  }
}
