/**
 * Storyboard task enrichment of the json task equivalent.
 *
 */

import _ from 'lodash'
import { IStoryboardEnrichProps, StoryboardElement } from './storyboard-element'
import { EventType } from './storyboard-execution'
import { PlatformType } from './storyboard-plan'
import { Task } from './storyboard-plan-model'

export enum TaskType {
  CREATE   = 'create',
  EDIT     = 'edit',
  SHARE    = 'share',
  DETAIL   = 'detail',
}


const taskSectionMap = {
  [TaskType.CREATE]: 'taskCreateSection',
  [TaskType.EDIT]  : 'taskEditSection',
  [TaskType.DETAIL]  : 'taskDetailSection',
}

export class StoryboardTask extends StoryboardElement {
  actionType: string

  constructor(data: Task, schema: any) {
    super(data, schema)
  }

  public enrich(props: IStoryboardEnrichProps): StoryboardTask {
    super.enrich(props)

    return this
  }

  public platformSchema(platform: PlatformType) {
    return _.get(this, `data.uiView.${platform}.uiSchema`)
  }

  /**
   * Get the UI schema based on the task action type
   * @param platform
   * @returns
   */
  public uiSchema(platform: PlatformType) {
    const platformSchema = this.platformSchema(platform)
    const schemaKey =  _.get(taskSectionMap, this.actionType)

    return _.get(platformSchema, schemaKey, {})
  }
}
