import classNames from 'classnames'
import Immutable from 'immutable'
import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { Entity } from 'shared-libs/models/entity'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { List } from 'browser/components/atomic-elements/atoms/list'
import { IAbstractListProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import { IRenderListItemProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import 'browser/components/json-elements/atoms/_list.scss'
import { getLabelProps } from 'browser/components/json-elements/higher-order-components/utils'

/**
 * @uiComponent
 */
export interface IUIListProps extends IAbstractListProps {
  addItemSheet?: React.ReactElement<any>
  entity: Entity
  isDisabled?: boolean
  isSelectable?: boolean
  frames: FramesManager
}

interface IUIListState {
  isAddItemSheetOpened: boolean
}

export class UIList extends React.Component<IUIListProps, IUIListState> {
  constructor(props: IUIListProps) {
    super(props)
    this.state = {
      isAddItemSheetOpened: false,
    }
  }

  public render() {
    const { addItemSheet } = this.props
    const renderAddButton = addItemSheet ? this.renderOpenAddItemButton : undefined
    return (
      <div>
        <List
          {...this.props}
          renderAddButton={renderAddButton}
          renderListItem={this.renderListItem}
        />
        {this.renderAddButtonSheet()}
      </div>
    )
  }

  private renderListItem = (props: IRenderListItemProps) => {
    const { frames, isSelectable } = this.props
    return createListItemElement({ frames, isSelectable, ...props })
  }

  private renderOpenAddItemButton = () => {
    const { addButtonClassName, addButtonText, showAddButton, size } = this.props
    const value = this.getValue()
    if (!showAddButton) {
      return
    }
    // In the case of no placeholder item
    if (_.isEmpty(value)) {
      // TODO(louis): Need to generalize this once we have more fields
      // depending on sheets.
      return (
        <FormTable className="c-formTable--noBorderTop">
          <FormGroup className="u-bumperBottom--lg" isHorizontalLayout={true}>
            <Label isHorizontalLayout={true} size="sm">
              Address
            </Label>
            <div className="c-formGroup-horizontalContent u-positionRelative">
              <div className="c-sheetPlaceholderBackground">
                <div className="c-fakeInputContainer">Address</div>
              </div>
              <Button
                className={classNames('c-abstractList-addButton u-width100 tr', Classes.MINIMAL)}
                onClick={this.handleOpenAddItemSheet}
              >
                {addButtonText}
              </Button>
            </div>
          </FormGroup>
        </FormTable>
      )
    }
    return (
      <div className="tr">
        <Button
          className={classNames('c-abstractList-addButton', Classes.MINIMAL, addButtonClassName)}
          onClick={this.handleOpenAddItemSheet}
          size="small"
        >
          {addButtonText}
        </Button>
      </div>
    )
  }

  private renderAddButtonSheet() {
    const { addItemSheet, entity, frames } = this.props
    const { isAddItemSheetOpened } = this.state
    if (!addItemSheet) {
      return
    }
    const handleAddItem = (newItem) => {
      const value = this.getValue()
      value.push(newItem)
      this.props.onChange(value)
      this.handleCloseAddItemSheet()
    }
    const dataSchemaPath = frames.getContext('dataSchemaPath').concat('items')
    const dataSchema = entity.resolveSubschemaByPath(dataSchemaPath).schema
    const uiSchemaPath = frames.getContext('uiSchemaPath').concat('items')
    const uiSchema = entity.resolveSubschemaByPath(uiSchemaPath).schema
    const labelProps = getLabelProps(dataSchema, uiSchema)
    return React.cloneElement(addItemSheet, {
      isOpen: isAddItemSheetOpened,
      labelProps,
      onCancel: this.handleCloseAddItemSheet,
      onChange: handleAddItem,
    })
  }

  private getValue() {
    return this.props.value || []
  }

  private handleOpenAddItemSheet = () => {
    this.setState({ isAddItemSheetOpened: true })
  }

  private handleCloseAddItemSheet = () => {
    this.setState({ isAddItemSheetOpened: false })
  }
}

export function createListItemElement(props) {
  const { frames, item, items, index, onRemove, onClick, selectionOptions, isSelectable } = props
  const renderer = frames.getContext('renderer')
  const uiSchema = frames.getContext('uiSchema').items
  const uiSchemaPath = frames.getContext('uiSchemaPath').concat('items')
  let dataSchema = frames.getContext('dataSchema')
  let dataSchemaPath = frames.getContext('dataSchemaPath')
  let valuePath

  if (!_.isEmpty(dataSchemaPath)) {
    dataSchemaPath = frames.getContext('dataSchemaPath').concat('items')
    dataSchema = renderer.getComponentDataSchema(dataSchemaPath)
    valuePath = frames.getContext('valuePath').concat(index)
  }
  const context = {
    dataSchema,
    dataSchemaPath,
    uiSchema,
    uiSchemaPath,
    valuePath,
  }
  const state = [item, { item, items, index }]
  const newFrames = renderer.createChildFrame(frames, context, state)
  // we will automatically inject onRemove into the list items, however,
  // user are able to map onRemove to props if they want to also since
  // we put onRemove in the frame's action hash
  return renderer.createElementFromFrame(newFrames, {
    index,
    onRemove,
    onClick,
    selectionOptions,
    isSelectable,
  })
}
