import classNames from 'classnames'
import React from 'react'
import { Motion, spring } from 'react-motion'
import { Classes } from '@blueprintjs/core'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { ErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/error-block'
import 'browser/components/atomic-elements/atoms/footer/_footer.scss'
import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core'

interface HotkeyDefinition {
  combo: string
  label: string
}

type OnClickType = (() => any) | (() => Promise<any>)

/**
 * @uiComponent
 */
export interface IFooterProps extends IBaseProps {
  errorText?: string
  errorBlockChildren?: React.ReactElement<any>
  isVisible?: boolean

  isCancelButtonDisabled?: boolean
  isCancelButtonLoading?: boolean
  cancelButtonText?: string
  cancelButtonGroupElement?: React.ReactElement<any>
  onCancelButtonClick?: OnClickType
  cancelButtonHotkey?: HotkeyDefinition

  isDeleteButtonDisabled?: boolean
  isDeleteButtonLoading?: boolean
  deleteButtonText?: string
  onDeleteButtonClick?: OnClickType
  deleteButtonHotkey?: HotkeyDefinition

  isPrimaryButtonDisabled?: boolean
  isPrimaryButtonLoading?: boolean
  primaryButtonText?: string
  primaryButtonGroupElement?: React.ReactElement<any>
  onPrimaryButtonClick?: OnClickType
  primaryButtonHotkey?: HotkeyDefinition

  // tslint:disable-next-line:member-ordering
  size?: string

  buttonClassName?: string
  footerHeight?: number
  shouldAnimate?: boolean
}

const FOOTER_HEIGHT = 60

@HotkeysTarget
export class Footer extends React.Component<IFooterProps, any, never> {

  public static defaultProps: Partial<IFooterProps> = {
    cancelButtonText: 'Cancel',
    deleteButtonText: 'Delete',
    isVisible: true,
    primaryButtonText: 'Save',
    footerHeight: FOOTER_HEIGHT,
    shouldAnimate: true,
  }

  constructor(props) {
    super(props)
    const { isVisible, footerHeight } = this.props
    const initialMarginBottom = isVisible ? 0 : -footerHeight
    this.state = {
      animation: {
        from: {
          height: footerHeight,
          marginBottom: initialMarginBottom,
        },
        to: {
          height: footerHeight,
          marginBottom: initialMarginBottom,
        },
      },
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isVisible !== nextProps.isVisible) {
      const { animation } = this.state
      const nextAnimation = {
        from: animation.from,
        to: {
          height: this.props.footerHeight,
          marginBottom: spring(nextProps.isVisible ? 0 : -this.props.footerHeight),
        },
      }
      this.setState({ animation: nextAnimation })
    }
  }

  public render() {
    const { animation } = this.state
    if (!this.props.shouldAnimate) {
      return this.renderContent({})
    }
    return (
      <Motion
        defaultStyle={animation.from}
        style={animation.to}
      >
        {(style) => this.renderContent(style)}
      </Motion>
    )
  }

  public renderContent(style) {
    const { className, children, size } = this.props
    const sizeClassName = size ? `c-footer--${size}` : ''
    if (children) {
      return (
        <div
          className={classNames('c-footer u-noPrint', sizeClassName,  className)}
          style={style}
        >
          {children}
        </div>
      )
    }
    return (
      <div
        className={classNames('c-footer u-noPrint', sizeClassName, className)}
        style={style}
      >
        {this.renderCancelButtonGroup()}
        {this.renderPrimaryButtonGroup()}
      </div>
    )
  }

  public renderHotkeys() {
    const { 
      primaryButtonHotkey,
      onPrimaryButtonClick,
      isPrimaryButtonDisabled, 
      isPrimaryButtonLoading,

      cancelButtonHotkey,
      onCancelButtonClick,
      isCancelButtonDisabled,
      isCancelButtonLoading,

      deleteButtonHotkey,
      onDeleteButtonClick,
      isDeleteButtonDisabled,
      isDeleteButtonLoading
     } = this.props
    return (
      <Hotkeys>
        {(primaryButtonHotkey && !isPrimaryButtonDisabled && !isPrimaryButtonLoading) && (
          this.renderHotkey(primaryButtonHotkey, onPrimaryButtonClick)
        )}
        {(cancelButtonHotkey && !isCancelButtonDisabled && !isCancelButtonLoading) && (
          this.renderHotkey(cancelButtonHotkey, onCancelButtonClick)
        )}
        {(deleteButtonHotkey && !isDeleteButtonDisabled && !isDeleteButtonLoading) && (
          this.renderHotkey(deleteButtonHotkey, onDeleteButtonClick)
        )}
      </Hotkeys>
    )
  }

  private renderHotkey(hotkey: HotkeyDefinition, callback: OnClickType) {
    return (
      <Hotkey
        global={true}
        allowInInput={true}
        combo={hotkey.combo}
        label={hotkey.label}
        onKeyDown={callback}
        stopPropagation={true}
        preventDefault={true}
      />
    )
  }

  private renderCancelButtonGroup() {
    const { cancelButtonGroupElement } = this.props
    return (
      <div className='c-footer-group'>
        {this.renderCancelButton()}
        {this.renderDeleteButton()}
        {cancelButtonGroupElement}
      </div>
    )
  }

  private renderCancelButton() {
    const {
      buttonClassName,
      cancelButtonText,
      onCancelButtonClick,
      isCancelButtonLoading,
      isCancelButtonDisabled,
      isDeleteButtonLoading,
      isPrimaryButtonLoading,
      isVisible,
      size,
    } = this.props
    if (onCancelButtonClick && cancelButtonText) {
      const tabIndex = isVisible ? 0 : -1
      return (
        <div className='c-footer-item'>
          <Button
            isLoading={isCancelButtonLoading}
            isDisabled={isCancelButtonDisabled || isPrimaryButtonLoading || isDeleteButtonLoading}
            data-debug-id='cancelButton'
            onClick={onCancelButtonClick}
            tabIndex={tabIndex}
            size={size}
            className={buttonClassName}
          >
            {cancelButtonText}
          </Button>
        </div>
      )
    }
  }

  private renderDeleteButton() {
    const {
      buttonClassName,
      deleteButtonText,
      onDeleteButtonClick,
      isCancelButtonLoading,
      isDeleteButtonLoading,
      isDeleteButtonDisabled,
      isPrimaryButtonLoading,
      isVisible,
      size,
    } = this.props
    if (onDeleteButtonClick) {
      const tabIndex = isVisible ? 0 : -1
      return (
        <div className='c-footer-item'>
          <Button
            className={classNames(Classes.INTENT_DANGER, buttonClassName)}
            isLoading={isDeleteButtonLoading}
            isDisabled={isDeleteButtonDisabled || isPrimaryButtonLoading || isCancelButtonLoading}
            data-debug-id='deleteButton'
            onClick={onDeleteButtonClick}
            tabIndex={tabIndex}
            size={size}
          >
            {deleteButtonText}
          </Button>
        </div>
      )
    }
  }

  private renderPrimaryButtonGroup() {
    const { primaryButtonGroupElement } = this.props
    return (
      <React.Fragment>
        <div className='c-footer-group'>
          {this.renderErrorBlock()}
        </div>
        <div className='c-footer-group' style={{flexShrink: 0}}>
          {primaryButtonGroupElement}
          {this.renderPrimaryButton()}
        </div>
      </React.Fragment>
    )
  }

  private renderErrorBlock() {
    const { errorText, errorBlockChildren } = this.props
    if (errorBlockChildren) {
      return errorBlockChildren
    }
    return (
      <ErrorBlock
        errorText={errorText}
      />
    )
  }

  // private renderPrimaryFooterGroup() {
  //   const {
  //     renderPrimaryFooterGroup,
  //   } = this.props
  //   const primaryButtonElement = this.renderPrimaryButton()
  //   if (renderPrimaryFooterGroup) {
  //     return renderPrimaryFooterGroup(primaryButtonElement, this.renderErrors())
  //   }
  //   return (
  //     <div
  //       className={classNames('c-footer-group', {
  //         'u-hide': !primaryButtonElement,
  //       })}
  //     >
  //       {this.renderErrors()}
  //       {primaryButtonElement}
  //     </div>
  //   )
  // }

  private renderPrimaryButton() {
    const {
      buttonClassName,
      isPrimaryButtonDisabled,
      isPrimaryButtonLoading,
      onPrimaryButtonClick,
      primaryButtonText,
      isVisible,
      size,
    } = this.props
    if (onPrimaryButtonClick) {
      const tabIndex = isVisible ? 0 : -1
      return (
        <div className='c-footer-item'>
          <Button
            data-debug-id='primaryButton'
            className={classNames('u-nowrap', Classes.INTENT_PRIMARY, buttonClassName)}
            isDisabled={isPrimaryButtonDisabled}
            isLoading={isPrimaryButtonLoading}
            onClick={onPrimaryButtonClick}
            tabIndex={tabIndex}
            size={size}
          >
            {primaryButtonText}
          </Button>
        </div>
      )
    }
  }

}
