import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'

interface IOrBlockProps extends IBaseProps {
  orText?: string
  isVertical?: boolean
  borderClassName?: string
}

export class OrBlock extends React.PureComponent<IOrBlockProps, any> {

  public static defaultProps: Partial<IOrBlockProps> = {
    orText: 'OR',
  }

  public render() {
    const { isVertical } = this.props
    if (isVertical) {
      return this.renderVerticalOrBlock()
    } else {
      return this.renderHorizontalOrBlock()
    }
  }

  private renderVerticalOrBlock() {
    const { borderClassName, className, orText } = this.props

    return (
      <div className={classNames('flex flex-column items-center mh2', className)}>
        <div className={classNames('bl b--light-gray h-100', borderClassName)} />
        <div className='pa3'>
          <HelpBlock className='collapse'>
            {orText}
          </HelpBlock>
        </div>
        <div className={classNames('bl b--light-gray h-100', borderClassName)} />
      </div>
    )
  }

  private renderHorizontalOrBlock() {
    const { borderClassName, className, orText } = this.props
    return (
      <div className={classNames('flex flex-row items-center mv2', className)}>
        <div className={classNames('bt b--light-gray w-100', borderClassName)} />
        <div className='pa2'>
          <HelpBlock className='collapse'>
            {orText}
          </HelpBlock>
        </div>
        <div className={classNames('bt b--light-gray w-100', borderClassName)} />
      </div>
    )
  }
}
