import { Icon, Intent, Position, Tag, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

/**
 * @uiComponent
 */
interface ITableIdentifierCellProps extends IBaseProps {
  value: any
}

export const TableIdentifierCell: React.FC<ITableIdentifierCellProps> = (props) => {
  const { value, className } = props
  if (!value) {
    return <span className={className}> - </span>
  }

  let flag = <span />
  if (value.isOnHold) {
    flag = (
      <Tag className='mr1' intent={Intent.DANGER}>
        <Icon icon={IconNames.FLAG} />
      </Tag>
    )
  }

  const trackingStatus = value.trackingStatus
  let trackingStatusIcon = <span />
  if (trackingStatus === 'Late') {
    trackingStatusIcon = (
      <Tooltip content='Late' position={Position.BOTTOM}>
        <Tag className={'mr1'} intent={Intent.DANGER}>
          <Icon icon={IconNames.TIME} />
        </Tag>
      </Tooltip>
    )
  }
  return (
    <span className={className}>
      <span className='mr1'>{value.identifier ? value.identifier : value.displayName}</span>{flag}{trackingStatusIcon}
    </span>
  )
}
