import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { DateInput } from 'browser/components/atomic-elements/atoms/input/date-input/date-input'
import 'browser/components/atomic-elements/atoms/input/date-range-input/_date-range-input.scss'
import { IInputProps } from 'browser/components/atomic-elements/atoms/input/input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'

/**
 * @uiComponent
 */
interface IDateRangeInputProps extends IInputProps {
  autofocus?: boolean
  defaultEndDateToStartDate?: boolean
  onChange: (value: any) => void
  showDatePicker?: boolean
  showEndDateInput?: boolean
  showTimeInput?: boolean
  showTimezoneInput?: boolean
  showSecondaryLabels?: boolean
  isTimezoneDisabled?: boolean
  tetherOptions?: object
  isStacked?: boolean
}

export class DateRangeInput extends React.Component<IDateRangeInputProps, any> {

  public static defaultProps: Partial<IDateRangeInputProps> = {
    isTimezoneDisabled: false,
    tetherOptions: {
      attachment: 'top left',
      targetAttachment: 'bottom left',
    },
  }

  public input: DateInput

  public focus() {
    this.input.focus()
  }

  public render() {
    const {
      className,
      isStacked,
      showDatePicker,
      showTimeInput,
      showEndDateInput,
      value,
    } = this.props
    const inputProps = _.omit(this.props, 'className')
    return (
      <div
        className={classNames('c-dateRangeInput', className, {
          'c-dateRangeInput--isStacked': isStacked,
        })}
      >
        <div className='c-dateRangeInput-start'>
          {this.renderSecondaryLabel('Start')}
          <DateInput
            {...inputProps as IInputProps}
            isHorizontalLayout={false}
            onChange={this.handleStartDateChange}
            ref={(ref) => { this.input = ref }}
            showDatePicker={showDatePicker}
            showTimeInput={showTimeInput}
            value={_.get(value, 'start')}
          />
        </div>

        <div className='c-dateRangeInput-end'>
          {this.renderSecondaryLabel('End')}
          <DateInput
            {...inputProps as IInputProps}
            isHorizontalLayout={false}
            autoFocus={false}
            onChange={this.handleEndDateChange}
            showDatePicker={showDatePicker}
            showDateInput={showEndDateInput}
            showTimeInput={showTimeInput}
            value={_.get(value, 'end')}
          />
        </div>
      </div>
    )
  }

  private renderSecondaryLabel(label) {
    if (this.props.showSecondaryLabels) {
      return (
        <Label
          isSecondary={true}
          className='c-label--collapse'
        >
          {label}
        </Label>
      )
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleChange(startDateTime, endDateTime) {
    const { onChange } = this.props

    if (startDateTime && endDateTime) {
      onChange({ start: startDateTime, end: endDateTime })
    } else if (startDateTime) {
      onChange({ start: startDateTime })
    } else if (endDateTime) {
      onChange({ end: endDateTime })
    } else {
      onChange(undefined)
    }
  }

  private handleStartDateChange = (startDateTime) => {
    const { defaultEndDateToStartDate, value } = this.props
    const oldStartDateTime = _.get(value, 'start')
    let endDateTime = _.get(value, 'end')

    if (!value && defaultEndDateToStartDate) {
      // We should only apply default when value does not exists
      // otherwise it is not a default value but a coerced value
      endDateTime = _.cloneDeep(startDateTime)
    } else if (endDateTime && oldStartDateTime) {
      const momentStartDateTime = this.getMomentDateTime(startDateTime)
      const momentOldEndDateTime = this.getMomentDateTime(endDateTime)
      const momentOldStartDateTime = this.getMomentDateTime(oldStartDateTime)

      // We are figuring out the period betwen the start date and end date before this start date change.
      // We then add that delta to the new start date to keep the period between the same
      // This is the same behaviour you will find in Outlook and Google Calendar
      const oldDelta = moment.duration(momentOldEndDateTime.diff(momentOldStartDateTime))
      endDateTime = this.assignDateTime(endDateTime, momentStartDateTime.add(oldDelta))
    }

    this.handleChange(startDateTime, endDateTime)
  }

  private handleEndDateChange = (endDateTime) => {
    const { value } = this.props
    this.handleChange(_.get(value, 'start'), endDateTime)
  }

  // WithoutTimezone
  private getMomentDateTime = (dateTime) => {
    const { showTimezoneInput } = this.props
    return moment(showTimezoneInput ? dateTime.dateTime : dateTime)
  }

  private assignDateTime = (value, momentDateTime) => {
    const { showTimezoneInput } = this.props
    const dateStr = momentDateTime.toISOString()
    if (showTimezoneInput) {
      value.dateTime = dateStr
      return value
    } else {
      return dateStr
    }
  }
}
