import classNames from 'classnames'
import _ from 'lodash'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { OrBlock } from 'browser/components/atomic-elements/atoms/or-block/or-block'

const CARRIER_PORTAL_INVITE_SCHEMA_ID = '4118277b-1aa0-426d-b0b9-abc7d0c9c6cd'

interface IInvitesListTabProps {
  invites: any[]
  navigation: any
  onClose?: () => void
  productName: string
  settings: any
  size?: string
}

interface IInvitesListTabStates {
  isJoiningOrg: boolean
}

export class InvitesListTab extends React.Component<IInvitesListTabProps, IInvitesListTabStates> {

  constructor(props) {
    super(props)
    this.state = {
      isJoiningOrg: false,
    }
  }

  public render() {
    const { invites } = this.props
    const title = invites && invites.length > 1 ? pluralize.plural('Invitation') : 'Invitation'
    return (
      <div>
        <div className='ph4 pb4'>
          <h3 className='f3 lh-title mt2 mb2'>
            Your {title}
          </h3>
          {_.map(invites, (invite, index) => this.renderInvitation(invite, index))}
          <OrBlock />
          {this.renderOptions()}
        </div>
        {this.renderFooter()}
      </div>
    )
  }

  private renderOptions() {
    const { onClose, navigation } = this.props
    const navigateToJoinOrg = () => navigation.navigate('joinOrganization')
    const navigateToSetupOrg = () => navigation.navigate('setupOrganization')
    // If within account settings the user should not be able to create a new
    // organization. We do not have an onClose prop during real onboarding.
    if (onClose) {
      return (
        <div className='pa3 tc'>
          Invitation doesn{"'"}t look right? <a onClick={navigateToJoinOrg}>Join different organization</a>.
        </div>
      )
    } else {
      return (
        <div className='pa3 tc'>
          Invitation doesn{"'"}t look right? <a onClick={navigateToJoinOrg}>Join different organization</a> or <a onClick={navigateToSetupOrg}>set up new organization</a>.
        </div>
      )
    }
  }
  private renderInvitation = (invite, index) => {
    const { isJoiningOrg } = this.state
    const firmName = invite.get('owner.firm.displayName')
    const userDisplayName = invite.get('createdBy.displayName')
    const handleJoinOrg = () => this.handleJoinOrganization(invite)
    if (invite.hasMixin(CARRIER_PORTAL_INVITE_SCHEMA_ID)) {
      return (
        <div
          className={classNames('pa3 tc mb2 bb bl br b--light-gray', {
            bt: index === 0,
          })}
          key={invite.uniqueId}
        >
          <h4 className='f4 lh-copy mt3'>
            {firmName}
          </h4>
          <div className='mb3 measure center'>
            {userDisplayName} has given you access to use their carrier portal.
          </div>
          <Button
            className={Classes.INTENT_PRIMARY}
            isLoading={isJoiningOrg}
            onClick={handleJoinOrg}
            size='large'
          >
            Accept Access
          </Button>
        </div>
      )
    }
    return (
      <div
        className={classNames('pa3 tc mb2 bb bl br b--light-gray', {
          bt: index === 0,
        })}
        key={invite.uniqueId}
      >
        <h4 className='f4 lh-copy mt3'>
          {firmName}
        </h4>
        <div className='mb3 center'>
          {userDisplayName} has invited you to join.
        </div>
        <Button
          className={Classes.INTENT_PRIMARY}
          isLoading={isJoiningOrg}
          onClick={handleJoinOrg}
          size='large'
        >
          Join {firmName}
        </Button>
      </div>
    )
  }

  private renderFooter() {
    const { onClose, size } = this.props
    if (onClose) {
      return (
        <Footer
          className='c-footer--transparent'
          cancelButtonText='Back'
          onCancelButtonClick={onClose}
        />
      )
    }
  }

  private handleJoinOrganization = (invite) => {
    this.setState({ isJoiningOrg: true })
    invite.invite.accept().then(() => {
      // IMPORTANT: need hard refresh to change organization
      window.location.assign('/redirect')
    }).finally(() => this.setState({ isJoiningOrg: false }))
  }
}
