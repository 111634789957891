declare let window: any

import Promise from 'bluebird'
import _ from 'lodash'
import Platform from 'platform'
import queryString from 'query-string'
import apis from 'browser/app/models/apis'
import Cookie from 'js-cookie'
import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { browserHistory } from 'browser/history'

const DEFAULT_WEBAPP_HOST = 'app.withvector.com'

export function getPostedByName(postedBy) {
  if (postedBy.displayName) {
    return postedBy.displayName
  } else if (!_.isEmpty(postedBy.first_name) && !_.isEmpty(postedBy.last_name)) {
    return `${postedBy.first_name} ${postedBy.last_name}`
  } else if (!_.isEmpty(postedBy.first_name)) {
    return postedBy.first_name
  } else {
    return postedBy.email
  }
}

export type GeolocationError = {
  message?: string
  nativeError?: GeolocationPositionError
}

export function getCurrentGeoPosition(): Promise<GeolocationPosition> {
  return new Promise((resolve, reject: (e: GeolocationError) => void, onCancel) => {
    let isCanceled = false
    const handleSuccess: PositionCallback = (result: GeolocationPosition) => {
      if (!isCanceled) {
        resolve(result)
      }
    }
    const handleError: PositionErrorCallback = (nativeError: GeolocationPositionError) => {
      if (!isCanceled) {
        reject({ nativeError })
      }
    }
    if (!navigator.geolocation) {
      reject({ message: 'Location not available' })
    } else {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError)
    }
    onCancel(() => (isCanceled = true))
  })
}

export function getInitialsColor(email) {
  const colors = [
    'red',
    'pink',
    'purple',
    'deepPurple',
    'indigo',
    'blue',
    'lightBlue',
    'cyan',
    'teal',
    'green',
    'lightGreen',
    'amber',
    'orange',
    'deepOrange',
    'brown',
    'blueGrey',
  ]
  const computeHash = (str) => {
    let hash = 0
    for (let i = 0; i < str.length; ++i) {
      hash += str.charCodeAt(i)
    }
    return hash
  }
  return colors[computeHash(email) % colors.length]
}

export function getMobileAgent() {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    return 'ios'
  }
  if (/Android/.test(navigator.userAgent) && !window.MSStream) {
    return 'android'
  }
  return false
}

export function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages || {}).reduce((messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

/**
 * Returns a randomized alphanumeric string
 *
 * @param length - desired length of the random string [default: 6]
 * @returns randomized alphanumeric string of specified length
 */
export function generateRandomAlphaNumericString(length = 6) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2)
    .toUpperCase()
}

export function isMobilePlatform() {
  return Platform.os.family === 'Android' || Platform.os.family === 'iOS'
}

/**
 * If this page is opened on the mobile platform and with defer, treat it like deferrer deeplink
 * if there is a isDeferLink
 *
 * 1. Mobile user opens the page https://app.withvector.com/register?deferLink=true&firstName=Minh
 * 2. This page will detect this is from mobile with deferLink is true
 * 3. If Android, it will create a deferred deeplink with whatever in the url path + query parameters
 *    and encoded it a blob in utm_content
 * 4. If iOS, it will encode the path/parameters inside utm_content as well to make it consistent
 *    as Android, then will use BranchIO to install the app
 * 5. Once the app is installed, it will get the deferred deeplink
 *    a. Android: will get the deferred deeplink from PlayStore, deserialize the blob
 *       from utm_content and treat it the same as deeplink
 *    b. iOS: will get utm_content value from branchIO, deserialize it, and also route it to
 *        same path as the deeplink
 *
 *  The idea to route the deferred deeplink to normal deep link once the app is installed
 */
export function handleMobileDeferredDeepLink(location) {
  const { isDeferredLink } = queryString.parse(decodeURIComponent(location.search))

  // Dont want to change the existing behavior and also allow the user to open the webpage normally
  // in mobile.  Only branch out if there is "deferredLink" in the parameter
  if (_.isEmpty(isDeferredLink)) {
    return false
  }

  const passthroughValue = _.get(location, 'pathname', '') + _.get(location, 'search', '')

  if (Platform.os.family === 'Android') {
    // tslint:disable-next-line:max-line-length

    const serializedPassthroughValue = encodeURIComponent(passthroughValue)
    const androidDeferredLink = encodeURIComponent(
      `utm_source=utm_content&utm_content=${serializedPassthroughValue}`
    )
    const googlePlayStoreLink = `https://play.google.com/store/apps/details?id=com.convoy.loaddoc&referrer=${androidDeferredLink}`
    // window.location.replace(googlePlayStoreLink)
    window.location.href = googlePlayStoreLink
    return true
  } else if (Platform.os.family === 'iOS') {
    /*
     * For iOS, unlike Google PlayStore, the App Store will not forward any data to our app after the installation,  branch.io is used instead.
     * The web will ask the server to generate the branch link with the given parameters and open the branch link.
     * This path is basically the same as invite via text message.
     */
    const url = apis.getDynamicLink({ deferredLink: passthroughValue })
    if (!_.isEmpty(url)) {
      window.location.href = url
      return true
    }
  }
  return false
}

export function qrOnboardingCode(firm) {
  const companyCodes = _.get(firm, 'firm.companyCodes', [])
  const defaultCompanyCodes = _.filter(companyCodes, { isDefault: true })
  const companyCode = _.get(defaultCompanyCodes, '[0].code', '')

  const hostName = _.isEmpty(apis.WEBAPP_URL) ? 'https://app.loaddocs.co' : apis.WEBAPP_URL
  const deepLink = new URL(hostName + '/register')

  deepLink.searchParams.append('companyCode', companyCode)
  deepLink.searchParams.append('isDeferredLink', 'true')
  return deepLink.toString()
}

export const getBrowserVersion = () => {
  return navigator.userAgent
}

function getDomains(domain) {
  const APP_PREFIX = 'app.'

  if (domain.startsWith(APP_PREFIX)) {
    return [domain, domain.replace(APP_PREFIX, '')]
  }

  return [domain]
}

export function clearCookie(cookie) {
  const currentDomains = getDomains(document.location.hostname)

  currentDomains.forEach((domain) => {
    Cookie.remove(cookie, { domain: domain })
  })
}

export function getDebugId(frames: FramesManager): string {
  const valuePath = frames?.getContext('valuePath')
  return _.join(valuePath, '.')
}

export function followLink(link: string = '') {
  if (isVectorLink(link)) {
    const relativeLink = getRelativeLink(link)
    browserHistory.push(relativeLink)
  } else {
    location.href = link;
  }
}

export function getRelativeLink(link: string) {
  try {
    const url = new URL(link)
    return url.pathname + url.search + url.hash
  }  catch (error) {
    console.error('Unable to parse link', link)
  }
  return ''
}

export function isVectorLink(link: string = '') {
  // on the local environment, WEBAPP_URL is empty because the host is empty.
  // Since link.indexOf('') on an empty string always returns true, set it to undefined if empty
  // to avoid a false positive.
  const internalDomains = [
    DEFAULT_WEBAPP_HOST,
    apis.WEBAPP_URL || undefined,
    apis.MOBILEWEB_URL || undefined,
  ]

  return internalDomains.some((domain) => link.indexOf(domain) !== -1)
}

export function getPlatform(): string {
  return Platform.name
}
