declare let window: any

import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import { Formatter } from 'shared-libs/helpers/formatter'
import { isPhoneValid } from 'shared-libs/helpers/utils'

import { Logger } from 'browser/apis/logging'
import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { OrBlock } from 'browser/components/atomic-elements/atoms/or-block/or-block'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
// tslint:disable-next-line:max-line-length
import { AuthenticationPage } from 'browser/components/atomic-elements/organisms/authentication-page/authentication-page'
import { browserHistory } from 'browser/history'

interface ISignInPasswordProps extends IBaseProps {
  location: any
}

interface ISignInPasswordStates {
  errorText: string
  identity: string
  isSendingVerificationCode: boolean
  isSigningIn: boolean
  nextRoute: string
  password: string
}

@withRouter
export class SignInPassword extends React.Component<ISignInPasswordProps, ISignInPasswordStates> {
  private input

  constructor(props) {
    super(props)
    const { location } = this.props

    if (_.isEmpty(location.state)) {
      browserHistory.push('/redirect')
    }

    const { identity, nextRoute } = location.state
    this.state = {
      errorText: null,
      identity,
      isSendingVerificationCode: false,
      isSigningIn: false,
      nextRoute,
      password: null,
    }
  }

  public render() {
    if (_.isEmpty(this.state)) {
      return null
    }

    const {
      errorText,
      identity,
      isSendingVerificationCode,
      isSigningIn,
      password,
    } = this.state
    const tetherOptions = {
      attachment: 'middle left',
      targetAttachment: 'middle right',
    }
    const hasErrors = !_.isEmpty(errorText)
    const formattedIdentiy = isPhoneValid(identity) ?
      Formatter.formatPhoneNumber(identity) : identity
    return (
      <AuthenticationPage
        className='mw5-m mw5-l'
        bodyClassName='c-onboarding-body'
      >
        <Head
          title='Sign in'
        />
        <div
          className={classNames('w-100 mb4', {
            shake: hasErrors,
          })}
        >
          <h3 className='f3 b lh-title mt4 mb2 tc'>
            Welcome Back
          </h3>
          <div className='lh-copy mb4 mt1 tc flex items-center justify-center'>
            <span className='flex mr2'>
              <Icon
                icon={IconNames.PERSON}
              />
            </span>
            <div>
              {formattedIdentiy}
            </div>
          </div>
          <div className='relative u-bumperBottom'>
            <InputField
              autoComplete='off'
              autoFocus={true}
              className='u-bumperBottom c-formGroup--inputHasBorder'
              data-debug-id='password'
              errorText={errorText}
              isDisabled={isSigningIn}
              onChange={this.handlePasswordChange}
              onEnterPressed={this.handleSignIn}
              placeholder='Password'
              size='lg'
              tabIndex={1}
              type='password'
              value={password}
              ref={(ref) => {
                 this.input = ref
              }}
            />
            <Link
              className={classNames('c-forgotPasswordLink', Classes.BUTTON, Classes.MINIMAL)}
              to={{
                pathname: '/sign-in/password-recover',
                search: window.location.search,
                state: {
                  identity,
                  nextRoute: _.get(this.props, 'location.state.nextRoute'),
                 },
              }}
              title='Reset your password'
            >
              Forgot It?
            </Link>
          </div>

          <Button
            className={classNames(Classes.INTENT_PRIMARY, Classes.FILL)}
            data-debug-id='signInButton'
            size='large'
            tabIndex={1}
            isDisabled={isSendingVerificationCode}
            isLoading={isSigningIn}
            onClick={this.handleSignIn}
          >
            Sign In
          </Button>
        </div>
        <Footer
          className='c-footer--transparent'
          cancelButtonText='Back'
          onCancelButtonClick={browserHistory.goBack}
        />
      </AuthenticationPage>
    )
  }

  private handleSendVerificationCode = () => {
    const { identity, nextRoute } = this.state
    this.setState({ isSendingVerificationCode: true })
    apis.sendVerificationCode(identity).then(() => {
      browserHistory.push({
        pathname: '/verify',
        search: window.location.search,
        state: { identity, nextRoute },
      })
    }).finally(() => this.setState({ isSendingVerificationCode: false }))
  }

  private handleSignIn = () => {
    const { identity, nextRoute, password } = this.state
    this.setState({ errorText: null, isSigningIn: true })
    apis.postLogin(identity, password).then(() => {
      Logger.logEvent('Login')
      if (nextRoute) {
        browserHistory.push(nextRoute)
      } else {
        browserHistory.push({
          pathname: '/redirect',
          search: window.location.search,
        })
      }
    }).catch((json) => {
      const errorText = _.isEmpty(password) ? 'Please enter your password.' :
        'Invalid credentials. Please try again or reset your password.'
      this.setState({ errorText })
      setTimeout(() => { this.input.getElement().select() }, 0) // EF: Need to delay this for some reason
    }).finally(() => this.setState({ isSigningIn: false }))
  }

  private handlePasswordChange = (password) => {
    this.setState({ password })
  }

}
