import React from 'react'

import { InlineEditable } from 'browser/components/atomic-elements/molecules/inline-editable'
import { CheckboxFieldImpl, ICheckboxFieldImplProps } from './checkbox-field'

/**
 * @uiComponent
 */
interface ICheckboxFieldProps extends ICheckboxFieldImplProps {
  onRemove?: () => void
  onSave?: () => void
  validate?: (value: any) => boolean
  helpText?: string
}

export class CheckboxField extends React.Component<ICheckboxFieldProps, any> {

  public render() {
    const { isEditableInline } = this.props
    const checkboxProps: ICheckboxFieldImplProps = this.props as any
    if (isEditableInline) {
      return (
        <InlineEditable
          isStatic={this.props.isStatic}
          onChange={this.props.onChange}
          onDelete={this.props.onRemove}
          onSave={this.props.onSave}
          size={this.props.size}
          validate={this.props.validate}
          value={this.props.value}
        >
          <CheckboxFieldImpl {...checkboxProps} />
        </InlineEditable>
      )
    } else {
      return (
        <CheckboxFieldImpl {...checkboxProps} />
      )
    }
  }
}
