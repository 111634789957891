import classNames from 'classnames'
import fuzzy from 'fuzzy'
import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { SortableList } from 'browser/components/atomic-elements/atoms/list/sortable-list'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { SelectList } from 'browser/components/atomic-elements/atoms/select/select-list'
import { ColumnConfigurationSheet } from 'browser/components/atomic-elements/atoms/table/column-configuration-sheet'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { SheetContext } from '../sheet/sheet-manager'

interface ITableConfigurationSectionProps extends IBaseProps {
  addButtonText: string
  emptyHelperText?: string
  helpText?: string
  maxSelection?: number
  onOrderChange: (value: any[]) => void
  onSelectionChange?: (value: any) => void
  options: any[]
  selection: any[]
  title: string
}

interface ITableConfigurationSectionStates {
  searchQuery: string
  selection: any[]
}

export class TableConfigurationSection
  extends React.Component<ITableConfigurationSectionProps, ITableConfigurationSectionStates> {

  private list: SelectList

  constructor(props) {
    super(props)
    this.state = {
      searchQuery: '',
      selection: props.selection,
    }
  }

  public render() {
    const { className, title, helpText, emptyHelperText } = this.props
    const { selection } = this.state
    const tetherOptions = {
      attachment: 'top right',
      targetAttachment: 'bottom right',
    }
    return (
      <SheetContext.Consumer>
        {({openOverlay}) => (
          <div className={className}>
            <FormTable>
              <FormGroup
                isHorizontalLayout={true}
              >
                <Label
                  isHorizontalLayout={true}
                  size='sm'
                >
                  {title}
                  <HelpBlock>
                    {helpText}
                  </HelpBlock>
                </Label>
                <div className='c-formGroup-horizontalContent u-darkBorderBottom'>
                  <SortableList
                    emptyHelperText={emptyHelperText}
                    items={selection}
                    onEditItem={(item, index) => this.handleConfigureItem(item, index, openOverlay)}
                    onChange={this.handleOptionsChange}
                  />
                </div>
              </FormGroup>
            </FormTable>

            <div className='tr'>
              <TetherTarget
                closeOnPortalClick={true}
                tethered={this.renderPopover()}
                tetherOptions={tetherOptions}
              >
                {this.renderAddButton()}
              </TetherTarget>
            </div>
          </div>
        )}
      </SheetContext.Consumer>
    )
  }

  private renderAddButton() {
    const { addButtonText, maxSelection } = this.props
    const { selection } = this.state

    if (selection.length === maxSelection) {
      return null
    }

    return (
      <Button
        className={Classes.MINIMAL}
      >
        {addButtonText}
      </Button>
    )
  }

  private renderPopover() {
    const { searchQuery } = this.state
    return (
      <Popover className='collapse'>
        <SelectList
          autoFocus={true}
          onSearch={this.handleSearchQueryChange}
          options={this.getFilterOptions()}
          optionRenderer={this.renderOption}
          searchQuery={searchQuery}
          ref={(ref) => { this.list = ref }}
          showSearchInput={true}
        />
      </Popover>
    )
  }

  private renderOption = ({ index, isHighlighted, key, option, style, onChange }) => {
    const { onSelectionChange } = this.props
    const handleClick = () => onSelectionChange(option)
    return (
      <li
        className={classNames('c-dropdownList-item', {
          'is-hovering': isHighlighted,
        })}
        key={index}
        style={style}
        onClick={handleClick}
      >
        {option.label}
      </li>
    )
  }

  private handleConfigureItem = (item, index, openOverlay) => {
    openOverlay(
      <ColumnConfigurationSheet
        column={item}
      />,
    )
  }

  private handleOptionsChange = (options) => {
    const { onOrderChange } = this.props
    this.setState({
      selection: options,
    })
    onOrderChange(options)
  }

  private handleSearchQueryChange = (query) => {
    this.setState({
      searchQuery: query,
    })
  }

  private getFilterOptions() {
    const { options } = this.props
    const { searchQuery } = this.state
    const filteredOptions = _.filter(options, (option) => {
      const results = fuzzy.filter(searchQuery, [option.label])
      return !_.isEmpty(results)
    })
    return _.sortBy(filteredOptions, (option) => option.label)
  }
}
