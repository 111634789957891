declare let window: any

import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Column } from 'browser/components/atomic-elements/atoms/table'
import { DateRangeField } from 'browser/components/atomic-elements/molecules/fields/date-range-field'

interface ICommissionReportProps extends IBaseProps {
  dataSet: any
  view: any
  settings: Settings
}

interface ICommissionReportStates {
  dateRange: any
  isCarrierRep: boolean
  isExporting: boolean
}

class CommissionReport extends React.Component<ICommissionReportProps, ICommissionReportStates> {
  constructor(props) {
    super(props)
    this.state = {
      dateRange: null,
      isCarrierRep: true,
      isExporting: false,
    }
  }

  public render() {
    const { dateRange, isCarrierRep } = this.state
    const start = _.get(dateRange, 'start', null)
    const end = _.get(dateRange, 'end', null)
    return (
      <div className='c-emptyTableMessage'>
        <div className='f3 lh-title mb2'>
          Preconfigured Commission Reports are available below.
        </div>
        <div className={classNames('mt4', Classes.BUTTON_GROUP)}>
          <Button
            isActive={isCarrierRep}
            onClick={() => this.setState({ isCarrierRep: true })}
            size='lg'
          >
            Carrier Rep.
          </Button>
          <Button
            isActive={!isCarrierRep}
            onClick={() => this.setState({ isCarrierRep: false })}
            size='lg'
          >
            Customer Rep.
          </Button>
        </div>
        <br />
        <div className='lh-copy mt4 mb4'>
          Please select your role above then enter a date range below to download your commission report.
        </div>
        <FormTable>
          <DateRangeField
            defaultEndDateToStartDate={false}
            isStacked={true}
            label='Commission Dates'
            onChange={this.handleDateChange}
            showTimeInput={false}
            value={dateRange}
          />
        </FormTable>
        <br />
        <Button
          isDisabled={!(start && end)}
          onClick={this.handleReportRequest}
        >
          Submit
        </Button>
      </div>
    )
  }

  private handleDateChange = (dateRange) => {
    this.setState({ dateRange })
  }

  private handleReportRequest = () => {
    const { dataSet, view } = this.props
    const { start, end } = this.state.dateRange
    const { metadata } = dataSet.query
    const columns: Column[] = view.get('view.state.columns')
    const repTypeFilter = this.getRepTypeFilter()
    const newColumns = this.updateColumns(columns)
    this.setState({ isExporting: true })
    const query = {
      columns: newColumns,
      computations: dataSet.query.computations,
      entityType: dataSet.entitySchema.uniqueId,
      filters: [
        {
          label: 'Primary Status',
          path: 'dispatchOrder.status.primary',
          type: 'match',
          values: [
            'Settling',
            'Tracking',
          ],
        },
        {
          gte: start,
          label: 'Pick Up Window',
          lte: end,
          path: 'precomputation.lane.origin.timeWindow.start.dateTime',
          type: 'range',
        },
        repTypeFilter,
      ],
      groups: dataSet.query.groups,
      metadata: {
        shouldIncludeLeafEntities: metadata.shouldIncludeLeafEntities,
      },
      orders: dataSet.query.orders,
    }
    apis.exportCommission(query).then((response: string) => {
      // https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
      const isIE11 = !!window.MSInputMethodContext && !!window.document.documentMode
      if (isIE11) {
        // https://stackoverflow.com/questions/47881250/typeerror-object-doesnt-support-this-action-in-ie-11
        const blob = new Blob([JSON.stringify(response)], { type: 'text/csv; charset=utf-8' })
        window.navigator.msSaveBlob(blob, 'export.csv')
      } else {
        const blob = new File([response], 'export.csv', {
          type: 'text/csv; charset=utf-8',
        })
        const URL = window.URL || window.webkitURL
        const downloadUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = downloadUrl
        a.download = 'export.csv'
        document.body.appendChild(a)
        a.click()
      }
    }).finally(() => {
      this.setState({ isExporting: false })
    })
  }

  private updateColumns(columns: Column[]) {
    const { isCarrierRep } = this.state
    const newColumns = _.cloneDeep(columns)
    if (isCarrierRep) {
      _.remove(newColumns, (column: Column) => column.formula && column.formula.includes('GET_CUSTOMER_REP_COMMISSION'))
      _.remove(newColumns, (column: Column) => column.path && column.path.includes('brokerOrder.customerRepresentative'))
    } else {
      _.remove(newColumns, (column: Column) => column.formula && column.formula.includes('GET_CARRIER_REP_COMMISSION'))
      _.remove(newColumns, (column: Column) => column.path && column.path.includes('brokerOrder.carrierRepresentative'))
    }
    return newColumns
  }

  private getRepTypeFilter() {
    const { isCarrierRep } = this.state
    const { settings } = this.props
    const user = settings.getUser()
    let label = ''
    let path = ''
    if (isCarrierRep) {
      label = 'Carrier Representative'
      path = 'brokerOrder.carrierRepresentative'
    } else {
      label = 'Customer Representative'
      path = 'brokerOrder.customerRepresentative'
    }

    return ({
      entityType: '/1.0/entities/metadata/brokerOrder.json',
      label,
      path,
      type: 'matchEdge',
      values: [
        {
          displayName: user.displayName,
          entityId: user.uniqueId,
        },
      ],
    })
  }
}

export default React.forwardRef((props: ICommissionReportProps, ref: React.Ref<CommissionReport>) => (
  <AppNavigatorContext.Consumer>
    {({ settings }) => (
      <CommissionReport {...props} settings={settings} ref={ref} />
    )}
  </AppNavigatorContext.Consumer>
))
