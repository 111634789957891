function getBaseDpi(): number {
  // See https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio#value
  const baseDesktopDpi = 96
  // See https://developer.android.com/training/multiscreen/screendensities
  const baseMobileDpi = 160
  return typeof window !== 'undefined' ? baseDesktopDpi : baseMobileDpi
}

/**
 * Converts points to millimeters. (mm expected by bwipjs barcode renderer)
 */
export function pointsToMillimeters(points: number, pixelDensity: number): number {
  const millimetersPerInch = 25.4
  const dpi = getBaseDpi()
  const ppi = dpi * pixelDensity
  const pixels = points * pixelDensity
  const inches = pixels / ppi
  return inches * millimetersPerInch
}
