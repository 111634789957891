import React from 'react'
import classNames from 'classnames'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

import './_form.scss'

/**
 * @uiComponent
 */
interface IMobileFormProps extends IBaseProps {}

export const MobileForm = (props: IMobileFormProps) => {
  const { children, className, style } = props
  return (
    <div className={classNames('mobile-form', className)} style={style}>
      {children}
    </div>
  )
}
