import _ from 'lodash'
import React from 'react'

import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { ImageLoader } from 'browser/components/atomic-elements/atoms/image-loader/image-loader'
import { IInputProps, Input } from 'browser/components/atomic-elements/atoms/input/input'
import { ILabelProps, Label } from 'browser/components/atomic-elements/atoms/label/label'
import 'browser/components/atomic-elements/molecules/fields/signature-field/_signature-field.scss'

/**
 * @uiComponent
 */
interface IOrderLabeledInputFieldProps extends IInputProps {
  label: string
  labelProps?: ILabelProps
  inputWidthClassName?: string
  referenceLabel?: string
  value?: any[]
}

// Component allows us to directly set a specific value to a specific item
// using the referenceLabel. Currently used for carrier load number
// on the additionalIdentifiers property in the dispatch order.

// TODO(peter/louis): we need to clean up the form fields. This has a lot of
// duplicated code from label-form-group.tsx
export class OrderLabeledInputField extends React.PureComponent<IOrderLabeledInputFieldProps, any> {

  public static get defaultProps(): Partial<IOrderLabeledInputFieldProps> {
    return {
      inputWidthClassName: 'c-formGroup-horizontalContent',
      referenceLabel: 'Carrier Load Number',
      value: [],
    }
  }

  private input: any

  constructor(props) {
    super(props)
    this.state = {
      isFocused: false,
    }
  }

  public focus() {
    this.input.focus()
  }

  public render() {
    const {
      value,
      isHorizontalLayout,
      size,
      className,
      labelProps,
      inputWidthClassName,
    } = this.props
    const source = _.get(value, 'source.0.uri', '')
    const { isFocused } = this.state
    const formGroupProps = {
      className,
      hasValue: !_.isNil(value),
      isFocused,
      isHorizontalLayout,
      size,
    }
    if (isHorizontalLayout) {
      return (
        <FormGroup {...formGroupProps}>
          {this.renderLabel()}
          <div className={inputWidthClassName}>
            {this.renderInput()}
          </div>
        </FormGroup>
      )
    } else {
      return (
        <FormGroup {...formGroupProps}>
          {this.renderLabel()}
          {this.renderInput()}
        </FormGroup>
      )
    }
  }

  private renderLabel() {
    const {
      errorText,
      isHorizontalLayout,
      size,
      isRequired,
      label,
      labelProps,
    } = this.props
    return (
      <Label
        {...labelProps}
        isHorizontalLayout={isHorizontalLayout}
        hasError={!_.isEmpty(errorText)}
        isRequired={isRequired}
        size={size}
      >
        {label}
      </Label>
    )
  }

  private renderInput() {
    const {
      value,
      referenceLabel,
      size,
    } = this.props
    const carrierLoadNumberReference = _.find(value, { label: referenceLabel })
    const carrierLoadNumber = _.get(carrierLoadNumberReference, 'value')
    const inputProps = _.omit(this.props, 'className')
    return (
      <Input
        {...inputProps}
        ref={(ref) => { this.input = ref }}
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnBlur}
        onChange={this.handleChange}
        value={carrierLoadNumber}
      />
    )
  }

  private handleChange = (carrierLoadNumber) => {
    const { onChange, referenceLabel, value } = this.props
    const carrierLoadNumberReference: any = _.find(value, {
      label: referenceLabel,
    })
    if (carrierLoadNumberReference) {
      _.set(carrierLoadNumberReference, 'value', carrierLoadNumber)
    } else {
      value.push({
        label: referenceLabel,
        value: carrierLoadNumber,
      })
    }
    onChange(value)
  }

  private handleOnFocus = () => {
    this.setState({ isFocused: true })
  }

  private handleOnBlur = () => {
    this.setState({ isFocused: false })
  }
}
