import _ from 'lodash'
import moment from 'moment'
import { translateString } from '../../helpers/utils'

const INTEGER_REGEX = /^-?[1-9]\d*$/
const NUMBER_REGEX = /^-?\d*\.?\d*$/

export const deserializeValue = (type, format, value, translationTable=null) => {
  if (_.isNil(value)) {
    return ''
  }

  if (type === 'string') {
    if (format === 'date') {
      const date = moment(value)
      return date.isValid() ? date.format('l') : ''
    } else if (format === 'date-time') {
      const dateTime = moment(value)
      return dateTime.isValid() ? dateTime.format('MMM DD, YYYY h:mm A z') : ''
    } else {
      value = translateString(value, translationTable)
    }
  }

  return String(value)
}

export const serializeValue = (type, value) => {
  if (_.isEmpty(value)) {
    value = undefined
  } else if (type === 'integer') {
    value = parseInt(value, 10)
    value = _.isNaN(value) ? undefined : value
  } else if (type === 'number') {
    value = parseFloat(value)
    value = _.isNaN(value) ? undefined : value
  }
  return value
}

export const isValid = (type, value) => {
  if (_.isEmpty(value)) {
    return true
  } else if (type === 'integer') {
    return !!INTEGER_REGEX.exec(value)
  } else if (type === 'number') {
    return !!NUMBER_REGEX.exec(value)
  }
  return true
}
