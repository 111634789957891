import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { DateRangePickerPopover } from 'browser/components/atomic-elements/atoms/date-picker/date-range-picker-popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import moment from 'moment'
import { Item } from './item'
import { NegatableFilter } from './negation-filter'

interface IDateRangeFilterItemProps extends IBaseProps {
  filter: object
  isButton: boolean
  isClearable: boolean
  onClear: () => void
  onChange: (value: any, ...args) => void
  showFilterValue: boolean
  tetherOptions: object
}

export class DateRangeFilterItem extends React.Component<IDateRangeFilterItemProps, any> {

  constructor(props) {
    super(props)
    this.state = {
      filter: _.cloneDeep(props.filter),
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.isEqual(this.props.filter, nextProps.filter)) {
      this.setState({ filter: _.cloneDeep(nextProps.filter) })
    }
  }

  public render() {
    const { isClearable } = this.props
    const { filter } = this.state

    return (
      <TetherTarget
        automaticAdjustOffset={true}
        openOnClick={isClearable}
        tetherOptions={this.props.tetherOptions}
        tethered={this.renderDateRangePickerPopover()}
      >
        <Item
          {...this.props}
          label={filter.label}
        >
          {this.getFilterValue()}
        </Item>
      </TetherTarget>
    )
  }

  private getFilterValue() {
    const { filter } = this.state
    const startDate = filter.gte
      ? moment(filter.gte).format('MMM D, YYYY') : ''
    const endDate = filter.lte
      ? moment(filter.lte).format('MMM D, YYYY') : ''
    const negationText = filter.isNegation ? "Excluding " : ""

    return `${negationText}${startDate} – ${endDate}`
  }

  private handleOnChange = ({ start, end }) => {
    const { filter } = this.state
    filter.gte = start
    filter.lte = end
    this.setState({ filter })
    if (filter.gte && filter.lte) {
      filter.gte = moment(filter.gte).startOf('day')
      filter.lte = moment(filter.lte).endOf('day')
      this.props.onChange(filter)
    }
  }

  private handleNegation = (filter, ...args) => {
    this.setState({ filter })
    this.props.onChange(filter, ...args)
  }

  private renderDateRangePickerPopover() {
    const { filter } = this.state
    return (
      <DateRangePickerPopover
        onChange={this.handleOnChange}
        value={{ start: filter.gte, end: filter.lte }}
        preContent={ this.renderNegationFilter() }
      />
    )
  }

  private renderNegationFilter() {
    return (
      <div className='u-innerBumperTop u-innerBumperLeft u-innerBumperBottom'>
        <NegatableFilter
          filter={this.props.filter}
          onChange={this.handleNegation}
        />
      </div>
    )
  }
}
