import _ from 'lodash'

export const Normalizer = {

  stripMetadata(root) {
    if (_.isArray(root)) {
      _.forEach(root, item => this.stripMetadata(item))
    } else if (_.isObject(root)) {
      delete (root as any)._metadata
      _.forEach(root, value => this.stripMetadata(value))
    }
  },

  prune(root) {
    if (root == null || root === '') {
      return null
    } else if (_.isString(root)) {
      return root.trim()
    } else if (_.isArray(root)) {
      // We should not prune empty array. It might cause extra component work
      // See email: "Empty array causing component work"
      // return result.length > 0 ? result : null;
      root = _.map(root, item => this.prune(item))
      return _.compact(root)
    } else if (_.isObject(root)) {
      // delete any transient _metadata from object
      delete (root as any)._metadata
      const result = {}
      for (const key in root) {
        const value = this.prune(root[key])
        if (value != null) {
          result[key] = value
        }
      }
      return Object.keys(result).length > 0 ? result : null
    }
    return root
  },

  string(text) {
    return text
      .replace(/^ /, '')
      .replace(/\u00ad/g, '')
  },

  integer(text) {
    return text
      .replace(/[^-\d]/g, '')
      .replace(/(.)-/g, '$1')
  },

  number(text) {
    return text
      .replace(/[^-.e\d]/ig, '')
      .replace(/(e[^e]*)e/ig, '$1')
      .replace(/([e.][^.]*)\./ig, '$1')
      .replace(/([^e])-/ig, '$1')
      .replace(/(e-?\d\d\d)\d/ig, '$1')
  }
}
