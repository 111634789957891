import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
// tslint:disable-next-line:max-line-length
import { AddressInput, IAddressInputProps } from 'browser/components/atomic-elements/atoms/input/address-input/address-input'
import {
  AddressInputSheet,
} from 'browser/components/atomic-elements/molecules/fields/address-field/address-input-sheet'

export interface IEmbeddedLocationSheetInputProps extends IAddressInputProps {
  location: Entity
}

export class EmbeddedLocationSheetInput
  extends React.Component<IEmbeddedLocationSheetInputProps, any> {

  public render() {
    const { location } = this.props
    return (
      <AddressInput
        {...this.props}
        showEditableFields={true}
        hasLabels={true}
        onChange={this.handleChange}
        value={location.get('location.address')}
      />
    )
  }

  private handleChange = (address) => {
    const { location, onChange } = this.props
    location.set('location.address', address)
    const entityId = location.get('uniqueId')
    onChange({ entityId })
  }
}
