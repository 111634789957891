import { Entity } from "../entity"

const schema = {
  "$schema": "http://json-schema.org/draft-04/schema#",
  "metadata": {
    "namespace": "columnConfiguration"
  },
  "properties": {
    "columnConfiguration": {
      "properties": {
        "horizontalAlignment": {
          "label": "Alignment",
          "type": "string",
          "enum": [
            "left",
            "right",
            "center"
          ],
          "enumOptions": [
            {
              "label": "Left",
              "value": "left"
            },
            {
              "label": "Right",
              "value": "right"
            },
            {
              "label": "Center",
              "value": "center"
            }
          ]
        },
        "format": {
          "properties": {
            "type": {
              "label": "Format",
              "type": "string",
              "enum": [
                "boolean",
                "currency",
                "custom",
                "date",
                "number",
                "percent"
              ],
              "enumOptions": [
                {
                  "label": "Boolean",
                  "value": "boolean"
                },
                {
                  "label": "Currency",
                  "value": "currency"
                },
                {
                  "label": "Custom",
                  "value": "custom"
                },
                {
                  "label": "Date",
                  "value": "date"
                },
                {
                  "label": "Number",
                  "value": "number"
                },
                {
                  "label": "Percent",
                  "value": "percent"
                }
              ]
            },
            "decimalPlaces": {
              "label": "Decimal Places",
              "type": "number"
            }
          },
          "type": "object"
        },
        "flexGrow": {
          "label": "Flex Grow",
          "type": "number"
        },
        "isFixed": {
          "label": "Is Fixed",
          "type": "boolean"
        },
        "isSortable": {
          "label": "Is Sortable",
          "type": "boolean"
        },
        "isVisible": {
          "label": "Is Hidden",
          "type": "boolean"
        },
        "label": {
          "label": "Label",
          "type": "string"
        },
        "width": {
          "label": "Column Width",
          "type": "number"
        }
      },
      "type": "object"
    },
    "type": "object"
  },
  "status": {
    "state": "idle"
  },
  "type": "object",
  "uiSchema": {
    "web": {
      "columnConfigurationSheet": {
        "children": [
          {
            "type": "ui:selectField",
            "isNative": true,
            "value": "columnConfiguration.horizontalAlignment"
          },
          {
            "type": "ui:selectField",
            "isNative": true,
            "value": "columnConfiguration.format.type"
          },
          {
            "conditionals": [
              {
                "test": "columnConfiguration.format.type === 'currency'",
                "type": "ui:inputField",
                "value": "columnConfiguration.format.decimalPlaces"
              }
            ],
            "type": "ui:conditional"
          },
          {
            "className": "u-hide",
            "type": "ui:inputField",
            "value": "columnConfiguration.flexGrow"
          },
          {
            "type": "ui:checkboxField",
            "value": "columnConfiguration.isFixed"
          }
        ],
        "type": "ui:formTable"
      }
    }
  },
  "uniqueId": "a4c848a7-baa9-497e-8c85-33474ab60131"
}

export function getColumnConfigurationSchema(apis) {
  return new Entity(schema, apis)
}
