import * as React from 'react'
import { IStoredValues } from './interface'

export interface ILocalStorageContext {
  storedValues: IStoredValues
  onChange: (key: string, value: any) => void
}

export const LocalStorageContext = React.createContext<ILocalStorageContext>({
  storedValues: {},
  onChange: () => null,
})
