import _ from 'lodash'
import React from 'react'
import { Helmet } from 'react-helmet'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

export interface IHeadProps extends IBaseProps {
  title?: string
}

export class Head extends React.Component<IHeadProps, any> {

  public render() {
    return (
      <Helmet>
        <title>{this.renderTitle()}</title>
      </Helmet>
    )
  }

  private renderTitle() {
    const { title } = this.props
    if (!_.isEmpty(title)) {
      return title + ' - ' + apis.getProductName()
    }
    return apis.getProductName()
  }
}
