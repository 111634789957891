import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import 'browser/components/atomic-elements/molecules/inline-editable/_inline-edit-controls.scss'

interface InlineControlsProps extends IBaseProps {
  isStatic?: boolean
  isDisabled?: boolean
  onCancel: (event: any) => void
  onDelete: (event: any) => void
  onEdit: (event: any) => void
  onSave: (event: any) => void
  size?: string
}

export class InlineControls extends React.Component<InlineControlsProps, any> {

  public render() {
    const {
      className,
      isDisabled,
      isStatic,
    } = this.props
    if (isDisabled) {
      return <div className={classNames('c-inlineEditControls', className)} />
    } else if (isStatic) {
      return this.renderEditControls()
    }
    return (
      <div className={classNames('c-inlineEditControls c-inlineEditControls--active', className)}>
        {this.renderCancelButton()}
        {this.renderSaveButton()}
        {this.renderOptionsButton()}
      </div>
    )
  }

  private renderEditControls() {
    const { className, size, onEdit } = this.props
    const sizeClass = _.isEmpty(size) ? '' : `c-button--${size}`
    return (
      <div className={classNames('c-inlineEditControls', className)}>
        <Button
          className={classNames('c-inlineEditControl', Classes.MINIMAL, sizeClass)}
          onClick={onEdit}
        >
          Edit
        </Button>
      </div>
    )
  }

  private renderCancelButton() {
    return (
      <Button
        className={classNames('c-button--square c-inlineEditControl')}
        size={this.props.size}
        onClick={this.props.onCancel}
      >
        <Icon
          icon={IconNames.CROSS}
        />
      </Button>
    )
  }

  private renderSaveButton() {
    return (
      <Button
        className={classNames('c-button--square c-inlineEditControl c-inlineEditControl--last', Classes.INTENT_PRIMARY)}
        size={this.props.size}
        onClick={this.props.onSave}
      >
        <Icon
          icon={IconNames.TICK}
        />
      </Button>
    )
  }

  private renderOptionsPopover() {
    const { onDelete } = this.props
    return (
      <Popover
        className='collapse'
      >
        <ul className='c-dropdownList'>
          <li
            className='c-dropdownList-item'
            onClick={onDelete}
          >
            Delete field
          </li>
        </ul>
      </Popover>
    )
  }

  private renderOptionsButton() {
    if (!this.props.onDelete) {
      return
    }
    const optionsPopoverTetherOptions = {
      attachment: 'top right',
      targetAttachment: 'bottom right',
    }
    return (
      <TetherTarget
        tetherOptions={optionsPopoverTetherOptions}
        tethered={this.renderOptionsPopover()}
      >
        <div className='c-inlineEditControl'>
          <Button
            className={classNames('c-button--square', Classes.MINIMAL)}
            size={this.props.size}
          >
            <Icon
              icon={IconNames.MORE}
            />
          </Button>
        </div>
      </TetherTarget>
    )
  }
}
