import React from 'react'
import { CargoDeliveryTaskRow, ICargoDeliveryTaskRowProps } from './cargo-delivery-task-row'

const TRIP_CARGOS_PATH = 'core_fulfilment_trip.plan.cargos'
const TRIP_STOPS_PATH = 'core_fulfilment_trip.plan.stops'
const CARGO_QUANTITY_UNIT_SCHEMA_PATH =
  'properties.core_fulfilment_trip.properties.plan.properties.cargos.items.properties.quantity.properties.unit'

/**
 * @component TripCargoDeliverTaskRow - table row to display cargoTasks at a stop on a trip
 *
 * @props ICargoDeliveryTaskRowProps
 */
export const TripCargoDeliveryTaskRow: React.FC<ICargoDeliveryTaskRowProps> = (
  props: ICargoDeliveryTaskRowProps
) => {
  return (
    <CargoDeliveryTaskRow
      {...props}
      cargoQuantityUnitSchemaPath={CARGO_QUANTITY_UNIT_SCHEMA_PATH}
      cargosPath={TRIP_CARGOS_PATH}
      stopsPath={TRIP_STOPS_PATH}
    />
  )
}
