import { Event } from "./types/storyboard/storyboard-execution-model"

export interface IDeviceInfoProvider {
  getDeviceInfo(workflowVersion: string): Event['deviceInfo']
}

let deviceInfoProvider: IDeviceInfoProvider = undefined

export function getDeviceInfoProvider(): IDeviceInfoProvider {
  return deviceInfoProvider
}

export function registerDeviceInfoProvider(provider: IDeviceInfoProvider): void {
  deviceInfoProvider = provider
}
