import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface IRbStatusSelectProps extends IBaseProps {
  onChange: (value) => void
  options: any[]
  optionLabelPath: string
  optionValuePath: string
  value: string
}

export const RbStatusSelect: React.FC<IRbStatusSelectProps> = (props) => {
  const { options, onChange, optionLabelPath, optionValuePath, value} = props
  return (
    <ul className='u-dropdownList'>
      {_.map(options, (option) => {
        const optionValue = option[optionValuePath]
        const onStatusChange = () => {
          onChange(optionValue)
        }
        return (
          <li
            className={classNames('c-dropdownList-item', {
              'is-active': optionValue === value,
            })}
            key={optionValue}
            onClick={onStatusChange}
          >
            {option[optionLabelPath]}
          </li>
        )
      })}
    </ul>
  )
}

RbStatusSelect.defaultProps = {
  optionLabelPath: 'label',
  optionValuePath: 'value',
}
