import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { TableConfigurationSheet } from 'browser/components/atomic-elements/atoms/table/table-configuration-sheet'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { CollapseState } from 'browser/components/atomic-elements/atoms/table'

import 'browser/components/atomic-elements/atoms/table/_table-actions-button.scss'

interface ITableActionsButtonProps extends IBaseProps {
  tableRef: React.RefObject<any>
}

export class TableActionsButton extends React.Component<ITableActionsButtonProps, any> {
  public render() {
    return (
      <TetherTarget
        closeOnPortalClick={true}
        tethered={this.renderPopover()}
      >
        <div
          className={classNames('c-tableActionsButton u-noPrint')}
        >
          <Icon
            icon={IconNames.MORE}
          />
        </div>
      </TetherTarget>
    )
  }

  private renderPopover() {
    return (
      <Popover
        className='collapse'
        data-debug-id='table-actions-popover'
      >
        <ul className='c-dropdownList'>
            <li
              className='c-dropdownList-item'
              onClick={this.collapseAll}
              key='collapse-all'
            >
              Collapse All
            </li>
            <li
              className='c-dropdownList-item'
              onClick={this.expandAll}
              key='expand-all'
            >
              Expand All
            </li>
        </ul>
      </Popover>
    )
  }

  private collapseAll = () => {
    const { tableRef } = this.props
    tableRef.current?.toggleAllRows(CollapseState.COLLAPSED)
  }

  private expandAll = () => {
    const { tableRef } = this.props
    tableRef.current?.toggleAllRows(CollapseState.EXPANDED)
  }
}
