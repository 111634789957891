import { browserHistory } from 'browser/history'
import _ from 'lodash'
import queryString from 'query-string'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Logger } from 'browser/apis/logging'
import ComponentsMap from 'browser/components'
import { IContainerProps } from 'browser/components/containers/container-props'
import { ViewRenderer } from 'shared-libs/components/view/renderer'
import { QueryOptionsGenerator } from 'shared-libs/models/query-options-generator'
import { AbstractSettings } from 'shared-libs/models/abstract-settings'
import apis from 'browser/app/models/apis'
import { filterTableColumns } from 'shared-libs/components/table/utils'
import { PrintableTable } from 'browser/app/pages/app/views/print'

const entityIdPath = 'data.uniqueId'

interface IEntityListProps extends IContainerProps {
  dataSets: any
  detailViewId: string
  detailViewSearchOptions: any
  settings: AbstractSettings
}

interface IEntityListViewState {
  selection: any[]
  viewMode?: 'list' | 'listDetail'
}

export class EntityListView extends React.Component<IEntityListProps, IEntityListViewState> {

  private onCountChange?: any = null
  constructor(props: IEntityListProps) {
    super(props)
    const { location, view } = props
    const entityId = this.getEntityIdFromLocation(location)
    const selection = entityId ? [_.set({}, entityIdPath, entityId)] : []
    // use view.state.viewMode > isFullScreen > listDetail
    const defaultIsFullScreen = _.get(view, 'view.state.viewMode') === 'list'
    const { isFullScreen } = queryString.parse(location.search)
    const viewMode = (isFullScreen || defaultIsFullScreen) ? 'list' : 'listDetail'
    this.state = { selection, viewMode }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const entityId = this.getEntityIdFromLocation(this.props.location)
    const nextEntityId = this.getEntityIdFromLocation(nextProps.location)
    if (entityId === nextEntityId) { return }
    let { selection } = this.state
    const selectedEntityId = _.get(_.first(selection), entityIdPath)
    // If next route has no entityId there the following cases:
    // 1. user closed detail view by pressing x (selection.length = 0)
    // 2. user closed detail view by navigating to table view (selection.length = 1)
    // 3. user clicked on grouped row (selection.length > 1)
    // We should only set selection in case 2, hence the check on selection.length
    if (!nextEntityId) {
      if (selection.length === 1) {
        this.setState({ selection: [] })
      }
    } else if (nextEntityId !== selectedEntityId) {
      // if next route has different entityId then what in selection (what user
      // has selected), we are probably navigating here by route
      selection = [_.set({}, entityIdPath, nextEntityId)]
      this.setState({ selection })
    }
  }

  public render() {
    const { children, dataSets, match, view, viewSchema, settings } = this.props
    const viewState = this.getViewState()
    const renderState = { ...this.state, dataSets, match, view, viewState, settings }

    const { location } = this.props
    const { isPrintTable } = queryString.parse(location.search)

    if (isPrintTable && !_.isEmpty(this.getViewState().columns)) {
      return <PrintableTable
        dataSets={dataSets}
        viewState={this.getViewState()}
        match={match}
      />
    }

    return (
      <ViewRenderer
        actions={this}
        componentsMap={ComponentsMap}
        state={renderState}
        schema={viewSchema}
        api={apis}
      >
        {children}
      </ViewRenderer>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Private Functions
  //////////////////////////////////////////////////////////////////////////////

  protected handleCloseDetailPanel = () => {
    const { match } = this.props
    const { viewMode } = this.state
    this.navigateTo({ pathname: match.url, viewMode })
  }

  protected handleColumnsChange(columns) {
    const viewState = this.getViewState()
    viewState.columns = columns
    this.forceUpdate()
  }

  protected handleFiltersChange(filters) {
    const { dataSets } = this.props
    const viewState = this.getViewState()
    viewState.filters = filters
    // apply filters to all of the dataSets
    _.forEach(dataSets, (dataSet) => {
      dataSet.handleFiltersChange(filters)
    })
    this.forceUpdate()
  }

  protected handleSelectionChange(selection, selectedRow?, parentRows?) {
    const { match } = this.props
    const { viewMode } = this.state
    const route = match.url
    // We have to set state before changing path otherwise, we run into a race
    // condition with setting selection
    this.setState({ selection }, () => this.navigateTo({ pathname: route, viewMode }))
  }

  protected handleRowClick(selection, selectedRow?, parentRows?) {
    const { detailViewId, detailViewSearchOptions, match } = this.props
    const { viewMode } = this.state
    const route = match.url
    const showDetailPanel = selection.length === 1
    if (detailViewId) {
      const nextState = { selection }
      const navState = this.getDetailRouteNavState(selection, selectedRow, parentRows)
      // We have to set state before changing path otherwise, we run into a race
      // condition with setting selection
      this.setState(nextState, () => this.navigateTo({ ...navState, viewMode }))
    } else {
      // TODO(Peter): figure out how to unhardcode this
      const idExtractor = (item) => _.get(item, 'data.uniqueId')
      const entityId = idExtractor(selectedRow)
      if (showDetailPanel) {
        Logger.logEvent('Entity Selected', { entityId })
      }
      // We have to set state before changing path otherwise, we run into a race
      // condition with setting selection
      const pathname = showDetailPanel ? `${route}/entity/${entityId}` : route
      const search = viewMode === 'list' ? '?isFullScreen=1' : undefined
      this.setState({ selection }, () => this.navigateTo({ pathname, viewMode }))
    }
  }

  protected handleViewModeChange(viewMode) {
    const { location, match } = this.props
    const pathname = viewMode === 'list' ? match.url : location.pathname
    this.setState({ viewMode })
    this.navigateTo({ pathname, viewMode })
  }

  protected getDetailRouteNavState(selection, selectedRow?, parentRows?) {
    const { dataSets, detailViewId, detailViewSearchOptions, match } = this.props
    const route = match.url
    const groups = _.get(dataSets, 'table.query.groups', [])
    const hasGroupings = groups.length > 1
    if (selection.length === 0) {
      return { pathname: match.url }
    } else if (_.isEmpty(parentRows)) {
      const searchOption = this.getSearchOption(selectedRow, detailViewSearchOptions)
      return {
        pathname: `${route}/view/${detailViewId}`,
        search: searchOption,
      }
    } else {
      const filters = this.getFiltersFromSelection([...parentRows, selectedRow])
      return {
        pathname: `${route}/view/${detailViewId}`,
        search: { q: uuidv4() },
        state: { filters },
      }
    }
  }

  protected getEntityIdFromLocation(location) {
    const { pathname } = location
    // TODO(Peter): should include route
    const matches = pathname.match(/entity\/([\w\d-]+)$/)
    return matches ? matches[1] : undefined
  }

  protected getFiltersFromSelection(groups) {
    const { dataSets } = this.props
    const listDataSet = dataSets.table
    const groupSpecs = listDataSet.query.groups
    return QueryOptionsGenerator.getFiltersFromGroups(groupSpecs, groups)
  }

  protected getSearchOption(row, searchOptions) {
    const searchOption = {}
    _.forEach(searchOptions, (searchOptionPath, key) => {
      searchOption[key] = _.get(row, searchOptionPath)
    })
    return searchOption
  }

  protected getViewState() {
    return this.props.view.view.state
  }

  protected navigateTo(props) {
    const { pathname, search, state, viewMode } = props
    const searchOption: any = search || {}
    if (viewMode === 'list') { searchOption.isFullScreen = 1 }
    return browserHistory.push({
      pathname,
      search: queryString.stringify(searchOption),
      state,
    })
  }
}
