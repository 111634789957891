import _ from 'lodash'
import React from 'react'

import {
  GeoRegionInput,
  IGeoRegionInputProps,
} from 'browser/components/atomic-elements/atoms/geo-region-input/geo-region-input'
import {
  IInputSheetProps,
  InputSheetFactory,
} from 'browser/components/atomic-elements/higher-order-components/input-sheet-factory'

export interface IGeoRegionInputSheetProps extends IGeoRegionInputProps, IInputSheetProps {}
export const GeoRegionInputSheet = InputSheetFactory<IGeoRegionInputProps>(
  GeoRegionInput,
  // No default props needed
  {},
  // Need to override these props in the sheet
  {
    showEditableFields: true,
  },
)
