import { Tag, Tooltip, Position } from '@blueprintjs/core'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/table/cells/_table-email-status-cell.scss'


// TODO: need to take color mapping from json, quick and dirty for now
interface ITableEmailStatusCellProps extends IBaseProps {
  row: any
  value: 'Unsent' | 'Sent' | 'Delivered' | 'Opened' | 'Failed' | 'Printed' | 'Ready'
}

export const TableEmailStatusCell: React.FC<ITableEmailStatusCellProps> = (props) => {
  const { value } = props
  if (!value) { return <div>-</div> }
  const tagName = _.replace(value, ' ', '-').toLowerCase()
  const tagClassName = value ? `c-tag--${tagName}` : ''

  let content = ''
  let isTooltipDisabled = true
  if (value === 'Failed') {
    const { row } = props
    const entity = _.get(row, 'data')
    // get failure message
    content = _.get(entity, 'emailable.failureMessage', 'No error message provided')
    isTooltipDisabled = false
  }
  return (
    <Tooltip
      content={content}
      disabled={isTooltipDisabled}
      hoverOpenDelay={500}
      position={Position.BOTTOM}
    >
      <Tag className={classNames('c-tag', tagClassName)}>
        {value}
      </Tag>
    </Tooltip>
  )
}
