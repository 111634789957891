import _ from 'lodash'

import { useEffect, useState } from 'react'

import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'

const DOCUMENT_TYPE = '/1.0/entities/metadata/document.json'

// TODO: define the response type after ESLint is upgraded (VD-6037)
export const useFetchDocuments = (orders): any => {
  const [documentByName, setDocumentByName] = useState<object[]>([])
  const [documentByEdge, setDocumentByEdge] = useState<object[]>([])
  const [documentByNameIsLoading, setDocumentByNameIsLoading] = useState<boolean>(true)
  const [documentByEdgeIsLoading, setDocumentByEdgeIsLoading] = useState<boolean>(true)

  const displayNames = _.map(orders, (order) => order.displayName)
  const documentsByNameDataSource = new EntityDataSource({
    entityType: DOCUMENT_TYPE,
    filters: [
      {
        entityType: DOCUMENT_TYPE,
        path: 'document.name',
        type: 'match',
        values: displayNames,
      },
    ],
  }).setOnChange((res) => {
    const documents = _.map(res, (r) => r.data)
    setDocumentByName(documents)
    setDocumentByNameIsLoading(false)
  })

  const documentByEdgeEntityDataSource = new EntityDataSource({
    entityType: DOCUMENT_TYPE,
    filters: [
      {
        entityType: DOCUMENT_TYPE,
        path: 'document.entity',
        type: 'matchEdge',
        values: orders,
      },
    ],
  }).setOnChange((res) => {
    const documents = _.map(res, (r) => r.data)
    setDocumentByEdge(documents)
    setDocumentByEdgeIsLoading(false)
  })
  // TODO:(David) kind of hacky we should fix it or commit to only edges
  useEffect(() => {
    if (!_.isEmpty(orders)) {
      documentsByNameDataSource.find()
      documentByEdgeEntityDataSource.find()
    }
    return () => {
      documentsByNameDataSource.dispose()
      documentByEdgeEntityDataSource.dispose()
    }
  }, [orders])

  return [_.union(documentByName, documentByEdge),
    (documentByNameIsLoading && documentByEdgeIsLoading)]
}
