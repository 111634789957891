import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface ITableCargoValueCellProps extends IBaseProps {
  value: any
}

export const TableCargoValueCell: React.FC<ITableCargoValueCellProps> = (props) => {
  const { value } = props
  const color = {
    color: value >= 100000 ? 'red' : 'black',
  }
  return <div style={color}>${value.toFixed(2)}</div>
}
