import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core'
import _ from 'lodash'
import React from 'react'
import { findDOMNode } from 'react-dom'

import { findAndClickFirstInput, getLabelProps } from './utils'

// temporarily providing some base component props, to quiet compiler errors for now.
// Ideally, we'd set these up in a nicer way.
type TempProps = Partial<{
  label: any
  isLabelEditable: any
  frames: any
  errors: any
  value: any

  showInlinePreview: any
  inlinePreviewSchema: any

  focusHotkey: any
}>

export function JSONInputFactory<T extends TempProps>(
  InputComponent: React.ComponentType<T>
): React.ComponentType<T> {
  @HotkeysTarget
  class WrappedComponent extends React.Component<T, any> {
    public static displayName = 'JSONInputFactory'
    private labelProps: object
    private inputProps: object
    private valuePath: string

    constructor(props: T) {
      super(props)
      const { frames } = props
      const schema = frames.getContext('dataSchema')
      this.valuePath = this.getValuePath()
      this.labelProps = getLabelProps(schema, props)
      this.inputProps = {
        'data-debug-id': _.get(props, 'data-debug-id', this.valuePath),
        type: this.getInputType(schema, props),
      }
    }

    public focus = () => {
      // TODO(peter/louis): added hack here because otherwise typescript
      // compiled complains.
      const element: any = findDOMNode(this)
      findAndClickFirstInput(element)
    }

    public render() {
      const { labelErrorText, valueErrorText } = this.getErrors()
      const labelProps = {
        ...this.labelProps,
        errorText: labelErrorText,
      }
      return (
        <InputComponent
          {...this.props}
          {...this.inputProps}
          errorText={valueErrorText}
          labelProps={labelProps}
        />
      )
    }

    public renderHotkeys() {
      const { focusHotkey, label } = this.props
      if (focusHotkey) {
        return (
          <Hotkeys>
            <Hotkey
              allowInInput={true}
              combo={focusHotkey}
              label={`Focus '${label}'`}
              onKeyDown={this.focus}
              stopPropagation={true}
              preventDefault={true}
              global={true}
            />
          </Hotkeys>
        )
      } else {
        return <Hotkeys />
      }
    }

    private getErrors() {
      const { frames, isLabelEditable } = this.props
      if (isLabelEditable) {
        const errors = frames.getValue('errors') || {}
        const labelErrorPath = `${this.valuePath}.label`
        const valueErrorPath = `${this.valuePath}.value`
        return {
          labelErrorText: this.getFirstError(errors, labelErrorPath),
          valueErrorText: this.getFirstError(errors, valueErrorPath),
        }
      }
      return {
        labelErrorText: null,
        valueErrorText: _.first(this.props.errors),
      }
    }

    private getFirstError(errors, path) {
      // should match even if this component's valuePath is not the complete path.
      // some inputs can write objects, whose parts can fail validation,
      // in which case we want to propagate `errorText` through.
      if (_.isEmpty(errors)) {
        return null
      }
      const errorKeys = Object.keys(errors)
      for (let i = 0; errorKeys && i < errorKeys.length; i++) {
        const errorKey = errorKeys[i]
        const rest = errorKey.replace(this.valuePath, '')
        if (!rest.length || rest[0] === '.') {
          path = errorKey
        }
      }
      return _.first(errors[path])
    }

    private getInputType(schema, props) {
      const { format, isLabelEditable, type } = props
      schema = isLabelEditable ? schema.properties.value : schema
      // data schema might not exist if it is not an entity Renderer
      const dataType = _.get(schema, 'type', type)
      const dataFormat = _.get(schema, 'format', format)
      return dataType || dataFormat
    }

    private getValuePath() {
      const { frames } = this.props
      const valuePath = frames.getContext('valuePath')
      const transientValuePath = frames.getContext('uiSchema')?.transientValue
      return _.join(valuePath, '.') || transientValuePath
    }
  }

  return WrappedComponent
}
