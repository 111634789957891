import React from 'react'
import _ from 'lodash'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { MobileInputField } from 'browser/mobile/components/input/mobile-input-field'
import { browserHistory } from 'browser/history'
import queryString from 'query-string'

interface RemoteConfigProps extends IBaseProps {
  settings: any
}

interface RemoteConfigState {
  rows: any[]
  name?: string
  value?: string
}

export class RemoteConfig extends React.Component<RemoteConfigProps, RemoteConfigState> {
  private isMobile = false

  constructor(props) {
    super(props)
    const { isMobile = false } = queryString.parse(location.search)
    this.isMobile = isMobile

    this.state = {
      rows: props.settings.getAllRemoteConfigs(),
    }
  }

  private fetchConfigs() {
    const { settings } = this.props
    this.setState({
      rows: settings.getAllRemoteConfigs()
    })
  }

  private handleChangeName(newValue) {
    this.setState({name: newValue})
  }

  private handleChangeValue(newValue) {
    this.setState({value: newValue})
  }

  private doChangeValue() {
    const { name, value } = this.state
    if (_.isEmpty(name)) {
      return
    }
    const { settings } = this.props
    settings.setRemoteConfigOverride(name, value)
    this.fetchConfigs()
  }

  render() {
    const columns = ['Name', 'Value']
    const { rows } = this.state

    return (
      <>
        <div className='u-bumperTop--lg u-bumperLeft--lg col-xs-6'>
          <table
            className='c-table c-table--auto u-pb'
            style={{marginBottom: 20}}
          >
            <thead className='c-table-header'>
              <tr key='header'>{columns.map((column) => (<th className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft' key={column}>{column}</th>))}</tr>
            </thead>
            <tbody>
              {
                Object.keys(rows).map((key: string) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td className='c-table-cell c-table-cell--tight c-table-cell--noBorderLeft' key={rows[key].asString()}>{rows[key].asString()}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <InputField
            placeholder='Property Name'
            onChange={this.handleChangeName.bind(this)}
            value={this.state.name}
            style={{marginTop: 20, flex: 1}}
          />
          <InputField
            placeholder='Property Value'
            onChange={this.handleChangeValue.bind(this)}
            value={this.state.value}
            style={{flex: 1}}
          />
          <Button
            onClick={this.doChangeValue.bind(this)}
            style={{marginTop: 20, flex: 1}}
          >Set Override</Button>
          {this.isMobile && <Button
            onClick={browserHistory.goBack}
            style={{marginTop: 20, marginLeft: 20, flex: 1}}
          >Back</Button>}
        </div>
      </>
    )
  }
}
