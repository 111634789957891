import { Classes } from '@blueprintjs/core'
import { PropMappingFunction } from 'shared-libs/components/context/ui-prop-mapping-context'

export function getPropMappingStrategy(schema: any, key: string, value: any): PropMappingFunction {
  return (mapLegacyBlueprintFontName(schema, key, value) ??
    mapLegacyBlueprintNamespace(schema, key, value)) as PropMappingFunction
}

const legacyBlueprintIcons16FontFamily = 'Icons16'
const blueprintIcons16FontFamily = 'blueprint-icons-16'

function mapLegacyBlueprintFontName(schema: any, key: string, value: any) {
  if (key !== 'style' || value?.fontFamily !== legacyBlueprintIcons16FontFamily) {
    return
  }

  return () => ({
    ...value,
    fontFamily: blueprintIcons16FontFamily,
  })
}

const legacyBlueprintNamespacePrefix = 'pt-'
const legacyBlueprintNamespacePrefixRegex = new RegExp(
  `\\b${legacyBlueprintNamespacePrefix}([\\w-]+)`,
  'g'
)
const blueprintNamespace = Classes.getClassNamespace()

/* Swaps the legacy Blueprint namespace to the one provided dynamically by the
 * currently installed version of @blueprint/core. This allows approximate
 * support to continue for instances of `pt-` that are used in UI schema, and
 * allows us to upgrade Blueprint freely. */
function mapLegacyBlueprintNamespace(schema: any, key: string, value: any) {
  if (key !== 'className') {
    return
  }
  // do a fast substring check first, so we don't run regex on all component
  // className props.
  if ((value?.indexOf?.(legacyBlueprintNamespacePrefix) ?? -1) === -1) {
    return
  }
  // check regex, including a word boundary so we don't match larger words that
  // contain the legacy prefix.
  if (!legacyBlueprintNamespacePrefixRegex.test(value)) {
    return
  }
  // replace the legacy CSS class names
  return () => {
    return value.replace(legacyBlueprintNamespacePrefixRegex, (match, group) => {
      // exempt `pt-kiosk`, this was a poorly named class on our side
      if (match === 'pt-kiosk') {
        return match
      }
      return `${blueprintNamespace}-${group}`
    })
  }
}
