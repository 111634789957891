import React from 'react'

import _ from 'lodash'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
// tslint:disable-next-line:max-line-length
import { ImageEditorCarousel } from 'browser/components/atomic-elements/organisms/image-editor-carousel/image-editor-carousel'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { ComponentsContext, IComponentsContext } from 'browser/contexts/components/components-context'

interface ImageEditorModalProps extends IBaseProps {
  entity: any
  imagesPath: string
  index: number
  onClose: () => void
  showOriginal: boolean
  editControls?: React.ReactNode
}

export class ImageEditorModal extends React.Component<ImageEditorModalProps, any> {
  public static open(props, componentsContext?: IComponentsContext): void {
    const Carousel = (injectedProps) => (
      <ImageEditorCarousel
        isEditable={true}
        isExportable={true}
        isInModal={true}
        {...props}
        {...injectedProps}
      />
    )
    const WrappedCarousel = (injectedProps) =>
      _.isNil(componentsContext) ? <Carousel {...injectedProps} /> : (
        <ComponentsContext.Provider value={componentsContext}>
          <Carousel {...injectedProps} />
        </ComponentsContext.Provider>
      )
    const settings = props.entity?.api.settings
    OverlayManager.openOverlayElement(
      <Modal
        modalBackdropClassName='c-modal-backdrop--dark'
        modalDialogClassName='c-imageViewer'
      >
        <WrappedCarousel />
      </Modal>,
    )
  }
}
