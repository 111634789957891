import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { GPSInput, IGPSInputProps } from 'browser/components/atomic-elements/atoms/input/gps-input'
import { ILabelProps } from 'browser/components/atomic-elements/atoms/label/label'
import { EntityMap } from 'browser/components/atomic-elements/atoms/mapbox/entity-map'
import LabelFieldFactory from 'browser/components/atomic-elements/higher-order-components/label-field-factory'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { FramesManager } from 'shared-libs/components/view/frames-manager'

// TODO(Peter): merge with geolocation field
/**
 * @uiComponent
 */
interface IGPSFieldProps extends IGPSInputProps {
  density: string
  entity: object
  isHorizontalLayout: boolean
  frames: FramesManager
}

export const LabeledGPSInput = LabelFieldFactory({
  InputComponent: GPSInput,
})

export class GPSField extends React.Component<IGPSFieldProps, any> {

  public render() {
    const {
      entity,
      value,
      frames,
    } = this.props
    const latitude = _.get(value, 'latitude')
    const longitude = _.get(value, 'longitude')
    if (_.isNil(latitude) || _.isNil(longitude)) {
      return null
    }

    // override geolocationPath
    const valuePath = frames && frames.getContext('valuePath')
    const pathProp = valuePath
      ? { geolocationPath: _.join(valuePath, '.') }
      : {}

    return (
      <div>
        <LabeledGPSInput
          {...this.props}
          isDisabled={true}
        />
        <div className='c-locationMap'>
          <EntityMap
            {...pathProp}
            isZoomControlsEnabled={true}
            entities={[entity]}
            zoomLevel={10}
          />
        </div>
      </div>
    )
  }
}
