import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import countries from 'browser/components/atomic-elements/atoms/input/address-input/countries'
import { Select } from 'browser/components/atomic-elements/atoms/select'

const STATES = countries[0].regions

export interface IGeoRegionStateTabPanelProps extends IBaseProps {
  isStatic?: boolean
  onChange: (value: any) => void
  placeholder?: string
  size?: string
  value?: any
}

export class GeoRegionStateTabPanel extends React.Component<IGeoRegionStateTabPanelProps, any> {

  public focus() {
    return false
  }

  public render() {
    const { value } = this.props
    const states = _.get(value, 'states', [])
    return (
      <FormGroup

      >
        <Select
          className='c-geoRegionInput-states'
          multi={true}
          onChange={this.handleStatesChange}
          options={STATES}
          optionLabelPath='name'
          optionValuePath='name'
          placeholder='Select states'
          value={states}
        />
      </FormGroup>
    )
  }

  private handleStatesChange = (states) => {
    this.props.onChange({
      states,
      type: 'states',
    })
  }
}
