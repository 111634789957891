import React from 'react'
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import './_scroll-down-button-wrapper.scss'
import { IBaseProps } from '../base-props'
import classNames from 'classnames'

enum ScrollBehavior {
  SinglePage = 'single-page',
  Bottom = 'bottom',
}

interface ScrollDownButtonWrapperProps extends IBaseProps {
  scrollBehavior?: ScrollBehavior
}

const TOLERANCE = 25 * window.devicePixelRatio

/** A container with a button at the bottom when there is overflowing content. */
export class ScrollDownButtonWrapper extends React.Component<ScrollDownButtonWrapperProps> {
  static defaultProps: Partial<ScrollDownButtonWrapperProps> = {
    scrollBehavior: ScrollBehavior.SinglePage,
  }

  private formRef = React.createRef<HTMLDivElement>()
  private foldRef = React.createRef<HTMLButtonElement>()
  private observer: MutationObserver

  componentDidMount(): void {
    this.handleScrollEvent()

    // update on window resize
    window.addEventListener('resize', this.handleScrollEvent)

    // observe content size changes in the container
    this.observer = new MutationObserver(this.handleScrollEvent)
    this.observer.observe(this.formRef.current, { childList: true, subtree: true })
  }

  componentWillUnmount(): void {
    this.observer.disconnect()
    window.removeEventListener('resize', this.handleScrollEvent)
  }

  render() { 
    const { className, style } = this.props
    return (
      <>
        <div
          ref={this.formRef}
          className={classNames('grid-block', className)}
          style={style}
          onScroll={this.handleScrollEvent}
        >
          {this.props.children}
        </div>
        <button ref={this.foldRef} className="c-scrollDown-button c-scrollDown-button--disabled" onClick={this.handleButtonClick}>
          <Icon color="#f89939" size={20} icon={IconNames.CHEVRON_DOWN} />
        </button>
      </>
    )
  }

  private handleScrollEvent = () => {
    const button = this.foldRef.current
    const enabled = this.hasContentBelowFold()
    const classes = classNames('c-scrollDown-button', {
      'c-scrollDown-button--enabled': enabled,
      'c-scrollDown-button--disabled': !enabled,
    })
    button.className = classes
  }

  private handleButtonClick = () => {
    const form = this.formRef.current
    form.scrollBy({
      top: this.getScrollDistance(),
      behavior: 'smooth',
    })
  }

  private hasContentBelowFold(): boolean {
    const container = this.formRef.current
    const scrollTop = container.scrollTop
    const divHeight = container.scrollHeight
    const clientHeight = container.clientHeight
    return (scrollTop + clientHeight) < (divHeight - TOLERANCE)
  }

  private getScrollDistance = () => {
    const { scrollBehavior } = this.props
    const form = this.formRef.current
    const foldButton = this.foldRef.current

    if (scrollBehavior === ScrollBehavior.SinglePage) {
      const buttonRegionHeight = form.clientHeight - foldButton.offsetTop
      return form.clientHeight - buttonRegionHeight - 20
    } else {
      return form.scrollHeight
    }
  }
}
