import { Classes, Icon, IconName } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { deserializeValue, serializeValue } from 'shared-libs/components/input/utils'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
// tslint:disable-next-line:max-line-length
import { FormGroupContentWrapper } from 'browser/components/atomic-elements/atoms/form-group-content-wrapper/form-group-content-wrapper'
import 'browser/components/atomic-elements/atoms/input/_input.scss'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { translateString } from 'shared-libs/helpers/utils'

/**
 * @uiComponent
 */
export interface IInputProps extends IBaseProps {
  frames?: FramesManager
  autoComplete?: string
  autoFocus?: boolean
  errorText?: string
  format?: string
  inputClassName?: string
  inputType?: string
  inputMode?: string
  pattern?: string
  maxLength?: number
  isControlled?: boolean
  isErrorInline?: boolean
  isRequired?: boolean
  // Add grey background when in edit mode, do not allow edits in static mode
  isDisabled?: boolean
  isSelectable?: boolean
  // Inline editable. Determines if the EDIT button shows up on hover.
  isEditableInline?: boolean
  isHorizontalLayout?: boolean
  isLoading?: boolean
  isSelectedStyle?: boolean
  name?: string
  numDecimalPlaces?: number
  onBlur?: (event: any) => void
  onChange?: (value: any, event?: any, silentUpdate?: boolean) => void
  onClick?: (event: any) => void
  onEnterPressed?: (event: any) => void
  onFocus?: (event: any) => void
  onKeyDown?: (event: any) => void
  onKeyPress?: (event: any) => void
  onPaste?: (event: any) => void
  onRightIconClick?: () => void
  onSave?: any
  openErrorsOnHover?: boolean
  placeholder?: string
  link?: string
  showLinkIcon?: boolean
  rightIcon?: IconName
  rightView?: React.ReactElement
  skipNumberSerialization?: boolean
  size?: string
  tabIndex?: number
  type?: string
  value?: any
  initialValue?: any
  charsToStrip?: RegExp
  regexToMatch?: RegExp
  extraRef?: (ref: any) => void
}

// The `internalValue` stores the value shown in the input box as a string.
// This can differ from the value from props because the
// `internalValue` may keep unnecessary digits.  For example, the `internalValue` may store '1.0' as the user is typing.
// '.0' is unnecessary but kept.  In this case, `this.props.value` would be `1`
interface IInputState {
  isFocused: boolean
  internalValue: string
}

export class Input extends React.Component<IInputProps, IInputState> {
  public static defaultProps: IInputProps = {
    isControlled: true,
    isSelectable: false,
    isErrorInline: true,
    isSelectedStyle: false,
    openErrorsOnHover: true,
    skipNumberSerialization: false,
    type: 'text',
  }

  private input: any

  constructor(props: IInputProps) {
    super(props)
    const { format, type, value, initialValue } = props
    this.state = {
      internalValue: deserializeValue(type, format, this.truncate(_.isNil(initialValue) ? value : initialValue)),
      isFocused: false,
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      const { format, type, value } = nextProps
      this.setState({ internalValue: deserializeValue(type, format, this.truncate(value)) })
    }
  }

  public componentDidMount() {
    const { isDisabled, value, initialValue, onChange } = this.props

    if (!isDisabled && !_.isNil(initialValue) && value !== initialValue) {
      // one time initalValue write back
      onChange(initialValue, undefined, true)
    }
  }

  public truncate(value) {
    const { numDecimalPlaces } = this.props
    if (numDecimalPlaces && !_.isNil(value)) {
      return _.round(value, numDecimalPlaces).toFixed(numDecimalPlaces)
    }
    return value
  }

  public focus() {
    this.getElement().focus()
  }

  public blur() {
    this.getElement().blur()
  }

  public getElement(): HTMLInputElement {
    return this.input
  }

  public render() {
    const {
      className,
      errorText,
      isDisabled,
      isHorizontalLayout,
      value,
      isSelectedStyle,
    } = this.props

    const { isFocused } = this.state
    return (
      <FormGroupContentWrapper
        hasError={!_.isEmpty(errorText)}
        isHorizontalLayout={isHorizontalLayout}
        isFocused={isFocused}
        isDisabled={isDisabled}
        className={classNames(
          {
            'c-formGroupContentWrapper--selected': isSelectedStyle && value,
          },
          className
        )}
      >
        {/* this.renderLeftIcon() */}
        {this.renderInput()}
        {this.renderLoading()}
        {this.renderRightIcon()}
      </FormGroupContentWrapper>
    )
  }

  private renderInput() {
    const {
      frames,
      autoComplete,
      isControlled,
      errorText,
      size,
      inputClassName,
      inputType,
      inputMode,
      pattern,
      maxLength,
      isHorizontalLayout,
      isDisabled,
      isSelectable,
      autoFocus,
      name,
      onKeyDown,
      onPaste,
      placeholder,
      tabIndex,
      type,
    } = this.props
    const { internalValue } = this.state

    const sizeClassName = _.isEmpty(size) ? '' : `c-input--${size}`
    const refFunc = (ref) => {
      this.input = ref
      if (this.props.extraRef) {
        this.props.extraRef(ref)
      }
    }
    const translationTable = frames?.getContext('translationTable')
    const translatedPlaceholder = translateString(placeholder, translationTable)

    // TODO(louis): Need to handle empty case where the placeholder is showing
    // but we should actually show '-'
    // NOTE: super important that we listen to onInput instead of onChange so that
    // when user paste into the text mask this still works. However, React
    // complians about the lack of onChange handler so we put a noop there
    return (
      <>
        <input
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={classNames('c-input', sizeClassName, inputClassName, {
            'c-input--error': !_.isEmpty(errorText),
            'c-input--isDisabled': isDisabled,
            'c-input--isHorizontalLayout': isHorizontalLayout,
          })}
          data-debug-id={_.get(this.props, 'data-debug-id')}
          disabled={isDisabled}
          ref={refFunc}
          name={name}
          onBlur={this.handleOnBlur}
          onChange={_.noop}
          onClick={this.handleClick}
          onFocus={this.handleOnFocus}
          onInput={this.handleOnChange}
          onKeyDown={onKeyDown}
          onKeyPress={this.handleOnKeyPress}
          onPaste={onPaste}
          placeholder={translatedPlaceholder}
          tabIndex={tabIndex}
          inputMode={inputMode}
          pattern={pattern}
          maxLength={maxLength}
          type={inputType || type}
          value={isControlled ? internalValue : undefined}
        />
        {isSelectable && <div className="c-input--selectable" onClick={this.handleClick} />}
      </>
    )
  }

  // TODO(Louis): we don't currently use this
  private renderLeftIcon() {
    return (
      <div className="c-inputLeftIcon">
        <LoadingSpinner size="xs" />
      </div>
    )
  }

  private renderRightIcon() {
    const { showLinkIcon, rightIcon, onRightIconClick, rightView, size } = this.props

    if (showLinkIcon) {
      // TODO(louis): Revisit size={size} once input has been properly sized
      return (
        <Button
          className={classNames('c-inputInfoIconButton', Classes.MINIMAL)}
          onClick={this.handleLinkIconClick}
        >
          <Icon icon={IconNames.SHARE} />
        </Button>
      )
    } else if (rightIcon) {
      return (
        <Button
          className={classNames('c-inputInfoIconButton', Classes.MINIMAL)}
          onClick={onRightIconClick}
        >
          <Icon icon={rightIcon} />
        </Button>
      )
    } else if (rightView) {
      return rightView
    }
  }

  private handleLinkIconClick = () => {
    const { link } = this.props
    window.open(link, '_blank')
  }

  private renderLoading() {
    const { isLoading } = this.props
    if (!isLoading) {
      return
    }
    return (
      <div className="c-inputRightIcon">
        <LoadingSpinner size="xs" />
      </div>
    )
  }

  private handleOnBlur = (event) => {
    const { onBlur } = this.props
    this.setState({ isFocused: false })
    if (onBlur) {
      onBlur(event)
    }
  }

  private handleOnChange = (event) => {
    const {
      onChange,
      type,
      value: oldSerializedValue,
      regexToMatch,
      charsToStrip,
      skipNumberSerialization,
    } = this.props
    let newValue = event.target.value
    if (charsToStrip) {
      newValue = newValue.replace(charsToStrip, '')
    }
    if (!regexToMatch || regexToMatch.test(newValue)) {
      // We set the internalValue to preserve unnecessary characters
      // (For example, "1.0".  The '.0' is unnecessary but we need to keep it)
      this.setState({ internalValue: newValue })
      // Convert this to a number sometimes depending on type in schema.
      if (type === 'number' && skipNumberSerialization) {
        onChange(newValue, event)
        return
      }

      const newSerializedValue = serializeValue(type, newValue)
      // After serializing newValue might be same as props.value.
      if (onChange && oldSerializedValue !== newSerializedValue) {
        onChange(newSerializedValue, event)
      }
    }
  }

  private handleClick = (event) => {
    const { onClick } = this.props
    if (onClick) {
      onClick(event)
    }
  }

  private handleOnFocus = (event) => {
    const { onFocus } = this.props
    this.setState({ isFocused: true })
    if (onFocus) {
      onFocus(event)
    }
  }

  private handleOnKeyPress = (event) => {
    const { onEnterPressed, onKeyPress } = this.props
    if (event.key === 'Enter') {
      if (onEnterPressed) {
        onEnterPressed(event)
      }
    }
    if (onKeyPress) {
      onKeyPress(event)
    }
  }
}
