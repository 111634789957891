import _ from 'lodash'
import React from 'react'

import { Store } from 'shared-libs/models/store'

import apis from 'browser/app/models/apis'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { SheetContext } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
// tslint:disable-next-line:max-line-length
import { AccountingMappingSheet } from 'browser/components/atomic-elements/domains/trucking/accounting-mapping-sheet/accounting-mapping-sheet'

/**
 * @uiComponent
 */
interface IOrderAccountingFormProps extends IBaseProps {
  accountItemType: string
  businessEntity?: any
  entity: any
  errorsMap: any
  exportsCollections: any
  frames: any
  itemReferencePath: string
  onChange: (value: any[]) => void
  value: any
  openOverlay: any
  settings: any
}

interface IOrderAccountingFormState {
  businessEntity: any
  exportItemType: string
  hasAccountingIntegration: boolean
  isAmendment: boolean
  isExporting: boolean
  showEditableLineItems: boolean
}

const QBO_SYNC_ID = '164fd38f-5ea0-41f5-bf35-5aae09ddcb7d'

class OrderAccountingForm extends React.Component<IOrderAccountingFormProps, IOrderAccountingFormState> {

  private store: Store

  constructor(props) {
    super(props)
    const { accountItemType } = this.props
    this.state = {
      businessEntity: null,
      exportItemType: accountItemType === 'Revenue' ? 'Invoice' : 'Bill',
      hasAccountingIntegration: false,
      isAmendment: false,
      isExporting: false,
      showEditableLineItems: false,
    }
    this.store = apis.getStore()
  }

  public componentDidMount() {
    const { accountItemType, entity, settings } = this.props
    const { brokerOrder, carrierOrder } = entity
    const order = settings.isBrokerTMSApplication() ? brokerOrder : carrierOrder

    let businessEntityId = null
    if (accountItemType === 'Revenue') {
      if (order.customer) {
        businessEntityId = order.customer.entityId
      }
    } else {
      if (order.carrier) {
        businessEntityId = order.carrier.entityId
      }
    }

    if (businessEntityId) {
      this.store.findRecord(businessEntityId).then((result) => {
        this.setState({businessEntity: result})
      })
      this.setState(this.getNextStateFromProps(this.props))
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { entity } = this.props
    if (entity.uniqueId !== nextProps.entity.uniqueId) {
      this.setState(this.getNextStateFromProps(nextProps))
    }
  }

  public render() {
    const { children } = this.props
    const { exportItemType, isAmendment, showEditableLineItems } = this.state
    if (!showEditableLineItems) {
      return this.renderEmptyState()
    }
    const title = isAmendment ? `${exportItemType} Amendment` : `New ${exportItemType}`
    return (
      <div className='mb2'>
        <div className='pa4 u-border'>
          <h6>
            {title}
          </h6>
          {children}
        </div>
        <div className='u-flex u-justifyContentSpaceBetween'>
          {this.renderHideAccountingFormButton()}
          {this.renderExportToQuickbooksButton()}
        </div>
      </div>
    )
  }

  private getNextStateFromProps(props) {
    const { value, settings } = props
    const qboSettings = settings.getQuickBooksOnlineSettings()
    const isInstalled = _.get(qboSettings, 'settings.isInstalled', false)
    const exportedLineItems = _.filter(value, (item: any) => item.accountingEntity)
    const isAmendment = exportedLineItems.length > 0
    return {
      hasAccountingIntegration: isInstalled,
      isAmendment,
      showEditableLineItems: !isAmendment,
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Renderer
  //////////////////////////////////////////////////////////////////////////////

  private renderEmptyState() {
    const { exportItemType, isAmendment } = this.state
    if (isAmendment) {
      return (
        <div/>
      )
    }
    return (
      <div className='tr'>
        <Button
          className='c-button--secondary'
          onClick={this.handleToggleShowEditableLineItems}
        >
          Add {isAmendment ? 'Amendment' : exportItemType}
        </Button>
      </div>
    )
  }

  private renderHideAccountingFormButton() {
    const { isAmendment } = this.state
    if (!isAmendment) {
      // Return an empty div because it's a child of a flex parent and we
      // want the other sibling to right align
      return <div/>
    }
    return (
      <Button
        className='u-bumperTop--xs'
        onClick={this.handleToggleShowEditableLineItems}
      >
        Remove Amendment
      </Button>
    )
  }

  private renderExportToQuickbooksButton() {
    const { hasAccountingIntegration, isExporting } = this.state
    const { businessEntity } = this.props

    if (businessEntity) {
      return (
        <Button
          className='u-bumperTop--xs'
          isLoading={isExporting}
          isDisabled={!hasAccountingIntegration}
          onClick={this.handleExportToQuickBooks}
        >
          Export to QuickBooks
        </Button>
      )
    }
  }

  private getValue() {
    return this.props.value || []
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private exportToQuickBooks() {
    const { accountItemType, entity } = this.props
    return accountItemType === 'Expense' ?
      apis.exportBillToQuickBooksOnline(entity.uniqueId) :
      apis.exportInvoiceToQuickBooksOnline(entity.uniqueId)
  }

  private handleExportToQuickBooks = () => {
    const { entity, exportsCollections, openOverlay } = this.props
    const { businessEntity } = this.state
    if (!_.some(businessEntity.activeMixins, { entityId: QBO_SYNC_ID })) {
      openOverlay(
        <AccountingMappingSheet
          businessEntity={businessEntity}
        />,
      )
      return
    }

    this.setState({ isExporting: true })
    // 1. save entity
    // 2. export to quickbooks
    // 3. reload entity so that accountingItems are correct
    // 4. reload collection so bills/invoices show up
    return entity.save().then(() => {
      return this.exportToQuickBooks()
    }).then(() => {
      this.setState({ isAmendment: true, showEditableLineItems: false })
      return entity.reload()
    }).then(() => {
      return exportsCollections.reload()
    }).finally(() => this.setState({ isExporting: false }))
  }

  private handleToggleShowEditableLineItems = () => {
    const { showEditableLineItems } = this.state
    this.setState({showEditableLineItems: !showEditableLineItems})
  }
}

export default React.forwardRef((props: IOrderAccountingFormProps, ref: React.Ref<OrderAccountingForm>) => (
  <AppNavigatorContext.Consumer>
    {({ settings }) => (
      <SheetContext.Consumer>
        {({ openOverlay }) => (
          <OrderAccountingForm {...props} openOverlay={openOverlay} settings={settings} ref={ref} />
        )}
      </SheetContext.Consumer>
    )}
  </AppNavigatorContext.Consumer>
))
