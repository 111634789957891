/**
 * A clone of entity-select.tsx but an ability to update the associated entity that point to
 * current entity.
 * entity-select:  update self to point to the selected entity
 * entity-invert-select: update the selected to point to self
 *
 * This is a superset of of entity-select, but for now it is better to have a separate component
 * to avoid any regression issue since entity-select is widely used.
 */
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import apis from 'browser/app/models/apis'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { ISelectProps, Select } from 'browser/components/atomic-elements/atoms/select'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
// tslint:disable-next-line:max-line-length
import { EntityAssociationsSheet } from 'browser/components/atomic-elements/organisms/entity/entity-associations/entity-associations-sheet'
import { EntityFormSheet } from 'browser/components/atomic-elements/organisms/entity/entity-form-sheet'
// tslint:disable-next-line:max-line-length
import { EntityPreview } from 'browser/components/atomic-elements/organisms/entity/entity-preview/entity-preview'
import { Entity } from 'shared-libs/models/entity'
import { Query } from 'shared-libs/models/query'
import { SheetContext } from '../sheet/sheet-manager'
import { ComponentsContext } from 'browser/contexts/components/components-context'

const DEFAULT_ORDERS = [
  {
    path: 'precomputation.displayName',
    type: 'ascending',
  },
]

interface IAssociationProps {
  fromSelectedFields?: any // the mapping fields from selected entity to self
  toSelectedFields?: any // the mapping fields from self to selected entity
}

/**
 * @uiComponent
 */
interface IEntityAssociationSelectProps extends ISelectProps {
  edgeToOptionCreator?: (edge: any, schema: any) => any
  entityToEdgeCreator?: (entity: any, schema: any) => any
  entityToOptionCreator?: (entity: any, schema: any) => any

  entityType: string
  entity?: any
  filter?: any
  isCreatable?: boolean
  newOptionAssociationProps?: any
  selectValuePath?: any // the path from the selected entity point to the current one
  associationProps?: IAssociationProps
  showLinkIcon?: boolean
  tetherOptions?: object
  addInflationSessionId?: (string) => void
  removeInflationSessionId?: (string) => void
  returnValueAsEdge?: boolean
  openOverlay?: any
}

interface IEntitySelectState {
  inputValue: string
  isLoading: boolean
  isOptionsLoaded: boolean
  options: any[]
  value: any
}

class EntityAssociationSelect extends React.Component<IEntityAssociationSelectProps, IEntitySelectState> {

  public static defaultProps: Partial<IEntityAssociationSelectProps> = {
    edgeToOptionCreator: defaultEdgeToOptionCreator,
    entityToEdgeCreator: defaultEntityToEdgeCreator,
    entityToOptionCreator: defaultEntityToOptionCreator,
    isCreatable: true,
    optionLabelPath: 'displayName',
    optionValuePath: 'uniqueId',
    returnValueAsEdge: true,
    showLinkIcon: true,
    tetherOptions: {
      attachment: 'top right',
      targetAttachment: 'bottom right',
    },
  }

  public collection: any
  public associatedEntityToSelf: any
  private entitySchema: any
  private input: Select
  private store: any
  private tether: TetherTarget
  private updateSelectedEntity: boolean
  private selectedEntity: any
  private previousSelectedEntity: any
  private selectionToSelf: boolean // if true, the selection entity points to current
                                   // if false, current entity points to selection

  static contextType = ComponentsContext
  context!: React.ContextType<typeof ComponentsContext>

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.entitySchema = this.store.getRecord(props.entityType)
    const { edgeToOptionCreator, options, value, entity } = props

    this.previousSelectedEntity = null
    this.selectedEntity = null

    this.state = {
      inputValue: undefined,
      isLoading: false,
      isOptionsLoaded: false,
      options: options || [],
      value: this.updateSelectedEntity ? undefined : edgeToOptionCreator(value, this.entitySchema),
    }
    this.handleInputChanged = _.debounce(this.handleInputChanged, 200)

    this.collection = new Query(apis)
      .setEntityType(props.entityType)
      .setOrders(DEFAULT_ORDERS)
      .getCollection()


    // if `selectValuePath` is set, then we need to filter for all entities that point
    // to the current one
    const { selectValuePath } = this.props
    this.selectionToSelf = false // current points to selected entity
    if ( !_.isEmpty(selectValuePath) ) {
      this.selectionToSelf = true // selected entity points to current
      this.collection.query.setFilters([
         {
           type: 'matchEdge',
           path: selectValuePath,
           value: { entityId: entity.uniqueId },
         },
       ])
    }

    const callbackAction = this.updateAssociatedEntities.bind(this)
    entity.addPreSaveAction({
      name: 'updateAssociatedEntities',
      action: callbackAction
    })

  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    // IMPORTANT: we need to reset options and value when filter changed
    if (!_.isEqual(this.props.filter, nextProps.filter)) {
      this.setState({
        isOptionsLoaded: false,
        options: [],
      })
    }
    if (!_.isEqual(this.props.value, nextProps.value)) {
      if (this.selectionToSelf) {
         const { entityToOptionCreator } = nextProps
         this.setState({value: entityToOptionCreator(this.selectedEntity, null)})
      } else {
        const { edgeToOptionCreator, value } = nextProps
        this.setState({
          value: edgeToOptionCreator(value, this.entitySchema),
        })
      }
    }

  }

  public componentDidMount() {
    if (this.selectionToSelf) {
      const { entityToOptionCreator, entity } = this.props
      this.collection.find().then((entities) => {
        if (!_.isEmpty(entities)) {
          const associatedEntity = _.first(entities)
          const value =  entityToOptionCreator(associatedEntity, null)
          this.previousSelectedEntity = associatedEntity
          this.setState({ value })
        }
      })
    }
  }

   public componentWillUnmount() {
    this.collection?.cancel()
  }

  public focus() {
    this.input.focus()
  }

  public render() {
    const { showLinkIcon } = this.props
    if (showLinkIcon) {
      return this.renderSelect(this.renderInfoButton())
    }
    return this.renderSelect(null)
  }

  private renderSelect(children) {
    const {
      className,
      isCreatable,
      optionValuePath,
    } = this.props
    const {
      isLoading,
      value,
    } = this.state
    const optionsWithValue = this.getOptions()
    const creatableProps = {
      isCreatable,
      onNewOptionClick: this.handleNewOptionClick,
    }
    const handleRef = (ref) => { this.input = ref }
    return (
      <Select
        {...this.props}
        {...creatableProps}
        className={classNames('flex flex-row', className)}
        isAsync={true}
        isLoading={isLoading}
        onChange={this.handleChange}
        onInputChange={this.handleInputChanged}
        onOpen={this.handleMenuOpened}
        options={optionsWithValue}
        ref={handleRef}
        value={_.get(value, optionValuePath, '')}
      >
        {children}
      </Select>
    )
  }

  private getOptions() {
    const { optionLabelPath, optionValuePath, associationProps } = this.props
    const { inputValue, options, value } = this.state

    if (value) {
      const lookupKey = optionValuePath
      const lookupValue = value[optionValuePath]
      const isValueInOptions = _.find(options, [lookupKey, lookupValue])
      // add value to options if the following criterias are met
      // 1. if searchQuery does not exists
      // 2. value is not in option
      if (_.isEmpty(inputValue) && !isValueInOptions) {
        return [value].concat(options)
      }
    }
    return options
  }

  private getCollectionFilters() {
    const { filter } = this.props
    if (filter && (filter.value || !_.isEmpty(filter.values))) {
      return [filter]
    }
    return []
  }

  private renderInfoButton() {
    const { size, tetherOptions } = this.props
    const { value } = this.state
    if (value) {
      return (
        <TetherTarget
          automaticAdjustOffset={true}
          tetherOptions={tetherOptions}
          tethered={this.renderEntityPreviewPopover()}
          ref={(ref) => { this.tether = ref }}
        >
          <Button
            className={classNames(
              'c-entitySelect-infoButton ',
              Classes.MINIMAL,
              Classes.iconClass(IconNames.INFO_SIGN)
            )}
            onClick={this.handleInfoButtonClick}
            size={size}
          />
        </TetherTarget>
      )
    }
  }

  private renderEntityPreviewPopover() {
    const { value } = this.props
    return (
      <EntityPreview
        value={value}
        renderAsPopover={true}
        components={this.context.components}
      />
    )
  }

  /****************************************************************************/
  // Entity Creation
  /****************************************************************************/

  private createNewEntity(entitySchema, defaultValue) {
    const { openOverlay } = this.props
    openOverlay(
      <EntityFormSheet
        defaultValue={defaultValue}
        entitySchema={entitySchema}
        onCreate={this.handleEntityCreated}
      />,
    )
  }

  private createNewAssociateNewEntity(newOptionAssociationProps, entityEdge, defaultValue) {
    const { openOverlay } = this.props
    const { associatedEntityType, edgePath, entityType, isEdgeOnEntity } = newOptionAssociationProps
    const associatedEntitySchema = this.store.getRecord(associatedEntityType)
    const entitySchema = this.store.getRecord(entityType)
    this.store.findRecord(entityEdge.entityId).then((entity) => {
      openOverlay(
        <EntityAssociationsSheet
          associatedEntityDefaultValue={defaultValue}
          associatedEntitySchema={associatedEntitySchema}
          entity={entity}
          entitySchema={entitySchema}
          edgePath={edgePath}
          isEdgeOnEntity={isEdgeOnEntity}
          onChange={this.handleEntityCreated}
        />,
      )
    })
  }

  /****************************************************************************/
  // Update associated entity
  /****************************************************************************/

  private initUpdateSelectedEntityConfig = () => {
   const { selectValuePath, associationProps, entity, entityType } = this.props
     this.updateSelectedEntity = true;

     // query for an associated entity with an edge to current entity
     this.associatedEntityToSelf = new Query(apis)
       .setEntityType(entityType)
       .setOrders(DEFAULT_ORDERS)
       .setFilters([
         {
           type: 'matchEdge',
           path: selectValuePath,
           value: { entityId: entity.uniqueId },
         },
       ])
       .getCollection()

     // pre save action to piggy back the new selected entity along with previous selected entity
     // for the sales invoice,  need to the following
     // - previous sales invoice: decouple the salesInvoice.invoiceDocument from this invoice
     // - newly selected sales invoice: set the salesInvoice.invoiceDocument to this invoice
     const callbackAction = this.updateAssociatedEntities.bind(this)
     entity.addPreSaveAction({
       name: 'updateAssociatedEntities',
       action: callbackAction
     })
  }

  /**
   * On action save the current entity, it will also save the associated entities
   * 1. Sync values from newly selected entity to the current one
   * 2. Clear values from the previous selected entity since it no longer associate with the current
   *   entity
   * 3. Sync values from current entity to newly selected one
   */
  private updateAssociatedEntities = (entity) => {
    const self = entity
    const selected = this.selectedEntity
    const previousSelected = this.previousSelectedEntity

    const { associationProps } = this.props

    const toSelectedFields = _.get(associationProps, 'toSelectedFields', {})
    const fromSelectedFields = _.get(associationProps, 'fromSelectedFields', {})

    // only repair the edge, the value is already on onChange callback
    this.syncEntityProps(selected, self, fromSelectedFields, true)

    if (!_.isNil(previousSelected)) {
      // clear out previous one
      this.syncEntityProps({}, previousSelected, toSelectedFields)
      entity.addAssociated(previousSelected)
    }

    if (!_.isNil(selected)) {
      // update the newly selected one
      this.syncEntityProps(self, selected, toSelectedFields)
      entity.addAssociated(selected)
    }

    return Promise.resolve()
  }

  /**
   *  Sync values or edges from one entity to another
   */
  private syncEntityProps = (src, dest, mapProps, skipValueSync = false) => {
    const keys = Object.keys(mapProps)

    if (_.isEmpty(mapProps)) {
      return
    }

    _.forEach(keys, (srcPath) => {
      const destPath = mapProps[srcPath]
      let destValue =  this.isEdge(src, srcPath)
        ? _.get(src, `${srcPath}.entityId`, null)
        : _.get(src, srcPath)
      const isEdge = this.isEdge(dest, destPath)
      if (isEdge && !_.isEmpty(destValue)) {
        destValue = { entityId: destValue }
      }

      if (!_.isEmpty(destValue) || isEdge || !skipValueSync) {
         _.setWith(dest._content, destPath, destValue)
      }
    })
  }

  private isEdge = (entity, path) => {
    if (_.isEmpty(entity)) {
      return false
    }
    const subSchema = entity.resolveSubschemaByValuePath(path)
    const refValue = _.get(subSchema, 'schema.$ref', '')

    return refValue === '/1.0/entities/metadata/entity.json#/definitions/edge'
  }


  /****************************************************************************/
  // Handlers
  /****************************************************************************/

  // tslint:disable-next-line:member-ordering
  public handleChange = (entityId, option) => {
    if (entityId) {
      const { entity, entityToEdgeCreator, associationProps } = this.props
      this.selectedEntity = option

      const srcEntity = this.selectedEntity
      const mappedFields = _.get(associationProps, 'fromSelectedFields', {})

      this.syncEntityProps(srcEntity, entity, mappedFields)

      const value = this.props.returnValueAsEdge ? entityToEdgeCreator(option, this.entitySchema) : option
      this.props.onChange(value, option)
    } else {
      this.props.onChange(undefined)
    }
  }

  private handleEntityCreated = (entity: Entity) => {
    const { entityToEdgeCreator } = this.props

    // It takes some time for some newly created entities to have their denormalized properties inflated.
    // We notify the parent entity that an inflation is pending so as to prevent saving until inflation is finished.
    const inflationSessionId = uuidv4()
    this.props.addInflationSessionId(inflationSessionId)

    entity.waitUntilIdle().then(() => {
      const value = entityToEdgeCreator(entity, this.entitySchema)
      this.props.onChange(value, entity)
    }).finally(() => { this.props.removeInflationSessionId(inflationSessionId) })
  }

  private handleMenuOpened = () => {
    const { options } = this.state
    if (_.isEmpty(options)) {
      this.handleInputChanged(null)
    }
  }

  private handleInputChanged = (input) => {
    input = input ? input.trim() : null
    // do not make remote request if input value didn't change
    const { isOptionsLoaded, inputValue } = this.state
    if (isOptionsLoaded && input === this.state.inputValue) {
      return
    }
    // we also need to load the selected entity to get the name
    this.collection.cancel()
    this.collection.query.setQuery(input)
    this.collection.query.setFilters(this.getCollectionFilters())
    this.setState({ isLoading: true })
    return this.collection.find().then((entities) => {
      this.setState({
        inputValue: input,
        isLoading: false,
        isOptionsLoaded: true,
        options: entities,
      })
    })
  }

  private handleNewOptionClick = (value: string) => {
    const { entityType, filter, newOptionAssociationProps, onNewOptionClick } = this.props
    // if onNewOptionClick is provided, we will defer to the provided one
    if (onNewOptionClick) { return onNewOptionClick(value) }
    const entitySchema = this.store.getRecord(entityType)
    const recordTemplate = this.store.createRecord(entitySchema)
    const defaultValue = getNewEntityDefaultValue(recordTemplate, value)
    if (newOptionAssociationProps && filter && filter.value) {
      const entityEdge = filter.value
      this.createNewAssociateNewEntity(newOptionAssociationProps, entityEdge, defaultValue)
    } else {
      this.createNewEntity(entitySchema, defaultValue)
    }
  }

  private handleInfoButtonClick = () => {
    const { showLinkIcon } = this.props
    const { value } = this.state
    // TODO(Peter): revisit when doing react-router@4.0 update
    if (value.uniqueId && showLinkIcon) {
      window.open(`/entity/${value.uniqueId}`, '_blank')
    }
  }

  }

// TODO(Peter): we should generalize this and move this logic into the schemas
export function getNewEntityDefaultValue(entity, newOptionString) {
  const schemaIds = _.map(entity.schemas, 'id')
  if (_.includes(schemaIds, '/1.0/entities/metadata/business.json')) {
    return {
      business: { legalName: newOptionString },
    }
  } else if (_.includes(schemaIds, '/1.0/entities/metadata/location.json')) {
    return {
      location: { name: newOptionString },
    }
  } else if (_.includes(schemaIds, '/1.0/entities/metadata/person.json')) {
    const tokens = newOptionString.split(/\s+/)
    return {
      person: {
        firstName: tokens[0],
        lastName: tokens[1],
      },
    }
  }
}


function defaultEntityToEdgeCreator(entity, schema) {
  const properties = _.get(schema, 'metadata.denormalizedProperties')
  const denormalizedProperties = {}
  _.forEach(properties, (path) => {
    denormalizedProperties[path] = _.get(entity, path)
  })
  return {
    denormalizedProperties,
    displayName: entity.displayName,
    entityId: entity.uniqueId,
  }
}

function defaultEdgeToOptionCreator(edge, schema) {
  if (!edge) {
    return
  }
  const option = {
    displayName: edge.displayName,
    uniqueId: edge.entityId,
  }
  const properties = _.get(edge, 'denormalizedProperties')
  _.forEach(properties, (value, path) => _.set(option, path, value))
  return option
}

function defaultEntityToOptionCreator(entity, schema) {
  if (_.isNil(entity)) {
    return {}
  }
  return {
    displayName: entity.displayName,
    uniqueId: entity.uniqueId
  }
}

export default React.forwardRef((props: IEntityAssociationSelectProps, ref: React.Ref<EntityAssociationSelect>) => (
  <SheetContext.Consumer>
    {({ openOverlay }) => (
      <EntityAssociationSelect {...props} openOverlay={openOverlay} ref={ref} />
    )}
  </SheetContext.Consumer>
))
