import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { Entity } from 'shared-libs/models/entity'

import { Checkbox } from 'browser/components/atomic-elements/atoms/checkbox/checkbox'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
// tslint:disable-next-line:max-line-length
import { Thumbnail } from 'browser/components/atomic-elements/molecules/thumbnail/thumbnail'
import { isPrintable } from './utils'

interface IDocumentItemProps {
  entity: Entity
  isSelected: boolean
  onOpenPreview: (entity: Entity, setLoading: (loading: boolean) => void) => void
  onSelect: (entity: Entity) => void
  showDividers?: boolean
  showSelectBox?: boolean
  showDisplayName?: boolean
}

export const DocumentItem: React.FC<IDocumentItemProps> = (props) => {
  const { entity, isSelected, onOpenPreview, onSelect, showDisplayName, showDividers, showSelectBox } = props
  const creationDate = moment(entity.get('creationDate')).format('MMM D, YYYY, h:mm A')
  const createdBy = entity.get('createdBy.displayName')
  const [loading, setLoading] = useState(false)

  const filePath = isPrintable(entity) ?  'core_printable.output' :  'document.attachments.files.0'
  const file = entity.get(filePath)

  const handleOpenPreview = () => {
    if (!file) {
      // This assumes if a file is not found, we are working with a digitized document
      // Maybe in the future we can add a link to the digitized pdf (grant is working on this)
      const { origin } = window.location
      const documentsViewId = '11111111-0000-0000-0000-100000000000'
      const url = `${origin}/view/${documentsViewId}/entity/${entity.get('uniqueId')}`
      window.open(url, '_blank')
    } else {
      onOpenPreview(entity, setLoading)
    }
  }

  const handleSelect = () => {
    onSelect(entity)
  }

  let content = entity.entityType
  if (showDisplayName) {
    content = `${entity.displayName} - ${entity.entityType}`
  }

  const renderSelectBox = () => {
    if (showSelectBox) {
      return (
        <div className='mr4'>
          <Checkbox
            className='c-checkbox-box--flush c-checkbox-box--relative c-checkbox--flush'
            onClick={handleSelect}
            label=''
            value={isSelected}
          />
        </div>
      )
    }
  }

  const renderThumbnail = () => {
    if (file) {
      return (
        <Thumbnail
          onClick={handleOpenPreview}
          file={file}
          loading={loading}
        />
      )
    }
    return (
      <div className={classNames('c-thumbnail-container')}>
        <Icon
          className='c-thumbnail--pdf'
          icon={IconNames.DOCUMENT}
          size={40}
        />
      </div>
    )
  }

  const renderNameAndDescription = () => {
    return (
      <div className='ml3'>
        <a
          className={classNames('f5 mb1 db')}
          onClick={handleOpenPreview}
        >
          {content}
        </a>
        <HelpBlock className='collapse'>
          Uploaded by {createdBy} on {creationDate}.
            </HelpBlock>
      </div>
    )
  }

  return (
    <tr className={classNames({ 'bt b--light-gray': showDividers })} key={entity.get('uniqueId')}>
      <td className='c-table-cell c-table-cell--verticallyCenter'>
        <div className='flex items-center'>
          {renderSelectBox()}
          {renderThumbnail()}
          {renderNameAndDescription()}
        </div>
      </td>
    </tr>
  )
}

DocumentItem.defaultProps = {
  showDisplayName: false,
  showDividers: true,
  showSelectBox: true,
}
