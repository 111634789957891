import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'

interface IButtonGroupSelectProps {
  onChange: (value: any) => void
  options: object[]
  optionLabelPath: string
  optionValuePath: string
  value: any
}

export class ButtonGroupSelect extends React.Component<IButtonGroupSelectProps, any> {
  public static defaultProps: Partial<IButtonGroupSelectProps> = {
    optionLabelPath: 'label',
    optionValuePath: 'value',
  }

  constructor(props) {
    super(props)
  }

  public render() {
    const { options } = this.props

    return (
      <div className={Classes.BUTTON_GROUP}>
        { _.map(options, (o) => this.renderOption(o)) }
      </div>
    )
  }

  private selectOption(option) {
    const { optionValuePath, onChange } = this.props

    onChange(option[optionValuePath])
  }

  private renderOption(option) {
    const {
      optionLabelPath,
      optionValuePath,
      value,
    } = this.props

    const isCurrentSelection = (option[optionValuePath] == value)
    const label = option[optionLabelPath]

    return <Button
      onClick={ () => this.selectOption(option) }
      className={ isCurrentSelection ? Classes.ACTIVE : null }
      key={label}
    >
      {label}
    </Button>
  }
}
