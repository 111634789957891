import Promise from 'bluebird'
import _ from 'lodash'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import { BatchCreationProgressPopover } from 'browser/components/atomic-elements/organisms/entity/batch-creation-progress-popover'
import { EntityCreationProgressPopover } from 'browser/components/atomic-elements/organisms/entity/entity-creation-progress-popover'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { Store } from 'shared-libs/models/store'
import { EntityFormBlock } from './entity-form-block'
import { debouncePromise } from 'shared-libs/helpers/debounce-promise'
import { AJVSchemaValidator } from 'shared-libs/components/entity/ajv-validator'
import { ComponentsContext } from 'browser/contexts/components/components-context'
import ComponentsMap from 'browser/components'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { UIPropMappingContext } from 'shared-libs/components/context/ui-prop-mapping-context'
import { getPropMappingStrategy } from 'browser/contexts/prop-mapping/ui-prop-mapping'

interface IEntityFormPanelProps extends IBaseProps {
  defaultValue?: object
  entity?: Entity
  onClose: () => void
  uiContext?: object
  schema: any
  state?: object
  redirectPathPrefix: string
  settings: Settings
  shouldRedirectOnSuccess?: boolean
  uiSchemaPath: string
  onSave?: (entity) => void
  onPreSave?: (entity) => void
}

interface IEntityFormPanelStates {
  errors?: any
  isFormHidden: boolean
  toastKey: any
}

const DOCUMENT_CLASSIFICATION_BATCH_UPLOAD_ID = '6b5ae4aa-4888-499b-a5e1-632885818350'
const DOCUMENT_CLASSIFICATION_TASK_ID = '8797230e-c481-4df6-8368-97d7dfd9fc79'

export class EntityFormPanel extends React.Component<
  IEntityFormPanelProps,
  IEntityFormPanelStates
> {
  public static defaultProps: Partial<IEntityFormPanelProps> = {
    redirectPathPrefix: '',
    uiSchemaPath: 'uiSchema.web.entityCreationPanel',
  }

  public static open(props) {
    const { defaultValue, schema } = props
    const store = apis.getStore()
    const entity = store.createRecord(schema, defaultValue)

    OverlayManager.openOverlay(this, {
      entity,
      ...props,
    })
  }

  /**
   * Open an entity create panel and dynamically resolve any dependencies as well
   * The original 'open' function depends all the schemas to be in bundle
   */
  public static openAndResolveDependencies(props) {
    const { defaultValue, entityId } = props
    const store = apis.getStore()
    return store.createEntities([entityId], defaultValue).then((entities) => {
      const entity = _.first(entities)
      OverlayManager.openOverlay(this, {
        entity,
        ...props,
      })
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      errors: undefined,
      isFormHidden: false,
      toastKey: null,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isFormHidden, toastKey } = this.state
    if (toastKey && prevState.isFormHidden !== isFormHidden && isFormHidden === false) {
      Toast.dismiss(toastKey)
    }
  }

  public render() {
    const { defaultValue, entity, settings, state, uiSchemaPath, uiContext } = this.props
    const { errors, isFormHidden } = this.state

    // NOTE: Since EntityFormPanel renders in a separate react root, we need to
    // provide the components context in case we have any nested components that
    // need to consume it, like entity-preview. Assume web here, since the form
    // panel is a desktop-only feature.
    return (
      <div style={isFormHidden ? { display: 'none' } : {}}>
        <ComponentsContext.Provider
          value={{ components: ComponentsMap, platform: PlatformType.WEB }}
        >
          <UIPropMappingContext.Provider value={{ getPropMappingStrategy }}>
            <EntityFormBlock
              key="1"
              defaultValue={defaultValue}
              entity={entity}
              errors={errors}
              onClose={this.handleClose}
              onSave={this.handleSave}
              settings={settings}
              state={state}
              uiSchemaPath={uiSchemaPath}
              uiContext={uiContext}
            />
          </UIPropMappingContext.Provider>
        </ComponentsContext.Provider>
      </div>
    )
  }

  private handleClose = (entity) => {
    const { onClose } = this.props

    if (!entity.isDirty) {
      return onClose()
    }
    if (entity.hasMixin('d4748af6-dd36-47ec-b7ae-9ba318b0088d')) {
      return onClose()
    }

    ConfirmationModal.open({
      confirmationText: 'Are you sure you want to close this panel and discard your changes?',
      confirmationTitle: 'You have unsaved changes',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: this.handleSave,
      onSecondaryClicked: onClose,
      primaryButtonText: 'Save Changes',
      secondaryButtonText: 'Discard Changes',
    })
  }

  private runValidations = async (entity: Entity, customValidations: any) => {
    const customValidator = customValidations
      ? await AJVSchemaValidator.compile(customValidations)
      : null

    const errors = await entity.validate(true, customValidator)

    if (_.isEmpty(errors)) {
      return
    }

    this.setState({
      errors
    })

    throw new Error("Failed to validate entity before save")
  }

  private handleSave = (entity): Promise<any> => {
    const { onPreSave, uiSchemaPath } = this.props

    onPreSave && onPreSave(entity)

    const validationSchema = entity.resolveSubschemaByPath(uiSchemaPath)?.schema?.validationSchema

    return new Promise((resolve, reject) => {
      if (validationSchema) {
        this.runValidations(entity, validationSchema)
          .then(() => resolve(entity))
          .catch(reject)
      } else {
        resolve(entity)
      }
    }).then(() => {
      return this.handleSaveHelper(entity)
    })
  }

  private handleSaveHelper = (entity): Promise<any> => {
    const { onClose, onSave } = this.props
    // if entity does not have attachment, we will save immediately so we
    // 1. handle any server errors
    // 2. prevent embedded location input bug where parent entities are remove
    //    when component unmount before we can save it
    if (entity.hasAttachments()) {
      const handleSave = (onProgress) =>
        entity
          .save({ onProgress })
          .then((entity) => {
            onClose()
            onSave && onSave(entity)
            return Promise.resolve(entity)
          })
          .catch(({ errors, message }) => this.setState({ errors, isFormHidden: false }))
      const handleClose = () => {
        if (entity.hasMixin(DOCUMENT_CLASSIFICATION_BATCH_UPLOAD_ID)) {
          apis.getStore().emit(Store.RELOAD_COLLECTION, DOCUMENT_CLASSIFICATION_TASK_ID)
        }
      }

      if (entity.hasMixin('7ab5b7c6-e93d-4763-a84f-9173b732d1ed')) {
        this.showBatchProgressToast(entity, { handleSave })
      } else {
        this.showUploadProgressToast(entity, { handleSave, handleClose })
      }
      this.setState({ isFormHidden: true })
      return Promise.resolve(entity)
    }

    return this.debouncedSave(entity)
      .then(() => {
        const handleSave = () => Promise.resolve(entity)

        this.showUploadProgressToast(entity, { handleSave })
        onClose()
        onSave && onSave(entity)
        return Promise.resolve(entity)
      })
      .catch(({ errors, message }) => this.setState({ errors }))
  }

  // VD-3469 wait a short time to catch latent UI formula updates
  // Needed to apply the debounce to the save promise, so that the Button's
  // `isLoading` still works as expected.
  protected debouncedSave = debouncePromise((entity) => {
    return entity.save()
  }, 800)

  private showUploadProgressToast(entity, { handleSave, handleClose = _.noop }) {
    const { redirectPathPrefix, shouldRedirectOnSuccess } = this.props
    const proxyHandleClose = () => {
      Toast.dismiss(toastKey)
      handleClose()
    }
    const redirectPath = `${redirectPathPrefix}/entity/${entity.uniqueId}`
    const toastKey = Toast.show({
      message: (
        <EntityCreationProgressPopover
          entity={entity}
          onClose={proxyHandleClose}
          onSave={handleSave}
          redirectPath={redirectPath}
          shouldRedirectOnSuccess={shouldRedirectOnSuccess}
        />
      ),
      position: Position.BOTTOM_RIGHT,
      timeout: 0,
    })
    this.setState({ toastKey })
  }

  private showBatchProgressToast(entity, { handleSave, handleClose = _.noop }) {
    const { redirectPathPrefix, shouldRedirectOnSuccess } = this.props
    const proxyHandleClose = () => {
      Toast.dismiss(toastKey)
      handleClose()
    }
    const redirectPath = `${redirectPathPrefix}/view/81e06f29-9bc6-4743-bbd5-b09465f6c298/entity/${entity.uniqueId}`
    const toastKey = Toast.show({
      message: (
        <BatchCreationProgressPopover
          entity={entity}
          onClose={proxyHandleClose}
          onSave={handleSave}
          redirectPath={redirectPath}
          shouldRedirectOnSuccess={shouldRedirectOnSuccess}
        />
      ),
      position: Position.BOTTOM_RIGHT,
      timeout: 0,
    })
    this.setState({ toastKey })
  }
}
