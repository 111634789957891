import $ from 'jquery'
import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface ISheetManagerProps extends IBaseProps {
  onChange?: (sheets: any[]) => void
}

export interface ISheetManagerContext {
  closeOverlay: any
  openOverlay: any
  updateOverlay: any
}

export const SheetContext = React.createContext<ISheetManagerContext | null>({
  closeOverlay: () => { throw new Error('closeOverlay() not implemented') },
  openOverlay: () => { throw new Error('openOverlay() not implemented') },
  updateOverlay: () => { throw new Error('updateOverlay() not implemented') },
})

export class SheetManager extends React.Component<ISheetManagerProps, any> {

  constructor(props) {
    super(props)
    this.state = {
      maxSheetHeight: 0,
      sheets: [],
    }
  }

  public render() {
    const { maxSheetHeight, sheets } = this.state
    const sheetsElements = _.map(sheets, (element: any, index) => {
      return React.cloneElement(element, {
        key: index,
        maxHeight: maxSheetHeight,
      })
    })
    const { onChange, ...divProps } = this.props
    return (
      <SheetContext.Provider
        value={{
          closeOverlay: this.handleCloseOverlay,
          openOverlay: this.handleOpenOverlay,
          updateOverlay: this.handleUpdateOverlay,
        }}
      >
        <div
          {...divProps}
        >
          {this.props.children}
          {sheetsElements}
        </div>
      </SheetContext.Provider>
    )
  }

  private getHeight() {
    const $node = $(ReactDOM.findDOMNode(this))
    return $node.outerHeight()
  }

  private handleCloseOverlay = () => {
    const { onChange } = this.props
    const { sheets } = this.state
    sheets.pop()
    if (onChange) { onChange(sheets) }
    this.setState({ sheets })
  }

  private handleOpenOverlay = (overlayElement) => {
    const { onChange } = this.props
    const { sheets } = this.state
    sheets.push(overlayElement)
    const maxSheetHeight = this.getHeight() - 100
    this.setState({ maxSheetHeight, sheets })
    if (onChange) { onChange(sheets) }
    return sheets.length - 1
  }

  private handleUpdateOverlay = (sheetId, overlayElement) => {
    const { sheets } = this.state
    if (sheetId < sheets.length) {
      sheets[sheetId] = overlayElement
      this.setState({ sheets })
    }
  }
}
