import { Tag } from '@blueprintjs/core'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/table/cells/_table-order-progress-cell.scss'

/**
 * @uiComponent
 */
interface ITableOrderProgressCellProps extends IBaseProps {
  value: any
}

export const TableOrderProgressCell: React.FC<ITableOrderProgressCellProps> = (props) => {
  const { value } = props
  if (!value || !value.status) { return <div>&apos;&apos;</div> }
  const primaryStatus = value.status.primary
  const secondaryStatus = value.status.secondary
  let content = primaryStatus
  if (!_.isEmpty(secondaryStatus)) {
    content = secondaryStatus
  }

  const tagClassName = secondaryStatus ? `c-tag--${secondaryStatus.toLowerCase()}` : ''
  return (
    <Tag className={classNames('c-tag', tagClassName)}>
      {content}
    </Tag>
  )
}
