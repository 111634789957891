import _ from 'lodash'
import React from 'react'
import { List } from 'react-virtualized/dist/es/List'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'

export interface ISelectListProps extends IBaseProps {
  autoFocus?: boolean
  hasNext?: boolean
  isLoading?: boolean
  isSearching?: boolean
  onLoadMore?: (index: number) => void
  onChange?: (any) => void
  onSearch?: (any) => void
  options: any[]
  optionLabelPath?: string
  optionValuePath?: string
  optionRenderer?: (props: any) => React.ReactElement<any>
  maxHeight?: number
  rowHeight?: number | ((props: any) => number)
  scrollToIndex?: number
  searchQuery?: string
  showSearchInput?: boolean
  searchInputHelpText?: string
  selection?: any
  width?: number
}

// NOTE: scrollToIndex and stopIndex are both necessary to fix this bug
// https://app.asana.com/0/167876960125712/482167559551306
interface ISelectListState {
  scrollToIndex: number
  stopIndex: number
}

export class SelectList extends React.Component<ISelectListProps, ISelectListState> {
  // Height and width should be kept in sync with .c-multiSelectFilter-list
  public static defaultProps: Partial<ISelectListProps> = {
    autoFocus: true,
    maxHeight: 300,
    rowHeight: 37,
    width: 300,
  }

  private list: any

  constructor(props) {
    super(props)
    this.state = {
      scrollToIndex: props.scrollToIndex,
      stopIndex: 0,
    }
    this.handleRowsRendered = _.debounce(this.handleRowsRendered, 200)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.scrollToIndex !== nextProps.scrollToIndex) {
      this.setState({ scrollToIndex: nextProps.scrollToIndex })
    }
  }

  public recomputeRowHeights(index) {
    this.list.recomputeRowHeights(index)
  }

  public forceUpdateList() {
    this.list && this.list.forceUpdateGrid()
  }

  public render() {
    return (
      <div>
        {this.renderSearchInput()}
        <div className='c-multiSelectFilter-list'>
          {this.renderOptionsList()}
        </div>
      </div>
    )
  }

  private getIsOptionSelectable(option, index) {
    // we want to return true by default
    return option.isSelectable !== false
  }

  private getNextSelectableIndex(scrollToIndex) {
    const { options } = this.props
    const numOptions = options.length
    return Math.min(scrollToIndex + 1, numOptions)
  }

  private getPreviousSelectableIndex(scrollToIndex) {
    return Math.max(scrollToIndex - 1, 0)
  }

  private handleKeyDown = (event) => {
    const { options } = this.props
    const { scrollToIndex, stopIndex } = this.state
    if (!options || options.length === 0) {
      return
    }
    const currentIndex = scrollToIndex || stopIndex
    if (event.keyCode === 38) {
      const nextIndex = this.getPreviousSelectableIndex(currentIndex)
      this.setState({ scrollToIndex: nextIndex })
    } else if (event.keyCode === 40) {
      const nextIndex = this.getNextSelectableIndex(currentIndex)
      this.setState({ scrollToIndex: nextIndex })
    }
  }

  private handleRenderOption = ({ index, key, style }): React.ReactElement<any> => {
    const { onLoadMore, onChange, options, optionLabelPath, optionRenderer } = this.props
    const { scrollToIndex } = this.state
    if (index >= options.length) {
      onLoadMore(index)
      this.setState({ scrollToIndex: index })
      return this.renderLoadMoreSpinner({ key, style })
    }
    const option = options[index]
    const isHighlighted = index === scrollToIndex
    if (optionRenderer) {
      const props = { index, isHighlighted, key, onChange, option, style }
      return optionRenderer(props)
    }
    const label = _.get(option, optionLabelPath)
    return (
      <div
        className='c-multiSelectFilter-item'
        key={key}
        onClick={() => onChange(option)}
      >
        {label}
      </div>
    )
  }

  private handleRowsRendered = ({ stopIndex }) => {
    this.setState({ stopIndex })
  }

  private renderLoadMoreSpinner({ key, style }) {
    return (
      <div
        className='c-multiSelectFilter-item'
        key={key}
        style={style}
      >
        <LoadingSpinner size='xs' />
      </div>
    )
  }

  private getListHeight() {
    const { maxHeight, options, rowHeight } = this.props
    const rowCount = options.length
    let totalHeight = 0
    if (_.isFunction(rowHeight)) {
      _.each(options, (option, index) => {
        totalHeight += rowHeight({ index })
      })
    } else {
      totalHeight = rowCount * rowHeight
    }
    return totalHeight > maxHeight ? maxHeight : totalHeight
  }

  private renderOptionsList() {
    const {
      hasNext,
      isLoading,
      maxHeight,
      options,
      rowHeight,
      width,
    } = this.props
    const { scrollToIndex } = this.state
    if (isLoading) {
      return (
        <div className='c-multiSelectFilter-loading'>
          <LoadingSpinner
            size='xs'
          />
        </div>
      )
    }

    if (_.isEmpty(options)) {
      return (
        <HelpBlock className='u-textCenter u-bumper'>
          No results found
        </HelpBlock>
      )
    }

    const rowCount = options.length
    // Max height of the .c-multiSelectFilter-list
    return (
      <List
        height={this.getListHeight()}
        onRowsRendered={this.handleRowsRendered}
        overscanRowCount={3}
        ref={(ref) => { this.list = ref }}
        rowCount={hasNext ? rowCount + 1 : rowCount}
        rowHeight={rowHeight}
        rowRenderer={this.handleRenderOption}
        scrollToIndex={scrollToIndex}
        width={width}
      />
    )
  }

  private renderSearchInput() {
    const {
      autoFocus,
      isSearching,
      onSearch,
      searchQuery,
      showSearchInput,
      searchInputHelpText,
    } = this.props
    if (!showSearchInput) {
      return
    }
    return (
      <div className='c-multiSelectFilter-search'>
        <FormTable>
          <InputField
            autoFocus={autoFocus}
            helpText={searchInputHelpText}
            isLoading={isSearching}
            onChange={onSearch}
            onKeyDown={this.handleKeyDown}
            placeholder='Search'
            value={searchQuery}
          />
        </FormTable>
      </div>
    )
  }
}
