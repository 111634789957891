import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { findDOMNode } from 'react-dom'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import { InlineControls } from './inline-controls'

export interface IFocusableInput {
  focus: () => void
}

export interface InlineEditableInputProps {
  inlineEditControls?: React.ReactElement<any>
  inlineEditSheet?: React.ReactElement<any>
  isEditableInline?: boolean
  isDisabled?: boolean
  isStatic?: boolean
  onChange?: (value: any) => void
  value?: any
}

export interface InlineEditableProps extends IBaseProps {
  inlineEditSheet?: React.ReactElement<any>
  isDisabled?: boolean
  isStatic: boolean
  onChange: (value: any) => void
  onDelete?: () => void
  onSave: () => void
  shouldDeleteOnCancel?: boolean
  size: string
  validate: (value: any) => void
  value: any
}

interface InlineEditableStates {
  errors: any
  isEditing: boolean
  value?: any
}

export class InlineEditable extends React.Component<InlineEditableProps, InlineEditableStates> {

  private input: any

  constructor(props) {
    super(props)
    this.state = {
      errors: null,
      isEditing: false,
    }
  }

  public componentWillUnmount() {
    this.removeDocumentMouseClickHandler()
  }

  public render() {
    const { inlineEditSheet } = this.props
    // inputElement is always static if we use inlineEditSheet,
    // otherwise it is static when it !isEditing
    const isEditingInput = !inlineEditSheet && this.state.isEditing
    const isStatic = !isEditingInput
    const child = React.Children.only(this.props.children)
    const childClassName = classNames('c-inlineEditable-container', child.props.className, {
      'c-inlineEditable-container--isStatic': isStatic,
    })
    // we need to proxy the original events over
    return React.cloneElement(child, {
      className: childClassName,
      inlineEditControls: this.renderInlineEditControls(isStatic),
      inlineEditSheet: this.renderinlineEditSheet(),
      isStatic,
      onChange: this.handleChange,
      onKeyDown: this.handleKeyDown,
      ref: (ref) => this.input = ref,
      value: isEditingInput ? this.state.value : this.props.value,
    })
  }

  private setupDocumentMouseClickHandler() {
    // it is important to attach to data-reactroot div instead of body, otherwise
    // handleOutsideMouseClick will be trigger while ConfirmationModal is
    // showing
    const app = document.querySelector('[data-reactroot]')
    app.addEventListener('click', this.handleOutsideMouseClick)
  }

  private removeDocumentMouseClickHandler() {
    const app = document.querySelector('[data-reactroot]')
    app.removeEventListener('click', this.handleOutsideMouseClick)
  }

  private startEditing() {
    const { inlineEditSheet, value } = this.props
    const handleFocus = () => {
      if (!inlineEditSheet) { this.input.focus() }
    }
    // NOTE: we have wait for setState to finish so that input goes out of
    // disabled state before calling focus on it
    this.setState({
      isEditing: true,
      value: _.cloneDeep(value),
    }, handleFocus)
    if (!inlineEditSheet) {
      this.setupDocumentMouseClickHandler()
    }
  }

  private endEditing() {
    this.removeDocumentMouseClickHandler()
    this.setState({
      errors: null,
      isEditing: false,
    })
  }

  private renderInlineEditControls(isStatic) {
    const { isDisabled, onDelete, size } = this.props
    return (
      <InlineControls
        isDisabled={isDisabled}
        isStatic={isStatic}
        onCancel={this.handleCancel}
        onDelete={onDelete}
        onEdit={this.handleEdit}
        onSave={this.handleSave}
        size={size}
      />
    )
  }

  private renderinlineEditSheet() {
    const { inlineEditSheet } = this.props
    if (!inlineEditSheet) {
      return null
    }
    const { errors, isEditing, value } = this.state
    return React.cloneElement(inlineEditSheet, {
      errors,
      errorsMap: errors,
      isHorizontalLayout: true,
      isOpen: isEditing,
      isStatic: false,
      onCancel: this.handleCancel,
      onChange: this.handleChange,
      onSave: this.handleSave,
      value,
    })
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleCancel = () => {
    if (_.isEqual(this.state.value, this.props.value)) {
      return this.handleRollback()
    }
    ConfirmationModal.open({
      confirmationText: <span>You have made changes.<br />Do you want to save or discard them?</span>,
      confirmationTitle: 'Review Changes',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: this.handleSave,
      onSecondaryClicked: this.handleRollback,
      primaryButtonText: 'Save',
      secondaryButtonText: 'Discard',
    })
  }

  private handleChange = (value) => {
    this.setState({ value })
  }

  private handleEdit = () => {
    this.startEditing()
  }

  private handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      // NOTE: we need to stop propagation otherwise modal will close
      // immediately after it opens
      event.stopPropagation()
      this.handleCancel()
      // send a blur event to make sure that input is blurred
      if (this.input.blur) {
        this.input.blur()
      }
    }
  }

  private handleOutsideMouseClick = (event) => {
    const root = findDOMNode(this.input)
    const isClickInsideInput = root.contains(event.target)
    if (!isClickInsideInput) {
      // Both prevent default and stop propagation are needed so user cannot
      // navigate away
      event.preventDefault()
      event.stopPropagation()
      this.handleCancel()
    }
  }

  private handleRollback = () => {
    const { onDelete, shouldDeleteOnCancel, value } = this.props
    if (shouldDeleteOnCancel) {
      onDelete()
    } else {
      this.setState({ value: _.cloneDeep(value) })
    }
    this.endEditing()
  }

  private handleSave = async () => {
    const { onChange, onSave, validate } = this.props
    const { value } = this.state
    const errors = await validate(value)
    if (_.isEmpty(errors)) {
      this.endEditing()
      onChange(value)
      return onSave()
    }
    this.setState({ errors })
    return Promise.reject(errors)
  }
}
