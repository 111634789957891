import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/organisms/top-navigation-bar/_top-navigation-bar.scss'

/**
 * @uiComponent
 */
interface ITopNavigationBarProps extends IBaseProps {
  toggleElement?: React.ReactElement<any>
}

export class TopNavigationBar extends React.Component<ITopNavigationBarProps, any> {
  public render() {
    const {
      className,
      children,
      toggleElement,
    } = this.props
    return (
      <div
        className={classNames('c-topNavigationBar', className)}
        data-debug-id='navbar'
      >
        {toggleElement}
        {children}
      </div>
    )
  }
}
