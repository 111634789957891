import React from 'react'
import _ from 'lodash'
import queryString from 'query-string'

import apis from 'browser/app/models/apis'
import { browserHistory } from 'browser/history'
import { TruckLoader } from '../components/truck-loader/truck-loader'
import { getEntityIds, normalizeUrlQuery, maybeResolveContainerId } from 'shared-libs/helpers/deeplink'

interface DeepLinkProps {
  location?: Location
}

export class DeepLink extends React.Component<DeepLinkProps> {
  public componentDidMount(): void {
    const url = this.props.location.href
    const search = queryString.parse(this.props.location.search)
    const props = normalizeUrlQuery(search)
    const entityIds = getEntityIds(props)

    this.handleDocumentAdd(entityIds, url, props)
  }

  public render(): JSX.Element {
    return <TruckLoader />
  }

  private handleDocumentAdd = async (entityIds: string[], url: string, props: any = {}) => {
    entityIds = await maybeResolveContainerId(entityIds, apis)

    this.createEntity(entityIds, props)
  }

  private createEntity = async (entityIds: string[], props: any = {}) => {
    console.log(this.props.location?.search)
    const search = queryString.parse(this.props.location?.search)
    const { storyId } = props

    const entities = await apis.getStore().createEntities(entityIds, props)
    const entity = _.head(entities)
    await entity.save()

    const searchParams = {
      ...search,
      jsonProps: undefined,
    }
    if (storyId) {
      searchParams.story_id = storyId
    }
    browserHistory.push({
      pathname: `/entity/${entity.uniqueId}`,
      search: queryString.stringify(searchParams),
    })
  }
}
