import { EventEmitter as FacebookEventEmitter } from 'fbemitter'

export interface IEventEmitter {
  addListener(eventType: any, callback: any): any
  emit(eventType: any, ...args: any[]): any
}

export class EventEmitter implements IEventEmitter {

  public eventEmitter: any = new FacebookEventEmitter()

  public addListener(eventType, callback) {
    return this.eventEmitter.addListener(eventType, callback)
  }

  public emit(eventType, ...args) {
    return this.eventEmitter.emit(eventType, ...args)
  }
}
