import _ from 'lodash'
import apis from 'browser/app/models/apis'
import { Query } from 'shared-libs/models/query'
import { Entity } from 'shared-libs/models/entity'
import { SchemaUris } from 'shared-libs/models/schema'
import { Collection } from 'shared-libs/models/collection'

export const DEFAULT_ORDERS = [
  {
    path: 'creationDate',
    type: 'descending',
  },
]

export async function queryActiveExecution(planId?: string): Promise<Entity | undefined> {
  const userId = apis.getSettings().getUser().uniqueId
  const filters = [
    {
      type: 'matchEdge',
      path: 'createdBy',
      value: { entityId: userId },
    },
    {
      type: 'match',
      path: 'core_storyboard_execution.status',
      values: ['Active', 'Pending'],
    },
    {
      type: 'match',
      path: 'core_storyboard_execution.settings.resumeOnActive',
      value: true,
    },
  ]

  if (planId) {
    filters.push({
      type: 'match',
      path: 'core_storyboard_execution.plan',
      value: { entityId: planId },
    })
  }

  const query = new Query(apis)
    .setEntityType(SchemaUris.STORYBOARD_EXECUTION)
    .setOrders(DEFAULT_ORDERS)
    .setFilters(filters)
  this.collection = new Collection(apis, query)

  const executions = await this.collection.find()

  return _.chain(executions)
    .filter('core_storyboard_execution.settings.resumeOnActive')
    .filter((execution) => {
      const stories = _.get(execution, 'core_storyboard_execution.storiesForCurrUser')
      return _.find(stories, (story) => _.get(story, 'settings.resumeOnActive', true))
    })
    .first()
    .value()
}
