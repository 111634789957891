import _ from 'lodash'

export function countBy(...params: any[]): any {
  return _.countBy.apply(null, params)
}

export function every(...params: any[]): boolean {
  return _.every.apply(null, params)
}

export function filter(...params: any[]): any[] {
  return _.filter.apply(null, params)
}

export function find(...params: any[]): any | undefined {
  return _.find.apply(null, params)
}

export function findLast(...params: any[]): any | undefined {
  return _.findLast.apply(null, params)
}

export function flatMap(...params: any[]): any[] {
  return _.flatMap.apply(null, params)
}

export function groupBy(...params: any[]): any[] {
  return _.groupBy.apply(null, params)
}

export function includes(collection: any, target: any, fromIdx: number): boolean {
  return _.includes(collection, target, fromIdx)
}

export function keyBy(...params: any[]): any[] {
  return _.keyBy.apply(null, params)
}

export function map(...params: any[]): any[] {
  return _.map.apply(null, params)
}

export function orderBy(...params: any[]): any[] {
  return _.orderBy.apply(null, params)
}

export function partition(...params: any[]): any[] {
  return _.partition.apply(null, params)
}

export function reject(...params: any[]): any[] {
  return _.reject.apply(null, params)
}

export function sample(collection: any): any {
  return _.sample(collection)
}

export function sampleSize(...params: any[]): any[] {
  return _.sampleSize.apply(null, params)
}

export function shuffle(...params: any[]): any[] {
  return _.shuffle.apply(null, params)
}

export function size(collection: any) {
  return _.size(collection)
}

export function some(...params: any[]): any[] {
  return _.some.apply(null, params)
}

export function sortBy(...params: any[]): any[] {
  return _.sortBy.apply(null, params)
}
