import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'

import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { QuantityInput } from 'browser/components/atomic-elements/atoms/input/quantity-input'
import { Select } from 'browser/components/atomic-elements/atoms/select'
import { default as EntitySelect } from 'browser/components/atomic-elements/atoms/select/entity-select'
import { HtmlTableRow, IHtmlTableRowProps } from 'browser/components/atomic-elements/atoms/table/html-table/row'
import { Textarea } from 'browser/components/atomic-elements/atoms/textarea/textarea'

/**
 * @uiComponent
 */
interface IOrderAccountingItemRowProps extends IHtmlTableRowProps {
  isList?: boolean
  accountItemType: string
  defaultItemType?: string
  entity: any
  errorsMap?: any
  frames: any
  index: number
  onChange: (value: any) => void
  value: any
}

interface IOrderAccountingItemRowState {
  accountingItems: any[]
  itemType: any
  ratingMethodOptions: any[]
}

const FLAT_RATE = 'Flat'
const RATE_PER_UNIT = 'Rate per Unit'
const PERCENT_OF_FREIGHT_CHARGE = '% of Freight Charge'
const FREIGHT_CHARGE = 'Freight Charge'

export class OrderAccountingItemRow
  extends React.Component<IOrderAccountingItemRowProps, IOrderAccountingItemRowState> {

  private accountItemSelect: any
  private htmlTableRow: HtmlTableRow
  private store: any

  constructor(props) {
    super(props)
    const { entity, frames } = props

    // brokerOrder, revenueItems, <itemIndex>
    const valuePath = frames.getContext('valuePath')
    const accountingItemPath = valuePath.slice(0, 2)
    const accountingItems = entity.get(accountingItemPath, [])

    this.store = apis.getStore()
    this.state = {
      accountingItems,
      itemType: null,
      ratingMethodOptions: [],
    }
  }

  public componentDidMount() {
    const { defaultItemType, index } = this.props
    const value = this.getValue()
    if (value.itemType) {
      const entityId = value.itemType.entityId
      this.store.findRecord(entityId).then((entity) => {
        this.setState({
          itemType: entity,
          ratingMethodOptions: this.getRatingMethodOptions(entity),
        })
      })
    } else if (defaultItemType && index === 0) {
      // TODO(Peter): it is not good to have to load defaultItemType everytime
      const collection = this.accountItemSelect.collection
      collection.query.setQuery(defaultItemType)
      collection.find().then((entities) => {
        const entity: any = _.first(entities)
        if (entity) {
          this.accountItemSelect.handleChange(entity.uniqueId, entity)
        }
      })
    }
  }

  public focus() {
    this.htmlTableRow.focus()
  }

  public render() {
    const value = this.getValue()
    const hasItemType = value.itemType
    const isFlatRate = value.ratingMethod === FLAT_RATE
    const isEditableRow = _.get(value, 'accountingEntity', false)
    return (
      <HtmlTableRow
        {...this.props as IHtmlTableRowProps}
        ref={this.handleRef}
        showItemDeleteButton={!isEditableRow}
      >
        {this.renderItemTypeSelect(value, isEditableRow)}
        {this.renderDescription(value, hasItemType, isEditableRow)}
        {this.renderRatingMethod(value, hasItemType, isEditableRow)}
        {this.renderQuantity(value, hasItemType, isFlatRate, isEditableRow)}
        {this.renderRatePerUnit(value, hasItemType, isFlatRate, isEditableRow)}
        {this.renderAmount(value, hasItemType, isFlatRate, isEditableRow)}
      </HtmlTableRow>
    )
  }

  /////////////////////////////////////////////////////////////////////////////
  // Renderers
  /////////////////////////////////////////////////////////////////////////////

  private getErrorText(paths): string {
    const { errorsMap } = this.props
    for (let i = 0; i < paths.length; ++i) {
      const errorText = _.get(errorsMap, [paths[i], '_errors', 0], null)
      if (errorText) {
        return errorText
      }
    }
  }

  private renderItemTypeSelect(item, isEditableRow) {
    const { accountItemType, size } = this.props
    const errorText = this.getErrorText(['itemType'])
    const filter = {
      path: 'financialAccountItemType.types',
      type: 'contains',
      value: accountItemType,
    }
    return (
      <EntitySelect
        entityType='/1.0/entities/metadata/financialAccountItemType.json'
        errorText={errorText}
        filter={filter}
        isCreatable={false}
        isDisabled={isEditableRow}
        showLinkIcon={false}
        onChange={this.handleItemTypeChange}
        ref={(ref) => { this.accountItemSelect = ref }}
        size={size}
        value={item.itemType}
      />
    )
  }

  private renderDescription(item, hasItemType, isEditableRow) {
    const errorText = this.getErrorText(['description'])
    const isDisabled = !hasItemType
    return (
      <Textarea
        errorText={isDisabled ? '' : errorText}
        minRows={1}
        isDisabled={isDisabled || isEditableRow}
        size={this.props.size}
        onChange={(value) => this.handlePropertyChange('description', value)}
        value={item.description}
      />
    )
  }

  private renderRatingMethod(item, hasItemType, isEditableRow) {
    const { size } = this.props
    const { ratingMethodOptions } = this.state
    const isDisabled = !hasItemType || ratingMethodOptions.length === 1
    const errorText = this.getErrorText(['ratingMethod'])
    return (
      <Select
        errorText={isDisabled ? '' : errorText}
        size={size}
        isDisabled={isDisabled || isEditableRow}
        isNative={true}
        onChange={this.handleRatingMethodsChange}
        options={ratingMethodOptions}
        value={item.ratingMethod}
      />
    )
  }

  private renderQuantity(item, hasItemType, isFlatRate, isEditableRow) {
    const { errorsMap, size } = this.props
    const isRatePerUnit = item.ratingMethod === RATE_PER_UNIT
    const isDisabled = !hasItemType || !isRatePerUnit
    const quantity = _.get(item, 'quantity')
    const unitOptions = []
    if (_.get(quantity, 'unit')) {
      const unit = _.get(quantity, 'unit')
      unitOptions.push({ label: unit, value: unit })
    }
    return (
      <QuantityInput
        errorsMap={isDisabled ? '' : _.get(errorsMap, 'quantity')}
        isDisabled={isDisabled || isEditableRow}
        onChange={(value) => this.handlePropertyChange('quantity', value)}
        unitOptions={unitOptions}
        value={quantity}
      />
    )
  }

  private renderRatePerUnit(item, hasItemType, isFlatRate, isEditableRow) {
    const { size } = this.props
    const errorText = this.getErrorText(['ratePerUnit'])
    const isDisabled = !hasItemType || isFlatRate
    const value = item.ratePerUnit
    return (
      <Input
        errorText={isDisabled ? '' : errorText}
        isDisabled={isDisabled || isEditableRow}
        inputClassName='tr'
        size={size}
        type='number'
        onChange={(value) => this.handlePropertyChange('ratePerUnit', value)}
        value={value}
      />
    )
  }

  private renderAmount(item, hasItemType, isFlatRate, isEditableRow) {
    const { errorsMap, size } = this.props
    const isDisabled = !hasItemType || !isFlatRate
    return (
      <QuantityInput
        errorsMap={isDisabled ? '' : _.get(errorsMap, 'amount')}
        isDisabled={isDisabled || isEditableRow}
        onChange={(value) => this.handlePropertyChange('amount', value)}
        showUnitSelect={false}
        size={size}
        type='number'
        value={_.get(item, 'amount')}
      />
    )
  }

  /////////////////////////////////////////////////////////////////////////////
  // Helpers
  /////////////////////////////////////////////////////////////////////////////

  private getValue() {
    return this.props.value || {}
  }

  private updateItemAmountBaseOnFrieghtCharge(freightChargeItem, item) {
    const { ratePerUnit } = item
    const freightCharge: number = _.get(freightChargeItem, 'amount.value', 0)
    const amount = freightCharge * ratePerUnit / 100
    item.amount = { value: _.isNaN(amount) ? undefined : amount }
  }

  private updateItemAmount(item) {
    const { accountingItems } = this.state
    const { ratingMethod, ratePerUnit, quantity } = item
    if (ratingMethod === RATE_PER_UNIT) {
      const amount = ratePerUnit * _.get(quantity, 'value', 0)
      item.amount = { value: _.isNaN(amount) ? undefined : amount }
    } else if (ratingMethod === PERCENT_OF_FREIGHT_CHARGE) {
      // update item's amount base of freight charge
      const freightChargeItem = _.find(accountingItems, (accountingItem) => {
        return _.get(accountingItem, 'itemType.displayName') === FREIGHT_CHARGE
      })
      this.updateItemAmountBaseOnFrieghtCharge(freightChargeItem, item)
    }
    // if current item is Friehgt Charge, update all items dependent on it
    if (item.itemType && item.itemType.displayName === FREIGHT_CHARGE) {
      _.forEach(accountingItems, (accountingItem) => {
        if (accountingItem.ratingMethod !== PERCENT_OF_FREIGHT_CHARGE) {
          return
        }
        this.updateItemAmountBaseOnFrieghtCharge(item, accountingItem)
      })
    }
  }

  private updateItemRatingMethod(item, itemType, ratingMethod) {
    const { defaultAmount, defaultRatePerUnit, unit } = itemType.financialAccountItemType
    delete item.quantity
    if (ratingMethod === FLAT_RATE) {
      delete item.ratePerUnit
      item.amount = defaultAmount
    } else if (ratingMethod === RATE_PER_UNIT) {
      delete item.amount
      item.ratePerUnit = defaultRatePerUnit
      item.quantity = { unit }
    } else {
      delete item.amount
      item.ratePerUnit = defaultRatePerUnit
    }
    item.ratingMethod = ratingMethod
  }

  private updateItemsAmount() {
    const { accountingItems } = this.state
    _.forEach(accountingItems, (accountingItem) => this.updateItemAmount(accountingItem))
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleRef = (ref) => {
    this.htmlTableRow = ref
  }

  private getRatingMethodOptions(financialAccountItemType) {
    const { ratingMethods } = financialAccountItemType.financialAccountItemType
    return _.map(ratingMethods, (method) => {
      return { label: method, value: method }
    })
  }

  private handleItemTypeChange = (edge, itemType) => {
    const { onChange } = this.props
    const value = this.getValue()
    const { unit, ratingMethods } = itemType.financialAccountItemType
    value.itemType = edge
    this.updateItemRatingMethod(value, itemType, _.first(ratingMethods))
    // update all item's amount. This is important e.g. if we change row from
    // freight charge to other types
    this.updateItemsAmount()
    this.setState({
      itemType,
      ratingMethodOptions: this.getRatingMethodOptions(itemType),
    })
    onChange(value)
  }

  private handleRatingMethodsChange = (ratingMethod) => {
    const { itemType } = this.state
    const { onChange } = this.props
    const value = this.getValue()
    this.updateItemRatingMethod(value, itemType, ratingMethod)
    this.updateItemAmount(value)
    onChange(value)
  }

  private handlePropertyChange = (key, propertyValue) => {
    const { onChange } = this.props
    const value = this.getValue()
    _.set(value, key, propertyValue)
    this.updateItemAmount(value)
    onChange(value)
  }
}
