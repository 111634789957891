import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { SheetManager } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'

export const AppBody: React.FC<IBaseProps> = (props) => {
  const { className, children } = props
  return (
    <SheetManager
      className={classNames('grid-block c-appBody', className)}
      data-debug-id={props['data-debug-id']}
    >
      {children}
    </SheetManager>
  )
}
