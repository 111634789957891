import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { default as SheetPortal } from 'browser/components/atomic-elements/atoms/sheet/sheet-portal'
import {
  EmbeddedLocationSheetInput,
  IEmbeddedLocationSheetInputProps,
} from 'browser/components/atomic-elements/molecules/fields/embedded-location-field/embedded-location-sheet-input'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'

/**
 * @uiComponent
 */
export interface IFieldSheetProps extends IEmbeddedLocationSheetInputProps {
  entity: Entity
  isOpen: boolean
  isLabelEditable?: boolean
  isLoading: boolean
  labelProps?: any
  onCancel: () => void
  onChange: (...value) => any
  onDelete: () => void
  onSave: () => Promise<any>
  value?: any
}

interface IFieldSheetState {
  isDeleting: boolean
  isNew: boolean
  isSaving: boolean
  locationEntity: Entity
  value: any
}

const LOCATION_SCHEMA_URI = '/1.0/entities/metadata/location.json'

// TODO(Peter): generalize this for other than embedded location field
export class EmbeddedLocationFieldSheet extends React.Component<IFieldSheetProps, IFieldSheetState> {

  private store: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = this.getNextState(props, true)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    // we want to reset state when we open or close
    if (this.props.isOpen !== nextProps.isOpen) {
      this.setState(this.getNextState(nextProps))
    }
  }

  public shouldComponentUpdate(nextProps, nextState) {
    // Fixes Embedded location field sheet opens really slowly in some screen
    // e.g. http://localhost:4200/settings/view/59722964-a05b-403e-a268-fed26595a788
    return this.props.isOpen !== nextProps.isOpen ||
        this.state.value !== nextState.value ||
        this.state.isDeleting !== nextState.isDeleting ||
        this.state.isNew !== nextState.isNew ||
        this.state.isSaving !== nextState.isSaving
  }

  public render() {
    const { isOpen, labelProps } = this.props
    const { locationEntity, value } = this.state
    return (
      <SheetPortal
        footer={this.renderSheetFooter()}
        isOpen={isOpen}
        size='sm'
      >
        <div className='c-sheet-body--padded'>
          <FormTable>
            <SelectField
              {...labelProps}
              isNative={true}
              isHorizontalLayout={true}
              label='Location Type'
              onChange={this.handleLabelChanged}
              value={value.label}
            />
            <EmbeddedLocationSheetInput
              {...this.props}
              location={locationEntity}
              onChange={this.handleValueChange}
            />
          </FormTable>
        </div>
      </SheetPortal>
    )
  }

  private renderSheetFooter() {
    const { onCancel } = this.props
    const { isDeleting, isSaving } = this.state
    return (
      <Footer
        isCancelButtonDisabled={isDeleting || isSaving}
        isPrimaryButtonDisabled={isDeleting}
        isPrimaryButtonLoading={isSaving}
        isVisible={true}
        onCancelButtonClick={onCancel}
        onPrimaryButtonClick={this.handleSave}
        cancelButtonGroupElement={this.renderCancelButtonGroup()}
      />
    )
  }

  private renderCancelButtonGroup = () => {
    const { isDeleting, isNew, isSaving } = this.state
    if (isNew) { return }
    return (
      <div className='c-footer-item'>
        <Button
          className={classNames(Classes.INTENT_DANGER)}
          isDisabled={isSaving}
          isLoading={isDeleting}
          onClick={this.handleDeleteLocation}
        >
          Delete
        </Button>
      </div>
    )
  }

  private getNextState(props, initialCall = false): any {
    const { entity, isOpen, labelProps, value } = props
    // https://app.asana.com/0/167876960125712/346093892201233
    const initialValue = value ? value : {
      label: _.get(labelProps, 'default'),
    }
    const locationEntityId = _.get(value, 'value.entityId')
    let locationEntity
    if (locationEntityId) {
      // location entity is either new and is a parent record or it is
      // already cached in the store
      locationEntity = entity.getParentRecord(locationEntityId) ||
          this.store.getRecord(locationEntityId)
      if (initialCall && locationEntity.get('location.name') === undefined) {
        locationEntity.set('location.name', 'Embedded Address')
      }
    } else {
      const locationSchema = this.store.getRecord(LOCATION_SCHEMA_URI)
      locationEntity = this.store.createRecord(locationSchema)
      // NOTE: in the back end the EmbeddedAddressTrigger will rename this
      locationEntity.set('location.name', 'Embedded Address')
    }
    return {
      isDeleting: false,
      isNew: !locationEntityId,
      isSaving: false,
      locationEntity,
      value: initialValue,
    }
  }

  private handleDeleteLocation = () => {
    const { entity, onDelete, onSave } = this.props
    const { locationEntity } = this.state
    const uniqueId = locationEntity.get('uniqueId')
    entity.removeParentRecord(uniqueId)

    this.setState({ isDeleting: true })
    onDelete()
    onSave().then(() => {
      this.setState({ isDeleting: false })
      return locationEntity.delete()
    })
  }

  private handleSave = () => {
    const { entity, onChange, onSave } = this.props
    const { locationEntity, value } = this.state

    this.setState({ isSaving: true })
    locationEntity.save().then(() => {
      this.setState({ isSaving: false })
      onChange(value)
      onSave()
    })
  }

  private handleLabelChanged = (label) => {
    const { value } = this.state
    value.label = label
    this.setState({ value: _.clone(value) })
  }

  private handleValueChange = (newValue) => {
    const { value } = this.state
    value.value = newValue
    this.setState({ value: _.clone(value) })
  }
}
