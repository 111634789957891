import _ from 'lodash'
import React, { useState } from 'react'

import {
  IThumbnailListProps,
  ThumbnailList,
} from 'browser/components/atomic-elements/molecules/thumbnail/thumbnail-list'
import { ImageEditorModal } from 'browser/components/atomic-elements/organisms/image-editor-modal'

const FILE_SCHEMA = '/1.0/entities/metadata/entity.json#/definitions/file'

/**
 * @uiComponent
 */
interface ITableFilesCellProps extends IThumbnailListProps {
  row: any
  schema: any
  value: any
  valuePath: string
}
const PREVIEWS_PAGE_LIMIT = 25

export const TableFilesCell: React.FC<ITableFilesCellProps> = (props) => {
  const { maxNumber, row, schema, showOriginal, value, valuePath } = props
  const [counter, setCounter] = useState<number>(0)

  const customForceUpdate = () => {
    setCounter(counter + 1)
  }

  const handleThumbnailClick = (event, file, index) => {
    const aggregatePages = _.get(value, 'aggregate.pages', PREVIEWS_PAGE_LIMIT + 1)
    if (aggregatePages > PREVIEWS_PAGE_LIMIT) { return }
    const entity = _.get(row, 'data')
    // We need to stop propagation so that we don't open detail panel when
    // people click on the cell
    event.stopPropagation()
    ImageEditorModal.open({
      entity,
      imagesPath: valuePath,
      index,
      onClose: customForceUpdate,
      schema,
      showOriginal,
    })
  }
  // Doing this becuase instead of fileSet we might just be passing in a file
  // there are many cases where value is undefined hence we are using the get
  // and default to []
  let files
  if (schema.type === 'array') {
    files = value
  } else if (schema.$ref === FILE_SCHEMA) {
    files = [value]
  } else {
    files = _.get(value, 'files', [])
  }
  return (
    <ThumbnailList
      files={files}
      maxNumber={maxNumber}
      onThumbnailClick={handleThumbnailClick}
      showOriginal={showOriginal}
    />
  )
}
