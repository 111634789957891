import React, { useContext } from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { Owner } from 'shared-libs/generated/server-types/entity'
import { renderAuthorHeader, renderEventTimestamp } from '../util'

interface INotificationItemProps extends IBaseProps {
  event: any
  owner: Owner
  index: number
  isFirst: boolean
  isLast: boolean
  workflowEntity: any
}

export const NotificationItem: React.FC<INotificationItemProps> = (props) => {
  const { className, event, owner, isFirst, workflowEntity } = props
  const { createdBy, creationDate, processedDate } = event
  const { settings } = useContext(AppNavigatorContext)

  const plan = _.get(workflowEntity, 'core_storyboard_execution.storyboardPlan.core_storyboard_plan')

  if (_.isEmpty(plan) || _.isEmpty(event.outputMappings)) {
    return null
  }

  return (
    <div
      className={classNames('c-timelineEvent', className)}
      key={event.uniqueId}
    >
      <div
        className={classNames('c-timelineEvent-line', className, {
          'c-timelineEvent-line--topCutOff': isFirst,
        })}
      />
      <div className='c-timelineEvent-dot' />
      <div className='c-timelineEvent-contentContainer'>
        { createdBy && (
          <div className='c-timelineEvent-header'>
          <div className='c-timelineEvent-author'>
            <Avatar
              className='c-timelineEvent-avatar'
              name={createdBy.displayName}
              size='xs'
            />
            <Label className='c-timelineEvent-authorName'>
              {renderAuthorHeader(createdBy, owner?.firm)}
            </Label>
          </div>
          <HelpBlock className='c-timelineEvent-timestamp'>
            {renderEventTimestamp(creationDate, processedDate, settings)}
          </HelpBlock>
          </div>
        ) }
        <div className='c-timelineEvent-body'>
          <p>was sent a notification</p>
        </div>
      </div>
    </div>
  )
}
