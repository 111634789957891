import _ from 'lodash'
import { jsonCloneDeep } from '../helpers/utils'

const defaultHandler = {
  get: function (prop) {
    return this[prop]
  },
  set: function (prop, value) {
    this[prop] = value
  }
}

export class EntityProxy {

  private _handler: any
  private _content: any
  private _prevContent: any
  /**
   * original, unmodified value from the server. User to generate
   * JSON patch for entity "PATCH" update method. The UI code should
   * never touch this field.
   */
  private _origContent: any

  constructor(target, handler = defaultHandler) {
    this._content = target
    this._handler = handler
    this.setOrigContent(target)
  }

  public get content() {
    return this._content
  }

  public set content(content) {
    throw new Error('Cannot update content directly. Use setContent')
  }

  public get isDirty() {
    return !_.isEqual(this._prevContent, this.content)
  }

  public get prevContent() {
    return this._prevContent
  }

  public set prevContent(content) {
    throw new Error('Cannot update content directly. Use setPrevContent')
  }

  public get origContent() {
    return this._origContent
  }

  public set origContent(content) {
    throw new Error('Cannot update content directly. Use setOrigContent')
  }

  public get(path, defaultValue?) {
    if (!path) {
      return defaultValue
    }
    return _.get(this._content, path, defaultValue)
  }

  public set(path, value) {
    if (_.isNil(value)) {
      _.unset(this._content, path)
    } else {
      _.set(this._content, path, value)
    }
    return this._content
  }

  /**
   * Sets a value in both content and prevContent.
   */
  public commit(path, value = undefined) {
    _.set(this._content, path, value)
    _.set(this._prevContent, path, jsonCloneDeep(value))
  }

  public rollback() {
    this.setContent(jsonCloneDeep(this._prevContent))
  }

  public setContent(content) {
    this._content = content
    this.registerOwnProperties()
  }

  public setPrevContent(content) {
    this._prevContent = jsonCloneDeep(content)
  }

  public setOrigContent(content) {
    this._origContent = jsonCloneDeep(content)
  }

  public setAllContents(content) {
    this.setContent(content)
    this.setPrevContent(content)
    this.setOrigContent(content)
  }

  protected registerOwnProperties() {
    // Clone direct properties (i.e., not part of a prototype).
    const target = this._content
    const propertyNames = Object.getOwnPropertyNames(target)
    propertyNames.forEach(prop => this.registerProperty(prop))
  }

  protected registerProperty(prop: string) {
    const target = this._content
    const getter = this._handler.get
    const setter = this._handler.set
    Object.defineProperty(this, prop, {
      configurable: true,
      enumerable: true,
      get: getter.bind(target, prop),
      set: setter.bind(target, prop),
    })
  }
}
