import classNames from 'classnames'
import React from 'react'

import DOMPurify from 'dompurify'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/help-block/_help-block.scss'
import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { translateString } from 'shared-libs/helpers/utils'

/**
 * @uiComponent
 */
interface IHelpBlockProps extends IBaseProps {
  frames?: FramesManager
  text?: string
  helpText?: string
  htmlText?: string
}

export const HelpBlock: React.FC<IHelpBlockProps> = (props) => {
  const { frames, helpText, htmlText, text, children, className, style } = props

  const translationTable = frames?.getContext('translationTable')
  const translatedText = translateString(helpText || text, translationTable)
  const content = translatedText || children

  if (htmlText) {
    const sanitized = sanitize(htmlText)
    return (
      <div
        dangerouslySetInnerHTML={{ __html: sanitized }}
        className={classNames('c-helpBlock', className)}
        style={style}
      />
    )
  }

  return (
    <div className={classNames('c-helpBlock', className)} style={style}>
      {content}
    </div>
  )
}

function sanitize(html: string): string {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: [
      'div',
      'span',
      'a',
      'ul',
      'ol',
      'li',
      'br',
      'hr',
      'p',
      'u',
      'b',
      'i',
      'em',
      'strong',
      'abbr',
      'blockquote',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'pre',
    ],
  })
}
