import _ from 'lodash'
import { Entity } from '../../entity'
import { StoryboardExecutionEntity } from './storyboard-execution'
import { StoryboardPlanEntity } from './storyboard-plan'
import { Query } from '../../query'
import { SchemaUris } from '../../schema'
import { DEFAULT_ORDERS } from '../../store'
import { Collection } from '../../collection'
import { Mappings } from './storyboard-plan-model'
import { EsprimaParser, ExpressionEvaluator } from '../../../helpers/evaluation'
import { CustomFormulas } from '../../../helpers/formulas'
import { Event } from './storyboard-execution-model'

export const PLAN_NAME_SPACE = 'core_storyboard_plan'
export const EXECUTION_NAME_SPACE = 'core_storyboard_execution'
export const INITIAL_ROUTE_KEY = '__ROOT__'
export const EXIT_ROUTE_KEY = '__EXIT__'

/**
 * The Entity in the UI is little different.  It is a monolothic object with
 * pointers to other object types.  Every mixin could be a pointer to another
 * object accessible by its namespace.  For example entity['document_imaage_settings'] could
 * point to an instance of DocumentImageSettings
 *
 * Helper function to extract the 'mixin' storyboard type from the Entity
 * @param entity
 */
export const getStoryboardPlan = (entity: Entity): StoryboardPlanEntity => {
  return entity[PLAN_NAME_SPACE] as StoryboardPlanEntity
}

/**
 * Helper function to extract 'mixin' execution type from entity type
 */
export const getStoryboardExecution = (entity?: Entity): StoryboardExecutionEntity => {
  return entity?.[EXECUTION_NAME_SPACE] as StoryboardExecutionEntity
}

export const queryWorkflowPlans = (apis) => {
  const query = new Query(apis)
    .setEntityType(SchemaUris.STORYBOARD_PLAN)
    .setOrders(DEFAULT_ORDERS)
    .setFilters()
  return new Collection(apis, query).find()
}

/**
 * Properties in the storyboard plan could be defined as
 *
 * "shipmentId": {
 *   "type": "expression",
 *   "value": "self.workflow_chamberlain.shipmentId
 * }
 * or
 * "shipmentId": "123"
 *
 * This function will recursively evaluate all the expression types
 * @param prop
 * @param evaluator
 * @returns new 'prop' will all the expression type evaluated
 */
export const materializeProps = (prop: any, evaluator: any) => {
  let newValue = prop
  if (_.isArray(prop)) {
    newValue = prop.map((val: any) => materializeProps(val, evaluator))
  } else if (_.isPlainObject(prop)) {
    if (_.get(prop, 'type', '') === 'expression') {
      const formula = _.get(prop, 'value', '')
      newValue = evaluator?.evaluate(formula)
    } else {
      newValue = _.transform(
        prop,
        (result: any, value, key) => {
          result[key] = materializeProps(value, evaluator)
          return result
        },
        {}
      )
    }
  }
  return newValue
}

/**
 * Standalone mappings evaluator
 *
 */
export const evaluateMappings = (mappings: Mappings, frames: any, ctx = {}) => {
  const evaluator = ExpressionEvaluator.create().setASTParser(EsprimaParser)
  const normalizedCtx = { ...ctx, _srcValue: null }
  const valueGetter = (key) =>
    frames?.getValue(key) ?? _.get(normalizedCtx, key, CustomFormulas[key])

  return _.reduce(
    mappings,
    (result, mapping) => {
      const normalizedMapping = materializeProps(mapping, evaluator)
      const srcValue = _.get(ctx, normalizedMapping?.source, null)
      _.set(normalizedCtx, '_srcValue', srcValue)

      evaluator.setValueGetter(valueGetter)
      const dstValue = normalizedMapping.formula
        ? evaluator.evaluate(normalizedMapping.formula)
        : srcValue

      if (dstValue != null) {
        const newValue = {}
        _.set(newValue, normalizedMapping?.destination, dstValue)
        _.merge(result, newValue)
      }
      return result
    },
    {}
  )
}

/**
 *  Similar to lodash _.difference but instead of O(N^2), do it with O(N)
 *
 * _.difference([], ['a', 'b', 'c'])
 *  []
 * _.difference(['a', 'b'], ['a', 'b', 'c'])
 *  []
 * _difference(['a', 'b', 'c'], [])
 *  ['a', 'b', 'c']
 * _difference(['a', 'b', 'c'], ['a'])
 *  ['b', 'c']
 *
 */
export const eventDifference = (left: Event[] = [], right: Event[] = []): Event[] => {
  if (left.length === 0) {
    return []
  }

  const rightMap: Record<string, Event> = {}

  for (const item of right) {
    rightMap[item.id] = item
  }

  const difference = []
  for (const item of left) {
    // if not contain in the right, then it is a diff
    if (!rightMap[item.id]) {
      difference.push(item)
    }
  }

  return difference
}

/**
 * Perform exclusive left/right joint operation on two set of events
 *
 * @returns  leftExclusive left - right
 *           rightExclusive right - left
 *           innerJoin left/right intersection
 */
export const eventJoinOperations = (
  leftEvents: Event[] = [],
  rightEvents: Event[] = []
): { leftExclusive: Event[]; rightExclusive: Event[]; innerJoin: Event[] } => {
  const leftMap: Record<string, Event>  = {}
  const rightMap: Record<string, Event> = {}
  const fullMap: Record<string, Event> = {}

  const leftExclusive: Event[] = []
  const rightExclusive: Event[] = []
  const innerJoin: Event[] = []

  // build hashmap for O(1) lookup
  for (const item of leftEvents) {
    leftMap[item.id] = item
    fullMap[item.id] = item
  }

  let isDiff = leftEvents.length !== rightEvents.length
  for (const item of rightEvents) {
    rightMap[item.id] = item
    fullMap[item.id] = item
    if (!isDiff && !leftMap[item.id]) {
      isDiff = true
    }
  }

  // bail out early if there is no diff
  if (!isDiff) {
    return {
      leftExclusive,
      rightExclusive,
      innerJoin: leftEvents,
    }
  }

  for (const key of Object.keys(fullMap)) {
    const value = fullMap[key]
    const inLeft = !!leftMap[key]
    const inRight = !!rightMap[key]

    if (inLeft && inRight) {
      innerJoin.push(value)
    } else if (inLeft && !inRight) {
      leftExclusive.push(value)
    } else if (!inLeft && inRight) {
      rightExclusive.push(value)
    }
  }

  return {
    leftExclusive,
    rightExclusive,
    innerJoin,
  }
}

export interface WorkflowRoute {
  story?: any // TODO - proper types
  scene?: any
  task?: any

  route?: any
}

export class WorkflowRouteImpl implements WorkflowRoute {
  story?: any
  scene?: any
  task?: any

  constructor(story, scene, task) {
    this.story = story
    this.scene = scene
    this.task = task
  }

  public get route() {
    return this.task || this.scene
  }
}
