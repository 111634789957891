import pluralize from 'pluralize'
import React, { useState } from 'react'

import { Entity } from 'shared-libs/models/entity'
import { ViewScope } from 'shared-libs/models/types/view'
import { canEditFirmView } from 'shared-libs/models/utils'
import { AbstractSettings } from 'shared-libs/models/abstract-settings'
import { Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { FilterList } from 'browser/components/atomic-elements/organisms/filters/filter-list'
import { ViewChooser } from 'browser/components/atomic-elements/organisms/view-header/view-chooser'
import { ViewControls } from 'browser/components/atomic-elements/organisms/view-header/view-controls'

import 'browser/components/atomic-elements/organisms/view-header/_view-header.scss'

export interface IViewHeaderProps extends IBaseProps {
  availableFilters: any[]
  blacklistedFilters: any[]
  baseViewId?: string
  entitySchema: object
  filters: any[]
  showFilters?: boolean
  onFiltersChange: (filters: any) => void
  orders?: any[]
  hidePageSummary?: boolean
  toggleFilterCard: () => void
  totalItems?: number
  view: Entity
  dataSet: any
}

export const ViewHeader: React.FC<IViewHeaderProps> = ({
  availableFilters,
  blacklistedFilters,
  children,
  className,
  baseViewId,
  entitySchema,
  filters,
  showFilters,
  onFiltersChange,
  orders,
  hidePageSummary,
  toggleFilterCard,
  totalItems,
  view,
  dataSet,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [counter, setCounter] = useState(0) // trigger state update when timer pause string changes - forceUpdate for a React.FC

  const handleOnViewSave = () => {
    setIsSaving(true)
    view.save().then(() => setIsSaving(false))
  }

  const handleOnViewReset = () => {
    view.rollback()
  }

  const renderPageSummary = () => {
    if (hidePageSummary) {
      return
    }
    if (totalItems === undefined) {
      return 'Loading...'
    }
    // TODO(louis): Figure out how to get type here
    const itemWord = pluralize('item', totalItems)
    const itemsSummary = `${totalItems} ${itemWord}`

    if (orders && orders.length > 0) {
      return `${itemsSummary}`
    }
    return `${itemsSummary}`
  }

  const renderViewControls = (settings: AbstractSettings) => {
    const isPersonalView = view.get('view.scope') === ViewScope.personal
    const isOwner = view.get('owner.user.entityId') === settings.getUser().uniqueId
    const isFirmView = !isPersonalView && view.get('owner.firm.entityId') === settings.getFirm().uniqueId
    const isEditEnabled =
      view.get('view').state.isEditEnabled === undefined || view.get('view').state.isEditEnabled
    const hasEditPermissions = settings.isAdmin
      || (isFirmView && canEditFirmView(settings))
      || (isPersonalView && isOwner)
    const isEditable = hasEditPermissions && view.isDirty && isEditEnabled

    return (
      <CardHeaderItem>
        <ViewControls
          handleResetButton={handleOnViewReset}
          handleSaveButton={handleOnViewSave}
          isSaving={isSaving}
          isVisible={isEditable}
        />
      </CardHeaderItem>
    )
  }

  const renderFilterPills = () => {
    if (showFilters) {
      return (
        <CardHeaderItem className="c-cardHeader-item--smallMargin">
          {filters && filters.length > 0 ? (
            <FilterList
              availableFilters={filters}
              entitySchema={entitySchema}
              filters={filters}
              isButton={true}
              isClearable={true}
              onChange={onFiltersChange}
              showFilterValue={true}
              tetherOptions={{
                attachment: 'top left',
                offset: { top: 0, left: -1 },
                targetAttachment: 'bottom left',
              }}
            >
              <Button
                className={Classes.iconClass(IconNames.FILTER)}
                onClick={toggleFilterCard}
                data-debug-id="filterCard"
              >
                Filter
              </Button>
            </FilterList>
          ) : (
            <Button
              className={Classes.iconClass(IconNames.FILTER)}
              onClick={toggleFilterCard}
              data-debug-id="filterCard"
            >
              Filter
            </Button>
          )}
        </CardHeaderItem>
      )
    }
  }

  const resetDataset = () => {
    // Clear table to reset scroll state, then pull in new data.
    dataSet.collection.clearContent()
    dataSet.collection.emitUpdate()
    dataSet.find()
    setCounter(counter+1)
  }

  const showPauseContent = dataSet.timer && dataSet.timer.isPaused && dataSet.timer.pauseReason
  const pauseContent = showPauseContent && <div className="flex items-center">
    Auto-refresh paused by {dataSet.timer.pauseReason}.&nbsp;<a onClick={resetDataset}>Reset View.</a>
  </div>

  return (
    <div className="u-darkBorderBottom u-noPrint">
      <CardHeader className={className}>
        <AppNavigatorContext.Consumer>
          {({ settings }) => (
            <>
              <Head title={view.displayName} />
              <CardHeaderItem
                className="c-cardHeader-item--title"
                description={renderPageSummary()}
              >
                <ViewChooser baseViewId={baseViewId} settings={settings} view={view} />
              </CardHeaderItem>
              <div className="c-viewHeader-controls flex flex-auto justify-between c-cardHeader-item--shrink">
                {/* if we don't have any filters set, render with other controls */}
                {(!filters || filters.length < 1) && renderFilterPills()}
                {children}
                {renderViewControls(settings)}
              </div>
            </>
          )}
        </AppNavigatorContext.Consumer>
      </CardHeader>
      {/* if 1 or more filters are set, render in it's own row */}
      {
        (filters && filters.length > 0 &&
          <div className="flex flex-auto justify-between c-cardHeader-item--shrink c-cardHeader-row">
            {renderFilterPills()}

            {pauseContent}
          </div>
        ) ||
        pauseContent
      }
    </div>
  )
}

ViewHeader.defaultProps = {
  showFilters: true,
}
