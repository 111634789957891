import { Classes, Tab, Tabs } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import SplitPane from 'react-split-pane'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import ComponentsMap from 'browser/components'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Card } from 'browser/components/atomic-elements/atoms/card/card'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { EntityFooter } from 'browser/components/atomic-elements/atoms/footer/entity-footer'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { BlockTransition } from 'browser/components/atomic-elements/atoms/navigation/block-transition'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import {
  IEntityDetailCardProps,
} from 'browser/components/atomic-elements/organisms/entity/entity-detail-card/entity-detail-card'
import { EntityFormPanel } from 'browser/components/atomic-elements/organisms/entity/entity-form-panel'
import 'browser/components/atomic-elements/organisms/entity/order-detail-card/_order-detail-card.scss'
import {
  SalesOrderSecondaryCardHeader,
// tslint:disable-next-line:max-line-length
} from 'browser/components/atomic-elements/organisms/entity/sales-order-detail-card/sales-order-secondary-card-header'
import { ShareBar } from 'browser/components/atomic-elements/organisms/share-bar/share-bar'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'
import { JSONEditor } from '../../internal-tools/json-editor'
import { EntityDataSource } from '../entity-data-source'
import { useForceUpdate } from 'browser/components/hooks/useForceUpdate'

const ORDER_ENTRY_TAB_ID = 'orderEntry'

export const SalesOrderDetailCard: React.FC<IEntityDetailCardProps> = (props) => {
  const { className, entity, header, isFullScreen, onClose, queries } = props
  const forceUpdate = useForceUpdate()
  const isDirty = entity.isDirty

  const createDataSets = () => {
    const results = {}
    _.forEach(queries, (query, key) => {
      results[key] = new EntityDataSource(query)
        .setOnChange(forceUpdate)
    })
    return results
  }

  const { settings } = useContext(AppNavigatorContext)
  const [activeSecondaryTab, setActiveSecondaryTab] = useState('documents')
  const [activeTab, setActiveTab] = useState('Details')
  const [dataSets, setDataSets] = useState(createDataSets())
  const [errors, setErrors] = useState(null)
  const [isRightPanelVisible, setIsRightPanelVisible] = useState(true)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    _.forEach(dataSets, (dataSet: EntityDataSource) => dataSet.find())
    return () => _.forEach(dataSets, (dataSet: EntityDataSource) => dataSet.dispose())
  }, [])

  //////////////
  // Handlers //
  //////////////

  const handleSaveEntity = (): Promise<any> => {
    setIsSaving(true)
    return entity.save()
      .then(() => setErrors(null))
      .catch(({ error }) => setErrors(error))
      .finally(() => setIsSaving(false))
  }

  const handleCancelAndArchive = () => {
    const cancelAndDeleteOrder = () => {
      entity.save()
        .then(() => entity.delete())
        .then(() => onClose ? onClose() : null)
    }
    ConfirmationModal.open({
      confirmationText: 'Do you want to cancel and delete this order?',
      confirmationTitle: 'Confirm Cancel and Delete',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: cancelAndDeleteOrder,
      primaryButtonText: 'Confirm',
    })
  }

  const handleDuplicateOrder = () => {
    const cloneFields = [
      'core_fulfilment_salesOrder.billTo',
      'core_fulfilment_salesOrder.soldTo',
      'core_fulfilment_salesOrder.cargos',
      'core_fulfilment_salesOrder.stops',
      'core_fulfilment_salesOrder.loadType',
    ]

    const defaultValue = {}
    _.forEach(cloneFields, (field) => {
      _.set(defaultValue, field, _.cloneDeep(entity.get(field)))
    })

    // TODO(Peter): this is really sketchy
    const schema = _.last(entity.schemas)
    EntityFormPanel.open({ schema, defaultValue })
  }

  const handleBlockTransition = (onTransition) => {
    const saveChanges = () => {
      handleSaveEntity()
        .then(() => onTransition())
    }
    const discardChanges = () => {
      handleRollback()
      onTransition()
    }
    ConfirmationModal.open({
      confirmationText: 'Are you sure you want to leave this screen and discard your changes?',
      confirmationTitle: 'You have unsaved changes',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: saveChanges,
      onSecondaryClicked: discardChanges,
      primaryButtonText: 'Save Changes',
      secondaryButtonText: 'Discard Changes',
    })
  }

  const handlePrimaryTabChange = (tabId) => {
    if (!isDirty) {
      setActiveTab(tabId)
      return
    }
    handleBlockTransition(() => {
      setActiveTab(tabId)
    })
  }

  const handleRollback = () => {
    entity.rollback()
    forceUpdate()
  }

  const handleSecondaryTabChange = (tabId) => {
    setActiveSecondaryTab(tabId)
  }

  const handleToggleRightPanelVisibility = () => {
    setIsRightPanelVisible(!isRightPanelVisible)
  }

  ///////////////////////
  // Render Components //
  ///////////////////////

  const renderOrderHelmet = () => {
    const stops = entity.get('core_fulfilment_salesOrder.stops')
    const firstStop = _.first(stops)
    const lastStop = _.last(stops)
    const addressPath = ['location', 'denormalizedProperties', 'location.address']
    const firstAddress: any = _.get(firstStop, addressPath)
    const lastAddress: any = _.get(lastStop, addressPath)
    let stopStatus = ''
    let title = ''
    if (firstAddress && lastAddress) {
      stopStatus = `${firstAddress.locality}, ${firstAddress.region} - ${lastAddress.locality}, ${lastAddress.region}`
      title = `${entity.displayName} | ${stopStatus} - Vector`
    } else {
      title = `${entity.displayName} - Vector`
    }
    return <Head title={title} />
  }

  const renderDuplicateButton = () => {
    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin'>
        <Button onClick={handleDuplicateOrder}>
          Duplicate
        </Button>
      </CardHeaderItem>
    )
  }

  const renderMoreOptionsDropdown = () => {
    const dropdownMenu = (
      <Popover className='collapse'>
        <ul className='c-dropdownList'>
          <li
            className='c-dropdownList-item'
            onClick={handleCancelAndArchive}
          >
            Cancel &amp; Archive
          </li>
        </ul>
      </Popover>
    )

    return (
      <CardHeaderItem className='c-cardHeader-item--smallMargin'>
        <TetherTarget
          closeOnPortalClick={true}
          tethered={dropdownMenu}
        >
          <Button buttonText={'More'} />
        </TetherTarget>
      </CardHeaderItem>
    )
  }

  const renderOrderHeader = () => {
    return React.cloneElement(header, {
      children: (
        <SalesOrderSecondaryCardHeader entity={entity} />
      ),
      ...(isFullScreen && {
        leftFullScreenActionElement: (
          <div className='flex'>
            {renderDuplicateButton()}
            {renderMoreOptionsDropdown()}
          </div>
        )})
      ,
      onClose,
    })
  }

  const renderTabTitle = (title) => {
    return (
      <div className='c-statusTabBar-title'>
        {title}
      </div>
    )
  }

  const renderSaveFooter = () => {
    return (
      <Footer
        isPrimaryButtonLoading={isSaving}
        isVisible={isDirty}
        onCancelButtonClick={handleRollback}
        onPrimaryButtonClick={handleSaveEntity}
      />
    )
  }

  const renderUiSchema = (uiSchemaName, context) => {
    const schemas = entity.schemas
    const renderState = Object.assign({ settings }, {
      activeSecondaryTab,
      activeTab,
      dataSets,
      errors,
      isRightPanelVisible,
      isSaving,
    })

    const uiSchemaPath = `uiSchema.web.${uiSchemaName}`
    for (let i = schemas.length - 1; i >= 0; --i) {
      if (!_.get(schemas[i], uiSchemaPath)) {
        continue
      }
      return (
        <EntityRenderer
          actions={this}
          componentsMap={ComponentsMap}
          context={context}
          errors={errors}
          state={renderState}
          onChangeComplete={forceUpdate}
          value={entity}
          uiContext={schemas[i]}
          uiSchemaPath={uiSchemaPath}
        />
      )
    }
  }

  const renderTabPanel = (uiSchemaName: string, options: object) => {
    return (
      <div className='grid-block vertical u-overflowHidden'>
        {renderUiSchema(uiSchemaName, options)}
        <EntityFooter
          errors={errors}
          entity={entity}
          isPrimaryButtonLoading={isSaving}
          isVisible={isDirty}
          onCancelButtonClick={handleRollback}
          onPrimaryButtonClick={handleSaveEntity}
        />
      </div>
    )
  }

  const renderJSONTab = () => {
    const detailTabPanel = (
      <div className='grid-block vertical'>
        <div className='grid-block'>
          <JSONEditor
            entity={entity}
            onChange={forceUpdate}
          />
        </div>
        {renderSaveFooter()}
      </div>
    )
    return (
      <Tab
        id='entityCardJSON'
        key='json-tab'
        title='JSON'
        className='grid-block vertical'
        panel={detailTabPanel}
      />
    )
  }

  const renderOrderDetailCard = () => {
    const numDocuments = _.get(dataSets, 'documents.collection.count', 0)
    const context = {}
    return (
      <div className='grid-block vertical'>
        <div className='grid-block vertical u-overflowHidden'>
          <Tabs
            className='grid-block vertical c-tabs'
            id='secondaryOrderCard'
            onChange={handlePrimaryTabChange}
            renderActiveTabPanelOnly={true}
            selectedTabId={activeTab}
          >
            <Tab
              className='grid-block'
              id={'Details'}
              title='Details'
              panel={renderTabPanel('components.orderEntryTab', context)}
            />
            <Tab
              className='grid-block'
              id='activity'
              title='Activity'
              panel={renderUiSchema('components.activityTab', context)}
            />
            <Tab
              className='grid-block'
              id='documents'
              title={numDocuments ? `Documents (${numDocuments})` : 'Documents'}
              panel={renderUiSchema('components.documentsTab', context)}
            />
            <Tabs.Expander />
            {settings.isAdmin && renderJSONTab()}
          </Tabs>
        </div>
        <ShareBar
          className='grid-block shrink'
          entity={entity}
        />
      </div>
    )
  }

  const renderLeftPanel = () => {
    const context = {
      isEditableInline: false,
      isStatic: false,
    }
    return (
      <Tabs
        className='grid-block vertical c-tabs'
        id='primaryOrderCard'
        onChange={handlePrimaryTabChange}
        renderActiveTabPanelOnly={true}
        selectedTabId={ORDER_ENTRY_TAB_ID}
      >
        <Tab
          className='grid-block'
          id={ORDER_ENTRY_TAB_ID}
          title={renderTabTitle('Order Details')}
          panel={renderTabPanel('components.orderEntryTab', context)}
        />
      </Tabs>
    )
  }

  const renderRightPanel = () => {
    const numDocuments = _.get(dataSets, 'documents.collection.count', 0)
    const isTmwSalesOrder = _.find(_.get(entity, 'mixins.active'), { entityId: '6c9d7b52-daaf-4d6a-bc23-5cfe039488cd' })
    const context = {
      density: 'collapse',
      isEditableInline: false,
      isHorizontalLayout: true,
      isStatic: true,
    }
    return (
      <div className='grid-block vertical'>
        <Tabs
          className='grid-block vertical c-tabs'
          id='secondaryOrderCard'
          onChange={handleSecondaryTabChange}
          selectedTabId={activeSecondaryTab}
          renderActiveTabPanelOnly={true}
        >
          <Tab
            className='grid-block'
            id='activity'
            title='Activity'
            panel={renderUiSchema('components.activityTab', context)}
          />
          <Tab
            className='grid-block'
            id='documents'
            title={numDocuments ? `Documents (${numDocuments})` : 'Documents'}
            panel={renderUiSchema('components.documentsTab', context)}
          />
          <Tab
            className='grid-block'
            id='invoice'
            title='Invoice'
            panel={renderUiSchema('components.invoicingTab', context)}
          />
          <Tabs.Expander />

          {!_.isNil(isTmwSalesOrder) && (
            <Tab
              className='grid-block'
              id='sourceDetails'
              title='Source Details'
              panel={(
                <div className='grid-content pt3'>
                  {renderUiSchema('components.sourceDetailsTab', context)}
                </div>
              )}
            />
          )}
          </Tabs>
        <ShareBar
          className='grid-block shrink'
          entity={entity}
        />
      </div>
    )
  }

  const renderContent = () => {
    let content = renderOrderDetailCard()
    if (isFullScreen) {
      if (isRightPanelVisible) {
        content = (
          <SplitPane
            primary='second'
            split='vertical'
            defaultSize={544}
            minSize={544}
          >
            {renderLeftPanel()}
            {renderRightPanel()}
          </SplitPane>
        )
      } else {
        content = renderLeftPanel()
      }
    }

    return (
      <div className='grid-block vertical'>
        {renderOrderHeader()}
        <div
          className={classNames('absolute c-toggleRightPanelVisibilityPanel', {
            'u-hide': !isFullScreen,
          })}
        >
          <Button
            className={Classes.iconClass(IconNames.INFO_SIGN)}
            onClick={handleToggleRightPanelVisibility}
          />
        </div>
        <div className='grid-block'>
          {content}
        </div>
      </div>
    )
  }

  return (
    <Card className={classNames('grid-block c-entityDetailCard c-orderCard u-noPrint', className)}>
      {renderOrderHelmet()}
      {renderContent()}
      <BlockTransition
        condition={isDirty}
        onBlockTransition={handleBlockTransition}
        debug='sales-order-detail-card'
        />
    </Card>
  )
}
