import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

const whiteListProps = [ 'className' ]

/**
 * @uiComponent
 */
interface IfProps extends IBaseProps {
  condition: any
  tagName?: string
}

export const If: React.FC<IfProps> = (props) => {
  if (props.condition) {
    const whiteListedProps = _.pick(props, whiteListProps)
    return React.createElement(props.tagName, whiteListedProps, props.children)
  }
  return null
}

If.defaultProps = {
  condition: false,
  tagName: 'div',
}
