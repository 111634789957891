import { StreamClient, StreamFeed } from 'getstream'
import * as React from 'react'
import { IInboxEntry } from './interface'

export interface IInboxContext {
  client: StreamClient
  feed: StreamFeed
  userId: string
  activities: Array<IInboxEntry>
  unread: number
  markAsRead: (...ids: (string | string[])[]) => void
  markAllAsRead: () => void
  fetchMore: () => void
  hasMore: boolean
}

export const InboxContext = React.createContext<IInboxContext>({
  client: null,
  feed: null,
  userId: null,
  unread: 0,
  activities: [],
  markAsRead: () => null,
  markAllAsRead: () => null,
  hasMore: true,
  fetchMore: () => null,
})
