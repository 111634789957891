import Promise from 'bluebird'
import _ from 'lodash'

import { AbstractCollection } from './abstract-collection'
import { Entity } from './entity'
import { QueryOptionsGenerator } from './query-options-generator'

export class Collection extends AbstractCollection {

  public entities: Entity[]
  private lastChildrenIsEmpty: Boolean

  constructor(apis, query) {
    super(apis.getStore(), query)
    this.subscribeToStore()
  }

  protected flatten(root) {
    const shouldStripMetadata = this.query.shouldStripMetadata
    const path = shouldStripMetadata ? '' : 'data'
    return super.flatten(root, path)
  }

  /// ///////////////////////////////////////////////////////////////////////////
  // Overridden Methods
  /// ///////////////////////////////////////////////////////////////////////////

  public clearContent() {
    this.entities = []
    super.clearContent()
    this.lastChildrenIsEmpty = null
  }

  public find(forceFetch?: boolean) {
    if (forceFetch) {
      this.cancel()
    } else if (this.isLoading) {
      return Promise.resolve(undefined)
    }

    this.lastChildrenIsEmpty = null
    this.isLoading = true
    this.query.setOffset(0)
    const query = this.query.build()

    return this.queryRecords(query).then(result => {
      this.result = result
      this.content = this.getData(query, result)
      this.entities = this.flatten(result)

      return this.content
    }).finally(() => {
      this.isLoading = false
    })
  }

  public paginateGroup(groupRows) {

    const groupSpecs = this.query.groups
    // create filters from groups
    const filters = QueryOptionsGenerator.getFiltersFromGroups(groupSpecs, groupRows)
    const groupToExpand: any = _.last(groupRows)
    const newFilters = this.query.filters.concat(filters)
    const newGroups = this.query.groups.slice(filters.length)
    const query = this.query.clone()
      .setFilters(newFilters)
      .setGroups(newGroups)
      .setOffset(groupToExpand.children.length)

    return this.queryRecords(query.build()).then((nextGroupPage) => {
      const children = groupToExpand.children
      children.push(...nextGroupPage.children)
      this.content = this.content.slice()
      this.entities = this.flatten(this.result)
      return this.content
    })
  }

  protected handleReloadCollection = (schemaId) => {
    if (this.query.entityMetadata.uniqueId === schemaId) {
      this.reload()
    }
  }

  protected handleOnInsert = (entity: Entity) => {
    // If a new entity is created locally, update if it is in collection
    const localFilters = this.query.getLocalFilters()
    if (localFilters.filter(entity) && this.hasOutboundListeners) {
      this.reload()
    }
  }

  protected handleOnPending = (entity: Entity) => {
    // If a new entity is created locally, update if it is in collection
    const localFilters = this.query.getLocalFilters()
    if (localFilters.filter(entity) && this.hasOutboundListeners) {
      this.reload()
    }
  }

  protected handleOnUpdate = (entity: Entity) => {
    // If a new entity goes from pending -> created or is updated
    // If it is accepted, see if it is already in the collection
    // If it is in the collection, emit update otherwise update
    // If it is not accepted, but is in collection call update
    const localFilters = this.query.getLocalFilters()
    const isAccepted = localFilters.filter(entity)
    const instance = this.findByUniqueId(entity)
    if (isAccepted)  {
      // if accepted and exists, just emit update
      if (instance) {
        this.emitUpdate(this.content)
      } else if (this.hasOutboundListeners) {
        // if accepted and doesn't, refetch collection
        this.reload()
      }
    } else {
      // if rejected but exists, refetch collection
      if (instance && this.hasOutboundListeners) {
        this.reload()
      }
    }
  }

  protected handleOnDelete = (entity: Entity) => {
    // TODO(Peter): we can search through the list and remove entity
    if (this.findByUniqueId(entity) && this.hasOutboundListeners) {
      setTimeout(() => { this.reload() }, 1000)
    }
  }

  public hasNext() {
    // If we are not loaded and have no result, return false
    // Todo this assumption could likely also be extended to non-group queries
    if (!this.result || (!_.isEmpty(this.query.groups) && this.lastChildrenIsEmpty)) {
      return false
    }
    // TODO(Peter): maybe we should consolidate totalEntityMatchCount and totalChildrenCount
    // to just totalEntityMatchCount?
    const metadata = this.result.metadata
    if (_.isEmpty(this.query.groups)) {
      return this.content.length < metadata.totalEntityMatchCount
    } else if (metadata.totalChildrenCount) {
      return this.content.length < metadata.totalChildrenCount
    }
  }

  public next() {
    if (this.isLoading || !this.hasNext()) {
      return Promise.resolve(this.content)
    }
    this.isLoading = true
    const query = this.query.setOffset(this.content.length).build()
    return this.queryRecords(query).then(result => {
      this.result.children = this.result.children.concat(result.children)
      this.content = this.content.concat(this.getData(query, result))
      this.entities = this.entities.concat(this.flatten(result))
      this.lastChildrenIsEmpty = _.isEmpty(result.children)
      return this.content
    })
    .finally(() => { this.isLoading = false })
  }

  /// ///////////////////////////////////////////////////////////////////////////
  // Private Methods
  /// ///////////////////////////////////////////////////////////////////////////

  private findByUniqueId(entity: Entity) {
    // TODO(Peter): this is inefficient
    return _.find(this.entities, { uniqueId: entity.get('uniqueId') })
  }

  // TODO(Peter): this is a temporary hack
  private getData(query, result) {
    if (!this.query.shouldIncludeLeafEntities) {
      return _.isEmpty(query.groups) ? result : result.children
    } else {
      return result.children
    }
  }

  // TODO(Peter): this is a temporary hack
  private queryRecords(query) {
    if (!this.query.shouldIncludeLeafEntities) {
      this.promise = this.store.queryInsight(query)
      return this.promise
    } else {
      const shouldStripMetadata = this.query.shouldStripMetadata
      this.promise = this.store.queryRecords(query, shouldStripMetadata)
      return this.promise
    }
  }
}
