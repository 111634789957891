import classNames from 'classnames'
import initials from 'initials'
import _ from 'lodash'
import React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import 'browser/components/atomic-elements/atoms/avatar/_avatar.scss'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { getInitialsColor } from 'browser/marketing-transition/utils/utils'

interface IAvatarProps extends IBaseProps {
  email?: string
  overflowValue?: number
  showPlaceholder?: boolean
  name?: string
  size?: string
  user?: any
}

export const Avatar: React.FC<IAvatarProps> = (props) => {
  const { showPlaceholder, size, overflowValue, className, user, name, email } = props

  const renderPlaceHolder = () => {
    return (
      <div className={classNames('c-avatar c-avatar--placeholder', sizeClass, className)}>
        <Icon icon={IconNames.PERSON} />
      </div>
    )
  }

  const getNormalizedName = () => {
    if (!user) {
      if (!_.isEmpty(name)) {
        return name
      }
      if (!_.isEmpty(email)) {
        return email
      }
      return 'Anon User'
    }
    if (user.first_name && user.last_name) {
      return `${user.first_name} ${user.last_name}`
    }
    if (user.email) {
      return user.email
    }
    return user.displayName
  }

  let avatarInitials = initials(getNormalizedName()).toUpperCase().substr(0, 2)
  let colorClass = `c-avatar--${getInitialsColor(avatarInitials)}`
  const sizeClass = _.isEmpty(size) ? '' : `c-avatar--${size}`

  if (showPlaceholder) {
    return renderPlaceHolder()
  }

  if (overflowValue) {
    const minOverflowValue = Math.min(overflowValue, 99)
    avatarInitials = '+' + minOverflowValue
    colorClass = 'c-avatar--overflow'
  }

  return (
    <div className={classNames('c-avatar', colorClass, sizeClass, className)}>
      <div className='c-avatar-initials'>
        {avatarInitials}
      </div>
    </div>
  )
}
