import React, { useEffect, useState } from 'react'
import { Icon, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Entity } from 'shared-libs/models/entity'
import apis from 'browser/app/models/apis'

interface IFollowButtonProps {
  entity: Entity
}

export function FollowButton(props: IFollowButtonProps) {
  const [following, setFollowing] = useState(false)
  const [initializing, setInitializing] = useState(true)
  const [entityId, setEntityId] = useState('')
  
  const checkFollowing = async () => {
    if (!props.entity) {
      return
    }
    const entityId = props.entity.get('uniqueId')
    setEntityId(entityId)
    const response = await apis.isUserFollowing(entityId)
    setFollowing(response.following)
    setInitializing(false)
  }

  useEffect(() => {
    checkFollowing()
  }, [])

  const onToggle = async () => {
    if (following) {
      await apis.unfollowEntity(entityId)
      setFollowing(false)
    } else {
      await apis.followEntity(entityId)
      setFollowing(true)
    }
  }

  return (
    <Button
      onClick={onToggle}
      intent={following ? Intent.SUCCESS : Intent.NONE}
      isLoading={initializing}
      data-debug-id="follow-button"
    >
      <Icon icon={IconNames.NOTIFICATIONS} title={following ? 'Unfollow' : 'Follow'} />
    </Button>
  )
}
