import _ from 'lodash'
import { isUUIDValid } from '../helpers/utils'
import { ApplicationBundleResolver } from '../resolvers/application-bundle-resolver'
import { ViewResolver } from '../resolvers/view-resolver'
import { Entity } from './entity'
import { SchemaIds } from './schema'
import { EntitySource, Store } from './store'

export const AppBundleIds = {
  BASE: '11111111-0000-0000-0000-200000000000',
  DMS: '11111111-0000-0000-0000-200000000001',
  TMS: '11111111-0000-0000-0000-200000000002'
}

export class ApplicationBundle {
  private apis: any
  private bundleResolver: ApplicationBundleResolver
  private viewResolver: ViewResolver
  private bundle: any
  private resolvedBundle: any

  constructor(apis: any) {
    this.apis = apis
    this.bundleResolver = new ApplicationBundleResolver(apis)
    this.viewResolver = new ViewResolver(apis.getStore())
  }

  public async initialize() {
    try {
      this.bundle = await this.apis.fetchBundle()
    } catch (e) {
      console.log('Failed to fetch app bundle from network: %s', e?.message || 'unknown error')
      this.bundle = await this.apis.getBundle()
    }
    return this.bundle
  }

  public resolve(bundle) {
    return this.bundleResolver.resolve(bundle).then((resolvedBundle) => {
      this.resolvedBundle = resolvedBundle
      return resolvedBundle
    })
  }

  public setResolvedBundle(bundle) {
    this.resolvedBundle = bundle
  }

  public getBundle() {
    return this.bundle
  }

  public getResolvedBundle() {
    return this.resolvedBundle
  }

  public getResolvedSchemas() {
    return _.get(this.resolvedBundle, 'applicationBundle.schemas', {})
  }

  public refresh() {
    /*
    Web app doesn't implement a separate `fetchBundle`, it just implements a `getBundle`,
    so don't use this on web for now.

    As of now, web and mobile bundle-getter methods are:
      web:
        getBundle - remote fetch

      mobile:
        getBundle - cache lookup, fallback to remote fetch
        fetchBundle - remote fetch

    TODO: implement a consistent interface for apis with a cache-policy
    and at least implement that cache policy on mobile like we already do in
    few places like FilteredCollection. Web could implement local cache portion
    by simply checking the in-memory maps in the store. See
    https://withvector.atlassian.net/browse/VD-3953

    NOTE: fetches additional non-schema entities like storyboard plans (in the
    future we may want to generalize this better. See
    https://withvector.atlassian.net/browse/VD-5818
    */

    const store: Store = this.apis.getStore()
    const entities = _.values(store.getCachedEntities())
    const additionalEntityIds = []

    const isMaterialized = (entity) => entity && entity instanceof Entity

    for (const entity of _.values(entities)) {
      if (
        isMaterialized(entity) &&
        (entity.hasMixin(SchemaIds.STORYBOARD_PLAN) ||
          entity.hasMixin(SchemaIds.PACKET) ||
          entity.hasMixin(SchemaIds.ENTITY_MAPPING) ||
          entity.hasMixin(SchemaIds.SETTINGS))
      ) {
        additionalEntityIds.push(entity.get('uniqueId'))
      }
    }

    const sources = [EntitySource.REMOTE]

    return this.apis.fetchBundle(sources).then((bundle) => {
      this.bundle = bundle
      this.resolvedBundle = bundle
    }).then(() => {
      return store.findRecords(additionalEntityIds)
    })
  }

  public getView(name): Promise<any> {
    const viewId = this.resolvedBundle.applicationBundle.views[name].entityId
    return this.viewResolver.resolveById(viewId)
  }

  /*
   * Recursively explore for view edges, and fetch any that don't exist in cache.
   */
  public findViewEdges(uiSchema): Promise<any[]> {
    const entityIds = []
    const explore = (obj) => {
      for (const key in obj) {
        const value = obj[key]
        if (_.isObject(value)) {
          const type = _.get(value, 'type')
          const id = _.get(value, 'to')
          if (type === 'ui:sideNavigationBarItem' && id && isUUIDValid(id)) {
            entityIds.push(id)
          } else {
            explore(value)
          }
        }
      }
    }
    explore(uiSchema)
    return this.apis.getStore().fetchOrGetRecords(entityIds)
  }
}
