import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

interface IErrorWrapperProps {
  className?: any
  error?: string | JSX.Element
  style?: any
}

export class ErrorWrapper extends React.Component<IErrorWrapperProps, any> {

  constructor(props) {
    super(props)
  }

  public render() {
    const { children, className, error, style } = this.props
    if (!_.isEmpty(error)) {
      const newChildren = React.Children.map(children, (child: any) => {
        return React.cloneElement(child, {
          style: { borderColor: '#D45C43' },
        })
      })
      return (
        <div className={className} style={style}>
          {newChildren}
          <ErrorMessageContainer>
            <ErrorText>{error}</ErrorText>
          </ErrorMessageContainer>
        </div>
      )
    }
    return (
      <div style={style}>
        {children}
      </div>
    )
  }
}

const ErrorMessageContainer = styled.div`
  background-color: #ffebee;
  border-radius: 5px;
  justify-content: center;
  margin-top: 5px;
  min-height: 20px;
  height: min-content;
`
const ErrorText = styled.div`
  color: #D45C43
  padding-right: 5px;
  padding-left: 5px;
  white-space: pre-wrap;
`
