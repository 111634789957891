import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import 'browser/components/atomic-elements/atoms/range-input/_range-input.scss'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'

interface IRangeInputProps extends IBaseProps {
  autofocus?: boolean
  errorText?: string
  isDisabled?: boolean
  isStatic?: boolean
  max: number
  min: number
  name?: string
  onBlur?: (event: any) => void
  onChange: (arg0: any) => void
  onEnterPressed?: (event: any) => void
  onFocus?: (event: any) => void
  onMouseUp?: (event: any) => void
  onKeyPress?: (event: any) => void
  placeholder?: string
  size?: string
  step?: number
  tabIndex?: number
  errorPopoverTetherOptions?: any
  value: number
}

export class RangeInput extends React.Component<IRangeInputProps, any> {
  public static defaultProps: Partial<IRangeInputProps> = {
    errorPopoverTetherOptions: {
      attachment: 'top left',
      offset: { top: -7, left: 0 },
      targetAttachment: 'bottom left',
    },
    max: 1,
    min: 0,
    step: 0.01,
  }

  constructor(props) {
    super(props)
    this.state = {
      isFocused: false,
    }
    this.handleOnBlur = this.handleOnBlur.bind(this)
    this.handleOnFocus = this.handleOnFocus.bind(this)
  }

  public handleOnBlur(event) {
    this.setState({ isFocused: false })
    if (this.props.onBlur) {
      this.props.onBlur(event)
    }
  }

  public handleOnFocus(event) {
    if (!this.props.isDisabled) {
      this.setState({ isFocused: true })
    }
    if (this.props.onFocus) {
      this.props.onFocus(event)
    }
  }

  public renderErrorPopover() {
    // TODO(louis): Ask peter about how to add links.
    // <div dangerouslySetInnerHTML={{__html: this.props.errorText}} />
    return (
      <Popover>
        {this.props.errorText}
      </Popover>
    )
  }

  public render() {
    const {
      errorPopoverTetherOptions,
      autofocus,
      className,
      errorText,
      isStatic,
      isDisabled,
      max,
      min,
      name,
      onChange,
      onMouseUp,
      placeholder,
      step,
      tabIndex,
      value,
      size,
    } = this.props
    const sizeClassName = _.isEmpty(size) ? '' : `c-rangeInput--${size}`

    return (
      <TetherTarget
        isEnabled={this.state.isFocused && !_.isEmpty(errorText)}
        tethered={this.renderErrorPopover()}
        tetherOptions={errorPopoverTetherOptions}
        theme='error'
      >
        <div className='c-rangeInput-container'>
          <input
            autoFocus={autofocus}
            className={classNames('c-rangeInput', sizeClassName, className, {
              'c-rangeInput--error': !_.isEmpty(errorText),
              'c-rangeInput--static': isStatic,
            })}
            data-debug-id={this.props['data-debug-id']}
            disabled={isDisabled}
            key='input'
            max={max}
            min={min}
            name={name}
            onBlur={this.handleOnBlur}
            onChange={onChange}
            onMouseUp={onMouseUp}
            onFocus={this.handleOnFocus}
            placeholder={placeholder}
            readOnly={isStatic}
            step={step}
            tabIndex={tabIndex}
            type='range'
            value={value}
          />
        </div>
      </TetherTarget>
    )
  }
}
