import _ from 'lodash'
import React from 'react'

import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { Item } from '../item'
import { FilterOptionsCollection } from './filter-options-collection'
import { formatFilterValue } from './formatter'
import { IMultiSelectFilterPopoverProps, MultiSelectFilterPopover } from './popover'
import { JSONSchemaResolver } from 'shared-libs/resolvers/json-schema-resolver'

import apis from 'browser/app/models/apis'

export interface IMultiSelectFilterItemProps extends IMultiSelectFilterPopoverProps {
  filter: any
  isButton: boolean
  isClearable: boolean
  showFilterValue: boolean
  tetherOptions: object
}

export class MultiSelectFilterItem extends React.Component<IMultiSelectFilterItemProps, any> {

  public static defaultProps: Partial<IMultiSelectFilterItemProps> = {
    FilterOptionsCollection,
    optionLabeler: formatFilterValue,
  }

  public constructor(props) {
    super(props)

    this.state = {
      isSchemaLoaded: false
    }
  }

  public UNSAFE_componentWillMount() {
    const { entitySchema } = this.props

    const schemaResolver = new JSONSchemaResolver(apis)
    schemaResolver.resolveSchema(entitySchema).then(() => {
      this.setState({
        isSchemaLoaded: true
      })
    })
  }

  public render() {
    const {
      filter,
      className,
      isButton,
      isClearable,
      onClear,
      optionLabeler,
      showFilterValue,
      tetherOptions,
      entitySchema
    } = this.props
    const filterValues = filter.values || filter.value
    const negationText = filter.isNegation ? "Excluding " : ""

    if (!this.state.isSchemaLoaded) {
      return <></>
    }

    const formattedValues = optionLabeler(filter, filterValues, entitySchema)

    return (
      <TetherTarget
        automaticAdjustOffset={true}
        openOnClick={isClearable}
        tetherOptions={tetherOptions}
        tethered={this.renderPopover()}
      >
        <Item
          className={className}
          isButton={isButton}
          isClearable={isClearable}
          label={filter.label}
          onClear={onClear}
          showFilterValue={showFilterValue}
        >
          {negationText}{formattedValues}
        </Item>
      </TetherTarget>
    )
  }

  private renderPopover() {
    return (
      <MultiSelectFilterPopover
        {...this.props}
      />
    )
  }
}
