import _ from 'lodash'

export function median(values: number[]): number {
  if (_.isEmpty(values)) {
    return undefined
  } else if (values.length % 2 === 0) {
    const mid = values.length / 2
    return (values[mid - 1] + values[mid]) * 0.5
  } else {
    const mid = (values.length / 2) | 0
    return values[mid]
  }
}

export function mean(values: any[]): number {
  return _.mean(values)
}

export function stddev(values: number[]): number {
  return Math.sqrt(variance(values))
}

export function variance(values: number[]): number {
  const µ = mean(values)
  const n = _.size(values)
  return _.reduce(values, (sum, x) => sum + (x - µ) ** 2, 0) / n
}
