import Promise from 'bluebird'
import _ from 'lodash'
import React, { Component } from 'react'

import styled from 'styled-components'

import apis from 'browser/app/models/apis'
import { generateRandomAlphaNumericString } from 'browser/app/utils/utils'
import { Checkbox } from 'browser/components/atomic-elements/atoms/checkbox/checkbox'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { Panel } from 'browser/components/atomic-elements/atoms/panel'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { Select } from 'browser/components/atomic-elements/atoms/select'
import { SheetManager } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
import { Column } from 'browser/components/atomic-elements/atoms/table'
import { HtmlTable } from 'browser/components/atomic-elements/atoms/table/html-table'
import { HtmlTableRow } from 'browser/components/atomic-elements/atoms/table/html-table/row'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { LabelFormGroup } from 'browser/components/atomic-elements/molecules/label-form-group/label-form-group'
import {
  EntityCreationProgressPopover,
} from 'browser/components/atomic-elements/organisms/entity/entity-creation-progress-popover'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { DocumentTypeList } from 'browser/components/domains/firm/document-type-list'
import { createEmptyAppBundle,
  getBaseAppBundleId,
  setApplicationType } from 'browser/components/domains/firm/firm-utils'
import { AppBundleIds } from 'shared-libs/models/app-bundle'
import { Entity } from 'shared-libs/models/entity'
import { AppBundleProps,
  EdgeProps,
  EntityProps,
  FirmProps,
  GeneralSettingsProps,
  OwnerProps,
  UserProps } from 'shared-libs/models/prop-constants'
import { SchemaIds, SchemaUris } from 'shared-libs/models/schema'
import { Store } from 'shared-libs/models/store'
import { createEdge } from 'shared-libs/models/utils'

const USER_SCHEMA_ID = SchemaIds.FIRM_ADMIN

const REPEAT_PASSWORD = 'repeatPassword'

const CheckboxWrapper = styled.div`
  padding-left: 0.75rem;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
`

// Labels
const LABELS = {}
LABELS[FirmProps.LEGAL_NAME] = 'Firm Name'
LABELS[FirmProps.SSO] = 'SSO Identifiers'
LABELS[FirmProps.COMPANY_CODES] = 'Company Codes'
LABELS[UserProps.FIRST_EMAIL_VALUE] = 'Email'
LABELS[UserProps.EMAILS] = 'Email'
LABELS[UserProps.PASSWORD] = 'Password'

const ApplicationTypeOptions = [
  { label: 'DMS', value: AppBundleIds.DMS },
  { label: 'TMS', value: AppBundleIds.TMS },
]

const DomainOptions = [
  { label: 'Vector', value: 'withvector.com' },
  { label: 'Loaddocs', value: 'loaddocs.co' },
]

const CompanyTypeOptions = [
  { label: 'Customer', value: 'Customer' },
  { label: 'Portal', value: 'Portal' },
  { label: 'Trial', value: 'Trial' },
  { label: 'Demo', value: 'Demo' },
  { label: 'Test', value: 'Test' },
  { label: 'Prospect', value: 'Prospect' },
]

const SSOTypeOptions = [
  { label: 'Creative Energies', value: 'creativeEnergies' },
  { label: 'Geotab', value: 'geotab' },
  { label: 'Honeywell', value: 'honeywell' },
  { label: 'Instinct', value: 'instinct' },
  { label: 'isaac', value: 'isaac' },
  { label: 'Peoplenet', value: 'peoplenet' },
  { label: 'Platform Science', value: 'platformScience' },
  { label: 'Velocity', value: 'velocity' },
  { label: 'Zonar', value: 'zonar' },
]

const ssoTableColumn: Column[] = [
  {
    label: 'SSO Type',
    textAlign: 'left',
    width: 80,
  },
  {
    label: 'Company ID',
    textAlign: 'left',
    width: 80,
  },
]

const companyCodesTableColumn: Column[] = [
  {
    label: 'Division',
    textAlign: 'left',
    width: 80,
  },
  {
    label: 'Code',
    textAlign: 'left',
    width: 80,
  },
]

const newSSOItem = () => {
  return { type: '', companyId: '' }
}

const newCompanyCodeItem = () => {
  return { division: '', code: generateRandomAlphaNumericString(), isDefault: false }
}

interface IAddFirmPageState {
  domain: string
  firm: Entity
  appBundle: Entity
  user: Entity
  docSchemasToSave: {}
  repeatPassword: string
  errors: {
    [key: string]: string
  }
  isSaving: boolean
  allowDoctypeCustomization: boolean
}

/**
 * @prop onClose - function to be executed when the Quick Firm Setup panel closes
 */
interface IAddFirmPageProps {
  onClose: () => {}
}

/**
 * 'Quick Firm Setup' Component that displays all of the necessary inputs to create a new Firm
 *
 * @props IAddFirmPageProps
 * @props IAddFirmPageState
 */
class AddFirmPage extends Component<IAddFirmPageProps, IAddFirmPageState> {

  public static open() {
    OverlayManager.openOverlay(this, {})
  }

  private store: Store
  private nameInput
  private generalSettingsDataSet: EntityDataSource

  constructor(props) {
    super(props)

    this.store = apis.getStore()

    // Firm
    const firmSchema = this.store.getRecord(SchemaIds.FIRM)
    const firm = this.store.createRecord(firmSchema)
    const domain = 'withvector.com'
    firm.set(FirmProps.DOMAIN, domain)
    firm.set(FirmProps.SUBDOMAIN, 'app')
    firm.set(FirmProps.COMPANY_TYPE, 'Customer')
    firm.set(FirmProps.COMPANY_CODES, [{ division: '', code: generateRandomAlphaNumericString(), isDefault: true }])
    firm.set(FirmProps.SSO, [])

    // App Bundle
    const appBundle = createEmptyAppBundle()

    // User
    const userSchema = this.store.getRecord(USER_SCHEMA_ID)
    const user = this.store.createRecord(userSchema)
    user.set(UserProps.FIRST_NAME, 'Vector')
    user.set(UserProps.LAST_NAME, 'Support')
    user.set(UserProps.HAS_SEEN_JOIN_ORGANIZATION_MODAL, true)
    user.set(UserProps.IS_INVITED, false)
    user.set(UserProps.EMAILS, [{
      isVerified: true,
      label: 'Primary Email',
      value: '',
    }])

    this.state = {
      allowDoctypeCustomization: true,
      appBundle,
      docSchemasToSave: {},
      domain,
      errors: null,
      firm,
      isSaving: false,
      repeatPassword: '',
      user,
    }

    this.renderSSORow = this.renderSSORow.bind(this)
    this.renderCompanyCodeRow = this.renderCompanyCodeRow.bind(this)
  }

  public componentDidMount() {
    this.handleApplicationTypeChange(AppBundleIds.DMS)
    this.nameInput.focus()
  }

  // Rendering
  // ---------

  public render() {
    const { domain, firm, errors, user, allowDoctypeCustomization } = this.state

    const baseAppBundleId = getBaseAppBundleId(this.state.appBundle)
    return (
      <Panel
        title='Quick Firm Setup'
        onClose={this.props.onClose}
        panelFooter={this.renderFooter()}
      >
        <SheetManager>
          <FormTable className='u-bumperBottom--lg'>
            <LabelFormGroup
              errorText={errors && errors[FirmProps.LEGAL_NAME]}
              isRequired={true}
              label={LABELS[FirmProps.LEGAL_NAME]}
              onChange={this.createFirmChangeHandler(FirmProps.LEGAL_NAME)}
              value={firm.get(FirmProps.LEGAL_NAME)}
              ref={(input) => { this.nameInput = input }}
            >
              <Input />
            </LabelFormGroup>
            <LabelFormGroup
              label='Application Type'
              onChange={this.handleApplicationTypeChange}
              value={baseAppBundleId}
            >
              <Select options={ApplicationTypeOptions} />
            </LabelFormGroup>
            <LabelFormGroup
              label='Domain'
              onChange={this.createFirmChangeHandler(FirmProps.DOMAIN)}
              value={firm.get(FirmProps.DOMAIN)}
            >
              <Select options={DomainOptions} />
            </LabelFormGroup>
            <LabelFormGroup
              label='Company Type'
              onChange={this.createFirmChangeHandler(FirmProps.COMPANY_TYPE)}
              value={firm.get(FirmProps.COMPANY_TYPE)}
            >
              <Select options={CompanyTypeOptions} />
            </LabelFormGroup>
          </FormTable>

          <Section title={LABELS[FirmProps.SSO]}>
            <HtmlTable
              className='c-table--collapse c-table--reponsive'
              density='collapse'
              columns={ssoTableColumn}
              hasError={errors && this.hasErrors(FirmProps.SSO)}
              onChange={this.createFirmChangeHandler(FirmProps.SSO)}
              renderListItem={this.renderSSORow}
              emptyStateView={this.renderSSOEmptyRow()}
              showItemDeleteButton={true}
              showAddButton={true}
              createNewItem={newSSOItem}
              value={firm.get(FirmProps.SSO)}
            />
          </Section>

          <Section title={LABELS[FirmProps.COMPANY_CODES]}>
            <HtmlTable
              className='c-table--collapse c-table--reponsive'
              density='collapse'
              columns={companyCodesTableColumn}
              hasError={errors && this.hasErrors(FirmProps.COMPANY_CODES)}
              onChange={this.createFirmChangeHandler(FirmProps.COMPANY_CODES)}
              renderListItem={this.renderCompanyCodeRow}
              showItemDeleteButton={true}
              showAddButton={true}
              createNewItem={newCompanyCodeItem}
              value={firm.get(FirmProps.COMPANY_CODES)}
            />
          </Section>

          <Section title='Initial User'>
            <FormTable className='u-bumperBottom--lg'>
              <LabelFormGroup
                errorText={errors && errors[UserProps.FIRST_EMAIL_VALUE]}
                isRequired={true}
                label={LABELS[UserProps.FIRST_EMAIL_VALUE]}
                value={user.get(UserProps.FIRST_EMAIL_VALUE)}
                onChange={this.createUserChangeHandler(UserProps.FIRST_EMAIL_VALUE)}
              >
                <Input />
              </LabelFormGroup>
              <LabelFormGroup
                errorText={errors && errors[UserProps.PASSWORD]}
                isRequired={true}
                label={LABELS[UserProps.PASSWORD]}
                value={user.get(UserProps.PASSWORD)}
                onChange={this.createUserChangeHandler(UserProps.PASSWORD)}
              >
                <Input type='password' />
              </LabelFormGroup>
              <LabelFormGroup
                errorText={errors && errors[REPEAT_PASSWORD]}
                isRequired={true}
                label='Repeat Password'
                value={this.state.repeatPassword}
                onChange={this.handleRepeatPasswordChange}
              >
                <Input type='password' />
              </LabelFormGroup>
            </FormTable>
          </Section>

          <Section title='Settings'>
            <FormTable className='u-bumperBottom--lg'>
              <LabelFormGroup label='Allow Doctype Customization'>
                <CheckboxWrapper>
                  <Checkbox
                    label=''
                    value={allowDoctypeCustomization}
                    style={{ width: '0' }}
                    onChange={this.handleAllowDoctypeCustomization}
                  />
                </CheckboxWrapper>
              </LabelFormGroup>
            </FormTable>
          </Section>

          { allowDoctypeCustomization &&
            <DocumentTypeList
              appBundle={this.state.appBundle}
              docSchemasToSave={this.state.docSchemasToSave}
              onChange={this.handleDocTypeListChange}
            />
          }

        </SheetManager>
      </Panel>
    )
  }

  private renderSSORow(props): React.ReactElement<any> {
    const { item, index } = props

    const handleTypeChange = (value) => {
      item.type = value
      this.forceUpdate()
    }

    const handleCompanyIdChange = (value) => {
      item.companyId = value
      this.forceUpdate()
    }

    return (
      <HtmlTableRow
        index={index}
        isEditableInline={true}
      >
        <Select
          onChange={handleTypeChange}
          options={SSOTypeOptions}
          value={_.get(item, 'type')}
        />
        <Input
          inputClassName='tr'
          onChange={handleCompanyIdChange}
          value={_.get(item, 'companyId')}
        />
      </HtmlTableRow>
    )
  }

  private renderSSOEmptyRow(): React.ReactElement<any> {
    return (
      <HelpBlock
        className='u-textCenter collapse'
        style={{
          paddingBottom: 8,
          paddingTop: 8,
        }}
      >
        No SSO Type Selected
      </HelpBlock>
    )
  }

  private renderCompanyCodeRow(props): React.ReactElement<any> {
    const { item, index } = props

    const isInputDisabled = index === 0 ? true : false
    const placeHolderText = index === 0 ? 'Default' : 'Division Name'

    const handleDivisionChange = (value) => {
      item.division = value
      this.forceUpdate()
    }

    const handleCodeChange = (value) => {
      item.code = value
      this.forceUpdate()
    }

    return (
      <HtmlTableRow
        index={index}
        isEditableInline={true}
      >
        <Input
          isDisabled={isInputDisabled}
          placeholder={placeHolderText}
          inputClassName='tr'
          onChange={handleDivisionChange}
          value={_.get(item, 'division')}
        />
        <Input
          inputClassName='tr'
          onChange={handleCodeChange}
          value={_.get(item, 'code')}
        />
      </HtmlTableRow>
    )
  }

  private renderFooter() {
    const { isSaving, errors } = this.state
    const { onClose } = this.props
    return (
      <Footer
        errorText={errors && this.getErrorsText()}
        isPrimaryButtonLoading={isSaving}
        onCancelButtonClick={onClose}
        onPrimaryButtonClick={this.handleSave}
      />
    )
  }

  // Handlers
  // --------

  private createFirmChangeHandler = (firmProp) => {
    return (value) => {
      const firm = this.state.firm.cloneForReactState()
      firm.set(firmProp, value)
      this.setState({ firm })
    }
  }

  private createUserChangeHandler = (userProp) => {
    return (value) => {
      const user = this.state.user.cloneForReactState()
      user.set(userProp, value)
      this.setState({ user })
    }
  }

  private handleRepeatPasswordChange = (value) => {
    this.setState({ repeatPassword: value })
  }

  private handleApplicationTypeChange = (appBundleId) => {
    const appBundle = this.state.appBundle.cloneForReactState()
    setApplicationType(appBundle, appBundleId).then(() => {
      this.setState({ appBundle })
    })
  }

  private handleAllowDoctypeCustomization = (checked) => {
    this.setState( { allowDoctypeCustomization: checked })
    if (!checked) {
      // when  unchecked, revert to original
      this.handleCustomDoctypesRollbackback()
    }
  }

  private handleDocTypeListChange = (appBundle: Entity, docSchemasToSave = _.cloneDeep(this.state.docSchemasToSave)) => {
    this.setState({ appBundle, docSchemasToSave })
  }
  private handleCustomDoctypesRollbackback = () => {
    const { appBundle, docSchemasToSave } = this.state
    if (appBundle.isDirty || !_.isEmpty(docSchemasToSave)) {
      const clonedAppBundle = appBundle.cloneForReactState()
      clonedAppBundle.rollback()
      this.setState({
        appBundle: clonedAppBundle,
        docSchemasToSave: {},
      })
    }
  }

  private handleGeneralSettingDatasetChange = () => {
    const { entities } = this.generalSettingsDataSet
    const generalSettings = _.first(entities)

    if (_.get(generalSettings, 'generalSettings.allowDoctypeCustomization') !== this.state.allowDoctypeCustomization) {
      generalSettings.set('generalSettings.allowDoctypeCustomization', this.state.allowDoctypeCustomization)
      return generalSettings.save()
    }
    return Promise.reject('Unable to fetch General Settings')
  }

  private handleSave = () => {
    const { firm, user, appBundle, docSchemasToSave } = this.state
    appBundle.set(AppBundleProps.FIRM, createEdge(firm.get(EntityProps.ID)))
    appBundle.set(AppBundleProps.NAME, `${firm.get(FirmProps.LEGAL_NAME)} Bundle`)

    user.set(EntityProps.OWNER, {
      [OwnerProps.FIRM]: {
        [EdgeProps.ENTITY_ID]: firm.get(EntityProps.ID),
        [EdgeProps.DISPLAY_NAME]: firm.get(FirmProps.LEGAL_NAME),
      },
    })
    this.setState({ isSaving: true })

    Promise.resolve(this.validate(firm, appBundle, user, docSchemasToSave)).then((validateErrors) => {
      if (!_.isEmpty(validateErrors)) {
        this.setState({ errors : validateErrors, isSaving: false })
      } else {
        const docSchemaSavePromises = _.values(docSchemasToSave).map((docSchema: Entity) => {
          return docSchema.saveWithoutValidation().then(() => this.store.cacheRecord(docSchema))
        })

        /*
         *  A series of steps to create a firm
         *
         *  1. Create all the new doc types
         *  2. Create firm
         *  3. Create app bundle
         *  4. Create user admin
         *  5. Wait for firm to idle.  It serves as an indication that the server also finished
         *     creating general settings entity
         *  6. Fetch the general settings to update with the override values in the quick firm setup
         *  7. Update the field "allowDoctypeCustomization" and save it back to general settings
         */
        return Promise.all(docSchemaSavePromises)
          .then(() => firm.saveWithoutValidation())
          .then(() => appBundle.saveWithoutValidation())
          .then(() => user.saveWithoutValidation())
          .then(() => firm.waitUntilIdle()) // wait for firm to be idle to ensure GeneralSettings is created
          .then(() => {
            // need to query general settings to update it with the settings in override
            this.generalSettingsDataSet = new EntityDataSource({
              entityType: SchemaUris.GENERAL_SETTINGS,
              filters: [
                {
                  path: GeneralSettingsProps.FIRM_ID,
                  type: 'match',
                  value: firm.get(EntityProps.ID),
                },
              ],
            })
            // fetch the query from the server
            return this.generalSettingsDataSet.collection.find()
          })
          .then(() => this.handleGeneralSettingDatasetChange()) // update the general settings
          .then(() => {
            const handleSave = () => {
              return Promise.resolve(firm)
            }
            this.showUploadProgressToast(firm, { handleSave })
            this.setState({ isSaving: false })
            this.props.onClose()
          })
          .catch(({ errors }) => this.setState({ errors, isSaving: false }))
      }
    })
  }

  private async validate(firm, appBundle, user, docSchemasToSave) {
    const errors = {}

    // validate each new schema
    for (const key in docSchemasToSave) {
      const docSchema = docSchemasToSave[key]
      Object.assign(errors, await docSchema.validate())
    }

    // validate firm, appBundle, and user
    Object.assign(errors, await firm.validate(), await appBundle.validate(), await user.validate())

    // extraValidation will ensure passwords match and that the user's email is not already used
    return this.extraValidation(user).then((extraValidationErrors) => {
      Object.assign(errors, extraValidationErrors)
      return errors
    })
  }

  // TODO:EF reuse the code from entity-form-panel
  private showUploadProgressToast(entity, { handleSave, handleClose = _.noop }) {
    const proxyHandleClose = () => {
      Toast.dismiss(toastKey)
      handleClose()
    }
    const toastKey = Toast.show({
      message: (
        <EntityCreationProgressPopover
          entity={entity}
          onClose={proxyHandleClose}
          onSave={handleSave}
          redirectPath={`/view/0ff17e0a-1370-49bb-87c0-f1f4681bf8a0/entity/${entity.uniqueId}`}
          shouldRedirectOnSuccess={true}
        />
      ),
      position: Position.BOTTOM_RIGHT,
      timeout: 0,
    })
  }

  // Utils
  // -----

  private getErrorsText() {
    const errors = { ...this.state.errors }
    // if passwords do not match, error message will already be handled by password field
    delete errors[REPEAT_PASSWORD]

    return Object.keys(errors).map((errorKey) => {
      // for fields with arrays of values, we only want the parent key for our LABELS
      const propKey = errorKey.split(/\.\d+\./)[0]
      const errorLabel = LABELS[propKey] || errorKey
      const errorMessage = errors[errorKey]
      return `${errorLabel} ${errorMessage}`
    }).join(', ')
  }

  private hasErrors(propKey: string) {
    const { errors } = this.state
    for(const errorKey in errors) {
      // for fields with arrays of values, check for parent key
      if (propKey === errorKey.split(/\.\d+\./)[0]) {
        return true
      }
    }
    return false
  }

  private extraValidation(user) {
    const errors = {}

    // Ensure passwords match
    if (this.state.repeatPassword !== user.get(UserProps.PASSWORD)) {
      errors[UserProps.PASSWORD] = ['passwords do not match']
      errors[REPEAT_PASSWORD] = ['passwords do not match']
    }

    return this.validateUserEmail(user).then((userEmailErrors) => {
      Object.assign(errors, userEmailErrors)
      return errors
    })
  }

  private validateUserEmail(user) {
    return apis.getUserByEmail(user.get(UserProps.FIRST_EMAIL_VALUE)).then(() => {
      // This is bad.  This means there is a user with this email already
      return { [UserProps.FIRST_EMAIL_VALUE]: ['A user with this email already exists'] }
    }).catch((err) => {
      const errors = _.get(err, 'responseJSON.errors', [])
      if (!_.isEmpty(errors)) {
        const message = errors[0].message
        if (err.status === 400 && message === 'User not found') {
          // This is good.  This means there is not a user with this email yet.
          return {}
        }
      }
      throw err
    })
  }
}

export { AddFirmPage }
