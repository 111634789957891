import React, { useCallback } from 'react'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { WithDefaultProps } from 'browser/components/atomic-elements/higher-order-components/with-default-props'
import { CSSObject } from '@emotion/serialize'

import '../../styles/_control.scss'

import { JSONSelectFactory } from 'browser/components/json-elements/higher-order-components/json-select-factory'
import { ISelectProps } from 'browser/components/atomic-elements/atoms/select'
import { MobileModal } from 'browser/mobile/elements/modal/modal'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { IconNames } from '@blueprintjs/icons'
import { Icon } from '@blueprintjs/core'

const MobileSelectImpl: React.FC<ISelectProps> = (props: ISelectProps) => {
  const onOpen = useCallback(() => {
    open(props)
  }, [])

  return (
    <SelectField
      {...props}
      styles={{
        valueContainer: () => ({
          height: '44pt',
        }),
      }}
      onOpen={onOpen}
    />
  )
}

function open(props: ISelectProps) {
  const ModalContent = (injectedProps) => (
    <div className="pa2">
      <div className="tr">
        <Button
          onClick={() => {
            injectedProps.onClose?.()
          }}
        >
          <Icon icon={IconNames.CROSS} />
        </Button>
      </div>
      <SelectField
        autoFocus={true}
        menuIsOpen={true}
        {...props}
        onChange={(value) => {
          props.onChange?.(value)
          injectedProps.onClose?.()
        }}
        styles={{
          option: (base): CSSObject => ({
            ...base,
            height: '44pt',
          }),
          menuList: (base): CSSObject => ({
            ...base,
            maxHeight: '100%',
          }),
          menu: (base): CSSObject => ({
            ...base,
            marginTop: '4rem',
            position: 'fixed',
            top: '2rem',
            right: '1rem',
            left: '0.5rem',
            display: 'block',
            overflow: 'hidden',
            width: 'calc(100% - 1rem)',
            height: 'calc(100% - 6.5rem)',
            backgroundColor: 'white',
          }),
        }}
      />
    </div>
  )

  MobileModal.open(<ModalContent />, {
    modalDialogClassName: 'overflow-hidden',
  })
}

const UISelectField = JSONSelectFactory(MobileSelectImpl)

export const MobileSelectField = WithDefaultProps(UISelectField, {
  inputClassName: 'mobile-control',
})
