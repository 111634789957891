import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/section/_section.scss'
import { SectionHeader } from 'browser/components/atomic-elements/atoms/section/section-header/section-header'
import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { translateString } from 'shared-libs/helpers/utils'

/**
 * @uiComponent
 */
interface ISectionProps extends IBaseProps {
  frames?: FramesManager
  bodyClassName?: string
  footerClassName?: string
  footerControls?: React.ReactElement<any>
  isCollapsable?: boolean
  isCollapsed?: boolean
  headerClassName?: string
  headerStyle?: object
  headerControls?: React.ReactElement<any>
  hideEmptyFields?: boolean
  hideHeaderBorder?: boolean
  optionsPopoverList?: React.ReactElement<any>
  optionsPopoverTetherOptions?: object
  reduceMargin: boolean
  showHideEmptyFieldsControls?: boolean
  subtitle?: string
  title?: string
  titleElement?: React.ReactElement<any>
}

interface ISectionStates {
  isCollapsed: boolean
  hideEmptyFields: boolean
}

export class Section extends React.Component<ISectionProps, ISectionStates> {
  public static defaultProps: ISectionProps = {
    isCollapsed: false,
    reduceMargin: false,
    showHideEmptyFieldsControls: false,
    headerStyle: {},
  }

  constructor(props) {
    super(props)
    this.state = {
      hideEmptyFields: this.props.hideEmptyFields,
      isCollapsed: this.props.isCollapsed,
    }
    this.handleIsCollapsedChange = this.handleIsCollapsedChange.bind(this)
    this.handleHideEmptyFieldsChange = this.handleHideEmptyFieldsChange.bind(this)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isCollapsed != this.props.isCollapsed) {
      this.setState({
        isCollapsed: nextProps.isCollapsed
      })
    }
  }

  public render() {
    const {
      frames,
      className,
      headerClassName,
      headerStyle,
      hideHeaderBorder,
      isCollapsable,
      showHideEmptyFieldsControls,
      title,
      subtitle,
      headerControls,
      optionsPopoverList,
      optionsPopoverTetherOptions,
      bodyClassName,
      titleElement,
      children,
      reduceMargin
    } = this.props
    const {
      isCollapsed,
      hideEmptyFields,
    } = this.state
    const margin = reduceMargin ? 'mb2' : 'mb4'

    const translationTable = frames?.getContext('translationTable')
    const translatedLabel = translateString(title, translationTable)

    return (
      <div className={classNames('c-section mb2', margin, className)}>
        <SectionHeader
          className={headerClassName}
          isCollapsable={isCollapsable}
          hideHeaderBorder={hideHeaderBorder}
          isCollapsed={isCollapsed}
          onIsCollapsedChange={this.handleIsCollapsedChange}
          onHideEmptyFieldsChange={this.handleHideEmptyFieldsChange}
          showHideEmptyFieldsControls={showHideEmptyFieldsControls}
          hideEmptyFields={hideEmptyFields}
          title={translatedLabel}
          titleElement={titleElement}
          subtitle={subtitle}
          headerControls={headerControls}
          optionsPopoverList={optionsPopoverList}
          optionsPopoverTetherOptions={optionsPopoverTetherOptions}
          style={headerStyle}
        />
        <div
          className={classNames('c-sectionBody', bodyClassName, {
            'is-collapsed': isCollapsed,
            'is-hideEmptyFields': hideEmptyFields,
          })}
        >
          {children}
        </div>
        {this.renderSectionFooter()}
      </div>
    )
  }

  private handleIsCollapsedChange(value) {
    this.setState({isCollapsed: value})
  }

  private handleHideEmptyFieldsChange(value) {
    this.setState({hideEmptyFields: value})
  }

  private renderSectionFooter() {
    const {
      footerControls,
      footerClassName,
    } = this.props

    if (footerControls) {
      return (
        <div className={classNames('c-sectionFooter', footerClassName)}>
          {footerControls}
        </div>
      )
    }
  }
}
