import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

/**
 * @uiComponent
 */
interface ISeparatorProps extends IBaseProps {}

export const Separator: React.FC<ISeparatorProps> = (props) => {
  const { className } = props
  return (
    <hr className={classNames('c-sectionBody-separator', className)} />
  )
}
