import _ from 'lodash'

import apis from 'browser/app/models/apis'
import { Entity } from 'shared-libs/models/entity'
import { Collection } from 'shared-libs/models/collection'
import { Query } from 'shared-libs/models/query'
import { VisibilityTimer } from 'browser/app/utils/visibility-timer'
import moment from 'moment'

const SCROLLING_THRESHOLD = 10

export class EntityDataSource {

  public static getDefaultProperties() {
    return {
      filters: [],
      orders: [
        {
          label: 'Posted date',
          path: 'creationDate',
          type: 'descending',
        },
      ],
    }
  }

  public collection: any
  public entitySchema: any

  private debouncedFind: () => void
  private onChange: (value: any) => void
  private refreshInterval: number
  public timer?: VisibilityTimer

  private enablePrefetch: boolean
  private prefetchCollection: Collection
  private lastSyncedTimestamp: any

  private pauseKey: string

  constructor(query, queryObj: Query=null) {
    const props = {
      ...EntityDataSource.getDefaultProperties(),
      ...query,
    }
    this.refreshInterval = props.debug?.refreshInterval || props.refreshInterval

    // value 'enablePrefetch' only apply for non-zero positive 'refreshInterval' value.  It
    // will help do a simple and quick Elastic Query fetch to determine if there is different
    // before doing a more expensive full-blown query with entity values
    this.enablePrefetch = _.get(query, 'enablePrefetch', false)

    const store = apis.getStore()
    if (queryObj) {
      this.collection = queryObj.getCollection()
    } else {
      this.collection = new Query(apis)
        .setEntityType(props.entityType)
        .setComputations(props.computations)
        .setFilters(props.filters)
        .setGroups(props.groups)
        .setOrders(props.orders)
        .setQuery(props.query)
        .setMetadata(props.metadata)
        .setDebug(props.debug)
        .setShouldStripMetadata(false)
        .getCollection()
    }

    this.entitySchema = store.getRecord(props.entityType)
    this.onChange = _.noop
    // debounce find to group query together e.g. if
    // handleFiltersChange and handleOrdersChange are called immediately
    // back to back
    this.debouncedFind = _.debounce(this.find, 0)

    if (props.type === 'dataSource:capacityOrderMatching') {
      const { capacity } = props
      const { origin, destination } = capacity.capacity
      const filters = [
        {
          path: 'precomputation.lane.origin.geolocation',
          type: 'geoIntersect',
          value: origin,
        },
        {
          label: 'Status',
          path: 'dispatchOrder.status.secondary',
          type: 'match',
          values: ['Available', 'Draft'],
        },
      ]
      if (destination) {
        filters.push({
          path: 'precomputation.lane.destination.geolocation',
          type: 'geoIntersect',
          value: destination,
        })
      }
      this.collection.query.setFilters(filters)
    }

    // setup refresh interval
    if (!_.isNil(this.refreshInterval)) {
      if (this.enablePrefetch) {
        // setup the pre-fetch query
        const refreshQuery = this.collection.query.clone()
        refreshQuery.setSize(1)
        this.prefetchCollection = refreshQuery.getCollection()
        this.handlePrefetchCollectionChange = this.handlePrefetchCollectionChange.bind(this)
      }

      this.timer = new VisibilityTimer(this.handleRefresh, this.refreshInterval)
    }

    this.collection.addUpdateListener(this.handleCollectionChange)
  }

  // pass through some variables

  public get computations() {
    return this.collection.query.computations
  }

  public get content() {
    return this.collection.content
  }

  public get count() {
    return this.collection.count
  }

  public get entities(): Entity[] {
    return this.collection.entities
  }

  public get filters() {
    return this.collection.query.filters
  }

  public get groups() {
    return this.collection.query.groups
  }

  public get isLoading() {
    // isLoading is true when it is loading initially
    const result = this.result
    return _.isEmpty(result) && this.collection.isLoading
  }

  public get query() {
    return this.collection.query
  }

  public get orders() {
    return this.collection.query.orders
  }

  public get metadata() {
    return this.collection.query.metadata
  }

  public get result() {
    return this.collection.result
  }

  public dispose() {
    this.timer && this.timer.dispose()

    this.collection.dispose()
    // It is important to set onChange to noop once we dispose the data set so
    // we don't call onChange on an unmounted component
    this.onChange = _.noop
  }

  public find(): void {
    this.lastSyncedTimestamp = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS')
    this.collection.cancel()

    if (this.timer) {
      this.timer.resumeDelayed(this.pauseKey)
      this.pauseKey = undefined
    }

    return this.collection.find().then((content) => {
      if (!content) {
        return undefined
      }

      this.handleCollectionChange(content)
      return content
    })
  }

  public handleFiltersChange = (filters): void => {
    const query = this.collection.query
    query.setFilters(filters).setOffset(0)
    this.debouncedFind()
  }

  public handleGroupsChange = (groups): void => {
    const query = this.collection.query
    query.setGroups(groups).setOffset(0)
    this.debouncedFind()
  }

  public hasNext() {
    return this.collection.hasNext()
  }

  public handleLoadNextPage = (): Promise<any> => {
    if (!this.collection.hasNext() || this.collection.isLoading) {
      return
    }

    if (this.timer && !this.pauseKey) {
      this.pauseKey = this.timer.pause('infinite scroll')
    }

    return this.collection.next().then(this.handleCollectionChange)
  }

  public considerLoadingNextPage = (currentIndex) => {
    if (currentIndex > (this.content.length - SCROLLING_THRESHOLD)) {
      this.handleLoadNextPage()
    }
  }

  public handleOrdersChange = (orders): void => {
    const query = this.collection.query
    query.setOrders(orders).setOffset(0)
    this.debouncedFind()
  }

  public handleMetadataChange = (metadata): void => {
    const query = this.collection.query
    query.setMetadata(metadata).setOffset(0)
    this.debouncedFind()
  }

  public handlePaginateGroup = (groupRows: any[]): void => {
    if (this.timer && !this.pauseKey) {
      this.pauseKey = this.timer.pause('group expansion')
    }

    return this.collection.paginateGroup(groupRows).then(this.handleCollectionChange)
  }

  private handleRefresh = () => {
    return _.isEmpty(this.prefetchCollection)
      ? this.find()
      : this.prefetch()
  }

  public setFilters(filters): EntityDataSource {
    const query = this.collection.query
    query.setFilters(filters).setOffset(0)
    return this
  }

  public setOnChange(onChange): EntityDataSource {
    this.onChange = onChange
    return this
  }

  public clone(overrides = {}) {
    const params = {
      refreshInterval: this.refreshInterval,
      entityType: this.query.entityMetadata.id,
      computations: this.query.computations,
      filters: this.query.filters,
      groups: this.query.groups,
      orders: this.query.orders,
      metadata: this.query.metadata
    }
    _.assign(params, overrides)

    return new EntityDataSource(params)
  }

  /**
   * a light weight pre-fetch to determine if there is any changes before performing a more
   * expensive query
   */
  private prefetch() {
    const filters = [...this.collection.query.filters]

    filters.push({
        path: 'modifiedDate',
        type: 'range',
        valueType: 'date',
        gte: this.lastSyncedTimestamp,
    })

    this.prefetchCollection.query.setFilters(filters)
    this.prefetchCollection.cancel()
    return this.prefetchCollection.find().then(this.handlePrefetchCollectionChange)
  }

  private handleCollectionChange = (content): void => {
    this.onChange(content)
  }

  private handlePrefetchCollectionChange = (content): void => {
    if (this.prefetchCollection.count > 0) {
      this.find()
    }
  }

}
