import _ from 'lodash'
import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { CustomFormulas } from 'shared-libs/helpers/formulas'
import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { evaluateExpressionWithScopes } from 'shared-libs/helpers/evaluation'
import LabelFieldFactory from 'browser/components/atomic-elements/higher-order-components/label-field-factory'

/**
 * @uiComponent
 */
interface IRadioButtonGroupProps {
  frames: FramesManager
  onChange?: (value: any, option?: any) => void
  options?: any[]
  entity?: any
  items: any
  value: any
  /**
   * Enables mapping the selected value. Note that this can disrupt the ability
   * for the component to restore the selection state after a page reload, since
   * the mapped value won't match the statically defined options.
   */
  selectionOptions?: { 
    formula: string 
  }
  containerClassName?: string
  containerStyle?: CSSProperties
  itemContainerClassName?: string
  itemDotClassName?: string
  itemInnerDotClassName?: string
  itemLabelClassName?: string
  itemContainerStyle?: CSSProperties
  itemDotStyle?: CSSProperties
  itemInnerDotStyle?: CSSProperties
  itemLabelStyle?: CSSProperties
}

interface IRadioButtonGroupState {
  selectedIndex?: number
}

export class RadioButtonGroup extends React.Component<IRadioButtonGroupProps, IRadioButtonGroupState> {
  constructor(props: IRadioButtonGroupProps) {
    super(props)
    this.state = {
      selectedIndex: this.getSelectedIndex(props),
    }
  }

  public componentDidUpdate(prevProps: IRadioButtonGroupProps): void {
    if (prevProps.value !== this.props.value) {
      this.setState({
        selectedIndex: this.getSelectedIndex(this.props),
      })
    }
  }

  public render(): React.ReactNode {
    const { options, containerClassName, containerStyle } = this.props

    if (_.isEmpty(options)) {
      return null
    }

    return (
      <div
        className={classNames('flex flex-column items-start', containerClassName)}
        style={{ ...containerStyle }}
      >
        {options.map((option, index) => this.renderItem(options, option, index))}
      </div>
    )
  }

  private getSelectedIndex(props: IRadioButtonGroupProps): number {
    return _.findIndex(props.options, (option) => {
      const optionValue = option?.value ?? option
      return optionValue === props.value
    })
  }

  private renderItem = (options: any[], option: any, index: number): React.ReactNode => {
    const {
      items,
      itemContainerClassName,
      itemContainerStyle,
      itemDotClassName,
      itemDotStyle,
      itemInnerDotClassName,
      itemInnerDotStyle,
      itemLabelClassName,
      itemLabelStyle,
    } = this.props
    const { selectedIndex } = this.state

    if (items) {
      return this.renderItemFromSchema(options, option, index)
    } else {
      return (
        <RadioButton
          key={index}
          frames={this.props.frames}
          option={option}
          isSelected={selectedIndex === index}
          onClick={() => this.handleItemClick(option, index)}
          containerClassName={itemContainerClassName}
          containerStyle={itemContainerStyle}
          dotClassName={itemDotClassName}
          dotStyle={itemDotStyle}
          innerDotClassName={itemInnerDotClassName}
          innerDotStyle={itemInnerDotStyle}
          labelClassName={itemLabelClassName}
          labelStyle={itemLabelStyle}
        />
      )
    }
  }

  private renderItemFromSchema = (options: any[], option: any, index: number): React.ReactNode => {
    const { selectedIndex } = this.state
    const { frames, selectionOptions } = this.props
    const renderer = frames.getContext('renderer')
    const uiSchema = frames.getContext('uiSchema').items
    const uiSchemaPath = frames.getContext('uiSchemaPath').concat('items')
    const dataSchemaPath = frames.getContext('dataSchemaPath')
    const dataSchema = frames.getContext('dataSchema')
    const valuePath = frames.getContext('valuePath')
  
    const context = {
      dataSchema,
      dataSchemaPath,
      uiSchema,
      uiSchemaPath,
      valuePath,
    }
    const state = [option, { item: option, items: options, index, selectedIndex }]
    const newFrames = renderer.createChildFrame(frames, context, state)
    return renderer.createElementFromFrame(newFrames, {
      key: index,
      index,
      onClick: () => this.handleItemClick(option, index),
      selectionOptions,
    })
  }

  private handleItemClick = (option: any, index: number): void => {
    const { entity, frames, selectionOptions, onChange } = this.props
    this.setState({ selectedIndex: index })

    if (!entity) {
      return
    }

    const optionValue = option?.value ?? option
    const value = selectionOptions?.formula
      ? evaluateExpressionWithScopes(frames, selectionOptions.formula, {
          ...CustomFormulas,
          item: optionValue,
          index,
        })
      : optionValue

    onChange?.(value)
  }
}

interface IRadioButtonProps {
  frames?: FramesManager
  option: any | any[]
  isSelected: boolean
  onClick: () => void

  containerClassName?: string
  dotClassName?: string
  innerDotClassName?: string
  labelClassName?: string
  containerStyle?: CSSProperties
  dotStyle?: CSSProperties
  innerDotStyle?: CSSProperties
  labelStyle?: CSSProperties
}

class RadioButton extends React.Component<IRadioButtonProps> {
  public render(): React.ReactNode {
     const {
       option,
       onClick,
       isSelected,
       containerClassName,
       dotClassName,
       innerDotClassName,
       labelClassName,
       containerStyle,
       dotStyle,
       innerDotStyle,
       labelStyle,
     } = this.props
    return (
      <div
        className={classNames('flex flex-rows items-center pa2', containerClassName)}
        style={{
          ...containerStyle,
        }}
        onClick={onClick}
      >
        <span
          className={dotClassName}
          style={{
            ...styles.dot,
            ...dotStyle,
          }}
        >
          {isSelected && (
            <span
              className={innerDotClassName}
              style={{
                ...styles.innerDot,
                ...innerDotStyle,
              }}
            />
          )}
        </span>
        <span
          className={labelClassName}
          style={{
            ...labelStyle,
          }}
        >
          {option?.label ?? option}
        </span>
      </div>
    )
  }
}

export const RadioButtonGroupField = LabelFieldFactory({ InputComponent: RadioButtonGroup })

const styles: Record<string, CSSProperties> = {
  dot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 16,
    width: 16,
    borderRadius: '50%',
    borderWidth: 1,
    borderColor: '#424242',
    borderStyle: 'solid',
    marginRight: 8,
  },
  innerDot: {
    backgroundColor: '#424242',
    height: 8,
    width: 8,
    borderRadius: '50%',
  },
}
