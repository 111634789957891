import _ from 'lodash'
import * as React from 'react'

import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'

export function fetchEdges(store, partialPath, object, promises) {
  const current = _.get(object, partialPath)
  if (!_.isPlainObject(current)) {
    return
  }
  _.forEach(current, (value, key) => {
    if (_.isPlainObject(value)) {
      const path = partialPath.concat(key)
      if (value.entityId) {
        promises.push(store.findRecord(value.entityId).then((entity) => {
          _.set(object, path, entity)
        }))
      } else {
        fetchEdges(store, path, object, promises)
      }
    } else if (_.isArray(value)) {
      _.forEach(value, (item, index) => {
        const path = partialPath.concat([key, index])
        fetchEdges(store, path, object, promises)
      })
    }
  })
}

export function renderContact(contact) {
  if (!contact) {
    return null
  }
  const phones = _.get(contact, 'person.phoneNumbers', [])
  const emails = _.get(contact, 'person.emails', [])
  return (
    <div>
      {getDisplayName(contact)}<br />
      {renderPhonesAndEmails(phones, emails)}
    </div>
  )
}

export function getDisplayName(entity) {
  return entity ? entity.displayName : '-'
}

export function renderIdentifier(label, identifier, index) {
  if (label.includes('Customer Load Number')) {
    return
  }
  return (
    <FormGroup

      isHorizontalLayout={true}
      key={index}
    >
      <Label
        size='sm'
        isHorizontalLayout={true}
      >
        {label}
      </Label>
      <div className='c-fakeInputContainer--sm'>
        {identifier}
      </div>
    </FormGroup>
  )
}

export function renderLocation(location) {
  if (_.isEmpty(location)) {
    return
  }
  const key = 'location.address'
  const address = location.denormalizedProperties ?
    location.denormalizedProperties[key] : _.get(location, key)

  // TODO(louis): Do not fully understand this
  // {location.displayName} = 'Embedded Address'
  return (
    <span>
      {address.streetAddress}<br />
      {renderExtendedAddress(address)}
      {address.locality}, {address.region} {address.postalCode}
    </span>
  )
}

function renderExtendedAddress(address) {
  if (!address.extendedAddress) {
    return null
  }
  return (
    <div>
      {address.extendedAddress} <br />
    </div>
  )
}

export function renderPhonesAndEmails(phones, emails) {
  const rows = []
  _.forEach(phones, (phone) => {
    rows.push(<span>{`${phone.label}: ${phone.value.phone}`}</span>)
    rows.push(<br />)
  })
  _.forEach(emails, (email) => {
    rows.push(<span>{`${email.label}: ${email.value}`}</span>)
    rows.push(<br />)
  })
  return _.map(rows, (element, index) => {
    return React.cloneElement(element, { key: index })
  })
}

export function renderSignatureLines(nameLabel) {
  return (
    <div className='row'>
      <div className='col-xs-12'>
        <div className='row mt5'>
          <div className='col-xs-4'>
            <hr className='bt b--gray' />
            {nameLabel}
          </div>
          <div className='col-xs-4'>
            <hr className='bt b--gray' />
            Signature
          </div>
          <div className='col-xs-2'>
            <hr className='bt b--gray' />
            Date
          </div>
        </div>
      </div>
    </div>
  )
}

export function renderTempRange(order, trailerType) {
  const { dispatchOrder } = order

  if (trailerType === 'Reefer') {
    // tslint:disable-next-line:max-line-length
    const tempRange = _.get(dispatchOrder, 'temperatureRange.min.value') + ' to ' + _.get(dispatchOrder, 'temperatureRange.max.value') + ' ' + _.get(dispatchOrder, 'temperatureRange.max.unit')

    return (
      <FormGroup

        isHorizontalLayout={true}
      >
        <Label
          size='sm'
          isHorizontalLayout={true}
        >
          Temperature Range
        </Label>
        <div className='c-fakeInputContainer--sm'>
          {tempRange}
        </div>
      </FormGroup>
    )
  }
}
