import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { PrintContainer } from 'browser/components/atomic-elements/atoms/print-container/print-container'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import {
  fetchEdges,
  renderIdentifier,
  renderTempRange,
} from 'browser/components/atomic-elements/domains/trucking/generated-document-helper'
import { StopCargoTable } from 'browser/components/atomic-elements/domains/trucking/stop-cargo-table/stop-cargo-table'

interface IDriverDispatchInstructionsProps extends IBaseProps {
  entity: any
  settings: Settings
}

interface IDriverDispatchInstructionsState {
  firm: any
  isLoading: boolean
  orderContent: any
  tmsSettings: any
}

// tslint:disable-next-line:max-line-length
class DriverDispatchInstructions extends React.Component<IDriverDispatchInstructionsProps, IDriverDispatchInstructionsState> {

  constructor(props) {
    super(props)
    this.state = {
      firm: null,
      isLoading: true,
      orderContent: null,
      tmsSettings: null,
    }
  }

  public componentDidMount() {
    const { entity, settings } = this.props
    const promises = []
    const store = apis.getStore()
    const orderContent = _.cloneDeep(entity.content)
    fetchEdges(store, ['dispatchOrder'], orderContent, promises)
    fetchEdges(store, ['brokerOrder'], orderContent, promises)
    fetchEdges(store, ['carrierOrder'], orderContent, promises)
    Promise.all(promises).then(() => {
      this.setState({
        firm: settings.getFirm(),
        isLoading: false,
        orderContent,
        tmsSettings: settings.getTMSSettings(),
      })
    })
  }

  public render() {
    const { isLoading } = this.state
    if (isLoading) {
      return this.renderLoading()
    }
    // TODO(peter/louis): need to pull out print frame to generalized view
    // and then there also handle the print modal
    return (
      <div className='c-printFrame'>
        {this.renderRateConfirmation()}
      </div>
    )
  }

  private renderLoading() {
    return (
      <div className='c-printFrame'>
        <Head
          title='Loading'
        />
        <div className='grid-block'>
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  private renderRateConfirmation() {
    const { firm, orderContent } = this.state
    const { settings } = this.props
    const { brokerOrder, dispatchOrder, carrierOrder } = orderContent
    const order = settings.isBrokerTMSApplication() ? brokerOrder : carrierOrder

    const brokerLogo = firm.get('business.logo.uri', '')
    const brokerAddress = _.first(firm.get('business.addresses', []))

    const user = settings.getUser()
    const userPhones = user.get('person.phoneNumbers', [])
    const userEmails = user.get('person.emails', [])

    const todaysDate = moment().format('ddd, MMM DD, YYYY')

    const { identifier, trailerType } = dispatchOrder
    const title = `${identifier} - Driver Dispatch Instructions`
    const primaryIdentifierName = 'Load Number'

    const origin = dispatchOrder.stops[0]
    const destination = dispatchOrder.stops[dispatchOrder.stops.length - 1]

    return (
      <PrintContainer>
        <Head
          title={title}
        />
        <div className='c-printPage c-printPage--portrait'>
          <div className='c-printPageInner'>

            <div className='row'>
              <div className='col-xs-6'>
                <div className='f2 lh-title'>
                  Driver Dispatch
                </div>
                <div className='u-bumperBottom'>
                  {primaryIdentifierName}: {identifier} &middot; Issued: {todaysDate}
                </div>
              </div>
              <div className='col-xs-6 tr'>
                <img
                  className='c-printLogo u-bumperBottom'
                  src={brokerLogo}
                />
                {/*  {firm.displayName} */}
              </div>
            </div>

            <Section
              title='Details'
            >
              <div className='row'>
                <div className='col-xs-6'>
                  <FormTable>
                    <FormGroup isHorizontalLayout={true}>
                      <Label
                        size='sm'
                        isHorizontalLayout={true}
                      >
                        {primaryIdentifierName}
                      </Label>
                      <div className='c-fakeInputContainer--sm b'>
                        {identifier}
                      </div>
                    </FormGroup>
                    {_.map(dispatchOrder.additionalIdentifiers, (additionalIdentifier: any, index) => {
                      return renderIdentifier(additionalIdentifier.label, additionalIdentifier.value, index)
                    })}
                    <FormGroup isHorizontalLayout={true}>
                      <Label
                        size='sm'
                        isHorizontalLayout={true}
                      >
                        Trailer Type
                      </Label>
                      <div className='c-fakeInputContainer--sm'>
                        {trailerType}
                      </div>
                    </FormGroup>
                    {renderTempRange(order, trailerType)}
                  </FormTable>
                </div>
                <div className='col-xs-6'>
                  <FormTable>
                    <FormGroup isHorizontalLayout={true}>
                      <Label
                        size='sm'
                        isHorizontalLayout={true}
                      >
                        Dispatch Notes
                      </Label>
                      <div className='c-fakeInputContainer--sm'>
                        {dispatchOrder.notes}
                      </div>
                    </FormGroup>
                  </FormTable>
                </div>
              </div>

              <StopCargoTable
                className='u-bumperTop'
                order={order}
                dispatchOrder={dispatchOrder}
              />
            </Section>
          </div>
        </div>
      </PrintContainer>
    )
  }
}

export default React.forwardRef((props: IDriverDispatchInstructionsProps, ref: React.Ref<DriverDispatchInstructions>) => (
  <AppNavigatorContext.Consumer>
    {({ settings }) => <DriverDispatchInstructions {...props} settings={settings} ref={ref} />}
  </AppNavigatorContext.Consumer>
))
