declare let window: any

import _ from 'lodash'
import React from 'react'
import { findDOMNode } from 'react-dom'
const d3 = require('d3')

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface IPolygonMaskProps extends IBaseProps {
  corners: any
  handleRadius?: number
  isDraggable?: boolean
  isRectangle?: boolean
  maxHeight?: number
  maxWidth?: number
  onChange: (value: any) => void
}

export class PolygonMask extends React.PureComponent<IPolygonMaskProps, any> {

  public static defaultProps: Partial<IPolygonMaskProps> = {
    handleRadius: 8,
  }

  public componentDidMount() {
    const { isRectangle } = this.props
    const domNode = d3.select(findDOMNode(this))
    const polygonMask = this
    function handlePolygonDrag() {
      polygonMask.handlePolygonDrag(d3.event)
    }
    function handleDragHandle() {
      const corner = this.getAttribute('data-corner')
      polygonMask.handleHandleDrag(corner, d3.event)
    }
    const dragPolygon = d3.drag()
      .on('drag', handlePolygonDrag)
      .on('end', handlePolygonDrag)
    const dragHandle = d3.drag()
      .on('drag', handleDragHandle)
      .on('end', handleDragHandle)
    domNode.selectAll('.handle').call(dragHandle)
    if (isRectangle) {
      domNode.select('.polygon').call(dragPolygon)
    }
  }

  public render() {
    const { corners } = this.props
    return (
      <g>
        {this.renderPolygon(corners)}
        {this.renderHandle('topLeft', corners)}
        {this.renderHandle('topRight', corners)}
        {this.renderHandle('bottomRight', corners)}
        {this.renderHandle('bottomLeft', corners)}
      </g>
    )
  }

  private renderPolygon(corners) {
    return (
      <path
        className='polygon'
        d={`M${corners.topLeft.x},${corners.topLeft.y} ` +
           `${corners.topRight.x},${corners.topRight.y} ` +
           `${corners.bottomRight.x},${corners.bottomRight.y} ` +
           `${corners.bottomLeft.x},${corners.bottomLeft.y}`}
        fill={'rgba(248, 153, 57, 0.5)'}
      />
    )
  }

  private renderHandle(name, corners) {
    const { handleRadius } = this.props
    const x = corners[name].x
    const y = corners[name].y
    return (
      <circle
        className='handle'
        data-corner={name}
        cx={x}
        cy={y}
        stroke={'#f89939'}
        fill={'#fff'}
        strokeWidth={3}
        r={handleRadius}
      />
    )
  }

  private handlePolygonDrag(event) {
    const { corners, maxHeight, maxWidth } = this.props
    const result: any = {}
    let isValid = true
    _.forEach(corners, (corner: any, key) => {
      const x = corner.x + event.dx
      const y = corner.y + event.dy
      result[key] = { x, y }
      isValid = isValid && x >= 0 && x < maxWidth && y >= 0 && y < maxHeight
    })
    if (isValid) { _.assign(corners, result) }
    this.props.onChange(corners)
  }

  private handleHandleDrag(corner, event) {
    const { corners, maxHeight, maxWidth } = this.props
    corners[corner].x = Math.min(Math.max(0, event.x), maxWidth)
    corners[corner].y = Math.min(Math.max(0, event.y), maxHeight)
    this.ensureRectangleShape(corner)
    this.props.onChange(corners)
  }

  private ensureRectangleShape(corner) {
    const { corners, isRectangle } = this.props
    if (!isRectangle) { return }
    const current = corners[corner]
    if (corner === 'topLeft') {
      corners.bottomLeft.x = current.x
      corners.topRight.y = current.y
    } else if (corner === 'topRight') {
      corners.bottomRight.x = current.x
      corners.topLeft.y = current.y
    } else if (corner === 'bottomLeft') {
      corners.topLeft.x = current.x
      corners.bottomRight.y = current.y
    } else if (corner === 'bottomRight') {
      corners.topRight.x = current.x
      corners.bottomLeft.y = current.y
    }
  }
}
