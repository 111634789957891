import React, { Component } from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { MergeUsersDialog } from 'browser/components/domain/user/merge-users-dialog'

/**
 * @uiComponent
 */
interface IMergeUsersButtonProps extends IBaseProps {
  initialTargetUser: object
}

export class MergeUsersButton extends Component<IMergeUsersButtonProps> {

  public render() {
    return (
      <Button buttonText='Merge' onClick={this.handleClick} />
    )
  }

  private handleClick = () => {
    const { initialTargetUser } = this.props
    MergeUsersDialog.open({ initialTargetUser })
  }
}
