import _ from 'lodash'
import React from 'react'

import { BaseMap, IBaseMapProps } from './base-map'
import { CanvasLineLayer } from './layers/canvas-line-layer'
import { MarkerLayer } from './layers/marker-layer'
import { LocationMarker } from './markers/location-marker'

const location = {
  latitude: 39,
  longitude: -95,
}

interface ILaneMapProps extends IBaseMapProps {
  lanes: any[]
  selection: any[]
  width?: number
  height?: number
  isZoomControlsEnabled?: boolean
}

export class LaneMap extends React.Component<ILaneMapProps, any> {
  public static defaultProps: Partial<ILaneMapProps> = {
    defaultLocation: undefined,
    zoomLevel: 3.25,
  }

  constructor(props) {
    super(props)
    const { locations, paths } = this.getLocationsFromLanes(props.lanes)
    const { selectionMap } = this.getSelectionMap(props.selection)
    this.state = {
      locations,
      paths,
      selectionMap,
      viewport: {
        isDragging: false,
        latitude: location.latitude,
        longitude: location.longitude,
        startDragLngLat: null,
        zoom: props.zoomLevel,
      },
    }
  }

  public componentDidMount() {
    this.setState({ isLoading: false })
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.lanes !== nextProps.lanes) {
      this.setState(this.getLocationsFromLanes(nextProps.lanes))
    }
    if (this.props.selection !== nextProps.selection) {
      this.setState(this.getSelectionMap(nextProps.selection))
    }
  }

  public getLocationsFromLanes(lanes) {
    const paths = []
    const locations = {}
    _.forEach(lanes, (lane) => {
      const origin = _.get(lane, 'origin') as any
      const destination = _.get(lane, 'destination') as any
      if (!locations[origin.identifier]) {
        locations[origin.identifier] = {
          address: origin, inboundLanes: [], outboundLanes: [],
        }
      }
      if (!locations[destination.identifier]) {
        locations[destination.identifier] = {
          address: destination, inboundLanes: [], outboundLanes: [],
        }
      }
      locations[origin.identifier].outboundLanes.push(lane)
      locations[destination.identifier].inboundLanes.push(lane)

      // Sometimes we fail to add geolocation to origin and destination
      // because google geo rate limits us
      if (_.isEmpty(origin.geolocation) || _.isEmpty(destination.geolocation)) {
        return
      }
      paths.push({
        destination,
        origin,
        points: [
          [origin.geolocation.longitude, origin.geolocation.latitude],
          [destination.geolocation.longitude, destination.geolocation.latitude],
        ],
      })
    })
    return { locations, paths }
  }

  public getSelectionMap(selection) {
    const selectionMap = {}
    _.forEach(selection, (lane) => {
      const origin = _.get(lane.data, 'origin') as any
      const destination = _.get(lane.data, 'destination') as any
      selectionMap[`${origin.identifier}:${destination.identifier}`] = lane
    })
    return { selectionMap }
  }

  public render() {
    const { viewport } = this.state
    const mapProps = {...viewport, ...this.props}
    return (
      <BaseMap
        {...mapProps}
        onViewportChange={this.handleViewportChanged}
      >
        {this.renderLanesLayer(viewport)}
        {this.renderLocationMarkerLayer(viewport)}
      </BaseMap>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleViewportChanged = (opt) => {
    this.setState({
      viewport: {
        isDragging: opt.isDragging,
        latitude: opt.latitude,
        longitude: opt.longitude,
        startDragLngLat: opt.startDragLngLat,
        zoom: opt.zoom,
      },
    })
  }

  private renderLocationMarkerLayer(viewport) {
    const { width, height } = this.props
    const { locations } = this.state
    const modifiers = (entity) => ({})
    const data = _.values(locations)
    // Note: width and height need to be kept in sync with .c-locationMarker
    return (
      <MarkerLayer
        entities={data}
        geolocationPath='address.geolocation'
        MarkerComponent={LocationMarker}
        markersHeight={33}
        markersWidth={22}
        modifiers={modifiers}
        onViewportChange={this.handleViewportChanged}
        width={width}
        height={height}
        viewport={viewport}
      />
    )
  }

  private renderLanesLayer(viewport) {
    const mapProps = { ...viewport, ...this.props }
    const { paths } = this.state
    const lines = []
    const trailColor = '#4a90e2'
    _.forEach(paths, (path) => {
      lines.push({
        color: trailColor,
        geolocations: path.points,
      })
    })
    return (
      <CanvasLineLayer
        {...mapProps}
        lines={lines}
      />
    )
  }
}
