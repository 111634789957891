import React from "react";
import _ from 'lodash'
import { HelpBlock } from "../../atoms/help-block/help-block";
import { EntitySelectField } from "../../molecules/fields/select-field/entity-select-field";

interface RequestedLocationWarningProps {
  entity: any
  previewEntity: any
}

export class RequestedLocationWarning extends React.Component<RequestedLocationWarningProps, any> {
  public render() {
    const { entity, previewEntity } = this.props

    const curSpotId = previewEntity?.uniqueId
    const yardMoves = previewEntity?.core_yms_spot?.activeYardMoves || []
    const filteredYardMoves = yardMoves.filter(ym =>
      entity?.core_yms_yardMove?.previousYardMove?.entityId != ym.yardMove.entityId)
    const inboundYardMoves = filteredYardMoves.filter(ym =>
      ym.yardMove.denormalizedProperties?.['core_yms_yardMove.requestedLocation']?.entityId === curSpotId)

    if (_.isEmpty(inboundYardMoves)) {
      return null
    }

    return (
      <>
        <HelpBlock
          helpText="Warning: Inbound trailer via requested yard move."
          style={{
            "backgroundColor": "#ffccbc",
            "color": "#bf360c",
            "margin": 0,
            "padding": 10,
            "fontSize": 14,
            "textAlign": "center"
          }}
        />
        { inboundYardMoves.map(ym => (
          <EntitySelectField
            value={ym.yardMove}
            showLinkIcon={true}
            isDisabled={true}
            showInlinePreview={true}
            key={ym.yardMove.entityId}
            entityType={"/1.0/entities/metadata/core_yms_yardMove.json"}
          />
        )) }
      </>
    )
  }
}
