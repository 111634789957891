import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import 'browser/components/atomic-elements/molecules/verification-input/_verification-input.scss'
import { ComponentsContext } from 'browser/contexts/components/components-context'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'

interface IVerificationInputProps extends IBaseProps {
  errorText?: string
  onChange: (value: string) => void
  isLoading?: boolean
  value: string
  size?: string
}

interface IVerificationInputState {
  errorText?: string
  codes: string[]
}

export class VerificationInput extends React.Component<IVerificationInputProps, IVerificationInputState> {
  static contextType = ComponentsContext
  context!: React.ContextType<typeof ComponentsContext>

  public static defaultProps: Partial<IVerificationInputProps> = {
    value: '',
  }

  private inputRefs: any[]

  constructor(props) {
    super(props)
    this.inputRefs = []
    this.state = {
      codes: props.value.split(''),
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ codes: nextProps.value.split('') })
    }
    if (this.state.errorText !== nextProps.errorText ||
        this.props.isLoading !== nextProps.isLoading) {
      this.setState({ errorText: nextProps.errorText }, () => {
        this.inputRefs[0].focus()
      })
    }
  }

  public render() {
    const { className, size } = this.props
    const inputClassName = 'flex-grow tc'
    const sizeClassName = _.isEmpty(size) ? 'c-verificationInput' : 'c-verificationInput--' + size
    return (
      <div className={className}>
        <div className='relative'>
          <div className='flex items-center justify-center'>
            {this.renderInput(0, 'c-verificationInput--container', classNames(inputClassName, sizeClassName))}
            {this.renderInput(1, 'c-verificationInput--container', classNames(inputClassName, sizeClassName))}
            {this.renderInput(2, 'c-verificationInput--container', classNames(inputClassName, sizeClassName))}
            {this.renderSeparator()}
            {this.renderInput(3, 'c-verificationInput--container', classNames(inputClassName, sizeClassName))}
            {this.renderInput(4, 'c-verificationInput--container', classNames(inputClassName, sizeClassName))}
            {this.renderInput(5, 'c-verificationInput--container', classNames(inputClassName, sizeClassName))}
          </div>
          {this.renderLoading()}
        </div>
        {this.renderErrors()}
      </div>
    )
  }

  private renderInput(index, className, inputClassName) {
    const { isLoading } = this.props
    const { codes } = this.state
    const handleChange = (value) => this.handleInputChange(value, index)
    const handleKeyDown = (event) => this.handleKeyDown(event, index)
    const handlePaste = (event) => this.handlePaste(event, index)
    const handleRef = (ref) => this.inputRefs[index] = ref
    return (
      <div className={className}>
        <Input
          autoFocus={index === 0}
          isDisabled={isLoading}
          inputClassName={classNames('c-input--hasBorder', inputClassName)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          ref={handleRef}
          inputMode='numeric'
          maxLength={1}
          pattern='[0-9]*'
          size='lg'
          value={codes[index]}
        />
      </div>
    )
  }

  private renderSeparator() {
    if (this.context.platform === PlatformType.MOBILE_WEB) {
      return null
    }
    return (
      <div className="c-verificationInput-separatorContainer">
        <hr className="c-verificationInput-separator b--gray" />
      </div>
    )
  }

  private renderLoading() {
    const { isLoading } = this.props
    if (isLoading) {
      return (
        <div className='c-verificationInput-loading'>
          <LoadingSpinner
            size='sm'
          />
        </div>
      )
    }
  }

  private renderErrors() {
    const { errorText } = this.state
    if (errorText) {
      return (
        <div className='mt3 tc c-errorColor'>
          {errorText}
        </div>
      )
    }
  }

  private handleChange(codes) {
    const { onChange } = this.props
    if (codes.length === 6 && _.every(codes, (code) => !_.isEmpty(code))) {
      onChange(codes.join(''))
    }
    this.setState({ codes, errorText: null })
  }

  private handleInputChange(value, index) {
    const { codes } = this.state
    // if user typed something that is not a number do nothing
    if (_.isEmpty(value) || isNumber(_.last(value))) {
      this.setCode(_.last(value), index)
    }
    // handle focus
    if (codes[index] && index < 5) {
      this.inputRefs[index + 1].focus()
    }
    this.handleChange(codes)
  }

  private handleKeyDown(event, index) {
    if (event.keyCode === 8 && index > 0) {
      // if user press backspace
      this.inputRefs[index - 1].focus()
      this.setCode(undefined, index)
    }
  }

  private handlePaste(event, index) {
    const { onChange } = this.props
    const { codes } = this.state
    event.preventDefault()
    const pastedCode = event.clipboardData.getData('text')
    // clear any codes after index
    for (let i = index; i < 6; ++i) {
      codes[i] = undefined
    }
    // paste code
    for (let i = 0; i < pastedCode.length && index < 6; ++i, ++index) {
      if (!isNumber(pastedCode[i])) { break }
      codes[index] = pastedCode[i]
    }
    // set focus
    if (index < 5) {
      this.inputRefs[index].focus()
    }
    this.handleChange(codes)
  }

  private setCode(char, index) {
    const { codes } = this.state
    // clear any characters after index
    for (let i = index; i < 6; ++i) {
      codes[i] = undefined
    }
    codes[index] = char
  }
}

function isNumber(value) {
  const numericValue = parseInt(_.last(value), 10)
  return !_.isNaN(numericValue)
}
