import _ from 'lodash'
import moment from 'moment'
import { Entity } from './entity'

// TODO(Dan): These IDs were added to assist with showing "Trip" or "Order" in driver app
// screen headers, since TMW customers prefer to see "Order". Once MS3 views are implemented,
// I'd like to see these removed in favor pulling the label formatting from the view (and
// inserting the trip entity displayName as the associated value string), so that we don't
// continue to add hardcoded UUIDs to the app code.

// Core trip schema ID
export const FULFILMENT_TRIP_ID = '86af510a-f030-41d6-bda4-2228b8e181b1'
// TMW trip mixin ID
export const TMW_TRIP_ID = '607a8842-e7ae-45cf-ab90-7e5821760718'
// Truckmate trip mixin ID
export const TRUCKMATE_TRIP_ID = 'c80d4440-2eea-46f4-9bff-5bce774dc68a'

// A leg map grouped by stop id
interface NestedStopLegMap {
  [stopId: string]: { [legId: string]: any }
}

// A cargo map grouped by stop id
interface StopCargoMap {
  [stopId: string]: CargoWithOrderName[]
}

// A cargo item with an order name attached
interface CargoWithOrderName {
  cargo: any
  orderName: string
}

/**
 * Core trip model.
 */
export class TripModel {

  public static NAMESPACE = 'core_fulfilment_trip'

  static getMetadataId() {
    return `/1.0/entities/metadata/${TripModel.NAMESPACE}.json`
  }

  private entity: Entity
  private stopsById: any
  private cargosById: any
  private ordersById: any
  private legMapByOriginStopId: NestedStopLegMap
  private legMapByDestinationStopId: NestedStopLegMap
  private cargosByStopId: StopCargoMap
  private orderMapByStopId: any

  constructor(entity: Entity) {
    this.entity = entity
    this.stopsById = _.keyBy(this.getStops(), 'uniqueId')
    this.cargosById = _.keyBy(this.getCargos(), 'uniqueId')
    this.ordersById = _.keyBy(this.getSalesOrders(), 'entityId')
    this.legMapByOriginStopId = _.reduce(this.getLegs(),
      _.partial(this.associateLegStop, _, _, 'origin'), {})
    this.legMapByDestinationStopId = _.reduce(this.getLegs(),
      _.partial(this.associateLegStop, _, _, 'destination'), {})
    this.cargosByStopId = _.reduce(this.stopsById, this.associateStopCargos, {})
    this.orderMapByStopId = _.reduce(this.getLegs(), this.associateOrderStop, {})
  }

  private associateOrderStop = (orderStopMap, leg) => {
    const orderId = _.get(leg, 'details.order.itemId')
    const originStopId = _.get(leg, 'origin.stop.itemId')
    if (orderId && originStopId) {
      _.set(orderStopMap, [originStopId, orderId], this.ordersById[orderId])
    }
    const destinationStopId = _.get(leg, 'destination.stop.itemId')
    if (orderId && destinationStopId) {
      _.set(orderStopMap, [destinationStopId, orderId], this.ordersById[orderId])
    }
    return orderStopMap
  }

  private associateLegStop = (stopLegMap, leg, legPointPathName: 'origin' | 'destination') => {
    const legPoint = _.get(leg, legPointPathName)
    const stopId = legPoint?.stop?.itemId
    if (stopId) {
      // See https://withvector.atlassian.net/browse/VD-4883
      // For Florida Beauty, our Stop Details screen was showing too many
      // orders/cargos, because we consider all legs where the stop is either an
      // origin or destination. In their case, they duplicate leg information, so
      // we end up with more legs here than originally intended. They want to
      // exclude these "duplicate" legs when the leg is not actually identified as
      // a pickup (-P) or destination (-D).
      if (
        !this.entity.hasMixin(TRUCKMATE_TRIP_ID) ||
        (legPointPathName === 'origin' && !_.endsWith(leg.identifier, 'D')) ||
        (legPointPathName === 'destination' && !_.endsWith(leg.identifier, 'P'))
      ) {
        this.populateMissingTimezone(legPoint, stopId)
        _.set(stopLegMap, [stopId, leg.identifier], leg)
      }
    }
    return stopLegMap
  }

  /* [VD-7267] populate leg timezones using stop timezone, if needed. */
  private populateMissingTimezone(legPoint, stopId): void {
    const stop = this.stopsById[stopId]
    const stopTimezoneId = _.get(stop, ['location', 'denormalizedProperties', 'location.address', 'timezoneId'])
    const legPointStart = legPoint.timeWindow?.start
    const legPointEnd = legPoint.timeWindow?.end
    if (legPointStart && !legPointStart.timezone && stopTimezoneId) {
      legPointStart.timezone = stopTimezoneId
    }
    if (legPointEnd && !legPointEnd.timezone && stopTimezoneId) {
      legPointEnd.timezone = stopTimezoneId
    }
  }

  private associateStopCargos = (stopCargoMap, stop, stopId) => {
    stopCargoMap[stopId] = _(this.getLegsForStop(stop))
      .map(leg => {
        // find leg order
        const orderId = _.get(leg, 'details.order.itemId')
        const orderName = _.get(this.ordersById[orderId], 'displayName')
        if (!orderName)
          return
        // combine cargo data and order display name
        const cargoEdges = _.get(leg, 'details.cargos', [])
        return _.map(cargoEdges, cargo => ({
          cargo: this.cargosById[cargo.itemId],
          orderName,
          orderId,
        }))
      })
      .flatten()
      .compact()
      .uniqBy('cargo.uniqueId')
      .value()
    return stopCargoMap
  }

  getEntity(): Entity {
    return this.entity
  }

  getEntityType(): string {
    return this.entity.entityType
  }

  getNamespace(): string {
    return TripModel.NAMESPACE
  }

  getUniqueId(): string {
    return this.entity.get('uniqueId')
  }

  getIdentifier() {
    return _.get(this.entity, `${TripModel.NAMESPACE}.identifier`)
  }

  getStatus(): string {
    return _.get(this.entity, `${TripModel.NAMESPACE}.status`)
  }

  getDisplayName() {
    return this.entity.displayName
  }

  getSalesOrders(stop?) {
    if (stop) {
      return _.values(this.orderMapByStopId[stop.uniqueId])
    }
    return _.get(this.entity, `${TripModel.NAMESPACE}.plan.orders`, [])
  }

  getStops(): any[] {
    return _.get(this.entity, `${TripModel.NAMESPACE}.plan.stops`)
  }

  getCargos(): any[] {
    return _.get(this.entity, `${TripModel.NAMESPACE}.plan.cargos`)
  }

  getLegs() {
    return _.get(this.entity, `${TripModel.NAMESPACE}.plan.legs`, [])
  }

  getAdditionalStopCount() {
    return Math.max(0, _.size(this.getStops()) - 2)
  }

  getOrigin(): any {
    return _.first(this.getStops())
  }

  getDestination(): any {
    return _.last(this.getStops())
  }

  getCargosForStop(stop): any[] {
    if (!stop) return
    return this.cargosByStopId[stop.uniqueId]
  }

  /**
   * @return List of all sales orders on legs that begin or terminate at the given stop.
   */
  getSalesOrdersForStop(stop) {
    if (!stop) return
    return _.chain(this.getLegsForStop(stop))
      .map('details.order.itemId')
      .map(itemId => this.ordersById[itemId])
      .compact()
      .value()
  }

  /**
   * @return List of instructions from all leg points that match this stop.
   */
  getInstructionsForStop(stop) {
    if (!stop) return
    return _(this.getLegPointsForStop(stop))
      .filter(_.partial(_.has, _, 'instructions'))
      .uniqBy('stop.uniqueId')
      .map('instructions')
      .compact()
      .value()
  }

  /*
   * Finds the earliest date-time among all of a stop's legPoints, given that
   * each legPoint has a time range from 'start' to 'end'. Compares all starts
   * or all ends at any given time.
   *
   * @param stop - The stop to match against legPoints
   * @param point - "start" or "end"
   * @return The earliest "dateTimeWithTimezone" for this stop.
   */
  getEarliestTimeForStop(stop, point) {
    const legPoints = this.getLegPointsForStop(stop)
    return _.reduce(legPoints, (acc, legPoint) => {
      const dateTime = _.get(legPoint, ['timeWindow', point, 'dateTime'])
      if (!dateTime) {
        return acc
      }
      const date = moment(dateTime)
      if (acc && date.isAfter(acc.dateTime)) {
        return acc
      }
      return legPoint.timeWindow[point]
    }, undefined)
  }

  private getLegsForStop(stop) {
    if (!stop) return
    const originLegs = _.values(this.legMapByOriginStopId[stop.uniqueId])
    const destinationLegs = _.values(this.legMapByDestinationStopId[stop.uniqueId])
    return [
      ...originLegs,
      ...destinationLegs,
    ]
  }

  private getLegPointsForStop(stop) {
    if (!stop) return
    const originLegs = _.values(this.legMapByOriginStopId[stop.uniqueId])
    const destinationLegs = _.values(this.legMapByDestinationStopId[stop.uniqueId])
    return [
      ..._.map(originLegs, 'origin'),
      ..._.map(destinationLegs, 'destination'),
    ]
  }
}
