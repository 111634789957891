import classNames from 'classnames'
import $ from 'jquery'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/print-container/_print-container.scss'

interface IPrintContainerProps extends IBaseProps {
  isLandscape?: boolean
}

export class PrintContainer extends React.Component<IPrintContainerProps, any> {

  public componentDidMount() {
    $('html').addClass('c-printPreviewMode')
  }

  public render() {
    const { children, isLandscape, className } = this.props
    return (
      <div
        className={classNames('c-printReport', className, {
          'c-printReport--landscape': isLandscape,
          'c-printReport--portrait': !isLandscape,
        })}
      >
        {this.renderPageSize()}
        {children}
      </div>
    )
  }

  private renderPageSize() {
    if (this.props.isLandscape) {
      return (
        <style>{'\
          @page {\
            size: 11in 8.5in;\
          }\
      '}</style>
      )
    } else {
      return (
        <style>{'\
          @page {\
            size: 8.5in 11in;\
          }\
      '}</style>
      )
    }
  }
}
