import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
// tslint:disable-next-line:max-line-length
import { FormGroupContentWrapper } from 'browser/components/atomic-elements/atoms/form-group-content-wrapper/form-group-content-wrapper'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import {
  default as EntitySelect,
  IEntitySelectProps,
} from 'browser/components/atomic-elements/atoms/select/entity-select'
import { components } from 'react-select'

// tslint:disable-next-line:no-empty-interface
type ILocationSelectProps = IEntitySelectProps

export class LocationSelect extends React.Component<ILocationSelectProps, any> {
  private input: any
  private store: any

  constructor(props: ILocationSelectProps) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      isFocused: false,
      isLoading: true,
      locationEntity: null,
    }
  }

  public componentDidMount() {
    const { value } = this.props
    const valueId: string = _.get(value, 'entityId')
    this.fetchValueEntity(valueId)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const valueId = _.get(this.props.value, 'entityId')
    const nextValueId: string = _.get(nextProps.value, 'entityId')
    if (valueId !== nextValueId) {
      this.fetchValueEntity(nextValueId)
    }
  }

  public focus() {
    this.input.focus()
  }

  public render() {
    const { className, errorText, isHorizontalLayout, isDisabled } = this.props
    const { isFocused } = this.state

    let content
    if (isDisabled) {
      content = (
        <FormGroupContentWrapper
          hasError={!_.isEmpty(errorText)}
          isHorizontalLayout={isHorizontalLayout}
          isFocused={isFocused}
          isDisabled={isDisabled}
          className={className}
        >
          {this.renderStaticValue()}
        </FormGroupContentWrapper>
      )
    } else {
      content = this.renderSelect()
    }
    return content
  }

  private renderSelect() {
    const { className, isDisabled } = this.props
    if (!isDisabled) {
      return (
        <EntitySelect
          {...this.props}
          optionHeight={51}
          onBlur={this.handleOnBlur}
          onFocus={this.handleOnFocus}
          className={className}
          optionRenderer={this.handleRenderOption}
          valueRenderer={this.handleRenderValue}
          ref={(ref) => {
            this.input = ref
          }}
        />
      )
    }
  }

  private renderStaticValue() {
    const { isDisabled, size } = this.props
    const { locationEntity, isLoading } = this.state
    const sizeClassName = size ? `c-fakeInputContainer--${size}` : ''
    if (!isDisabled) {
      return
    }

    if (!locationEntity || !this.isValidLocation(locationEntity)) {
      if (isLoading) {
        return (
          <div className={classNames('relative c-fakeInputContainer', sizeClassName)}>
            <LoadingSpinner size="xs" />
          </div>
        )
      } else {
        return <div />
      }
    }

    const location = locationEntity.location
    const locationName = location.name
    const address = location.address
    return (
      <div className={classNames('c-fakeInputContainer', sizeClassName)}>
        <span className="b">{locationName}</span>
        <br />
        {address.streetAddress}
        {this.renderExtendedAddress(address)}
        <br />
        {address.locality}, {address.region} {address.postalCode}
      </div>
    )
  }

  private fetchValueEntity(uniqueId: string) {
    console.log('uniqueId', uniqueId)
    if (!uniqueId) {
      return this.setState({ locationEntity: null, isLoading: false })
    }
    this.setState({ isLoading: true })
    this.store.findRecord(uniqueId).then((entity) => {
      this.setState({
        isLoading: false,
        locationEntity: entity,
      })
    })
  }

  private renderLocationDetails = (props) => {
    const option = props.data
    const address: any = _.get(option, 'location.address', {})
    return (
      <div className="c-locationSelect--group">
        <div>{option.displayName}</div>
        <div className="c-locationSelect-inlineAddress c-helpBlock--color">
          {address.streetAddress}
          {this.renderExtendedAddress(address)}, {address.locality}, {address.region}{' '}
          {address.postalCode}
        </div>
      </div>
    )
  }

  private renderExtendedAddress(address) {
    if (!_.isEmpty(address.extendedAddress)) {
      return <span>, {`${address.extendedAddress}`}</span>
    }
  }

  private handleOnBlur = (event) => {
    const { onBlur } = this.props
    this.setState({ isFocused: false })
    if (onBlur) {
      onBlur(event)
    }
  }

  private handleOnFocus = (event) => {
    const { onFocus } = this.props
    this.setState({ isFocused: true })
    if (onFocus) {
      onFocus(event)
    }
  }

  private handleRenderValue = (props) => {
    return (
      <components.SingleValue {...props}>
        {props.data.displayName}
      </components.SingleValue>
    )
  }

  private handleRenderOption = (props) => {
    return (
      <components.Option {...props}>
        {this.renderLocationDetails(props)}
      </components.Option>
    )
  }

  private isValidLocation = (locationEntity) => {
    return  !_.isEmpty(locationEntity?.location?.address) && !_.isEmpty(locationEntity?.location?.name)
  }
}
