import React from 'react'
import _ from 'lodash' 

import { globalTranslationTable } from 'browser/mobile/util/global-translation-table'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { translateString } from 'shared-libs/helpers/utils'
import { Settings } from 'browser/app/models/settings'
import { User } from 'shared-libs/models/types/user'
import { browserHistory } from 'browser/history'
import { IconNames } from '@blueprintjs/icons'
import apis from 'browser/app/models/apis'
import { Icon } from '@blueprintjs/core'
import { signout } from 'browser/app/utils/auth'

const VectorLogo = require('images/navigation/vector-logo-white.svg')

interface IMenuProps {
  settings: Settings
}

export const Menu: React.FC<IMenuProps> = (props) => {
  const { settings } = props
  const userEntity = settings.getUser()
  const user: User = userEntity.user
  const identityString = user.identity || user.additionalIdentity
  const firm = settings.getFirm()

  return (
    <Popover className="collapse c-mobile-menu" data-debug-id="mobile-menu-options-popover">
      <div className="c-mobile-menu-identity-header">
        <img className="c-mobile-menu-vector-logo" src={VectorLogo} />
        <div className="c-mobile-menu-identity-header-details">
          <div className="c-mobile-menu-identity-detail c-mobile-menu-user-name">
            {userEntity.displayName}
          </div>
          <div className="c-mobile-menu-identity-detail c-mobile-menu-user-identity">
            {identityString}
          </div>
          <div className="c-mobile-menu-identity-detail c-mobile-menu-user-firm">
            {firm.displayName}
          </div>
        </div>
      </div>
      <div>
        <ul className="c-dropdownList">
          <li className="c-dropdownList-item items-center overflow-hidden" onClick={confirmLogout}>
            <Icon className="mr3" icon={IconNames.LOG_OUT} />
            <span className="truncate">Sign out of {firm.displayName}</span>
          </li>
        </ul>
      </div>
    </Popover>
  )
}

function confirmLogout() {
  const translatedConfirmation = translateString('Confirm_Log_Out_Msg', globalTranslationTable)
  if (confirm(translatedConfirmation)) {
    void signout()
  }
}
