/**
 * Storyboard scene enrichment of the json scene equivalent
 */

import _ from 'lodash'
import { IStoryboardEnrichProps, StoryboardElement } from './storyboard-element'
import { StoryboardNavigationMap } from './storyboard-navigation-map'
import { StoryboardNavigationRoute } from './storyboard-navigation-route'
import { PlatformType } from './storyboard-plan'
import { Identifier, Scene } from './storyboard-plan-model'
import { SharedContext } from './storyboard-shared-context'
import { StoryboardTask } from './storyboard-task'
export class StoryboardScene extends StoryboardElement {
  private navigationMap: StoryboardNavigationMap<StoryboardTask> = null

  constructor(data: Scene, schema: any) {
    super(data, schema)
    this.navigationMap = new StoryboardNavigationMap(data.taskRoutes, {})
  }

  public enrich(props: IStoryboardEnrichProps): StoryboardScene {
    const {
      getElement,
    }: {
      getElement: (id) => any
    } = props

    this.navigationMap.enrich({ getElement })

    return this
  }

  public get initialRoute(): StoryboardNavigationRoute {
    return this.navigationMap.getInitialRoute()
  }

  public getTaskWithId(id: Identifier): StoryboardTask {
    return this.navigationMap.getItem(id)
  }

  public getTasks(): StoryboardTask[] {
    return this.navigationMap.getItems() || []
  }

  /**
   * Get the next task route based on the current state.
   */
  public getNextTaskRoute(
    task: StoryboardTask,
    sharedContext: SharedContext
  ): StoryboardNavigationRoute {
    return this.navigationMap.getNextRoute(task, sharedContext)
  }

  public platformSchema(platform: PlatformType) {
    return _.get(this, `data.uiView.${platform}.uiSchema`)

  }

  public uiSchema(platform: PlatformType) {
    // for scenes, the additional properties e.g. validationSchema, overlaySection, etc..
    // are all mixed in with the base path for the UI schema to be rendered
    return this.platformSchema(platform)
  }
}
