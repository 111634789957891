import moment from 'moment'

import { dictFromPairs } from '../utils'

/*
    Relative Date Classes
*/
export class RelativeDate {
  public unit: any
  public label: string
  public format: (int) => string

  constructor({ unit, label, format}) {
    this.unit = unit
    this.label = label
    this.format = format
  }

  public getNow = moment
  public getKey = () => this.unit
  public calculateDate(date: any, offset: number) {}
}

export const FIXED = 'fixed'
export const RELATIVE = 'relative'

export interface RelativeDateFilter {
  mode: string
  offsetUnit?: string
  offsetValue?: string
  date?: string
  timezone?: string
}

export interface RelativeDateFilterRange {
  startDate: RelativeDateFilter
  endDate: RelativeDateFilter
}

class RelativeDateNow extends RelativeDate {
  public calculateDate = (d, _) => d
}

class RelativeDateOffset extends RelativeDate {
  public calculateDate = (d, n) => d.subtract(n, this.unit)
}

class RelativeDateOffsetStartOf extends RelativeDate {
  public getKey = () => `${this.unit}_start`
  public calculateDate = (d, n) => d.subtract(n, this.unit).startOf(this.unit)
}

class RelativeDateOffsetEndOf extends RelativeDate {
  public getKey = () => `${this.unit}_end`
  public calculateDate = (d, n) => d.subtract(n, this.unit).endOf(this.unit)
}

/*
    String Formatting
*/
function inflect(string, n) {
  if (n === 1) {
    return string
  }

  return `${string}s`
}

function formatCalendarWeekOffset(startOrEnd, period, n) {
  if (n === 0) {
    return `${startOrEnd} Of This ${period}`
  } else if (n === 1) {
    return `${startOrEnd} Of Last ${period}`
  } else {
    return `${startOrEnd} Of ${n} ${inflect(period, n)} Ago`
  }
}

function formatDayOffset(n) {
  if (n === 0) {
    return 'Today'
  } else if (n === 1) {
    return 'Yesterday'
  } else if (n === -1) {
    return 'Tomorrow'
  } else {
    return `${Math.abs(n)} ${inflect("Day", n)} ${agoFromNow(n)}`
  }
}

function agoFromNow(n) {
  return n < 0 ? "From Now" : "Ago"
}


/*
    Relative Date Mode Configurations
*/
export const relativeDateModes = [
  new RelativeDateNow({
    unit: 'now',
    label: 'Now',
    format: (_) => 'Now',
  }),
  new RelativeDateOffset({
    unit: 'days',
    label: 'Days Ago',
    format: formatDayOffset,
  }),
  new RelativeDateOffset({
    unit: 'weeks',
    label: 'Weeks Ago',
    format: (n) => `${Math.abs(n)} ${inflect("Week", n)}  ${agoFromNow(n)}`,
  }),
  new RelativeDateOffset({
    unit: 'months',
    label: 'Months Ago',
    format: (n) => `${Math.abs(n)} ${inflect("Month", n)}  ${agoFromNow(n)}`,
  }),
  new RelativeDateOffset({
    unit: 'years',
    label: 'Years Ago',
    format: (n) => `${Math.abs(n)} ${inflect("Year", n)}  ${agoFromNow(n)}`,
  }),
  new RelativeDateOffsetStartOf({
    unit: 'weeks',
    label: 'Weeks Ago, Start Of',
    format: (n) => formatCalendarWeekOffset("Start", "Week", n),
  }),
  new RelativeDateOffsetEndOf({
    unit: 'weeks',
    label: 'Weeks Ago, End Of',
    format: (n) => formatCalendarWeekOffset("End", "Week", n),
  }),
  new RelativeDateOffsetStartOf({
    unit: 'months',
    label: 'Months Ago, Start Of',
    format: (n) => formatCalendarWeekOffset("Start", "Month", n),
  }),
  new RelativeDateOffsetEndOf({
    unit: 'months',
    label: 'Months Ago, End Of',
    format: (n) => formatCalendarWeekOffset("End", "Month", n),
  }),
  new RelativeDateOffsetStartOf({
    unit: 'years',
    label: 'Years Ago, Start Of',
    format: (n) => formatCalendarWeekOffset("Start", "Year", n),
  }),
  new RelativeDateOffsetEndOf({
    unit: 'years',
    label: 'Years Ago, End Of',
    format: (n) => formatCalendarWeekOffset("End", "Year", n),
  }),
]

export const relativeDateModesByKey = dictFromPairs(relativeDateModes.map((mode) => {
  return [mode.getKey(), mode]
}))
