import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { AddressInput } from 'browser/components/atomic-elements/atoms/input/address-input/address-input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { renderAuthorHeader, renderEventTimestamp } from '../util'

interface ICheckCallItemProps extends IBaseProps {
  item: any
  isFirst: boolean
  isLast: boolean
}

export const CheckCallItem: React.FC<ICheckCallItemProps> = (props) => {
  const { className, item, isFirst } = props
  const { checkCall, createdBy, owner } = item
  const { address, timestamp, notes } = checkCall

  return (
    <div
      className={classNames('c-timelineEvent', className)}
      key={item.uniqueId}
    >
      <div
        className={classNames('c-timelineEvent-line', className, {
          'c-timelineEvent-line--topCutOff': isFirst,
        })}
      />
      <div className='c-timelineEvent-dot' />
      <div className='c-timelineEvent-contentContainer'>
        <div className='c-timelineEvent-header'>
          <div className='c-timelineEvent-author'>
            <Avatar
              className='c-timelineEvent-avatar'
              name={createdBy.displayName}
              size='xs'
            />
            <Label className='c-timelineEvent-authorName'>
              {renderAuthorHeader(createdBy, owner?.firm)}
            </Label>
          </div>
          <HelpBlock className='c-timelineEvent-timestamp'>
            {renderEventTimestamp(timestamp)}
          </HelpBlock>
        </div>
        <div className='c-timelineEvent-body'>
          <HelpBlock>
            Check Call at:
          </HelpBlock>
          <AddressInput
            className='c-fakeInputContainer--plain'
            showEditableFields={false}
            value={address}
          />
          {!_.isEmpty(notes) && (
            <div>
              <HelpBlock>
                Notes:
              </HelpBlock>
              {notes}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
