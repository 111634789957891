import React from 'react'
import { CargoDeliveryTaskRow, ICargoDeliveryTaskRowProps } from './cargo-delivery-task-row'

const SALES_ORDER_CARGOS_PATH = 'core_fulfilment_salesOrder.cargos'
const SALES_ORDER_STOPS_PATH = 'core_fulfilment_salesOrder.stops'
const CARGO_QUANTITY_UNIT_SCHEMA_PATH =
  'properties.core_fulfilment_salesOrder.properties.cargos.items.properties.quantity.properties.unit'

/**
 * @component SalesOrderCargoDeliverTaskRow - table row to display cargoTasks at a stop on a sales order
 *
 * @props ICargoDeliveryTaskRowProps
 */
export const SalesOrderCargoDeliveryTaskRow: React.FC<ICargoDeliveryTaskRowProps> = (
  props: ICargoDeliveryTaskRowProps
) => {
  return (
    <CargoDeliveryTaskRow
      {...props}
      cargoQuantityUnitSchemaPath={CARGO_QUANTITY_UNIT_SCHEMA_PATH}
      cargosPath={SALES_ORDER_CARGOS_PATH}
      stopsPath={SALES_ORDER_STOPS_PATH}
    />
  )
}
