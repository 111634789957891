import _ from 'lodash'
import { EntitySource, Store } from '../models/store'
import { Try } from '../helpers/utils'

export class ApplicationBundleResolver {

  private api: any
  private store: Store

  constructor(api) {
    this.api = api
    this.store = api.getStore()
  }

  // the resolved bundle is a raw javascript object
  public resolve(bundle, sources?: EntitySource[]) {
    const parentEdges = bundle.applicationBundle.extends
    if (_.isEmpty(parentEdges)) {
      return this.resolveSchemasForBundle(bundle).then(() => bundle)
    }
    const ids = _.map(parentEdges, 'entityId')
    return this.store.findRecords(ids, {
      shouldCacheRecord: false,
      shouldMaterialize: false,
      shouldResolveMixins: false,
    }).then((parentBundles) => {
      let resolvedBundle = _.cloneDeep(parentBundles[0])
      for (let i = 1; i < parentBundles.length; ++i) {
        resolvedBundle = this.mergeBundles(resolvedBundle, parentBundles[i])
      }
      resolvedBundle = this.mergeBundles(resolvedBundle, bundle)
      return this.resolveSchemasForBundle(resolvedBundle, sources).then(() => resolvedBundle)
    })
  }

  private mergeBundles(baseBundle, overlayBundle) {
    mergeProperty(baseBundle, overlayBundle, 'applicationBundle.extends')
    mergeProperty(baseBundle, overlayBundle, 'applicationBundle.initialRoute')
    mergeProperty(baseBundle, overlayBundle, 'applicationBundle.name')
    baseBundle.applicationBundle.schemas = mergeMaps(
      baseBundle.applicationBundle.schemas,
      overlayBundle.applicationBundle.schemas,
    )
    baseBundle.applicationBundle.views = mergeMaps(
      baseBundle.applicationBundle.views,
      overlayBundle.applicationBundle.views,
    )
    return baseBundle
  }

  private resolveSchemasForBundle(bundle, sources?: EntitySource[]) {
    const namespace = 'schemas'
    const mappings = bundle.applicationBundle[namespace]
    const edges = _.values(mappings)
    const ids = _.map(edges, 'entityId')
    return this.store.resolveEntitiesById(ids, sources)
  }
}

function mergeMaps(map1 = {}, map2 = {}) {
  const mergedProperties = { ...map1, ...map2 }
  const results = {}
  _.forEach(mergedProperties, (value: any, key: any) => {
    if (value._operation !== 'DELETE') {
      results[key] = value
    }
  })
  return results
}

function mergeProperty(baseBundle, overlayBundle, key) {
  const baseBundleValue = _.get(baseBundle, key)
  const overlayValue = _.get(overlayBundle, key, baseBundleValue)
  _.set(baseBundle, key, overlayValue)
}
