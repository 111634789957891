import _ from 'lodash'
import { IToastProps, Position, ToastSingleton } from 'browser/components/atomic-elements/atoms/toast/toast'
export { Position } from 'browser/components/atomic-elements/atoms/toast/toast'
import './_toast.scss'

class MobileToastSingleton extends ToastSingleton {
  public show(props: IToastProps) {
    return super.show({
      className: 'mobile-toast',
      isFullWidth: true,
      position: Position.BOTTOM,
      ...props,
    })
  }
}

export const Toast = new MobileToastSingleton()
