import _ from 'lodash'
import Papa from 'papaparse'
import React, { Component, Fragment } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import styled from 'styled-components'

import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import apis from 'browser/app/models/apis'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Panel } from 'browser/components/atomic-elements/atoms/panel'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { default as EntitySelect } from 'browser/components/atomic-elements/atoms/select/entity-select'
import { SheetManager } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
import { Toggle } from 'browser/components/atomic-elements/atoms/toggle/toggle'
import { DefaultValueSheet } from 'browser/components/atomic-elements/organisms/entity/bulk-add/default-value-sheet'
import { FieldDragSource } from 'browser/components/atomic-elements/organisms/entity/bulk-add/field-drag-source'
import { FieldDropTarget } from 'browser/components/atomic-elements/organisms/entity/bulk-add/field-drop-target'
import { IField, InviteFields } from 'browser/components/atomic-elements/organisms/entity/bulk-add/fields'
import {
  NewColumnDropTarget,
} from 'browser/components/atomic-elements/organisms/entity/bulk-add/new-column-drop-target'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { browserHistory } from 'browser/history'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { isEmailValid, isPhoneValid } from 'shared-libs/helpers/utils'
import { Entity } from 'shared-libs/models/entity'
import { EntityProps, FirmProps, InviteProps } from 'shared-libs/models/prop-constants'
import { Roles } from 'shared-libs/models/role'
import { SchemaIds, SchemaUris } from 'shared-libs/models/schema'
import { createEdge } from 'shared-libs/models/utils'
import { ViewIds } from 'shared-libs/models/view'
import { BulkAddTableRow } from './table/bulk-add-table-row'

const GRAY = '#9e9e9e'
const RED = '#e53935'
const GREEN = '#2e7d32'

enum Stage { FIRM_SELECT, CSV_SELECT, DATA_MANIPULATION, SAVING, SAVE_SUMMARY }

const Error = {
  INVALID: 'Invalid',
  REQUIRED: 'Required',
}

const CenteringWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 300px;
`

const DragSourcesWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 27.5px;
`

const DragSourceGrid = styled.div`
  display: grid;
  grid-template-columns: 70px auto 160px;
  grid-template-rows: 27.5px 27.5px;
  grid-template-areas: 'reql reqi new'
                       'optl opti new';
  grid-row-gap: 0.5rem;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 1rem;
`

const HeaderCellWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`

const ToggleText = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0;
`

const NoneLeftLabel = styled.div`
  color: ${GRAY};
  font-style: italic;
`

const AllValidMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  color: ${GREEN};
`

const DataWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface IColumnMetadata {
  splittable: boolean
}

interface IBulkAddEntityPanelProps {
  onClose: () => {}
}

interface IBulkAddEntityPanelState {
  isSaving: boolean
  rowDataArray: string[][]
  rowErrorsArray: string[][]
  fields: IField[]
  columnMetadataArray: IColumnMetadata[]
  availableFields: any[]
  panelX: number
  panelY: number
  showErrorsOnly: boolean
  newColumnField: IField
  stage: Stage
  firm: Entity
  currentSavingIndex: number
  successfulSaves: number
  saveErrors: any
  lastSavedEntityId: string
  focusedRow: number
}

class InnerBulkAddEntityPanel extends Component<IBulkAddEntityPanelProps, IBulkAddEntityPanelState> {

  public static open(props) {
    OverlayManager.openOverlay(this, { ...props })
  }

  private wrapperRef: Element
  private fileInput
  private firstDataRow

  constructor(props) {
    super(props)

    this.state = {
      availableFields: this.getResetAvailableFields(),
      columnMetadataArray: [],
      currentSavingIndex: 0,
      fields: [], // These manifest as columns in the table.  They are the entity's fields
      firm: null,
      focusedRow: null,
      isSaving: false,
      lastSavedEntityId: null,
      newColumnField: null,
      panelX: 0,
      panelY: 0,
      rowDataArray: [],
      rowErrorsArray: [],
      saveErrors: [],
      showErrorsOnly: false,
      stage: Stage.FIRM_SELECT,
      successfulSaves: 0,
    }
  }

  // Rendering
  // ---------

  public render() {
    const { onClose } = this.props
    const { stage } = this.state
    return (
      <div ref={this.assignWrapperRef} style={{ position: 'relative' }} >
        <Panel
          title='Invite Employees'
          onClose={onClose}
          panelFooter={this.renderFooter()}
          size='lg'
        >
          <SheetManager>
            <CenteringWrapper
              style={{ alignItems: stage === Stage.DATA_MANIPULATION ? 'flex-start' : 'center' }}
            >
              {stage === Stage.FIRM_SELECT && this.renderFirmSelect()}
              {stage === Stage.CSV_SELECT && this.renderStartButtons()}
              {stage === Stage.DATA_MANIPULATION && (
                <Fragment>
                  {this.renderHeader()}
                  <hr style={{ marginBottom: '1rem' }} />
                  {this.renderFieldDragSources()}
                  {this.renderTable()}
                </Fragment>
              )}
              {stage === Stage.SAVING && this.renderSavingMessage()}
              {stage === Stage.SAVE_SUMMARY && this.renderSaveSummary()}
            </CenteringWrapper>
            <DefaultValueSheet
              isOpen={!!this.state.newColumnField}
              columnName={_.get(this.state.newColumnField, 'label')}
              onClose={this.handleDefaultValueSheetClosed}
            />
          </SheetManager>
        </Panel>
        <ReactResizeDetector
          handleWidth={true}
          handleHeight={true}
          onResize={this.handleResize}
        />
      </div>
    )
  }

  private renderSavingMessage() {
    const { currentSavingIndex, rowDataArray } = this.state
    return (
      <h4>{`Sending ${currentSavingIndex + 1} of ${rowDataArray.length}`}</h4>
    )
  }

  private renderSaveSummary() {
    const { successfulSaves, saveErrors, rowDataArray } = this.state
    return (
      <Fragment>
        <h4 style={{ marginBottom: '1rem' }}>
          {`Successfully sent ${successfulSaves} of ${rowDataArray.length} Invites`}
        </h4>
        {saveErrors.length > 0 && (
          <Section title='Failures'>
            {this.renderErrorTable()}
          </Section>
        )}
      </Fragment>
    )
  }

  private renderErrorTable() {
    const { rowDataArray } = this.state

    const columnNames = this.state.fields.map((f) => f.label)
    columnNames.push('Error')

    const errorRows = this.state.saveErrors.map((saveError) => {
      return [ ...rowDataArray[saveError.row], saveError.error ]
    })

    return (
      <table className='c-table'>
        <thead className='c-table-header'>
          <tr className='c-table-row'>
            {columnNames.map((columnName, col) => this.renderErrorHeaderCell(columnName, col))}
          </tr>
        </thead>
        <tbody className='c-table-body'>
          {errorRows.map((errorRowData, row) => this.renderErrorRow(errorRowData, row))}
        </tbody>
      </table>
    )
  }

  private renderErrorHeaderCell = (columnName, col) => {
    return (
      <th key={`error-header-${col}`} className='c-table-cell'>
        {columnName}
      </th>
    )
  }

  private renderErrorRow = (errorRowData, row) => {
    return (
      <tr className='c-table-row' key={`error-row-${row}`}>
        {errorRowData.map((cellData, col) => this.renderErrorCell(cellData, row, col))}
      </tr>
    )
  }

  private renderErrorCell = (cellData, row, col) => {
    const numColumns = this.state.rowDataArray[0].length
    return (
      <td className='c-table-cell' key={`error-cell-${row}-${col}`}>
        {numColumns === col && cellData}
        {numColumns !== col && <DataWrapper>{cellData}</DataWrapper>}
      </td>
    )
  }

  private renderFirmSelect() {
    return (
      <div style={{ width: '350px' }}>
        <EntitySelect
          entityType={SchemaUris.FIRM}
          returnValueAsEdge={false}
          onChange={this.handleFirmSelect}
          style={{ border: '1px solid #E0E0E0' }}
          placeholder='Choose Firm'
          autoFocus={true}
          isCreatable={false}
        />
      </div>
    )
  }

  private renderStartButtons() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '230px', alignItems: 'stretch' }}>
        <Button
          className={Classes.iconClass(IconNames.UPLOAD)}
          onClick={this.handleUploadClick}
        >
          Start by Uploading CSV File
        </Button>
        <div style={{ height: '0.5rem' }} />
        <Button
          className={Classes.iconClass(IconNames.PLAY)}
          onClick={this.handleStartFromScratchClick}
        >
            Start from Scratch
        </Button>
        <input
          ref={(fi) => { this.fileInput = fi }}
          type='file'
          style={{ display: 'none' }}
          onChange={this.handleNewFile}
        />
      </div>
    )
  }

  private renderHeader = () => {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          gridArea: 'head',
          justifyContent: 'space-between',
          marginBottom: '1rem',
        }}
      >
        <h4> Drag Field Names to Column Headers Below </h4>
        <Controls>
          <div style={{ marginRight: '0.5rem' }}><Button onClick={this.handleAddRowClick}>Add Row</Button></div>
          <div style={{ marginRight: '0.5rem' }}><Button onClick={this.handleAutoDetectClick}>Auto Detect</Button></div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ width: '40px', height: '20px', marginRight: '0.25rem' }}>
              <Toggle
                hasError={this.state.showErrorsOnly}
                value={this.state.showErrorsOnly}
                onChange={this.handleShowErrorsOnlyToggle}
              />
            </div>
            <ToggleText>Errors Only</ToggleText>
          </div>
        </Controls>
      </div>
    )
  }

  private renderFieldDragSources = () => {
    const { panelX, panelY } = this.state
    const reqFields = this.getAvailableRequiredFields()
    const oneOrMoreFields = this.getAvailableOneOrMoreFields()
    const optFields = this.getAvailableOptionalFields()

    // if there is only one xor field then it is optional since one xor has already been chosen
    if (oneOrMoreFields.length === 1) {
      optFields.push(...oneOrMoreFields)
      oneOrMoreFields.length = 0
    }

    return (
      <DragSourceGrid>
        <div style={{ gridArea: 'reql', fontWeight: 600 }}>Required</div>
        <DragSourcesWrapper style={{ gridArea: 'reqi' }}>
          {reqFields.length === 0 && oneOrMoreFields.length === 0 && (
            <NoneLeftLabel>
              All required fields have been assigned
            </NoneLeftLabel>
          )}
          {reqFields.map((f) => <FieldDragSource field={f} key={`uf-${f.id}`} panelX={panelX} panelY={panelY} />)}
          { oneOrMoreFields.map((f, i) => (
            <div style={{ display: 'flex', alignItems: 'center' }} key={`uf-${f.id}`} >
              {i > 0 && <div style={{ marginRight: '0.75em' }}>or</div>}
              <FieldDragSource field={f} panelX={panelX} panelY={panelY} />
            </div>
          )) }
        </DragSourcesWrapper>
        <div style={{ gridArea: 'optl', fontWeight: 600 }}>Optional</div>
        <DragSourcesWrapper style={{ gridArea: 'opti' }}>
          {optFields.length === 0 && <NoneLeftLabel>All optional fields have been assigned</NoneLeftLabel>}
          {optFields.map((f) => <FieldDragSource field={f} key={`uf-${f.id}`} panelX={panelX} panelY={panelY} />)}
        </DragSourcesWrapper>
        <NewColumnDropTarget
          style={{
            gridArea: 'new',
            height: '100%',
          }}
          handleNewColumnDrop={this.handleNewColumnDrop}
        />
      </DragSourceGrid>
    )
  }

  private renderTable() {
    const { rowDataArray, rowErrorsArray, focusedRow } = this.state
    const firstRow: string[] = _.first(rowDataArray) as string[]
    const numColumns = firstRow && firstRow.length

    const rowHasErrorsArray = rowErrorsArray.map((rowErrors) => this.hasError(rowErrors))
    const anyRowHasError = _.some(rowHasErrorsArray, (rowHasErrors) => rowHasErrors)

    return (
      <div>
        <table className='c-table'>
          <thead className='c-table-header'>
            <tr className='c-table-row'>
              {numColumns && Array(numColumns).fill(undefined).map((_, i) => this.renderHeaderCell(i))}
              <th className='c-table-cell c-table-cell--delete'/>
            </tr>
          </thead>
          <tbody className='c-table-body'>
            {rowDataArray && rowDataArray.map((_, row) => {
              if (!this.state.showErrorsOnly || rowHasErrorsArray[row] || focusedRow === row) {
                return this.renderDataRow(row, numColumns, rowErrorsArray[row])
              }
            })}
          </tbody>
        </table>
        {this.state.showErrorsOnly && !anyRowHasError && <AllValidMessage>All Rows are Valid</AllValidMessage>}
      </div>
    )
  }

  private renderHeaderCell = (i) => {
    const { panelX, panelY, columnMetadataArray } = this.state
    return (
      <th key={`header-${i}`} className='c-table-cell' style={{ backgroundColor: '#FFF' }}>
        <HeaderCellWrapper>
          <FieldDropTarget
            field={this.state.fields[i]}
            handleFieldDrop={(field) => this.handleFieldDrop(i, field)}
            handleFieldDelete={() => this.handleFieldDelete(i)}
            handleSplitColumn={() => this.handleSplitColumn(i)}
            handleColumnDelete={() => this.handleColumnDelete(i)}
            panelX={panelX} panelY={panelY}
          />
          {columnMetadataArray[i].splittable && (
            <Icon
              icon={IconNames.SPLIT_COLUMNS}
              color={GRAY}
              onClick={() => this.handleSplitColumn(i)}
              style={{ cursor: 'pointer', marginLeft: '0.5rem' }}
            />
          )}
        </HeaderCellWrapper>
      </th>
    )
  }

  private renderDataRow = (row, numColumns, rowErrors) => {
    const additionalProps: any = {}
    if (row === 0) {
      additionalProps.ref = (ref) => { this.firstDataRow = ref }
    }
    return (
      <BulkAddTableRow
        key={`row-${row}`}
        row={row}
        numColumns={numColumns}
        handleRowDelete={() => this.handleRowDelete(row)}
        handleDataChange={this.handleDataChange}
        rowData={this.state.rowDataArray[row]}
        rowErrors={rowErrors}
        onFocus={() => this.handleRowFocus(row)}
        onBlur={this.handleRowBlur}
        {...additionalProps}
      />
    )
  }

  private renderFooter() {
    let additionalProps: any = {}
    switch (this.state.stage) {
      case Stage.FIRM_SELECT:
        additionalProps = {
          cancelButtonText: 'Cancel',
          onCancelButtonClick: this.handleCancelClick,
        }
        break
      case Stage.CSV_SELECT:
        additionalProps = {
          cancelButtonText: 'Back',
          onCancelButtonClick: this.handleBackClick,
        }
        break
      case Stage.DATA_MANIPULATION:
        additionalProps = {
          cancelButtonText: 'Back',
          isPrimaryButtonDisabled: this.hasErrors(),
          onCancelButtonClick: this.handleBackClick,
          onPrimaryButtonClick: this.handleSave,
          primaryButtonText: 'Invite Employees',
        }
        break
      case Stage.SAVING:
        additionalProps = {
          isPrimaryButtonLoading: true,
          onPrimaryButtonClick: this.handleSave,
          primaryButtonText: 'Invite Employees',
        }
        break
      case Stage.SAVE_SUMMARY:
        additionalProps = {
          onPrimaryButtonClick: this.handleCloseAfterSave,
          primaryButtonText: 'Close',
        }
        break
    }
    return (
      <Footer {...additionalProps} />
    )
  }

  // Handlers
  // --------

  private handleFirmSelect = (firm) => {
    this.setState({
      firm,
      stage: Stage.CSV_SELECT,
    })
  }

  private handleCancelClick = () => {
    this.props.onClose()
  }

  private handleCloseAfterSave = () => {
    const { lastSavedEntityId } = this.state
    let pathname = `/view/${ViewIds.USER_INVITES}`
    if (lastSavedEntityId) {
      pathname = `${pathname}/entity/${lastSavedEntityId}`
    }
    browserHistory.push({ pathname })
    this.props.onClose()
  }

  private handleBackClick = () => {
    switch (this.state.stage) {
      case Stage.FIRM_SELECT:
        // shouldn't happen
        break
      case Stage.CSV_SELECT:
        this.setState({ stage: Stage.FIRM_SELECT })
        break
      case Stage.DATA_MANIPULATION:
        this.setState({
          availableFields: this.getResetAvailableFields(),
          columnMetadataArray: [],
          rowDataArray: null,
          stage: Stage.CSV_SELECT,
        })
        break
    }
  }

  private assignWrapperRef = (wrapperRef) => {
    this.wrapperRef = wrapperRef
    this.handleResize()
  }

  private handleResize = () => {
    if (this.wrapperRef) {
      const box: any = this.wrapperRef.getBoundingClientRect()
      this.setState({ panelX: box.x, panelY: box.y })
    }
  }

  private handleRowFocus = (row) => {
    this.setState({ focusedRow: row })
  }

  private handleRowBlur = () => {
    this.setState({ focusedRow: null })
  }

  private handleSave = () => {
    const store = apis.getStore()
    const schema = store.getRecord(SchemaIds.USER_INVITE)
    const entities = this.state.rowDataArray.map((rowData) => {
      const entity = store.createRecord(schema)
      this.state.fields.forEach((field, col) => {
        if (field) {
          const normalizedData = this.normalizeDataForSave(field, rowData[col])
          entity.set(field.id, normalizedData)
        }
      })
      entity.set(EntityProps.OWNING_FIRM, createEdge(this.state.firm.get(EntityProps.ID)))
      return entity
    })

    this.setState({ stage: Stage.SAVING })
    this.saveNextEntity(entities, 0)
  }

  private saveNextEntity(entities, row) {
    if (entities.length > 0) {
      this.setState({ currentSavingIndex: row })
      entities[0].save()
        .then((entity) => {
          this.setState({
            lastSavedEntityId: entity.get(EntityProps.ID),
            successfulSaves: this.state.successfulSaves + 1,
          })

          return this.saveNextEntity([ ...entities.slice(1) ], row + 1)
        })
        .catch((err) => {
          let message = err.message
          if (!message) {
            message = _.values(err.errors)[0]
          }
          this.setState({ saveErrors: [ ...this.state.saveErrors, { row,  error: message } ] })
          return this.saveNextEntity([ ...entities.slice(1) ], row + 1)
        })
    } else {
      this.setState({ stage: Stage.SAVE_SUMMARY })
    }
  }

  private handleUploadClick = () => {
    this.fileInput.click()
  }

  private handleStartFromScratchClick = () => {
    const fields = [ ...this.getAvailableRequiredFields() ]
    const oneOrMoreFields = this.getAvailableOneOrMoreFields()
    if (oneOrMoreFields.length > 0) {
      fields.push(oneOrMoreFields[0])
    }
    const availableFields = [ ...oneOrMoreFields.slice(1), ...this.getAvailableOptionalFields() ]
    const columnMetadataArray = Array(fields.length).fill(this.createDefaultColumnMetadata())
    const rowData = Array(fields.length).fill('')
    const rowDataArray = [ rowData ]
    const rowErrorsArray = [ this.validateRow(rowData, fields) ]
    this.setState({
      availableFields,
      columnMetadataArray,
      fields,
      rowDataArray,
      rowErrorsArray,
      stage: Stage.DATA_MANIPULATION,
    })
    this.focusFirstDataCell()
  }

  private handleDefaultValueSheetClosed = (defaultValue) => {
    if (defaultValue !== null) {
      const field = this.state.newColumnField
      const fields = [ ...this.state.fields ]
      const availableFields = [ ...this.state.availableFields ]
      this.removeFromAvailableFields(availableFields, field)

      this.removeFieldFromFields(fields, field)
      fields.push(field)

      const rowDataArray = this.state.rowDataArray.map((d: any[]) => [ ...d, defaultValue ])
      const columnMetadataArray = [ ...this.state.columnMetadataArray, this.createDefaultColumnMetadata() ]

      this.setState({ fields, availableFields, rowDataArray, columnMetadataArray })
    }
    this.setState({ newColumnField: null })
  }

  private handleAddRowClick = () => {
    const numColumns = this.state.rowDataArray[0].length
    const newRow = Array(numColumns).fill('')
    const rowDataArray = [ newRow, ...this.state.rowDataArray ]
    const rowErrorsArray = [ this.validateRow(newRow), ...this.state.rowErrorsArray ]
    this.setState({ rowDataArray, rowErrorsArray })
    this.focusFirstDataCell()
  }

  private handleAutoDetectClick = () => {
    const { rowDataArray } = this.state
    const numColumns = rowDataArray[0].length
    const numRows = rowDataArray.length
    const availableFields = [ ...this.state.availableFields ]
    const fields = [ ...this.state.fields ]
    let rowErrorsArray = [ ...this.state.rowErrorsArray ]

    const checkIfColumnIsField = (col, possibleField, checkFunc) => {
      let found = false
      let numFound = 0
      for (let row = 0; row < numRows; row++) {
        if (checkFunc(rowDataArray[row][col])) {
          numFound++
        }
      }

      if (numFound / numRows > 0.8) {
        fields[col] = possibleField
        this.removeField(availableFields, possibleField.id)
        rowErrorsArray = this.validateColumn(col, possibleField, rowDataArray, rowErrorsArray)
        found = true
      }

      return found
    }

    for (let col = 0; col < numColumns; col++) {
      let found = false
      const emailField = this.getField(availableFields, InviteProps.EMAIL)
      if (emailField) {
        found = checkIfColumnIsField(col, emailField, isEmailValid)
      }

      const phoneField = this.getField(availableFields, InviteProps.PHONE_NUMBER)
      if (!found && phoneField) {
        found = checkIfColumnIsField(col, phoneField, isPhoneValid)
      }

      const roleField = this.getField(availableFields, InviteProps.ROLE)
      if (!found && roleField) {
        found = checkIfColumnIsField(col, roleField, (cellData) => (this.validateRole(cellData) !== Error.INVALID))
      }
    }

    this.setState({ fields, availableFields, rowErrorsArray })
  }

  private getField = (fields, fieldId) => {
    return _.find(fields, (f) => (f.id === fieldId))
  }

  private removeField = (fields: IField[], fieldId) => {
    _.remove(fields, (f) => (f.id === fieldId))
  }

  private handleShowErrorsOnlyToggle = (newValue) => { this.setState({ showErrorsOnly: newValue.target.checked }) }

  private handleDataChange = (row, col, newValue) => {
    const { columnMetadataArray } = this.state
    const targetRow: any[] = this.state.rowDataArray[row]
    const newRow = this.replaceElementInArray(targetRow, newValue, col)
    const rowDataArray = this.replaceElementInArray(this.state.rowDataArray, newRow, row)
    const fieldAtCol = this.state.fields[col]
    if (fieldAtCol) {
      const error = this.validateValue(fieldAtCol, newValue, newRow)
      const previousError = this.state.rowErrorsArray[row][col]
      // const previousError = this.state.columnMetadataArray[col].rowErrors[row]
      if (error !== previousError) {
        this.setState({ rowErrorsArray: this.replaceErrorInRowErrorsArray(this.state.rowErrorsArray, error, col, row) })
      }
    }
    this.setState({ rowDataArray })
  }

  private handleFieldDrop = (col, field) => {
    const fields = [ ...this.state.fields ]
    const previousIndexOfField = this.getIndexOfField(fields, field)
    if (previousIndexOfField !== col) {
      const availableFields = [ ...this.state.availableFields ]
      const previousFieldAtCol = fields[col]

      this.removeFromAvailableFields(availableFields, field)
      if (previousFieldAtCol) {
        availableFields.push(previousFieldAtCol)
      }

      this.removeFieldFromFields(fields, field)
      const modifiedFields = [ ...fields.slice(0, col), field, ...fields.slice(col + 1) ]

      let rowErrorsArray = this.validateColumn(col, field)
      if (previousIndexOfField !== -1) {
        rowErrorsArray = this.removeErrorsFromColumn(previousIndexOfField, rowErrorsArray)
      }

      this.setState({ fields: modifiedFields, availableFields, rowErrorsArray })
    }
  }

  private handleNewColumnDrop = (field) => {
    this.setState({ newColumnField: field })
  }

  private removeFromAvailableFields = (availableFields, field) => {
    _.remove(availableFields, (f: IField) => (f.id === field.id))
  }

  private removeFieldFromFields = (fields, field) => {
    const previousIndexOfField = this.getIndexOfField(fields, field)
    if (previousIndexOfField >= 0) {
      fields[previousIndexOfField] = undefined
    }
  }

  private getIndexOfField = (fields, field) => {
    return _.findIndex(fields, (f: IField) => (f && f.id === field.id))
  }

  private handleFieldDelete = (col) => {
    const fieldAtCol = this.state.fields[col]
    if (fieldAtCol) {
      const fields = [ ...this.state.fields ]
      fields[col] = undefined
      const rowErrorsArray = this.replaceErrorsInColumn(this.state.rowErrorsArray, null, col)

      this.setState({ fields, availableFields: [ ...this.state.availableFields, fieldAtCol ], rowErrorsArray })
    }
  }

  private handleRowDelete = (row) => {
    if (row === 0 && this.state.rowDataArray.length === 1) {
      // When deleting the last row, just delete the content from the row
      const rowData = Array(this.state.fields.length).fill('')
      const rowDataArray = [ rowData ]
      const rowErrorsArray = [ this.validateRow(rowData) ]
      this.setState({ rowDataArray, rowErrorsArray })
    } else {
      this.setState({
        rowDataArray: [ ...this.state.rowDataArray.slice(0, row), ...this.state.rowDataArray.slice(row + 1) ],
        rowErrorsArray: [ ...this.state.rowErrorsArray.slice(0, row), ...this.state.rowErrorsArray.slice(row + 1) ],
      })
    }
  }

  private handleColumnDelete = (col) => {
    const fields = [ ...this.state.fields.slice(0, col), ...this.state.fields.slice(col + 1) ]
    const columnMetadataArray = [
      ...this.state.columnMetadataArray.slice(0, col),
      ...this.state.columnMetadataArray.slice(col + 1),
    ]

    const rowDataArray = []
    this.state.rowDataArray.forEach((rowData) => {
      const newRow = [ ...rowData.slice(0, col), ...rowData.slice(col + 1) ]
      rowDataArray.push(newRow)
    })

    this.setState({ fields, columnMetadataArray, rowDataArray })
  }

  private handleSplitColumn = (col) => {
    const fields = [ ...this.state.fields.slice(0, col + 1), undefined, ...this.state.fields.slice(col + 1) ]

    const rowDataArray = []
    this.state.rowDataArray.forEach((rowData: string[]) => {
      const splitData = rowData[col].split(' ')
      const newRowData = [
        ...rowData.slice(0, col), splitData[0], splitData.slice(1).join(' '),
        ...rowData.slice(col + 1),
      ]
      rowDataArray.push(newRowData)
    })

    const rowErrorsArray = this.validateColumn(col, this.state.fields[col], rowDataArray)

    const columnMetadataArray = [
      ...this.state.columnMetadataArray.slice(0, col),
      this.createDefaultColumnMetadata(),
      this.createDefaultColumnMetadata(),
      ...this.state.columnMetadataArray.slice(col + 1),
    ]

    this.setState({ fields, columnMetadataArray, rowDataArray, rowErrorsArray })
  }

  private handleNewFile = () => {
    const file = this.fileInput.files[0]
    const textType = /text.*/

    if (file.type.match(textType)) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const results = Papa.parse(reader.result)
        if (results.data.length > 0) {
          const numColumns = results.data[0].length
          const rowDataArray = this.cleanseData(results.data)
          const rowErrorsArray = Array(rowDataArray.length).fill(Array(numColumns).fill(null))
          const columnMetadataArray = this.generateColumnMetadataArray(rowDataArray)
          const fields = Array(results.data[0].length).fill(undefined)
          this.setState({ rowDataArray, rowErrorsArray, columnMetadataArray, fields, stage: Stage.DATA_MANIPULATION })
        }
      }
      reader.readAsText(file)
    }
  }

  // Helpers
  // -------

  private cleanseData = (resultData) => {
    const data = []
    // don't add any rows that have no values in them
    resultData.forEach((rowData) => {
      if (rowData.length > 0) {
        _.forEach(rowData, (colData) => {
          if (colData && colData.length > 0) {
            data.push(rowData)
            return false
          }
        })
      }
    })
    return data
  }

  private generateColumnMetadataArray = (rowDataArray): IColumnMetadata[] => {
    const numColumns = rowDataArray[0].length
    const twoWordCounts = Array(numColumns).fill(0)
    const numRowsToCount = Math.min(rowDataArray.length, 10)
    for (let row = 0; row < numRowsToCount; row++) {
      const rowData = rowDataArray[row]
      for (let col = 0; col < numColumns; col++) {
        if (rowData[col].indexOf(' ') !== -1 && rowData[col].match(/[a-z]/i)) {
          twoWordCounts[col]++
        }
      }
    }

    return twoWordCounts.map((count) => ({
      splittable: (count / numRowsToCount >= 0.5),
    }))
  }

  private getResetAvailableFields() {
    return _.cloneDeep(InviteFields)
  }

  private getAvailableRequiredFields = () => {
    return this.state.availableFields.filter((field) => (field.type === 'required'))
  }

  private getAvailableOneOrMoreFields = () => {
    return this.state.availableFields.filter((field) => (field.type === 'oneOrMore'))
  }

  private getAvailableOptionalFields = () => {
    return this.state.availableFields.filter((field) => (field.type === 'optional'))
  }

  private focusFirstDataCell = () => {
    setTimeout(() => {
      if (this.firstDataRow) {
        this.firstDataRow.focus()
      }
    }, 1)
  }

  private validateRow = (rowData, fields = this.state.fields) => {
    return fields.map((field, col) => this.validateValue(field, rowData[col], rowData))
  }

  private validateColumn = (col, field, rowDataArray = this.state.rowDataArray, rowErrorsArray = this.state.rowErrorsArray) => {
    return rowErrorsArray.map((rowErrors, row) => {
      const rowData = rowDataArray[row]
      const error = this.validateValue(field, rowData[col], rowData)
      return this.replaceElementInArray(rowErrors, error, col)
    })
  }

  private validateValue = (field, dataValue, rowData) => {
    if (!field) {
      return null
    }

    let error = null
    // Required validation
    switch(field.id) {
      case InviteProps.FIRST_NAME:
      case InviteProps.LAST_NAME:
      case InviteProps.ROLE:
      case InviteProps.EMAIL:
      case InviteProps.PHONE_NUMBER: {
        if (!dataValue || dataValue.length === 0) {
          error = Error.REQUIRED
        }
        break
      }
      default:
        break
    }

    // Additional validation
    if (!error) {
      if (field.id === InviteProps.ROLE) {
        error = this.validateRole(dataValue)
      } else if (field.id === InviteProps.EMAIL) {
        error = !isEmailValid(dataValue) ? Error.INVALID : null
      } else if (field.id === InviteProps.PHONE_NUMBER) {
        error = !isPhoneValid(dataValue) ? Error.INVALID : null
      }
    }

    return error
  }

  private validateRole = (dataValue) => {
    const normalizedValue = dataValue && dataValue.toLowerCase()
    if (!_.includes(_.values(Roles), normalizedValue)) {
      return Error.INVALID
    }
    return null
  }

  private replaceElementInArray(array, newElement, index) {
    return [ ...array.slice(0, index), newElement, ...array.slice(index + 1) ]
  }

  private addElementToArray(array, newElement, index) {
    return [ ...array.slice(0, index), newElement, ...array.slice(index) ]
  }

  private createDefaultColumnMetadata = () => {
    return { splittable: false }
  }

  private removeErrorsFromColumn = (col, rowErrorsArray = this.state.rowErrorsArray) => {
    return rowErrorsArray.map((rowErrors) => this.replaceElementInArray(rowErrors, null, col))
  }

  private replaceErrorInRowErrorsArray(rowErrorsArray, error, col, row) {
    const rowErrors = this.replaceElementInArray(rowErrorsArray[row], error, col)
    return this.replaceElementInArray(rowErrorsArray, rowErrors, row)
  }

  private replaceErrorsInColumn(rowErrorsArray, error, col) {
    return rowErrorsArray.map((rowErrors) => this.replaceElementInArray(rowErrors, error, col))
  }

  private hasError = (rowErrors) => {
    return _.some(rowErrors, (error) => (error !== null))
  }

  private dataErrorsPresent = () => {
    let hasErrors = false
    _.forEach(this.state.rowErrorsArray, (rowErrors) => {
      if (this.hasError(rowErrors)) {
        hasErrors = true
        return false // break
      }
    })
    return hasErrors
  }

  private hasErrors = () => {
    return (this.getAvailableRequiredFields().length > 0 ||
    this.getAvailableOneOrMoreFields().length > 1 ||
    this.dataErrorsPresent())
  }

  private normalizeDataForSave = (field, data) => {
    if (field.id === InviteProps.ROLE) {
      return data && data.toLowerCase()
    } else if (field.id === InviteProps.PHONE_NUMBER) {
      return { phone: data }
    }
    return data
  }
}

const BulkAddEntityPanel = DragDropContext(HTML5Backend)(InnerBulkAddEntityPanel)
export { BulkAddEntityPanel }
