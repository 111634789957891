import React from 'react'
import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core'
import { findDOMNode } from 'react-dom'
import _ from 'lodash'
import { findAndClickFirstInput } from './utils'
import { getDebugId } from 'browser/app/utils/utils'

type TempProps = Partial<{
  label: any
  frames: any
  errors: any
  value: any
  focusHotkey: any
}>

export function JSONElementFactory<T extends TempProps>(
  FormElement: React.ComponentType<T>
): React.ComponentType<T> {
  @HotkeysTarget
  class ElementWrapper extends React.Component<T, any> {
    public static displayName = 'JSONElementFactory'
    public render() {
      const { frames, errors } = this.props
      const errorText = errors && errors.join('\n')
      const dataDebugId = getDebugId(frames)

      return (
        <FormElement
          {...this.props}
          data-debug-id={dataDebugId}
          errorText={errorText}
        />
      )
    }

    public renderHotkeys() {
      const { focusHotkey, label } = this.props
      if (focusHotkey) {
        return (
          <Hotkeys>
            <Hotkey
              allowInInput={true}
              combo={focusHotkey}
              label={`Focus '${label}'`}
              onKeyDown={this.focus}
              stopPropagation={true}
              preventDefault={true}
              global={true}
            />
          </Hotkeys>
        )
      } else {
        return <Hotkeys />
      }
    }

    private focus = () => {
      // Will only work on wrapped components that use an input
      const element: any = findDOMNode(this)
      findAndClickFirstInput(element)
    }
  }
  
  return ElementWrapper
}
