import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { CodeMirrorInput } from 'browser/components/atomic-elements/atoms/input/code-mirror-input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { SelectList } from 'browser/components/atomic-elements/atoms/select/select-list'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'
import { JSONSchemaResolver } from 'shared-libs/resolvers/json-schema-resolver'

/**
 * @uiComponent
 */
interface IEntitySchemaEditorProps extends IBaseProps {
  metadata: any
}

interface IEntitySchemaEditorState {
  isSaving: boolean
}

export class EntitySchemaEditor extends React.Component<IEntitySchemaEditorProps, IEntitySchemaEditorState> {

  public store: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      isSaving: false,
    }
  }

  public render() {
    // TODO(peter/louis): Clean this up once we have the entitySchemas tab working
    const { className } = this.props
    return (
      <div className={classNames('grid-block', className)}>
        <div className='grid-content collapse'>
          {this.renderEntityFields()}
          {/* {this.renderMixinsSection()} */}
          {/* {this.renderSettingsSection()} */}
        </div>
      </div>
    )
  }

  private renderEntityFields() {
    return (
      <div>
        {/* <Section
          headerClassName='u-bumperTop'
          title='System Fields (Hidden to users)'
        >
          <InputField
            isHorizontalLayout={true}

            label='Entity Schema Name'
            value={metadata.displayName}
          />
          <InputField
            isHorizontalLayout={true}

            label='Unique ID'
            value={metadata.uniqueId}
          />
        </Section> */}
        <Section
          title='Fields'
        >
          {this.renderEntitySchemaProperties()}
        </Section>
      </div>
    )
  }

  private renderEntitySchemaProperties() {
    const entitySchema = this.props.metadata
    const namespace = entitySchema.metadata.namespace
    const properties = _.get(entitySchema, ['properties', namespace, 'properties'])
    if (_.isEmpty(properties)) {
      return (
        <div className='pa3 ba b--light-gray'>
          <HelpBlock className='c-helpBlock--collapse tc'>
            Contact support@withvector.com to add fields to this document type.
          </HelpBlock>
        </div>
      )
    }
    const fields = _.map(properties, (property: any, key) => {
      return this.renderEntitySchemaProperty(key, property)
    })

    return (
      <div>
        {fields}
        <div className='pa3 ba b--light-gray mt4'>
          <HelpBlock className='c-helpBlock--collapse tc'>
            Contact support@withvector.com to update fields to this document type.
          </HelpBlock>
        </div>
      </div>
    )
  }

  private renderEntitySchemaProperty(key, property) {
    return (
      <div key={key}>
        <InputField
          isHorizontalLayout={true}

          label='Label'
          value={property.label}
        />
        {this.renderEntitySchemaPropertyType(property)}
      </div>
    )
  }

  private renderEntitySchemaPropertyType(property: any) {
    if (property[JSONSchemaResolver.REF_KEY]) {
      return (
        <InputField
          isDisabled={true}
          isHorizontalLayout={true}
          label='Type'
          value={property[JSONSchemaResolver.REF_KEY]}
        />
      )
    }
    const typeOptions = [
      { label: 'Integer', value: 'integer' },
      { label: 'Number', value: 'number' },
      { label: 'Object', value: 'object' },
      { label: 'String', value: 'string' },
    ]
    return (
      <SelectField
        isDisabled={true}
        isHorizontalLayout={true}
        label='Type'
        options={typeOptions}
        value={property.type}
      />
    )
  }

  private renderMixinsSection() {
    return (
      <Section
        title='AllOf and Mixins'
      >
        <div className='row'>
          <div className='col-xs-4'>
            <p>
              AllOf property? Entities have entity.json and maybe another json?
            </p>
          </div>
          <div className='col-xs-8'>
            Entity.json
            Document.json
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-4'>
            <p>
              Entities all have the entity and entity schema mixin?
            </p>
          </div>
          <div className='col-xs-8'>
            Entity.json
            EntitySchema.json
          </div>
        </div>
      </Section>
    )
  }

  private renderSettingsSection() {
    return (
      <Section
        title='Entity Schema Settings'
      >
        <div className='row'>
          <div className='col-xs-4'>
            <p>
              TBD - What kind of settings go in here?
            </p>
          </div>
          <div className='col-xs-8'>
            TBD
          </div>
        </div>
      </Section>
    )
  }
}
