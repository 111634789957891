import { GeoRegionInput } from 'browser/components/atomic-elements/atoms/geo-region-input/geo-region-input'
import {
  GeoRegionInputSheet,
} from 'browser/components/atomic-elements/molecules/fields/geo-region-field/geo-region-input-sheet'

import LabelFieldFactory from 'browser/components/atomic-elements/higher-order-components/label-field-factory'
export const GeoRegionField = LabelFieldFactory({
  InputComponent: GeoRegionInput,
  InputSheetComponent: GeoRegionInputSheet,
})
