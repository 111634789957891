import classNames from 'classnames'
import React from 'react'
import { Props as ISplitPaneProps } from 'react-split-pane'
import SplitPane from 'react-split-pane'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

/**
 * @uiComponent
 */
interface IUISplitPaneProps extends IBaseProps, ISplitPaneProps {

}

export class UISplitPane extends React.Component<IUISplitPaneProps, any> {

  public render() {
    const { className, children } = this.props
    return (
      <SplitPane
        {...this.props}
        className={classNames('c-splitPane', className)}
      >
        {children}
      </SplitPane>
    )
  }
}
