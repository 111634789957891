import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import './_table-requirements-cell.scss'

export interface TableSimpleArrayCellProps extends IBaseProps {
  value: any
}

export const TableSimpleArrayCell: React.FC<TableSimpleArrayCellProps> = (props) => {
  // value must be an array
  const { value } = props
  const arrayItemString = _.join(
    _.flatMap(value, (obj) =>
      typeof obj === 'object'
      ? obj.displayName || _.values(obj)[0]
      : obj
    ),
    ', '
  )

  return <div>{arrayItemString}</div>
}
