import * as messaging from 'firebase/messaging'

import api from 'browser/app/models/apis'
import { getBrowserVersion } from 'browser/app/utils/utils'

const DEVICE_ENTITY_TYPE = '/1.0/entities/metadata/device.json'

export const requestFirebaseNotificationPermission = async () => {
  if (!(await messaging.isSupported())) {
    return
  }

  return Notification.requestPermission()
    .then(() =>
      messaging.getToken(messaging.getMessaging(),  {
        vapidKey:
          'BPX6b7dQl0qCkPon-cyfq5UCAQ_HnmMTLLiypgGHJOjMvc0os61UwwKq4oZ2kI3RbKiY-ZWMQVUZJ4Kq7UYpyNU',
      })
    )
    .then((firebaseToken) => {
      registerDevice(firebaseToken)
      return firebaseToken
    })
    .catch((err) => {
      console.log(`Unable to get permission to notify: ${err.message}`)
    })
}

export const registerDevice = (deviceToken: string) => {
  const store: any = api.getStore()
  const metadata = store.getRecord(DEVICE_ENTITY_TYPE)
  const deviceEntity = store.createRecord(metadata, {
    device: {
      identifier: deviceToken,
      platform: 'web',
      product: getBrowserVersion(),
      appVersion: 'browser',
    },
  })

  deviceEntity
    .save({ useOfflineQueue: false })
    .then((data) => {
      // silently do nothing
    })
    .catch((err) => {
      // silently do nothing
    })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage(messaging.getMessaging(), (payload) => {
      resolve(payload)
    })
  })
