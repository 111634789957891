import { Collapse, Tab, Tabs } from '@blueprintjs/core'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { ErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/error-block'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import countries from 'browser/components/atomic-elements/atoms/input/address-input/countries'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { QuantityInput } from 'browser/components/atomic-elements/atoms/input/quantity-input'
import { TextMaskInput } from 'browser/components/atomic-elements/atoms/input/text-mask-input'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { Select } from 'browser/components/atomic-elements/atoms/select'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'

const STATES = countries[0].regions

export interface IGeolocationFilterStateTabPanelProps extends IBaseProps {
  isStatic?: boolean
  onChange: (value: any) => void
  placeholder?: string
  size?: string
  value?: any
}

export class GeolocationFilterStateTabPanel extends React.Component<IGeolocationFilterStateTabPanelProps, any> {

  private filterKey: string

  constructor(props) {
    super(props)
    const { value } = props
    this.filterKey = value.key || value.path
  }

  public focus() {
    return false
  }

  public render() {
    const { value } = this.props
    const selection = this.getSelection()
    return (
      <FormGroup >
        <Select
          className='c-geoRegionInput-states'
          multi={true}
          onChange={this.handleStatesChange}
          options={STATES}
          optionLabelPath='name'
          optionValuePath='code'
          placeholder='Select states'
          value={selection}
        />
      </FormGroup>
    )
  }

  private getSelection() {
    const { value } = this.props
    return value.type === 'match' ? _.get(value, 'values', []) : []
  }

  private handleStatesChange = (states) => {
    const { onChange, value } = this.props
    onChange({
      filterType: 'geolocation',
      key: this.filterKey,
      label: value.label,
      path: `${this.filterKey}.region`,
      type: 'match',
      values: states,
    })
  }
}
