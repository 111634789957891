import _ from 'lodash'

import { useEffect, useState } from 'react'

import apis from 'browser/app/models/apis'


/**
 * @hook useFetchSalesInvoiceRelatedDocuments - *   react hook to return documents associated
 * for sales invoice
 * @returns a hook containing an array of document entities and loading state
 */
export const useFetchSalesInvoiceRelatedDocuments = (salesInvoice): any => {
  // TODO: define the response type after ESLint is upgraded (VD-6037)
  const [result, setResult] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    apis.salesInvoiceRelatedDocuments([salesInvoice])
    .then((docs) => {
      setResult(_.flatten(docs))
      setIsLoading(false)
    })
  }, [])

  return [result, isLoading]
}
