import _ from 'lodash'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'

import { Settings } from 'browser/app/models/settings'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { CheckboxField } from 'browser/components/atomic-elements/molecules/fields/checkbox-field'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'

interface ISelectProductsTabProps extends IBaseProps {
  navigation: any
  productName: string
  onClose: any
  settings: Settings
  size?: string
}

interface ISelectProductsTabStates {
  firm: Entity
  errorText: string
  isLoading: boolean
  user: Entity
}

export class SelectProductsTab extends React.Component<ISelectProductsTabProps, ISelectProductsTabStates> {

  constructor(props) {
    super(props)
    this.state = {
      errorText: null,
      firm: null,
      isLoading: false,
      user: null,
    }
  }

  public UNSAFE_componentWillMount() {
    const { settings } = this.props
    const firm = settings.getFirm()
    const user = settings.getUser()
    this.setState({ firm, user })
  }

  public render() {
    const { navigation, size } = this.props
    const { errorText, firm, isLoading } = this.state
    return (
      <div>
        <div className='ph4 mb4'>
          <h3 className='f3 lh-title mt2 mb4 tc'>
            Select Products
          </h3>

          <div className='ba b--light-gray pa3'>
            <div>
              <CheckboxField
                label='Document Management'
                onChange={null}
                helpText='Contact us for at info@withvector.com if you are interested in an enterprise account.'
              />
            </div>
          </div>

          <div className='bl br bb b--light-gray pa3'>
            <div className='w-100'>
              <CheckboxField
                className='u-bumperBottom'
                label='Carrier Portal'
                onChange={null}
              />

              <div className='ml4'>
                <FormTable>
                  <InputField
                    label='MC Number'
                  />
                  <InputField
                    label='Invite ID'
                    isDisabled={true}
                  />
                </FormTable>
              </div>
            </div>
          </div>
          <div className='bl br bb b--light-gray pa3'>
            <div className='w-100'>
              <CheckboxField
                className='u-bumperBottom'
                label='Customer Portal'
                onChange={null}
              />

              <div className='ml4'>
                <FormTable>
                  <InputField
                    label='MC Number'
                  />
                  <InputField
                    label='Invite ID'
                    isDisabled={true}
                  />
                </FormTable>
              </div>
            </div>
          </div>
          <div className='bl br bb b--light-gray pa3'>
            <CheckboxField
              label='Transportation Management System'
              onChange={null}
            />
          </div>
        </div>

        <Footer
          className='c-footer--transparent c-footer--horizontalPadding'
          cancelButtonText='Back'
          isPrimaryButtonLoading={isLoading}
          onPrimaryButtonClick={this.handleSetupOrganization}
          onCancelButtonClick={navigation.goBack}
          primaryButtonText='Next'
        />
      </div>
    )
  }

  private handleNameChange = (value) => {
    const { firm } = this.state
    firm.set('business.legalName', value)
    this.setState({ firm })
  }

  private handleLogoChange = (value) => {
    const { firm } = this.state
    firm.set('business.logo', value)
    this.setState({ firm })
  }

  private handleSetupOrganization = () => {
    const { onClose } = this.props
    const { firm, user } = this.state
    this.setState({ errorText: null, isLoading: true })
    return firm.save().then(() => {
      // set hasSeenJoinOrganizationModal to true so we don't
      user.set('user.preferences.hasSeenJoinOrganizationModal', true)
      return user.save()
    }).then(onClose).catch((error) => {
      this.setState({ errorText: 'Organization name cannot be empty.' })
    }).finally(() => this.setState({ isLoading: false }))
  }
}
