import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { IUIListProps, UIList } from 'browser/components/json-elements/atoms/list'
import 'browser/components/json-elements/molecules/list-field/_list-field.scss'

interface IUIListFieldProps extends IUIListProps {
  density?: string
  isStatic?: boolean
  isHorizontalLayout?: boolean
  isEditableInline?: boolean
  listWidthClassName?: string
  size?: string
  value: any
}

export class UIListField extends React.Component<IUIListFieldProps, any> {
  public render() {
    const {
      className,
      density,
      isEditableInline,
      isHorizontalLayout,
      isStatic,
      size,
      listWidthClassName,
      value,
    } = this.props

    const formGroupProps = {
      className,
      density,
      hasValue: !_.isEmpty(value),
      isEditableInline,
      isHorizontalLayout,
      isStatic,
      size,
    }
    const contentListClassName = isHorizontalLayout ? listWidthClassName : ''
    return (
      <FormGroup {...formGroupProps}>
        {this.renderList()}
      </FormGroup>
    )
  }

  private renderList() {
    const {
      density,
      ...listProps
    } = this.props
    return (<UIList {...listProps} />)
  }
}
