import apis from 'browser/app/models/apis'
import { browserHistory } from 'browser/history'
import _ from 'lodash'
import React, { Fragment } from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'

interface IRenditionBillingConfirmationModalProps extends IBaseProps {
  onClose: any
  selection: any[]
}

export class RenditionBillingConfirmationModal extends React.Component<IRenditionBillingConfirmationModalProps, any> {

  public static open(props) {
    return OverlayManager.openOverlayElement(
      <Modal>
        <RenditionBillingConfirmationModal {...props} />
      </Modal>,
    )
  }

  public render() {
    const { onClose, selection } = this.props
    let content = (
      <div>
        We will be sending an invoice for <b>all</b> loads ready to invoice.
      </div>
    )
    if (!_.isEmpty(selection)) {
      content = (
        <div>
          We will be sending an invoice for <b>{selection.length}</b> selected loads that are ready to invoice.
        </div>
      )
    }

    return (
      <Fragment>
        <div className='c-modalHeader'>
          <h4 className='c-modal-title'>Rendition Billing</h4>
        </div>
        <div className='c-modalBody collapse u-bumperLeft--lg u-bumperRight--lg u-bumperTop'>
          <div>
            {content} <br />
            Please make sure billing preferences, addresses, and emails are confirmed before proceeding.
          </div>
        </div>
        <Footer
          className='u-bumperTop'
          isVisible={true}
          cancelButtonText='Close'
          onCancelButtonClick={onClose}
          primaryButtonText='Start Rendition Billing'
          onPrimaryButtonClick={this.handleStartRenditionBillingPressed}
        />
      </Fragment>
    )
  }

  private handleStartRenditionBillingPressed = () => {
    const { onClose, selection } = this.props
    let entityIds = []
    // We are going to assume here if selection is empty we want to invoice all loads ready to invoices
    if (!_.isEmpty(selection)) {
      entityIds = _.map(selection, (entity) => entity.data.uniqueId)
    }
    // const redirectPath = `${redirectPathPrefix}/entity/${entity.uniqueId}`
    const toastKey = Toast.show({
      message: 'Rendition Billing in Progress...',
      position: Position.BOTTOM_RIGHT,
      timeout: 0,
    })
    this.handleOnRedirect()
    apis.startRenditionBilling(entityIds).then((response) => {
      Toast.update(toastKey, {
        message: (
          <div><a onClick={this.handleOnRedirect}>Rendition Billing Complete</a></div>
        ),
        position: Position.BOTTOM_RIGHT,
        timeout: 5000,
      })
    })
    onClose()
  }

  private handleOnRedirect = () => {
    if (window.location.pathname.includes('view/1c69ff82-3b17-4b6d-9908-38e334413314')) {
      window.location.reload()
    } else {
      browserHistory.push({
        pathname: `/view/cb21bbd1-29b9-4660-bdc1-7e168092c8ea/view/1c69ff82-3b17-4b6d-9908-38e334413314`,
      })
    }
  }
}
