/**
 * A container to query the pending jobs from the native layer and pass along
 * the jobs status to its children.  The children view is defined under
 * `viewPassthrough`.  The postfix `Passthrough` is a special marker so the abstract-render
 * will skip this property from its usual enrichment
 */

 import _ from 'lodash'
 import React from 'react'
 import moment from 'moment'

 import { Timer } from 'shared-libs/helpers/timer'
import apis from 'browser/app/models/apis'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { ErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/error-block'

/**
 * @uiComponent
 */
 export interface IUploadProgressProps {
   frames: any
   entityId: string
   /**
    * Sample
    *
    *
    *       "uiSyncSchema": {
    *          "type": "ui:uploadProgress",
    *          "entityId": {
    *            "type": "expression",
    *            "value": "executionEntityId"
    *          },
    *          "viewPassthrough": [
    *            {
    *              "type": "ui:view",
    *              "children": [
    *                {
    *                  "type": "ui:helpBlock",
    *                  "style": {
    *                    "color": "#000000",
    *                    "fontSize": 20,
    *                    "textAlign": "center",
    *                    "lineHeight": 24
    *                  },
    *                  "helpText": "Hello MINH"
    *                },
    *                {
    *                  "type": "ui:helpBlock",
    *                  "style": {
    *                    "color": "#000000",
    *                    "fontSize": 20,
    *                    "textAlign": "center",
    *                    "lineHeight": 24
    *                  },
    *                  "helpText": {
    *                    "type": "expression",
    *                    "value": "'Progress:' + progressDetails.numCompletedJobs + ' / ' + progressDetails.numJobs"
    *                  }
    *                }
    *              ]
    *            }
    *          ]
    *        },

    */
   viewPassthrough: any
 }

 interface IUploadProgressState {
   /**
    * this is a mirror copy of progressDetails.progress, but outside for ease of accessibility for
    * children components
    */
   progress: number
   progressDetails: IUploadProgress
   isLoading: boolean
   errors: any[]
 }

 export interface IUploadProgress {
   numJobs: number
   numCompletedJobs: number
   isCompleted: boolean

   // same information in different format
   progress: number // 0 to 1
   percentage: number // 0 to 100

   jobs: { [key: string]: IJobDetail }
   jobDetails: IJobDetail[]
 }

 interface IJobDetail {
   entityId: string
   taskId: string
   taskTag: string
   status: string
   progress?: number
   error?: string
 }

 interface ISubscribers {
   progress?: any
   success?: any
   failure?: any
 }

 /**
  * Follow the Android WorkManager status enum
  */
 export enum JobStatus {
   Blocked   = 'Blocked',
   Cancelled = 'Cancelled',
   Pending   = 'Pending',
   Enqueued  = 'Enqueued',
   Failed    = 'Failed',
   Running   = 'Running',
   Succeeded = 'Succeeded',
 }

 export class UploadProgress extends React.PureComponent<
   IUploadProgressProps,
   IUploadProgressState
 > {
   private executedActions: boolean[] = [] // ensure the action only executed once
   private eventBlur: any
   private eventFocus: any
   private subscribers: ISubscribers = {}
   private pollingId: any
   private startTime = moment()
   constructor(props: IUploadProgressProps) {
     super(props)
     this.state = {
       progress: 0,
       progressDetails: {
         numJobs: 0,
         numCompletedJobs: 0,
         progress: 0,
         percentage: 0,
         isCompleted: false,
         jobs: {},
         jobDetails: [],
       },
       errors: [],
       isLoading: true,
     }
   }

   public componentDidMount() {
     const { frames } = this.props
     // a way to cancel the timeout once it is navigated away from this screen
     const navigation = frames?.getValue('navigation')
     this.eventBlur = navigation?.addListener('blur', () => {
       this.stopListeners()
     })

     this.eventBlur = navigation?.addListener('focus', () => {
       this.startListeners()
     })

     const jobs = apis.pendingJobsForEntityId()
     const progressDetails = this.updateUploadProgress(jobs)

     this.startListeners()
     this.pollingId = new Timer(this.queryPendingJobs, 2000, false, 10 * 60 * 1000)
     this.setState({ isLoading: false, progressDetails })
   }

   public componentWillUnmount() {
     this.stopListeners()
     this.eventBlur?.()
     this.eventFocus?.()
   }

   public renderProgressView() {
     const { frames, viewPassthrough: uiSchema } = this.props
     const { progress, progressDetails } = this.state

     const renderer = frames.getContext('renderer')
     const uiSchemaPath = frames.getContext('uiSchemaPath').concat(['viewPassthrough'])
     const newFrames = renderer.createChildFrame(frames, { uiSchema, uiSchemaPath }, [
       {
         progress,
         progressDetails,
       },
     ])
     const defaultProps = _.omit(this.props, ['viewPassthrough'])

     return renderer.createElementFromFrame(newFrames, defaultProps)
   }

   public render() {
     if (this.state.isLoading) {
      return (
        <div>
          <LoadingSpinner />
        </div>
      )
     } else {
      return (
        <div style={{display: 'contents'}}>
          {this.renderProgressView()}
          <ErrorBlock
            className='justify-center'
            errorText={
              <div>
                <ul>
                  {_.map(this.state.errors, (e, index) => (<li key={index}>{e}</li>))}
                </ul>
              </div>
            }
          />
        </div>
      )
     }
   }

   /********************************************************************************/
   private startListeners() {
     if (!this.subscribers.progress) {
       this.subscribers.progress = apis.subscribeToHTTPRequestProgress(
         this.handleUploadProgress
       )
     }
     if (!this.subscribers.failure) {
       this.subscribers.failure = apis.subscribeToHTTPRequestFailure(this.handleUploadFailure)
     }
     if (!this.subscribers.success) {
       this.subscribers.success = apis.subscribeToHTTPRequestSuccess(this.handleUploadSuccess)
     }
   }

   private stopListeners() {
     this.subscribers?.progress?.remove()
     this.subscribers?.failure?.remove()
     this.subscribers?.success?.remove()
     this.pollingId?.dispose()
     this.subscribers = {}
   }

   private handleUploadProgress = (props: any) => {
     return this.updateJobStatus({
       ...props,
       status: props.progress === 1 ? JobStatus.Succeeded : JobStatus.Running,
     })
   }

   private handleUploadSuccess = (props: any) => {
     return this.updateJobStatus({ ...props, progress: 1, status: JobStatus.Succeeded })
   }

   private handleUploadConflict = (props: any) => {
     return this.updateJobStatus({ ...props, progress: 0, status: JobStatus.Blocked })
   }

   private handleUploadFailure = (props: any) => {
     const error = props.error
     if (error) {
      const errors = this.state.errors
      errors.push(error)
      this.setState({errors})
     }
     return this.updateJobStatus({ ...props, progress: 1, status: JobStatus.Failed })
   }

   /**
    * Update individual job status for entityId
    */
   private updateJobStatus = (job: IJobDetail) => {
     if (this.props.entityId !== job.taskTag) {
       return
     }

     const currJobs = this.state.progressDetails.jobs
     const foundJob = _.get(currJobs, job.taskId)
     if (_.isEmpty(foundJob)) {
       return
     }

     foundJob.progress = job.progress
     foundJob.status = job.status
     this.updateUploadProgress(currJobs)
   }

   /**
    * Query for all pending jobs for current entity
    * @returns
    */
   private queryPendingJobs = () => {
     const { entityId } = this.props
     const jobs = apis.totalJobsForEntityId()

    /**
     * for iOS, the query only return the pending jobs so need to treat missing entry as a completed job
     */
    const prevJobs = this.state.progressDetails.jobs
    const newJobs = _.assignWith(jobs, prevJobs, (newEntry, prevEntry) => {
      // if the job is already succeed, no need to update it anymore
      if (prevEntry?.status === JobStatus.Succeeded) {
        return prevEntry
      }
      // absence of an entry means the job is succeeded
      if (_.isEmpty(newEntry)) {
        prevEntry.status = JobStatus.Succeeded
        return prevEntry
      }
      return undefined
    })

    this.updateUploadProgress(newJobs)
    return Promise.resolve(newJobs)
   }

   /**
    * Update the overall upload progress which is contained multiple upload jobs
    * @param jobs
    */
   private updateUploadProgress = (jobs: any) => {
     const progressDetails = _.cloneDeep(this.state.progressDetails)

     const numJobs          = _.size(jobs)
     const numCompletedJobs = _.sumBy(jobs, 'progress') | 0
     progressDetails.numJobs          = numJobs
     progressDetails.numCompletedJobs = numCompletedJobs
     progressDetails.jobs             = jobs
     progressDetails.jobDetails       = _.values(jobs)
     const percentage            = numJobs > 0 ? Math.round(numCompletedJobs * 100 / numJobs)  : 100
     progressDetails.progress    = percentage / 100 // 0.0 to 1.0
     progressDetails.percentage  = percentage       // 0 to 100
     progressDetails.isCompleted = numJobs === numCompletedJobs

     this.setState({ progressDetails, progress: progressDetails.progress })

     return progressDetails
   }
 }
