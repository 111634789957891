import classNames from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { Textarea } from 'browser/components/atomic-elements/atoms/textarea/textarea'
import { CheckboxField } from 'browser/components/atomic-elements/molecules/fields/checkbox-field'
import 'browser/components/atomic-elements/organisms/feed/_comment-input.scss'
import { ComponentsContext } from 'browser/contexts/components/components-context'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'

// TODO: from blueprintjs v5, we're still on v2. Downloaded from v5 icon list at
// https://blueprintjs.com/docs/#icons/icons-list. Can be replaced by using the
// `send-message` icon from v5 when we upgrade.
const SendMessageIcon = require('images/send-message.svg')

interface ICommentInputProps extends IBaseProps {
  addButtonDropdown?: any
  onSubmit: (value: string) => Promise<void>
  onAttach?: () => void
  onFocus?: () => void
  attachButtonEnabled?: boolean
  showViewableOptions?: boolean
  commentInputPlaceholder?: string
}

export const CommentInput: React.FC<ICommentInputProps> = (props) => {
  const { platform } = useContext(ComponentsContext)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const commentInput = useRef(null)

  const handlePostComment = (event) => {
    const { onSubmit } = props
    const trimmedComment = comment.trim()
    if (!_.isEmpty(trimmedComment)) {
      setComment('')
      setIsSaving(true)
      onSubmit(trimmedComment).then(() => {
        setIsSaving(false)
      })
    }
  }

  const handleOnEnterPressed = (event) => {
    event.preventDefault()
    handlePostComment(event)
  }

  const handleClick = (event) => {
    if (commentInput != null && $.contains(commentInput, event.target)) {
      setIsExpanded(true)
      props.onFocus()
    } else {
      setIsExpanded(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick, false)
    return () => {
      document.removeEventListener('click', handleClick, false)
    }
  })

  const updateComment = (value) => {
    setComment(value)
  }

  return (
    <div className={classNames('c-commentInput-container u-noPrint')} ref={commentInput}>
      {props.onAttach && (
        <Button
          isDisabled={!props.attachButtonEnabled}
          className={classNames(
            'c-commentInput-attachment-button c-button--square',
            Classes.MINIMAL,
            Classes.iconClass(IconNames.PAPERCLIP)
          )}
          onClick={props.onAttach}
        />
      )}
      <div
        className={classNames('c-commentInput', {
          'c-commentInput--focused': isExpanded,
        })}
      >
        <div className="flex">
          <Textarea
            data-debug-id="details-comments-input"
            onChange={updateComment}
            onEnterPressed={handleOnEnterPressed}
            minRows={1}
            placeholder="Type a message..."
            tabIndex="1"
            textareaClassName={classNames('c-commentInput-textarea', {
              'c-commentInput-textarea--focused': isExpanded,
            })}
            value={comment}
          />
          {props.addButtonDropdown && (
            <div className="c-commentInput-additionalInputs">
              <TetherTarget closeOnPortalClick={true} tethered={props.addButtonDropdown}>
                <Button
                  className={classNames(
                    'c-button--square',
                    Classes.MINIMAL,
                    Classes.iconClass(IconNames.PAPERCLIP)
                  )}
                />
              </TetherTarget>
              <TetherTarget closeOnPortalClick={true} tethered={props.addButtonDropdown}>
                <Button
                  className={classNames(
                    'c-button--square',
                    Classes.MINIMAL,
                    Classes.iconClass(IconNames.MAP_MARKER)
                  )}
                />
              </TetherTarget>
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames('c-commentInput-controls', {
          'c-commentInput-controls--visible': isExpanded,
        })}
      >
        {props.showViewableOptions && (
          <div className="u-flex u-flexGrow u-alignItemsCenter">
            <HelpBlock className="u-bumperRight collapse">Viewable</HelpBlock>
            <CheckboxField className="u-bumperRight" onChange={_.noop} label="Internal" />
            <CheckboxField onChange={_.noop} label="External" />
          </div>
        )}
        <Button
          tabIndex={2}
          className={classNames('c-commentInput-mobile-send-button', Classes.INTENT_PRIMARY)}
          isLoading={isSaving}
          onClick={handlePostComment}
        >
          {platform === PlatformType.MOBILE_WEB ? (
            <img className="c-commentInput-mobile-send-icon" src={SendMessageIcon} />
          ) : (
            'Comment'
          )}
        </Button>
      </div>
    </div>
  )
}

CommentInput.defaultProps = {
  onFocus: _.noop,
  showViewableOptions: false,
  commentInputPlaceholder: 'Type a message...',
  attachButtonEnabled: true,
}
