import _ from 'lodash'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'

export class EntityAssociationsRequest {

  private isArrayOfEdges: boolean
  private isEdgeOnEntity: boolean
  private edgeDefinition: any
  private edgePath: string

  constructor(props) {
    const { associatedEntitySchema, edgePath, entitySchema, isEdgeOnEntity } = props
    const store = apis.getStore()
    const pathFragment = edgePath.split('.').join('.properties.')
    const edgeEntitySchema = isEdgeOnEntity ? entitySchema : associatedEntitySchema
    const edgeSchemaPath = `properties.${pathFragment}`
    this.edgeDefinition = _.get(edgeEntitySchema, edgeSchemaPath)
    this.isArrayOfEdges = this.edgeDefinition.type === 'array'
    this.isEdgeOnEntity = isEdgeOnEntity
    this.edgePath = edgePath
  }

  public addAssociateEntity(entity: Entity, associatedEntity: Entity, props?: any) {
    const {
      isArrayOfEdges,
      isEdgeOnEntity,
      edgeDefinition,
      edgePath,
    } = this
    let promise
    if (isEdgeOnEntity) {
      if (isArrayOfEdges) {
        this.addEntityToArrayOfEdges(entity, associatedEntity, edgeDefinition, edgePath)
      } else {
        this.addEntityToEdge(entity, associatedEntity, edgeDefinition, edgePath)
      }
      // if edge is on entity, then entity is the "child" model and associatedEntity
      // is the parent record. This means the associatedEntity needs to be created
      // first before entity can reference it
      entity.addParentRecord(associatedEntity)
      promise = entity.save(props)
    } else {
      if (isArrayOfEdges) {
        this.addEntityToArrayOfEdges(associatedEntity, entity, edgeDefinition, edgePath)
      } else {
        this.addEntityToEdge(associatedEntity, entity, edgeDefinition, edgePath)
      }
      // if edge is on associatedEntity, then associatedEntity is the "child" model and
      // entity needs to be created first. saveChildRecord will take care of that
      promise = entity.saveChildRecord(associatedEntity, props)
    }
    return promise.then(() => associatedEntity)
  }

  public removeAssociateEntity(entity, associatedEntity) {
    const {
      isArrayOfEdges,
      isEdgeOnEntity,
      edgeDefinition,
      edgePath,
    } = this
    if (isEdgeOnEntity) {
      if (isArrayOfEdges) {
        this.removeEntityToArrayOfEdges(entity, associatedEntity, edgeDefinition, edgePath)
      } else {
        this.removeEntityToEdge(entity, associatedEntity, edgeDefinition, edgePath)
      }
      return entity.save()
    } else {
      if (isArrayOfEdges) {
        this.removeEntityToArrayOfEdges(associatedEntity, entity, edgeDefinition, edgePath)
      } else {
        this.removeEntityToEdge(associatedEntity, entity, edgeDefinition, edgePath)
      }
      return associatedEntity.save()
    }
  }

  private addEntityToArrayOfEdges(entity, entityToAdd, edgeDefinition, edgePath) {
    const edgesArray = entity.get(edgePath, [])
    edgesArray.push({
      entityId: entityToAdd.uniqueId,
      entityType: edgeDefinition.items.entityType,
    })
    entity.set(edgePath, edgesArray)
  }

  private addEntityToEdge(entity, entityToAdd, edgeDefinition, edgePath) {
    const edge: any = entity.get(edgePath, {})
    edge.entityId = entityToAdd.uniqueId
    edge.entityType = edgeDefinition.entityType
    entity.set(edgePath, edge)
  }

  private removeEntityToArrayOfEdges(entity, entityToRemove, edgeDefinition, edgePath) {
    const edgesArray = entity.get(edgePath, [])
    _.remove(edgesArray, { entityId: entityToRemove.uniqueId })
    entity.set(edgePath, edgesArray)
  }

  private removeEntityToEdge(entity, entityToRemove, edgeDefinition, edgePath) {
    entity.set(edgePath, undefined)
  }

}
