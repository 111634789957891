import _ from 'lodash'

import { Entity } from './entity'
import { ExpressionEvaluator, EsprimaParser } from '../helpers/evaluation'
import { AbstractSettings } from './abstract-settings'

const FILTER_TYPE_INCLUDE = "INCLUDE"
const FILTER_TYPE_EXCLUDE = "EXCLUDE"

/**
 * @param entityTypes The entity type list to filter (e.g. doc schemas or workflow plans)
 * @param settings Global settings model that has access to entity type filters.
 * @param context Context variables that may be needed by filter formulas.
 * @returns A new entity type list filtered through any inclusion/exclusion
 * lists defined in firm's entityTypeFilter settings.
 */
export function filterEntityTypes(entityTypes: Entity[], settings: AbstractSettings, context: any = {}): Entity[] {
  const evaluator = ExpressionEvaluator.create()
    .setDefaultContext(context)
    .setASTParser(EsprimaParser)
    .setErrorLogger((error) => console.log(error))
  const filters = _.flatMap(settings.getEntityTypeFilterSettings(), 'entityTypeFilterSettings.filters')

  // produce a 2-level table: `{ INCLUDE: { <uuid>: {...} }, EXCLUDE: { <uuid>: {...} } }`
  const filterMap = _.reduce(filters, (acc, filter) => {
    if (evaluator.evaluate(filter.formula)) {
      _.forEach(filter.entityTypes, edge => {
        const filterType = filter.filterType
        const entityId = edge.entityId
        _.set(acc, [filterType, entityId], { filterType, entityId })
      })
    }
    return acc
  }, {})

  // types must past both inclusion + exclusion restrictions
  return _.filter(entityTypes, (entityType) => {
    const uniqueId = entityType.get('uniqueId')
    const included = _.has(filterMap, [FILTER_TYPE_INCLUDE, uniqueId]) || _.isEmpty(filterMap[FILTER_TYPE_INCLUDE])
    const excluded = _.has(filterMap, [FILTER_TYPE_EXCLUDE, uniqueId])
    return included && !excluded
  })
}
