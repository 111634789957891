import React from 'react'

import { AddFirmPage } from 'browser/components/domains/firm/add-firm-page'

export const AddFirmOmniButtonItem: React.FC = (props) => {
  const handleOpenAddFirmPage = () => {
    AddFirmPage.open()
  }

  return (
    <li className='c-dropdownList-item' onClick={handleOpenAddFirmPage} >
      Firm Setup
    </li>
  )
}
