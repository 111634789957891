// If/when Rendition Billing V1 is deprecated, simply remove RB_V1_CONFIG and refactor RB_V2_CONFIG constants

/**
 * Config for Rendition Billing V1
 */
export const RB_V1_CONFIG = {
  invoiceOptions: [
    { label: 'Invoice canceled', value: 'Invoice canceled' },
    { label: 'Awaiting documents', value: 'Awaiting documents' },
    { label: 'Invoice ready', value: 'Invoice ready' },
    { label: 'Invoice sent', value: 'Invoice sent' },
    { label: 'Invoice send failed', value: 'Invoice send failed' },
    { label: 'Invoice archived', value: 'Invoice archived' },
  ],
  INVOICE_CUSTOMER_PATH: 'rbInvoice.customer.entityId',
  INVOICE_NUMBER_PATH: 'rbInvoice.invoiceNumber',
  INVOICE_ORDERS_PATH: 'rbInvoice.orders',
  INVOICE_STATUS_PATH: 'rbInvoice.invoiceStatus'
}

/**
 * Config for Rendition Billing V2
 */
export const INVOICE_PATH = 'core_accounting_accountsReceivable_salesInvoice'
export const CONSOLIDATED_SALES_INVOICE_PATH = 'core_accounting_accountsReceivable_consolidatedSalesInvoice'
export const INVOICE_ENTITY_TYPE = "/1.0/entities/metadata/core_accounting_accountsReceivable_salesInvoice.json"
export const INVOICE_REQUIREMENTS_PATH = `${INVOICE_PATH}.readyToInvoice.requirements`
export const RB_V2_CONFIG = {
  invoiceOptions: [
    { label: 'Pending', value: 'Pending' },
    { label: 'Submitted', value: 'Submitted' },
    { label: 'Sent', value: 'Sent' },
    { label: 'Archived', value: 'Archived' },
    { label: 'Canceled', value: 'Canceled' }
  ],
  INVOICE_CUSTOMER_PATH: `${INVOICE_PATH}.billTo.entityId`,
  INVOICE_NUMBER_PATH: `${INVOICE_PATH}.invoiceNumber`,
  INVOICE_ORDERS_PATH: `${INVOICE_PATH}.order`,
  INVOICE_STATUS_PATH: `${INVOICE_PATH}.stage`
}

export const ORDER_PATH = 'core_fulfilment_salesOrder'
export const ORDER_ENTITY_TYPE = `/1.0/entities/metadata/${ORDER_PATH}.json`

export const CONSOLIDATED_SALES_INVOICE_ID = '8143804c-541b-4979-928f-b48ff78643dc'
export const TMW_SALES_INVOICE_ID = '946facf0-28d6-4416-9e04-5baa9d9589b0'
