import _ from 'lodash'
import React from 'react'
import Promise from 'bluebird'
import classNames from 'classnames'
import { ISelectProps, Select } from '..'
import apis from 'browser/app/models/apis'
import { InputActionMeta } from 'react-select'
import { SchemaUris } from 'shared-libs/models/schema'
import { translateString } from 'shared-libs/helpers/utils'
import { withContext } from 'shared-libs/components/context/with-context'
import { ISheetManagerContext, SheetContext } from '../../sheet/sheet-manager'
import { globalTranslationTable } from 'browser/mobile/util/global-translation-table'
import { EntityFormSheet } from 'browser/components/atomic-elements/organisms/entity/entity-form-sheet'

import 'browser/components/atomic-elements/atoms/select/crm-search/_crm-search.scss'

const MIN_PARTIAL_CARRIER_LENGTH = 3

const translatedPlaceholder = translateString('CRM_Search_Field_Placeholder', globalTranslationTable)

interface ICRMSearchState {
  queryResults: any[]
  dotOptions: any[]
  isLoading?: boolean
}

interface ContextProps {
  sheetContext: ISheetManagerContext
}

@withContext(SheetContext, 'sheetContext')
export class CRMSearch extends React.Component<ISelectProps & ContextProps, ICRMSearchState> {
  static defaultProps: Partial<ISelectProps> = {
    placeholder: translatedPlaceholder,
  }

  private searchPromise: Promise<void> | null = null

  constructor(props) {
    super(props)

    this.state = {
      queryResults: [],
      dotOptions: [],
    }
  }

  public render() {
    const { value } = this.props
    const { dotOptions, isLoading } = this.state

    return (
      <Select
        {...this.props}
        isAsync={true}
        onInputChange={this.handleTextChangeWrapper}
        formatOptionLabel={this.renderCRMItem}
        valueRenderer={this.renderCRMValue}
        onChange={this.handleValueChange}
        filterOption={_.stubTrue}
        isLoading={isLoading}
        options={dotOptions}
        isClearable={true}
        value={value}
      />
    )
  }

  private renderCRMValue = ({ data }) => {
    return data.displayName
  }

  private renderCRMItem = (props) => {
    const { queryResults } = this.state
    const {
      label,
      value: idx,
    } = props
    const data = queryResults[idx]
    if (!data) {
      return null
    }

    return (
      <div
        className={classNames('c-crmSearchItem', {
          'c-crmSearchItem--suggested': !!data.uniqueId,
        })}
        {...props.innerProps}
      >
        <div>{label}</div>
        <div>DOT#: {data.carrier.dotNumber}</div>
      </div>
    )
  }

  private handleValueChange = (idx) => {
    const { onChange, sheetContext } = this.props
    const { queryResults } = this.state
    const result = queryResults[idx]
    if (!result) {
      onChange(undefined)
      return
    }

    this.searchPromise?.cancel()
    this.searchPromise = null
    this.setState({
      dotOptions: [],
    })

    if (result.uniqueId) {
      const edge = {
        entityId: result.uniqueId,
        displayName: result.business.legalName,
      }
      onChange(edge)
    } else {
      const carrierSchema = apis.getStore().getRecord(SchemaUris.CARRIER)

      const defaultValue = {}
      if (result.business?.legalName) {
        _.set(defaultValue, 'business.legalName', _.get(result, 'business.legalName'))
      }
      if (result.carrier?.dotNumber) {
        _.set(defaultValue, 'carrier.dotNumber', _.get(result, 'carrier.dotNumber'))
      }

      sheetContext.openOverlay(
        <EntityFormSheet
          defaultValue={defaultValue}
          entitySchema={carrierSchema}
          onCreate={this.handleCarrierCreated}
        />,
      )
    }
  }

  private handleTextChange = (companyCodeValue: string, actionMeta?: InputActionMeta) => {
    const { onInputChange } = this.props
    onInputChange?.(companyCodeValue, actionMeta)

    this.searchPromise?.cancel()
    if (companyCodeValue.length < MIN_PARTIAL_CARRIER_LENGTH) {
      this.setState({
        queryResults: [],
        dotOptions: [],
        isLoading: false,
      })
      return
    }

    this.setState({
      isLoading: true,
    })

    this.searchPromise = apis
      .getCRMSearchResults(companyCodeValue)
      .then((results) => {
        const options = _.map(results, (result, idx) => {
          const { business } = result
          const label = business.legalName
          return { label, value: idx }
        })
        this.setState({
          queryResults: results,
          dotOptions: options,
        })
      })
      .catch((e) => {
        console.error('Error searching for carriers:', e)
        this.setState({
          queryResults: [],
          dotOptions: [],
        })
      })
      .finally(() => {
        this.searchPromise = null
        this.setState({
          isLoading: false,
        })
      })
  }
  private handleTextChangeDebounced = _.debounce(this.handleTextChange, 250)

  private handleTextChangeWrapper = (companyCodeValue: string, actionMeta?: InputActionMeta) => {
    this.setState({
      isLoading: true,
    })

    this.handleTextChangeDebounced(companyCodeValue, actionMeta)
  }

  private handleCarrierCreated = (entity) => {
    const { onChange } = this.props
    const edge = {
      entityId: entity.uniqueId,
      displayName: entity.business.legalName
    }
    onChange?.(edge)
  }
}
