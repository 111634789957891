import _ from 'lodash'
import React from 'react'
import { evaluateExpressionWithScopes } from 'shared-libs/helpers/evaluation'
import { findAndClickFirstInput, getLabelProps } from './utils'
import { EntityPreview } from 'browser/components/atomic-elements/organisms/entity/entity-preview/entity-preview'
import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core'
import { findDOMNode } from 'react-dom'
import { getDebugId } from 'browser/app/utils/utils'
import { CustomFormulas } from 'shared-libs/helpers/formulas'
import { ComponentsContext } from 'browser/contexts/components/components-context'

// TODO: temporarily providing some base component props to quiet compiler
// errors, for now.
type TempProps = Partial<{
  entity: any
  label: any
  frames: any
  errors: any
  value: any
  focusHotkey: any

  showInlinePreview: any
  inlinePreviewSchema: any
  previewComponent: any
  uiSchemaPassthrough: any
}>

export function JSONSelectFactory<T extends TempProps>(
  SelectComponent: React.ComponentType<T>
): React.ComponentType<T> {
  @HotkeysTarget
  class SelectWrapper extends React.Component<T, any> {
    public static displayName = 'JSONSelectFactory'
    private selectProps: object

    static contextType = ComponentsContext
    context!: React.ContextType<typeof ComponentsContext>

    constructor(props: T) {
      super(props)
      this.selectProps = this.getSelectProps(props)
    }

    public render() {
      const { entity, frames, errors, value, showInlinePreview, inlinePreviewSchema, uiSchemaPassthrough, previewComponent } = this.props
      const dataDebugId = getDebugId(frames)
      const errorText = errors && errors.join('\n')
      let preview = null

      if (value && (showInlinePreview || previewComponent)) {
        preview = <EntityPreview
          entity={entity}
          value={value}
          previewSchema={inlinePreviewSchema}
          previewComponent={previewComponent}
          uiSchema={uiSchemaPassthrough}
          components={this.context.components}
        />
      }

      return (
        <div>
          <SelectComponent
            {...this.props}
            {...this.selectProps}
            data-debug-id={dataDebugId}
            errorText={errorText}
          />
          {preview}
        </div>
      )
    }

    public renderHotkeys() {
      const { focusHotkey, label } = this.props
      if (focusHotkey) {
        return (
          <Hotkeys>
            <Hotkey
              allowInInput={true}
              combo={focusHotkey}
              label={`Focus '${label}'`}
              onKeyDown={this.focus}
              stopPropagation={true}
              preventDefault={true}
              global={true}
            />
          </Hotkeys>
        )
      } else {
        return <Hotkeys />
      }
    }

    private focus = () => {
      // Will only work on wrapped components that use an input
      const element: any = findDOMNode(this)
      findAndClickFirstInput(element)
    }

    private getEntityType(schema, props) {
      const { entityType, isLabelEditable } = props
      // if label is editable, extract entityType from labeled schema
      if (isLabelEditable) {
        const valueSchema = schema.properties.value
        return valueSchema.entityType
      }
      // use entityType from props
      return entityType
    }

    private getOptions(schema, props, optionLabelPath = null) {
      if (schema?.type === 'array') {
        // if property is an array, then the select is a multi select. This means that the
        // options are on the item. E.g.
        // ratingMethods: { items: { enum: [...], type: string }, type: array }
        schema = schema.items
      }

      const enums = _.get(schema, 'enum', [])
      const enumOptions = _.get(schema, 'enumOptions', [])
      const suggestions = _.get(schema, 'suggestions', [])
      const { formula } = props
      if (!_.isEmpty(enumOptions)) {
        return enumOptions
      }
      let options = !_.isEmpty(enums) ? enums : suggestions
      if (_.isEmpty(options) && formula) {
        // The `formula` prop has two uses.
        //
        // Previously, the only usage was here:
        // apps/webapp/src/browser/components/atomic-elements/atoms/select/index.tsx
        // This enables simple filtering on keyword-based data sources, like `enum`.
        // In this case, we grab that data and pass it back in as a context variable
        // called `data` that the formula can use.
        //
        // However, the usage below (when `enum`, etc. are not present) opens up
        // selects to other data sources, like arrays sitting at arbitrary value
        // paths. In this case, no `data` variable is needed, just execute the
        // formula as-is using frames for value path lookups.
        options = evaluateExpressionWithScopes(props.frames, formula, CustomFormulas)
      }
      return _.map(options, (option) => ({
        value: option,
        label: schema?.type === 'object' ? _.get(option, optionLabelPath, '') : option,
      }))
    }

    private getSelectProps(props) {
      const { frames, optionLabelPath } = props
      const schema = frames.getContext('dataSchema')
      return {
        entityType: this.getEntityType(schema, props),
        labelProps: getLabelProps(schema, props),
        options: this.getOptions(schema, props, optionLabelPath),
      }
    }
  }

  return SelectWrapper
}
