import { UserProps } from 'shared-libs/models/prop-constants'
import styled from 'styled-components'

export const CONTENT_WIDTH = 1000

export const TABLE_COLUMNS = [
  { label: 'Name',  entityKey: UserProps.NAME },
  { label: 'Firm',  entityKey: UserProps.FIRM_NAME },
  { label: 'Role',  entityKey: UserProps.ROLE, width: 70 },
  { label: 'Email', entityKey: UserProps.FIRST_EMAIL_VALUE, width: 250 },
  { label: 'Phone', entityKey: UserProps.FIRST_PHONE_VALUE },
  { label: null,    entityKey: 'actions' },
  { label: null,    entityKey: 'delete', className: 'c-table-cell--delete' },
]

export const KEEP_DISCARD_OPTIONS = [
  { label: 'Keep', value: 'keep' },
  { label: 'Discard', value: 'discard' },
]

export const ContentWrapper = styled.div`
  margin: 2rem;
  width: ${CONTENT_WIDTH}px;
`

export const Option = styled.div`
  display: flex;
`

export const OptionProp = styled.div`
  width: ${CONTENT_WIDTH / 5}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:not(:last-child) {
    margin-right: 1rem;
  }
`

export const NameOptionProp = styled(OptionProp)`
  width: 175px;
`

export const RoleOptionProp = styled(OptionProp)`
  width: 150px;
`

export const ShortOptionProp = styled(OptionProp)`
  width: 130px;
`

export const EmailOptionProp = styled(OptionProp)`
  width: 250px;
`

export const OptionPropLabel = styled.span`
  font-style: italic;
  color: #616161;
`

export const TableCell = styled.td`
  vertical-align: middle !important;
  overflow: hidden;
`

export const DeleteTableCell = styled(TableCell)`
  overflow: visible;
  width: 1px;
`
