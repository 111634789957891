import _ from 'lodash'
import React from 'react'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/organisms/entity/order-detail-card/_order-detail-card.scss'

import moment from 'moment'
import { Entity } from 'shared-libs/models/entity'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { EntitySelectField } from 'browser/components/atomic-elements/molecules/fields/select-field/entity-select-field'

/**
 * @uiComponent
 */
interface ISalesInvoiceDetailSection extends IBaseProps {
  entity: Entity
}

export const SalesInvoiceDetailSection: React.FC<ISalesInvoiceDetailSection> = (props) => {
  const { entity } = props
  const submitDate = entity.get('core_accounting_accountsReceivable_salesInvoice.submittedDate')
  const delivDate = _.get(entity, ['emailable', 'deliveredDate'])
  const openDate = _.get(entity, ['emailable', 'openedDate'])
  const createDate = entity.get('creationDate')

  return (
    <Section>
        <EntitySelectField
          isDisabled={true}
          label='Customer'
          entityType='/1.0/entities/metadata/customer.json'
          value={entity.get('core_accounting_accountsReceivable_salesInvoice.billTo')}
        />
        <InputField
          isDisabled={true}
          label='Customer Id'
          value={_.get(entity, ['core_accounting_accountsReceivable_salesInvoice', 'billTo', 'denormalizedProperties', 'business.sourceId'])}
        />
        <InputField
          isDisabled={true}
          label='Total Revenue'
          numDecimalPlaces={2}
          value={entity.get('core_accounting_accountsReceivable_salesInvoice.totalRevenue.value')}
        />
        <InputField
          isDisabled={true}
          label='Invoice Submitted Date'
          value={submitDate ? moment(submitDate).format('MMM D, YYYY, h:mm A') : submitDate}
        />
        <InputField
          isDisabled={true}
          placeholder='Invoice has not been billed'
          label='Email Delivered Date'
          value={delivDate ? moment(delivDate).format('MMM D, YYYY, h:mm A') : delivDate}
        />
        <InputField
          isDisabled={true}
          placeholder='Invoice has not been billed'
          label='Email Opened Date'
          value={openDate ? moment(openDate).format('MMM D, YYYY, h:mm A') : openDate}
        />
        <InputField
          isDisabled={true}
          label='Source Created Date'
          value={createDate ? moment(createDate).format('MMM D, YYYY, h:mm A') : createDate}
        />
        <InputField
          isDisabled={true}
          label='Source Id'
          value={entity.get('core_accounting_accountsReceivable_salesInvoice.sourceId')}
        />
    </Section>
  )
}
