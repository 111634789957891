import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { PickerWheel } from '../picker-wheel/picker-wheel'
import { PickerWheelGroup } from '../picker-wheel/picker-wheel-group'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { TextMaskInput } from 'browser/components/atomic-elements/atoms/input/text-mask-input'
import {
  timeMask,
  deriveState,
  ITimeInputProps,
  ITimeInputState,
  createAutoCorrectedTimePipe,
} from 'browser/components/atomic-elements/atoms/input/time-input'

const DEFAULT_MINUTE_PRECISION = 15

export interface IMobileTimeInputProps extends ITimeInputProps {
  minutePrecision?: number
}

export interface IMobileTimeInputState extends ITimeInputState {
  selectedHour: number
  selectedMinute: number
}

/**
 * A mobile-web variant of the desktop time-input. Displays a picker wheel in a
 * popover.
 *
 * Similar to time-input, it uses the minutes/seconds to alter the underlying
 * date-time string value.
 */
export class MobileTimePicker extends React.Component<IMobileTimeInputProps, IMobileTimeInputState> {
  static defaultProps: Partial<IMobileTimeInputProps> = {
    minutePrecision: DEFAULT_MINUTE_PRECISION,
  }

  private hoursItems: number[]
  private minutesItems: number[]

  constructor(props: IMobileTimeInputProps) {
    super(props)

    const state = this.getNextStateFromProps(props)

    this.state = {
      ...state,
      selectedHour: state.date.hours(),
      selectedMinute: this.normalizeMinute(state.date.minutes()),
    }

    const { minutePrecision } = props

    if (60 % minutePrecision !== 0) {
      throw new Error('minutePrecision must divide evenly into 60')
    }

    // NOTE: military time not yet supported (same with desktop time-input)
    this.hoursItems = Array.from({ length: 24 }, (_, i) => i)
    this.minutesItems = Array.from({ length: 60 / minutePrecision }, (_, i) => i * minutePrecision)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IMobileTimeInputProps): void {
    // See time-input.tsx; avoiding re-rendering if there are changes only at
    // lower levels of granularity.
    const oldDateTime = moment(this.props.value)
    const newDateTime = moment(nextProps.value)

    if (!oldDateTime.isSame(newDateTime, 'minutes')) {
      this.setState(this.getNextStateFromProps(nextProps))
    }
  }

  private getNextStateFromProps(props: IMobileTimeInputProps): ITimeInputState {
    return deriveState(props)
  }

  public render() {
    const { isDisabled } = this.props
    const { time } = this.state
    const canOpenPopover = !isDisabled

    return (
      <TetherTarget
        automaticAdjustOffset={true}
        isEnabled={canOpenPopover}
        openOnClick={false}
        openOnFocus={true}
        tethered={this.renderTimePickerPopover()}
      >
        <div className="c-mobile-time-input">
          <TextMaskInput
            {...this.props}
            onChange={this.onTextEdited}
            guide={false}
            keepCharPositions={false}
            mask={timeMask}
            pipe={createAutoCorrectedTimePipe()}
            placeholder='hh:mm'
            value={time}
          />
        </div>
      </TetherTarget>
    )
  }

  private renderTimePickerPopover() {
    const { selectedHour, selectedMinute } = this.state

    return (
      <Popover
        className={classNames('collapse', this.props.className)}
      >
        <PickerWheelGroup>
          <PickerWheel
            curvature={1}
            items={this.hoursItems}
            selectedItem={selectedHour}
            onItemSelected={this.handleHourChange}
          />
          <PickerWheel
            curvature={-1}
            items={this.minutesItems}
            selectedItem={selectedMinute}
            onItemSelected={this.handleMinuteChange}
          />
        </PickerWheelGroup>
      </Popover>
    )
  }

  private onTextEdited = (value: string) => {
    const { onChange } = this.props
    this.setState({ time: value })
    if (_.isEmpty(value) || !value.match(/\d\d:\d\d/)) {
      return onChange(undefined)
    }
    const tokens = value.split(':')
    const hours = parseInt(tokens[0])
    const minutes = parseInt(tokens[1])
    this.handlePickerChange(hours, minutes)
  }

  private handleHourChange = (hour: number) => {
    this.setState({ selectedHour: hour }, () => {
      const { selectedMinute } = this.state
      this.handlePickerChange(hour, selectedMinute)
    })
  }

  private handleMinuteChange = (minute: number) => {
    this.setState({ selectedMinute: minute }, () => {
      const { selectedHour } = this.state
      this.handlePickerChange(selectedHour, minute)
    })
  }

  private handlePickerChange = (hours: number, minutes: number) => {
    const { onChange } = this.props

    const { date } = this.state
    date.hours(hours)
    date.minutes(minutes)

    onChange(date.toISOString())
  }

  /** normalize the minute to the nearest minutePrecision */
  private normalizeMinute = (minute: number) => {
    const { minutePrecision } = this.props
    return Math.round(minute / minutePrecision) * minutePrecision
  }
}
