import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Sheet } from 'browser/components/atomic-elements/atoms/sheet'

interface ICarrierQuoteAssignmentSheetProps extends IBaseProps {
  brokerOrder: any
  carrierQuote: any
  onConfirm: (value: any) => void
}

// Assign carrier quote to a brokerOrder
export class CarrierQuoteAssignmentSheet
  extends React.Component<ICarrierQuoteAssignmentSheetProps, any> {

  private sheet: Sheet

  public render() {
    const { brokerOrder, carrierQuote } = this.props
    const { carrier } = carrierQuote
    const handleRef = (ref) => { this.sheet = ref }
    return (
      <Sheet
        ref={handleRef}
        size='sm'
      >
        <div className='c-sheet-body--padded u-textCenter'>
          <p className='c-p'>Assign carrier {carrier.displayName} to order {brokerOrder.displayName}.</p>
          <p className='c-p'>Set this carrier quote to assigned.</p>
        </div>
        <Footer
          isVisible={true}
          onCancelButtonClick={this.handleClose}
          onPrimaryButtonClick={this.handleConfirm}
          primaryButtonText='Confirm'
        />
      </Sheet>
    )
  }

  private handleConfirm = () => {
    const { carrierQuote, onConfirm } = this.props
    onConfirm(carrierQuote)
    this.sheet.close()
  }

  private handleClose = () => {
    this.sheet.close()
  }
}
