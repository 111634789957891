import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import Promise from 'bluebird'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { VerificationInput } from 'browser/components/atomic-elements/molecules/verification-input/verification-input'
import 'browser/components/atomic-elements/organisms/verification-block/_verification-block.scss'

interface IVerificationBlockProps extends IBaseProps {
  identity: string
  onSubmit: (value: string) => Promise<any>
  onSuccess: () => void
  value?: string
  size?: string
}

export const VerificationBlock: React.FC<IVerificationBlockProps> = (props) => {
  const [errorText, setErrorText] = useState<string>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isResendingCode, setIsResendingCode] = useState<boolean>(false)
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)
  const [value, setValue] = useState<string>(props.value)

  const handleChange = (code) => {
    const { onSubmit, onSuccess } = props
    setIsLoading(true)
    setValue(code)
    onSubmit(code).then(() => {
      setIsSuccessful(true)
      setTimeout(onSuccess, 400)
    }).catch(() => {
      setErrorText('Invalid verification code')
    }).finally(() => setIsLoading(false))
  }

  const showResendSuccessToast = () => {
    Toast.show({
      message: 'Sent new verification code.',
      position: Position.BOTTOM_RIGHT,
    })
  }

  const handleResendCode = () => {
    setErrorText(null)
    setIsResendingCode(true)
    setValue('')
    apis.sendVerificationCode(props.identity).then(() => {
      showResendSuccessToast()
    }).finally(() => setIsResendingCode(false))
  }

  useEffect(() => {
    if (value && value.length === 6) {
      handleChange(value)
    }
  }, [])

  return (
    <>
      <div className="relative">
        <VerificationInput
          size={props.size}
          className="mt4"
          errorText={errorText}
          isLoading={isLoading}
          onChange={handleChange}
          value={value}
        />
        {isSuccessful && (
          <div className="c-verificationBlock-success">
            <Icon icon={IconNames.TICK} />
          </div>
        )}
      </div>
      <div className="tc mt2">
        <Button
          className={classNames(Classes.MINIMAL, Classes.iconClass(IconNames.REFRESH))}
          size="small"
          isLoading={isResendingCode}
          onClick={handleResendCode}
        >
          Resend Code
        </Button>
      </div>
    </>
  )
}
