import { Spinner, SpinnerSize } from "@blueprintjs/core"
import { Cell } from "fixed-data-table-2"
import BPromise from 'bluebird'
import _ from "lodash"
import React from "react"

export interface IAsyncCellProps {
  promise: BPromise<any> | Promise<any>
  children: React.ReactNode
}

interface IAsyncCellState {
  cellData?: any
  isLoading: boolean
  isError: boolean
}

export class AsyncCell extends React.Component<IAsyncCellProps, IAsyncCellState> {
  private promise: BPromise<any>

  constructor(props: IAsyncCellProps) {
    super(props)
    if (props.promise) {
      this.promise = BPromise.resolve(props.promise)
    }
    this.state = {
      isLoading: !_.isNil(props.promise),
      isError: false,
    }
  }

  componentDidUpdate(prevProps: Readonly<IAsyncCellProps>, prevState: Readonly<IAsyncCellState>, snapshot?: any): void {
    const { promise } = this.props
    if (promise !== prevProps.promise) {
      this.promise?.cancel()
      if (promise) {
        this.promise = BPromise.resolve(promise)
        this.updateAsyncCellContents()
      } else {
        this.promise = undefined
      }
      return
    }
    // If we erase an existing promise, clear out cached async results.
    if (_.isNil(promise) && !_.isNil(this.state.cellData)) {
      this.setState({
        cellData: undefined
      })
    }
  }

  updateAsyncCellContents(): BPromise<void> {
    if (!this.promise) return
    this.setState({
      isLoading: true,
      cellData: undefined,
    })
    // `await` on a BPromise that's cancelled is an edge case with unpredictable results
    return this.promise
      .then((result) => {
        if (_.isNil(result)) {
          this.setState({
            isLoading: false,
            cellData: undefined
          })
        } else {
          this.setState({
            isLoading: false,
            cellData: result
          })
        }
      })
      .catch((e: any) => {
        // TODO(BOBBI): Add error state and CSS?
        this.setState({
          isLoading: false,
          cellData: undefined,
        })
        console.warn(`cell value async function failed: ${e.message}\n${e.stack}`)
      })
  }

  render(): React.ReactNode {
    const { cellData, isLoading } = this.state
    if (isLoading) {
      return (
        <Spinner size={SpinnerSize.SMALL}/>
      )
    } else if (!_.isNil(cellData)) {
      // TODO(BOBBI): Distinguish null celldata from error celldata?
      return (
        <Cell {...this.props}>
          {cellData}
        </Cell>
      )
    } else {
      return (
        <Cell {...this.props}>
          {this.props.children}
        </Cell>
      )
    }
  }
}