import apis from 'browser/app/models/apis'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { GeoRegionInput } from 'browser/components/atomic-elements/atoms/geo-region-input/geo-region-input'
// tslint:disable-next-line:max-line-length
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { GeolocationFilterCityTabPanel } from './city-filter/city-tab-panel'
import { GeolocationFilterStateTabPanel } from './state-filter/state-tab-panel'
import { GeolocationFilterZipcodeTabPanel } from './zipcode-filter/zipcode-tab-panel'
import { NegatableFilter } from '../negation-filter'

export interface IGeolocationFilterPopoverProps extends IBaseProps {
  activeFilters: any[]
  entitySchema: object
  filter: object
  onClear?: () => void
  onClose?: () => void
  onChange: (value: any, ...args) => void
  onResize?: () => void
}

interface IGeolocationFilterPopoverState {
  filter: any
}

export class GeolocationFilterPopover
  extends React.Component<IGeolocationFilterPopoverProps, IGeolocationFilterPopoverState> {

  private collection: any

  constructor(props) {
    super(props)
    const { activeFilters, entitySchema, filter } = props
    const isCitySearch = filter.type !== 'prefix'
    this.state = {
      filter: _.clone(filter),
    }
  }

  private handleNegation = (filter, ...args) => {
    this.setState({ filter })
    this.props.onChange(filter, ...args)
  }

  public render() {
    const { activeFilters, entitySchema } = this.props
    const { filter } = this.state
    const types = [
      { id: 'geoDistance', title: 'City' },
      { id: 'match', title: 'State' },
      { id: 'prefix', title: 'ZIP' },
    ]
    return (
      <Popover className='collapse c-geoFilterPopover'>
        <div className='u-innerBumperTop u-innerBumperLeft u-innerBumperBottom'>
          <NegatableFilter
            filter={filter}
            onChange={this.handleNegation}
          />
        </div>
        <div className='pa3'>
          <FormTable>
            <GeoRegionInput
              showEditableFields={true}
              defaultType='geoDistance'
              onChange={this.handleFilterChanged}
              renderCityTabPanel={this.renderCityTabPanel}
              renderStateTabPanel={this.renderStateTabPanel}
              renderZipTabPanel={this.renderZipTabPanel}
              types={types}
              value={filter}
            />
          </FormTable>
        </div>
        {this.renderFooter()}
      </Popover>
    )
  }

  private handleApplyPressed = () => {
    // Set the distance field to zero if undefined.  The geo-region-input allows for a blank value (undefined)
    const filter = { ...this.state.filter, distance: this.state.filter.distance || 0 }
    this.props.onChange(filter)
    this.props.onClose()
  }

  private handleFilterChanged = (filter) => {
    const { isNegation } = this.state.filter
    filter.isNegation = isNegation

    const { onResize } = this.props
    this.setState({ filter }, onResize)
  }

  private renderCityTabPanel = () => {
    const { filter } = this.state
    return (
      <GeolocationFilterCityTabPanel
        {...this.props}
        onChange={this.handleFilterChanged}
        value={filter}
      />
    )
  }

  private renderStateTabPanel = () => {
    const { filter } = this.state
    return (
      <GeolocationFilterStateTabPanel
        {...this.props}
        onChange={this.handleFilterChanged}
        value={filter}
      />
    )
  }

  private renderZipTabPanel = () => {
    const { filter } = this.state
    return (
      <GeolocationFilterZipcodeTabPanel
        {...this.props}
        onChange={this.handleFilterChanged}
        value={filter}
      />
    )
  }

  private renderFooter() {
    const { filter } = this.state
    return (
      <Footer
        onCancelButtonClick={this.props.onClose}
        onPrimaryButtonClick={_.isNil(filter) ? null : this.handleApplyPressed}
        primaryButtonText='Apply'
      />
    )
  }
}
