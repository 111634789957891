import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { IToggleProps, Toggle } from 'browser/components/atomic-elements/atoms/toggle/toggle'
import 'browser/components/atomic-elements/molecules/fields/toggle-field/_toggle-field.scss'
import { IFocusableInput, InlineEditableInputProps } from 'browser/components/atomic-elements/molecules/inline-editable'

export interface IToggleFieldImplProps extends IToggleProps, InlineEditableInputProps {
  density?: string
  onChange: (value: boolean) => void
  value?: boolean
}

export class ToggleFieldImpl extends React.Component<IToggleFieldImplProps, any> implements IFocusableInput {

  public static defaultProps: Partial<IToggleFieldImplProps> = {
    value: false,
  }

  private input: Toggle

  public focus() {
    this.input.focus()
  }

  public render() {
    const {
      className,
      density,
      inlineEditControls,
      isHorizontalLayout,
      value,
    } = this.props
    const props = _.omit(this.props, 'className')
    return (
      <FormGroup
        className={classNames('c-toggleField', className)}
        hasValue={!_.isNil(value)}
        isHorizontalLayout={isHorizontalLayout}
      >
        <Toggle
          {...props as IToggleProps}
          onChange={this.handleChange}
          ref={(ref) => this.input = ref}
        />
      </FormGroup>
    )
  }

  private handleChange = (event) => {
    const value = event.target.checked
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }
}
