import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Classes, Position, Tooltip } from '@blueprintjs/core'

import { ViewsIndex as ViewPage } from 'browser/app/pages/app/views'
import { AppBody } from 'browser/components/atomic-elements/atoms/app-body'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import 'browser/components/atomic-elements/organisms/tool-container/_tool-container.scss'
import { ViewContext } from 'browser/contexts/view-context'

const SentryRoute = Sentry.withSentryRouting(Route);

interface IToolContainerProps extends IBaseProps {
  location: any
  match: any
  tabs: any[]
  title: string
  showViewChooserSidebar?: boolean
  showZero?: boolean
}

// tslint:disable-next-line:no-empty-interface
interface IToolContainerState {
}

@withRouter
export class ToolContainer extends
  React.PureComponent<IToolContainerProps, IToolContainerState> {

  public render() {
    const { location, match } = this.props
    const route = match.url
    return (
      <AppBody className='vertical'>
        <CardHeader
          className='u-noPrint u-displayBlock c-cardHeader--flushBottom'
        >
          {this.renderSubtools()}
        </CardHeader>
        <Switch>
          <SentryRoute
            exact={true}
            path={match.url}
            render={this.handleRenderIndexRoute}
          />
          <SentryRoute
            path={`${route}/view/:viewId/custom/:customViewId`}
            render={this.handleRenderViewDetail}
          />
          <SentryRoute
            path={`${route}/view/:viewId`}
            render={this.handleRenderViewDetail}
          />
        </Switch>
      </AppBody>
    )
  }

  private renderSubtools() {
    const { tabs, title } = this.props
    return (
      <div className='flex u-justifyContentSpaceBetween u-alignItemsFlexEnd'>
        <CardHeaderItem className='c-cardHeader-item--grow c-viewHeader-title u-rightFlush'>
          <h2
            className='c-cardHeader-superTitle u-ellipsis'
            data-debug-id={`viewHeaderTitle:${title}`}
          >
            {title}
          </h2>
        </CardHeaderItem>
        <div className='c-tabs c-tabs--flush c-tabs--links'>
          <div className={Classes.TAB_LIST}>
            {_.map(tabs, this.renderTabLink)}
          </div>
        </div>
      </div>
    )
  }

  private renderTabLink = (tab, index) => {
    const { match, showZero } = this.props
    const { className, count, title, viewId } = tab
    const shouldShowDigits = (showZero && _.isNumber(count)) || count
    const formattedTitle = shouldShowDigits ? `${title} (${count})` : title
    const pathname = `${match.url}/view/${viewId}`

    const titleContent = tab.tooltip ? (
      <Tooltip
        content={tab.tooltip}
        position={Position.BOTTOM}
      >
        {formattedTitle}
      </Tooltip>
    ) : formattedTitle

    return (
      <NavLink
        activeClassName='active'
        className={classNames(Classes.TAB, className)}
        key={index}
        to={{ pathname }}
      >
        {titleContent}
      </NavLink>
    )
  }

  private handleRenderIndexRoute = () => {
    const { match, tabs } = this.props
    const viewId = _.first(tabs).viewId
    const pathname = `${match.url}/view/${viewId}`
    return (
      <Redirect
        to={{
          pathname,
          state: {},
        }}
      />)
  }

  private handleRenderViewDetail = (props) => {
    const { history, location, match, staticContext } = props

    return (
      <div className='grid-block c-toolContainer-view'>
        {this.renderViewChooser()}
        <ViewContext.Provider value={{isWithinPortal: true, basePath: this.props.match.url}}>
          <ViewPage
            history={history}
            location={location}
            match={match}
          />
        </ViewContext.Provider>
      </div>
    )
  }

  private renderViewChooser() {
    const { showViewChooserSidebar } = this.props
    if (showViewChooserSidebar) {
      return (
        <div className='grid-block shrink c-toolContainer-viewChooserSideNavigation'>
          <div className='grid-content collapse'>
            <Label
              isSecondary={true}
              className='u-bumperTop--lg u-bumperLeft--lg'
            >
              Accounting
            </Label>
            <a className='c-sideNavigationBarItem c-sideNavigationBarItem--white'>
              <div className='c-sideNavigationBarItem-label'>
                View Name #1
              </div>
            </a>
            <a className='c-sideNavigationBarItem c-sideNavigationBarItem--white'>
              <div className='c-sideNavigationBarItem-label'>
                View Name #2
              </div>
            </a>

            <Label
              isSecondary={true}
              className='u-bumperTop u-innerBumperTop u-bumperLeft--lg u-borderTop'
            >
              Accounting
            </Label>
            <a className='c-sideNavigationBarItem c-sideNavigationBarItem--white'>
              <div className='c-sideNavigationBarItem-label'>
                Report #1
              </div>
            </a>
            <a className='c-sideNavigationBarItem c-sideNavigationBarItem--white'>
              <div className='c-sideNavigationBarItem-label'>
                Report #2
              </div>
            </a>
            <a className='c-sideNavigationBarItem c-sideNavigationBarItem--white'>
              <div className='c-sideNavigationBarItem-label'>
                Report #3
              </div>
            </a>
            <a className='c-sideNavigationBarItem c-sideNavigationBarItem--white'>
              <div className='c-sideNavigationBarItem-label'>
                Report #4
              </div>
            </a>

          </div>
        </div>
      )
    }
  }
}
