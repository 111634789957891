import _ from 'lodash'

import { ErrorModal } from 'browser/components/atomic-elements/organisms/error-modal'
// tslint:disable-next-line:max-line-length
import { ImagingSummaryModal } from 'browser/components/atomic-elements/organisms/imaging-summary-modal/imaging-summary-modal'
import { EntityListView } from 'browser/components/containers/entity-list-view'
import { Entity } from 'shared-libs/models/entity'
import apis from 'browser/app/models/apis'
import { Query } from 'shared-libs/models/query'

export class DocumentImagingListView extends EntityListView {

  public handleProcessOutstandingDocuments = () => {
    const { dataSets, settings } = this.props
    const { documentClassificationTasks } = dataSets
    const tasksReadyToPublish = _.filter(documentClassificationTasks.entities, (task) => {
      return task.documentClassificationTask.taskStatus === 'Indexed'
    })
    return this.fetchRelatedSalesOrders(tasksReadyToPublish).then((relatedSalesOrders) => {
      ImagingSummaryModal.open({
        settings,
        entities: tasksReadyToPublish,
        relatedSalesOrders,
        onSave: _.noop,
      })
    })
  }

  public handleReImagingQueuedDocuments = () => {
    this.handleDocumentReImaging(['Queued'])
  }

  public handleReImagingIndexedDocuments = () => {
    this.handleDocumentReImaging(['Indexed'])
  }

  private handleDocumentReImaging = (statuses = ['Queued']) => {
    const { dataSets } = this.props
    const { documentClassificationTasks } = dataSets
    const allTasks = _.filter(documentClassificationTasks.entities, (task) => {
      const taskState =  _.get(task, 'status.state')
      const taskStatus =  _.get(task, 'documentClassificationTask.taskStatus')
      return !_.isEqual(taskState, 'busy') && _.includes(statuses, taskStatus)
    })

    ImagingSummaryModal.open({
      headerTitle: 'Queued documents',
      bodyMessage: `The following documents(${_.size(allTasks)}) will be reimaged`,
      primaryButtonTitle: 'Submit',
      processingToastMessage: 'Processing ...',

      entities: allTasks,
      getDocumentNames: ImagingSummaryModal.getClassificationTaskName,
      preSaveCallback: ImagingSummaryModal.scrubClassificationTask,

      onSave: _.noop,
    })
  }

  public handleStartImagingPressed = () => {
    const { dataSets } = this.props
    const rows = _.get(dataSets, 'documentClassificationTasks.content')
    if (_.isEmpty(rows)) {
      this.openErrorModal()
      return
    }
    const firstRow = _.first(rows)
    this.handleRowClick([firstRow], firstRow)
  }

  private openErrorModal = () => {
    ErrorModal.open({
      errorText: 'Please add more new uploads to start imaging',
      errorTitle: 'No more new uploads',
    })
  }

  private fetchRelatedSalesOrders(entities): Promise<any> {
    const { settings } = this.props
    const orderEntityType = settings.getOrderEntityType()
    const orderIdentifier = settings.getOrderIdentifierPath()

    if (!orderEntityType) {
      return Promise.resolve([])
    }

    const loadNumbers = _.chain(entities)
      .flatMap('documentClassificationTask.classificationTasks')
      .map('documentProperties.document.name')
      .compact()
      .uniq()
      .value()

    const collection = new Query(apis)
      .setEntityType(orderEntityType)
      .setMetadata({
        maxSizePerGroup: 1,
      })
      .setFilters([
        {
          path: orderIdentifier,
          values: loadNumbers,
          type: 'match',
        },
      ])
      .setGroups([
        {
          path: orderIdentifier,
          type: 'field',
        },
      ])
      .setOrders([
        {
          path: 'precomputation.displayName',
          type: 'ascending',
        },
      ])
      .getCollection()

    return collection
      .find()
      .then((result) => {
        return _.flatMap(result, 'children')
      })
      .catch(() => {
        return Promise.resolve([])
      })
  }

}
