import _ from 'lodash'
import React from 'react'

import { BaseMap, IBaseMapProps } from './base-map'
import { CanvasLineLayer } from './layers/canvas-line-layer'
import { MarkerLayer } from './layers/marker-layer'
import { LoadMarker } from './markers/load-marker'
import { LocationMarker } from './markers/location-marker'
import { fitBounds } from './utils/fit-bounds'

// middle of USA
const DefaultLocation = {
  latitude: 39,
  longitude: -95,
}

interface ILoadMapProps extends IBaseMapProps {
  loads: any[]
  locationEntityIdPath?: string
  locationGeolocationPath?: string | string[]
  locations: any[]
  width?: number
  height: number
  mapStyle: string
  onSelect: (value: any[], selected: any) => void
  padding?: object
  shouldAlwaysShowRoute?: boolean
  selection?: any[]
  zoomLevel?: number
}

// Plots truck loads on a map: last known location, current projected location, destination.
// Let the user click to select loads and destinations. When that happens, show full routes.
export class LoadMap extends React.Component<ILoadMapProps, any> {
  public static defaultProps: Partial<ILoadMapProps> = {
    locationEntityIdPath: 'uniqueId',
    locationGeolocationPath: 'location.address.geolocation',
    onSelect: () => {},
    padding: { left: 10, right: 10, top: 10, bottom: 10 },
    zoomLevel: 3.5,
  }

  constructor(props) {
    super(props)
    const { selection } = props
    this.state = {
      hoveredByTypes: {},
      ...this.getNextStateForProps(props),
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.loads !== nextProps.loads ||
        this.props.locations !== nextProps.locations ||
        this.props.selection !== nextProps.selection) {
      this.setState(this.getNextStateForProps(nextProps, this.state))
    }
  }

  public render() {
    const { viewport } = this.state
    const mapProps = { ...viewport, ...this.props }
    return (
      <BaseMap
        {...mapProps}
        onViewportChange={this.handleViewportChanged}
        viewport={viewport}
      >
        {this.renderLineLayer(viewport)}
        {this.renderLocationMarkerLayer(viewport)}
        {this.renderLoadCurrentLocationMarkerLayer(viewport)}
      </BaseMap>
    )
  }

  private getNextStateForProps(props, prevState?) {
    const selectedByTypes = getPresenceMapByTypes(props.selection)
    const result: any = this.getBoundsFromLocation(props)
    const viewport = _.get(prevState, 'viewport')
    const zoom = result ? result.zoom - 0.5 : props.zoomLevel
    return {
      selectedByTypes,
      viewport: {
        isDragging: _.get(viewport, 'isDragging'),
        latitude: result ? result.latitude : DefaultLocation.latitude,
        longitude: result ? result.longitude : DefaultLocation.longitude,
        startDragLngLat: _.get(viewport, 'startDragLngLat'),
        zoom,
      },
    }
  }

  private getBoundsFromLocation(props) {
    const { locations, locationGeolocationPath, width, height, padding, zoomLevel } = props
    let latitudePath
    let longitudePath
    if (_.isString(locationGeolocationPath)) {
      latitudePath = `${locationGeolocationPath}.latitude`
      longitudePath = `${locationGeolocationPath}.longitude`
    } else {
      latitudePath = locationGeolocationPath.concat(['latitude'])
      longitudePath = locationGeolocationPath.concat(['longitude'])
    }
    const latitudes = _.map(locations, (el) => _.get(el, latitudePath))
    const longitudes = _.map(locations, (el) => _.get(el, longitudePath))
    const minLat = _.min(latitudes)
    const minLng = _.min(longitudes)
    const maxLat = _.max(latitudes)
    const maxLng = _.max(longitudes)
    if (!_.isNil(minLat) && !_.isNil(minLng) && !_.isNil(maxLat) && !_.isNil(maxLng)) {
      const bounds = [[minLat, minLng], [maxLat, maxLng]]
      // if all entities are at the same point, just return lat, lng, default
      // zoom, fitBounds doesn't handle that case
      // if (_.isEqual(bounds[0], bounds[1])) {
      // }
      return {
        latitude: minLat,
        longitude: minLng,
        zoom: zoomLevel,
      }
      // return fitBounds(width, height, bounds, {
      //   paddingBottom: padding.bottom,
      //   paddingLeft: padding.left,
      //   paddingRight: padding.right,
      //   paddingTop: padding.top,
      // })
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Rendering
  /////////////////////////////////////////////////////////////////////////////

  private renderLineLayer(viewPort) {
    const { loads, shouldAlwaysShowRoute } = this.props
    const { hoveredByTypes, selectedByTypes } = this.state
    const selectedMap = selectedByTypes.Load || {}
    const hoveredMap = getHoveredLoads(hoveredByTypes)
    const associatedMap = getAssociatedLoads(selectedByTypes.Location)
    const mapProps = { ...viewPort, ...this.props }
    const trailColor = '#4a90e2'
    const lines = []
    _.forEach(loads, (entity) => {
      const associated = associatedMap[entity.uniqueId]
      const hovered = hoveredMap[entity.uniqueId]
      const selected = selectedMap[entity.uniqueId]
      // const fade20 = hasSelected && !selected
      // const fade50 = hasHovered && !hovered
      const isFocused = associated || hovered || selected
      if (!shouldAlwaysShowRoute && !isFocused) {
        return
      }
      const { routeProgress } = entity
      if (!routeProgress) {
        return
      }
      const { pointsPast, pointsProjected } = routeProgress
      if (pointsPast.length > 1) {
        lines.push({
          color: trailColor,
          geolocations: pointsPast,
        })
      }
      if (pointsProjected.length > 1) {
        lines.push({
          color: trailColor,
          geolocations: pointsProjected,
          lineDash: 1,
        })
      }
    })
    return (
      <CanvasLineLayer
        {...mapProps}
        lines={lines}
      />
    )
  }

  private renderLocationMarkerLayer(viewport) {
    const { locationEntityIdPath, locationGeolocationPath, locations, width, height } = this.props
    const { hoveredByTypes, selectedByTypes } = this.state
    const selectedMap = selectedByTypes.Location || {}
    const hoveredMap = getHoveredLocations(hoveredByTypes)
    const associatedMap = getAssociatedLocations(selectedByTypes.Load)
    const hasSelected = !_.isEmpty(selectedMap)
    const hasHovered = !_.isEmpty(hoveredMap)
    const modifiers = (entity) => {
      const origin = !_.isEmpty(entity.outboundLoads)
      const destination = !_.isEmpty(entity.inboundLoads)
      const associated = !!associatedMap[entity.uniqueId]
      const hovered = !!hoveredMap[entity.uniqueId]
      const selected = !!selectedMap[entity.uniqueId]
      const fade20 = hasSelected && !selected
      const fade50 = hasHovered && !hovered
      return { associated, destination, fade20, fade50, hovered, origin, selected }
    }
    // Note: width and height need to be kept in sync with .c-locationMarker
    return (
      <MarkerLayer
        entities={locations}
        entityIdPath={locationEntityIdPath}
        geolocationPath={locationGeolocationPath}
        MarkerComponent={LocationMarker}
        markersHeight={33}
        markersWidth={22}
        modifiers={modifiers}
        onSelect={this.handleSelectLoad}
        onMouseOver={(entities) => this.handleMouseOver('Location', entities)}
        onMouseOut={(entities) => this.handleMouseOut('Location', entities)}
        onViewportChange={this.handleViewportChanged}
        width={width}
        height={height}
        viewport={viewport}
      />
    )
  }

  private renderLoadCurrentLocationMarkerLayer(viewport) {
    const { loads, width, height } = this.props
    const loadsWtihRoutes = _.filter(loads, (load) => load.routeProgress)
    const { hoveredByTypes, selectedByTypes } = this.state
    const selectedMap = selectedByTypes.Load || {}
    const hoveredMap = getHoveredLoads(hoveredByTypes)
    const associatedMap = getAssociatedLoads(selectedByTypes.Location)
    const hasSelected = !_.isEmpty(selectedMap)
    const hasHovered = !_.isEmpty(hoveredMap)
    // console.log(isDragging)
    const modifiers = (entity) => {
      const associated = !!associatedMap[entity.uniqueId]
      const hovered = !!hoveredMap[entity.uniqueId]
      const selected = !!selectedMap[entity.uniqueId]
      const fade20 = hasSelected && !selected
      const fade50 = hasHovered && !hovered
      return { associated, fade20, fade50, hovered, selected }
    }
    // Note: width and height need to be kept in sync with .c-truckTrackingPin
    return (
      <MarkerLayer
        entities={loadsWtihRoutes}
        geolocationPath='routeProgress.lastKnownLocation'
        MarkerComponent={LoadMarker}
        markersHeight={14}
        markersWidth={14}
        modifiers={modifiers}
        onSelect={this.handleSelectLoad}
        onMouseOver={(entities) => this.handleMouseOver('Load', entities)}
        onMouseOut={(entities) => this.handleMouseOut('Load', entities)}
        onViewportChange={this.handleViewportChanged}
        width={width}
        height={height}
        viewport={viewport}
      />
    )
  }

  /////////////////////////////////////////////////////////////////////////////
  // Handlers
  /////////////////////////////////////////////////////////////////////////////

  private handleViewportChanged = (viewport) => {
    this.setState({ viewport })
  }

  // Expects an entity (load or location), or null
  private handleSelectLoad = (selected) => {
    this.props.onSelect(selected, _.last(selected))
  }

  private handleMouseOver = (type, hovered) => {
    const { hoveredByTypes } = this.state
    hoveredByTypes[type] = getPresenceMap(hovered)
    this.setState({ hoveredByTypes })
  }

  private handleMouseOut = (type, unhovered) => {
    const { hoveredByTypes } = this.state
    if (!hoveredByTypes[type]) {
      return
    }
    const hoveredMap = hoveredByTypes[type]
    _.forEach(unhovered, (entity) => delete hoveredMap[entity.uniqueId])
    this.setState({ hoveredByTypes })
  }
}

/////////////////////////////////////////////////////////////////////////////
// Static Helper Methods
/////////////////////////////////////////////////////////////////////////////

function getPresenceMap(selection) {
  const mapping = {}
  _.forEach(selection, (entity) => {
    mapping[entity.uniqueId] = entity
  })
  return mapping
}

function getPresenceMapByTypes(selection) {
  const mapping = {}
  _.forEach(selection, (entity) => {
    const type = entity.entityType
    mapping[type] = mapping[type] || {}
    mapping[type][entity.uniqueId] = entity
  })
  return mapping
}

function getAssociatedLocations(selectedLoads) {
  const results = {}
  _.forEach(selectedLoads, (load) => {
    const { stops } = load.load
    _.forEach(stops, (stop) => {
      if (stop.location) {
        results[stop.location.entityId] = true
      }
    })
  })
  return results
}

function getHoveredLocations(hoveredByTypes) {
  const hoveredLocations = hoveredByTypes.Location
  if (!_.isEmpty(hoveredLocations)) {
    return hoveredLocations
  }
  const hoveredLoads = hoveredByTypes.Load
  return getAssociatedLocations(hoveredLoads)
}

function getAssociatedLoads(selectedLocations) {
  const results = {}
  _.forEach(selectedLocations, (location) => {
    _.forEach(location.inboundLoads, (load) => { results[load.uniqueId] = load })
    _.forEach(location.outboundLoads, (load) => { results[load.uniqueId] = load })
  })
  return results
}

function getHoveredLoads(hoveredByTypes) {
  const hoveredLoads = hoveredByTypes.Load
  if (!_.isEmpty(hoveredLoads)) {
    return hoveredLoads
  }
  const hoveredLocations = hoveredByTypes.Location
  return getAssociatedLoads(hoveredLocations)
}
