import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { EntityErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/entity-error-block'
import classNames from 'classnames'

export interface IConfirmationModalProps extends IBaseProps {
  cancelButtonText: string
  confirmationText: string
  confirmationTitle: string
  onClose: () => void      // this function is usually being override by Modal wrapper, so don't use this one
  onPrimaryClicked: () => void
  onSecondaryClicked: () => void
  onCloseCallback: () => void  // called last to do any clean up
  primaryButtonText: string
  secondaryButtonText: string
  footerClassName?: string
  animateFooter?: boolean
  buttonClassName?: string
  errors?: any
}

interface IConfirmationModalState {
  errors?: any
}

export class ConfirmationModal extends React.Component<IConfirmationModalProps, IConfirmationModalState> {
  public static defaultProps: Partial<IConfirmationModalProps> = {
    cancelButtonText: 'Cancel',
    primaryButtonText: 'Confirm',
    secondaryButtonText: 'Maybe',
    animateFooter: true,
  }

  public static open(props?: any, ref?: any) {
    const modalDialogClassName = props.modalDialogClassName || 'c-modal-dialog--sm'
    return OverlayManager.openOverlayElement(
      <Modal
        modalDialogClassName={modalDialogClassName}
      >
        <ConfirmationModal {...props} ref={ref}/>
      </Modal>,
    )
  }

  public static openYesNo(props?: any) {
    return OverlayManager.openOverlayElement(
      <Modal modalDialogClassName='c-modal-dialog--sm'>
        <ConfirmationModal
          primaryButtonText='Yes'
          cancelButtonText='No'
          confirmationTitle='Review Changes'
          {...props}
        />
      </Modal>,
    )
  }

  public static promise(props?: any) {
    return new Promise((resolve, reject) => {
      this.open({
        ...props,
        onPrimaryClicked: resolve,
      })
    })
  }

  constructor(props?: any) {
    super(props)

    this.state = ({
      errors: props.errors
    })
  }

  public render() {
    return (
      <div>
        <div className='c-modalHeader'>
          <h4 className='c-modal-title u-textCenter'>
            {this.props.confirmationTitle}
          </h4>
        </div>
        <div className='c-modalBody u-textCenter'>
          {this.props.confirmationText}
        </div>
        <Footer
          isVisible={true}
          errorBlockChildren={this.renderErrorBlock()}
          primaryButtonText={this.props.primaryButtonText || null}
          cancelButtonText={this.props.cancelButtonText || null}
          onCancelButtonClick={this.props.onClose ? this.onCloseHandler : null}
          onPrimaryButtonClick={this.props.onPrimaryClicked ? this.handleOnPrimaryClicked : null}
          primaryButtonGroupElement={this.renderPrimaryButtonGroup()}
          className={this.props.footerClassName}
          buttonClassName={this.props.buttonClassName}
          shouldAnimate={this.props.animateFooter}
        />
      </div>
    )
  }

  // a hook to allow error message refresh in modal mode
  public refreshErrors(errors?: any) {
    this.setState({errors})
  }

  private onCloseHandler = () => {
    const { onClose, onCloseCallback } = this.props

    if (onClose) {
      onClose()
    }

    if (onCloseCallback) {
      onCloseCallback()
    }
  }

  private handleOnPrimaryClicked = () => {
    const { onPrimaryClicked } = this.props
    const result: any = onPrimaryClicked()
    return (result && result.then) ? result.then(this.onCloseHandler) : this.onCloseHandler()
  }

  private handleOnSecondaryClicked = () => {
    const { onSecondaryClicked } = this.props
    const result: any = onSecondaryClicked()
    return (result && result.then) ? result.then(this.onCloseHandler) : this.onCloseHandler()
  }

  private renderPrimaryButtonGroup() {
    const { onSecondaryClicked, secondaryButtonText } = this.props
    if (onSecondaryClicked) {
      return (
        <div className='c-footer-item'>
          <Button
            className='u-nowrap'
            onClick={this.handleOnSecondaryClicked}
          >
            {secondaryButtonText}
          </Button>
        </div>
      )
    }
  }
  private renderErrorBlock() {
    const { errors } = this.state
    return (
      <EntityErrorBlock
        entity={null}
        errors={errors}
      />
    )
  }
}
