import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { ImageLoader } from 'browser/components/atomic-elements/atoms/image-loader/image-loader'
import 'browser/components/atomic-elements/molecules/thumbnail/thumbnail.scss'
import { resizeImageUri } from 'shared-libs/helpers/utils'
import { Icon, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

// TODO(Louis): change to new doctype other :)
const DoctypeOtherImage = require('images/doctype-other.svg')
const ImagePreviewPdf = require('images/add-document-image-preview-pdf.png')

interface IThumbnailProps extends IBaseProps {
  imageClassName?: string
  file: any
  onClick?: (event: any) => void
  showOriginal?: boolean
  size?: string
  /** Size parameter for the image CDN. */
  fitIn?: number | number[]
  loading?: boolean
}

interface IThumbnailState {
  hasError?: boolean
}

export class Thumbnail extends React.Component<IThumbnailProps, IThumbnailState> {

  public static defaultProps: Partial<IThumbnailProps> = {
    onClick: _.noop,
    showOriginal: false,
    fitIn: 64,
  }

  constructor(props: IThumbnailProps) {
    super(props)
    this.state = {}
  }

  public render() {
    const { hasError } = this.state
    const { loading, className, file, size, imageClassName } = this.props
    const { type, name } = file
    const src = this.getImageSource(file, type)
    const sizeClass = size ? 'c-thumbnail-container--' + size : ''

    if (_.endsWith(name, '.heic') && hasError) {
      return (
        <Tooltip content="HEIC Preview Unavailable">
          <div className={classNames('c-thumbnail-container', sizeClass, className)}>
            <div
              className={classNames('c-thumbnail', {
                'c-thumbnail--generic': type === 'generic',
                'c-thumbnail--image': type === 'image',
                'c-thumbnail--pdf': type === 'pdf',
              })}
            >
              <Icon icon={IconNames.MEDIA} size={20} />
            </div>
          </div>
        </Tooltip>
      )
    }

    return (
      <div
        className={classNames('c-thumbnail-container', sizeClass, className)}
        onClick={this.handleOnClick}
      >
        <ImageLoader
          className={classNames('c-thumbnail', {
            'c-thumbnail--generic': type === 'generic',
            'c-thumbnail--image': type === 'image',
            'c-thumbnail--pdf': type === 'pdf',
          })}
          src={src}
          imageClassName={imageClassName}
          size={size}
          showLoadingSpinner={true}
          loadOverride={loading}
          onError={() => this.setState({ hasError: true })}
        />
      </div>
    )
  }

  private getImageSource(file, type) {
    const { showOriginal, fitIn } = this.props
    // Entity.json shows the different types a file can be
    if (type === 'image') {
      // source only exists when processing is done
      if (showOriginal) {
        // Refine the base uri with a fit-in size parameter, to avoid loading an
        // excessively large thumbnail.

        // TODO: Improve this in more general way, by using the underlying <img>
        // layout size to load a perfectly sized thumbnail, and get rid of all
        // the `file.source` array logic. This is mainly a stop-gap to prevent
        // the superadmin entity table from loading huge images when
        // `showOriginal` is true. The choice for image size ideally should be
        // up to the client and image layout dimensions, rather than
        // predetermined on the backend, i.e.:
        // https://github.com/LoadDOCs/vector/blob/ff5befccbc4d35a97e5ee9344390662f833404cc/apps/server/src/main/java/co/loaddocs/service/entity/type/image/ImageComponent.java#L355
        const [width, height] = _.isArray(fitIn) ? fitIn : [fitIn, fitIn]
        return resizeImageUri(file.uri, width, height)
      } else if (_.isEmpty(file.source)) {
        return file.uri
      } else {
        const thumbnail = _.last(file.source) as any
        return thumbnail.uri
      }
    } else if (type === 'pdf' || type === 'generic') {
      return ImagePreviewPdf
    }
    return DoctypeOtherImage
  }

  private handleOnClick = (event) => {
    const { file, onClick } = this.props
    let source = []
    if (file.type === 'image') {
      source = file.source
    } else if (file.type === 'pdf') {
      source = _.get(file, 'preview.0.source') as any[]
    } else {
      // hack for generic files, especially printables, which dont have a source prior to onClick
      // for printables, the source is dynamically generated by the backend, and fetched at render time
      onClick(event)
      return
    }
    if (!_.isEmpty(source)) {
      onClick(event)
    }
  }
}
