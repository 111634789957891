import _ from 'lodash'
import BPromise from 'bluebird'
import { v4 as uuidv4 } from 'uuid'
import React, { useMemo } from 'react'
import classNames from 'classnames'
import { BarcodeFormat, DecodeHintType } from '@zxing/library'
import { BrowserMultiFormatReader, IScannerControls } from '@zxing/browser'
import { IconNames } from '@blueprintjs/icons'
import { Classes, Icon } from '@blueprintjs/core'

import 'browser/mobile/styles/_control.scss'
import './_barcode-field.scss'

import LabelFieldFactory from 'browser/components/atomic-elements/higher-order-components/label-field-factory'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { getDebugId } from 'browser/app/utils/utils'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { translateString } from 'shared-libs/helpers/utils'
import { globalTranslationTable } from 'browser/mobile/util/global-translation-table'
import { MobileInputField } from '../input/mobile-input-field'
import { Barcode, IBarcodeProps } from 'browser/components/atomic-elements/atoms/barcode/barcode'
import { MobileModal } from 'browser/mobile/elements/modal/modal'
import { convertCanvasToBlob } from 'browser/app/utils/image'
import { Entity } from 'shared-libs/models/entity'
import { Image } from 'shared-libs/generated/server-types/entity/fileSet'
import { ImageLoader } from 'browser/components/atomic-elements/atoms/image-loader/image-loader'
import { ImageEditorModal } from 'browser/components/atomic-elements/organisms/image-editor-modal'
import { withContext } from 'shared-libs/components/context/with-context'
import { ComponentsContext, IComponentsContext } from 'browser/contexts/components/components-context'
import { IImageEditorCarouselProps } from 'browser/components/atomic-elements/organisms/image-editor-carousel/image-editor-carousel'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'

// NOTE: native barcode detection not widely supported yet, but something to
// keep an eye on if we want to eventually drop the zxing dependency:
// https://developer.mozilla.org/en-US/docs/Web/API/Barcode_Detection_API

/**
 * @uiComponent
 */
interface IMobileBarcodeFieldProps extends IBaseProps, IBarcodeProps {
  frames?: FramesManager
  value?: string
  entity: Entity
  emptyButtonText?: string
  helpText?: string
  carouselProps?: Partial<IImageEditorCarouselProps>
  onChange: (value: any, silentUpdate?: boolean, overridePath?: string) => void
  imagePath?: string
  /** 
   * The operation mode for the barcode component. Defaults to "capture".
   */
  mode?: 'capture' | 'display'
}

interface ContextProps {
  componentsContext?: IComponentsContext
}

@withContext(ComponentsContext, 'componentsContext')
class MobileBarcodeField extends React.Component<IMobileBarcodeFieldProps & ContextProps> {
  static defaultProps: Partial<IMobileBarcodeFieldProps> = {
    emptyButtonText: 'Press to scan a barcode',
    mode: 'capture',
    carouselProps: {},
  }

  constructor(props: IMobileBarcodeFieldProps) {
    super(props)

    this.state = {}
  }

  render() {
    const { mode } = this.props
    return mode === 'display' ? this.renderDisplay() : this.renderInput()
  }

  private renderDisplay() {
    const { frames } = this.props
    const debugId = getDebugId(frames)
    return <Barcode {...this.props} data-debug-id={debugId} />
  }

  private renderInput() {
    const path = this.normalizeImagePath()
    const image = path ? this.props.entity.get(path)?.[0] : undefined

    return (
      <BarcodeField
        {...this.props}
        onInputClick={this.handleOpenScanner}
        onThumbnailClick={this.handleOpenImagePreview}
        image={image}
      />
    )
  }

  private onCapture = (barcode: string) => {
    const { onChange } = this.props
    onChange?.(barcode)
  }

  private onCaptureImage = (barcode: string, blob: Blob, width: number, height: number) => {
    const { entity, onChange } = this.props

    const path = this.normalizeImagePath()
    if (!path) {
      return
    }

    // create File with safe filename
    const filename = _.chain(barcode)
      .deburr()
      .trim()
      .replace(/[^a-zA-Z0-9_.-]/g, '_')
      .truncate({ length: 255, omission: '' })
      .value()
    const file = new File([blob], `${filename}.png`, { type: 'image/png' })

    const uniqueId = uuidv4()
    entity.clearMultipartFiles(path)
    entity.addMultipartFiles(path, [{ file, uniqueId }])

    const uri = URL.createObjectURL(file)

    const fileJson = {
      name: file.name,
      type: 'image',
      uniqueId,
      uri,
      source: [
        {
          width: width,
          height: height,
          uri,
        },
      ],
      width: width,
      height: height,
      isEditable: false,
      transformations: [],
      transformUri: uri,
    }

    const files = [fileJson]

    onChange?.(files, false, path.join('.'))
  }

  /** normalize the images path to better handle arrays */
  private normalizeImagePath(): string[] | undefined {
    const { frames, imagePath } = this.props
    if (!imagePath) {
      return
    }

    const valuePath: string[] = frames?.getContext('valuePath')

    // need to find the parent path
    const normalizedImagePath = _.split(imagePath, '.')
    const parentIndex = _.findLastIndex(valuePath, (value) => typeof value === 'number')

    return !valuePath || parentIndex === -1
      ? normalizedImagePath
      : [..._.slice(valuePath, 0, parentIndex + 1), ...normalizedImagePath]
  }

  private handleOpenScanner = () => {
    MobileModal.open(
      <BarcodeScanner
        {...this.props}
        onCapture={this.onCapture}
        onCaptureImage={this.onCaptureImage}
      />
    )
  }

  private handleOpenImagePreview = () => {
    const { entity, imagePath, carouselProps } = this.props

    const { schema } = entity.resolveSubschemaByValuePath(imagePath)

    const componentsContext = {
      ...this.props.componentsContext,
      platform: this.props.componentsContext.platform,
    }

    ImageEditorModal.open(
      {
        ...carouselProps,
        entity,
        schema,
        imagesPath: imagePath,
        index: 0,
        isEditable: true,
        editControls: <this.renderEditControls />,
        onClose: () => this.forceUpdate(),
      },
      componentsContext
    )
  }

  private renderEditControls = ({ onClose }: { onClose?: () => void }) => {
    const handleImageDelete = () => {
      const { entity, imagePath, onChange } = this.props

      ConfirmationModal.open({
        confirmationText: 'Do you want to delete this image?',
        confirmationTitle: 'Review Changes',
        modalDialogClassName: 'c-modal-dialog--sm',
        onPrimaryClicked: () => {
          entity.clearMultipartFiles(imagePath)
          onChange?.(undefined, false, imagePath)
          onClose?.()
        },
        primaryButtonText: 'Confirm',
      })
    }

    return (
      <div className="u-flex u-bumperRight--xs">
        <div className="c-cardHeader-item c-cardHeader-item--smallMargin">
          <Button onClick={handleImageDelete}>
            <Icon icon={IconNames.TRASH} />
          </Button>
        </div>
      </div>
    )
  }
}

const LabeledMobileBarcodeField = LabelFieldFactory({ InputComponent: MobileBarcodeField })
export { LabeledMobileBarcodeField as MobileBarcodeField }

interface IBarcodeFieldProps extends IMobileBarcodeFieldProps {
  onInputClick: () => void
  onThumbnailClick: () => void
  image: Image | undefined
}

const BarcodeField: React.FC<IBarcodeFieldProps> = (props) => {
  const { frames, value, emptyButtonText, onInputClick, onThumbnailClick } = props
  const debugId = getDebugId(frames)

  const translationTable = frames?.getContext('translationTable')
  const translatedEmptyButtonText = useMemo(
    () => translateString(emptyButtonText, translationTable),
    [emptyButtonText]
  )

  const showImage = !!props.image
  const onImageClick = showImage ? onThumbnailClick : undefined

  if (_.isEmpty(value)) {
    return (
      <Button
        className="w-100 mv2 mobile-button flex flex-column"
        onClick={props.onInputClick}
        data-debug-id={debugId}
      >
        <div className="w-100 p-100 pa0 ma0 flex flex-column tc">
          <div>
            <Icon icon="camera" size={24} />
          </div>
          <div>{translatedEmptyButtonText}</div>
        </div>
      </Button>
    )
  }
  return (
    <div className="mobile-media-input-container w-100">
      <div className="mobile-media-input-field" onClick={onInputClick}>
        <MobileInputField
          inputClassName="mobile-control"
          frames={frames}
          value={props.value}
          data-debug-id={debugId}
        />
      </div>
      <span className="mobile-media-input-thumbnail" onClick={showImage ? onImageClick : onInputClick}>
        {!showImage && <Icon className="mobile-icon" color="#f89939" icon="camera" />}
        {showImage && <Thumbnail image={props.image} />}
      </span>
    </div>
  )
}

const Thumbnail = ({ image }: { image: Image }) => {
  return (
    <ImageLoader
      className="c-thumbnail c-thumbnail--image"
      imageClassName="c-image"
      style={{ maxHeight: '32pt' }}
      src={image.uri}
    />
  )
}

interface IBarcodeScannerProps extends IMobileBarcodeFieldProps {
  onClose: () => void
  onCapture: (barcode: string) => void
  onCaptureImage: (barcode: string, image: Blob, width: number, height: number) => void
}

interface IBarcodeScannerState {
  loading?: boolean
  error?: Error
}

type CapturedImageBlob = {
  blob: Blob
  width: number
  height: number
}

type DecodedResult = {
  barcode: string
  image?: CapturedImageBlob
}

class BarcodeScanner extends React.PureComponent<IBarcodeScannerProps, IBarcodeScannerState> {
  static defaultProps: Partial<IBarcodeScannerProps> = {
    helpText:
      'Point your camera at a QR code to capture it. You may need to slightly adjust the angle and distance.',
  }

  private static ALLOWED_FORMATS = [
    BarcodeFormat.AZTEC,
    BarcodeFormat.CODABAR,
    BarcodeFormat.CODE_39,
    BarcodeFormat.CODE_93,
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_8,
    BarcodeFormat.EAN_13,
    BarcodeFormat.ITF,
    BarcodeFormat.MAXICODE,
    BarcodeFormat.PDF_417,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.RSS_14,
    BarcodeFormat.RSS_EXPANDED,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
    BarcodeFormat.UPC_EAN_EXTENSION,
  ]

  private codeReader: BrowserMultiFormatReader
  private promise: BPromise<void>

  constructor(props) {
    super(props)

    const hints = new Map<DecodeHintType, any>([
      [DecodeHintType.TRY_HARDER, true],
      [DecodeHintType.POSSIBLE_FORMATS, BarcodeScanner.ALLOWED_FORMATS],
    ])
    this.codeReader = new BrowserMultiFormatReader(hints)

    this.state = {
      loading: false,
    }
  }

  componentDidMount(): void {
    this.startCamera()
  }

  componentWillUnmount(): void {
    this.promise?.cancel?.()
    this.stopDecoding()
  }

  render() {
    const { onClose } = this.props
    return (
      <div className="flex flex-column tc h-100 pa2">
        <div className="tr mb2">
          <Button onClick={onClose}>
            <Icon icon={IconNames.CROSS} />
          </Button>
        </div>
        <div className="flex-grow flex flex-column justify-center">
          {this.renderState()}
          {this.renderVideo()}
        </div>
      </div>
    )
  }

  private renderState() {
    const { error, loading } = this.state
    if (error) {
      return this.renderError(error)
    } else if (loading) {
      return this.renderLoader()
    } else {
      return null
    }
  }

  private renderVideo() {
    const { loading } = this.state
    const { helpText, frames } = this.props

    const translationTable = frames?.getContext('translationTable')
    const translatedHelpText = translateString(helpText, translationTable, {
      host: window.location.hostname
    })

    return (
      <div
        className={classNames({
          'u-hide': loading /* <video> must be in DOM tree when starting decoding */,
        })}
      >
        <div className="mb1">
          <video id="video" width="90%" className="barcode-field-video" />
        </div>
        {!_.isEmpty(translatedHelpText) && <div className="tc mh4">{translatedHelpText}</div>}
      </div>
    )
  }

  private renderLoader() {
    return <LoadingSpinner style={{ pointerEvents: 'none' }} />
  }

  private renderError(error: Error) {
    if (!error) {
      return null
    }

    const translatedErrorTitle = translateString('Error', globalTranslationTable)
    const translatedErrorRetry = translateString('Retry', globalTranslationTable)

    return (
      <div className="mh4">
        <p className="c-lead mb2">{translatedErrorTitle}</p>
        {this.renderErrorBody(error)}
        <Button
          className={classNames('mobile-button mt3 w-100', Classes.BUTTON)}
          onClick={this.startCamera}
        >
          {translatedErrorRetry}
        </Button>
      </div>
    )
  }

  private renderErrorBody(error: Error) {
    const translatedErrorBody = translateString('Barcode_Field_Error_No_Camera_Permission', globalTranslationTable)
    const translatedErrorRemedy = translateString('Barcode_Field_Error_No_Camera_Permission_Remedy', globalTranslationTable, {
      host: window.location.hostname
    })

    if (error.name === 'NotAllowedError') {
      return (
        <>
          <p>{translatedErrorBody}</p>
          <br />
          <p>{translatedErrorRemedy}</p>
        </>
      )
    } else {
      return <p>{error.message}</p>
    }
  }

  private startCamera = () => {
    const { onCapture, onCaptureImage, onClose } = this.props

    this.setState({
      error: undefined,
      loading: true,
    })

    this.promise = this.startDecoding()
      .then((result) => {
        const { barcode, image } = result
        onCapture(barcode)
        if (image) {
          onCaptureImage(barcode, image.blob, image.width, image.height)
        }
        onClose?.()
      })
      .catch((error: Error) => {
        this.setState({ error })
      })
  }

  private onResult = () => {
    if (this.state.loading) {
      this.setState({ loading: false })
    }
  }

  private startDecoding(): BPromise<DecodedResult> {
    return new BPromise((resolve, reject, onCancel) => {
      let cancelableControls: IScannerControls | undefined

      onCancel(() => {
        cancelableControls?.stop()
      })

      this.codeReader
        .decodeFromConstraints(
          { video: { facingMode: 'environment' } },
          'video',
          async (result, error, controls) => {
            cancelableControls = controls

            this.onResult()

            if (result) {
              const decodedResult = {
                barcode: result.getText(),
                image: await this.captureImage(),
              }
              resolve(decodedResult)
              controls.stop()
            }
          }
        )
        .catch((error) => {
          reject(error)
        })
    })
  }

  private async captureImage(): Promise<DecodedResult['image']> {
    const { imagePath } = this.props
    if (!imagePath) {
      // no image path, no need to capture image
      return
    }

    const videoElement = document.getElementById('video') as HTMLVideoElement
    const canvasElement = document.createElement('canvas')
    const context = canvasElement.getContext('2d')
    canvasElement.width = videoElement.videoWidth
    canvasElement.height = videoElement.videoHeight
    context.drawImage(videoElement, 0, 0, videoElement.videoWidth, videoElement.videoHeight)

    return convertCanvasToBlob(canvasElement, 'image/webp', 0.8).then((blob) => ({
      blob,
      width: videoElement.videoWidth,
      height: videoElement.videoHeight,
    }))
  }

  private stopDecoding() {
    BrowserMultiFormatReader.releaseAllStreams()
  }
}
