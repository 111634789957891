import React from 'react'

export interface IWorkflowProgressContextProps {
  /**
   * Save/upload progress, in range [0.0, 100.0]. Non-nil 100.0 indicates a save
   * may still be completing - all bytes are uploaded but still waiting for
   * server to respond, e.g. finish processing the image.
   */
  progress?: number

  /**
   * Number of completed saves.
   */
  numComplete?: number

  /**
   * Total number of saves.
   */
  numTotal?: number

  /**
   * Whether any saves are uploading attachments. (A save may send progress of
   * 100 without having any attachments).
   */
  hasAttachments?: boolean
}

export const WorkflowProgressContext = React.createContext<IWorkflowProgressContextProps>({
  progress: undefined
})
