import _ from 'lodash'

export function chunk(...params: any[]): any[] {
  return _.chunk.apply(null, params)
}

export function compact(array: any[]): any[] {
  return _.compact(array)
}

export function concat(...params: any[]): any[] {
  return _.concat.apply(null, params)
}

export function difference(array: any[], values: any[]): any[] {
  return _.difference(array, values)
}

export function drop(...params: any[]): any[] {
  return _.drop.apply(null, params)
}

export function fill(...params: any[]): any[] {
  return _.fill.apply(null, params)
}

export function flatten(array: any[]): any[] {
  return _.flatten(array)
}

export function flattenDeep(array: any[]): any[] {
  return _.flattenDeep(array)
}

export function head(array: any[]): any[] {
  return _.head(array)
}

export function initial(array: any[]): any[] {
  return _.initial(array)
}

export function intersection(...params: any[]): any[] {
  return _.intersection.apply(null, params)
}

export function join(array: any[], separator: string): string {
    return _.join(array, separator)
}

export function joinStr(...params: any[]): string {
  const array = _.initial(params)
  const separator = _.last(params)
  return array.join(separator)
}

export function last(array: any[]): any {
  return _.last(array)
}

export function nth(...params: any[]): any {
  return _.nth.apply(null, params)
}

export function reverse(array: any[]): any[] {
  return _.reverse(array)
}

export function slice(...params: any[]): any[] {
  return _.slice.apply(null, params)
}

export function tail(array: any[]): any[] {
  return _.tail(array)
}

export function take(...params: any[]): any[] {
  return _.take.apply(null, params)
}

export function takeRight(...params: any[]): any[] {
  return _.takeRight.apply(null, params)
}

export function union(...params: any[]): any[] {
  return _.union.apply(null, params)
}

export function uniq(array: any[]): any[] {
  return _.uniq(array)
}

export function unzip(array: any[]): any[] {
  return _.unzip(array)
}

export function without(...params: any[]): any[] {
  return _.without.apply(null, params)
}

export function xor(...params: any[]): any[] {
  return _.xor.apply(null, params)
}

export function zip(...params: any[]): any[] {
  return _.zip.apply(null, params)
}

