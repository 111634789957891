import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
// tslint:disable-next-line:max-line-length
import { FormGroupContentWrapper } from 'browser/components/atomic-elements/atoms/form-group-content-wrapper/form-group-content-wrapper'
import { Formatter } from 'shared-libs/helpers/formatter'

export interface IGPSInputProps extends IBaseProps {
  showGPSAsDegree: boolean
  value?: any
  size?: string
  isDisabled?: boolean
  isHorizontalLayout?: boolean
}

export class GPSInput extends React.Component<IGPSInputProps, any> {
  public static defaultProps: IGPSInputProps = {
    isDisabled: true,
    isHorizontalLayout: true,
    showGPSAsDegree: false,
  }

  public render() {
    const {
      className,
      isDisabled,
      isHorizontalLayout,
      showGPSAsDegree,
      size,
      value,
    } = this.props
    const googleMapUrl = Formatter.formatGeolocationAsGoogleMapUrl(value)
    const formattedGeolocation = Formatter.formatGeolocation(value, showGPSAsDegree)
    const staticContainerClassName = _.isEmpty(size) ? 'c-fakeInputContainer' : `c-fakeInputContainer--${size}`
    return (
      <FormGroupContentWrapper
        isHorizontalLayout={isHorizontalLayout}
        isDisabled={isDisabled}
        className={className}
      >
        <a
          className={classNames('u-displayBlock', staticContainerClassName)}
          href={googleMapUrl}
          target='_blank' rel='noopener noreferrer'
        >
          {formattedGeolocation}
        </a>
      </FormGroupContentWrapper>
    )
  }
}
