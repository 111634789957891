import { UserIds } from 'shared-libs/models/user'
import { FirmIds } from 'shared-libs/models/firm'
import _ from 'lodash'
import shortid from 'shortid'

import { AppBundleProps, FirmProps, SchemaRefProps, EdgeProps, EntitySchemaProps, EntityProps } from 'shared-libs/models/prop-constants';
import apis from 'browser/app/models/apis';
import { Entity } from 'shared-libs/models/entity';
import { SchemaIds } from 'shared-libs/models/schema';
import { createEdge } from 'shared-libs/models/utils';
import { AppBundleIds } from 'shared-libs/models/app-bundle';

const DELETE_OPERATION = 'DELETE'
const DOCUMENT_SCHEMA_URI = '/1.0/entities/metadata/document.json'

const digitToStr = {
  '0': 'zero',
  '1': 'one',
  '2': 'two',
  '3': 'three',
  '4': 'four',
  '5': 'five',
  '6': 'six',
  '7': 'seven',
  '8': 'eight',
  '9': 'nine',
}

const getDocSchemasForAppBundleByAppBundleId = (appBundleId) => {
  return apis.getStore().getOrFetchRecord(appBundleId)
    .then((appBundle) => getDocSchemasForAppBundle(appBundle))
}

const getDocSchemasForAppBundle = (appBundle) => {
  const schemaRefs = {}
  const docSchemas = []
  return loadSchemaRefs(appBundle, schemaRefs)
    .then(() => filterForDocumentSchemas(schemaRefs, docSchemas))
    .then(() => _.sortBy(docSchemas, (docSchema) => docSchema.content.title))
}

const filterForDocumentSchemas = (schemaRefs, docSchemas) => {
  const promises = []
  Object.keys(schemaRefs).forEach((schemaKey) => {
    const schemaId = schemaRefs[schemaKey][SchemaRefProps.ENTITY_ID]
    const promise = apis.getStore().getOrFetchRecord(schemaId).then((schema) => {
      if (isDocumentSchema(schema)) {
        docSchemas.push(schema)
      }
    })
    promises.push(promise)
  })
  return Promise.all(promises)
}

const loadSchemaRefs = (appBundle, schemaRefs) => {
  // NOTE:EF not handling the case where a schemaRef could be a DELETE operation (unnecessary at time of writing)
  schemaRefs = Object.assign(schemaRefs, getSchemaRefs(appBundle))

  const extensionRefs = appBundle.get(AppBundleProps.EXTEND_REFS) || []
  const extensionLoads = extensionRefs.map((extensionRef) => {
    return apis.getStore().getOrFetchRecord(extensionRef[EdgeProps.ENTITY_ID])
      .then((extensionAppBundle) => loadSchemaRefs(extensionAppBundle, schemaRefs))
  })
  return Promise.all(extensionLoads)
}

const getBaseAppBundleId = (appBundle) => {
  const extensionRefs = appBundle.get(AppBundleProps.EXTEND_REFS)
  if (extensionRefs && extensionRefs.length > 0) {
    const lastExtensionRef = extensionRefs.slice(-1)[0]
    return lastExtensionRef[EdgeProps.ENTITY_ID]
  }
  return null
}

const isDocumentSchema = (schema) => {
  const inheritedSchemas = schema.get('allOf')
  return _.some(inheritedSchemas, (is) => (is.$ref === DOCUMENT_SCHEMA_URI))
}

const getSchemaRefs = (appBundle: Entity) => {
  let schemaRefs = appBundle.get(AppBundleProps.SCHEMA_REFS)
  if (!schemaRefs) {
    schemaRefs = {}
    appBundle.set(AppBundleProps.SCHEMA_REFS, schemaRefs)
  }
  return schemaRefs
}

export const convertFirstCharIfDigit = (str: string) => {
  const firstChar = _.first(str)
  if (firstChar >= '0' && firstChar <= '9') {
    return `${digitToStr[firstChar]}${str.slice(1)}`
  }
  return str
}

const createNamespaceForName = (name) => {
  return `${convertFirstCharIfDigit(_.camelCase(name))}${shortid.generate()}`
}

const createNewDocumentType = (docTypeName) => {
  const store = apis.getStore()
  const docSchema = store.createRecord(store.getRecord(SchemaIds.ENTITY_SCHEMA))

  const namespace = _.get(docSchema, 'content.uniqueId')
  docSchema.set(EntitySchemaProps.URI, `/1.0/entities/metadata/${namespace}.json`)
  docSchema.set(EntitySchemaProps.TITLE, docTypeName)
  docSchema.set(EntitySchemaProps.DESCRIPTION, docTypeName)
  docSchema.set(EntitySchemaProps.TYPE, 'object')
  docSchema.set(EntitySchemaProps.ALL_OF, [
    { $ref: "/1.0/entities/metadata/entity.json" },
    { $ref: "/1.0/entities/metadata/document.json" },
  ])
  docSchema.set(EntitySchemaProps.METADATA, {
    hasRevisionHistory: true,
    namespace,
    isSearchable: true
  })
  docSchema.set(EntitySchemaProps.PROPERTIES, {
    [namespace]: { type: 'object', properties: {} },
    document: {
      type: 'object',
      required: [ 'attachments' ],
      properties: {
        attachments: {
          type: 'object',
          required: [ 'files' ],
          properties: {
            files: { type: 'array', minItems: 1 }
          }
        }
      }
    }
  })

  docSchema.set(EntityProps.ACTIVE_MIXINS, [
    createEdge(SchemaIds.ENTITY),
    createEdge(SchemaIds.ENTITY_SCHEMA)
  ])
  docSchema.set(EntityProps.OWNING_FIRM_ID, FirmIds.VECTOR)
  docSchema.set(EntityProps.OWNING_USER_ID, UserIds.SYSTEM)

  return docSchema
}

const setApplicationType = (appBundle, appBundleId) => {
  const appBundleIdSet = new Set()
  return addExtensionsToSet(appBundleId, appBundleIdSet).then(() => {
    appBundleIdSet.add(appBundleId) // This needs to be last since application expects it this way
    appBundle.set(AppBundleProps.EXTEND_REFS, Array.from(appBundleIdSet.values()).map((v) => createEdge(v)))
    return appBundle
  })
}

const addExtensionsToSet = (appBundleId, appBundleIdSet) => {
  return apis.getStore().getOrFetchRecord(appBundleId).then((appBundle: Entity) => {
    const extensionRefs = appBundle.get(AppBundleProps.EXTEND_REFS) || []
    const promises = []
    for (const extensionRef of extensionRefs) {
      const extensionId = extensionRef[EdgeProps.ENTITY_ID]
      promises.push(addExtensionsToSet(extensionId, appBundleIdSet).then(() => {
        if (!appBundleIdSet.has(extensionId)) {
          appBundleIdSet.add(extensionId)
        }
      }))
    }
    return Promise.all(promises)
  })
}

const createEmptyAppBundle = () => {
  const appBundleSchema = apis.getStore().getRecord(SchemaIds.APP_BUNDLE)
  const appBundle = apis.getStore().createRecord(appBundleSchema)

  appBundle.set(AppBundleProps.EXTEND_REFS, [])
  appBundle.set(AppBundleProps.SCHEMA_REFS, {})

  return appBundle
}

const createAppBundleForType = (appBundleType) => {
  const appBundleSchema = apis.getStore().getRecord(SchemaIds.APP_BUNDLE)
  const appBundle = apis.getStore().createRecord(appBundleSchema)

  appBundle.set(AppBundleProps.EXTEND_REFS, [])
  appBundle.set(AppBundleProps.SCHEMA_REFS, {})

  return setApplicationType(appBundle, appBundleType)
}

/**
 * if app bundle is one of based bundle DMS/TMS, then need
 * to create a new one so we don't override the based one.  We
 * don't want to ever to use the based one during custom doc type
 *
 * @param bundle current app bundle to check, either an entity or uniqueid string
 */

const createAppBundleIfBasedBundleForFirm = (bundle, firm) => {
  const isBundleId = typeof bundle === 'string'
  const bundleId = isBundleId ? bundle : bundle.uniqueId
  if (!isBasedBundle(bundleId) && (isBundleId || !bundle.isDeleted)) {
    return bundle
  }

  // TODO: how about TMS or TMS/DMS
  return createAppBundleForType(AppBundleIds.DMS).then((appBundle) => {
    appBundle.set(AppBundleProps.FIRM, createEdge(firm.get(EntityProps.ID)))
    appBundle.set(AppBundleProps.NAME, `${firm.get(FirmProps.LEGAL_NAME)} Bundle`)
    return appBundle
  })

}

const isBasedBundle = (bundleId) => {
  const basedBundles = [AppBundleIds.BASE, AppBundleIds.DMS, AppBundleIds.TMS]

  return _.includes(basedBundles, bundleId)
}

export {
  DELETE_OPERATION,
  createEmptyAppBundle,
  createAppBundleForType,
  createAppBundleIfBasedBundleForFirm,
  getDocSchemasForAppBundle,
  getDocSchemasForAppBundleByAppBundleId,
  getBaseAppBundleId,
  isDocumentSchema,
  getSchemaRefs,
  createNamespaceForName,
  createNewDocumentType,
  setApplicationType
}
