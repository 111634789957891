import _ from 'lodash'
import React from 'react'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'

export function WithSettingsProps(WrappedComponent, settingsMappings) {
  return class extends React.Component {
    public render() {
      return (
        <AppNavigatorContext.Consumer>
          {({settings}) => (
            <WrappedComponent
              {...this.getDefaultProperties(settings)}
              {...this.props}
            />
          )}
        </AppNavigatorContext.Consumer>
      )
    }

    private getDefaultProperties(settings) {
      const defaultProps = {}
      _.forEach(settingsMappings, (path, key) => {
        const pathArr = path.split('.')
        const namespace = pathArr[0]
        const property = pathArr.slice(1).join('.')
        defaultProps[key] = settings.getSettingsProperty(namespace, property)
      })
      return defaultProps
    }
  }
}
