import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'

export interface ItemProps extends IBaseProps {
  label: string
  isButton: boolean
  isClearable: boolean
  onClear: () => void
  showFilterValue: boolean
  onMouseEnter?: () => void
  onClick?: () => void
  onMouseLeave?: () => void
}

export class Item extends React.Component<ItemProps, any> {

  public render() {
    const { className, isButton, isClearable } = this.props
    // injected props from tether
    const injectedProps = {
      onClick: this.props.onClick,
      onMouseEnter: this.props.onMouseEnter,
      onMouseLeave: this.props.onMouseLeave,
    }
    return (
      <div
        className={classNames(className, {
          'c-filterPullButton': isClearable,
          [Classes.BUTTON]: isButton,
        })}
        {...injectedProps}
      >
        <div
          className={classNames({
            'flex items-center': isButton,
            'w-100 pre-wrap pr3': !isButton,
          })}
        >
          {this.renderLabel()}
          {this.renderFilterValue()}
          {this.renderInlineClearButton()}
        </div>
      </div>
    )
  }

  private handleClear = (event) => {
    // When filter item is a button, the clear button is inside the filter
    // button. We want to prevent the popover from opening when clicking clear
    event.stopPropagation()
    this.props.onClear()
  }

  private renderClearButton() {
    const { isClearable, onClear } = this.props
    if (!isClearable) {
      return
    }
    return (
      <Button
        className={Classes.MINIMAL}
        size='sm'
        onClick={onClear}
      >
        Clear
      </Button>
    )
  }

  private renderInlineClearButton() {
    const { isButton, isClearable } = this.props
    if (isClearable && isButton) {
      return (
        <Button
          className={classNames('c-filterPillInlineClearButton', Classes.MINIMAL)}
          size='sm'
          onClick={this.handleClear}
        >
          <Icon
            icon={IconNames.CROSS}
          />
        </Button>
      )
    }
  }

  private renderLabel() {
    const { label, isButton } = this.props
    if (isButton) {
      return (
        <span className='u-ellipsis'>
          {label}:
        </span>
      )
    }
    return (
      <div className='flex u-alignItemsCenter'>
        <div className='u-flexGrow u-ellipsis'>
          {label}
        </div>
        <div className='u-ellipsis'>
          {this.renderClearButton()}
        </div>
      </div>
    )
  }

  private renderFilterValue() {
    const { children, isButton, showFilterValue } = this.props
    if (!showFilterValue) {
      return
    }
    if (isButton) {
      return (
        <div className='c-filterPillValue u-ellipsis'>
          {children}
        </div>
      )
    }
    return (
      <HelpBlock className='collapse'>
        {children}
      </HelpBlock>
    )
  }
}
