import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { Column } from 'browser/components/atomic-elements/atoms/table'
import { EntityTable } from 'browser/components/atomic-elements/organisms/entity/entity-table'
import { EntityDataSource } from '../entity-data-source'

const FIRM_ID_PATH = 'owner.firm.entityId'
const DISPATCH_ORDER_INVOICE_PATH = 'dispatchOrder.invoice'

const RBINVOICE_TYPE = '/1.0/entities/metadata/rbInvoice.json'
const TIGERLINES_FIRM_ID = 'bf6a36f7-c417-47ea-8b2b-1697694e4bde'

interface IRbInvoiceTable  {
  entity: any
}

export class RbInvoiceTable extends React.Component<IRbInvoiceTable, any> {

  private dataSet: EntityDataSource

  constructor(props) {
    super(props)
    this.dataSet = new EntityDataSource({
      entityType: RBINVOICE_TYPE,

    }).setOnChange(() => this.forceUpdate())
  }

  public componentDidMount() {
    const { entity } = this.props
    const firmId = _.get(entity, FIRM_ID_PATH, '')
    // If it is tigerlines
    if (firmId === TIGERLINES_FIRM_ID) {
      const invoiceEdges = _.get(entity, DISPATCH_ORDER_INVOICE_PATH, [])
      const invoiceIds = _.map(invoiceEdges, (edge) => edge.entityId)
      this.dataSet.setFilters({
        path: 'uniqueId',
        type: 'match',
        values: invoiceIds,
      })
      this.dataSet.find()
      // If it is western or brady or alpine i guess
    } else {
      apis.getRelatedInvoices(entity.uniqueId).then((res) => {
        this.dataSet.setFilters({
          path: 'uniqueId',
          type: 'match',
          values: res,
        })
        this.dataSet.find()
      })
    }
  }

  public componentWillUnmount() {
    this.dataSet.dispose()
  }

  public render() {
    const entitySchema = apis.getStore().getRecord(RBINVOICE_TYPE)
    const columns: Column[] = [
      {
        cellComponent: {
          labelPath: 'rbInvoice.invoiceNumber',
          linkPath: 'uniqueId',
          path: 'entity',
          type: 'ui:table:linkCell',
        },
        flexGrow: 1,
        groupKey: 'data',
        isFixed: true,
        isSortable: true,
        label: 'Invoice Number',
        path: 'data',
        sortKey: 'rbInvoice.invoiceNumber',
        width: 80,
      },
      {
        className: 'u-tabularFigures',
        flexGrow: 1,
        format: { type: 'currency' },
        horizontalAlignment: 'right',
        isSortable: true,
        label: 'Revenue ($)',
        path: 'rbInvoice.revenue.value',
        width: 100,
      },
      {
        className: 'u-tabularFigures',
        flexGrow: 1,
        isSortable: true,
        label: 'Invoice Status',
        path: 'rbInvoice.invoiceStatus',
        width: 200,
      },
      {
        flexGrow: 1,
        isSortable: false,
        label: 'Invoice Sent',
        path: 'rbInvoice.invoiceSentDate',
        width: 200,
      },
    ]
    return (
      <Section title='Related Invoices'>
        <div className='u-positionRelative u-minHeight400'>
          <div className='grid-content vertical'>
            <AutofillBlock className='u-border'>
              <EntityTable
                columns={columns}
                entitySchema={entitySchema}
                isConfigurable={false}
                isLoading={this.dataSet.isLoading}
                onEndReached={this.dataSet.handleLoadNextPage}
                onRowClick={null}
                onRowSelect={null}
                rows={this.dataSet.content}
              />
            </AutofillBlock>
          </div>
        </div>
      </Section>
    )
  }
}
