import classNames from 'classnames'
import Color from 'color'
import _ from 'lodash'
import React, { Fragment, PureComponent } from 'react'
import styled from 'styled-components'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import apis from 'browser/app/models/apis'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Checkbox } from 'browser/components/atomic-elements/atoms/checkbox/checkbox'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { List } from 'browser/components/atomic-elements/atoms/list'
import { SortableListDragHandle } from 'browser/components/atomic-elements/atoms/list/sortable-list'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { LabelFormGroup } from 'browser/components/atomic-elements/molecules/label-form-group/label-form-group'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import { CreateDocTypeSheet } from 'browser/components/domains/firm/create-doc-type-sheet'
import {
  DELETE_OPERATION,
  getBaseAppBundleId,
  getDocSchemasForAppBundle,
  getDocSchemasForAppBundleByAppBundleId,
  getSchemaRefs,
} from 'browser/components/domains/firm/firm-utils'
import { RenameDocTypeSheet } from 'browser/components/domains/firm/rename-doc-type-sheet'
import { Entity } from 'shared-libs/models/entity'
import {
  AppBundleProps,
  EntityProps,
  EntitySchemaProps,
  MetadataProps,
  SchemaRefProps,
} from 'shared-libs/models/prop-constants'
import { SchemaIds } from 'shared-libs/models/schema'
import { Store } from 'shared-libs/models/store'
import { createEdge, createRef } from 'shared-libs/models/utils'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'

const BORDER_COLOR = '#e0e0e0'
const LINK_COLOR = '#46A9DC'

const SORTABLE = true

const DocumentListWrapper = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
`

const CheckboxWrapper = styled.div`
  height: 32px;
  padding-left: 0.75rem;
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid ${ BORDER_COLOR };
  justify-content: space-between;
`

const NoneWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  font-style: italic;
`

const HeaderButton = styled.div`
  color: ${LINK_COLOR};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${Color(LINK_COLOR).darken(0.3).string()};
  }
`

const RenameButton = styled.div`
  color: ${LINK_COLOR};
  cursor: pointer;
  transition: all 0.3s ease;
   &:hover {
    color: ${Color(LINK_COLOR).darken(0.3).string()};
  }
`

const ButtonWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

interface IDocumentTypeListProps {
  appBundle: Entity
  docSchemasToSave: {}
  onChange: (Entity, docSchemasToSave?: object, newReplaceDocSchemaMappings?: object) => void
}

interface IDocumentTypeListState {
  baseDocSchemaIds?: Set<string>
  docSchemaIds?: Set<string>
  isAddCustomDocTypeSheetOpen: boolean
  isCreateDocTypeSheetOpen: boolean
  renamingDocSchemaId?: string
  nameBeingRenamed?: string
}

export class DocumentTypeList extends PureComponent<IDocumentTypeListProps, IDocumentTypeListState> {
  private store: Store

  constructor(props) {
    super(props)

    this.store = apis.getStore()

    this.state = {
      baseDocSchemaIds: null,
      docSchemaIds: null,
      isAddCustomDocTypeSheetOpen: false,
      isCreateDocTypeSheetOpen: false,
      nameBeingRenamed: null,
      renamingDocSchemaId: null,
    }
  }

  public componentDidMount() {
    this.initialize(this.props.appBundle)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (getBaseAppBundleId(this.props.appBundle) !== getBaseAppBundleId(nextProps.appBundle)
        || !_.isEmpty(this.props.docSchemasToSave) && _.isEmpty(nextProps.docSchemasToSave)) {
      this.initialize(nextProps.appBundle)
    }
  }

  public render() {
    const { appBundle } = this.props
    const { baseDocSchemaIds } = this.state

    // If not fully loaded
    if (!baseDocSchemaIds) {
      return <div />
    }

    const docSchemasByEntityId = this.getAllDocSchemasAsMap()
    const docSchemaIds = Object.keys(docSchemasByEntityId)
    const schemaRefs = appBundle.get(AppBundleProps.SCHEMA_REFS)
    const docSchemaRefs = _.pickBy(schemaRefs, (schemaRef) => {
      return (((schemaRef.orderPriority != null || schemaRef._operation != null) &&
              _.includes(docSchemaIds, schemaRef[SchemaRefProps.ENTITY_ID])))
    })
    const docSchemaRefsForRender = Object.keys(docSchemaRefs).map((uri) => {
      const docSchemaRef = docSchemaRefs[uri]
      const docSchemaId = docSchemaRef[SchemaRefProps.ENTITY_ID]
      return { uri, label: docSchemasByEntityId[docSchemaId].content.title, ...docSchemaRef }
    })

    const deletedDocSchemaRefs = []
    const selectedDocSchemaRefs = []
    docSchemaRefsForRender.forEach((sr) => {
      if (sr[SchemaRefProps.OPERATION] === DELETE_OPERATION) {
        deletedDocSchemaRefs.push(sr)
      } else {
        selectedDocSchemaRefs.push(sr)
      }
    })
    const sortedSelectedDocSchemaRefs = _.sortBy(selectedDocSchemaRefs, SchemaRefProps.ORDER_PRIORITY)
    const sortedDeletedDocSchemaRefs = _.sortBy(deletedDocSchemaRefs, 'label')

    return (
      <DocumentListWrapper>
        <ButtonWrapper>
          <Button onClick={this.handleOpenCreateDocTypeSheet}>
              Create New Document Type
          </Button>
        </ButtonWrapper>

        <Section
          title='Selected Document Types'
          headerControls={this.renderRemoveAllButton(sortedSelectedDocSchemaRefs.length)}
        >
          {sortedSelectedDocSchemaRefs.length > 0 && (
            <List
              renderListItem={this.renderSortableListItem}
              value={sortedSelectedDocSchemaRefs}
              showRemoveButton={false}
              isSortable={true}
              onChange={this.handleSortChange}
            />
          )}
          {sortedSelectedDocSchemaRefs.length <= 0 && <NoneWrapper> Select From Below </NoneWrapper>}
        </Section>

        <Section
          title='Unselected Document Types'
          headerControls={this.renderSelectAllButton(sortedDeletedDocSchemaRefs.length)}
        >
          {sortedDeletedDocSchemaRefs.length > 0 && (
            <FormTable>
              {sortedDeletedDocSchemaRefs.map((docSchemaRef) => {
                return this.renderDocSchemaCheckboxListItem(docSchemaRef)
              })}
            </FormTable>
          )}
          {sortedDeletedDocSchemaRefs.length <= 0 && <NoneWrapper> All Selected Above </NoneWrapper>}
        </Section>

        <CreateDocTypeSheet isOpen={this.state.isCreateDocTypeSheetOpen} onClose={this.handleCloseCreateDocTypeSheet} />
        <RenameDocTypeSheet
          isOpen={!!this.state.renamingDocSchemaId}
          nameBeingRenamed={this.state.nameBeingRenamed}
          onClose={this.handleRenameConfirmation}
        />
      </DocumentListWrapper>
    )
  }

  private initialize(appBundle: Entity) {
    if (appBundle) {
      getDocSchemasForAppBundle(appBundle).then((docSchemas) => {
        appBundle = appBundle.cloneForReactState()
        const schemaRefs = getSchemaRefs(appBundle)
        let highestPriority = this.getHighestPriority(schemaRefs)

        // Ensure that all doc schema refs have a priority for this app bundle
        docSchemas.forEach((docSchema) => {
          // create or update schemaRef for appBundle if necessary
          const schemaRef = schemaRefs[docSchema[EntitySchemaProps.URI]]
          if (!schemaRef) {
            highestPriority += 1
            this.addDocSchemaRef(schemaRefs, docSchema, highestPriority)
          } else if (!schemaRef[SchemaRefProps.ORDER_PRIORITY] && !schemaRef[SchemaRefProps.OPERATION]) {
            highestPriority += 1
            schemaRef[SchemaRefProps.ORDER_PRIORITY] = highestPriority
          }
        })

        if (appBundle.isDirty) {
          appBundle.clearDirt() // We don't want to allow saving until the user makes a change
          this.props.onChange(appBundle)
        }

        // save the firm specific doc schema ids
        this.setState({ docSchemaIds: new Set(docSchemas.map((ds) => ds[EntityProps.ID])) })

        // save the based doc schema ids
        const baseAppBundleId = getBaseAppBundleId(appBundle)
        if (baseAppBundleId) {
          getDocSchemasForAppBundleByAppBundleId(baseAppBundleId).then((basedDocSchemas) => {
            this.setState({ baseDocSchemaIds: new Set(basedDocSchemas.map((ds) => ds[EntityProps.ID])) })
          })
        }
      })
    }
  }

  private renderRemoveAllButton(numberOfOptions) {
    return numberOfOptions > 0 ? <HeaderButton onClick={this.handleRemoveAll}>Remove All</HeaderButton> : null
  }

  private renderSelectAllButton(numberOfOptions) {
    return numberOfOptions > 0 ? <HeaderButton onClick={this.handleSelectAll}>Select All</HeaderButton> : null
  }

  private renderSortableListItem = (listItemProps) => {
    return this.renderDocSchemaCheckboxListItem(listItemProps.item, SORTABLE)
  }

  private renderDocSchemaCheckboxListItem(docSchemaRef, isSortable = false) {
    const checkboxValue = docSchemaRef[SchemaRefProps.OPERATION] !== DELETE_OPERATION
    const docSchemaId = docSchemaRef[SchemaRefProps.ENTITY_ID]
    const docSchema = this.getDocSchema(docSchemaId)
    const onCheckHandler = (checked) => this.handleDocumentChecked(docSchema, checked)
    const label = docSchema.content.title

    const onRenameButtonClick = () => {
      this.handleRenameButtonClick(docSchemaId, label)
    }

    const labelWithId = <>
      {docSchema.content.title}
      <HelpBlock
        className='u-bumperBottom--xs'
        text={docSchema.uniqueId}
      />
    </>

    return (
      <LabelFormGroup
        key={docSchemaId}
        label={labelWithId}
        labelProps={{ className: classNames('c-label--isHorizontalVeryWide', 'c-label--ellipsis') }}
      >
        <CheckboxWrapper>
          <Checkbox label='' value={checkboxValue} style={{ width: '0' }} onChange={onCheckHandler} />
          {isSortable && (
            <Fragment>
              <RenameButton onClick={onRenameButtonClick}>
                Rename
              </RenameButton>
              <SortableListDragHandle>
                <Icon icon={IconNames.DRAG_HANDLE_VERTICAL} tabIndex={-1} />
              </SortableListDragHandle>
            </Fragment>
          )}
        </CheckboxWrapper>
      </LabelFormGroup>
    )
  }

  // Handlers
  // --------

  private handleDocumentChecked = (docSchema: Entity, checked) => {
    const appBundle = this.props.appBundle.cloneForReactState()
    const docSchemasToSave = { ...this.props.docSchemasToSave }
    const schemaRefs = getSchemaRefs(appBundle)
    const schemaUri = docSchema[EntitySchemaProps.URI]

    if (checked) {
      const schemaRef = schemaRefs[schemaUri]
      schemaRef[SchemaRefProps.ORDER_PRIORITY] = this.getHighestPriority(schemaRefs) + 1
      delete schemaRef[SchemaRefProps.OPERATION]
      this.props.onChange(appBundle, docSchemasToSave)
    } else {
      const docName = docSchema.get(EntitySchemaProps.TITLE)
      ConfirmationModal.openYesNo({
        confirmationText: `This will remove the document type "${docName}"
        from selection for current uploads, however it will still be available
        for historical uploads. Do you wish to proceed?`,
        onPrimaryClicked: () => {
          this.removeDocSchema(docSchema, schemaRefs, docSchemasToSave)
          this.props.onChange(appBundle, docSchemasToSave)
        },
      })
    }
  }

  private handleSortChange = (newSortedSchemaRefsForRender) => {
    const appBundle = this.props.appBundle.cloneForReactState()
    const schemaRefs = appBundle.get(AppBundleProps.SCHEMA_REFS)
    newSortedSchemaRefsForRender.forEach((sr, index) => {
      schemaRefs[sr.uri][SchemaRefProps.ORDER_PRIORITY] = index + 1
    })

    this.props.onChange(appBundle)
  }

  private handleRemoveAll = () => {
    const appBundle = this.props.appBundle.cloneForReactState()
    const schemaRefs = appBundle.get(AppBundleProps.SCHEMA_REFS)
    const docSchemasToSave = { ...this.props.docSchemasToSave }

    const docSchemas: Entity[] = this.getSelectedDocSchemas()
    docSchemas.forEach((docSchema) => {
      this.removeDocSchema(docSchema, schemaRefs, docSchemasToSave)
    })

    this.props.onChange(appBundle, docSchemasToSave)
  }

  private handleSelectAll = () => {
    const appBundle = this.props.appBundle.cloneForReactState()
    const schemaRefs = appBundle.get(AppBundleProps.SCHEMA_REFS)
    let highestPriority = this.getHighestPriority(schemaRefs)

    const docSchemas: Entity[] = this.getDocSchemas()
    docSchemas.forEach((docSchema) => {
      const schemaUri = docSchema.get(EntitySchemaProps.URI)
      const schemaRef = schemaRefs[schemaUri]
      delete schemaRef[SchemaRefProps.OPERATION]
      if (!schemaRef[SchemaRefProps.ORDER_PRIORITY]) {
        highestPriority += 1
        schemaRef[SchemaRefProps.ORDER_PRIORITY] = highestPriority
      }
    })

    this.props.onChange(appBundle)
  }

  private handleOpenCreateDocTypeSheet = () => {
    this.setState({ isCreateDocTypeSheetOpen: true })
  }

  private handleCloseCreateDocTypeSheet = (newDocSchema: Entity) => {
    if (newDocSchema) {
      const appBundle = this.props.appBundle.cloneForReactState()
      const schemaRefs = getSchemaRefs(appBundle)
      this.addDocSchemaRef(schemaRefs, newDocSchema)
      const docSchemasToSave = { ...this.props.docSchemasToSave, [newDocSchema.get(EntityProps.ID)]: newDocSchema }
      this.props.onChange(appBundle, docSchemasToSave)
    }
    this.setState({ isCreateDocTypeSheetOpen: false })
  }

  private handleRenameButtonClick = (renamingDocSchemaId, nameBeingRenamed) => {
    this.setState({ renamingDocSchemaId, nameBeingRenamed })

  }

  private handleRenameConfirmation = (newName) => {
    let displayConfirmationPopup = false
    if (newName) {
      const { renamingDocSchemaId, baseDocSchemaIds } = this.state
      const renamingDocSchema: Entity = this.getDocSchema(renamingDocSchemaId)
      const oldName = renamingDocSchema.get(EntitySchemaProps.TITLE)

      const confirmationMsg = `This will rename all existing document types
      from "${oldName}" to "${newName}" in all application areas. Do you wish to proceed?`

      if (oldName !== newName) {
        displayConfirmationPopup = true
        ConfirmationModal.openYesNo({
          confirmationText: confirmationMsg,
          onCloseCallback: () => {
            this.setState({ renamingDocSchemaId: null, nameBeingRenamed: null })
          },
          onPrimaryClicked: () => {
            this.handleCloseRenameDocTypeSheet(newName)
          },
        })
      }
    }

    // if popup does not display, then clear rename style sheet.
    // If it does appears, then the handleCloseRenameDocTypeSheet will clear it
    if (!displayConfirmationPopup) {
      this.setState({ renamingDocSchemaId: null, nameBeingRenamed: null })
    }
  }

  private handleCloseRenameDocTypeSheet = (newName) => {
    if (newName) {
      const { renamingDocSchemaId, baseDocSchemaIds } = this.state
      const renamingDocSchema: Entity = this.getDocSchema(renamingDocSchemaId)
      const appBundle = this.props.appBundle.cloneForReactState()
      let docSchema
      const newReplaceDocSchemaMappings = {}
      if (baseDocSchemaIds.has(renamingDocSchemaId)) {
        // In this case, we extend the renamingDocSchema since we don't want to overwrite a system doc schema
        const schemaRefs = getSchemaRefs(appBundle)

        docSchema = this.store.createRecord(this.store.getRecord(SchemaIds.ENTITY_SCHEMA))

        // using unique id as namespace.  It also serves as a signature for all doc types
        // that are created by the script
        const namespace = _.get(docSchema, 'content.uniqueId')

        const allOf = _.cloneDeep(renamingDocSchema.get(EntitySchemaProps.ALL_OF))
        allOf.push(createRef(renamingDocSchema))
        docSchema.set(EntitySchemaProps.ALL_OF, allOf)

        const metadata = _.cloneDeep(renamingDocSchema.get(EntitySchemaProps.METADATA))
        metadata[MetadataProps.NAMESPACE] = namespace
        docSchema.set(EntitySchemaProps.METADATA, metadata)

        docSchema.set(EntityProps.ACTIVE_MIXINS, [ createEdge(SchemaIds.ENTITY), createEdge(SchemaIds.ENTITY_SCHEMA) ])
        docSchema.set(EntitySchemaProps.TYPE, 'object')
        docSchema.set(EntitySchemaProps.URI, `/1.0/entities/metadata/${namespace}.json`)

        docSchema.set(EntitySchemaProps.TITLE, newName)
        docSchema.set(EntitySchemaProps.DESCRIPTION, newName)

        // replace the renaming doc schema with new schema
        const schemaRefsForBase = schemaRefs[renamingDocSchema.get(EntitySchemaProps.URI)]
        this.addDocSchemaRef(schemaRefs, docSchema, schemaRefsForBase[SchemaRefProps.ORDER_PRIORITY])
        this.removeDocSchemaRef(schemaRefs, renamingDocSchema)

        // We want to notify our parent (controller) that documents may
        // need to be migrated from one doc schema to another
        newReplaceDocSchemaMappings[renamingDocSchemaId] = docSchema.get(EntityProps.ID)
      } else {
        docSchema = renamingDocSchema.cloneForReactState()
        docSchema.set(EntitySchemaProps.TITLE, newName)
        docSchema.set(EntitySchemaProps.DESCRIPTION, newName)
      }
      const docSchemasToSave = { ...this.props.docSchemasToSave, [docSchema.get(EntityProps.ID)]: docSchema }
      this.props.onChange(appBundle, docSchemasToSave, newReplaceDocSchemaMappings)
    }

    // this.setState({ renamingDocSchemaId: null, nameBeingRenamed: null })
  }

  // Helpers
  // -------

  private getHighestPriority(schemaRefs) {
    return _.max(_.map(_.values(schemaRefs), SchemaRefProps.ORDER_PRIORITY)) || 0
  }

  private addDocSchemaRef(schemaRefs, docSchema, priority = (this.getHighestPriority(schemaRefs) + 1)) {
    schemaRefs[docSchema.get(EntitySchemaProps.URI)] = {
      [SchemaRefProps.ENTITY_ID]: docSchema.get(EntityProps.ID),
      [SchemaRefProps.ORDER_PRIORITY]: priority,
    }
  }

  private removeDocSchema = (docSchema, schemaRefs, docSchemasToSave) => {
    const docSchemaId = docSchema.get(EntityProps.ID)
    const schemaUri = docSchema.get(EntitySchemaProps.URI)
    if (this.state.docSchemaIds.has(docSchemaId) ||
        this.state.baseDocSchemaIds.has(docSchema)) {
      schemaRefs[schemaUri] = this.createDeleteRef(docSchemaId)
    } else {
      delete schemaRefs[schemaUri]
      delete docSchemasToSave[docSchemaId]
    }
  }

  private createDeleteRef = (docSchemaId) => ({
    [SchemaRefProps.OPERATION]: DELETE_OPERATION,
    [SchemaRefProps.ENTITY_ID]: docSchemaId,
  })

  private removeDocSchemaRef = (schemaRefs, schemaToDelete) => {
    schemaRefs[schemaToDelete.get(EntitySchemaProps.URI)] = this.createDeleteRef(schemaToDelete.get(EntityProps.ID))
  }

  private getDocSchema = (docSchemaId) => {
    return this.props.docSchemasToSave[docSchemaId] || this.store.getRecord(docSchemaId)
  }

  private getSelectedDocSchemas = () => {
    const selectedDocSchemaIds = new Set(this.state.docSchemaIds)
    const schemaRefs = getSchemaRefs(this.props.appBundle)
    _.values(schemaRefs).forEach((sr) => {
      if (sr[SchemaRefProps.OPERATION] === DELETE_OPERATION) {
        selectedDocSchemaIds.delete(sr[SchemaRefProps.ENTITY_ID])
      } else {
        selectedDocSchemaIds.add(sr[SchemaRefProps.ENTITY_ID])
      }
    })

    return Array.from(selectedDocSchemaIds).map((id) => (this.props.docSchemasToSave[id] || this.store.getRecord(id)))
  }

  private getBaseDocSchemas = () => {
    return Array.from(this.state.baseDocSchemaIds).map((id) => this.store.getRecord(id))
  }
  private getDocSchemas = () => {
    return Array.from(this.state.docSchemaIds).map((id) => this.store.getRecord(id))
  }

  private getAllDocSchemasAsMap = () => {
    const result = {}
    this.getBaseDocSchemas().forEach((docSchema) => { result[docSchema.get(EntityProps.ID)] = docSchema })
    this.getDocSchemas().forEach((docSchema) => { result[docSchema.get(EntityProps.ID)] = docSchema })
    this.getSelectedDocSchemas().forEach((docSchema) => { result[docSchema.get(EntityProps.ID)] = docSchema })
    return result
  }
}
