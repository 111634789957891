/**
 * Data blob that store the current state of a Storyboard execution.  It takes
 * in event logs and flatten them into a shared context structure
 */
import _ from 'lodash'

import { Mapping, Mappings } from './storyboard-plan-model'

import { EsprimaParser, ExpressionEvaluator } from '../../../helpers/evaluation'
import { CustomFormulas } from '../../../helpers/formulas'
import { Entity } from '../../entity'
import { EventEmitter } from '../../event-emitter'
import { Event } from './storyboard-execution-model'
import * as log from '../../../helpers/logger'
import { materializeProps } from './storyboard-utils'
import { mergeUndefined } from '../../utils'

export type ObserverCallback = (value: any) => void
export enum EventType {
  New,
  Update,
}
export interface ISharedContextEvent {
  path: string
  prevValue: any
  value: any
}

type SharedContextData = object

// Any path starting with '_transient' will not be saved to the event log;
// it is used to store transient values in the shared context and will not be synchronized with the server.
const TRANSIENT_PREFIX = '_transient'
export class SharedContext extends EventEmitter {
  public sharedContext: SharedContextData // a generic blob of data.  Should this blob of data be persistent
  private entity: Entity

  constructor(entity: Entity, events: Event[] = []) {
    super()
    this.sharedContext = {}
    this.entity = entity

    if (_.isEmpty(events)) {
      // construct shared context from events
      this.addEvents(events)
    }
  }

  /**
   * Set the initial value for the shared context
   */
  public setDefault(blob: any): void {
    const clonedBlob = _.cloneDeep(blob)
    this.sharedContext = { ...clonedBlob }
  }

  public clear(): void {
    this.sharedContext = {}
  }

  /**
   * Add events into shared context
   */
  public addEvents(events: Event[]): void {
    // process event one by one
    _.forEach(events, (event) => {
      this.addEvent(event)
    })
  }

  public addEvent(event: Event): void {
    this.setValueWithMappings(event.outputMappings, {})
  }

  /**
   * Map the input data into the shared context
   *
   * @return an array of destination/value that set to the shared context to construct the event
   * to reconstruct the shared context
   */
  public setValueWithMappings(mappings: any[], data: any): Mappings {
    const evaluator = this.evaluator({ ...data, ...data?.content, data })

    // transient data will not be saved pernamently to shared context,  it is mostly use
    // for navigation logic
    return mappings
      ?.map((mapping) => {
        const normalizedMapping = materializeProps(mapping, evaluator)
        return this.setValueWithMapping(normalizedMapping, data)
      })
      .filter((mapping) => !_.startsWith(mapping?.destination, TRANSIENT_PREFIX))
  }

  /**
   * Extract the value from sharedContext using the given mapping
   */
  public getValueWithMappings(mappings: any, entity?: Entity) {
    const data = {}

    if (_.isEmpty(mappings)) {
      return data
    }

    const evaluator = this.evaluator()
    _.forEach(mappings, (mapping) => {
      const normalizedMapping = materializeProps(mapping, evaluator)
      const mappingValue = this.getValueWithMapping(normalizedMapping)
      const destPath = mapping.destination ? mapping.destination : mapping.source

      _.isEmpty(destPath) ? _.merge(data, mappingValue) : _.setWith(data, destPath, mappingValue)
    })

    if (entity) {
      _.assign(entity.content, data)
    }
    return data
  }

  public getValueWithPaths(paths: string[]) {
    const data = {}
    _.forEach(paths, (path) => {
      const value = this.getValueWithPath(path)
      _.setWith(data, path, value)
    })
    return data
  }

  public getValueWithPath(path: string) {
    const value = _.get(this.sharedContext, path)
    // fallthrough if the value is not there
    return !_.isNil(value) ? value : this.entity.get(path)
  }

  /**
   * Do a deep path set into the shared context data blob
   */
  public setValueWithPath(path: string, value: any) {
    const isNew = _.get(this.sharedContext, path) == null
    const prevValue = _.get(this.sharedContext, path)
    const isUpdate = !_.isEqual(value, prevValue)
    let newValue

    if (path) {
      newValue = _.set({}, path, value)
    } else {
      newValue = value
    }
    mergeUndefined(this.sharedContext, newValue, { mergeArray: !_.isArray(value) })

    const event: ISharedContextEvent = {
      path,
      prevValue,
      value,
    }
    isNew && this.emit(EventType.New, event)
    isUpdate && this.emit(EventType.Update, event)
  }

  public dumpSharedContext() {
    log.info('SharedContext:', JSON.stringify(this.sharedContext, null, '\t'))
  }

  public get sharedContextBlob(): SharedContextData {
    return this.sharedContext
  }

  public evaluate(expression: any, ctx = {}) {
    return this.evaluator(ctx).evaluate(expression)
  }

  /**
   * A helper function to expand the output event mapping into a structure
   */
  public normalizeEventMappings(mappings: Mappings): object {
    return _.reduce(
      mappings,
      (result, value) => {
        const newValue = {}
        _.set(newValue, value.destination, value.value)
        _.merge(result, newValue)
        return result
      },
      {}
    )
  }

  // --------------------------------------------------------------------------------
  /**
   * Use the provided mapping to transform a blob of data into shared context
   *
   * @return the destination, value pair that is set to shared context.  This is used
   * to recconstruct the shared context from the events
   */
  public setValueWithMapping(mapping: Mapping, data: any = {}): Mapping {
    const srcPath = mapping.source
    const dstPath = mapping.destination ? mapping.destination : mapping.source
    const blobValue = mapping.value

    // if dstPath start with '_', it should be excluded from the shared context.
    // it is used to pass props into the target component like task and scene renderer
    if (dstPath?.charAt(0) === '_' && !dstPath.startsWith(TRANSIENT_PREFIX)) {
      return {}
    }

    const sourceValue = _.get(data, srcPath, blobValue)
    // flatten out the "data" for easier acces, but still maintain "data" for legacy
    const value = this.evaluateMappingWithContext(
      { ...data, data, _srcValue: sourceValue },
      mapping,
      sourceValue
    )

    this.setValueWithPath(dstPath, value)

    // check if value has any reference to the local file and update the
    // multipartFiles to match it
    this.entity?.addMultipartFilesFromBlob(value, dstPath)

    return { destination: dstPath, value }
  }

  /**
   * Use the provided mapping to extract/transform the values from shared context
   */
  public getValueWithMapping(mapping: Mapping) {
    const srcPath = mapping.source
    const defaultValue = mapping.value

    const sourceValue = this.getValueWithPath(srcPath)
    const value = this.evaluateMappingWithContext({ _srcValue: sourceValue }, mapping, sourceValue)

    return value !== undefined ? value : defaultValue
  }

  /*
   * Check if the output from this mapping already in shared context
   * @param mappings
   */
  public hasValue(mappings: Mappings) {
    const outputPaths =
      _.map(mappings, (mapping) =>
        _.isEmpty(mapping.destination) ? mapping.source : mapping.destination
      ) || []

    const values = this.getValueWithPaths(outputPaths as string[])

    const emptyValues = _.find(values, (value) => _.isEmpty(value))

    return !_.isEmpty(emptyValues)
  }

  private evaluateMappingWithContext(ctx: any, mapping: Mapping, sourceValue: any) {
    return !_.isEmpty(mapping.formula)
      ? this.evaluator({ ...ctx, sourceValue }).evaluate(mapping.formula)
      : sourceValue
  }

  private evaluator = (localContext = {}): ExpressionEvaluator => {
    const settings = this.entity?.getSettings()
    const normalizedCtx = {
      _,
      // a back door to access sharedContext and pass-in context in case of namespace collision
      _sharedContext: this.sharedContext,
      _localContext: localContext,

      ...this.sharedContext,
      ...localContext,
      self: this.entity,
      settings,  // allow input/output mappings to access to settings
    }
    // fallback on execution state if not found in shared context
    const valueGetter = (key) => _.get(normalizedCtx, key, this.entity?.get(key, CustomFormulas[key]))
    return ExpressionEvaluator.create().setASTParser(EsprimaParser).setValueGetter(valueGetter)
  }
}
