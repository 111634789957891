import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'

interface IStatusSelectProps extends IBaseProps {
  onChange: (value, option) => void
  options: any[]
  optionGroupPath?: string
  optionLabelPath: string
  optionValuePath: string
  value: string
}

export class StatusSelect extends React.Component<IStatusSelectProps, any> {

  public static defaultProps: Partial<IStatusSelectProps> = {
    optionLabelPath: 'label',
    optionValuePath: 'value',
  }

  public render() {
    const { options, optionGroupPath } = this.props
    const content = optionGroupPath ?
      this.renderGroupOptions(options) : this.renderOptions(options)
    return (
      <ul className='c-dropdownList'>
        {content}
      </ul>
    )
  }

  private renderGroupOptions(options) {
    const { optionGroupPath, optionLabelPath, optionValuePath } = this.props
    const groupings = _.groupBy(options, optionGroupPath)
    const results = []
    _.forEach(groupings, (group, groupName) => {
      results.push(
        <HelpBlock
          className='u-bumperLeft'
          key={groupName}
        >
          {groupName}
        </HelpBlock>,
      )
      results.push.apply(results, this.renderOptions(group))
    })
    return results
  }

  private renderOptions(options) {
    const { onChange, optionLabelPath, optionValuePath, value } = this.props
    return _.map(options, (option) => {
      const optionValue = option[optionValuePath]
      return (
        <li
          className={classNames('c-dropdownList-item', {
            'is-active': optionValue === value,
          })}
          key={optionValue}
          onClick={() => onChange(optionValue, option)}
        >
          {option[optionLabelPath]}
        </li>
      )
    })
  }
}

export function getDispatchStatus(entity) {
  const { stops } = entity.dispatchOrder
  const planningStatus = [
    { primary: 'Planning', secondary: 'Canceled' },
    { primary: 'Planning', secondary: 'Draft' },
    { primary: 'Planning', secondary: 'Available' },
  ]
  const trackingStatus = []
  trackingStatus.push(
    // Load is booked
    { primary: 'Tracking', secondary: 'Booked' },
    // In Transit to Stop 1
    { primary: 'Tracking', secondary: `Dispatched` },
    // Arrived at Stop 1
    { primary: 'Tracking', secondary: `Loading` },
    // In Transit to Stop {index}
    { primary: 'Tracking', secondary: `Loaded` },
    // Arrived at Stop {index}
    { primary: 'Tracking', secondary: `Unloading` },
  )

  // TODO(louis): Figure out how we'll ever use the more logical approach
  // _.forEach(stops, (stop, index) => {
  //   trackingStatus.push(
  //     // In Transit to Stop 1
  //     { primary: 'Tracking', secondary: `Dispatched` },
  //     // Arrived at Stop 1
  //     { primary: 'Tracking', secondary: `Loading` },
  //     // In Transit to Stop {index}
  //     { primary: 'Tracking', secondary: `Loaded` },
  //     // Arrived at Stop {index}
  //     { primary: 'Tracking', secondary: `Unloading` },
  //   )
  // })
  const settlingStatus = [
    { primary: 'Settling', secondary: 'Delivered' },
    { primary: 'Settling', secondary: 'Claim' },
  ]
  return planningStatus.concat(trackingStatus).concat(settlingStatus)
}
