import { Colors, Icon, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React, { Fragment } from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface ITableCarrierNameCellProps extends IBaseProps {
  value: any
}

export const TableCarrierNameCell: React.FC<ITableCarrierNameCellProps> = (props) => {
  const { value } = props
  const carrierName = _.get(value, 'business.legalName', '')
  const portal = _.get(value, 'carrier.portal', false)
  return (
    <Fragment>
      {carrierName}
      <Tooltip
        content={<span> Has Portal Access </span>}
        className={classNames('ml2', {
          'u-hide': !portal,
        })}
      >
        <Icon icon={IconNames.ENDORSED} color={Colors.GREEN3} />
      </Tooltip>
    </Fragment>
  )
}
