import _ from 'lodash'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { MobileHeaderContext } from '../contexts/mobile-header-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

export const MobileTitle = ({ children }: IBaseProps) => {
  const { title } = useContext(MobileHeaderContext)
  return (
    <>
      <Helmet title={title} />
      {children}
    </>
  )
}
