
import _ from 'lodash'
import React from 'react'
import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { ITableRequirementStatusCellProps, TableRequirementStatusCell } from './table-requirements-cell'

/**
 * @props ITableRequirementStatusCellProps
 * @prop settings - application settings
 */
interface ITableRbPreferenceRequirementStatusCellProps extends ITableRequirementStatusCellProps {
  settings: Settings
}

const TableRbPreferenceRequirementStatusCellComponent: React.FC<ITableRbPreferenceRequirementStatusCellProps> = props => {
  const { value } = props
  // TODO: refactor
  const customerId = _.get(value, 'billTo.entityId') || _.get(value, 'billTo.company.entityId')
  const SetPreferencesTooltipItem = () => {
    if (!customerId) {
      return null
    }
    return (
      <div className='requirement-checklist-item'>
        <div>
          <a
            href={`/entity/${customerId}`}
            onClick={e => e.stopPropagation()}
            target={'_blank'}
            rel="noreferrer"
          >
            Click to set customer preferences
          </a>
        </div>
      </div>
    )
  }

  return (
    <TableRequirementStatusCell
      {...props}
      AdditionalToolTipContent={SetPreferencesTooltipItem}
    />
  )
}

/**
 * @component TableRbPreferenceRequirementStatusCell - A table cell that takes a list of
 * invoice preferences requirements and renders a checkmark if they are satisfied,
 * and an x if they are not.
 * On hover it displays the details, as well as a link to set a firm's preferences
 *
 * @props ITableRbPreferenceRequirementStatusCellProps
 *
 * n.b. Don't forward a ref, because it's a functional component which isn't compatible w/ the refs concept in React (requires instances of classes)
 */
export const TableRbPreferenceRequirementStatusCell = React.forwardRef((props: ITableRbPreferenceRequirementStatusCellProps, ref) => (
  <AppNavigatorContext.Consumer>
    {({ settings }) => <TableRbPreferenceRequirementStatusCellComponent {...props} settings={settings}/>}
  </AppNavigatorContext.Consumer>
))
