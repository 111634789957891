import _ from 'lodash'
import React from 'react'

import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { EntityTable, IEntityTableProps } from 'browser/components/atomic-elements/organisms/entity/entity-table'

/**
 * Entity table to render rows entities, with group simplification enabled
 *
 * @props IEntityTableProps
 * @prop children - components to be rendered as a secondary table action bar, above the entity table
 */
export class EntityTableWithGroupSimplification extends React.PureComponent<IEntityTableProps, any> {
  public static defaultProps: Partial<IEntityTableProps> = {
    addAutofillContainer: true,
  }

  public render() {
    const { children, groups, addAutofillContainer } = this.props

    // ldv - we cannot enable group simplifcation if we have more than 1 level of grouping
    const enableGroupSimplification = groups && groups.length === 1

    if (!addAutofillContainer) {
      return (
        <>
          {children}
          <EntityTable
            {...this.props}
            enableGroupSimplification={enableGroupSimplification}
          />
        </>
      )
    }

    return (
      <div className='grid-block vertical'>
        {children}
        <div className='grid-block'>
          <AutofillBlock>
            <EntityTable
              {...this.props}
              enableGroupSimplification={enableGroupSimplification}
            />
          </AutofillBlock>
        </div>
      </div>
    )
  }
}
