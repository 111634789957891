import React from 'react'
import classNames from 'classnames'

import { IconName, IconNames } from './iconNames'
import './_custom-icon.scss'

/**
 * @prop icon - a developer-defined icon name
 */
interface IIconProps {
  icon: IconName
}

/**
 * A custom Icon class for when we want to use icons unavailable through blueprintjs
 *
 * @props IIconProps
 */
export const Icon: React.FC<IIconProps> = ({ icon }) => {
  return (
    <div className={classNames('vector-custom-icon', `${icon}-icon`)} />
  )
}

export { IconNames }
