import { Intent, Popover } from '@blueprintjs/core'
import _ from 'lodash'
import React from 'react'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { IEntityPageContainerProps } from 'browser/components/atomic-elements/organisms/entity/entity-page-container'
import { SalesOrdersViewHeaderPrintSelected } from './sales-orders-view-header-print-selected'
import { ISalesOrdersViewHeaderOptions } from './'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

/**
 * @prop dataSet - the entire EntityDataSource of the displayed invoice entities
 * @prop handleExport - handler to export the table of invoice entities as a csv file
 * @prop handleShowEntityFormPanel - handler to open the sales invoice creation panel
 * @prop onViewModeChange - handler to toggle the view state between full and detail entity views
 * @prop selection - array of invoice entities selected by the user
 */
export interface ISalesOrdersViewHeaderButtonProps extends ISalesOrdersViewHeaderOptions {
  dataSet: IEntityPageContainerProps['dataSet']
  handleExport: () => void
  handleShowEntityFormPanel: () => void
  onViewModeChange: IEntityPageContainerProps['onViewModeChange']
  selection: IEntityPageContainerProps['selection']
}
export interface ISalesOrdersViewHeaderEntitySelectionProps extends IBaseProps {
  selection: any[]
}

/**
 * buttons to be rendered into the View Header for invoicing views
 *
 * @props ISalesOrdersViewHeaderButtonProps
 */
export const SalesOrdersViewHeaderButtons: React.FC<ISalesOrdersViewHeaderButtonProps> = ({
  dataSet,
  handleExport,
  handleShowEntityFormPanel,
  selection,
  onViewModeChange
}) => {
  return (
    <>
      <CardHeaderItem>
        <SalesOrdersViewHeaderPrintSelected selection={selection} />
      </CardHeaderItem>
    </>
  )
}
