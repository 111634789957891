import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface ILoadMarkerProps extends IBaseProps {
  entity: any
  modifiers: (entity: object) => any
  onClick: (event: any) => void
  onMouseOver: (event: any) => void
  onMouseOut: (event: any) => void
}

export class LoadMarker extends React.Component<ILoadMarkerProps, any> {
  public animationDelay: string

  constructor(props) {
    super(props)
    this.animationDelay = (Math as any).random(10) + 's'
  }

  public render() {
    const { entity, modifiers, onClick, onMouseOver, onMouseOut, style } = this.props
    const { associated, fade20, fade50, hovered, selected } = modifiers(entity)
    return (
      <div
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        style={style}
      >
        <div className='c-truckTrackingPin-container'>
          <div
            className={classNames('c-truckTrackingPin-pulse', {
              'c-truckTrackingPin-pulse--associated': associated,
              'c-truckTrackingPin-pulse--fade20': fade20,
              'c-truckTrackingPin-pulse--fade50': fade50,
              'c-truckTrackingPin-pulse--hovered': hovered,
              'c-truckTrackingPin-pulse--selected': selected,
            })}
            style={{
              animationDelay: this.animationDelay,
            }}
          />
          <div className={classNames('c-truckTrackingPin', {
            'c-truckTrackingPin--associated': associated,
            'c-truckTrackingPin--fade20': fade20,
            'c-truckTrackingPin--fade50': fade50,
            'c-truckTrackingPin--hovered': hovered,
            'c-truckTrackingPin--selected': selected,
          })} />
        </div>
      </div>
    )
  }
}
