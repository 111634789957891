import apis from "browser/app/models/apis";
import _ from "lodash";

export const getBusinessEmails = (edge, entityType: string) => {
  if (!edge) { return Promise.resolve([]) }
  return apis.getStore().findRecord(edge.entityId).then((entity) => {
    const emails = entity.get('business.emails')
    const contacts = _.map(emails, (labeledEmail: any) => ({
      companyName: edge.displayName,
      email: labeledEmail.value,
      entityType,
      name: labeledEmail.label,
    }))
    return _.filter(contacts, (contact) => contact.email)
  })
}

export const getBusinessContacts = (edge, entityType: string) => {
  if (!edge) { return Promise.resolve([]) }
  const contactQuery = {
    filters: [{
      path: 'contact.contactOfBusinesses',
      type: 'containsEdge',
      value: edge,
    }],
  }
  return apis.getStore().queryRecords(contactQuery, true).then((result) => {
    const contacts = _.map(result.children, (contact: any) => ({
      companyName: edge.displayName,
      email: contact.person.primaryEmail,
      entityType,
      name: contact.displayName,
    }))
    return _.filter(contacts, (contact) => contact.email)
  })
}
