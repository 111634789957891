import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { renderEventTimestamp } from '../util'
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

interface IShareGroupItemProps extends IBaseProps {
  entity: any
  shareGroup: any
  isFirst: boolean
  isLast: boolean
}

interface IShareGroupItemState {
  expanded: boolean
}

export class ShareGroupItem extends React.Component<IShareGroupItemProps, IShareGroupItemState> {

  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  public render() {
    const { className, shareGroup, isFirst } = this.props
    const { share, uniqueId } = shareGroup[0].val

    return (
      <div className={classNames('c-timelineEvent', className)} key={uniqueId}>
        <div
          className={classNames('c-timelineEvent-line', className, {
            'c-timelineEvent-line--topCutOff': isFirst,
          })}
        />
        <div className="c-timelineEvent-dot" />
        <div className="c-timelineEvent-contentContainer">
          <div className="c-timelineEvent-body">
            {this.renderTitle()}
            {this.renderAccordion()}
            {this.renderBody()}
          </div>
        </div>
      </div>
    )
  }

  private renderTitle = () => {
    const { shareGroup } = this.props
    const { share, creationDate } = shareGroup[0].val
    const { sharedBy } = share
    const documentName = share.entity.displayName
    return (
      <HelpBlock>
        <span className="b">{sharedBy.displayName}</span> shared {documentName},{' '}
        {renderEventTimestamp(creationDate)}
      </HelpBlock>
    )
  }

  private renderAccordion = () => {
    const { expanded } = this.state
    return (
      <button className="c-timelineEvent-body-accordion" onClick={this.handleToggleExpanded}>
        <Icon
          icon={IconNames.CHEVRON_DOWN}
          className={classNames({
            'c-timelineEvent-body-accordion__icon': true,
            'c-timelineEvent-body-accordion__icon--open': expanded,
          })}
        />
      </button>
    )
  }

  private renderBody = () => {
    const { expanded } = this.state
    if (!expanded) {
      return this.renderSummary()
    } else {
      return this.renderShares()
    }
  }

  private renderSummary = () => {
    const { shareGroup } = this.props
    return (
      <div>
        <strong>Show {shareGroup.length} recipients</strong>
      </div>
    )
  }

  private renderShares = () => {
    const { shareGroup } = this.props
    return shareGroup.map(this.renderShare)
  }

  private renderShare = (shareEvent) => {
    const { share, uniqueId } = shareEvent.val
    const { sharedTo } = share
    return (
      <div key={uniqueId}>
        <div>
          <span className="b">{sharedTo.displayName}</span> {this.getSharedToLabel(sharedTo)}
        </div>
      </div>
    )
  }

  private getSharedToLabel(sharedTo) {
    if (sharedTo.firstName) {
      return sharedTo.firstName + ' ' + sharedTo.lastName
    } else if (sharedTo.email) {
      return sharedTo.email
    } else if (sharedTo.phoneNumber) {
      return sharedTo.phoneNumber.phone
    }
    return null
  }

  private handleToggleExpanded = () => {
    const { expanded } = this.state
    this.setState({
      expanded: !expanded,
    })
  }
}
