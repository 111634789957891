import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import 'browser/components/atomic-elements/atoms/select/contact-select/_contact-select.scss'
import { default as EntitySelect, IEntitySelectProps } from 'browser/components/atomic-elements/atoms/select/entity-select'
import { components } from 'react-select'

// tslint:disable-next-line:no-empty-interface
type IContactSelectProps = IEntitySelectProps

export class ContactSelect extends React.Component<IContactSelectProps, any> {
  private input: any
  private store: any

  constructor(props: IContactSelectProps) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      contactEntity: null,
      isLoading: true,
    }
  }

  public componentDidMount() {
    const { value } = this.props
    const valueId: string = _.get(value, 'entityId')
    this.fetchValueEntity(valueId)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const valueId = _.get(this.props.value, 'entityId')
    const nextValueId: string = _.get(nextProps.value, 'entityId')
    if (valueId !== nextValueId) {
      this.fetchValueEntity(nextValueId)
    }
  }

  public focus() {
    this.input.focus()
  }

  public render() {
    return (
      <div className='c-contactSelect u-flexGrow'>
        {this.renderSelect()}
        {this.renderStaticValue()}
      </div>
    )
  }

  private renderContact = (props) => {
    const option = props.data
    const phoneNumber = _.get(option, 'person.phoneNumbers.0.value.phone', '')
    return (
      <div>
        {option.displayName} <span className='c-contactSelect-inlineInformation c-helpBlock--color ml2'>{phoneNumber}</span>
      </div>
    )
  }

  private renderSelect() {
    const {
      className,
      isStatic,
    } = this.props
    if (!isStatic) {
      return (
        <EntitySelect
          {...this.props}
          className={className}
          optionRenderer={this.handleRenderOption}
          valueRenderer={this.handleRenderValue}
          ref={(ref) => { this.input = ref }}
        />
      )
    }
  }
  private renderStaticValue() {
    const { isStatic, size } = this.props
    const { contactEntity } = this.state
    const sizeClassName = size ? `c-fakeInputContainer--${size}` : ''
    if (!isStatic) {
      return
    }

    if (!contactEntity) {
      return (
        <div className={classNames('relative c-fakeInputContainer', sizeClassName)}>
          <LoadingSpinner size='xs' />
        </div>
      )
    }

    // const person: any = _.get(option, 'person', {})
    // const phoneNumber = person.phoneNumbers.length > 0 ? person.phoneNumbers[0].value.phone : ''

    // return (
    //   <div className={classNames('c-fakeInputContainer', sizeClassName)}>
    //     {option.displayName} <span className='c-contactSelect-inlineInformation c-helpBlock--color ml2'>{phoneNumber}</span>
    //   </div>
    // )
  }

  private fetchValueEntity(uniqueId: string) {
    if (!uniqueId) {
      return this.setState({ contactEntity: null })
    }
    this.setState({ isLoading: true })
    this.store.findRecord(uniqueId).then((entity) => {
      this.setState({
        contactEntity: entity,
        isLoading: false,
      })
    })
  }

  private handleRenderOption = (props) => {
    return (
      <components.Option {...props}>
        {this.renderContact(props)}
      </components.Option>
    )
  }

  private handleRenderValue = (props) => {
    return (
      <components.SingleValue {...props}>
        {this.renderContact(props)}
      </components.SingleValue>
    )
  }
}
