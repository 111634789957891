import Promise from 'bluebird'
import _ from 'lodash'
import React from 'react'
import { browserHistory } from 'browser/history'

import { getDeferredUser } from 'shared-libs/helpers/utils'
import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'

interface IEntityFormBlockProps extends IBaseProps {
  defaultValue?: object
  entity: Entity
  errors?: object
  onClose?: (entity: Entity) => void
  onSave: (entity: Entity) => Promise<any>
  settings: Settings
  state?: object
  uiContext?: object
  uiSchemaPath: string
}

interface IEntityFormBlockStates {
  entity: Entity
  errors: any
  title: string
  recipients: any[]
}

const enum NavigationPreference {
  KEEP = 'keep',
  REMOVE = 'remove',
}

export class EntityFormBlock extends React.Component<IEntityFormBlockProps, IEntityFormBlockStates> {

  public static defaultProps: Partial<IEntityFormBlockProps> = {
    uiContext: {
      density: 'collapse',
      isHorizontalLayout: true,
    },
  }

  private store: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    const { entity } = props
    const title = `New ${entity.entityType}`
    this.state = {
      entity,
      errors: props.errors,
      recipients: [],
      title,
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.errors !== nextProps.errors) {
      this.setState({ errors: nextProps.errors })
    }
  }

  public render() {
    const { defaultValue, settings, state, uiSchemaPath, uiContext } = this.props
    const { entity, errors } = this.state
    // TODO(Peter): jank. I guess we need defaultValue here for the document-creation-flow
    const rendererState = {
      defaultValue,
      settings,
      ...this.state,
      ...state,
    }
    const renderContext = {
      ...uiContext,
      onClose: this.handleOnClose,
    }
    // Note that most entities need isHorizontalLayout, except load entity.
    return (
      <EntityRenderer
        applyDefaults={true}
        actions={this}
        componentsMap={ComponentsMap}
        context={renderContext}
        errors={errors}
        onChangeComplete={this.handleOnChange}
        state={rendererState}
        uiSchemaPath={uiSchemaPath}
        value={entity}
      />
    )
  }

  /****************************************************************************/
  // UI Schema actions
  /****************************************************************************/

  private handleOnClose = () => {
    const { entity } = this.state
    this.props.onClose(entity)
  }

  public navigateTo = (entityId: string = undefined, viewId: string = NavigationPreference.KEEP, args: any) => {
    if (!entityId && !viewId) {
      return
    }

    let viewPath = ''
    if (viewId === NavigationPreference.REMOVE) {
      // no-op, default value
    } else if (viewId === NavigationPreference.KEEP) {
      const urlSegments = window.location.pathname.split('/').splice(1)

      // this is a hack
      if (urlSegments[2] === 'view') {
        viewPath = `/${urlSegments[0]}/${urlSegments[1]}/${urlSegments[2]}/${urlSegments[3]}`
      } else if (urlSegments[0] === 'view') {
        viewPath = `/${urlSegments[0]}/${urlSegments[1]}`
      }
    } else {
      viewPath = `/view/${viewId}`
    }

    let entityPath = ''
    if (entityId !== undefined) {
      entityPath = `/entity/${entityId}`
    }

    const destinationPath = `${viewPath}${entityPath}`

    return browserHistory.push({
      pathname: destinationPath
    })
  }

  private handleOnChange = (entity) => {
    const { recipients } = this.state
    // TODO (David): Maybe this needs to be more generic since orders,
    // capacity, and anything can use the entity-form-block
    // This should be moved to the recipients field.
    const result = entity.resolveSubschemaByPath('uiSchema.web.entityCreationSection')
    const defaultRecipients = _.get(result, 'schema.metadata.defaultRecipients', [])
    this.setState({
      entity,
      recipients: _.union(defaultRecipients, recipients),
      title: `New ${entity.entityType}`,
    })
  }

  private handleOnDelete = () => {
    const { entity } = this.state
    entity.delete().then(() => {
      this.handleOnClose()
    })
  }

  private handleOnSave = (): Promise<any> => {
    const { onSave } = this.props
    const { entity, recipients } = this.state
    return Promise.resolve(entity.validate()).then((errors) => {
      if (!_.isEmpty(errors)) {
        this.setState({ errors })
        return Promise.reject(errors)
      }

      const shares = recipients.map((recipient) => entity.addShare(getDeferredUser(recipient)))

      return Promise.all([onSave(entity), ...shares])
    })
  }

  private handleRecipientsChange = (recipients) => {
    this.setState({ recipients })
  }

  private handleOnSendInvite = () => {
    const { entity } = this.state
    entity.set('invite.status', 'Sent')
    void this.handleOnSave()
  }
}
