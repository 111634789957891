import _ from 'lodash'
import React, { useState } from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'

interface ITableAccoutingMappingCellProps extends IBaseProps {
  businessType: string
  dataSet?: any
  row: any
}

const QB_SYNC_TYPE = '/1.0/entities/metadata/quickBooksSync.json'
const QB_SYNC_ID = '164fd38f-5ea0-41f5-bf35-5aae09ddcb7d'

export const TableAccountingMappingCell: React.FC<ITableAccoutingMappingCellProps> = (props) => {
  const { businessType, dataSet, row } = props
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const qbSyncSchema = apis.getStore().getRecord(QB_SYNC_TYPE)
  const entity = row.data

  const handleClearMapping = () => {
    const { activeMixins } = entity
    if (_.some(activeMixins, { entityId: QB_SYNC_ID })) {
      _.remove(activeMixins, (mixin: any) => mixin.entityId === QB_SYNC_ID)
    }
    entity.set('quickBooksSync', null)
    entity.save()
  }

  const addQuickBooksSync = (qbs) => {
    if (!_.find(entity.activeMixins, (mixin) => mixin.entityId === QB_SYNC_ID)) {
      entity.addMixin(qbSyncSchema)
    }
    _.set(entity.content, 'quickBooksSync.identifier', qbs.identifier)
    _.set(entity.content, 'quickBooksSync.version', qbs.version)
    entity.save().then(() => {
      dataSet.find()
    })
  }

  const handleCreateNewQBOEntity = () => {
    setIsSaving(true)
    if (businessType === 'Customer') {
      apis.createQBOCustomer(entity.uniqueId).then((response) => {
        if (!response) { throw Error('Unable to create QBO Customer') }
        addQuickBooksSync(response)
      }).catch((error) => { setIsSaving(false) })
    } else {
      apis.createQBOVendor(entity.uniqueId).then((response) => {
        if (!response) { throw Error('Unable to create QBO Vendor') }
        addQuickBooksSync(response)
      }).catch((error) => { setIsSaving(false) })
    }
  }

  if (isSaving) { return <LoadingSpinner /> }
  if (_.some(entity.activeMixins, { entityId: QB_SYNC_ID })) {
    return (
      <div onClick={handleClearMapping}>
        Clear existing mapping
      </div>
    )
  }
  return (
    <div onClick={handleCreateNewQBOEntity}>
      Create in QBO and map to it
    </div>
  )
}
