import React, { useContext, useState } from 'react'
import _ from 'lodash'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import MobileComponents from '../index'
import { useForceUpdate } from 'browser/components/hooks/useForceUpdate'
import { Entity } from 'shared-libs/models/entity'
import { MultiMixinRenderer } from 'shared-libs/components/entity/multi-mixin-renderer'
import { WithMobileHeader } from 'browser/mobile/contexts/mobile-header-context'
import { EntityErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/entity-error-block'
import { MobileFooter } from '../footer/mobile-footer'
import { Toast } from '../toast/toast'

import './_entity-details.scss'
import { ScrollDownButtonWrapper } from 'browser/components/atomic-elements/atoms/scroll-down-button-wrapper/scroll-down-button-wrapper'

const MOBILE_WEB_UI_SCHEMA_PATH = 'uiSchema.mobileWeb.entityDetails'

/**
 * @uiComponent
 */
interface IMobileEntityDetailsProps {
  history: any
  location: any
  match: any
  entity: Entity
  pageTitle?: string
}

export const MobileEntityDetails = (props: IMobileEntityDetailsProps) => {
  const { match, history, location, entity, pageTitle } = props

  const { settings } = useContext(AppNavigatorContext)
  const [saving, setSaving] = useState(false)
  const [progress, setProgress] = useState<number | undefined>(undefined)
  const [errors, setErrors] = useState(null)
  const forceUpdate = useForceUpdate()

  const displayText =
    pageTitle || (entity ? `${entity.displayName} - ${entity.entityType}` : 'Vector')

  const rollbackEntity = () => {
    entity.rollback()
    setErrors(null)
    forceUpdate()
  }

  const saveEntity = async () => {
    setSaving(true)
    try {
      await entity.save({ onProgress: setProgress })
      setErrors(null)
      Toast.show({
        message: 'Saved successfully',
        timeout: 1500,
      })
    } catch (e) {
      setErrors(e?.errors)
    } finally {
      setProgress(undefined)
    }
    setSaving(false)
  }

  return (
    <div className="grid-block vertical u-overflowHidden mobile-entity-details ph3">
      <div className="grid-block vertical u-overflowHidden mobile-entity-details__renderer">
        <ScrollDownButtonWrapper className="flex-column">
          <WithMobileHeader title={displayText}>
            <MultiMixinRenderer
              actions={{}}
              componentsMap={MobileComponents}
              context={{
                density: 'collapse',
                isFullScreen: true,
                isHorizontalLayout: false,
                location,
                onSave: saveEntity,
              }}
              errors={errors}
              shouldOverrideUiSchema={true}
              state={{ history, location, match, settings, errors }}
              value={entity}
              uiSchemaPath={MOBILE_WEB_UI_SCHEMA_PATH}
              onChangeComplete={forceUpdate}
            />
          </WithMobileHeader>
        </ScrollDownButtonWrapper>
      </div>
      <MobileErrorBlock errors={errors} />
      <MobileFooter
        progress={progress}
        isPrimaryButtonLoading={saving}
        isVisible={entity.isDirty}
        onCancelButtonClick={rollbackEntity}
        onPrimaryButtonClick={saveEntity}
      />
    </div>
  )
}

interface IErrorBlockProps {
  errors: any
}

const MobileErrorBlock: React.FC<IErrorBlockProps> = (props: IErrorBlockProps) => {
  const [isSummary, setIsSummary] = useState(true)

  return (
    <div onClick={() => setIsSummary(!isSummary)}>
      <EntityErrorBlock
        className="u-innerBumper"
        errors={props.errors}
        isSummary={isSummary}
      />
    </div>
  )
}
