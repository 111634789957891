import Promise from 'bluebird'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { EntityFooter } from 'browser/components/atomic-elements/atoms/footer/entity-footer'
import { Sheet } from 'browser/components/atomic-elements/atoms/sheet'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
// tslint:disable-next-line:max-line-length
import { EntityCreationProgressPopover } from 'browser/components/atomic-elements/organisms/entity/entity-creation-progress-popover'
import { EntityFormBlock } from 'browser/components/atomic-elements/organisms/entity/entity-form-block'
import { EntityAssociationsRequest } from './entity-associations-request'

interface IEntityAssociationsSheetProps extends IBaseProps {
  associatedEntityDefaultValue?: object
  associatedEntitySchema?: any
  edgePath: string
  entity: any
  entitySchema?: any
  isEdgeOnEntity: boolean
  maxHeight?: number
  onChange?: (value: any) => void
  showFooter?: boolean
  uiSchemaPath?: string
  size?: string
  state?: any
}

interface IEntityAssociationsSheetStates {
  associatedEntity: any
  errors: object
  isSaving: boolean
}

export class EntityAssociationsSheet extends
  React.Component<IEntityAssociationsSheetProps, IEntityAssociationsSheetStates> {

  public static defaultProps: Partial<IEntityAssociationsSheetProps> = {
    onChange: _.noop,
    showFooter: true,
    size: 'sm',
    uiSchemaPath: 'uiSchema.web.entityCreationSheet',
  }

  private sheet: Sheet
  private store: any
  private request: EntityAssociationsRequest

  constructor(props) {
    super(props)
    const {
      associatedEntityDefaultValue,
      associatedEntitySchema,
      edgePath,
      entitySchema,
      isEdgeOnEntity,
    } = props
    this.store = apis.getStore()
    const associatedEntity = this.store.createRecord(
      associatedEntitySchema, associatedEntityDefaultValue)
    this.request = new EntityAssociationsRequest({
      associatedEntitySchema, edgePath, entitySchema, isEdgeOnEntity,
    })
    this.state = {
      associatedEntity,
      errors: {},
      isSaving: false,
    }
  }

  public render() {
    const {
      associatedEntityDefaultValue,
      maxHeight,
      uiSchemaPath,
      size,
      state,
    } = this.props
    const { associatedEntity, errors } = this.state
    const handleRef = (ref) => this.sheet = ref
    return (
      <Sheet
        footer={this.renderSheetFooter()}
        maxHeight={maxHeight}
        ref={handleRef}
        size={size}
      >
        <EntityFormBlock
          defaultValue={associatedEntityDefaultValue}
          entity={associatedEntity}
          errors={errors}
          onClose={this.handleClose}
          onSave={this.handleSave}
          state={state ? state : null}
          uiSchemaPath={uiSchemaPath}
        />
      </Sheet>
    )
  }

  private handleClose = () => {
    this.sheet.close()
  }

  private handleSave = (associatedEntity): Promise<any> => {
    return Promise.resolve(associatedEntity.validate()).then((errors) => {
      if (!_.isEmpty(errors)) {
        this.setState({ errors })
        return Promise.resolve(errors)
      }
      this.setState({ isSaving: true })
      this.showUploadProgressToast(associatedEntity)
      this.props.onChange(associatedEntity)
      this.handleClose()
      return Promise.resolve()
    })
  }

  private renderSheetFooter() {
    const { showFooter } = this.props
    const { associatedEntity, errors, isSaving } = this.state
    if (!showFooter) {
      return
    }
    const handleConfirm = () => this.handleSave(associatedEntity)
    return (
      <EntityFooter
        entity={associatedEntity}
        errors={errors}
        isPrimaryButtonLoading={isSaving}
        isVisible={true}
        onCancelButtonClick={this.handleClose}
        onPrimaryButtonClick={handleConfirm}
      />
    )
  }

  private showUploadProgressToast(associatedEntity) {
    const { entity } = this.props
    const handleSave = (onProgress) => {
      return this.request.addAssociateEntity(entity, associatedEntity, { onProgress })
    }
    const toastKey = Toast.show({
      message: (
        <EntityCreationProgressPopover
          entity={entity}
          // tslint:disable-next-line:jsx-no-lambda
          onClose={() => Toast.dismiss(toastKey)}
          onSave={handleSave}
        />
      ),
      position: Position.BOTTOM_RIGHT,
    })
  }
}
