import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Checkbox } from 'browser/components/atomic-elements/atoms/checkbox/checkbox'
import { Store } from 'shared-libs/models/store'

interface IMixinCheckboxFieldProps extends IBaseProps {
  entity: any
  mixinId: any
  onChange: any
}

export class MixinCheckboxField extends React.Component<IMixinCheckboxFieldProps, any> {

  private mixinSchema: any
  private store: Store

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.mixinSchema = this.store.getRecord(props.mixinId)
  }

  public render() {
    const toggle = this.containsMixin(this.props)
    return (
      <Checkbox
        {...this.props}
        onClick={this.onToggle}
        value={toggle}
      />
    )
  }

  private onToggle = () => {
    const { entity, onChange } = this.props
    const { activeMixins } = entity
    const containsMixin = this.containsMixin(this.props)
    if (containsMixin) {
      _.remove(activeMixins, (mixin: any) => {
        return mixin.entityId === this.mixinSchema.uniqueId
      })
      // const namespace = this.mixinSchema.get('metadata.namespace')
      // _.omit(entity, [namespace])
    } else {
      entity.addMixin(this.mixinSchema)
    }
    onChange()
  }

  private containsMixin = (props) => {
    const { entity } = props
    const { activeMixins } = entity
    return _.some(activeMixins, (mixin) => {
      return mixin.entityId === this.mixinSchema.uniqueId
    })
  }
}
