import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { IRenderListItemProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Column } from 'browser/components/atomic-elements/atoms/table'
import { TableButtonCell } from 'browser/components/atomic-elements/atoms/table/cells/table-button-cell'
import { TableLinkCell } from 'browser/components/atomic-elements/atoms/table/cells/table-link-cell'
import { HtmlTable } from 'browser/components/atomic-elements/atoms/table/html-table'
import { HtmlTableRow } from 'browser/components/atomic-elements/atoms/table/html-table/row'
import { Textarea } from 'browser/components/atomic-elements/atoms/textarea/textarea'
import { Query } from 'shared-libs/models/query'

const columns: Column[] = [
  {
    label: 'Carrier',
    width: 220,
  },
  {
    label: 'Notes',
  },
  {
    label: 'Quote ($)',
    textAlign: 'right',
    width: 80,
  },
  {
    width: 72,
  },
]

/**
 * @uiComponent
 */
interface IOrderCapacityMatchingTableProps extends IBaseProps {
  entity: any
}

interface IOrderCapacityMatchingTableState {
  capacities: any[]
  isLoading: boolean
}

export class OrderCapacityMatchingTable
  extends React.Component<IOrderCapacityMatchingTableProps, IOrderCapacityMatchingTableState> {

  public collection: any

  constructor(props) {
    super(props)
    this.state = {
      capacities: [],
      isLoading: false,
    }
  }

  public componentDidMount() {
    const { entity } = this.props
    const { stops } = entity.dispatchOrder
    const origin = _.get(stops[0], 'location', null)
    const destination = _.get(stops[1], 'location', null)
    if (!origin || !destination) {
      return
    }
    const filters = [
      this.getGeolocationFilter('capacity.origin', origin),
      this.getGeolocationFilter('capacity.destination', destination),
      {
        label: 'Status',
        path: 'capacity.status',
        type: 'match',
        values: ['Available'],
      },
    ]
    const orders = [{
      label: 'Posted date',
      path: 'creationDate',
      type: 'descending',
    }]
    const entityType = '/1.0/entities/metadata/capacity.json'
    this.collection = new Query(apis)
      .setEntityType(entityType)
      .setFilters(filters)
      .setOrders(orders)
      .getCollection()
    this.setState({ isLoading: true })
    this.collection.find().then((content) => {
      this.setState({
        capacities: content,
        isLoading: false,
      })
    })
  }

  public render() {
    const { capacities, isLoading } = this.state
    if (isLoading) {
      return (
        <div className='u-border u-innerBumper--xl u-textCenter u-positionRelative'>
          <LoadingSpinner size='sm' />
        </div>
      )
    }
    if (_.isEmpty(capacities)) {
      return this.renderEmptyState()
    }
    return (
      <HtmlTable
        className='c-table--collapse c-table--responsive'
        density='collapse'
        columns={columns}
        renderListItem={this.renderLineItem}
        showItemDeleteButton={false}
        value={capacities}
      />
    )
  }

  private getGeolocationFilter(path, location) {
    const address = location.denormalizedProperties['location.address']
    return {
      path,
      type: 'geoIntersect',
      value: {
        geolocation: address.geolocation,
        type: 'geolocation',
      },
    }
  }

  private renderEmptyState() {
    return (
      <div className='u-border u-innerBumper--lg u-textCenter'>
        <HelpBlock>
          No matching capacity found.
        </HelpBlock>
      </div>
    )
  }

  private renderLineItem = (props: IRenderListItemProps): React.ReactElement<any> => {
    const { entity } = this.props
    const { item, index } = props
    return (
      <HtmlTableRow
        index={index}
        isEditableInline={false}
        isStatic={true}
      >
        <TableLinkCell
          className='u-ellipsis c-tableCellText'
          value={_.get(item, 'capacity.carrier')}
        />
        <Textarea
          minRows={1}
          value={_.get(item, 'capacity.notes', '-')}
        />
        <Input
          inputClassName='tr'
          value={_.get(item, 'capacity.quote.value')}
        />
        <TableButtonCell
          buttonClassName={classNames(Classes.MINIMAL)}
          buttonText='Assign'
          overlayComponent='ui:capacityAssignmentSheet'
          params={{ brokerOrder: entity }}
          value={item}
          valueParamName='capacity'
        />
      </HtmlTableRow>
    )
  }
}
