import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { EntityTable } from 'browser/components/atomic-elements/organisms/entity/entity-table'
import { EntityDataSource } from '../entity-data-source'

const FIRM_ID_PATH = 'owner.firm.entityId'
const DISPATCH_ORDER_REVENUE_ITEM_VALUE_PATH = 'dispatchOrder.revenueItems.amount.value'

const ORDER_TYPE = '/1.0/entities/metadata/dispatchOrder.json'
const TIGERLINES_FIRM_ID = 'bf6a36f7-c417-47ea-8b2b-1697694e4bde'

interface IRbOrdersTableProps  {
  entity: any
}

interface IRbOrdersTableStates {
  entities: any
  isLoading: boolean
  showErrorState: boolean
  errorTitle: string
  errorDescription: string
}

const TABLE_COLUMNS = [
  {
    cellComponent: {
      path: 'entity',
      type: 'ui:table:hasRequiredDocsCell',
    },
    isFixed: true,
    label: '',
    path: 'data',
    width: 50,
  },
  {
    cellComponent: {
      labelPath: 'dispatchOrder.identifier',
      linkPath: 'uniqueId',
      path: 'entity',
      type: 'ui:table:linkCell',
    },
    groupKey: 'data',
    isFixed: true,
    label: 'Orders',
    path: 'data',
    sortKey: 'dispatchOrder.identifier',
    width: 100,
  },
  {
    className: 'u-tabularFigures',
    format: { type: 'currency' },
    label: 'Revenue ($)',
    path: 'computations.totalRevenue',
    width: 100,
  },
  {
    className: 'u-tabularFigures',
    flexGrow: 1,
    label: 'Delivery Window',
    path: 'dispatchOrder.stops.last.timeWindow',
    width: 130,
  },
  {
    flexGrow: 1,
    isSortable: false,
    label: 'Destination',
    path: 'dispatchOrder.stops.last.location',
    width: 130,
  },
]

export class RbOrdersTable extends React.Component<IRbOrdersTableProps, IRbOrdersTableStates> {

  private dataSet: EntityDataSource

  constructor(props) {
    super(props)

    const handleOnChange = (data) => {
      const orderIds = _.map(data, (e) => e.data.uniqueId)
      apis.checkIfOrdersContainRequiredDocuments(orderIds).then((result) => {
        _.forEach(data, (e) => {
          _.set(e, 'containsRequiredDocuments', result[e.data.uniqueId])
        })
        this.setState({ entities: data })
      }).finally(() => {
        this.setState({ isLoading: false })
      })
      this.forceUpdate()
    }

    this.dataSet = new EntityDataSource({
      computations: {
        totalRevenue: {
          path: DISPATCH_ORDER_REVENUE_ITEM_VALUE_PATH,
          type: 'sum',
        },
      },
      entityType: ORDER_TYPE,
    }).setOnChange(handleOnChange)

    this.state = {
      entities: [],
      isLoading: true,
      showErrorState: false,
      errorTitle: '',
      errorDescription: ''
    }
  }

  public componentDidMount() {
    const { entity } = this.props
    const firmId = _.get(entity, FIRM_ID_PATH, '')
    // If it is tigerlines
    if (firmId === TIGERLINES_FIRM_ID) {
      apis.getRelatedOrders(entity.uniqueId).then((res: string[]) => {
        if (res.length > 80) {
          this.setState({
            errorTitle: 'Unable to display related orders',
            errorDescription: `There are ${res.length} related orders which exceed this view${"'"}s limit.`,
            showErrorState: true,
            isLoading: false })
        } else if (res.length === 0) {
          this.setState({
            errorTitle: 'Unable to find related orders',
            errorDescription: 'There are no related orders for this invoice in our system.',
            showErrorState: true,
            isLoading: false })
        } else {
          this.dataSet.setFilters(
            {
              path: 'uniqueId',
              type: 'match',
              values: res,
            },
          )
          this.dataSet.find()
        }
      })
    } else {
      const orderIds = _.uniq(_.map(entity.rbInvoice.orders, (order) => order.entityId))
      this.dataSet.setFilters(
        {
          path: 'uniqueId',
          type: 'match',
          values: orderIds,
        },
      )
      this.dataSet.find()
    }
  }

  public componentWillUnmount() {
    this.dataSet.dispose()
  }

  public render() {
    return (
      <Section title='Related Orders'>
        <div className='u-positionRelative u-minHeight720'>
          <div className='grid-content vertical'>
            <AutofillBlock className='u-border'>
              {this.renderContent()}
            </AutofillBlock>
          </div>
        </div>
      </Section>
    )
  }

  private renderContent = () => {
    const { entities, isLoading, showErrorState, errorTitle, errorDescription } = this.state
    const entitySchema = apis.getStore().getRecord(ORDER_TYPE)

    if (showErrorState) {
      return (
        <div className='c-emptyTableMessage'>
          <div className='f3 lh-title mb2'>
            {errorTitle}
          </div>
          <div className='lh-copy mb4'>
            {errorDescription}
          </div>
        </div>
      )
    }

    return (
      <EntityTable
        columns={TABLE_COLUMNS}
        entitySchema={entitySchema}
        isConfigurable={false}
        isLoading={isLoading}
        onRowClick={null}
        onRowSelect={null}
        rows={entities}
      />
    )
  }
}
