import _ from 'lodash'
import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'

import 'browser/components/atomic-elements/atoms/button/_button-group.scss'
import 'browser/components/atomic-elements/atoms/button/_button.scss'
import { getDebugId } from 'browser/app/utils/utils'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { CustomFormulas } from 'shared-libs/helpers/formulas'
import { evaluateExpressionWithScopes } from 'shared-libs/helpers/evaluation'
import { FramesManager } from 'shared-libs/components/view/frames-manager'

import 'browser/components/atomic-elements/atoms/qrcode-view/_qrcode-view.scss'

/**
 * @uiComponent
 */
interface IQRCodeViewProps extends IBaseProps {
  qrValue: string
  qrSize?: number
  frames: FramesManager
  formula?: string
  showValue?: boolean
  includeMargin?: boolean
  imageSettings?: any
}

type LegacyProps = { size?: number }
type ForwardRef<T> = { forwardRef: React.Ref<T> }

const QRCodeViewDefault: React.FC<Partial<IQRCodeViewProps> & ForwardRef<any>> = ({
  forwardRef,
  ...props
}) => {
  const { qrValue, qrSize, showValue, frames, formula } = props

  const [value, setValue] = useState()
  useEffect(() => {
    const evaluatedValue = formula
      ? evaluateExpressionWithScopes(frames, formula, CustomFormulas)
      : undefined
    const value = _.isEmpty(evaluatedValue) ? qrValue : evaluatedValue
    setValue(value)
  }, [formula, qrValue, showValue])

  if (!value) {
    return null
  }

  // maintain support for any schemas still supplying `size`. If size is
  // numeric, use it, otherwise default to qrSize.
  const legacyProps = props as LegacyProps
  const size = _.defaultTo(_.toNumber(legacyProps.size), qrSize)

  // extract any relevant QRCode props, as passing `{...props}` directly will
  // log many noisy console errors indicating that the DOM element doesn't
  // support them.
  let qrProps: any = _.pick(props, [
    'includeMargin',
    'bgColor',
    'fgColor',
    'level',
    'imageSettings',
    'renderAs',
  ])

  qrProps = {
    ...qrProps,
    size,
    value,
  }

  const renderValue = () => {
    return <HelpBlock className="wrap mt3 mb3" helpText={value} />
  }

  return (
    <div className="grid-block align-verticalCenter u-justifyContentCenter" data-debug-id={getDebugId(frames)}>
      <QRCode {...qrProps} className="mt3 mb3" />
      {showValue && renderValue()}
    </div>
  )
}

QRCodeViewDefault.defaultProps = {
  showValue: false,
  qrSize: 128,
}

export const QRCodeView: React.FC<Partial<IQRCodeViewProps>> = React.forwardRef(
  (props: Partial<IQRCodeViewProps>, ref: React.Ref<any>) => (
    <QRCodeViewDefault {...props} forwardRef={ref} />
  )
)
