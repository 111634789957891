import _ from 'lodash'
import React from 'react'

import 'browser/components/atomic-elements/atoms/tether-target/_tether-target.scss'
import { IPortalProps, Portal } from './portal'

interface ITetherTargetProps extends IPortalProps {
  tethered: React.ReactElement<any>
  theme?: string
}

export class TetherTarget extends React.Component<ITetherTargetProps, any> {

  public static defaultProps: Partial<ITetherTargetProps> = {
    theme: 'basic',
  }

  private portal: Portal

  public close = () => {
    // Need null check on this.portal, because we are wrapping it in setTimeout
    // so code maybe executed after this.portal becomes null
    setTimeout(() => {
      if (this.portal) { this.portal.closePortal() }
    })
  }

  public updateTetherPosition = () => {
    if (this.portal) { this.portal.updateTetherPosition() }
  }

  public toggle = () => {
    const isOpened = this.portal.isOpened()
    // Need null check on this.portal, because we are wrapping it in setTimeout
    // so code maybe executed after this.portal becomes null
    setTimeout(() => {
      if (!this.portal) { return }
      isOpened ? this.portal.closePortal() : this.portal.openPortal()
    })
  }

  public render() {
    const portalProps = _.omit(this.props, ['children', 'tethered'])
    const handleRef = (ref) => { this.portal = ref }
    return (
      <Portal
        {...portalProps}
        ref={handleRef}
        tetherTarget={React.Children.only(this.props.children)}
      >
        {this.renderTethered()}
      </Portal>
    )
  }

  private renderTethered() {
    const { theme, tethered } = this.props
    const classNames = `c-tether-element c-tether-theme-${theme}`
    const content = React.cloneElement(tethered, {
      onClose: this.close,
      onResize: this.updateTetherPosition,
    })
    return (
      <div className={classNames}>
        {content}
      </div>
    )
  }
}
