import _ from 'lodash'
import { Query } from 'shared-libs/models/query'

export class CityQuery {
  private apis: any
  private entityType: string
  private filterKey: string

  constructor(apis, entityType, filters, filterKey) {
    this.apis = apis
    this.entityType = entityType
    this.filterKey = filterKey
  }

  public getGeolocationForOption(option) {
    if (option.geolocation) {
      return Promise.resolve(option.geolocation)
    }
    return this.apis.getGoogleMapsPlace(option.placeId).then((place) => {
      return {
        latitude: place[0].geometry.location.lat(),
        longitude: place[0].geometry.location.lng(),
      }
    })
  }

  public queryLocationOptions(query) {
    const autoCompleteOptions = { types: ['(cities)'] }
    return this.apis.getGoogleMapsPlacesAutomComplete(query, autoCompleteOptions)
      .then((locations) => {
        const options = _.map(locations, (location: any, index) => ({
          displayName: `${location.terms[0].value}, ${location.terms[1].value}`,
          locality: location.terms[0].value,
          numLoads: 0,
          placeId: location.place_id,
          region: location.terms[1].value,
        }))
        return this.queryLoadCountForLocations(options).then((loadsByLocation) => {
          _.forEach(options, (option) => {
            option.numLoads = this.getLoadCountForLocation(option, loadsByLocation)
          })
          return options
        })
      })
  }

  public queryLoadCountForGeolocation(geolocation, radius) {
    const request = {
      computations: {
        countEntities: {
          path: 'uniqueId',
          type: 'valueCount',
        },
      },
      filters: [
        {
          center: geolocation,
          distance: radius,
          distanceUnit: 'miles',
          key: this.filterKey,
          path: `${this.filterKey}.geolocation`,
          type: 'geoDistance',
        },
      ],
      groups: [
        {
          lanePointBucketType: 'localityRegion',
          path: this.filterKey,
          type: 'lanePoint',
        },
      ],
    }
    return new Query(this.apis)
      .setComputations(request.computations)
      .setEntityType(this.entityType)
      .setFilters(request.filters)
      .getCollection().find()
  }

  public queryLoadCountForLocation(location) {
    return this.queryLoadCountForLocations([location])
  }

  private getLoadCountForLocation(location, loadCountsByLocation: any[]) {
    const { locality, region } = location
    for (const loadCount of loadCountsByLocation) {
      if (loadCount.data === `${locality}--${region}`) {
        return loadCount.computations.countEntities
      }
    }
    return 0
  }

  private queryLoadCountForLocations(locations) {
    const localities = _.map(locations, 'locality')
    const region = _.uniq(_.map(locations, 'region'))
    const request = {
      computations: {
        countEntities: {
          path: 'uniqueId',
          type: 'valueCount',
        },
      },
      filters: [
        {
          path: `${this.filterKey}.locality`,
          type: 'match',
          values: localities,
        },
        {
          path: `${this.filterKey}.region`,
          type: 'match',
          values: region,
        },
      ],
      groups: [
        {
          path: `${this.filterKey}.localityRegion`,
          type: 'field',
        },
      ],
    }
    return new Query(this.apis)
      .setComputations(request.computations)
      .setEntityType(this.entityType)
      .setFilters(request.filters)
      .setGroups(request.groups)
      .getCollection().find()
  }
}
