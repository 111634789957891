import {
  TimezoneSelect as BlueprintTimezoneSelect,
  TimezoneDisplayFormat,
} from '@blueprintjs/datetime'
import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
// tslint:disable-next-line:max-line-length
import { FormGroupContentWrapper } from 'browser/components/atomic-elements/atoms/form-group-content-wrapper/form-group-content-wrapper'
import 'browser/components/atomic-elements/atoms/select/timezone-select/_timezone-select.scss'

export interface ITimezoneSelectProps extends IBaseProps {
  date?: Date
  errorText?: string
  isDisabled?: boolean
  onChange?: (timezone: string) => void
  value?: string
  onBlur?: any
  onFocus?: any
  isHorizontalLayout?: boolean
}

// tslint:disable-next-line:no-empty-interface
interface ITimezoneSelectState {
  isFocused: boolean
}

export class TimezoneSelect extends React.Component<ITimezoneSelectProps, ITimezoneSelectState> {
  constructor(props) {
    super(props)
    this.state = {
      isFocused: false,
    }
  }

  public render() {
    const {
      className,
      date,
      errorText,
      isDisabled,
      isHorizontalLayout,
      onChange,
      value,
    } = this.props
    const { isFocused } = this.state
    return (
      <FormGroupContentWrapper
        hasError={!_.isEmpty(errorText)}
        isHorizontalLayout={isHorizontalLayout}
        isFocused={isFocused}
        isDisabled={isDisabled}
        className={className}
      >
        <BlueprintTimezoneSelect
          buttonProps={{
            className: Classes.MINIMAL,
          }}
          date={date}
          disabled={isDisabled}
          value={value}
          onChange={onChange}
          inputProps={{
            onBlur: this.handleOnBlur,
            onFocus: this.handleOnFocus,
          }}
          popoverProps={{
            modifiers: {
              preventOverflow: { enabled: true },
            },
          }}
          placeholder=""
          valueDisplayFormat={TimezoneDisplayFormat.ABBREVIATION}
        />
      </FormGroupContentWrapper>
    )
  }

  private handleOnBlur = (event) => {
    const { onBlur } = this.props
    this.setState({ isFocused: false })
    if (onBlur) {
      onBlur(event)
    }
  }

  private handleOnFocus = (event) => {
    const { onFocus } = this.props
    this.setState({ isFocused: true })
    if (onFocus) {
      onFocus(event)
    }
  }
}
