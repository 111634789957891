import _ from 'lodash'
import React, { Component } from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { BulkAddTableCell } from './bulk-add-table-cell'

interface IBulkAddTableRowProps {
  handleRowDelete: () => void
  handleDataChange: (row, col, newValue) => void
  numColumns: number
  row: number
  rowData: string[]
  rowErrors: string[]
  onFocus: () => void
  onBlur: () => void
}

export class BulkAddTableRow extends Component<IBulkAddTableRowProps> {
  private firstCellInRow

  public focus() {
    if (this.firstCellInRow) {
      this.firstCellInRow.focus()
    }
  }

  public shouldComponentUpdate(nextProps) {
    return  !_.isEqual(this.props.rowData, nextProps.rowData) ||
            !_.isEqual(this.props.rowErrors, nextProps.rowErrors) ||
            this.props.row !== nextProps.row
  }

  public render() {
    const { numColumns } = this.props
    return (
      <tr className='c-table-row' >
        {numColumns && Array(numColumns).fill(undefined).map((_, col) => this.renderDataCell(col))}
        <td className='c-table-cell c-table-cell--delete' style={{ verticalAlign: 'middle' }}>
          <div style={{ marginLeft: '-3px', display: 'flex', alignItems: 'center' }}>
            <Icon
              icon={IconNames.CROSS}
              color='#db3737'
              style={{ cursor: 'pointer' }}
              onClick={this.props.handleRowDelete}
            />
          </div>
        </td>
      </tr>
    )
  }

  private renderDataCell(col) {
    const { handleDataChange, onBlur, onFocus, row, rowData } = this.props
    const data = rowData[col]
    const error = this.props.rowErrors[col]

    const additionalProps: any = {}
    if (col === 0) {
      additionalProps.ref = (ref) => { this.firstCellInRow = ref }
    }

    const handleOnChange = (newValue) => {
      handleDataChange(row, col, newValue)
    }

    return (
      <BulkAddTableCell
        key={`data-${row}-${col}`}
        data={data}
        error={error}
        onChange={handleOnChange}
        {...additionalProps}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    )
  }
}
