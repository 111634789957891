import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CanvasLayer, ICanvasLayerProps } from './canvas-layer'

interface Line {
  color: string
  geolocations: any[]
  lineDash?: number
  lineWidth?: number
}

interface ICanvasLineLayerProps extends ICanvasLayerProps {
  lines: Line[]
}

export class CanvasLineLayer extends React.Component<ICanvasLineLayerProps, any> {

  public render() {
    return (
      <CanvasLayer
        {...this.props}
        redraw={this.handleRedrawPaths}
      />
    )
  }

  private handleRedrawPaths = ({ ctx, width, height, project }) => {
    const { lines } = this.props
    const pixelsPerMeter = this.getPixelsPerMeter()
    const trailWidth = this.getTrailWidthMeters() * pixelsPerMeter * 0.1
    ctx.clearRect(0, 0, width, height)
    _.forEach(lines, (line) => {
      const points = _.map(line.geolocations, project)
      ctx.beginPath()
      if (line.lineDash) {
        const multiplier = line.lineDash
        ctx.setLineDash([trailWidth * multiplier, trailWidth * multiplier])
      }
      ctx.lineWidth = trailWidth
      ctx.strokeStyle = line.color
      this.drawPoints(ctx, points)
      ctx.stroke()
    })
  }

  private drawPoints(ctx, points) {
    ctx.moveTo(points[0][0], points[0][1])
    for (let i = 0; i < points.length; ++i) {
      ctx.lineTo(points[i][0], points[i][1])
    }
  }

  // Calculates the current trail width, based on zoom
  private getTrailWidthMeters() {
    const { zoom } = this.props
    // Zoomed all the way out at zoom level 0, each trail is 100km wide.
    return 100e3 * Math.pow(0.6, zoom)
  }

  private getPixelsPerMeter() {
    const { zoom } = this.props
    return Math.pow(2, zoom) / 10e3
  }
}
