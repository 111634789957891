declare let window: any

import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'

import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
// tslint:disable-next-line:max-line-length
import { ShareConfigurationModal } from 'browser/components/atomic-elements/organisms/share-configuration-modal/share-configuration-modal'
import 'browser/components/atomic-elements/organisms/table-action-bar/_table-action-bar.scss'
import { TableActionBar } from 'browser/components/atomic-elements/organisms/table-action-bar/table-action-bar'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { DefaultTableActionBar } from 'browser/components/atomic-elements/organisms/table-action-bar/default-table-action-bar'

/**
 * @prop entityPath - key in the document object to access the document entity
 * @prop selection - array of objects representing documents
 */
interface IDocumentsTableActionBarProps extends IBaseProps {
  entityPath: string
  selection: any[]
}

/**
 * Toolbar component containing action buttons displayed when multiple documents are selected
 *
 * @props IDocumentsTableActionBarProps
 */
export const DocumentsTableActionBar: React.FC<IDocumentsTableActionBarProps> = (props) => {
  const { entityPath, selection } = props
  const [isCreatingPDF, setIsCreatingPDF] = useState(false)
  const [isDownloadingDocuments, setIsDownloadingDocuments] = useState(false)
  const [isDeletingDocuments, setIsDeletingDocuments] = useState(false)
  const [isProcessingDocuments, setIsProcessingDocuments] = useState(false)
  const [isUnProcessingDocuments, setIsUnProcessingDocuments] = useState(false)
  const [isTriggerPush, setIsTriggerPush] = useState(false)

  const handleDownloadDocuments = () => {
    const attachments = _.map(getEntities(), (document: any) => ({
      entityId: document.get('uniqueId'),
      fileName: `${document.displayName}.pdf`,
      type: 'document',
    }))
    setIsDownloadingDocuments(true)
    apis.batchDownloadDocuments({ attachments }).then((url) => {
      window.location = url
      setIsDownloadingDocuments(false)
    })
  }

  const handleShowShareConfigurationModal = () => {
    const entities = getEntities()
    ShareConfigurationModal.open({
      entities,
      onSend: handleShareDocuments,
      showRecipientsList: false,
      showShareAccessSettings: true,
    })
  }

  const handleShareDocuments = (emails, message) => {
    const uniqueIds = getEntityIds()
    return apis.batchShareDocuments(uniqueIds, emails, message)
  }

  const handleBatchPrint = () => {
    const entities = getEntityIds()
    setIsCreatingPDF(true)
    return apis.batchPrintDocuments(entities).then((response: string) => {
      setIsCreatingPDF(false)
      window.open(response)
    })
  }

  const handleTriggerPush = () => {
    const entities = getEntityIds()
    setIsTriggerPush(true)
    return apis.batchTriggerPush(entities).then((response: string) => {
      setIsTriggerPush(false)
    })
  }

  const handleProcessDocuments = (shouldDocBeProcessed: boolean) => {
    /**
     * Note: this method operates by marking each document's processed status individually.
     * An API request will be made for each document.
     */
    shouldDocBeProcessed ? setIsProcessingDocuments(true) : setIsUnProcessingDocuments(true)
    const entities = getEntities()
    return Promise.all(entities.map((entity: Entity) => {
      entity.set('document.processed', shouldDocBeProcessed)
      return entity.save()
    })).then(() => {
      shouldDocBeProcessed ? setIsProcessingDocuments(false) : setIsUnProcessingDocuments(false)
    })
  }

  const handleShowDeleteDocumentsModal = () => {
    ConfirmationModal.open({
      confirmationText: 'Do you want to delete these items?',
      confirmationTitle: 'Confirm Delete',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: handleBatchDelete,
      primaryButtonText: 'Confirm',
    })
  }

  const handleBatchDelete = () => {
    /**
     * Note: this method operates by deleting each document individually.
     * An API request will be made for each document.
     */
    const entities = getEntities()
    return Promise.all(entities.map((entity) => {
      return entity.delete()
    })).then(() => {
      setIsDeletingDocuments(false)
        Toast.show({
          message: (
            <div className="c-toastContents">
              <Icon
                className="c-toastContents-icon mr3"
                icon={IconNames.TRASH}
                size={30}
              />
              <div className="c-toastContents-message">
                The selected documents have been deleted. The table will be refreshed.
              </div>
            </div>
          ),
          position: Position.BOTTOM_RIGHT,
          timeout: 10000,
        })
    })
  }

  const getEntities = () => {
    return _.filter(_.map(selection, entityPath), (item) => {
      return item instanceof Entity
    })
  }

  const getEntityIds = () => {
    const entities = getEntities()
    return _.map(entities, 'uniqueId')
  }

  const renderActionButtons = () => {
    const DefaultBar = new DefaultTableActionBar(props)

    return (
      <Fragment>
        <Button
          className={classNames('mr2', Classes.iconClass(IconNames.DOWNLOAD))}
          data-debug-id='multi-details-download'
          isLoading={isDownloadingDocuments}
          onClick={handleDownloadDocuments}
        >
          Download
        </Button>
        <Button
          className={classNames('mr2', Classes.iconClass(IconNames.PRINT))}
          isLoading={isCreatingPDF}
          onClick={handleBatchPrint}
        >
          Print All
        </Button>
        <Button
          className={classNames('mr2', Classes.iconClass(IconNames.SHARE))}
          onClick={handleShowShareConfigurationModal}
        >
          Share
        </Button>
        <Button
          className={classNames('mr2', Classes.iconClass(IconNames.TICK))}
          isLoading={isProcessingDocuments}
          onClick={() => { handleProcessDocuments(true)}}
        >
          Mark Processed
        </Button>
        <Button
          className={classNames('mr2', Classes.iconClass(IconNames.UNDO))}
          isLoading={isUnProcessingDocuments}
          onClick={() => { handleProcessDocuments(false)}}
        >
          Mark Unprocessed
        </Button>
        <Button
          className={classNames('mr2', Classes.iconClass(IconNames.TICK))}
          isLoading={isTriggerPush}
          onClick={handleTriggerPush}
        >
          Trigger Push Selected
        </Button>
        <Button
          className={classNames('mr2', Classes.iconClass(IconNames.DELETE))}
          isLoading={isDeletingDocuments}
          onClick={handleShowDeleteDocumentsModal}
        >
          Delete Selected
        </Button>
        {DefaultBar.renderActionButtons()}
      </Fragment>
    )
  }

  if (selection.length <= 1) {
    return null
  }

  return (
    <TableActionBar
      actionButtons={renderActionButtons()}
      selection={selection}
    />
  )
}
