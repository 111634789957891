import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'

interface IErrorModalProps extends IBaseProps {
  errorTitle: string
  errorText: string
  onClose: () => void
}

export class ErrorModal extends React.Component<IErrorModalProps, any> {

  public static open(props) {
    OverlayManager.openOverlay(this, props)
  }

  public render() {
    const { errorText, errorTitle, onClose } = this.props
    return (
      <Modal
        modalDialogClassName='c-modal-dialog--sm'
        onClose={onClose}
      >
        <div className='c-modalHeader'>
          <h4 className='c-modal-title u-textCenter'>{errorTitle}</h4>
        </div>
        <div className='c-modalBody u-textCenter' data-debug-id='app-error'>
          {errorText}
        </div>
        <Footer
          isVisible={true}
          cancelButtonText='Close'
          onCancelButtonClick={onClose}
        />
      </Modal>
    )
  }
}
