import _ from 'lodash'
import Promise from 'bluebird'

import { UISchemaResolver } from './ui-schema-resolver'
import { Store } from '../models/store'

Promise.config({
  cancellation: true
})

export class ViewResolver {

  private store: Store
  private uiSchemaResolver: UISchemaResolver

  constructor(store) {
    this.store = store
    this.uiSchemaResolver = new UISchemaResolver()
  }

  public resolveById(viewId): Promise<any> {
    let isCanceled = false
    return new Promise((resolve, reject, onCancel) => {
      onCancel = () => isCanceled = true
      this.resolveView(viewId).then((result) => {
        if (!isCanceled) { resolve(result) }
      }).catch((error) => {
        if (!isCanceled) { reject(error) }
      })
    })
  }

  private resolveView(viewId) {
    // TODO: refactor to unify Api/apis (mobile/web)
    // TODO: add params to pass a cache policy through to the store. That way,
    // view resolver can stay agnostic (instead of specifying getOrFetchRecord,
    // which is "cache or network") and let platforms decide cache behavior.

    return this.store.fetchOrGetRecord(viewId).then((view) => {
      const promises = _.map(view.view.extends, (edge: any) => {
        return this.resolveView(edge.entityId)
      })
      return Promise.all(promises).then((resolvedViews) => {
        resolvedViews.push({
          view,
          index: _.cloneDeep(view.view.index),
          props: _.cloneDeep(view.view.props),
          uiSchema: _.cloneDeep(view.view.uiSchema),
          translationTable: _.cloneDeep(view.translationTable),
        })
        const result: any = _.first(resolvedViews)
        result.view = view
        for (let i = 1; i < resolvedViews.length; ++i) {
          result.index = resolvedViews[i].index || result.index
          result.props = resolvedViews[i].props || result.props
          const parentUiSchema = result.uiSchema
          const childUiSchema = resolvedViews[i].uiSchema
          if (!parentUiSchema) {
            result.uiSchema = childUiSchema
          } else if (childUiSchema) {
            result.uiSchema = this.uiSchemaResolver.applyPatch(parentUiSchema, childUiSchema)
          }
          const parentTranslations = result.translationTable
          const childTranslations = resolvedViews[i].translationTable
          if (!parentTranslations) {
            result.translationTable = childTranslations
          } else if (childTranslations) {
            result.translationTable = _.merge({}, parentTranslations, childTranslations)
          }
        }
        return result
      })
    })
  }
}
