import Promise from 'bluebird'
import _ from 'lodash'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { EntityFooter } from 'browser/components/atomic-elements/atoms/footer/entity-footer'
import { Sheet } from 'browser/components/atomic-elements/atoms/sheet'
import { EntityFormBlock } from './entity-form-block'
import { AJVSchemaValidator } from 'shared-libs/components/entity/ajv-validator'

interface IEntityFormSheetProps extends IBaseProps {
  defaultValue?: object
  entitySchema: object
  maxHeight?: number
  onCreate: (entity: any) => void
  size?: string
  uiSchemaPath?: string
}

interface IEntityFormSheetState {
  errors: object
  entity: Entity
  isSaving: boolean
}

export class EntityFormSheet extends React.Component<IEntityFormSheetProps, IEntityFormSheetState> {
  public static defaultProps: Partial<IEntityFormSheetProps> = {
    size: 'sm',
    uiSchemaPath: 'uiSchema.web.entityCreationSheet'
  }

  private sheet: Sheet

  constructor(props) {
    super(props)
    const { defaultValue, entitySchema } = props
    const entityStore = apis.getStore()
    const entity = entityStore.createRecord(entitySchema, defaultValue)
    this.state = {
      entity,
      errors: {},
      isSaving: false,
    }
  }

  public render() {
    const { maxHeight, size, uiSchemaPath } = this.props
    const { entity, errors } = this.state
    const handleRef = (ref) => this.sheet = ref
    const settings = apis.getSettings()

    return (
      <Sheet
        footer={this.renderSheetFooter()}
        maxHeight={maxHeight}
        ref={handleRef}
        size={size}
      >
        <EntityFormBlock
          entity={entity}
          errors={errors}
          onSave={this.handleSave}
          uiSchemaPath={uiSchemaPath}
          settings={settings}
        />
      </Sheet>
    )
  }

  private runValidations = async (entity: Entity, customValidations: any) => {
    const customValidator = customValidations
      ? await AJVSchemaValidator.compile(customValidations)
      : null

    const errors = await entity.validate(true, customValidator)

    if (_.isEmpty(errors)) {
      return
    }

    this.setState({
      errors
    })

    throw new Error("Failed to validate entity before save")
  }

  private handleSave = (entity): Promise<any> => {
    const { onCreate, uiSchemaPath } = this.props

    const validationSchema = entity.resolveSubschemaByPath(uiSchemaPath)?.schema?.validationSchema
    const validationPromise = new Promise((resolve, reject) => {
      if (validationSchema) {
        this.runValidations(entity, validationSchema)
          .then(() => resolve(entity))
          .catch(reject)
      } else {
        resolve(entity)
      }
    })

    return validationPromise.then((errors: any) => {
      this.setState({ isSaving: true })
      return entity.save().then(() => {
        onCreate(entity)
        this.sheet.close()
      })
    })
  }

  private renderSheetFooter() {
    const { entity, errors, isSaving } = this.state
    const onConfirm = () => this.handleSave(entity)
    const onCancel = () => this.sheet.close()
    return (
      <EntityFooter
        entity={entity}
        errors={errors}
        isPrimaryButtonLoading={isSaving}
        isVisible={true}
        onCancelButtonClick={onCancel}
        onPrimaryButtonClick={onConfirm}
      />
    )
  }
}
