// NOTE: this is temporary, we will find a place for this
export default [
  {
    code: 'US',
    continent: 'North America',
    name: 'United States',
    postalCodeLabel: 'ZIP',
    regionLabel: 'State',
    regions: [
      {
        code: 'AL',
        name: 'Alabama',
        subdivision: 'state',
      },
      {
        code: 'AK',
        name: 'Alaska',
        subdivision: 'state',
      },
      {
        code: 'AZ',
        name: 'Arizona',
        subdivision: 'state',
      },
      {
        code: 'AR',
        name: 'Arkansas',
        subdivision: 'state',
      },
      {
        code: 'CA',
        name: 'California',
        subdivision: 'state',
      },
      {
        code: 'CO',
        name: 'Colorado',
        subdivision: 'state',
      },
      {
        code: 'CT',
        name: 'Connecticut',
        subdivision: 'state',
      },
      {
        code: 'DE',
        name: 'Delaware',
        subdivision: 'state',
      },
      {
        code: 'FL',
        name: 'Florida',
        subdivision: 'state',
      },
      {
        code: 'GA',
        name: 'Georgia',
        subdivision: 'state',
      },
      {
        code: 'HI',
        name: 'Hawaii',
        subdivision: 'state',
      },
      {
        code: 'ID',
        name: 'Idaho',
        subdivision: 'state',
      },
      {
        code: 'IL',
        name: 'Illinois',
        subdivision: 'state',
      },
      {
        code: 'IN',
        name: 'Indiana',
        subdivision: 'state',
      },
      {
        code: 'IA',
        name: 'Iowa',
        subdivision: 'state',
      },
      {
        code: 'KS',
        name: 'Kansas',
        subdivision: 'state',
      },
      {
        code: 'KY',
        name: 'Kentucky',
        subdivision: 'state',
      },
      {
        code: 'LA',
        name: 'Louisiana',
        subdivision: 'state',
      },
      {
        code: 'ME',
        name: 'Maine',
        subdivision: 'state',
      },
      {
        code: 'MD',
        name: 'Maryland',
        subdivision: 'state',
      },
      {
        code: 'MA',
        name: 'Massachusetts',
        subdivision: 'state',
      },
      {
        code: 'MI',
        name: 'Michigan',
        subdivision: 'state',
      },
      {
        code: 'MN',
        name: 'Minnesota',
        subdivision: 'state',
      },
      {
        code: 'MS',
        name: 'Mississippi',
        subdivision: 'state',
      },
      {
        code: 'MO',
        name: 'Missouri',
        subdivision: 'state',
      },
      {
        code: 'MT',
        name: 'Montana',
        subdivision: 'state',
      },
      {
        code: 'NE',
        name: 'Nebraska',
        subdivision: 'state',
      },
      {
        code: 'NV',
        name: 'Nevada',
        subdivision: 'state',
      },
      {
        code: 'NH',
        name: 'New Hampshire',
        subdivision: 'state',
      },
      {
        code: 'NJ',
        name: 'New Jersey',
        subdivision: 'state',
      },
      {
        code: 'NM',
        name: 'New Mexico',
        subdivision: 'state',
      },
      {
        code: 'NY',
        name: 'New York',
        subdivision: 'state',
      },
      {
        code: 'NC',
        name: 'North Carolina',
        subdivision: 'state',
      },
      {
        code: 'ND',
        name: 'North Dakota',
        subdivision: 'state',
      },
      {
        code: 'OH',
        name: 'Ohio',
        subdivision: 'state',
      },
      {
        code: 'OK',
        name: 'Oklahoma',
        subdivision: 'state',
      },
      {
        code: 'OR',
        name: 'Oregon',
        subdivision: 'state',
      },
      {
        code: 'PA',
        name: 'Pennsylvania',
        subdivision: 'state',
      },
      {
        code: 'RI',
        name: 'Rhode Island',
        subdivision: 'state',
      },
      {
        code: 'SC',
        name: 'South Carolina',
        subdivision: 'state',
      },
      {
        code: 'SD',
        name: 'South Dakota',
        subdivision: 'state',
      },
      {
        code: 'TN',
        name: 'Tennessee',
        subdivision: 'state',
      },
      {
        code: 'TX',
        name: 'Texas',
        subdivision: 'state',
      },
      {
        code: 'UT',
        name: 'Utah',
        subdivision: 'state',
      },
      {
        code: 'VT',
        name: 'Vermont',
        subdivision: 'state',
      },
      {
        code: 'VA',
        name: 'Virginia',
        subdivision: 'state',
      },
      {
        code: 'WA',
        name: 'Washington',
        subdivision: 'state',
      },
      {
        code: 'WV',
        name: 'West Virginia',
        subdivision: 'state',
      },
      {
        code: 'WI',
        name: 'Wisconsin',
        subdivision: 'state',
      },
      {
        code: 'WY',
        name: 'Wyoming',
        subdivision: 'state',
      },
    ],
  },
  {
    code: 'CA',
    continent: 'North America',
    name: 'Canada',
    postalCodeLabel: 'Postal Code',
    regionLabel: 'Province',
    regions: [
      {
        code: 'AB',
        name: 'Alberta',
        subdivision: 'province',
      },
      {
        code: 'BC',
        name: 'British Columbia',
        subdivision: 'province',
      },
      {
        code: 'MB',
        name: 'Manitoba',
        subdivision: 'province',
      },
      {
        code: 'NB',
        name: 'New Brunswick',
        subdivision: 'province',
      },
      {
        code: 'NL',
        name: 'Newfoundland and Labrador',
        subdivision: 'province',
      },
      {
        code: 'NS',
        name: 'Nova Scotia',
        subdivision: 'province',
      },
      {
        code: 'ON',
        name: 'Ontario',
        subdivision: 'province',
      },
      {
        code: 'PE',
        name: 'Prince Edward Island',
        subdivision: 'province',
      },
      {
        code: 'QC',
        name: 'Quebec',
        subdivision: 'province',
      },
      {
        code: 'SK',
        name: 'Saskatchewan',
        subdivision: 'province',
      },
      {
        code: 'NT',
        name: 'Northwest Territories',
        subdivision: 'territory',
      },
      {
        code: 'NU',
        name: 'Nunavut',
        subdivision: 'territory',
      },
      {
        code: 'YT',
        name: 'Yukon',
        subdivision: 'territory',
      },
    ],
  },
]
