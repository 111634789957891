import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { ImageEditorModal } from '../../organisms/image-editor-modal'
import { Entity } from 'shared-libs/models/entity'
import { IImageLoaderProps, ImageLoader } from '../image-loader/image-loader'
import { IImageEditorCarouselProps } from '../../organisms/image-editor-carousel/image-editor-carousel'

/**
 * @uiComponent
 */
interface IAttachmentViewerProps extends IBaseProps {
  entity?: Entity
  value: any
  frames?: any
  thumbnailProps?: Partial<IImageLoaderProps>
  carouselProps?: Partial<IImageEditorCarouselProps>
}

export class AttachmentViewer extends React.PureComponent<IAttachmentViewerProps> {
  constructor(props) {
    super(props)
  }

  public render() {
    const { thumbnailProps } = this.props
    const images = this.getImages()
    const uri = images?.[0]?.uri
    if (!uri) {
      return null
    }
    return (
      <ImageLoader
        {...thumbnailProps}
        src={uri}
        showLoadingSpinner={true}
        onClick={this.handleThumbnailClick}
      />
    )
  }

  private handleThumbnailClick = () => {
    const { entity, frames, carouselProps } = this.props

    const schema = frames.getContext('dataSchema')
    const imagesPath = frames.getContext('valuePath').join('.')

    ImageEditorModal.open({
      ...carouselProps,
      entity,
      schema,
      imagesPath,
      onClose: () => this.forceUpdate(),
    })
  }

  public getImages() {
    const images: any[] = []
    const files = _.get(this.props.value, 'files', [])
    _.forEach(files, (file) => {
      if (file.type === 'image') {
        images.push(file)
      } else if (file.type === 'pdf') {
        Array.prototype.push.apply(images, file.preview)
      }
    })
    return images
  }
}
