import React, { Component } from 'react'
import styled from 'styled-components'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

const Wrapper = styled.div`
  padding: 0.25rem 0.5rem;
  border: 1px dashed #f89939;
  font-weight: bold;
  cursor: grab;
  border-radius: 3px;
  background-color: #FFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface IDragBoxProps extends IBaseProps {
  label: string
}

export class DragBox extends Component<IDragBoxProps> {
  constructor(props) {
    super(props)
  }

  public render() {
    const { style } = this.props
    return (
      <Wrapper style={style}>
        {this.props.label}
      </Wrapper>
    )
  }
}
