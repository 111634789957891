import classNames from 'classnames'
import { Cell } from 'fixed-data-table-2'
import React from 'react'

interface ITableHeaderColumnCellProps {
  column: any
  onClick: (column: any) => void
}

export const TableHeaderColumnCell: React.FC<ITableHeaderColumnCellProps> = (props) => {
  const { children, column, onClick } = props
  const handleClick = () => {
    if (column.isSortable && onClick) {
      onClick(column)
    }
  }

  return (
    <Cell
      className={classNames('c-columnHeader', {
        'c-columnHeader--isSortable': column.isSortable,
      })}
      onClick={handleClick}
    >
      <div className='flex'>
        {children}
      </div>
    </Cell>
  )
}
