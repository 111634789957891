import _ from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import { DayPicker } from 'react-dates'
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/date-picker/_date-picker.scss'
import { isSameDay } from './date-utils'

export interface IDatePickerProps extends IBaseProps {
  daySize?: number
  defaultHours?: number
  defaultMinutes?: number
  defaultTimezone?: string
  onChange: (value: string) => void
  value: string
}

interface IDatePickerState {
  date: object
  hoverDate: object
  hours: number
  minutes: number
}

export class DatePicker extends React.Component<IDatePickerProps, IDatePickerState> {
  public static defaultProps: Partial<IDatePickerProps> = {
    daySize: 30,
    defaultHours: 0,
    defaultMinutes: 0,
    defaultTimezone: moment.tz.guess(),
    onChange: _.noop,
  }

  public today: object

  constructor(props) {
    super(props)

    const { defaultTimezone } = this.props

    this.today = moment.tz(defaultTimezone)
    this.state = {
      ...this.getNextStateFromProps(props),
      hoverDate: null,
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.value, nextProps.value)) {
      this.setState(this.getNextStateFromProps(nextProps))
    }
  }

  public render() {
    const { daySize } = this.props

    const modifiers = {
      // before anything has been set or after both are set
      hovered: (day) => this.isHovered(day),
      // while start date has been set, but end date has not been
      selected: (day) => this.isSelected(day),
      today: (day) => this.isToday(day),
    }
    return (
      <DayPicker
        enableOutsideDays={false}
        navPrev={<Icon icon={IconNames.CHEVRON_LEFT} />}
        navNext={<Icon icon={IconNames.CHEVRON_RIGHT} />}
        modifiers={modifiers}
        numberOfMonths={1}
        daySize={daySize}
        onDayMouseEnter={this.handleDayMouseEnter}
        onDayMouseLeave={this.handleDayMouseLeave}
        onDayClick={this.handleDayClick}
        initialVisibleMonth={() => this.today}
      />
    )
  }

  private getNextStateFromProps(props): any {
    const { value, defaultTimezone } = props
    const defaultState = {
      date: null,
      hours: props.defaultHours,
      minutes: props.defaultMinutes,
    }
    if (_.isEmpty(value)) {
      return defaultState
    }
    const date = moment(value, moment.ISO_8601, true).tz(defaultTimezone)
    if (!date.isValid()) {
      return defaultState
    }
    const hours = date.hours()
    const minutes = date.minutes()
    return { date, hours, minutes }
  }

  private handleDayClick = (day, modifiers, e) => {
    if (e) {
      e.preventDefault()
    }
    const { hours, minutes } = this.state
    day.hours(hours)
    day.minutes(minutes)
    this.props.onChange(day.toISOString())
  }

  private handleDayMouseEnter = (day) => {
    this.setState({
      hoverDate: day,
    })
  }

  private handleDayMouseLeave = () => {
    this.setState({
      hoverDate: null,
    })
  }

  private isHovered(day) {
    return isSameDay(day, this.state.hoverDate)
  }

  private isToday(day) {
    return isSameDay(day, this.today)
  }

  private isSelected(day) {
    const { date } = this.state
    return isSameDay(day, date)
  }
}
