import _ from 'lodash'
import numeral from 'numeral'

export function formatNumber(value) {
  if (_.isNil(value) || value === 'NaN' || value === 'Infinity' || value === '-Infinity') {
    return '–'
  }
  return parseFloat(value).toFixed(2)
}

export function formatMoney(value) {
  if (_.isNil(value) || value === 'NaN' || value === 'Infinity' || value === '-Infinity') {
    return '–'
  }
  return numeral(value).format('0,0.00')
}

export function formatPercent(value) {
  if (_.isNil(value) || value === 'NaN' || value === 'Infinity' || value === '-Infinity') {
    return '–'
  }
  value = parseFloat(value) * 100
  return `${value.toFixed(2)}%`
}

// **************************************************
// GROUP component of /entity-* routes
// **************************************************

export function groupByLane() {
  return {
    label: 'Lane',
    lanePointBucketType: 'localityRegion',
    path: 'precomputation.lane',
    type: 'lane',
  }
}

export function groupByLoad() {
  return {
    label: 'Load',
    path: '',
    properties: [ 'dispatchOrder.identifier' ],
    type: 'entity',
  }
}

export function groupByCarrier() {
  return {
    label: 'Carrier',
    path: 'brokerOrder.carrier',
    type: 'edge',
  }
}

export function groupByCustomer() {
  return {
    label: 'Customer',
    path: 'brokerOrder.customer',
    type: 'edge',
  }
}

export function groupByTrailerType() {
  return {
    label: 'Trailer Type',
    path: 'dispatchOrder.trailerType',
    type: 'field',
  }
}

export function groupByDateHistogram(interval) {
  return {
    interval: `${interval}`,
    path: 'precomputation.lane.origin.timeWindow.start.time', // TODO could also use destination
    type: 'dateHistogram',
  }
}

// **************************************************
// FILTER component of /entity-* routes
// **************************************************

export function filterLoad() {
  return {
    isVisible: false,
    path: 'mixins.active',
    type: 'containsEdge',
    value: {
      displayName: 'Broker Order',
      entityId: '11111111-0000-0000-0000-000000000209',
    },
  }
}

export function filterLoadStatus(loadStatus) {
  return {
    label: 'Load Status',
    path: 'dispatchOrder.status.primary',
    type: 'match',
    values: [`${loadStatus}`],
  }
}

export function filterDateRange(startDate, endDate) {
  return {
    gte: startDate,
    label: 'Origin date',
    lte: endDate,
    path: 'precomputation.lane.origin.timeWindow.start.time',
    type: 'range',
  }
}

export function filterLane(laneIdentifier) {
  return {
    path: 'precomputation.lane',
    type: 'matchLane',
    value: {
      identifier: `${laneIdentifier}`,
    },
  }
}

export function filterCarrier(carrierEntityId) {
  return {
    path: 'brokerOrder.carrier',
    type: 'matchEdge',
    value: {
      entityId: `${carrierEntityId}`,
    },
  }
}

export function filterCustomer(customerEntityId) {
  return {
    path: 'brokerOrder.customer',
    type: 'matchEdge',
    value: {
      entityId: `${customerEntityId}`,
    },
  }
}
