import _ from 'lodash'
import { useContext, useEffect } from 'react'
import { LocalStorageContext } from 'browser/contexts/local-storage/local-storage-context'

export function useLocalStorage<T>(key: string, defaultValue: T): any[] {
  const { onChange, storedValues } = useContext(LocalStorageContext)

  useEffect(() => {
    if (!storedValues.hasOwnProperty(key)) {
      onChange(key, defaultValue)
    }
  }, [])
  
  const setValue = (newValue: T) => {
    onChange(key, newValue)
  }
  
  const value = storedValues.hasOwnProperty(key) ? storedValues[key]: defaultValue
  
  return [value, setValue]
}
