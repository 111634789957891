import _ from 'lodash'
import { CustomFormulas } from './formulas'
import { FramesManager } from '../components/view/frames-manager'
import * as esprima from 'esprima-next'
import { LRUCache } from '../models/cache'
import escodegen from 'escodegen'

interface FunctionCacheEntry {
  /** JS AST derived from the formula code string. */
  ast: esprima.Program
  /** A function compiled from the formula code string and arg list. */
  fn: Function
}

const FUNCTION_CACHE_CAPACITY = 100
const fnCache = new LRUCache<string, FunctionCacheEntry>(FUNCTION_CACHE_CAPACITY)

interface IFilterExpressionValue {
  type: string
  value: string
}

type IFilterValue = string | number | IFilterExpressionValue

interface IFilter {
  value?: IFilterValue
  values?: IFilterValue[] | IFilterExpressionValue
  condition?: IFilterExpressionValue
  [key: string]: any
}

export type ValueGetter = (key: string) => any
type SymbolMap = object

type ParseResult =
  | { ast: esprima.Program }
  | { error: Error }

// restricted set of syntax, e.g. no variable assignment or function bodies w/
// returns on simple formulas.
const vegaAllowedSyntax = new Set<esprima.Syntax>([
  esprima.Syntax.Program,
  esprima.Syntax.ExpressionStatement,
  esprima.Syntax.ArrayExpression,
  esprima.Syntax.BinaryExpression,
  esprima.Syntax.CallExpression,
  esprima.Syntax.ConditionalExpression,
  esprima.Syntax.Identifier,
  esprima.Syntax.Literal,
  esprima.Syntax.LogicalExpression,
  esprima.Syntax.MemberExpression,
  esprima.Syntax.ObjectExpression,
  esprima.Syntax.Property,
  esprima.Syntax.UnaryExpression,

  // additional features which vega-expression does not implement
  esprima.Syntax.ChainExpression,
  esprima.Syntax.ArrowFunctionExpression,
])
const astCache = new Map<string, ParseResult>()

interface ASTParser {
  parse(code: string): any
  sanitize(code: string): string
}

const DefaultParser: ASTParser = {
  parse: () => ({}),
  sanitize: (code) => code
}

export const VegaParser: ASTParser = {
  parse: (code: string) => {
    const cached = astCache.get(code)
    if (!_.isNil(cached)) {
      if ("ast" in cached) {
        return cached.ast
      } else {
        throw new Error(cached.error.message)
      }
    }

    // parse
    let ast: esprima.Program
    try {
      ast = esprima.parse(code)
      astCache.set(code, { ast })
    } catch (error) {
      astCache.set(code, { error })
      throw error
    }

    // validate allowed syntax (see https://github.com/vega/vega-expression/blob/master/src/ast.js)
    const walk = (node: esprima.Node): string | undefined => {
      if (_.isEmpty(node)) {
        return
      }
      if (node.type && !vegaAllowedSyntax.has(node.type)) {
        return `Illegal syntax: ${node.type}`
      }
      for (const key in node) {
        if (_.isObject(node[key])) {
          const result = walk(node[key])
          if (result) {
            return result
          }
        }
      }
    }
    const validationError = walk(ast)
    if (!validationError) {
      astCache.set(code, { ast })
      return ast
    } else {
      const error = new Error(validationError)
      astCache.set(code, { error })
      throw error
    }
  },
  sanitize: (code) => code
}

export const EsprimaParser: ASTParser = {
  parse: (code: string) => {
    const cached = astCache.get(code)
    if (!_.isNil(cached)) {
      if ("ast" in cached) {
        return cached.ast
      } else {
        throw new Error(cached.error.message)
      }
    }

    // parse
    try {
      const ast = esprima.parse(code)
      astCache.set(code, { ast })
      return ast
    } catch (error) {
      astCache.set(code, { error })
      throw error
    }
  },
  // wrap in a function so code with multiple statements can be parsed
  sanitize: (code) => `(()=>{ ${code} })()`,
}

export class ExpressionEvaluator {
  private astParser = DefaultParser
  private valueGetter: ValueGetter
  private context: SymbolMap
  private errorLogger: (error) => void
  private throwError = false
  private readonly defaultValueGetter: ValueGetter = (key) => {
    return this.context ? this.context[key] : undefined
  }

  private constructor() {}

  public static create() {
    return new ExpressionEvaluator().setErrorLogger((err) => {
      console.groupCollapsed(`Unable to evaluate: ${err?.message}`)
      console.warn(`code: ${err?.invalidCode}`)
      console.warn(err)
      console.groupEnd()
    }) // set default err logger
  }

  public setASTParser(astParser: ASTParser) {
    this.astParser = astParser
    return this
  }

  public setValueGetter(valueGetter: ValueGetter) {
    this.valueGetter = valueGetter
    return this
  }

  /**
   * Sets the default context for the default value getter to search.
   */
  public setDefaultContext(context: SymbolMap) {
    this.context = context
    return this
  }

  public setErrorLogger(logger) {
    this.errorLogger = logger
    return this
  }

  public setThrowError(throwError) {
    this.throwError = !!throwError
    return this
  }

  private extractArgs(ast: any): SymbolMap {
    const valueGetter = this.valueGetter ? this.valueGetter : this.defaultValueGetter
    const args = {}
    extractVariablesFromAST(ast, valueGetter, args)
    return args
  }

  private sanitize(code: string): string {
    // remove newlines
    code = code.replace(/\n|\r/g, '')

    // remove surrounding whitespace
    code = _.trim(code)

    // wrap BlockExpression in parens, as the implementor is likely wanting to
    // return an ExpressionObject (as if it were JSON). Top-level
    // BlockStatements are not supported.
    if (code.charAt(0) === '{') {
      code = `(${code})`
    }

    // normalize empty formula to `undefined` to avoid unnecessary log when the
    // AST parser throws 'unexpected end of input'.
    if (_.isEmpty(code)) {
      code = 'undefined'
    }

    return code
  }

  /**
   * Modify the formula string to add a return statement in the appropriate
   * location. The resulting string is what's eval'd.
   *
   * When using Esprima, this means simplifying the result of `sanitize` to
   * better handle single expression statements, removing the curly braces.
   *
   *     () => { 5 };
   *     () => 5;
   *
   * Or when there are multiple statements, support implicit return of the final
   * expression statement.
   *
   *     () => { const a = 5; a; };
   *     () => { const a = 5; return a; };
   *
   * In all cases, we prefix the final code (whether it's an IIFE or not) with
   * `return `.
   *
   *     5;
   *     return 5;
   *
   *     (() => { return 5; })()
   *     return (() => { return 5; })()
   */
  private getFunctionBody(code: string, ast: esprima.Program): string {
    if (
      ast.type === 'Program' &&
      _.size(ast.body) === 1 &&
      ast.body[0].type === esprima.Syntax.ExpressionStatement &&
      ast.body[0].expression?.type === esprima.Syntax.CallExpression &&
      ast.body[0].expression.callee.type === esprima.Syntax.ArrowFunctionExpression &&
      ast.body[0].expression.callee.body.type === esprima.Syntax.BlockStatement
    ) {
      const statements = ast.body[0].expression.callee.body.body
      const lastStatement = statements.length > 0 ? statements[statements.length - 1] : undefined

      if (!_.isEmpty(statements) && lastStatement.type === esprima.Syntax.ExpressionStatement) {
        // replace the final expression statement with a return statement by
        // mutating the AST and regenerating the code.
        statements[statements.length - 1] = new esprima.ReturnStatement(lastStatement.expression)
      }
      code = escodegen.generate(ast, { format: { compact: true } } )
    }
    return `return ${code}`
  }

  /**
   * Get a JS string from a formula code string.
   */
  public getProgramString(code: string) {
    code = this.sanitize(code)
    code = this.astParser.sanitize(code)
    try {
      const ast = this.astParser.parse(code)
      return this.getFunctionBody(code, ast)
    } catch (e) {
      return undefined
    }
  }

  /**
   * Evaluates a program, parsing it for symbols unless an arg map is provided.
   * @param code Code string to evaluate.
   * @param args Argument name-value map to using during evaluation. Skips AST parsing.
   */
  public evaluate(code: string, args?: SymbolMap) {
    try {
      const argKeys = args ? `\nargs: ${Object.keys(args)}` : ''
      const cacheKey = code + argKeys
      const cacheEntry = fnCache.get(cacheKey)

      if (cacheEntry) {
        args = args || this.extractArgs(cacheEntry.ast)
        const argValues = Object.keys(args).map((key) => args[key])
        const fn = cacheEntry.fn
        return fn(...argValues)
      } else {
        // we need to parse the AST and create the Function
        // TODO: might be nice to streamline and clarify this sequence of
        // modifications to use esprima to wrap the code in an IIFE as needed by
        // modifying the AST and regenerating the code from that, instead of
        // having a mixture of string operations (sanitize) and AST
        // modifications (getFunctionBody).
        // We could clarify to a parse -> normalize -> generate sequence.
        code = this.sanitize(code)
        code = this.astParser.sanitize(code)
        const ast = this.astParser.parse(code)
        const program = this.getFunctionBody(code, ast)

        args = args || this.extractArgs(ast)
        const argValues = Object.keys(args).map((key) => args[key])
        const argNames = Object.keys(args)
        const fn = Function(...argNames, program)
        fnCache.set(cacheKey, { ast, fn })
        return fn(...argValues)
      }
    } catch (e) {
      if (this.errorLogger) {
        e.invalidCode = code
        this.errorLogger(e)
      }
      if (this.throwError) {
        throw e
      }
      return undefined
    }
  }

  // permissive syntax check (used by json/formula validator)
  public compile(code: string): Function {
    code = this.sanitize(code)

    const program = EsprimaParser.sanitize(code)
    const ast = EsprimaParser.parse(program)
    code = this.getFunctionBody(program, ast)

    return Function(code)
  }
}

function extractVariablesFromAST(node, valueGetter, variables) {
  if (!node) {
    return
  }
  if (node.type === 'Identifier') {
    variables[node.name] = valueGetter(node.name)
  }
  _.forEach(getASTNodeChildren(node), (child) => {
    extractVariablesFromAST(child, valueGetter, variables)
  })
}

function getASTNodeChildren(node) {
  switch (node.type) {
    case esprima.Syntax.Program:
      return [...node.body]
    case esprima.Syntax.ExpressionStatement:
    case esprima.Syntax.ChainExpression:
      return [node.expression]
    case esprima.Syntax.ArrowFunctionExpression:
      return [node.body]
    case esprima.Syntax.BlockStatement:
      return [...node.body]
    case esprima.Syntax.VariableDeclaration:
      return [...node.declarations]
    case esprima.Syntax.VariableDeclarator:
      return [node.init]
    case esprima.Syntax.ArrayExpression:
      return node.elements
    case esprima.Syntax.AssignmentExpression:
      return [node.right]
    case esprima.Syntax.BinaryExpression:
    case esprima.Syntax.LogicalExpression:
      return [node.left, node.right]
    case esprima.Syntax.CallExpression:
      return [node.callee, ...node.arguments]
    case esprima.Syntax.IfStatement:
      return [node.test]
    case esprima.Syntax.ConditionalExpression:
      return [node.test, node.consequent, node.alternate]
    case esprima.Syntax.MemberExpression:
      return [node.object, node.property]
    case esprima.Syntax.ObjectExpression:
      return node.properties
    case esprima.Syntax.Property:
      return [node.key, node.value]
    case esprima.Syntax.ReturnStatement:
    case esprima.Syntax.UnaryExpression:
      return [node.argument]
    case esprima.Syntax.Identifier:
    case esprima.Syntax.Literal:
    case 'RawCode':
    default:
      return []
  }
}

// Convenience functions for existing code

export function evaluateExpression(context: SymbolMap, code: string) {
  return ExpressionEvaluator.create().evaluate(code, context)
}

export function evaluateExpressionWithValueGetter(
  code: string,
  valueGetter: ValueGetter,
  parser: ASTParser = EsprimaParser
) {
  return ExpressionEvaluator.create()
    .setASTParser(parser)
    .setValueGetter(valueGetter)
    .evaluate(code)
}

export function evaluateExpressionWithScopes(
  frames: FramesManager,
  code: string,
  defaultContext: any = {},
  parser: ASTParser = EsprimaParser
) {
  const preprocessed = preprocess(code)
  const valueGetter: ValueGetter = (key) => {
    const value = frames.getValue(key)
    return _.isNil(value) ? defaultContext[key] : value
  }
  return evaluateExpressionWithValueGetter(preprocessed, valueGetter, parser)
}

export function preprocess(code: string): string {
  const preprocessSpecifications = [
    // check if code has a special marker, '_computedName', since this is not through a proxy, we need
    // to pre-append with 'content' to access it
    { pattern: '_computedName', replacement: 'content._computedName' },
    // prepend the translation table to calls to TRANSLATE
    { pattern: 'TRANSLATE(', replacement: 'TRANSLATE(translationTable,' },
  ]

  return _.reduce(
    preprocessSpecifications,
    (code, spec) => _.replace(code, spec.pattern, spec.replacement),
    code
  )
}

// Helpers

/**
 * Evaluates an expression that contains a variable named "data".
 *
 * Currently used for evaluating select options' formulas.
 *
 * @param data Arbitrary JSON data.
 * @param code An expression to evaluate.
 * @param frames Context to assist with hydrating symbols during evaluation.
 */
export function evaluateData(data: any, code: string, frames: FramesManager): any {
  if (!code || !frames) return data

  const scopes = {
    ...CustomFormulas,
    data,
  }
  return evaluateExpressionWithScopes(frames, code, scopes)
}

function evaluate(value: IFilterValue, context): IFilterValue | IFilterValue[] {
  if (!_.isObject(value) || (value as IFilterExpressionValue).type !== 'expression') {
    return value
  }
  value = value as IFilterExpressionValue
  return ExpressionEvaluator.create()
    .setASTParser(EsprimaParser)
    .setDefaultContext(context)
    .evaluate(value.value)
}

function evaluateFilter(filter: IFilter, context: any): IFilter {
  const secondaryEntries = _.omit(filter, 'value', 'values', 'condition')
  const evaluatedFilter = _.mapValues(secondaryEntries, (val) => evaluate(val, context))

  // shallow clone the filter and evaluate the single value
  const updatedFilter: IFilter = {
    ...evaluatedFilter,
    value: evaluate(filter.value, context) as IFilterValue,
  }

  if (filter.values) {
    if ((filter.values as IFilterExpressionValue).type === 'expression') {
      // if values is an expression object, replace it with the result of the expression
      updatedFilter.values = evaluate(
        filter.values as IFilterExpressionValue,
        context
      ) as IFilterValue[]
    } else {
      updatedFilter.values = _.map(filter.values as IFilterValue[], (val) =>
        evaluate(val, context)
      ) as IFilterValue[]
    }
  }

  return updatedFilter
}

/**
 * Evaluate any query filters whose `value` is an 'expression' object when it is
 * the 'value' or in the 'values' of a view schema's filters.
 */
export function evaluateFilters(filters: IFilter[], context: any): IFilter[] {
  if (!filters) {
    return
  }

  const optionalFilters = filters.filter((filter) =>
    _.isEmpty(filter.condition) || evaluate(filter.condition, context))

  return optionalFilters.map((filter) =>
    evaluateFilter(filter, context))
}
