import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import React from 'react'
import { FramesManager } from 'shared-libs/components/view/frames-manager'
import { translateString } from 'shared-libs/helpers/utils'

const DEFAULT_TAG = 'p'
const TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']

/**
 * @uiComponent
 */
interface ITextProps extends IBaseProps {
  frames?: FramesManager
  textType?: string
  text?: string
}

export const Text = (props: ITextProps) => {
  const { frames, style, className, children, textType, text } = props
  const tag = TAGS.indexOf(textType) >= 0 ? textType : DEFAULT_TAG
  const translationTable = frames?.getContext('translationTable')
  const translatedText = translateString(text, translationTable)
  return React.createElement(
    tag,
    {
      style,
      className,
    },
    translatedText || children
  )
}
