import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/form-group/_form-group.scss'
import { View } from 'browser/components/atomic-elements/atoms/view'

interface IFormGroupProps extends IBaseProps {
  tagName?: any
  isEditableInline?: boolean
  isFocused?: boolean
  isDisabled?: boolean
  isHorizontalLayout?: boolean
  isLabelEditable?: boolean
  hasError?: boolean
  hasValue?: boolean
  size?: string
}

export class FormGroup extends React.Component<IFormGroupProps, any> {

  public static defaultProps: Partial<IFormGroupProps> = {
    tagName: 'div',
  }

  public render() {
    const {
      tagName,
      children,
      size,
      className,
      isEditableInline,
      isDisabled,
      isFocused,
      isLabelEditable,
      hasError,
      hasValue,
      isHorizontalLayout,
    } = this.props
    const sizeClass = _.isEmpty(size) ? '' : `c-formGroup--${size}`
    return (
      <View
        tagName={tagName}
        className={classNames('c-formGroup', sizeClass, className, {
          'c-formGroup--hasError': hasError,
          'c-formGroup--hasNoValue': !hasValue,
          'c-formGroup--isDisabled': isDisabled,
          'c-formGroup--isEditableInline': isEditableInline,
          'c-formGroup--isFocused': isFocused,
          'c-formGroup--isHorizontal': isHorizontalLayout,
          'c-formGroup--isLabelEditable': isLabelEditable,
        })}
        data-debug-id={this.props['data-debug-id']}
      >
        {children}
      </View>
    )
  }
}
