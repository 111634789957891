import React, { useContext } from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { renderAuthorHeader, renderDeviceInfoTooltipContent, renderEventTimestamp } from '../util'
import { Owner } from 'shared-libs/generated/server-types/entity'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { Position, Tooltip } from '@blueprintjs/core'

interface IBreadcrumbItemProps extends IBaseProps {
  event: any
  owner: Owner
  index: number
  isFirst: boolean
  isLast: boolean
  workflowEntity: any
}

export const BreadcrumbItem: React.FC<IBreadcrumbItemProps> = (props) => {
  const { className, event, isFirst, workflowEntity, owner } = props
  const { createdBy, creationDate, processedDate } = event
  const { settings } = useContext(AppNavigatorContext)

  if (_.isEmpty(event.outputMappings)) {
    return null
  }

  const plan = _.get(workflowEntity, 'core_storyboard_execution.storyboardPlan.core_storyboard_plan')
  const pathId = event.outputMappings[0].destination
  // unlike other feed items, breadcrumbs will fallback to at least displaying
  // the last path element for greater visibility for superadmins.
  const pathName = plan.getNameFromPathId(pathId) ?? plan.getLastPathElement(pathId)
  const breadcrumbType = event.outputMappings[0].value.isCompleted ? 'completion' : 'viewed'

  return (
    <div
      className={classNames('c-timelineEvent', className)}
      key={event.uniqueId}
    >
      <div
        className={classNames('c-timelineEvent-line', className, {
          'c-timelineEvent-line--topCutOff': isFirst,
        })}
      />
      <div className='c-timelineEvent-dot' />
      <div className='c-timelineEvent-contentContainer'>
        <div className='c-timelineEvent-header'>
          <div className='c-timelineEvent-author'>
            <Avatar
              className='c-timelineEvent-avatar'
              name={createdBy.displayName}
              size='xs'
            />
            <Tooltip
              disabled={!event.deviceInfo}
              content={renderDeviceInfoTooltipContent(event.deviceInfo)}
              position={Position.TOP}
            >
              <Label className='c-timelineEvent-authorName'>
                {renderAuthorHeader(createdBy, owner?.firm)}
              </Label>
            </Tooltip>
          </div>
          <HelpBlock className='c-timelineEvent-timestamp'>
            {renderEventTimestamp(creationDate, processedDate, settings)}
          </HelpBlock>
        </div>
        <div className='c-timelineEvent-body'>
          <p> A <strong>{breadcrumbType}</strong> breadcrumb was dropped at <strong>{pathName}</strong> </p>
        </div>
      </div>
    </div>
  )
}
