import React from 'react'

import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { jsonCloneDeep } from 'shared-libs/helpers/utils'

interface IPrintConfigurationModalProps {
  onClose?: () => void
  onConfirm: any

  title: string
  config: any
}

interface IPrintConfigurationModalState {
  config: any
}

export class PrintConfigurationModal extends React.PureComponent<
  IPrintConfigurationModalProps,
  IPrintConfigurationModalState
> {
  public static defaultProps: Partial<IPrintConfigurationModalProps> = {
    title: 'Print Table Configuration',
  }

  public static open(props) {
    return OverlayManager.openOverlayElement(
      <Modal>
        <PrintConfigurationModal {...props} />
      </Modal>
    )
  }

  public constructor(props: IPrintConfigurationModalProps) {
    super(props)

    this.state = {
      config: jsonCloneDeep(props.config),
    }
  }

  public render() {
    return (
      <div className="u-bumper">
        {this.renderHeader()}
        {this.renderOptions()}
        {this.renderFooter()}
      </div>
    )
  }

  public renderHeader() {
    const { title } = this.props

    return (
      <div className="c-modalHeader" data-debug-id="member-configuration">
        <h4 className="c-modal-title">{title}</h4>
      </div>
    )
  }

  public renderOptions() {
    const { config } = this.state

    return (
      <>
        <InputField
          isHorizontalLayout={true}
          label="Scaling"
          value={config.zoom}
          onChange={(val) => {
            config.zoom = val
          }}
        />
        <InputField
          isHorizontalLayout={true}
          label="Page Title"
          value={config.title}
          onChange={(val) => {
            config.title = val
          }}
        />
      </>
    )
  }

  public renderFooter() {
    const { onClose, onConfirm } = this.props

    return (
      <Footer className="u-bumperTop" isVisible={true}>
        <Button className="u-bumperLeft" onClick={onClose}>
          Close
        </Button>
        <Button
          className={classNames('u-bumperRight', Classes.INTENT_PRIMARY)}
          onClick={() => {
            onConfirm(this.state.config)
            onClose()
          }}
        >
          Save
        </Button>
      </Footer>
    )
  }
}
