import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import Promise from 'bluebird'
import _ from 'lodash'
import React from 'react'
import classNames from 'classnames'

import { Entity } from 'shared-libs/models/entity'

import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Select } from 'browser/components/atomic-elements/atoms/select'
import 'browser/components/atomic-elements/organisms/share-configuration-modal/_share-configuration-item.scss'
import { Formatter } from 'shared-libs/helpers/formatter'

interface IRecipientItemProps {
  onDelete: (share: Entity) => Promise<any>
  share: any
}

interface IRecipientItemState {
  isDeleting: boolean
}

// tslint:disable-next-line:max-line-length
export class RecipientItem extends React.PureComponent<IRecipientItemProps, IRecipientItemState> {

  constructor(props) {
    super(props)
    this.state = {
      isDeleting: false,
    }
  }

  public render() {
    const { share } = this.props
    const { isDeleting } = this.state
    const sharedToName = share.get('share.sharedTo.user.displayName', '')
    const sharedToEmail = share.get('share.sharedTo.email', '')
    const sharedToPhone = share.get('share.sharedTo.phoneNumber.phone', '')
    const sharedToDisplayName = this.findSharedToDisplayName(sharedToName, sharedToEmail, sharedToPhone)
    const notificationOptions = [
      { label: 'Notify on changes, comments, and shares', value: true },
      { label: 'Mute notifications', value: false },
    ]
    const accessOptions = [
      { label: 'Can edit and comment', value: true },
      { label: 'Can comment', value: false },
      { label: 'Can view', value: false },
    ]
    return (
      <div
        className='c-boxListItem-container'
        data-debug-id={`sharedWithRow:${sharedToDisplayName}`}
        key={share.uniqueId}
      >
        <div className='c-boxListItem c-boxListItem--noHover'>
          <div className='c-boxListItem-content'>
            <div className='flex'>
              <Avatar
                className='u-bumperRight--sm'
                email={sharedToEmail}
                name={sharedToDisplayName}
                size='xs'
              />
              <div className='u-flexGrow'>
                <div>
                  <span className='b'>{sharedToDisplayName}</span>{this.renderReadReceipt()}
                </div>

                {this.renderSubtitle(sharedToName, sharedToEmail, sharedToPhone)}
              </div>
            </div>
          </div>
          {this.renderAccessControls()}
          {this.renderNotificationControls()}
          <Button
            className={classNames("c-button--icon c-boxListItem-button", Classes.MINIMAL)}
            data-debug-id={`details-share-remove-${sharedToDisplayName}`}
            isLoading={isDeleting}
            onClick={this.handleRemoveFollower}
          >
            <Icon
              icon={IconNames.CROSS}
            />
          </Button>
        </div>
      </div>
    )
  }

  private renderReadReceipt() {
    const readReceiptEnabled = false
    if (readReceiptEnabled) {
      return (
        <span>&middot; Viewed 2 days ago</span>
      )
    }
  }

  private renderSubtitle(sharedToName, sharedToEmail, sharedToPhone) {
    const subtitle = sharedToEmail ? sharedToEmail : sharedToPhone
    if (!_.isEmpty(sharedToName)) {
      return (
        <HelpBlock className='collapse'>
          {subtitle}
        </HelpBlock>
      )
    }
  }

  private renderAccessControls() {
    const accessControlsEnabled = false
    if (accessControlsEnabled) {
      return (
        <Button
          className={classNames(
            'c-button--icon c-boxListItem-button',
            Classes.iconClass(IconNames.EDIT)
          )}
        />
      )
    }
    //   < Select
    // size = 'sm'
    // onChange = { this.handleNotificationOptionChange }
    // options = { notificationOptions }
    // value = { share.share.shouldSendNotification }
    //   />
  }

  private renderNotificationControls() {
    const notificationControlsEnabled = false
    if (notificationControlsEnabled) {
      return (
        <Button
          className={classNames(
            'c-button--icon c-boxListItem-button',
            Classes.iconClass(IconNames.NOTIFICATIONS)
          )}
        />
      )
    }
    // < Select
    //   size = 'sm'
    //   onChange = { this.handleAccessOptionChange }
    //   options = { accessOptions }
    //   value = { share.share.shouldSendNotification }
    // />
  }

  private handleNotificationOptionChange = (value) => {
    const { share } = this.props
    share.share.shouldSendNotification = Boolean(value)
    share.save()
  }

  private handleAccessOptionChange = (value) => {
    const { share } = this.props
    // TODO(peter/louis): this is wrong.
    share.share.shouldSendNotification = Boolean(value)
    share.save()
  }

  private handleRemoveFollower = () => {
    const { onDelete, share } = this.props
    this.setState({ isDeleting: true })
    onDelete(share).then(() => {
      this.setState({ isDeleting: false })
    })
  }

  private findSharedToDisplayName(sharedToName, sharedToEmail, sharedToPhone) {
    if (!_.isEmpty(sharedToName)) {
      return sharedToName
    }
    if (!_.isEmpty(sharedToEmail)) {
      return sharedToEmail
    }
    if (!_.isEmpty(sharedToPhone)) {
      return sharedToPhone
    }
  }
}
