import React from 'react'
import { IModalProps, Modal } from 'browser/components/atomic-elements/atoms/modal'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import classNames from 'classnames'

export class MobileModal {
  public static open(child: React.ReactNode, props: IModalProps = {}): Element {
    return OverlayManager.openOverlayElement(
      <Modal
        modalDialogClassName={classNames("pa0 pt1 vh-100", props.modalDialogClassName)}
        modalPaperClassName="h-100"
        modalBackdropClassName=""
        modalStyle={{ padding: 0, width: '100%', height: '100%' }}
        {...props}
      >
        {child}
      </Modal>
    )
  }
}
