import { Tab, Tabs } from '@blueprintjs/core'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import 'browser/components/atomic-elements/organisms/onboarding-flow/_onboarding-flow.scss'
// tslint:disable-next-line:max-line-length
import { IdentityVerificationTab } from 'browser/components/atomic-elements/organisms/onboarding-flow/identity-verification-tab'
// tslint:disable-next-line:max-line-length
import { InvitesListTab } from 'browser/components/atomic-elements/organisms/onboarding-flow/invites-list-tab'
// tslint:disable-next-line:max-line-length
import { JoinOrSetUpOrganizationTab } from 'browser/components/atomic-elements/organisms/onboarding-flow/join-or-setup-organization-tab'
// tslint:disable-next-line:max-line-length
import { JoinOrganizationTab } from 'browser/components/atomic-elements/organisms/onboarding-flow/join-organization-tab'
import { SelectProductsTab } from 'browser/components/atomic-elements/organisms/onboarding-flow/select-products-tab'
// tslint:disable-next-line:max-line-length
import { SetUpOrganizationTab } from 'browser/components/atomic-elements/organisms/onboarding-flow/set-up-organization-tab'

interface IOnboardingFlowProps extends IBaseProps {
  activeTabId?: string
  settings: Settings
  size?: string
  onClose: () => void
}

interface IOnboardingFlowState {
  activeTabId: string
  activeTabProps?: any
  isLoading: boolean
  previousTabs: any[]
}

export class OnboardingFlow extends React.Component<IOnboardingFlowProps, IOnboardingFlowState> {

  public static defaultProps: Partial<IOnboardingFlowProps> = {
    activeTabId: 'joinOrSetUpOrganization',
  }

  constructor(props) {
    super(props)
    this.state = {
      activeTabId: props.activeTabId,
      isLoading: true,
      previousTabs: [],
    }
  }

  public componentDidMount() {
    const { settings } = this.props
    const { activeTabId } = this.state
    const user = settings.getUser()
    this.setState({ isLoading: true })
    let initialActiveTabId = activeTabId
    return user.user.getPendingInvites().then(({ invites, unverifiedInvites }) => {
      const activeTabProps = { invites, unverifiedInvites }
      const previousTabs = []
      if (!_.isEmpty(unverifiedInvites)) {
        initialActiveTabId = 'verifyIdentity'
        previousTabs.push({ tabId: 'joinOrSetUpOrganization' })
      } else if (!_.isEmpty(invites)) {
        initialActiveTabId = 'existingInvites'
      }
      this.setState({
        activeTabId: initialActiveTabId,
        activeTabProps,
        previousTabs,
      })
    }).finally(() => this.setState({ isLoading: false }))
  }

  public render() {
    const { activeTabId, isLoading } = this.state
    if (isLoading) {
      return (
        <div className='c-onboardingFlow-initialLoading flex items-center justify-center'>
          <LoadingSpinner />
        </div>
      )
    }
    return (
      <AppNavigatorContext.Consumer>
        {({settings}) => (
          <Tabs
            className='c-onboardingFlow'
            id='joinOrSetupTabs'
            onChange={this.handleTabChange}
            selectedTabId={activeTabId}
          >
            <Tab
              id='existingInvites'
              title='Existing Invites'
              panel={this.renderExistingInvites(settings)}
            />
            <Tab
              id='joinOrSetUpOrganization'
              title='Join or Personal Organization'
              panel={this.renderJoinOrSetUpOrganization()}
            />
            <Tab
              id='joinOrganization'
              title='Join Organization'
              panel={this.renderJoinOrganization()}
            />
            <Tab
              id='setupOrganization'
              title='Setup Organization'
              panel={this.renderSetupOrganization(settings)}
            />
            <Tab
              id='verifyIdentity'
              title='Verify Identity'
              panel={this.renderFindInvitations(settings)}
            />
            <Tab
              id='selectProducts'
              title='Select Products'
              panel={this.renderSelectProductsTab(settings)}
            />
          </Tabs>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private renderExistingInvites(settings) {
    const { onClose, size } = this.props
    const { activeTabProps } = this.state
    return (
      <InvitesListTab
        {...activeTabProps}
        navigation={this.getNavigation()}
        settings={settings}
        size={size}
        productName={apis.getProductName()}
        onClose={onClose}
      />
    )
  }

  private renderJoinOrSetUpOrganization() {
    const { onClose, size } = this.props
    return (
      <JoinOrSetUpOrganizationTab
        navigation={this.getNavigation()}
        productName={apis.getProductName()}
      />
    )
  }

  private renderJoinOrganization() {
    const { onClose, size } = this.props
    return (
      <JoinOrganizationTab
        navigation={this.getNavigation()}
        size={size}
        productName={apis.getProductName()}
        onClose={onClose}
      />
    )
  }

  private renderSetupOrganization(settings) {
    const { onClose, size } = this.props
    return (
      <SetUpOrganizationTab
        navigation={this.getNavigation()}
        onClose={onClose}
        settings={settings}
        size={size}
        productName={apis.getProductName()}
      />
    )
  }

  private renderFindInvitations(settings) {
    const { onClose, size } = this.props
    const { activeTabProps } = this.state
    return (
      <IdentityVerificationTab
        {...activeTabProps}
        navigation={this.getNavigation()}
        settings={settings}
        size={size}
        onClose={onClose}
      />
    )
  }

  private renderSelectProductsTab(settings) {
    const { onClose, size } = this.props
    const { activeTabProps } = this.state
    return (
      <SelectProductsTab
        {...activeTabProps}
        navigation={this.getNavigation()}
        settings={settings}
        size={size}
        onClose={onClose}
      />
    )
  }

  private getNavigation() {
    const { onClose, size } = this.props
    return {
      dismiss: onClose,
      goBack: this.handleNavigateBack,
      navigate: this.handleTabChange,
    }
  }

  private handleNavigateBack = () => {
    const { previousTabs } = this.state
    const previousTab: any = previousTabs.pop()
    if (previousTab) {
      const { tabId, tabProps } = previousTab
      this.handleTabChange(tabId, tabProps)
    }
  }

  private handleTabChange = (tabId: string, tabProps?) => {
    const { activeTabId, activeTabProps, previousTabs } = this.state
    previousTabs.push({
      tabId: activeTabId,
      tabProps: activeTabProps,
    })
    this.setState({
      activeTabId: tabId,
      activeTabProps: tabProps,
      previousTabs,
    })
  }
}
