import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Thumbnail } from 'browser/components/atomic-elements/molecules/thumbnail/thumbnail'

export interface IThumbnailListProps extends IBaseProps {
  files: any[]
  maxNumber?: number
  onThumbnailClick: (event: any, file, index) => void
  showOriginal?: boolean
  size?: string
  fitIn?: number
}

export class ThumbnailList extends React.Component<IThumbnailListProps, {}> {
  public static defaultProps: Partial<IThumbnailListProps> = {
    files: [],
    maxNumber: 4,
    onThumbnailClick: _.noop,
    size: 'xs',
  }

  public render() {
    const { className, maxNumber, files } = this.props
    const thumbnails = files.slice(0, maxNumber)
    return (
      <div className={classNames('c-thumbnailList', className)}>
        {this.renderThumbnails(thumbnails)}
        {this.renderRemainingThumbnailsHint(thumbnails, maxNumber)}
      </div>
    )
  }

  private renderThumbnails(files) {
    const { onThumbnailClick, showOriginal, size, fitIn } = this.props
    return _.map(files, (file: any, index) => {
      const handleClick = (event) => onThumbnailClick(event, file, index)
      return (
        <Thumbnail
          onClick={handleClick}
          className='mr2 c-thumbnail--active'
          key={file.uniqueId}
          file={file}
          showOriginal={showOriginal}
          size={size}
          fitIn={fitIn}
        />
      )
    })
  }

  private renderRemainingThumbnailsHint(files, maxNumber) {
    if (files && files.length > maxNumber) {
      const attachmentRemaining = files.length - maxNumber
      const { size } = this.props
      const sizeClassName = size ? `c-thumbnail-remaining--${size}` : ''
      return (
        <div className={classNames('c-thumbnail-remaining', sizeClassName)}>
          <div className='c-label c-label--secondary u-bottomFlush c-thumbnail-remainingText'>
            <Icon icon={IconNames.PLUS} /> {attachmentRemaining}
          </div>
        </div>
      )
    }
  }
}
