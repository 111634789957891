import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { DatePicker, IDatePickerProps } from 'browser/components/atomic-elements/atoms/date-picker/date-picker'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'

type IDatePickerPopoverProps = IDatePickerProps

export class DatePickerPopover extends React.PureComponent<IDatePickerPopoverProps, any> {
  public render() {
    return (
      <Popover
        className={classNames('collapse', this.props.className)}
      >
        <DatePicker
          {...this.props}
        />
      </Popover>
    )
  }
}
