import _ from 'lodash'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { UIDispatchOrderStopList } from './dispatch-order-stop-list'

const TRIP_LEGS_PATH = 'core_fulfilment_trip.plan.legs'

export class UITripStopList extends UIDispatchOrderStopList {
  public render() {
    return (
      <UIDispatchOrderStopList
        {...this.props}
        onChange={this.handleOnChange}
      />
    )
  }

  private handleOnChange = (stops) => {
    const { onChange } = this.props
    this.recalculateLegs(stops)
    onChange && onChange(stops)
  }

  // When stops are created/removed/reordered this recalculates the stops on legs accordingly
  private recalculateLegs = (stops) => {
    const { entity } = this.props
    const legs = entity.get(TRIP_LEGS_PATH, [])
    const legStops = {}
    if (legs.length > 0) {
      // create map of all origins/destination of each leg indexed by stop itemId
      const firstLegOrigin = _.get(legs[0], 'origin', {})
      const firstLegOriginItemId = _.get(firstLegOrigin, 'stop.itemId')
      firstLegOriginItemId && _.set(legStops, firstLegOriginItemId, firstLegOrigin)
      /**
       * because the destination of one leg is also the origin of the next leg,
       * we only need to capture the origin of the first leg and then index the destinations
       */
      _.map(legs, (leg) => {
        const legDestination = _.get(leg, 'destination', {})
        const legDestinationItemId = _.get(legDestination, 'stop.itemId')
        legDestinationItemId && _.set(legStops, legDestinationItemId, legDestination)
      })
    }

    const newLegs = []
    const numLegs = stops.length - 1 // when stops are added/removed, legs needs to reflect it
    let stopIndex = 0
    /**
     * traverse the leg list, and replace the origin and destination of each leg with stops in the
     * order that the stops appear
     */
    for (let legIndex = 0; legIndex < numLegs; legIndex++) {
      // create a new leg if it does not yet exist
      const leg = legs[legIndex] || { uniqueId: uuidv4() }
      const originId = _.get(stops[stopIndex], 'uniqueId', '')
      const origin = legStops[originId] || { stop: { itemId: originId } }
      _.set(leg, 'origin', origin)
      stopIndex++
      const destinationId = _.get(stops[stopIndex], 'uniqueId', '')
      const destination = legStops[destinationId] || { stop: { itemId: destinationId } }
      _.set(leg, 'destination', destination)
      newLegs.push(leg)
    }
    entity.set(TRIP_LEGS_PATH, newLegs)
  }
}
