import Promise from 'bluebird'

import { Timer } from 'shared-libs/helpers/timer'
import { VisibilityManager } from 'browser/app/utils/visibility-manager'

export class VisibilityTimer extends Timer {
  private visibilityManager: VisibilityManager
  private pauseKey: string = undefined

  constructor(timedFunction: () => Promise<any>, interval, continueOnFailure=false) {
    super(timedFunction, interval, continueOnFailure)

    const resumeWithKey = () => {
      if (!this.pauseKey) {
        return
      }

      this.resume(this.pauseKey)
      this.pauseKey = undefined
    }

    const pauseWithKey = () => {
      if (this.pauseKey) {
        return
      }

      this.pauseKey = this.pause()
    }

    this.visibilityManager = new VisibilityManager(resumeWithKey, pauseWithKey)
  }

  public dispose() {
    super.dispose()
    this.visibilityManager.dispose()
  }
}
