declare let window: any

import classNames from 'classnames'
import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { useFetchSalesInvoiceRelatedDocuments } from 'browser/components/hooks/useFetchSalesInvoiceRelatedDocuments'

import apis from 'browser/app/models/apis'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { DocumentItem } from 'browser/components/atomic-elements/organisms/entity/documents-list/document-item'
import { ImageEditorModal } from 'browser/components/atomic-elements/organisms/image-editor-modal'
import { Entity } from 'shared-libs/models/entity'
import { ImageLoader } from 'browser/components/atomic-elements/atoms/image-loader/image-loader'
import { Thumbnail } from 'browser/components/atomic-elements/molecules/thumbnail/thumbnail'
import { ShareConfigurationModal } from 'browser/components/atomic-elements/organisms/share-configuration-modal/share-configuration-modal'
import { isPrintable } from './utils'
import components from 'browser/components'

const ImagePreviewPdf = require('images/add-document-image-preview-pdf.png')

/**
 * @uiComponent
 */
interface IRbDocumentsListProps extends IBaseProps {
  entity: any
}

export const Rb2DocumentsList: React.FC<IRbDocumentsListProps> = (props) => {
  const { className, entity } = props
  const { settings } = useContext(AppNavigatorContext)
  const [ isDownloading, setIsDownloading ] = useState<boolean>(false)
  const [ isPrinting, setIsPrinting ] = useState<boolean>(false)
  const [ selectedDocuments, setSelectedDocuments ] = useState([])
  const [ documents, isLoading ] = useFetchSalesInvoiceRelatedDocuments(entity)

  const combinedInvoicePdf = _.get(entity, 'core_accounting_accountsReceivable_salesInvoice.combinedPdf')

  const renderTableActionBar = () => {
    const hasNoSelection = _.isEmpty(selectedDocuments)
    return hasNoSelection ? renderAllActionBar() : renderSelectedActionBar()
  }

  const renderCombinedActionBar = () => {
    return (
      <div className="flex mv1">
        <Button
          className={classNames(
            'ml2',
            Classes.SMALL,
            Classes.iconClass(IconNames.PRINT),
            Classes.MINIMAL
          )}
          onClick={handlePrintCombinedDocument}
        >
          Print
        </Button>
        <Button
          className={classNames(
            'ml2',
            Classes.SMALL,
            Classes.iconClass(IconNames.DOWNLOAD),
            Classes.MINIMAL
          )}
          onClick={handleDownloadCombinedDocument}
        >
          Download
        </Button>
      </div>
    )
  }

  const renderSelectedActionBar = () => {
    return (
      <div className="flex mv1">
        <Button
          className={classNames(
            'ml2',
            Classes.SMALL,
            Classes.iconClass(IconNames.SHARE),
            Classes.MINIMAL
          )}
          onClick={handleOpenShareConfigurationModal}
        >
          Share
        </Button>
        <Button
          className={classNames(
            'ml2',
            Classes.SMALL,
            Classes.iconClass(IconNames.PRINT),
            Classes.MINIMAL
          )}
          isLoading={isPrinting}
          onClick={handlePrintDocuments}
        >
          Print
        </Button>
        <Button
          className={classNames(
            'ml2',
            Classes.SMALL,
            Classes.iconClass(IconNames.DOWNLOAD),
            Classes.MINIMAL
          )}
          isLoading={isDownloading}
          onClick={handleDownloadDocuments}
        >
          Download
        </Button>
      </div>
    )
  }

  const renderAllActionBar = () => {
    const shouldDisabled = _.isEmpty(documents)

    return (
      <div className="flex mv1">
        <Button
          className={classNames(
            'ml2',
            Classes.SMALL,
            Classes.iconClass(IconNames.PRINT),
            Classes.MINIMAL
          )}
          isLoading={isPrinting}
          isDisabled={shouldDisabled}
          onClick={handlePrintAllDocuments}
        >
          Print All
        </Button>
        <Button
          className={classNames(
            'ml2',
            Classes.SMALL,
            Classes.iconClass(IconNames.DOWNLOAD),
            Classes.MINIMAL
          )}
          isLoading={isDownloading}
          isDisabled={shouldDisabled}
          onClick={handleDownloadAllDocuments}
        >
          Download All
        </Button>
      </div>
    )
  }

  const renderCombinedInvoicePdf = () => {
    const invoiceName = _.get(entity, 'core_accounting_accountsReceivable_salesInvoice.invoiceNumber')

    if (_.isNil(combinedInvoicePdf)) {
      return renderEmptyState()
    }

    const handleOpenPreview = () => {
      window.open(combinedInvoicePdf)
    }

    const renderThumbnail = () => {
      return (
        <Thumbnail
          onClick={handleOpenPreview}
          file={{
            uri: combinedInvoicePdf,
            type: 'pdf'
          }}
          loading={false}
        />
      )
    }

    const renderName = () => {
      return (
        <div className="ml3">
          <a className={classNames('f5 mb1 db')} onClick={handleOpenPreview}>
            {invoiceName} - Combined Invoice PDF
          </a>
          <HelpBlock className="collapse">
            Auto-generated by Vector System, merge all documents above
          </HelpBlock>
        </div>
      )
    }

    return (
      <table className="c-table c-table--flushHorizontal c-table--auto c-table--noBorders">
        <tbody className="c-table-body">
          <tr className={classNames({ 'bt b--light-gray': true })}>
            <td className="c-table-cell c-table-cell--verticallyCenter">
              <div className="flex items-center">
                {renderThumbnail()}
                {renderName()}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  const renderDocumentList = () => {
    if (_.isEmpty(documents)) {
      return renderEmptyState()
    }
    const uniqueEntities: any = _.uniqBy(documents, (e: any) => e.uniqueId)
    const listItems = _.map(uniqueEntities, (document) => {
      const handleSelectDocument = () => {
        if (_.indexOf(selectedDocuments, document) >= 0) {
          _.pull(selectedDocuments, document)
        } else {
          selectedDocuments.push(document)
        }
        setSelectedDocuments(selectedDocuments.slice())
      }

      const handleOpenPreview = (entity: Entity, setLoading: (loading: boolean) => void) => {
        if (!document.isIdle) {
          return
        }
        if (isPrintable(document)) {
          setLoading(true)
          apis.getPrintablePreview(document.uniqueId).then((result: any) => {
            ImageEditorModal.open({
              isEditable: false,
              entity: result,
              imagesPath: 'document.attachments'
            })
            setLoading(false)
          })
        } else {
          ImageEditorModal.open({
            entity: document,
            imagesPath: 'document.attachments'
          })
        }
      }

      return (
        <DocumentItem
          entity={document}
          isSelected={_.indexOf(selectedDocuments, document) >= 0}
          key={document.uniqueId}
          onOpenPreview={handleOpenPreview}
          onSelect={handleSelectDocument}
          showDisplayName={true}
        />
      )
    })
    return (
      <table className="c-table c-table--flushHorizontal c-table--auto c-table--noBorders">
        <tbody className="c-table-body">{listItems}</tbody>
      </table>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className="c-boxListItem c-boxListItem--noHover">
        <div className="c-boxListItem-content u-textCenter u-bumperTop u-bumperBottom">
          <HelpBlock className="mb2">No documents have been attached yet.</HelpBlock>
        </div>
      </div>
    )
  }

  const handleOpenShareConfigurationModal = () => {
    ShareConfigurationModal.open({
      onSend: handleSendDocument,
      showRecipientsList: false,
      showShareAccessSettings: false
    })
  }

  const handleSendDocument = (recipients, message) => {
    const firm = settings.getFirm()
    // TODO (David): Need to change this, assuming that entity is order here
    const loadNumber = entity.displayName
    const attachments = getAttachments(selectedDocuments)
    const subject = `Documents from ${firm.displayName} for Load ${loadNumber}`
    return apis
      .sendNotification({
        attachments,
        message,
        recipients,
        subject
      })
      .then(() => {
        Toast.show({
          message: 'Emailing documents.',
          position: Position.BOTTOM_RIGHT
        })
      })
  }

  const handleDownloadDocuments = () => {
    const attachments = getAttachments(selectedDocuments)
    setIsDownloading(true)
    apis.batchDownloadDocuments({ attachments }).then((url) => {
      window.location = url
      setIsDownloading(false)
    })
  }

  const handlePrintDocuments = () => {
    const entityIds = getEntityIds(selectedDocuments)
    setIsPrinting(true)
    return apis.batchPrintDocuments(entityIds).then((url: string) => {
      setIsPrinting(false)
      window.open(url)
    })
  }

  const handleDownloadAllDocuments = () => {
    const attachments = getAttachments(documents)
    setIsDownloading(true)
    apis.batchDownloadDocuments({ attachments })
    .then((url) => {
      window.location = url
      setIsDownloading(false)
    })
  }

  const handlePrintAllDocuments = () => {
    const entityIds = getEntityIds(documents)
    setIsPrinting(true)
    return apis.batchPrintDocuments(entityIds).then((url: string) => {
      setIsPrinting(false)
      window.open(url)
    })
  }

  const handlePrintCombinedDocument = () => {
    window.open(combinedInvoicePdf)
  }

  const handleDownloadCombinedDocument = () => {
    window.open(combinedInvoicePdf, '_blank')
  }

  const getAttachments = (attachments) => {
    const loadNumber = entity.displayName
    return _.map(attachments, (document) => ({
      entityId: document.get('uniqueId'),
      fileName: `${loadNumber} - ${document.entityType}.pdf`,
      type: 'document'
    }))
  }

  const getEntityIds = (entities) => {
    return _.map(entities, 'uniqueId')
  }

  const renderCombinedInvoicePdfSection = () => {
    if (_.isEmpty(combinedInvoicePdf)) {
      return
    }
    return (
      <Section title="Combined Invoice PDF" headerControls={renderCombinedActionBar()}>
        {renderCombinedInvoicePdf()}
      </Section>
    )
  }

  const renderRelatedDocumentsSection = () => {
    return (
      <Section title="Related Documents" headerControls={renderTableActionBar()}>
        {renderDocumentList()}
      </Section>
    )
  }

  return (
    <div className={classNames('c-documentsList', className)}>
      {renderRelatedDocumentsSection()}
      {renderCombinedInvoicePdfSection()}
    </div>
  )
}
