import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/error-block/_error-block.scss'
import { ErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/error-block'

/**
 * @uiComponent
 */
interface IEntityErrorBlockProps extends IBaseProps {
  entity: any
  errors: any
  isSummary?: boolean
}

export class EntityErrorBlock extends React.Component<IEntityErrorBlockProps, any> {
  static defaultProps: Partial<IEntityErrorBlockProps> = {
    isSummary: false,
  }

  public render() {
    const { className } = this.props

    return (
      <ErrorBlock
        errorText={this.createErrorString()}
        className={className}
      />
    )
  }

  private joinStrings(messages: string[]|string): string {
    if (_.isArrayLike(messages)) {
      return _.join(messages, ', ')
    } else {
      try {
        return _.get(messages, 'message', JSON.stringify(messages))
      } catch (e) {
        return messages
      }
    }
  }

  private createErrorString() {
    const { entity, errors, isSummary } = this.props

    if (_.isEmpty(errors)) {
      return null
    }

    if (isSummary) {
      const errorCount = _.reduce(errors, (acc: number, messages: string[], key: any) => {
        return acc + _.size(messages)
      }, 0)
      return `${errorCount} error${errorCount === 1 ? '' : 's'}`
    }

    let hasKeyedError = false
    const errorStrings = []
    _.forEach(errors, (messages: string[]|string, key?: string) => {
      const errorString = this.joinStrings(messages)
      if (_.isEmpty(key) || key === 'undefined') {
        errorStrings.push(errorString)
        return
      } else {
        hasKeyedError = true
      }

      if (entity) {
        const path = key.split('.')
        const schemaEntity = entity.resolveSubschemaByValuePath(path)
        // sometimes server gives us key that are not valid paths
        // e.g. "$.invite[?(@.pending == true)]"
        if (schemaEntity) {
          const label = schemaEntity.schema.label
          if (_.isEmpty(label)) {
            errorStrings.push(`${key}: ${errorString}`)
          } else {
            errorStrings.push(`${label}: ${errorString}`)
          }
        } else {
          // catch more general errors, e.g. 500 error w/ schema namespace not matching the id
          errorStrings.push(errorString)
        }
      } else {
        errorStrings.push(`${key}: ${errorString}`)
      }
    })

    // assumes keyed errors are due to validation failures, otherwise fall back
    // to a less specific message (e.g. network issue).
    const prefix = hasKeyedError ? 'Please fix validation errors' : 'Error'
    return `${prefix}: ${errorStrings.join('; ')}`
  }
}
