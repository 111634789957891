import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import 'browser/components/atomic-elements/domains/trucking/order-revenue-expense-table/_order-revenue-expense-table.scss'

/**
 * @uiComponent
 */
interface IOrderRevenueExpenseTableProps extends IBaseProps {
  density?: string
  entity: any
  expenseItemsPath: string
  expenseTable: React.ReactElement<any>
  revenueItemsPath: string
  revenueTable: React.ReactElement<any>
  showMargin?: boolean
}

export class OrderRevenueExpenseTable extends React.Component<IOrderRevenueExpenseTableProps, any> {
  public static defaultProps: Partial<IOrderRevenueExpenseTableProps> = {
    showMargin: true,
  }

  public render() {
    const { className } = this.props
    return (
      <div className={className}>
        {this.renderRevenueTable()}
        {this.renderExpenseTable()}
        {/* {this.renderMargin()} */}
      </div>
    )
  }

  private getTotal(key) {
    const { entity } = this.props
    const accountingItems = entity.get(key, {})
    return _.reduce(accountingItems, (accum: number, item: any) => {
      const value = _.get(item, 'amount.value', 0)
      return accum + Number(value)
    }, 0)
  }

  private getTotalRevenue() {
    const { revenueItemsPath } = this.props
    return this.getTotal(revenueItemsPath)
  }

  private getTotalExpenses() {
    const { expenseItemsPath } = this.props
    return this.getTotal(expenseItemsPath)
  }

  private getTotalMargin() {
    return this.getTotalRevenue() - this.getTotalExpenses()
  }

  private getPercentageMargin() {
    const totalMargin = this.getTotalMargin()
    const revenue = this.getTotalRevenue()
    const profit = revenue - this.getTotalExpenses()
    if (revenue !== 0) {
      const percentageProfit = (profit / revenue * 100).toFixed(2)
      return (
        <h6>
          {percentageProfit}%
        </h6>
      )
    }
  }

  private parseFloat(value) {
    if (_.isEmpty(value)) {
      return 0
    }
    const parsedValue = parseFloat(value)
    if (_.isNaN(parsedValue)) {
      return 0
    }
    return parsedValue
  }

  private renderExpenseTableFooter = (handleAddItem) => {
    const { density } = this.props
    const totalExpense = this.getTotalExpenses()
    const densityClassName = _.isEmpty(density) ? '' : `c-flexTable-cell--${density}`
    return (
      <div className='c-flexTable-row c-flexTable-row--footer'>
        <div className={classNames('c-flexTable-cell c-flexTable-cell--footer', densityClassName)}>
          <Button
            className={classNames(Classes.MINIMAL)}
            onClick={handleAddItem}
          >
            Add Carrier Charge
          </Button>
        </div>
        <div className={classNames('c-flexTable-cell c-flexTable-cell--grow c-flexTable-cell--footer tr u-bumperRight', densityClassName)}>
          Total Carrier Costs
        </div>
        <div className={classNames('c-flexTable-cell c-flexTable-cell--footer tr', densityClassName)}>
          <div className='u-fontWeightSemibold'>
            ${totalExpense.toFixed(2)}
          </div>
        </div>
        <div className={classNames('c-flexTable-cell c-flexTable-cell--footer c-flexTable-cell--remove', densityClassName)} />
      </div>
    )
  }

  private renderRevenueTableFooter = (handleAddItem) => {
    const { density } = this.props
    const totalRevenue = this.getTotalRevenue()
    const densityClassName = _.isEmpty(density) ? '' : `c-flexTable-cell--${density}`
    return (
      <div className='c-flexTable-row c-flexTable-row--footer'>
        <div className={classNames('c-flexTable-cell c-flexTable-cell--footer', densityClassName)}>
          <Button
            className={classNames(Classes.MINIMAL)}
            onClick={handleAddItem}
          >
            Add Customer Charge
          </Button>
        </div>
        <div className={classNames('c-flexTable-cell c-flexTable-cell--footer', densityClassName)} />
        <div className={classNames('c-flexTable-cell c-flexTable-cell--footer', densityClassName)} />
        <div
          className={classNames('c-flexTable-cell c-flexTable-cell--grow c-flexTable-cell--footer tr u-bumperRight', densityClassName)}
        >
          Total Customer Charges
        </div>
        <div className={classNames('c-flexTable-cell c-flexTable-cell--footer tr', densityClassName)}>
          <div className='u-fontWeightSemibold'>
            ${totalRevenue.toFixed(2)}
          </div>
        </div>
        <div className={classNames('c-flexTable-cell c-flexTable-cell--footer c-flexTable-cell--remove', densityClassName)} />
      </div>
    )
  }

  private renderRevenueTable() {
    let { revenueTable } = this.props
    if (!_.isEmpty(revenueTable)) {
      revenueTable = React.cloneElement(revenueTable, {
        footer: this.renderRevenueTableFooter,
      })
      return revenueTable
    }
  }

  private renderExpenseTable() {
    let { expenseTable } = this.props
    if (!_.isEmpty(expenseTable)) {
      expenseTable = React.cloneElement(expenseTable, {
        footer: this.renderExpenseTableFooter,
      })
      return expenseTable
    }
  }

  private renderMargin() {
    const { showMargin } = this.props
    if (showMargin) {
      const totalMargin = this.getTotalMargin()
      const percentageMargin = this.getPercentageMargin()
      return (
        <table className='c-table c-table--tight c-table--flushHorizontal u-bumperTop mb6'>
          <tbody className='c-table-body'>
            <tr>
              <td className='c-loadPnlTableComissionCellEmpty' />
              <td className='c-loadPnlTableComissionCellEmpty' />
              <td className='c-loadPnlTableComissionCell tr'>
                <h6 className='u-fontWeightNormal'>
                  Margin
                </h6>
              </td>
              <td className='c-loadPnlTableAmountColumn c-loadPnlTableComissionCell tr'>
                {percentageMargin}
              </td>
              <td className='c-loadPnlTableDeleteColumn c-loadPnlTableComissionCell' />
            </tr>
          </tbody>
        </table>
      )
    }
  }
}
