import classNames from 'classnames'
import React from 'react'
import _ from 'lodash'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/image-loader/_image-loader.scss'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import Bluebird from 'bluebird'

/**
 * @uiComponent
 */
export interface IImageLoaderProps extends IBaseProps {
  hasCenteringHelper?: boolean
  imageClassName?: string
  size?: string
  src: string
  showLoadingSpinner?: boolean
  loadOverride?: boolean
  onClick?: () => void
  onError?: (event: any) => void
}

interface IImageLoaderState {
  isLoading: boolean
}

const MAX_RETRIES = 1
const RETRYABLE_STATUS_CODES = [0, 408, 429, 500, 502, 503, 504];

export class ImageLoader extends React.PureComponent<IImageLoaderProps, IImageLoaderState> {
  public static defaultProps: Partial<IImageLoaderProps> = {
    showLoadingSpinner: true,
    onClick: _.noop,
  }

  private imageRef = React.createRef<HTMLImageElement>()
  private retries = 0

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  public render() {
    const { className, children, imageClassName, src, style, onClick } = this.props
    const { isLoading } = this.state
    const imageStyle = {
      opacity: isLoading ? 0 : 1,
    }
    return (
      <div
        className={classNames(className, {
          'c-imageLoader': isLoading,
        })}
        style={style}
      >
        {this.renderHelper()}
        <img
          ref={this.imageRef}
          className={classNames('c-image', imageClassName)}
          onLoad={this.handleLoad}
          onClick={onClick}
          onError={this.handleError}
          src={src}
          style={imageStyle}
        />
        {children}
        {this.renderLoading()}
      </div>
    )
  }

  private renderHelper() {
    if (this.props.hasCenteringHelper) {
      return <span className='c-image-helper' />
    }
  }

  private renderLoading() {
    const { loadOverride, size, showLoadingSpinner } = this.props
    const { isLoading } = this.state

    if ((isLoading && showLoadingSpinner) || (loadOverride)) {
      return (
        <LoadingSpinner
          className='c-imageSpinner'
          data-debug-id='image-loader-spinner'
          size={size}
        />
      )
    }
  }

  private handleLoad = () => {
    this.setState({ isLoading: false })
  }

  private handleError = async (event) => {
    const { onError } = this.props
    if (onError) {
      onError?.(event)
      return
    }

    // NOTE: This is broken, and needs to stay broken until we change the logic
    // introduced by `73d4aec462 2024-03-01 [VD-11235] wait for spinners to
    // disappear (#9676)`. The cloudrun/exportpdf function depends on these
    // loading spinners disappearing (or not) as an indication that all images
    // are loaded (or are failing to load and may need a retry on the cloudrun
    // side). That code would need to become aware of the presence of failed
    // images before we can fix the indefinite spinner issue in the general
    // case.
    // See also `f991f55e20 2024-03-26 Revert "[VD-11536] let image-loader's img
    // show its default failure icon (#9716)" (#9840)`.
    const code = event?.target?.status
    if (_.includes(RETRYABLE_STATUS_CODES, code) && ++this.retries <= MAX_RETRIES) {
      console.warn(`Failed to load image, retrying [${this.retries}/${MAX_RETRIES}]...`)
      await Bluebird.delay(100)
      this.reloadImage()
    }
  }

  private reloadImage = () => {
    this.imageRef.current.src = this.props.src
  }
}
