import apis from 'browser/app/models/apis'
import _ from 'lodash'
import { FilterOptionsCollection } from '../multi-select-filter-item/filter-options-collection'

export class EmployeeFilterCollection extends FilterOptionsCollection {

  public find() {
    this.collection.query.setFilters(this.getFilters())
    return this.collection.find().then((content) => {
      return this.augmentEntityWithEmployeeId(content)
    })
  }

  public next() {
    return this.collection.next().then((content) => {
      return this.augmentEntityWithEmployeeId(content)
    })
  }

  private augmentEntityWithEmployeeId(content) {
    const entityIdsMap = {}
    _.forEach(content, ({ data }) => entityIdsMap[data.entityId] = data)
    const ids = _.keys(entityIdsMap)
    return apis.getStore().findRecords(ids).then((employees) => {
      _.forEach(employees, (employee) => {
        if (entityIdsMap[employee.uniqueId]) {
          const employeeId = _.get(employee, 'user.employeeId')
          entityIdsMap[employee.uniqueId].employeeId = employeeId
        }
      })
      return content
    })
  }
}
