import { InviteProps } from "shared-libs/models/prop-constants";

interface IField {
  id: string
  label: string
  type: string
}

const InviteFields: IField[] = [
  { id: InviteProps.FIRST_NAME, label: 'First Name', type: 'required' },
  { id: InviteProps.LAST_NAME, label: 'Last Name', type: 'required' },
  { id: InviteProps.ROLE, label: 'Role', type: 'required' },
  { id: InviteProps.EMPLOYEE_ID, label: 'Employee ID', type: 'optional' },
  { id: InviteProps.DIVISION, label: 'Division', type: 'optional' },
  { id: InviteProps.EMAIL, label: 'Email', type: 'oneOrMore' },
  { id: InviteProps.PHONE_NUMBER, label: 'Phone Number', type: 'oneOrMore' }
]

export { InviteFields, IField }