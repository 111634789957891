import { Tooltip } from '@blueprintjs/core'
import Promise from 'bluebird'
import _ from 'lodash'
import React from 'react'

import { Entity } from 'shared-libs/models/entity'

import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Checkbox } from 'browser/components/atomic-elements/atoms/checkbox/checkbox'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Select } from 'browser/components/atomic-elements/atoms/select'
import 'browser/components/atomic-elements/organisms/share-configuration-modal/_share-configuration-item.scss'
import { Formatter } from 'shared-libs/helpers/formatter'

interface ISuggestedRecipientsListProps {
  onChange: (emails: string[]) => void
  suggestions: any[]
  recipients: string[]
}

// tslint:disable-next-line:max-line-length
export class SuggestedRecipientsList extends React.PureComponent<ISuggestedRecipientsListProps, {}> {

  public render() {
    const { suggestions, recipients } = this.props
    return (
      <div className='bb bl br b--light-gray ph2'>
        <table className='c-table c-table--flushHorizontal c-table--auto c-table--noBorders'>
          <tbody className='c-table-body'>
            {_.map(suggestions, (item, index) => this.renderRow(item, index))}
          </tbody>
        </table>
      </div>
    )
  }

  private renderRow(item, index) {
    const { onChange, recipients } = this.props
    const { companyName, email, entityType, name } = item
    const isSelected = _.includes(recipients, email)
    const handleToggleCheckbox = () => {
      if (isSelected) {
        onChange(_.without(recipients, email))
      } else {
        onChange(recipients.concat([email]))
      }
    }
    return (
      <tr key={index}>
        <td className='c-table-cell c-table-cell--verticallyCenter'>
          <div className='flex'>
            <div className='mr4'>
              <Checkbox
                className='c-checkbox-box--collapseMinHeight c-checkbox-box--flush c-checkbox-box--relative c-checkbox--flush'
                onChange={handleToggleCheckbox}
                label=''
                value={isSelected}
              />
            </div>
            <div>
              {name} ({email})
            </div>
          </div>
        </td>
        <td>{companyName}</td>
        <td>{entityType}</td>
      </tr>
    )
  }
}
