import Promise from 'bluebird'
import { browserHistory } from 'browser/history'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface IEntityCreationProgressPopoverProps extends IBaseProps {
  entity: any
  onClose: () => void
  onSave: (onProgress: (progress) => void) => Promise<any>
  redirectPath?: string
  shouldRedirectOnSuccess?: boolean
}

interface IEntityCreationProgressPopoverState {
  message: string
}

export class EntityCreationProgressPopover
  extends React.Component<IEntityCreationProgressPopoverProps, IEntityCreationProgressPopoverState> {

  constructor(props) {
    super(props)
    this.state = {
      message: '',
    }
  }

  public componentDidMount() {
    const { onSave, redirectPath, shouldRedirectOnSuccess } = this.props
    onSave(this.handleProgress).then((entity) => {
      this.setState({ message: 'Processing...' })
      return entity.waitUntilIdle()
    }).then((entity) => {
      if (shouldRedirectOnSuccess) {
        browserHistory.push({ pathname: redirectPath })
      }
    }).finally(() => {
      this.props.onClose()
    })
  }

  public render() {
    const { entity } = this.props
    const { message } = this.state
    return (
      <div className='u-flex u-justifyContentSpaceBetween' data-debug-id='toast'>
        <div className='u-ellipsis'>
          {entity.entityType}: {entity.displayName}
        </div>
        <div className='u-bumperLeft'>
          {message}
        </div>
      </div>
    )
  }

  private handleProgress = (progress) => {
    this.setState({ message: `${Math.round(progress * 100)}%` })
  }
}
