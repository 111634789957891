import { AddressInput } from 'browser/components/atomic-elements/atoms/input/address-input/address-input'
// tslint:disable-next-line:max-line-length
import { AddressInputSheet } from 'browser/components/atomic-elements/molecules/fields/address-field/address-input-sheet'

import LabelFieldFactory from 'browser/components/atomic-elements/higher-order-components/label-field-factory'

export const AddressField = LabelFieldFactory({
  InputComponent: AddressInput,
  InputSheetComponent: AddressInputSheet,
})
