import _ from 'lodash'
import React, { Fragment } from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface ITableEnumLabelCellProps extends IBaseProps {
  path: string
  row: any
  value: any
}

export const TableEnumLabelCell: React.FC<ITableEnumLabelCellProps> = (props) => {
  const { path, row, value } = props
  const entity = row.data
  const enumPath = path.startsWith('data.') ? path.replace('data.', '') : path
  const enumSchema = entity.resolveSubschemaByValuePath(enumPath)
  const enumOptions = enumSchema.schema.enumOptions
  const option: any = _.find(enumOptions, { value })
  return (
    <Fragment> {option.label} </Fragment>
  )
}
