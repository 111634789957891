import apis from 'browser/app/models/apis'
import SemaText from 'browser/app/models/sematext'
import _ from 'lodash'

class LoggingInstance {

  private logger?: SemaText

  public initialize(apiKey: string) {
    if (!apiKey) {
      console.warn('ApiKey is required in initialize()')
      return
    }
    this.logger = new SemaText(apiKey, apis.platform)
    const deviceInfo = {
      userAgent: navigator.userAgent,
    }
    this.logger.setDeviceProperties(deviceInfo)
  }

  public logEvent(eventName: string, eventProperties?: any) {

    if (this.logger) {
      this.logger.log('info', eventName, eventProperties)
    } else {
      try {
        console.log(`Logging DEV mode: event "${eventName}" properties ${JSON.stringify(eventProperties)}`)
      } catch(e) {
        console.error(e.stack)
      }
    }
  }

  public setUserId(userId: string) {
    this.logger?.setUserProperties({ userId })
  }

  public setUserProperties(properties: any) {
    this.logger?.setUserProperties(properties)
  }
}

export const Logger = new LoggingInstance()
