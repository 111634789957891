import _ from 'lodash'
import React from 'react'

import { getDocumentSchemas } from 'shared-libs/models/utils'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { ISelectProps } from 'browser/components/atomic-elements/atoms/select'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'

/**
 * @uiComponent
 */
interface IDocumentTypeSelectFieldProps extends ISelectProps {
  entity: any
  label: string
  settings: Settings
}

interface IDocumentTypeSelectFieldState {
  documentSchemas: any[]
}

class DocumentTypeSelectField extends React.Component<IDocumentTypeSelectFieldProps, IDocumentTypeSelectFieldState> {

  constructor(props) {
    super(props)
    this.state = {
      documentSchemas: [],
    }
  }

  public componentDidMount() {
    const { settings } = this.props
    const schemas = settings.getBundleSchemas()
    const store = apis.getStore()
    this.setState({
      documentSchemas: getDocumentSchemas(store, schemas, 'web'),
    })
  }

  public render() {
    const { entity, label } = this.props
    const { documentSchemas } = this.state
    const value = _.last(entity.schemas)
    return (
      <SelectField
        {...this.props}
        label={label}
        isHorizontalLayout={true}
        onChange={this.handleChange}
        options={documentSchemas}
        optionLabelPath='displayName'
        optionValuePath='uniqueId'
        value={value}
      />
    )
  }

  private handleChange = (schemaId, schema) => {
    const { entity, onChange } = this.props
    const value: any = _.last(entity.schemas)
    _.remove(entity.activeMixins, (mixin: any) => {
      return mixin.entityId === value.uniqueId
    })
    const store = apis.getStore()
    entity.addMixin(schema)
    onChange(entity.mixins)
  }
}

export default React.forwardRef((props: IDocumentTypeSelectFieldProps, ref: React.Ref<DocumentTypeSelectField>) => (
  <AppNavigatorContext.Consumer>
    {({ settings }) => <DocumentTypeSelectField {...props} settings={settings} ref={ref} />}
  </AppNavigatorContext.Consumer>
))
