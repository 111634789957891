import React from 'react'
import { withMobileHeader } from '../contexts/mobile-header-context'
import { globalTranslationTable } from '../util/global-translation-table'
import { translateString } from 'shared-libs/helpers/utils'

const translatedTitle = translateString('Page_Not_Found', globalTranslationTable)
const translatedBody = translateString('Page_Not_Found_Message', globalTranslationTable)

@withMobileHeader({ title: translatedTitle })
export class Mobile404 extends React.PureComponent {
  render() {
    return (
      <div className="u-bumper--lg">
        <p>{translatedBody}</p>
      </div>
    )
  }
}
