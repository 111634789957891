import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/header/_header.scss'

export interface IHeaderProps extends IBaseProps {
  title: string
}

export class Header extends React.Component<IHeaderProps, any> {

  public render() {
    const { className, title } = this.props
    return (
      <div className={classNames('c-header', className)}>
        <h6>{title}</h6>
      </div>
    )
  }

}
