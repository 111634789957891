import React from 'react'
import { DragSource } from 'react-dnd'
import styled from 'styled-components'

import { DragBox } from 'browser/components/atomic-elements/organisms/entity/bulk-add/drag-box'
import { DragTypes } from 'browser/components/atomic-elements/organisms/entity/bulk-add/drag-types'

const Wrapper = styled.div`
  padding: 0.25rem 0.5rem;
  border: 1px dashed #f89939;
  font-weight: 600;
  cursor: grab;
  border-radius: 3px;
`

const fieldDragHandlers = {
  beginDrag(props) {
    return { field: props.field, panelX: props.panelX, panelY: props.panelY }
  },
}

interface IFieldDragSourceProps {
  isDragging: boolean
  connectDragSource: any
  connectDragPreview: any
  field: { label; id; required }
  panelX: number
  panelY: number
}

class InnerFieldDragSource extends React.Component<IFieldDragSourceProps> {

  public render() {
    const { field, connectDragSource, isDragging } = this.props
    const style = {
      display: isDragging ? 'none' : 'block',
      marginRight: '0.75rem',
    }

    return (
      <div style={style}>
        {connectDragSource(<div><DragBox label={field.label} /></div>)}
      </div>
    )
  }
}

function mapDragStateToProps(connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }
}

const FieldDragSource = DragSource(DragTypes.FIELD, fieldDragHandlers, mapDragStateToProps)(InnerFieldDragSource)
export { FieldDragSource }
