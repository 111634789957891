import React, { useState } from 'react'
import _ from 'lodash'

import {
  PlatformType,
  StoryboardPlanEntity,
} from 'shared-libs/models/types/storyboard/storyboard-plan'
import { User } from 'shared-libs/models/types/user'
import { Scene, Story } from 'shared-libs/models/types/storyboard/storyboard-plan-model'
import { StoryboardStory } from 'shared-libs/models/types/storyboard/storyboard-story'
import { SectionHeader } from '../atomic-elements/atoms/section/section-header/section-header'

import 'browser/components/containers/_scene-select.scss'

const EXPANDED_BY_DEFAULT = false

interface ISceneSelectProps {
  plan: StoryboardPlanEntity
  user: User
  currentScene: Scene
  goToStory: (story: StoryboardStory) => void
}

export const SceneSelect = (props: ISceneSelectProps) => {
  const { currentScene, goToStory, plan, user } = props
  const [isOpen, setIsOpen] = useState(EXPANDED_BY_DEFAULT)
  const stories = plan.getStoriesForUser(user, PlatformType.WEB)

  const renderSceneRow = (scene) => {
    const activeClass = currentScene === scene ? 'c-sceneRow--active' : ''
    const className = `c-sceneRow ${activeClass} u-bumperTop--sm u-bumperBottom--sm`

    return (
      <div className={className} key={scene.id}>
        {scene.name}
      </div>
    )
  }

  const renderStorySelector = (story: StoryboardStory) => {
    const scenes = story.getScenes()
    const scenesContent = scenes.map((scene) => renderSceneRow(scene))

    return (
      <div key={story.data.id}>
        <div className="c-storyHeader" onClick={() => goToStory(story)}>
          {story.data.name}
        </div>
        {scenesContent}
      </div>
    )
  }

  return (
    <div className="c-sceneSelect u-bumperBottom u-bumperLeft--xl u-bumperRight--xl">
      <SectionHeader
        isCollapsable={true}
        isCollapsed={!isOpen}
        onIsCollapsedChange={(isCollapsed) => {
          setIsOpen(!isCollapsed)
        }}
        title={currentScene.name}
      />
      {isOpen && (
        <div className="u-bumperTop">{stories.map((story) => renderStorySelector(story))}</div>
      )}
    </div>
  )
}
