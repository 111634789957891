import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { Entity } from 'shared-libs/models/entity'
import { Store } from 'shared-libs/models/store'

import apis from 'browser/app/models/apis'
import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'

import 'browser/components/atomic-elements/organisms/inbox/_inbox-card-item.scss'

interface IInboxCardItemProps extends IBaseProps {
  match: any
  notification: Entity
}

interface IInboxCardItemState extends IBaseProps {
  feedEvent: Entity
  isLoading: boolean
  isTogglingFollow: boolean
  share: Entity
  sharedEntity: Entity
}

export class InboxCardItem extends React.Component<IInboxCardItemProps, IInboxCardItemState> {

  private store: Store

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      feedEvent: null,
      isLoading: true,
      isTogglingFollow: false,
      share: null,
      sharedEntity: null,
    }
  }

  public componentDidMount() {
    const { notification } = this.props
    const feedEventId = notification.get('notification.entity.entityId')
    const shareId = notification.get('notification.share.entityId')
    const promises = [
      this.store.findRecord(feedEventId),
      this.store.findRecord(shareId),
    ]
    Promise.all(promises).then((result) => {
      const [feedEvent, share] = result
      const shareEntityId = _.get(share, 'share.entity.entityId')
      this.store.findRecord(shareEntityId).then((sharedEntity) => {
        this.setState({
          feedEvent,
          isLoading: false,
          share,
          sharedEntity,
        })
      })
    })
  }

  public render() {
    const { match, notification } = this.props
    const { feedEvent, isLoading, isTogglingFollow, share, sharedEntity } = this.state
    if (isLoading) {
      return null
    }
    const isArchived = notification.get('notification.isArchived')
    // if (isArchived) {
    //   return null
    // }
    const isFollowing = share.get('share.shouldSendNotification')
    const mutatorName = notification.get('notification.mutator.displayName', '')
    const entityId = feedEvent.get('feedEvent.entity.entityId')
    const title = `${sharedEntity.entityType}: ${sharedEntity.displayName}`

    let isShare = false
    let isComment = false
    let isChange = false
    let content = ''
    if (_.get(feedEvent, 'share')) {
      isShare = true
      content = ' shared something.'
    } else if (_.get(feedEvent, 'comment')) {
      isComment = true
      content = ' added a comment.'
    } else {
      isChange = true
      content = ' changed a value.'
    }

    return (
      <NavLink
        activeClassName='is-active'
        className='c-inboxCardItem-container'
        to={{
          pathname: `${match.url}/entity/${entityId}`,
        }}
      >
        <div className='c-boxListItem c-inboxCardItem'>
          <div className='c-inboxCardItem-content'>
            <h4>{title}</h4>
            <div className='u-flex u-alignItemsCenter'>
              <Avatar
                className='u-bumperRight--xs'
                name={mutatorName}
                size='xs'
              />
              <p>{mutatorName} {content}</p>
            </div>
            <div className='tr'>
              <Button
                className={Classes.MINIMAL}
                isLoading={isTogglingFollow}
                onClick={this.toggleFollowEntity}
                size='sm'
              >
                {isFollowing ? 'Unfollow' : 'Follow'} {sharedEntity.entityType}
              </Button>
            </div>
          </div>
          <Button
            className={classNames(
              'c-button--icon c-boxListItem-button c-boxListItem-button--fadeIn',
              Classes.MINIMAL
            )}
            onClick={this.handleArchive}
          >
            <Icon
              icon={IconNames.CROSS}
            />
          </Button>
        </div>
      </NavLink>
    )
  }

  private handleArchive = () => {
    const { notification } = this.props
    notification.set('notification.isArchived', true)
    notification.save()
  }

  private toggleFollowEntity = () => {
    const { share } = this.state
    const isFollowing = share.get('share.shouldSendNotification')
    share.set('share.shouldSendNotification', !isFollowing)
    this.setState({ isTogglingFollow: true })
    share.save().finally(() => this.setState({ isTogglingFollow: false }))
  }

}
