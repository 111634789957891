import React from 'react'

import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { browserHistory } from 'browser/history'
import { Entity } from 'shared-libs/models/entity'
import { EntityProps } from 'shared-libs/models/prop-constants'

export class MergeUsersProgressToast {
  public showToastAndExecuteRequest(mergeUserRequest, destinationUser: Entity) {
    const toastKey = Toast.show({
      message: (
        <div className='u-flex u-justifyContentSpaceBetween' data-debug-id='toast'>
          <div className='u-ellipsis'>
            Merging Users
          </div>
        </div>
      ),
      position: Position.BOTTOM_RIGHT,
      timeout: 0,
    })

    mergeUserRequest.save().then(() => {
      destinationUser.waitUntilIdle().finally(() => {
        // Do this on success or failure
        browserHistory.push({
          pathname: `/view/3de75624-7f7f-4f14-b313-299266ec16c4/entity/${destinationUser.get(EntityProps.ID)}`,
        })
        Toast.dismiss(toastKey)
      })
    })
  }
}
