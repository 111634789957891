import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface ILocationMarkerProps extends IBaseProps {
  entity?: object
  onClick: () => void
  onMouseOver: () => void
  onMouseOut: () => void
  style?: object
  modifiers?: (entity: any) => any
  viewport?: object
}

export class LocationMarker extends React.Component<ILocationMarkerProps, any> {
  public static height = 33
  public static width = 22

  public static defaultProps: Partial<ILocationMarkerProps> = {
    modifiers: () => ({}),
  }

  public render() {
    const { entity, modifiers, onClick, onMouseOver, onMouseOut, style } = this.props
    const { associated, fade20, fade50, hovered, selected, origin, destination } = modifiers(entity)
    return (
      <div
        className={classNames('c-locationMarker', {
          'c-locationMarker--associated': associated,
          'c-locationMarker--destination': destination,
          'c-locationMarker--fade20': fade20,
          'c-locationMarker--fade50': fade50,
          'c-locationMarker--hovered': hovered,
          'c-locationMarker--origin': origin,
          'c-locationMarker--selected': selected,
        })}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        style={style}
      >
        <div
          className={classNames('c-locationMarker-circle', {
            'c-locationMarker-circle--associated': associated,
            'c-locationMarker-circle--destination': destination,
            'c-locationMarker-circle--fade20': fade20,
            'c-locationMarker-circle--fade50': fade50,
            'c-locationMarker-circle--hovered': hovered,
            'c-locationMarker-circle--origin': origin,
            'c-locationMarker-circle--selected': selected,
          })}
        />
        <div
          className={classNames('c-locationMarker-pickupIcon u-hide')}
        >
          &uarr;
        </div>
        <div
          className={classNames('c-locationMarker-dropoffIcon u-hide')}
        >
          &darr;
        </div>
      </div>
    )
  }
}
