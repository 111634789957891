import _ from 'lodash'
import React from 'react'
import classNames from 'classnames'
import { Icon } from '@blueprintjs/core'
import apis from 'browser/app/models/apis'
import { IInputProps, Input } from './input'
import { IconNames } from '@blueprintjs/icons'
import { Entity } from 'shared-libs/models/entity'
import { TryAsync } from 'shared-libs/helpers/utils'
import { Edge } from 'shared-libs/generated/server-types/entity'
import { LoadingSpinner } from '../loading-spinner/loading-spinner'
import { FramesManager } from 'shared-libs/components/view/frames-manager'

import './_blind-match-input.scss'

const VectorLogo = require('images/navigation/vector-logo-white.svg')

interface BlindMatchValue {
  verified?: boolean
  value: string
}

enum LabelStatus {
  Loading,
  Precheck,
  Failed,
  Verified,
  Unavailable,
}

/**
 * @uiComponent
 */
interface IBlindMatchInputProps extends IInputProps {
  frames: FramesManager
  entity: Entity | Edge
  /** Optional source value, supplied directly as a property. */
  source?: string
  /** Optional path on the entity where the target string value is located. */
  sourcePath?: string
  // /** Optional key to use when sourcing the data from remote secret string API. */
  // secretStringPath?: string
  value?: BlindMatchValue
  onChange?: (value: BlindMatchValue) => void
  placeholder?: string
}

interface IBlindMatchInputState {
  labelStatus?: LabelStatus
  entity?: Entity
}

export class BlindMatchInput extends React.Component<IBlindMatchInputProps, IBlindMatchInputState> {
  constructor(props: IBlindMatchInputProps) {
    super(props)
    this.state = {}
  }

  private isResolvingEntity = false

  public componentDidMount() {
    const { value } = this.props
    void this.computeVerification(value?.value)
  }

  public componentDidUpdate(prevProps: Readonly<IBlindMatchInputProps>): void {
    const { source, entity, value } = this.props
    const { source: prevSource, entity: prevEntity } = prevProps

    const hasNewEdge = this.shouldResolveEntity(entity, prevEntity)

    if (hasNewEdge) {
      void this.resolveEntity()
    } else if (!_.isEqual(source, prevSource)) {
      void this.computeVerification(value?.value)
    }
  }

  private shouldResolveEntity = (entity: Entity | Edge, prevEntity: Entity | Edge): boolean => {
    const { entity: resolvedEntity } = this.state
    if (!entity) {
      return false
    } else if (entity instanceof Entity) {
      // we have an entity and haven't captured it yet (disregard prevEntity)
      return !resolvedEntity
    } else {
      // it's an edge
      const prev = prevEntity as Edge | undefined
      return !resolvedEntity || entity.entityId !== prev?.entityId
    }
  }

  public render() {
    const { labelStatus } = this.state
    const { value } = this.props
    const textValue = value?.value
    const placeholder = labelStatus === LabelStatus.Unavailable ? '' : this.props.placeholder
    const props = { ...this.props, placeholder }

    return (
      <div className="flex w-100">
        <Input
          {...props}
          value={textValue}
          className="inline-flex"
          isDisabled={labelStatus === LabelStatus.Loading}
          onChange={this.computeVerification}
          rightView={<StatusIndicator labelStatus={labelStatus} />}
        />
      </div>
    )
  }

  /* resolves the entity, if it's an edge, and compute */
  private resolveEntity = async () => {
    const { entity, value } = this.props

    if (this.isResolvingEntity) {
      // not done yet, bail
      return
    }

    if (entity instanceof Entity) {
      // nothing to do, just save the props entity into state
      this.setState({ entity: entity })
    } else {
      // resolve the edge
      this.isResolvingEntity = true
      const resolvedEntity = await TryAsync(() => apis.getStore().getOrFetchRecord(entity.entityId))
      this.isResolvingEntity = false

      if (!resolvedEntity) {
        // couldn't resolve, bail out
        return
      }

      this.setState({ entity: resolvedEntity }, () => this.computeVerification(value?.value))
    }
  }

  // TODO: when adding support for remote verification, add a debounce
  private computeVerification = async (text: string | undefined) => {
    // const { secretStringPath } = this.props
    // const needsRemoteVerification = !!secretStringPath

    // if (needsRemoteVerification) {
    // this.computeVerificationAsync(text)
    // } else {
    this.computeVerificationSync(text)
    // }
  }

  /* async path - the data is secret, need to check match remotely */
  private computeVerificationAsync = async (value: string | undefined) => {
    const { /* secretStringPath, */ onChange } = this.props

    const newValue: BlindMatchValue = { value }

    this.setState({ labelStatus: LabelStatus.Loading })
    // TODO: https://withvector.atlassian.net/browse/VD-12549 - call server to get a match status
    // TODO: https://withvector.atlassian.net/browse/VD-12547 - implement a retry button
    // this.setState({ labelStatus: ... })

    onChange?.(newValue)
  }

  /* sync path - we have the data in plaintext, just check match locally */
  private computeVerificationSync = (value: string | undefined) => {
    const { onChange } = this.props

    const sourceValue = this.getSourceValue()
    const { verified, labelStatus } = this.getVerificationStatus(sourceValue, value)

    onChange?.({
      value,
      verified,
    })

    this.setState({ labelStatus })
  }

  private getSourceValue = () => {
    const { source, sourcePath } = this.props
    const { entity } = this.state

    if (source) {
      return source
    } else if (sourcePath && entity) {
      return entity.get(sourcePath)
    } else {
      return null
    }
  }

  private getVerificationStatus(
    sourceValue: any,
    value: string
  ): { verified: boolean; labelStatus: LabelStatus } {
    if (!_.isNil(sourceValue)) {
      if (!sourceValue) {
        return {
          labelStatus: LabelStatus.Unavailable,
          verified: undefined,
        }
      } else if (!value) {
        return {
          labelStatus: LabelStatus.Precheck,
          verified: undefined,
        }
      } else if (_.isEqual(_.toLower(value), _.toLower(sourceValue))) {
        return {
          labelStatus: LabelStatus.Verified,
          verified: true,
        }
      } else {
        return {
          labelStatus: LabelStatus.Failed,
          verified: false,
        }
      }
    } else {
      return {
        labelStatus: LabelStatus.Unavailable,
        verified: undefined,
      }
    }
  }
}


const statusToText: { [K in LabelStatus]: string | null } = {
  [LabelStatus.Loading]: null,
  [LabelStatus.Precheck]: 'Precheck',
  [LabelStatus.Failed]: 'Match Failed',
  [LabelStatus.Verified]: 'Match Verified',
  [LabelStatus.Unavailable]: 'Match Unavailable',
}

interface IStatusIndicatorProps {
  labelStatus: LabelStatus
  style?: React.CSSProperties
}

const StatusIndicator: React.FC<IStatusIndicatorProps> = ({ labelStatus }) => {
  const text = statusToText[labelStatus]

  return (
    <div
      className={classNames('c-blindMatch-statusIndicator', {
        'c-blindMatch-statusIndicator--failed': labelStatus === LabelStatus.Failed,
        'c-blindMatch-statusIndicator--loading': labelStatus === LabelStatus.Loading,
        'c-blindMatch-statusIndicator--precheck': labelStatus === LabelStatus.Precheck,
        'c-blindMatch-statusIndicator--verified': labelStatus === LabelStatus.Verified,
        'c-blindMatch-statusIndicator--unavailable': labelStatus === LabelStatus.Unavailable,
      })}
    >
      <span className="c-blindMatch-icon">
        <StatusIcon labelStatus={labelStatus} />
      </span>
      <span>{text}</span>
    </div>
  )
}

const StatusIcon: React.FC<{ labelStatus: LabelStatus }> = ({ labelStatus }) => {
  if (labelStatus === LabelStatus.Loading) {
    return <LoadingSpinner />
  } else if (labelStatus === LabelStatus.Precheck) {
    return <img className="c-blindMatch-vectorLogo c-logo" src={VectorLogo} />
  } else if (labelStatus === LabelStatus.Failed) {
    return <Icon icon={IconNames.DELETE} />
  } else if (labelStatus === LabelStatus.Verified) {
    return <Icon icon={IconNames.CONFIRM} />
  } else {
    return null
  }
}
