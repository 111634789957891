import _ from 'lodash'

import { useEffect, useState } from 'react'

import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'

const CUSTOMER_TYPE = '/1.0/entities/metadata/customer.json'

// TODO: define the response type after ESLint is upgraded (VD-6037)
export const useFetchCustomer = (id): any => {
  const [result, setResult] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const customerDataSource = new EntityDataSource({
    entityType: CUSTOMER_TYPE,
    filters: [
      {
        path: 'uniqueId',
        type: 'match',
        value: id,
      },
    ],
  }).setOnChange((res) => {
    const customer = _.first(res)
    const entity = _.get(customer, 'data', null)
    setResult(entity)
    setIsLoading(false)
  })

  useEffect(() => {
    _.isEmpty(id) ? setIsLoading(false) : customerDataSource.find()
    return () => customerDataSource.dispose()
  }, [])

  return [result, isLoading]
}
