import _ from 'lodash'
import { Query } from 'shared-libs/models/query'

export class FilterOptionsCollection {

  public query: string

  protected collection: any
  protected apis: any
  protected activeFilters: any
  protected entitySchema: any
  protected filter: any

  constructor(apis, activeFilters, entitySchema, filter) {
    this.activeFilters = activeFilters
    this.entitySchema = entitySchema
    this.filter = filter

    this.collection = new Query(apis)
      .setEntityType(entitySchema.id)
      .setGroups(this.getGroups())
      .setShouldIncludeLeafEntities(false)
      .setSize(25)
      .setMetadata(_.get(filter, 'metadata'))
      .getCollection()
  }

  public cancel() {
    this.collection?.cancel()
  }

  public find(forceFetch?: boolean) {
    this.collection.query.setFilters(this.getFilters())
    return this.collection.find(forceFetch)
  }

  public hasNext() {
    return this.collection.hasNext()
  }

  public next() {
    return this.collection.next()
  }

  protected getFilters() {
    const { activeFilters, filter, query } = this
    // We want to reject the current filter from the active filters
    // otherwise the only suggestions returned from the suggestion route
    // will only show the selected options in the suggestions
    const filters: any[] = _.reject(activeFilters, { path: filter.path })
    if (query) {
      filters.push({
        path: filter.path,
        type: 'query',
        value: query,
      })
    }
    return filters
  }

  protected getGroups() {
    const filterType = this.filter.type
    const groupType = filterType === 'matchEdge' || filterType === 'containsEdge' ? 'edge' : 'field'
    return [{
      path: this.filter.path,
      type: groupType,
    }]
  }
}
