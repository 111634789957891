import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import { JSONSchemaResolver } from 'shared-libs/resolvers/json-schema-resolver'

import { Logger } from 'browser/apis/logging'
import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { DateRangeFilterItem } from './date-range-filter-item'
import { EmployeeFilterItem } from './employee-filter-item'
import { GeolocationFilterItem } from './geolocation-filter-item'
import { MultiSelectFilterItem } from './multi-select-filter-item'
import { RelativeDateRangeFilterItem } from './relative-date-range-filter-item'

interface IFilterListProps extends IBaseProps {
  availableFilters: any[]
  blacklistedFilters?: any[]
  entitySchema: object
  filters: any[]
  isButton?: boolean
  isClearable?: boolean
  itemClassName?: string
  onChange: (values: any[]) => void
  showFilterValue?: boolean
  tetherOptions: object
}

const FILTER_ITEM_MAP = {
  contains: MultiSelectFilterItem,
  containsEdge: MultiSelectFilterItem,
  geoBounds: GeolocationFilterItem,
  geoDistance: GeolocationFilterItem,
  geolocation: GeolocationFilterItem,
  match: MultiSelectFilterItem,
  matchEdge: MultiSelectFilterItem,
  matchLanePoint: GeolocationFilterItem,
  prefix: GeolocationFilterItem,
  range: RelativeDateRangeFilterItem,
  plainRange: DateRangeFilterItem,
}

export class FilterList extends React.Component<IFilterListProps, any> {

  public static get defaultProps(): Partial<IFilterListProps> {
    return {
      filters: [],
    }
  }

  private schemaResolver: JSONSchemaResolver

  constructor(props) {
    super(props)
    this.schemaResolver = new JSONSchemaResolver(apis)
  }

  public render() {
    const { availableFilters, blacklistedFilters, children, isButton } = this.props
    const allFilters = _.filter(availableFilters, (filter) => {
      return !_.find(blacklistedFilters, { path: filter.path })
    })
    const uniqFilters = _.uniqBy(allFilters, f => f.path)
    return (
      <div
        className={classNames({
          'c-dropdownList': !isButton,
          [Classes.BUTTON_GROUP]: isButton,
        })}
      >
        {children}
        {_.map(uniqFilters, this.handleRenderFilter)}
      </div>
    )
  }

  // TODO:(Peter/David) Need to think about the filter keys, need this function
  // since we have a normalizedKey now and need to find the normalizedKey now instead of the
  // normal key.... do we need the ' || newFilter.path'?? since we are adding normalized everywhere now?
  private findFilterIndex = (filters, newFilter) => {
    const newFilterKey = newFilter.key || newFilter.path
    return _.findIndex(filters, (filter: any) => {
      const filterKey = filter.key || filter.path
      return filterKey === newFilterKey
    })
  }

  private handleClearFilter = (filter) => {
    const { filters } = this.props
    // TODO: (Peter/David) Filter Key change
    const index = this.findFilterIndex(filters, filter)
    if (index >= 0) {
      _.pullAt(filters, index)
    }
    this.props.onChange(filters)
  }

  private handleFiltersChange = (filter, isConfig=false) => {
    // isConfig is used to specify whether the change is configuration (e.g. include/exclude)
    // or if the change relates to the core filter value (e.g. date range)
    // we can't send a filter without values to the server
    // TODO(joco) -- turn filters into a class/object that can say when it's "complete"

    const { filters } = this.props
    const index = this.findFilterIndex(filters, filter)
    if (index < 0) {
      if (isConfig) {
        return
      }

      filters.push(filter)
    } else {
      filters[index] = filter
    }
    const filterLabels = _.map(filters, (fil) => fil.label)

    // This will only log when users change the filters on the tableview.
    Logger.logEvent('Selected Filters', { filterLabels } )
    this.props.onChange(filters)
  }

  private handleRenderFilter = (filter, index) => {
    const {
      entitySchema, filters, isButton, isClearable, itemClassName,
      showFilterValue, tetherOptions,
    } = this.props
    // see if there are any existing active filters
    // TODO: (Peter/David) Filter Key change
    const filterIndex = this.findFilterIndex(filters, filter)
    filter = filterIndex >= 0 ? filters[filterIndex] : filter
    let FilterItemComponent =
        FILTER_ITEM_MAP[filter.filterType] || FILTER_ITEM_MAP[filter.type]
    if (filter.path === 'createdBy') {
      FilterItemComponent = EmployeeFilterItem
    }
    if (_.isNil(FilterItemComponent)) {
      return
    }

    const isFilterClearable = _.get(filter, 'isClearable', isClearable)
    return (
      <FilterItemComponent
        activeFilters={filters}
        className={itemClassName}
        entitySchema={entitySchema}
        filter={filter}
        isButton={isButton}
        isClearable={isFilterClearable}
        key={filter.path}
        onChange={this.handleFiltersChange}
        onClear={() => this.handleClearFilter(filter)}
        showFilterValue={showFilterValue}
        tetherOptions={tetherOptions}
      />
    )
  }
}
