import React from 'react'
import { Settings } from 'browser/app/models/settings'

interface IAppNavigatorContext {
  settings: Settings
}

export const AppNavigatorContext = React.createContext<IAppNavigatorContext>({
  settings: null,
})
