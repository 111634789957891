import _ from 'lodash'
import React from 'react'

import { ISheetProps, Sheet } from 'browser/components/atomic-elements/atoms/sheet'
import { SheetContext } from './sheet-manager'

export interface ISheetPortalProps extends ISheetProps {
  isOpen?: boolean
  openOverlay?: any
  updateOverlay?: any
}

class SheetPortal extends React.Component<ISheetPortalProps, any> {

  private sheet: Sheet
  private sheetId: number

  public componentDidMount() {
    this.renderPortal(this.props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.isEqual(this.props, nextProps)) {
      return
    }

    this.renderPortal(nextProps)
  }

  public componentWillUnmount() {
    this.close()
  }

  public close() {
    if (this.sheet) {
      this.sheet.close()
      this.sheet = null
      this.sheetId = null
    }
  }

  public render() {
    return null
  }

  private renderSheet(props) {
    const handleRef = (ref) => this.sheet = ref
    return (
      <Sheet
        {...props}
        ref={handleRef}
      >
        {props.children}
      </Sheet>
    )
  }

  private renderPortal(props) {
    if (props.isOpen) {
      const sheetElement: any = this.renderSheet(props)
      if (!_.isNil(this.sheetId)) {
        props.updateOverlay(this.sheetId, sheetElement)
      } else {
        this.sheetId = props.openOverlay(sheetElement)
      }
    } else if (!props.isOpen) {
      this.close()
    }
  }
}

export default React.forwardRef((props: ISheetPortalProps, ref: React.Ref<SheetPortal>) => (
  <SheetContext.Consumer>
    {({ openOverlay, updateOverlay }) => (
      <SheetPortal
        {...props}
        openOverlay={openOverlay}
        updateOverlay={updateOverlay}
        ref={ref}
      />
    )}
  </SheetContext.Consumer>
))
