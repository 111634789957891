import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { ButtonGroupSelect } from 'browser/components/atomic-elements/atoms/select/button-group-select/button-group-select'

export interface INegatableFilterProps extends IBaseProps {
  filter: object
  onChange: (value: any, ...args) => void
}

export class NegatableFilter extends React.Component<INegatableFilterProps, any> {
  private handleNegationChange = (isNegation) => {
    const { filter } = this.props
    filter['isNegation'] = isNegation
    this.props.onChange(filter, true)
  }

  public render() {
    const { filter } = this.props

    const negationOptions = [
      {
        label: "Include",
        value: false,
      },
      {
        label: "Exclude",
        value: true,
      },
    ]

    return <ButtonGroupSelect
      onChange={this.handleNegationChange}
      options={negationOptions}
      value={filter['isNegation'] || false}
    />
  }
}
