import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment-timezone'
import React from 'react'

import 'browser/components/atomic-elements/atoms/input/_time-input.scss'
import { ITextMaskInputProps, TextMaskInput } from 'browser/components/atomic-elements/atoms/input/text-mask-input'

export const timeMask = [/\d/, /\d/, ':', /\d/, /\d/]

export function createAutoCorrectedTimePipe(dateFormat = 'HH MM') {
  return (conformedValue) => {
    const indexesOfPipedChars = []
    const dateFormatArray = dateFormat.split(/[^HM]+/)
    const maxValue = { HH: 23, MM: 59 }
    const minValue = { HH: 0, MM: 0 }
    const conformedValueArr = conformedValue.split('')

    // Check first digit
    dateFormatArray.forEach((format) => {
      const position = dateFormat.indexOf(format)
      const maxFirstDigit = parseInt(maxValue[format].toString().substr(0, 1), 10)
      if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
        conformedValueArr[position + 1] = conformedValueArr[position]
        conformedValueArr[position] = 0
        indexesOfPipedChars.push(position)
      }
    })

    // Check for invalid time
    const isInvalid = dateFormatArray.some((format) => {
      const position = dateFormat.indexOf(format)
      const length = format.length
      const textValue = conformedValue.substr(position, length).replace(/\D/g, '')
      const value = parseInt(textValue, 10)
      return value > maxValue[format] || (textValue.length === length && value < minValue[format])
    })

    if (isInvalid) {
      return false
    }

    return {
      indexesOfPipedChars,
      value: conformedValueArr.join(''),
    }
  }
}

function getTimeLabel(hours, minutes, isMilitaryTime = true) {
  const minutesName = minutes < 10 ? `0${minutes}` : minutes
  if (isMilitaryTime) {
    const hoursName = hours < 10 ? `0${hours}` : hours
    return `${hoursName}:${minutesName}`
  } else {
    const hoursName = hours % 12 === 0 ? 12 : hours % 12
    const timeOfDay = hours < 12 ? 'AM' : 'PM'
    return `${hoursName}:${minutesName} ${timeOfDay}`
  }
}

/**
 * Implements the time-input's `getNextStateFromProps`.
 */
export function deriveState(props: ITimeInputProps): ITimeInputState {
  const { value, timezoneId } = props

  const defaultState = {
    date: moment.tz(timezoneId).startOf('day'),
    time: '',
  }

  if (_.isEmpty(value)) {
    return defaultState
  }

  const date = moment.tz(value, moment.ISO_8601, true, props.timezoneId)
  if (!date.isValid()) {
    return defaultState
  }

  const hours = date.hours()
  const minutes = date.minutes()
  const time = getTimeLabel(hours, minutes)
  return { date, time }
}

/**
 * @uiComponent
 */
export interface ITimeInputProps extends ITextMaskInputProps {
  onChange?: (value: string) => void
  value?: string
  size?: string
  timezoneId?: string
}

export interface ITimeInputState {
  date: any
  time: string
}

export class TimeInput extends React.Component<ITimeInputProps, ITimeInputState> {
  static defaultProps: Partial<ITimeInputProps> = {
    timezoneId: moment.tz.guess(),
  }

  constructor(props: ITimeInputProps) {
    super(props)
    this.state = this.getNextStateFromProps(props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    // VD-10864
    // our date-time inputs only operate on date + hours + minutes
    // but someone up the stack is adding seconds + millis, which breaks the equality check
    const oldDateTime = moment(this.props.value)
    const newDateTime = moment(nextProps.value)

    if (!oldDateTime.isSame(newDateTime, 'minutes')) {
      this.setState(this.getNextStateFromProps(nextProps))
    }
  }

  private getNextStateFromProps(props): ITimeInputState {
    return deriveState(props)
  }

  public render() {
    const { inputClassName } = this.props
    const { time } = this.state
    return (
      <TextMaskInput
        {...this.props}
        inputClassName={classNames('c-timeInput', inputClassName)}
        onChange={this.handleChange}
        guide={false}
        keepCharPositions={false}
        mask={timeMask}
        pipe={createAutoCorrectedTimePipe()}
        placeholder='hh:mm'
        value={time}
      />
    )
  }

  private handleChange = (value: string) => {
    const { onChange } = this.props
    this.setState({ time: value })
    if (_.isEmpty(value) || !value.match(/\d\d:\d\d/)) {
      return onChange(undefined)
    }
    const tokens = value.split(':')
    const hours = parseInt(tokens[0])
    const minutes = parseInt(tokens[1])
    const { date } = this.state
    date.hours(hours)
    date.minutes(minutes)

    onChange(date.toISOString())
  }
}
