import { Icon, Position, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import React from 'react'

import apis from 'browser/app/models/apis'
import { Query } from 'shared-libs/models/query'

import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import 'browser/components/atomic-elements/atoms/select/_user-select.scss'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { EntitySelectPopover } from './popover'

interface IUserSelectProps extends IBaseProps {
  entityType: string
  onChange: (value: any) => void
  value: any
}

interface IUserSelectState {
  inputValue: string
  isLoading: boolean
  isOpen: boolean
  scrollToIndex: number
}

export class UserSelect extends React.Component<IUserSelectProps, IUserSelectState> {

  private collection: any
  private store: any
  private tetherTarget: TetherTarget

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      inputValue: undefined,
      isLoading: false,
      isOpen: false,
      scrollToIndex: 0,
    }
    this.collection = new Query(apis)
      .setEntityType(props.entityType)
      .getCollection()
  }

  public componentWillUnmount() {
    this.collection?.cancel()
  }

  public focus() {
    // TODO(Peter): focus select
  }

  public render() {
    const { entityType, onChange, value } = this.props
    const { inputValue, scrollToIndex } = this.state
    const tethered = (
      <EntitySelectPopover
        collection={this.collection}
        entityType={entityType}
        inputValue={inputValue}
        optionRenderer={this.handleRenderOption}
        scrollToIndex={scrollToIndex}
        value={value}
      />
    )
    const tetherOptions = {
      attachment: 'top left',
      offset: { top: 14, left: -14 },
      targetAttachment: 'bottom left',
    }
    return (
      <TetherTarget
        automaticAdjustOffset={true}
        closeOnTetherTargetClick={false}
        onOpen={() => this.setState({ isOpen: true })}
        onClose={() => this.setState({ isOpen: false, scrollToIndex: 0 })}
        ref={(ref) => { this.tetherTarget = ref }}
        tethered={tethered}
        tetherOptions={tetherOptions}
      >
        {this.renderTetherTarget()}
      </TetherTarget>
    )
  }

  private handleClearSelection = (event) => {
    const { onChange } = this.props
    event.stopPropagation()
    onChange(undefined)
  }

  private handleKeyDown = (event) => {
    const { scrollToIndex } = this.state
    const numOptions = this.collection.content.length
    if (!numOptions) {
      return
    }
    if (event.keyCode === 38) {
      this.setState({ scrollToIndex: Math.max(scrollToIndex - 1, 0) })
    } else if (event.keyCode === 40) {
      this.setState({ scrollToIndex: Math.min(scrollToIndex + 1, numOptions) })
    }
  }

  private handleSelectionChange = (user) => {
    const { onChange } = this.props
    onChange({
      displayName: user.displayName,
      entityId: user.uniqueId,
    })
    this.tetherTarget.close()
  }

  private handleRenderOption = (options) => {
    const { index, key, option, style } = options
    return (
      <div
        className='c-userSelectPopover-row'
        key={key}
        style={style}
        onClick={() => this.handleSelectionChange(option)}
      >
        <Avatar
          size='xxs'
          user={option}
        />
        <div className='u-bumperLeft--sm u-ellipsis'>
          {option.displayName}
        </div>
      </div>
    )
  }

  private renderTetherTarget() {
    const { value, className } = this.props
    const { isOpen, inputValue } = this.state
    let showPlaceholder = false
    if (!value || inputValue) {
      showPlaceholder = true
    }
    // TODO(peter/louis): How can we make the tooltip not catch the event
    return (
      <div
        className={classNames('c-userSelect-container', className, {
          'c-userSelect-container--open': isOpen,
        })}
      >
        <div className='u-flex u-alignItemsCenter'>
          <Avatar
            className='c-userSelect-selectedAvatar'
            showPlaceholder={showPlaceholder}
            user={value}
            size='xxs'
          />
          {this.renderClosedState()}
          {this.renderOpenState(isOpen)}
        </div>
      </div>
    )
    // return (
    //   <Tooltip
    //     content={this.renderTooltip()}
    //     isDisabled={false}
    //     position={Position.BOTTOM}
    //   >
    //    <div
    //       className={classNames('c-userSelect-container', {
    //         'c-userSelect-container--open': isOpen
    //       })}
    //     >
    //       {content}
    //     </div>
    //   </Tooltip>
    // )
  }

  private renderTooltip() {
    // TODO(peter/louis): This should be a prop
    return (
      <HelpBlock>
        <span className='u-fontWeightSemibold'>Customer Rep</span>
        <hr />
        Usually the person responsible for order entry.
      </HelpBlock>
    )
  }

  private renderOpenState(isOpen) {
    const { value } = this.props
    const { inputValue } = this.state
    if (isOpen) {
      return (
        <Input
          autoFocus={true}
          className='c-userSelect-input'
          onChange={(inputValue) => this.setState({ inputValue })}
          onKeyDown={this.handleKeyDown}
          placeholder={value ? value.displayName : 'Name or email'}
          value={inputValue}
        />
      )
    }
  }

  private renderClosedState() {
    const { value } = this.props
    let content = 'Unassigned'
    let hasValue = false
    if (value) {
      content = value.displayName
      hasValue = true
    }
    return (
      <div className='flex u-alignItemsCenter'>
        <div
          className={classNames('c-cardHeader-label', {
            'c-userSelect-title--placeholder': !hasValue,
          })}
        >
          {content}
        </div>
        {hasValue ? this.renderRemoveButton() : null}
      </div>
    )
  }

  private renderRemoveButton() {
    return (
      <a
        className='c-userSelect-remove'
        onClick={this.handleClearSelection}
      >
        <Icon
          icon={IconNames.CROSS}
        />
      </a>
    )
  }
}
