import _ from 'lodash'
import pluralize from 'pluralize'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { VerificationBlock } from 'browser/components/atomic-elements/organisms/verification-block/verification-block'

interface IIdentityVerificationTabProps extends IBaseProps {
  navigation: any
  unverifiedInvites: any[]
  onClose?: () => void
  settings: Settings
  size?: string
}

interface IIdentityVerificationTabStates {
  isJoiningOrg: boolean
}

export class IdentityVerificationTab
  extends React.Component<IIdentityVerificationTabProps, IIdentityVerificationTabStates> {

  constructor(props) {
    super(props)
    this.state = {
      isJoiningOrg: false,
    }
  }

  public render() {
    const { unverifiedInvites, navigation, size, onClose } = this.props
    const title = unverifiedInvites && unverifiedInvites.length > 1 ? pluralize.plural('Invitation') : 'Invitation'
    const footerAction = onClose ? onClose : navigation.goBack
    return (
      <div>
        <div className='ph4 pb4'>
          {this.renderVerificationBlock()}
          {_.map(unverifiedInvites, this.renderUnverifiedInvite)}
        </div>
        <Footer
          cancelButtonText='Back'
          onCancelButtonClick={footerAction}
          size={size}
        />
      </div>
    )
  }

  private getIdentity() {
    const { unverifiedInvites } = this.props
    const invite: any = _.first(unverifiedInvites)
    if (invite) {
      return invite.get('invite.email') || invite.get('invite.phoneNumber.phone')
    }
  }

  private renderVerificationBlock() {
    const { unverifiedInvites } = this.props
    const identity = this.getIdentity()
    const invitationWord = unverifiedInvites && unverifiedInvites.length > 1 ? pluralize.plural('Invitation') : 'Invitation'
    return (
      <div>
        <h3 className='f3 lh-title mt2 mb0'>
          Enter Verification Code to Accept Pending {invitationWord}
        </h3>
        <div className='mb4 mt1'>
          We{"'"}ve sent you a six-digit verification code to {identity}. It will expire shortly, so enter your code soon.
        </div>
        <VerificationBlock
          identity={identity}
          onSubmit={this.handleVerifyIdentity}
          onSuccess={this.handleIdentifiyVerified}
        />
      </div>
    )
  }

  private renderUnverifiedInvite = (invite, index) => {
    const firmName = invite.get('owner.firm.displayName')
    const identity = this.getIdentity()
    return (
      <div
        className='pa3 tc mt2 ba b--light-gray'
        key={invite.uniqueId}
      >
        <h4 className='f4 lh-copy mt3'>
          {firmName}
        </h4>
        <div className='mb3 center'>
          Please verify {identity} to accept your invitation.
        </div>
        <Button
          className={Classes.INTENT_PRIMARY}
          isDisabled={true}
          size='large'
        >
          Join {firmName}
        </Button>
      </div>
    )
  }

  private handleIdentifiyVerified = () => {
    const { settings } = this.props
    const { navigation } = this.props
    const user = settings.getUser()
    user.user.getPendingInvites().then(({ invites }) => {
      if (_.isEmpty(invites)) {
        navigation.navigate('joinOrganization')
      } else {
        navigation.navigate('existingInvites', { invites })
      }
    })
  }

  private handleVerifyIdentity = (code) => {
    const { settings } = this.props
    const identity = this.getIdentity()
    return apis.confirmVerificationCode(identity, code).then(() => {
      // Reload user after we verify so user is not stale
      return settings.getUser().reload()
    })
  }

}
