import _ from 'lodash'
import React, { Fragment } from 'react'
import moment from 'moment'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
// tslint:disable-next-line:max-line-length
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { browserHistory } from 'browser/history'
import { Formatter } from 'shared-libs/helpers/formatter'
import { Entity } from 'shared-libs/models/entity'
import { EntityMapper } from 'shared-libs/models/entity-mapping'
import { AbstractSettings } from 'shared-libs/models/abstract-settings'
import apis from 'browser/app/models/apis'

interface IImagingSummaryModalProps extends IBaseProps {
  headerTitle: string
  bodyMessage: string
  primaryButtonTitle: string
  processingToastMessage: string
  getDocumentNames?: any
  preSaveCallback?: any  // modify/cleanup any fields before save
  entities: Entity[]
  relatedSalesOrders: Entity[]
  onClose: () => void
  onSave: () => void
  settings: AbstractSettings
}

export class ImagingSummaryModal extends React.Component<IImagingSummaryModalProps, any> {

  public static scrubClassificationTask = (entity) => {
    const { documentClassificationTask } = entity
    const { classificationTasks } = documentClassificationTask

    // update modifiedDate to make it dirty so it could send to the server for update
    entity.set('modifiedDate', moment().toISOString())

    // need to re-set various field in order to re-run imaging triggers
    _.set(documentClassificationTask, 'taskStatus', 'Queued')

    // scrub everything except the attachement
    const tasks = []
    _.forEach(classificationTasks, (task) => {
      task = _.pick(task, ['attachment'])
      tasks.push(task)
    })
    _.set(documentClassificationTask, 'classificationTasks', tasks)

    return entity
  }

  public static getClassificationTaskName = (entity) => {
    const postedTime = Formatter.formatDateTime(entity.creationDate)
    const { classificationTasks } = entity.documentClassificationTask
    const documentNames = []
    _.forEach(classificationTasks, (task) => {
      const loadNumber = _.get(task, 'documentProperties.document.name', null)
      const documentType = _.get(task, 'documentType', null)
      let name = postedTime
      if (loadNumber && documentType) {
        name = `${documentType.displayName}, ${loadNumber}, ${postedTime}`
      }
      documentNames.push(name)
    })
    return _.uniq(documentNames)
  }

  private static defaultPreSaveCallback = (entity) => {
    _.set(entity, 'documentClassificationTask.taskStatus', 'Published')
  }

  public static defaultProps: Partial<IImagingSummaryModalProps> = {
    headerTitle: 'Publish Documents',
    bodyMessage: 'The following documents will be created:',
    processingToastMessage: 'Publishing Tasks...',
    primaryButtonTitle: 'Publish',
    getDocumentNames: ImagingSummaryModal.getClassificationTaskName,
    preSaveCallback: ImagingSummaryModal.defaultPreSaveCallback
  }

  public static open(props) {
    return OverlayManager.openOverlayElement(
      <Modal>
        <ImagingSummaryModal {...props} />
      </Modal>,
    )
  }

  public render() {
    const {
      onClose,
      entities,
      headerTitle,
      bodyMessage,
      primaryButtonTitle,
      getDocumentNames } = this.props
    return (
      <Fragment>
        <div className='c-modalHeader'>
          <h4 className='c-modal-title'>{headerTitle}</h4>
        </div>
        <div className='c-modalBody'>
          {bodyMessage}
          {_.map(entities, (entity) => this.renderDocumentNames(entity))}
        </div>
        <Footer
          isVisible={true}
          cancelButtonText='Cancel'
          primaryButtonText={primaryButtonTitle}
          onCancelButtonClick={onClose}
          onPrimaryButtonClick={this.handleOnSave}
        />
      </Fragment>
    )
  }

  private renderDocumentNames = (entity) => {
    const { getDocumentNames } = this.props
    const names = getDocumentNames(entity)
    return _.map(names, (name, index) => <div key={index}>{name}</div> )
  }

  private applyEntityMappings = (taskEntity): Promise<void[]> => {
    const { relatedSalesOrders, settings } = this.props
    const identifierPath = settings && settings.getOrderIdentifierPath()
    if (!identifierPath) {
      console.warn(`could not find order identifier path, skipping mappings${!settings ? ' (settings undefined)' : ''}`)
      return Promise.resolve([])
    }

    const mapper = (source, destination, destinationEntityTypeId) =>
      EntityMapper.create(apis, settings)
        .fromEntity(source)
        .to(destination, destinationEntityTypeId)
        .execute()

    // map all tasks into promises
    const promises = _.chain(taskEntity)
      .flatMap('documentClassificationTask.classificationTasks')
      .compact()
      .map((task) => {
        const source = _.find(relatedSalesOrders, (order) => {
          const loadNumber = _.get(task, 'documentProperties.document.name')
          const orderIdentifier = _.get(order, identifierPath)
          return loadNumber === orderIdentifier
        })
        const destination = _.get(task, 'documentProperties')
        const docTypeId = _.get(task, 'documentType.entityId')
        return mapper(source, destination, docTypeId)
      })
      .value()

    return Promise.all(promises)
  }

  private handleOnSave = () => {
    const {
      onClose,
      onSave,
      entities,
      processingToastMessage,
      preSaveCallback,
    } = this.props

    const promises = _.map(entities, (entity) => {
      preSaveCallback(entity)
      return this.applyEntityMappings(entity).then(() => entity.save())
    })
    const toastKey = Toast.show({
      message: processingToastMessage,
      position: Position.BOTTOM_RIGHT,
      timeout: 0,
    })
    Promise.all(promises)
      .then((result) => Promise.all(_.map(result, (entity) => entity.waitUntilIdle())))
      .then(() => {
        Toast.update(toastKey, {
          message: (
            <div>
              <a onClick={this.handleOnRedirect}>Publishing Complete!</a>
            </div>
          ),
          position: Position.BOTTOM_RIGHT,
          timeout: 5000,
        })
      })
    onClose()
    onSave()
  }

  private handleOnRedirect = () => {
    browserHistory.push({
      pathname: `/view/11111111-0000-0000-0000-100000000000`,
    })
  }
}
