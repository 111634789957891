import _ from 'lodash'
import React, { useContext } from 'react'

import { Entity } from 'shared-libs/models/entity'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
// tslint:disable-next-line:max-line-length
import { GeneratedDocumentItem } from 'browser/components/atomic-elements/organisms/entity/documents-list/generated-document-item'

const CUSTOMER_RATE_CONFIRMATION_VIEW_ID = '7f36f300-4534-4550-aa09-7dfa806a48ab'
const CARRIER_RATE_CONFIRMATION_VIEW_ID = '6942f1b7-b9d1-4d04-a569-815ce26528eb'
const CUSTOMER_INVOICE_VIEW_ID = '323a1aaa-6c33-4a6d-a20e-10a0b825630e'
const BILL_OF_LADING_VIEW_ID = '7d9e5cfb-041d-48f2-bdf3-bcaaa42b0328'
const BLIND_BILL_OF_LADING_VIEW_ID = '32862717-626b-4b35-ab69-ea4b23f07319'
const DRIVER_DISPATCH_VIEW_ID = 'db15a301-c051-4acb-ac9e-bdc3d2356e76'
const BROKER_TMS = 'BROKER_TMS'
const CARRIER_TMS = 'CARRIER_TMS'

const DOCUMENT_ITEMS = [
  {
    contexts: [BROKER_TMS, CARRIER_TMS],
    label: 'Customer Rate Confirmation',
    viewId: CUSTOMER_RATE_CONFIRMATION_VIEW_ID,
  },
  {
    contexts: [BROKER_TMS],
    label: 'Carrier Rate Confirmation',
    viewId: CARRIER_RATE_CONFIRMATION_VIEW_ID,
  },
  {
    contexts: [BROKER_TMS, CARRIER_TMS],
    label: 'Customer Invoice',
    viewId: CUSTOMER_INVOICE_VIEW_ID,
  },
  {
    contexts: [BROKER_TMS, CARRIER_TMS],
    label: 'Bill of Lading',
    viewId: BILL_OF_LADING_VIEW_ID,
  },
  {
    contexts: [BROKER_TMS, CARRIER_TMS],
    label: 'Bill of Lading (Blind)',
    viewId: BLIND_BILL_OF_LADING_VIEW_ID,
  },
  {
    contexts: [BROKER_TMS, CARRIER_TMS],
    label: 'Driver Dispatch Instructions',
    viewId: DRIVER_DISPATCH_VIEW_ID,
  },
]

interface IGeneratedOrderDocumentsListProps extends IBaseProps {
  entity: Entity
  onSelect: (selection: any[]) => void
  selection: any[]
}

export const GeneratedOrderDocumentsList: React.FC<IGeneratedOrderDocumentsListProps> = (props) => {
  const { entity, selection, onSelect } = props
  const { settings } = useContext(AppNavigatorContext)

  const renderDocumentItems = (documentItems) => {
    const context = settings.isCarrierTMSAppliction() ? CARRIER_TMS : BROKER_TMS
    documentItems = _.filter(documentItems, (item) => _.includes(item.contexts, context))
    return _.map(documentItems, (item: any, index) => (
      <GeneratedDocumentItem
        entity={entity}
        isSelected={_.indexOf(selection, item) >= 0}
        item={item}
        key={index}
        onSelect={handleSelect}
      />
    ))
  }

  const handleSelect = (item) => {
    if (_.indexOf(selection, item) >= 0) {
      _.pull(selection, item)
    } else {
      selection.push(item)
    }
    onSelect(selection.slice())
  }

  return (
    <div>
      <Label className='u-bumperTop c-label--heading'>
        Generated Documents
      </Label>
      {/* Instantly preview and create key documents with the latest order information. Generated documents can be found under the document tab in the right sidebar. To change company information like address, phone number, and invoice terms, please visit account &amp; settings. */}
      <table className='c-table c-table--flushHorizontal c-table--auto c-table--noBorders'>
        <tbody className='c-table-body'>
          {renderDocumentItems(DOCUMENT_ITEMS)}
        </tbody>
      </table>
    </div>
  )
}
