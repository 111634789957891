import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { Store } from 'shared-libs/models/store'
import { getDocumentSchemas } from 'shared-libs/models/utils'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Input } from 'browser/components/atomic-elements/atoms/input/input'
import { IRenderListItemProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import { HtmlTable } from 'browser/components/atomic-elements/atoms/table/html-table'
import { HtmlTableRow } from 'browser/components/atomic-elements/atoms/table/html-table/row'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { MultiMixinRenderer } from 'shared-libs/components/entity/multi-mixin-renderer'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'
import { ClassificationTaskProps } from 'shared-libs/models/prop-constants'
import { Entity } from 'shared-libs/models/entity'

interface IImagingPropertiesPanelProps extends IBaseProps {
  classificationTask: any
  handleNavigateToNextPage: any
  numberOfPages: number
  onChange: (value: any) => void
  pageIndex: number
  previousName: string
  previousDocumentType: any
  settings: Settings
  setLoadInput: any
  entity: Entity
  relatedSalesOrders: Entity[]
}

class ImagingPropertiesPanel extends React.Component<IImagingPropertiesPanelProps, any> {

  private entityRenderer: EntityRenderer
  private store: Store
  private loadNumberInput: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      record: null,
      template: null,
    }
  }

  public componentDidMount() {
    const { classificationTask, setLoadInput } = this.props
    const documentTable = _.get(classificationTask, ClassificationTaskProps.DOCUMENT_TABLE, undefined)

    if (documentTable) {
      const templateId = _.get(classificationTask, ClassificationTaskProps.TEMPLATE_ID)
      this.store.findRecord(templateId).then((template) => {
        this.setState({ template })
      })
    }

    this.getPotentialRecord(classificationTask).then((record) => {
      this.setState({ record })
    })

    setLoadInput(this.loadNumberInput)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { classificationTask, previousName, previousDocumentType } = nextProps
    if (previousName && previousDocumentType) {
      const loadNumber = _.get(classificationTask, 'documentProperties.document.name', null)
      const currentDocumentType = _.get(classificationTask, 'documentType', null)
      if (!loadNumber && (!currentDocumentType || _.isEqual(currentDocumentType, previousDocumentType))) {
        this.handleDocumentNameChange(previousName)
        this.handleDocumentTypeChange(previousDocumentType.entityId)
      }
    }
    this.getPotentialRecord(classificationTask).then((record) => {
      this.setState({ record })
    })
  }

  public focusOnFirstInput() {
    const inputElement = this.entityRenderer.findElement((element) => element.focus)
    if (inputElement) {
      inputElement.focus()
    }
  }

  public render() {
    const { className } = this.props
    return (
      <div className={classNames('grid-block vertical', className)}>
        <div className='grid-content collapse'>
          <div className='pt3 u-bumperLeft--lg u-bumperRight--lg'>
            {this.renderDocumentProperties()}
            {this.renderDocumentTable()}
            <h3 className='mt3 pt3 c-cardHeader-title u-ellipsis'>Related Load Information</h3>
            {this.renderOrderProperties()}
          </div>
        </div>
      </div>
    )
  }

  private getPotentialRecord = (classificationTask) => {
    const { entity } = this.props
    const documentType = _.get(classificationTask, 'documentType', null)
    const documentProperties = _.get(classificationTask, 'documentProperties', {})
    if (documentType) {
      return Promise.resolve(entity.schemaResolver.resolveRemoteSchema(documentType.entityId))
        .then((schemas) => {
          // once it get here, all the schemas should be existed in the store already
          const documentSchema = this.store.getRecord(documentType.entityId)
          return this.store.createRecord(documentSchema, documentProperties)
        }).catch((err) => {
          return null
        })
    }
    return Promise.resolve(null)
  }

  private renderDocumentProperties() {
    const { classificationTask } = this.props
    const documentType = _.get(classificationTask, 'documentType')
    const displayName = _.get(documentType, 'displayName', 'Untyped Document')
    const documentProperties = _.get(classificationTask, 'documentProperties.document', {})
    const documentName = _.get(documentProperties, 'name', '')
    return (
      <div className='grid-block vertical u-overflowHidden'>
        <div className='c-cardHeader-item c-cardHeader-item--grow u-overflowHidden'>
          <h3 className='c-cardHeader-title u-ellipsis'> Properties </h3>
          <div className='c-label c-label--secondary c-cardHeader-description u-ellipsis mb3'>
            {displayName}
          </div>
        </div>
        <div>
          <FormTable>
            <SelectField
              isHorizontalLayout={true}
              label='Document Type'
              onChange={this.handleDocumentTypeChange}
              placeholder='Select Document Type'
              options={this.getDocumentSchemas()}
              optionLabelPath='displayName'
              optionValuePath='uniqueId'
              value={documentType}
            />
            <InputField
              label='Reference Number'
              isHorizontalLayout={true}
              ref={(ref) => { this.loadNumberInput = ref }}
              onChange={this.handleDocumentNameChange}
              onEnterPressed={this.handleNavigateToNextPage}
              value={documentName}
            />
          </FormTable>
          {this.renderProperties()}
          <div tabIndex={0} />
        </div>
      </div>
    )
  }

  private handleNavigateToNextPage = () => {
    const { handleNavigateToNextPage, numberOfPages, pageIndex } = this.props
    if (pageIndex < numberOfPages - 1) {
      handleNavigateToNextPage(pageIndex + 1)
    }
  }

  private renderDocumentTable() {
    const { classificationTask } = this.props
    const { template } = this.state
    if (template && classificationTask) {
      const { templateType, sectionMappings } = template.documentImagingTemplate
      if (templateType === 'Standard-Table' && sectionMappings) {
        const { documentTable } = classificationTask
        const tableHeader = _.filter(sectionMappings, (mapping) => mapping.sectionType === 'Table Header')
        const columns = _.map(tableHeader, (header) => {
          return {
            label: header.label,
            width: 150,
          }
        })

        return (
          <div className='collapse mt3 mb2'>
            <div
              className='pt1 u-bumperLeft--lg u-bumperRight--lg overflow-x-scroll'
              onClick={_.noop}
            >
              <HtmlTable
                columns={columns}
                renderListItem={this.renderLineItem}
                showItemDeleteButton={false}
                value={documentTable}
              />
            </div>
          </div>
        )
      }
    }
  }

  private renderLineItem = (props: IRenderListItemProps): React.ReactElement<any> => {
    const { item, index } = props
    return (
      <HtmlTableRow
        index={index}
        isEditableInline={false}
      >
        {_.map(item, (row) => this.renderCell(row))}
      </HtmlTableRow>
    )
  }

  private renderCell = (text) => {
    const props = {
      cellClassName: 'c-table-cell--collapse',
    }
    return (
      <Input
        isEditableInline={false}
        value={text}
        {...props}
      />
    )
  }

  private renderProperties = () => {
    const { classificationTask } = this.props
    const { record } = this.state
    const documentType = _.get(classificationTask, 'documentType', null)
    if (documentType && record) {
      return (
        <MultiMixinRenderer
          actions={this}
          componentsMap={ComponentsMap}
          context={{
            density: 'collapse',
            isHorizontalLayout: true,
            isStatic: false,
          }}
          shouldOverrideUiSchema={true}
          state={{}}
          onChange={this.handleOnChange}
          onChangeComplete={() => this.forceUpdate()}
          value={record}
          uiSchemaPath={'uiSchema.web.imagingDetailSection'}
        />
      )
    }
    return null
  }

  private handleOnChange = (entity, path) => {
    const { classificationTask } = this.props
    const { documentProperties } = classificationTask
    const joinedPath = _.join(path, '.')
    const value = _.get(entity, joinedPath)
    _.set(documentProperties, joinedPath, value)
  }

  private handleDocumentNameChange = (value) => {
    const { classificationTask, onChange } = this.props
    _.set(classificationTask, 'documentProperties.' + 'document.name', value)
    onChange(classificationTask)
  }

  private handleDocumentTypeChange = (schemaId) => {
    const { classificationTask, onChange } = this.props
    const documentSchema = _.find(this.getDocumentSchemas(), (schema: any) => schema.uniqueId === schemaId)
    _.set(classificationTask, 'documentType.entityId', documentSchema.uniqueId)
    _.set(classificationTask, 'documentType.displayName', documentSchema.displayName)
    onChange(classificationTask)
  }

  private renderOrderProperties() {
    const { relatedSalesOrders } = this.props
    if (_.isEmpty(relatedSalesOrders)) {
      return (
        <div className='pv5 mv3 ba u-darkBorder tc u-bumperLeft--lg u-bumperRight--lg'>
          <HelpBlock helpText='Enter valid reference number' />
        </div>
      )
    }
    return (
      <EntityRenderer
        actions={this}
        componentsMap={ComponentsMap}
        context={{
          density: 'collapse',
          isEditable: true,
          isHorizontalLayout: true,
        }}
        state={this.state}
        uiSchemaPath='uiSchema.web.components.keyDetailsTab'
        value={relatedSalesOrders[0]}
      />
    )
  }

  private getDocumentSchemas() {
    const { settings } = this.props
    const schemas = settings.getBundleSchemas()
    return getDocumentSchemas(this.store, schemas, 'web')
  }

}

export default React.forwardRef((props: IImagingPropertiesPanelProps, ref: React.Ref<ImagingPropertiesPanel>) => (
  <AppNavigatorContext.Consumer>
    {({ settings }) => (
      <ImagingPropertiesPanel {...props} settings={settings} ref={ref} />
    )}
  </AppNavigatorContext.Consumer>
))
