import Promise from 'bluebird'
import apis from 'browser/app/models/apis'
import _ from 'lodash'

import React from 'react'
import queryString from 'query-string'

import { Store } from 'shared-libs/models/store'
import { ViewResolver } from 'shared-libs/resolvers/view-resolver'
import { Settings } from 'browser/app/models/settings'
import { CustomFormulas } from 'shared-libs/helpers/formulas'

import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { evaluateFilters } from 'shared-libs/helpers/evaluation'

// TODO(Peter): viewProps is not great we use it in entity-master-detail-layout
interface IViewsIndexProps extends IBaseProps {
  history: any
  location: any
  match: any
  pageProps?: any
}

interface IViewsIndexState {
  dataSets: any
  isLoading: any
  view: any
  viewIndex: any
  viewProps: any
  viewSchema: any
  error?: any
}

export const ViewsIndex: React.FC<IViewsIndexProps> = (props) => {
  return (
    <AppNavigatorContext.Consumer>
      {({settings}) => (
        <ViewsIndexWithSettings
          {...props}
          settings={settings}
        />
      )}
    </AppNavigatorContext.Consumer>
  )
}

type IViewsIndexWithSettings = IViewsIndexProps & {
  settings: Settings
}

export class ViewsIndexWithSettings extends React.Component<IViewsIndexWithSettings, IViewsIndexState> {

  private requestPromise: Promise<any>
  private store: Store
  private subscription: any
  private viewResolver: ViewResolver
  private handleDataSetChange: any

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.viewResolver = new ViewResolver(this.store)
    this.state = {
      dataSets: null,
      isLoading: true,
      view: null,
      viewIndex: null,
      viewProps: null,
      viewSchema: null,
    }

    this.handleDataSetChange = _.debounce(this._handleDataSetChange, 500)
  }

  public componentDidMount() {
    const viewId = this.getViewId(this.props)
    this.fetchView(viewId)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    // if there is no entity in the props, and entityId is different
    const viewId = this.getViewId(this.props)
    const nextViewId = this.getViewId(nextProps)
    if (viewId !== nextViewId) {
      this.rollbackCurrentView()
      this.fetchView(nextViewId)
    }
  }

  public componentWillUnmount() {
    const { dataSets } = this.state
    // we need to rollback current view, dispose data sources and subs
    this.rollbackCurrentView()
    _.forEach(dataSets, (dataSet) => dataSet.dispose())

    this.handleDataSetChange.cancel()
  }

  public render() {
    const { location, match, settings, pageProps } = this.props
    const { dataSets, isLoading, view, viewIndex, viewProps, viewSchema, error } = this.state
    if (isLoading) {
      return (
        <div className='grid-block c-appBody'>
          <LoadingSpinner />
        </div>
      )
    } else if (error?.status === 404) {
      const viewId = this.getViewId(this.props)
      return (
        <div className='grid-block c-appBody flex-column tc'>
          View not found:<br />{viewId}
        </div>
      )
    }
    const IndexComponent = ComponentsMap[viewIndex]
    return React.createElement(IndexComponent, {
      dataSets,
      location,
      match,
      view,
      viewSchema,
      settings,
      ...pageProps,
      ...viewProps,
    }, this.props.children)
  }

  /////////////////////////////////////////////////////////////////////////////
  // Helper Methods
  /////////////////////////////////////////////////////////////////////////////

  private initializeView(view) {
    const { settings } = this.props
    const { dataSets } = this.state
    const viewState = _.get(view, 'view.state', {})
    // unsubscribe and subscribe to new view
    if (this.subscription) { this.subscription.remove() }
    this.subscription = view.addListener(Store.RECORD_RESET,
      () => this.initializeView(view))
    // create data sets
    _.forEach(dataSets, (dataSource) => dataSource.dispose())
    const results = {}
    _.forEach(viewState.queries, (query, queryName) => {
      // TODO(Peter): this is rather crappy code. We should come up with a
      // better way for applying filters
      const rawFilters = _.isEmpty(query.filters) ? viewState.filters : query.filters
      const filters = evaluateFilters(rawFilters, { settings, ...CustomFormulas })
      const debug = {
        context: `view--${view.displayName}`,
        refreshInterval: this.getRefreshIntervalMs(),
      }
      const dataSet = new EntityDataSource({...query, filters, debug })
      results[queryName] = dataSet.setOnChange(() => {
        this.handleDataSetChange(queryName, dataSet)
      })
      dataSet.find()
    })
    return results
  }

  private getViewId(props) {
    const { match } = props

    return match.params.customViewId || match.params.viewId
  }

  private fetchView(viewId) {
    this.requestPromise?.cancel()
    this.handleDataSetChange.cancel()

    this.setState({ isLoading: true })
    this.requestPromise = this.viewResolver.resolveById(viewId)
    this.requestPromise.then((result) => {
      const { view } = result
      const filters = _.get(view, 'view.state.filters', [])
      const queries = _.values(_.get(view, 'view.state.queries'))
      const allItems = _.concat(filters, queries)
      return precacheMissingEntityTypes(allItems).then(() => result)
    }).then(({ index, props, uiSchema, view }) => {
      this.setState({
        dataSets: this.initializeView(view),
        isLoading: false,
        view,
        viewIndex: index,
        viewProps: props,
        viewSchema: uiSchema,
      })
      // NOTE: after upgrading to webpack 5, the warning "a promise was created
      // in a handler at ... but was not returned from it", see
      // http://goo.gl/rRqMUw. Adding the `return null` quieted the warning.
      return null
    }).catch((error) => {
      if (error?.status === 404) {
        this.setState({
          error,
          isLoading: false,
        })
      } else {
        throw error
      }
    })
  }

  private rollbackCurrentView() {
    const { view } = this.state
    // need to remove subscription to prevent callback on rollback
    if (this.subscription) { this.subscription.remove() }
    if (view) { view.rollback() }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Handlers
  /////////////////////////////////////////////////////////////////////////////

  private _handleDataSetChange = (queryName, dataSet) => {
    const { view } = this.state
    const viewState = view.view.state
    const query = viewState.queries[queryName]
    query.groups = dataSet.query.groups
    query.orders = dataSet.query.orders
    this.setState({ view })
  }

  private getRefreshIntervalMs() {
    const { refreshIntervalS } = queryString.parse(location.search)

    if (!refreshIntervalS) {
      return undefined
    }

    return Math.max(Number(refreshIntervalS) * 1000, 5000)
  }
}

function precacheMissingEntityTypes(items: { entityType: string }[]): Promise<any> {
  const store = apis.getStore()
  const missingUris = _.reduce(items, (acc, item) => {
    const uri = item.entityType
    if (uri && !store.getRecord(uri)) {
      acc.push(uri)
    }
    return acc
  }, [])
  if (missingUris.length) {
    return Promise.resolve(store.resolveEntitiesByUri(missingUris))
  } else {
    return Promise.resolve()
  }
}
