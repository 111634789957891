import React, { useContext } from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { Owner } from 'shared-libs/generated/server-types/entity'
import { renderAuthorHeader, renderEventTimestamp } from '../util'
import { EventChanges, renderEventChange } from './event-changes'

interface IExternalItemProps extends IBaseProps {
  event: any
  owner: Owner
  index: number
  isFirst: boolean
  isLast: boolean
  workflowEntity: any
}

export const ExternalItem: React.FC<IExternalItemProps> = (props) => {
  const { className, event, owner, isFirst, workflowEntity } = props
  const { createdBy, creationDate, processedDate } = event
  const { settings } = useContext(AppNavigatorContext)

  const plan = _.get(workflowEntity, 'core_storyboard_execution.storyboardPlan.core_storyboard_plan')

  if (_.isEmpty(plan) || _.isEmpty(event.outputMappings)) {
    return null
  }

  const changeElements = event.outputMappings
    .map((mapping, index) => renderEventChange({ mapping, workflowEntity: workflowEntity, index }))
    .filter(Boolean)
  if (_.isEmpty(changeElements) && !settings.isAdmin) {
    return null
  }

  return (
    <div className={classNames('c-timelineEvent', className)} key={event.uniqueId}>
      <div
        className={classNames('c-timelineEvent-line', className, {
          'c-timelineEvent-line--topCutOff': isFirst,
        })}
      />
      <div className='c-timelineEvent-dot' />
      <div className='c-timelineEvent-contentContainer'>
        { createdBy && (
          <div className='c-timelineEvent-header'>
            <div className='c-timelineEvent-author'>
              <Avatar
                className='c-timelineEvent-avatar'
                name={createdBy.displayName}
                size='xs'
              />
              <Label className='c-timelineEvent-authorName'>
                {renderAuthorHeader(createdBy, owner?.firm)}
              </Label>
            </div>
            <HelpBlock className='c-timelineEvent-timestamp'>
              {renderEventTimestamp(creationDate, processedDate, settings)}
            </HelpBlock>
          </div>
        ) }
        <div className='c-timelineEvent-body'>
          <p>Server Integration <strong>{event.name}</strong></p>
          <EventChanges>{changeElements}</EventChanges>
        </div>
      </div>
    </div>
  )
}
