import { WithDefaultProps } from 'browser/components/atomic-elements/higher-order-components/with-default-props'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import 'browser/mobile/styles/_control.scss'
import 'browser/mobile/components/footer/_mobile-footer.scss'
import { UIFileField } from 'browser/components/json-elements/molecules/file-field'

export const MobileFileField = WithDefaultProps(UIFileField, {
  addFilesButtonText: 'Add files',
  addFilesButtonClassName: classNames(Classes.LARGE, 'mobile-control'),
  buttonClassName: classNames('mobile-button', Classes.INTENT_PRIMARY, 'w-100'),
  emptyCueHelpText: 'Tap to capture a photo or select your file(s)',
  isMultiFile: true,
  itemDeleteButtonClassName: 'mobile-control',
  confirmationDialogProps: {
    animateFooter: false,
    footerClassName: 'mobile-footer-vertical',
    buttonClassName: 'mobile-button',
  },
  showOptionsButton: false,
})
