import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import React from 'react'

import { EntityType } from 'shared-libs/models/types/entity-type'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { List } from 'browser/components/atomic-elements/atoms/list'
import { IRenderListItemProps, IAbstractListProps } from 'browser/components/atomic-elements/atoms/list/abstract-list'
import { SortableListDragHandle } from 'browser/components/atomic-elements/atoms/list/sortable-list'
import { createListItemElement } from 'browser/components/json-elements/atoms/list'
import 'browser/components/json-elements/domains/-dispatch-order-stop-list.scss'

/**
 * @uiComponent
 */
interface IUIDispatchOrderStopList extends IAbstractListProps {
  entity: EntityType
  frames: any
}

export class UIDispatchOrderStopList extends React.Component<IUIDispatchOrderStopList, any> {

  public static defaultProps: Partial<IUIDispatchOrderStopList> = {
    isSortable: true,
  }

  public render() {
    const { children, className } = this.props
    // TODO(peter/louis): need to evaluate the perf consequences
    // if we were to use containerQuery here...
    return (
      <div className={classNames('c-timelineEvents', className)}>
        <List
          {...this.props}
          className={classNames('c-abstractList--noBorder', className)}
          helperClassName={'c-containerQuery--md c-dispatchOrerStopList-itemSortableHelper'}
          renderAddButton={this.renderAddButton}
          renderListItem={this.renderListItem}
        />
        {children}
      </div>
    )
  }

  private renderListItem = (props: IRenderListItemProps) => {
    const { frames, showAddButton } = this.props
    return (
      <div className='c-timelineEvent'>
        {this.renderLine(showAddButton, props)}
        {this.renderHandle()}
        <div className='c-timelineEvent-dot c-timelineEvent-dot--lg' />
        <div className='c-timelineEvent-contentContainer'>
          {createListItemElement({ frames, ...props })}
        </div>
      </div>
    )
  }

  private renderLine(showAddButton, props) {
    const lastIndex = props.items.length - 1
    const index = props.index
    return (
      <div
        className={classNames('c-timelineEvent-line c-timelineEvent-line--thick', {
          'c-timelineEvent-line--bottomOffset': index === lastIndex && !showAddButton,
          'c-timelineEvent-line--topCutOff': index === 0,
        })}
      />
    )
  }

  private renderHandle() {
    const { isSortable } = this.props
    if (isSortable) {
      return (
        <SortableListDragHandle className='c-dispatchOrerStopListHandle'>
          <Icon
            icon={IconNames.DRAG_HANDLE_VERTICAL}
            tabIndex={-1}
          />
        </SortableListDragHandle>
      )
    }
  }

  private renderAddButton = (handleAddItem) => {
    return (
      <div className='c-timelineEvent'>
        <div
          className={classNames('c-timelineEvent-line c-timelineEvent-line--thick', {
            'c-timelineEvent-line--bottomOffset': true,
          })}
        />
        <div className='c-timelineEvent-contentContainer c-timelineEvent-contentContainer--flushLeft'>
          <Button
            className={classNames('relative', Classes.MINIMAL)}
            onClick={handleAddItem}
          >
            <div className='c-timelineEvent-dot c-timelineEvent-dot--xl'>
              <Icon icon={IconNames.PLUS} />
            </div>
            <span className='ml3 pl1'>
              Add Stop
            </span>
          </Button>
        </div>
      </div>
    )
  }
}
