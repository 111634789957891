import _ from 'lodash'
import { JSONSchemaResolver } from '../resolvers/json-schema-resolver'
import { EdgeProps, EntitySchemaProps } from '../models/prop-constants';
import { Entity } from '../models/entity';
import { AbstractSettings } from './abstract-settings';
import { SchemaIds } from './schema';
import { Try } from '../helpers/utils';

const DOCUMENT_SCHEMA_URI = '/1.0/entities/metadata/document.json'

// find distance of each mixins from 'entity.json'
export function getDistanceFromRoot (store, schema, visited) {
  if (visited[schema.uniqueId]) {
    return visited[schema.uniqueId]
  }
  visited[schema.uniqueId] = 1
  const allOfs = schema.get('allOf', [])
  const anyOfs = schema.get('anyOf', [])
  const parentIds = _.map(allOfs.concat(anyOfs), JSONSchemaResolver.REF_KEY)
  parentIds.forEach(parentId => {
    const parentSchema = store.getRecord(parentId)
    if (parentSchema) {
      const newDist = getDistanceFromRoot(store, parentSchema, visited) + 1
      visited[schema.uniqueId] = Math.max(
          visited[schema.uniqueId], newDist)
    }
  })
  return visited[schema.uniqueId]
}

export function orderMixins(store, entity) {
  const visited = {}
  _.forEach(entity.schemas, schema => {
    getDistanceFromRoot(store, schema, visited)
  })
  const newOrder = _.sortBy(entity.activeMixins, [
    mixin => visited[mixin.entityId],
    mixin => mixin.displayName
  ])
  entity.set('mixins.active', newOrder)
}

export const convertServerError = (error) => {
  const { code, statusCode, errors, message } = error.responseJSON ?? {}
  const formattedErrors: Record<string | undefined, string[]> = {}
  _.forEach(errors, entry => {
    formattedErrors[entry.field] = [entry.message]
  })
  return {
    code: code || statusCode,
    errors: formattedErrors,
    message,
  }
}

/** Creates an {@link Error} from any kind of error, including structured server errors. */
export function getError(error: any) {
  if (error instanceof Error) {
    return error
  } else if (_.isString(error)) {
    return new Error(error)
  } else {
    const message =
      stringifyError(error) ||
      Try(
        () => JSON.stringify(error),
        () => 'Unknown error'
      )
    return new Error(message)
  }
}

export function stringifyError(error: any) {
  const serverError = convertServerError(error)
  return serverError.message ? serverError.message : stringifyServerError(serverError)
}

/** Join server errors into one string */
export function stringifyServerError(serverError) {
  const errors = _.flatMap(serverError.errors, (errors, field) => {
    const joined = _.join(errors, ', ')
    return field && field !== 'undefined' ? `${field}: ${joined}` : joined
  })
  return _.join(errors, '; ')
}

export const getDocumentSchemas = (store, schemas, platform, includeStoryboards=true) => {
  let documentSchemas = []
  let documentSchemasWithPriority = []
  _.forEach(schemas, (edge) => {
    if (platform && edge.platforms && !_.includes(edge.platforms, platform)) {
      return
    }
    if (edge._operation === 'DELETE') {
      return
    }

    const schema = store.getRecord(edge.entityId)
    const mixins = _.map(schema.allOf, '$ref')
    const isDocument = _.includes(mixins, DOCUMENT_SCHEMA_URI)
    const isAbstract = _.get(schema, 'metadata.isAbstract', false)
    const isStoryboardPlan = _.includes(_.map(schema.mixins.active, 'entityId'), SchemaIds.STORYBOARD_PLAN)
    // VD-10800 exclude storyboard plans from ES search if appropriate.
    if ((isDocument && !isAbstract) || (includeStoryboards && isStoryboardPlan)) {
      if (edge.orderPriority) {
        documentSchemasWithPriority.push({ schema, priority: edge.orderPriority })
      } else  {
        documentSchemas.push(schema)
      }
    }
  })
  documentSchemas = _.sortBy(documentSchemas, 'title')
  documentSchemasWithPriority = _.sortBy(documentSchemasWithPriority, 'priority')
  const orderedDocumentSchemasWithPriority = _.map(documentSchemasWithPriority, (document) => document.schema)
  return _.concat(orderedDocumentSchemasWithPriority, documentSchemas)
}

export const createEdge = (entityId) => {
  return {
    [EdgeProps.ENTITY_ID]: entityId
  }
}

export const createRef = (entitySchema: Entity) => {
  return {
    "$ref": entitySchema.get(EntitySchemaProps.URI)
  }
}

export function dictFromPairs(pairsList) {
  const ret = {}

  _.each(pairsList, pair => ret[pair[0]] = pair[1])

  return ret
}

export function canEditFirmView(settings: AbstractSettings) {
  return settings.isAdmin || settings.isManager || settings.isFirmAdmin
}

/**
 * @returns a pre-order dfs traversal of a component ref tree.
 */
export function flattenRefTree(refTree: any): any[] {
  const dfs = (node: any, results = []) => {
    if (!node) {
      return results
    }
    if (node.ref) {
      results.push(node.ref)
    }
    for (const key in node) {
      if (node.hasOwnProperty(key) && key !== 'ref') {
        dfs(node[key], results)
      }
    }
    return results
  }
  return dfs(refTree)
}

/**
 * Creates a sortable string to represent a UI component instance in a view
 * tree. It replaces instances of "items" in a uiSchemaPath with the actual
 * array item index from the valuePath.
 */
export function reifyUiSchemaArrayIndices(
  entity: Entity,
  uiSchemaPath: string[],
  valuePath: string[]
): string {
  if (!_.isArray(uiSchemaPath) || !_.isArray(valuePath)) {
    return
  }

  const uiSchemaPathCopy = [...uiSchemaPath]

  for (const index in uiSchemaPathCopy) {
    const value = uiSchemaPathCopy[index]
    if (value === 'items') {
      const slice = uiSchemaPathCopy.slice(0, Number(index))
      const resolved = entity.resolveSubschemaByPath(slice)
      if (resolved?.schema?.value) {
        const resolvedValuePath = resolved.schema.value.split('.')
        const arrayIndex = valuePath[resolvedValuePath.length]
        if (_.isNumber(arrayIndex)) {
          // replace 'items' with the actual array item index
          uiSchemaPathCopy[index] = arrayIndex
        }
      }
    }
  }
  return _.join(uiSchemaPathCopy, '.')
}

/**
  * Convert json pointer format "/a/b/c/1" to object path "a.b.c.1"
  */
export function jsonPointerToObjectPath(value?: string) {
  return value?.replace(/\//g, '.')?.slice(1)?.replace(/\.-$/, '')
}

export function objectPathToJsonPointer(path: string): string {
  return "/" + path.split(".").join("/");
}


export interface  IOption {
  entityType: string
  label: string
  namespace: string
  path: string
  propertyType: string
  enum?: Array<{ label: string; value: string }>
}

export function getOptionsFromSchema(schema, apis, resolver) {
  const options = []
  const { namespace } = schema.metadata
  const namespaceProperties = _.get(schema, 'properties.' + namespace + '.properties')
  _.forEach(namespaceProperties, (prop, key) => {
    try {
      const option = getOptionFromProperty(schema, namespace, prop, key, apis, resolver)
      if (option) {
        options.push(option)
      }
    } catch (err) {
      console.error(`Error processing ${namespace}.${key}, please fix the schema.`)
    }
  })
  return options
}

export function getOptionFromProperty(
  entitySchema,
  namespace: string,
  prop,
  propertyName: string,
  apis,
  resolver
): IOption {
  const property = ensurePropertyIsDenormalized(entitySchema, prop, resolver)
  if (property.type === 'array') {
    const valueOption = getOptionFromProperty(
      entitySchema,
      namespace,
      property.items,
      propertyName,
      apis,
      resolver
    )
    if (valueOption) {
      return {
        ...valueOption,
        propertyType: `array-${valueOption.propertyType}`,
        label: property.label
      }
    }
  } else if (
    (property.format !== 'date-time' && property.type === 'string') ||
    property.type === 'boolean' ||
    property.type === 'number' ||
    property.type === 'integer'
  ) {
    return {
      entityType: property.entityType,
      enum: _.map(property.enum, (option) => ({ label: option, value: option })),
      label: property.label,
      namespace,
      path: namespace + '.' + propertyName,
      propertyType: property.type || 'edge',
    }
  } else if (property.$ref === '/1.0/entities/metadata/entity.json#/definitions/edge') {
    const entitySchema = apis.getStore().getRecord(property.entityType)
    return {
      entityType: property.entityType,
      label: property.label ? property.label : entitySchema.title,
      namespace,
      path: namespace + '.' + propertyName,
      propertyType: 'edge',
    }
  }
}

export function ensurePropertyIsDenormalized(entitySchema, property, resolver) {
  // no need to denormalize edges, we know how to handle them already
  if (property.$ref && property.$ref !== '/1.0/entities/metadata/entity.json#/definitions/edge') {
    const { schema } = resolver.resolveReference(entitySchema, property.$ref)
    return {
      ...schema,
      ...property,
    }
  } else {
    return property
  }
}

/**
 * For lodash _merge, if the source field is undefined, it essentially skips the property
 * and does not set the property to undefined in the destination object.
 * In this implementation, it will honor the "undefined" from the source
 */
export function mergeUndefined<T>(dest: T, src: Partial<T>, options:any = {} ): T {
    const { mergeArray = false } = options
    for (const key in src) {
      const srcValue = src[key]
      if (srcValue !== undefined ) {
        const destValue = dest[key]
        if (_.isPlainObject(srcValue)
            && _.isPlainObject(destValue)
            || (_.isArray(destValue) && mergeArray)) {
          dest[key] = mergeUndefined(destValue, srcValue, options)
        } else {
          dest[key] = srcValue
        }
      } else {
        dest[key] = undefined
      }
    }
    return dest
  }

