import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Motion, spring } from 'react-motion'

import { QueryOptionsGenerator } from 'shared-libs/models/query-options-generator'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CardHeader } from 'browser/components/atomic-elements/atoms/card/card-header'
import { CardHeaderItem } from 'browser/components/atomic-elements/atoms/card/card-header-item'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import 'browser/components/atomic-elements/organisms/filters/_filter-card.scss'
import { FilterList } from 'browser/components/atomic-elements/organisms/filters/filter-list'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'

// Sync with $c-filterCard-width
const FILTER_CARD_WIDTH = 240

const optionsPopoverTetherOptions = {
  attachment: 'top left',
  targetAttachment: 'top right',
}

interface NestedFilterProps {
  showOnlyAvailableFilters?: boolean
  sortAllFilters?: boolean
  sortGeneratedFilters?: boolean // default true, must specify false to turn off
}

interface IFilterCardProps extends IBaseProps {
  availableFilters: any[]
  blacklistedFilters: any[]
  filterProps: NestedFilterProps
  availableGroups?: any[]
  entitySchema: any
  filters: any[]
  groups?: any[]
  isVisible: boolean
  onChange: (value: any[]) => void
  onClose: () => void
  onSave?: () => void
}

interface IFilterState {
  animation: any
  allFilters: any[]
  isLoading: boolean
}

const priorityFilterPaths = new Set([
  "owner.firm",
  "document.name",
  "precomputation.entityType.entityId-11111111-0000-0000-0000-000000000011",
  "createdBy",
  "creationDate",
  "precomputation.address.point",
  "user.department",
  "user.division",
  "user.employeeId",
  "user.position",
])

export class FilterCard extends React.Component<IFilterCardProps, IFilterState> {

  public static get defaultProps(): Partial<IFilterCardProps> {
    return {
      availableFilters: [],
      blacklistedFilters: [],
      filterProps: {},
      isVisible: false,
    }
  }

  private query: any

  constructor(props) {
    super(props)
    const { isVisible } = this.props
    const initialWidth = isVisible ? FILTER_CARD_WIDTH : 0
    const initialBorderWidth = isVisible ? 1 : 0
    this.state = {
      allFilters: [],
      isLoading: true,
      animation: {
        from: {
          borderWidth: initialBorderWidth,
          width: initialWidth,
        },
        to: {
          borderWidth: initialBorderWidth,
          width: initialWidth,
        },
      },
    }
  }

  private sortFilters(filters) {
    return _.sortBy(filters, f => f.label)
  }

  public componentDidMount() {
    const {
      availableFilters,
      filterProps,
      entitySchema,
    } = this.props

    if (filterProps.showOnlyAvailableFilters) {
      this.setState({
        isLoading: false,
        allFilters: filterProps.sortAllFilters ?
          this.sortFilters(availableFilters) :
          availableFilters
      })

      return
    }

    this.query = QueryOptionsGenerator.getValidSchemas(apis, entitySchema)
    this.query.then((validSchemas) => {
      const generatedFilters = QueryOptionsGenerator.getFilterOptions(validSchemas)
      const maybeSortedGenFilters = filterProps.sortGeneratedFilters !== false ?
        this.sortFilters(generatedFilters) :
        generatedFilters

      const highPriGenFilters = _.filter(maybeSortedGenFilters, f => priorityFilterPaths.has(f.path))
      const lowPriGenFilters = _.filter(maybeSortedGenFilters, f => !priorityFilterPaths.has(f.path))

      const combinedFilters = availableFilters.concat(highPriGenFilters).concat(lowPriGenFilters)
      const allFilters = filterProps.sortAllFilters ?
        this.sortFilters(combinedFilters) :
        combinedFilters

      this.setState({
        allFilters,
        isLoading: false,
      })
    })
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isVisible !== nextProps.isVisible) {
      const { animation } = this.state
      const nextAnimation = {
        from: animation.from,
        to: {
          borderWidth: nextProps.isVisible ? 1 : 0,
          width: spring(nextProps.isVisible ? FILTER_CARD_WIDTH : 0),
        },
      }
      this.setState({ animation: nextAnimation })
    }
  }

  public componentWillUnmount() {
    this.query?.cancel()
  }

  public render() {
    const { animation } = this.state
    return (
      <Motion
        defaultStyle={animation.from}
        style={animation.to}
      >
        {(style) => this.renderContent(style)}
      </Motion>
    )
  }

  private renderContent(style) {
    const { className, children } = this.props
    return (
      <div
        className={classNames('grid-block shrink vertical c-filterCard', className)}
        style={style}
      >
        <div className='c-filterCard-inner'>
          {this.renderFilterContent()}
        </div>
      </div>
    )
  }

  private renderFilterContent() {
    const { className, isVisible, onClose } = this.props
    // TODO(Louis): Should use query params for isVisible.
    return (
      <div className='grid-block vertical'>
        <CardHeader
          className='grid-block shrink c-cardHeader--flush c-cardHeader--alignCenter c-cardHeader--labelHeight u-innerBumperLeft--xl'
        >
          <CardHeaderItem
            label='Filters'
          />
          <CardHeaderItem
            onClick={onClose}
            icon={IconNames.CROSS}
          />
        </CardHeader>
        <div className='grid-block vertical'>
          <div className='grid-content collapse'>
            {this.renderActiveFilters()}
            {this.renderAvailableFilters()}
          </div>
        </div>
      </div>
    )
  }

  private renderActiveFilters() {
    const { filters } = this.props
    // TODO(louis): Decide if we still need this
    // headerControls={
    //   <Button
    //     className={classNames('pt-minimal', {
    //       'u-hide': _.isEmpty(filters),
    //     })}
    //     onClick={this.handleClearFilters}
    //     size='sm'
    //   >
    //     Clear All
    //   </Button>
    // }
    return (
      <Section
        className='u-bumperBottom--xl'
        subtitle='Active'
        hideHeaderBorder={false}
        headerClassName='c-sectionHeader--collapse u-bumperLeft--xl'
      >
        {this.renderActiveFilterList()}
      </Section>
    )
  }

  private renderActiveFilterListZeroState() {
    return (
      <div className='u-textCenter u-bumperLeft u-bumperRight u-bumperTop'>
        <HelpBlock>
          No active filters
        </HelpBlock>
        <HelpBlock>
          Select one or more from the<br />
          available filter options below.
        </HelpBlock>
      </div>
    )
  }

  private renderActiveFilterList() {
    const { entitySchema, filters, onChange } = this.props
    if (_.isEmpty(filters)) {
      return this.renderActiveFilterListZeroState()
    }
    return (
      <FilterList
        availableFilters={filters}
        entitySchema={entitySchema}
        filters={filters}
        isClearable={true}
        itemClassName='c-dropdownList-item c-dropdownList-item--lg'
        onChange={onChange}
        showFilterValue={true}
        tetherOptions={optionsPopoverTetherOptions}
      />
    )
  }

  private renderAvailableFilters() {
    const { blacklistedFilters, entitySchema, filters, onChange } = this.props
    const { allFilters, isLoading } = this.state
    return (
      <Section
        subtitle='Available'
        hideHeaderBorder={false}
        headerClassName='c-sectionHeader--collapse u-bumperLeft--xl'
      >
        {
          isLoading &&
            <LoadingSpinner />
        }
        <FilterList
          availableFilters={allFilters}
          blacklistedFilters={blacklistedFilters}
          entitySchema={entitySchema}
          filters={filters}
          itemClassName='c-dropdownList-item c-dropdownList-item--lg'
          onChange={onChange}
          tetherOptions={optionsPopoverTetherOptions}
        />
      </Section>
    )
  }

  private handleClearFilters = () => {
    this.props.onChange([])
  }
}
