import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'

import { SystemEventEntity } from 'shared-libs/generated/server-types/entity/systemEventEntity'

import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { renderEventTimestamp } from '../util'
import { AbstractSettings } from 'shared-libs/models/abstract-settings'
import { ComponentsContext } from 'browser/contexts/components/components-context'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import moment from 'moment-timezone'

interface ISystemEventItemProps extends IBaseProps {
  item: any
  isFirst: boolean
  // SystemEvents-list does not have a concept of last yet
  isLast?: boolean
  settings?: AbstractSettings
}

export const SystemEventItem: React.FC<ISystemEventItemProps> = (props) => {
  const { platform } = useContext(ComponentsContext)
  const { className, item, settings } = props
  return (
    <div
      className={classNames('c-timelineEvent', className)}
      key={item.uniqueId}
    >
      {platform === PlatformType.MOBILE_WEB ? (
        <MobileSystemEventItem {...props} isMine={false} />
      ) : (
        <DesktopSystemEventItem {...props} />
      )}
    </div>
  )
}

const DesktopSystemEventItem = (props: ISystemEventItemProps) => {
  const { className, item, isFirst } = props
  const { creationDate } = item

  return (
      <>
        <div
          className={classNames('c-timelineEvent-line', className, {
            'c-timelineEvent-line--topCutOff': isFirst,
          })}
        />
        <div className="c-timelineEvent-dot" />
        <div className="c-timelineEvent-contentContainer">
          <div className="c-timelineEvent-header">
            <div className="c-timelineEvent-author">
              <Label className="c-timelineEvent-authorName">
                {"System"}
              </Label>
            </div>
            <HelpBlock className="c-timelineEvent-timestamp">
              {renderEventTimestamp(creationDate)}
            </HelpBlock>
          </div>
          <div className="c-timelineEvent-bodyContainer">
            <SystemEventTextItem entity={item} />
          </div>
        </div>
      </>
  )
}

const MobileSystemEventItem = (props: ISystemEventItemProps & { isMine: boolean }) => {
  const { item } = props
  return (
    <>
      <div className="c-timelineEvent-contentContainer">
        <div className="c-timelineEvent-header">
          <div className="c-timelineEvent-author">
            <Avatar className="c-timelineEvent-avatar" name={"System"} size="xs" />
          </div>
        </div>
        <div className="c-timelineEvent-bodyContainer">
          <SystemEventTextItem entity={item}/>
        </div>
      </div>
    </>
  )
}

const SystemEventTextItem: React.FC<{ entity: SystemEventEntity}> = ({ entity }) => {
  // TODO: for now, only implemented for systemEvents to support mobile-web's
  // grouped timestamp UI. If we want this feature on web, we should also
  // refactor to standardize these items to share common features.
  const [showTimeOfDay, toggleShowTimeOfDay] = useState(false)
  const [text, setText] = useState(entity.systemEvent.text)

  useEffect(() => {
    if (_.isEmpty(text)) {
      return
    }
  }, [entity.systemEvent.text, text])
  if (!text) {
    return null
  }

  return (
    <>
      <div className={
        classNames("c-timelineEvent-body")
      } onClick={() => toggleShowTimeOfDay(!showTimeOfDay)}>
        <p> {text} </p>
      </div>
      {showTimeOfDay && <TimeOfDay entity={entity} />}
    </>
  )
}

const TimeOfDay: React.FC<{ entity: SystemEventEntity }> = ({ entity }) => {
  const { platform } = useContext(ComponentsContext)
  if (platform !== PlatformType.MOBILE_WEB) {
    return null
  }
  return (
    <div className="c-timelineEvent-body-timeOfDay">
      {moment(_.get(entity, 'creationDate')).format('h:mm a')}
    </div>
  )
}
