export class VisibilityManager {
  private visibleCallback: () => void
  private hiddenCallback: () => void

  private isHiddenKey: string
  private visibilityKey: string

  private isDestroyed: boolean

  constructor(visibleCallback, hiddenCallback) {
    this.visibleCallback = visibleCallback
    this.hiddenCallback = hiddenCallback

    const { isHiddenKey, visibilityKey } = this.getVisibilityApi()
    if (isHiddenKey && visibilityKey) {
      document.addEventListener(visibilityKey, this.visibilityChange, false)
      this.isHiddenKey = isHiddenKey
      this.visibilityKey = visibilityKey
    }
  }

  public dispose() {
    document.removeEventListener(this.visibilityKey, this.visibilityChange)
  }

  private visibilityChange = (e: Event, isDebounced=false) => {
    if (this.isDestroyed) { return }

    if (!isDebounced) {
      // Wait a second before calling the actual function body
      // To ensure the user stayed on the tab v.s. skipping past it
      setTimeout(() => { this.visibilityChange(e, isDebounced=true) }, 1000)
      return
    }

    if (document[this.isHiddenKey]) {
      this.hiddenCallback()
    } else {
      this.visibleCallback()
    }
  }

  private getVisibilityApi() {
    if (typeof document['hidden'] !== "undefined") {
      return {
        isHiddenKey: "hidden",
        visibilityKey: "visibilitychange",
      }
    } else if (typeof document['msHidden'] !== "undefined") {
      return {
        isHiddenKey: "msHidden",
        visibilityKey: "msvisibilitychange",
      }
    } else if (typeof document['webkitHidden'] !== "undefined") {
      return {
        isHiddenKey: "webkitHidden",
        visibilityKey: "webkitvisibilitychange",
      }
    }

    return {}
  }
}
