import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { browserHistory } from 'browser/history'

interface IJoinOrSetUpOrganizationTab extends IBaseProps {
  navigation: any
  productName: string
}

export class JoinOrSetUpOrganizationTab extends React.Component<IJoinOrSetUpOrganizationTab, any> {

  public render() {
    const { navigation, productName } = this.props
    const navigateToJoinOrg = () => navigation.navigate('joinOrganization')
    const navigateToSetupOrg = () => navigation.navigate('setupOrganization')
    return (
      <div className='ph4 pb4'>
        <h3 className='f3 lh-title mt2 mb0 tc'>
          Get Started with an Organization
        </h3>
        <div className='mb4 mt1 measure tc center'>
          In {productName}, everything happens within an organization. A {productName} organization is simply a group of people using {productName} together – like your company or workspace. Is your group already on {productName}, or do you need to start a new organization?
        </div>

        <div className='c-boxListItem-container'>
          <div
            className='c-boxListItem flex items-center bt b--light-gray pa3'
            onClick={navigateToJoinOrg}
          >
            <div className='flex-auto'>
              <h4 className='f4 lh-copy'>
                Join Organization
              </h4>
              <div>
                Accept or find invitations or request access to existing organizations.
              </div>
            </div>
            <div>
              <a>
                <Icon
                  className='ml3'
                  icon={IconNames.CHEVRON_RIGHT}
                />
              </a>
            </div>
          </div>
        </div>
        <div className='c-boxListItem-container'>
          <div
            className='c-boxListItem flex items-center bt b--light-gray pa3'
            onClick={navigateToSetupOrg}
          >
            <div className='flex-auto'>
              <h4 className='f4 lh-copy'>
                Create a New Organization
              </h4>
              <div>
                Use {productName} for personal use. Documents will not be shared automatically.
              </div>
            </div>
            <div>
              <a>
                <Icon
                  className='ml3'
                  icon={IconNames.CHEVRON_RIGHT}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
