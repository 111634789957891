import _ from 'lodash'
import React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/atoms/table/cells/_table-has-required-docs-cell.scss'

interface ITableHasRequiredDocsCellProps extends IBaseProps {
  entity: any
  row: any
  value: any
}

export const TableHasRequiredDocsCell: React.FC<ITableHasRequiredDocsCellProps> = (props) => {
  const { row } = props
  const { containsRequiredDocuments } = row

  if (containsRequiredDocuments) {
    return null
  }

  return (
    <Icon
      className={'c-warning'}
      title='Missing Required Documents'
      icon={IconNames.WARNING_SIGN}
    />
  )
}
