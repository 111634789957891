import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import { isPhoneValid } from 'shared-libs/helpers/utils'

import apis from 'browser/app/models/apis'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
// tslint:disable-next-line:max-line-length
import { AuthenticationPage } from 'browser/components/atomic-elements/organisms/authentication-page/authentication-page'
import { browserHistory } from 'browser/history'

interface IPasswordRecoverProps {
  location: any
}

interface IPasswordRecoverState {
  errorText: string
  identity?: string
  isLoading: boolean
}

export class PasswordRecover extends React.Component<IPasswordRecoverProps, IPasswordRecoverState> {
  constructor(props) {
    super(props)
    const { location } = this.props
    const { identity } = _.get(location, 'state', {})
    this.state = {
      errorText: '',
      identity,
      isLoading: false,
    }
  }

  public render() {
    return (
      <AuthenticationPage
        className='mw5-m mw5-l'
        bodyClassName='c-onboarding-body'
      >
        <Head
          title='Recover Password'
        />
        <div className='w-100 ph4 mb4'>
          {this.renderContent()}
        </div>
        <Footer
          className='c-footer--transparent'
          cancelButtonGroupElement={(
            <Link
              className={classNames(Classes.BUTTON, Classes.LARGE)}
              data-debug-id='sign-in'
              to={{
                pathname: '/sign-in',
                search: window.location.search,
              }}
            >
              Back
            </Link>
          )}
        />
      </AuthenticationPage>
    )
  }

  private renderContent() {
    return (
      <div className='u-flex u-alignItemsCenter u-justifyContentCenter'>
        <div className='w-100'>
          <h3 className='f3 b lh-title mt4 mb2 tc'>
            Reset Password
          </h3>
          <div className='lh-copy mb4 mt1 tc'>
            Please enter the mobile number or email that you used when creating your {apis.getProductName()} account and we{"'"}ll send you instructions to reset your password.
          </div>
          <InputField
            size='lg'
            className='u-bumperBottom c-formGroup--inputHasBorder'
            value={this.state.identity}
            onChange={this.handleIdentityChange}
            placeholder='Mobile number or email'
            errorText={this.state.errorText}
            onEnterPressed={this.handleResetPassword}
          />
          <Button
            className={classNames(Classes.INTENT_PRIMARY, Classes.FILL)}
            size='large'
            isLoading={this.state.isLoading}
            onClick={this.handleResetPassword}
          >
            Send Instructions
          </Button>
        </div>
      </div>
    )
  }

  private handleIdentityChange = (identity) => {
    this.setState({ identity })
  }

  private handleResetPassword = () => {
    const { identity } = this.state
    this.setState({ errorText: '', isLoading: true })
    const isPhoneNumber = isPhoneValid(identity)
    apis.forgotPassword(identity).then(() => {
      browserHistory.push({
        pathname: '/verify',
        search: window.location.search,
        state: {
          identity,
          nextVerificationRoute: '/sign-in/password-reset',
          nextRoute: _.get(this.props, 'location.state.nextRoute'),
        },
      })
    }).catch(() => {
      const errorText = 'We’re unable to reset your password at the moment. Please try again later.'
      this.setState({ errorText })
    }).finally(() => this.setState({ isLoading: false }))
  }
}
