import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import _ from 'lodash'
import React from 'react'
import { Entity } from 'shared-libs/models/entity'
import { convertFile, detectWebpSupport, getFileMimeType, isIOSDevice } from 'browser/app/utils/image'
import { captureImage, detectPage, imageSourceFromURL, imageSourceFromVideo } from 'browser/mobile/util/page-utils'
import 'browser/components/json-elements/atoms/document-camera/_document-camera.scss'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Icon, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { translateString } from 'shared-libs/helpers/utils'
import { globalTranslationTable } from 'browser/mobile/util/global-translation-table'
import { Settings } from 'browser/app/models/settings'
import { MobileModal } from 'browser/mobile/elements/modal/modal'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { ComponentsContext, IComponentsContext } from 'browser/contexts/components/components-context'
import { IModalProps } from 'browser/components/atomic-elements/atoms/modal'
import classNames from 'classnames'
import { ErrorText } from 'browser/app/pages/app/tools/errors'
import 'rvfc-polyfill'
import * as gm from 'gammacv'

const shutterImage = require('images/camera/shutterButton.png')
const fileImage = require('images/camera/libraryIcon.png')

export interface IDocumentCaptureResult {
  bounds?: any[]
  imageFile: File
  originalMimeType: string
}

interface IDocumentCameraFieldProps {
  entity: Entity
  openLibrary?: () => void
  onImageCaptured: (IDocumentCaptureResult) => void
  onCancel: (string?) => void
  onClose?: () => void
  enableAutoGrayscale?: boolean
  enablePageDetection?: boolean
  isMultiFile?: boolean
  index?: number
  showLibraryPhoto?: boolean
}

interface IDocumentCameraFieldState {
  isCameraSettingUp: boolean
  showCamera: boolean
  error?: Error
}

/*
1. ~~Keep higher resolution ideal selector~~
2. ~~use a `video` tag for continuous video, and update the rectangle as processing allows~~
3. ~~Apply grayscale only on processed image (all transforms)~~
4. ~~feature flag~~
5. ~~Better UX if the camera can't be attached, fallback to dropzone only~~
6. byte size optimizations, downscale to 1700px as native app does
7. Better UX for edit mode, can we remove the overflow:scroll?
8. Edit mode done should use the hardware instead of server round trip
9. Torch mode?
*/
export class DocumentCamera extends React.PureComponent<IDocumentCameraFieldProps, IDocumentCameraFieldState> {

  private captureCanvas: HTMLCanvasElement
  private drawCanvas: HTMLCanvasElement
  private video: any
  private track: MediaStreamTrack
  private stream: MediaStream
  private isWebpSupported = false
  private videoRequest?: number

  public static defaultProps: Partial<IDocumentCameraFieldProps> = {
    showLibraryPhoto: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      isCameraSettingUp: true,
      showCamera: true,
    }
    this.captureCanvas = document.createElement('canvas')
  }

  public async componentDidMount(): Promise<void> {
    this.isWebpSupported = await detectWebpSupport()
  }

  render(): React.ReactNode {
    const { error, showCamera } = this.state
    const errorText = error && <ErrorText>{error.message}</ErrorText>
    const loadingSpinner = this.state.isCameraSettingUp && <LoadingSpinner />
    return (
      <div className='capture-container'>
          <header className="mobile-header">
            {this.renderTitle()}
            {this.renderExpander()}
            {this.renderCloseButton()}
          </header>
          {loadingSpinner}
          <div className={classNames('video-container', {
              'u-hide': this.state.isCameraSettingUp,
              'c-fileInput--error': !_.isEmpty(errorText)
            })}
          >
            { errorText }
            <video
              className='fileinput-field-video'
              autoPlay playsInline muted
              ref={this.handleVideoRef}
              onCanPlay={this.handleCanPlay}
            />
            <canvas className='fileinput-field-canvas u-hide' ref={this.handleCanvasRef} />
            {this.renderControls()}
          </div>
        </div>
    )
  }

  private renderTitle() {
    const translatedTitle = translateString('Capture Image', globalTranslationTable)
    return (
      <span className="mobile-header__title-container">
        <h1 className="mobile-header__title">{translatedTitle}</h1>
      </span>
    )
  }

  private renderExpander() {
    return <span className="flex-grow" />
  }

  private renderCloseButton() {
    return (
      <Button
        intent={Intent.PRIMARY}
        data-debug-id="headerCameraButton"
        className="mobile-header__button-active bg-blue"
        onClick={this.handleCancel}
      >
        <Icon icon={IconNames.CROSS} />
      </Button>
    )
  }

  private renderControls = () => {
    const instructions = 'After pressing the capture button, hold still until the image is fully captured'
    const translatedInstructions = translateString(instructions, globalTranslationTable)
    const libraryButton = this.props.showLibraryPhoto ? (
      <span className='col-xs-5'>
        <Button
          className='c-controlButton col-xs-1'
          onClick={this.handleOpenLibrary}
        >
          <img src={fileImage} className='c-libraryImage'/>
        </Button>
      </span>
    ) : <span className='col-xs-5'/>
    return (
      <span className='c-controlButtonContainer'>
        <div className='c-innerButtonContainer row'>
          { libraryButton }
          <Button
            buttonText={this.state.isCameraSettingUp ? 'loading' : null}
            onClick={this.handleCaptureClick}
            className='c-controlButton col-xs-1'
            isDisabled={this.state.isCameraSettingUp}
          >
            <img src={shutterImage} className='c-controlImage'/>
          </Button>
        </div>
        <p style={{gridColumnStart: 1, gridColumnEnd: 4}}>
          {translatedInstructions}
        </p>
        </span>
    )
  }

  private handleCanvasRef = (ref: HTMLCanvasElement) => {
    this.drawCanvas = ref
  }

  private handleVideoRef = async (ref: HTMLVideoElement) => {
    // Another possible race condition - we could be redrawing the camera when the
    // user chooses an image, and the canvas ref comes after the camera itself is closed.
    const { showCamera } = this.state
    if (!showCamera) return

    this.video = ref
    if (!ref) return
    try {
      if (!this.track) {
        const stream = await this.acquireStream()
        this.stream = stream
        const [track] = stream.getVideoTracks();
        this.track = track
        const capabilities = track.getCapabilities?.()
        if (!_.isNil(capabilities)) {
          console.log(`capabilities ${JSON.stringify(capabilities)}`)
          const constraints = track.getConstraints()
          let changed = false
          if (_.get(constraints, 'width.ideal', 0) > capabilities.width.max) {
            constraints.width = capabilities.width.max
            changed = true
          }
          if (_.get(constraints, 'height.ideal', 0) > capabilities.height.max) {
            constraints.height = capabilities.height.max
            changed = true
          }
          if (changed) {
            track.applyConstraints(constraints)
          }
        }
        this.video.srcObject = stream
      } else if (this.stream) {
        this.video.srcObject = this.stream
      }
    } catch (err) {
      const { onCancel, onClose } = this.props
      console.error(`Failed to attach camera: ${err?.stack}`)
      if (!this.state.error) {
        this.setState({ error: err })
        // fallback to dropzone
        this.handleOpenLibrary()
      }
      onClose?.()
      onCancel()
    }
  }

  private handleCanPlay = async () => {
    try {
      this.captureCanvas.width = this.video.videoWidth
      this.captureCanvas.height = this.video.videoHeight
      //console.log(`canvas width ${this.video.width} ${this.video.height} ${JSON.stringify(this.captureCanvas.getBoundingClientRect())}`)
      this.setState({ isCameraSettingUp: false })
      this.acquireFrames()
    } catch (err) {
      console.error(`Failed to acquire frames: ${err?.stack}`)
      this.setState({ error: err })
    }
  }

  private handleCancel = () => {
    const { onCancel, onClose } = this.props
    this.track?.stop()
    onClose?.()
    onCancel()
  }

  private handleOpenLibrary = () => {
    const { openLibrary, onClose } = this.props
    this.track?.stop()
    openLibrary()
    onClose?.()
  }

  private async acquireStream(height=2000, width=3000): Promise<MediaStream> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const selectedDevice = _.find(
      devices, (info) =>
        (info.kind === "videoinput" && (info as any).facingMode === "environment") ||
        info.label.indexOf("facing back") >= 0)

    // These constraints need to be different to test in the browser,
    // remove min: and max: keys from both.
    // TODO: figure out how to set constraints that work with both.
    const constraints: MediaStreamConstraints = {
      audio: false,
      video: {
        facingMode: 'environment',
        width: { min: 1920, ideal: width, max: 4000 },
        height: { min: 1920, ideal: height, max: 3000 },
      },
    };

    // specify exact device if known (some android devices have multiple rear cameras, we want the best one)
    if (selectedDevice) {
      const video = constraints.video as any
      video.deviceId = {
        exact: selectedDevice.deviceId,
      }
    }


    try {
      return navigator.mediaDevices.getUserMedia(constraints);
    } catch (err) {
      console.error("mediaDevices.getUserMedia(constraints) failed: ", err);
      throw err;
    }
  }

  private waitForNextFrame = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      try {
        if (!this.video || this.videoRequest) {
          reject(new Error('no video object or already waiting'))
          return
        }
        this.videoRequest = this.video?.requestVideoFrameCallback(() => {
          resolve()
        })
      } catch(e) {
        console.error(`failed to wait for frame: ${e.stack}`)
        reject(e)
      }
    })
  }

  private acquireFrames = async () => {
    const { enablePageDetection } = this.props

    if (!this.state.showCamera) return

    await this.waitForNextFrame()
    this.videoRequest = null
    enablePageDetection && await this.drawFrameAndBounds()
    if (this.state.showCamera) {
      this.acquireFrames()
    }
  }

  private handleCaptureClick = async () => {
    const {
      onImageCaptured, index = 0, onCancel, onClose, enablePageDetection } = this.props
    try {
      this.setState({ showCamera: false })
      this.videoRequest && this.video?.cancelVideoFrameCallback(this.videoRequest)
      this.videoRequest = null
      const imageSource = imageSourceFromVideo(this.video)
      await this.waitForNextFrame()
      const originalFile = await captureImage(imageSource, `image${index}.png`, this.video)
      this.track?.stop()
      const frameUrl = URL.createObjectURL(originalFile)
      const fileImageSource = await imageSourceFromURL(frameUrl)
      const { bounds, error } = enablePageDetection ? detectPage(fileImageSource, (err) => {
        this.setState({ error: err })
        if (err) {
          onClose?.()
          onCancel?.(err)
        }
      }) : { bounds: null, error: null }
      if (error) {
        this.setState({error})
        onClose?.()
        onCancel?.(error)
        return
      }
      const boundsObject = bounds ? { bounds } : null
      const detectedMimeType = await getFileMimeType(originalFile)
      const file = this.isWebpSupported ? await convertFile(originalFile, 'image/webp') : originalFile

      onImageCaptured?.({
        ...boundsObject,
        imageFile: file,
        originalMimeType: detectedMimeType
      })
    } catch(e) {
      console.error(`problem capturing frame: ${e.message} ${e.stack}`)
    } finally {
      this.track?.stop()
      this.track = null
      onClose?.()
    }
  }

  private drawFrameAndBounds = async () => {
    const { onCancel, onClose } = this.props
    const bbox = this.video.getBoundingClientRect()
    const context2d = this.drawCanvas.getContext('2d')
    const xScale = bbox.width / this.video.videoWidth
    const yScale = bbox.height / this.video.videoHeight

    //console.log(`scale ${xScale} ${yScale} width ${frame.width} height ${frame.height}`)
    try {
      const { bounds, error } = detectPage(imageSourceFromVideo(this.video), (err) => {
        this.setState({error: err})
        if (err) {
          onClose?.()
          onCancel?.(err)
        }
      })
      if (error) {
        this.setState({error})
        onClose?.()
        onCancel?.(error)
        return
      }
      const bbox = this.video.getBoundingClientRect()
      //console.log(`image ${blob} bounds ${JSON.stringify(bounds)} video ${JSON.stringify(bbox)}`)
      if (bounds && bounds.length) {
        this.drawCanvas.className = 'fileinput-field-canvas'
        this.drawCanvas.style.top = `${bbox.top}`
        this.drawCanvas.style.left = `${bbox.left}`
        this.drawCanvas.style.width = `${bbox.width}`
        this.drawCanvas.style.height = `${bbox.height}`
        this.drawCanvas.width = bbox.width
        this.drawCanvas.height = bbox.height
        context2d.clearRect(0,0,bbox.width,bbox.height)
        context2d.strokeStyle = 'orange'
        context2d.fillStyle = '#ff800080'
        context2d.beginPath()
        context2d.moveTo(bounds[0].x * xScale, bounds[0].y * yScale)
        context2d.lineTo(bounds[1].x * xScale, bounds[1].y * yScale)
        context2d.lineTo(bounds[2].x * xScale, bounds[2].y * yScale)
        context2d.lineTo(bounds[3].x * xScale, bounds[3].y * yScale)
        context2d.fill()
      }
    } catch(e) {
      console.error(`failed to detect page: ${e.message} at ${e.stack}`)
      this.setState({ error: e })
      onClose?.()
      onCancel?.(e)
    }
  }

  public static open(
    props: IDocumentCameraFieldProps,
    settings: Settings,
    componentsContext: IComponentsContext,
    modalProps?: IModalProps
  ): Element {
    // TODO: the modal is outside the scope of the context providers since it
    // renders in the portal sitting in the root <body> element as a sibling to
    // the app root, so providing it here. May want to generalize this to
    // "mobile modals" so any components within can use contexts.
    const ModalWithContext = (injectedProps) => (
      <AppNavigatorContext.Provider value={{ settings: settings }}>
        <ComponentsContext.Provider value={componentsContext}>
            <DocumentCamera {...props} {...injectedProps} />
        </ComponentsContext.Provider>
      </AppNavigatorContext.Provider>
    )
    return MobileModal.open(<ModalWithContext />, modalProps)
  }

  /**
   * Determines if WebGL and necessary extensions are available.
   */
  public static async isCameraAvailable(): Promise<boolean> {
    if (!navigator.mediaDevices) return false
    // Workaround for Apple bug that drops webgl context randomly
    if (isIOSDevice()) return false
    const canvas = document.createElement('canvas')
    const gl = canvas.getContext('webgl')
    const ext = gl.getExtension('OES_texture_float')
    if (_.isNil(ext)) return false
    const tensor = new gm.Tensor("float32", [3, 1, 4]);
    const operation: any = gm.resize(tensor, 10, 10)
    const session = new gm.Session()
    try {
      session.init(operation)
    } catch(err) {
      console.log(`failed to initialize GL session: ${err.stack}`)
      return false
    }

    return true
  }

  private takePhoto = async (): Promise<Blob> => {
    const context = this.captureCanvas.getContext('2d')
    context.drawImage(this.video, 0, 0)
    return new Promise((resolve, reject) => {
      try {
        this.captureCanvas.toBlob((blob) => {
          resolve(blob)
        })
      } catch (err) {
        reject(err)
      }
    })
  }
}