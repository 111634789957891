declare let window: any

import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { IInputProps, Input } from 'browser/components/atomic-elements/atoms/input/input'

// TODO(louis)
// GooglePlaceInput.propTypes = _.assign({}, GooglePlaceInputPropTypes, Input.propTypes)
interface IGooglePlaceInputProps extends IInputProps {
  onPlaceChange: (value: any) => void
  types?: string[]
}

export class GooglePlaceInput extends React.Component<IGooglePlaceInputProps, any> {
  public static defaultProps: Partial<IGooglePlaceInputProps> = {
    types: ['address'],
  }

  public autocomplete: any
  public input: any

  public componentDidMount() {
    const Autocomplete = window.google.maps.places.Autocomplete
    this.autocomplete = new Autocomplete(this.getElement(), {
      componentRestrictions: { country: [ 'us', 'ca' ] },
      types: this.props.types,
    })
    this.autocomplete.addListener('place_changed', this.handleOnPlaceChange.bind(this))
  }

  public focus() {
    this.getElement().focus()
  }

  public getElement() {
    return this.input.getElement()
  }

  public render() {
    // we want to ommit all the props from GooglePlaceInput.propTypes
    const props = _.omit(this.props, ['onPlaceChange', 'types'])
    return (
      <Input
        {...props}
        ref={(ref) => { this.input = ref }}
      />
    )
  }

  private handleOnPlaceChange() {
    const place = this.autocomplete.getPlace()
    this.props.onPlaceChange(place)
  }
}
