import _ from 'lodash'
import React, { useState } from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import 'browser/components/atomic-elements/organisms/table-action-bar/_table-action-bar.scss'
import { TableActionBar } from 'browser/components/atomic-elements/organisms/table-action-bar/table-action-bar'
import { browserHistory } from 'browser/history'

interface IInvitesTableActionBarProps extends IBaseProps {
  selection: any[]
}

export const InvitesTableActionBar: React.FC<IInvitesTableActionBarProps> = (props) => {
  const { selection } = props
  const [isLoading, setIsLoading] = useState(false)

  const handleSendInvitationClicked = () => {
    const entities = _.map(selection, (entity) => entity.data)
    _.forEach(entities, (entity) => {
      entity.set('invite.status', 'Sent')
    })
    const promises = _.map(entities, (entity) => entity.save())
    setIsLoading(true)
    Promise.all(promises).then((result) => {
      const waitUntilIdle = _.map(result, (entity) => entity.waitUntilIdle())
      return Promise.all(waitUntilIdle)
    }).then(() => {
      setIsLoading(false)
      browserHistory.push(
        { pathname: `/view/7ec9f633-0341-4160-8bb2-c305d645ee67/view/dc31f3b2-4d6c-45b9-8bd9-7f7d5bfc8329` },
      )
    })
  }

  const renderActionButtons = () => {
    return (
      <Button
        isLoading={isLoading}
        onClick={handleSendInvitationClicked}
      >
        Send Invitation
      </Button>
    )
  }

  if (selection.length <= 1) {
    return null
  }

  return (
    <TableActionBar
      actionButtons={renderActionButtons()}
      selection={selection}
    />
  )
}
