import { Colors, Icon, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'

interface ITableWorkflowPlanActiveCellProps extends IBaseProps {
  value: any
}

export const TableWorkflowPlanActiveCell: React.FC<ITableWorkflowPlanActiveCellProps> = (props) => {
  const { value } = props
  const activePlanId = _.get(value, ['core_storyboard_plan', 'version', 'container', 'denormalizedProperties', 'core_storyboard_plansContainer.activePlan', 'entityId'], '')
  const uniqueId = _.get(value, 'uniqueId', '')
  const active = uniqueId === activePlanId
  return (
    <div className={classNames('ml2', {'u-hide': !active})}>
      <Icon icon={IconNames.ENDORSED} color={Colors.GREEN3} />
    </div>
  )
}
