import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { ImageLoader } from 'browser/components/atomic-elements/atoms/image-loader/image-loader'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { PrintContainer } from 'browser/components/atomic-elements/atoms/print-container/print-container'
import 'browser/components/atomic-elements/domains/dms/havas-expense-report/_havas-expense-report.scss'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { SignatureField } from 'browser/components/atomic-elements/molecules/fields/signature-field/signature-field'
import { TextareaField } from 'browser/components/atomic-elements/molecules/fields/textarea-field'

interface IHavasExpenseReportProps extends IBaseProps {
  entity: any
}

interface IHavasExpenseReportState {
  firm: any
  isLoading: boolean
  order: any
}

export class HavasExpenseReport extends React.Component<IHavasExpenseReportProps, IHavasExpenseReportState> {

  constructor(props) {
    super(props)
    this.state = {
      firm: null,
      isLoading: false,
      order: null,
    }
  }

  public render() {
    const { isLoading } = this.state
    if (isLoading) {
      return this.renderLoading()
    }
    // TODO(peter/louis): need to pull out print frame to generalized view
    // and then there also handle the print modal
    return (
      <AppNavigatorContext.Consumer>
        {({settings}) => (
          <div className='c-printFrame'>
            <Head
              title='Havas Expense Report'
            />
            {this.renderHavasExpenseReport(settings)}
          </div>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private renderLoading() {
    return (
      <div className='c-printFrame'>
        <div className='grid-block'>
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  private renderHavasExpenseReport(settings) {
    const { entity } = this.props
    const firm = settings.getFirm()
    const user = entity.get('owner.user')
    const businessLogo = _.get(firm, 'business.logo.uri', '')
    const totalExpense = '$' + entity.havasExpenseReport.totalExpensesCalc

    return (
      <PrintContainer isLandscape={true}>
        <div className='c-printPage c-printPage--landscape'>
          <div className='c-printPageInner'>
            <div className='row'>
              <div className='col-xs-6'>
                <h1 className='c-h1'>Havas Expense Report</h1>
              </div>
              <div className='col-xs-6 tr'>
                <img
                  className='c-printLogo u-bumperBottom'
                  src={businessLogo}
                />
                {/*  {firm.displayName} */}
              </div>
            </div>

            <div className='row'>
              <div className='col-xs-6'>
                <InputField
                  isHorizontalLayout={true}

                  label='Card Holder Name'
                  value={user.displayName}
                />
                <InputField
                  isHorizontalLayout={true}

                  label='Card Type'
                  value={entity.havasExpenseReport.cardType}
                />
                <InputField
                  isHorizontalLayout={true}

                  label='Credit Card #'
                  value={entity.havasExpenseReport.creditCardNumber}
                />
                <InputField
                  isHorizontalLayout={true}

                  label='Report Date'
                  value={moment(entity.havasExpenseReport.reportDate).format('M/D/YYYY')}
                />
              </div>
              <div className='col-xs-6'>
                <InputField
                  isHorizontalLayout={true}

                  label='Project Name'
                  value={entity.havasExpenseReport.projectName}
                />
                <InputField
                  isHorizontalLayout={true}

                  label='Client Number'
                  value={entity.havasExpenseReport.clientNumber}
                />
                <InputField
                  isHorizontalLayout={true}

                  label='Job Number'
                  value={entity.havasExpenseReport.productName}
                />
                <InputField
                  isHorizontalLayout={true}

                  label='Product Number'
                  value={entity.havasExpenseReport.jobNumber}
                />
              </div>
            </div>

            <hr className='u-pt-2' />
            <h3 className='c-h3'>Expenses</h3>
            {this.renderExpenses()}

            <div className='row u-pt'>
              <div className='col-xs-4'>
                <SignatureField

                  isHorizontalLayout={true}
                  label='Employee Signature'
                  className='c-havasExpenseReport-signature'
                  value={entity.havasExpenseReport.employeeSignature}
                />
                <InputField

                  isHorizontalLayout={true}
                  isStatic={true}
                  label='Date'
                  value={moment(entity.havasExpenseReport.reportDate).format('M/D/YYYY')}
                />
              </div>
              <div className='col-xs-4'>
                <SignatureField

                  isHorizontalLayout={true}
                  label='Customer Signature'
                  className='c-havasExpenseReport-signature'
                  value={entity.havasExpenseReport.employeeSignature}
                />
                <InputField

                  isHorizontalLayout={true}
                  isStatic={true}
                  label='Date'
                  value={''}
                />
              </div>
              <div className='col-xs-3 col-xs-offset-1'>
                <InputField

                  size='lg'
                  isHorizontalLayout={true}
                  isStatic={true}
                  label='Total Expense'
                  value={totalExpense}
                />
              </div>
            </div>
          </div>
        </div>

        {this.renderReceiptPages(settings)}
      </PrintContainer>
    )
  }

  private renderExpenses() {
    const { entity } = this.props
    let airfareSum = 0
    let hotelsSum = 0
    let fuelSum = 0
    let mealsSum = 0
    let tollsParkingSum = 0
    let localTransportationSum = 0
    let otherSum = 0
    const expenseRows = _.map(entity.havasExpenseReport.expenses, (expense: any, index) => {
      const formattedReceiptDate = moment(expense.receiptDate).format('M/D/YYYY')

      let airfare = '-'
      let hotels = '-'
      let fuel = '-'
      let meals = '-'
      let tollsParking = '-'
      let localTransportation = '-'
      let other = ''

      if (expense.type === 'Airfare') {
        airfare = expense.amount
        airfareSum = airfareSum + expense.amount
      } else if (expense.type === 'Hotels') {
        hotels = expense.amount
        hotelsSum = hotelsSum + expense.amount
      } else if (expense.type === 'Fuel') {
        fuel = expense.amount
        fuelSum = fuelSum + expense.amount
      } else if (expense.type === 'Meals') {
        meals = expense.amount
        mealsSum = mealsSum + expense.amount
      } else if (expense.type === 'Tolls/Parking') {
        tollsParking = expense.amount
        tollsParkingSum = tollsParkingSum + expense.amount
      } else if (expense.type === 'Local Transportation (i.e. Car Rental, Taxi)') {
        localTransportation = expense.amount
        localTransportationSum = localTransportationSum + expense.amount
      } else if (expense.type === 'Other') {
        other = expense.amount
        otherSum = otherSum + expense.amount
      }

      return (
        <tr key={index}>
          <td className='c-table-cell'>{index + 1}</td>
          <td className='c-table-cell'>{formattedReceiptDate}</td>
          <td className='c-table-cell'>{expense.receiptDescription}</td>
          <td className='c-table-cell'>{airfare}</td>
          <td className='c-table-cell'>{hotels}</td>
          <td className='c-table-cell'>{fuel}</td>
          <td className='c-table-cell'>{meals}</td>
          <td className='c-table-cell'>{tollsParking}</td>
          <td className='c-table-cell'>{localTransportation}</td>
          <td className='c-table-cell'>{other}</td>
          <td className='c-table-cell'>{expense.workCode}</td>
        </tr>
      )
    })
    return (
      <table className='c-table c-table--auto u-pb'>
        <thead className='c-table-header'>
          <tr>
            <th className='c-table-cell'>Receipt #</th>
            <th className='c-table-cell'>Date</th>
            <th className='c-table-cell'>Detailed Description of Receipt</th>
            <th className='c-table-cell'>TextareaField</th>
            <th className='c-table-cell'>Hotels</th>
            <th className='c-table-cell'>Fuel</th>
            <th className='c-table-cell'>Meals</th>
            <th className='c-table-cell'>Tolls/Parking</th>
            <th className='c-table-cell'>Local Transportation<br />(Car Rental, Taxi, other)</th>
            <th className='c-table-cell'>Other</th>
            <th className='c-table-cell'>Work Code</th>
          </tr>
        </thead>
        <tbody className='c-table-body'>
          {expenseRows}
        </tbody>
        <tfoot className='c-table-footer'>
          <tr>
            <td className='c-table-cell c-table-cell--noBorderLeft' />
            <td className='c-table-cell c-table-cell--noBorderLeft' />
            <td className='c-table-cell b c-table-cell--noBorderLeft'>Totals</td>
            <td className='c-table-cell b'>{airfareSum}</td>
            <td className='c-table-cell b'>{hotelsSum}</td>
            <td className='c-table-cell b'>{fuelSum}</td>
            <td className='c-table-cell b'>{mealsSum}</td>
            <td className='c-table-cell b'>{tollsParkingSum}</td>
            <td className='c-table-cell b'>{localTransportationSum}</td>
            <td className='c-table-cell b u-borderRight'>{otherSum}</td>
          </tr>
        </tfoot>
      </table>
    )
  }

  private renderReceiptPages(settings) {
    const { entity } = this.props
    const firm = settings.getFirm()
    const user = entity.get('owner.user')
    const businessLogo = _.get(firm, 'business.logo.uri', '')
    const receiptPages = _.map(entity.havasExpenseReport.expenses, (expense: any, index) => {
      const formattedReceiptDate = moment(expense.receiptDate).format('M/D/YYYY')
      const receipts = expense.receipts.files[0].uri
      return (
        <div
          className='c-printPage c-printPage--portrait'
          key={index}
        >
          <div className='c-printPageInner'>
            <div className='row'>
              <div className='col-xs-6'>
                <h1 className='c-h1'>Havas Expense Report - Receipt #: {index + 1}</h1>
              </div>
              <div className='col-xs-6 tr'>
                <img
                  className='c-printLogo u-bumperBottom'
                  src={businessLogo}
                />
                {/*  {firm.displayName} */}
              </div>
            </div>

            <div className='row u-pb'>
              <div className='col-xs-6'>
                <InputField

                  isHorizontalLayout={true}
                  isStatic={true}
                  label='Receipt #'
                  value={index + 1}
                />
                <InputField

                  isHorizontalLayout={true}
                  isStatic={true}
                  label='Date'
                  value={formattedReceiptDate}
                />
              </div>
              <div className='col-xs-6'>
                <TextareaField

                  isHorizontalLayout={true}
                  isStatic={true}
                  label='Description'
                  value={expense.receiptDescription}
                />
                <InputField

                  isHorizontalLayout={true}
                  isStatic={true}
                  label='Work Code'
                  value={expense.workCode}
                />
              </div>
            </div>

            {this.renderReceipts(expense)}
          </div>
        </div>
      )
    })

    return (
      <div>
        {receiptPages}
      </div>
    )
  }

  private renderReceipts(expense) {
    const receipts = _.map(expense.receipts, (receipt: any, index) => {
      if (receipt && receipt.length > 0) {
        const receiptImage = receipt[0].uri
        return (
          <div key={index}>
            <ImageLoader
              src={receiptImage}
            />
          </div>
        )
      }
    })
    return (
      <div>
        {receipts}
      </div>
    )
  }
}
