import classNames from 'classnames'
import { Cell, Column as ColumnComponent, Table as FixedDataTable } from 'fixed-data-table-2'
import React from 'react'

import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import 'browser/components/atomic-elements/atoms/table/_flex-table.scss'
import 'browser/components/atomic-elements/atoms/table/_table.scss'
import { TableCheckboxCell } from 'browser/components/atomic-elements/atoms/table/cells/internal/table-checkbox-cell'
// tslint:disable-next-line:max-line-length
import { TableHeaderColumnCell } from 'browser/components/atomic-elements/atoms/table/cells/internal/table-header-column-cell'
// tslint:disable-next-line:max-line-length
import { LOAD_MORE_PREFIX, TablePrimaryColumnCell } from 'browser/components/atomic-elements/atoms/table/cells/internal/table-primary-column-cell'
import { Hotkey, Hotkeys, HotkeysTarget, Spinner, SpinnerSize } from '@blueprintjs/core'
import apis from 'browser/app/models/apis'
import { MessagePayload, getMessaging, onMessage } from 'firebase/messaging'
import { EntitySubscriptionManager } from 'browser/app/utils/entity-subscription-manager'
import { isReactElement } from 'shared-libs/helpers/utils'
import _ from 'lodash'
import { AsyncCell } from './cells/table-async-cell'

const HEADER_HEIGHT = 37
const CHECKBOX_COLUMN_KEY = 'CHECKBOX_COLUMN'

/**
 * @prop cellComponent - (optional)
 * @prop className - (optional) class name to be rendered in column html
 * @prop debugKey - (optional)
 * @prop groupKey - (optional)
 * @prop flexGrow - (optional) flexGrow CSS property
 * @prop format - (optional)
 * @prop formatter - (optional)
 * @prop formula - (optional)
 * @prop horizontalAlignment - (optional) alignment of column contents
 * @prop isFixed - (optional)
 * @prop isPrimary - (optional)
 * @prop isSortable - (optional)
 * @prop label - (optional) text label to be displayed in column header
 * @prop path - (optional)
 * @prop schema - (optional)
 * @prop sortKey: string
 * @prop textAlign - (optional) alignment of column text
 * @prop type - (optional)
 * @prop width - (optional) width of the table column
 */
export interface Column {
  cellComponent?: any
  className?: string
  debugKey?: string
  groupKey?: string
  flexGrow?: number
  format?: {
    type: string
  }
  formatter?: Function
  formula?: any
  horizontalAlignment?: 'left' | 'right' | 'center'
  isFixed?: boolean
  isPrimary?: boolean
  isSortable?: boolean
  label?: string
  path?: string
  schema?: any
  sortKey?: string
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit'
  type?: any
  width?: number
}

/**
 * @uiComponent
 */
export interface ITableProps extends IBaseProps {
  childrenKey?: string
  columns: Column[]
  enableCtrlClick?: boolean
  enableGroupSimplification?: boolean
  enableMultiSelect?: boolean
  enableShiftClick?: boolean
  enableRowClick?: boolean
  emptyStateView?: React.ReactElement<any>
  footerContent?: React.ReactElement<any>
  frames: any
  height?: number
  isResizable?: boolean
  isSelectable?: boolean
  keyPath?: string | ((item: any, index: number) => string)
  maxHeight?: number
  onColumnHeaderClick?: (column: any) => void
  onRowClick?: (selection: any, selectedRow: any, parentRows?: any[]) => void
  onEndReached?: () => void
  considerLoadingNextPage?: (int) => void
  onPaginateGroup?: (groups) => Promise<any>
  onRowSelect?: (selection: any, selectedRow: any, parentRows?: any[]) => void
  onVerticalScroll?: any
  orders?: any[]
  rowHeight?: number
  rows: any[]
  selection?: any[]
  width?: number
  onColumnsChange?: (columns: any[]) => void
}

interface ITableStates {
  columns: Column[]
  isAllSelected?: boolean
  rows: any[]
  rowStates: any[]
  selection: any[]
  // track if a load more row is selected separately from other selections
  // since we never want it to be included in any actions taken against selected data
  selectedLoadMoreIndex: number
}

export enum CollapseState {
  COLLAPSED = 'collapsed',
  EXPANDED = 'expanded',
}

@HotkeysTarget
export class Table extends React.Component<ITableProps, ITableStates> {
  public static defaultProps: Partial<ITableProps> = {
    childrenKey: 'children',
    considerLoadingNextPage: _.noop,
    enableMultiSelect: true,
    enableRowClick: true,
    isResizable: true,
    keyPath: 'data.uniqueId',
    maxHeight: 500,
    onColumnHeaderClick: _.noop,
    onEndReached: _.noop,
    onRowClick: _.noop,
    onRowSelect: _.noop,
    // c-button--sm height + 1px
    rowHeight: 37,
    width: 500,
  }

  private isColumnResizing: boolean
  private lastSelected: any
  private edges: string[]

  constructor(props) {
    super(props)
    this.isColumnResizing = false
    this.lastSelected = null
    this.state = {
      ...this.getNextStateFromProps(props),
      isAllSelected: false,
      selectedLoadMoreIndex: undefined
    }
    onMessage(getMessaging(), this.handleNotification)
    window.addEventListener('beforeunload', this.componentWillUnmount.bind(this))
    this.updateEntitySubscriptions(props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.rows !== nextProps.rows ||
      this.props.columns !== nextProps.columns ||
      this.props.selection !== nextProps.selection
    ) {
      this.setState(this.getNextStateFromProps(nextProps))
      if (this.props.rows !== nextProps.rows) {
        this.updateEntitySubscriptions(nextProps)
      }
    }
    if (this.props.width !== nextProps.width) {
      this.setState(this.getNextColumnsFromProps(nextProps))
    }
  }

  componentWillUnmount(): void {
    const updatesEnabled = apis.getSettings().getRemoteConfigValue('entityUpdates').asBoolean()
    if (updatesEnabled) {
      EntitySubscriptionManager.removeEntityUpdateSubscriptions(this.edges)
        .catch((e) => console.log)
    }
  }

  public render() {
    const hasData = !_.isEmpty(this.state.rows)

    // TODO(louis): Needs to be refactored. Odd thing happening with height
    // and max-height.
    const {
      className,
      enableRowClick,
      footerContent,
      height,
      maxHeight,
      onVerticalScroll,
      width,
      rowHeight,
    } = this.props
    return (
      <>
        <FixedDataTable
          className={classNames('c-fixedDataTable', className, {
            'c-fixedDataTable--noClick': !enableRowClick,
          })}
          footerHeight={footerContent ? HEADER_HEIGHT : undefined}
          headerHeight={HEADER_HEIGHT}
          height={height}
          isColumnResizing={this.isColumnResizing}
          maxHeight={!hasData ? rowHeight : maxHeight}
          onColumnResizeEndCallback={this.handleColumnResizeEndCallback}
          onRowClick={this.handleRowClick}
          onScrollEnd={this.handleScrollEnd}
          onVerticalScroll={onVerticalScroll}
          rowClassNameGetter={this.getPrimaryColumnClassNameBinded}
          rowHeight={rowHeight}
          rowsCount={this.state.rows.length}
          width={width}
        >
          {this.renderColumns()}
        </FixedDataTable>

        {!hasData && this.renderEmptyState()}
      </>
    )
  }

  public renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          allowInInput={true}
          combo="ctrl+["
          label="Up"
          onKeyDown={this.moveToPrevRow}
          stopPropagation={true}
          preventDefault={true}
          global={true}
        />
        <Hotkey
          allowInInput={true}
          combo="ctrl+;"
          label="Down"
          onKeyDown={this.moveToNextRow}
          stopPropagation={true}
          preventDefault={true}
          global={true}
        />
      </Hotkeys>
    )
  }

  ///////////////////
  // Hotkey support
  ///////////////////
  private moveToPrevRow = () => {
    if (!this.canUseKeyboardNavigation()) {
      return
    }
    const index = this.getCurrentRow()
    const clickIndex = this.findNextSelectableRow(index, -1)
    this.clickRow(clickIndex)
  }

  private moveToNextRow = () => {
    if (!this.canUseKeyboardNavigation()) {
      return
    }
    const index = this.getCurrentRow()
    const clickIndex = this.findNextSelectableRow(index, 1)
    this.clickRow(clickIndex)
  }

  private findNextSelectableRow = (index: number, directionDelta: number) => {
    const { rows } = this.state
    let currIndex = index + directionDelta
    let candidateRow = rows[currIndex]
    while (currIndex >= 0 && currIndex < rows.length && _.get(candidateRow, 'children.length', 0) > 0) {
      currIndex += directionDelta
      candidateRow = rows[currIndex]
    }
    return currIndex
  }

  private canUseKeyboardNavigation = () => {
    const { isAllSelected, selection } = this.state
    return !isAllSelected && selection.length <= 1
  }

  private getCurrentRow = () => {
    const { selection, selectedLoadMoreIndex } = this.state
    let index = -1
    if (selection.length === 1) {
      index = this.state.rows.indexOf(this.state.selection[0])
    } else if (selection.length === 0 && !_.isUndefined(selectedLoadMoreIndex)) {
      index = selectedLoadMoreIndex
    }
    return index
  }

  private clickRow = (index: number) => {
    const row = this.state.rows[index]
    if (row) {
      if (this.isGroupExpansionRow(row)) {
        this.setState({
          selectedLoadMoreIndex: index
        })
        this.focusLoadMore(index)
        this.props.onRowClick([], row, this.getParentRows(index))
        this.clearSelection()
      } else {
        this.setState({
          selectedLoadMoreIndex: undefined
        })
        this.props.onRowClick([row], row, this.getParentRows(index))
      }
    }
  }

  private focusLoadMore = (index: number) => {
    const anchor = document.getElementById(`${LOAD_MORE_PREFIX}${index}`)
    if (anchor) {
      anchor.focus()
    }
  }


  //////////////////////////////////////////////////////////////////////////////
  // Internal state management
  //////////////////////////////////////////////////////////////////////////////
  private getNextColumnsFromProps(props) {
    const { width } = props
    const columns = _.cloneDeep(props.columns)
    const normalColumns = _.filter(columns, (column) => !column.flexGrow)
    const flexGrowColumns = _.filter(columns, (column) => column.flexGrow)
    // Find its initial width by subtracting width from all known width
    let flexGrowWidth = width
    _.forEach(normalColumns, (column) => (flexGrowWidth -= column.width))
    const colFlexGrowWidth = flexGrowWidth / flexGrowColumns.length
    _.forEach(flexGrowColumns, (column: any) => {
      column.width = Math.max(column.width, colFlexGrowWidth)
    })
    return { columns }
  }

  private getNextStateFromProps(props) {
    const { columns } = this.getNextColumnsFromProps(props)
    const { rows, rowStates, selection, selectedLoadMoreIndex } = this.syncSelectionState(props)
    return {
      columns,
      rowStates,
      rows,
      selection,
    }
  }

  private flattenTree(rows, level = 0, isSelected = false) {
    const results = { rows: [], rowStates: [] }
    _.forEach(rows, (row, i) => {
      const rootIndex = i
      const childRowState = { level, isSelected, rootIndex }
      this.recursiveFlattenTree(results, row, childRowState, rootIndex)
    })
    return results
  }

  private getChildren(row) {
    return row[this.props.childrenKey] || []
  }

  private hasChildren(row) {
    return this.getChildren(row).length > 0
  }

  // rootIndex is the index of the entity at the root level associated with the given entity
  private recursiveFlattenTree(results, node, defaultRowState, rootIndex) {
    const { isSelected, level } = defaultRowState
    const { rows, rowStates } = results
    const { enableGroupSimplification, onPaginateGroup } = this.props
    const children = this.getChildren(node)
    // if we enable group simplifcation, we pull the children up a level
    // when it is the only child
    if (enableGroupSimplification && children.length === 1) {
      this.recursiveFlattenTree(results, children[0], defaultRowState, rootIndex)
      return
    }
    rows.push(node)
    rowStates.push(defaultRowState)
    const parent = {
      state: defaultRowState,
      row: node,
    }

    _.forEach(children, (child) => {
      const childRowState = { isSelected, level: level + 1, parent, rootIndex }
      this.recursiveFlattenTree(results, child, childRowState, rootIndex)
    })
    // We add a virtual row to the group to allow user to click and load more rows
    if (
      onPaginateGroup &&
      children.length > 0 &&
      children.length < node.metadata.totalChildrenCount
    ) {
      rows.push({
        isVirtual: false,
        type: 'GroupExpansionRow',
      })
      rowStates.push({ isSelected, level: level + 1, parent, rootIndex })
    }
  }

  private async updateEntitySubscriptions(props: ITableProps) {
    try {
      const updatesEnabled = await apis.getSettings().getRemoteConfigValue('entityUpdates').asBoolean()
      if (!updatesEnabled) {
        return
      }
    } catch(e) {
      console.warn(`failed to get remote config: ${e.message}: ${e.stack}`)
    }
    // NB: since rows is declared an `Any` in props, we can't guarantee that
    // there's a data member.
    const allEdges = new Set<string>(_.compact(_.flatMap(props.rows, row => [
      row.data?.uniqueId,
      ...(row.data?.getEdges()?.map(edge => edge.value.entityId) ?? [])
    ])))
    const updatedEdges = [...allEdges]
    await EntitySubscriptionManager.diffEntitySubscriptions(this.edges, updatedEdges)
    this.edges = updatedEdges
  }

  //////////////////////////////////////////////////////////////////////////////
  // Event Handling Code
  //////////////////////////////////////////////////////////////////////////////

  private handleColumnResizeEndCallback = (newColumnWidth, index) => {
    const { columns, onColumnsChange } = this.props
    if (onColumnsChange) {
      const newColumns = _.cloneDeep(columns)
      newColumns[index].width = newColumnWidth
      delete newColumns[index].flexGrow
      onColumnsChange(newColumns)
    } else {
      this.props.columns[index].width = newColumnWidth
      delete this.props.columns[index].flexGrow
      const updatedColumns = _.map(this.state.columns, (column, columnIndex) => {
        if (columnIndex === index) {
          return {
            ...column,
            width: newColumnWidth
          }
        } else {
          return column
        }
      })
      this.setState({
        columns: updatedColumns
      })
      this.forceUpdate()
    }
  }

  private handlePaginateGroup = (event, rowIndex) => {
    const { onPaginateGroup } = this.props
    const { rows, rowStates } = this.state
    const parentRows = this.getParentRows(rowIndex)
    onPaginateGroup(parentRows)
  }

  private handleRowClick = (event, index) => {
    const { enableRowClick } = this.props
    const { selection, rows, rowStates } = this.state
    const row = rows[index]
    const rowState = rowStates[index]

    if (enableRowClick) {
      if (row.isVirtual === false) {
        return
      }
      if (rowState.isCollapsed) {
        this.handleToggleCollapseExpandRow(event, index)
        return
      }
      const ctrlPressed = this.props.enableCtrlClick && (event.ctrlKey || event.metaKey)
      const shiftPressed = this.props.enableShiftClick && event.shiftKey
      this.selectRow(row, index, ctrlPressed, shiftPressed)
    }
  }

  private handleScrollEnd = (hscroll, vscroll) => {
    const contentHeight = this.state.rows.length * this.props.rowHeight
    const tableHeight = this.props.height || this.props.maxHeight
    const bodyHeight = tableHeight - HEADER_HEIGHT
    const maxScrollY = contentHeight - bodyHeight
    if (contentHeight > bodyHeight && vscroll > 0.9 * maxScrollY) {
      this.props.onEndReached()
    }
  }

  private handleNotification = async (e: MessagePayload) => {
    const uri = _.get(e, 'data.uri')
    if (!uri) {
      return
    }
    try {
      const parsedUri = new URL(uri)
      if (parsedUri.pathname !== '/actions/entity/update') return
      // Mobile and web seem to present the notification differently
      const json = parsedUri.searchParams.get('jsonProps').replace(/\\/g,'')
      const payload = JSON.parse(json)
      await Promise.all(_.map(payload.updatedEntities, async (updated) => {
        const record = apis.getStore().getRecord(updated)
        if (_.isNil(record)) {
          return apis.getStore().getOrFetchRecord(updated)
        } else {
          return record.reload()
        }
      }))
      this.forceUpdate()
    } catch (err) {
      console.warn(`failed to parse notification: ${err.message}\n${err.stack}`)
    }
  }

  public toggleAllRows = (state: CollapseState) => {
    const { rows } = this.state

    // note(joco) - we need to iterate manually via index because expanding
    // the table can change subsequent rows on each iteration
    for (let i = 0; i < rows.length; i += 1) {
      const row = rows[i]

      if (_.isString(row.data)) { // group rows -- we don't have a cleaner way to define this (todo)
        this.handleToggleCollapseExpandRow(undefined, i, undefined, undefined, state)
      }
    }
  }

  private handleToggleCollapseExpandRow = (event, index, propRows?, propRowStates?, desiredState?: CollapseState) => {
    event?.stopPropagation()
    const { enableMultiSelect } = this.props
    const rows = propRows || this.state.rows
    const rowStates = propRowStates || this.state.rowStates
    const row = rows[index]
    const rowState = rowStates[index]
    const children = this.getChildren(row)
    const flattenedResult = this.flattenTree(
      children,
      rowState.level + 1,
      enableMultiSelect ? rowState.isSelected : false
    )

    const collapseFn = () => {
      rows.splice(index + 1, flattenedResult.rows.length)
      rowStates.splice(index + 1, flattenedResult.rows.length)
      rowState.isCollapsed = true
    }
    const expandFn = () => {
      Array.prototype.splice.apply(rows, [index + 1, 0].concat(flattenedResult.rows))
      Array.prototype.splice.apply(rowStates, [index + 1, 0].concat(flattenedResult.rowStates))
      rowState.isCollapsed = false
    }

    if (desiredState) {
      if (desiredState === CollapseState.COLLAPSED && !rowState.isCollapsed) {
        collapseFn()
      } else if (desiredState === CollapseState.EXPANDED && rowState.isCollapsed) {
        expandFn()
      }
    } else {
      if (rowState.isCollapsed) {
        expandFn()
      } else {
        collapseFn()
      }
    }

    _.isNil(propRows) && this.setState({ rows, rowStates })
  }

  private handleToggleRowSelection = (event, index) => {
    event.stopPropagation()
    const { onRowSelect } = this.props
    const { rows, rowStates, selection } = this.state
    const row = rows[index]
    const isSelected = !rowStates[index].isSelected
    if (isSelected) {
      this.addToSelection(row)
      this.getChildren(row).forEach((item) => this.addToSelection(item))
    } else {
      this.removeFromSelection(row)
      this.getChildren(row).forEach((item) => this.removeFromSelection(item))
    }
    const parentRows = this.getParentRows(index)
    onRowSelect(selection.slice(), row, parentRows)
  }

  //////////////////////////////////////////////////////////////////////////////
  // Selection Code
  //////////////////////////////////////////////////////////////////////////////

  private addToSelection(item, rows?, rowStates?, selection?) {
    rows = rows || this.state.rows
    rowStates = rowStates || this.state.rowStates
    selection = selection || this.state.selection
    const index = rows.indexOf(item)
    if (index >= 0 && !rowStates[index].isSelected) {
      rowStates[index].isSelected = true
      selection.push(item)
    }
  }

  private removeFromSelection(item, rows?, rowStates?, selection?) {
    rows = rows || this.state.rows
    rowStates = rowStates || this.state.rowStates
    selection = selection || this.state.selection
    const index = rows.indexOf(item)
    if (index >= 0 && rowStates[index].isSelected) {
      rowStates[index].isSelected = false
      _.pull(selection, item)
    }
  }

  private clearSelection() {
    _.forEach(this.state.rowStates, (item) => {
      item.isSelected = false
    })
    _.remove(this.state.selection)
  }

  private getParentRows(index) {
    const parents = []
    let rowState = this.state.rowStates[index]

    while (!_.isNil(rowState.parent)) {
      parents.push(rowState.parent.row)
      rowState = rowState.parent.state
    }
    return parents.reverse()
  }

  private getRowsInRange(item1, item2) {
    const rows = this.state.rows
    const index1 = rows.indexOf(item1)
    const index2 = rows.indexOf(item2)
    if (index1 === -1 || index2 === -1) {
      return []
    }
    const begin = index1 < index2 ? index1 : index2
    const end = index1 < index2 ? index2 : index1
    const result = []
    rows.forEach((item, index) => {
      if (index < begin || index > end) {
        return
      }
      result.push(item)
      this.getChildren(item).forEach((doc) => result.push(doc))
    })
    return result
  }

  private selectRow(row, index, ctrlPressed, shiftPressed) {
    const { enableMultiSelect, onRowClick } = this.props
    const { rowStates, selection } = this.state
    // if onRowSelect is not defined, turn selection off. This is a controlled
    // component, onRowSelect has to be defined
    if (!onRowClick) {
      return
    }
    const lastSelected = this.lastSelected
    if (shiftPressed && lastSelected) {
      const rows = this.getRowsInRange(lastSelected, row)
      rows.forEach((item) => this.addToSelection(item))
    } else if (ctrlPressed) {
      const isSelected = !rowStates[index].isSelected
      if (isSelected) {
        this.addToSelection(row)
        this.getChildren(row).forEach((item) => this.addToSelection(item))
      } else {
        this.removeFromSelection(row)
        this.getChildren(row).forEach((item) => this.removeFromSelection(item))
      }
    } else {
      this.clearSelection()
      this.addToSelection(row)
      // if multi select is enabled, add children to selection
      if (enableMultiSelect) {
        this.getChildren(row).forEach((item) => this.addToSelection(item))
      }
    }
    this.lastSelected = row
    const parentRows = this.getParentRows(index)
    onRowClick(selection.slice(), row, parentRows)
  }

  private syncSelectionState(props) {
    const { enableMultiSelect, keyPath } = props
    const previousLoadMoreIndex = this.state ? this.state.selectedLoadMoreIndex : undefined
    const keyExtractor = _.isFunction(keyPath)
      ? keyPath
      : (item, index) => _.get(item, keyPath, item)
    const { rows, rowStates } = this.flattenTree(props.rows)
    const candidates = _.map(props.selection, (row) => {
      const itemKey = keyExtractor(row)
      const match = _.find(rows, (item) => keyExtractor(item) === itemKey)
      return match || row
    })
    const selection = []
    _.forEach(candidates, (row) => {
      this.addToSelection(row, rows, rowStates, selection)
      // if multi select is enabled, add children to selection
      if (enableMultiSelect) {
        this.getChildren(row).forEach((child) => {
          this.addToSelection(child, rows, rowStates, selection)
        })
      }
    })

    this.applyCollapseState(rows, rowStates)

    if (candidates.length === 0
      && !_.isUndefined(previousLoadMoreIndex)
      && !this.isGroupExpansionRow(rows[previousLoadMoreIndex])) {
        // use case: user is focused on 'Load more', we want to auto focus the
        // entity that takes its place after fetching more data
        this.clickRow(previousLoadMoreIndex)
    }
    const selectedLoadMoreIndex = selection.length > 0 ? undefined : previousLoadMoreIndex

    this.lastSelected = _.first(selection)
    return { rowStates, rows, selection, selectedLoadMoreIndex }
  }

  private applyCollapseState(rows, rowStates) {
    const curRows = this.state?.rows
    const curRowStates = this.state?.rowStates

    if (_.isEmpty(curRows) || _.isEmpty(curRowStates)) {
      return
    }

    curRowStates.forEach((curState, i) => {
      if (!curState.isCollapsed) {
        return
      }

      const curGroup = curRows[i].data // todo - column group key technically, but it's set to data 100% of the time...
      const groupIndex = rows.findIndex(row => row.data === curGroup)

      if (groupIndex < 0) {
        return
      }

      this.handleToggleCollapseExpandRow(undefined, groupIndex, rows, rowStates)
    })
  }

  //////////////////////////////////////////////////////////////////////////////
  // Render Column
  //////////////////////////////////////////////////////////////////////////////

  private renderCellComponent({ column, data, value }) {
    const { frames } = this.props
    const renderer = frames.getContext('renderer')
    const uiSchemaPath = frames.getContext('uiSchemaPath').concat('cellComponent')
    const context = {
      uiContext: _.set({}, uiSchemaPath, column.cellComponent),
      uiSchema: column.cellComponent,
      uiSchemaPath: uiSchemaPath,
    }

    const newFrames = renderer.createChildFrame(frames, context, { data: data.data })
    const props = _.omit(column.cellComponent, 'type')

    return renderer.createElementFromFrame(newFrames, {
      ...props,
      ...column,
      row: data,
      value,
    })
  }

  private getCellContent(data, column: Column, hasChildren = false) {
    // TODO(Peter): this is bad, we need to fix it
    const valuePath = hasChildren ? column.groupKey : column.path
    let value = valuePath === '.' ? data : _.get(data, valuePath)

    // legacy rendering for hard coded tables that dont pass frames
    if (column.cellComponent && !this.props.frames) {
      const TableCell = ComponentsMap[column.cellComponent.type]
      const props = _.omit(column.cellComponent, 'type')
      return React.createElement(TableCell, {
        row: data,
        value,
        ...column,
        ...props,
      })
    }

    const isGroupingRow = _.isString(data.data)
    if (isGroupingRow && !column.groupKey) {
      return
    }

    // TODO(Peter): this is bad to assume it has displayName
    if (column.groupKey && _.isObject(value) &&
      (!column.cellComponent || column.cellComponent.type === 'ui:table:tableConditionalStyleCell')
    ) {
      value = value.displayName ? value.displayName : value
    }

    // TODO(Andrew): this is a hack to run formatter on style cells since
    // other cellComponent cells have a column formatter for some unknown reason
    if (
      column.formatter &&
      !(hasChildren && column.groupKey) &&
      (!column.cellComponent || column.cellComponent.type === 'ui:table:tableConditionalStyleCell')
    ) {
      value = column.formatter(value)
    }

    if (column.cellComponent) {
      // Handling async values is the responsibility of the cellComponent
      return this.renderCellComponent({ column, data, value })
    }

    if (_.isObject(value) && !_.isArray(value) && !isReactElement(value) && !(value instanceof Promise)) {
      throw new Error(`Error: Cannot render object in table cell for path '${column.path}'`)
    }

    return value
  }

  private getSortArrow(column: Column) {
    const { orders } = this.props
    const sortKey = column.sortKey || column.path || ''
    // TODO(David): this is bad, clean this up
    const path = sortKey.replace(/^data\./, '')
    const order = _.find(orders, { path })
    if (_.isObject(order) && order.type) {
      return order.type === 'ascending' ? ' ↑' : ' ↓'
    }
    return ''
  }

  private renderColumns() {
    const { isResizable, isSelectable } = this.props
    const { columns, isAllSelected } = this.state
    const tableColumns = _.map(columns, (column: Column, index: number) => {
      const { isFixed, horizontalAlignment, isPrimary, width } = column
      const cell = isPrimary ? this.renderPrimaryColumn : this.renderColumnCell
      return (
        <ColumnComponent
          align={horizontalAlignment}
          cell={cell}
          columnKey={index}
          fixed={isPrimary || isFixed}
          // TODO(peter): Swap me out to make the footer
          footer={this.renderColumnFooter(column, index)}
          header={this.renderHeaderColumn(column, index)}
          isResizable={isResizable}
          key={column.path}
          width={width}
        />
      )
    })

    const selectColumnHeader = (
      <TableCheckboxCell
        isSelected={isAllSelected}
        onChange={this.toggleAllSelected}
        rowIndex={0}
      />
    )

    if (isSelectable) {
      tableColumns.unshift(
        <ColumnComponent
          cell={this.renderCheckboxColumnCell}
          columnKey={CHECKBOX_COLUMN_KEY}
          header={selectColumnHeader}
          fixed={true}
          key={CHECKBOX_COLUMN_KEY}
          width={50}
        />
      )
    }
    return tableColumns
  }

  private toggleAllSelected = () => {
    const { onRowSelect } = this.props
    const { isAllSelected, rows, rowStates, selection } = this.state
    _.forEach(rows, (row, index) => {
      if (!isAllSelected) {
        this.addToSelection(row)
        this.getChildren(row).forEach((item) => this.addToSelection(item))
      } else {
        this.removeFromSelection(row)
        this.getChildren(row).forEach((item) => this.removeFromSelection(item))
      }
      const parentRows = this.getParentRows(index)
      onRowSelect(selection.slice(), row, parentRows)
    })
    if (isAllSelected) {
      this.clearSelection()
    }
    this.setState({ isAllSelected: !isAllSelected })
  }

  private renderCheckboxColumnCell = (props) => {
    const { rowStates } = this.state
    const rowState = rowStates[props.rowIndex]
    return (
      <TableCheckboxCell
        isSelected={rowState.isSelected}
        onChange={this.handleToggleRowSelection}
        rowIndex={props.rowIndex}
      />
    )
  }

  private renderColumnCell = (props) => {
    const { columns, rows, rowStates } = this.state
    const column = columns[props.columnKey]
    const rowData = rows[props.rowIndex]
    const rootIndex = rowStates[props.rowIndex].rootIndex
    this.props.considerLoadingNextPage(rootIndex)
    const value = this.getCellContent(rowData, column)
    const cellValue = (value instanceof Promise) ? '' : value
    if (value instanceof Promise) {
      props.promise = value
    }
    
    return (
      <AsyncCell {...props} className={column.className}>
        {cellValue}
      </AsyncCell>
    )
  }

  private renderColumnFooter = (column: Column, index: number) => {
    const { footerContent } = this.props
    if (!footerContent) {
      return
    }
    return <Cell>{index === 0 ? 'Total' : this.getCellContent(footerContent, column)}</Cell>
  }

  private renderHeaderColumn(column: Column, index: number) {
    const fullLabel = `${column.label} ${this.getSortArrow(column)}`
    return (
      <TableHeaderColumnCell column={column} onClick={this.props.onColumnHeaderClick}>
        {fullLabel}
      </TableHeaderColumnCell>
    )
  }

  private renderEmptyState() {
    const { emptyStateView } = this.props
    const content = emptyStateView ? (
      emptyStateView
    ) : (
      <HelpBlock
        className="u-textCenter collapse"
        style={{
          paddingBottom: 8,
          // $v-spacing-unit--sm, but need to round to the nearest integer,
          // otherwise the table has odd artifacts
          paddingTop: 8,
        }}
      >
        No data available
      </HelpBlock>
    )
    return <div className="packetTable-empty">{content}</div>
  }

  private getPrimaryColumnClassNameBinded = (index) => {
    const { selectedLoadMoreIndex, rows, rowStates } = this.state
    const row = rows[index]
    const rowState = rowStates[index]
    if (this.hasChildren(row)) {
      return classNames({
        'is-active': rowState.isSelected,
        'is-collapsed': rowState.isCollapsed,
        'is-expanded': !rowState.isCollapsed,
        'is-packet': true,
      })
    }
    return classNames({
      'is-active': rowState.isSelected || index === selectedLoadMoreIndex,
    })
  }

  private renderPrimaryColumn = (props) => {
    const { columns, rows, rowStates } = this.state
    const column = columns[props.columnKey]
    const row = rows[props.rowIndex]
    const rowState = rowStates[props.rowIndex]
    const hasChildren = this.hasChildren(row)
    const isGroupExpansionRow = this.isGroupExpansionRow(row)
    const content = isGroupExpansionRow ? null : this.getCellContent(row, column, hasChildren)
    return (
      <TablePrimaryColumnCell
        {...props}
        column={column}
        isGroupExpansionRow={isGroupExpansionRow}
        level={rowState.level}
        onPaginateGroup={this.handlePaginateGroup}
        onToggleExpandCollapseRow={this.handleToggleCollapseExpandRow}
        showToggle={hasChildren}
        row={row}
      >
        {content}
      </TablePrimaryColumnCell>
    )
  }

  private isGroupExpansionRow = (row) => {
    return row && row.type === 'GroupExpansionRow'
  }
}
