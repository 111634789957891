import React from 'react'
import { Link } from 'react-router-dom'

import { Checkbox } from 'browser/components/atomic-elements/atoms/checkbox/checkbox'
import { Entity } from 'shared-libs/models/entity'

interface IGeneratedDocumentItemProps {
  entity: Entity
  isSelected: boolean
  item: any
  onSelect: (entity: Entity) => void
}

export const GeneratedDocumentItem: React.FC<IGeneratedDocumentItemProps> = (props) => {
  const { entity, isSelected, item, onSelect } = props
  const route = {
    pathname: `/view/${item.viewId}`,
    search: `&entityId=${entity.get('uniqueId')}&isPrint=1`,
  }

  const handleSelect = () => {
    onSelect(item)
  }

  return (
    <tr className='bt b--light-gray'>
      <td className='c-table-cell c-table-cell--verticallyCenter'>
        <div className='flex'>
          <div>
            <Checkbox
              onClick={handleSelect}
              label=''
              value={isSelected}
            />
          </div>
          <Link
            target='_blank' rel='noopener noreferrer'
            to={route}
          >
            {item.label}
          </Link>
        </div>
      </td>
    </tr>
  )
}
