import classNames from 'classnames'
import JSONStringify from 'json-stable-stringify'
import _ from 'lodash'
import React from 'react'
import { Classes, Colors, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { Entity } from 'shared-libs/models/entity'

import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CodeMirrorInput } from 'browser/components/atomic-elements/atoms/input/code-mirror-input'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { linter } from '@codemirror/lint'
import 'browser/components/atomic-elements/organisms/internal-tools/_json-editor.scss'

/**
 * @uiComponent
 */
interface IJSONEditorProps extends IBaseProps {
  entity: Entity
  onChange: (value: Entity) => void
  showControls?: boolean
}

interface IJSONEditorState {
  error: string
  json: any
  jsonString: string
}

export class JSONEditor extends React.Component<IJSONEditorProps, IJSONEditorState> {

  static defaultProps: Partial<IJSONEditorProps> = {
    showControls: false,
  }

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      error: null,
      json: _.cloneDeep(entity.content),
      jsonString: JSONStringify(entity.content, { space: '  ' }),
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { json } = this.state
    const newJson = nextProps.entity.content
    if (!_.isEqual(json, newJson)) {
      // preserve user formatting except when resetting the entity
      const jsonString = nextProps.entity.isDirty
        ? this.state.jsonString
        : JSONStringify(newJson, { space: '  ' })

      this.setState({
        json: _.cloneDeep(newJson),
        jsonString,
      })
    }
  }

  public render() {
    return (
      <div className={classNames('grid-block vertical', this.props.className)}>
        {this.renderHeaderControls()}
        {this.renderJSONEditor()}
      </div>
    )
  }

  private renderJSONEditor() {
    const { jsonString } = this.state
    return (
      <div className='grid-block'>
        <div className='grid-content collapse c-settingsMetadata-schema'>
          <CodeMirrorInput
            value={jsonString}
            onChange={this.handleJSONChanged}
            extensions={[
              json(),
              linter(jsonParseLinter()),
            ]}
          />
        </div>
      </div>
    )
  }

  private renderHeaderControls() {
    const { showControls } = this.props
    if (!showControls) {
      return null
    }
    return (
      <div className="tr pa1">
        <Button
          data-debug-id="formatCodeButton"
          className={Classes.BUTTON}
          onClick={this.format}
        >
          <Icon icon={IconNames.SORT_ALPHABETICAL} color={Colors.DARK_GRAY1} />
        </Button>
      </div>
    )
  }

  private format = () => {
    const { entity } = this.props
    const formatted = JSONStringify(entity.content, { space: '  ' })
    this.handleJSONChanged(formatted)
  }

  private handleJSONChanged = (newCode) => {
    const { entity, onChange } = this.props
    try {
      const json = JSON.parse(newCode)
      entity.setContent(json)
      this.setState({ jsonString: newCode })
      onChange(entity)
    } catch (ex) {
      this.setState({ jsonString: newCode, error: '' })
    }
  }
}
