import React from 'react'
import _ from 'lodash'
import Immutable from 'immutable'

import { Formatter } from '../../helpers/formatter'
import { FramesManager } from '../view/frames-manager'
import {
  IAbstractRendererProps,
  IAbstractRendererState,
  AbstractRenderer,
} from '../abstract-renderer'
import { buildErrorTree } from './helper'
import { Entity } from '../../models/entity'
import { JSONSchemaResolver } from '../../resolvers/json-schema-resolver'

// TODO(Peter): this is probably not the right place to import prototype extensions
import '../../helpers/prototype-extensions'
import { Store } from '../../models/store'
import { IFormulaTaskResult } from './formula-batch-processor'

export interface IEntityRendererProps extends IAbstractRendererProps {
  applyDefaults?: boolean
  errors?: any
  onChange?: (value: Entity, dataSchemaPath: string[]) => void
  onChangeComplete?: (value: Entity) => void
  onSubmitEditing?: (target: any, ref: any, refTree: any, entity: Entity) => void
  value: Entity
  uiContext?: any
  uiSchema?: any
  uiSchemaPath: string | string[]
  isEditable?: boolean
}

interface IEntityRendererState extends ErrorState, IAbstractRendererState {
  contextProps: any
  uiContext: any
  uiSchema: any
  uiSchemaPath: string
  translationTable: any
}

type ErrorState = {
  errors: any
  errorsTree: any
  hasClearedErrors: boolean
}

export const ENTITY_COMPUTATION_STATUS = 'ENTITY_COMPUTATION_STATUS'

export class EntityRenderer extends AbstractRenderer<IEntityRendererProps, IEntityRendererState> {
  public static defaultProps: Partial<IEntityRendererProps> = {
    isEditable: true
  }

  private errorState: ErrorState

  constructor(props) {
    super(props)
    this.state = this.getNextStateFromProps(props)
    this.errorState = this.getErrorInfo()
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(this.state.errors, nextProps.errors) ||
      !_.isEqual(this.props.errors, nextProps.errors) ||
      !_.isEqual(this.props.uiContext, nextProps.uiContext) ||
      !_.isEqual(this.props.uiSchemaPath, nextProps.uiSchemaPath) ||
      !_.isEqual(this.props.uiSchema, nextProps.uiSchema) ||
      this.props.value !== nextProps.value
    ) {
      this.setState(this.getNextStateFromProps(nextProps))
      this.handleFormulaComponentRegistrationChange()
    }
    if (nextProps.value.get('dailyPaySheet.payStatus') === 'Pending') {
      const json = JSON.stringify(nextProps.value.content, null, 2)
    }
  }

  public getComponentDataSchema(schemaPath) {
    const entity = this.props.value
    const resolved = entity.resolveSubschemaByPath(schemaPath)
    if (!resolved) {
      // treat it as warning if an enriched data path is detected.  Server
      // could enrich entity by injecting data that is not defined in the
      // json schema like 'denormalizedProperties'
      const joinedPath = schemaPath.join('.')
      if (!joinedPath.includes('denormalizedProperties')) {
        console.error(`Could not find data schema for path: ${schemaPath}`)
      }
    }
    return resolved ? resolved.schema : null
  }

  public findElement(matcher: (element) => boolean) {
    const { uiSchemaPath } = this.state
    const root = _.get(this.trackedRefsBySchemaPath, uiSchemaPath)
    return findElement(root, matcher)
  }

  public findElementById(id: string) {
    return this.findElement((element) => element.props.refId === id)
  }

  protected getNextStateFromProps(props) {
    const { errors, value, uiSchema } = props
    let { uiContext, uiSchemaPath } = props
    let resolvedUISchema
    const entity = value
    if (!uiSchemaPath) {
      throw new Error('uiSchemaPath must be defined')
    }
    if (_.isString(uiSchemaPath)) {
      uiSchemaPath = uiSchemaPath.split('.')
    }

    if (!_.isEmpty(uiSchema)) {
      resolvedUISchema = uiSchema
    } else if (uiContext) {
      resolvedUISchema = _.get(uiContext, uiSchemaPath, uiContext)
    } else {
      const resolved = entity.resolveSubschemaByPath(uiSchemaPath)
      uiContext = resolved.context
      resolvedUISchema = resolved.schema
    }

    /**
     * The translation table starts with the toplevel Entity translations,
     * then any translation tables in the path along the `uiSchemaPath`.
     * We merge translations down along the way, so a lower level redefinition overrides
     * one from a higher level, similar to how variable scopes work in a language.
     * VD-7726 Fetch order is uiContext.get, for when uiContext is an entity, then uiContext.translations, for when it isn't,
     * then entity.translationTable, to pick up the translation tables from its schemas.
     */
    const translationPath = 'translations'
    const translationTable = _.merge({}, uiContext?.get?.(translationPath) || uiContext?.[translationPath] || entity?.translationTable)
    for (let idx = 0; idx <= uiSchemaPath.length; idx++) {
      const path : string[] = _.slice(uiSchemaPath, 0, idx)
      const table = _.get(uiContext, [...path, translationPath])
      _.merge(translationTable, table)
    }
    // These are injected into every component in the tree
    const contextProps = {
      entity,
      ...props.context,
    }
    return {
      contextProps,
      errors,
      errorsTree: buildErrorTree(errors),
      hasClearedErrors: false,
      extras: {},
      translationTable,
      uiContext,
      uiSchema: resolvedUISchema,
      uiSchemaPath,
    }
  }

  /****************************************************************************/
  // Form related
  /****************************************************************************/

  // clones error state and adds a map to track which paths had errors that were
  // cleared, so we can separate individual path clearings from a final
  // setState, and avoid a re-render if no new error information is present.
  private getErrorInfo(): ErrorState {
    const { errors, errorsTree } = this.state
    const state = _.cloneDeep({ errors, errorsTree })
    return {
      ...state,
      hasClearedErrors: false,
    }
  }

  // reset individual paths on an ErrorState
  protected clearErrors(path, errorState: ErrorState) {
    const { errorsTree, errors } = errorState
    const prevErrors = _.get(errorsTree, path)
    if (prevErrors) {
      const jsonPath = _.join(path, '.')
      _.unset(errors, jsonPath)
      _.set(errorsTree, path, undefined)
      errorState.hasClearedErrors = true
    }
  }

  // commit the ErrorState to component state
  protected finalizeErrorState(state: ErrorState) {
    if (state.hasClearedErrors) {
      this.setState(state)
    }
  }

  protected getErrors(path, alternatePath?) {
    if (!path) return
    const { errorsTree } = this.state
    const error = _.get(errorsTree, path)
    if (error) {
      return error
    }
    if (alternatePath) {
      return _.get(errorsTree, alternatePath)
    }
  }

  protected getIsRequired(frames, path) {
    const fieldPath = _.last(path)
    let parentDataSchema = frames.getParentContext('dataSchema')
    // take care of base case here. If we cannot find a parent data schema
    // we should look at the namespace. In the case of e.g. dispatchOrder.identifier
    // we should see which or the entity's mixin has the namespace dispatchOrder
    // and use it as the parentDataSchema
    if (parentDataSchema === null) {
      const prefixPath = _.initial(path)
      const dataSchemaPath = JSONSchemaResolver.getSchemaPathFromValuePath(path)
      parentDataSchema = this.getComponentDataSchema(dataSchemaPath)
    }
    // changing from `parentDataSchema.required` to _.get() will probably
    // get rid of the white screen as well.  If the path is incorrect, that
    // ui component won't get displayed
    const required = _.get(parentDataSchema, 'required')
    return required && _.includes(required, fieldPath)
  }

  protected getInitialValue(path, schema) {
    const { applyDefaults } = this.props
    const entity = this.props.value
    let value = this.getValue(path)
    if (applyDefaults) {
      const defaultValue = _.get(schema, 'default')
      if (_.isUndefined(value) && !_.isUndefined(defaultValue)) {
        entity.commit(path, defaultValue)
        value = defaultValue
      }
    }
    return value
  }

  protected getValue(path) {
    const value = this.props.value
    return _.isEmpty(path) ? value : _.get(value, path)
  }

  protected setValue = (
    path,
    newValue,
    oldValue,
    silentUpdate = false
  ): boolean => {
    /**
     * In the case of object/array values, we can't just simply do a deep
     * comparison because oldValue is the same object instance as newValue.
     *
     * E.g. the list component pushes an item to its array value when the user
     * clicks the "add item" button, and then sets the array value here as the
     * `newValue`. But since `oldValue` is the same array, the deep comparison
     * thinks nothing has changed. If we could preserve/copy the previous array
     * first, that would allow the deep comparison to work, otherwise we have to
     * use the !isObject as an escape hatch. This leads to more unnecessary
     * re-renders when values are objects, but this will be a fix for a later
     * stage.
     *
     * A question to ask during later refactor is whether components should be
     * responsible for providing a whole new object value, or in other words
     * should the "value" passed down to components be immutable.
     */
    if (newValue === oldValue && !_.isObject(newValue)) {
      return false
    }
    const { value, onChange } = this.props
    value.beforeChange(path, oldValue, newValue, silentUpdate)
    if (silentUpdate === true) {
      // some components call `onChange` with the 2nd argument being an object,
      // so explicitly check for `true` to avoid allowing any truthy value to
      // trigger this, for now. We should see if components can be safely
      // refactored to conform better to the api of component prop `onChange` to
      // reduce ambiguity.
      value.commit(path, newValue)
    } else {
      value.set(path, newValue)
    }
    this.clearErrors(path, this.errorState)

    if (onChange) {
      onChange(value, path)
    }
    return true
  }



  // immediate response to user edits
  protected handleChange = (path, newValue, oldValue, silentUpdate = false, isTransient = false) => {
    const { isEditable } = this.props

    const { value } = this.props
    this.handleFormulaComponentRegistrationChangeDebounced.cancel()
    this.cancelPendingFormulaWork()
    // Discard any user-edited changes if it is in read-only mode.
    const userEdited = isTransient
      ? this.setTransientValue(path, newValue, oldValue)
      : isEditable && this.setValue(path, newValue, oldValue, silentUpdate)
    value.emit(ENTITY_COMPUTATION_STATUS, 'busy')
    this.handleChangeDebounced(userEdited)
  }

  /**
   * Run final edit actions, like emitting Entity change events, etc.
   * @param results Batched formula results
   * @param userEdited Whether a user edit caused a change.
   */
  protected handleChangesComplete = (
    results: IFormulaTaskResult[],
    userEdited = false
  ): void => {
    const { value, onChangeComplete } = this.props
    const formulasEdited = _.some(
      results,
      (result) => !_.isEqual(result.task.originalValue, result.value)
    )
    const hasChanges = userEdited || formulasEdited
    this.startFormulaRefreshTimer(hasChanges)

    value.emit(ENTITY_COMPUTATION_STATUS, 'ready')

    if (!hasChanges) {
      return
    }

    const shouldRefreshStore = _.some(
      results,
      (result) => result.task.schema.isComputedProperty || result.task.valuePath
    )
    if (shouldRefreshStore) {
      value.getStore().emit(Store.RECORD_CHANGED, value)
    }
    value.emit(Store.RECORD_CHANGED, value)

    this.finalizeErrorState(this.errorState)

    if (onChangeComplete) {
      onChangeComplete(value)
    }
  }

  protected addInflationSessionId = (id) => {
    this.props.value.addInflationSessionId(id)
    if (this.props.onChangeComplete) {
      this.props.onChangeComplete(this.props.value) // This is just forcing an update at the parent level
    }
  }

  protected removeInflationSessionId = (id) => {
    this.props.value.removeInflationSessionId(id)
    if (this.props.onChangeComplete) {
      this.props.onChangeComplete(this.props.value) // This is just forcing an update at the parent level
    }
  }

  protected submitEditing(target, valuePath) {
    const { onSubmitEditing } = this.props
    if (onSubmitEditing) {
      const ref = _.get(this.trackedRefsByValuePath, valuePath)?.ref
      onSubmitEditing(target, ref, this.trackedRefsByValuePath, this.props.value)
    }
  }

  protected async validate(schema, path, newValue) {
    const { value, errors } = this.props
    const oldValue = _.get(value, path)
    value.set(path, newValue)
    const errorsTree = buildErrorTree(await value.validate())
    value.set(path, oldValue)
    this.setState({ errorsTree, errors })
    return _.get(errorsTree, path)
  }

  /****************************************************************************/
  // Rendering
  /****************************************************************************/

  protected makeRootScope() {
    const { actions, state, value } = this.props
    const { uiContext, uiSchema, uiSchemaPath, extras } = this.state
    const translationTable = this.state?.translationTable || {}
    const rootState = [
      value,
      { ...state, extras },
      { _, entity: value, Formatter },
      { translationTable },
      this.transientVariables,
    ]
    const rootFrame = Immutable.Map({
      actions,
      context: {
        dataSchema: null,
        dataSchemaPath: [],
        renderer: this,
        translationTable,
        uiContext,
        uiSchema,
        uiSchemaPath,
        value,
        valuePath: [],
      },
      key: '',
      // NOTE: we put value before state so that value's properties don't
      // shadow the state's properties
      state: rootState,
      ref: {},
    })
    return new FramesManager(Immutable.List.of(rootFrame))
  }

  protected makeSchemaPath(path, fragmentPath) {
    // if fragmentPath is . we will inherit the current path
    if (fragmentPath === '.') return path
    // if fragmentPath starts with ~ we will not inherit the current path
    if (fragmentPath[0] === '~') {
      path = []
      fragmentPath = fragmentPath.substr(1)
    }
    let pathFragment
    try {
      pathFragment = _.isArray(fragmentPath) ? fragmentPath : fragmentPath.split('.')
    } catch(e) {
      console.log(`failed to split path fragment: ${e.message} fragment ${fragmentPath} type ${typeof(fragmentPath)}`)
      return path
    }
    pathFragment = JSONSchemaResolver.getSchemaPathFromValuePath(pathFragment)
    return path.concat(pathFragment)

  }

  protected makeValuePath(path, fragmentPath) {
    // if fragmentPath is . we will inherit the current path
    if (fragmentPath === '.') return path
    // if fragmentPath starts with ~ we will not inherit the current path
    if (fragmentPath[0] === '~') {
      path = []
      fragmentPath = fragmentPath.substr(1)
    }
    const pathFragment = _.isArray(fragmentPath) ? fragmentPath : fragmentPath.split('.')
    return path.concat(pathFragment)
  }

  protected materializeSchemaProps(frames, schema) {
    if (!schema) {
      return
    }
    const { properties, required, type, ...schemaProps } = schema
    return schemaProps
  }

  protected makeComponentProps(frames, defaultProps) {
    const { isEditable }  = this.props
    const { contextProps } = this.state
    const dataSchema = frames.getContext('dataSchema')
    const valuePath = frames.getContext('valuePath')
    const uiSchema = frames.getContext('uiSchema')
    const uiSchemaPath = frames.getContext('uiSchemaPath')
    const translationTable = _.merge(
      {},
      frames.getContext('translationTable'),
      uiSchema.translations
    )
    const type = uiSchema.type
    const component = this.props.componentsMap[type]
    if (!component) {
      throw new Error(`[Renderer]: Cannot find component with type=${type} uiSchema=${JSON.stringify(uiSchema)}`)
    }
    const schemaProps = this.materializeSchemaProps(frames, dataSchema)
    const uiSchemaProps = this.materializeUiSchemaProps(frames, uiSchema)

    // NOTE: it is important that onChange is only defined when value property
    // is explicitly defined on uiSchema. E.g. we don't want to bind to the
    // onChange of a view or something like that
    let formProps
    if (uiSchema.value) {
      const value = this.getInitialValue(valuePath, dataSchema)
      // TODO(Peter): HACK! alternate error path e.g. this is necessary for fileInput
      const errorPath = uiSchemaProps.errorPath
      const errorsMap = this.getErrors(valuePath, errorPath)
      formProps = {
        errorsMap,
        errors: _.get(errorsMap, '_errors'),
        isRequired: this.getIsRequired(frames, valuePath),
        onChange: (newValue, silentUpdate = false, overridePath = null) => {
          this.errorState = this.getErrorInfo()
          this.handleChange(overridePath || valuePath, newValue, value, silentUpdate)
        },
        onSubmitEditing: (evt) => this.submitEditing(evt?.target, valuePath),
        validate: (value) => this.validate(dataSchema, valuePath, value),
        addInflationSessionId: (id) => this.addInflationSessionId(id),
        removeInflationSessionId: (id) => this.removeInflationSessionId(id),
        value,
      }

      // Allow uiSchema `onChange` to override. Driver app's
      // `LoadInformationSection` Input -> Select mutation also sets an
      // `onChange` action via uiSchema as a way of overriding the handling of
      // user selection in the Select component.
      if (uiSchemaProps.onChange) {
        formProps.onChange = uiSchemaProps.onChange
      }
    } else if (uiSchema.transientValue) {
      // NOTE: this enables all components to be able to use transientValue to
      // write a transient variable.
      const value = frames.getValue(uiSchema.transientValue)
      formProps = {
        onChange: (newValue) => {
          this.errorState = this.getErrorInfo()
          const value = frames.getValue(uiSchema.transientValue)
          const silentUpdate = false
          const isTransient = true
          this.handleChange(uiSchema.transientValue, newValue, value, silentUpdate, isTransient)
        },
        value,
      }
    }

    formProps = {
      ...formProps,
      formulaRegistry: this.formulaComponentRegistry,
      // TODO(Dan): Refactor this out in favor of context updates.
      // Currently this is used to branch out from `onChange` to support ability
      // for abstract-list item clicks to write to another path.
      forceUpdate: () => this.forceUpdate(),
    }

    // If rendering in read-only mode ('isEditable' is false), make all children readonly as well.
    // Since there are various flags to set it to read-only, apply them all.
    const readonlyFlags = !isEditable ? {
      isEditable: false,
      isDisabled: true
    } : {}

    // we are layering the props
    // 1. the defaultProps allows caller of makeObject to add default props
    // 2. the contextProps which is injected into every component in the tree
    // 3. the schemaProps has attributes such as: label, format
    // 4. the uiSchemaProps has the opportunity to override the schemaProps
    // 5. the form specific props such as errors, value, schema etc...
    const componentProps = {
      ...readonlyFlags,
      ...defaultProps,
      ...contextProps,
      ...schemaProps,
      ...uiSchemaProps,
      ...formProps,
      translationTable,
      frames,
      key: frames.getKey(),
      ref: this.createRefForFrame(frames),
    }
    return { component, componentProps }
  }

  protected createChildFrame(frames, currentContext, currentState?) {
    const { uiSchema, uiSchemaPath } = currentContext
    const entity = this.props.value
    const schemaResolver = entity.schemaResolver
    const dataSchemaPath = currentContext.dataSchemaPath || frames.getContext('dataSchemaPath')
    const uiContext = currentContext.uiContext || frames.getContext('uiContext')
    const valuePath = currentContext.valuePath || frames.getContext('valuePath')
    const resolved = schemaResolver.resolveSubschema(uiContext, uiSchema)
    currentContext = {
      ...currentContext,
      uiContext: resolved.context,
      uiSchema: resolved.schema,
      uiSchemaPath,
    }
    const newUiSchema = currentContext.uiSchema
    // . (inherit parent context)
    // ~[namespace][...] (use global context)
    // [namespace][...] (use global context)
    if (newUiSchema.value) {
      const newDataSchemaPath = this.makeSchemaPath(dataSchemaPath, newUiSchema.value)
      const newDataSchema = this.getComponentDataSchema(newDataSchemaPath)
      const newValuePath = this.makeValuePath(valuePath, newUiSchema.value)
      currentContext = {
        ...currentContext,
        dataSchema: newDataSchema,
        dataSchemaPath: newDataSchemaPath,
        valuePath: newValuePath,
      }
    }
    // We need to put value in state first in case capture depends on "value"
    if (newUiSchema.captures) {
      const newDataSchema = currentContext.dataSchema
      const newValuePath = currentContext.valuePath
      const value = this.getInitialValue(newValuePath, newDataSchema)
      currentState = currentState ? currentState : {}
      currentState['value'] = value
      _.forEach(newUiSchema.captures, (capturePath, captureKey) => {
        // get value from currentState first and then try frames
        const captureValue = _.get(currentState, capturePath, frames.getValue(capturePath))
        currentState[captureKey] = captureValue
      })
    }
    return super.createChildFrame(frames, currentContext, currentState)
  }

  // We have to override createElementFromFrame because entity uiSchema allow referencing
  // other uiSchema that are within the same uiContext or in a remote uiContext
  // TODO(Peter): we might want to generalize this logic to the view renderer
  // in the future. However, schema resolution in entity also search through
  // the active mixins when it try to resolve a schema
  public createElementFromFrame(frames, defaultProps = {}) {
    const uiSchema = frames.getContext('uiSchema')
    const { uiSchemaFilter } = this.props

    if (uiSchemaFilter && !uiSchemaFilter(uiSchema)) {
      return this.createEmptyElement(frames)
    }

    const uiSchemaPath = frames.getContext('uiSchemaPath')
    const { component, componentProps } = this.makeComponentProps(frames, defaultProps)
    const children = this.makeChildren(componentProps, uiSchema, uiSchemaPath)
    return React.createElement(component, componentProps, children)
  }
}

const findElement = (root, matcher: (node) => boolean) => {
  const depthFirstSearch = (node) => {
    if (matcher(node.ref)) {
      return node.ref
    }
    // take care of normal children and lists
    const children = node.children || _.get(node, 'items.children', [])
    for (const child of children) {
      if (!child) {
        continue
      }
      const result = depthFirstSearch(child)
      if (result) {
        return result
      }
    }
  }
  return depthFirstSearch(root)
}
