import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { renderEventTimestamp } from '../util'

interface IInvoiceSubmissionEventItemInterface extends IBaseProps {
  entity: any
  item: any
  isFirst: boolean
  isLast: boolean
}

export const InvoiceSubmissionEventItem: React.FC<IInvoiceSubmissionEventItemInterface> = (props) => {
  const { className, item, isFirst } = props
  const { creationDate, modifiedDate, invoiceSubmissionEvent, owner } = item

  const author = _.get(invoiceSubmissionEvent, 'author', '')
  const invoiceNumber = _.get(invoiceSubmissionEvent, 'invoiceNumber', '')
  const recipient = _.get(invoiceSubmissionEvent, 'recipient', {})
  const documentsSent = _.get(invoiceSubmissionEvent, 'documentsSent', [])

  const renderContent = () => {
    const docs = _.join(documentsSent, ', ')
    const preference = _.get(recipient, 'preference', '')
    const address = _.get(recipient, 'address', '')

    return (<div className='pre-wrap'>
      Submitted Invoice {invoiceNumber} for mail delivery <br/>
      Document attached for this invoice: {docs} <br/>
      Invoice preference set to {preference}: <br/>
      {address}
      </div>
    )
  }

  return (
    <div
      className={classNames('c-timelineEvent', className)}
      key={item.uniqueId}
    >
      <div
        className={classNames('c-timelineEvent-line', className, {
          'c-timelineEvent-line--topCutOff': isFirst,
        })}
      />
      <div className='c-timelineEvent-dot' />
      <div className='c-timelineEvent-contentContainer'>
        <div className='c-timelineEvent-body'>
          <HelpBlock>
            <span className='b'>{_.get(author, 'displayName', '')}</span> on {renderEventTimestamp(creationDate)}:<br />
            {renderContent()}
          </HelpBlock>
        </div>
      </div>
    </div>
  )
}
