import React, { useContext } from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import { Tooltip, Position } from '@blueprintjs/core'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { Owner } from 'shared-libs/generated/server-types/entity'
import { renderAuthorHeader, renderEventTimestamp, renderDistance, renderDeviceInfoTooltipContent } from '../util'
import { renderEventChange, EventChanges } from './event-changes'

interface IEditItemProps extends IBaseProps {
  event: any
  owner: Owner
  index: number
  isFirst: boolean
  isLast: boolean
  workflowEntity: any
  appointmentDestinationPath?: string
}

export const EditItem: React.FC<IEditItemProps> = (props) => {
  const { className, appointmentDestinationPath, event, isFirst, owner, workflowEntity } = props
  const { createdBy, createdAt, creationDate, processedDate } = event
  const { settings } = useContext(AppNavigatorContext)

  const plan = _.get(workflowEntity, 'core_storyboard_execution.storyboardPlan.core_storyboard_plan')

  if (_.isEmpty(plan) || _.isEmpty(event.outputMappings)) {
    return null
  }

  const pathId = event.source && (event.source.taskId || event.source.sceneId)
  const pathName = plan.getNameFromPathId(pathId)

  const changeElements = event.outputMappings
    .map((mapping, index) => renderEventChange({ mapping, workflowEntity: workflowEntity, index }))
    .filter(Boolean)
  if (_.isEmpty(changeElements) && !settings.isAdmin) {
    return null
  }

  return (
    <div className={classNames('c-timelineEvent', className)} key={event.uniqueId}>
      <div
        className={classNames('c-timelineEvent-line', className, {
          'c-timelineEvent-line--topCutOff': isFirst,
        })}
      />
      <div className="c-timelineEvent-dot" />
      <div className="c-timelineEvent-contentContainer">
        <div className="c-timelineEvent-header">
          <div className="c-timelineEvent-author">
            <Avatar
              className='c-timelineEvent-avatar'
              name={createdBy.displayName}
              size='xs'
            />
            <Tooltip
              disabled={!event.deviceInfo}
              content={renderDeviceInfoTooltipContent(event.deviceInfo)}
              position={Position.TOP}
            >
              <Label className="c-timelineEvent-authorName">
                {renderAuthorHeader(createdBy, owner?.firm)}
              </Label>
            </Tooltip>
          </div>
          <HelpBlock className="c-timelineEvent-timestamp">
            {renderEventTimestamp(creationDate, processedDate, settings)}
          </HelpBlock>
        </div>
        <div className="c-timelineEvent-body">
          {renderSubheading(pathName)}
          <EventChanges>{changeElements}</EventChanges>
        </div>
        <div className="c-timelineEvent-footer">
          {createdAt && renderDistance(event, workflowEntity, 'miles', settings, appointmentDestinationPath)}
        </div>
      </div>
    </div>
  )
}

function renderSubheading(pathName: string | undefined) {
  if (!pathName) {
    return <p>Updated the workflow</p>
  } else {
    return (
      <p>
        Completed <strong>{pathName}</strong>
      </p>
    )
  }
}
