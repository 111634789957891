import { Classes, Hotkey, Hotkeys, HotkeysTarget, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import 'browser/components/atomic-elements/atoms/panel/_panel.scss'
import { SheetManager } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'

/**
 * @uiComponent
 */
interface IPanelProps extends IBaseProps {
  bodyClassName?: string
  headerClassName?: string
  isMinimized?: boolean
  onClose: () => void
  panelFooter?: React.ReactElement<any>
  paperLevel?: string
  size?: string
  title: string
}

interface IPanelState {
  hasSheet: boolean
  isMinimized: boolean
}

// TODO(Peter): we need panel / entity panel
@HotkeysTarget
export class Panel extends React.Component<IPanelProps, IPanelState, never> {

  public static defaultProps: Partial<IPanelProps> = {
    isMinimized: false,
    onClose: _.noop,
    paperLevel: '4',
  }

  private panelRef: HTMLElement

  constructor(props) {
    super(props)
    this.state = {
      hasSheet: false,
      isMinimized: props.isMinimized,
    }
  }

  public componentDidMount() {
    this.focusPanel()
  }

  public render() {
    // TODO(louis): crazy logic, needs to be cleaned up
    // 50 and 90 represent the height of the navbar and the c-header, 14 to buffer
    // should listen to window resize
    const maxHeight = $(window).height() - (50 + 90 + 14)
    const maxHeightStyle = {
      maxHeight: maxHeight + 'px',
    }
    const {
      bodyClassName,
      children,
      className,
      panelFooter,
      paperLevel,
      size,
      title,
    } = this.props
    const { isMinimized } = this.state
    const sizeClass = _.isEmpty(size) ? '' : `c-panel--${size}`
    const paperLevelClassName = `paper--zDepth-${paperLevel}`
    // This is needed for IE11
    // 40 header + 60 footer height
    const panelMaxHeight = maxHeight - 100
    return (
      <div
        className='c-panel-container'
        data-debug-id={`panel:${title}`}
        tabIndex={-1}
        ref={this.handleSetPanelRef}
      >
        <SheetManager
          className={
            classNames('c-panel paper', paperLevelClassName, sizeClass, className, {
              'c-panel--isMinimized': isMinimized,
            },
            )}
          onChange={this.handleSheetManagerChange}
          style={maxHeightStyle}
        >
          {this.renderPanelHeader()}
          <div
            className={classNames('c-panelBody', bodyClassName, {
              'u-hide': isMinimized,
            })}
            style={{
              maxHeight: panelMaxHeight,
            }}
          >
            {children}
          </div>
          {isMinimized ? null : panelFooter}
        </SheetManager>
      </div>
    )
  }

  public renderHotkeys() {
    const { hasSheet } = this.state
    return (
      <Hotkeys>
        <Hotkey
          allowInInput={true}
          disabled={hasSheet}
          group='Panels'
          combo='esc'
          label='Close Popover'
          onKeyDown={(event) => this.props.onClose()}
          stopPropagation={true}
          preventDefault={true}
        />
      </Hotkeys>
    )
  }

  private renderPanelHeader() {
    const { title, headerClassName, onClose } = this.props
    const { isMinimized } = this.state
    // TODO(louis): Revise this to how inbox.google.com does it.
    return (
      <div
        className={classNames('c-panelHeader', headerClassName)}
      >
        <div
          className='c-panelHeader-group'
          onClick={this.handleMaximize}
        >
          <div
            className={classNames('c-panelHeader-title', {
              'c-panelHeader-title--isMinimized': isMinimized,
            })}
          >
            {title}
          </div>
        </div>
        <div className='c-panelHeader-group'>
          <div
            className={classNames('c-panelHeader-item c-panelHeader-actionIcon', {
              'u-hide': isMinimized,
            })}
          >
            <Button
              onClick={this.handleMinimize}
              className={Classes.MINIMAL}
            >
              <Icon icon={IconNames.MINIMIZE} />
            </Button>
          </div>
          <div
            className={classNames('c-panelHeader-item c-panelHeader-actionIcon', {
              'u-hide': !isMinimized,
            })}
          >
            <Button
              onClick={this.handleMaximize}
              className={Classes.MINIMAL}
            >
              <Icon icon={IconNames.MAXIMIZE} />
            </Button>
          </div>
          <div className='c-panelHeader-item c-panelHeader-actionIcon'>
            <Button
              onClick={onClose}
              className={Classes.MINIMAL}
            >
              <Icon icon={IconNames.CROSS} />
            </Button>
          </div>
        </div>
      </div>
    )
  }

  private focusPanel = () => {
    if (this.panelRef != null) {
      this.panelRef.focus()
    }
  }

  private handleSheetManagerChange = (sheets: any[]) => {
    const hasSheet = sheets.length > 0
    this.setState({ hasSheet })
    // once all sheets are closed, reset focus on the panel
    if (!hasSheet) {
      this.focusPanel()
    }
  }

  private handleSetPanelRef = (ref: Element) => {
    this.panelRef = ref as HTMLElement
  }

  private handleMinimize = () => {
    this.setState({
      isMinimized: true,
    })
  }

  private handleMaximize = () => {
    this.setState({
      isMinimized: false,
    })
  }
}
