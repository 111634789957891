import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { EntityPreview } from 'browser/components/atomic-elements/organisms/entity/entity-preview/entity-preview'
import ComponentsMap from 'browser/components'
import _ from 'lodash'
import { Colors, Icon, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

/**
 * @uiComponent
 */
interface ITableEntityPreviewCellProps extends IBaseProps {
  text: string
  value: string
  entity: any
  entities: any
  previewSchema: string
}

export const TableEntityPreviewCell: React.FC<ITableEntityPreviewCellProps> = (props) => {
  const { value, className, text, entity, previewSchema, entities } = props
  if (_.isEmpty(entity) && _.isEmpty(entities)) {
    return <div className={className}>{text}</div>
  }

  let allEntities = entities
  if (entity && _.isEmpty(entities)) {
    allEntities = [entity]
  }

  const previews = _.map(allEntities, (entity) => {
    return (
      <EntityPreview
        key={entity.entityId}
        value={entity}
        components={ComponentsMap}
        previewSchema={previewSchema || 'uiSchema.web.entityPreviewPopover'}
      />
    )
  })

  const previewContent = <div className="c-entityPreviewPopover">{previews}</div>

  return (
    <Popover
      content={previewContent}
      interactionKind={PopoverInteractionKind.HOVER}
      minimal={true}
      position={Position.BOTTOM_LEFT}
    >
      <div className={className}>
        <Icon icon={IconNames.INFO_SIGN} color={Colors.ORANGE5} className={'pr1'} />
        {text}
      </div>
    </Popover>
  )
}
