import _ from 'lodash'

export function abs(value: number): number {
  return Math.abs(value)
}

export function ceil(value: number): number {
  return Math.ceil(value)
}

export function floor(value: number): number {
  return Math.floor(value)
}

export function round(...params: any[]): number {
  return _.round.apply(null, params)
}

export function sum(...params: any[]): number {
  return _.sum.apply(null, params)
}

export function sumBy(...params: any[]): number {
  return _.sumBy.apply(null, params)
}

export function max(value: number[]): number {
  return _.max(value)
}

export function min(value: number[]): number {
  return _.min(value)
}

export function ln(value: number): number {
  return Math.log2(value)
}

export function log(value: number, base: number): number {
  if (base != null) {
    return Math.log(value) / Math.log(base)
  }
  return Math.log(value)
}

export function exp(value: number): number {
  return Math.exp(value)
}

export function pow(base: number, exp: number): number {
  return Math.pow(base, exp)
}

export function sign(value: number): number {
  return Math.sign(value)
}

export function isEven(value: number): boolean {
  return value % 2 === 0
}

export function isOdd(value: number): boolean {
  return value % 2 !== 0
}

export function gcd(a, b) {
  return !b ? a : gcd(b, a % b)
}

export function lcm(a, b) {
  return (a * b) / gcd(a, b)
}

export function clamp(...params: number[]): number {
  return _.clamp.apply(null, params)
}

export function inRange(...params: number[]): boolean {
  return _.inRange.apply(null, params)
}

export function random(...params: number[]): number {
  return _.random.apply(null, params)
}

export function diffZero(...params: any[]): number {
  const diff = parseFloat(params[0]) - parseFloat(params[1])
  return diff < 0 ? 0 : diff
}

/**
 * Calculate distance in meters between two geo locations or addresses.
 *
 * iOS/Android both have native function to calculate the distance between two
 * locations, but since this is pretty simple, just copy-n-paste in js so it
 * could used in mobile and web
 *
 * https://www.movable-type.co.uk/scripts/latlong.html
 *
 * @param start start address
 * @param end end address
 *
 * @return distance between two points in miles
 */
export function geoDistance(start: any, end: any): number {
  const R = 6371e3 // radius of earth in meters
  const startAddr = _.get(start, 'geolocation', start)
  const endAddr = _.get(end, 'geolocation', end)

  const lat1 = _.get(startAddr, 'latitude', 0)
  const lat2 = _.get(endAddr, 'latitude', 0)

  const lon1 = _.get(startAddr, 'longitude', 0)
  const lon2 = _.get(endAddr, 'longitude', 0)

  const φ1 = (lat1 * Math.PI) / 180 // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180
  const Δφ = ((lat2 - lat1) * Math.PI) / 180
  const Δλ = ((lon2 - lon1) * Math.PI) / 180

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c // in meters
}

export function metersToMiles(meters: number): number {
  return meters * 0.000621371
}
