import { Tab, Tabs } from '@blueprintjs/core'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { ErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/error-block'

import { GeoRegionCityTabPanel } from './geo-region-city-tab-panel'
import { GeoRegionStateTabPanel } from './geo-region-state-tab-panel'
import { GeoRegionZipTabPanel } from './geo-region-zip-tab-panel'

/**
 * @uiComponent
 */
export interface IGeoRegionInputProps extends IBaseProps {
  defaultType?: string
  errors?: any[]
  helpText?: string
  showEditableFields?: boolean
  showDeadHeadInput?: boolean
  showGeoRegionTabs?: boolean
  innerDensity?: string
  isDisabled?: boolean
  isHorizontalLayout?: boolean
  onChange: (value: any) => void
  placeholder?: string
  renderCityTabPanel?: () => React.ReactElement<any>
  renderStateTabPanel?: () => React.ReactElement<any>
  renderZipTabPanel?: () => React.ReactElement<any>
  size?: string
  types?: any[]
  value?: any
}

interface IGeoRegionInputState {
  activeTabId: string
}

export class GeoRegionInput extends React.Component<IGeoRegionInputProps, IGeoRegionInputState> {

  public static defaultProps: Partial<IGeoRegionInputProps> = {
    defaultType: 'address',
    showDeadHeadInput: true,
    showGeoRegionTabs: true,
    types: [
      { id: 'address', title: 'City' },
      { id: 'states', title: 'State' },
      { id: 'zipCode', title: 'ZIP' },
    ],
  }

  constructor(props: IGeoRegionInputProps) {
    super(props)
    const { defaultType, value } = props
    this.state = {
      activeTabId: this.valueToTabId(value),
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = this.props

    if (value !== nextProps.value) {
      this.setState({ activeTabId: this.valueToTabId(nextProps.value) })
    }
  }

  public focus() {
    // TODO(peter/louis): Depends on the value and the tabs
    // this.input.focus()
    return false
  }

  public render() {
    const { showEditableFields, className, showGeoRegionTabs, types, value } = this.props
    const { activeTabId } = this.state
    if (showEditableFields) {
      return (
        <div className={classNames(className)}>
          <Tabs
            className={classNames('c-tabButtons c-tabs c-tabs--leftFlush c-tabs--rightFlush c-tabButtons--square c-geoRegionInputTabs', {
              'c-tabButtons--hide': !showGeoRegionTabs,
            })}
            id='geoRegionInputTabs'
            onChange={this.handleTabChange}
            selectedTabId={activeTabId}
          >
            <Tab
              className='c-tab--first'
              id={types[0].id}
              title={types[0].title}
              panel={this.renderCityTabPanel()}
            />
            <Tab
              id={types[1].id}
              title={types[1].title}
              panel={this.renderStateTabPanel()}
            />
            <Tab
              className='c-tab--last'
              id={types[2].id}
              title={types[2].title}
              panel={this.renderZipTabPanel()}
            />
          </Tabs>
          {this.renderErrors()}
        </div>
      )
    } else {
      let content = (<span className='c-placeholderColor'>-</span>)
      let deadhead
      if (!_.isEmpty(value)) {
        if (value.type === 'address') {
          content = (
            <span>{value.address.locality}, {value.address.region}</span>
          )
          if (value.radius && value.radius.value) {
            deadhead = (
              <span className='c-placeholderColor'> + {value.radius.value} {value.radius.unit}</span>
            )
          }
        } else if (value.type === 'states') {
          content = (
            <span>{value.states.toString()}</span>
          )
        } else if (value.type === 'zipCode') {
          content = (
            <span>Zipcode: {value.zipCode}</span>
          )
        }
      }
      return (
        <div className={classNames('c-fakeInputContainer', className)}>
          {content}<span>{deadhead}</span>
        </div>
      )
    }
  }

  private renderCityTabPanel() {
    const { renderCityTabPanel } = this.props
    if (renderCityTabPanel) {
      return renderCityTabPanel()
    }
    return <GeoRegionCityTabPanel {...this.props} />
  }

  private renderStateTabPanel() {
    const { renderStateTabPanel } = this.props
    if (renderStateTabPanel) {
      return renderStateTabPanel()
    }
    return <GeoRegionStateTabPanel {...this.props} />
  }

  private renderZipTabPanel() {
    const { renderZipTabPanel } = this.props
    if (renderZipTabPanel) {
      return renderZipTabPanel()
    }
    return <GeoRegionZipTabPanel {...this.props} />
  }

  private renderErrors() {
    if (!_.isEmpty(this.props.errors)) {
      return (
        <ErrorBlock
          errorText={`Please add at least an address, state, or zip code range.`}
        />
      )
    }
  }

  private handleTabChange = (activeTabId: string) => {
    this.setState({ activeTabId })
  }

  /*
   * funky logic to map elastic search value to current tab
   * since city filtering is both radius filtering and exact match locality/region
   * filtering, need to map both of them to tab id 'geoDistance'
   *
   * TODO: we need to decouple the filter type from tab id
   */
  private valueToTabId(value: any) {
    const filterType = _.get(value, 'filterType')
    const type =  _.get(value, 'type', this.props.defaultType)
    if (filterType === 'geoDistance' && type === 'match') {
      return 'geoDistance'
    }
    return type
  }
}
