import _ from 'lodash'
import React, { Component } from 'react'
import styled from 'styled-components'

import apis from 'browser/app/models/apis'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { globalTranslationTable } from 'browser/mobile/util/global-translation-table'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { translateString } from 'shared-libs/helpers/utils'
import { stringifyError } from 'shared-libs/models/utils'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 6rem;
`

const IdentityLabel = styled.p`
  font-size: 1rem;
  margin-top: 1rem;
`

const TokenLabel = styled.p`
  font-size: 3rem;
`

interface IGenerateOneUseTokenDialogProps {
  onClose: () => void
  targetUserId: string
  identity?: string
}

interface IGenerateOneUseTokenDialogState {
  isLoading: boolean
  token: string
  error?: any
}

export class GenerateOneUseTokenDialog extends Component<
  IGenerateOneUseTokenDialogProps,
  IGenerateOneUseTokenDialogState
> {
  public static open(props) {
    return OverlayManager.openOverlay(this, { ...props })
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      token: null,
    }
  }

  public componentDidMount() {
    this.fetchToken()
  }

  public render() {
    const { error } = this.state

    const primaryText = !_.isNil(error) ? 'Retry' : 'Copy'
    const translatedPrimaryText = translateString(primaryText, globalTranslationTable)
    const translatedCancelText = translateString('Close', globalTranslationTable)
    const translatedHeaderText = translateString('Generate One Use Token', globalTranslationTable)

    return (
      <Modal>
        <div>
          <div className="c-modalHeader">
            <h4 className="c-modal-title u-textCenter">{translatedHeaderText}</h4>
          </div>
          <ContentWrapper>{this.renderContent()}</ContentWrapper>
          <Footer
            primaryButtonText={translatedPrimaryText}
            onPrimaryButtonClick={this.handlePrimaryClick}
            cancelButtonText={translatedCancelText}
            onCancelButtonClick={this.handleClose}
          />
        </div>
      </Modal>
    )
  }

  private renderContent() {
    const { identity } = this.props
    const { isLoading, token, error } = this.state
    if (isLoading) {
      return <LoadingSpinner />
    } else if (!_.isNil(error)) {
      return <IdentityLabel>{stringifyError(error)}</IdentityLabel>
    }
    return (
      <>
        {identity && <IdentityLabel>{identity}</IdentityLabel>}
        <TokenLabel>{token}</TokenLabel>
      </>
    )
  }

  private handlePrimaryClick = () => {
    const { token, error } = this.state

    if (!_.isNil(error)) {
      void this.fetchToken()
    } else {
      this.copyToClipboard(token)
    }
  }

  private handleClose = () => {
    this.props.onClose()
  }

  private fetchToken() {
    this.setState({ isLoading: true, error: undefined })

    apis
      .sendImpersonateRequest(this.props.targetUserId, this.props.identity)
      .then((payload) => {
        this.setState({ isLoading: false, token: payload.token })
      })
      .catch((error) => {
        this.setState({ isLoading: false, error: error })
      })
  }

  public copyToClipboard(link) {
    const translatedText = translateString('Copied token to the clipboard!', globalTranslationTable)

    navigator.clipboard.writeText(link)
    Toast.show({
      message: translatedText,
      position: Position.BOTTOM_RIGHT,
    })
  }
}
