import React from 'react'

import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { Panel } from 'browser/components/atomic-elements/atoms/panel'
import { Textarea } from 'browser/components/atomic-elements/atoms/textarea/textarea'
import 'browser/components/atomic-elements/organisms/chat-panel/_chat-panel.scss'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { SheetContext } from '../../atoms/sheet/sheet-manager'

export class ChatPanel extends React.Component<IBaseProps, any> {

  public static open(props) {
    OverlayManager.openOverlay(this, props)
  }

  constructor(props) {
    super(props)
  }

  public render() {
    return (
      <SheetContext.Consumer>
        {({closeOverlay}) => (
          <Panel
            size='xs'
            onClose={closeOverlay}
            title='Jim Smith | Active 2m ago'
            bodyClassName='c-panelBody--collapse'
          >
            <div className='c-timelineEvent-header'>
              <div className='c-timelineEvent-author'>
                <Avatar
                  className='c-timelineEvent-avatar'
                  name='Jim Smith'
                  size='xs'
                />
                <Label className='c-timelineEvent-authorName'>
                  Jim Smith
                </Label>
              </div>
              <Label
                isSecondary={true}
                className='c-timelineEvent-timestamp'
              >
                10:23 AM
              </Label>
            </div>
            <div className='c-timelineEvent-body'>
              <p>
                I would still be down for Panda
              </p>
            </div>
            <div>
              Joey Hurlocker [11:44 AM]
              I would still be down for Panda
            </div>
            <div>
              Olivia Dorgan [11:49 AM]
              is anyone willing to grab? Matt and I have a demo in 10
            </div>
            <div>
              Louis de Valliere [11:53 AM]
              whole foooods?
            </div>
            <div>
              Peter Pong [11:59 AM]
              I am going to get food myself
            </div>
            <div>
              Louis de Valliere [12:05 PM]
              @davidsong @mark ?
            </div>
            <Textarea
              autoFocus={true}
              minRows={1}
              placeholder='Send a message'
            />
          </Panel>
        )}
      </SheetContext.Consumer>
    )
  }
}
